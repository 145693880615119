import { combineReducers } from "redux";
import homepage from "./homepage.reducer";
import common from "./common.reducer";
import breadcrumbReducer from "./breadcrumb.reducer";
import { reducer as notifications } from "react-notification-system-redux";
import authReducer from "./auth.reducer";
import search from "./search.reducer";
import productListingReducer from "./productlisting.reducer";
import wishListReducer from "./wishlist.reducer";
import cartReducer from "./cart.reducer";
import productDetailReducer from "./productDetail.reducer";
import megaMenuReducer from "./megaMenu.reducer";
import checkoutReducer from "./checkout.reducer";
import addressReducer from "./address.reducer";
import brandsReducer from "./brands.reducer";
import footerReducer from "./footer.reducer";
import myAccountReducer from "./myAccount.reducer";
import orderReducer from "./order.reducer";
import paymentReducer from "./payment.reducer";
import needHelpReducer from "./needHelp.reducer";
import sendUsEmailReducer from "./sendUsEmail.reducer";
import storeLocatorReducer from "./storeLocator.reducer";
import sizeChartReducer from "./sizeChart.reducer";
import quickFilterReducer from "./quickFilter.reducer";
import pageReducer from "./page.reducer";
import lisaReducer from "./lisa.reducer";
import sectionReducer from "./sections.reducer";

const rootReducer = combineReducers({
  notifications,
  homepage,
  common,
  breadcrumbReducer,
  authReducer,
  search,
  productListingReducer,
  wishListReducer,
  cartReducer,
  productDetailReducer,
  megaMenuReducer,
  checkoutReducer,
  addressReducer,
  brandsReducer,
  footerReducer,
  myAccountReducer,
  orderReducer,
  paymentReducer,
  needHelpReducer,
  sendUsEmailReducer,
  storeLocatorReducer,
  sizeChartReducer,
  quickFilterReducer,
  page: pageReducer,
  lisaReducer,
  sectionReducer
});

export default rootReducer;
