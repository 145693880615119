import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Swiper from "react-id-swiper";
import isEmpty from "lodash/isEmpty";
import CarouselItem from "./CarouselItem";

import { isMobile, isServer } from "../../../util";
import { selectLanguage } from "../../../util/selectors";
import {
  getAutoScrollConfig,
  getInfiniteLoopConfig
} from "../../../util/carousel";
import { getReorderedArray } from "../../../util/dynamic-banner";

import { BULLET_TYPES, CONTROLLER_TYPES, LANGUAGE } from "../../../constants";

const getElement = (el, className) => el && el.querySelector(className);

const CarouselWrapperRow = ({ rowData, bannerModuleName, bannerPageTitle }) => {
  const {
    autoScroll,
    width,
    styles,
    imageList,
    indicatorStyles,
    controllerStyles,
    controllerType
  } = rowData;

  const language = useSelector(selectLanguage);
  const carouselRef = useRef(null);

  const activeBullet =
    !isServer &&
    carouselRef?.current?.querySelector(`.slider-bullet-active${rowData.id}`);

  const setControllerStyles = () => {
    const carouselWrapper = !isServer && carouselRef?.current;

    const nextButton = getElement(carouselWrapper, ".swiper-button-next");
    const prevButton = getElement(carouselWrapper, ".swiper-button-prev");

    if (nextButton && prevButton && controllerStyles) {
      nextButton.style.background = controllerStyles.backgroundControllerColor;
      prevButton.style.background = controllerStyles.backgroundControllerColor;
    }
  };

  const setActiveBulletColor = () => {
    const activeBullet =
      !isServer &&
      carouselRef?.current?.querySelector(`.slider-bullet-active${rowData.id}`);
    if (activeBullet) {
      activeBullet.style.background = rowData.indicatorStyles.indicatorColor;
    }
  };

  if (rowData.indicatorType === BULLET_TYPES.NONE) {
    const carouselWrapper =
      !isServer && document.querySelector(`#carousel-${rowData.id}`);

    if (carouselWrapper) {
      carouselWrapper.classList.add("without-bullets");
    }
  }

  useEffect(() => {
    const isBulletType =
      rowData.indicatorType !== BULLET_TYPES.SCROLL ||
      rowData.indicatorType === BULLET_TYPES.NONE;

    if (isBulletType) {
      onSlideChange();
    }
  }, [activeBullet, imageList.length]);

  useEffect(() => {
    const isIndicatorType = rowData.indicatorType === BULLET_TYPES.SCROLL;
    if (isIndicatorType) {
      setIndicatorColor();
    }
    if (controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  }, []);

  const onSlideChange = () => {
    const allBullets =
      !isServer &&
      carouselRef?.current?.querySelectorAll(`.slider-bullet-${rowData.id}`);

    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background = indicatorStyles.backgroundIndicatorColor;
      });
      setActiveBulletColor();
    }
  };

  const bullets = {
    pagination: {
      el: ".swiper-pagination",
      renderBullet: (index, className) => {
        return `<span class='${className} ${rowData.indicatorType} slider-bullet-${rowData.id}'></span>`;
      },
      bulletActiveClass: `swiper-pagination-bullet-active slider-bullet-active${rowData.id}`,
      clickable: true
    },
    on: {
      slideChange: onSlideChange
    }
  };

  const indicator = {
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true
    }
  };

  const paginationForSwiper =
    rowData.indicatorType === BULLET_TYPES.SCROLL ? indicator : bullets;

  const setIndicatorColor = () => {
    const carouselID =
      !isServer && document.getElementById(`carousel-${rowData.id}`);

    const swiperScrollbar = getElement(carouselID, ".swiper-scrollbar");
    const swiperScrollbarDrag = getElement(
      carouselID,
      ".swiper-scrollbar-drag"
    );

    if (swiperScrollbar && swiperScrollbarDrag) {
      swiperScrollbar.style.background =
        indicatorStyles.backgroundIndicatorColor;
      swiperScrollbarDrag.style.background = indicatorStyles.indicatorColor;
    }
  };

  const navigation = {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  };

  const autoScrollConfig = getAutoScrollConfig(autoScroll);

  const isInfiniteLoopEnable =
    rowData.itemsPerGroup <= 1 && !isEmpty(autoScrollConfig);
  const swiperParams = {
    ...autoScrollConfig,
    ...params,
    ...getInfiniteLoopConfig(isInfiniteLoopEnable, rowData),
    ...(controllerType &&
      controllerType !== CONTROLLER_TYPES.NONE &&
      !isMobile.any() && { navigation }),
    slidesPerView: rowData.visibleImages,
    ...(rowData.indicatorType &&
      rowData.indicatorType !== BULLET_TYPES.NONE &&
      paginationForSwiper)
  };

  const rowStyles = {
    ...styles,
    maxWidth: width?.value || "100%",
    marginLeft: "auto",
    marginRight: "auto"
  };

  const imagesData = isMobile.any()
    ? imageList.filter(el => el[language]?.enabledOn?.includes("resp"))
    : imageList;

  const reorderedArray =
    rowData.itemsPerGroup > 1
      ? getReorderedArray(imagesData, rowData.itemsPerGroup)
      : imagesData;

  const isNotAvailable = isMobile.any() && !rowData.enabledOn?.includes("resp");

  if (isNotAvailable) {
    return null;
  }

  const imageWidth = 100 / rowData.visibleImages;
  const carouselItems = reorderedArray.map((imageContent, index) => {
    const dataForAnalytic = {
      bannerModuleName: bannerModuleName?.toUpperCase(),
      index: index + 1,
      bannerRowTitle: rowData.title?.toUpperCase(),
      imageUrl: imageContent[language]?.imageURL,
      pageName: bannerPageTitle,
      imageListData: imageContent
    };
    return (
      <div key={index} style={{ maxWidth: `${imageWidth}%` }}>
        <CarouselItem
          imageContent={imageContent}
          language={language}
          imageWidth={imageWidth}
          dataForAnalytic={dataForAnalytic}
          itemIndex={index}
        />
      </div>
    );
  });

  return (
    <div
      style={rowStyles}
      className="dynamic-carousel-wrapper"
      id={`carousel-${rowData.id}`}
      ref={carouselRef}
    >
      <Swiper
        {...swiperParams}
        freeMode
        rtl={language === LANGUAGE.arabic ? "rtl" : ""}
      >
        {carouselItems}
      </Swiper>
    </div>
  );
};

const params = {
  dots: true,
  rebuildOnUpdate: true,
  spaceBetween: 0
};

export default CarouselWrapperRow;
