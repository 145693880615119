import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { selectRouteSlug } from "../../../util/selectors";
import presentBoxImage from "../../../routes/my-account/images/present-box-account-overview.svg";

const LoyaltyGeneralInfo = ({
  onJoinLoyaltyProgram,
  translation,
  errorMessage,
  routeSlug
}) => (
  <div className="loyalty-general-info">
    <div className="main-section">
      <div className="image-block">
        <img src={presentBoxImage} alt="present-box" />
      </div>
      <div className="info-block">
        <h4 className="">{translation.smileMore}</h4>
        <p>{translation.discoverSmile}</p>
      </div>
    </div>

    <button
      onClick={onJoinLoyaltyProgram}
      type="button"
      className="round_btn form_black_btn join_loyalty_btn"
    >
      {translation.joinNow}
    </button>
    <div className="benefits-link">
      <Link
        to={`/${routeSlug}/loyaltyprogram/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        <b>{translation.seeBenefits}</b>
      </Link>
    </div>

    {errorMessage && <span className="error">{errorMessage}</span>}
  </div>
);

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(LoyaltyGeneralInfo);
