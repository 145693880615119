import ApiService from "./ApiService";

import { BEConfig } from "../config/env";

import { webapiPost } from "../webapis/core";

class SocialAuthService extends ApiService {
  checkMobileStatus = async ({ token, phone }) => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.checkMobileStatus
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, phone },
      this.getVisitorId()
    ).request;
    return response.data;
  };

  startRegistration = async ({ token, phone }) => {
    const apiURL = `${this.getBaseUrl()}${BEConfig.customerApi.socialRegister}`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, phone },
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    if (!response.data.success) {
      throw new Error(response.data.message);
    }
    return response.data;
  };

  deactivatePreviousAccounts = async ({ token = "", otp = "" }) => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.socialDeactivatePreviousAccounts
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, otp },
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  completerRegistration = async ({ token = "", otp = "" }) => {
    const apiURL = `${this.getBaseUrl()}${BEConfig.customerApi.socialComplete}`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, otp },
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new SocialAuthService();

export default instance;
