import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import { selectRouteSlug, selectLanguageSlug } from "../../../util/selectors";

import "./style.scss";

import icon from "./icon.svg";

const Ramadan2021 = ({ data }) => {
  const history = useHistory();

  const routeSlug = useSelector(selectRouteSlug);
  const language = useSelector(selectLanguageSlug);

  const [isImageLoading, setImageLoadingStatus] = useState(true);

  useEffect(
    () => {
      setImageLoadingStatus(true);
    },
    [language]
  );

  const handleImageLoaded = useCallback(() => {
    setImageLoadingStatus(false);
  }, []);

  return (
    <div className={cn("ramadan_2021", { arabic: language === "ar" })}>
      <div className="header">
        <div className="icon-wrapper">
          <img src={icon} className="icon" alt="ramadan-icon" />
        </div>

        <div className="description-wrapper">
          <div className="title">{data.title[language]}</div>

          <div className="description">{data.description[language]}</div>
        </div>
      </div>

      <div className={cn("image-list", { invisible: isImageLoading })}>
        {data.items.map(item => (
          <img
            src={item.image[language]}
            onClick={() => history.push(`/${routeSlug}/${item.product_url}`)}
            className="promotion-product-image"
            alt={item.product_url}
            key={item.product_url}
            onLoad={handleImageLoaded}
          />
        ))}
      </div>
    </div>
  );
};

export default Ramadan2021;
