import React, { Component, Fragment } from "react";
import StoreLocatorHeaderWeb from "./store-locator-header-web";
import StoreLocatorHeaderMob from "./store-locator-header-mob";

class StoreLocatorHeader extends Component {
  render() {
    const {
      useCurrentLocation,
      storeLocatorTranslation,
      storeCountry,
      changeSelectedCountry,
      cityList,
      selectedCountry,
      selectedShop,
      changeSelectedShop
    } = this.props;
    return (
      <Fragment>
        <div className="storeLocatorHeaderWrapWeb">
          <StoreLocatorHeaderWeb
            useCurrentLocation={useCurrentLocation}
            storeLocatorTranslation={storeLocatorTranslation}
            storeCountry={storeCountry}
            changeSelectedCountry={changeSelectedCountry}
            cityList={cityList}
            selectedCountry={selectedCountry}
            selectedShop={selectedShop}
            changeSelectedShop={changeSelectedShop}
          />
        </div>
        <div className="storeLocatorHeaderWrapMob">
          <StoreLocatorHeaderMob
            useCurrentLocation={useCurrentLocation}
            storeLocatorTranslation={storeLocatorTranslation}
            storeCountry={storeCountry}
            changeSelectedCountry={changeSelectedCountry}
            cityList={cityList}
            selectedCountry={selectedCountry}
            selectedShop={selectedShop}
            changeSelectedShop={changeSelectedShop}
          />
        </div>
      </Fragment>
    );
  }
}

export default StoreLocatorHeader;
