import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { includesSubString } from "../../../../util";
import {
  getPrefixedQuickFilterPath,
  addAllItemsPrefix,
  checkAnyIncludes,
  getTitle
} from "../../../../util/quickFilter";
import { LANGUAGE_ROUTE_KEY } from "../../../../constants";
import {
  QUICK_FILTER_SHOES,
  FOOTWEAR,
  QUICK_FILTER_ALL_PRODUCTS,
  ITEMS_AMOUNT_FOR_BIG_ICONS,
  KIDS,
  SHOP_ALL,
  STATIC_ICON_MAP,
  CARD_SUBTITLE_MAP,
  QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES
} from "../../../../constants/quickFilter";
import { ARABIC_ALL_OPTION_VARIANTS } from "../../../../constants/quickFilter/ar_quickFilter";
import {
  setQuickFilterStepPath,
  setCategory,
  resetCategories
} from "../../../../redux/actions/quickFilter.action";
import {
  SizeTabs,
  SizeColumn,
  ModalBigCardMobile,
  ModalBigCard,
  ModalMediumCard,
  withLazyImageDiv,
  ModalRoundedCard,
  ShopAllLi,
  QuickFilterModalFooter,
  NoDataMessage
} from "..";
import { get } from "lodash";
import AnalyticService from "../../../../services/analytic-service";

export const QuickFilterModalContent = ({
  joinedPath,
  isMob,
  filterStepPath,
  filterStepData,
  language,
  selectedOption,
  activeTranslation,
  setSelectedOption
}) => {
  const stepRef = useRef(null);
  const dispatch = useDispatch();
  const [activeSizeTab, setActiveSizeTab] = useState(null);
  const quickFilterStore = useSelector(state => state.quickFilterReducer);
  const {
    maxStepBySelectedCategory,
    quickFilterStep,
    groupedAvailableSizes,
    selectedCategories,
    selectedSizes,
    quickFilterProducts
  } = quickFilterStore;

  const onCategoryClickHandler = category => {
    const strArrayToCheck = [
      ...Object.values(SHOP_ALL[language]),
      QUICK_FILTER_ALL_PRODUCTS[language],
      QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES
    ];
    const allItemsAlreadySelected = checkAnyIncludes(
      selectedCategories.join(""),
      strArrayToCheck
    );
    const clickedIsAllItemsOption = checkAnyIncludes(category, strArrayToCheck);
    if (
      clickedIsAllItemsOption ||
      (allItemsAlreadySelected && !clickedIsAllItemsOption)
    ) {
      dispatch(resetCategories());
    }

    dispatch(setCategory(category));
  };

  useEffect(() => {
    if (!isEmpty(groupedAvailableSizes)) {
      setActiveSizeTab(Object.keys(groupedAvailableSizes)[0]);
    }
  }, [groupedAvailableSizes]);

  const onGoFurther = () => {
    const {
      quickFilterData,
      quickFilterStepPath,
      selectedCategories,
      selectedSizes
    } = quickFilterStore;
    AnalyticService.quickFilter.trackQuickFilterClick({
      homePageBannerClick: false,
      quickFilterData,
      quickFilterStepPath,
      selectedCategories,
      selectedSizes
    });
  };

  useEffect(() => {
    if (quickFilterStep && stepRef.current < quickFilterStep) {
      onGoFurther();
    }
    stepRef.current = quickFilterStep;
  }, [quickFilterStep, onGoFurther]);

  const stepWithMultiSelection = maxStepBySelectedCategory - 1;

  const SizesComponent =
    includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
    includesSubString(joinedPath, FOOTWEAR)
      ? SizeTabs
      : SizeColumn;

  const hasHits = Boolean(get(quickFilterProducts, "hits.length"));
  const sizesContent =
    quickFilterStep === maxStepBySelectedCategory &&
    (!isEmpty(groupedAvailableSizes) ? (
      <SizesComponent
        data={groupedAvailableSizes}
        activeSizeTab={activeSizeTab}
        setActiveSizeTab={setActiveSizeTab}
        selectedSizes={selectedSizes}
      />
    ) : !hasHits ? (
      <NoDataMessage
        text={`${activeTranslation.sorry} ${activeTranslation.noSizes}`}
        translation={activeTranslation}
      />
    ) : (
      isEmpty(groupedAvailableSizes) &&
      hasHits && (
        <NoDataMessage
          text={`${activeTranslation.sorry} ${activeTranslation.noData}`}
          translation={activeTranslation}
        />
      )
    ));

  let CardComponent = isMob ? ModalBigCardMobile : ModalBigCard;
  if (quickFilterStep && quickFilterStep < stepWithMultiSelection) {
    CardComponent = ModalMediumCard;
  } else if (quickFilterStep && quickFilterStep >= stepWithMultiSelection) {
    CardComponent = withLazyImageDiv(ModalRoundedCard, filterStepPath);
  }

  let content = sizesContent;
  if (filterStepData && !sizesContent) {
    const dataObj = filterStepData.children;
    if (isEmpty(dataObj)) return null;
    let dataKeysArray = Object.keys(dataObj);
    const titles = dataKeysArray.map(key => getTitle(dataObj[key]));

    if (quickFilterStep && quickFilterStep < stepWithMultiSelection) {
      dataKeysArray = dataKeysArray.sort();
    }
    const titlesArrayIncludesAll = [
      QUICK_FILTER_ALL_PRODUCTS[language],
      ...ARABIC_ALL_OPTION_VARIANTS,
      QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES
    ];
    const doesNotHaveAllItemsKey = !checkAnyIncludes(
      titles.join(""),
      titlesArrayIncludesAll
    );
    const useSmallIcons = dataKeysArray.length > ITEMS_AMOUNT_FOR_BIG_ICONS;
    if (
      quickFilterStep &&
      quickFilterStep === stepWithMultiSelection &&
      !doesNotHaveAllItemsKey
    ) {
      const shopAllIndex = dataKeysArray.findIndex(key =>
        checkAnyIncludes(getTitle(dataObj[key]), titlesArrayIncludesAll)
      );
      if (shopAllIndex >= 0) {
        dataKeysArray = [
          ...dataKeysArray.slice(0, shopAllIndex),
          ...dataKeysArray.slice(shopAllIndex + 1),
          dataKeysArray[shopAllIndex]
        ];
      }
    }

    content = dataKeysArray.map(key => {
      const contentTitle = getTitle(dataObj[key]);
      if (
        quickFilterStep &&
        quickFilterStep === stepWithMultiSelection &&
        checkAnyIncludes(contentTitle, titlesArrayIncludesAll)
      ) {
        const title = includesSubString(joinedPath, KIDS)
          ? addAllItemsPrefix(filterStepData.subTitle, language)
          : getPrefixedQuickFilterPath(filterStepPath, language);
        return (
          <ShopAllLi
            key={title}
            title={title}
            onClick={() => onCategoryClickHandler(title, key)}
            selected={
              selectedCategories.includes(title) || selectedOption === title
            }
            className={classnames({ "small-icon": useSmallIcons })}
          />
        );
      }

      const selected =
        quickFilterStep === stepWithMultiSelection
          ? selectedCategories.includes(contentTitle) ||
            selectedOption === contentTitle
          : selectedCategories.includes(key) || selectedOption === key;
      return (
        <li
          key={key}
          onClick={() => {
            if (quickFilterStep === stepWithMultiSelection) {
              onCategoryClickHandler(contentTitle, key);
            } else {
              (isMob || quickFilterStep) && setSelectedOption(key);
              !isMob &&
                !quickFilterStep &&
                dispatch(setQuickFilterStepPath(key));
            }
          }}
        >
          <CardComponent
            title={contentTitle}
            selected={selected}
            className={classnames({ "small-icon": useSmallIcons })}
            image={
              quickFilterStep < stepWithMultiSelection
                ? STATIC_ICON_MAP[joinedPath + key]
                : dataObj[key].imageUrl
            }
            subTitle={activeTranslation[CARD_SUBTITLE_MAP[key]]}
          />
        </li>
      );
    });

    if (
      quickFilterStep === stepWithMultiSelection &&
      content.length &&
      doesNotHaveAllItemsKey
    ) {
      const title = includesSubString(joinedPath, KIDS)
        ? addAllItemsPrefix(filterStepData.subTitle || "", language)
        : getPrefixedQuickFilterPath(filterStepPath, language);
      const allItemsElement = (
        <ShopAllLi
          key={title}
          title={title}
          onClick={() => onCategoryClickHandler(title)}
          selected={
            selectedCategories.includes(title) || selectedOption === title
          }
          className={classnames({ "small-icon": useSmallIcons })}
        />
      );
      content.push(allItemsElement);
    }
  }

  const scrollbarsParams = {
    style: { width: "100%", height: "100%" }
  };

  const onFooterNextClick = quickFilterStep === maxStepBySelectedCategory && {
    onNextClick: onGoFurther
  };

  return (
    <>
      <div
        className={classnames("row justify-content-center h-100", {
          "content-wrapper": !quickFilterStep
        })}
      >
        {sizesContent ? (
          <div className="col col-lg-9">
            <div
              className={classnames("sizes-content", {
                arabic: language === LANGUAGE_ROUTE_KEY.arabic
              })}
            >
              <Scrollbars {...scrollbarsParams}>{content}</Scrollbars>
            </div>
          </div>
        ) : (
          <div className={classnames("col", { "col-lg-9": quickFilterStep })}>
            <Scrollbars {...scrollbarsParams}>
              <ul
                className={classnames("content-list", {
                  "kids-list":
                    quickFilterStep === 1 && filterStepPath.includes(KIDS),
                  arabic: language === LANGUAGE_ROUTE_KEY.arabic,
                  "wide-screen":
                    !isMob && quickFilterStep < stepWithMultiSelection
                })}
              >
                {content}
              </ul>
            </Scrollbars>
          </div>
        )}
      </div>
      {(!!quickFilterStep || isMob) && (
        <QuickFilterModalFooter
          isMob={isMob}
          activeTranslation={activeTranslation}
          setSelectedOption={setSelectedOption}
          stepWithMultiSelection={stepWithMultiSelection}
          selectedOption={selectedOption}
          language={language}
          {...onFooterNextClick}
          {...quickFilterStore}
        />
      )}
    </>
  );
};
