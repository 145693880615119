import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cn from "classnames";
import ModalBFL from "../../components/modal/modal";
import OrderList from "../../components/send-us-email/send-us-email-orderList";
import { useSelector } from "react-redux";
import { LOADER_TYPES } from "../../constants";
import Loader from "../../components/loader";
import { selectOrderFetchingLoader } from "../../util/selectors";

const loaderStyle = {
  position: "absolute",
  inset: "0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "2",
  height: "72%",
  top: "100%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -100%)"
};

const OrderListModal = ({
  toggleModal,
  showModal,
  sendUsEmailTranslation,
  handleScroll,
  myOrders,
  userInfo,
  language,
  selectedOrderId,
  handleSelectOrder
}) => {
  const showMyOrdersLoader = useSelector(selectOrderFetchingLoader);

  return (
    <ModalBFL
      showModal={showModal}
      modalClass={cn("generic_modal", "orderId_modal", {
        arabic: language === "ar"
      })}
    >
      <button className="cross_btn" onClick={() => toggleModal(false)}>
        &times;
      </button>
      <h2 className="title">{sendUsEmailTranslation.listOfOrder}</h2>
      <ul className="question_list">
        <li className="table_heading">
          <div>
            <span>{sendUsEmailTranslation.orderId} </span>
            <span>{sendUsEmailTranslation.date}</span>
            <span>{sendUsEmailTranslation.Value}</span>
          </div>
        </li>
      </ul>

      {showMyOrdersLoader && (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={loaderStyle}
        />
      )}
      <Scrollbars
        autoHeight
        autoHeightMin={120}
        autoHeightMax={229}
        onScroll={handleScroll}
      >
        <ul className="question_list">
          <OrderList
            order={myOrders}
            isUserLogIn={userInfo.userLoggedIn}
            handleSelectOrder={handleSelectOrder}
            selectedOrderId={selectedOrderId}
            toggleModal={toggleModal}
          />
        </ul>
      </Scrollbars>
    </ModalBFL>
  );
};

export default OrderListModal;
