import React, { useEffect } from "react";
import { POST_PAY_CURRENCY_MULTIPLIER } from "../../../../constants";
import { PostPayService } from "../../../../services";

export const PostPayForm = ({
  locale,
  currency,
  countryShort,
  totalAmount
}) => {
  useEffect(() => {
    initPostPay();
  }, []);

  const initPostPay = async () => {
    await PostPayService.loadSdk();
    PostPayService.init();
  };

  return (
    <div className="post-pay-form">
      <div
        className="postpay-widget"
        data-type="payment-summary"
        data-amount={totalAmount * POST_PAY_CURRENCY_MULTIPLIER}
        data-currency={currency}
        data-country={countryShort}
        data-hide-if-invalid={true}
        data-locale={locale}
      />
    </div>
  );
};
