import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LANGUAGE_ROUTE_KEY } from "../../constants";
import { selectLanguage } from "../../util/selectors";
import FooterNewsletter from "../footer/footer-newsletter";

const stylesForRow = (styles, width) => ({
  ...styles,
  maxWidth: width.value,
  marginLeft: "auto",
  marginRight: "auto"
});

const NewsLetterWrapper = ({ bannerData }) => {
  const [translation, setTranslation] = useState(null);
  const language = useSelector(selectLanguage);
  const shortLang = LANGUAGE_ROUTE_KEY[language];
  const styles = bannerData.styles;

  useEffect(
    () => {
      const loadTranslation = async () => {
        const translation = await import(`../../language/footer/${shortLang}/footer`);
        setTranslation(translation.footerTranslation);
      };
      loadTranslation();
    },
    [shortLang]
  );

  return (
    <>
      {translation && (
        <div style={stylesForRow(styles, bannerData.width)}>
          <FooterNewsletter footerTranslation={translation} isBanner />
        </div>
      )}
    </>
  );
};

export default NewsLetterWrapper;
