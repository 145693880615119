import React, { Component } from "react";
import get from "lodash/get";
import cn from "classnames";
import {
  changeLanguage,
  getCountryList,
  saveBreadcrumb,
  toastMsg
} from "../../redux/actions/common.action";
import {
  changeQuestionList,
  formSubmit,
  getImgHash,
  handleTextChange,
  removeOrderImg,
  selectOrderId,
  selectQuestionList,
  sumbmitMsgModal
} from "../../redux/actions/sendUsEmail.action";

import Breadcrumb from "../../components/breadcrumb";
import QuestionAbout from "../../components/question-about";
import ModalBFL from "../../components/modal/modal";
import QuestionForm from "../../components/send-us-email/send-us-emailForm";
import ThankYou from "../../components/modal/modal-mobile-verification/thankyou";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchMyOrders } from "../../redux/actions/myAccount.action";
import { getQuestionsList } from "../../redux/actions/sendUsEmail.action";
import { sendUsEmailTranslation } from "../../language/sendUsEmail/en/sendUsEmail";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { selectRouteSlug } from "../../util/selectors";
import Page from "../../components/page";
import { seoTranslation } from "../../language/seo/en/seo";
import { LOADER_TYPES } from "../../constants";
import Loader from "../../components/loader";

const loaderStyle = {
  position: "relative",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

class SendUsEmail extends Component {
  state = {
    sendUsEmailTranslation: sendUsEmailTranslation,
    showModal: false,
    isImageExist: "",
    pageBottomScroll: false,
    pageNum: 1,
    isLoading: false,
    isEndOfResult: false,
    orderList: []
  };

  async componentDidMount() {
    const { fetchMyOrders, language, getQuestionsList, userInfo } = this.props;
    if (userInfo?.userLoggedIn) {
      const data = await fetchMyOrders(language);
      if (data?.order) {
        this.setState({
          orderList: [...this.state.orderList, ...data.order]
        });
      }
    }

    getQuestionsList(language);
  }

  UNSAFE_componentWillMount() {
    const { match, language, handleLanguageChange } = this.props;
    const { sendUsEmailTranslation } = this.state;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.getlanguage(language);
    this._setBreadCrumb(sendUsEmailTranslation);
  }

  _setBreadCrumb = translation => {
    const { saveBreadcrumb, routeSlug } = this.props;

    if (saveBreadcrumb) {
      const modelNames = [
        "product",
        "productList",
        "category",
        "productListLvl1",
        "productListLvl2",
        "brands"
      ];

      saveBreadcrumb({
        modelName: "sendUsEmail",
        modelData: {
          sendUsEmailName: translation.sendusEmail,
          sendUsEmailUrl: `/${routeSlug}/send-us-email/`
        }
      });

      modelNames.forEach(modelName => {
        saveBreadcrumb({
          modelName: modelName,
          modelData: {
            [`${modelName}Name`]: "",
            [`${modelName}Url`]: ""
          }
        });
      });
    }
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fetchMyOrders,
      getQuestionsList,
      sendUsEmail,
      countryData,
      userInfo,
      language
    } = this.props;
    const { pageNum, orderList } = this.state;
    if (language !== nextProps.language) {
      this.getlanguage(nextProps.language);
    }
    if (nextProps.userInfo.userLoggedIn !== userInfo.userLoggedIn) {
      const data = await fetchMyOrders(language, pageNum, 0, 5, false, true);
      this.setState({
        orderList: [...orderList, ...data.order]
      });
    }
    if (nextProps.sendUsEmail.isFormSubmit !== sendUsEmail.isFormSubmit) {
      getQuestionsList(nextProps.language);
      const data = await fetchMyOrders(
        nextProps.language,
        pageNum,
        0,
        5,
        false,
        countryData && countryData.toLowerCase,
        true
      );
      this.setState({
        orderList: [...orderList, ...data.order]
      });
    }
  }

  getlanguage = language => {
    const sendUsEmail = import(
      `../../language/sendUsEmail/${language}/sendUsEmail`
    );
    sendUsEmail.then(response => {
      this.setState({
        sendUsEmailTranslation: response.sendUsEmailTranslation
      });
      this._setBreadCrumb(response.sendUsEmailTranslation);
    });
  };

  handleSelectList = (id, groupName, innerText, e) => {
    e && e.preventDefault();
    this.props.selectQuestionList(id, groupName, innerText);
  };

  handleChange = groupName => {
    this.props.changeQuestionList(groupName);
  };

  submitMsgModal = value => this.props.submitMsgModal(value);

  handleTextChange = (name, value) => {
    const {
      getImgHash,
      handleTextChange,
      sendUsEmail: {
        ticket: {
          userForm: { uploadFiles }
        }
      }
    } = this.props;
    if (name === "uploadFiles") {
      const imageExist =
        !!uploadFiles.length &&
        uploadFiles.filter(item => item.filename === value[0].name);
      const isImageExist =
        !!imageExist.length && imageExist[0].filename === value[0].name;

      this.setState({ isImageExist });
      const valueKeys = Object.keys(value);
      !!valueKeys &&
        !!valueKeys.length &&
        valueKeys.map(key => {
          const _formData = new FormData();
          if (value[key].type.split("/")[0] !== "image") {
            this.props.toastMsg("error", sendUsEmailTranslation.fileFormat);
          } else if (value[key].type.split("/")[1].indexOf("tif") !== -1) {
            this.props.toastMsg("error", sendUsEmailTranslation.fileFormat); // file type error
          } else {
            _formData.append("file", value[key]);
            !isImageExist && getImgHash(_formData, name, value[key]);
          }
          return null;
        });
    } else {
      handleTextChange(name, value);
    }
  };

  handleRemoveImg = selectImg => {
    !!selectImg && this.props.removeOrderImg(selectImg);
    this.setState({ isImageExist: "" });
  };

  handleSelectOrder = orderId => this.props.selectOrderId(orderId);

  handleSubmit = ({
    orderId,
    userName,
    email,
    phone,
    userMessage,
    areaCode
  }) => {
    const {
      sendUsEmail: { ticket },
      formSubmit,
      customerId,
      defaultAreaCode
    } = this.props;
    const areaMobileCode =
      areaCode || get(defaultAreaCode.split(","), "[0]", "");

    const updatedTicket = {
      ...ticket,
      userForm: {
        ...ticket.userForm,
        orderId,
        name: userName,
        email,
        phone,
        message: userMessage,
        areaCode: areaMobileCode
      }
    };

    formSubmit(updatedTicket, customerId);
  };

  listKeys = () => {
    const {
      sendUsEmail: { questionListId, content }
    } = this.props;
    const listkeys =
      !!content &&
      Object.keys(content).filter(
        item => item !== "_id" && content[item]?.id === Number(questionListId)
      );
    return listkeys;
  };

  questionList = () => {
    const {
      sendUsEmail: { questionListId, content }
    } = this.props;

    const listKeys = this.listKeys();
    if (content) {
      const questionList = Boolean(questionListId)
        ? listKeys.map(item => content[`${item}`])
        : content;

      return questionList;
    }
  };

  descriptionList = () => {
    const {
      sendUsEmail: { questionListId, content, reasonListId }
    } = this.props;

    const listKeys = this.listKeys();
    if (content) {
      const descriptionListKeys = Object.keys(
        content[`${listKeys[0]}`].ContactCategory
      );

      const filteredDescriptionKeys = descriptionListKeys.filter(
        item =>
          item !== "_id" &&
          +content[listKeys[0]].ContactCategory[item].id === +reasonListId
      );

      const descriptionList =
        Boolean(questionListId) && !reasonListId
          ? listKeys.map(item => content[`${item}`].ContactCategory)
          : content[`${listKeys[0]}`].ContactCategory[
              `${filteredDescriptionKeys}`
            ];

      return Array.isArray(descriptionList)
        ? { ...descriptionList[0] }
        : { 0: { ...descriptionList } };
    }
  };

  handleScroll = e => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      this.handleOrdersScroll();
    }
  };

  handleOrdersScroll = () => {
    const { language, fetchMyOrders, countryData } = this.props;
    const { pageNum, isLoading, isEndOfResult, orderList } = this.state;
    if (!isLoading && !isEndOfResult) {
      this.setState(
        {
          pageBottomScroll: true,
          pageNum: pageNum + 1,
          isLoading: true
        },
        () =>
          fetchMyOrders(
            language,
            this.state.pageNum,
            0,
            5,
            true,
            countryData && countryData.toLowerCase,
            true
          ).then(data => {
            this.setState({
              orderList: [...orderList, ...data.order],
              isLoading: false,
              isEndOfResult: !get(data, "order.length")
            });
          })
      );
    } else {
      this.setState({ pageBottomScroll: false, isLoading: false });
    }
  };

  render() {
    const { sendUsEmailTranslation, isImageExist, orderList } = this.state;

    const {
      language,
      userInfo,
      sendUsEmail: {
        questionListId,
        reasonListId,
        ticket,
        errors,
        selectedOrderId,
        isFormSubmit
      },
      countryData,
      defaultCountry,
      mobileLocalNumberLength,
      getCountryList,
      showLoader
    } = this.props;

    const listOfQues = this.questionList();
    const listOfdiscription = !!questionListId && this.descriptionList();
    return (
      <Page
        title={seoTranslation.sendUnEmailTitle}
        meta={{
          [language]: {
            metaDescription:
              seoTranslation[language].sendUnEmailDescription ||
              seoTranslation.defaultPageDescription
          }
        }}
      >
        <div>
          <Breadcrumb />
          <div className="container">
            <div
              className={cn("send_us_email_wrapper", {
                arabic: language.includes("ar")
              })}
            >
              <div className="head_with_desc">
                <h3>{sendUsEmailTranslation.sendUsEmailTitle}</h3>
                <p className="heading_desc">
                  {sendUsEmailTranslation.getBackTitleSmall}
                </p>
              </div>
              <div className="ask_question">
                <h3>{sendUsEmailTranslation.questionTitle}</h3>

                {showLoader ? (
                  <Loader
                    type={LOADER_TYPES.RING}
                    width={25}
                    height={25}
                    visible
                    style={loaderStyle}
                  />
                ) : (
                  <QuestionAbout
                    list={listOfQues}
                    listId={questionListId}
                    groupName="question"
                    handleSelectList={this.handleSelectList}
                    handleChange={this.handleChange}
                    language={language}
                    translation={sendUsEmailTranslation}
                  />
                )}

                {Boolean(questionListId) && (
                  <>
                    <h3 className="margin">
                      {sendUsEmailTranslation.moreSpecifically}
                    </h3>
                    <QuestionAbout
                      list={listOfdiscription}
                      listId={reasonListId}
                      groupName="reason"
                      handleSelectList={this.handleSelectList}
                      handleChange={this.handleChange}
                      language={language}
                      translation={sendUsEmailTranslation}
                    />
                  </>
                )}

                {Boolean(reasonListId) && (
                  <QuestionForm
                    translation={sendUsEmailTranslation}
                    userForm={ticket.userForm}
                    handleRemoveImg={this.handleRemoveImg}
                    userInfo={userInfo}
                    handleSubmit={this.handleSubmit}
                    countryData={countryData}
                    defaultCountry={defaultCountry}
                    isImageExist={isImageExist}
                    mobileLocalNumberLength={mobileLocalNumberLength}
                    handleScroll={this.handleScroll}
                    myOrders={orderList}
                    language={language}
                    selectedOrderId={selectedOrderId}
                    handleSelectOrder={this.handleSelectOrder}
                    fieldErrors={errors}
                    handleTextChange={this.handleTextChange}
                    getCountryList={getCountryList}
                  />
                )}

                {isFormSubmit && (
                  <ModalBFL
                    showModal={isFormSubmit}
                    modalClass={cn(
                      "generic_modal",
                      "orderId_modal",
                      "thankYouModalWrapper",
                      {
                        arabic: language.includes("ar")
                      }
                    )}
                  >
                    <button
                      className="cross_btn"
                      onClick={() => this.submitMsgModal(!isFormSubmit)}
                    >
                      &times;
                    </button>
                    <ThankYou
                      orderSuccessTranslation={sendUsEmailTranslation}
                    />
                  </ModalBFL>
                )}
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  userInfo: state.authReducer,
  routeSlug: selectRouteSlug(state),
  sendUsEmail: state.sendUsEmailReducer,
  countryData: state.common.countryData,
  defaultCountry: state.common.settings.countrySHORT,
  mobileLocalNumberLength:
    state.common.settings && state.common.settings.mobileLocalNumberLength,
  customerId: state.authReducer.customerId,
  defaultAreaCode: get(state, "common.settings.mobileLocalCode"),
  showLoader: state.common.showLoader
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  fetchMyOrders: bindActionCreators(fetchMyOrders, dispatch),
  selectQuestionList: bindActionCreators(selectQuestionList, dispatch),
  changeQuestionList: bindActionCreators(changeQuestionList, dispatch),
  handleTextChange: bindActionCreators(handleTextChange, dispatch),
  selectOrderId: bindActionCreators(selectOrderId, dispatch),
  removeOrderImg: bindActionCreators(removeOrderImg, dispatch),
  formSubmit: bindActionCreators(formSubmit, dispatch),
  getQuestionsList: bindActionCreators(getQuestionsList, dispatch),
  getImgHash: bindActionCreators(getImgHash, dispatch),
  submitMsgModal: bindActionCreators(sumbmitMsgModal, dispatch),
  toastMsg: bindActionCreators(toastMsg, dispatch),
  saveBreadcrumb: bindActionCreators(saveBreadcrumb, dispatch),
  getCountryList: bindActionCreators(getCountryList, dispatch)
});

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(SendUsEmail);
