import React from "react";
import cn from "classnames";
import ProductSize from "../product/product-size";
import SizeFormatMessage from "./size-format-message";

const ProductSizeDetails = ({
  isProductAvailableInCountry,
  toggleViewMore,
  addSpaceClass,
  sizeSelection,
  selectedSize,
  viewMoreHeight,
  colorSizeOptions,
  onSizeChange,
  selectedSizeLabelInternational,
  language,
  handleSizeSelection,
  showViewMore,
  wrapperRef,
  toggleViewMoreDetails,
  translation,
  sizeIndication
}) => {
  return (
    <>
      {isProductAvailableInCountry ? (
        <div className="view_product_quality_outer">
          <div
            className={cn("view_product_quality", "view_more_sizes", {
              expand: !toggleViewMore,
              view_more_spacing: addSpaceClass,
              size_selection_error: sizeSelection && !selectedSize
            })}
            style={
              !toggleViewMore
                ? {
                    maxHeight: viewMoreHeight + 50,
                    minHeight: viewMoreHeight + 30
                  }
                : {}
            }
          >
            <ProductSize
              colorSizeOption={colorSizeOptions}
              onSizeChange={onSizeChange}
              selectedSize={selectedSizeLabelInternational}
              language={language}
              showStock
              translation={translation}
              handleSizeSelection={handleSizeSelection}
              wrapperRef={wrapperRef}
            />
            {showViewMore && (
              <p className="view_more">
                {toggleViewMore ? (
                  <span onClick={toggleViewMoreDetails}>
                    {translation.ShowAllSizes}
                  </span>
                ) : (
                  <span onClick={toggleViewMoreDetails}>
                    {translation.ShowLessSizes}
                  </span>
                )}{" "}
              </p>
            )}
          </div>

          {sizeIndication?.isSizeShown && (
            <div className="hidden_on_mobile">
              <SizeFormatMessage
                translation={translation}
                sizeIndication={sizeIndication}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="item_info">
          <span className="stock_limit not_available">
            {translation.notAvailableIn}
          </span>
        </div>
      )}
    </>
  );
};

export default ProductSizeDetails;
