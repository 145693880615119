export const getDividedNumber = ({
  phone = "",
  mobileCountryCode = "",
  mobileLocalCode = "",
  mobileLocalNumberLength
}) => {
  const arrayOfAreaCodes = mobileLocalCode.split(",");
  const countryCodeLength = mobileCountryCode.toString().length;
  const isNumberWithCountryCode =
    phone.startsWith(mobileCountryCode) &&
    phone.length > mobileLocalNumberLength;
  const phoneWithoutCountryCode = isNumberWithCountryCode
    ? phone.slice(countryCodeLength)
    : phone;
  const areaCode =
    arrayOfAreaCodes.find(code => phoneWithoutCountryCode.startsWith(code)) ||
    arrayOfAreaCodes[0] ||
    "";

  return { phoneWithoutCountryCode, areaCode };
};
