import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  handleFacetChange,
  getProductsData,
  deleteFacet
} from "../../../redux/actions/productlisting.action";
import Category from "./category";
import Swiper from "react-id-swiper";

const hierarchicalCategories = [
  "hierarchicalCategories.lvl0",
  "hierarchicalCategories.lvl1",
  "hierarchicalCategories.lvl2"
];

const params = {
  dots: false,
  slidesPerView: 4,
  slidesToScroll: 1,
  rebuildOnUpdate: true,
  spaceBetween: 14,
  grabCursor: true,
  freeMode: true,
  breakpoints: {
    800: {
      slidesPerView: "auto"
    },
    600: {
      slidesPerView: "auto"
    },
    320: {
      slidesPerView: "auto",
      spaceBetween: 5
    }
  }
};

class CategoryHandle extends Component {
  handleItemChecked = (e, hierarchicalCategories, catName, state) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      handleFacetChange,
      getProductsData,
      history,
      language,
      deleteFacet,
      handleSwitchFilter,
      handleFilterToggle
    } = this.props;

    !!handleSwitchFilter && handleSwitchFilter(true);
    !!handleFilterToggle && handleFilterToggle(hierarchicalCategories, e);

    if (hierarchicalCategories.indexOf(hierarchicalCategories) !== -1) {
      if (hierarchicalCategories === hierarchicalCategories[1]) {
        deleteFacet(hierarchicalCategories[2]);
      } else {
        deleteFacet(hierarchicalCategories[1]);
      }
    }
    handleFacetChange(
      hierarchicalCategories,
      catName,
      state || e.target.checked
    );
    getProductsData(hierarchicalCategories, history, null, null, language);
  };

  renderCategoryItem = (catName, key) => (
    <span
      key={key}
      onClick={e => {
        this.props.handleMobileFilter(false);
        this.handleItemChecked(e, hierarchicalCategories[0], catName, true);
      }}
      aria-label={hierarchicalCategories[0]}
    >
      {catName}
    </span>
  );

  render() {
    const { products, handleMobileFilter } = this.props;

    const categoryItems = Object.keys(
      products.facets[hierarchicalCategories[0]] || {}
    ).map(this.renderCategoryItem);

    return (
      <div className="chooseCategory_wrapper">
        <div className="chooseCategory">
          <div className="category_list web">
            {!!products.facets[hierarchicalCategories[0]] &&
              Object.keys(products.facets[hierarchicalCategories[0]]).map(
                (catName, index) => {
                  return (
                    <Category
                      key={index}
                      handleItemChecked={this.handleItemChecked}
                      handleMobileFilter={handleMobileFilter}
                      hierarchicalCategories={hierarchicalCategories[0]}
                      catName={catName}
                    />
                  );
                }
              )}
          </div>
          <div className="category_list mobile">
            <Swiper {...params} shouldSwiperUpdate>
              {categoryItems}
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  handleFacetChange: bindActionCreators(handleFacetChange, dispatch),
  getProductsData: bindActionCreators(getProductsData, dispatch),
  deleteFacet: bindActionCreators(deleteFacet, dispatch)
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(CategoryHandle)
);
