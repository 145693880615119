import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { selectLanguageSlug } from "../../util/selectors";

import { CommonService } from "../../services";

import Ramadan2021 from "./Ramadan2021";

const Promotions = () => {
  const [promotionsData, setPromotionsData] = useState([]);

  const language = useSelector(selectLanguageSlug);
  const countryId = useSelector(state =>
    get(state, "common.settings.countryId")
  );

  useEffect(
    () => {
      if (!countryId) return;

      const loadPromotions = async () => {
        try {
          const promotions = await CommonService.getAllPromotions({
            language,
            countryId
          });

          setPromotionsData(promotions);
        } catch {}
      };

      loadPromotions();
    },
    [language, countryId]
  );

  return (
    <Fragment>
      {promotionsData.map(promotion => {
        switch (promotion.name) {
          case "ramadan_2021": {
            return <Ramadan2021 data={promotion} key={promotion.name} />;
          }

          default: {
            return null;
          }
        }
      })}
    </Fragment>
  );
};

export default Promotions;
