import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import cn from "classnames";
import { Link } from "react-router-dom";

import { getProductUrlName } from "../../../util";
import { getInternationalSizes } from "../../../util/cart";
import { isPriceAvailableByUserIpcountry } from "../../../util/priceUtil";

import ContentDescription from "../../content/content-description";
import ProductSize from "../../product/product-size";
import CustomDropdown from "../../genericDropDown";
import GoldLabel from "../../gold-label";

const QuickLookModalContent = props => {
  const {
    product,
    onQtyTextChange,
    showQtyTextBox,
    selectedQty,
    onSizeChange,
    selectedSize,
    translation,
    saveToWishList,
    deleteFromWishList,
    wishListBaseData,
    cart,
    onQtyChange,
    language,
    errShowQtyTextBox,
    handleAddToCart,
    moveToProductDetail,
    currencyCode,
    selectedInternational,
    setInternationalSelected,
    sizeSelection,
    handleSizeSelection,
    commonSettings,
    toggleViewMore,
    showViewMore,
    viewMoreHeight,
    toggleViewButton,
    refCallback,
    handleAddToCartText,
    showAddedToCardText,
    _addAnimate,
    wishlistAnimationClass,
    onBlurChange,
    handleQuantityAddition,
    maxQuantityThatCanBeAdded,
    maxQuantityAddedMessage,
    quantityOverflowMessage,
    handleSelectSizeFirstQL,
    qtySelectSizeQL,
    cartQuantity
  } = props;
  const isPresent = item =>
    item &&
    wishListBaseData &&
    wishListBaseData.length &&
    wishListBaseData.some(
      wishItem =>
        wishItem.productId === item.id && wishItem.size === selectedSize
    );
  const isPresentCart = item =>
    item &&
    cart &&
    cart.length &&
    cart.some(cartItem => {
      return (
        cartItem.productId === item.id &&
        cartItem.size === selectedSize &&
        (cartItem.quantity || 1) === selectedQty
      );
    });
  const getQtyList = TEMP_QTY =>
    [...new Array(TEMP_QTY).keys()].map(x => x + 1);
  let availableSizes =
    product &&
    product.colors &&
    product.colors.colorSizeOptions &&
    product.colors.colorSizeOptions.filter(option => option.sizeLabel);
  let internationalSizes = {};
  product &&
    product.IsMultisize &&
    getInternationalSizes(availableSizes, internationalSizes);
  const internationalSizesKeys = Object.keys(internationalSizes).sort();
  if (internationalSizesKeys.length) {
    availableSizes =
      internationalSizes[selectedInternational] ||
      internationalSizes[internationalSizesKeys[0]];
  }
  let notInStock =
    availableSizes &&
    availableSizes.length &&
    availableSizes.filter(option => option.sizeStock);

  const selectedSizeObject = selectedSize
    ? availableSizes &&
      availableSizes.length &&
      availableSizes.find(
        option => option.sizeLabel.trim() === selectedSize.trim()
      )
    : null;
  const allowedQuantity = selectedSizeObject
    ? selectedSizeObject.sizeStock
    : availableSizes && availableSizes.length
    ? null
    : product.pstock || 0;
  const firstSizeType =
    internationalSizesKeys.length && internationalSizesKeys[0];
  const styleObj = {
    maxHeight: viewMoreHeight + 50
  };

  useEffect(() => {
    if (!selectedInternational && internationalSizesKeys[0]) {
      setInternationalSelected(internationalSizesKeys[0]);
    }
  }, []);

  const handleToggleQuickLook = showQuicklookModal => {
    props.updateQuickLookModalToggleQuickLook(showQuicklookModal);
  };

  const onAddItem = () => {
    if (
      !isPresentCart(product) &&
      !errShowQtyTextBox &&
      allowedQuantity &&
      (availableSizes && availableSizes.length ? selectedSize : true)
    ) {
      const validationResult = handleQuantityAddition(
        product,
        cart,
        selectedQty,
        availableSizes,
        selectedSize,
        allowedQuantity,
        internationalSizes
      );
      if (validationResult.isOverflow) {
        return;
      }
      handleAddToCartText();
      handleToggleQuickLook();
      handleAddToCart(
        product,
        (internationalSizes[selectedInternational || firstSizeType] &&
          internationalSizes[selectedInternational || firstSizeType].find(
            size => size.sizeLabel === selectedSize
          )) ||
          selectedSize,
        selectedQty,
        selectedInternational || firstSizeType
      );
    } else if (isPresentCart(product) && !errShowQtyTextBox) {
      const validationResult = handleQuantityAddition(
        product,
        cart,
        selectedQty,
        availableSizes,
        selectedSize,
        allowedQuantity,
        internationalSizes
      );
      if (validationResult.isOverflow) {
        return;
      }
      handleSizeSelection(false);
      handleAddToCart(
        product,
        (internationalSizes[selectedInternational || firstSizeType] &&
          internationalSizes[selectedInternational || firstSizeType].find(
            size => size.sizeLabel === selectedSize
          )) ||
          selectedSize,
        selectedQty,
        selectedInternational || firstSizeType
      );
    } else if (!errShowQtyTextBox) {
      handleSizeSelection(true);
    }
  };

  const toggleSaveToWishList = e => {
    e.preventDefault();

    if (!isPresent(product)) {
      saveToWishList(product, null, null, null, null, selectedSize);
    } else {
      deleteFromWishList(product.id, null, selectedSize);
    }

    _addAnimate(
      product,
      true,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      true
    );
  };

  const isPriceAvailableByIpcountry = isPriceAvailableByUserIpcountry(product);

  return (
    <div className="quicklook_content">
      <ContentDescription
        item={product}
        translation={translation}
        language={language}
        currencyCode={currencyCode}
        commonSettings={commonSettings}
        showVat
        Redirection={true}
        getProductUrlName={getProductUrlName}
        isProductAvailableInCountry={isPriceAvailableByIpcountry}
        showRetailPrice
      />
      <GoldLabel item={product} language={language} placement="pdp" />
      <div
        className={`size_container ${
          sizeSelection ? "size_container_error" : ""
        }`}
      >
        <Scrollbars autoHeight autoHeightMax={190}>
          <div
            className={`view_product_quality view_more_sizes ${
              !toggleViewMore ? "expand" : ""
            }`}
            style={!toggleViewMore ? styleObj : {}}
            ref={e => refCallback(e)}
          >
            {isPriceAvailableByIpcountry && (
              <ProductSize
                colorSizeOption={availableSizes}
                onSizeChange={onSizeChange}
                selectedSize={selectedSize}
                language={language}
                showStock
                translation={translation}
              />
            )}

            {showViewMore && (
              <p className="view_more">
                {toggleViewMore ? (
                  <span onClick={() => toggleViewButton()}>
                    {translation.ShowAllSizes}
                  </span>
                ) : (
                  <span onClick={() => toggleViewButton()}>
                    {translation.ShowLessSizes}
                  </span>
                )}{" "}
              </p>
            )}
          </div>
        </Scrollbars>
      </div>

      {product &&
      product.IsMultisize &&
      internationalSizes &&
      internationalSizesKeys.length ? (
        <div className="item_extra_info">
          <CustomDropdown
            name={"location"}
            data={internationalSizesKeys}
            selectedItem={selectedInternational || internationalSizesKeys[0]}
            containerClass={"location_dropdown"}
            scrollbarMaxHeight={150}
            handleChange={setInternationalSelected}
          />
        </div>
      ) : null}

      {sizeSelection &&
      !!notInStock &&
      !!notInStock.length &&
      !qtySelectSizeQL ? (
        <p className="size_selection_message">
          {translation && translation.sizeSelectionToProceed}
        </p>
      ) : null}
      {qtySelectSizeQL &&
      selectedSize === "" &&
      availableSizes &&
      availableSizes.length &&
      !!notInStock &&
      !!notInStock.length ? (
        <p className="size_selection_message">
          {translation.sizeSelectionToProceed}
        </p>
      ) : null}

      <div className="action_container">
        {isPriceAvailableByIpcountry ? (
          <>
            <p className="quantity">
              {!!availableSizes && !!availableSizes.length && (
                <em
                  className={`${
                    selectedSize !== "" ? "displayNone" : "selectSizeFirst"
                  }`}
                  onClick={() => handleSelectSizeFirstQL(availableSizes)}
                />
              )}
              <span className="qty_text">
                {translation && translation.quantity}
              </span>
              {!showQtyTextBox ? (
                <CustomDropdown
                  data={getQtyList(allowedQuantity)}
                  selectedItem={
                    getQtyList(allowedQuantity).length ? selectedQty : 0
                  }
                  handleChange={quantity =>
                    onQtyChange(quantity, allowedQuantity)
                  }
                  defaultSelectValue={
                    getQtyList(allowedQuantity).length ? selectedQty : 0
                  }
                  containerClass={"quantity_dropdown"}
                  scrollbarMaxHeight={150}
                />
              ) : (
                <input
                  type="text"
                  value={selectedQty}
                  className={`dropdown-custom-text ${
                    errShowQtyTextBox && "borderRed"
                  }`}
                  onChange={e =>
                    onQtyTextChange(e.target.value, allowedQuantity)
                  }
                  onBlur={() => onBlurChange()}
                  aria-label="Quantity"
                />
              )}
            </p>
            <button
              className={`add_to_btn ${
                errShowQtyTextBox ||
                !selectedQty ||
                !allowedQuantity ||
                (availableSizes && availableSizes.length && !selectedSize)
                  ? ""
                  : ""
              } ${
                isPresentCart(product) && showAddedToCardText
                  ? "added_to_cart"
                  : ""
              }  `}
              onClick={onAddItem}
            >
              {!showAddedToCardText
                ? translation.addToCart
                : translation.addedToCart}
            </button>
            <span
              className={cn("wishlist-icon", wishlistAnimationClass, {
                wishlistIcon: !wishlistAnimationClass && isPresent(product),
                wishlistedIcon: !wishlistAnimationClass && !isPresent(product)
              })}
              onClick={e => {
                if (!wishlistAnimationClass) {
                  toggleSaveToWishList(e);
                }
              }}
            />
            {errShowQtyTextBox && (
              <span className="error">{errShowQtyTextBox}</span>
            )}
            {quantityOverflowMessage && (
              <span className="error">
                {translation.alreadyAdded} {cartQuantity}{" "}
                {translation.qtyItemInCart}.{translation.theAvailableStock}{" "}
                {allowedQuantity}. {translation.youCanAddMaxOf}
                {maxQuantityThatCanBeAdded} {translation.moreQty}
              </span>
            )}

            {maxQuantityAddedMessage && (
              <span className="error">
                {translation.alreadyAdded} {allowedQuantity}{" "}
                {translation.qtyItemInCart}.{translation.theAvailableStock}{" "}
                {allowedQuantity}.
              </span>
            )}
          </>
        ) : (
          <span className="error">{translation.notAvailableIn}</span>
        )}
      </div>
      <Link
        className="link_to_pdp"
        to={`/${language}-${
          (commonSettings &&
            commonSettings.countrySHORT &&
            commonSettings.countrySHORT.toLowerCase()) ||
          "ae"
        }/${getProductUrlName(props.product.en_title || props.product.title)}/${
          props.product.id
        }/p/`}
        onClick={e => moveToProductDetail(product, e)}
      >
        {translation && translation.linkToProductDetail}
      </Link>
    </div>
  );
};

export default QuickLookModalContent;
