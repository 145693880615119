import {
  HANDLE_FACET_CHANGE,
  SET_PRODUCTS,
  HANDLE_GRID_CHANGE,
  HANDLE_PAGE_CHANGE,
  HANDLE_RANGE_CHANGE,
  RESET_RANGE_CHANGE,
  SET_PAGE_ROUTE,
  TOGGLE_QUICKLOOK_VIEW,
  TOGGLE_APP_ONLY_ITEM_MODAL,
  DELETE_FACET,
  HANDLE_SORT_CHANGE,
  RESET_PRODUCT_LIST,
  HANDLE_ASSISTIVE_FILTER_CLICK,
  SET_INITIAL_STATE,
  HANDLE_GENERATED_URL,
  HANDLE_URL_CHANGE,
  SET_BRAND_PAGE_URLS,
  SET_MOBILE_CURRENT_FILTER_SELECTION
} from "../constants";

import { StorageService } from "../../services";

import {
  productListingSeed,
  emptyProductSeed
} from "../initialSeed/productListingSeed";
import { getValidFacets } from "../../util/itemsFilter";

const initialState = {
  isProductListingPage: false,
  products: {
    ...productListingSeed
  },
  gridValue: StorageService.getGridSize(),
  showQuicklookModal: false,
  assistiveFilterClick: false,
  appOnlyItemModal: {
    showAppOnlyItemModal: false,
    product: null
  },
  mobileFilter: {
    currentFilterSelected: null
  },
  sysGeneratedUrl: []
};

const setMobilecurrentFilterSelected = (state, { payload }) => ({
  ...state,
  mobileFilter: {
    currentFilterSelected: payload
  }
});

const setPageRoute = state => ({
  ...state,
  isProductListingPage: !state.isProductListingPage
});

const handlePageChange = (state, { page }) => ({
  ...state,
  products: {
    ...state.products,
    page
  }
});

const handleRangeChange = (state, { range }) => ({
  ...state,
  products: {
    ...state.products,
    range: {
      ...range
    },
    rangeChanged: true
  }
});

const resetRangeChanged = state => ({
  ...state,
  products: {
    ...state.products,
    rangeChanged: false
  }
});

const handleFacetChange = (state, { facet, subFacet, checked }) => {
  let newState = null;
  if (state.products.facets && state.products.facets[facet]) {
    newState = {
      ...state,
      products: {
        ...state.products,
        facets: {
          ...state.products.facets,
          [facet]: {
            ...state.products.facets[facet],
            [subFacet]: {
              ...state.products.facets[facet][subFacet],
              checked: checked
            }
          }
        }
      }
    };
  } else {
    let updatedFacets = Object.assign({}, state.products.facets);
    updatedFacets[facet] = {
      [subFacet]: {
        checked: checked
      }
    };
    newState = {
      ...state,
      products: {
        ...state.products,
        facets: {
          ...updatedFacets
        }
      }
    };
  }
  return newState;
};

const handleSortChange = (state, { sort }) => ({
  ...state,
  products: {
    ...state.products,
    sort
  }
});

const handleURLChange = (state, { url }) => ({
  ...state,
  products: {
    ...state.products,
    url
  }
});

const setProducts = (state, { content, facetName, updateRangeFlag }) => {
  const { facets } = state.products;
  const updatedFacets = content.facets;
  const specificFacet = facetName && {
    [facetName]: {
      ...facets[facetName],
      ...updatedFacets[facetName]
    }
  };

  let allFacets = { ...updatedFacets, ...specificFacet };
  if (allFacets["sizeHierarchicalFilter.lvl2"]) {
    const filteredFacets = getValidFacets(
      allFacets["sizeHierarchicalFilter.lvl2"]
    );
    allFacets = {
      ...allFacets,
      "sizeHierarchicalFilter.lvl2": filteredFacets
    };
  }

  return {
    ...state,
    en_title: content.en_title || state.en_title,
    ar_title: content.ar_title || state.ar_title,
    products: {
      ...content,
      url: content.url || (state.products && state.products.url) || "",
      facets: {
        ...allFacets
      },
      ...(!updateRangeFlag && { rangeChanged: state.products.rangeChanged })
    }
  };
};

const handleGridChange = (state, { gridValue }) => ({ ...state, gridValue });

const toggleQuicklookView = (state, { showQuicklookModal }) => ({
  ...state,
  showQuicklookModal
});
const toggleAppOnlyItemModal = (state, { showAppOnlyItemModal, product }) => ({
  ...state,
  appOnlyItemModal: {
    showAppOnlyItemModal: showAppOnlyItemModal,
    product
  }
});

const handleAssistiveFilterClick = (state, { val }) => ({
  ...state,
  assistiveFilterClick: val
});

const deleteFacet = (state, { facetName }) => {
  if (state.products && state.products.facets) {
    state.products.facets[facetName] = emptyProductSeed;
  }
  return {
    ...state
  };
};

const resetProductList = state => ({
  ...state,
  products: {
    ...state.products,
    hits: productListingSeed.hits
  }
});

const setInitialState = state => ({
  ...initialState,
  sysGeneratedUrl: state.sysGeneratedUrl
});

const handleGeneratedUrls = (state, { data }) => ({
  ...state,
  sysGeneratedUrl: data
});

const setBrandPageUrls = (state, payload) => {
  let newState = { ...state };
  if (payload.parentCategory) {
    newState = {
      ...newState,
      brandPageDetails: {
        ...newState.brandPageDetails,
        parentCategory: payload.parentCategory.pcatname,
        seoParentCategory: payload.parentCategory.pcatname_seo,
        en_parentCategory: payload.parentCategory.en_pcatname,
        ar_parentCategory: payload.parentCategory.ar_pcatname
      }
    };
  }
  if (payload.brand) {
    newState = {
      ...newState,
      brandPageDetails: {
        ...newState.brandPageDetails,
        brandName: payload.brand.brandname,
        seoBrandName: payload.brand.brandname_seo,
        en_brandName: payload.brand.en_brandname,
        ar_brandName: payload.brand.ar_brandname
      }
    };
  }
  return newState;
};

const productListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_CURRENT_FILTER_SELECTION:
      return setMobilecurrentFilterSelected(state, action);
    case SET_PRODUCTS:
      return setProducts(state, action);
    case HANDLE_FACET_CHANGE:
      return handleFacetChange(state, action);
    case HANDLE_GRID_CHANGE:
      return handleGridChange(state, action);
    case HANDLE_PAGE_CHANGE:
      return handlePageChange(state, action);
    case TOGGLE_QUICKLOOK_VIEW:
      return toggleQuicklookView(state, action);
    case TOGGLE_APP_ONLY_ITEM_MODAL:
      return toggleAppOnlyItemModal(state, action);
    case HANDLE_RANGE_CHANGE:
      return handleRangeChange(state, action);
    case RESET_RANGE_CHANGE:
      return resetRangeChanged(state);
    case SET_PAGE_ROUTE:
      return setPageRoute(state);
    case DELETE_FACET:
      return deleteFacet(state, action);
    case HANDLE_SORT_CHANGE:
      return handleSortChange(state, action);
    case RESET_PRODUCT_LIST:
      return resetProductList(state);
    case SET_INITIAL_STATE:
      return setInitialState(state);
    case HANDLE_ASSISTIVE_FILTER_CLICK:
      return handleAssistiveFilterClick(state, action);
    case HANDLE_GENERATED_URL:
      return handleGeneratedUrls(state, action);
    case HANDLE_URL_CHANGE:
      return handleURLChange(state, action);
    case SET_BRAND_PAGE_URLS:
      return setBrandPageUrls(state, action);
    default:
      return state;
  }
};

export default productListingReducer;
