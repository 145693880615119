import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import { emailValidationRegExp } from "../../../constants";
import IntlInput from "../../intl-tel-input/intl-tel-input";
import OrderListModal from "../../../routes/send-us-email/order-list-modal";
import FormActivityHandler from "../../formActionsHandler";

export default class QuestionForm extends Component {
  state = {
    isOrderListModalOpen: false,
    lastFocusedField: ""
  };

  static initialValues = {
    orderId: "",
    userName: "",
    email: "",
    phone: "",
    userMessage: ""
  };

  componentDidMount() {
    const { getCountryList, language } = this.props;
    getCountryList(language);
  }

  static schema = mobileLength =>
    Yup.object().shape({
      orderId: Yup.string().trim(),
      userName: Yup.string().min(1).trim().required(),
      email: Yup.string().trim().matches(emailValidationRegExp).required(),
      phone: Yup.string().min(mobileLength).max(mobileLength).required(),
      areaCode: Yup.string(),
      userMessage: Yup.string().trim().required()
    });

  onOrdersListModalToggle = () => {
    if (this.props.userInfo.userLoggedIn) {
      this.setState(prevState => ({
        isOrderListModalOpen: !prevState.isOrderListModalOpen
      }));
    }
  };

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });
  render() {
    const {
      translation,
      userForm: { dialCode, areaCode, uploadFiles },
      handleRemoveImg,
      userInfo,
      handleSubmit,
      countryData,
      defaultCountry,
      isImageExist,
      mobileLocalNumberLength,
      handleScroll,
      myOrders,
      language,
      selectedOrderId,
      handleSelectOrder,
      fieldErrors,
      handleTextChange
    } = this.props;
    const { isOrderListModalOpen } = this.state;

    return (
      <div className="SendUsEmail_form">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={() => QuestionForm.schema(mobileLocalNumberLength)}
          initialValues={{ ...QuestionForm.initialValues, areaCode }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => {
            const isInputInvalid = valueKey =>
              Boolean(touched[valueKey] && errors && errors[valueKey]);

            return (
              <form onSubmit={handleSubmit} className="signUp">
                <div className="order form-group">
                  <input
                    type="text"
                    className="form-control order_id"
                    name="orderId"
                    placeholder={translation.orderId}
                    value={values.orderId}
                    onChange={handleChange}
                    aria-label="Order id"
                    onFocus={this.setLastFocusedField}
                  />
                  <div
                    className={cn("choose_order", {
                      login_tooltip: !userInfo.userLoggedIn
                    })}
                    onClick={this.onOrdersListModalToggle}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className={cn("form-control", {
                      borderRed: isInputInvalid("userName")
                    })}
                    name="userName"
                    placeholder={translation.name}
                    value={values.userName}
                    onChange={handleChange}
                    aria-label="Name"
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("userName") && (
                    <span className="error">{fieldErrors.name.message}</span>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    className={cn("form-control", {
                      borderRed: isInputInvalid("email")
                    })}
                    name="email"
                    placeholder={translation.email}
                    value={values.email}
                    onChange={handleChange}
                    aria-label="Email"
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("email") && (
                    <span className="error">{fieldErrors.email.message}</span>
                  )}
                </div>

                <div className="form-group">
                  <IntlInput
                    valid={!isInputInvalid("phone")}
                    phone={values.phone}
                    dialCode={dialCode}
                    areaCode={values.areaCode}
                    handleCountryChange={(e, phone) =>
                      setFieldValue("phone", phone)
                    }
                    onSelectArea={area => setFieldValue("areaCode", area)}
                    defaultCountry={defaultCountry}
                    mobileLocalNumberLength={mobileLocalNumberLength}
                    countryData={countryData}
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("phone") && (
                    <span className="error">{fieldErrors.phone.message}</span>
                  )}
                </div>

                <div className="form-group">
                  <textarea
                    name="userMessage"
                    placeholder={translation.message}
                    className={cn("form-control", "message", {
                      borderRed: isInputInvalid("userMessage")
                    })}
                    rows="4"
                    value={values.userMessage}
                    onChange={e => setFieldValue("userMessage", e.target.value)}
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("userMessage") && (
                    <span className="error">{fieldErrors.message.message}</span>
                  )}
                </div>

                <div className="form-group browse">
                  <label className="round_btn browse_btn">
                    {translation.Browse}
                    <input
                      type="file"
                      name="file"
                      onClick={e => (e.target.value = null)}
                      onChange={e =>
                        handleTextChange("uploadFiles", e.target.files)
                      }
                      accept="image/gif, image/jpeg, image/png"
                      data-max-size="2048"
                      multiple
                      onFocus={this.setLastFocusedField}
                    />
                  </label>
                  <div className="browse_txt">{translation.BrowseText}</div>
                  {!!uploadFiles &&
                    !!uploadFiles.length &&
                    uploadFiles.map(item => {
                      return (
                        <div className="browsed_file" key={item.hash}>
                          <span className="browse_icon_img" />
                          <span className="browse_icon_cancel">
                            <span onClick={() => handleRemoveImg(item.hash)}>
                              &times;
                            </span>
                          </span>
                          <span>{item.filename}</span>
                        </div>
                      );
                    })}
                </div>
                {isImageExist && (
                  <p className="error">{translation.imageSelected}</p>
                )}

                {isOrderListModalOpen && (
                  <OrderListModal
                    toggleModal={this.onOrdersListModalToggle}
                    showModal={isOrderListModalOpen}
                    sendUsEmailTranslation={translation}
                    handleScroll={handleScroll}
                    myOrders={myOrders}
                    userInfo={userInfo}
                    language={language}
                    selectedOrderId={selectedOrderId}
                    handleSelectOrder={orderId => {
                      setFieldValue("orderId", orderId);
                      handleSelectOrder(orderId);
                    }}
                  />
                )}

                <button
                  type="submit"
                  className="btn btn-sendUsEmail round_btn form_black_btn"
                >
                  {translation.send}
                </button>
                <FormActivityHandler
                  formName={"Send Us Email Form"}
                  lastFocusedFormField={this.state.lastFocusedField}
                />
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
