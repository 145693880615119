import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import { _countryStoreSelection, languageFromPathName } from "../../util";
import {
  changeLanguage,
  getBrandsData,
  getConfigurationSettings,
  getCountrySettings,
  resetBrandsReducer,
  setCommonData
} from "../../redux/actions/common.action";

import { BROWSER_STORAGE } from "../../redux/constants";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getPageApi } from "../../redux/actions/page.action";
import { handleSectionsTask } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;

      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  },
  settings: {
    asyncTask: ({ store, params = {}, url }) => {
      const { language } = params;

      return store.dispatch(
        getCountrySettings(languageFromPathName(language), null, url)
      );
    },
    priority: 2
  },
  fetchHomePage: {
    asyncTask: ({ store, params = {}, url, cookies }) => {
      const { common } = store.getState();
      const { language } = params;

      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getPageApi(
          "/",
          language,
          _country && _country.countryId,
          storeId || _bflStoreId,
          null,
          cookies
        )
      );
    },
    priority: 4
  },
  fetchMegaMenu: {
    asyncTask: ({ store, params = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }

      const { common } = store.getState();
      const { language } = params;

      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];
      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getMegaMenuData(
          languageFromPathName(language),
          storeId || _bflStoreId,
          _country && _country.countryId,
          cookies
        )
      );
    },
    priority: 5
  },
  getBrandsData: {
    asyncTask: ({ store }) => {
      const { common } = store.getState();
      const { language } = common;

      return store.dispatch(
        getBrandsData(
          language === LANGUAGE_ROUTE_KEY.arabic
            ? LANGUAGE.arabic
            : LANGUAGE.english
        )
      );
    },
    priority: 6
  },
  resetBrandsReducer: {
    asyncTask: ({ store }) => {
      const { common } = store.getState();
      const { language } = common;

      return store.dispatch(
        resetBrandsReducer(
          language === LANGUAGE_ROUTE_KEY.arabic
            ? LANGUAGE.arabic
            : LANGUAGE.english
        )
      );
    },
    priority: 7
  },
  fetchHeaderSectins: {
    asyncTask: arg => handleSectionsTask(arg, SECTION_SLUGS.HEADER),
    priority: 8
  },
  configuration: {
    asyncTask: ({ store }) => store.dispatch(getConfigurationSettings()),
    priority: 9
  }
};
