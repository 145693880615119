import { createSelector } from "reselect";
import { selectAddressReducer, selectAuthReducer } from "../../util/selectors";

export const selectUserDataForOrder = createSelector(
  selectAuthReducer,
  selectAddressReducer,
  (authReducer, addressReducer) => ({
    email: authReducer.email,
    firstname: addressReducer.firstname,
    lastname: addressReducer.lastname,
    phone: addressReducer.phone
  })
);
