import ApiService from "./ApiService";

import { BEConfig } from "../config/env";

import { webapiGet } from "../webapis/core";
import { LANGUAGE_ROUTE_KEY_MAP } from "../constants";

class SectionService extends ApiService {
  async getBannerSections(sectionSlug) {
    const baseUrl = `${this.getBaseUrl()}${
      BEConfig.catalogApi.bannerSections
    }/${sectionSlug}`;
    const language = LANGUAGE_ROUTE_KEY_MAP[this.getLanguage()];

    try {
      const response = await webapiGet(
        this.getAccessToken(),
        baseUrl,
        this.getVisitorId(),
        language
      ).request;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new SectionService();

export default instance;
