import { Image } from "../../image";
import ProductOverlayTag from "../../product/product-overlayTag";
import RoundedDiscount from "../../discount/RoundedDiscount";

const ContentImage = ({
  img,
  url,
  overlayLabel,
  overlayTagBgColor,
  overlayTagLabelColor,
  lazyLoad,
  showDefaultImage,
  colorAvailable,
  showDiscountSlab = true,
  settings = {},
  item
}) => {
  return (
    <div className="content_image">
      {img && url && (
        <Image
          src={`${url}${img}`}
          style={{ width: "100% " }}
          lazyLoad={lazyLoad}
          showDefaultImage={showDefaultImage}
          colorAvailable={colorAvailable}
        />
      )}
      {showDiscountSlab && item && (
        <RoundedDiscount item={item} countrySettings={settings} />
      )}

      <ProductOverlayTag
        overlayLabel={overlayLabel}
        overlayTagBgColor={overlayTagBgColor}
        overlayTagLabelColor={overlayTagLabelColor}
      />
    </div>
  );
};

export default ContentImage;
