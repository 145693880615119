import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Page from "../../components/page";
import thankYouImage from "../../images/thankyou-badge.png";
import { signUpTranslation } from "../../language/signUp/en/signUp";
import { changeLanguage } from "../../redux/actions/common.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

class ThankYouPage extends Component {
  state = {
    signUpTranslation: signUpTranslation
  };

  componentDidMount() {
    const { handleLanguageChange, match, language } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.getlanguage({ language: languageParam });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps);
    }
  }

  getlanguage(nextProps) {
    const signUpResponse = import(
      `../../language/signUp/${nextProps.language}/signUp.js`
    );
    signUpResponse.then(response => {
      this.setState({ signUpTranslation: response.signUpTranslation });
    });
  }

  signInHandler = () => {
    const { history, language, settings } = this.props;
    history.push(
      `/${language}-${
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
        "ae"
      }/login/`
    );
  };

  render() {
    const { signUpTranslation } = this.state;
    const source = this.props.location.state
      ? this.props.location.state.detail
      : "";
    return (
      <Page>
        <div className="register_user_page">
          <div className="thankyou signup_layout">
            <p className="thank_img">
              <img src={thankYouImage} alt="thankyou for registering" />
            </p>
            {source === "fromResetPassword" ? (
              <div className="thankyou_message">
                <h3>{signUpTranslation.forgotPasswordHelp1}</h3>
                <p>{signUpTranslation.forgotPasswordHelp2}</p>
              </div>
            ) : (
              <div>
                <h3>{signUpTranslation.thankyouHelp1}</h3>
                <p>{signUpTranslation.thankyouHelp2}</p>
              </div>
            )}

            <button className="round_btn btn" onClick={this.signInHandler}>
              {signUpTranslation.signIn}
            </button>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings
});

const mapStateToDispatch = dispatch => ({
  handleLanguageChange(name) {
    dispatch(changeLanguage(name));
  }
});

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapStateToDispatch)
)(ThankYouPage);
