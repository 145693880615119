import {
  SET_CART_SECTIONS,
  SET_CHECKOUT_SECTIONS,
  SET_HEADER_SECTIONS,
  SET_ORDER_SUCCESS_SECTIONS,
  SET_PDP_SECTIONS
} from "../constants/sections";

const initialState = {
  headerSections: {
    headerBottom: null,
    headerShopByStore: null
  },
  pdpSections: {
    pdpTop: null,
    pdpSectionOne: null,
    pdpSectionTwo: null
  },
  cartSections: {
    cartTop: null,
    cartVat: null
  },
  checkoutSections: {
    checkoutTop: null
  },
  orderSuccessSections: {
    orderSuccessTop: null,
    orderSuccessSectionOne: null
  }
};

const setHeaderSections = (state, action) => ({
  ...state,
  headerSections: action.sections
});

const setPDPSections = (state, action) => ({
  ...state,
  pdpSections: action.sections
});

const setCartSections = (state, action) => ({
  ...state,
  cartSections: action.sections
});

const setCheckoutSections = (state, action) => ({
  ...state,
  checkoutSections: action.sections
});

const setOrderSuccessSections = (state, action) => ({
  ...state,
  orderSuccessSections: action.sections
});

const sectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_SECTIONS:
      return setHeaderSections(state, action);
    case SET_PDP_SECTIONS:
      return setPDPSections(state, action);

    case SET_CART_SECTIONS:
      return setCartSections(state, action);

    case SET_CHECKOUT_SECTIONS:
      return setCheckoutSections(state, action);

    case SET_ORDER_SUCCESS_SECTIONS:
      return setOrderSuccessSections(state, action);
    default:
      return state;
  }
};

export default sectionsReducer;
