import { fetchWebApi } from "../../webapis/apiResource";
import { BEConfig } from "../../config/env";
import { getAccessToken } from "../../util/storeHelper";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  getVisitorId
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import {
  STORE_LOCATOR_LIST,
  CHANGE_SELECTED_COUNTRY,
  CHANGE_SELECTED_SHOP
} from "../constants";
import { getPrevModeStorage } from "../../util/browserStorage";

const storeLocatorList = (locatorList, countryId, countryData) => ({
  type: STORE_LOCATOR_LIST,
  locatorList,
  countryId,
  countryData
});

export const changeSelectedCountry = selectedCountry => ({
  type: CHANGE_SELECTED_COUNTRY,
  selectedCountry
});

export const changeSelectedShop = selectedShop => ({
  type: CHANGE_SELECTED_SHOP,
  selectedShop
});

export const getStoreLocatorList = (language, countryId) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.commonApi.protocol}${BEConfig.commonApi.baseURL}${
    BEConfig.commonApi.port
  }${BEConfig.commonApi.versionInfo}${BEConfig.commonApi.common}/${
    BEConfig.commonApi.shops
  }`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language,
      null,
      null,
      null,
      getPrevModeStorage()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        const countryData = getState().common.countryData;
        if (response.data && response.status === 200) {
          dispatch(storeLocatorList(response.data, countryId, countryData));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
