import React from "react";
import RoundOrderImg from "../round-img";
import moment from "moment";

const orderCard = ({
  orderId,
  totalAmount,
  orderPlaced,
  estimatedDelivery,
  myAccountTranslation,
  orderItems,
  orderStatus
}) => {
  return (
    <div className="orderwrapper">
      <div className="row">
        <div className="col-md-5 col-sm-12 padding-right0">
          <div className="product_img">
            {orderItems.length > 1 ? (
              <div>
                <RoundOrderImg
                  imgSrc={`${orderItems[0].image}`}
                  intialClass={`initialProduct`}
                />
                <RoundOrderImg
                  imgSrc={`${orderItems[1].image}`}
                  overlay={true}
                  productCount={orderItems.length - 2}
                  myAccountTranslation={myAccountTranslation}
                />
              </div>
            ) : (
              <div>
                <RoundOrderImg
                  imgSrc={`${orderItems[0].image}`}
                  intialClass={`initialProduct`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-7 col-sm-12 padding-left0">
          {orderId && (
            <p className="order-text">
              <span>{myAccountTranslation.orderID} </span>
              {`${orderId}`}
            </p>
          )}
          {totalAmount && (
            <>
              <p className="amnt-text">
                <span>{myAccountTranslation.totalAmount} </span>{" "}
                {`${totalAmount}`}
              </p>
            </>
          )}
          {orderPlaced && (
            <p className="ordrePlaced-text">
              <span>{myAccountTranslation.orderPlacedOn} </span>
              {orderPlaced}
            </p>
          )}
          {estimatedDelivery &&
            orderStatus !== "DELIVERED" &&
            orderStatus !== "RETURNED" && (
              <p className="delivey-text">
                <span>{myAccountTranslation.estimatedDeliveryTime} </span>
                {moment(estimatedDelivery).format("DD MMM, YYYY")}
              </p>
            )}
        </div>
      </div>
    </div>
  );
};

export default orderCard;
