import React from "react";
import SizeFormatMessage from "../add-to-cart/size-format-message";
import SizeHelpMessage from "../add-to-cart/size-help-message";

const AdditionalSizeInfo = ({
  translation,
  sizeIndication,
  handleSizeChart
}) => (
  <div className="additional_size_info">
    {sizeIndication?.isSizeShown ? (
      <SizeFormatMessage
        translation={translation}
        sizeIndication={sizeIndication}
        handleSizeChart={handleSizeChart}
        withNoBorder
      />
    ) : (
      <SizeHelpMessage
        translation={translation}
        handleSizeChart={handleSizeChart}
        withNoBorder
      />
    )}
  </div>
);

export default AdditionalSizeInfo;
