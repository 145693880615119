import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { BEConfig } from "../../config/env";
import {
  selectCommonSettings,
  selectShowAppOnlyItemModalState
} from "../../util/selectors";

import oopsImage from "../../images/oops_lence.svg";
import mobileHeader from "../../images/mobile_header.png";

const AppOnlyItemInfo = ({ language, configCdnImagesSettings }) => {
  const [assets, setAssets] = useState({
    appExclusiveTranslation: null
  });
  const commonSettings = useSelector(selectCommonSettings);
  const { product } = useSelector(selectShowAppOnlyItemModalState);
  const qrCodeImageUrl = get(commonSettings, "cdn.downloadAppQRCodeImageUrl");
  const { appExclusiveTranslation } = assets;

  const loadAssets = async language => {
    const [{ appExclusiveTranslation }] = await Promise.all([
      import(`../../language/appExclusive/${language}/appExclusive`)
    ]);

    setAssets({
      appExclusiveTranslation: appExclusiveTranslation
    });
  };

  useEffect(() => {
    if (language) {
      loadAssets(language);
    }
  }, [language]);

  return (
    <div>
      <div className="main_section">
        <div className="phone_screen_container">
          <div className="phone">
            <div className="notch-container">
              <div className="notch" />
            </div>
            <div className="volume_button" />
            <div className="phone_screen">
              {oopsImage && (
                <img src={oopsImage} alt="oops" className="oops_img" />
              )}
              <img
                src={mobileHeader}
                alt="mobile header"
                className="mobile_header"
              />
              <img
                src={`${configCdnImagesSettings?.list}${product.captionImageURL}`}
                alt="product"
                className="product_img"
              />
            </div>
          </div>
        </div>

        <h3 className="title">{appExclusiveTranslation?.title}</h3>
        <p className="description">{appExclusiveTranslation?.description}</p>
      </div>
      <div className="download_section">
        <div className="qr_section">
          <div className="qr_code">
            <img src={qrCodeImageUrl} alt="QR code" />
            <span className="text">{appExclusiveTranslation?.scanQrCode}</span>
          </div>
        </div>

        <div className="app_links">
          <a
            href={BEConfig.commonSettings.ios}
            className="app_store"
            target="_blank"
            title=""
          />
          <a
            href={BEConfig.commonSettings.android}
            className="play_store"
            target="_blank"
            title=""
          />
        </div>
      </div>
    </div>
  );
};
export default AppOnlyItemInfo;
