export const megaMenuTranslation = {
  welcome: "Welcome!",
  signIn: "Sign In",
  signUp: "Join",
  myAccount: "My Account",
  myOrders: "My Orders",
  country: "You’re in",
  language: "Language",
  signOut: "Sign Out",
  whatsApp: "WhatsApp Us",
  shopByStore: "Shop by store",
  hi: "Hi",
  needHelp: "Need help?",
  contactUs: "Contact Us",
  trackOrder: "Track Order"
};
