import React from "react";
import { useSelector } from "react-redux";
import { map } from "lodash";
import { selectShowLoader } from "../../../util/selectors";
import BounceLoader from "../../bounce-loader";

const ReAttachNumber = ({
  translation,
  phoneNumber,
  linkedEmails,
  onReattachClick,
  buttonActionText
}) => {
  const showLoader = useSelector(selectShowLoader);

  return (
    <div className="reattach_number m-4 pt-2">
      <p className="reattach_number_description">
        {translation.forYourInformation} <b>+{phoneNumber}</b>.{" "}
        {translation.clickingOn}{" "}
        <b>“{buttonActionText || translation.createAccount}”</b>{" "}
        {translation.willAddNumber}
      </p>
      <ul>
        {map(linkedEmails, email => (
          <li key={email}>
            <b>* {email}</b>
          </li>
        ))}
      </ul>
      <button
        className="round_btn form_black_btn small_btn mt-4 px-5"
        onClick={onReattachClick}
      >
        {showLoader ? (
          <BounceLoader color="#f8d000" size={10} qty={3} />
        ) : (
          buttonActionText || translation.createAccount
        )}
      </button>
    </div>
  );
};

export default ReAttachNumber;
