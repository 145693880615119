import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import cn from "classnames";
import CustomDropdown from "../genericDropDown";
import {
  handleTextChange,
  resetSavedCard
} from "../../redux/actions/payment.action";
import { validateCard } from "./../../util/cardValidator";
import { removeWhiteSpaces, generateArrayOfYearStrings } from "../../util";
import { EXPIRY_MONTHS, CARD_TYPES } from "../../constants";

const expiryMonths = EXPIRY_MONTHS;
const expiryYears = generateArrayOfYearStrings();
const cardTypes = CARD_TYPES;

class CreditDebitCard extends Component {
  cardHolderNameRef = React.createRef();

  changeField = (fieldName, value) => {
    const digitsOnly = /^\d*$/.test(value);

    if (fieldName === "cardNumber" && !digitsOnly) return;

    const status = validateCard(value);
    this.props.handleTextChange(
      fieldName,
      value,
      status.luhn_valid && status.length_valid
    );
    if (status && status.card_type && cardTypes[status.card_type]) {
      this.props.handleTextChange("cardName", cardTypes[status.card_type]);
    } else {
      this.props.handleTextChange("cardName", "");
    }
  };

  changeRestFields = (name, value) => {
    if (name === "cvv") {
      const re = /^[0-9\b]+$/;
      value.length <= 3 &&
        (re.test(value) || value.trim() === "") &&
        this.props.handleTextChange(name, value);
    } else {
      this.props.handleTextChange(name, value);
    }
  };

  UNSAFE_componentWillMount() {
    this.props.resetSavedCard();
  }

  CVVextraFocusHandler = () => {
    const { payment } = this.props;
    if (
      !payment.errors.cardHolderName.touched &&
      !payment.errors.cardNumber.touched &&
      this.cardHolderNameRef.current
    ) {
      this.cardHolderNameRef.current.focus();
    }
  };

  render() {
    const { checkoutTranslation, payment, selectedSavedCard, hideCvv } =
      this.props;
    return (
      <div>
        <div className="card_details">
          <div className="form-group">
            <label>{checkoutTranslation.nameOnCard}</label>
            <input
              name="cardHolderName"
              className={cn("form-control", {
                borderRed:
                  !payment.errors.cardHolderName.valid &&
                  payment.errors.cardHolderName.touched
              })}
              type="text"
              value={!selectedSavedCard ? payment.cardHolderName : ""}
              placeholder={checkoutTranslation.enterCardOwnerName}
              onChange={e =>
                this.changeRestFields("cardHolderName", e.target.value)
              }
              ref={this.cardHolderNameRef}
              aria-label="Card holder name"
            />
            {!payment.errors.cardHolderName.valid &&
              payment.errors.cardHolderName.touched && (
                <span className="error">
                  {checkoutTranslation.fieldIsRequired}
                </span>
              )}
          </div>
          <div className="form-group">
            <label>{checkoutTranslation.cardNumber}</label>
            <div
              className={cn({
                [payment.cardName]: payment.errors.cardNumber.valid
              })}
            >
              <input
                name="cardNumber"
                type="text"
                maxLength="16"
                className={cn("form-control", {
                  borderRed:
                    !payment.errors.cardNumber.valid &&
                    payment.errors.cardNumber.touched
                })}
                value={!selectedSavedCard ? payment.cardNumber : ""}
                placeholder={checkoutTranslation.enterCardNumber}
                onChange={e =>
                  this.changeField("cardNumber", e.target.value.trim())
                }
                onPaste={e =>
                  this.changeField(
                    "cardNumber",
                    removeWhiteSpaces(e.clipboardData.getData("Text"))
                  )
                }
                aria-label="Card number"
              />
              {!payment.errors.cardNumber.valid &&
                payment.errors.cardNumber.touched && (
                  <span className="error">
                    {checkoutTranslation.cardNumberInvalid}
                  </span>
                )}
              <input className="hidden_input" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="expiry_label">
            {checkoutTranslation.expiryDate}
          </label>
          <div className="security_details">
            <div className="month calender">
              <CustomDropdown
                data={expiryMonths}
                scrollbarMaxHeight={350}
                selectedItem={payment.expiryMonth}
                containerClass={cn("form-control-dropdown form-control", {
                  borderRed:
                    !payment.errors.expiryMonth.valid &&
                    payment.errors.expiryMonth.touched
                })}
                handleChange={e => this.changeRestFields("expiryMonth", e)}
                defaultSelectValue={
                  !selectedSavedCard
                    ? payment.expiryMonth ||
                      "MM" ||
                      (expiryMonths && expiryMonths[0])
                    : ""
                }
              />
              {!payment.errors.expiryMonth.valid &&
                payment.errors.expiryMonth.touched && (
                  <span className="error">
                    {checkoutTranslation.fieldIsRequired}
                  </span>
                )}
            </div>
            <div className="year calender">
              <CustomDropdown
                data={expiryYears}
                scrollbarMaxHeight={350}
                containerClass={cn("form-control-dropdown form-control", {
                  borderRed:
                    !payment.errors.expiryYear.valid &&
                    payment.errors.expiryYear.touched
                })}
                selectedItem={payment.expiryYear}
                handleChange={e => this.changeRestFields("expiryYear", e)}
                defaultSelectValue={
                  !selectedSavedCard
                    ? payment.expiryYear ||
                      "YYYY" ||
                      (expiryYears && expiryYears[0])
                    : ""
                }
              />
              {!payment.errors.expiryYear.valid &&
                payment.errors.expiryYear.touched && (
                  <span className="error">
                    {checkoutTranslation.fieldIsRequired}
                  </span>
                )}
            </div>
            {!hideCvv && (
              <div className="cvv_input flex">
                <p className="">
                  {checkoutTranslation.enterCVV}
                  <span>
                    (?)
                    <span className="tooltip_alert">
                      {checkoutTranslation.cvvAlert}
                    </span>
                  </span>
                </p>
                <div className="cvv_input_value">
                  <input
                    type="password"
                    className={cn("form-control cvv_value", {
                      borderRed:
                        !payment.errors.cvv.valid &&
                        !selectedSavedCard &&
                        payment.errors.cvv.touched
                    })}
                    value={!selectedSavedCard ? payment.cvv : ""}
                    onChange={e => this.changeRestFields("cvv", e.target.value)}
                    onFocus={this.CVVextraFocusHandler}
                    name="card_cvv"
                    aria-label="Card cvv"
                  />
                  {!payment.errors.cvv.valid &&
                    payment.errors.cvv.touched &&
                    !selectedSavedCard && (
                      <span className="error">
                        {checkoutTranslation.fieldIsRequired}
                      </span>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  payment: state.paymentReducer
});

const mapDispatchToProps = dispatch => ({
  handleTextChange: bindActionCreators(handleTextChange, dispatch),
  resetSavedCard: bindActionCreators(resetSavedCard, dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditDebitCard)
);
