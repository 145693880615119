import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import { OurnewArrival } from "../../language/ourNewestArrival/en/ourNewestArrival";
import { isServer } from "../../util";
import { Image } from "../image";

class NewArrivalSection extends Component {
  state = {
    OurnewArrivalTranslation: OurnewArrival
  };

  UNSAFE_componentWillMount() {
    if (!isServer) {
      this.getlanguage();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage();
    }
  }

  getlanguage = () => {
    const { match, language } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    const languageResponse = import(`../../language/ourNewestArrival/${languageParam}/ourNewestArrival`);
    languageResponse.then(response => {
      this.setState({
        OurnewArrivalTranslation: response.OurnewArrival
      });
    });
  };

  arrivalRedirection = itemName => {
    const { history, routelanguage, settings } = this.props;
    history.push(
      `/${routelanguage}-${(settings &&
        settings.countrySHORT &&
        settings.countrySHORT.toLowerCase()) ||
        "ae"}${itemName}`
    );
  };

  render() {
    const { newestArrival, language, routelanguage, settings } = this.props;
    params.rtl = this.props.language === "arabic" ? "rtl" : "";
    const { OurnewArrivalTranslation } = this.state;
    return (
      <Fragment>
        {newestArrival[language] &&
          !!newestArrival[language].arrivalList.length && (
            <div className="newest_arrivals">
              <div className="container padding0">
                <div className="head_with_desc">
                  <h2>{newestArrival[language].title}</h2>
                  <p className="heading_desc">
                    {newestArrival[language].subTitle}
                  </p>
                </div>
                <div className="arrival_slider">
                  <Swiper {...params}>
                    {newestArrival[language] &&
                      newestArrival[language].arrivalList.map(
                        (newestArrivalItem, index) => (
                          <div
                            key={
                              newestArrivalItem._id
                                ? newestArrivalItem._id
                                : index
                            }
                          >
                            <div
                              className="arrival_img_wrapper carousal-margin"
                              onClick={() =>
                                this.arrivalRedirection(
                                  newestArrivalItem.redirectionLink
                                )
                              }
                            >
                              <Image
                                src={newestArrivalItem.imageLink}
                                alt="arrival-items"
                              />
                              <div className="arrival_content">
                                <h4 className="heading">
                                  {newestArrivalItem.categoryName}
                                </h4>
                                <p>{newestArrivalItem.subCategoryName}</p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Swiper>
                </div>
                <div className="newArrival_cta">
                  <Link
                    to={`/${routelanguage}-${(settings &&
                      settings.countrySHORT &&
                      settings.countrySHORT.toLowerCase()) ||
                      "ae"}/new-arrivals/`}
                    className="white section_cta round_btn"
                  >
                    {OurnewArrivalTranslation.newestArrivalBtn}
                  </Link>
                </div>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}

const params = {
  dots: true,
  slidesPerView: 4.5,
  rebuildOnUpdate: true,
  spaceBetween: 10,
  breakpoints: {
    1024: {
      slidesPerView: 4,
      spaceBetween: 5
    },
    800: {
      slidesPerView: 3.5,
      spaceBetween: 5
    },
    600: {
      slidesPerView: 2.5,
      spaceBetween: 5
    },
    320: {
      slidesPerView: 2.5,
      spaceBetween: 10
    }
  },

  navigation: {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  }
};

export default withRouter(NewArrivalSection);
