import React, { useCallback } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const RangeSelector = ({
  changeRange,
  changeRangeComplete,
  maxValue,
  minValue,
  value
}) => {
  const handleChange = useCallback(
    value => {
      const newValue =
        value.max > maxValue ? { ...value, max: maxValue } : value;
      changeRange(newValue);
    },
    [value]
  );

  return (
    <InputRange
      draggableTrack
      maxValue={maxValue}
      minValue={minValue}
      value={value}
      onChange={handleChange}
      onChangeComplete={changeRangeComplete}
    />
  );
};

export default RangeSelector;
