import React from "react";
import moment from "moment";
import * as priceUtil from "../../../util/priceUtil";
class MyWalletTransactionCard extends React.Component {
  render() {
    const { myAccountTranslation, walletHistory } = this.props;
    return (
      <div
        className={`transaction ${
          walletHistory.action === "ADD" ? "positive" : "negative"
        }`}
      >
        <div className="row no-gutters">
          <div className="col-8">
            <p className="title_text">
              {myAccountTranslation[walletHistory.mode] || walletHistory.mode}
            </p>
            <p className="order margin0">
              {walletHistory && walletHistory.mode === "Order Placed" ? (
                <span className="order_title">
                  {myAccountTranslation.orderId}
                </span>
              ) : null}
              <span className="order_numb">{walletHistory.message}</span>
            </p>
          </div>
          <div className="col-4">
            <p className="balance">
              {" "}
              {walletHistory.action === "ADD"
                ? `+ ${
                    walletHistory.currencyCode
                  } ${priceUtil.shapePriceWithComma(
                    walletHistory.transactionAmount
                  )}`
                : `- ${
                    walletHistory.currencyCode
                  } ${priceUtil.shapePriceWithComma(
                    walletHistory.transactionAmount
                  )}`}
            </p>
            <p className="date margin0">
              {" "}
              {moment(walletHistory.date).format("DD MMM YYYY")}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MyWalletTransactionCard;
