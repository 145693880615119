export const emailValidationRegExp =
  /^(?=[\w\.\-]+@[\w\.\-]+\.\w{2,4}).{5,70}$/;
export const linkRegExp =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const languageCountryRegExp = /([a-z]{2}-[a-z]{2})/;

export const percentageRegEx = /\%/;
export const LANGUAGE = {
  english: "english",
  arabic: "arabic"
};

export const LANGUAGE_SHORT = {
  en: "en",
  ar: "ar"
};

export const LANGUAGE_ROUTE_KEY = {
  english: "en",
  arabic: "ar"
};
export const LANGUAGE_ROUTE_KEY_MAP = {
  en: "english",
  ar: "arabic"
};
export const DEFAULT_STORE_ID = 2;
export const TEMP_QTY = 14;
export const ALL_CATEGORY_NAME = "All";
export const PARENT_CATEGORY_NAME = "parentCategoryName";
export const BRAND_NAME = "brandName";
export const BRAND_NAME_URL_SUFFIX = "/brands";
export const PARENT_CATEGORY_SEO_KEY_NAME = "seo_parentCategoryName";
export const BRAND_NAME_SEO_KEY_NAME = "seo_brandName";
export const HIERARCHICAL_CATEGORIES_LIST = [
  "hierarchicalCategories.lvl0",
  "hierarchicalCategories.lvl1",
  "hierarchicalCategories.lvl2"
];
export const PARENT_CATEGORY_KEY_NAME = HIERARCHICAL_CATEGORIES_LIST[0];
export const CATEGORY_KEY_NAME = HIERARCHICAL_CATEGORIES_LIST[0];
export const SUB_CATEGORY_KEY_NAME = HIERARCHICAL_CATEGORIES_LIST[0];
export const DROP_DOWN_SCROLL_TYPES = {
  DEFAULT: "DEFAULT",
  DROP_DOWN_WITH_INLINE_SCROLL: "DROP_DOWN_WITH_INLINE_SCROLL",
  DROP_DOWN_WITHOUT_INLINE_SCROLL: "DROP_DOWN_WITHOUT_INLINE_SCROLL"
};
export const IMAGE_SIZES = {
  smallWidth: 140,
  smallHeight: 210,
  mediumWidth: 184,
  mediumHeight: 276,
  largeWidth: 290,
  largeHeight: 435
};
export const ONE_SIZE = "One Size";
export const SYTE_DISCOVERY_LOCATION = {
  LISTING: "LISTING",
  PDP: "PDP"
};
export const EXCLUDE_COUNTRY_SELECTION_DROPDOWN = ["my-account"];
export const QUICK_FILTERS = "Quick Filters";
export const DELIVERY_TYPES = {
  DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
  SELF_PICKUP: "SELF_PICKUP",
  STORE_PICKUP: "STORE_PICKUP"
};
export const WHATSAPP_NAME = "whatsapp";
export const WHATSAPP_NUMBER = "+971 800 8333";
export const WHATSAPP_LINK = "https://wa.me/9718008333";
export const STORE_LOCATOR_LINK = "/store-locator/";
export const TABBY = "TABBY";
export const TAMARA = "TAMARA";

export const MODAL_DIALOG_OPTIONS = {
  yes: "yes",
  no: "no"
};

export const FREE_SHIPPING_VALUE = 9999999999;
export const FREE_SHIPPING = "FREE_SHIPPING";
export const SHOW_ALL = "show all";

export const LOADER_TYPES = {
  DOTS: "Dots",
  RING: "Ring",
  RIPPLE: "Ripple"
};

export const BULLET_TYPES = {
  RECTANGLE: "rectangle",
  BULLET: "bullet",
  NONE: "none",
  SCROLL: "scroll"
};

export const CONTROLLER_TYPES = {
  DEFAULT: "default",
  NONE: "none"
};

export const BROWSER = "browser";
export const DESKTOP = "desktop";
export const TABLET = "tablet";
export const MOBILE = "mobile";

export const BANNER_ROW_TYPE = {
  VIDEO: "video",
  COUNTDOWN: "countdown"
};

export const BACKGROUND_TYPE = {
  GRADIENT: "backgroundGradient",
  COLOR: "backgroundColor",
  IMAGE: "backgroundImage"
};

export const HOMEPAGE_ROUTE = "/";

export const PRODUCT_CAROUSEL_ITEMS_LIMIT = 20;

export const POST_MESSAGE = {
  HANDLE_CONTINUE_SHOPPING: "handleContinueShopping"
};

export const TABBY_PAYMENT_ID = "TABBY_PAYMENT_ID";
export const TAMARA_PAYMENT_ID = "TAMARA_PAYMENT_ID";

export const tabbyPromotionUrl =
  "https://checkout.tabby.ai/promos/product-page/installments";

export const ZIP_CODE_LENGTH_MIN = {
  USA: 5,
  IND: 6,
  PHL: 4,
  JPN: 5
};

export const ZIP_CODE_LENGTH_MAX = {
  USA: 5,
  IND: 6,
  PHL: 4,
  JPN: 7
};

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error"
};

export const ORDER_HANDED_TO_SHIPPER = "HANDED TO SHIPPER";

export const DEFAULT_PAGE_LOADER_CONFIG = {
  type: LOADER_TYPES.DOTS,
  fullscreen: true,
  size: 16,
  qty: 3,
  background: "rgba(251, 251, 253, 0.4)"
};

export const PLP_CMS_DATA_SLUGS = {
  BOTTOM_SECTION: "BOTTOM_SECTION",
  TOP_SECTION: "TOP_SECTION"
};

export const MAIN_TAB_INDEX = 1;

export const UNMINIMIZED_NAME = {
  CATALOG_PAGE: "CatalogPage",
  HOME_PAGE: "HomePage",
  PRODUCT_LISTING: "ProductListing",
  REDIRECTION: "Redirection"
};

export const UNSWIPEABLE_COUNT = 3;

export const commonLoaderStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export const DEFAULT_CURRENCY_CODE = "AED";

export const DEFAULT_ITEM_QUANTITY = "1";

export const WALLET_FAQ_ACCORDION = "cms-wallet-faq-accordion";
