// search
export const SEARCH_INTERACTION_TEXT = "text";
export const SEARCH_INTERACTION_VISUAL = "visual";

// page type
export const QUICKLOOK_MODAL = "quicklook";
export const ORIGIN_PRODUCT = "product";

export const LOGGED_IN = "logged in";
export const LOGGED_OFF = "logged off";
export const LOGGED_OUT = "logged out";
