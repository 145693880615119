import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import isEmpty from "lodash/isEmpty";
import {
  saveBreadcrumb,
  resetBreadCrumb
} from "../../redux/actions/common.action";
import {
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  UNMINIMIZED_NAME
} from "../../constants";
import { CATALOG_SECTION_TYPES } from "../../constants/catalog";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import CatalogCarousal from "../../components/catalog-carousal";
import Page from "../../components/page";
import ProductCarousel from "../../components/product/product-carousel";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import { selectPageMeta } from "../../util/selectors";

class CatalogPage extends Component {
  static unminimizedName = UNMINIMIZED_NAME.CATALOG_PAGE;

  componentDidMount() {
    const breadcrumbStore = {};
    const { pageName, saveBreadcrumb, resetBreadCrumb } = this.props;
    breadcrumbStore.modelName = "category";
    breadcrumbStore.modelData = { categoryName: pageName, categoryUrl: `` };
    resetBreadCrumb && resetBreadCrumb();
    saveBreadcrumb && saveBreadcrumb(breadcrumbStore);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pageName, saveBreadcrumb, resetBreadCrumb } = this.props;
    if (pageName !== nextProps.pageName) {
      const breadcrumbStore = {};
      breadcrumbStore.modelName = "category";
      breadcrumbStore.modelData = {
        categoryName: nextProps.pageName,
        categoryUrl: ``
      };
      resetBreadCrumb && resetBreadCrumb();
      saveBreadcrumb &&
        saveBreadcrumb(breadcrumbStore) &&
        saveBreadcrumb({
          modelName: "product",
          modelData: {
            productName: "",
            productUrl: ""
          }
        });
    }
  }

  render() {
    const { CatalogContent, language, routelanguage, settings, meta } =
      this.props;
    const pageTitle = meta?.title || "";
    const metaData = isEmpty(meta) ? {} : { [routelanguage]: meta };
    return (
      <Page
        meta={metaData}
        language={language}
        id="catalog"
        indexable
        title={pageTitle}
      >
        <div
          className={
            routelanguage === "ar" ? "catalog catalog_arabic" : "catalog"
          }
        >
          {CatalogContent &&
            CatalogContent.content.map((catalogItem, index) => {
              switch (catalogItem.type) {
                case CATALOG_SECTION_TYPES.PRODUCT_CAROUSEL:
                  return (
                    <ProductCarousel
                      content={catalogItem}
                      key={index}
                      bannerPageTitle={CatalogContent.title}
                      isSaleCarousel={false}
                    />
                  );
                case CATALOG_SECTION_TYPES.CATALOG_CAROUSEL:
                  return (
                    <CatalogCarousal
                      carousalContent={catalogItem}
                      language={language}
                      routelanguage={routelanguage}
                      settings={settings}
                      key={index}
                    />
                  );
                case CATALOG_SECTION_TYPES.DYNAMIC_BANNERS:
                  return (
                    <DynamicBannersWrapper
                      key={index}
                      section={catalogItem}
                      bannerPageTitle={CatalogContent.title}
                    />
                  );
                default:
                  return null;
              }
            })}
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.common.settings,
  language:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  routelanguage: state.common.language,
  storeId: state.page.homepageState.storeId,
  meta: selectPageMeta(state),
  redirectionData: state.page.redirectionData
});

const mapStateToDispatch = {
  saveBreadcrumb,
  resetBreadCrumb
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  connect(mapStateToProps, mapStateToDispatch),
  withPageTypeTracking
)(CatalogPage);
