import React from "react";
import classnames from "classnames";

const FilterRightPane = ({
  list,
  l3list,
  handleSizeOptionChange,
  sizeOption,
  handleSizeChange,
  height
}) => {
  const l3Data =
    (l3list &&
      l3list.map(item => ({
        ...item[Object.keys(item)[0]],
        name: Object.keys(item)[0]
      }))) ||
    [];

  return (
    <div className="filterRightPane">
      <ul className="rightTab">
        {list &&
          list.map((item, index) => (
            <li
              key={item}
              className={classnames({
                active: sizeOption === item || (!sizeOption && index === 0)
              })}
              onClick={
                !sizeOption && index === 0
                  ? handleSizeOptionChange(item)
                  : () => handleSizeOptionChange(item)
              }
            >
              {item}
            </li>
          ))}
      </ul>
      <ul className="rightFilterContent" style={{ height: height - 151 }}>
        {l3Data.map(item => (
          <li key={item.name}>
            <p
              onClick={() =>
                handleSizeChange(
                  "level2",
                  item,
                  "",
                  item.subFacet,
                  !item.checked,
                  false
                )
              }
            >
              <span
                className={classnames("checkbox", { checked: item.checked })}
              />
              <span className="text">{item.name}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterRightPane;
