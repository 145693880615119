import {
  GET_PRODUCT_DETAILS,
  RESET_PRODUCT_DETAIL,
  GET_PDP_SIZE_CHART,
  HANDLE_SIZE_SELECTION,
  CLEAR_SIZE_SELECTION
} from "../constants";
import { productDetailSeed } from "../initialSeed/productDetailSeed";

const initialState = {
  productDetail: productDetailSeed,
  sizeChart: [],
  sizeSelection: false
};

const getProductDetail = (state, { productDetail }) => ({
  ...state,
  productDetail
});

const getSizeChart = (state, { data }) => ({ ...state, sizeChart: [...data] });

const resetProductDetail = state => ({ ...initialState });

const handleSizeSelection = (state, { value }) => ({
  ...state,
  sizeSelection: value
});

const clearSizeSelection = state => ({ ...state, sizeSelection: "" });

const productDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DETAILS:
      return getProductDetail(state, action);
    case RESET_PRODUCT_DETAIL:
      return resetProductDetail(state);
    case GET_PDP_SIZE_CHART:
      return getSizeChart(state, action);
    case HANDLE_SIZE_SELECTION:
      return handleSizeSelection(state, action);
    case CLEAR_SIZE_SELECTION:
      return clearSizeSelection(state);
    default:
      return state;
  }
};

export default productDetailReducer;
