export const orderSuccessTranslation = {
  thankYouForOrdering: "Thank you for your order!",
  confirmationOnEmail:
    "Your order has been placed successfully. The order details have been sent to your email address.",
  paymentInProccess:
    "Your order has been received and is being processed. Check your email for details.",
  orderSummary: "Order Summary",
  youOrdered: "Here's what you ordered",
  viewAllOrder: "View All Orders",
  shipTo: "Ship To",
  orderID: "Order ID",
  viewOrder: "View Order",
  totalAmount: "Total Amount",
  orderPlacedOn: "Order Placed on",
  orderPlacedOnTemplate:
    '<p class="green_text">Order Placed on <span><%=date%><%=time%></span> </p>',
  paymentMode: "Payment Mode",
  COD: "Cash on Delivery",
  VOUCHER: "Voucher",
  CC: "Credit / Debit Card",
  CBD: "Cash Before Delivery",
  WALLET: "Wallet",
  deliveryMode: "Delivery Mode",
  DELIVERY_ADDRESS: "Standard Delivery",
  EXPRESS_DELIVERY: "Express Delivery",
  SELF_PICKUP: "Collection Points",
  STORE_PICKUP: "In-store Pickup",
  estimatedDelivery: "Estimated delivery by",
  items: "Item(s)",
  price: "Price",
  size: "Size",
  quantity: "Qty",
  sendOtp: "Send OTP",
  OTPVerification: "OTP Verification",
  sent4DigitOTP: "Please enter the 4 digit OTP sent to",
  resendOTP: "Resend OTP",
  OTPResent: "OTP Resent",
  verify: "Verify",
  telephone: "Tel",
  missedLoyaltyPoints: "You missed earning loyalty points for this",
  order: "order",
  plsRegisterAndEarn: "Please register and earn",
  loyaltyPoints: "Loyalty Points",
  fromNextOrder: "from your next order",
  youWillEarnUpTo: "You will earn up to",
  points: "points",
  needHelp: "Need Help?",
  contactText:
    "If there is any problem with your order, please contact our customer care at",
  or: "or",
  contactTime: "(Sat - Thu From 7 am to 7 pm)",
  continueShopping: "Continue Shopping",
  trackOrder: "Track Order",
  thankYou: "Thank You!",
  yourNumberVerified: "Your mobile number has been verified.",
  phoneNotValid: "Enter a valid Phone Number",
  invalidOtp: "Invalid OTP.",
  verifyMobileNumber: "Please verify your mobile number",
  verifyMobile: "Verify Number",
  IncOfVat: "Incl. of VAT",
  Discount: "Discount",
  more: "MORE",
  PAYPAL: "PayPal",
  TABBY_PAY_LATER: "Tabby - Pay Later",
  TABBY_PAY_INSTALLMENT: "Tabby - 4 interest-free payments",
  POSTPAY: "PostPay",
  update: "Update",
  pleaseTryResend: "Please try sending OTP in ",
  TAMARA_PAY_BY_INSTALMENTS_2: "Tamara - 2 installments",
  TAMARA_PAY_BY_INSTALMENTS_3: "Tamara - 3 installments",
  TAMARA_PAY_BY_INSTALMENTS_4: "Tamara - 4 installments"
};
