import React from "react";
import "./style.scss";

const TierLabel = ({ translation }) => (
  <div className="tier_label_container position-absolute">
    <i className="star_icon" />
    <span className="black_tier_label">{translation.blackTier}</span>
  </div>
);

export default TierLabel;
