import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import cn from "classnames";
import moment from "moment";
import { otp_resend_timeout } from "../../../config/app";
import { commonLoaderStyle } from "../../../constants";
import { ButtonWithLoader } from "../../generalElements/buttons";
import { GAService } from "../../../services/GA-service";

const SendOTP = ({
  orderSuccessTranslation,
  phone,
  otp,
  handleOTPChange,
  verifyOtp,
  otpValid,
  sendOtp,
  sendOtpCount,
  errorMessage,
  showLoader
}) => {
  const [timeoutActive, setTimeoutActive] = useState(true);
  const [timeLeft, setTimeLeft] = useState(otp_resend_timeout);
  const [countdownId, setCountdownId] = useState(null);

  const setCountdownFlow = () => {
    if (!timeoutActive) return;
    if (!timeLeft) {
      resetCountdownFlow();
      return;
    }
    const countdownId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    setCountdownId(countdownId);

    return () => clearTimeout(countdownId);
  };

  const resetCountdownFlow = () => {
    setTimeoutActive(false);
    clearTimeout(countdownId);
  };

  useEffect(() => {
    GAService.auth.trackOTPRequest();
  }, []);

  useEffect(setCountdownFlow, [timeoutActive, timeLeft]);

  useEffect(() => {
    if (errorMessage) {
      resetCountdownFlow();
      GAService.common.trackErrorOccured("otp", errorMessage);
    }
  }, [errorMessage]);

  const getOTPString = () =>
    moment().startOf("day").seconds(timeLeft).format("mm:ss");

  const handleResentOpt = () => {
    GAService.auth.trackResentOTP();
  };
  const showResentOTPText = Boolean(
    otpValid && sendOtpCount && !errorMessage && timeoutActive
  );

  return (
    <div className="otp_verify generic_modal_content d-flex flex-column">
      <h2>{orderSuccessTranslation.OTPVerification}</h2>

      <p className="grey_head">
        {orderSuccessTranslation.sent4DigitOTP} <span>+{phone}</span>
      </p>

      <div
        className={cn("form-group mobile_otp", {
          "flex-wrap": errorMessage && !otpValid
        })}
      >
        <OtpInput
          onChange={handleOTPChange}
          shouldAutoFocus
          numInputs={4}
          value={otp}
          separator={<span />}
        />
        {showResentOTPText && (
          <span className="otp_resent">
            {orderSuccessTranslation.OTPResent}
          </span>
        )}
        {!otpValid && (
          <span
            className={cn("error", {
              "position-relative w-100 text-center ": errorMessage
            })}
          >
            {orderSuccessTranslation.invalidOtp}
          </span>
        )}

        {errorMessage && (
          <span
            className={cn("error", {
              "position-relative w-100 text-center ": !otpValid
            })}
          >
            {errorMessage}
          </span>
        )}
      </div>

      <ButtonWithLoader
        className={cn("round_btn form_black_btn small_btn position-relative", {
          disable_order_btn: !(otp && otp.length === 4)
        })}
        onClick={verifyOtp}
        disabled={showLoader}
        showLoader={showLoader}
      >
        {orderSuccessTranslation.verify}
      </ButtonWithLoader>
      {!errorMessage && sendOtp && (
        <button
          className="resend_otp_btn mt-3"
          disabled={timeoutActive}
          onClick={e => {
            sendOtp(e);
            setTimeLeft(otp_resend_timeout);
            setTimeoutActive(true);
            handleResentOpt();
          }}
        >
          {timeoutActive ? (
            <>
              {orderSuccessTranslation.pleaseTryResend}
              <span className="resend_otp_time">{getOTPString()}</span>
            </>
          ) : (
            orderSuccessTranslation.resendOTP
          )}
        </button>
      )}
    </div>
  );
};

export default SendOTP;
