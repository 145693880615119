import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import has from "lodash/has";
import get from "lodash/get";
import Breadcrumb from "../../components/breadcrumb";
import GuestTrackingOrder from "../../components/guest-tracking-order";
import {
  resetAPIError,
  showModal,
  signInModal
} from "../../redux/actions/homepage.action";
import {
  trackOrder,
  clearTrackOrder,
  trackOrderResponse
} from "../../redux/actions/myAccount.action";
import RoundOrderImg from "../../components/round-img";
import TrackOrderTimeline from "../../components/track-order-timelines";
import { trackOrderTranslation } from "../../language/trackOrder/en/trackOrder";
import { myAccountTranslation } from "../../language/myAccount/en/myAccount";
import { needHelp } from "../../language/needHelp/en/needHelp";
import {
  changeLanguage,
  hidePageLoader,
  resetBreadCrumb,
  saveBreadcrumb,
  showPageLoader
} from "../../redux/actions/common.action";
import {
  getOrderData,
  getOrderStatusData
} from "../../redux/actions/order.action";
import NeedHelpFooter from "../help/footer";
import { HELP_DATA_TILES, LOADER_TYPES } from "../../constants";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { selectRouteSlug } from "../../util/selectors";
import {
  checkIsOrderHandedToShipper,
  getTrackOrderWebUrl
} from "../../util/order";
import Page from "../../components/page";
import { seoTranslation } from "../../language/seo/en/seo";
import { GAService } from "../../services/GA-service";

class TrackOrder extends Component {
  state = {
    trackOrderTranslation: trackOrderTranslation,
    myAccountTranslation: myAccountTranslation,
    needHelpTranslation: needHelp,
    orderId: "",
    email: "",
    showContent: false,
    isOrderHandedToShipper: false
  };

  componentDidMount() {
    const {
      language,
      getOrderStatusData,
      history,
      trackOrderResponse,
      resetBreadCrumb,
      settings,
      saveBreadcrumb
    } = this.props;
    const searchQuery = get(history, "location.search", "");
    const token = searchQuery.replace("?token=", "");

    getOrderStatusData(token, language).then((req, res) => {
      const _orderId = req && req.data.success && req.data.orderId.trim();
      if (req.data.success) {
        trackOrderResponse(req.data);
      }
      if (_orderId) {
        this.setState({ orderId: _orderId });
      }
    });
    resetBreadCrumb && resetBreadCrumb();
    this.setBreadCrumb(
      saveBreadcrumb,
      language,
      null,
      this.state.trackOrderTranslation.trackShipment,
      false,
      settings
    );
  }

  UNSAFE_componentWillMount() {
    const { match, language, handleLanguageChange } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.loadLanguage(language);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps.language);
    }
    const { userInfo, history, routeSlug } = this.props;
    if (userInfo && userInfo.accessToken !== nextProps.userInfo.accessToken) {
      const urlByToken = history.location.search.includes("token=")
        ? `/track-order/${history.location.search}`
        : "/my-account/my-orders/";

      const resultUrl = `/${routeSlug}${urlByToken}`;

      history.push(resultUrl);
    }
  }

  componentWillUnmount() {
    const { resetAPIError, clearTrackOrder } = this.props;
    clearTrackOrder && clearTrackOrder();
    resetAPIError && resetAPIError();
  }

  handleTextChange = event => {
    const { clearTrackOrder, trackOrderR } = this.props;
    this.setState({ [event.target.name]: event.target.value.trim() }, () => {
      if (Object.keys(trackOrderR).length > 0) {
        clearTrackOrder();
        this.setState({ showContent: false });
      }
    });
  };
  handleOnClickTrackOrder = async () => {
    const { trackOrder, showPageLoader, hidePageLoader } = this.props;
    const { email, orderId } = this.state;
    showPageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });

    const { data } = await trackOrder({
      email,
      orderId
    });
    if (data.success) {
      GAService.checkout.trackTrackOrder(orderId);
    }
    const isOrderHandedToShipper = checkIsOrderHandedToShipper(
      data?.orderStatusDetails
    );
    if (isOrderHandedToShipper) {
      this.handleOrderHandedStatus(data);
    }
    hidePageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
    this.setState({ showContent: true, isOrderHandedToShipper });
  };

  handleOrderHandedStatus = trackOrderDetails => {
    const { configCdnDomainSettings, language } = this.props;
    const trackOrderWebUrl = getTrackOrderWebUrl({
      trackOrderWebUrl: configCdnDomainSettings?.trackOrderWebUrl,
      trackOrderDetails,
      language
    });
    window.open(trackOrderWebUrl);
  };

  handleSignInClick = () => {
    const { signInModal, showModal } = this.props;
    signInModal(true);
    showModal();
  };

  loadLanguage = async language => {
    const { settings, saveBreadcrumb } = this.props;
    const [trackOrderResponse, myAccountTranslation, needHelpTranslation] =
      await Promise.all([
        import(`../../language/trackOrder/${language}/trackOrder`),
        import(`../../language/myAccount/${language}/myAccount`),
        import(`../../language/needHelp/${language}/needHelp`)
      ]);

    this.setBreadCrumb(
      saveBreadcrumb,
      language,
      null,
      trackOrderResponse.trackOrderTranslation.trackShipment,
      false,
      settings
    );

    this.setState({
      trackOrderTranslation: trackOrderResponse.trackOrderTranslation,
      myAccountTranslation: myAccountTranslation.myAccountTranslation,
      needHelpTranslation: needHelpTranslation.needHelp
    });
  };

  setBreadCrumb = (
    saveBreadcrumb,
    language,
    _accordionTitle,
    trackShipment,
    resetBreadCrumb,
    settings
  ) => {
    resetBreadCrumb && resetBreadCrumb();
    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "trackShipment",
        modelData: {
          trackShipmentName: trackShipment,
          trackShipmentOptName: !!_accordionTitle && _accordionTitle,
          trackShipmentUrl: `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/track-shipment`
        }
      }) &&
      saveBreadcrumb({
        modelName: "product",
        modelData: {
          productName: "",
          productUrl: ""
        }
      }) &&
      saveBreadcrumb({
        modelName: "productList",
        modelData: {
          productListName: "",
          productListUrl: ""
        }
      }) &&
      saveBreadcrumb({
        modelName: "category",
        modelData: {
          categoryName: "",
          categoryUrl: ""
        }
      });
  };

  render() {
    const {
      trackOrderTranslation,
      myAccountTranslation,
      needHelpTranslation,
      email,
      orderId,
      showContent,
      isOrderHandedToShipper
    } = this.state;
    const {
      orderData,
      language,
      trackOrderR,
      settings,
      staticContent,
      userInfo
    } = this.props;

    const isOrderCancelled =
      has(orderData, "items") &&
      orderData.items.some((item, idx) => {
        return item.quantity === 0 && item.cancelQty > item.quantity;
      });

    const isTimeLineAvailable =
      trackOrderR.orderId?.includes(orderId) && !isOrderHandedToShipper;

    return (
      <Page
        title={seoTranslation.trackOrderTitle}
        meta={{
          [language]: {
            metaDescription: seoTranslation.defaultPageDescription
          }
        }}
      >
        <div
          className={`track_order_container ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <Breadcrumb />
          <div className="container track_heading">
            {!(trackOrderR.orderId && trackOrderR.orderId.includes(orderId)) ? (
              <div className="page_heading">
                <h2>{trackOrderTranslation.tractYourOrder}</h2>
                {userInfo && !userInfo.userLoggedIn ? (
                  <p>
                    {trackOrderTranslation.alreadyHaveAnAccount}
                    <Link
                      to={`/${language}-${
                        (settings &&
                          settings.countrySHORT &&
                          settings.countrySHORT.toLowerCase()) ||
                        "ae"
                      }/login/`}
                      onClick={() => GAService.auth.trackSignCTA(true)}
                    >
                      <button className="round_btn form_black_btn width_auto">
                        {trackOrderTranslation.signIn}
                      </button>
                    </Link>
                  </p>
                ) : null}
              </div>
            ) : null}
            <GuestTrackingOrder
              guestOrderTrack
              trackOrderTranslation={trackOrderTranslation}
              handleTextChange={this.handleTextChange}
              handleOnClickTrackOrder={this.handleOnClickTrackOrder}
              orderId={orderId}
              email={email}
              trackOrderGuest={trackOrderR.orderId}
              showContent={showContent}
              trackOrderR={trackOrderR}
            />
          </div>
          {isTimeLineAvailable ? (
            <div className="guest_order_wrapper container padding-web-0">
              <div className="product_order_timeline">
                <TrackOrderTimeline trackOrder={trackOrderR} />
                {isOrderCancelled && (
                  <div className="cancel_orders">
                    {trackOrderTranslation.orderCancelled}
                  </div>
                )}
              </div>
              <div className="product_order_detail">
                <div className="product_order_data">
                  <div className="product_img">
                    {orderData &&
                    orderData.items &&
                    orderData.items.length > 1 ? (
                      <div>
                        <RoundOrderImg
                          imgSrc={`${orderData.items[0].image}`}
                          intialClass={`initialProduct`}
                        />
                        <RoundOrderImg
                          imgSrc={`${orderData.items[1].image}`}
                          overlay={true}
                          productCount={orderData.items.length - 2}
                          myAccountTranslation={myAccountTranslation}
                        />
                      </div>
                    ) : (
                      <div>
                        <RoundOrderImg
                          imgSrc={`${
                            orderData &&
                            orderData.items &&
                            orderData.items[0].image
                          }`}
                          intialClass={`initialProduct`}
                        />
                      </div>
                    )}
                  </div>
                  <div className="order_id">
                    <h4>
                      <span>{trackOrderTranslation.orderId}:</span>{" "}
                      {trackOrderR.orderId}
                    </h4>
                  </div>
                  <div className="order_placed">
                    <h4>{trackOrderTranslation.orderPlacedOn}:</h4>
                    <p>
                      {moment.utc(trackOrderR.orderPlacedDate).format("LLL")}
                    </p>
                  </div>
                  {Boolean(trackOrderR.trackingNumber) && (
                    <div className="order_tracking_number">
                      <h4>{trackOrderTranslation.trackNumber}:</h4>
                      <p>{trackOrderR.trackingNumber}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <NeedHelpFooter
            needHelpTranslation={needHelpTranslation}
            helpDataTiles={HELP_DATA_TILES}
            staticContent={staticContent}
            language={language}
            settings={settings}
          />
        </div>
      </Page>
    );
  }
}
const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  routeSlug: selectRouteSlug(reduxState),
  staticContent: reduxState.footerReducer.staticContent,
  trackOrderR: reduxState.myAccountReducer.trackOrder,
  orderData: reduxState.orderReducer.order,
  countryName:
    reduxState.common.settings && reduxState.common.settings.countryName,
  userInfo: reduxState.authReducer,
  mobileCountryCode:
    reduxState.common.settings && reduxState.common.settings.mobileCountryCode,
  configCdnDomainSettings: reduxState.common.configSettings.cdnDomain
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  signInModal: bindActionCreators(signInModal, dispatch),
  trackOrder: bindActionCreators(trackOrder, dispatch),
  clearTrackOrder: bindActionCreators(clearTrackOrder, dispatch),
  getOrderData: bindActionCreators(getOrderData, dispatch),
  getOrderStatusData: bindActionCreators(getOrderStatusData, dispatch),
  trackOrderResponse: bindActionCreators(trackOrderResponse, dispatch),
  saveBreadcrumb(navData) {
    dispatch(saveBreadcrumb(navData));
  },
  resetBreadCrumb() {
    dispatch(resetBreadCrumb());
  },
  resetAPIError: bindActionCreators(resetAPIError, dispatch),
  showPageLoader: bindActionCreators(showPageLoader, dispatch),
  hidePageLoader: bindActionCreators(hidePageLoader, dispatch)
});

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(TrackOrder);
