import React, { memo, useMemo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import replace from "lodash/replace";
import { COUNTRY_NAME_SEO, PLP_CMS_DATA_SLUGS } from "../../../constants";
import { selectCountryCode } from "../../../util/selectors";
import HtmlParser from "../../html-parser";
import ShowMoreHtmlWrapper from "../dynamic-show-more-html-wrapper";

const DynamicHTMLBanner = ({ content, language, bannerSlug }) => {
  const html = content?.[language]?.html;
  if (!html) return null;

  const styles = {
    ...content.styles,
    maxWidth: content.width?.value || "100%",
    margin: `${content.styles.marginTop}px auto ${content.styles.marginBottom}px`
  };
  const bottomSection = bannerSlug === PLP_CMS_DATA_SLUGS.BOTTOM_SECTION;

  const countryCode = useSelector(selectCountryCode);

  const updatedHTML = useMemo(
    () => replace(html, new RegExp(COUNTRY_NAME_SEO, "g"), countryCode),
    [html, countryCode]
  );

  if (content.isShowMoreButtonVisible) {
    return (
      <ShowMoreHtmlWrapper
        showMoreContentHeight={content.showMoreContentHeight}
      >
        <div className="top_banner_wrapper" style={styles} data-id={bannerSlug}>
          <div className={cn("container", { top_container: !bottomSection })}>
            <HtmlParser html={updatedHTML} />
          </div>
        </div>
      </ShowMoreHtmlWrapper>
    );
  }

  return (
    <div className="top_banner_wrapper" style={styles} data-id={bannerSlug}>
      <div className={cn("container", { top_container: !bottomSection })}>
        <HtmlParser html={updatedHTML} />
      </div>
    </div>
  );
};

export default memo(DynamicHTMLBanner);
