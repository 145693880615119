import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import {
  HELP_DATA_TILES,
  NEED_HELP_TOPIC_MAP,
  TILE_DATA
} from "../../constants";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import {
  changeLanguage,
  resetBreadCrumb,
  saveBreadcrumb
} from "../../redux/actions/common.action";
import Breadcrumb from "../../components/breadcrumb";
import HelpAccordion from "./accordion";
import NeedHelpCategories from "./helpCategories";
import NeedHelpFooter from "./footer";
import NeedHelpHeader from "./header";
import Page from "../../components/page";
import RightArrowImg from "../../images/arrow-point-to-right.svg";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getNeedHelpData } from "../../redux/actions/needHelp.action";
import { isServer } from "../../util";
import { needHelp } from "../../language/needHelp/en/needHelp";
import { seoTranslation } from "../../language/seo/en/seo";
import {
  withAnalyticPageBrowseTracking,
  withLanguageFromUrl,
  withPageTypeTracking
} from "../../hocs";
import ssrConfig from "./helpDetailsSsrConfig";
import { GAService } from "../../services/GA-service";

class HelpDetails extends Component {
  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      needHelpTranslation: needHelp,
      openIndex: null,
      _accordionTitle: "",
      seoTranslated: seoTranslation[urlLang]
    };
  }

  UNSAFE_componentWillMount() {
    const { currentLanguage, handleLanguageChange } = this.props;

    if (
      !isServer &&
      window.location.search &&
      window.location.search.includes("op=")
    ) {
      let _search = window.location.search.split("?"),
        _openIndex;
      _search &&
        _search.length &&
        _search.map(searchParam => {
          if (searchParam.includes("op=")) {
            _openIndex = +searchParam.replace("op=", "");
            this.setState({ openIndex: _openIndex });
          }
          return null;
        });
    }

    handleLanguageChange(currentLanguage);
    this.getlanguage(currentLanguage);
  }

  componentDidMount() {
    const {
      saveBreadcrumb,
      language,
      settings,
      resetBreadCrumb,
      getNeedHelpData,
      fullLanguage
    } = this.props;
    resetBreadCrumb && resetBreadCrumb();
    this.setBreadCrumb(
      saveBreadcrumb,
      language,
      null,
      this.state.needHelpTranslation.needHelp,
      false,
      settings
    );
    settings &&
      settings.countryId &&
      getNeedHelpData(fullLanguage, settings.countryId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
      nextProps.settings &&
        nextProps.settings.countryId &&
        this.props.getNeedHelpData(
          nextProps.fullLanguage,
          nextProps.settings.countryId
        );
    } else if (
      (nextProps.settings && nextProps.settings.countryId) !==
      (this.props.settings && this.props.settings.countryId)
    ) {
      this.getlanguage(nextProps.language);
      nextProps.settings &&
        nextProps.settings.countryId &&
        this.props.getNeedHelpData(
          nextProps.fullLanguage,
          nextProps.settings.countryId
        );
    }
  }

  getlanguage = language => {
    const languageResponse = import(
      `../../language/needHelp/${language}/needHelp`
    );
    languageResponse.then(response => {
      this.setState({
        needHelpTranslation: response.needHelp
      });
    });
    const seoResponse = import(`../../language/seo/${language}/seo`);
    seoResponse.then(response => {
      this.setState({ seoTranslated: response.seoTranslation });
    });
  };

  setBreadCrumb = (
    saveBreadcrumb,
    language,
    _accordionTitle,
    needHelp,
    resetBreadCrumb,
    settings
  ) => {
    resetBreadCrumb && resetBreadCrumb();
    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "needHelp",
        modelData: {
          needHelpName: needHelp,
          needHelpOptName: !!_accordionTitle && _accordionTitle,
          needHelpUrl: `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/need-help/`
        }
      }) &&
      saveBreadcrumb({
        modelName: "product",
        modelData: {
          productName: "",
          productUrl: ""
        }
      }) &&
      saveBreadcrumb({
        modelName: "productList",
        modelData: {
          productListName: "",
          productListUrl: ""
        }
      }) &&
      saveBreadcrumb({
        modelName: "category",
        modelData: {
          categoryName: "",
          categoryUrl: ""
        }
      });
  };

  handleSetTitle = (title, needHelp) => {
    const { saveBreadcrumb, language, settings } = this.props;
    const { needHelpTranslation } = this.state;
    title &&
      this.setBreadCrumb(
        saveBreadcrumb,
        language,
        needHelpTranslation[title],
        needHelp,
        false,
        settings
      );
  };

  render() {
    const { needHelpTranslation, _accordionTitle, seoTranslated } = this.state;
    const {
      language,
      needHelpContent,
      staticContent,
      settings,
      match,
      history
    } = this.props;
    let flag = false,
      title = "frequentlyAskedQuestions",
      _topicObject = {};

    Object.keys(NEED_HELP_TOPIC_MAP).map(topic => {
      if (match.url.includes(topic)) {
        title = NEED_HELP_TOPIC_MAP[topic];
        _topicObject = needHelpContent[NEED_HELP_TOPIC_MAP[topic]];
        flag = true;
        if (_accordionTitle !== title) {
          this.handleSetTitle(title, needHelpTranslation.needHelp);
        }
      }
      return null;
    });

    if (!flag) {
      history.push("/need-help/");
    }

    let _accordion = (_topicObject && _topicObject.content) || [];

    const meta = _topicObject && _topicObject.meta;
    let seoTitle = "";
    let metaInfo = {};
    const isMeta = meta && meta.metaDescription && meta.title;
    if (isMeta) {
      let metaDescInfo = `${meta.metaDescription.trim()}`;
      seoTitle = `${meta.title.trim()} | ${seoTranslated.bfl}`;
      metaInfo = language
        ? {
            [language]: {
              ...meta,
              metaDescription: metaDescInfo
            }
          }
        : {};
    }
    return (
      <Page
        title={seoTitle}
        meta={metaInfo}
        language={language}
        id="need-help/order-issues"
      >
        <Fragment>
          <Breadcrumb />
          <div
            className={`help_container container ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <NeedHelpHeader needHelpTranslation={needHelpTranslation} />
            <div className="qa_container">
              <div className="faq_section">
                <h4>{needHelpTranslation[title]}</h4>
                {_accordion &&
                  _accordion.length > 0 &&
                  _accordion.map((tile, index) => (
                    <HelpAccordion
                      tile={tile}
                      key={index}
                      accordionHeading={needHelp[title]}
                    />
                  ))}
              </div>
              <div className="topics_section">
                <h4>{needHelpTranslation.topics}</h4>
                <ul>
                  {TILE_DATA &&
                    TILE_DATA.map((category, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          GAService.common.trackNeedHelpViewAllFaqClick(
                            needHelp[category.data]
                          )
                        }
                      >
                        <Link
                          to={`/${language}-${
                            (settings &&
                              settings.countrySHORT &&
                              settings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }${category.link}`}
                        >
                          <h5>
                            {needHelpTranslation[category.data]}
                            <img src={RightArrowImg} alt="right-arrow" />
                          </h5>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <NeedHelpCategories needHelpTranslation={needHelpTranslation} />
            </div>
            <NeedHelpFooter
              needHelpTranslation={needHelpTranslation}
              helpDataTiles={HELP_DATA_TILES}
              staticContent={staticContent}
              language={language}
              settings={settings}
            />
          </div>
        </Fragment>
      </Page>
    );
  }
}

HelpDetails.need = ssrConfig;

const mapStateToProps = state => ({
  language: state.common.language,
  needHelpContent: state.needHelpReducer.needHelpContent,
  settings: state.common.settings,
  fullLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  staticContent: state.footerReducer.staticContent
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  getNeedHelpData: bindActionCreators(getNeedHelpData, dispatch),
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch),
  saveBreadcrumb(navData) {
    dispatch(saveBreadcrumb(navData));
  },
  resetBreadCrumb() {
    dispatch(resetBreadCrumb());
  }
});

export default compose(
  withRouter,
  withLanguageFromUrl,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(HelpDetails);
