import React from "react";
import basket from "../../../images/basket.svg";
import merge from "../../../images/merge.svg";
import discount from "../../../images/discount.svg";

const MyWalletUsp = ({
  myAccountTranslation: {
    InstantCheckout,
    InstantCheckoutSubTitle,
    ConsolidatedMoney,
    ConsolidatedMoneySubTitle,
    MoreBenifits,
    MoreBenifitsSubTitle
  }
}) => {
  return (
    <div className="wallet_usp_wrapper">
      <div className="usp_card">
        <p className="icon_wrap">
          <span>
            <img src={basket} alt="checkout-basket" />
          </span>
        </p>
        <p className="usp_title_text">{InstantCheckout}</p>
        <p className="usp_sub_title_text">{InstantCheckoutSubTitle}</p>
      </div>
      <div className="usp_card bdr">
        <p className="icon_wrap">
          <span>
            <img src={merge} alt="Wallet Merge" />
          </span>
        </p>
        <p className="usp_title_text">{ConsolidatedMoney}</p>
        <p className="usp_sub_title_text">{ConsolidatedMoneySubTitle}</p>
      </div>
      <div className="usp_card">
        <p className="icon_wrap">
          <span>
            <img src={discount} alt="all-discount" />
          </span>
        </p>
        <p className="usp_title_text">{MoreBenifits}</p>
        <p className="usp_sub_title_text">{MoreBenifitsSubTitle}</p>
      </div>
    </div>
  );
};

export default MyWalletUsp;
