import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import cn from "classnames";
import * as Sentry from "@sentry/browser";

import {
  COUNTRIES_WITH_CIVIL_ID,
  MAX_CIVIL_ID_LENGTH,
  MIN_CIVIL_ID_LENGTH,
  PAYMENT_GATEWAYS,
  PAYMENT_MODES
} from "../../../constants";

import { TabbyService } from "../../../services";

import { selectCountrySlug, selectLanguageSlug } from "../../../util/selectors";

import { confirmOrder } from "../../../redux/actions/order.action";

class TabbyButton extends Component {
  onStartPayment = async () => {
    const {
      checkoutPage,
      history,
      confirmOrder,
      language,
      getSessionStatus,
      currency,
      personalId,
      changePersonalIdStatus,
      countrySlug
    } = this.props;

    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(countrySlug);
    const isPersonalIdInvalid =
      personalId.length < MIN_CIVIL_ID_LENGTH ||
      personalId.length > MAX_CIVIL_ID_LENGTH;

    if (isPersonalIdRequired && isPersonalIdInvalid) {
      return changePersonalIdStatus();
    }
    let orderBody;
    let createdOrderResponse;
    let tabbyPaymentResult;
    try {
      checkoutPage.showLoader();

      orderBody = this.getOrderBody();

      createdOrderResponse = await confirmOrder(orderBody, history, language);

      const isPossibleToBuy =
        get(createdOrderResponse, "data.success") &&
        orderBody.paymentMode &&
        orderBody.transactionAmount !== 0;

      if (!isPossibleToBuy) {
        checkoutPage.setState({
          orderFailedText: createdOrderResponse.data.message
        });
        checkoutPage.hideLoader();

        return;
      }

      this.startTabbySession(orderBody, createdOrderResponse.data).then(
        response => {
          if (response === "rejected") {
            checkoutPage.hideLoader();
            getSessionStatus && getSessionStatus(response);
          }
        }
      );
    } catch (error) {
      const errorData = {
        type: "TABBY PAYMENT FAIL",
        error,
        orderBody,
        createdOrderResponse: createdOrderResponse && createdOrderResponse.data,
        tabbyPaymentResult
      };

      console.error(errorData);

      Sentry.captureException(errorData);

      checkoutPage.hideLoader();
      checkoutPage.redirectToErrorPage();
    }
  };

  startTabbySession = async (orderBody, createdOrderResponse) => {
    const {
      order,
      language,
      countrySlug,
      user,
      currency,
      deliveryCharges,
      shippingAddress
    } = this.props;
    const params = {
      order,
      language,
      countrySlug,
      user,
      currency,
      deliveryCharges,
      orderBody,
      createdOrderResponse,
      shippingAddress
    };
    return await TabbyService.startTabbySession(params);
  };

  getOrderBody() {
    const { checkoutPage, countrySlug, personalId } = this.props;
    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(countrySlug);
    const orderBody = checkoutPage.getOrderBody();

    const enhancedOrderBody = {
      ...orderBody,
      paymentGateway: PAYMENT_GATEWAYS.TABBY,
      paymentExtraInfo: orderBody.paymentMode.includes(
        PAYMENT_MODES.TABBY_PAY_LATER
      )
        ? PAYMENT_MODES.TABBY_PAY_LATER
        : PAYMENT_MODES.TABBY_PAY_INSTALLMENT,
      paymentMode: orderBody.paymentMode.map(mode => {
        const tabbyModes = [
          PAYMENT_MODES.TABBY_PAY_LATER,
          PAYMENT_MODES.TABBY_PAY_INSTALLMENT
        ];

        if (tabbyModes.includes(mode)) return PAYMENT_MODES.TABBY;

        return mode;
      }),
      ...(isPersonalIdRequired && { personalId })
    };

    return enhancedOrderBody;
  }

  render() {
    const {
      buttonText,
      className,
      stepsCompleted,
      tabbyPaymentError,
      onButtonClick
    } = this.props;

    return (
      <button
        className={cn(className, {
          disable_order_btn: !stepsCompleted
        })}
        onClick={() => {
          this.onStartPayment();
          onButtonClick();
        }}
      >
        {buttonText}
      </button>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer,
  order: state.checkoutReducer.checkoutData,
  countrySlug: selectCountrySlug(state),
  language: selectLanguageSlug(state),
  currency: get(state, "common.settings.currencyCode")
});

const mapDispatchToProps = { confirmOrder };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TabbyButton);
