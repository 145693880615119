import React from "react";
const ContactInfo = ({ orderSuccessTranslation, customerCare }) => (
  <p className="contact_info">
    {orderSuccessTranslation.contactText}
    <a href={`mailto:${customerCare && customerCare.csEmail}`}>
      {" "}
      {customerCare && customerCare.csEmail}
    </a>
    {orderSuccessTranslation.or}
    <span>
      <a href={`tel:${customerCare && customerCare.csNumber}`}>
        {customerCare && customerCare.csNumber}
      </a>{" "}
      {customerCare && customerCare.csTime}.
    </span>
  </p>
);

export default ContactInfo;
