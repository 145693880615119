export const navListTranslation = {
  navList: [
    {
      id: 1,
      subRouteName: "account-overview",
      "nav-text": "Account Overview",
      "icon-class": "my-account-avtar",
      hideInMobile: true
    },
    {
      id: 2,
      subRouteName: "my-details",
      "nav-text": "My Details",
      "icon-class": "my-account-detail",
      hideInMobile: true
    },
    {
      id: 3,
      subRouteName: "my-orders",
      "nav-text": "My Orders",
      "icon-class": "my-account-order"
    },
    {
      id: 4,
      subRouteName: "my-address-book",
      "nav-text": "My Address Book",
      "icon-class": "my-account-locationFlag"
    },
    {
      id: 5,
      subRouteName: "my-wallet",
      "nav-text": "My Wallet",
      "icon-class": "my-account-wallet"
    },
    {
      id: 6,
      subRouteName: "loyalty-program",
      "nav-text": "Loyalty Program",
      "icon-class": "my-account-gift"
    },
    {
      id: 7,
      subRouteName: "saved-cards",
      "nav-text": "Saved Cards",
      "icon-class": "my-account-saveCard"
    },
    {
      id: 8,
      subRouteName: "redeem-gift-card",
      "nav-text": "Redeem Gift Card",
      "icon-class": "my-account-redeem-gift-card-icon",
      checkIsEnabledBy: "enableRedeemGiftCard"
    },
    // task BFL-6503 Need to disable “Social Account” section from the my account page, until the exact functionality is confirmed.
    // {
    //   id: 9,
    //   subRouteName: "social-accounts",
    //   "nav-text": "Social Accounts",
    //   "icon-class": "my-account-handLike"
    // },
    {
      id: 10,
      subRouteName: "need-help",
      "nav-text": "Need Help",
      "icon-class": "my-account-help-msg"
    },
    {
      id: 11,
      subRouteName: "sign-out",
      "nav-text": "Sign Out",
      "icon-class": "my-account-arrowLeft"
    }
  ]
};
