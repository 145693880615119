import React, { Component, Fragment } from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import cn from "classnames";
import { isMobile, isTouchScreen } from "../../../util";
import { selectLanguageSlug } from "../../../util/selectors";
import { LANGUAGE_SHORT } from "../../../constants";
import CircleRadio from "../../circle-radio";

class ProductSize extends Component {
  handleSizeButtonColor = e => {
    this.setState({ sizeButtonColor: e });
  };

  isSizePresentInCart = size => {
    const { id, cartItems, item, colorSizeOption } = this.props;
    if (item && item.IsMultisize) {
      let _sizeId =
        colorSizeOption &&
        colorSizeOption.find(
          items => items.sizeLabel && items.sizeLabel.trim() === size
        ).sizeId;
      return (
        cartItems &&
        cartItems.length &&
        cartItems.find(item => item.id === id && item.sizeId === _sizeId)
      );
    } else {
      return (
        cartItems &&
        cartItems.length &&
        cartItems.find(item => item.id === id && item.size === size)
      );
    }
  };
  render() {
    const {
      colorSizeOption,
      onSizeChange,
      selectedSize,
      showStock,
      translation,
      showStockMobile,
      handleMultipleSizesModal,
      item,
      selectedQty,
      presentWithoutQuantity,
      firstSizeType,
      handleAddToCart,
      allowedQuantity,
      defaultAddToCart,
      handleSizeSelection,
      wrapperRef,
      languageSlug,
      productSizeExtraData
    } = this.props;

    const isMobileAny = isMobile.any();

    let sizeList =
      colorSizeOption &&
      colorSizeOption.map((size, index) => {
        const isPlural = size.sizeStock > 1;
        let piecesLeftText = `${translation.pieces} ${size.sizeStock} ${
          translation[isPlural ? "leftPlural" : "leftSingular"] ||
          translation.left
        } `;

        if (languageSlug === LANGUAGE_SHORT.en) {
          piecesLeftText = `${size.sizeStock} ${
            translation[isPlural ? "piecesLeft" : "pieceLeft"]
          }`;
        }

        const isSizeChecked =
          selectedSize &&
          selectedSize.trim() === (size.sizeLabel && size.sizeLabel.trim());
        const outOfStock = size?.sizeStock <= 0;

        return size.sizeLabel ? (
          <li
            key={index}
            onClick={e => {
              if (size.sizeStock && size.sizeStock > 0) {
                const trimmedSizeLabel =
                  size.sizeLabel && size.sizeLabel.trim();
                onSizeChange &&
                  onSizeChange(
                    trimmedSizeLabel,
                    size.sizeStock,
                    defaultAddToCart,
                    size.sizeId
                  );
                handleSizeSelection && handleSizeSelection(true);
                this.handleSizeButtonColor(
                  size.sizeLabel && size.sizeLabel.trim()
                );
                defaultAddToCart &&
                  handleAddToCart(
                    item,
                    trimmedSizeLabel,
                    selectedQty,
                    firstSizeType || trimmedSizeLabel || "",
                    presentWithoutQuantity,
                    allowedQuantity
                  );
                defaultAddToCart && handleMultipleSizesModal(false);
                showStockMobile && handleMultipleSizesModal();
              }
            }}
            className={cn({
              size_box_color: isSizeChecked,
              "size_not_available not_clickable": outOfStock
            })}
          >
            {isMobileAny && (
              <div className="circle-radio-wrapper">
                <CircleRadio
                  isChecked={isSizeChecked}
                  index={index}
                  name={firstSizeType}
                />
              </div>
            )}
            {size.sizeLabel && size.sizeLabel.trim()}{" "}
            {showStock && size.sizeStock <= 5 && size.sizeStock > 0 && (
              <p className="size_quantity">{` ${size.sizeStock} ${
                translation[isPlural ? "leftPlural" : "leftSingular"] ||
                translation.left
              }`}</p>
            )}
            {showStockMobile && !showStock ? (
              this.isSizePresentInCart(
                size.sizeLabel && size.sizeLabel.trim()
              ) ? (
                <p>{translation.alreadyAddedToCart}</p>
              ) : size.sizeStock <= 5 && size.sizeStock > 0 ? (
                <p>{piecesLeftText}</p>
              ) : size.sizeStock < 5 ? (
                <p>{translation.soldOut}</p>
              ) : null
            ) : null}
          </li>
        ) : null;
      });
    return (
      <Fragment>
        {colorSizeOption && colorSizeOption.length ? (
          <ul
            className="size_list"
            onClick={e => e.preventDefault()}
            ref={wrapperRef}
          >
            {productSizeExtraData}
            {sizeList}
          </ul>
        ) : null}
      </Fragment>
    );
  }
}

export const SwiperProductSize = props => {
  const { displaySizes, gridSize, isMobileView } = props;

  if (!(displaySizes && displaySizes.length)) return null;

  const swiperParams = {
    dots: false,
    slidesPerView: "auto",
    freeMode: true,
    grabCursor: true,
    ...(!isTouchScreen() && {
      spaceBetween: 8,
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      }
    })
  };

  return (
    <ul
      className={cn("size_list", {
        smaller_list: gridSize > 4 || isMobileView
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Swiper {...swiperParams}>
        {displaySizes.map(
          sizes =>
            sizes.size && <li key={sizes.size}> {sizes?.size?.trim()} </li>
        )}
      </Swiper>
    </ul>
  );
};

const mapStateToProps = state => ({
  languageSlug: selectLanguageSlug(state)
});

export default connect(mapStateToProps)(ProductSize);
