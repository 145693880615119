import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCountryList,
  hidePageLoader,
  showPageLoader
} from "../../../redux/actions/common.action";
import {
  cancelOrder,
  setCancelOrder,
  resetCancelOrder,
  trackOrder
} from "../../../redux/actions/myAccount.action";

import AnalyticService from "../../../services/analytic-service";

import OrderCard from "../../order/order-version-return";
import CancelOrderAlert from "../../cancel-order-alert";
import ModalBFL from "../../modal/modal";
import OrderDetail from "../myAcc-order-detail-version-return";
import DeliveryDetails from "../myAcc-delivery-details";
import TrackOrderTimeline from "../../track-order-timelines";
import {
  checkIsOrderHandedToShipper,
  getReturnWebUrl,
  getTrackOrderWebUrl
} from "../../../util/order";
import { pretendClick } from "../../../util/routing";
import { LOADER_TYPES } from "../../../constants";
import { GAService } from "../../../services/GA-service";

const deliveryTypes = ["DELIVERY_ADDRESS", "EXPRESS_DELIVERY", "SELF_PICKUP"];

class RecentOrderReturnVersion extends Component {
  state = {
    cancelOrderModal: false,
    accordionActive: false,
    cancelReason: "",
    showTrackOrder: false,
    currentTrackOrderData: null,
    isOrderHandedToShipper: false
  };
  handleSelectChange = selected => {
    const { setCancelOrder, email, orderId, order } = this.props;
    this.setState({ cancelReason: selected });
    setCancelOrder("reason", selected);
    setCancelOrder("orderId", orderId);
    setCancelOrder("email", email);
    const requestArr = [];
    order.items.map(obj => {
      requestArr.push({ ...obj, productId: obj.pId });
      return null;
    });
    setCancelOrder("orderItems", requestArr);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activePage } = this.props;
    if (nextProps.activePage !== activePage) {
      this.setState({
        accordionActive: false,
        showTrackOrder: false
      });
    }
  }

  handleTrackOrder = async () => {
    const { email, orderId, trackOrder, showPageLoader, hidePageLoader } =
      this.props;

    this.setState({
      showTrackOrder: !this.state.showTrackOrder,
      currentTrackOrderData: null
    });
    if (!this.state.showTrackOrder) {
      showPageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });

      const { data } = await trackOrder({ email: email, orderId: orderId });
      if (data.success) {
        GAService.checkout.trackTrackOrder(orderId);
      }
      const isOrderHandedToShipper = checkIsOrderHandedToShipper(
        data?.orderStatusDetails
      );
      if (isOrderHandedToShipper) {
        this.handleOrderHandedStatus(data);
        this.setState({ showTrackOrder: false });
      }
      hidePageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
      this.setState({
        currentTrackOrderData: data,
        isOrderHandedToShipper
      });
    }
  };

  handleOrderHandedStatus = trackOrderDetails => {
    const { configCdnDomainSettings, language } = this.props;
    const trackOrderWebUrl = getTrackOrderWebUrl({
      trackOrderWebUrl: configCdnDomainSettings?.trackOrderWebUrl,
      trackOrderDetails,
      language
    });

    const newWindow = window.open(trackOrderWebUrl);
    if (
      !newWindow ||
      newWindow.closed ||
      typeof newWindow.closed === "undefined"
    ) {
      pretendClick(trackOrderWebUrl);
    }
  };

  handleTextArea = e => {
    const { setCancelOrder } = this.props;
    setCancelOrder("comment", e.target.value);
  };
  toggleCancelOrderModal = value => {
    const { orderId } = this.props;
    this.setState({ cancelOrderModal: value, cancelReason: "" });
    if (!this.state.cancelOrderModal) {
      this.props.resetCancelOrder();
    }
    if (value) {
      GAService.product.trackOrderCancellationStart(orderId);
    }
  };
  validateCancelOrderForm = e => {
    const { OrderDetailR, setCancelOrder } = this.props;
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    _OrdersKey.forEach(key => {
      setCancelOrder(key, OrderDetailR.cancelOrder[key]);
    });
  };
  handleCancelOrder = () => {
    const {
      cancelOrderAction,
      OrderDetailR,
      order,
      language,
      activePage,
      authReducer,
      commonSettings
    } = this.props;
    this.validateCancelOrderForm();
    OrderDetailR.errors.quantity.valid = true;
    OrderDetailR.errors.quantity.touched = true;
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    if (
      _OrdersKey.every(
        key =>
          OrderDetailR.errors[key].valid && OrderDetailR.errors[key].touched
      )
    ) {
      const cancelOrderRequestBody = {
        ...OrderDetailR.cancelOrder,
        quantity: parseInt(OrderDetailR.cancelOrder.quantity),
        currencyCode: order.CurrencyCode,
        lang: language
      };
      cancelOrderAction(cancelOrderRequestBody, activePage).then(() => {
        this.toggleCancelOrderModal(false);
      });
      AnalyticService.order.trackMulipleOrderCancellation({
        orderDetails: OrderDetailR,
        authReducer,
        commonSettings
      });
    }
  };
  handleShowAccordion = async () => {
    const { accordionActive } = this.state;
    const { getCountryList, language } = this.props;

    if (!accordionActive) {
      await getCountryList(language);
    }

    this.setState({ accordionActive: !this.state.accordionActive });
  };
  handleReturnOrder = () => {
    const { language, order, configCdnDomainSettings } = this.props;
    const returnOrderWebUrl = getReturnWebUrl({
      returnWebUrl: configCdnDomainSettings?.returnWebUrl,
      order,
      language
    });
    window.open(returnOrderWebUrl);
  };

  render() {
    const {
      accordion,
      orderId,
      activePage,
      totalAmount,
      orderPlaced,
      myAccountTranslation,
      language,
      handleAction,
      order,
      OrderDetailR,
      deliveryOptions,
      countryData,
      configCdnDomainSettings
    } = this.props;
    const {
      accordionActive,
      cancelOrderModal,
      cancelReason,
      showTrackOrder,
      currentTrackOrderData,
      isOrderHandedToShipper
    } = this.state;
    let deliveryOption = "";
    if (deliveryOptions) {
      if (order.deliveryType === deliveryTypes[0]) {
        deliveryOption = deliveryOptions[0];
      } else if (order.deliveryType === deliveryTypes[1]) {
        deliveryOption = deliveryOptions[1];
      } else {
        deliveryOption = deliveryOptions[2];
      }
    }

    let AllOrderCancelled = true;
    order &&
      order.items.length &&
      order.items.forEach(item => {
        if (item.quantity !== 0) {
          AllOrderCancelled = false;
        }
      });

    const isTimeLineAvailable =
      !isOrderHandedToShipper && showTrackOrder && currentTrackOrderData;

    return (
      <div className="margin-bottom29" key={orderId}>
        <div className="recent_order_Wrapper">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <OrderCard
                orderId={orderId}
                totalAmount={totalAmount}
                orderPlaced={orderPlaced}
                myAccountTranslation={myAccountTranslation}
                orderItems={order.items || []}
                orderStatus={order.status}
                orderMessage={order.f_message}
              />
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="order_action_wrapper">
                {accordion ? (
                  <>
                    <div
                      className="form_black_border_btn track_order_btn"
                      onClick={this.handleTrackOrder}
                    >
                      {myAccountTranslation.trackOrder}
                    </div>
                    {order.action === "CANCEL" && (
                      <div
                        className="form_black_border_btn cancel_order_btn"
                        onClick={() => {
                          this.toggleCancelOrderModal(true);
                        }}
                      >
                        {myAccountTranslation.cancelOrder}
                      </div>
                    )}
                    {order.action === "RETURN" && (
                      <div
                        className="form_black_border_btn cancel_order_btn"
                        onClick={this.handleReturnOrder}
                      >
                        {myAccountTranslation.return}
                      </div>
                    )}
                  </>
                ) : (
                  <button onClick={handleAction}>
                    <i className="order_icon_arrowRight" />
                  </button>
                )}
              </div>
            </div>
            {accordion && (
              <span
                className={`accordian_trigger_icon ${
                  accordionActive ? "active" : ""
                }`}
                onClick={this.handleShowAccordion}
              />
            )}
          </div>
          <ModalBFL
            showModal={cancelOrderModal}
            modalClass={`generic_modal cancel_order_modal ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <button
              className="cross_btn"
              onClick={e => this.toggleCancelOrderModal(false)}
            >
              &times;
            </button>
            <CancelOrderAlert
              myAccountTranslation={myAccountTranslation}
              handleCancelOrder={this.handleCancelOrder}
              cancelReason={cancelReason}
              handleTextArea={this.handleTextArea}
              handleSelectChange={this.handleSelectChange}
              OrderDetail={OrderDetailR}
              toggleCancelOrderModal={() => this.toggleCancelOrderModal(false)}
            />
          </ModalBFL>
        </div>
        {isTimeLineAvailable && (
          <TrackOrderTimeline
            trackOrder={currentTrackOrderData}
            handleTrackOrder={this.handleTrackOrder}
            showCrossIcon={true}
          />
        )}
        {accordionActive && (
          <>
            {order.items.map(orderItem => (
              <OrderDetail
                key={orderItem.pId}
                myAccountTranslation={myAccountTranslation}
                orderItem={orderItem}
                order={order}
                activePage={activePage}
              />
            ))}
            <DeliveryDetails
              myAccountTranslation={myAccountTranslation}
              order={order}
              countryName={
                order.shippingCountry &&
                countryData.find(
                  country => country.countryCode === order.shippingCountry
                )?.countryName
              }
              countryCode={
                order.shippingCountry &&
                countryData.find(
                  country => country.countryCode === order.shippingCountry
                )?.mobileCountryCode
              }
            />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  email: state.authReducer.email,
  OrderDetailR: state.myAccountReducer.OrderDetail,
  language: state.common.language,
  trackOrderR: state.myAccountReducer.trackOrder,
  countryData: state.common.countryData,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  configCdnDomainSettings: state.common.configSettings.cdnDomain
});
const mapStateToDispatch = dispatch => ({
  setCancelOrder: bindActionCreators(setCancelOrder, dispatch),
  cancelOrderAction: bindActionCreators(cancelOrder, dispatch),
  resetCancelOrder: bindActionCreators(resetCancelOrder, dispatch),
  trackOrder: bindActionCreators(trackOrder, dispatch),
  getCountryList: bindActionCreators(getCountryList, dispatch),
  showPageLoader: bindActionCreators(showPageLoader, dispatch),
  hidePageLoader: bindActionCreators(hidePageLoader, dispatch)
});
export default connect(
  mapStateToProps,
  mapStateToDispatch
)(RecentOrderReturnVersion);
