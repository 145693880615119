import React, { Component } from "react";
import map from "lodash/map";
import isUndefined from "lodash/isUndefined";
import classnames from "classnames";
import size from "lodash/size";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import DeleteModal from "../../delete-modal";

export default class VoucherList extends Component {
  state = {
    event: null,
    voucherCode: "",
    isVoucherConfirmModal: false,
    isInvalidVoucher: false
  };

  onSelectVoucher = (e, voucherCode) => {
    const { order, isVoucherValid } = this.props;
    const isInvalidVoucher =
      !isNull(isVoucherValid) &&
      !isUndefined(isVoucherValid) &&
      !isVoucherValid &&
      order.voucherCode;

    this.setState(
      { event: e, voucherCode, isInvalidVoucher },
      this.checkIsAnotherVoucherApplied
    );
  };

  checkIsAnotherVoucherApplied = async () => {
    const { voucherCode, isInvalidVoucher } = this.state;
    const { order, changeVoucher } = this.props;

    if (isInvalidVoucher) {
      await changeVoucher({}, "");
      this.setVoucher();
      return;
    }
    const isAnotherVoucherApplied =
      order.voucherCode && voucherCode && order.voucherCode !== voucherCode;

    if (isAnotherVoucherApplied) {
      this.setState({ isVoucherConfirmModal: true });
    } else {
      this.setVoucher();
    }
  };

  setVoucher = () => {
    const {
      clearBinCode,
      applyVoucher,
      changeVoucher,
      setSummaryRecalculationInProgress
    } = this.props;
    const { event, voucherCode } = this.state;
    setSummaryRecalculationInProgress();
    clearBinCode();
    changeVoucher(event, voucherCode);
    setTimeout(() => {
      applyVoucher(event);
    }, 100);
  };

  applyConfirm = () => {
    this.setVoucher();
    this.setState({ isVoucherConfirmModal: false });
  };

  render() {
    const {
      translation,
      VoucherCodes,
      toggleViewMore,
      showViewMore,
      handleToggleViewMore,
      styleObj,
      language
    } = this.props;
    const { isVoucherConfirmModal } = this.state;

    const voucherCodes = filter(VoucherCodes, code => !code.isBankSpecific);

    return (
      <div>
        <div className="applePay_appyVoucher couponCode">
          {!isEmpty(voucherCodes) && (
            <p className="applyVoucherTitle">
              {translation && translation.avialablePromoCodes}
            </p>
          )}
          <div
            className={classnames("voucherWrapper", {
              expand: !toggleViewMore
            })}
            {...!toggleViewMore && { style: styleObj }}
          >
            {map(voucherCodes, (codes, index) => {
              const { voucherText } = codes;

              return (
                <div className="couponCode_wrapper" key={index}>
                  <div className="couponCode">
                    <span className="code">{codes.voucherCode}</span>
                    <div className="coupon_description">
                      <span>{voucherText.message}</span>
                      <span>{codes.remarks}</span>
                      <span>{voucherText.date}</span>
                    </div>
                  </div>
                  <div className="ApplyActoin_couponCode">
                    <button
                      onClick={e => {
                        e.persist();
                        this.onSelectVoucher(e, codes.voucherCode);
                      }}
                    >
                      {translation.Applybtn}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {showViewMore &&
          size(voucherCodes) > 1 && (
            <p className="view_more" onClick={handleToggleViewMore}>
              {toggleViewMore
                ? translation.showAllCodes
                : translation.showLessCodes}
            </p>
          )}

        {isVoucherConfirmModal && (
          <DeleteModal
            language={language}
            bodyTxt={translation.cardPromoMessage}
            cancelBtnTxt={translation.no}
            deleteBtnTxt={translation.yes}
            isDeleteModalActive={isVoucherConfirmModal}
            handleCloseModal={() =>
              this.setState({ isVoucherConfirmModal: false })
            }
            handleDeleteItem={this.applyConfirm}
          />
        )}
      </div>
    );
  }
}
