import React from "react";
import cn from "classnames";
import OldPriceSection from "../../old-price-section";

const PriceSection = ({
  currencyCode,
  item,
  cartPrice,
  normalPrice,
  countrySettings
}) => {
  const oldPrice = item.price?.old;
  const currentPrice = item.price?.current;
  const isDiscount = Boolean(oldPrice && currentPrice !== oldPrice);

  return (
    <p className="item_price">
      {currencyCode && (
        <span className={cn("price", { red: isDiscount })}>
          {currencyCode} {item.transactionPrice ? cartPrice : normalPrice}
        </span>
      )}
      {isDiscount && (
        <OldPriceSection item={item} countrySettings={countrySettings} />
      )}
    </p>
  );
};

export default PriceSection;
