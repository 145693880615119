import React from "react";
import ReactHtmlParser from "react-html-parser";
import ExclamationMark from "./assets/exclamation-mark-orange.svg";

const InfoText = ({ text }) => {
  return (
    <div className="info_wrapper">
      <img src={ExclamationMark} alt="exclamation-mark" />
      {ReactHtmlParser(text)}
    </div>
  );
};

export default InfoText;
