import React, { Component } from "react";
import SizeCategoryTabs from "../../size/size-category-tab";
class SizeFilter extends Component {
  render() {
    const {
      handleSizeChange,
      selectedSizeFilter,
      subFacet,
      sizeFilterStyle,
      sliderMaxWidth,
      maxSliderWidth,
      handleSizeFilter,
      handleFilterToggle,
      screenHeight
    } = this.props;
    return (
      <SizeCategoryTabs
        subFacet={subFacet}
        handleSizeChange={handleSizeChange}
        selectedSizeFilter={selectedSizeFilter}
        sizeFilterStyle={sizeFilterStyle}
        sliderMaxWidth={sliderMaxWidth}
        maxSliderWidth={maxSliderWidth}
        handleSizeFilter={handleSizeFilter}
        handleFilterToggle={handleFilterToggle}
        screenHeight={screenHeight}
      />
    );
  }
}

export default SizeFilter;
