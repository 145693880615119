import React, { useEffect } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { Link } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { getLoyaltyExpiryDetails } from "../../../redux/actions/myAccount.action";
import { selectLanguageSlug, selectRouteSlug } from "../../../util/selectors";
import CardInfo from "../../loyalty-card/CardInfo";
import LoyaltyRedeemInfoMobile from "../loyalty-redeem-info/loyalty-redeem-info-mobile";
import LoyaltyProgressBarMobile from "../loyalty-points-progress-bar/loyalty-points-progress-bar-mobile";
import RedeemExpiryDetailsPopup from "./redeem-expiry-details-popup";
import RedeemLoyaltyPointsBtn from "../redeem-loyalty-points-btn/index";
import blackStar from "../../../routes/my-account/images/black-star.svg";
import {
  DEFAULT_POINTS_FOR_NEXT_LEVEL,
  LOYALTY_MEMBERSHIP_LEVELS,
  MEMBERSHIP_STYLE_CLASS,
  MIN_POINTS_FOR_REDEEM
} from "../../../constants/loyalty-points";

const RedeemLoyaltyMobile = props => {
  const style = {
    margin: "27px 0 0 0"
  };
  const {
    myAccountTranslation,
    setting,
    getLoyaltyExpiryDetails,
    loyaltyPoints: { LoyaltyStatics: loyaltyStatics, expiryDetails },
    currencyCode,
    language,
    countryId,
    isArabic,
    routeSlug
  } = props;
  const {
    loyaltyDetails: {
      memeberLevel,
      pointNeededForNextLevel,
      accuralPoints,
      cutoffDate
    }
  } = loyaltyStatics;

  const isPointsRedeemable =
    parseInt(loyaltyStatics.loyaltyDetails.currentBalance) <
    MIN_POINTS_FOR_REDEEM;

  const getAdditionalText = () => {
    switch (MEMBERSHIP_STYLE_CLASS[memeberLevel]) {
      case "yellow":
        return myAccountTranslation.upgradeToBlackTier;
      case "black":
        return myAccountTranslation.retainOnBlackTier;

      default:
        return "";
    }
  };

  const getPointsToNextLevel = () => {
    const pointsResult =
      (pointNeededForNextLevel || DEFAULT_POINTS_FOR_NEXT_LEVEL) -
      accuralPoints;

    return pointsResult < 0 ? 0 : pointsResult;
  };

  useEffect(() => {
    if (countryId && currencyCode && language) {
      getLoyaltyExpiryDetails(countryId, currencyCode, language);
    }
  }, [countryId, currencyCode, language]);

  return (
    <div
      className={cn("redeem-loyalty-mobile", {
        black:
          MEMBERSHIP_STYLE_CLASS[memeberLevel] ===
          LOYALTY_MEMBERSHIP_LEVELS.black
      })}
    >
      <CardInfo
        myAccountTranslation={myAccountTranslation}
        countryCode={setting.countryCode}
        countryShortCode={setting.countrySHORT}
        currencyCode={setting.currencyCode}
        loyaltyDetails={loyaltyStatics.loyaltyDetails}
        isArabic={isArabic}
        routeSlug={routeSlug}
      />
      <LoyaltyRedeemInfoMobile
        translation={myAccountTranslation}
        currencyCode={setting.currencyCode}
        loyaltyDetails={loyaltyStatics.loyaltyDetails}
      />
      <div style={style}>
        <div className="bar-code">
          <Barcode
            value={loyaltyStatics.loyaltyDetails.cardNumber}
            background="white"
            width={2.3}
            height={60}
            textAlign="right"
            fontSize={13}
            fontOptions="bold"
          />
        </div>
        <LoyaltyProgressBarMobile
          maxTierPoints={
            loyaltyStatics.loyaltyDetails.pointNeededForNextLevel ||
            DEFAULT_POINTS_FOR_NEXT_LEVEL
          }
          availablePoints={loyaltyStatics.loyaltyDetails.accuralPoints}
          memberLevel={loyaltyStatics.loyaltyDetails.memeberLevel}
        />
        <div className="benefits-section">
          <Link
            to={`/${routeSlug}/loyaltyprogram/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <b>{myAccountTranslation.seeBenefits}</b>
          </Link>
        </div>
        <div className="upgrade-tier-section">
          <div
            className={cn("black-star-icon", {
              arabic: isArabic
            })}
          >
            <img src={blackStar} alt="" />
          </div>
          <p className="to-black-tier">{getAdditionalText()}</p>
          <p className="more-smile-points">
            {myAccountTranslation.byCollecting} {getPointsToNextLevel()}{" "}
            {myAccountTranslation.moreSmilePoints}{" "}
            {moment(cutoffDate).format("DD MMM YYYY")}.
          </p>
        </div>

        {!isEmpty(expiryDetails) && (
          <>
            {Boolean(expiryDetails.earliestExpiryPoints) && (
              <div className="mt-4">
                <RedeemExpiryDetailsPopup
                  points={expiryDetails.earliestExpiryPoints}
                  expireDate={expiryDetails.earliestPointExpiryDate}
                  translation={myAccountTranslation}
                />
              </div>
            )}

            {Boolean(expiryDetails.expiryPointsNextMon) && (
              <div className="mt-4">
                <RedeemExpiryDetailsPopup
                  points={expiryDetails.expiryPointsNextMon}
                  expireDate={expiryDetails.expiryPointsDateNextMon}
                  translation={myAccountTranslation}
                />
              </div>
            )}
          </>
        )}
        <RedeemLoyaltyPointsBtn myAccountTranslation={myAccountTranslation} />
        {isPointsRedeemable && (
          <p className="min-points">{myAccountTranslation.minPointsMsg}</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  setting: state.common.settings,
  loyaltyPoints: get(state, "myAccountReducer.loyaltyPoints", {}),
  language: state.common.language,
  currencyCode: state.common.settings.currencyCode,
  countryId: state.common.settings.countryId,
  isArabic: selectLanguageSlug(state) === "ar",
  routeSlug: selectRouteSlug(state)
});

const mapDispatchToProps = {
  getLoyaltyExpiryDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemLoyaltyMobile);
