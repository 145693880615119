import React from "react";
import cn from "classnames";

const CircleRadio = ({ isChecked, index, isNotActive, ...args }) => (
  <div className="circle-radio">
    <input
      className={cn({ disabled: isNotActive })}
      id={index}
      type="radio"
      name="circle-radio"
      value="circle-radio"
      checked={isChecked}
      readOnly
      {...args}
    />
  </div>
);

export default CircleRadio;
