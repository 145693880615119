import React, { useState } from "react";
import cn from "classnames";
import Loader from "../../loader";
import { LOADER_TYPES, commonLoaderStyle } from "../../../constants";

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "inherit"
};

export const ButtonWithLoader = ({
  showLoader,
  children,
  className,
  loaderProps,
  onClick,
  ...buttonProps
}) => {
  const [ignoreShowLoaderFlag, setShowIgnoreLoaderFlag] = useState(true);

  return (
    <button
      className={cn("position-relative", className)}
      onClick={(...args) => {
        setShowIgnoreLoaderFlag(false); //added to prevent loader showing if we have some fetching in progress but haven't clicked the button yet
        onClick && onClick(...args);
      }}
      {...buttonProps}
    >
      {!ignoreShowLoaderFlag && showLoader && (
        <Loader
          type={LOADER_TYPES.DOTS}
          visible
          style={loaderStyle}
          size={8}
          {...loaderProps}
        />
      )}
      {children}
    </button>
  );
};
