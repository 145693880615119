import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import isString from "lodash/isString";
import get from "lodash/get";
import Scrollbars from "react-custom-scrollbars";
import FilterHandle from "../filter-handle";
import { productListing } from "../../../language/productListing/en/productListing";
import {
  handleFacetChange,
  getProductsData,
  handleSortChange,
  handleAssistiveFilterClick,
  setMobilecurrentFilterSelected
} from "../../../redux/actions/productlisting.action";
import {
  saveBreadcrumb,
  resetBreadCrumb
} from "../../../redux/actions/common.action";
import {
  isServer,
  setFilterOrder,
  urlFilterMap,
  _handleHckeys,
  isTouchScreen,
  decodeURLRecursively,
  isMobile
} from "../../../util";
import FilterCategory from "../filter-categoryhandle";
import CustomDropdown from "../../genericDropDown";
import { setHomeBreadcrumb } from "../../../redux/actions/breadcrumb";
import { breadcrumbTranslation } from "../../../language/breadcrumb/en/breadcrumb";

const hierarchicalCategories = [
  "hierarchicalCategories.lvl0",
  "hierarchicalCategories.lvl1",
  "hierarchicalCategories.lvl2"
];
const urlCategoryKeys = ["categories-0", "categories-1", "categories-2"];
const defaultHierarchyState = [
  "hierarchicalCategories.lvl1",
  "hierarchicalCategories.lvl2"
];
const filtersToRemoveForBrandsPage = [
  "catname",
  "brandName",
  ...hierarchicalCategories,
  ...urlCategoryKeys
];
class FilterTypes extends Component {
  state = {
    productListing: productListing,
    isToggle: false,
    filterToggled: "",
    extraClass: "",
    hiddenHierarchy: defaultHierarchyState,
    sizeFilterStyle: {
      maxWidth: "",
      lvl0Slides: "",
      lvl1Slides: ""
    },
    hcKeys: "",
    searchInit: true,
    sizeFilterActive: "",
    breadcrumbTranslation: breadcrumbTranslation,
    filtersToRemoveForBrandsPage: filtersToRemoveForBrandsPage
  };

  initialPriceRange = {
    min: 0,
    max: 0
  };

  componentDidMount() {
    const { match, language, products, sysGeneratedUrl } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    this.getLanguage(languageParam);
    this._setBreadCrumb({ products, language, sysGeneratedUrl });
  }

  _getHierarchicalFacetCount = facets => {
    let count = 0;
    hierarchicalCategories.forEach(category => {
      facets[category] &&
        Object.keys(facets[category]).forEach(key => {
          if (facets[category][key].checked) {
            count++;
          }
        });
    });
    return count;
  };

  _setBreadCrumb = nextProps => {
    const { saveBreadcrumb, resetBreadCrumb, settings, setHomeBreadcrumb } =
      this.props;
    resetBreadCrumb && resetBreadCrumb();
    setHomeBreadcrumb && setHomeBreadcrumb(this.state.breadcrumbTranslation);
    let selectedCategory = "",
      selectedCategoryLvl1 = "",
      selectedCategoryLvl2 = "";
    const nextHierarchicalProp =
      nextProps.products.facets &&
      nextProps.products.facets[hierarchicalCategories[0]];
    const nextHierarchicalLvl1Prop =
      nextProps.products.facets &&
      nextProps.products.facets[hierarchicalCategories[1]];
    const nextHierarchicalLvl2Prop =
      nextProps.products.facets &&
      nextProps.products.facets[hierarchicalCategories[2]];
    nextHierarchicalProp &&
      Object.keys(nextHierarchicalProp).forEach(category => {
        if (
          nextProps.products.facets[hierarchicalCategories[0]][category].checked
        ) {
          selectedCategory = category;
        }
      });
    nextHierarchicalLvl1Prop &&
      Object.keys(nextHierarchicalLvl1Prop).forEach(category => {
        if (
          nextProps.products.facets[hierarchicalCategories[1]][category].checked
        ) {
          selectedCategoryLvl1 = category;
        }
      });
    nextHierarchicalLvl2Prop &&
      Object.keys(nextHierarchicalLvl2Prop).forEach(category => {
        if (
          nextProps.products.facets[hierarchicalCategories[2]][category].checked
        ) {
          selectedCategoryLvl2 = category;
        }
      });

    if (!selectedCategory || !selectedCategoryLvl1 || !selectedCategoryLvl2) {
      const productUrl =
        nextProps &&
        nextProps.products &&
        nextProps.products.url &&
        nextProps.products.url.split("&");
      if (productUrl) {
        productUrl.forEach(item => {
          if (
            item.includes("hierarchicalCategories.lvl0") ||
            item.includes("categories-0")
          ) {
            let _selectedCategory = decodeURIComponent(item.split("=")[1]);
            selectedCategory = _selectedCategory ? _selectedCategory : "";
          } else if (
            item.includes("hierarchicalCategories.lvl1") ||
            item.includes("categories-1")
          ) {
            let _selectedCategoryLvl1 = decodeURIComponent(item.split("=")[1]);
            selectedCategoryLvl1 = _selectedCategoryLvl1
              ? decodeURIComponent(_selectedCategoryLvl1)
              : "";
          } else if (
            item.includes("hierarchicalCategories.lvl2") ||
            item.includes("categories-2")
          ) {
            let _selectedCategoryLvl2 = decodeURIComponent(item.split("=")[1]);
            selectedCategoryLvl2 = _selectedCategoryLvl2
              ? decodeURIComponent(_selectedCategoryLvl2)
              : "";
          }
        });
      }
    }

    let matchData0 = {},
      matchData1 = {},
      matchData2 = {};

    if (nextProps.sysGeneratedUrl && nextProps.sysGeneratedUrl.length) {
      const trimmedSelectedCategory = selectedCategory.trim();
      const hierarchicalCategory = hierarchicalCategories[0];
      const urlCategoryKey = urlCategoryKeys[0];

      matchData0 =
        this.getSysGeneratedUrl(
          [hierarchicalCategory],
          [trimmedSelectedCategory],
          nextProps
        ) ||
        this.getSysGeneratedUrl(
          [urlCategoryKey],
          [trimmedSelectedCategory],
          nextProps
        );
      const arrayTrimmedSelectedCategoriesLvl1 = [
        trimmedSelectedCategory,
        selectedCategoryLvl1.trim()
      ];
      const hierarchicalCategoriesArray1 = [
        hierarchicalCategory,
        hierarchicalCategories[1]
      ];
      const urlCategoryKeysArray1 = [urlCategoryKey, urlCategoryKeys[1]];
      matchData1 =
        this.getSysGeneratedUrl(
          hierarchicalCategoriesArray1,
          arrayTrimmedSelectedCategoriesLvl1,
          nextProps
        ) ||
        this.getSysGeneratedUrl(
          urlCategoryKeysArray1,
          arrayTrimmedSelectedCategoriesLvl1,
          nextProps
        );
      const arrayTrimmedSelectedCategoriesLvl2 = [
        ...arrayTrimmedSelectedCategoriesLvl1,
        selectedCategoryLvl2.trim()
      ];
      const hierarchicalCategoriesArray2 = [
        ...hierarchicalCategoriesArray1,
        hierarchicalCategories[2]
      ];
      const urlCategoryKeysArray2 = [
        ...urlCategoryKeysArray1,
        urlCategoryKeys[2]
      ];
      matchData2 =
        this.getSysGeneratedUrl(
          hierarchicalCategoriesArray2,
          arrayTrimmedSelectedCategoriesLvl2,
          nextProps
        ) ||
        this.getSysGeneratedUrl(
          urlCategoryKeysArray2,
          arrayTrimmedSelectedCategoriesLvl2,
          nextProps
        );
    }

    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "productList",
        modelData: {
          ar_productListName:
            (matchData0 &&
              this.getObjectKeysLength(matchData0) &&
              matchData0["ar_title"]) ||
            "",
          en_productListName:
            (matchData0 &&
              this.getObjectKeysLength(matchData0) &&
              matchData0["en_title"]) ||
            "",
          productListUrl:
            (matchData0 &&
              this.getObjectKeysLength(matchData0) &&
              `/${nextProps.language}-${
                (settings &&
                  settings.countrySHORT &&
                  settings.countrySHORT.toLowerCase()) ||
                "ae"
              }${matchData0.url}`) ||
            `/${nextProps.language}-${
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }/`
        }
      });
    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "productListLvl1",
        modelData: {
          productListLvl1Name:
            (matchData1 &&
              this.getObjectKeysLength(matchData1) &&
              matchData1[`${nextProps.language}_title`]) ||
            "",
          productListLvl1Url:
            (matchData1 &&
              this.getObjectKeysLength(matchData1) &&
              `/${nextProps.language}-${
                (settings &&
                  settings.countrySHORT &&
                  settings.countrySHORT.toLowerCase()) ||
                "ae"
              }${matchData1.url}`) ||
            `/${nextProps.language}-${
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }/`
        }
      });
    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "productListLvl2",
        modelData: {
          productListLvl2Name:
            (matchData2 &&
              this.getObjectKeysLength(matchData2) &&
              matchData2[`${nextProps.language}_title`]?.replace(
                `${matchData0[`${nextProps.language}_title`]}`.trim(),
                ""
              )) ||
            "",
          productListLvl2Url:
            (matchData2 &&
              this.getObjectKeysLength(matchData2) &&
              `/${nextProps.language}-${
                (settings &&
                  settings.countrySHORT &&
                  settings.countrySHORT.toLowerCase()) ||
                "ae"
              }${matchData2.url}`) ||
            `/${nextProps.language}-${
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }/`
        }
      });
  };

  setBreadCrumb = brandPageDetails => {
    const { parentCategory, brandName } = brandPageDetails;
    const { saveBreadcrumb, language, settings } = this.props;
    if (parentCategory) {
      saveBreadcrumb &&
        saveBreadcrumb({
          modelName: "brandsList",
          modelData: {
            brandsName: brandPageDetails[`${language}_parentCategory`],
            brandsUrl: `/${language}-${
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }/${this.getProductUrlName(
              brandPageDetails.en_parentCategory
            ).toLowerCase()}/`
          }
        });
      saveBreadcrumb &&
        saveBreadcrumb({
          modelName: "brandsListLvl1",
          modelData: {
            brandsName: brandPageDetails[`${language}_brandName`],
            brandsUrl: ""
          }
        });
    }
    if (!parentCategory && brandName) {
      saveBreadcrumb &&
        saveBreadcrumb({
          modelName: "brandsList",
          modelData: {
            brandsName: brandPageDetails[`${language}_brandName`],
            brandsUrl: ""
          }
        });
    }
  };

  getProductUrlName = (name = "") => {
    let urlName = name && name.replace(/ - /g, "-");
    urlName = urlName && urlName.replace(/\s+/g, "-").toLowerCase();
    urlName = urlName && urlName.replace(/&/g, "and");
    urlName = urlName && urlName.replace(/,/g, "");
    urlName = urlName && urlName.replace(/%/g, "");
    urlName = urlName && urlName.replace(/\//g, "");
    urlName = urlName && urlName.replace(/#/g, "");
    return urlName;
  };

  getSysGeneratedUrl = (levels, selectedValues, nextProps) => {
    if (
      levels &&
      levels.length === 1 &&
      selectedValues &&
      selectedValues.length === 1
    ) {
      const BreadCrumbUrl = this.getBreadcrumbUrl(
        `${levels[0]}=${encodeURIComponent(selectedValues[0])}`,
        nextProps
      );
      return Object.keys(BreadCrumbUrl).length && BreadCrumbUrl;
    } else if (
      levels &&
      levels.length === 2 &&
      selectedValues &&
      selectedValues.length === 2 &&
      selectedValues[1]
    ) {
      const filterUrl = `${levels[0]}=${encodeURIComponent(
        selectedValues[0]
      )}&${levels[1]}=${encodeURIComponent(selectedValues[1])}`;
      let BreadCrumbUrl = this.getBreadcrumbUrl(filterUrl, nextProps, 2);
      return Object.keys(BreadCrumbUrl).length && BreadCrumbUrl;
    } else if (
      levels &&
      levels.length === 3 &&
      selectedValues &&
      selectedValues.length === 3 &&
      selectedValues[2]
    ) {
      const BreadCrumbUrl = this.getBreadcrumbUrl(
        `${levels[0]}=${encodeURIComponent(selectedValues[0])}&${
          levels[1]
        }=${encodeURIComponent(selectedValues[1])}&${
          levels[2]
        }=${encodeURIComponent(selectedValues[2])}`,
        nextProps,
        3
      );
      return Object.keys(BreadCrumbUrl).length && BreadCrumbUrl;
    }
  };

  getObjectKeysLength = object => {
    return object && Object.keys(object).length;
  };

  getBreadcrumbUrl = (filterUrl, nextProps, length = 0) => {
    const result =
      (nextProps.sysGeneratedUrl &&
        (nextProps.sysGeneratedUrl.find(
          item =>
            decodeURLRecursively(item.filterCondition) ===
            decodeURLRecursively(filterUrl)
        ) ||
          nextProps.sysGeneratedUrl.find(
            item =>
              item.ar_filterCondition &&
              item.ar_filterCondition.indexOf(
                filterUrl.split("&")[length - 1 > 0 ? length - 1 : 0]
              ) !== -1 &&
              (item.url.match(/\//g) || []).length - 1 === length
          ) ||
          nextProps.sysGeneratedUrl.find(
            item =>
              decodeURLRecursively(item.ar_filterCondition) ===
              decodeURLRecursively(filterUrl)
          ))) ||
      {};
    return result;
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { products, handleSwitchFilter, categoryTitle, sysGeneratedUrl } =
      this.props;
    const { filterToggled } = this.state;
    if (nextProps.language !== this.props.language) {
      this.getLanguage(nextProps.language);
      !!handleSwitchFilter && handleSwitchFilter(false);
    }
    if (
      nextProps &&
      nextProps.sysGeneratedUrl &&
      nextProps.sysGeneratedUrl.length !== sysGeneratedUrl
    ) {
      this._setBreadCrumb(nextProps);
    }
    if (
      products &&
      nextProps.products &&
      this._getHierarchicalFacetCount(products.facets) !==
        this._getHierarchicalFacetCount(nextProps.products.facets)
    ) {
      let hiddenHierarchy = [];
      this._setBreadCrumb(nextProps);
      hierarchicalCategories.forEach(hierarchy => {
        const hierarchyFacet =
          nextProps.products.facets && nextProps.products.facets[hierarchy];
        if (hierarchyFacet) {
          if (
            Object.keys(hierarchyFacet).find(
              subFacetKey => hierarchyFacet[subFacetKey].checked
            )
          ) {
            if (hiddenHierarchy.indexOf(hierarchy) === -1) {
              hiddenHierarchy.push(hierarchy);
            }
          }
        }
      });
      const isMob = Boolean(isMobile.any() && isMobile.any().length);
      let stateUpdate = { searchInit: false };
      const isSearchInit =
        hiddenHierarchy &&
        hiddenHierarchy.length &&
        hiddenHierarchy.indexOf(hierarchicalCategories[1]) !== -1;

      if (isSearchInit) {
        stateUpdate = { searchInit: true };
      }
      this.setState(stateUpdate, () => {
        const runHideHierarchyCallback =
          isMob && filterToggled && !isSearchInit;

        if (runHideHierarchyCallback) {
          this.setHiddenHierarchy(hiddenHierarchy);
        }
      });
      if (!isMob || isSearchInit) {
        this.setHiddenHierarchy(hiddenHierarchy);
      }
    }

    this.setState({ hcKeys: _handleHckeys(nextProps) });

    if (categoryTitle !== nextProps.categoryTitle) {
      this._setBreadCrumb(nextProps);
    }
  }

  setHiddenHierarchy = hiddenHierarchy => {
    const { searchInit } = this.state;
    const { assistiveFilterClick } = this.props;
    if (hiddenHierarchy.length === 1) {
      hiddenHierarchy = [hierarchicalCategories[0], hierarchicalCategories[2]];
      this.setState({ hiddenHierarchy });
      !searchInit &&
        !assistiveFilterClick &&
        this.handleFilterToggle(hierarchicalCategories[1]);
    } else if (hiddenHierarchy.length > 1) {
      hiddenHierarchy = [hierarchicalCategories[0], hierarchicalCategories[1]];
      this.setState({ hiddenHierarchy });
      if (!isServer && window.screen.width > 812) {
        !searchInit &&
          !assistiveFilterClick &&
          this.handleFilterToggle(hierarchicalCategories[2], "", "mobile");
      } else {
        if (!searchInit && !assistiveFilterClick) {
          this.handleFilterToggle(hierarchicalCategories[2]);
        }
      }
    } else {
      this.setState({ hiddenHierarchy: defaultHierarchyState });
    }
  };

  getLanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(
      `../../../language/productListing/${language}/productListing`
    );
    const languageResponseBreadcrumb = import(
      `../../../language/breadcrumb/${language}/breadcrumb`
    );

    languageResponse.then(response => {
      this.setState({
        productListing: response.productListing
      });
    });
    languageResponseBreadcrumb.then(response => {
      this.setState({
        breadcrumbTranslation: response.breadcrumbTranslation
      });
    });
  };

  setExtraClass = (e, selectedVal) => {
    let isOpenLeft = false;
    let extraClass = "";
    let maxWidth = "";
    let lvl0Slides = 3;
    let lvl1Slides = 3;
    if (selectedVal !== "sizeHierarchicalFilter.lvl2") {
      if (e && e.clientX + 300 > window.screen.width) {
        extraClass = "openLeft";
      } else if (e && e.clientX + 300 < 500) {
        extraClass = "openRight";
      }
    } else if (selectedVal === "sizeHierarchicalFilter.lvl2") {
      //to calculate max width of sizeFilter
      let windowWidth =
        window.screen.width >= 1220 ? 1220 : window.screen.width;
      let currentWidthOfFilter = e.target.offsetWidth || 170;
      let parentOffsetLeft =
        (e.currentTarget &&
          e.currentTarget.parentNode &&
          e.currentTarget.parentNode.offsetLeft) ||
        0;
      let offsetLeft = e.target.offsetLeft - parentOffsetLeft;
      if (offsetLeft > windowWidth / 2) {
        isOpenLeft = true;
        extraClass = "openLeft";
      }
      maxWidth = isOpenLeft
        ? offsetLeft + currentWidthOfFilter
        : windowWidth - offsetLeft;
      maxWidth =
        parseInt(windowWidth * 0.7) > maxWidth
          ? maxWidth
          : parseInt(windowWidth * 0.7);
      lvl0Slides = parseInt(maxWidth / 150);
      lvl1Slides = parseInt(maxWidth / 130);
    }
    if (window.screen.width > 1120) {
      maxWidth = maxWidth && maxWidth < 800 ? 800 : maxWidth;
    }
    this.setState({
      extraClass,
      sizeFilterStyle: { maxWidth, lvl0Slides, lvl1Slides }
    });
  };

  setSearchInit = () => {
    this.setState({ searchInit: false });
    this.props.handleAssistiveFilterClick &&
      this.props.handleAssistiveFilterClick(false);
  };

  setHierarchy = hierarchy => {
    const { handleFacetChange, products, handleAssistiveFilterClick } =
      this.props;
    const clearFacet = Object.keys(products.facets).find(
      key =>
        key ===
        (hierarchy === hierarchicalCategories[2]
          ? hierarchicalCategories[1]
          : hierarchicalCategories[0])
    );
    handleAssistiveFilterClick && handleAssistiveFilterClick(false);
    if (clearFacet) {
      const parentFacet = products.facets[clearFacet];
      const filterItems = Object.keys(parentFacet);
      filterItems.forEach(facetItem => {
        handleFacetChange(clearFacet, facetItem, false);
      });
      hierarchy === hierarchicalCategories[1] &&
        this.handleFilterToggle(hierarchicalCategories[0]);
    }
  };

  handleFilterToggleAssistive = (selectedVal, e, searchInit) => {
    this.props.handleAssistiveFilterClick &&
      this.props.handleAssistiveFilterClick(false);
    this.setState({ searchInit }, () => {
      this.handleFilterToggle(selectedVal, e);
    });
  };

  setSort = sort => {
    const {
      getProductsData,
      history,
      language,
      handleSortChange,
      handleSortChangeEvent,
      handleAssistiveFilterClick
    } = this.props;
    handleSortChange && handleSortChange(sort);
    handleSortChangeEvent(sort);
    handleAssistiveFilterClick && handleAssistiveFilterClick(false);
    getProductsData && getProductsData(null, history, null, null, language);
  };

  generateHTMLForSortOptions = (isChecked, label = "") => {
    const containerClasses = cn("right_check_label", { checked: isChecked });
    return `<div class="${containerClasses}">
        <span class="title">${label}</span>
        <span class="check_circle">
          <input type="checkbox" />
        </span>
      </div>`;
  };

  handleFilterToggle = (selectedVal, e, btnName) => {
    e && e.stopPropagation();
    const { filterToggled, productListing } = this.state;
    const {
      handleAssistiveFilterClick,
      handleSizeFilter,
      setMobilecurrentFilterSelected
    } = this.props;
    // Setting globally the type of current filter navigated in
    setMobilecurrentFilterSelected(selectedVal);

    e && handleAssistiveFilterClick && handleAssistiveFilterClick(false);

    this.setExtraClass(e, selectedVal);
    const screenWidth = window.screen.width;
    if (filterToggled !== selectedVal) {
      this.setState(prevState => ({
        isToggle: true,
        filterToggled: selectedVal
      }));
    } else if (
      screenWidth < 768 &&
      (selectedVal === "hierarchicalCategories.lvl1" ||
        selectedVal === "hierarchicalCategories.lvl2")
    ) {
      this.setState(prevState => ({
        isToggle: true,
        filterToggled: selectedVal
      }));
    } else {
      this.setState(prevState => {
        !isTouchScreen() &&
          prevState.isToggle &&
          setMobilecurrentFilterSelected(null);
        return {
          isToggle: !prevState.isToggle,
          filterToggled
        };
      });
    }

    if (
      btnName !== "apply" &&
      handleSizeFilter &&
      e &&
      e.currentTarget &&
      e.currentTarget.innerText
    ) {
      let sizeFilter = false;
      let value = true;
      let showResult = true;
      if (e.currentTarget.innerText.includes("Size")) {
        sizeFilter = true;
        showResult = false;
      }
      if (
        e.currentTarget.innerText === "Category" ||
        e.currentTarget.innerText.includes("Category")
      ) {
        value = false;
        showResult = false;
      }
      if (!selectedVal.includes("hierarchicalCategories")) {
        value = false;
        showResult = false;
      }
      handleSizeFilter(value, sizeFilter, showResult);
    } else {
      if (handleSizeFilter && btnName === "apply") {
        handleSizeFilter(false, false, true);
      } else if (
        (handleSizeFilter &&
          e &&
          e.currentTarget === document &&
          e.target.innerText !== productListing.clear) ||
        (handleSizeFilter &&
          selectedVal.currentTarget &&
          selectedVal.currentTarget.classList.length &&
          selectedVal.currentTarget.className === "mBackIcon")
      ) {
        handleSizeFilter(false, false, true);
      }
    }
  };

  render() {
    const {
      productListing,
      isToggle,
      filterToggled,
      extraClass,
      hiddenHierarchy,
      sizeFilterStyle,
      hcKeys
    } = this.state;
    const {
      language,
      products,
      handleSwitchFilter,
      isWeb,
      handleMobileFilter,
      handleSizeFilter,
      // isCountrySelected,
      isShouldShowTextForFilters,
      handleFilterItemChecked,
      sortOptions,
      selectedSort
    } = this.props;
    const url = (products && products.url) || "";
    const screenHeight = !isServer && window.innerHeight;
    const modifiedURL =
      (products && products.url && urlFilterMap(`?${products.url}`)) || "";

    let filters = setFilterOrder(Object.keys(products.facets)).filter(key => {
      const encodedKey = encodeURIComponent(key);
      return [hiddenHierarchy, url, modifiedURL].every(
        url => !url.includes(encodedKey)
      );
    });

    if (
      url.indexOf("hierarchicalCategories.lvl") !== -1 ||
      url.indexOf("categories") !== -1
    ) {
      filters = filters.filter(
        key => key.indexOf("hierarchicalCategories.lvl") === -1
      );
    }

    const sortFilter = get(products, "sortFilter", {});
    const filteredSortOptions = sortOptions.map(option => ({
      key: option.key,
      value: this.generateHTMLForSortOptions(
        selectedSort === option.key,
        option.label
      )
    }));
    const selectedSortElement =
      (selectedSort &&
        filteredSortOptions.find(option => option.key === selectedSort)) ||
      selectedSort;
    let screenWidth;
    if (!isServer) {
      screenWidth = document.body.clientWidth;
    }

    const { min, max } = this.initialPriceRange;
    if (products.range) {
      this.initialPriceRange = {
        min: min === 0 ? +products.range.min : min,
        max: max === 0 ? +products.range.max : max
      };
    }

    const filterHandlers = item => (
      <FilterHandle
        key={item}
        translation={productListing}
        item={item}
        className={extraClass}
        isToggle={isToggle}
        filterToggled={filterToggled}
        handleFilterToggle={this.handleFilterToggle}
        handleFilterItemChecked={handleFilterItemChecked}
        setHierarchy={this.setHierarchy}
        setSearchInit={this.setSearchInit}
        sizeFilterStyle={sizeFilterStyle}
        intialPriceMaxvalue={this.initialPriceRange.max}
        isShouldShowTextForFilters={isShouldShowTextForFilters}
        clearRangeOnly={this.props.clearRangeOnly}
        {...(handleSizeFilter && { handleSizeFilter })}
      />
    );

    const shouldHaveAdditionalWrapper =
      isTouchScreen() &&
      (!filterToggled ||
        (isToggle && !isString(filterToggled)) ||
        (!isToggle && isString(filterToggled)));

    const filteredFiltersContent =
      !!filters.length &&
      filters
        .filter(filter => productListing[filter] && filter !== "subcatname")
        .map(filterHandlers);

    const filtersList = shouldHaveAdditionalWrapper ? (
      <Scrollbars
        autoHeight
        autoHeightMin={55}
        autoHeightMax={screenHeight - 120}
      >
        {filteredFiltersContent}
      </Scrollbars>
    ) : (
      filteredFiltersContent
    );

    return (
      <div
        className={cn("filter_wrapper", {
          arabic: language.includes("ar")
          // countryNotSelected: isCountrySelected <-- uncomment this line WHEN THE <CountryFirstView/> WILL BE UNCOMMENTED AGAIN!!!
        })}
      >
        <div className="container padding0 listing_filter_web_view">
          {isWeb && hcKeys ? (
            <FilterCategory
              products={products}
              handleFilterToggle={(categories, e) =>
                this.handleFilterToggleAssistive(categories, e, true)
              }
              handleSwitchFilter={handleSwitchFilter}
              handleMobileFilter={handleMobileFilter}
              language={language}
            />
          ) : (
            <div className="filter_types">
              {filtersList}
              {sortFilter.isEnabled &&
                screenWidth > 812 &&
                !!filters.length && (
                  <CustomDropdown
                    data={filteredSortOptions}
                    selectedItem={selectedSortElement}
                    forceTitle={productListing.sort}
                    containerClass={cn("withBorder sortingProduct", {
                      selected: selectedSortElement?.key?.trim()
                    })}
                    scrollbarMaxHeight={150}
                    handleChange={sort => this.setSort(sort)}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  products: reduxState.productListingReducer.products,
  assistiveFilterClick: reduxState.productListingReducer.assistiveFilterClick,
  sysGeneratedUrl: reduxState.productListingReducer.sysGeneratedUrl,
  brandPageDetails: reduxState.productListingReducer.brandPageDetails
});

const mapDispatchToProps = dispatch => ({
  handleFacetChange: (facet, subFacet, checked) =>
    dispatch(handleFacetChange(facet, subFacet, checked)),
  handleSortChange: sort => dispatch(handleSortChange(sort)),
  getProductsData: (...args) => dispatch(getProductsData(...args)),
  handleAssistiveFilterClick: val => dispatch(handleAssistiveFilterClick(val)),
  saveBreadcrumb: navData => dispatch(saveBreadcrumb(navData)),
  resetBreadCrumb: () => dispatch(resetBreadCrumb()),
  setHomeBreadcrumb: translatedValue =>
    dispatch(setHomeBreadcrumb(translatedValue)),
  setMobilecurrentFilterSelected: filter =>
    dispatch(setMobilecurrentFilterSelected(filter))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterTypes)
);
