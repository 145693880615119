import React from "react";
import { BEConfig } from "../../config/env";

const AppDownloads = () => (
  <section className="download_options">
    <a
      href={BEConfig.commonSettings.ios}
      target="_blank"
      rel="noopener noreferrer"
      className="apple_store"
    >
      {" "}
    </a>
    <a
      href={BEConfig.commonSettings.android}
      target="_blank"
      rel="noopener noreferrer"
      className="google_play"
    >
      {" "}
    </a>
    <a
      href={BEConfig.commonSettings.huawei}
      target="_blank"
      rel="noopener noreferrer"
      className="huawei_store"
    >
      {" "}
    </a>
  </section>
);

export default AppDownloads;
