import get from "lodash/get";
import queryString from "query-string";
import { BEConfig } from "../../config/env";
import {
  TOGGLE_QUICK_FILTER_MODAL,
  SET_QUICK_FILTER_DATA,
  UPDATE_QUICK_FILTER_DATA,
  SET_QUICK_FILTER_STEP,
  SET_QUICK_FILTER_STEP_PATH,
  SET_QUICK_FILTER_STEP_DATA,
  APPLY_SELECTED_CATEGORIES,
  SET_QUICK_FILTER_PRODUCTS,
  RESET_QUICK_FILTER_PRODUCTS,
  RESET_QUICK_FILTER_SELECTION,
  RESET_QUICK_FILTER,
  SET_CATEGORY,
  RESET_CATEGORIES,
  SET_SIZE,
  PARSE_PRODUCTS_SIZES
} from "../constants";
import { LANGUAGE_ROUTE_KEY, LANGUAGE_ROUTE_KEY_MAP } from "../../constants";
import {
  QUICK_FILTER_KIDS,
  QUICK_FILTER_SHOES,
  QUICK_FILTER_ALL_PRODUCTS,
  QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES,
  TODDLERS,
  TODDLERS_N_BABIES,
  FOOTWEAR,
  TODDLERS_N_BABIES_AMPERSAND,
  SHOP_ALL,
  TODDLERS_IDEAL_FOR_PARAM_BY_PATH,
  TODDLERS_HIERARCHY_FOOTWEAR
} from "../../constants/quickFilter";
import { ARABIC_REQUEST_PATH_MAP } from "../../constants/quickFilter/ar_quickFilter";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { fetchWebApi } from "../../webapis/apiResource";
import { urlFilterMap, includesSubString } from "../../util";
import {
  prettifyToddlersPath,
  getQuickFilterHistoryPushObject,
  getKidsQuickFilterHistoryPushObject,
  addDataFieldToPath,
  setObjectKeyAsAdditionalField,
  checkAnyIncludes,
  getSplittedUrlArrayBySearch,
  getQuickFilterDataFromMegaMenu,
  getStringifiedAdditionalInfoQuery
} from "../../util/quickFilter";
import { getHierarchySearchArrayFromPath } from "../../util/sizes";
import { setInitialState } from "./productlisting.action";

export const toggleQuickFilerModal = () => ({
  type: TOGGLE_QUICK_FILTER_MODAL
});

export const setQuickFilterData = (megaMenuData, language) => {
  const quickFilterData = getQuickFilterDataFromMegaMenu(
    megaMenuData,
    language
  );
  return {
    type: SET_QUICK_FILTER_DATA,
    payload: quickFilterData
  };
};

export const updateQuickFilterData = (childrenObject, updatePath) => {
  return {
    type: UPDATE_QUICK_FILTER_DATA,
    payload: { childrenObject, updatePath }
  };
};

export const setQuickFilterStep = step => ({
  type: SET_QUICK_FILTER_STEP,
  payload: step
});

export const setQuickFilterStepPath = path => ({
  type: SET_QUICK_FILTER_STEP_PATH,
  payload: path
});

export const setQuickFilterStepData = data => ({
  type: SET_QUICK_FILTER_STEP_DATA,
  payload: data
});

export const setCategory = category => ({
  type: SET_CATEGORY,
  payload: category
});

export const resetCategories = () => ({
  type: RESET_CATEGORIES
});

export const setSize = sizeURL => ({
  type: SET_SIZE,
  payload: sizeURL
});

export const setQuickFilterProducts = (content, facetName) => ({
  type: SET_QUICK_FILTER_PRODUCTS,
  content,
  facetName
});

export const resetQuickFilterProducts = () => ({
  type: RESET_QUICK_FILTER_PRODUCTS
});

export const applySelectedCategories = () => {
  const { protocol, baseURL, port, versionInfo, getPageHandle } =
    BEConfig.catalogApi;

  const host = `${protocol}${baseURL}${port}${versionInfo}${getPageHandle}`;
  const getDataSearchURL = (
    quickFilterStepPath,
    selectedCategories,
    language = LANGUAGE_ROUTE_KEY.english
  ) => {
    const search = `categories-2=`;
    let paramsArray = "";
    if (language === LANGUAGE_ROUTE_KEY.english) {
      paramsArray = getHierarchySearchArrayFromPath(
        quickFilterStepPath.map(item =>
          item.replace(TODDLERS_N_BABIES, TODDLERS_N_BABIES_AMPERSAND)
        ),
        selectedCategories
      );
    } else {
      const requestPathKey = [...quickFilterStepPath].pop();
      paramsArray = selectedCategories.map(
        category => ARABIC_REQUEST_PATH_MAP[requestPathKey] + " > " + category
      );
    }

    return "?" + search + paramsArray.join(",");
  };

  return async (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    dispatch(resetQuickFilterProducts());
    const { quickFilterReducer, common, page } = getState();
    const { selectedCategories, quickFilterStep, quickFilterData } =
      quickFilterReducer;
    const { countryId } = common.settings;
    const { language } = common;
    const { storeId } = page.homepageState;
    let { quickFilterStepPath } = quickFilterReducer;
    let pageUrl = "";
    const { redirectUrl } = get(
      quickFilterData,
      addDataFieldToPath(quickFilterStepPath)
    );

    const joinedPath = quickFilterStepPath.join("");
    const strArrayToCheck = [
      ...Object.values(SHOP_ALL[language]),
      QUICK_FILTER_ALL_PRODUCTS[language],
      QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES
    ];
    if (
      includesSubString(joinedPath, TODDLERS) &&
      !includesSubString(joinedPath, QUICK_FILTER_SHOES)
    ) {
      const search = checkAnyIncludes(
        selectedCategories.join(""),
        strArrayToCheck
      )
        ? ""
        : getDataSearchURL(
            prettifyToddlersPath(quickFilterStepPath),
            selectedCategories,
            language
          );
      const pageUrl = redirectUrl + search;

      const query = getStringifiedAdditionalInfoQuery(
        pageUrl,
        countryId,
        LANGUAGE_ROUTE_KEY_MAP[language],
        storeId
      );

      try {
        const response = await fetchWebApi(null, host + "?" + query);

        if (response.status === 200 && response.data) {
          const { data } = response.data;
          dispatch({
            type: APPLY_SELECTED_CATEGORIES,
            payload: { nextStep: quickFilterStep + 1 }
          });
          dispatch(setQuickFilterProducts(data, "sizeHierarchicalFilter.lvl2"));
        }

        return response;
      } catch (error) {
        handleFetchError(error, dispatch);
        return error.response;
      } finally {
        dispatch(hideLoadingIndicator());
      }
    } else {
      if (
        includesSubString(joinedPath, QUICK_FILTER_KIDS) &&
        (includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
          includesSubString(joinedPath, FOOTWEAR))
      ) {
        const [redirectWithoutSearch, searchFromRedirectURL] =
          getSplittedUrlArrayBySearch(redirectUrl);
        pageUrl = `${redirectWithoutSearch.slice(
          1
        )}?occasion=${selectedCategories.join(",")}${
          searchFromRedirectURL ? "&" + searchFromRedirectURL : ""
        }`;
      } else {
        pageUrl =
          redirectUrl.slice(1) +
          getDataSearchURL(quickFilterStepPath, selectedCategories, language);
      }

      if (checkAnyIncludes(pageUrl, strArrayToCheck)) {
        pageUrl = pageUrl.split("?")[0];
      }
      const query = queryString.stringify({
        url: urlFilterMap(pageUrl),
        countryId,
        language: LANGUAGE_ROUTE_KEY_MAP[language],
        storeId
      });
      try {
        const response = await fetchWebApi(null, host + "?" + query);
        if (response.status === 200 && response.data) {
          const { data } = response.data;
          dispatch(setQuickFilterProducts(data, "sizeHierarchicalFilter.lvl2"));

          dispatch({
            type: APPLY_SELECTED_CATEGORIES,
            payload: { nextStep: quickFilterStep + 1 }
          });
        }
        return response;
      } catch (error) {
        handleFetchError(error, dispatch);
        return error.response;
      } finally {
        dispatch(hideLoadingIndicator());
      }
    }
  };
};

export const parseProductsSizes = sizeFacetObj => ({
  type: PARSE_PRODUCTS_SIZES,
  payload: sizeFacetObj
});

export const getSelectedProductsPage = (history, baseURL) => {
  return (dispatch, getState) => {
    dispatch(setInitialState());
    const { quickFilterReducer, common } = getState();
    const { selectedCategories, selectedSizes, quickFilterData } =
      quickFilterReducer;
    const flattenSizes = selectedSizes.flat();
    let { quickFilterStepPath } = quickFilterReducer;
    const { language } = common;
    const strArrayToCheck = [
      ...Object.values(SHOP_ALL[language]),
      QUICK_FILTER_ALL_PRODUCTS[language],
      QUICK_FILTER_ALL_PRODUCTS_BOTH_LANGUAGES
    ];
    const isAllCategories = checkAnyIncludes(
      selectedCategories.join(""),
      strArrayToCheck
    );
    let historyPushObject = null;

    const joinedPath = quickFilterStepPath.join("");
    if (includesSubString(joinedPath, QUICK_FILTER_KIDS)) {
      const { redirectUrl } = get(
        quickFilterData,
        addDataFieldToPath(quickFilterStepPath)
      );
      let urlForHistory = redirectUrl;
      let filterSteps = quickFilterStepPath;
      if (
        includesSubString(joinedPath, TODDLERS_N_BABIES) &&
        !includesSubString(joinedPath, QUICK_FILTER_SHOES)
      ) {
        filterSteps = [
          ...quickFilterStepPath,
          ...(language === LANGUAGE_ROUTE_KEY.english
            ? [TODDLERS_N_BABIES_AMPERSAND]
            : [])
        ];
      }
      historyPushObject = getKidsQuickFilterHistoryPushObject(
        baseURL,
        filterSteps,
        !isAllCategories && selectedCategories,
        flattenSizes,
        urlForHistory,
        language
      );
    } else {
      historyPushObject = getQuickFilterHistoryPushObject(
        baseURL,
        quickFilterStepPath,
        !isAllCategories && selectedCategories,
        flattenSizes,
        language
      );
    }
    history.push(historyPushObject);
  };
};

export const getAdditionalFilterData =
  redirectUrl => async (dispatch, getState) => {
    const { protocol, baseURL, port, versionInfo, getPageHandle } =
      BEConfig.catalogApi;

    const host = `${protocol}${baseURL}${port}${versionInfo}${getPageHandle}`;

    dispatch(showLoadingIndicator());
    const { quickFilterReducer, common, page } = getState();
    const { quickFilterStepPath } = quickFilterReducer;
    const { countryId } = common.settings;
    const { language } = common;
    const { storeId } = page.homepageState;
    const joinedPath = quickFilterStepPath.join("");
    const isToddlersFlow = joinedPath.includes(TODDLERS);
    let additionalQueryParam = "";
    if (isToddlersFlow && includesSubString(joinedPath, QUICK_FILTER_SHOES)) {
      additionalQueryParam = `?ideal_for=${TODDLERS_IDEAL_FOR_PARAM_BY_PATH[joinedPath][language]}`;
    }
    const query = getStringifiedAdditionalInfoQuery(
      redirectUrl,
      countryId,
      LANGUAGE_ROUTE_KEY_MAP[language],
      storeId
    );

    try {
      const response = await fetchWebApi(
        null,
        host + "?" + query + additionalQueryParam
      );
      dispatch(hideLoadingIndicator());
      if (response.status === 200 && response.data) {
        const { data } = response.data;
        if (isToddlersFlow) {
          const { url } = response.data;
          const clothesFromResponse = get(
            data,
            ["facets", data.assistiveFilter || "hierarchicalCategories.lvl2"],
            {}
          );
          const clothesObj = Object.keys(clothesFromResponse).reduce(
            (acc, clothKey) => {
              const lastKeyParam = clothKey.split(" > ").pop();
              if (lastKeyParam !== TODDLERS_HIERARCHY_FOOTWEAR[language]) {
                acc[lastKeyParam] = {
                  [lastKeyParam]: clothesFromResponse[clothKey],
                  headerTitle: lastKeyParam
                };
              }
              return acc;
            },
            {}
          );

          dispatch(
            updateQuickFilterData(
              { children: clothesObj, redirectUrl: url },
              quickFilterStepPath
            )
          );
          dispatch(
            setQuickFilterStepData({ children: clothesObj, redirectUrl: url })
          );
        } else {
          const updateObj = get(
            data,
            ["facets", data.assistiveFilter],
            get(data, ["facets", "attributes.Occasion"])
          );
          const occasions = setObjectKeyAsAdditionalField(
            updateObj,
            "subTitle"
          );

          dispatch(
            updateQuickFilterData({ children: occasions }, quickFilterStepPath)
          );
          occasions &&
            dispatch(setQuickFilterStepData({ children: occasions }));
        }
      }
      return response;
    } catch (error) {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
      return error.response;
    }
  };
export const resetQuickFilterSelection = () => ({
  type: RESET_QUICK_FILTER_SELECTION
});

export const resetQuickFilter = () => ({ type: RESET_QUICK_FILTER });
