import React, { Fragment } from "react";
import classnames from "classnames";
import { getColorHex } from "../../../util";
import { Image } from "../../image";

import MobFilterItem from "../../mobile/m-filter/m-filterItem";

const FilterItem = ({
  facetName,
  subFacetName,
  subFacetValue,
  handleItemChecked,
  colors,
  language,
  storeList
}) => {
  const isColorFilter = facetName === "attributes.Color Family";
  const colorHex = getColorHex(colors, subFacetName, language);
  const isStoreFilter = facetName === "store";
  const storeItem =
    isStoreFilter &&
    storeList.find(
      item => item.title.toLowerCase() === subFacetName.toLowerCase()
    );

  return (
    <Fragment>
      <MobFilterItem
        facetName={facetName}
        subFacetName={subFacetName}
        subFacetValue={subFacetValue}
        handleToggle={handleItemChecked}
        colors={colors}
        language={language}
        storeList={storeList}
      />

      <li
        className={classnames(
          { checked: subFacetValue.checked },
          "right_check_label",
          "filter_item_web"
        )}
      >
        <label>
          <div className="filterSelectedContent">
            {isColorFilter && (
              <span
                className="color"
                style={{
                  backgroundColor: colorHex,
                  margin: language === "ar" ? "0 0 0 10px" : "0 10px 0 0"
                }}
              />
            )}
            {isStoreFilter &&
              storeItem && (
                <Image
                  className="store_img"
                  style={{
                    margin: language === "ar" ? "0 0 0 10px" : "0 10px 0 0"
                  }}
                  src={storeItem.iconImageLink}
                  alt={storeItem.title}
                />
              )}
            {`${subFacetName} ${
              isStoreFilter ? "" : `(${subFacetValue.value})`
            }`}
          </div>
          <span className="check_circle">
            <input
              id={`${facetName}:${subFacetName}`}
              type="checkbox"
              checked={Boolean(subFacetValue.checked)}
              onChange={handleItemChecked}
            />
          </span>
        </label>
      </li>
    </Fragment>
  );
};

export default FilterItem;
