import get from "lodash/get";
import { BEConfig } from "../config/env";
import { getState } from "../redux/store";
import throttle from "lodash/throttle";
import qs from "query-string";
import { selectCountrySlug, selectRouteSlug } from "../util/selectors";
import { webapiPost, webapiPut } from "../webapis/core";
import ApiService from "./ApiService";
import { getPaymentData } from "../util/tabby";
import { CustomerService } from ".";
import {
  LANGUAGE_ROUTE_KEY,
  PAYMENT_GATEWAYS,
  TABBY_PAYMENT_ID,
  TABBY_SNIPPET_URL
} from "../constants";

class TabbyService extends ApiService {
  cacheLifeTime = 1000 * 60 * 5;

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, checkoutHandle } =
      BEConfig.checkoutApi;
    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
    return url;
  }

  startTabbySession = async params => {
    const body = await getPaymentData(params);
    const {
      checkoutApi: { tabbySession }
    } = BEConfig;

    const url = `${this.getBaseUrl()}${tabbySession}`;

    try {
      const response = await webapiPost(
        this.getAccessToken(),
        url,
        body,
        this.getVisitorId(),
        this.getLanguage()
      ).request;

      if (response.status === 200) {
        if (response.data.status === "created") {
          localStorage.setItem(TABBY_PAYMENT_ID, response.data.payment.id);
          window.location =
            response.data.configuration.available_products.installments[0].web_url;
          return response.data.status;
        } else if (response.data.status === "rejected") {
          return response.data.status;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  getOrders = throttle(
    async () => {
      try {
        const maxOrderHistoryItems = 10;

        const data = await CustomerService.getOrderList({
          pageSize: maxOrderHistoryItems
        });

        return data.order;
      } catch {
        return [];
      }
    },
    this.cacheLifeTime,
    { trailing: false }
  );

  getMerchantCode() {
    const state = getState();
    const countrySlug = selectCountrySlug(state);

    if (countrySlug === "ae") return "bfluae";
    if (countrySlug === "sa") return "bflksa";
  }

  getMerchantUrls = (createdOrderResponse, orderBody) => {
    const state = getState();
    const routeSlug = selectRouteSlug(state);
    const originLocation = window.location.origin;
    const { countryId } = state.common.settings;
    const { _id } = state.checkoutReducer.checkoutData;

    const successPageUrl = `${originLocation}/${routeSlug}/order-success/${
      createdOrderResponse?.orderId
    }?${qs.stringify({
      continueAsGuest: orderBody.continueAsGuest || "",
      countryId,
      currencyCode: orderBody.paymentCurrency,
      isPaymentSuccess: true,
      paymentGateWay: PAYMENT_GATEWAYS.TABBY,
      paymentToken: createdOrderResponse.paymentToken,
      transactionOrderTotal: orderBody.paymentAmount,
      paymentReferenceNo: createdOrderResponse.paymentToken
    })}`;

    const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;
    const cancelUrl = `${originLocation}/${routeSlug}/checkout/${_id}`;

    return {
      success: successPageUrl,
      failure: failedPageUrl,
      cancel: cancelUrl
    };
  };

  verifyPayment = async payment_id => {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.checkoutApi.tabbyVerifyPayment
    }/${payment_id}`;

    const response = await webapiPut(
      this.getAccessToken(),
      endpoint,
      {},
      this.getVisitorId()
    ).request;

    return response.data;
  };

  // Tabby Checkout Snippet

  loadCheckoutScript = (option = {}) => {
    const state = getState();
    return new Promise((res, rej) => {
      {
        const script = document.createElement("script");

        script.src = TABBY_SNIPPET_URL;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          if (Object.keys(option).length > 0)
            new TabbyCard({
              selector: "#tabbyCard",
              currency: option?.currency,
              lang: get(state, "common.language", LANGUAGE_ROUTE_KEY.english),
              price: option?.totalAmount,
              size: "wide",
              theme: "black",
              header: false
            });

          res(script);
        };
      }
    });
  };
}

const instance = new TabbyService();

export default instance;
