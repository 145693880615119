export const catalogCarousalSwiperConfig = {
  dots: true,
  slidesPerView: 3.3,
  rebuildOnUpdate: true,
  spaceBetween: 10,
  breakpoints: {
    1024: {
      slidesPerView: 3.4,
      spaceBetween: 10
    },
    800: {
      slidesPerView: 3.3,
      spaceBetween: 10
    },
    600: {
      slidesPerView: 3.15,
      spaceBetween: 8
    },

    425: {
      slidesPerView: 2.15,
      spaceBetween: 8
    },

    320: {
      slidesPerView: 2.15,
      spaceBetween: 5
    }
  }
};
