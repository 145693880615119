import React from "react";

const QuestionAboutList = ({
  id,
  listId,
  innerText,
  handleSelectList,
  groupName,
  handleChange,
  translation
}) => (
  <li
    className={
      !!listId && listId === id
        ? "checked right_check_label"
        : "right_check_label"
    }
    onClick={e => {
      handleSelectList(id, groupName, innerText, e);
    }}
  >
    <span>{innerText}</span>
    {!!listId ? (
      <span
        className="change"
        onClick={e => {
          e.stopPropagation();
          handleChange(groupName);
        }}
      >
        {translation.change}
      </span>
    ) : (
      <span
        className="check_circle_radio"
        onClick={() => {
          handleSelectList(id, groupName, innerText);
        }}
      >
        <input id={id} type="radio" name={groupName} />
      </span>
    )}
  </li>
);

export default QuestionAboutList;
