import validator from "validator";

import {
  ADDRESS_CHANGE,
  SET_ADDRESS_DATA,
  REQUIRED_EN,
  MINIMUM_TWO_CHARS_EN,
  NUMBER_EN,
  RESET_ADDRESS,
  MAP_DELIVERY_ADDRESS,
  COMMON_DATA
} from "../constants";

const initialState = {
  firstname: "",
  lastname: "",
  cityId: "",
  area: "",
  areaId: null,
  countryId: "",
  addressLine1: "",
  phone: "",
  default: false,
  collectionPointId: null,
  dialCode: "",
  areaCode: "",
  errors: {
    firstname: {
      valid: true,
      validWhen: true,
      check: "isByteLength",
      message: MINIMUM_TWO_CHARS_EN,
      options: {
        min: 2,
        max: undefined
      }
    },
    cityId: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    addressLine1: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    areaId: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    phone: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: NUMBER_EN
    },
    zipcode: {
      valid: true,
      validWhen: true,
      check: "isLength",
      options: {
        min: 5,
        max: 5
      }
    }
  }
};

const handleTextChange = (state, action) => {
  let transformedState = {
    ...state,
    [action.name]: typeof action.value === "undefined" ? "" : action.value
  };
  if (transformedState.errors[action.name]) {
    transformedState.errors = {
      ...state.errors,
      [action.name]: state.errors[action.name]
        ? {
            ...state.errors[action.name],
            touched: true,
            valid: state.errors[action.name].check
              ? validator[state.errors[action.name].check](
                  action.value ? action.value.toString().trim() : "",
                  state.errors[action.name].options
                ) === state.errors[action.name].validWhen &&
                (typeof action.status !== "undefined" ? action.status : true)
              : true
          }
        : { touched: true }
    };
  }
  return transformedState;
};

const setAddressData = (state, { address }) => ({
  ...state,
  ...address
});

const setPickUpAddress = (state, { address }) => ({
  ...initialState,
  firstname: address.station_name,
  areaId: address.bfl_area_id,
  countryId: address.countryId,
  addressLine1: address.address_info,
  collectionPointId: address.station_id,
  phone: address.phone,
  area: address.area_name
});

const resetAddress = () => ({ ...initialState });

const setCountryAddressData = (state, action) => {
  const { settings } = action;
  const addressData = {};

  if (settings && settings.countryId && settings.mobileCountryCode) {
    const { countryId, mobileCountryCode, mobileLocalCode } = settings;

    addressData.countryId = countryId;
    addressData.dialCode = `${mobileCountryCode}`;
    addressData.areaCode = `${mobileLocalCode &&
      mobileLocalCode.split(",")[0]}`;
  }

  return {
    ...state,
    ...addressData
  };
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDRESS_CHANGE:
      return handleTextChange(state, action);
    case SET_ADDRESS_DATA:
      return setAddressData(state, action);
    case RESET_ADDRESS:
      return resetAddress(state);
    case MAP_DELIVERY_ADDRESS:
      return setPickUpAddress(state, action);
    case COMMON_DATA:
      return setCountryAddressData(state, action);
    default:
      return state;
  }
};

export default addressReducer;
