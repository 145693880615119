import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";
import { selectRouteSlug } from "../../../util/selectors";
import { hideMegaMenu, highlight } from "../../../util/dom";

import List from "./list";
import { GAService } from "../../../services/GA-service";

const MenuList = ({ listItem, countryShort, language, routeSlug }) => {
  const {
    subTitle,
    redirectUrl,
    bold,
    children,
    isMarginClass,
    color: { value }
  } = listItem;
  const linkUrl = `/${routeSlug}${redirectUrl}`;
  return (
    <div
      className={cn("subTitle-wrapper", {
        list: children && Boolean(children.length),
        "margin-bottom": isMarginClass
      })}
    >
      <p className="subTitle" onClick={highlight}>
        <Link to={linkUrl}>
          <span
            style={{
              color: value,
              fontFamily: `${
                !!bold
                  ? "'FuturaPT-Demi', Helvetica, Arial, sans-serif"
                  : "'Futura PT Book', Helvetica, Arial, sans-serif"
              }`
            }}
            onClick={e => {
              GAService.header.trackLevel1SubMenuClick(listItem, linkUrl);
              hideMegaMenu(e);
            }}
          >
            {subTitle}
          </span>
        </Link>
      </p>
      {Boolean(children && children.length) && (
        <ul>
          {children.map(item => (
            <List
              item={item}
              key={item.uid}
              countryShort={countryShort}
              language={language}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(MenuList);
