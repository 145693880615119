import { LANGUAGE } from "../../constants";
import { BROWSER_STORAGE } from "../../redux/constants";
import { _countryStoreSelection, languageFromPathName } from "../../util";
import {
  changeLanguage,
  getConfigurationSettings,
  getCountrySettings,
  setCommonData
} from "../../redux/actions/common.action";
import { fetchAndSetBrandPageUrlsFromSeoSafeUrls } from "../../redux/actions/common.action";
import { getBrandsListProductsData } from "../../redux/actions/productlisting.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getPageApi } from "../../redux/actions/page.action";
import { handleSectionsTask } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;
      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  },
  settings: {
    asyncTask: ({ store, params = {}, url }) => {
      const { language } = params;
      return store.dispatch(
        getCountrySettings(languageFromPathName(language), null, url)
      );
    },
    priority: 2
  },
  fetchHomePage: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getPageApi(
          "/",
          language,
          _country && _country.countryId,
          storeId || _bflStoreId,
          null,
          cookies
        )
      );
    },
    priority: 4
  },
  fetchAndSetBrandPageUrlsFromSeoSafeUrls: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }
      const { language } = params;
      const _language =
        language.includes("ar-") || language.includes("ar")
          ? LANGUAGE.arabic
          : LANGUAGE.english;
      let categoryName, brandName;
      categoryName = params.categoryName;
      brandName = params.brandName;
      return store.dispatch(
        fetchAndSetBrandPageUrlsFromSeoSafeUrls(
          categoryName,
          brandName,
          _language
        )
      );
    },
    priority: 5
  },
  getBrandsListProductsData: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }
      const { language } = params;
      const _language =
        language.includes("ar-") || language.includes("ar")
          ? LANGUAGE.arabic
          : LANGUAGE.english;
      let _query = [];
      Object.keys(queryParams).forEach(key => {
        _query.push(`${key}=${queryParams[key]}`);
      });

      _query = _query.join("&");
      _query = _query ? `?${_query}` : _query;

      return store.dispatch(getBrandsListProductsData(_query, _language));
    },
    priority: 6
  },
  fetchMegaMenu: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getMegaMenuData(
          languageFromPathName(language),
          storeId || _bflStoreId,
          _country && _country.countryId,
          cookies
        )
      );
    },
    priority: 7
  },
  fetchHeaderSectins: {
    asyncTask: arg => handleSectionsTask(arg, SECTION_SLUGS.HEADER),
    priority: 8
  },
  configuration: {
    asyncTask: ({ store }) => store.dispatch(getConfigurationSettings()),
    priority: 9
  }
};
