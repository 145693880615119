import React, { Component, Fragment } from "react";

class ProductOverlayTag extends Component {
  render() {
    const {
      overlayLabel,
      overlayTagBgColor,
      overlayTagLabelColor
    } = this.props;
    return (
      <Fragment>
        {overlayLabel ? (
          <span
            className="product_tag product_overlay_tag"
            style={{
              backgroundColor: overlayTagBgColor
                ? `${overlayTagBgColor}`
                : "none",
              color: overlayTagLabelColor ? `${overlayTagLabelColor}` : `none`
            }}
          >
            {" "}
            {overlayLabel}{" "}
          </span>
        ) : null}
      </Fragment>
    );
  }
}

export default ProductOverlayTag;
