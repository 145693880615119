import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { createSelector } from "reselect";
import shuffle from "lodash/shuffle";

import { selectLanguage, selectLanguageSlug } from "../../../util/selectors";
import { withClientOnlyRender } from "../../../hocs";

import { changeLanguage } from "../../../redux/actions/common.action";

import { productListing } from "../../../language/productListing/en/productListing";

import CatalogProductCarousel from "./catalog-product-carousel";
import { isMobile } from "../../../util";

class ProductCarousel extends PureComponent {
  state = {
    productTranslation: productListing
  };

  componentDidMount() {
    const { languageSlug, handleLanguageChange, match } = this.props;

    handleLanguageChange(match.params.language);
    this.loadTranslation(languageSlug);
  }

  componentDidUpdate(prevProps) {
    const { languageSlug } = this.props;

    if (prevProps.languageSlug !== languageSlug) {
      this.loadTranslation(languageSlug);
    }
  }

  loadTranslation = async language => {
    const translation = await import(
      `../../../language/productListing/${language}/productListing`
    );

    this.setState({ productTranslation: translation.productListing });
  };

  truncateProductTitle = (title, characters) => {
    if (title && title.length > characters)
      return title.substring(0, characters) + "...";
    else {
      return title;
    }
  };

  getImageList = createSelector(
    data =>
      data && Array.isArray(data.imageList) && shuffle([...data.imageList]),
    imageList => imageList || []
  );

  render() {
    const { content, bannerModuleName, bannerPageTitle, isSaleCarousel } =
      this.props;

    const isNotAvailable =
      isMobile.any() && !content?.enabledOn?.includes("resp");

    if (isNotAvailable) {
      return null;
    }

    return (
      <CatalogProductCarousel
        getImageList={this.getImageList}
        productTranslation={this.state.productTranslation}
        content={content}
        bannerModuleName={bannerModuleName}
        bannerPageTitle={bannerPageTitle}
        isSaleCarousel={isSaleCarousel}
      />
    );
  }
}

const mapStateToProps = store => ({
  language: selectLanguage(store),
  languageSlug: selectLanguageSlug(store)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage
};

export default compose(
  withClientOnlyRender,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductCarousel);
