const swiperParams = {
  dots: true,
  slidesPerView: 3,
  rebuildOnUpdate: true,
  spaceBetween: 15,
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 13
    },
    600: {
      slidesPerView: 2.5,
      spaceBetween: 10
    },
    320: {
      slidesPerView: 2.5,
      spaceBetween: 10
    }
  },

  navigation: {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  }
};

export default swiperParams;
