import { fetchWebApi } from "../../webapis/apiResource";
import { GET_SIZE_CHART } from "../constants";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";

export const getSizeChart = content => ({ type: GET_SIZE_CHART, content });

export const fetchSizeChartData = language => {
  const apiURL = `${BEConfig.catalogApi.protocol}${
    BEConfig.catalogApi.baseURL
  }${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${
    BEConfig.catalogApi.sizeChartHandle
  }?language=${language}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(null, apiURL)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(getSizeChart(response.data.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
