import React, { Component } from "react";
import cn from "classnames";
import { CircleFlag } from "react-circle-flags";

class AccountSettings extends Component {
  state = {
    showLanguageList: false
  };

  openLink = redirectLink => {
    const { openLink } = this.props;

    if (redirectLink !== whatsAppUrl) {
      openLink(redirectLink);
    } else {
      window.open(redirectLink, "_blank");
    }
  };

  openCountryModal = () => {
    const { closeMenu, openCountryModal } = this.props;

    closeMenu();
    openCountryModal();
  };

  logOut = history => {
    const { closeMenu, logOut } = this.props;

    closeMenu();
    logOut(history, true);
  };

  toggleLanguageList = () => {
    const { toggleLanguageList } = this.props;
    const { showLanguageList } = this.state;

    this.setState({ showLanguageList: !showLanguageList });
    toggleLanguageList(!showLanguageList);
  };

  changeLanguage(lang) {
    const { language, toggleLanguage, closeMenu } = this.props;

    if (lang !== language) {
      toggleLanguage();
    }
    this.toggleLanguageList();
    closeMenu();
  }

  getDataList() {
    const {
      userLoggedIn,
      language,
      countryCode,
      countryShort,
      megaMenuTranslation,
      history
    } = this.props;

    const dataList = [
      {
        id: 1,
        heading: megaMenuTranslation.myAccount,
        redirectionUrl: userLoggedIn
          ? "/my-account/account-overview/"
          : "/login/?my-account/account-overview/",
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 2,
        heading: megaMenuTranslation.myOrders,
        redirectionUrl: userLoggedIn
          ? "/my-account/my-orders/"
          : "/login/?my-account/my-orders/",
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 3,
        heading: megaMenuTranslation.trackOrder,
        redirectionUrl: userLoggedIn
          ? "/my-account/my-orders/"
          : "/track-order/",
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 4,
        heading: megaMenuTranslation.needHelp,
        redirectionUrl: "/need-help/",
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 5,
        heading: megaMenuTranslation.contactUs,
        redirectionUrl: "/send-us-email/",
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 6,
        heading: megaMenuTranslation.whatsApp,
        redirectionUrl: whatsAppUrl,
        showWithoutLogin: true,
        value: "",
        onClick: this.openLink
      },
      {
        id: 7,
        heading: megaMenuTranslation.country,
        redirectionUrl: "",
        showWithoutLogin: true,
        value: countryCode,
        flagClass: countryShort && countryShort.toLowerCase(),
        onClick: this.openCountryModal
      },
      {
        id: 8,
        heading: megaMenuTranslation.language,
        redirectionUrl: "",
        showWithoutLogin: true,
        value: language.includes("ar") ? "عربى" : "ENG",
        onClick: this.toggleLanguageList
      },
      {
        id: 9,
        heading: megaMenuTranslation.signOut,
        redirectionUrl: "",
        showWithoutLogin: false,
        value: "",
        onClick: () => this.logOut(history)
      }
    ];

    return dataList;
  }

  render() {
    const { userLoggedIn, language, megaMenuTranslation } = this.props;
    const { showLanguageList } = this.state;

    const dataList = this.getDataList();

    if (showLanguageList)
      return (
        <ul className="sub_categories_wrapper languageList">
          <h3>
            <span className="back_btn" onClick={this.toggleLanguageList} />

            {megaMenuTranslation.language}
          </h3>

          {languageList.map(item => (
            <li
              className={
                language === item.languageCode
                  ? "language_switch"
                  : "language_not_selected"
              }
              key={item.id}
              onClick={() => this.changeLanguage(item.languageCode)}
            >
              {item.language}
            </li>
          ))}
        </ul>
      );

    return (
      <ul className="account_setting_wrapper">
        {dataList
          .filter(data => userLoggedIn || data.showWithoutLogin)
          .map(item => {
            const hasFlag = item.id === 7;

            return (
              <li
                key={item.id}
                className={cn({ country_flag: hasFlag })}
                onClick={() => item.onClick(item.redirectionUrl)}
              >
                <p>
                  {item.heading}{" "}
                  {hasFlag && (
                    <span>
                      <CircleFlag
                        countryCode={item.flagClass?.toLowerCase()}
                        width={18}
                        className="country_image"
                      />
                      {item.value}
                    </span>
                  )}{" "}
                  {item.id === 8 && <span>{item.value}</span>}
                </p>
              </li>
            );
          })}
      </ul>
    );
  }
}

const languageList = [
  { id: 1, language: "English", languageCode: "en" },
  { id: 2, language: "عربى", languageCode: "ar" }
];

const whatsAppUrl = "https://wa.me/9718008333";

export default AccountSettings;
