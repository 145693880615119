import React from "react";
import Content from "./content";

const RightPanel = ({
  currentTab,
  navText,
  myAccountTranslation,
  checkoutTranslation,
  checkoutTranslationEn,
  isToggle,
  selectList,
  resetPasswordTranslation,
  language,
  orderSuccessTranslation,
  subTab,
  noRecordImgUrl,
  handleGoBack
}) => (
  <div className={`right-wrapper m-t-10 ${isToggle ? "showRightPanel" : ""}`}>
    <Content
      currentTab={currentTab}
      subTab={subTab}
      navText={navText}
      myAccountTranslation={myAccountTranslation}
      checkoutTranslation={checkoutTranslation}
      checkoutTranslationEn={checkoutTranslationEn}
      selectList={selectList}
      resetPasswordTranslation={resetPasswordTranslation}
      language={language}
      orderSuccessTranslation={orderSuccessTranslation}
      noRecordImgUrl={noRecordImgUrl}
      handleGoBack={handleGoBack}
    />
  </div>
);

export default RightPanel;
