export const needHelp = {
  howCanWeHelp: "How can we help?",
  findRightAnswer: "Find the right answer",
  trackingShippingAndDelivery: "Tracking, Shipping & Delivery",
  loyaltyProgram: "Loyalty Program",
  productAndStock: "Products & Stock",
  paymentAndVouchers: "Payment & Vouchers",
  orderIssues: "Order Issues",
  general: "General",
  chatWithUs: "Chat with us",
  sendUsAnEmail: "Send us an email",
  connectOnMessenger: "Connect on Messenger",
  connectOnFacebook: "Connect on Facebook",
  viewAll: "VIEW ALL",
  stillNeedHelp: "Still need help? Don’t worry…",
  wereHereToHelp: "We’re here to help",
  frequentlyAskedQuestions: "Frequently Asked Questions",
  topics: "Topics",
  needHelp: "Need Help"
};
