import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";

import { selectRouteSlug } from "../../util/selectors";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

import Page from "../../components/page";
import SignupModal from "../../components/modal/signup";
import SocialLoginModal from "../../components/modal/social-login";
import { loginTranslation } from "../../language/login/en/login";
import { signUpTranslation } from "../../language/signUp/en/signUp";
import { myAccountTranslation } from "../../language/myAccount/en/myAccount";
import {
  changeLanguage,
  getConfigurationSettings,
  getCountrySettings
} from "../../redux/actions/common.action";
import JoinSignToggle from "../../components/join-sign-toggle";
import { seoTranslation } from "../../language/seo/en/seo";
import { GAService } from "../../services/GA-service";

class SignUpPage extends Component {
  state = {
    loginTranslation: loginTranslation,
    signUpTranslation: signUpTranslation,
    myAccountTranslation
  };

  componentDidMount() {
    const {
      handleLanguageChange,
      match,
      language,
      countryData,
      getCountrySettings,
      getConfigurationSettings
    } = this.props;
    let languageParam = get(match, "params.language", language);
    if (languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);

    this.loadLanguage({ language: languageParam });
    if (isEmpty(countryData)) {
      getCountrySettings();
      getConfigurationSettings();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps);
    }
  }

  loadLanguage(nextProps) {
    let language = nextProps.language;
    if (language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }

    return Promise.all([
      import(`../../language/login/${language}/login.js`),
      import(`../../language/signUp/${language}/signUp.js`),
      import(`../../language/myAccount/${language}/myAccount.js`)
    ]).then(responses => {
      const [languageResponse, signUpResponse, myAccountResponse] = responses;
      this.setState({
        loginTranslation: languageResponse.loginTranslation,
        signUpTranslation: signUpResponse.signUpTranslation,
        myAccountTranslation: myAccountResponse.myAccountTranslation
      });
    });
  }

  toggleSignInTab = () => {
    const { history, routeSlug } = this.props;

    history.push(`/${routeSlug}/login/`);
    GAService.auth.trackSignCTA(true);
  };

  render() {
    const { language, history, location, routeSlug } = this.props;
    const { loginTranslation, signUpTranslation, myAccountTranslation } =
      this.state;

    return (
      <Page
        title={seoTranslation.joinTitle}
        meta={{
          [language]: { metaDescription: seoTranslation.defaultPageDescription }
        }}
        id="signup"
      >
        <div
          className={cn(
            "register_user_page",
            language.includes("ar") && "arabic"
          )}
        >
          <div className="signup_layout footer-included">
            <JoinSignToggle
              loginButtonText={loginTranslation.signIn}
              signupButtonText={loginTranslation.join}
            />
            <div className="padding_with_footer pb-0">
              <SignupModal
                translation={signUpTranslation}
                myAccountTranslation={myAccountTranslation}
              />
              <p className="signUp_conditions">
                {loginTranslation.youAgreeTo}{" "}
                <a
                  href={`/${routeSlug}/terms-conditions/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {loginTranslation.termsAndConditions}
                </a>{" "}
                &{" "}
                <a
                  href={`/${routeSlug}/privacy-policy/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {loginTranslation.privacyPolicy}
                </a>{" "}
              </p>
            </div>
            <SocialLoginModal
              translation={loginTranslation}
              language={language}
              history={history}
              textFor="join"
              redirection={
                location.search && location.search.split("?")[1]
                  ? `/${routeSlug}`
                  : `/${routeSlug}/`
              }
            />
            <div className="login_footer">
              <p>
                {loginTranslation.alreadyHaveAccount}{" "}
                <span onClick={this.toggleSignInTab}>
                  {loginTranslation.signInAccount}
                </span>
              </p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  routeSlug: selectRouteSlug(state),
  countryData: state.common.countryData
});

const mapStateToDispatch = {
  handleLanguageChange: changeLanguage,
  getCountrySettings,
  getConfigurationSettings
};

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapStateToDispatch)
)(SignUpPage);
