import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import {
  BACKGROUND_TYPE,
  BANNER_ROW_TYPE,
  HOMEPAGE_SECTION_TYPES
} from "../../../constants";
import { getDeviceType, isMobile, isServer } from "../../../util";
import {
  selectCountrySlug,
  selectIsArabic,
  selectLanguage
} from "../../../util/selectors";
import ProductCarousel from "../../product/product-carousel";
import { DynamicSlider } from "../dynamic-slider";
import CarouselWrapperRow from "../dynamic-carousel";
import DynamicSingleBanner from "../dynamic-single-banner";
import DynamicModule from "../dynamic-module";
import { getPrevModeStorage } from "../../../util/browserStorage";
import CountdownWrapper from "../../countdown-wrapper";
import DynamicHTMLBanner from "../dynamic-html-banner";
import { getState } from "../../../redux/store";
import BannerClickAnalyticHandler from "../../banner/bannerClickAnalyticHandler";

const removeBannerWrapper = id => {
  const rowSection = !isServer && document.getElementById(id);

  if (rowSection) {
    rowSection.classList.remove("d-flex");
    rowSection.classList.add("d-none");
  }
};

const removeCountdown = (id, countDownId) => {
  const rowSection = !isServer && document.getElementById(id);
  const bannerSection =
    !isServer &&
    rowSection &&
    rowSection.querySelector(`#countdown-${countDownId}`);

  if (bannerSection) {
    bannerSection.style.display = "none";
  }
};

const BannerRow = ({
  rowData,
  bannerModuleName,
  bannerPageTitle,
  bannerSlug
}) => {
  const { imageList, styles, width } = rowData;

  const language = useSelector(selectLanguage);

  const rowStyles = {
    ...styles,
    width: width?.value || "100%",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    background: styles.backgroundColor || "none"
  };

  switch (rowData.bannerType) {
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_CAROUSEL:
      return (
        <CarouselWrapperRow
          rowData={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
        />
      );
    case HOMEPAGE_SECTION_TYPES.SLIDER:
      return (
        <DynamicSlider
          section={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
        />
      );

    case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
      return (
        <ProductCarousel
          content={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
          isSaleCarousel={false}
        />
      );

    case HOMEPAGE_SECTION_TYPES.CUSTOM:
      return <DynamicModule content={rowData} />;

    case HOMEPAGE_SECTION_TYPES.HTML:
      return (
        <DynamicHTMLBanner
          content={rowData}
          language={language}
          bannerSlug={bannerSlug}
        />
      );

    case HOMEPAGE_SECTION_TYPES.SINGLE:
      return getSingleBanner(
        imageList,
        rowStyles,
        rowData.id,
        language,
        bannerModuleName,
        rowData.title,
        bannerPageTitle
      );

    case HOMEPAGE_SECTION_TYPES.FLASH_SALE_CAROUSEL:
      return (
        <ProductCarousel
          content={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
          isSaleCarousel
        />
      );

    default:
      return null;
  }
};

const getSingleBanner = (
  imageList,
  rowStyles,
  rowDataId,
  language,
  bannerModuleName,
  rowTitle,
  bannerPageTitle
) => {
  const imageListLength = imageList.length;
  const includesCountDown = imageList.some(
    imageData => imageData.type === BANNER_ROW_TYPE.COUNTDOWN
  );
  return (
    <div
      className={cn("banner-row", "d-flex")}
      style={rowStyles}
      id={rowDataId}
    >
      {imageList.map((imageData, index) => {
        const isNotAvailable =
          isMobile.any() &&
          !imageData[language]?.enabledOn?.includes("resp") &&
          imageData.type !== BANNER_ROW_TYPE.COUNTDOWN;

        if (isNotAvailable) {
          return null;
        }

        if (imageData.type === BANNER_ROW_TYPE.COUNTDOWN) {
          return (
            <CountdownWrapper
              key={index}
              content={imageData}
              imageListLength={imageListLength}
              removeBannerWrapper={() => removeBannerWrapper(rowDataId)}
              removeCountdown={countDownId =>
                removeCountdown(rowDataId, countDownId)
              }
            />
          );
        }
        const itemsAmountToKeepCountdownNotLessHalfWidth =
          imageListLength > 2
            ? imageListLength + imageListLength / 2
            : imageListLength;
        return (
          <DynamicSingleBanner
            key={index}
            data={imageData}
            imageListLength={
              includesCountDown
                ? itemsAmountToKeepCountdownNotLessHalfWidth
                : imageListLength
            }
            index={index}
            bannerModuleName={bannerModuleName}
            rowTitle={rowTitle}
            bannerPageTitle={bannerPageTitle}
          />
        );
      })}
    </div>
  );
};

const getBackgroundForBanner = styles => {
  switch (styles.backgroundType) {
    case BACKGROUND_TYPE.COLOR:
      return { background: styles.backgroundColor };

    case BACKGROUND_TYPE.GRADIENT:
      return { background: styles.backgroundGradient };
    case BACKGROUND_TYPE.IMAGE:
      return {
        backgroundImage: `url(${styles.backgroundImage})`
      };

    default:
      break;
  }
};

const DynamicBanner = ({ bannerData, bannerPageTitle }) => {
  const deviceTypeSSR = get(getState(), "common.deviceType", "");
  const deviceType = deviceTypeSSR || getDeviceType();
  const { id, enable } = bannerData || {};
  const deviceSpecifiedData = bannerData[deviceType];
  if (!deviceSpecifiedData || !enable) return null;
  const { styles } = deviceSpecifiedData;
  const isPrevMode = getPrevModeStorage();
  const countrySlug = useSelector(state => selectCountrySlug(state));
  const isArabic = useSelector(selectIsArabic);
  const background = getBackgroundForBanner(styles);
  const pageName = bannerPageTitle;
  const pageUrl = !isServer && window.location.href;
  const wrapperStyles = {
    ...styles,
    ...background
  };

  return (
    <div
      className={cn("dynamic_banner", {
        prevMode: isPrevMode,
        arabic: isArabic
      })}
      style={wrapperStyles}
    >
      {isPrevMode && (
        <div className="prev_mode_banner_name">{bannerData?.title || ""}</div>
      )}
      {deviceSpecifiedData.rows.map((rowData, index) => (
        <BannerClickAnalyticHandler
          key={id + index}
          bannerModuleName={bannerData?.title}
          rowModuleName={rowData.title}
          countrySlug={countrySlug}
          pageName={pageName}
          pageUrl={pageUrl}
        >
          <BannerRow
            rowData={rowData}
            bannerModuleName={bannerData?.title}
            bannerPageTitle={pageName}
            bannerSlug={bannerData?.slug}
          />
        </BannerClickAnalyticHandler>
      ))}
    </div>
  );
};

const DynamicBannersWrapper = props => {
  const data = { ...props.section };
  return (
    <DynamicBanner bannerData={data} bannerPageTitle={props.bannerPageTitle} />
  );
};

export default DynamicBannersWrapper;
