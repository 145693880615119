import React, { Component } from "react";
import Slider from "react-slick";
import { Image } from "../../image";
import { isServer } from "../../../util";

class StoreLocatorThumbSwiper extends Component {
  state = {
    gallerySwiper: null,
    thumbnailSwiper: null,
    nav1: null,
    nav2: null
  };
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const slider1_setting = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true
          }
        }
      ]
    };

    const slider2_setting = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      rebuildOnUpdate: true,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      spaceBetween: 15,
      centerMode: false
    };

    const screenWidth = !isServer && document.body.clientWidth;
    const { selectedShop } = this.props;
    let _galleryImages = [];
    if (selectedShop.shopImageUrl) _galleryImages = [selectedShop.shopImageUrl];
    if (selectedShop.galleryImages)
      _galleryImages = [..._galleryImages, ...selectedShop.galleryImages];
    return (
      <div>
        <Slider
          {...slider1_setting}
          className="swiper-container store-locator-gallery-container"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
          {_galleryImages &&
            _galleryImages.map((item, index) => (
              <Image src={`${item.img || item}`} alt="image" key={index} />
            ))}
        </Slider>
        {screenWidth > 767 && (
          <Slider
            {...slider2_setting}
            className="store-locator-thumbnail-container"
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
          >
            {_galleryImages &&
              _galleryImages.map((item, index) => (
                <Image src={`${item.img || item}`} alt="image" key={index} />
              ))}
          </Slider>
        )}
      </div>
    );
  }
}

export default StoreLocatorThumbSwiper;
