import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { selectRouteSlug } from "../../util/selectors";
import { TEMPLATE_HTML_KEYS } from "../../constants/html/templateHtmlKeys.constant";
import { templateHtmlParser } from "../../util/templateHtmlParser";
// import { addCustomCountdown } from "../../util/custom-countdown";

const HtmlParser = React.memo(({ html = "", routeSlug }) => {
  const replaceKeysMap = {
    [TEMPLATE_HTML_KEYS.COUNTRY_PREFIX]: routeSlug
  };
  useEffect(() => {
    // addCustomCountdown(html);
  }, [html]);

  return (
    <React.Fragment>
      {ReactHtmlParser(templateHtmlParser(html, replaceKeysMap))}
    </React.Fragment>
  );
});

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(HtmlParser);
