import React, { memo, useState } from "react";
import LazyLoad from "react-lazy-load";
import loaderIcon from "../../images/loading-icon.svg";

const LazyImage = ({
  imageURL,
  onImageLoad,
  loader = <img src={loaderIcon} alt="loader-icon" className="loader-icon" />
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <>
      {imageLoading && loader}
      <LazyLoad
        debounce={false}
        offsetTop={500}
        offsetBottom={500}
        className="lazy-load-wrapper"
      >
        <img
          src={imageURL}
          alt="banner"
          className="banner-image"
          onLoad={() => {
            onImageLoad && onImageLoad();
            setImageLoading(false);
          }}
        />
      </LazyLoad>
    </>
  );
};

export default memo(LazyImage);
