import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { hidePageLoader } from "../redux/actions/common.action";
import { confirmOrder } from "../redux/actions/order.action";
import queryString from "query-string";
import { selectRouteSlug } from "../util/selectors";
import { languageFromPathName } from "../util";
import get from "lodash/get";

const areebaScriptPath =
  "https://epayment.areeba.com/checkout/version/55/checkout.js";
class AreebaIncluded extends Component {
  handleError = e => {
    console.log(`error areeba`, e);
  };

  handleCancel = () => {
    const { hidePageLoader } = this.props;

    hidePageLoader();
  };

  redirectToSuccessPage(orderId) {
    const { history, order, routeSlug } = this.props;

    history.push(
      `/${routeSlug}/order-success/${orderId}?${queryString.stringify({
        continueAsGuest: order.continueAsGuest || ""
      })}`
    );
  }

  handleComplete = () => {
    const {
      confirmOrder,
      history,
      hidePageLoader,
      order,
      checkoutRequestData,
      language
    } = this.props;
    const {
      transactionOrderTotal,
      currencyCode,
      countryId
    } = checkoutRequestData;
    const { paymentToken, orderId } = order;
    const lang = languageFromPathName(get(document, "location.pathname", ""));
    const bodyTag = document.getElementsByTagName("body")[0];

    if (bodyTag.style.position === "fixed") {
      bodyTag.style.position = "static";
    }

    confirmOrder(
      {
        lang,
        transactionOrderTotal,
        paymentReferenceNo: paymentToken,
        paymentGateWay: "AREEBA",
        orderId,
        paymentToken,
        cardName: "",
        cardNumber: "",
        currencyCode,
        isPaymentSuccess: true,
        countryId
      },
      history,
      language,
      true
    )
      .then(response => {
        if (response.status === 200 && response.data) {
          hidePageLoader();
          this.redirectToSuccessPage(orderId);
        }
        return response;
      })
      .catch(error => {
        hidePageLoader();
        return error.response;
      });
  };

  componentDidMount() {
    if (window) {
      window.areebaHandleError = this.handleError;
      window.areebaHandleCancel = this.handleCancel;
      window.areebaHandleComplete = this.handleComplete;
    }

    this.attachAreebaScripts();
  }

  attachAreebaScripts = () => {
    const areebaTags = document.querySelectorAll(
      'script[src*="areeba"], iframe[src*="areeba"]'
    );

    if (areebaTags.length) return;

    const script = document.createElement("script");
    script.src = areebaScriptPath;
    script.async = true;
    script.setAttribute("data-error", "areebaHandleError");
    script.setAttribute("data-cancel", "areebaHandleCancel");
    script.setAttribute("data-complete", "areebaHandleComplete");
    script.onload = () => {
      console.log(`Areeba loaded`);
    };

    document.body.appendChild(script);
  };

  render() {
    const { wrappedComponent: WrappedComponent } = this.props;
    return <WrappedComponent {...this.props} />;
  }
}

export function withAreebaPayment(WrappedComponent, data) {
  return props => {
    const areebaCountries = ["LB"];

    return props.country && areebaCountries.includes(props.country) ? (
      <AreebaIncluded wrappedComponent={WrappedComponent} {...props} />
    ) : (
      <WrappedComponent {...props} />
    );
  };
}

const mapStateToProps = state => ({
  country: get(state, "common.settings.countrySHORT"),
  routeSlug: selectRouteSlug(state),
  order: get(state, "checkoutReducer.checkoutOrder"),
  checkoutRequestData: get(state, "checkoutReducer.checkoutRequestData", {}),
  language: get(state, "common.language", "")
});
const mapDispatchToProps = { hidePageLoader, confirmOrder };

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAreebaPayment
);
