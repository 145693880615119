export const order = {
  orderId: "............",
  isCOD: true,
  transactionShippingCharge: 0,
  transactionAmount: 0,
  transactionCodFee: 0,
  shippingName: "..............",
  shippingAddress: "...............",
  shippingState: ".............",
  shippingCountry: null,
  shippingMob: "",
  shippingZip: "",
  createDate: "2019-05-03T11:04:01.006Z",
  shipStatus: "............",
  deliveryType: "EXPRESS_DELIVERY",
  paymentMode: ["COD"],
  points: 0,
  deliveryMessage: "",
  items: [
    {
      pId: 0,
      brandname: "............",
      proname: "...............",
      image: "...........",
      quantity: 1,
      transactionPrice: 0,
      transactionTotal: 0,
      transactionOldPrice: null,
      action: "...............",
      colorid: 0,
      sizeid: 0,
      cancelQty: 0,
      returnQty: 0
    }
  ]
};
