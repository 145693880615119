import React from "react";
import PropTypes from "prop-types";
import "./css/Ring.scss";
import "./css/common-loader.scss";
import spinnerImage from "./images/ring.gif";

const Ring = props =>
  props.fullscreen ? buildFullScreenHtml(props) : buildInlineScreenHtml(props);

const buildFullScreenHtml = props => (
  <div className="bfl-loader-wrapper">
    <div className="loader-container">
      <img
        alt="load"
        src={spinnerImage}
        style={{ height: props.height, width: props.width }}
      />
    </div>
  </div>
);

const buildInlineScreenHtml = props => (
  <div className="inline-loader-container">
    <img
      alt="load"
      src={spinnerImage}
      style={{ height: props.height, width: props.width }}
    />
  </div>
);

Ring.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  fullscreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

Ring.defaultProps = {
  height: 150,
  width: 150,
  color: "green"
};

export default Ring;
