import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import classnames from "classnames";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";

import SizeFilter from "../../size/size-filter";

import { isMobile, isServer } from "../../../util";
import { getPageApi } from "../../../redux/actions/page.action";
import {
  handleFacetChange,
  getProductsData,
  handleRangeChange,
  deleteFacet
} from "../../../redux/actions/productlisting.action";

import FilterItem from "./filterItem";
import HierarchicalCategoriesItem from "./hierarchicalCategoriesItem";

import RangeDragerCard from "../../range-drager-card";
import { LANGUAGE_ROUTE_KEY_MAP } from "../../../constants";

class FilterContent extends Component {
  state = {
    selectedSizeFilter: { lvl0: "", lvl1: "" },
    maxSliderWidth: 300,
    rangeMax: 0,
    searchValue: "",
    filteredBrands: Object.keys(this.props.subFacet)
  };

  componentDidMount() {
    const {
      range,
      shopByStore,
      storeId,
      countryId,
      language,
      getPageApi,
      clearRangeOnly,
      facetName
    } = this.props;

    if (!shopByStore && language && countryId && storeId) {
      getPageApi("/", language, countryId, storeId);
    }
    this.setState({ rangeMax: range.max });

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("scroll", this.handleClickOutside);

    clearRangeOnly?.(facetName);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("scroll", this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    /*Hardcoded check for 'filter_open' need to find better way*/
    const isContainTarget = this.wrapperRef?.contains(event.target);
    const isFilterOpenClass = event.target?.className?.includes("filter_open");
    const isMobileHeaderClass = event.target?.className?.includes(
      "mobile_filter_list_head"
    );
    const isDocAndScrollType =
      event.currentTarget === document && event.type === "scroll";

    if (
      this.wrapperRef &&
      !isContainTarget &&
      (isDocAndScrollType || !isFilterOpenClass) &&
      !isMobileHeaderClass
    ) {
      event.stopPropagation();
      const { handleFilterToggle, facetName } = this.props;
      handleFilterToggle(facetName, event);
    }
  };
  changeRange = e => {
    const { handleRangeChange } = this.props;

    handleRangeChange(e);
  };

  changeRangeComplete = e => {
    const {
      handleRangeChange,
      getProductsData,
      history,
      handleFilterItemChecked
    } = this.props;

    handleRangeChange(e);
    getProductsData(null, history).then(() =>
      handleFilterItemChecked(["priceBy", `min-${e.min}/max-${e.max}`], true)
    );
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  handleItemChecked = (e, state, mobile) => {
    const {
      handleFacetChange,
      handleFilterItemChecked,
      getProductsData,
      history,
      language,
      deleteFacet,
      setSearchInit,
      range
    } = this.props;

    this.setState({ rangeMax: range.max });
    if (setSearchInit) setSearchInit();

    const facets = e.target.id.split(":");

    if (hierarchicalCategories.includes(facets[0])) {
      this.clearHierarchy(facets[0], false, false);
      if (facets[0] === hierarchicalCategories[1]) {
        deleteFacet(hierarchicalCategories[2]);
      } else if (facets[0] === hierarchicalCategories[0]) {
        deleteFacet(hierarchicalCategories[1]);
      }
    }
    const isChecked = state || e.target.checked;
    handleFacetChange(facets[0], facets[1], isChecked);
    getProductsData(
      facets[0],
      history,
      null,
      null,
      language,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    ).then(() => handleFilterItemChecked(facets, isChecked));

    e.preventDefault();
    e.stopPropagation();
  };

  clearHierarchy = (
    facetName,
    shouldFetchProductData = true,
    shouldSetParentHierarchy = true
  ) => {
    const {
      subFacet,
      handleFacetChange,
      language,
      history,
      getProductsData,
      setHierarchy
    } = this.props;

    if (facetName === hierarchicalCategories[2]) {
      const filterItems = Object.keys(subFacet);

      filterItems.forEach(facetItem => {
        handleFacetChange(facetName, facetItem, false);
      });
    }

    if (shouldSetParentHierarchy) {
      setHierarchy(facetName);
    }
    if (shouldFetchProductData) {
      getProductsData(facetName, history, null, null, language);
    }
  };

  clearSelection = e => {
    const {
      facetName,
      subFacet,
      handleFacetChange,
      getProductsData,
      history,
      language
    } = this.props;
    const { searchValue } = this.state;
    e.stopPropagation();

    const filterItems = Object.keys(subFacet).filter(
      item => subFacet[item].checked
    );

    if (searchValue) {
      this.setState({ searchValue: "" }, () => this.handleBrandSearch());
    }

    if (!filterItems.length) return null;

    filterItems.forEach(facetItem =>
      handleFacetChange(facetName, facetItem, false)
    );

    getProductsData(
      facetName,
      history,
      null,
      null,
      language,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    );
  };

  handleSizeChange = (
    level,
    item,
    header,
    subFacet,
    checkedState,
    showLoader = true
  ) => {
    const {
      handleFacetChange,
      history,
      language,
      getProductsData,
      handleFilterItemChecked
    } = this.props;
    const { selectedSizeFilter } = this.state;

    const facetName = "sizeHierarchicalFilter.lvl2";

    if (
      level === "level0" &&
      selectedSizeFilter &&
      selectedSizeFilter.lvl0 !== item
    ) {
      this.setState({
        selectedSizeFilter: {
          ...selectedSizeFilter,
          lvl0: item,
          lvl1: ""
        }
      });
    } else if (
      level === "level1" &&
      selectedSizeFilter &&
      selectedSizeFilter.lvl1 !== item
    ) {
      this.setState({
        selectedSizeFilter: {
          ...selectedSizeFilter,
          lvl1: item
        }
      });
    } else if (level === "level2") {
      handleFacetChange(facetName, subFacet, Boolean(checkedState));
      getProductsData(
        facetName,
        history,
        null,
        null,
        language,
        showLoader
      ).then(() =>
        handleFilterItemChecked([facetName, subFacet], Boolean(checkedState))
      );
    }
  };

  sliderMaxWidth = max => {
    const { sizeFilterStyle } = this.props;
    const { maxSliderWidth } = this.state;

    if (sizeFilterStyle && sizeFilterStyle.maxWidth && max) {
      const newMaxSliderWidth = Math.min(sizeFilterStyle.maxWidth, max);

      if (newMaxSliderWidth !== maxSliderWidth) {
        this.setState({ maxSliderWidth: newMaxSliderWidth });
      }
    }
  };

  handleViewAll = e => {
    const { language, getProductsData, history, range, setSearchInit } =
      this.props;

    this.setState({ rangeMax: range.max, rangeMin: 0 });

    if (setSearchInit) {
      setSearchInit();
    }

    this.clearHierarchy(hierarchicalCategories[2], false, false);
    getProductsData(hierarchicalCategories[2], history, null, null, language);

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  isPresentInString = (text, searchedText) =>
    text
      .toString()
      .toLowerCase()
      .includes(searchedText.toString().toLowerCase());

  handleType = e => {
    this.setState({ searchValue: e.target.value }, () =>
      this.handleBrandSearch()
    );
  };

  handleBrandSearch = () => {
    const { subFacet } = this.props;
    const { searchValue } = this.state;

    const filterItems = Object.keys(subFacet);

    const filteredData = searchValue
      ? filterItems.filter(data => this.isPresentInString(data, searchValue))
      : filterItems;

    this.setState({ filteredBrands: filteredData });
  };

  render() {
    const { searchValue, filteredBrands, selectedSizeFilter, maxSliderWidth } =
      this.state;

    const {
      subFacet,
      facetName,
      range,
      className,
      translation,
      sizeFilterStyle,
      currency,
      handleFilterToggle,
      handleSizeFilter,
      intialPriceMaxvalue,
      colors,
      language,
      shopByStore
    } = this.props;

    if (!shopByStore) return null;

    let _value = 0;

    if (facetName === hierarchicalCategories[2]) {
      Object.keys(subFacet).map(key => {
        _value = _value + subFacet[key].value;
        return null;
      });
    }
    const screenHeight = !isServer && window.innerHeight;

    const sortYears = filterItems => {
      const orderOptions = [
        language.includes("ar") ? "desc" : "asc",
        "asc",
        "asc"
      ];
      const sorted = orderBy(
        filterItems.map((item, index) => {
          const [digits, words] = item.split(" ");
          const [first, second] = digits.match(/(\d+)/g).map(Number);
          return { first, second, words, index };
        }),
        ["words", "first", "second"],
        orderOptions
      ).map(item => filterItems[item.index]);
      return sorted;
    };

    const sortFilterItems = filterItems => {
      if (facetName.includes("Toys Age Group")) {
        return sortYears(filterItems);
      } else {
        const _sortedFilterItems = !hierarchicalCategories.includes(facetName)
          ? filterItems.sort()
          : filterItems;
        const _num = [];
        const _str = [];
        _sortedFilterItems.forEach(item =>
          isNaN(item[0]) ? _str.push(item) : _num.push(item)
        );
        return [..._str, ..._num];
      }
    };

    const filterItems = Object.keys(subFacet);
    const filterItemsSelectedCount = filterItems.filter(
      filterItem => subFacet[filterItem].checked
    ).length;

    const isMob = Boolean(isMobile.any());

    const screenWidth = document.body.clientWidth;

    const storeList = get(
      shopByStore,
      [LANGUAGE_ROUTE_KEY_MAP[language], "storeList"],
      []
    );

    return (
      <div
        ref={this.setWrapperRef}
        className={classnames("filter_content", className, {
          "size_chart_wrapper padding0":
            facetName === "sizeHierarchicalFilter.lvl2"
        })}
        style={screenWidth > 800 ? { maxWidth: maxSliderWidth } : {}}
        onClick={this.stopPropagation}
      >
        {facetName === "sizeHierarchicalFilter.lvl2" ? (
          <div>
            <ul className="size_chart">
              <SizeFilter
                subFacet={subFacet}
                selectedSizeFilter={selectedSizeFilter}
                handleSizeChange={this.handleSizeChange}
                sizeFilterStyle={sizeFilterStyle}
                sliderMaxWidth={this.sliderMaxWidth}
                maxSliderWidth={maxSliderWidth}
                handleSizeFilter={handleSizeFilter}
                handleFilterToggle={handleFilterToggle}
                screenHeight={screenHeight}
              />
            </ul>
          </div>
        ) : (
          <>
            <Scrollbars autoHeight autoHeightMin={55} autoHeightMax={240}>
              {hierarchicalCategories.includes(facetName) && !isMob ? (
                <div className="hierarchical_categories_item_web">
                  {facetName !== hierarchicalCategories[0] &&
                    facetName !== hierarchicalCategories[1] && (
                      <p
                        className="cat_back_arrow"
                        onClick={() => this.clearHierarchy(facetName)}
                      >
                        {Boolean(filterItems.length) &&
                        filterItems[0].split(">").length > 2
                          ? filterItems[0].split(">")[1]
                          : filterItems[0].split(">")[0]}
                      </p>
                    )}
                  <ul
                    className={classnames(
                      "hierarchical_categories_item",
                      {
                        forward_arrow:
                          facetName === hierarchicalCategories[0] ||
                          facetName === hierarchicalCategories[1]
                      },
                      {
                        first_arrow: facetName === hierarchicalCategories[0]
                      }
                    )}
                  >
                    {filterItems.map(filterItem => (
                      <HierarchicalCategoriesItem
                        key={filterItem}
                        facetName={facetName}
                        subFacetName={filterItem}
                        subFacetValue={subFacet[filterItem]}
                        showRadio={facetName === hierarchicalCategories[2]}
                        handleItemChecked={this.handleItemChecked}
                      />
                    ))}
                    {filterItems.length > 1 &&
                      facetName === hierarchicalCategories[2] && (
                        <li
                          className="categories_item_li"
                          onClick={this.handleViewAll}
                        >
                          <span>{`View All(${_value})`}</span>
                          <span
                            className={classnames(
                              !filterItemsSelectedCount && "selected_option",
                              "option"
                            )}
                          >
                            <input
                              id="ViewAll"
                              type="radio"
                              className={classnames(
                                !filterItemsSelectedCount && "checked_radio",
                                "custom_radio"
                              )}
                              onClick={this.handleViewAll}
                            />
                            <label
                              id="LabelViewAll"
                              className="radio_option_value"
                            />
                          </span>
                        </li>
                      )}
                  </ul>
                </div>
              ) : (
                <div>
                  {facetName !== "priceBy" &&
                    facetName !== "sizeHierarchicalFilter.lvl2" && (
                      <div className="clear_selected_filter">
                        <a name={facetName} onClick={this.clearSelection}>
                          <span>{translation.clear}</span>
                        </a>
                      </div>
                    )}
                  <div className="back_selected_filter_page">
                    <span className="mBackIcon" onClick={handleFilterToggle} />
                  </div>
                  {facetName === "brandName" && (
                    <div className="brand-search-wrapper">
                      <input
                        type="text"
                        placeholder={translation.searchBrandsPlaceholder}
                        value={searchValue}
                        onChange={this.handleType}
                        aria-label="Brand search"
                      />
                      {!searchValue && <span className="search_icon" />}
                    </div>
                  )}
                  <ul
                    className={classnames(
                      "hierarchical_categories2_item",
                      facetName === "sizeHierarchicalFilter.lvl2" &&
                        "size_chart"
                    )}
                  >
                    {(facetName === "brandName" &&
                      sortFilterItems(filteredBrands).map(filterItem => (
                        <FilterItem
                          key={filterItem}
                          facetName={facetName}
                          subFacetName={filterItem}
                          subFacetValue={subFacet[filterItem]}
                          handleItemChecked={this.handleItemChecked}
                          colors={colors}
                          language={language}
                          storeList={storeList}
                        />
                      ))) ||
                      (facetName !== "priceBy" &&
                        facetName !== "sizeHierarchicalFilter.lvl2" &&
                        sortFilterItems(filterItems).map(filterItem => (
                          <FilterItem
                            key={filterItem}
                            facetName={facetName}
                            subFacetName={filterItem}
                            subFacetValue={subFacet[filterItem]}
                            handleItemChecked={this.handleItemChecked}
                            colors={colors}
                            language={language}
                            storeList={storeList}
                          />
                        ))) ||
                      (facetName !== "sizeHierarchicalFilter.lvl2" && (
                        <RangeDragerCard
                          currency={currency}
                          translationRange={translation.range}
                          minValue={0}
                          maxValue={intialPriceMaxvalue}
                          rangeMax={range.max || 0}
                          rangeMin={range.min || 0}
                          changeRangeComplete={this.changeRangeComplete}
                        />
                      ))}
                    {filterItems.length > 1 &&
                      facetName === hierarchicalCategories[2] && (
                        <Fragment>
                          <li
                            className={classnames(
                              !filterItemsSelectedCount && "checked",
                              "filter_item_mobile"
                            )}
                          >
                            <span
                              id="LabelViewAll"
                              onClick={this.handleViewAll}
                            >
                              View All
                            </span>
                            <span>{_value}</span>
                          </li>
                          <li
                            className={classnames(
                              !filterItemsSelectedCount && "checked",
                              "right_check_label",
                              "filter_item_web"
                            )}
                            onClick={this.handleViewAll}
                          >
                            <span>{`View All(${_value})`}</span>
                            <span className="check_circle">
                              <input
                                id="ViewAll"
                                type="checkbox"
                                checked={!filterItemsSelectedCount}
                                onClick={this.handleViewAll}
                              />
                            </span>
                          </li>
                        </Fragment>
                      )}
                  </ul>
                </div>
              )}
            </Scrollbars>
          </>
        )}
        {facetName !== "priceBy" &&
          !hierarchicalCategories.includes(facetName) &&
          facetName !== "sizeHierarchicalFilter.lvl2" && (
            <div className="count_selected">
              {filterItemsSelectedCount} selected
              <a name={facetName} onClick={this.clearSelection}>
                <span>{translation.clear}</span>
              </a>
            </div>
          )}
        {facetName !== "hierarchicalCategories.lvl0" &&
          facetName !== "hierarchicalCategories.lvl1" &&
          facetName !== "sizeHierarchicalFilter.lvl2" && (
            <button
              className="viewItems round_btn form_black_btn Applybtn_hc2"
              onClick={e => handleFilterToggle("", e, "apply")}
            >
              {translation.apply}
            </button>
          )}
      </div>
    );
  }
}

const hierarchicalCategories = [
  "hierarchicalCategories.lvl0",
  "hierarchicalCategories.lvl1",
  "hierarchicalCategories.lvl2"
];

const mapStateToProps = reduxState => ({
  currency: get(reduxState, "common.settings.currencyCode"),
  colors: reduxState.common.colors,
  language: reduxState.common.language,
  shopByStore: reduxState.page.storeList,
  countryId: reduxState.page.homepageState.countryId,
  storeId: reduxState.page.homepageState.storeId,
  products: reduxState.productListingReducer.products
});

const mapDispatchToProps = {
  deleteFacet,
  getProductsData,
  handleFacetChange,
  handleRangeChange,
  getPageApi
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterContent);
