import React from "react";
import cn from "classnames";
import ModalBFL from "../modal/modal";
import { MODAL_DIALOG_OPTIONS } from "../../constants";

const DeleteModal = ({
  language,
  titleTxt,
  bodyTxt,
  deleteBtnTxt,
  cancelBtnTxt,
  isDeleteModalActive,
  handleCloseModal,
  handleDeleteItem,
  itemId,
  viewCart,
  goToCart
}) => (
  <ModalBFL
    showModal={isDeleteModalActive}
    modalClass={cn("generic_modal delete_address_modal", {
      arabic: language && language.includes("ar")
    })}
  >
    <div className="address_delete">
      <h4>{titleTxt}</h4>
      <p>{bodyTxt}</p>
      <div className="action_btn">
        {viewCart && (
          <button className="confirm_btn" onClick={() => goToCart()}>
            {viewCart}
          </button>
        )}
        <button
          className="confirm_btn"
          onClick={() => handleCloseModal(false, MODAL_DIALOG_OPTIONS.yes)}
        >
          {cancelBtnTxt}
        </button>
        <button
          className="cancel_btn"
          onClick={() => {
            handleCloseModal(false, MODAL_DIALOG_OPTIONS.no);
            if (handleDeleteItem) {
              handleDeleteItem(itemId);
            }
          }}
        >
          {deleteBtnTxt}
        </button>
      </div>
    </div>
  </ModalBFL>
);
export default DeleteModal;
