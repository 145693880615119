import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useHistory } from "react-router";
import { useSelector } from "react-redux";
import qs from "query-string";

import { selectRouteSlug, selectLanguageSlug } from "../../util/selectors";
import { CartService } from "../../services";
import englishTranslation from "../../language/repopulate-cart/en";
import { withPageTypeTracking } from "../../hocs";

import checkIcon from "../../images/smart-mirror-check.svg";
import smartMirrorIcon from "../../images/smart-mirror-icon.svg";

import "./style.scss";

const RepopulateCart = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [translation, setTranslation] = useState(englishTranslation);
  const location = useLocation();
  const history = useHistory();

  const routeSlug = useSelector(selectRouteSlug);
  const language = useSelector(selectLanguageSlug);

  useEffect(() => {
    (async () => {
      try {
        const parsedData = qs.parse(location.search);

        const productData = parsedData.products
          .split(",")
          .map(productDataString => {
            const [productId, stockId, quantity] = productDataString.split("-");

            return {
              productId: Number(productId),
              stockId: Number(stockId),
              quantity: Number(quantity)
            };
          });

        await CartService.repopulateCart({
          productData,
          countryId: parsedData.countryId
        });
        setIsCompleted(true);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [location.search]);

  useEffect(() => {
    (async () => {
      const { default: translation } = await import(
        `../../language/repopulate-cart/${language}`
      );

      setTranslation(translation);
    })();
  }, [language]);

  return (
    <div className="repopulate-cart">
      <img className="smart-mirror-icon" src={smartMirrorIcon} alt="" />

      {isCompleted ? (
        <Fragment>
          <div className="success-result">
            <span className="text">{translation.success.title}</span>
            <img src={checkIcon} alt="" />
          </div>

          <div className="processing-text mb-3">
            {translation.success.description}
          </div>

          <div className="more-steps-text">{translation.success.moreSteps}</div>

          <button
            className="cart-button round_btn form_black_btn"
            onClick={() => history.replace(`/${routeSlug}/my-cart/`)}
          >
            {translation.success.cartButton}
          </button>
        </Fragment>
      ) : (
        <div className="processing-text">{translation.processing}</div>
      )}
    </div>
  );
};

export default withPageTypeTracking(RepopulateCart);
