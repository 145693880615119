import React from "react";
import cn from "classnames";

export const ModalBigCardMobile = React.memo(({ title, selected, image }) => (
  <>
    <div className="modal-big-card">
      <div
        className="zoomedImage"
        style={{
          backgroundImage: `url(${image})`
        }}
      />
      <div className={cn("checkbox-icon", { selected })} />
    </div>
    <div className="big-card-description-mobile">
      <h3>{title.toUpperCase()}</h3>
    </div>
  </>
));
