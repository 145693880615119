import React, { useEffect } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import {
  DEFAULT_POINTS_FOR_NEXT_LEVEL,
  MEMBERSHIP_STYLE_CLASS,
  MIN_POINTS_FOR_REDEEM
} from "../../../constants/loyalty-points";
import { selectLanguageSlug, selectRouteSlug } from "../../../util/selectors";
import { getLoyaltyExpiryDetails } from "../../../redux/actions/myAccount.action";
import LoyaltyCard from "../../loyalty-card";
import LoyaltyProgressBar from "../loyalty-points-progress-bar";
import RedeemExpiryDetailsPopup from "./redeem-expiry-details-popup";
import LoyaltyRedeemInfo from "../loyalty-redeem-info";
import RedeemLoyaltyPointsBtn from "../redeem-loyalty-points-btn";
import ProgressBarWrapper from "../loyalty-progress-bar-wrapper";
import Loader from "../../loader";
import { LOADER_TYPES, commonLoaderStyle } from "../../../constants";

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "rgb(255 255 255 / 75%)"
};

const RedeemLoyalty = props => {
  const {
    myAccountTranslation,
    setting,
    userInfo,
    userOriginCountryData,
    getLoyaltyExpiryDetails,
    loyaltyPoints: { LoyaltyStatics: loyaltyStatics, expiryDetails },
    currencyCode,
    language,
    countryId,
    mobileLocalCode,
    mobileLocalNumberLength,
    isArabic,
    routeSlug
  } = props;

  const isPointsRedeemable =
    parseInt(loyaltyStatics.loyaltyDetails.currentBalance) <
    MIN_POINTS_FOR_REDEEM;

  useEffect(() => {
    if (countryId && currencyCode && language) {
      getLoyaltyExpiryDetails(countryId, currencyCode, language);
    }
  }, [countryId, currencyCode, language]);
  return (
    <div
      className={`redeem_loyalty_wrapper myAcc-card-wrapper ${
        MEMBERSHIP_STYLE_CLASS[
          get(loyaltyStatics, "loyaltyDetails.memeberLevel", "")
        ]
      }`}
    >
      <div
        className={cn("loyalty-program-info", "position-relative", {
          arabic: isArabic
        })}
      >
        {isUndefined(loyaltyStatics?.loyaltyDetails) ? (
          <Loader
            type={LOADER_TYPES.RING}
            width={25}
            height={25}
            visible
            style={loaderStyle}
          />
        ) : (
          <>
            <div className="info-left-side">
              {loyaltyStatics && loyaltyStatics.loyaltyDetails && (
                <LoyaltyCard
                  myAccountTranslation={myAccountTranslation}
                  countryCode={setting.countryCode}
                  countryShortCode={setting.countrySHORT}
                  currencyCode={setting.currencyCode}
                  loyaltyDetails={loyaltyStatics.loyaltyDetails}
                  isArabic={isArabic}
                  routeSlug={routeSlug}
                />
              )}
              {!isEmpty(expiryDetails) && (
                <>
                  {Boolean(expiryDetails.earliestExpiryPoints) && (
                    <div className="mt-4">
                      <RedeemExpiryDetailsPopup
                        points={expiryDetails.earliestExpiryPoints}
                        expireDate={expiryDetails.earliestPointExpiryDate}
                        translation={myAccountTranslation}
                      />
                    </div>
                  )}

                  {Boolean(expiryDetails.expiryPointsNextMon) && (
                    <div className="mt-4">
                      <RedeemExpiryDetailsPopup
                        points={expiryDetails.expiryPointsNextMon}
                        expireDate={expiryDetails.expiryPointsDateNextMon}
                        translation={myAccountTranslation}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="info-right-side">
              {userInfo && loyaltyStatics && loyaltyStatics.loyaltyDetails && (
                <>
                  <LoyaltyRedeemInfo
                    loyaltyDetails={loyaltyStatics.loyaltyDetails}
                    userInfo={userInfo}
                    mobileCountryCode={userOriginCountryData.mobileCountryCode}
                    mobileLocalCode={mobileLocalCode}
                    mobileLocalNumberLength={mobileLocalNumberLength}
                    currencyCode={setting.currencyCode}
                    isArabic={isArabic}
                    translation={myAccountTranslation}
                  />
                  <div className="progress-bar-with-redeem-btn">
                    <div className="loyalty-progress-bar">
                      <ProgressBarWrapper
                        loyaltyDetails={get(
                          loyaltyStatics,
                          "loyaltyDetails",
                          {}
                        )}
                        translation={myAccountTranslation}
                      >
                        <LoyaltyProgressBar
                          maxTierPoints={
                            loyaltyStatics.loyaltyDetails
                              .pointNeededForNextLevel ||
                            DEFAULT_POINTS_FOR_NEXT_LEVEL
                          }
                          availablePoints={
                            loyaltyStatics.loyaltyDetails.accuralPoints
                          }
                          memberLevel={
                            loyaltyStatics.loyaltyDetails.memeberLevel
                          }
                          stepsPerTierAmount={8}
                        />
                      </ProgressBarWrapper>
                    </div>

                    <div className="redeem-button-section">
                      <p className="smile-points">
                        {myAccountTranslation.smilePoints}{" "}
                        {loyaltyStatics.loyaltyDetails.usedPointsToConvert} ={" "}
                        {currencyCode}{" "}
                        {loyaltyStatics.loyaltyDetails.earnedAmountFromConvert}
                      </p>
                      <RedeemLoyaltyPointsBtn
                        myAccountTranslation={myAccountTranslation}
                      />
                      {isPointsRedeemable && (
                        <p className="min-points">
                          {myAccountTranslation.minPointsMsg}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  setting: state.common.settings,
  userInfo: state.authReducer,
  userOriginCountryData: state.common.userOriginCountryData,
  loyaltyPoints: get(state, "myAccountReducer.loyaltyPoints", {}),
  language: state.common.language,
  currencyCode: state.common.settings.currencyCode,
  countryId: state.common.settings.countryId,
  mobileLocalCode: state.common.settings.mobileLocalCode,
  mobileLocalNumberLength: state.common.settings.mobileLocalNumberLength,
  isArabic: selectLanguageSlug(state) === "ar",
  routeSlug: selectRouteSlug(state)
});

const mapDispatchToProps = {
  getLoyaltyExpiryDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemLoyalty);
