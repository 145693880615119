import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  authenticateUser,
  resetAPIError,
  clearModal,
  updateRegistrationDataStatusOnLogin,
  setOTPLoginFlowBFL
} from "../../redux/actions/homepage.action";
import {
  emailValidationRegExp,
  SIGNUP_CODES_FOR_MODAL,
  SIGNUP_API_CODE_NAMES,
  IS_AUTH_FROM_CHECKOUT,
  CHECKOUT_ID
} from "../../constants";
import {
  selectCountrySlug,
  selectRouteSlug,
  selectRegistrationDataStatus
} from "../../util/selectors";
import {
  changeCheckoutData,
  clearGuestEmail
} from "../../redux/actions/checkout.action";
import {
  fetchUserAndLoadData,
  setPrevPath,
  resetTokenGeneratorCheckoutGuest
} from "../../redux/actions/authConfig.action";
import { getWishListBaseData } from "../../redux/actions/wishList.action";
import { getCartAndWishlistGeneralData } from "../../redux/actions/cart.action";
import { removeDeliverySettings } from "../../redux/actions/common.action";
import { GAService } from "../../services/GA-service";
import FormActivityHandler from "../formActionsHandler";

class LoginModal extends Component {
  static initialValues = {
    email: "",
    password: ""
  };

  static schema = Yup.object().shape({
    email: Yup.string().trim().matches(emailValidationRegExp).required(),
    password: Yup.string().trim().required()
  });

  state = { isShowPassword: false, lastFocusedField: "" };

  componentWillUnmount() {
    const { clearModal, resetAPIError } = this.props;

    clearModal();
    resetAPIError();
  }

  fetchUserData = () => {
    const { changeCheckoutData } = this.props;
    changeCheckoutData("continueAsGuest", false);
    changeCheckoutData("step1Completed", true);
  };

  handleErrorOccuredInForm = errorObj => {
    GAService.auth.trackLoginFormError(errorObj);
  };

  onSubmit = async data => {
    const {
      loginUser,
      history,
      language,
      changeCustomerId,
      redirection,
      clearGuestEmail,
      resetTokenGeneratorCheckoutGuest,
      countrySlug,
      prevPath,
      setPrevPath,
      stopRedirection,
      routeSlug,
      countryId,
      removeDeliverySettings,
      setOTPLoginFlowBFL
    } = this.props;

    setOTPLoginFlowBFL(true);

    const response = await loginUser(
      data,
      history,
      language,
      false,
      countrySlug,
      countryId
    );

    if (response && response.data && response.data.customerId) {
      resetTokenGeneratorCheckoutGuest();

      this.fetchUserData();

      if (changeCustomerId) return changeCustomerId(response.data.customerId);

      if (localStorage.getItem(IS_AUTH_FROM_CHECKOUT)) {
        removeDeliverySettings();
        history.push(
          `/${routeSlug}/checkout/${localStorage.getItem(CHECKOUT_ID)}/`
        );
        localStorage.removeItem(IS_AUTH_FROM_CHECKOUT);
        return;
      }

      if (prevPath && setPrevPath && !stopRedirection) {
        if (
          prevPath.includes("/order-success/") ||
          prevPath.includes("/registration/thank-you") ||
          prevPath.includes("/forgot-password") ||
          prevPath.includes(`/${routeSlug}/`) ||
          prevPath.includes("")
        ) {
          setPrevPath("");
          return history.push(`/${routeSlug}/`);
        }

        history.goBack(-1);
        return setPrevPath("");
      }

      if (redirection) history.push(redirection);
    } else if (response.data?.code === SIGNUP_API_CODE_NAMES.MOBILE_REQUIRED) {
      this.props.updateRegistrationDataStatusOnLogin(response.data);
    }

    clearGuestEmail();
  };

  toggleShowPassword = () =>
    this.setState(prevState => ({
      isShowPassword: !prevState.isShowPassword
    }));

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });

  render() {
    const {
      translation,
      apiErrorDetail,
      showForgotModal,
      registrationDataStatus
    } = this.props;
    const { isShowPassword } = this.state;
    const errorMessage =
      !isEmpty(registrationDataStatus) &&
      ((registrationDataStatus.code &&
        !SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code]) ||
        (registrationDataStatus.hasOwnProperty("success") &&
          !registrationDataStatus.success))
        ? registrationDataStatus.message
        : !apiErrorDetail.success && apiErrorDetail.message;

    return (
      <>
        <Formik
          initialValues={LoginModal.initialValues}
          onSubmit={this.onSubmit}
          validationSchema={LoginModal.schema}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            const isInputInvalid = valueKey =>
              Boolean(touched[valueKey] && errors && errors[valueKey]);

            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className={cn("form-control", {
                      borderRed: isInputInvalid("email")
                    })}
                    placeholder={translation.email}
                    value={values.email}
                    onChange={handleChange}
                    aria-label="Email"
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("email") && (
                    <span className="error">{translation.emailRequired}</span>
                  )}
                </div>

                <div className="form-group e-pwd">
                  <input
                    type={isShowPassword ? "text" : "password"}
                    name="password"
                    className={cn("form-control", {
                      borderRed: isInputInvalid("password")
                    })}
                    placeholder={translation.password}
                    value={values.password}
                    onChange={handleChange}
                    aria-label="Password"
                    onFocus={this.setLastFocusedField}
                  />{" "}
                  <span className="show-span" onClick={this.toggleShowPassword}>
                    {isShowPassword
                      ? `${translation.hide}`
                      : `${translation.showPass}`}
                  </span>
                  {isInputInvalid("password") && (
                    <span className="error">{translation.required}</span>
                  )}
                </div>

                <span className="forget_password" onClick={showForgotModal}>
                  {translation.forgotPassword}
                </span>

                <button
                  type="submit"
                  className="btn btn-default round_btn form_black_btn"
                  onClick={() => GAService.auth.trackSignInMethod()}
                >
                  {translation.signIn}
                </button>
                <FormActivityHandler
                  formName={"login form"}
                  lastFocusedFormField={this.state.lastFocusedField}
                  onErrors={this.handleErrorOccuredInForm}
                />
              </form>
            );
          }}
        </Formik>

        {errorMessage && (
          <div className="form-error">
            <span className="error">{errorMessage}</span>
          </div>
        )}
      </>
    );
  }
}

LoginModal.propTypes = {
  loginUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  login: state.homepage.login,
  language: state.common.language,
  settings: state.common.settings,
  customerId: state.authReducer.customerId,
  prevPath: state.authReducer.prevPath,
  apiErrorDetail: state.homepage.APIErrorResponse,
  countrySlug: selectCountrySlug(state),
  routeSlug: selectRouteSlug(state),
  countryId: state.common.settings.countryId,
  registrationDataStatus: selectRegistrationDataStatus(state)
});

const mapDispatchToProps = {
  loginUser: authenticateUser,
  fetchUserAndLoadData,
  getWishListBaseData,
  resetAPIError,
  clearModal,
  clearGuestEmail,
  changeCheckoutData,
  resetTokenGeneratorCheckoutGuest,
  setPrevPath,
  updateRegistrationDataStatusOnLogin,
  removeDeliverySettings,
  getCartAndWishlistGeneralData,
  setOTPLoginFlowBFL
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginModal)
);
