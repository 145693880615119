import React from "react";

const SizeChartList = ({
  brandId,
  brandName,
  handlePopup,
  handleSizeChart,
  guideType,
  bodyText,
  popupHeading,
  handleItemClick
}) => {
  return (
    <li className="sizeChartList">
      <span
        onClick={() => {
          handlePopup(true);
          handleSizeChart(brandId, brandName, guideType, popupHeading);
          handleItemClick();
        }}
      >
        {bodyText}
      </span>
    </li>
  );
};

export default SizeChartList;
