import get from "lodash/get";
import { getDeviceType } from "..";
import { getState } from "../../redux/store";
import { ENV_CONFIG } from "../../config/env";
import { selectCountrySlug } from "../selectors";
import { getAccessToken } from "../storeHelper";

export const getSubModuleIframeUrl = ({ domain, page }) => {
  const state = getState();
  const language = get(state.common, "language", "en");
  const countrySlug = selectCountrySlug(state) || "ae";
  const countryId = get(
    state.common,
    "settings.countryId",
    ENV_CONFIG.countryId
  );
  const deviceType = get(state, "common.deviceType", "") || getDeviceType();
  const currencyCode = get(state.common, "settings.currencyCode", "AED");
  const iframeSourceUrl = `${domain}${page}?t=${getAccessToken(
    state
  )}&locale=${language}-${countrySlug}&country_id=${countryId}&device=${deviceType ||
    "desktop"}&platform=web&currency_code=${currencyCode}`;
  return iframeSourceUrl;
};
