import React from "react";
import cn from "classnames";

const sizeChartNav = ({ activeTab, handleActiveTab, index, item, keyName }) => {
  return (
    <li
      className={cn({
        active: activeTab === keyName,
        bdr_lft_rgt: index === 1
      })}
      onClick={() => handleActiveTab(keyName)}
    >
      {item}
    </li>
  );
};

export default sizeChartNav;
