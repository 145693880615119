import React, { Component } from "react";
import { connect } from "react-redux";
import Headroom from "react-headroom";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { isServer, globalScrollHandler } from "../../../util";
import {
  getProductsData,
  handleSortChange,
  handleAssistiveFilterClick,
  handleFacetChange,
  handlePageChange,
  resetRangeChanged,
  setMobilecurrentFilterSelected
} from "../../../redux/actions/productlisting.action";

import Filtertypes from "../../filter/filters";
import CloseClearFilter from "./filterClearClose";
import FilterFooter from "./filterActionFooter";
import CustomDropdown from "../../genericDropDown";

class MobileFilterTypes extends Component {
  state = {
    isToggle: "",
    filterIndex: false,
    sizeFilterActive: false,
    anotherFilter: false,
    isFiltersListView: true,
    filterName: ""
  };

  componentDidMount() {
    !isServer &&
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    !isServer &&
      document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      const { isToggle } = this.state;
      if (isToggle) {
        this.handleToggle();
      }
    }
  };

  handleToggle = value => {
    globalScrollHandler(this.wrapperRef, value);
    this.setState({ isToggle: value });
  };

  handleClearFilters = () => {
    const { isFiltersListView } = this.state;
    isFiltersListView ? this.clearAll() : this.clearSelection();
  };

  filterClickHandler = () => {
    this.setState({
      filterIndex: !this.state.filterIndex,
      sizeFilterActive: false
    });
  };

  setSort = sort => {
    const {
      getProductsData,
      history,
      language,
      handleSortChange,
      handleAssistiveFilterClick,
      handleSortChangeEvent
    } = this.props;

    handleSortChange(sort);
    handleAssistiveFilterClick(false);
    handleSortChangeEvent(sort);
    getProductsData(null, history, null, null, language);
  };

  generateHTMLForSortOptions = (isChecked, label) => {
    const containerClasses = cn("right_check_label", { checked: isChecked });
    return `<div class="${containerClasses}">
        <span class="title">${label}</span>
      </div>`;
  };

  handleSizeFilter = (value, isSizeChart, isFiltersListView) => {
    this.setState({
      sizeFilterActive: isSizeChart,
      anotherFilter: value,
      isFiltersListView: isFiltersListView
    });
  };

  clearAll = () => {
    const {
      selectedFilters,
      handleFacetChange,
      getProductsData,
      history,
      handlePageChange,
      handleSwitchFilter,
      match,
      resetRangeChanged,
      resetIsResponsive
    } = this.props;

    selectedFilters.forEach(filter => {
      handleFacetChange(filter.facet, filter.subFacet, false);
    });

    handlePageChange(0);

    resetRangeChanged && resetRangeChanged();
    getProductsData(
      null,
      history,
      "",
      null,
      match && match.params && match.params.language,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    );
    handleSwitchFilter(false);
    resetIsResponsive();
  };

  clearSelection = () => {
    const {
      selectedFilters,
      handleFacetChange,
      getProductsData,
      history,
      language,
      currentFilterSelected,
      setMobilecurrentFilterSelected,
      resetRangeChanged
    } = this.props;
    const { filterName } = this.state;
    // getting all filters applied for current facet
    const selectedFacetFilters = selectedFilters.filter(
      filter => filter.facet === currentFilterSelected
    );

    if (filterName !== "priceBy" && !selectedFacetFilters.length) return null;

    // unselecting all subfacets for current facet
    selectedFacetFilters.forEach(facetFilter =>
      handleFacetChange(facetFilter.facet, facetFilter.subFacet, false)
    );

    if (filterName === "priceBy") {
      resetRangeChanged && resetRangeChanged();
    }
    getProductsData(
      null,
      history,
      null,
      null,
      language,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    );
    this.setState({ isFiltersListView: true });
    setMobilecurrentFilterSelected(null);
  };

  render() {
    const {
      isToggle,
      filterIndex,
      sizeFilterActive,
      anotherFilter,
      isFiltersListView
    } = this.state;
    const {
      language,
      products,
      translation,
      title,
      selectedFilterCount,
      productSearchTitle,
      searchText,
      currentSelectedCategory,
      currentFilterSelected,
      handleFilterItemChecked,
      handleSortChangeEvent,
      sortOptions,
      selectedSort
    } = this.props;
    const filters = Object.keys(products.facets);
    const sortFilter = get(products, "sortFilter", {});
    const filteredSortOptions = sortOptions.map(option => ({
      key: option.key,
      value: this.generateHTMLForSortOptions(
        selectedSort === option.key,
        option.label
      )
    }));

    const selectedSortElement =
      (selectedSort &&
        filteredSortOptions.find(option => option.key === selectedSort)) ||
      selectedSort;
    let screenHeight = !isServer && window.innerHeight;
    return (
      <>
        <h1 className="item_category_type">
          {title
            ? `${title} ${
                currentSelectedCategory && `: ${currentSelectedCategory}`
              }`
            : searchText
            ? `${translation.searchResultsFor} "${searchText}"`
            : `${productSearchTitle && productSearchTitle}`}
        </h1>
        <div
          className={cn("filter_wrapper_mobile", {
            arabic: language.includes("ar"),
            inc_index: filterIndex,
            sizeFilterActive: sizeFilterActive,
            anotherFilter: !anotherFilter,
            filters_list_view: isFiltersListView
          })}
          ref={mFilter => (this.mobileFilter = mFilter)}
          onClick={this.filterClickHandler}
        >
          <Headroom disableInlineStyles>
            <div className="filter_types mobile_view_filters">
              <div className="product_counts">
                {products.nbHits} {translation.item}
              </div>
              <div className="sort_filter">
                {!isEmpty(filters) && (
                  <>
                    {sortFilter.isEnabled && (
                      <CustomDropdown
                        data={filteredSortOptions}
                        selectedItem={selectedSortElement}
                        forceTitle={translation.sort}
                        containerClass={"withBorder sortingProduct sort"}
                        scrollbarMaxHeight={150}
                        handleChange={sort => this.setSort(sort)}
                      />
                    )}
                    <div
                      className="filter_product"
                      onClick={() => this.handleToggle(true)}
                    >
                      <h4 className="filter">
                        {translation.filterTitle}
                        {selectedFilterCount !== 0 && (
                          <span className="filter_count">
                            {selectedFilterCount}
                          </span>
                        )}
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Headroom>
          <div
            className={cn("filter_flip_wrap", {
              open_multi_filter: isToggle,
              close_multi_filter: isToggle === false
            })}
          >
            <div
              ref={node => (this.wrapperRef = node)}
              className={cn("mobile_filter_flip", {
                open_filter_menu: isToggle,
                close_filter_menu: isToggle === false
              })}
            >
              <div
                className={cn("level_one_filter_type", {
                  arabic: language.includes("ar")
                })}
                style={{ height: screenHeight - 90 }}
              >
                <CloseClearFilter
                  closeFilter={() => this.handleToggle(false)}
                  translation={translation}
                  currentFilterSelected={currentFilterSelected}
                />
                <div className="clear_selected_filter">
                  <span onClick={this.handleClearFilters}>
                    {translation.clear}
                  </span>
                </div>
                <Filtertypes
                  handleSizeFilter={this.handleSizeFilter}
                  screenHeight={screenHeight}
                  isShouldShowTextForFilters={true}
                  clearRangeOnly={filterName => {
                    this.setState({ filterName });
                  }}
                  handleFilterItemChecked={handleFilterItemChecked}
                  handleSortChangeEvent={handleSortChangeEvent}
                  sortOptions={sortOptions}
                  selectedSort={selectedSort}
                />
                {
                  <FilterFooter
                    closeFilter={() => this.handleToggle(false)}
                    translation={translation}
                    products={products}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = reduxState => ({
  products: reduxState.productListingReducer.products,
  assistiveFilterClick: reduxState.productListingReducer.assistiveFilterClick,
  currentFilterSelected:
    reduxState.productListingReducer.mobileFilter.currentFilterSelected
});

const mapDispatchToProps = dispatch => ({
  handleSortChange: sort => dispatch(handleSortChange(sort)),
  getProductsData: (...args) => dispatch(getProductsData(...args)),
  handleAssistiveFilterClick: val => dispatch(handleAssistiveFilterClick(val)),
  handleFacetChange: (facet, subFacet, checked) =>
    dispatch(handleFacetChange(facet, subFacet, checked)),
  handlePageChange: page => dispatch(handlePageChange(page)),
  resetRangeChanged: () => dispatch(resetRangeChanged()),
  setMobilecurrentFilterSelected: filter =>
    dispatch(setMobilecurrentFilterSelected(filter))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileFilterTypes)
);
