import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import hoistNonReactStatics from "hoist-non-react-statics";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import AnalyticService from "../services/analytic-service";
import { UNMINIMIZED_NAME } from "../constants";
import { setPageType } from "../redux/actions/page.action";
import { GAService } from "../services/GA-service";
import { selectIsArabic } from "../util/selectors";

const getUrlBasedPageName = url => {
  if (!url) return null;

  if (url.includes("/brands")) return "Brands";
  if (url.includes("/checkout/")) return "Checkout";
  if (url.includes("/re-populate-cart/")) return "Repopulate cart";
  if (url.includes("/payment-error")) return "Payment Error";
  if (url.includes("/order-success")) return "Thank You";
  const lastPart = url.match(/[^\/]+(?=\/)/gi).pop();
  if (!lastPart) return null;
  const pdpUrlId = "p";

  return lastPart === pdpUrlId
    ? "pdp"
    : capitalize(lastPart.replaceAll("-", " "));
};

const getPredefinedPageName = (url, name) => {
  if (!url) return null;

  if (url.includes("/search")) return "Search";
  if (url.includes("/list")) return "List";
  if (url.includes("/brand")) return "product listing";
  if (name === UNMINIMIZED_NAME.HOME_PAGE) return "Home Page";
};

const withPageTypeTracking = Component => {
  const WrappedComponent = props => {
    const payloadRef = useRef(null);
    const [gaPayload, setGaPayload] = useState(null);
    const location = useLocation();
    const authData = useSelector(state => state.authReducer);
    const isArabic = useSelector(selectIsArabic);
    const dispatch = useDispatch();
    const { redirectionData, isBrandPage } = props;
    useEffect(() => {
      const wrappedComponentName =
        Component?.WrappedComponent?.unminimizedName ||
        Component?.unminimizedName;
      let pagePayload = {};
      if (redirectionData?.type) {
        const { type } = redirectionData;

        let pageTitle = "";
        if (!isEmpty(redirectionData))
          pageTitle =
            redirectionData.data?.en_title || redirectionData.data?.title;
        if (isEmpty(pageTitle) && document) {
          pageTitle = document.title;
        }
        pagePayload = { type, pageTitle: pageTitle.toLowerCase() };
      } else if (
        !isBrandPage &&
        wrappedComponentName !== UNMINIMIZED_NAME.REDIRECTION
      ) {
        const url = location.pathname;
        const type = wrappedComponentName
          ? getPredefinedPageName(url, wrappedComponentName)
          : getUrlBasedPageName(url);

        pagePayload = { type, pageTitle: type };
      }
      if (pagePayload.type) {
        dispatch(setPageType(pagePayload.type)); // setting an PageType for use in another events.
        setGaPayload(pagePayload);
      }
    }, [redirectionData?.url]);

    useEffect(() => {
      const { userLoggedIn } = authData;
      let authPayload = null;
      const prevGaPayload = payloadRef.current;
      if (userLoggedIn) {
        // if logged in
        if (authData.customerId) {
          authPayload = { customerId: `${authData.customerId}`, userLoggedIn };
        }
      } else {
        authPayload = { customerId: undefined, userLoggedIn: false };
      }
      if (gaPayload && !isEqual(gaPayload, prevGaPayload) && authPayload) {
        const wrappedComponentName =
          Component?.WrappedComponent?.unminimizedName ||
          Component?.unminimizedName;
        let url = redirectionData?.url;
        if (wrappedComponentName !== UNMINIMIZED_NAME.REDIRECTION) {
          url = location.pathname + location.search;
          if (isArabic) {
            const { match } = props;
            url = match.params.productName
              ? location.pathname.replace(match.params.productName, "")
              : location.pathname;
          }
        }

        // Meta Event
        GAService.common.trackPageView({
          ...authPayload,
          ...gaPayload
        });
        // Page View Seperate Event
        AnalyticService.common.trackPageViewEvent({
          url,
          ...authPayload,
          ...gaPayload
        });

        payloadRef.current = gaPayload;
      }
    }, [authData, gaPayload]);

    return <Component {...props} />;
  };

  hoistNonReactStatics(WrappedComponent, Component);

  return WrappedComponent;
};

export default withPageTypeTracking;
