import React from "react";
import CustomDropdown from "../../genericDropDown";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const CountrySelector = ({
  translation,
  otherCountrySelection,
  defaultCountry,
  countryData,
  onChangeHandler,
  settings,
  addExtraClass
}) => {
  const filteredCountryData =
    (countryData &&
      countryData.filter(
        country =>
          country.countrySHORT !==
          (defaultCountry && defaultCountry.countrySHORT)
      )) ||
    [];
  let _data =
    (filteredCountryData &&
      filteredCountryData.map(country => ({
        key: country.countrySHORT?.toUpperCase(),
        countryName: country.countryName
      }))) ||
    [];
  return (
    <div className="country_selector">
      {otherCountrySelection ? (
        <p>{translation.canShopFrom}</p>
      ) : (
        <p>{translation.shopIn}</p>
      )}
      <CustomDropdown
        alwaysShowDropdownIcon
        data={_data}
        selectedItem={
          (defaultCountry && defaultCountry.countryName) || "Location"
        }
        containerClass={"country_selector_wrap pref_country_dropdown"}
        scrollbarMaxHeight={250}
        handleChange={onChangeHandler}
        flagImage={defaultCountry && defaultCountry.countrySHORT}
        addExtraClass={addExtraClass}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  settings: state.common.settings
});

export default withRouter(connect(mapStateToProps)(CountrySelector));
