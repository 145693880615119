import { isNull, isUndefined } from "lodash";
import get from "lodash/get";
import moment from "moment";
import { isServer } from "..";
import { COUNTRY_NAME_SEO } from "../../constants";
import { getDeviceType } from "../../util";
import { isTouchScreen } from "../../util";

export const isItemAvailableInCountry = (item, countryId) =>
  Boolean(
    item &&
      item.available &&
      JSON.parse(item.available).find(c => c.countryId === countryId)
  );

export const getDeviceInfo = () => !isServer && get(window, "ua", "");

export const sleep = ms =>
  new Promise(resolve => {
    const timerId = setTimeout(() => {
      resolve();

      clearTimeout(timerId);
    }, ms);
  });

export const isLinkStartsFromHTTPS = (link = "") => link.startsWith("https://");

export const replaceCountryConstant = (string = "", countryCode) =>
  string.replace(COUNTRY_NAME_SEO, countryCode).trim();

export const replaceSeoKeys = (string = "", configSEOSettings) => {
  if (configSEOSettings && typeof configSEOSettings === "object")
    Object.keys(configSEOSettings)?.forEach(key => {
      string = string.replace(key, configSEOSettings[key]);
    });
  return string;
};

export const isNil = value => Boolean(isUndefined(value) || isNull(value));

export const getRedirectionUrl = (isExternalLink, redirectionPath) =>
  isExternalLink ? { pathname: redirectionPath } : redirectionPath;

export const retryCall = ({ command, retry = 3, delay = 1000 }) => {
  let currentRetryCount = retry;

  const updatedCommand = async () => {
    try {
      currentRetryCount--;

      return await command();
    } catch (error) {
      if (!currentRetryCount) throw error;

      if (delay) await sleep(delay);

      return updatedCommand();
    }
  };

  return updatedCommand();
};

export const getDataAsObject = data =>
  typeof data === "object" ? data : JSON.parse(data);

export const truncateProductTitle = (title, characters) => {
  if (title && title.length > characters)
    return title.substring(0, characters) + "...";
  else {
    return title;
  }
};

export const isDateInPast = date => moment(date).isBefore(new Date());
export const isDateInFuture = date => moment(date).isAfter(new Date());
