import capitalize from "lodash/capitalize";
import { LANGUAGE_ROUTE_KEY } from "../../constants";
import { getTrabsformedSubfacet, getFilterName } from "../../util/itemsFilter";
import { ARABIC_REQUEST_PATH_MAP } from "../../constants/quickFilter/ar_quickFilter";

export const getSizeKeys = subFacet =>
  subFacet ? Object.keys(subFacet).sort() || [] : [];

export const getMappedSizeObject = (subFacet, sizeKeys) => {
  if (!(subFacet && sizeKeys)) return;
  const obj = {};
  sizeKeys.forEach(k => {
    let ft = k.split(" > ");
    if (!obj[ft[0]]) {
      obj[ft[0]] = {
        sortOrderlvl0: ft[4] ? +ft[4] : 99999,
        [ft[1]]: {
          [ft[2]]: [],
          sortOrderlvl1: ft[5] ? +ft[5] : 99999
        }
      };
      obj[ft[0]][ft[1]][ft[2]].push({
        [ft[3]]: { ...subFacet[k], subFacet: k },
        sortOrderlvl2: ft[6] ? +ft[6] : 99999
      });
    }
    if (!obj[ft[0]][ft[1]]) {
      obj[ft[0]][ft[1]] = {
        [ft[2]]: [],
        sortOrderlvl1: ft[5] ? +ft[5] : 99999
      };
      obj[ft[0]][ft[1]][ft[2]].push({
        [ft[3]]: { ...subFacet[k], subFacet: k },
        sortOrderlvl2: ft[6] ? +ft[6] : 99999
      });
    }
    if (!obj[ft[0]][ft[1]][ft[2]]) {
      obj[ft[0]][ft[1]][ft[2]] = [
        {
          [ft[3]]: { ...subFacet[k], subFacet: k },
          sortOrderlvl2: ft[6] ? +ft[6] : 99999
        }
      ];
    }
    if (obj[ft[0]][ft[1]][ft[2]].findIndex(x => x[ft[3]]) < 0) {
      obj[ft[0]][ft[1]][ft[2]].push({
        [ft[3]]: { ...subFacet[k], subFacet: k },
        sortOrderlvl2: ft[6] ? +ft[6] : 99999
      });
    }
  });
  return obj;
};

export const getSortedLvl0SizeObject = mappedObj => {
  let lvl0Object = {};
  Object.keys(mappedObj)
    .sort(function(x, y) {
      return mappedObj[x].sortOrderlvl0 - mappedObj[y].sortOrderlvl0;
    })
    .forEach(key => {
      delete mappedObj[key].sortOrderlvl0;
      lvl0Object[key] = mappedObj[key];
      let lvl1Object = {};
      Object.keys(lvl0Object[key])
        .sort(function(a, b) {
          return (
            lvl0Object[key][a].sortOrderlvl1 - lvl0Object[key][b].sortOrderlvl1
          );
        })
        .forEach(key1 => {
          delete lvl0Object[key][key1].sortOrderlvl1;
          lvl1Object[key1] = lvl0Object[key][key1];
        });
      lvl0Object[key] = lvl1Object;
    });
  return lvl0Object;
};

export const getSelectedSizeIndexObj = (
  sortedSizeFilterObj,
  selectedSizeFilter
) => ({
  lvl0:
    Object.keys(sortedSizeFilterObj).indexOf(selectedSizeFilter.lvl0) > -1
      ? Object.keys(sortedSizeFilterObj).indexOf(selectedSizeFilter.lvl0)
      : 0,
  lvl1:
    Object.keys(
      sortedSizeFilterObj[
        Object.keys(sortedSizeFilterObj)[
          (selectedSizeFilter.lvl0 &&
            Object.keys(sortedSizeFilterObj).indexOf(
              selectedSizeFilter.lvl0
            )) ||
            0
        ]
      ]
    ).indexOf(selectedSizeFilter.lvl1) > -1
      ? Object.keys(
          sortedSizeFilterObj[
            Object.keys(sortedSizeFilterObj)[
              (selectedSizeFilter.lvl0 &&
                Object.keys(sortedSizeFilterObj).indexOf(
                  selectedSizeFilter.lvl0
                )) ||
                0
            ]
          ]
        ).indexOf(selectedSizeFilter.lvl1)
      : 0
});

export const getSelectedFilters = subFacet => {
  const selectedFilters = {};
  Object.keys(subFacet).forEach(item => {
    if (subFacet[item].checked) {
      const filterValue = getTrabsformedSubfacet(item);
      const selectedFilterName = getFilterName(item);
      if (selectedFilters[selectedFilterName]) {
        selectedFilters[selectedFilterName].push(filterValue);
      } else {
        selectedFilters[selectedFilterName] = [filterValue];
      }
    }
  });
  return selectedFilters;
};

export const getLvl0FilterArray = sizesObj => Object.keys(sizesObj);

export const getLvl1FilterArray = (sizesObj, lvl0Key) =>
  Object.keys(sizesObj[Object.keys(sizesObj)[lvl0Key]]);

export const getLvl2FilterObject = (
  sizesObj,
  lvl1FilterArray,
  lvl0Key,
  lvl1Key
) => sizesObj[Object.keys(sizesObj)[lvl0Key]][lvl1FilterArray[lvl1Key]];

//exact the same as getLvl0FilterArray() but as the getLvl1FilterArray() function is different
//let each filter level has its own function for array getting to keep the more supportable code
export const getLvl2FilterArray = lvl2SizesObj => Object.keys(lvl2SizesObj);

export const getLvl2FilterData = (
  sizesObj,
  selectedLvl0Data,
  activeSlideKeyLvl1
) =>
  sizesObj[selectedLvl0Data] && sizesObj[selectedLvl0Data][activeSlideKeyLvl1];

export const getLvl2FilterDataArray = level2Data =>
  level2Data ? Object.keys(level2Data) : [];

export const getLvl3FilterData = (
  sizesObj,
  selectedLvl0Data,
  activeSlideKeyLvl1,
  sizeOption
) =>
  sizesObj[selectedLvl0Data] &&
  sizesObj[selectedLvl0Data][activeSlideKeyLvl1] &&
  sizesObj[selectedLvl0Data][activeSlideKeyLvl1][sizeOption];

const getSplittedCapitalizedRedirection = quickFilterStepPath =>
  [...quickFilterStepPath]
    .pop()
    .split("/")
    .filter(Boolean)
    .map(item =>
      item
        .split(" & ")
        .map(capitalize)
        .join(" & ")
    );

export const getHierarchySearchArrayFromPath = (
  quickFilterStepPath,
  selectedCategories,
  language = LANGUAGE_ROUTE_KEY.english
) => {
  if (language === LANGUAGE_ROUTE_KEY.english) {
    const lastRedirect = getSplittedCapitalizedRedirection(quickFilterStepPath);
    return selectedCategories.map(category =>
      encodeURIComponent([...lastRedirect, category].join(" > "))
    );
  } else {
    return selectedCategories.map(
      category =>
        ARABIC_REQUEST_PATH_MAP[[...quickFilterStepPath].pop()] +
        " > " +
        category
    );
  }
};

export const getFilteredSizeObject = (
  selectedProductTypes,
  filteredSizesObject,
  allowedSizeFormats
) => {
  const parsedPaths = {};
  selectedProductTypes.forEach(type =>
    Object.keys(filteredSizesObject[type]).forEach(style => {
      Object.keys(filteredSizesObject[type][style])
        .filter(sizeFormat => {
          const filter = new RegExp(`^${allowedSizeFormats.join("|")}$`, "i");
          return filter.test(sizeFormat);
        })
        .forEach(sizeFormat => {
          const sizeFormatArray = filteredSizesObject[type][style][sizeFormat];
          sizeFormatArray.flat().forEach(sizeObj => {
            const sizeTitle = Object.keys(sizeObj)[0];
            const filterURLPath = sizeObj[sizeTitle].subFacet;
            const { sortOrderlvl2 } = sizeObj;
            const trimmedTitle = sizeTitle.trim();
            if (parsedPaths[trimmedTitle]) {
              parsedPaths[trimmedTitle] = {
                path: [...parsedPaths[trimmedTitle].path, filterURLPath],
                sortOrderlvl2: Math.max(
                  parsedPaths[trimmedTitle].sortOrderlvl2,
                  sortOrderlvl2
                )
              };
            } else {
              parsedPaths[trimmedTitle] = {
                path: [filterURLPath],
                sortOrderlvl2
              };
            }
          });
        });
    })
  );
  return parsedPaths;
};

export const getArrayFromSizeObject = sizesObj =>
  Object.keys(sizesObj).map(key => ({
    [key]: sizesObj[key].path,
    sortOrderlvl2: sizesObj[key].sortOrderlvl2
  }));
