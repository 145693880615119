import { lowerCase } from "lodash";
import GAExtensions from "../extensions";

class HeaderService extends GAExtensions {
  getMenuTree = (...levels) => {
    const correctFormat = string => string.toLowerCase().trim();
    const formattedLevels = levels.map(correctFormat);
    return formattedLevels.join(" > ");
  };
  /**
   * @param {string} click_text , text of navigation where user clicked
   * @param {string} link link of navigation
   * @description this method is for BFL store logo click, download app nav, and country selection nav on top header.
   */
  trackNavTop = (click_text = "", link = null) => {
    let nav_link = {};
    if (link) {
      nav_link = { nav_link: link };
    }
    this.pushToDataLayer({
      event: "nav_top",
      click_text: click_text.toLowerCase(),
      ...nav_link
    });
  };
  /**
   * @param {string} uri
   * @description When user click on BFL Logo.
   */
  trackHomeLogoClick = uri => {
    this.trackNavTop("home", uri);
  };

  // track menu and submenus clicks
  trackNavClick = (click_text, link = null, tree = null) => {
    click_text = lowerCase(click_text);
    const obj = { click_text };
    if (tree) obj.nav_tree = tree;
    if (link) obj.nav_link = link;

    this.pushToDataLayer({
      event: "nav_click",
      ...obj
    });
  };

  trackMegaMenuClick = menuItem => {
    const headerTitle = menuItem.en_headerTitle || menuItem.headerTitle || "";
    this.trackNavClick(headerTitle, menuItem.redirectionLink);
  };
  trackLevel1SubMenuClick = (menuItem, clickLink) => {
    const headerTitle = menuItem.en_headerTitle || menuItem.headerTitle || "";
    const subTitle = menuItem.en_subTitle || menuItem.subTitle || "";
    const navTree = this.getMenuTree(headerTitle, subTitle);

    this.trackNavClick(subTitle, clickLink, navTree);
  };
  trackLevel2SubMenuClick = (menuItem, clickLink) => {
    const headerTitle = menuItem.en_headerTitle || menuItem.headerTitle || "";
    const subTitle = menuItem.en_subTitle || menuItem.subTitle || "";
    const subTitle1 = menuItem.en_subTitle1 || menuItem.subTitle1 || "";
    const navTree = this.getMenuTree(headerTitle, subTitle1, subTitle);

    this.trackNavClick(subTitle, clickLink, navTree);
  };
  trackChangeLanguage = selected_language => {
    this.pushToDataLayer({ event: "change_language", selected_language });
  };
  // Search Interaction
  trackSearchInteraction = (search_type = null) => {
    this.pushToDataLayer({
      event: "search_interaction",
      search_type
    });
  };
  trackSyteSearchInteraction = () => {
    this.trackSearchInteraction("visual");
  };
  trackSearchInputInteraction = () => {
    this.trackSearchInteraction("text");
  };

  trackProductSearch = payload => {
    this.pushToDataLayer({
      event: "search",
      ...payload
    });
  };
  trackRecommendedProductSearch = payload => {
    const {
      search_term,
      search_category,
      productItem,
      number_of_search_results = 1
    } = payload;
    this.trackProductSearch({
      search_type: "text",
      ...(search_term && { search_term: lowerCase(search_term) }),
      search_category: lowerCase(search_category),
      click_text: lowerCase(productItem.en_title || productItem.title || ""),
      number_of_search_results
    });
  };
  trackEmptySearchResult = (search_term = "") => {
    this.pushToDataLayer({
      event: "null_search",
      search_term
    });
  };
  trackMyAccountOptionsClicked = option => {
    this.pushToDataLayer({
      event: "my_account_option_clicked",
      option_selected: lowerCase(option)
    });
  };
  trackViewSearchResult = payload => {
    this.pushToDataLayer({
      event: "view_search_results",
      ...payload
    });
  };
}

export default HeaderService;
