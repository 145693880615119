import { CARD_TYPE_VALIDATORS } from "../../constants";

const get_card_type = number => {
  let card_type, _i, _len;
  for (_i = 0, _len = CARD_TYPE_VALIDATORS.length; _i < _len; _i++) {
    card_type = CARD_TYPE_VALIDATORS[_i];
    if (number.match(card_type.pattern)) return card_type;
  }
  return null;
};

const is_valid_luhn = number => {
  var digit, n, sum, _len, _ref;
  sum = 0;
  _ref = number
    .split("")
    .reverse()
    .join("");
  for (n = 0, _len = _ref.length; n < _len; n++) {
    digit = _ref[n];
    digit = +digit;
    if (n % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
};

const is_valid_length = (number, card_type) => {
  return card_type.valid_length.indexOf(number.length) !== -1;
};

const normalize = number => {
  return number.replace(/[ -]/g, "");
};

export const validateCard = number => {
  let card_type, length_valid, luhn_valid;
  card_type = get_card_type(normalize(number));
  luhn_valid = false;
  length_valid = false;
  if (card_type != null) {
    luhn_valid = is_valid_luhn(number);
    length_valid = is_valid_length(number, card_type);
  }
  return {
    card_type: card_type && card_type.name,
    luhn_valid: luhn_valid,
    length_valid: length_valid
  };
};
