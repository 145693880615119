import React, { Fragment } from "react";
import moment from "moment";
import map from "lodash/map";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { myAccountTranslation } from "../../language/myAccount/en/myAccount";
import { isServer } from "../../util";
import { changeLanguage } from "../../redux/actions/common.action";

class TrackOrderTimeline extends React.Component {
  state = {
    trackTranslation: myAccountTranslation
  };
  UNSAFE_componentWillMount() {
    const { match, language, handleLanguageChange } = this.props;
    if (!isServer) {
      let languageParam =
        (match && match.params && match.params.language) || language;
      if (languageParam.includes("ar-") || languageParam.includes("ar")) {
        languageParam = "ar";
      } else {
        languageParam = "en";
      }
      handleLanguageChange(languageParam);
      this.getlanguage(language);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
    }
  }
  getlanguage = language => {
    const languageResponse = import(
      `../../language/myAccount/${language}/myAccount`
    );
    languageResponse.then(response => {
      this.setState({
        trackTranslation: response.myAccountTranslation
      });
    });
  };

  render() {
    const { trackTranslation } = this.state;
    const { trackOrder, handleTrackOrder, showCrossIcon = false } = this.props;
    let completeStatus =
      trackOrder.orderStatusDetails &&
      !!trackOrder.orderStatusDetails.length &&
      trackOrder.orderStatusDetails.findIndex(
        orderStatus => !orderStatus.status
      );
    return (
      <div className="track_order_wrapper">
        {
          <div className="order_deliver_steps">
            {showCrossIcon && (
              <span className="cross-icon" onClick={handleTrackOrder} />
            )}
            <ul className="track_order_web">
              {map(trackOrder.orderStatusDetails, (orderStatus, idx) => (
                <li
                  key={orderStatus.statusMessage}
                  className={
                    idx === completeStatus - 1
                      ? "coming_bar"
                      : idx < completeStatus - 1
                      ? "success_bar"
                      : completeStatus === -1
                      ? "success_bar"
                      : ""
                  }
                >
                  <div className="order_delivery_status">
                    <h4>
                      {orderStatus.date &&
                        moment(orderStatus.date).format("DD MMM")}
                    </h4>
                    <p className="success">
                      {trackTranslation[orderStatus.statusMessage]}
                    </p>
                  </div>
                </li>
              ))}
              {/*** use 'coming_bar ,success_bar' for dotted strip ***/}
            </ul>
            <ul className="track_order_mob">
              {map(trackOrder.orderStatusDetails, (orderStatus, idx) => (
                <li
                  key={orderStatus.statusMessage}
                  className={
                    idx === completeStatus - 1
                      ? "coming_bar"
                      : idx < completeStatus - 1
                      ? "success_bar"
                      : completeStatus === -1
                      ? "success_bar"
                      : ""
                  }
                >
                  <div className="order_delivery_status">
                    {trackOrder.orderStatusDetails.length + completeStatus >=
                    trackOrder.orderStatusDetails.length ? (
                      completeStatus - 1 === idx ? (
                        <Fragment>
                          <h4>
                            {orderStatus.date &&
                              moment(orderStatus.date).format("DD MMM")}
                          </h4>
                          <p className="success">
                            {trackTranslation[orderStatus.statusMessage]}
                          </p>
                        </Fragment>
                      ) : null
                    ) : trackOrder.orderStatusDetails.length +
                        completeStatus ===
                      idx ? (
                      <Fragment>
                        <h4>
                          {orderStatus.date &&
                            moment(orderStatus.date).format("DD MMM")}
                        </h4>
                        <p className="success">
                          {trackTranslation[orderStatus.statusMessage]}
                        </p>
                      </Fragment>
                    ) : null}
                  </div>
                </li>
              ))}
            </ul>
            {trackOrder.courier && (
              <div className="delivery_mode">
                <h4>
                  <span>Courier Name:</span> {trackOrder.courier}{" "}
                </h4>
              </div>
            )}
          </div>
        }
        <div className="order_status_timeline">
          <ul>
            {map(trackOrder.trackingDetails, trackingDetails => (
              <li key={trackingDetails.traceDate}>
                <h4>
                  {trackingDetails.traceDate && (
                    <>
                      {moment(trackingDetails.traceDate).format("DD MMM")},{" "}
                      <span>
                        {moment(trackingDetails.traceDate).format("LT")}
                      </span>
                    </>
                  )}
                </h4>
                <p>{trackingDetails.status}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  language: reduxState.common.language
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrackOrderTimeline)
);
