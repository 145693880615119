import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Scrollbars } from "react-custom-scrollbars";

import {
  COUNTRY_SELECTION_MAP,
  DELIVERY_TYPES,
  DROP_DOWN_SCROLL_TYPES,
  LOADER_TYPES
} from "../../../constants";

import {
  isServer,
  getInstoreCityList,
  languageFromPathName,
  isTouchScreen
} from "../../../util";
import { getDividedNumber } from "../../../util/phoneNumber";

import AnalyticService from "../../../services/analytic-service";

import {
  setAddress,
  inStoreDeliveryAddressCheckout,
  handleIntlInputTextChange,
  resetInStoreDeliveryAddress,
  setStandardDeliveryAddress,
  resetMapDeliveryAddress
} from "../../../redux/actions/checkout.action";
import {
  saveAddress,
  guestUserCheckout,
  resetAPIError
} from "../../../redux/actions/homepage.action";
import {
  handleTextChange,
  resetAddress
} from "../../../redux/actions/address.action";
import {
  deleteAddress,
  setDefaultAddress
} from "../../../redux/actions/authConfig.action";
import {
  getConfigurationSettings,
  getCountrySettings
} from "../../../redux/actions/common.action";
import { selectIsConfigSettingsLoaded } from "../../../util/selectors";

import AddressForm from "../../../routes/my-account/address-form";
import CustomDropdown from "../../genericDropDown";
import GoogleMap from "../../google/google-map";
import ModalBFL from "../../modal/modal";
import ViewAddress from "../../address/viewAddress";
import ChangeAddressButtons from "../../change-address-buttons";
import HtmlParser from "../../html-parser";
import MoveToNextStep from "../../move-next-step-btn";
import PickUpUserForm from "./delivery-pick-up-user-form";
import MobileDeliveryAddressModal from "../../mobile/mobile-delivery-address-modal";
import MobileAddressBook from "../../mobile/mobile-address-book";
import AddAddressMobileNumber from "../../modal/modal-mobile-verification/addAddressMobileNumber";
import { checkoutTranslation as checkoutTranslationEn } from "../../../language/checkoutFlow/en/checkoutFlow";

import {
  getModifiedInStoreShopName,
  getCollectionPointsMarkup
} from "./templates";
import { GAService } from "../../../services/GA-service";
import DeleteModal from "../../delete-modal";
import Loader from "../../loader";
import CrossIcon from "../../../images/cross_grey_icon.svg";

const fallbackCoordinates = {
  //as a fallback, if the user's country doesn't have it's initial coordinates, the map will be centered on Dubai
  lat: 25.2048493,
  lng: 55.270782800000006
};

class DeliveryDetails extends Component {
  state = {
    openAddressModal: false,
    openRespAddressModal: false,
    openAddressBook: false,
    openRespAddressBook: false,
    selectedMapArea: "",
    selectedMapLat: "",
    selectedMapLng: "",
    mapSearchText: "",
    isEditAddress: false,
    isEditGuestAddress: false,
    slicePhoneLength: 0,
    invalidPhoneNumber: false,
    invalidFirstName: false,
    site_id: "",
    selectedInStoreMapLat: "",
    selectedInStoreMapLng: "",
    inStoreShopName: [],
    invalidInstorePhoneNumber: false,
    invalidInstoreFirstName: false,
    selectedInstoreShop: "",
    cityList: [],
    mapRecalculationTrigger: null,
    showOTPModal: false,
    onSuccessOtp: null,
    showButtonLoader: false,
    storePickUpMapZoom: 10,
    showNoCollectionPointsAlert: false,
    activeCollectionPoint: null,
    collectionPointsIsFetching: false
  };

  pickUpFormRef = React.createRef();

  componentDidMount() {
    const {
      language,
      countryId,
      checkoutReducer,
      countryData,
      getCountrySettings,
      open,
      getConfigurationSettings,
      isConfigSettingsLoaded
    } = this.props;

    setTimeout(() => {
      const { fetchInStorePickupData } = this.props;
      let languageParam = window.location.pathname || language;
      languageParam = languageFromPathName(languageParam);
      fetchInStorePickupData(languageParam, countryId);
    }, 300);

    if (isConfigSettingsLoaded) {
      this.locationDataManaging();
    }

    if (checkoutReducer.inStoreList.length) {
      const storeList = get(checkoutReducer, "inStoreList", []);
      const inStoreCityList = getInstoreCityList(storeList, countryId);

      this.changeSelectedInstoreCity(inStoreCityList[0]);
    }
    if (open && isEmpty(countryData)) {
      getCountrySettings(language);
      getConfigurationSettings();
    }
  }

  async componentDidUpdate(prevProps) {
    const prevOpen = prevProps.open;
    const {
      countryData,
      language,
      getCountrySettings,
      open,
      getConfigurationSettings
    } = this.props;
    const openFlagOn = open && !prevOpen;

    if (openFlagOn && isEmpty(countryData)) {
      await getCountrySettings(language);
      getConfigurationSettings();
    }

    if (
      !prevProps.configSettings.default?.mapPoints &&
      this.props.configSettings.default?.mapPoints
    ) {
      this.locationDataManaging();
    }
  }

  fetchSelfPickupPoints = async (
    lat,
    lng,
    languageParam,
    withNoPointsModal
  ) => {
    const { fetchMapFilterData } = this.props;
    const { collectionPointsIsFetching } = this.state;

    const response = await fetchMapFilterData(lat, lng, languageParam);

    if (withNoPointsModal && !response.data?.points?.length) {
      this.setState({ showNoCollectionPointsAlert: true });
    }
    collectionPointsIsFetching &&
      this.setState({ collectionPointsIsFetching: false });
  };

  locationDataManaging = async () => {
    const { language, checkoutTranslation, configSettings } = this.props;

    const points = configSettings.default?.mapPoints;
    let languageParam = languageFromPathName(
      window.location.pathname || language
    );
    const mapPoints =
      (!isEmpty(points) && JSON.parse(points)) || fallbackCoordinates;
    const { lat, lng } = mapPoints;
    this.fetchSelfPickupPoints(lat, lng, languageParam);
    this.setState({
      selectedMapArea: {
        ...this.state.selectedMapArea,
        station_name: checkoutTranslation.selectPickUpLocation
      }
    });
  };

  saveMapAddress = (e, address) => {
    const { mapDeliveryAddressCheckout } = this.props;
    GAService.checkout.trackCollectionPtPickUp(address);
    mapDeliveryAddressCheckout(address);
  };

  saveInStoreMapAddress = (e, address) => {
    const { inStoreDeliveryAddressCheckout } = this.props;

    this.triggerSelectShop(address);
    this.setState({
      selectedInstoreShop: {
        ...address,
        stateId: this.state.selectedInStoreStateId
      }
    });

    inStoreDeliveryAddressCheckout({
      ...address,
      stateId: this.state.selectedInStoreStateId
    });
  };

  changeSelectedArea = point => {
    const { mapFilterData } = this.props;

    const selectedArea = mapFilterData.station_list.find(
      list => list.collectionPointId === point.collectionPointId
    );
    this.setState({
      selectedMapArea: selectedArea,
      selectedMapLat: selectedArea.lat,
      selectedMapLng: selectedArea.lng,
      site_id: selectedArea.site_id
    });
    this.saveMapAddress(null, selectedArea);
  };

  toggleChange = () => {
    const { order, changeCheckoutData, checkoutTranslation } = this.props;
    if (order.step2Completed) {
      changeCheckoutData("step2Completed", false);
      AnalyticService.checkout.trackCheckoutStepFlow({
        step: 2,
        name: checkoutTranslation.deliveryDetails
      });
    }
  };

  onMapBoundsMove = ({ center: { lat, lng } }) => {
    const { language } = this.props;

    if (lat !== 0 && lng !== 0) this.fetchSelfPickupPoints(lat, lng, language);
  };

  changeSelectedInstoreCity = (selectedInstoreCity, address) => {
    const inStoreShopName = get(selectedInstoreCity, "shops", []).map(shop => ({
      city: selectedInstoreCity.name || selectedInstoreCity.value,
      ...shop
    }));

    this.setState({
      inStoreShopName,
      selectedInstoreCity,
      selectedInStoreMapLat: +get(selectedInstoreCity, "lat", 0),
      selectedInStoreMapLng: +get(selectedInstoreCity, "lng", 0),
      selectedInStoreStateId: +get(selectedInstoreCity, "stateId", 0),
      selectedInstoreShop: address
    });
  };
  triggerSelectShop = data => GAService.checkout.trackPickUpStoreSelect(data);

  changeSelectedInstoreCityShops = shop => {
    const { inStoreDeliveryAddressCheckout } = this.props;
    const { inStoreShopName } = this.state;

    const selectedInstoreCityShop = inStoreShopName.find(
      list => list.station_id === shop.station_id
    );
    this.triggerSelectShop(shop);
    this.setState({
      selectedInstoreShop: shop,
      selectedInStoreMapLat: +selectedInstoreCityShop.lat,
      selectedInStoreMapLng: +selectedInstoreCityShop.lng,
      selectedInStoreStateId: this.state.selectedInStoreStateId
    });
    inStoreDeliveryAddressCheckout({
      ...shop,
      stateId: this.state.selectedInStoreStateId
    });
  };

  validateForm = e => {
    if (e) e.preventDefault();

    const { _address } = this.props;

    if (_address) {
      Object.keys(_address.errors).forEach(key => {
        this.handleTextChange({
          target: {
            name: key,
            value: _address[key]
          }
        });
      });
    }
  };

  handleTextChange = (e, p) => {
    const { handleTextChange } = this.props;

    handleTextChange(p || e.target.name, e.target.value);
  };

  toggleAddressModal = (
    value,
    clearModal,
    pickOrderAddress,
    isEditAddress = false
  ) => {
    const { resetAddress, order, setAddress } = this.props;
    const { slicePhoneLength } = this.state;

    this.setState({
      openAddressModal: value,
      isEditGuestAddress: isEditAddress
    });

    if (clearModal || !value) {
      resetAddress();
    }

    if (order && order.address) {
      if (setAddress && pickOrderAddress) {
        const _address = {
          ...order.address,
          firstname: `${order.address.firstname}`.trim(),
          lastname: `${order.address.lastname}`.trim(),
          address: `${order.address.address}`.trim(),
          area: `${order.address.area}`.trim(),
          country: `${order.address.country}`.trim(),
          city: `${order.address.city}`.trim()
        };

        setAddress({
          ..._address,
          phone: _address.phone.slice(-slicePhoneLength)
        });
      }
    }
  };

  toggleRespAddressModal = value => {
    const { resetAddress } = this.props;

    this.setState({ isEditAddress: false });
    resetAddress();
    this.setState({ openRespAddressModal: value });
  };

  handleInvalidPhoneNumber = () => {
    const { mapDeliveryAddress } = this.props.checkoutReducer;

    this.setState({
      invalidPhoneNumber: mapDeliveryAddress.phone === "",
      invalidFirstName: mapDeliveryAddress.firstname.trim().length < 2
    });
  };

  handleInstoreInvalidPhoneNumber = () => {
    const { inStoreDeliveryAddress } = this.props.checkoutReducer;

    this.setState({
      invalidInstorePhoneNumber: inStoreDeliveryAddress.phone === "",
      invalidInstoreFirstName:
        inStoreDeliveryAddress.firstname.trim().length < 2
    });
  };

  moveToNextStep = async () => {
    const { moveToNextStep, order, checkoutReducer } = this.props;
    const { mapDeliveryAddress, inStoreDeliveryAddress } = checkoutReducer;
    this.setState(() => ({ showButtonLoader: true }));

    this.submitPickupForm();

    let addressObject = null;
    if (order.deliveryType === DELIVERY_TYPES.SELF_PICKUP) {
      addressObject = mapDeliveryAddress;
    } else if (order.deliveryType === DELIVERY_TYPES.STORE_PICKUP) {
      addressObject = inStoreDeliveryAddress;
    }

    if (addressObject) {
      const { phone, errors, firstname } = addressObject;
      const dataIsValid =
        phone &&
        errors.phone.valid &&
        firstname &&
        errors.firstname.valid &&
        firstname.trim?.().length;
      if (!!dataIsValid) {
        await moveToNextStep();
      } else {
        this.handleInvalidPhoneNumber();
      }
      this.setState(() => ({ showButtonLoader: false }));
      return false;
    }

    await moveToNextStep();
    this.setState(() => ({ showButtonLoader: false }));
  };

  changeFromAddressBook = value => {
    this.setState({ openAddressBook: value });
  };

  changeFromRespAddressBook = value => {
    this.setState({ openRespAddressBook: value });
  };

  submitPickupForm = () => {
    if (this.pickUpFormRef && this.pickUpFormRef.current) {
      this.pickUpFormRef.current.handleSubmit();
    }
  };

  _getSelectedCityArea = (address, state) => {
    const selectedCity =
      address &&
      address.cityId &&
      state &&
      state.length &&
      state.find(obj => obj.stateId === parseInt(address.cityId));
    const selectedArea =
      address &&
      address.areaId &&
      selectedCity &&
      selectedCity.area &&
      selectedCity.area.length &&
      selectedCity.area.find(obj => obj.areaId === parseInt(address.areaId));

    return { selectedArea, selectedCity };
  };

  // TODO: Bind city data logic to component's state
  handleMapSelect = address => {
    const { fetchMapFilterData, language } = this.props;
    const { selectedSelfPickupCity } = this.state;

    const constrainedAddress = `${address}, ${selectedSelfPickupCity}`;
    this.setState({ collectionPointsIsFetching: true });
    geocodeByAddress(constrainedAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng =>
        this.setState(
          {
            mapSearchText: address,
            selectedMapLat: latLng.lat,
            selectedMapLng: latLng.lng
          },
          () => {
            this.fetchSelfPickupPoints(
              this.state.selectedMapLat,
              this.state.selectedMapLng,
              language,
              true,
              true
            );
          }
        )
      )
      .catch(error => {
        this.setState({ collectionPointsIsFetching: false });
        console.error("Error", error);
      });
  };

  handleMapChange = address => {
    this.setState({ mapSearchText: address });
  };

  handleDeleteAddress = id => {
    const { deleteAddress, setStandardDeliveryAddress } = this.props;

    setStandardDeliveryAddress({});
    deleteAddress(id);
  };

  handleEditAddress = address => {
    const { setAddress } = this.props;

    this.setState({ isEditAddress: true, openRespAddressModal: true });

    setAddress(address);
  };

  handleDefaultAddress = (e, index, address) => {
    e && e.preventDefault();
    const { setDefaultAddress, addressBook, saveAddress } = this.props;
    setDefaultAddress(
      e.target.name,
      e.target.type === "radio" ? e.target.checked : e.target.value,
      index
    );

    if (addressBook[index]) {
      addressBook[index].addressLine2 = addressBook[index].addressLine2 || "";

      saveAddress({
        ...address,
        default: e.target.checked,
        lastname: address.lastname || ""
      });
    }
  };

  showAddress = continueAsGuest => {
    const { addressBook, commonSettings } = this.props;

    if (continueAsGuest) return true;

    const currAddress = find(addressBook, {
      countryId: commonSettings.countryId
    });

    return !isEmpty(currAddress);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      order,
      mobileLocalCode,
      mobileCountryCode,
      mobileLocalNumberLength,
      fetchMapFilterData,
      language
    } = this.props;
    const { mapDeliveryAddress, inStoreList, inStoreDeliveryAddress } =
      this.props.checkoutReducer;

    const npInStoreDeliveryAddress =
      nextProps.checkoutReducer.inStoreDeliveryAddress;
    if (
      inStoreDeliveryAddress !== npInStoreDeliveryAddress &&
      !get(npInStoreDeliveryAddress, "addressLine1", "")
    ) {
      this.setState({ selectedInstoreShop: "" });
    }

    if (order.address && mobileCountryCode) {
      const fullPhoneNumber = order.address.phone;
      const phoneDetails = {
        phone: fullPhoneNumber,
        mobileCountryCode,
        mobileLocalCode,
        mobileLocalNumberLength
      };
      const { areaCode } = getDividedNumber(phoneDetails);
      const slicePhoneLength =
        ((areaCode && areaCode.length) || 0) + mobileLocalNumberLength;
      this.setState({ slicePhoneLength });
    }

    if (
      order &&
      order.continueAsGuest !==
        (nextProps && nextProps.order && nextProps.order.continueAsGuest)
    ) {
      this.showAddress(nextProps.order.continueAsGuest);
    }
    const npMapDeliveryAddress = nextProps.checkoutReducer.mapDeliveryAddress;
    if (
      npMapDeliveryAddress &&
      npMapDeliveryAddress.site_id &&
      mapDeliveryAddress.site_id !== npMapDeliveryAddress.site_id
    ) {
      let languageParam = window.location.pathname || language;
      languageParam = languageFromPathName(languageParam);
      this.fetchSelfPickupPoints(
        npMapDeliveryAddress.lat,
        npMapDeliveryAddress.lng,
        languageParam
      );
    }

    if (inStoreList.length !== nextProps.checkoutReducer.inStoreList.length) {
      const storeList = get(nextProps.checkoutReducer, "inStoreList", []);
      const countryId = get(
        nextProps,
        "checkoutReducer.checkoutData.address.countryId",
        ""
      );
      const inStoreCityList = getInstoreCityList(
        storeList,
        get(nextProps, "countryId", countryId || 236)
      );
      this.changeSelectedInstoreCity(
        inStoreCityList[0],
        get(nextProps, "_address", "")
      );
    }
  }

  handleCountryChange = (status, value) => {
    const { handleIntlInputTextChange, order } = this.props;

    const deliveryType = order.deliveryType;

    handleIntlInputTextChange("phone", value, deliveryType, status);

    switch (deliveryType) {
      case "SELF_PICKUP":
        this.setState({ invalidPhoneNumber: false });
        break;
      case "STORE_PICKUP":
        this.setState({ invalidInstorePhoneNumber: false });
        break;
      default:
        return null;
    }
  };

  onSelectFlag = (a, b) => {
    const { handleIntlInputTextChange, order } = this.props;
    const deliveryType = order.deliveryType;

    handleIntlInputTextChange("phone", "", deliveryType);
    handleIntlInputTextChange("dialCode", b.dialCode, deliveryType);
  };

  onSelectArea = area => {
    const { handleIntlInputTextChange, order } = this.props;
    const deliveryType = order.deliveryType;

    handleIntlInputTextChange("phone", "", deliveryType);
    handleIntlInputTextChange("areaCode", area, deliveryType);

    switch (deliveryType) {
      case "SELF_PICKUP":
        this.setState({ invalidPhoneNumber: false });
        break;
      case "STORE_PICKUP":
        this.setState({ invalidInstorePhoneNumber: false });
        break;
      default:
        return null;
    }
  };

  handleNameChange = e => {
    const { handleIntlInputTextChange, order } = this.props;
    const deliveryType = order.deliveryType;

    handleIntlInputTextChange(e.target.name, e.target.value, deliveryType);

    switch (deliveryType) {
      case "SELF_PICKUP":
        this.setState({ invalidFirstName: false });
        break;
      case "STORE_PICKUP":
        this.setState({ invalidInstoreFirstName: false });
        break;
      default:
        return null;
    }
  };

  getAreaCode(address) {
    const { mobileLocalCode, mobileLocalNumberLength } = this.props;
    const { slicePhoneLength } = this.state;

    const length = mobileLocalCode && mobileLocalCode.split(",")[0].length;
    if (address.phone.length > mobileLocalNumberLength) {
      return address.phone.slice(-slicePhoneLength).slice(0, length);
    }

    return address.areaCode;
  }

  getAreaCodes = (countryData, defaultCountry) => {
    const countryInfo = find(countryData, { countrySHORT: defaultCountry });

    return (
      countryInfo &&
      countryInfo.mobileLocalCode &&
      countryInfo.mobileLocalCode.split(",")
    );
  };

  resetAddAddress = () => {
    const { resetAddAddress } = this.props;

    resetAddAddress();
  };

  filteredAddressBook = () => {
    const { addressBook, countryId } = this.props;

    const filteredAddressBookByDefaultAddress = filter(addressBook, {
      countryId,
      default: true
    });
    const filteredAddressBookByCountry = filter(addressBook, { countryId });

    if (filteredAddressBookByDefaultAddress.length > 0) {
      return get(filteredAddressBookByDefaultAddress, "0", {});
    } else {
      return get(filteredAddressBookByCountry, "0", {});
    }
  };

  getStandardDeliveryAddress = address => {
    const { mobileLocalCode, mobileCountryCode, mobileLocalNumberLength } =
      this.props;

    const { phoneWithoutCountryCode } = getDividedNumber({
      phone: address.phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    });

    return {
      ...address,
      phone: phoneWithoutCountryCode
    };
  };

  onSubmit = async data => {
    const {
      order,
      guestUserCheckout,
      updateAddress,
      setStandardDeliveryAddress,
      alreadyLoggedIn,
      address
    } = this.props;

    const addressData = this.getTransformedAddressData(data, address.addressId);

    if (alreadyLoggedIn) {
      updateAddress(addressData, "step2", null, null, () => {
        this.toggleAddressModal(false);
        this.toggleRespAddressModal(false);
      });

      return;
    }

    const response = await guestUserCheckout({
      email: order.guestEmail,
      ...addressData
    });

    const onSuccessOtp = userData => {
      if (get(userData, "data.success")) {
        updateAddress(
          { ...addressData, addressId: userData.data.addressId },
          "step2",
          userData.data.customerId
        );
        setStandardDeliveryAddress({
          ...addressData,
          addressId: userData.data.addressId
        });

        this.toggleAddressModal(false);
        this.toggleRespAddressModal(false);
        this.handleGAGuest(addressData, userData.data.customerId);
      }
    };

    if (response.OTPNeeded) {
      this.setState({ showOTPModal: true, onSuccessOtp });
      return;
    }

    onSuccessOtp(response);
  };

  getDefaultCountryPhoneArea() {
    const { mobileLocalCode } = this.props;

    return mobileLocalCode?.length > 0 ? mobileLocalCode.split(",")[0] : "";
  }

  getPhoneNumberForStorePickUp = address => {
    const { mobileCountryCode, mobileLocalCode, mobileLocalNumberLength } =
      this.props;

    const { phoneWithoutCountryCode } = getDividedNumber({
      phone: address.phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    });

    return phoneWithoutCountryCode;
  };

  getTransformedAddressData = (
    {
      address,
      area,
      phoneArea = "",
      city,
      country,
      isDefaultAddress,
      firstName,
      lastName,
      phoneNumber,
      zipCode
    },
    addressId
  ) => {
    const areaCode = phoneArea || this.getDefaultCountryPhoneArea();
    const dialCode = this.props.mobileCountryCode;

    return {
      addressLine1: address.trim(),
      area: area.value,
      areaCode,
      areaId: area.key,
      cityId: city.key,
      city: city.value,
      country: country.value,
      countryId: country.key,
      default: isDefaultAddress,
      dialCode,
      firstname: firstName,
      lastname: lastName,
      phone: dialCode + areaCode.toString() + phoneNumber,
      zipcode: zipCode.toString(),
      addressId
    };
  };

  handleGAGuest = (address, guestCustomerId) => {
    const { settings, order } = this.props;

    AnalyticService.checkout.trackAddressGuest({
      countryName: get(settings, "countryCode", ""),
      firstName: get(address, "firstname", ""),
      lastName: get(address, "lastname", ""),
      emailConsent: get(order, "guestEmailConsent", false),
      email: get(order, "guestEmail", ""),
      customerId: guestCustomerId
    });
  };

  onDeliveryTypeSelect = option => {
    const {
      changeSelectedDelivery,
      updateAddress,
      checkoutReducer,
      changeCheckoutData,
      setStandardDeliveryAddress,
      order,
      onPaymentOptionChange
    } = this.props;

    const { standardDeliveryAddress } = checkoutReducer;

    changeSelectedDelivery(option.key);

    if (
      standardDeliveryAddress &&
      standardDeliveryAddress.addressLine1 &&
      option.key === "DELIVERY_ADDRESS"
    ) {
      updateAddress(standardDeliveryAddress);
    } else {
      setStandardDeliveryAddress(this.filteredAddressBook());
    }

    /*
      For store pickup delivery type COD payment method is disabled
    */
    if (
      option.key === "STORE_PICKUP" &&
      order.selectedPayment &&
      order.selectedPayment.includes("COD")
    ) {
      onPaymentOptionChange("");
      changeCheckoutData("selectedPaymentOption", null, false);
      changeCheckoutData("selectedPayment", [], false);
      changeCheckoutData("step3Completed", false, false);
    }

    changeCheckoutData("step2Completed", false);

    this.resetAddAddress();
    this.setState({
      invalidFirstName: false,
      invalidPhoneNumber: false,
      invalidInstorePhoneNumber: false,
      invalidInstoreFirstName: false
    });
  };

  onCloseAddressOTP = () => {
    this.setState({ showOTPModal: false });
  };

  onSubmitBtnClick = () => {
    const { isEditAddress } = this.state;
    const { editAddress, saveAddress } = checkoutTranslationEn;
    const ctaText = isEditAddress ? editAddress : saveAddress;
    GAService.common.trackCTAClick(ctaText, "button");
  };

  render() {
    const {
      language,
      open,
      order,
      handleContinueAsGuest,
      updateAddress,
      checkoutTranslation,
      alreadyLoggedIn,
      countryName,
      state,
      addressBook,
      mobileCountryCode,
      deliveryOptions,
      mapFilterData,
      deliveryAddressFromMap,
      addAddress,
      email,
      countryId,
      checkoutReducer,
      countrySHORT,
      mobileLocalNumberLength,
      countryData,
      commonSettings,
      mobileLocalCode,
      resetAPIError,
      apiErrorDetail,
      nextStepButtonDisabled,
      configSettings,
      resetMapDeliveryAddress
    } = this.props;
    const { address, continueAsGuest } = order;
    const {
      openAddressModal,
      openRespAddressModal,
      openAddressBook,
      openRespAddressBook,
      selectedMapArea,
      selectedMapLat,
      selectedMapLng,
      mapSearchText,
      isEditAddress,
      isEditGuestAddress,
      slicePhoneLength,
      inStoreShopName,
      selectedInstoreCity,
      selectedInStoreMapLat,
      selectedInStoreMapLng,
      selectedInstoreShop,
      cityList,
      selectedSelfPickupCity,
      showOTPModal,
      onSuccessOtp,
      showButtonLoader,
      storePickUpMapZoom,
      showNoCollectionPointsAlert,
      activeCollectionPoint,
      collectionPointsIsFetching
    } = this.state;

    let { mapDeliveryAddress, inStoreDeliveryAddress, inStoreList } =
      checkoutReducer;

    const { selectedCity, selectedArea } = this._getSelectedCityArea(
      address,
      state
    );
    const selectedStation =
      mapFilterData &&
      mapFilterData.station_list &&
      deliveryAddressFromMap.addressLine1 &&
      mapFilterData.station_list.filter(
        s => s.collectionPointId === deliveryAddressFromMap.collectionPointId
      );

    const { standardDeliveryAddress } = checkoutReducer;

    const storeList = get(checkoutReducer, "inStoreList", []);
    const inStoreCityList = getInstoreCityList(storeList, countryId);
    const inStoreShopNameWithCapacity = inStoreShopName.filter(
      inStoreShop => inStoreShop.capacity > 0
    );

    const areaList =
      mapFilterData &&
      mapFilterData.station_list &&
      mapFilterData.station_list.map((list, index) =>
        getCollectionPointsMarkup(list, index, checkoutTranslation)
      );
    const flagImg =
      COUNTRY_SELECTION_MAP[commonSettings && commonSettings.countryCode];
    const standardDeliveryAddressIsPresent = Boolean(
      standardDeliveryAddress && !!standardDeliveryAddress.addressLine1
    );
    const deliveryAddressFromMapIsPresent = Boolean(
      deliveryAddressFromMap && !!deliveryAddressFromMap.addressLine1
    );
    const inStoreDeliveryAddressFromMapIsPresent = Boolean(
      inStoreDeliveryAddress && !!inStoreDeliveryAddress.addressLine1
    );

    const inStoreShopNameGenerated =
      inStoreShopName &&
      inStoreShopName.map((instore, index) =>
        getModifiedInStoreShopName(instore, index, checkoutTranslation)
      );
    const countryShortCode = countrySHORT ? countrySHORT.toLowerCase() : "";

    const mapCenterLat =
      (selectedSelfPickupCity && selectedSelfPickupCity.center.lat) ||
      (cityList.length && cityList[0].center.lat);
    const mapCenterLng =
      (selectedSelfPickupCity && selectedSelfPickupCity.center.lng) ||
      (cityList.length && cityList[0].center.lng);

    const mapsCenterLocation =
      !isServer &&
      window.google &&
      window.google.maps &&
      new window.google.maps.LatLng(mapCenterLat, mapCenterLng);

    const nextStepButtonProps = {
      moveToNextStep: this.moveToNextStep,
      checkoutTranslation,
      disabled: nextStepButtonDisabled || showButtonLoader,
      showButtonLoader
    };

    return (
      <div className="checkout_steps deliveryDetails">
        <div className="steps_heading" onClick={this.toggleChange}>
          <h4 className={cn({ notOpened: !order.step2Completed })}>
            {order.step2Completed && <span className="green_round_check" />}
            <span className="serialNo">2.</span>{" "}
            {checkoutTranslation.deliveryDetails}
            {order.step2Completed && <span>{checkoutTranslation.change}</span>}
          </h4>
        </div>

        {open ? (
          <div className="steps_content delivery_details">
            <div className="inner_content">
              <p>{checkoutTranslation.reviewDeliveryMethods}</p>

              <div className="delivery_options_wrap">
                {map(deliveryOptions, option => (
                  <div
                    key={option.key}
                    className={cn(
                      { selected_option: order.deliveryType === option.key },
                      "option"
                    )}
                    onClick={() => this.onDeliveryTypeSelect(option)}
                  >
                    <input
                      type="radio"
                      className={cn(
                        { checked_radio: order.deliveryType === option.key },
                        "custom_radio"
                      )}
                      value={option.key}
                    />

                    <div className="delivery_option_value radio_option_value">
                      <HtmlParser html={option.value} />
                    </div>
                  </div>
                ))}
              </div>

              {order.deliveryType !== "DELIVERY_ADDRESS" && (
                <>
                  <div className="google_map_area">
                    {order.deliveryType === "SELF_PICKUP" && (
                      <div>
                        <div className="form_group">
                          <PlacesAutocomplete
                            value={mapSearchText}
                            onChange={this.handleMapChange}
                            onSelect={this.handleMapSelect}
                            searchOptions={{
                              location: mapsCenterLocation,
                              radius: 2000,
                              types: ["geocode", "establishment"],
                              componentRestrictions: {
                                country: countrySHORT.toLowerCase()
                              }
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading
                            }) => {
                              const inputProps = {
                                ...getInputProps({
                                  placeholder:
                                    checkoutTranslation.searchAreaPlaceholder,
                                  className:
                                    "form-control collection_points_input"
                                })
                              };

                              return (
                                <div>
                                  <div className="position-relative">
                                    <input {...inputProps} />
                                    {inputProps.value && (
                                      <button
                                        className="clear_point_button"
                                        onClick={() => {
                                          const points =
                                            configSettings.default?.mapPoints;
                                          const mapPoints =
                                            (!isEmpty(points) &&
                                              JSON.parse(points)) ||
                                            fallbackCoordinates;
                                          this.setState({
                                            storePickUpMapZoom: 10
                                          });

                                          this.setState({
                                            selectedMapArea: {
                                              ...selectedMapArea,
                                              station_name:
                                                checkoutTranslation.selectPickUpLocation
                                            },
                                            selectedMapLat: mapPoints.lat,
                                            selectedMapLng: mapPoints.lng,
                                            mapSearchText: ""
                                          });
                                          resetMapDeliveryAddress();
                                        }}
                                      >
                                        <img
                                          src={CrossIcon}
                                          alt="clear point"
                                        ></img>
                                      </button>
                                    )}
                                  </div>
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                            lineHeight: 2
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                            lineHeight: 2
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                        <CustomDropdown
                          name="Your Selected Area"
                          data={areaList}
                          disableOption
                          selectedItem={
                            (mapDeliveryAddress &&
                              mapDeliveryAddress.addressLine1) ||
                            (selectedMapArea && selectedMapArea.station_name) ||
                            (areaList && areaList.length && areaList[0])
                          }
                          defaultSelectValue={
                            (areaList &&
                              areaList.length &&
                              areaList[0].value) ||
                            checkoutTranslation.selectPickUpLocation
                          }
                          containerClass={cn(
                            "user_delivery_address withBorder",
                            { muted: collectionPointsIsFetching }
                          )}
                          handleChange={this.changeSelectedArea}
                          scrollbarMaxHeight={350}
                          scrollType={
                            DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL
                          }
                        />
                      </div>
                    )}
                    {order.deliveryType === "STORE_PICKUP" && (
                      <div>
                        <CustomDropdown
                          name={"Your Selected Area"}
                          data={inStoreCityList}
                          selectedItem={
                            (selectedInstoreCity && selectedInstoreCity.name) ||
                            inStoreCityList[0]
                          }
                          defaultSelectValue={inStoreCityList[0]}
                          containerClass="user_delivery_address withBorder"
                          handleChange={this.changeSelectedInstoreCity}
                          scrollbarMaxHeight={150}
                        />
                        <CustomDropdown
                          name="Your Selected Area"
                          disableOption
                          data={inStoreShopNameGenerated}
                          selectedItem={
                            (inStoreDeliveryAddress &&
                              inStoreDeliveryAddress.station_name) ||
                            (inStoreShopName &&
                              inStoreShopName.length &&
                              inStoreShopName[0].name)
                          }
                          defaultSelectValue={
                            (inStoreShopName &&
                              inStoreShopName.length &&
                              inStoreShopName[0].name) ||
                            checkoutTranslation.selectPickUpLocation
                          }
                          containerClass="user_delivery_address withBorder"
                          handleChange={this.changeSelectedInstoreCityShops}
                          scrollbarMaxHeight={350}
                          scrollType={
                            DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITH_INLINE_SCROLL
                          }
                        />
                      </div>
                    )}
                    <div className="map_container position-relative">
                      {order.deliveryType === "SELF_PICKUP" && (
                        <>
                          {collectionPointsIsFetching && (
                            <Loader
                              type={LOADER_TYPES.RING}
                              width={25}
                              height={25}
                              visible
                              style={loaderInlineStyles}
                            />
                          )}
                          {mapFilterData?.station_list?.length && (
                            <GoogleMap
                              stores={mapFilterData.station_list}
                              selectedStore={
                                (selectedStation &&
                                  selectedStation.length &&
                                  selectedStation[0]) ||
                                deliveryAddressFromMap ||
                                selectedMapArea ||
                                mapFilterData.station_list[0]
                              }
                              activeCollectionPoint={activeCollectionPoint}
                              setActiveCollectionPoint={point =>
                                this.setState({ activeCollectionPoint: point })
                              }
                              lat={selectedMapLat || 0}
                              lng={selectedMapLng || 0}
                              zoom={storePickUpMapZoom}
                              language={language}
                              saveMapAddress={this.saveMapAddress}
                              translation={checkoutTranslation}
                              onMapBoundsMove={this.onMapBoundsMove}
                            />
                          )}
                        </>
                      )}
                      {order.deliveryType === "STORE_PICKUP" && (
                        <GoogleMap
                          stores={
                            inStoreShopNameWithCapacity &&
                            inStoreShopNameWithCapacity
                          }
                          customIcon={true}
                          selectedStore={selectedInstoreShop}
                          lat={selectedInStoreMapLat || 25.321761}
                          lng={selectedInStoreMapLng || 55.3856038}
                          zoom={13}
                          language={language}
                          saveMapAddress={this.saveInStoreMapAddress}
                          translation={checkoutTranslation}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="delivery_address pickUp">
                      {deliveryAddressFromMapIsPresent &&
                        order.deliveryType === "SELF_PICKUP" && (
                          <div className="address_desc">
                            <address>
                              {deliveryAddressFromMap.addressLine1} <br />
                              {deliveryAddressFromMap.addressLine2} <br />
                              {checkoutTranslation.workingHours}:
                              {deliveryAddressFromMap.workingHours?.map(
                                (info, index) => (
                                  <Fragment key={index}>
                                    {info}
                                    <br />
                                  </Fragment>
                                )
                              )}
                              <br />
                            </address>
                          </div>
                        )}
                      {inStoreDeliveryAddressFromMapIsPresent &&
                        order.deliveryType === "STORE_PICKUP" && (
                          <div className="address_desc">
                            <address>
                              {inStoreDeliveryAddress.station_name} <br />
                              {inStoreDeliveryAddress.addressLine2} <br />
                              {checkoutTranslation.workingHours}:
                              {inStoreDeliveryAddress.extra_info}
                              <br />
                            </address>
                          </div>
                        )}
                    </div>
                    {deliveryAddressFromMapIsPresent &&
                      order.deliveryType === "SELF_PICKUP" && (
                        <div>
                          <PickUpUserForm
                            checkoutTranslation={checkoutTranslation}
                            translation={checkoutTranslation}
                            onSubmit={this.onSubmit}
                            countrySHORT={countrySHORT}
                            countryData={countryData}
                            mobileLocalNumberLength={mobileLocalNumberLength}
                            handleNameChange={this.handleNameChange}
                            handleCountryChange={this.handleCountryChange}
                            onSelectArea={this.onSelectArea}
                            defaultArea={this.getDefaultCountryPhoneArea()}
                            inStoreDeliveryAddress={mapDeliveryAddress}
                            mobileCountryCode={mobileCountryCode}
                            mobileLocalCode={mobileLocalCode}
                            checkoutReducer={checkoutReducer}
                            pickUpFormRef={this.pickUpFormRef}
                            resetAPIError={resetAPIError}
                            apiErrorDetail={apiErrorDetail}
                            formName="selfPickUp"
                          />
                        </div>
                      )}
                    {inStoreDeliveryAddressFromMapIsPresent &&
                      order.deliveryType === "STORE_PICKUP" && (
                        <div>
                          <PickUpUserForm
                            checkoutTranslation={checkoutTranslation}
                            translation={checkoutTranslation}
                            onSubmit={this.onSubmit}
                            countrySHORT={countrySHORT}
                            countryData={countryData}
                            mobileLocalNumberLength={mobileLocalNumberLength}
                            handleNameChange={this.handleNameChange}
                            handleCountryChange={this.handleCountryChange}
                            onSelectArea={this.onSelectArea}
                            defaultArea={this.getDefaultCountryPhoneArea()}
                            inStoreDeliveryAddress={inStoreDeliveryAddress}
                            mobileCountryCode={mobileCountryCode}
                            mobileLocalCode={mobileLocalCode}
                            checkoutReducer={checkoutReducer}
                            pickUpFormRef={this.pickUpFormRef}
                            resetAPIError={resetAPIError}
                            apiErrorDetail={apiErrorDetail}
                            formName="storePickUp"
                          />
                        </div>
                      )}
                  </div>
                </>
              )}
              {order.deliveryType !== 3 &&
                order.deliveryType === "DELIVERY_ADDRESS" && (
                  <Fragment>
                    <div className="delivery_address">
                      <h4 className="deliveryAddressHead">
                        {checkoutTranslation.deliveryAddress}
                      </h4>
                      {standardDeliveryAddress &&
                      standardDeliveryAddress.addressLine1 ? (
                        !openAddressBook ? (
                          <div className="address_desc">
                            {this.showAddress(continueAsGuest) && (
                              <ViewAddress
                                address={this.getStandardDeliveryAddress({
                                  ...standardDeliveryAddress
                                })}
                                slicePhoneLength={slicePhoneLength}
                                selectedArea={selectedArea}
                                selectedCity={selectedCity}
                                countryName={countryName}
                                showEditAddress={true}
                                checkoutTranslation={checkoutTranslation}
                                mobileCountryCode={mobileCountryCode}
                                continueAsGuest={order.continueAsGuest}
                                handleEditAddress={this.handleEditAddress}
                                flag={flagImg}
                                countryShortCode={countryShortCode}
                                openEditAddressModal={() =>
                                  this.toggleAddressModal(
                                    true,
                                    false,
                                    true,
                                    true
                                  )
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="list_of_addresses">
                              <span
                                className="cross-icon"
                                onClick={e => this.changeFromAddressBook(false)}
                              />
                              <h4>{checkoutTranslation.addressBook}</h4>
                              <div className="address_list_wrapper">
                                {(addressBook &&
                                  addressBook.length &&
                                  addressBook
                                    .filter(
                                      address => address.countryId === countryId
                                    )
                                    .map((addressBookAddress, index) => {
                                      const phoneDetails = {
                                        phone: addressBookAddress.phone,
                                        mobileCountryCode,
                                        mobileLocalCode,
                                        mobileLocalNumberLength
                                      };
                                      const {
                                        areaCode,
                                        phoneWithoutCountryCode
                                      } = getDividedNumber(phoneDetails);

                                      const updatedAddressBook = {
                                        ...addressBookAddress,
                                        phone: phoneWithoutCountryCode
                                      };

                                      const slicedPhoneNumberLength =
                                        ((areaCode && areaCode.length) || 0) +
                                        mobileLocalNumberLength;

                                      return (
                                        <div
                                          key={addressBookAddress.addressId}
                                          className="address_desc address_book_list"
                                          onClick={() => {
                                            updateAddress(
                                              addressBookAddress,
                                              null,
                                              null,
                                              true
                                            );
                                            this.changeFromAddressBook(false);
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            className={cn("custom_radio", {
                                              checked_radio:
                                                address.addressId ===
                                                addressBookAddress.addressId
                                            })}
                                            value={addressBookAddress.addressId}
                                            onClick={() => {
                                              updateAddress(addressBookAddress);
                                              this.changeFromAddressBook(false);
                                            }}
                                          />
                                          <div className="radio_option_value">
                                            <ViewAddress
                                              slicePhoneLength={
                                                slicedPhoneNumberLength
                                              }
                                              address={updatedAddressBook}
                                              checkoutTranslation={
                                                checkoutTranslation
                                              }
                                              mobileCountryCode={
                                                mobileCountryCode
                                              }
                                              continueAsGuest={
                                                order.continueAsGuest
                                              }
                                              countryShortCode={
                                                countryShortCode
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    })) ||
                                  "No Records !!"}
                              </div>
                            </div>
                          </Fragment>
                        )
                      ) : null}
                      {Boolean(address && openRespAddressBook) && (
                        <MobileAddressBook
                          openRespAddressBook={openRespAddressBook}
                          checkoutTranslation={checkoutTranslation}
                          addressBook={addressBook}
                          updateAddress={updateAddress}
                          mobileCountryCode={mobileCountryCode}
                          address={address}
                          addressID={address.addressId}
                          changeFromRespAddressBook={
                            this.changeFromRespAddressBook
                          }
                          language={language}
                          deleteAddress={this.handleDeleteAddress}
                          handleEditAddress={this.handleEditAddress}
                          handleDefaultAddress={this.handleDefaultAddress}
                          toggleRespAddressModal={this.toggleRespAddressModal}
                          countryId={countryId}
                          slicePhoneLength={slicePhoneLength}
                          countryShortCode={countryShortCode}
                          mobileLocalNumberLength={mobileLocalNumberLength}
                          mobileLocalCode={mobileLocalCode}
                        />
                      )}
                      <ModalBFL
                        showModal={openAddressModal}
                        modalClass={cn(
                          "login_modal",
                          "delivery_detail_address",
                          "change_address_modal",
                          {
                            arabic: language.includes("ar")
                          }
                        )}
                      >
                        <button
                          className="cross_btn"
                          onClick={() => this.toggleAddressModal(false)}
                        >
                          &times;
                        </button>
                        {!isEditGuestAddress ? (
                          <h2>
                            {address && address.addressId
                              ? checkoutTranslation.changeAddress
                              : checkoutTranslation.AddNewAddress}
                          </h2>
                        ) : (
                          <h2>{checkoutTranslation.editAddress}</h2>
                        )}
                        <Scrollbars
                          autoHeight
                          autoHeightMin={200}
                          autoHeightMax={600}
                        >
                          <AddressForm
                            closeModal={() => this.toggleAddressModal(false)}
                            checkoutTranslation={checkoutTranslation}
                            onSubmitBtnClick={this.onSubmitBtnClick}
                            language={language}
                            alreadyLoggedIn
                            submitButtonText={checkoutTranslation.saveAddress}
                            onSubmit={this.onSubmit}
                          />
                        </Scrollbars>
                      </ModalBFL>
                      <MobileDeliveryAddressModal
                        openRespAddressModal={openRespAddressModal}
                        toggleRespAddressModal={this.toggleRespAddressModal}
                        language={language}
                        address={address}
                        checkoutTranslation={checkoutTranslation}
                        handleContinueAsGuest={handleContinueAsGuest}
                        guestEmail={order.guestEmail}
                        alreadyLoggedIn={alreadyLoggedIn}
                        updateAddress={this.onSubmit}
                        isEditAddress={isEditAddress}
                        onSubmitBtnClick={this.onSubmitBtnClick}
                      />
                      <AddAddressMobileNumber
                        showModal={showOTPModal}
                        onSuccessOtp={onSuccessOtp}
                        closeModal={this.onCloseAddressOTP}
                        verifyOnly
                      />
                      <div
                        className={cn("change_address_options", {
                          new_add: !standardDeliveryAddressIsPresent
                        })}
                      >
                        {!openAddressBook &&
                        standardDeliveryAddressIsPresent &&
                        !continueAsGuest &&
                        this.showAddress(continueAsGuest) ? (
                          <Fragment>
                            <button
                              className="round_btn form_white_btn mCheckout"
                              onClick={e => this.changeFromAddressBook(true)}
                            >
                              {checkoutTranslation.changeAddress}
                            </button>
                            <ChangeAddressButtons
                              checkoutTranslation={checkoutTranslation}
                              toggleRespAddressModal={
                                this.toggleRespAddressModal
                              }
                              changeFromRespAddressBook={
                                this.changeFromRespAddressBook
                              }
                            />
                          </Fragment>
                        ) : null}
                        {!openAddressBook &&
                        (!standardDeliveryAddressIsPresent ||
                          !continueAsGuest) ? (
                          <Fragment>
                            <button
                              className="round_btn form_white_btn mCheckout"
                              onClick={() => {
                                GAService.common.trackAddNewAddressCTA();
                                this.toggleAddressModal(true, true);
                              }}
                            >
                              {checkoutTranslation.addNewAddress}
                            </button>
                            {!standardDeliveryAddressIsPresent && (
                              <button
                                className="mCheckoutSign round_btn form_white_btn web"
                                onClick={e => {
                                  GAService.common.trackAddNewAddressCTA();
                                  this.toggleRespAddressModal(true);
                                }}
                              >
                                {checkoutTranslation.addNewAddress}
                              </button>
                            )}
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                    {standardDeliveryAddressIsPresent && (
                      <MoveToNextStep
                        {...nextStepButtonProps}
                        className="mProceedToPayment"
                      />
                    )}
                  </Fragment>
                )}
            </div>
            {((order.deliveryType === DELIVERY_TYPES.SELF_PICKUP &&
              deliveryAddressFromMapIsPresent) ||
              (order.deliveryType === DELIVERY_TYPES.STORE_PICKUP &&
                inStoreDeliveryAddressFromMapIsPresent)) && (
              <div className="continue_btn_container pickUp">
                <MoveToNextStep
                  {...nextStepButtonProps}
                  {...(isTouchScreen() && { className: "mProceedToPayment" })}
                />
              </div>
            )}

            {order.deliveryType === "DELIVERY_ADDRESS" && (
              <div className="continue_btn_container">
                <MoveToNextStep {...nextStepButtonProps} />
              </div>
            )}
            {addAddress && order.deliveryType !== "SELF_PICKUP" ? (
              <div className="addAddressError error">
                {checkoutTranslation.addAddress}
              </div>
            ) : null}
          </div>
        ) : (
          <Fragment>
            {order.deliveryType !== 3 &&
            order.deliveryType === "DELIVERY_ADDRESS" ? (
              <div className="delivery_address summaryAddress">
                {Boolean(email) && (
                  <h4 className="deliveryAddressHead">
                    {checkoutTranslation.standardDelivery}{" "}
                  </h4>
                )}
                {address ? (
                  !openAddressBook ? (
                    <div className="address_desc">
                      <ViewAddress
                        address={address}
                        slicePhoneLength={slicePhoneLength}
                        selectedArea={selectedArea}
                        selectedCity={selectedCity}
                        countryName={countryName}
                        showEditAddress={true}
                        checkoutTranslation={checkoutTranslation}
                        mobileCountryCode={mobileCountryCode}
                        handleEditAddress={this.handleEditAddress}
                        continueAsGuest={order.continueAsGuest}
                        flag={flagImg}
                        countryShortCode={countryShortCode}
                        openEditAddressModal={() =>
                          this.toggleAddressModal(true, false, true, true)
                        }
                      />
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : (
              deliveryAddressFromMapIsPresent &&
              order.deliveryType === "SELF_PICKUP" && (
                <div className="delivery_address summaryAddress">
                  <h4 className="deliveryAddressHead">
                    {checkoutTranslation.selfPickup}
                  </h4>
                  <div className="address_desc">
                    <address>
                      {deliveryAddressFromMap.addressLine1} <br />
                      {deliveryAddressFromMap.addressLine2} <br />
                      {checkoutTranslation.workingHours}:
                      {deliveryAddressFromMap.workingHours?.map(
                        (info, index) => (
                          <Fragment key={index}>
                            {info}
                            <br />
                          </Fragment>
                        )
                      )}
                      <br />
                      <img
                        alt="country"
                        src={
                          COUNTRY_SELECTION_MAP[
                            commonSettings && commonSettings.countryCode
                          ]
                        }
                      />{" "}
                      {"+"}
                      {mobileCountryCode}{" "}
                      {mobileLocalNumberLength ===
                        deliveryAddressFromMap.phone.length &&
                        deliveryAddressFromMap.areaCode}
                      {this.getPhoneNumberForStorePickUp(
                        deliveryAddressFromMap
                      )}
                    </address>
                  </div>
                </div>
              )
            )}
            {order.deliveryType === "STORE_PICKUP" &&
              inStoreDeliveryAddressFromMapIsPresent && (
                <div className="delivery_address summaryAddress">
                  <h4 className="deliveryAddressHead">
                    {checkoutTranslation.inStorePickup}
                  </h4>
                  <div className="address_desc">
                    <address>
                      {inStoreDeliveryAddress.station_name} <br />
                      {inStoreDeliveryAddress.addressLine2} <br />
                      {checkoutTranslation.workingHours}:
                      {inStoreDeliveryAddress.extra_info}
                      <br />
                      <img
                        alt="country"
                        src={
                          COUNTRY_SELECTION_MAP[
                            commonSettings && commonSettings.countryCode
                          ]
                        }
                      />{" "}
                      {"+"}
                      {mobileCountryCode}{" "}
                      {mobileLocalNumberLength ===
                        inStoreDeliveryAddress.phone.length &&
                        inStoreDeliveryAddress.areaCode}
                      {this.getPhoneNumberForStorePickUp(
                        inStoreDeliveryAddress
                      )}
                    </address>
                  </div>
                </div>
              )}

            {order.continueAsGuest && (
              <MobileDeliveryAddressModal
                openRespAddressModal={openRespAddressModal}
                toggleRespAddressModal={this.toggleRespAddressModal}
                language={language}
                address={address}
                checkoutTranslation={checkoutTranslation}
                handleContinueAsGuest={handleContinueAsGuest}
                guestEmail={order.guestEmail}
                alreadyLoggedIn={alreadyLoggedIn}
                updateAddress={this.onSubmit}
                onSubmitBtnClick={this.onSubmitBtnClick}
              />
            )}
          </Fragment>
        )}
        {order.deliveryType === DELIVERY_TYPES.SELF_PICKUP &&
          showNoCollectionPointsAlert && (
            <DeleteModal
              titleTxt={checkoutTranslation.alert}
              bodyTxt={checkoutTranslation.noCollectionPointsForArea}
              cancelBtnTxt={checkoutTranslation.Ok}
              handleCloseModal={() =>
                this.setState({
                  showNoCollectionPointsAlert: false
                })
              }
              isDeleteModalActive={true}
              language={language}
            />
          )}
      </div>
    );
  }
}

const loaderInlineStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  backgroundColor: "rgba(255, 255, 255, 0.8)"
};

const mapStateToProps = state => ({
  address: state.addressReducer,
  checkoutReducer: state.checkoutReducer,
  countryData: state.common.countryData,
  countrySHORT: state.common.settings && state.common.settings.countrySHORT,
  addressBook: state.authReducer.addressBook,
  countryId: state.common.settings.countryId,
  mobileCountryCode: get(state, "common.settings.mobileCountryCode"),
  mobileLocalCode: get(state, "common.settings.mobileLocalCode"),
  _address: state.addressReducer,
  mobileLocalNumberLength: get(
    state,
    "common.settings.mobileLocalNumberLength"
  ),
  commonSettings: state.common.settings,
  prevPath: state.authReducer.prevPath,
  apiErrorDetail: state.homepage.APIErrorResponse,
  configSettings: state.common.configSettings,
  isConfigSettingsLoaded: selectIsConfigSettingsLoaded(state)
});

const mapDispatchToProps = {
  setAddress,
  setDefaultAddress,
  saveAddress,
  resetAddress,
  deleteAddress,
  setStandardDeliveryAddress,
  handleTextChange,
  inStoreDeliveryAddressCheckout,
  handleIntlInputTextChange,
  resetInStoreDeliveryAddress,
  guestUserCheckout,
  resetAPIError,
  getCountrySettings,
  getConfigurationSettings,
  resetMapDeliveryAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);
