import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import get from "lodash/get";

import { LANGUAGE_ROUTE_KEY } from "../../constants";
import { ENV_CONFIG } from "../../config/env";

import { getBaseUrl, isServer } from "../../util";
import {
  setPrevModeStorage,
  removePrevModeStorage
} from "../../util/browserStorage";

import AnalyticService from "../../services/analytic-service";

import {
  hideModal,
  setOTPLoginFlowBFL
} from "../../redux/actions/homepage.action";
import {
  fetchUserAndLoadData,
  showPrevMode
} from "../../redux/actions/authConfig.action";
import {
  setCommonData,
  getStatesData,
  loadIpcountry,
  changeLanguage
} from "../../redux/actions/common.action";
import {
  fetchWishListData,
  getWishListBaseData
} from "../../redux/actions/wishList.action";
import { getCartAndWishlistGeneralData } from "../../redux/actions/cart.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { handleSearchText } from "../../redux/actions/search.action";

import Header from "../../components/header";
import ModalBfl from "../../components/modal";
import Footer from "../../components/footer";
import ScrollToTop from "../../components/scroll-to-top";
import PreviewMode from "../../components/preview-mode";

import AppContainer from "./appContainer";

import ErrorPage from "../error-page";
import ProductDetail from "../product-detail";
import HomePage from "../homepage";
import Redirection from "./Redirection";
import SignUp from "../signup";
import LogIn from "../login";
import ThankYou from "../thankyou";
import ResetPassword from "../reset-password";
import ForgotPassword from "../forgot-password";
import CatalogPage from "../catalog";
import Wishlist from "../wishlist";
import ProductListing from "../product-listing";
import Cart from "../cart";
import ContactUs from "../contact-us";
import Checkout from "../checkout";
import ShopByBrands from "../shop-by-brands";
import OrderSuccess from "../order-success";
import OrderSuccessV2 from "../order-success-v2";
import HelpDetails from "../help/helpDetails";
import Help from "../help";
import MyAccount from "../my-account";
import PaymentError from "../payment-error";
import SendUsEmail from "../send-us-email";
import SizeChart from "../size-chart";
import StoreLocator from "../store-locator";
import AppDownload from "../app-download";
import TrackOrder from "../track-order";
import BrandListing from "../brand-listing";
import RepopulateCart from "../re-populate-cart";
import PaymentProcessing from "../payment-processing";
import { selectOTPLoginFlowBFL, selectRouteSlug } from "../../util/selectors";
import { GAService } from "../../services/GA-service";
import paymentErrorV2 from "../payment-error-v2";

export const routes = [
  {
    path: "/:language/error-page",
    component: ErrorPage
  },
  {
    exact: true,
    path: "/:language/app-download",
    component: AppDownload
  },
  {
    exact: true,
    path: "/:language/sign-up",
    component: SignUp
  },
  {
    exact: true,
    path: "/:language/login",
    component: LogIn
  },
  {
    exact: true,
    path: "/:language/registration/thank-you",
    component: ThankYou
  },
  {
    exact: true,
    path: "/:language/reset-password",
    component: ResetPassword
  },
  {
    exact: true,
    path: "/:language/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/:language/:productName/:id/p",
    component: ProductDetail
  },
  {
    path: "/:language/category/:categoryName",
    component: CatalogPage
  },
  {
    exact: true,
    path: "/:language/wishlist",
    component: Wishlist
  },
  {
    exact: true,
    path: "/:language/my-cart",
    component: Cart
  },
  {
    path: "/:language/checkout/:id",
    component: Checkout
  },
  {
    path: "/:language/order-success/:orderId",
    component: OrderSuccess
  },
  {
    path: "/:language/order/success/",
    component: OrderSuccessV2
  },
  {
    path: "/:language/order/failed/",
    component: paymentErrorV2
  },
  {
    exact: false,
    path: "/:language/brands/:brandName",
    component: BrandListing
  },
  {
    exact: true,
    path: "/:language/:categoryName/brands/:brandName",
    component: BrandListing
  },
  {
    exact: true,
    path: "/:language/search",
    component: ProductListing
  },
  {
    exact: true,
    path: "/:language/list",
    component: ProductListing
  },
  {
    exact: true,
    path: "/:language/contact-us",
    component: ContactUs
  },
  {
    exact: true,
    path: "/:language/need-help/tracking-shipping-and-delivery",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/loyalty-program",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/product-and-stock",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/payment-and-vouchers",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/order-issues",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/general",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help",
    component: Help
  },
  {
    exact: true,
    path: "/:language/shop-by-brands",
    component: ShopByBrands
  },
  {
    exact: true,
    path: "/:language/brands",
    component: ShopByBrands
  },
  {
    exact: true,
    path: "/:language/size-chart",
    component: SizeChart
  },
  {
    exact: true,
    path: "/:language/store-locator",
    component: StoreLocator
  },
  {
    exact: true,
    path: "/:language/my-account/account-overview",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-details",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-orders",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-address-book",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-wallet",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/loyalty-program",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/saved-cards",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/redeem-gift-card",
    component: MyAccount
  },
  // task BFL-6503 Need to disable “Social Account” section from the my account page, until the exact functionality is confirmed.
  // {
  //   exact: true,
  //   path: "/:language/my-account/social-accounts",
  //   component: MyAccount
  // },
  {
    exact: true,
    path: "/:language/send-us-email",
    component: SendUsEmail
  },
  {
    path: "/:language/payment-error",
    component: PaymentError
  },
  {
    path: "/:language/payment-processing/",
    component: PaymentProcessing
  },
  {
    exact: true,
    path: "/:language/track-order",
    component: TrackOrder
  },
  {
    exact: false,
    path: "/:language/re-populate-cart",
    component: RepopulateCart
  },
  {
    path: "/:language/:redirected",
    component: Redirection
  },
  {
    exact: true,
    path: "/:language",
    component: HomePage
  },
  {
    exact: true,
    path: "/",
    component: HomePage
  }
];

const pagesWOHeader = ["checkout", "payment-error", "payment-processing"];

const getHideHeaderValue = () => {
  const pathName = isServer ? "" : get(window, "location.pathname", "");
  return (
    Boolean(pathName) && pagesWOHeader.some(page => pathName.includes(page))
  );
};

class AuthContent extends React.Component {
  state = {
    hideHeader: getHideHeaderValue(),
    setFooterSpace: false,
    hideInMobile: false
  };

  componentDidMount() {
    const {
      fetchUserAndLoadData,
      getCartAndWishlistGeneralData,
      userLoggedIn,
      loadIpcountry,
      countryId,
      settings
    } = this.props;

    loadIpcountry();

    let url = window.location;
    url = url.toString();
    if (url.includes("?prevMode=")) {
      let _urlArray = url.split("?prevMode=");
      setPrevModeStorage(_urlArray[1], document.domain);
      window.location = _urlArray[0];
    } else if (url.includes("&prevMode=")) {
      let _urlArray = url.split("&prevMode=");
      let _prevModeIndex = url.indexOf("&prevMode=");
      let newUrl = url.replace(url.substr(_prevModeIndex), "");
      setPrevModeStorage(_urlArray[1], document.domain);
      window.location = newUrl;
    }

    const { handleLanguageChange } = this.props;
    let pathname = window.location.pathname,
      _language =
        pathname.includes(`/${LANGUAGE_ROUTE_KEY.english}-`) ||
        pathname.includes(`/${LANGUAGE_ROUTE_KEY.english}/`)
          ? LANGUAGE_ROUTE_KEY.english
          : pathname.includes(`/${LANGUAGE_ROUTE_KEY.arabic}-`) ||
            pathname.includes(`/${LANGUAGE_ROUTE_KEY.arabic}/`)
          ? LANGUAGE_ROUTE_KEY.arabic
          : LANGUAGE_ROUTE_KEY.english;

    handleLanguageChange(_language);

    const needFetchUserData = (countryId || settings.countryId) && userLoggedIn;

    if (needFetchUserData) {
      fetchUserAndLoadData({ keepDeliverySettings: true }).then(response => {
        const customerId = get(response, "data.userProfile.customerId", "");
        AnalyticService.common.trackSessionStart(customerId);
      });
    }

    if (settings.countryId) {
      getCartAndWishlistGeneralData();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      userLoggedIn,
      fetchUserAndLoadData,
      settings,
      setOTPLoginFlowBFL,
      isProductListingPage
    } = this.props;

    let pathName = window.location.pathname.split("/");
    let pathIndex = pathName[pathName.length - 2];
    let pathIndex2 = pathName[pathName.length - 1];
    this.setState({
      hideHeader: pagesWOHeader.some(page =>
        window.location.pathname.includes(page)
      ),
      setFooterSpace:
        window.location.pathname.indexOf("product-detail") !== -1
          ? true
          : false,
      hideInMobile:
        pathIndex === "p" ||
        pathIndex === "my-cart" ||
        pathIndex === "wishlist" ||
        pathIndex2 === "p" ||
        pathIndex2 === "my-cart" ||
        pathIndex2 === "wishlist"
    });
    if (nextProps.userLoggedIn !== userLoggedIn) {
      if (nextProps.userLoggedIn) {
        fetchUserAndLoadData({ keepDeliverySettings: true }).then(response => {
          const isOTPLoginFlowBFL =
            nextProps.otpLoginFlowBFL &&
            response.status === 200 &&
            response.data.success;

          if (isOTPLoginFlowBFL) {
            setOTPLoginFlowBFL(false);
            const userProfile = get(response, "data.userProfile", {});
            const countryName = get(settings, "countryCode", "");
            // Email Login
            GAService.auth.trackLoginSuccess({
              mode: "email",
              user_id: userProfile?.customerId,
              isLoggedIn: response.data?.success
            });
            AnalyticService.auth.trackSignIn({ userProfile, countryName });
          }
          const { userLoggedInEmail } = this.props;
          if (
            ENV_CONFIG.listUserEmail.includes(userLoggedInEmail) &&
            (window.location.href.toLowerCase().includes("/sign-up") ||
              window.location.href.toLowerCase().includes("/login"))
          ) {
            let currentDomain = document.domain,
              _url = window.location;
            _url = _url.toString();
            _url = _url.replace(`/sign-up/`, `/`);
            _url = _url.replace(`/sign-up`, `/`);
            _url = _url.replace(`/login/`, `/`);
            _url = _url.replace(`/login`, `/`);
            currentDomain && (window.location = _url);
          } else if (
            !ENV_CONFIG.listUserEmail.includes(userLoggedInEmail) &&
            (window.location.href.toLowerCase().includes("/list") ||
              window.location.href.toLowerCase().includes("/sign-up") ||
              window.location.href.toLowerCase().includes("/login"))
          ) {
            let currentDomain = document.domain,
              _url = window.location;
            _url = _url.toString();
            _url = _url.replace(`/sign-up/`, `/`);
            _url = _url.replace(`/sign-up`, `/`);
            _url = _url.replace(`/login/`, `/`);
            _url = _url.replace(`/login`, `/`);
            _url = _url.replace(`/list/`, `/`);
            _url = _url.replace(`/list`, `/`);
            currentDomain && (window.location = _url);
          }
        });
      }

      if (
        !nextProps.userLoggedIn &&
        window.location.href.toLowerCase().includes("/list")
      ) {
        let currentDomain = document.domain,
          _url = window.location;
        _url = _url.toString();
        _url = _url.replace(`/list/`, `/`);
        _url = _url.replace(`/list`, `/`);
        currentDomain && (window.location = _url);
      }
    }

    if (
      !nextProps.userLoggedIn &&
      window.location.href.toLowerCase().includes("/list")
    ) {
      let currentDomain = document.domain,
        _url = window.location;
      _url = _url.toString();
      _url = _url.replace(`/list/`, `/`);
      _url = _url.replace(`/list`, `/`);
      currentDomain && (window.location = _url);
    }

    if (
      !nextProps.userLoggedIn &&
      window.location.href.toLowerCase().includes("/search") &&
      !window.location.search
    ) {
      let currentDomain = document.domain,
        _url = window.location;
      _url = _url.toString();
      _url = _url.replace(`/search/`, `/`);
      _url = _url.replace(`/search`, `/`);
      currentDomain && (window.location = _url);
    }

    if (
      isProductListingPage !== nextProps.isProductListingPage &&
      !nextProps.isProductListingPage
    ) {
      this.props.handleSearchText("");
    }
  }

  componentDidUpdate(prevProps) {
    const { countryId, userLoggedIn, fetchUserAndLoadData, settings } =
      this.props;
    const prevCountryId = prevProps.countryId || prevProps.settings.countryId;
    const currentCountryId = countryId || settings.countryId;
    const needFetchUserData =
      currentCountryId && !prevCountryId && userLoggedIn;
    if (needFetchUserData) {
      fetchUserAndLoadData({ keepDeliverySettings: true }).then(response => {
        const customerId = get(response, "data.userProfile.customerId", "");
        AnalyticService.common.trackSessionStart(customerId);
      });
    }
  }

  hideHeader = state => {
    this.setState({ hideHeader: state });
  };
  setFooterSpace = state => {
    this.setState({ setFooterSpace: state });
  };
  hideInMobile = state => {
    this.setState({ hideInMobile: state });
  };
  closeModal = () => this.props.hideModal();

  exitPrevMode = () => {
    const { history, language, commonSettings } = this.props;

    this.props.showPrevMode(false);
    removePrevModeStorage(document.domain);
    history.push(getBaseUrl({ language, commonSettings }) + "/");
  };

  render() {
    const { hideHeader, setFooterSpace, hideInMobile } = this.state;
    const {
      showModal,
      language,
      showPreview,
      userLoggedIn,
      userLoggedInEmail,
      routeSlug
    } = this.props;
    /*
     * routesWithoutLogin
     * Exclude the Login or signUp object from the routes array
     */
    const routesWithoutLogin = routes.filter(
      route => !route.path.includes("/list")
    );
    const routesWithLoginListUserEmail = routes.filter(
      route =>
        userLoggedIn &&
        ENV_CONFIG.listUserEmail.includes(userLoggedInEmail) &&
        !(route.path.includes("/login") || route.path.includes("/sign-up"))
    );
    const routesWithLoginNotListUserEmail = routes.filter(
      route =>
        userLoggedIn &&
        !ENV_CONFIG.listUserEmail.includes(userLoggedInEmail) &&
        !(
          route.path.includes("/list") ||
          route.path.includes("/login") ||
          route.path.includes("/sign-up")
        )
    );

    let redirectPaths = ["/list"];
    if (userLoggedIn) {
      redirectPaths = ENV_CONFIG.listUserEmail.includes(userLoggedInEmail)
        ? ["/login", "/sign-up"]
        : ["/list", "/login", "/sign-up"];
    }
    const switchRoutes = routes.map(route => {
      return (
        <Route
          exact={!!route.exact}
          path={route.path}
          component={route.component}
          key={route.path}
        >
          {redirectPaths.some(path => route.path.includes(path)) && (
            <Redirect to={`/${routeSlug}/`} />
          )}
        </Route>
      );
    });

    return (
      <ScrollToTop>
        <Fragment>
          {showPreview && <PreviewMode exitPrevMode={this.exitPrevMode} />}
          {!hideHeader ? (
            <Header
              handleLanguageChange={this.props.handleLanguageChange}
              hideInMobile={hideInMobile}
            />
          ) : null}
          <ModalBfl showModal={showModal} closeModal={this.closeModal} />
          <AppContainer
            hideHeader={this.hideHeader}
            setFooterSpace={this.setFooterSpace}
            hideInMobile={this.hideInMobile}
          >
            <Switch>{switchRoutes}</Switch>
          </AppContainer>
          {!hideHeader && (
            <Footer
              language={language}
              setFooterSpace={setFooterSpace}
              hideInMobile={hideInMobile}
            />
          )}
        </Fragment>
      </ScrollToTop>
    );
  }
}

const mapStateToProps = state => ({
  showModal: state.homepage.showModal,
  language: state.common.language,
  ipCountryData: state.common.ipCountryData,
  userLoggedIn: state.authReducer.userLoggedIn,
  accessGuestToken: state.authReducer.accessGuestToken,
  userLoggedInEmail: state.authReducer.email,
  settings: state.common.settings,
  countryId: state.page.homepageState.countryId,
  storeId: state.page.homepageState.storeId,
  customerId: state.authReducer.customerId,
  showPreview: state.authReducer.showPreview,
  isProductListingPage: state.productListingReducer.isProductListingPage,
  payPalToken: state.common.payPalToken,
  routeSlug: selectRouteSlug(state),
  otpLoginFlowBFL: selectOTPLoginFlowBFL(state)
});

const mapDispatchToProps = {
  hideModal,
  handleLanguageChange: changeLanguage,
  fetchUserAndLoadData,
  fetchWishListData,
  getWishListBaseData,
  setCommonData,
  getStatesData,
  getMegaMenuData,
  showPrevMode,
  handleSearchText,
  loadIpcountry,
  getCartAndWishlistGeneralData,
  setOTPLoginFlowBFL
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthContent);
