import React from "react";
import cn from "classnames";
import { LOADER_TYPES, commonLoaderStyle } from "../../constants";
import Loader from "../loader";

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "inherit"
};

const MoveToNextStep = ({
  moveToNextStep,
  checkoutTranslation,
  className = "round_btn form_black_btn continue_btn",
  showButtonLoader,
  ...rest
}) => (
  <button
    className={cn("position-relative", className)}
    onClick={moveToNextStep}
    {...rest}
  >
    {showButtonLoader && (
      <Loader type={LOADER_TYPES.DOTS} visible style={loaderStyle} size={10} />
    )}
    {checkoutTranslation.proceedToPayment}
  </button>
);

export default MoveToNextStep;
