import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";

import { selectLanguageSlug } from "../../../util/selectors";
import { withClientOnlyRender } from "../../../hocs";
import { LANGUAGE_SHORT } from "../../../constants";

import InfoIconGrey from "../../../images/info-icon-grey.svg";
import { PostPayService } from "../../../services/PostPayService";
import { TabbyInfoModal } from "../../tabby/tabby-info-modal";

import "./style.scss";
import { GAService } from "../../../services/GA-service";

const PriceSnippet = ({
  translation,
  paymentService,
  languageSlug,
  currencyCode,
  paymentSystemIcon,
  minAmountForPayment,
  price,
  normalPrice,
  paymentMode,
  payIconClassName,
  ModalComponent = TabbyInfoModal
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (paymentService) {
      paymentService.loadSdk();
    }
  }, []);

  useEffect(() => {
    if (paymentService && paymentService.isSdkLoaded) {
      paymentService.init({});
    }
  }, [languageSlug]);

  const handleInfoIconClick = () => {
    setModalOpen(true);
    GAService.product.trackPaymentMethodInfo(paymentMode.toLowerCase());
  };

  return (
    <div
      className={cn("price-snippet", {
        arabic: languageSlug === LANGUAGE_SHORT.ar
      })}
    >
      <div className="description">
        <p> {translation.description}</p>
        <p>
          {translation.description2}{" "}
          <span className="price">
            {currencyCode} {minAmountForPayment}
          </span>
          <span className="learn_more" onClick={handleInfoIconClick}>
            {translation.learnMore}
          </span>
        </p>
      </div>

      <div
        className={cn("pay-icon", paymentMode.toLowerCase(), payIconClassName)}
      >
        <img
          src={paymentSystemIcon}
          alt="Payment System Icon"
          className="svg-icon"
        />

        <div
          /*
           * A blank element needed to detect a click event to open Tabby Promo modal.
           * It is a quick fix, because of adding data-tabby-info attribute to HTML
           * elements that have child elements has no effect
           */
          className="touch-detector"
          data-tabby-info="installments"
          data-tabby-language={languageSlug}
        />
      </div>
      {paymentService instanceof PostPayService ? (
        <div className="info-icon">
          <div
            className="postpay-widget"
            data-type="product"
            data-amount={price}
            data-currency={currencyCode}
            data-locale={languageSlug}
          />
        </div>
      ) : (
        <div className="info-icon" onClick={handleInfoIconClick}>
          <img src={InfoIconGrey} alt="info" />
        </div>
      )}
      {modalOpen && (
        <ModalComponent
          modalOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          language={languageSlug}
          amount={normalPrice}
          currency={currencyCode}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currencyCode: get(state, "common.settings.currencyCode"),
  languageSlug: selectLanguageSlug(state)
});

export default compose(
  withClientOnlyRender,
  connect(mapStateToProps)
)(PriceSnippet);
