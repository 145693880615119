import React, { Component } from "react";

import { getDividedNumber } from "../../util/phoneNumber";

class ShippingAddress extends Component {
  state = {
    phone: null,
    areaCode: ""
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { mobileCountryCode, mobileLocalNumberLength, mobileLocalCode } =
      this.props;

    if (nextProps.order.shippingMob) {
      const phoneDetails = {
        phone: nextProps.order.shippingMob,
        mobileCountryCode,
        mobileLocalCode,
        mobileLocalNumberLength
      };
      const { areaCode: phoneAreaCode } = getDividedNumber(phoneDetails);
      const areaCode = phoneAreaCode || this.state.areaCode;
      this.setState({ areaCode });
    }
  }

  render() {
    const {
      orderSuccessTranslation,
      order,
      countryName,
      mobileCountryCode,
      mobileLocalNumberLength,
      mobileLocalCode
    } = this.props;
    const { phone, areaCode } = this.state;

    let modifiedPhone = phone === null ? order.shippingMob : phone;
    const phoneDetails = {
      phone: modifiedPhone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { areaCode: phoneAreaCode = areaCode, phoneWithoutCountryCode } =
      getDividedNumber(phoneDetails);

    if (modifiedPhone.length > mobileLocalNumberLength) {
      modifiedPhone = phoneWithoutCountryCode.slice(
        phoneAreaCode && phoneAreaCode.length
      );
    }

    return (
      <div className="ship_to_address">
        <address>
          {order?.shippingName && `${order.shippingName}`}
          {order?.shippingAddress && `, ${order.shippingAddress}`}
          {order?.shippingState && `, ${order.shippingState}`}
          {countryName && `, ${countryName}`}
          {order?.shippingZip && `, ${order.shippingZip}`}

          <a
            className="mCheckoutSign"
            href={`tel:+${mobileCountryCode} ${phoneAreaCode}${modifiedPhone}`}
          >
            ,&nbsp;
            {orderSuccessTranslation.telephone}:
            {`+${mobileCountryCode} ${phoneAreaCode}${modifiedPhone}`}
          </a>
        </address>

        <a
          className="telePhone"
          href={`tel:+${mobileCountryCode} ${phoneAreaCode}${modifiedPhone}`}
        >
          {orderSuccessTranslation.telephone}:
          {`+${mobileCountryCode} ${phoneAreaCode}${modifiedPhone}`}
        </a>
      </div>
    );
  }
}

export default ShippingAddress;
