import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import {
  resetPasswordAPI,
  updateUserPassword
} from "../../redux/actions/homepage.action";
import { updateAuthData } from "../../redux/actions/authConfig.action";
import FormActivityHandler from "../formActionsHandler";

class ResetPassword extends React.Component {
  formikFormRef = React.createRef();

  state = {
    apiErrorMessage: "",
    lastFocusedField: ""
  };

  static initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  static schema = Yup.object().shape({
    oldPassword: Yup.string().trim().required(),
    newPassword: Yup.string().min(8).max(32).trim().required(),
    confirmPassword: Yup.string().min(8).max(32).trim().required()
  });

  handleSubmit = ({ oldPassword, newPassword }) => {
    const {
      resetPasswordAPI,
      currentPassword,
      updateUserPassword,
      token,
      email
    } = this.props;

    if (
      this.isNewPasswordsIsSame() &&
      !this.isOldAndNewPasswordIsSame(oldPassword, newPassword)
    ) {
      if (currentPassword) {
        this.resetPassword(
          {
            token,
            email,
            oldPassword,
            newPassword
          },
          updateUserPassword
        );
      } else {
        this.resetPassword(
          { token, email, password: newPassword },
          resetPasswordAPI
        );
      }
    }
  };

  resetPassword = async (apiData, ApiType) => {
    const {
      toggleResetPasswordModal,
      history,
      language,
      resetPasswordConfirm,
      settings,
      updateAuthData
    } = this.props;
    const response = await ApiType(
      apiData,
      history,
      language,
      (settings &&
        settings.countrySHORT &&
        settings.countrySHORT.toLowerCase()) ||
        "ae"
    );
    if (resetPasswordConfirm) {
      resetPasswordConfirm(response);
    }
    if (response && response.data.success) {
      toggleResetPasswordModal && toggleResetPasswordModal();
      const { email, newPassword: password } = apiData;
      updateAuthData(email, password);
    }
  };

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });

  isNewPasswordsIsSame = () => {
    const { current } = this.formikFormRef;
    return (
      current && current.values.newPassword === current.values.confirmPassword
    );
  };

  isOldAndNewPasswordIsSame = (oldPassword, newPassword) =>
    oldPassword === newPassword;

  render() {
    const { apiErrorDetail, resetPasswordTranslation } = this.props;

    return (
      <div className="register_user_page">
        <div className="signup_layout">
          <h4>{resetPasswordTranslation.resetPassword}</h4>
          <Formik
            innerRef={this.formikFormRef}
            initialValues={ResetPassword.initialValues}
            onSubmit={this.handleSubmit}
            validationSchema={ResetPassword.schema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              isValid
            }) => {
              const isInputInvalid = valueKey =>
                Boolean(touched[valueKey] && errors && errors[valueKey]);

              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="password"
                      name="oldPassword"
                      value={values.password}
                      className={cn("form-control", {
                        borderRed: isInputInvalid("oldPassword")
                      })}
                      placeholder={resetPasswordTranslation.currentPassword}
                      onChange={handleChange}
                      aria-label="Old password"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("oldPassword") && (
                      <span className="error">
                        {resetPasswordTranslation.required}
                      </span>
                    )}
                    {!isInputInvalid("oldPassword") &&
                      !apiErrorDetail.success && (
                        <span className="error">
                          {resetPasswordTranslation.incorrectCredentialsError}
                        </span>
                      )}
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      name="newPassword"
                      value={values.newPassword}
                      className={cn("form-control", {
                        borderRed: isInputInvalid("newPassword")
                      })}
                      placeholder={resetPasswordTranslation.placeholder_1}
                      onChange={handleChange}
                      aria-label="New password"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("newPassword") && (
                      <span className="error">
                        {resetPasswordTranslation.passwordRequired}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      className={cn("form-control", {
                        borderRed:
                          isInputInvalid("confirmPassword") ||
                          (submitCount && !this.isNewPasswordsIsSame())
                      })}
                      placeholder={resetPasswordTranslation.placeholder_2}
                      onChange={handleChange}
                      aria-label="Confirm new password"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("confirmPassword") && (
                      <span className="error">
                        {resetPasswordTranslation.passwordRequired}
                      </span>
                    )}
                    {Boolean(
                      !this.isNewPasswordsIsSame() &&
                        submitCount &&
                        !isInputInvalid("confirmPassword")
                    ) && (
                      <span className="error">
                        {resetPasswordTranslation.matchErrorText}
                      </span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-default round_btn form_black_btn"
                  >
                    {resetPasswordTranslation.submit}
                  </button>

                  {Boolean(
                    submitCount &&
                      isValid &&
                      this.isOldAndNewPasswordIsSame(
                        values.oldPassword,
                        values.newPassword
                      )
                  ) && (
                    <div className="form-error">
                      <span className="error">
                        {resetPasswordTranslation.notDifferentPasswords}
                      </span>
                    </div>
                  )}
                  <FormActivityHandler
                    formName={"reset password"}
                    lastFocusedFormField={this.state.lastFocusedField}
                  />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  apiErrorDetail: state.homepage.APIErrorResponse,
  language: state.common.language,
  settings: state.common.settings
});

const mapDispatchToProps = {
  updateUserPassword,
  resetPasswordAPI,
  updateAuthData
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
