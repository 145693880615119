import React, { useState } from "react";
import get from "lodash/get";

import CustomDropdown from "../genericDropDown";
import BounceLoader from "../bounce-loader";

const CancelOrderAlert = ({
  myAccountTranslation,
  cancelReason,
  handleSelectChange,
  handleTextArea,
  handleCancelOrder,
  OrderDetail,
  toggleCancelOrderModal,
  orderItem,
  quantitySelected,
  handleQuantityChange
}) => {
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const quantityOptions = [];
  const itemQuantity = get(orderItem, "quantity");

  itemQuantity &&
    [...new Array(itemQuantity).keys()].map(x => quantityOptions.push(x + 1));

  const isItemQuantityMultiple = itemQuantity && itemQuantity > 1;

  const saveCancelReason = () => {
    if (isItemQuantityMultiple && !quantitySelected) {
      return handleCancelOrder(orderItem);
    }
    if (cancelReason) {
      setIsDisabledButton(true);
    }
    handleCancelOrder(orderItem);
  };

  const {
    orderCancellation,
    cancellationReason1,
    cancellationReason2,
    cancellationReason3,
    cancellationReason4,
    cancellationReason5,
    cancellationReason,
    reasonRequired,
    selectQuantity,
    quantityRequired,
    additionalComment,
    typeComment,
    save,
    cancel
  } = myAccountTranslation;

  const { reason, quantity } = OrderDetail.errors;

  return (
    <div className="cancel_order_wrapper generic_modal_content">
      <h2>{orderCancellation}</h2>
      <div
        className={`cancel_reason ${
          isItemQuantityMultiple ? "with-quantity" : "without-quantity"
        }`}
      >
        <CustomDropdown
          name="cancel-reason"
          data={[
            cancellationReason1,
            cancellationReason2,
            cancellationReason3,
            cancellationReason4,
            cancellationReason5
          ]}
          selectedItem={cancelReason || cancellationReason}
          containerClass="cancel_order_dropdown"
          scrollbarMaxHeight={150}
          handleChange={selected => handleSelectChange(selected)}
          valid={reason.valid}
        />

        {!reason.valid &&
          reason.touched && <span className="error">{reasonRequired}</span>}
      </div>
      <div className="qty_cancel">
        {isItemQuantityMultiple && (
          <CustomDropdown
            name="cancel-quantity"
            data={quantityOptions}
            selectedItem={quantitySelected || selectQuantity}
            containerClass="cancel_qty"
            scrollbarMaxHeight={150}
            handleChange={selected => handleQuantityChange(selected)}
            valid={reason.valid}
          />
        )}
        {isItemQuantityMultiple &&
          !quantity.valid &&
          quantity.touched && <span className="error">{quantityRequired}</span>}
      </div>
      <div className="form-group">
        <label>{additionalComment}</label>
        <textarea
          rows="4"
          placeholder={typeComment}
          onChange={e => handleTextArea(e)}
        />
      </div>
      <div className="action_btn">
        <button
          className="round_btn form_black_btn"
          onClick={saveCancelReason}
          disabled={isDisabledButton}
        >
          {isDisabledButton ? (
            <BounceLoader color="#f8d000" size={10} qty={3} />
          ) : (
            save
          )}
        </button>
        <button
          className="round_btn form_white_btn"
          onClick={toggleCancelOrderModal}
        >
          {cancel}
        </button>
      </div>
    </div>
  );
};

export default CancelOrderAlert;
