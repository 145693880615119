import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import emptyWishList from "../../../images/wishlistEmpty.svg";
import { Image } from "../../image";

const EmptyWishlist = ({ productTranslation, language, country }) => (
  <Fragment>
    <div
      className={`wishlist ${
        language.includes("ar-") || language.includes("ar") ? "arabic" : ""
      }`}
    >
      <Image src={emptyWishList} alt="empty-wishlist" />
      <h2>{productTranslation && productTranslation.wishlistEmpty}</h2>
      <p>{productTranslation && productTranslation.wishListEmptyMessage}</p>
      <Link to={`/${language}-${country || "ae"}/`}>
        <button className="round_btn btn">
          {productTranslation && productTranslation.continueShopping}
        </button>
      </Link>
    </div>
  </Fragment>
);

export default EmptyWishlist;
