import React from "react";

const PromotionalMessage = ({ translation }) => (
  <div className="promotional_message">
    <p>
      <span>{translation && translation.voucherCode}</span>{" "}
      {translation && translation.codeApply}.
    </p>
  </div>
);

export default PromotionalMessage;
