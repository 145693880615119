import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { selectLanguageSlug, selectRouteSlug } from "../../../util/selectors";
import { selectOrder } from "./selectors";
import CheckoutSignInUser from "./checkout-sign-in-user";
import CheckoutSignInGuest from "./checkout-sign-in-guest";
import { loginTranslation } from "../../../language/login/en/login";
import { checkoutTranslation } from "../../../language/checkoutFlow/en/checkoutFlow";

const CheckoutFirstStep = ({ isUserLoggedIn, params, submitGuestEmail }) => {
  const [loginPageTranslation, setLoginPageTranslation] =
    useState(loginTranslation);
  const [checkoutPageTranslation, setCheckoutPageTranslation] =
    useState(checkoutTranslation);
  const routeSlug = useSelector(selectRouteSlug);
  const { orderItems } = useSelector(selectOrder);
  const language = useSelector(selectLanguageSlug);

  useEffect(() => {
    const loadLanguage = async () => {
      if (language) {
        const loginResponse = await import(
          `../../../language/login/${language}/login`
        );
        const checkoutResponse = await import(
          `../../../language/checkoutFlow/${language}/checkoutFlow`
        );

        setLoginPageTranslation(loginResponse.loginTranslation);
        setCheckoutPageTranslation(checkoutResponse.checkoutTranslation);
      }
    };

    loadLanguage();
  }, [language]);

  return (
    <div className={classnames({ user_loggedIn: isUserLoggedIn })}>
      {!isUserLoggedIn && (
        <p className="loyalty_points">
          <span>{checkoutPageTranslation.loyaltyPoints}</span>
        </p>
      )}
      <div className="sign_in_container">
        <div className="user_sign">
          <CheckoutSignInUser
            translation={checkoutPageTranslation}
            orderItems={orderItems}
            routeSlug={routeSlug}
            params={params}
          />
        </div>
        <p className="mCheckoutSign checkoutSeparator">
          <span>{loginPageTranslation.OR}</span>
        </p>
        <div className="guest_sign">
          <p>{checkoutPageTranslation.guestEmailSignText}</p>

          <CheckoutSignInGuest
            checkoutTranslation={checkoutPageTranslation}
            translation={loginPageTranslation}
            onSubmit={submitGuestEmail}
            params={params}
          />

          <p className="no_loyalty_text">
            {checkoutPageTranslation.noLoyaltyPointsForGuest}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFirstStep;
