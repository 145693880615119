import React from "react";
import RangeSelector from "../../range-drager-card/range-selector";
import * as priceUtil from "../../../util/priceUtil";
import { LANGUAGE_SHORT } from "../../../constants";

const CartToastMessage = ({
  translation,
  freeShippingUpto,
  total,
  currencyCode,
  language
}) => (
  <div className="toast_wrapper">
    {freeShippingUpto - total > 0 && (
      <div className="cart_price_range bfl-range-slider">
        <RangeSelector
          minValue={0}
          maxValue={freeShippingUpto || 0}
          value={total || 0}
          changeRange={() => {}}
          changeRangeComplete={() => {}}
        />
      </div>
    )}
    {freeShippingUpto - total > 0 && (
      <p className="black_text">
        {language === LANGUAGE_SHORT["en"] ? (
          <>
            {translation.only}{" "}
            <span>
              {currencyCode || "AED"}{" "}
              <span className="green_color_text">
                {priceUtil.shapePriceWithComma(freeShippingUpto - total, 2)}
              </span>
            </span>{" "}
            {translation.awayFromFreeShipping}
          </>
        ) : (
          <>
            {translation.awayFromFreeShipping + " "}
            <span>
              {currencyCode || "AED"}{" "}
              <span className="green_color_text">
                {priceUtil.shapePriceWithComma(freeShippingUpto - total, 2)}
              </span>
            </span>{" "}
            {translation.only}
          </>
        )}
      </p>
    )}
  </div>
);

export default CartToastMessage;
