import React, { Component } from "react";
import cn from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { emailValidationRegExp } from "../../constants";
import FormActivityHandler from "../formActionsHandler";

class NewsLetterForm extends Component {
  state = { lastFocusedField: "" };
  static initialValues = {
    email: ""
  };

  static schema = Yup.object().shape({
    email: Yup.string().trim().matches(emailValidationRegExp).required()
  });

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });

  render() {
    const { onSubmit, translation, formikFormRef, isBanner } = this.props;

    return (
      <Formik
        innerRef={formikFormRef}
        initialValues={NewsLetterForm.initialValues}
        onSubmit={onSubmit}
        validationSchema={NewsLetterForm.schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          submitCount
        }) => {
          const isInputInvalid = valueKey =>
            Boolean(touched[valueKey] && errors && errors[valueKey]);

          return (
            <>
              <form
                onSubmit={handleSubmit}
                className={cn("news-letter-form", {
                  "news-letter-banner": isBanner
                })}
                id="mc-embedded-subscribe-form"
              >
                <input
                  type="text"
                  name="email"
                  placeholder={translation.enterYourEmail}
                  value={values.email}
                  onChange={handleChange}
                  aria-label="Email"
                  onFocus={this.setLastFocusedField}
                />

                <button
                  className="news-letter-btn"
                  value="Subscribe"
                  type="submit"
                >
                  <span className="arrow_right" />
                </button>
                <FormActivityHandler
                  formName={"newsletter form"}
                  lastFocusedFormField="email"
                />
              </form>

              {isInputInvalid("email") && !isEmpty(values.email) && (
                <span className="error">{translation.enterValidEmail}</span>
              )}

              {Boolean(submitCount && isEmpty(values.email)) && (
                <span className="error">{translation.enterEmail}</span>
              )}
            </>
          );
        }}
      </Formik>
    );
  }
}

export default NewsLetterForm;
