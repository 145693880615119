import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import { setIsPickUpFormValid } from "../../../redux/actions/checkout.action";
import { selectIsPickUpFormValid } from "../../../util/selectors";
import { getDividedNumber } from "../../../util/phoneNumber";
import IntlInput from "../../intl-tel-input/intl-tel-input";
import FormActivityHandler from "../../formActionsHandler";

class PickUpUserForm extends Component {
  static initialValues = {
    firstname: "",
    lastname: "",
    areaCode: "",
    phone: ""
  };

  state = {
    initialDataFromProps: { ...PickUpUserForm.initialValues },
    lastFocusedField: ""
  };

  static schema = mobileLength =>
    Yup.object().shape({
      firstname: Yup.string().min(1).trim().required(),
      lastname: Yup.string(),
      phone: Yup.string().min(mobileLength).max(mobileLength).required(),
      areaCode: Yup.string()
    });

  componentDidMount() {
    const initialDataFromProps = this.getFormattedUserData();
    this.setState({ initialDataFromProps });
  }

  componentWillUnmount() {
    this.props.resetAPIError();
  }

  getFormattedUserData() {
    const {
      inStoreDeliveryAddress: { phone, firstname, lastname, areaCode },
      mobileCountryCode,
      mobileLocalNumberLength,
      mobileLocalCode,
      defaultArea
    } = this.props;

    const phoneDetails = {
      phone: areaCode + phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };

    const { phoneWithoutCountryCode, areaCode: phoneAreaCode } =
      getDividedNumber(phoneDetails);

    const mobileAreaCode = phoneAreaCode || areaCode || defaultArea;

    const userData = {
      firstname,
      lastname,
      areaCode: mobileAreaCode,
      phone
    };

    if (
      phoneWithoutCountryCode &&
      phoneWithoutCountryCode.length !== mobileLocalNumberLength
    ) {
      userData.phone = phoneWithoutCountryCode.slice(mobileAreaCode.length);
    }

    const isValid = PickUpUserForm.schema(mobileLocalNumberLength).isValidSync(
      userData
    );

    return isValid ? userData : PickUpUserForm.initialValues;
  }

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });

  render() {
    const {
      checkoutTranslation,
      translation,
      handleNameChange,
      countryData,
      countrySHORT,
      mobileLocalNumberLength,
      handleCountryChange,
      onSelectArea,
      defaultArea,
      pickUpFormRef,
      apiErrorDetail,
      resetAPIError,
      isPickUpFormValid,
      setIsPickUpFormValid,
      formName
    } = this.props;

    return (
      <Formik
        initialValues={{
          ...PickUpUserForm.initialValues,
          ...this.state.initialDataFromProps
        }}
        validationSchema={() => PickUpUserForm.schema(mobileLocalNumberLength)}
        validateOnBlur
        enableReinitialize={true}
        innerRef={pickUpFormRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur
        }) => {
          const isInputInvalid = valueKey =>
            Boolean(touched[valueKey] && errors && errors[valueKey]);

          const requiredFields = {
            phone: values.phone,
            firstname: values.firstname
          };

          const isValid = PickUpUserForm.schema(
            mobileLocalNumberLength
          ).isValidSync(requiredFields);

          if (isValid !== isPickUpFormValid[formName]) {
            setIsPickUpFormValid(formName, isValid);
          }

          const onNameChange = e => {
            handleNameChange(e);
            handleChange(e);
          };

          const onAreaChange = area => {
            onSelectArea(area);
            setFieldValue("areaCode", area);
            setFieldValue("phone", "");
          };

          const onPhoneChange = (e, phone) => {
            const areaCode = values.areaCode ? values.areaCode : defaultArea;
            onSelectArea(areaCode);
            handleCountryChange(e, phone);
            setFieldValue("phone", phone);
            if (!apiErrorDetail.success) resetAPIError();
          };

          const isPhoneError =
            !apiErrorDetail.success && !isInputInvalid("phone");

          return (
            <form>
              <div className="form-group">
                <div className="delivery_address_phone">
                  <div className="form-group">
                    <input
                      type="text"
                      className={cn("form-control", {
                        borderRed: isInputInvalid("firstname")
                      })}
                      name="firstname"
                      placeholder={translation.firstName}
                      value={values.firstname}
                      onChange={onNameChange}
                      onBlur={handleBlur}
                      aria-label="First name"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("firstname") && (
                      <span className="error">
                        {translation.enterFirstName}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      name="lastname"
                      type="text"
                      placeholder={checkoutTranslation.lastName}
                      className="form-control"
                      value={values.lastname}
                      onChange={onNameChange}
                      aria-label="Last name"
                      onFocus={this.setLastFocusedField}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="add-phone-number">
                    <h4 className="add-contact">
                      {checkoutTranslation.enterValidPhoneNumber}
                    </h4>

                    <IntlInput
                      valid={!isInputInvalid("phone")}
                      phone={values.phone}
                      handleCountryChange={(e, phone) =>
                        onPhoneChange(e, phone)
                      }
                      name="phone"
                      areaCode={values.areaCode}
                      countryData={countryData}
                      defaultCountry={countrySHORT}
                      mobileLocalNumberLength={mobileLocalNumberLength}
                      onSelectArea={onAreaChange}
                      onBlur={handleBlur}
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("phone") && (
                      <span className="error">
                        {checkoutTranslation.enterValidPhoneNumber}
                      </span>
                    )}
                    {isPhoneError && (
                      <span className="error apiError">
                        {apiErrorDetail.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <FormActivityHandler
                formName={"delivery pickup form"}
                lastFocusedFormField={this.state.lastFocusedField}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  isPickUpFormValid: selectIsPickUpFormValid(state)
});

const mapDispatchToProps = {
  setIsPickUpFormValid
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PickUpUserForm);
