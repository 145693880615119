import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import SignUp from "./signup";
import Login from "./login";
import SocialLoginModal from "./social-login";
import ModalBFL from "./modal";
import ForgotPassword from "./forgot-password";
import {
  signInModal,
  showForgotPasswordModal,
  hideForgotPasswordModal,
  hideModal,
  clearModal,
  resetAPIError,
  resetnotificationMsg
} from "../../redux/actions/homepage.action";
import { changeLanguage } from "../../redux/actions/common.action";
import { Scrollbars } from "react-custom-scrollbars";
import { loginTranslation } from "../../language/login/en/login";
import { signUpTranslation } from "../../language/signUp/en/signUp";
import { forgotPasswordTranslation } from "../../language/forgotPassword/en/forgotPassword";
import { compose } from "redux";
import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";

class SignInBFL extends Component {
  state = {
    loginTranslation: loginTranslation,
    signUpTranslation: signUpTranslation,
    forgotPasswordTranslation: forgotPasswordTranslation,
    modalIsOpen: false,
    forgotPasswordModal: false
  };

  componentDidMount() {
    const { currentLanguage, handleLanguageChange } = this.props;
    handleLanguageChange(currentLanguage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLanguage } = nextProps;
    if (currentLanguage !== this.props.currentLanguage) {
      const languageResponse = import(
        `../../language/login/${currentLanguage}/login.js`
      );
      const signUpResponse = import(
        `../../language/signUp/${currentLanguage}/signUp.js`
      );
      const forgotpasswordResponse = import(
        `../../language/forgotPassword/${currentLanguage}/forgotPassword.js`
      );
      languageResponse.then(response => {
        this.setState({ loginTranslation: response.loginTranslation });
      });
      signUpResponse.then(response => {
        this.setState({ signUpTranslation: response.signUpTranslation });
      });
      forgotpasswordResponse.then(response => {
        this.setState({
          forgotPasswordTranslation: response.forgotPasswordTranslation
        });
      });
    }
  }

  closeModal = () => {
    const { closeModal, clearModal, ChangeSignInModal } = this.props;
    ChangeSignInModal(true);
    closeModal();
    clearModal();
  };

  toggleSignInTab = () => {
    const { ChangeSignInModal, hideForgotPasswordModal, resetAPIError } =
      this.props;
    ChangeSignInModal(true);
    hideForgotPasswordModal();
    resetAPIError();
  };
  toggleSignUpTab = () => {
    const { ChangeSignInModal, resetAPIError } = this.props;
    ChangeSignInModal(false);
    resetAPIError();
  };
  closeForgotPasswordModal = () => {
    this.setState({ forgotPasswordModal: false });
  };
  showForgotPasswordModal = () => {
    const { clearModal, showForgotPasswordModal, resetAPIError } = this.props;
    showForgotPasswordModal();
    clearModal();
    resetAPIError();
  };
  closeMessageModal = () => {
    const { history, language, settings, resetnotificationMsg } = this.props;
    resetnotificationMsg();
    history.push(
      `/${language}-${
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
        "ae"
      }/login/`
    );
  };

  render() {
    const {
      showModal,
      language,
      history,
      responseMessage,
      signInModalShow,
      forgotPasswordModal,
      hideForgotPasswordModal
    } = this.props;
    const { loginTranslation, signUpTranslation, forgotPasswordTranslation } =
      this.state;
    return (
      <Fragment>
        <ModalBFL
          showModal={showModal && responseMessage === ""}
          modalClass="login_modal"
          language={language}
        >
          {forgotPasswordModal ? (
            <ForgotPassword
              translation={forgotPasswordTranslation}
              closeForgotPasswordModal={hideForgotPasswordModal}
              toggleSignInTab={this.toggleSignInTab}
            />
          ) : (
            <div
              className={classNames("modal_spacing", {
                arabic: language.includes("ar")
              })}
            >
              <div className="modal_spacing_wrap">
                <span
                  onClick={this.toggleSignInTab}
                  className={classNames({ under_line: signInModalShow })}
                >
                  <h2>{loginTranslation.signIn}</h2>
                </span>
                <span
                  onClick={this.toggleSignUpTab}
                  className={classNames({
                    under_line: Boolean(signInModalShow) === false
                  })}
                >
                  <h2>{loginTranslation.join}</h2>
                </span>
                <button className="cross_btn" onClick={this.closeModal}>
                  &times;
                </button>
                <Scrollbars autoHeight autoHeightMin={200} autoHeightMax={410}>
                  {signInModalShow ? (
                    <div className="login_modal_content">
                      <Login
                        translation={loginTranslation}
                        showForgotModal={this.showForgotPasswordModal}
                        hideForgotModal={this.closeForgotPasswordModal}
                        stopRedirection={true}
                      />
                      <SocialLoginModal
                        translation={loginTranslation}
                        language={language}
                        history={history}
                        textFor="login"
                      />
                    </div>
                  ) : (
                    <div className="login_modal_content">
                      <SignUp
                        translation={signUpTranslation}
                        closeModal={this.closeModal}
                      >
                        {" "}
                      </SignUp>
                      <SocialLoginModal
                        translation={loginTranslation}
                        language={language}
                        history={history}
                        textFor="join"
                      />
                    </div>
                  )}
                </Scrollbars>
              </div>
              {signInModalShow ? (
                <div className="login_footer">
                  <p>
                    {loginTranslation.dontHaveAccount}{" "}
                    <span onClick={this.toggleSignUpTab}>
                      {loginTranslation.join}
                    </span>
                  </p>
                </div>
              ) : (
                <div className="login_footer">
                  <p>
                    {" "}
                    {loginTranslation.alreadyHaveAccount}{" "}
                    <span onClick={this.toggleSignInTab}>
                      {loginTranslation.signIn}
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
        </ModalBFL>
        {responseMessage !== "" && (
          <ModalBFL showModal modalClass="message_modal" language={language}>
            <div className="prompt_box">
              <p>
                {language.includes("ar")
                  ? "تم إرسال رابط لإعادة تعيين كلمة المرور إلى عنوان بريدك الإلكتروني"
                  : "A link to reset password has been sent to your email address"}
              </p>
              <button
                type="button"
                className="btn btn-default round_btn form_black_btn"
                onClick={this.closeMessageModal}
              >
                {language.includes("ar") ? "قريب" : "Close"}
              </button>
            </div>
          </ModalBFL>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  login: state.homepage.login,
  signUp: state.homepage.signUp,
  signInModalShow: state.homepage.signInModal,
  forgotPasswordModal: state.homepage.forgotPasswordModal,
  language: state.common.language,
  settings: state.common.settings,
  responseMessage: state.homepage.forgotPassword.APINotificationMsg
});

const mapDispatchToProps = dispatch => ({
  showForgotPasswordModal() {
    dispatch(showForgotPasswordModal());
  },
  hideForgotPasswordModal() {
    dispatch(hideForgotPasswordModal());
  },
  ChangeSignInModal(data) {
    dispatch(signInModal(data));
  },
  closeModal() {
    dispatch(hideModal());
  },
  clearModal() {
    dispatch(clearModal());
  },
  handleLanguageChange(name) {
    dispatch(changeLanguage(name));
  },
  resetAPIError() {
    dispatch(resetAPIError());
  },
  resetnotificationMsg() {
    dispatch(resetnotificationMsg());
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withLanguageFromUrl
)(SignInBFL);
