import React from "react";

const ProductCard = ({
  handlePopup,
  bgImage,
  marginClass,
  title,
  handleSizeChart,
  brandId,
  guideType,
  brandName,
  handleImgBlockSubCatName,
  subHeading,
  popupHeading
}) => {
  const style = {
    backgroundImage: `url(${bgImage})`
  };
  return (
    <div
      className={`product_card ${!!marginClass ? marginClass : ""}`}
      onClick={() => {
        handlePopup(true);
        handleSizeChart(brandId, brandName, guideType, popupHeading);
        handleImgBlockSubCatName(subHeading);
      }}
      style={style}
    >
      <p className="title">{title}</p>
    </div>
  );
};

export default ProductCard;
