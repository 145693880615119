import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import get from "lodash/get";
import cn from "classnames";

import {
  updateDbCheckoutData,
  setSelectedCardCvv,
  clearSelectedCardCvv,
  clearBinCode,
  setBinCode
} from "../../../../../redux/actions/checkout.action";

import visaIcon from "../../../../../images/payment/cards/Visa.svg";
import masterCardIcon from "../../../../../images/payment/cards/MasterCard.svg";

import "./style.scss";

class SavedCardList extends Component {
  componentWillUnmount() {
    const { setSelectedCardCvv } = this.props;

    setSelectedCardCvv("");
  }
  getCardIcon(type) {
    const map = {
      visa: visaIcon,
      mastercard: masterCardIcon
    };

    return map[type.toLowerCase()];
  }

  onCvvChange = event => {
    const {
      setSelectedCardCvv,
      compareBinCodes,
      creditCardList,
      creditCardOptions,
      setBinCode
    } = this.props;
    const { value } = event.target;
    const cvvRegExp = /(^[0-9]{1,4}$|^$)/;

    if (cvvRegExp.test(value)) {
      setSelectedCardCvv(value);
    }
    const selectedCardId = get(creditCardOptions, "selectedCard");

    const selectedCardProp = creditCardList.find(
      card => card.id === selectedCardId
    );

    if (value.length >= 3) {
      compareBinCodes(selectedCardProp.bin);
      setBinCode(selectedCardProp.bin);
    }
  };

  onCardSelect(cardId) {
    const {
      updateDbCheckoutData,
      creditCardOptions = {},
      clearSelectedCardCvv,
      removeAppliedVoucher,
      clearBinCode,
      changeVoucherStatus,
      binNo,
      handleVoucherAppliedMessage
    } = this.props;

    updateDbCheckoutData("creditCardOptions", {
      ...creditCardOptions,
      savingCardEnabled: false,
      selectedCard: cardId
    });
    if (binNo) {
      changeVoucherStatus(null);
      clearBinCode();
      removeAppliedVoucher();
      handleVoucherAppliedMessage();
    }

    clearSelectedCardCvv();
  }

  onCvvInputBlur = e => {
    const {
      removeAppliedVoucher,
      clearBinCode,
      handleVoucherAppliedMessage,
      order,
      voucherCodes
    } = this.props;
    if (e.target.value.length < 3 && order.voucherCode) {
      const bankSpecificCodes = voucherCodes.filter(
        voucher => voucher.isBankSpecific
      );
      const isBankSpecificCodeApplied = bankSpecificCodes.find(
        code => code.voucherCode === order.voucherCode
      );
      if (isBankSpecificCodeApplied) {
        removeAppliedVoucher();
        clearBinCode();
        handleVoucherAppliedMessage();
      }
    }
  };

  render() {
    const { creditCardOptions, creditCardList, cvv, translation, className } =
      this.props;

    const selectedCard = get(creditCardOptions, "selectedCard");

    const isCvvValidated = cvv.length >= 3;

    return (
      <div className={cn("saved-card-list", className)}>
        <div className="title">{translation.savedCards}</div>

        <div className="card-list">
          {map(creditCardList, cardData => {
            const cardIcon = this.getCardIcon(cardData.cardName);

            const isSelected = cardData.id === selectedCard;

            return (
              <div
                className={cn("card-item", { selected: isSelected })}
                key={cardData.id}
              >
                <div
                  className="info-block"
                  onClick={() => this.onCardSelect(cardData.id)}
                >
                  <div
                    className={cn("radio-button", { checked: isSelected })}
                  />

                  <div className="icon-wrapper">
                    <img src={cardIcon} alt="card-icon" />
                  </div>

                  <div className="text">
                    <span className="card-type">{cardData.cardName}</span>{" "}
                    {translation.endingWith} {cardData.cardNumber}
                  </div>
                </div>

                {isSelected && (
                  <div
                    className={cn("cvv-form", { "not-valid": !isCvvValidated })}
                  >
                    <span className="cvv-form-title d-none-tablet">
                      {translation.enterCVV}
                    </span>
                    <input
                      value={cvv}
                      className="input"
                      type="password"
                      placeholder="CVV"
                      onChange={this.onCvvChange}
                      onBlur={this.onCvvInputBlur}
                      aria-label="Card cvv"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  creditCardList: state.checkoutReducer.creditCardList,
  creditCardOptions: state.checkoutReducer.checkoutData.creditCardOptions,
  cvv: state.checkoutReducer.selectedCardCvv,
  voucherCodes: state.checkoutReducer.VoucherCodes,
  order: state.checkoutReducer.checkoutData
});

const mapDispatchToProps = {
  updateDbCheckoutData,
  setSelectedCardCvv,
  clearSelectedCardCvv,
  clearBinCode,
  setBinCode
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedCardList);
