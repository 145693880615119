import React from "react";
import * as priceUtil from "../../util/priceUtil";

const OldPriceSection = ({ item, countrySettings }) => {
  return (
    Boolean(item.price?.old) && (
      <span className="old_price">
        {countrySettings.currencyCode}{" "}
        {priceUtil.convertWithComma(item.price.old, countrySettings)}
      </span>
    )
  );
};

export default OldPriceSection;
