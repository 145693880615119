import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import {
  addAnimate,
  isMobile,
  isTabletView,
  getWishListGeneralDataStore,
  isPresent
} from "../../../util";
import {
  selectDeleteWishListItemLoaded,
  selectWishListAndCartGeneralLoaded,
  selectWishListGeneralProducts
} from "../../../util/selectors";
import {
  saveToWishList,
  deleteWishListData
} from "../../../redux/actions/wishList.action";
import { getCartAndWishlistGeneralData } from "../../../redux/actions/cart.action";

export const SaleWishList = ({ product, iconRef }) => {
  const item = { ...product, productId: product.id };
  const [wishlistAnimationClass, setWishlistAnimationClass] = useState("");
  const prevPropsRef = useRef(null);
  const isMobileView = isMobile.any();
  const wishListBaseData = useSelector(selectWishListGeneralProducts);

  const wishListAndCartBaseDataLoaded = useSelector(
    selectWishListAndCartGeneralLoaded
  );
  const deleteWishListItemLoaded = useSelector(selectDeleteWishListItemLoaded);

  const dispatch = useDispatch();

  const addAnimateForWishlist = (
    item,
    isClicked,
    wishListBaseData,
    wishlistAnimationClass,
    selectedSize,
    isDetailPage
  ) => {
    const value = addAnimate(
      item,
      isClicked,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      isDetailPage
    );
    setWishlistAnimationClass(value);
  };

  const removeAnimationClass = () =>
    setTimeout(() => {
      setWishlistAnimationClass("");
    }, 700);

  useEffect(() => {
    const checkOnWishListAndCartBaseData =
      !prevPropsRef?.current?.wishListAndCartBaseDataLoaded &&
      wishListAndCartBaseDataLoaded;
    const checkOnDeleteWishListItem =
      !prevPropsRef?.current?.deleteWishListItemLoaded &&
      deleteWishListItemLoaded;

    if (
      wishlistAnimationClass &&
      (checkOnWishListAndCartBaseData || checkOnDeleteWishListItem)
    ) {
      removeAnimationClass();
    }

    prevPropsRef.current = {
      wishListAndCartBaseDataLoaded,
      deleteWishListItemLoaded
    };
  }, [
    wishlistAnimationClass,
    deleteWishListItemLoaded,
    wishListAndCartBaseDataLoaded
  ]);

  const onWishlistIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!wishlistAnimationClass) {
      delayedWishlistHandler(item);
    }
  };

  const toggleSaveToWishList = product => {
    if (!isPresent(product)) {
      saveItemToWishList(product);
    } else {
      deleteFromWishList(product.id);
    }

    addAnimateForWishlist(
      product,
      true,
      wishListBaseData,
      wishlistAnimationClass,
      "",
      false
    );
  };

  const delayedWishlistHandler = useCallback(
    debounce(val => {
      toggleSaveToWishList(val);
    }, 500),
    [toggleSaveToWishList]
  );

  const saveItemToWishList = async item => {
    const itemData = {
      size: "",
      sizeType: null,
      productId: Number(item.id)
    };
    await dispatch(saveToWishList(itemData));

    dispatch(getCartAndWishlistGeneralData());
  };

  const deleteFromWishList = async (productId, size = "") => {
    const wishListBaseData = getWishListGeneralDataStore();
    const wishListItem =
      !isEmpty(wishListBaseData) &&
      wishListBaseData.find(
        item => item.productId === productId && item.size === size
      );
    await dispatch(
      deleteWishListData(productId, (wishListItem && wishListItem._id) || "")
    );
    dispatch(getCartAndWishlistGeneralData());
  };

  const mobileTabletView = cn({
    "mobile-tablet-view": isMobileView || isTabletView()
  });

  const isItemPresentInWishlist = isPresent(item);

  const wishListIconcn = cn(
    "wishlist-icon",
    mobileTabletView,
    wishlistAnimationClass,
    {
      wishlistIcon: !wishlistAnimationClass && isItemPresentInWishlist,
      wishlistedIcon: !wishlistAnimationClass && !isItemPresentInWishlist
    }
  );

  return (
    <span
      className={wishListIconcn}
      ref={iconRef}
      onClick={onWishlistIconClick}
    />
  );
};
