import React from "react";
import cn from "classnames";
import includes from "lodash/includes";
import { emailValidationRegExp } from "../../constants";

const GuestTrackingOrder = ({
  handleTextChange,
  handleOnClickTrackOrder,
  orderId,
  email,
  guestOrderTrack,
  trackOrderTranslation,
  trackOrderGuest,
  showContent,
  trackOrderR
}) => {
  const buttonIsDisabled = !orderId || !emailValidationRegExp.test(email);
  return (
    <div
      className={cn("guest_track_wrapper", {
        guest_order_page: trackOrderGuest
      })}
    >
      <h3>
        {trackOrderGuest
          ? trackOrderTranslation.trackAnotherOrder
          : trackOrderTranslation.guestTrackText}
      </h3>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="orderId"
          value={orderId}
          onChange={handleTextChange}
          placeholder={trackOrderTranslation.orderNumber}
          aria-label="Order id"
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder={trackOrderTranslation.emailAddress}
          value={email}
          onChange={handleTextChange}
          aria-label="Email"
        />
      </div>
      <button
        className={cn("round_btn form_black_btn width_auto", {
          disabled: buttonIsDisabled
        })}
        onClick={handleOnClickTrackOrder}
        disabled={buttonIsDisabled}
      >
        {trackOrderTranslation.trackOrder}
      </button>
      {!includes(trackOrderR.orderId, orderId) && showContent && (
        <div className="container noRecordFound">{trackOrderR.message}</div>
      )}
      {!includes(trackOrderGuest, orderId) && (
        <p>{trackOrderTranslation.eyeOnOrder}</p>
      )}
    </div>
  );
};

export default GuestTrackingOrder;
