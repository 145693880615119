import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import get from "lodash/get";
import WishlistContent from "../../components/wishlist-content";
import EmptyWishlist from "../../components/wishlist-content/wishlist-empty-content";
import { productListing } from "../../language/productListing/en/productListing";
import { seoTranslation } from "../../language/seo/en/seo";
import { changeLanguage } from "../../redux/actions/common.action";
import {
  deleteWishListData,
  fetchWishListData,
  selectedSize,
  saveToWishList
} from "../../redux/actions/wishList.action";
import {
  saveToCart,
  fetchCartData,
  getCartAndWishlistGeneralData
} from "../../redux/actions/cart.action";
import { getProductUrlName } from "../../util";
import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";
import WishlistPagination from "../../components/page/pagination/wishListPagination";
import AnalyticService from "../../services/analytic-service";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import filter from "lodash/filter";
import head from "lodash/head";
import Loader from "../../components/loader";
import { LOADER_TYPES } from "../../constants";
import { GAService } from "../../services/GA-service";

const loaderStyle = {
  position: "relative",
  backgroundColor: "initial",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "213px"
};
class Wishlist extends Component {
  state = {
    productTranslation: productListing,
    productsId: "",
    backRoute: "/",
    isLoading: false
  };

  getlanguage = language => {
    const languageResponse = import(
      `../../language/productListing/${language}/productListing`
    );
    languageResponse.then(response => {
      this.setState({
        productTranslation: response.productListing
      });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { language, location, commonSettings } = nextProps;
    if (language !== this.props.language) {
      this.getlanguage(language);
    }
    if (
      language !== this.props.language ||
      commonSettings.countryId !== this.props.commonSettings.countryId
    ) {
      if (commonSettings.countryId) {
        this.props.fetchWishListData(language, location.search);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      currentLanguage,
      fetchWishListData,
      wishListPagination,
      history
    } = this.props;
    const { location: prevLocation } = prevProps;
    const { page, pagesAmount } = wishListPagination;

    const query = pagesAmount ? `?page=${pagesAmount}` : location.search;
    const shouldRelocate =
      (!pagesAmount || +page > +pagesAmount) && location.search !== query;

    if (shouldRelocate) {
      history.push(`${location.pathname}${query}`);
      return;
    }
    if (location.search !== prevLocation.search) {
      fetchWishListData(currentLanguage, location.search);
    }
  }

  componentDidMount() {
    const {
      currentLanguage,
      handleLanguageChange,
      fetchWishListData,
      location,
      commonSettings
    } = this.props;
    handleLanguageChange(currentLanguage);
    this.getlanguage(currentLanguage);
    if (commonSettings.countryId) {
      fetchWishListData(currentLanguage, location.search);
    }

    this.setState({ backRoute: get(location, "state.prevLocation", "/") });
  }

  fetchDeleteWishListData = productId => {
    const { wishList, authReducer, commonSettings } = this.props;
    const removedItem = filter(wishList, item => item.productId === productId);
    const item = head(removedItem);
    AnalyticService.cart.trackDeleteWishlist({
      item,
      wishList,
      authReducer,
      commonSettings
    });
  };

  deleteWishListData = (item, isAfterAddToCart = false) => {
    const {
      deleteWishListData,
      currentLanguage,
      location,
      fetchWishListData,
      getCartAndWishlistGeneralData
    } = this.props;
    const { productId, _id } = item;
    deleteWishListData(productId, _id).then(res => {
      if (res && res.data.success) {
        fetchWishListData(currentLanguage, location.search, false);
        this.fetchDeleteWishListData(productId);
        getCartAndWishlistGeneralData();

        if (!isAfterAddToCart) {
          GAService.cart.removeProductFromWishList(item);
        }
      }
    });
  };

  addToCart = async (item, selectedSize, itemIndex) => {
    const {
      saveToCart,
      authReducer,
      commonSettings,
      selectedSizeType,
      fetchCartData
    } = this.props;
    const cartData = {
      productId: item.productId || item.id,
      size: item.size,
      sizeId: item.sizeId || selectedSize.sizeId,
      sizeType: item.sizeType || selectedSizeType,
      quantity: item.quantity || 1,
      currentPrice: item.currentPrice || 1,
      price: item.price
    };

    let color;
    if (item.size && item && item.colors && item.colors.colorSizeOptions) {
      color = item.colors.colorSizeOptions.find(e => e.sizeLabel === item.size);
    }
    AnalyticService.cart.trackAddToCart({
      item,
      cartData,
      authReducer,
      commonSettings,
      color
    });
    GAService.cart.trackAddProductToCart({
      item: { ...item, position: itemIndex },
      cartData
    });

    const saveToCartResponse = await saveToCart(cartData);
    if (saveToCartResponse?.data?.success) {
      this.deleteWishListData(item, true);
      return await fetchCartData(false);
    }

    return saveToCartResponse;
  };

  saveItem = (productId, size, sizeId, sizeType, _id) => {
    const { selectedSize, saveToWishList } = this.props;
    selectedSize(size, productId, sizeId, sizeType, _id);
    return saveToWishList(
      { productId, size, sizeId, sizeType, _id },
      false,
      false
    );
  };

  getProductId = pId => {
    this.setState({ productsId: pId });
  };

  moveToProductDetail = (item, e) => {
    e.stopPropagation();
    if (item && item.productId) {
      const { history, language, authReducer, commonSettings } = this.props;
      const ProductTitle = getProductUrlName(item.en_title || item.title || "");
      history.push(
        `/${language}-${
          (commonSettings &&
            commonSettings.countrySHORT &&
            commonSettings.countrySHORT.toLowerCase()) ||
          "ae"
        }/${ProductTitle ? ProductTitle : "---"}/${item.productId}/p/`
      );
      AnalyticService.product.trackMoveToProductDetail({
        item,
        authReducer,
        commonSettings
      });
    }
  };
  handleGoBack = () => {
    const { history } = this.props;
    const { backRoute } = this.state;
    history.push(backRoute);
  };

  render() {
    const {
      language,
      wishList,
      wishListLength,
      isShow,
      commonSettings,
      cart,
      showLoader,
      pageLoader
    } = this.props;
    const { productTranslation } = this.state;
    return (
      <>
        <Helmet title={seoTranslation.wishlistPageTitle} />
        {/*<Breadcrumb/>*/}
        {showLoader && !pageLoader ? (
          <Loader
            type={LOADER_TYPES.DOTS}
            size={15}
            qty={3}
            visible
            style={loaderStyle}
          />
        ) : wishList && wishList.length ? (
          <>
            <WishlistContent
              moveToProductDetail={this.moveToProductDetail}
              wishlistSize={wishListLength}
              productData={wishList}
              productTranslation={productTranslation}
              language={language}
              addToCart={this.addToCart}
              deleteWishListData={this.deleteWishListData}
              getProductId={this.getProductId}
              isShow={isShow}
              saveItem={this.saveItem}
              handleGoBack={this.handleGoBack}
            />
            <WishlistPagination translation={productTranslation} />
          </>
        ) : (
          <EmptyWishlist
            productTranslation={productTranslation}
            language={language}
            country={
              (commonSettings &&
                commonSettings.countrySHORT &&
                commonSettings.countrySHORT.toLowerCase()) ||
              "ae"
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  wishList: state.wishListReducer.wishList,
  wishListLength: state.wishListReducer.length,
  wishListPagination: state.wishListReducer.pagination,
  language: state.common.language,
  products: state.productListingReducer.products,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  cart: state.cartReducer.cart,
  selectedSizeType: state.common.selectedSizeType,
  showLoader: state.common.showLoader,
  pageLoader: state.common.pageLoader.visible
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  deleteWishListData: bindActionCreators(deleteWishListData, dispatch),
  saveToCart: bindActionCreators(saveToCart, dispatch),
  saveToWishList: bindActionCreators(saveToWishList, dispatch),
  fetchWishListData: bindActionCreators(fetchWishListData, dispatch),
  fetchCartData: bindActionCreators(fetchCartData, dispatch),
  selectedSize: bindActionCreators(selectedSize, dispatch),
  getCartAndWishlistGeneralData: bindActionCreators(
    getCartAndWishlistGeneralData,
    dispatch
  )
});

export default compose(
  withRouter,
  withLanguageFromUrl,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(Wishlist);
