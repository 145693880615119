export const profileTranslation = {
  myAccount: "My Account",
  myOrders: "My Orders",
  trackOrders: "Track Order",
  signOut: "Sign Out",
  join: "Join",
  signIn: "Sign In",
  quantity: "QTY",
  checkout: "Checkout",
  myCart: "My Cart",
  item: "Item",
  items: "Items",
  subtotal: "Sub - Total",
  itemAddedInCart: "Item added in your cart",
  itemAddedInWishlist: "Item added in your wishlist",
  itemRemovedFromWishlist: "Item's removed from your wishlist",
  off: "off",
  quantityAlert:
    "Removing this item from cart will also remove all the selected quantities",
  hello: "Hello",
  shopByStores: "Shop by store",
  viewBag: "View Cart",
  size: "Size",
  showAllItems: "Show All Items",
  showLessItems: "Show Less Items",
  total: "Total",
  totalResp: "Total",
  excludingDelivery: "(excluding delivery)",
  IncOfVat: "Incl. Of VAT",
  notAvailableIn: "This product is not available in your country",
  currentlyLive: "Currently live",
  upcomingShows: "Upcoming shows",
  discoverMoreLivestreams: "Discover more livestreams"
};
