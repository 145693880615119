import React, { Component, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { isServer } from "../../../util";

class Pagination extends Component {
  resetFilter = () => {
    const { history, language, clearAll, countryShort, isSalePagination } =
      this.props;
    !isSalePagination &&
      history.push(
        `/${language}-${(countryShort && countryShort.toLowerCase()) || "ae"}/`
      );
    clearAll();
    //getProductsData(null, history, null, null, language);
  };

  getPageQuery = page => {
    const { history } = this.props;
    let q;
    if (history.location.search === "") {
      q = `?page=${page}`;
    } else if (
      history.location.search &&
      history.location.search.indexOf("page=") === -1
    ) {
      q = `${history.location.search}&page=${page}`;
    } else {
      let query = [];
      let s = history.location.search.split("&");
      s.map(r => {
        if (r.indexOf("page=") === -1) query.push(r);
        return null;
      });
      q = query.join("&");

      if (page !== 1) {
        q = q.length > 0 ? `${q}&page=${page}` : `?page=${page}`;
      }
    }
    return `${history.location.pathname}` + q;
  };
  render() {
    const { products, handlePageClick, language, translation } = this.props;
    let screenWidth;
    if (!isServer) {
      screenWidth = document.body.clientWidth;
    }
    return (
      <Fragment>
        {products && products.hits && products.hits.length !== 0 ? (
          <div
            className={`pagination_wrapper ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <div className="paginate_display">
              {screenWidth > 1025 ? (
                <span className="page_count">
                  {translation && translation.page}{" "}
                  {language.includes("ar-") || language.includes("ar") ? (
                    <span>
                      {" "}
                      {products.nbPages}/{products.page + 1}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      {products.page + 1}/{products.nbPages}
                    </span>
                  )}
                </span>
              ) : null}
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={products.nbPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                forcePage={products.page}
                containerClassName={"pagination"}
                subContainerClassNsame={"pages pagination"}
                activeClassName={"active"}
                hrefBuilder={page => this.getPageQuery(page)}
              />
            </div>
          </div>
        ) : (
          <div className="reset-filter noRecordForListing">
            {/*<Link to={`/${language}-${countryShort && countryShort.toLowerCase() || 'ae'}/product-lisiting`} >*/}
            <button
              className="round_btn form_black_btn btn"
              onClick={this.resetFilter}
            >
              {translation && translation.resetFilter}
            </button>
            {/*</Link>*/}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Pagination;
