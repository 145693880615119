import React from "react";

export const SaleComingSoon = ({ translation, heading }) => (
  <>
    {heading && <h2 className="coming-soon-heading">{heading}</h2>}

    <div className="coming-soon-section">
      <p>{translation?.comingSoon}</p>
    </div>
  </>
);
