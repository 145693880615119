import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage, selectSettings } from "../util/selectors";
import { LANGUAGE_ROUTE_KEY } from "../constants";
import { changeLanguage } from "../redux/actions/common.action";

const DEFAULT_LANGUAGE = "DEFAULT_LANGUAGE";

const withDefaultLanguageHandling = Component => {
  const EnhancedComponent = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector(selectLanguage);
    const configs = useSelector(selectSettings);
    const defaultLanguage = configs?.default?.langauge;
    const countryId = configs?.countryId;

    useEffect(() => {
      if (defaultLanguage) {
        const isNeedToChangeLang = language !== defaultLanguage;
        const isAlreadySet = localStorage.getItem(
          `${DEFAULT_LANGUAGE}_${countryId}`
        );
        if (isNeedToChangeLang && !isAlreadySet) {
          const pathname = history.location?.pathname;
          const updatedLang = pathname.replace(
            LANGUAGE_ROUTE_KEY[language],
            LANGUAGE_ROUTE_KEY[defaultLanguage]
          );

          localStorage.setItem(`${DEFAULT_LANGUAGE}_${countryId}`, true);
          history.push(updatedLang);
          dispatch(changeLanguage(LANGUAGE_ROUTE_KEY[defaultLanguage]));
        }
      }
    }, [defaultLanguage, countryId]);

    return <Component {...props} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withDefaultLanguageHandling;
