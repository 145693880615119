import ApiService from "./ApiService";

import { BEConfig } from "../config/env";

import { webapiPost, webapiPut } from "../webapis/core";

class AddressService extends ApiService {
  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }

  async addAddress(body, guest) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.addressHandle
    }/?guest=${guest || ""}`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      body,
      this.getVisitorId()
    ).request;
    return response;
  }

  async completeAdding(body) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.addressHandle
    }/complete`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      body,
      this.getVisitorId()
    ).request;
    return response;
  }

  async updateAddress(body, guest) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.addressHandle
    }/${body.addressId}?guest=${guest || ""}`;

    const response = await webapiPut(
      this.getAccessToken(),
      endpoint,
      body,
      this.getVisitorId()
    ).request;
    return response;
  }

  async completeUpdating(body, addressId) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.addressHandle
    }/${addressId}/complete`;

    const response = await webapiPut(
      this.getAccessToken(),
      endpoint,
      body,
      this.getVisitorId()
    ).request;
    return response;
  }

  async resendOtp(token) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.userHandle
    }/resend-otp`;
    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { token },
      this.getVisitorId()
    ).request;
    return response;
  }
}

const instance = new AddressService();

export default instance;
