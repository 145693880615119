import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import * as priceUtil from "../../../util/priceUtil";
import TierLabel from "../../tier-label";
import { DELIVERY_LOYALTY_TIERS } from "../../../constants/loyalty-points";
import {
  isFreeShippingForced,
  shippingMayBeFree
} from "../../../util/shipping";

const CartDeliveryType = ({
  modalData,
  translation,
  commonSettings,
  showCheckBox,
  language
}) => {
  const showDeliveryType = (deliveryType, showFreeMessage = false) => {
    const {
      standardDelivery,
      selfPickup,
      inStorePickUp,
      free,
      collect,
      pickup
    } = translation;
    switch (deliveryType) {
      case "DELIVERY_ADDRESS":
        return showFreeMessage ? free : standardDelivery;
      case "SELF_PICKUP":
        return showFreeMessage ? collect : selfPickup;
      case "STORE_PICKUP":
        return showFreeMessage ? pickup : inStorePickUp;
      default:
        return null;
    }
  };

  const currencyCode = get(commonSettings, "currencyCode", "");

  const showShippingCharge = (freeShippingLimit, shippingCharge) =>
    isFreeShippingForced(freeShippingLimit, shippingCharge)
      ? translation.deliveryPrice
      : `${currencyCode} ${priceUtil.shapePriceWithComma(shippingCharge)}`;

  return (
    <div className="selected_option option">
      {showCheckBox && (
        <input type="radio" className="checked_radio custom_radio" />
      )}
      {modalData &&
        modalData.length &&
        modalData.map((item, key) => {
          const freeShippingForced = isFreeShippingForced(
            item.freeShippingLimit,
            item.shippingCharge
          );
          const freeShippingReachable = shippingMayBeFree(
            item.freeShippingLimit,
            item.shippingCharge
          );
          return (
            <div
              key={item.deliveryType || key}
              className="delivery_option_value radio_option_value"
            >
              <h5 className="shippingType position-relative">
                {showDeliveryType(item.deliveryType)}
                <span className="amnt">
                  (
                  {showShippingCharge(
                    item.freeShippingLimit,
                    item.shippingCharge
                  )}
                  )
                </span>
                {item.tier === DELIVERY_LOYALTY_TIERS.Black && (
                  <TierLabel translation={translation} />
                )}
              </h5>
              <p className="shippingMsg">
                {commonSettings.deliverySettings[key].deliveryMessage ||
                  commonSettings.deliverySettings[key][
                    `${language}_deliveryMessage`
                  ]}
              </p>
              {(freeShippingForced || freeShippingReachable) && (
                <p className="txtGreen">
                  <span className="free">
                    {showDeliveryType(item.deliveryType, true)}
                  </span>{" "}
                  {freeShippingForced
                    ? translation.allOrders
                    : `${
                        translation.spendOver
                      } ${currencyCode} ${priceUtil.shapePriceWithComma(
                        item.freeShippingLimit
                      )}`}
                </p>
              )}
              <div className="border_bottom_style" />
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = state => ({
  language: state.common.language
});

export default connect(mapStateToProps)(CartDeliveryType);
