import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { compose } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import debounce from "lodash.debounce";
import cn from "classnames";
import qs from "query-string";

import { SECTION_SLUGS } from "../../constants/sections";

import { settings_cart } from "../../config/app";

import AnalyticService from "../../services/analytic-service";

import {
  validateProduct,
  getProductUrlName,
  isServer,
  itemsInCart,
  getBaseUrl,
  selectedItemSizeId,
  isMobile,
  getShippingLimitMessage
} from "../../util";
import { isItemAvailableInCountry } from "../../util/common";
import { removeGuestUserStorage } from "../../util/browserStorage";
import * as priceUtil from "../../util/priceUtil";
import {
  selectCartBannerSections,
  selectRouteSlug,
  selectWishListGeneralProducts,
  selectIsCheckedLoyaltyProgram
} from "../../util/selectors";
import { getLoyaltyPointsBody } from "../../util/loyalty-points";

import {
  withAnalyticPageBrowseTracking,
  withDeliverySettingsFetching,
  withPageTypeTracking
} from "../../hocs";

import {
  changeLanguage as handleLanguageChange,
  getLoyalityPoints,
  showPageLoader,
  hidePageLoader
} from "../../redux/actions/common.action";
import {
  deleteCartData,
  fetchCartData,
  updateSizeQty,
  saveToCart,
  clearPrevValue,
  getCartAndWishlistGeneralData
} from "../../redux/actions/cart.action";
import {
  checkoutCart,
  clearCheckoutData
} from "../../redux/actions/checkout.action";
import {
  saveToWishList,
  getWishListBaseData
} from "../../redux/actions/wishList.action";
import { showModal } from "../../redux/actions/homepage.action";
import { resetTokenGeneratorCheckoutGuest } from "../../redux/actions/authConfig.action";
import { getBannerSections } from "../../redux/actions/section.action";
import { fetchEarnedLoyaltyPoints } from "../../redux/actions/myAccount.action";

import CartToastMessage from "../../components/cart/cart-toast-message";
import ItemsInCart from "../../components/item/items-in-cart";
import DeliveryPricePreview from "../../components/order/order-delivery-price-preview";
import LoyaltyAlerts from "../../components/loyalty/loyalty-alert";
import FooterAcceptMoney from "../../components/footer/footer-accept-money";
import PromotionalMessage from "../../components/promotional-alerts";
import RecentlyViewed from "../../components/recent-views";
import EmptyCart from "../../components/cart/cart-empty";
import ApplePay from "../../components/applePay";
import DeleteModal from "../../components/delete-modal";

import { myCart } from "../../language/myCart/en/myCart";
import { productListing } from "../../language/productListing/en/productListing";
import { seoTranslation } from "../../language/seo/en/seo";
import { productDetail } from "../../language/productDetail/en/productDetail";

import emptyCartImg from "../../images/cartEmpty.svg";
import CheckoutButtonFooter from "../../components/checkout/checkout-btn-footer";

import Promotions from "./Promotions";
import Page from "../../components/page";
import {
  isFreeShippingForced,
  isFreeShippingReached,
  shippingMayBeFree
} from "../../util/shipping";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import {
  DEFAULT_PAGE_LOADER_CONFIG,
  GOOGLE_MAPS_APIS_SRC
} from "../../constants";
import { GAService } from "../../services/GA-service";
import { addScriptToBody } from "../../util/dom";

class Cart extends Component {
  state = {
    productTranslation: productListing,
    myCartTranslation: myCart,
    tabbyTranslation: productDetail.tabbyPriceSnippet,
    selectedDelivery: null,
    isShow: false,
    freeShippingUpto: 0,
    isApplePay: false,
    showErrQtyTextBox: "",
    showAlertPopup: false,
    isShowLoyaltyAlert: false,
    pids: []
  };

  async componentDidMount() {
    const {
      match,
      fetchCartData,
      currency,
      authReducer,
      commonSettings,
      cart,
      countryId,
      getBannerSections,
      showLoader,
      showPageLoader,
      fetchEarnedLoyaltyPoints,
      language,
      userLoggedIn
    } = this.props;
    showLoader && showPageLoader(DEFAULT_PAGE_LOADER_CONFIG);
    if (countryId) {
      const [cartData] = await Promise.all([
        fetchCartData(true, match.params.language),
        getBannerSections(SECTION_SLUGS.CART)
      ]);

      if (!isEmpty(cartData)) {
        if (userLoggedIn) {
          fetchEarnedLoyaltyPoints(language, currency, countryId);
        } else {
          this.setState({ isShowLoyaltyAlert: true });
        }
        const pids = map(cart, product => product.id);
        this.setState({ pids });
        GAService.cart.trackViewCartPage(cartData);
        AnalyticService.cart.trackFetchingCartData({
          cartData,
          authReducer,
          commonSettings
        });
      }
    }
    addScriptToBody(GOOGLE_MAPS_APIS_SRC, null, true);
  }

  async componentDidUpdate(prevProps) {
    const {
      language,
      cart,
      countryId,
      showLoader,
      showPageLoader,
      hidePageLoader,
      isJoinedToLoyaltyProgram,
      currencyCode,
      commonSettings,
      getLoyalityPoints,
      isCheckedLoyaltyProgram
    } = this.props;
    const prevLanguage = prevProps.language;
    const prevCountryId = prevProps.countryId;
    const isLoyaltyStatusRefetched =
      isCheckedLoyaltyProgram &&
      prevProps.isCheckedLoyaltyProgram !== isCheckedLoyaltyProgram;
    if (prevLanguage !== language || prevCountryId !== countryId) {
      this.setState({ pids: [] }, () => {
        this.setState({
          pids: map(cart, product => product.id)
        });
      });
    }
    if (prevProps.showLoader && !showLoader) {
      hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG);
    }
    if (!prevProps.showLoader && showLoader) {
      showPageLoader(DEFAULT_PAGE_LOADER_CONFIG);
    }

    if (isJoinedToLoyaltyProgram && isLoyaltyStatusRefetched) {
      const loyalityPointsBody = getLoyaltyPointsBody(
        currencyCode,
        cart,
        commonSettings
      );

      await getLoyalityPoints(loyalityPointsBody);
      this.setState({ isShowLoyaltyAlert: true });
    } else if (isLoyaltyStatusRefetched && !isJoinedToLoyaltyProgram) {
      this.setState({ isShowLoyaltyAlert: true });
    }
  }

  UNSAFE_componentWillMount() {
    const { language, handleLanguageChange, clearCheckoutData } = this.props;

    if (!isServer) {
      handleLanguageChange(language);
      this.loadLanguage(language);
      clearCheckoutData();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { showAlertPopup, isCheckoutTapped } = this.state;
    const {
      fetchCartData,
      deliverySettings,
      cart,
      checkout,
      getBannerSections,
      currencyCode,
      userLoggedIn,
      fetchEarnedLoyaltyPoints
    } = this.props;

    const {
      language: nextPropsLanguage,
      currency: nextPropCurrency,
      countryId: nextPropsCountryId
    } = nextProps;

    if (nextPropsLanguage !== this.props.language) {
      this.loadLanguage(nextPropsLanguage);
      if (nextPropsCountryId) {
        await Promise.all([
          fetchCartData(true, nextPropsLanguage),
          getBannerSections(SECTION_SLUGS.CART)
        ]);
      }
    }

    if (nextProps.currencyCode && nextProps.currencyCode !== currencyCode) {
      if (nextPropsCountryId) {
        const [cartData] = await Promise.all([
          fetchCartData(true, nextPropsLanguage),
          getBannerSections(SECTION_SLUGS.CART)
        ]);

        if (!isEmpty(cartData)) {
          if (userLoggedIn) {
            fetchEarnedLoyaltyPoints(
              nextPropsLanguage,
              nextPropCurrency,
              nextPropsCountryId
            );
          } else {
            this.setState({ isShowLoyaltyAlert: true });
          }
        }
      }
    }

    if (
      nextProps.deliverySettings &&
      deliverySettings &&
      settings_cart &&
      nextProps.deliverySettings.length !== deliverySettings.length
    ) {
      const filteredDeliverySettings =
        nextProps.deliverySettings &&
        nextProps.deliverySettings.length &&
        nextProps.deliverySettings.filter(
          setting => setting.paymentType === settings_cart
        );
      filteredDeliverySettings &&
        filteredDeliverySettings.length &&
        this.setState({
          freeShippingUpto: filteredDeliverySettings[0].freeShippingLimit || 0
        });
    }

    let displayOutOfStock = false,
      displayPriceChange = false,
      displayQuantityChanged = false;

    cart.forEach(item => {
      const prodStatus = validateProduct(checkout, item);
      if (prodStatus) {
        displayOutOfStock = displayOutOfStock || prodStatus._OutOfStock;
        displayPriceChange = displayPriceChange || prodStatus._priceChange;
        displayQuantityChanged =
          displayQuantityChanged || prodStatus._quantityChanged;
      }
    });

    if (
      (displayOutOfStock || displayPriceChange || displayQuantityChanged) &&
      !showAlertPopup &&
      isCheckoutTapped
    ) {
      this.setState({ showAlertPopup: true });
    }
  }

  componentWillUnmount() {
    const { cart, clearPrevValue, showLoader, hidePageLoader } = this.props;
    clearPrevValue(cart);
    if (showLoader) {
      hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG);
    }
  }

  deleteCartListData = async (item, isDeleteWithWishlist = false) => {
    const {
      deleteCartData,
      authReducer,
      commonSettings,
      currency,
      userLoggedIn,
      fetchEarnedLoyaltyPoints,
      language,
      countryId
    } = this.props;

    let color;
    if (item.size && item && item.colors && item.colors.colorSizeOptions) {
      color = item.colors.colorSizeOptions.find(e => e.sizeLabel === item.size);
    }

    AnalyticService.cart.trackRemoveFromCart({
      item,
      authReducer,
      commonSettings,
      color
    });
    GAService.cart.trackRemoveFromCart({ ...item, color });
    let showLoader = true;
    if (isDeleteWithWishlist) {
      showLoader = false;
    }

    deleteCartData(item.productId, item._id, showLoader).then(response => {
      if (
        response &&
        response.status === 200 &&
        response.data.success &&
        userLoggedIn
      ) {
        fetchEarnedLoyaltyPoints(language, currency, countryId, false);
      }
    });
  };

  addToWishListMoEngage = (item, wishListData) => {
    const { authReducer, commonSettings, cart } = this.props;

    const itemIndex = cart.findIndex(cartItem => cartItem.id === item.id);
    GAService.cart.trackAddProductToWishList(
      {
        item: { ...item, position: itemIndex },
        wishListData
      },
      true
    );
    AnalyticService.cart.trackAddingToWishlist({
      item,
      wishListData,
      authReducer,
      commonSettings
    });
  };

  saveToWishList = item => {
    const { saveToWishList, getCartAndWishlistGeneralData } = this.props;
    const wishListData = {
      productId: item.productId,
      size: item.size,
      sizeType: item.sizeType
    };
    saveToWishList(wishListData).then(async resp => {
      if (resp && resp.data && resp.data.success) {
        this.addToWishListMoEngage(item, wishListData);
        await this.deleteCartListData(item, true);
        getCartAndWishlistGeneralData();
      }
    });
  };

  loadLanguage(language) {
    const languageResponse = import(`../../language/myCart/${language}/myCart`);
    const languageResponseProduct = import(
      `../../language/productListing/${language}/productListing`
    );
    const languageResponseTabby = import(
      `../../language/productDetail/${language}/productDetail`
    );
    languageResponseProduct.then(response => {
      this.setState({
        productTranslation: response.productListing
      });
    });
    languageResponse.then(response => {
      this.setState({
        myCartTranslation: response.myCart
      });
    });
    languageResponseTabby.then(response => {
      this.setState({
        tabbyTranslation: response.productDetail.tabbyPriceSnippet
      });
    });
  }

  changeCartItemSize = (exactSizeInfo, item) => {
    const {
      fetchCartData,
      saveToCart,
      updateSizeQty,
      clearCheckoutData,
      cart,
      userLoggedIn
    } = this.props;

    const body = {
      productId: item.productId,
      size: exactSizeInfo.sizeLabel,
      sizeId: exactSizeInfo.sizeId,
      quantity: item.quantity,
      _id: item._id,
      createdDate: item.createdDate
    };

    const isProductMerged = cart.some(cartItem => {
      return (
        cartItem.productId === item.productId &&
        cartItem.sizeId === exactSizeInfo.sizeId
      );
    });

    !isProductMerged &&
      updateSizeQty(item._id, "size", exactSizeInfo.sizeLabel);

    clearCheckoutData();
    saveToCart(body, false)
      .then(resp => {
        if (resp && resp.data.success) {
          const { currency, language, countryId, fetchEarnedLoyaltyPoints } =
            this.props;

          if (userLoggedIn) {
            fetchEarnedLoyaltyPoints(language, currency, countryId);
          }

          fetchCartData(true, language, isProductMerged);
        }
      })
      .catch(console.error);
  };

  changeCartItemQuantity = (quantity, item, itemIndex) => {
    const {
      fetchCartData,
      saveToCart,
      updateSizeQty,
      selectedSizeType,
      userLoggedIn
    } = this.props;

    const selectedSize = selectedItemSizeId(item, selectedSizeType, item.size);
    const sizeId = (selectedSize && selectedSize.sizeId) || item.sizeId || 0;

    const body = {
      productId: item.productId,
      quantity,
      size: item.size,
      _id: item._id,
      createdDate: item.createdDate,
      sizeId,
      sizeType: item.sizeType || selectedSizeType
    };

    updateSizeQty(item._id, "quantity", quantity);

    saveToCart(body, false)
      .then(resp => {
        if (resp && resp.data.success) {
          const { currency, language, countryId, fetchEarnedLoyaltyPoints } =
            this.props;
          const isLoweredQuantity = item.quantity > quantity;
          if (isLoweredQuantity) {
            GAService.cart.trackRemoveFromCart({
              ...item,
              quantity: item.quantity - quantity,
              position: itemIndex
            });
          } else {
            GAService.cart.trackAddProductToCart({
              item: {
                ...item,
                position: itemIndex,
                quantity: Math.abs(item.quantity - quantity)
              }
            });
          }

          if (userLoggedIn) {
            fetchEarnedLoyaltyPoints(language, currency, countryId);
          }

          fetchCartData(true, language, false);
        }
      })
      .catch(console.error);
  };

  wishListLink = () => {
    this.props.history.push(`/${this.props.language}/wishlist/`);
  };

  moveToProductDetail = (item, e) => {
    e.stopPropagation();
    if (item && item.productId) {
      const { history, authReducer, commonSettings } = this.props;
      history.push(
        `${getBaseUrl(this.props)}/${getProductUrlName(
          item.en_title || item.title
        )}/${item.productId}/p/`
      );
      AnalyticService.product.trackMoveToProductDetail({
        item,
        authReducer,
        commonSettings
      });
    }
  };

  _getModifiedDeliveryOption = (option, myCartTranslation, currencyCode) => {
    const { cart, commonSettings, language } = this.props;
    const total = this._getTotal(cart);
    const toDecimalPlaces =
      (commonSettings && commonSettings.decimalPlaces) || 0;
    const { freeShippingLimit, shippingCharge: optionPrice } = option || {};
    const freeShippingForced = isFreeShippingForced(
      freeShippingLimit,
      optionPrice
    );
    const freeShippingReachable = shippingMayBeFree(
      freeShippingLimit,
      optionPrice
    );

    const html =
      option &&
      option.deliveryType &&
      !freeShippingForced &&
      freeShippingReachable &&
      this._returnHtmlCartToastMessage(
        currencyCode,
        freeShippingLimit,
        total,
        myCartTranslation,
        toDecimalPlaces
      );
    let shippingChargeMessage = "";
    let shippingLimitMessage = "";
    const calculatedFree =
      freeShippingReachable && isFreeShippingReached(freeShippingLimit, total);
    const gotFreeShipping = freeShippingForced || calculatedFree;

    let showDeliveryContent;

    if (option && gotFreeShipping) {
      shippingChargeMessage = myCartTranslation.free;
      shippingLimitMessage = getShippingLimitMessage(option, myCartTranslation);
      showDeliveryContent = false;
    } else {
      shippingChargeMessage = `${
        currencyCode || "AED"
      } ${priceUtil.shapePriceWithComma(get(option, "shippingCharge", 0))}`;
      showDeliveryContent = true;
    }
    return (
      (option && {
        key: option.deliveryType,
        price: optionPrice,
        freeShippingLimit,
        value: `

        ${
          !showDeliveryContent
            ? `<h5 class="delivery_direction"><span class="DeliveryMode">${
                myCartTranslation && myCartTranslation.delivery
              } : </span>
        ${myCartTranslation && myCartTranslation[option.deliveryType]}
    <span class=${cn({
      green_color_text: gotFreeShipping
    })}> (${shippingChargeMessage})</span>
    </h5>`
            : `<h5 class="delivery_direction"><span class="DeliveryMode">${
                myCartTranslation && myCartTranslation.standard_delivery
              } </span>
    <span class=${cn({
      green_color_text: gotFreeShipping
    })}> (${shippingChargeMessage})</span>
    </h5>`
        }

              ${`
              <p class="deliveryMessage black_text">${
                (language === "en"
                  ? option.en_deliveryTypeMessage
                  : option.ar_deliveryTypeMessage) || ""
              }</p>
              <p class="deliveryMessage black_text">${
                (language === "en"
                  ? option.en_deliveryMessage
                  : option.ar_deliveryMessage) || ""
              }</p>
              ${
                shippingLimitMessage &&
                `<h6 class=${cn({
                  green_color_text: gotFreeShipping
                })}>${shippingLimitMessage}</h6>`
              }
              
              `}
              ${html ? html : ""}`
      }) ||
      null
    );
  };

  _returnHtmlCartToastMessage = (
    currencyCode,
    freeShippingUpto,
    total,
    myCartTranslation,
    toDecimalPlaces
  ) => {
    total = +total;
    return renderToString(
      <CartToastMessage
        translation={myCartTranslation}
        currencyCode={!!currencyCode && currencyCode}
        freeShippingUpto={
          !!freeShippingUpto && freeShippingUpto % 1 !== 0
            ? freeShippingUpto.toFixed(2)
            : freeShippingUpto
        }
        total={
          !!total && priceUtil.formatPriceByDecimal(total, toDecimalPlaces)
        }
        language={this.props.language}
      />
    );
  };

  changeSelectedDelivery = id => {
    const { deliverySettings } = this.props;
    const { myCartTranslation } = this.state;
    const filteredDeliverySettings =
      deliverySettings &&
      deliverySettings.length &&
      deliverySettings.filter(setting => setting.paymentType === settings_cart);
    if (filteredDeliverySettings && !!filteredDeliverySettings.length) {
      const ModifidSelectedDelivery = this._getModifiedDeliveryOption(
        filteredDeliverySettings.find(item => item.deliveryType === id),
        myCartTranslation
      );
      this.setState({
        selectedDelivery: ModifidSelectedDelivery,
        freeShippingUpto:
          ModifidSelectedDelivery && ModifidSelectedDelivery.freeShippingLimit
      });
    }
  };

  validateCheckout = stopRedirect => {
    const {
      cart,
      currency,
      checkoutCart,
      deliverySettings,
      history,
      language,
      commonSettings,
      resetTokenGeneratorCheckoutGuest
    } = this.props;

    this.setState({ isCheckoutTapped: true });

    removeGuestUserStorage("");
    resetTokenGeneratorCheckoutGuest();

    const filteredDeliverySettings =
      deliverySettings &&
      deliverySettings.length &&
      deliverySettings.filter(setting => setting.paymentType === settings_cart);
    const { selectedDelivery, showErrQtyTextBox } = this.state;

    const orderToValidate = {
      deliveryType:
        (selectedDelivery && selectedDelivery.key) ||
        (filteredDeliverySettings &&
          filteredDeliverySettings.length &&
          filteredDeliverySettings[0].deliveryType) ||
        0,
      ...getLoyaltyPointsBody(currency, cart, commonSettings)
    };
    if (!showErrQtyTextBox) {
      checkoutCart(
        orderToValidate,
        history,
        language,
        stopRedirect,
        get(commonSettings, "countrySHORT", "ae").toLowerCase(),
        cart
      );
    }
  };

  _getTotal = cart => {
    const { commonSettings } = this.props;
    return (
      cart &&
      !!cart.length &&
      cart
        .map(item => {
          return item.currentPrice * ((item && item.quantity) || 1);
        })
        .reduce((total, num) => total + num, 0)
    );
  };

  viewAllWishlistItems = () => {
    const { wishListBaseData } = this.props;
    const { isShow } = this.state;

    if (wishListBaseData.length > 4) {
      this.setState({ isShow: !isShow });
    }

    this.props.history.push(`${getBaseUrl(this.props)}/wishlist/`);
  };

  OpenSignInModal = () => {
    const { showModal } = this.props;
    showModal && showModal();
  };

  handleApplePay = value => {
    this.setState({
      isApplePay: value
    });
  };

  handleGoBack = () => {
    const { history } = this.props;
    history.push(`${getBaseUrl(this.props)}/`);
  };

  isCheckoutEnable = value => {
    this.setState({ showErrQtyTextBox: value });
  };

  generateContinueShoppingUrl = () => {
    const { language, settings } = this.props;
    const baseUrl = `/${language}-${
      settings?.countrySHORT?.toLowerCase() || "ae"
    }`;
    const continueShoppingUrl = localStorage.getItem("CONTINUE_SHOPPING_URL");
    const urlForNavigation = `${baseUrl}${continueShoppingUrl || "/"}`;
    const { url, query, fragmentIdentifier } = qs.parseUrl(urlForNavigation, {
      parseFragmentIdentifier: true
    });

    return {
      pathname: url,
      search: qs.stringify(query),
      hash: qs.stringify(fragmentIdentifier),
      state: { continueShopping: true }
    };
  };

  render() {
    const {
      myCartTranslation,
      productTranslation,
      selectedDelivery,
      freeShippingUpto,
      isApplePay,
      showAlertPopup,
      isShowLoyaltyAlert,
      pids
    } = this.state;
    const {
      language,
      cart,
      wishListBaseData,
      history,
      outOfStock,
      deliverySettings,
      currencyCode,
      loyaltyPoints,
      commonSettings,
      authReducer,
      checkout,
      countryId,
      userLoggedIn,
      isJoinedToLoyaltyProgram,
      routeSlug,
      cartSections
    } = this.props;
    const filteredDeliverySettings =
      deliverySettings &&
      deliverySettings.length &&
      deliverySettings.filter(setting => setting.paymentType === settings_cart);
    const deliveryOptionsGenerated =
      filteredDeliverySettings &&
      filteredDeliverySettings.length &&
      filteredDeliverySettings.map(option =>
        this._getModifiedDeliveryOption(option, myCartTranslation, currencyCode)
      );
    let screenWidth;
    if (!isServer) {
      screenWidth = document.body.clientWidth;
    }
    const total = this._getTotal(cart);
    const _selectedPaymentOption = get(checkout, "selectedPaymentOption", {});

    let displayOutOfStock = false,
      displayPriceChange = false,
      displayQuantityChanged = false;

    cart.forEach(item => {
      const prodStatus = validateProduct(checkout, item);
      if (prodStatus) {
        displayOutOfStock = displayOutOfStock || prodStatus._OutOfStock;
        displayPriceChange = displayPriceChange || prodStatus._priceChange;
        displayQuantityChanged =
          displayQuantityChanged || prodStatus._quantityChanged;
      }
    });

    const { cartTop, cartVat } = cartSections;

    return (
      <Page title={seoTranslation.cartPageTitle}>
        <div className="cart_main_container">
          {!isEmpty(cart) ? (
            <div className="my_cart_block">
              <div
                className={cn("cart_layout_wrapper", {
                  arabic: language.includes("ar")
                })}
              >
                <div className="cart_three_fourth_wrapper">
                  <div className="my_cart_wrapper">
                    <div className="my_cart_text">
                      <h3>
                        {myCartTranslation.myCart}
                        <span>
                          {itemsInCart(cart)}{" "}
                          {itemsInCart(cart) > 1
                            ? myCartTranslation.items
                            : myCartTranslation.item}
                        </span>
                        <i className="icon_cross" onClick={this.handleGoBack} />
                      </h3>
                    </div>
                    <div className="my_cart_continue_shopping_btn">
                      <Link
                        className="btn btn-default round_btn form_white_btn continue_shopping_btn"
                        to={this.generateContinueShoppingUrl()}
                      >
                        {myCartTranslation.continueShopping}
                      </Link>
                    </div>
                  </div>
                  {cartTop &&
                    cartTop.map((section, key) => (
                      <DynamicBannersWrapper
                        section={section}
                        key={key}
                        bannerPageTitle={section.sectionTitle}
                      />
                    ))}

                  {screenWidth < 800 && (
                    <div className="cart_items_count">
                      ITEMS(
                      {itemsInCart(cart)})
                      <span>
                        TOTAL: <b>AED {total + 15}</b>
                      </span>
                    </div>
                  )}

                  <div className="cart_item_wrapper">
                    {Boolean(cart && cart.length) &&
                      cart
                        .sort(product =>
                          priceUtil.isPriceAvailableByUserIpcountry(product) &&
                          isItemAvailableInCountry(product, countryId)
                            ? 0
                            : -1
                        )
                        .filter(item => get(item, "_id"))
                        .map((item, index) => (
                          <ItemsInCart
                            key={item._id}
                            moveToProductDetail={this.moveToProductDetail}
                            changeCartItemSize={exactSizeInfo =>
                              this.changeCartItemSize(exactSizeInfo, item)
                            }
                            changeCartItemQuantity={value =>
                              this.changeCartItemQuantity(value, item, index)
                            }
                            item={item}
                            translation={myCartTranslation}
                            productTranslation={productTranslation}
                            deleteCartListData={this.deleteCartListData}
                            wishListBaseData={wishListBaseData}
                            saveToWishList={debounce(this.saveToWishList, 1000)}
                            language={language}
                            currencyCode={currencyCode}
                            outOfStock={
                              outOfStock &&
                              outOfStock.length &&
                              outOfStock.indexOf(item.productId) !== -1
                            }
                            changeSelectedDelivery={this.changeSelectedDelivery}
                            deliveryType={
                              selectedDelivery ? selectedDelivery.key : ""
                            }
                            checkout={checkout}
                            isCheckoutEnable={this.isCheckoutEnable}
                            commonSettings={commonSettings}
                          />
                        ))}

                    <div className="continue_shopping_wrapper">
                      <Link
                        className="btn btn-default round_btn form_white_btn continue_shopping_btn"
                        to={this.generateContinueShoppingUrl()}
                      >
                        {myCartTranslation.continueShopping}
                      </Link>
                    </div>
                  </div>

                  <Promotions />
                </div>

                <div className="cart_one_fourth_wrapper">
                  <div className="delivery_section">
                    <h4>
                      <span className="delivery">
                        {myCartTranslation.delivery}
                      </span>
                      <span className="orderSummary">
                        {myCartTranslation.orderSummary}
                      </span>
                    </h4>
                    <DeliveryPricePreview
                      deliveryOptions={deliveryOptionsGenerated}
                      selectedDelivery={
                        selectedDelivery ||
                        (deliveryOptionsGenerated &&
                          deliveryOptionsGenerated[0])
                      }
                      changeSelectedDelivery={this.changeSelectedDelivery}
                    />
                    <CheckoutButtonFooter
                      translation={myCartTranslation}
                      total={total}
                      freeShippingUpto={freeShippingUpto}
                      deliveryOptions={deliveryOptionsGenerated}
                      selectedDelivery={
                        selectedDelivery ||
                        (deliveryOptionsGenerated &&
                          deliveryOptionsGenerated[0])
                      }
                      changeSelectedDelivery={this.changeSelectedDelivery}
                      currencyCode={currencyCode}
                      validateCheckout={() => this.validateCheckout()}
                      handleApplePay={this.handleApplePay}
                      commonSettings={commonSettings}
                      _selectedPaymentOption={_selectedPaymentOption}
                      language={language}
                      cartSection={cartVat}
                    />
                  </div>

                  {isShowLoyaltyAlert && (
                    <LoyaltyAlerts
                      language={language}
                      token={authReducer.accessToken}
                      loyaltyPoints={loyaltyPoints}
                      outOfStock={
                        displayOutOfStock ||
                        displayPriceChange ||
                        displayQuantityChanged
                      }
                      translation={myCartTranslation}
                      OpenSignInModal={this.OpenSignInModal}
                      isUserLoggedIn={userLoggedIn}
                      isJoinedToLoyaltyProgram={isJoinedToLoyaltyProgram}
                      routeSlug={routeSlug}
                    />
                  )}

                  <FooterAcceptMoney
                    defaultCountry={commonSettings}
                    translation={myCartTranslation}
                  />
                  <PromotionalMessage translation={myCartTranslation} />
                </div>
              </div>

              {!isMobile.any() && (
                <div
                  id="syte-personalization-container"
                  data-sku={pids}
                  data-placement="Cart"
                />
              )}

              <RecentlyViewed
                translation={myCartTranslation}
                language={language}
                history={history}
              />
            </div>
          ) : (
            <EmptyCart
              noRecordHeading={myCartTranslation.cartEmpty}
              noRecordDesc={myCartTranslation.noItemsInCart}
              language={language}
              noRecordImgUrl={emptyCartImg}
              buttonText={myCartTranslation.continueShopping}
              buttonLink={getBaseUrl(this.props)}
            />
          )}

          {isApplePay && (
            <div
              className={cn({
                arabic: language.includes("ar")
              })}
            >
              <ApplePay
                handleApplePay={this.handleApplePay}
                translation={myCartTranslation}
              />
            </div>
          )}

          <DeleteModal
            isDeleteModalActive={showAlertPopup}
            bodyTxt={myCartTranslation.outOfStockAlertMsg}
            cancelBtnTxt={myCartTranslation.ok}
            handleCloseModal={() =>
              this.setState({ showAlertPopup: false, isCheckoutTapped: false })
            }
            language={language}
          />
        </div>
      </Page>
    );
  }
}

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  cart: reduxState.cartReducer.cart,
  outOfStock: get(reduxState, "checkoutReducer.checkoutData.outOfStock"),
  checkout: reduxState.checkoutReducer.checkoutData,
  wishListBaseData: selectWishListGeneralProducts(reduxState),
  deliverySettings: get(reduxState, "common.settings.deliverySettings"),
  currency: get(reduxState, "common.settings.currencyCode"),
  authReducer: reduxState.authReducer,
  commonSettings: reduxState.common.settings,
  countryId: get(reduxState, "common.settings.countryId", ""),
  loyaltyPoints: reduxState.common.loyaltyPoints,
  currencyCode: get(reduxState, "common.settings.currencyCode"),
  settings: get(reduxState, "common.settings"),
  selectedSizeType: get(reduxState, "common.selectedSizeType"),
  userLoggedIn: reduxState.authReducer.userLoggedIn,
  isJoinedToLoyaltyProgram: reduxState.common.isJoinedToLoyaltyProgram,
  showLoader: reduxState.common.showLoader,
  routeSlug: selectRouteSlug(reduxState),
  cartSections: selectCartBannerSections(reduxState),
  isCheckedLoyaltyProgram: selectIsCheckedLoyaltyProgram(reduxState)
});

const mapDispatchToProps = {
  handleLanguageChange,
  deleteCartData,
  saveToWishList,
  fetchCartData,
  getWishListBaseData,
  updateSizeQty,
  saveToCart,
  checkoutCart,
  clearCheckoutData,
  getLoyalityPoints,
  showModal,
  resetTokenGeneratorCheckoutGuest,
  clearPrevValue,
  getBannerSections,
  showPageLoader,
  hidePageLoader,
  getCartAndWishlistGeneralData,
  fetchEarnedLoyaltyPoints
};

export default compose(
  withDeliverySettingsFetching,
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(Cart);
