import React from "react";
import ModalBFL from "../../modal/modal";
import AddressForm from "../../../routes/my-account/address-form";

const MobileDeliveryAddressModal = ({
  openRespAddressModal,
  language,
  checkoutTranslation,
  updateAddress,
  toggleRespAddressModal,
  isEditAddress,
  onSubmitBtnClick
}) => (
  <ModalBFL
    overlayClassName="respDeliveryOverlay addressBookOverlay"
    showModal={openRespAddressModal}
    modalClass={`slideRight back respDeliveryAddressModal ${
      language.includes("ar-") || language.includes("ar") ? "arabic" : ""
    }`}
  >
    <span className="crossBack" onClick={e => toggleRespAddressModal(false)} />
    <h3>
      {isEditAddress
        ? checkoutTranslation.editAddress
        : checkoutTranslation.addressBook}
    </h3>
    <AddressForm
      checkoutTranslation={checkoutTranslation}
      language={language}
      submitButtonText={checkoutTranslation.saveAddress}
      onSubmit={address => {
        updateAddress(address);
      }}
      onSubmitBtnClick={onSubmitBtnClick}
      closeModal={() => toggleRespAddressModal(false)}
    />
  </ModalBFL>
);

export default MobileDeliveryAddressModal;
