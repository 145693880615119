import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPositionStyle } from "../../util/lisa";
import { selectLisaPlayerIsActive } from "../../util/selectors";
import icon from "../../images/close-alert.svg";
import gif from "../../images/liveIconAnimation.gif";
import "./style.scss";

const LiveAnimation = ({ videoPageRoute, customProps }) => {
  const lisaPlayerIsActive = useSelector(selectLisaPlayerIsActive);
  const [close, setClose] = useState(lisaPlayerIsActive);
  const closeAlert = () => {
    setClose(true);
  };
  const positionStyle = getPositionStyle(customProps, "px");
  useEffect(
    () => {
      setClose(lisaPlayerIsActive);
    },
    [lisaPlayerIsActive]
  );
  return (
    !close && (
      <div className="wrapper">
        <div
          className={cn("alert_LiveVideo", {
            [customProps.position || "bl"]: !positionStyle
          })}
          {...positionStyle && { style: positionStyle }}
        >
          <div className="main">
            <Link to={videoPageRoute}>
              <div>
                <img className="live_gif" src={gif} alt="live_gif" />
              </div>
            </Link>
            <Link to={videoPageRoute}>
              <div className="text-area">
                <p>
                  We are <span className="live">LIVE</span> now!
                </p>
                <div className="alert_link">
                  <span>Click here</span>
                  <p> to Watch & Shop Live.</p>
                </div>
              </div>
            </Link>
          </div>

          <div className="close" onClick={closeAlert}>
            <img src={icon} alt="close_icon" />
          </div>
        </div>
      </div>
    )
  );
};

export default LiveAnimation;
