import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import get from "lodash/get";

import { stormlyKey } from "../../config/app";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getFooterData } from "../../redux/actions/footer.action";
import { hideModal } from "../../redux/actions/homepage.action";
import {
  fetchUserAndLoadData,
  showPrevMode
} from "../../redux/actions/authConfig.action";
import {
  setCommonData,
  getStatesData,
  changeLanguage,
  getCountrySettings,
  getConfigurationSettings,
  updateLanguageName
} from "../../redux/actions/common.action";
import { fetchWishListData } from "../../redux/actions/wishList.action";
import { fetchCartData } from "../../redux/actions/cart.action";

import {
  DEFAULT_STORE_ID,
  HOMEPAGE_ROUTE,
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  languageCountryRegExp
} from "../../constants";

import {
  setUserStoreStorage,
  getUserCountryStorage
} from "../../util/browserStorage";
import { getStoreMap, isServer, languageFromPathName } from "../../util";
import withSyteAiFeeds from "../../hocs/withSyteAiFeeds";
import { getPageApi } from "../../redux/actions/page.action";
import { selectRouteSlug } from "../../util/selectors";
import { locationKeyChanged } from "../../util/routing";
import { getBannerSections } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";
import withDefaultLanguageHandling from "../../hocs/withDefaultLanguageHandling";
import StormlyService from "../../services/StormlyService";

const isPresentInUrl = (string, location = window && window.location) =>
  Boolean(location && location.pathname.includes(string));

const isNavigateToHome = (prevUrlParam, nextUrlParam, routeSlug) =>
  !nextUrlParam.includes(prevUrlParam) &&
  (nextUrlParam === routeSlug || nextUrlParam === HOMEPAGE_ROUTE);
class AppContainer extends Component {
  UNSAFE_componentWillMount() {
    const { hideHeader, setFooterSpace, hideInMobile, history } = this.props;

    this.unlisten = history.listen(location => {
      hideHeader &&
        hideHeader(
          isPresentInUrl("checkout", location) ||
            isPresentInUrl("payment-error", location)
        );

      if (setFooterSpace) {
        setFooterSpace(isPresentInUrl("product-detail", location));
      }

      let pathName = window.location.pathname.split("/");
      let pathIndex = pathName[pathName.length - 2];
      let pathIndex2 = pathName[pathName.length - 1];

      hideInMobile &&
        hideInMobile(
          pathIndex === "p" ||
            pathIndex === "my-cart" ||
            pathIndex === "wishlist" ||
            pathIndex2 === "p" ||
            pathIndex2 === "my-cart" ||
            pathIndex2 === "wishlist"
        );
    });
  }

  componentDidMount() {
    const {
      language,
      location,
      routeSlug,
      getPageApi,
      settings,
      getMegaMenuData,
      countryId,
      storeId
    } = this.props;
    let url = window.location.href.toLowerCase();

    const locationPath = location.pathname;
    const pageUrl = locationPath.split(routeSlug)[1];
    const isHomePage = pageUrl === HOMEPAGE_ROUTE;

    this.fetchCountryList();

    let pathName = window.location.pathname.split("/");
    let pathIndex = pathName[pathName.length - 2];
    let pathIndex2 = pathName[pathName.length - 1];

    this.setState({
      hideHeader: isPresentInUrl("checkout") || isPresentInUrl("payment-error"),
      setFooterSpace: isPresentInUrl("product-detail"),
      hideInMobile:
        pathIndex === "p" ||
        pathIndex === "my-cart" ||
        pathIndex === "wishlist" ||
        pathIndex2 === "p" ||
        pathIndex2 === "my-cart" ||
        pathIndex2 === "wishlist"
    });

    let _storeMap = getStoreMap(settings);

    const _store =
      settings &&
      settings.stores &&
      settings.stores.length &&
      (settings.stores.find(store => url.includes(_storeMap[store.mid])) ||
        settings.stores.find(store =>
          store.name.toLocaleLowerCase().includes("brands")
        ));

    _store && setUserStoreStorage(_store.mid);
    const needToCallHomePage =
      settings.countryId &&
      _store &&
      _store.mid &&
      !(countryId && storeId) &&
      isHomePage;

    if (needToCallHomePage) {
      getPageApi("/", language, settings && settings.countryId, _store.mid);
      getMegaMenuData(language, _store.mid, settings && settings.countryId);
      this.getHeaderBannerSections();
    }

    StormlyService.loadAnaliticsScript(stormlyKey);
  }

  getHeaderBannerSections = () => {
    this.props.getBannerSections(SECTION_SLUGS.HEADER);
  };

  fetchCountryList = () => {
    const { setCommonData, getStatesData, getCountrySettings } = this.props;

    let actions = [],
      country = getUserCountryStorage();

    const { language, settings, ipCountryData, history, location } = this.props;

    if (!Object.keys(settings).length) {
      actions.push(getCountrySettings(language, null, null, true));

      Promise.all(actions).then(function (data) {
        let selectedCountry = null,
          selectedIPCountry = null,
          countryCookie = null,
          countryData = get(data, "[0].data", []),
          url = window.location.href.toLowerCase(),
          _url = window.location;

        _url = _url.toString();
        countryData.map(_country => {
          const countryShort = _country.countrySHORT;

          if (
            url.includes(`/en-${countryShort.toLowerCase()}`) ||
            url.includes(`/ar-${countryShort.toLowerCase()}`)
          ) {
            selectedCountry = _country;
          }

          if (countryShort === country) {
            countryCookie = _country;
          }

          if (
            ipCountryData &&
            ipCountryData.countrySHORT &&
            ipCountryData.countrySHORT === countryShort
          ) {
            selectedIPCountry = _country;
          }

          return null;
        });

        if (selectedCountry) {
          setCommonData(selectedCountry);
          selectedCountry.countryId &&
            getStatesData(selectedCountry.countryId, language);
        }
      });
    } else {
      settings.countryId && getStatesData(settings.countryId, language);
      let url = window.location.href.toLowerCase(),
        pathname = window.location.pathname,
        _url = window.location;

      _url = _url.toString();
      let _pathname = pathname.split("/");
      if (!(url.includes("//localhost") || url.includes("bfltest-"))) {
        const { search, hash } = location;
        if (url.includes(`/ar-`) || url.includes(`/ar/`)) {
          _pathname[1] = `ar-${settings.countrySHORT.toLowerCase()}`;
          _pathname = _pathname.join("/");
          history.replace({ pathname: _pathname, search, hash });
        } else if (url.includes(`/en-`) || url.includes(`/en/`)) {
          _pathname[1] = `en-${settings.countrySHORT.toLowerCase()}`;
          _pathname = _pathname.join("/");
          history.replace({ pathname: _pathname, search, hash });
        } else {
          history.replace({
            pathname: `/en-${settings.countrySHORT.toLowerCase()}${pathname}`,
            search,
            hash
          });
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { location, settings, language, getPageApi, routeSlug } = this.props;
    const { countryId } = settings;
    const currentLocation = location.pathname;
    const prevLocation = prevProps.location.pathname;

    const isToHome = isNavigateToHome(
      prevLocation,
      currentLocation,
      `/${routeSlug}/`
    );
    if (isToHome) {
      getPageApi(HOMEPAGE_ROUTE, language, countryId, DEFAULT_STORE_ID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    let prevUrlParam = this.props.location.pathname;
    let nextUrlParam = nextProp.history.location.pathname;

    const splitPrevArray = prevUrlParam.split(
      `/${nextProp.language}-${
        (nextProp.settings &&
          nextProp.settings.countrySHORT &&
          nextProp.settings.countrySHORT.toLowerCase()) ||
        "ae"
      }`
    );
    const splitNextArray = nextUrlParam.split(
      `/${nextProp.language}-${
        (nextProp.settings &&
          nextProp.settings.countrySHORT &&
          nextProp.settings.countrySHORT.toLowerCase()) ||
        "ae"
      }`
    );

    prevUrlParam =
      splitPrevArray.length > 1 ? splitPrevArray[1] : splitPrevArray[0];
    nextUrlParam =
      splitNextArray.length > 1 ? splitNextArray[1] : splitNextArray[0];

    const url = !isServer && window.location.href.toLowerCase();
    const {
      getPageApi,
      language,
      settings,
      getMegaMenuData,
      getBannerSections,
      getConfigurationSettings,
      updateLanguageName
    } = this.props;
    let _flag = false,
      storeID = null,
      _bflStoreID = null,
      _storeMap = getStoreMap(nextProp.settings);

    nextProp.settings &&
      nextProp.settings.stores &&
      Array.isArray(nextProp.settings.stores) &&
      nextProp.settings.stores.map(store => {
        if (url.includes(_storeMap[store.mid])) {
          storeID = store.mid;
          _flag = true;
        }
        if (store.name.toLocaleLowerCase().includes("brands")) {
          _bflStoreID = store.mid;
        }
        return null;
      });

    if (nextProp.settings.countryId && (storeID || _bflStoreID)) {
      let _language =
          nextProp.language === LANGUAGE_ROUTE_KEY.arabic
            ? LANGUAGE.arabic
            : LANGUAGE.english,
        _storeList = [];
      nextProp.settings &&
        nextProp.settings.stores &&
        nextProp.settings.stores.forEach(_store => {
          let _storeURL = _store.name;
          _storeURL = _storeURL.toLowerCase();
          _storeURL = _storeURL.replace(/ /g, "-");
          if (_storeURL.includes("brands-for-less")) {
            _storeList.push(`/`);
          } else {
            _storeList.push(`/${_storeURL}`);
            _storeList.push(`/${_storeURL}/`);
          }
        });

      const isHomePage = nextUrlParam === HOMEPAGE_ROUTE;

      const [lang, countryId, storeId] = [
        _language,
        nextProp.settings && nextProp.settings.countryId,
        _flag ? storeID : _bflStoreID
      ];
      const languageHasChanged = nextProp.language !== language;
      const countryShortHasChanged =
        nextProp.settings.countrySHORT !== settings.countrySHORT;
      const theSameLinkWasClicked = locationKeyChanged(
        this.props.location,
        nextProp.location
      );
      if (languageHasChanged || countryShortHasChanged) {
        isHomePage && getPageApi(HOMEPAGE_ROUTE, lang, countryId, storeId);
        getMegaMenuData(lang, storeId, countryId);
        this.getHeaderBannerSections();
        getConfigurationSettings();
      } else if (theSameLinkWasClicked) {
        const callUrl = isHomePage
          ? HOMEPAGE_ROUTE
          : nextUrlParam.replace("/", "");
        getPageApi(callUrl, lang, countryId, DEFAULT_STORE_ID);
      }
    }

    let prevError = this.props.isError;
    let nextError = nextProp.isError;
    const { is500, is503 } = nextProp.isError;
    let lang = `${nextProp.language}`;
    let country =
      nextProp.settings &&
      nextProp.settings.countrySHORT &&
      `${nextProp.settings.countrySHORT.toLowerCase()}`;
    if (
      JSON.stringify(prevError) !== JSON.stringify(nextError) &&
      (is500 || is503) &&
      country &&
      lang
    ) {
      nextProp.history.push(`/${lang}-${country}/error-page`);
    }

    const browserLanguageFromURL = languageFromPathName(
      (document.location.pathname.match(languageCountryRegExp) || [])[0]
    );

    if (lang !== browserLanguageFromURL) {
      updateLanguageName(browserLanguageFromURL);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    return <div className="parent-container">{this.props.children}</div>;
  }
}
const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings,
  countryId: state.page.homepageState.countryId,
  storeId: state.page.homepageState.storeId,
  isHomePage: state.homepage.isHomePage,
  isError: state.common.errorPage,
  showModal: state.homepage.showModal,
  ipCountryData: state.common.ipCountryData,
  userLoggedIn: state.authReducer.userLoggedIn,
  userLoggedInEmail: state.authReducer.email,
  customerId: state.authReducer.customerId,
  showPreview: state.authReducer.showPreview,
  isProductListingPage: state.productListingReducer.isProductListingPage,
  routeSlug: selectRouteSlug(state)
});

const mapDispatchToProps = dispatch => ({
  getPageApi: bindActionCreators(getPageApi, dispatch),
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch),
  footerContent: bindActionCreators(getFooterData, dispatch),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  fetchUserAndLoadData: bindActionCreators(fetchUserAndLoadData, dispatch),
  fetchWishListData: bindActionCreators(fetchWishListData, dispatch),
  fetchCartData: bindActionCreators(fetchCartData, dispatch),
  setCommonData: bindActionCreators(setCommonData, dispatch),
  getStatesData: bindActionCreators(getStatesData, dispatch),
  showPrevMode: bindActionCreators(showPrevMode, dispatch),
  getCountrySettings: bindActionCreators(getCountrySettings, dispatch),
  getBannerSections: bindActionCreators(getBannerSections, dispatch),
  getConfigurationSettings: bindActionCreators(
    getConfigurationSettings,
    dispatch
  ),
  updateLanguageName: bindActionCreators(updateLanguageName, dispatch)
});

export default compose(
  withDefaultLanguageHandling,
  withSyteAiFeeds,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AppContainer);
