import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Image } from "../../image";
import ListComponent from "./list-component";
import { isArray } from "../../../util";

class MobileMenuCategories extends Component {
  state = {
    isToggle: false,
    page: 1,
    page2Data: [],
    page2Header: "",
    page3Data: [],
    page3Header: "",
    page3Title: "",
    carousalList: []
  };

  handleMenuList = menuList => {
    const { pageChange } = this.props;
    if (this.checkIfChildExists(menuList)) {
      let page2Data = this.createSingleList(menuList);
      this.setState({
        page: 2,
        page2Data,
        page2Header: menuList.headerTitle,
        carousalList: menuList.catCarousalList
      });
      pageChange(2);
    } else {
      this.props.openLink(menuList.redirectionLink);
    }
  };

  createSingleList = list => {
    let colList = ["col1", "col2", "col3", "col4", "col5", "col6"];
    let singleList = [];
    colList.forEach(item => {
      if (!!isArray(list[item]) && !!list[item].length) {
        singleList = [...singleList, ...list[item]];
      }
    });
    return singleList;
  };

  checkIfChildExists(list) {
    let colList = ["col1", "col2", "col3", "col4", "col5", "col6"];
    let isChild = false;
    colList.forEach(item => {
      if (!!isArray(list[item]) && !!list[item].length) {
        isChild = true;
        return false;
      } else {
        return true;
      }
    });
    return isChild;
  }

  innerPageListClick = item => {
    if (!!isArray(item.children) && !!item.children.length) {
      this.setState({
        page: 3,
        page3Data: item.children,
        page3Header: item.subTitle,
        page3Title: item.groupBy,
        carousalList: []
      });
    } else {
      this.props.openLink(item.redirectUrl);
    }
  };

  backBtnClick = () => {
    let { page } = this.state;
    page = page - 1;
    this.setState({ page });
    this.props.pageChange(page);
  };

  render() {
    const {
      page3Title,
      page,
      page2Data,
      page3Data,
      page2Header,
      page3Header,
      carousalList
    } = this.state;
    const { megaMenu, superPriceStore } = this.props;
    let content = null;
    if (page === 1) {
      content = (
        <ul className="menu_categories_wrapper">
          {!!megaMenu &&
            !!megaMenu.length &&
            megaMenu.map(menuList => (
              <li
                key={menuList.uid}
                onClick={() => this.handleMenuList(menuList)}
              >
                <span>{menuList.headerTitle}</span>
                <span className="menu_img">
                  <Image src={menuList.hoverImageUrl} atl="categories menu" />
                </span>
              </li>
            ))}
          {superPriceStore &&
            superPriceStore.showSps && (
              <li
                onClick={() =>
                  this.handleMenuList({
                    ...superPriceStore,
                    redirectionLink:
                      (superPriceStore && superPriceStore.spsRedirectUrl) || ""
                  })
                }
              >
                <span>{superPriceStore.spsTitle}</span>
                <span className="menu_img">
                  <Image
                    src={superPriceStore.imageUrl || ""}
                    atl="categories menu"
                  />
                </span>
              </li>
            )}
          {/* {superPriceStore}   */}
        </ul>
      );
    } else if (page === 2) {
      content = (
        <ListComponent
          carousalList={carousalList}
          data={page2Data}
          header={page2Header}
          handleClick={this.innerPageListClick}
          backBtnClick={this.backBtnClick}
          isLevel2
        />
      );
    } else if (page === 3) {
      content = (
        <ListComponent
          data={page3Data}
          header={page3Header}
          handleClick={this.innerPageListClick}
          backBtnClick={this.backBtnClick}
          page3Title={page3Title}
        />
      );
    }

    return <Fragment>{content}</Fragment>;
  }
}

const mapStateToProps = state => ({
  megaMenu: state.megaMenuReducer.megaMenuList,
  superPriceStore: state.megaMenuReducer.staticContent
});

export default connect(
  mapStateToProps,
  null
)(MobileMenuCategories);
