import React from "react";
import { LANGUAGE_SHORT, LOADER_TYPES } from "../../constants";
import CountrySelector from "../country/country-selector";
import FooterAcceptMoney from "../footer/footer-accept-money";
import Loader from "../loader";
import * as priceUtil from "../../util/priceUtil";
import { isFreeShippingForced, shippingMayBeFree } from "../../util/shipping";

const loaderStyle = {
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "inherit",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const SetUserPreferences = ({
  userPreferencesTranslation,
  defaultCountry,
  onChangeHandler,
  submit,
  countryData,
  ipCountryData,
  freeShipping,
  currencyCode,
  language,
  defaultCurrency,
  shippingCharge,
  showButtonLoader
}) => {
  const freeShippingReachable = shippingMayBeFree(freeShipping, shippingCharge);
  const freeShippingForced = isFreeShippingForced(freeShipping, shippingCharge);
  return (
    <div className="user_preferences">
      <h2>{userPreferencesTranslation.setPreference}</h2>
      <p>{userPreferencesTranslation.selectRegionCurrency}</p>
      <div className="inner_content">
        <CountrySelector
          translation={userPreferencesTranslation}
          defaultCountry={defaultCountry}
          countryData={countryData}
          ipCountryData={ipCountryData}
          onChangeHandler={onChangeHandler}
        />
        <div className="country_usp">
          <ul>
            {(freeShippingReachable || freeShippingForced) &&
              (() => {
                if (freeShippingForced) {
                  return (
                    <li>
                      {userPreferencesTranslation.freeShippingForAllTheOrders}
                    </li>
                  );
                } else if (language === LANGUAGE_SHORT.en) {
                  return (
                    <li>
                      {userPreferencesTranslation.freeShipping} {currencyCode}{" "}
                      {priceUtil.shapePriceWithComma(freeShipping)}{" "}
                    </li>
                  );
                }
                return (
                  <li>
                    {currencyCode} {priceUtil.shapePriceWithComma(freeShipping)}{" "}
                    {userPreferencesTranslation.freeShipping}{" "}
                  </li>
                );
              })()}

            <li>{userPreferencesTranslation.hassleFree}</li>
          </ul>
        </div>
        <div className="currency_selector">
          <p>{userPreferencesTranslation.currency}</p>
          <div>
            {userPreferencesTranslation[defaultCurrency] || defaultCurrency}
          </div>
        </div>
        <FooterAcceptMoney
          codAvailable={true}
          translation={userPreferencesTranslation}
          defaultCountry={defaultCountry}
        />
        <button
          className="round_btn form_black_btn position-relative"
          onClick={submit}
          disabled={showButtonLoader}
        >
          {showButtonLoader && (
            <Loader
              type={LOADER_TYPES.DOTS}
              visible
              style={loaderStyle}
              size={10}
            />
          )}
          {userPreferencesTranslation.apply}
        </button>
      </div>
    </div>
  );
};

export default SetUserPreferences;
