import { BROWSER_STORAGE } from "../../redux/constants";
import {
  changeLanguage,
  getConfigurationSettings
} from "../../redux/actions/common.action";
import { fetchProductDetail } from "../../redux/actions/productDetail.action";
import {
  getCountrySettings,
  setCommonData
} from "../../redux/actions/common.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getPageApi } from "../../redux/actions/page.action";
import {
  _countryStoreSelection,
  getStoreMap,
  languageFromPathName
} from "../../util";
import { handleSectionsTask } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;
      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  },
  settings: {
    asyncTask: ({ store, params = {}, url }) => {
      const { language } = params;
      return store.dispatch(
        getCountrySettings(languageFromPathName(language), null, url)
      );
    },
    priority: 2
  },
  fetchHomePage: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getPageApi(
          "/",
          language,
          _country && _country.countryId,
          storeId || _bflStoreId,
          null,
          cookies
        )
      );
    },
    priority: 4
  },
  fetchMegaMenu: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      let _country = null,
        countryAE = {},
        selectedCountry = null,
        selectedIPCountry = null,
        countryCookie = null,
        countryData = common.countryData || [];

      countryData.map(_country => {
        if (
          url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
          url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
        ) {
          selectedCountry = _country;
        }
        if (_country.countrySHORT === userCountry) {
          countryCookie = _country;
        }
        if (_country.countrySHORT === "AE") {
          countryAE = _country;
        }
        if (
          common.ipCountryData &&
          common.ipCountryData.countrySHORT &&
          common.ipCountryData.countrySHORT === _country.countrySHORT
        ) {
          selectedIPCountry = _country;
        }
        return null;
      });

      if (selectedCountry) {
        _country = selectedCountry;
        store.dispatch(setCommonData(selectedCountry));
      } else if (!selectedCountry) {
        if (countryCookie) {
          _country = countryCookie;
          store.dispatch(setCommonData(countryCookie));
        } else if (selectedIPCountry) {
          _country = selectedIPCountry;
          store.dispatch(setCommonData(selectedIPCountry));
        } else {
          _country = countryAE;
          store.dispatch(setCommonData(countryAE));
        }
      }

      const _storeList = (_country && _country.stores) || [];
      let storeId = null,
        _bflStoreId = null,
        _storeMap = getStoreMap(_country);

      _storeList &&
        _storeList.forEach(item => {
          if (url.includes(_storeMap[item.mid])) {
            storeId = item.mid;
          }
          if (item.name.toLocaleLowerCase().includes("brands")) {
            _bflStoreId = item.mid;
          }
        });
      url = "";
      return store.dispatch(
        getMegaMenuData(
          languageFromPathName(language),
          storeId || _bflStoreId,
          _country && _country.countryId,
          cookies
        )
      );
    },
    priority: 5
  },
  fetchProductDetail: {
    asyncTask: ({ store, params = {}, ipcountry }) => {
      const { language, id } = params;

      return store.dispatch(fetchProductDetail(id, language, ipcountry));
    },
    priority: 6
  },
  fetchHeaderSectins: {
    asyncTask: arg => handleSectionsTask(arg, SECTION_SLUGS.HEADER),
    priority: 7
  },
  configuration: {
    asyncTask: ({ store }) => store.dispatch(getConfigurationSettings()),
    priority: 8
  }
};
