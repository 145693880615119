import React from "react";
import Img from "react-image";
import { useHistory } from "react-router-dom";
import oopsImage from "../../images/layer-1@2x.png";
import "./style.scss";
const FeatureNotAvailable = ({ message, buttonLabel }) => {
  const history = useHistory();
  return (
    <div className="error-wrap">
      <div className="image-container">
        <Img src={oopsImage} alt="Error Found" className="oops-image" />
      </div>
      <p className="not-found-text">{message}</p>
      <button
        onClick={() => history.push("/")}
        className="not-found-page-button"
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default FeatureNotAvailable;
