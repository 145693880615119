import React from "react";
import cn from "classnames";

const AddItemToWishlistMob = ({
  item,
  translation,
  isWishListed,
  handleAddToWishList,
  removeCartItem
}) => (
  <div
    className={cn("move_to_wishlist", "responsive", {
      wish_listed: isWishListed(item)
    })}
  >
    {!isWishListed(item) && (
      <span className="saveForLater_btn" onClick={handleAddToWishList}>
        <i className="wishlist_icon" />
        {translation && translation.moveToMyWishlist}
      </span>
    )}
    <span className="remove_text" onClick={removeCartItem}>
      {translation && translation.removeTxt}
    </span>
  </div>
);

export default AddItemToWishlistMob;
