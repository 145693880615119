export const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

export const years = Array.from(
  { length: new Date().getFullYear() - 1968 },
  (_, i) => i + 1950
).reverse();

export const sliceBirthdayIndex = 10;

export const months = [
  { en: "January", ar: "يناير", number: "01" },
  { en: "February", ar: "فبراير", number: "02" },
  { en: "March", ar: "مارس", number: "03" },
  { en: "April", ar: "أبريل", number: "04" },
  { en: "May", ar: "مايو", number: "05" },
  { en: "June", ar: "يونيو", number: "06" },
  { en: "July", ar: "يوليو", number: "07" },
  { en: "August", ar: "أغسطس", number: "08" },
  { en: "September", ar: "سبتمبر", number: "09" },
  { en: "October", ar: "أكتوبر", number: "10" },
  { en: "November", ar: "نوفمبر", number: "11" },
  { en: "December", ar: "ديسمبر", number: "12" }
];

export const transformBirthdayValue = value =>
  value.toString().length > 1 ? value : `0${value}`;

export const getDividedBirthday = (birthday, language = "en") => {
  const resultArray = birthday.slice(0, sliceBirthdayIndex).split("-");

  const month = (months.find(month => month.number === resultArray[1]) || {})[
    language
  ];

  return {
    selectedYear: resultArray[0],
    selectedMonth: month,
    selectedDay: resultArray[2]
  };
};
