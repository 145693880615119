import React, { Component } from "react";
import { connect } from "react-redux";
import Headroom from "react-headroom";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import { getProductUrlName, handleScrollTop, isServer } from "../../../util";
import { getInternationalSizes } from "../../../util/cart";
import Listing from "./listing";
import AnalyticService from "../../../services/analytic-service";
import {
  selectWishListGeneralProducts,
  selectSyteSettings
} from "../../../util/selectors";
import { GAService } from "../../../services/GA-service";

class ContentListing extends Component {
  state = {
    foldLoaded: false,
    productImpression: {},
    defaultProductImpression: false
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleFloatingPagination);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      products,
      commonSettings,
      authReducer,
      pageTitle,
      language,
      handleProductListingImpressions
    } = this.props;

    const productsHits = get(products, "hits", []);
    const nextPropsHits = get(nextProps, "products.hits", []);
    const productsHitsHaveItems = !isEmpty(productsHits);
    const nextPropsHitsHaveItems = !isEmpty(nextPropsHits);
    const currentAndNextHitsExist =
      productsHitsHaveItems && nextPropsHitsHaveItems;

    if (currentAndNextHitsExist && productsHits[0].id !== nextPropsHits[0].id) {
      this.setState({ foldLoaded: false });
    }
    const nextBrandName = get(
      nextProps,
      "breadcrumb.brandsList.brandsName",
      ""
    );
    const nextProductList = get(
      nextProps,
      ["breadcrumb", "productList", `${language}_productListName`],
      ""
    );
    if (
      this.listRef &&
      !this.state.defaultProductImpression &&
      currentAndNextHitsExist &&
      products.hits[0].id &&
      (nextBrandName ||
        (nextProductList && nextProductList !== nextProps.pageTitle))
    ) {
      const { width: containerWidth } = this.listRef.getBoundingClientRect();
      const { width: elementWidth } =
        this.listRef.children[0].getBoundingClientRect();
      const itemPerRow = parseInt(containerWidth / elementWidth);
      let startIndex = null,
        lastIndex = null;
      startIndex = 0;
      lastIndex = itemPerRow - 1 || 0;
      let _arr = [];
      for (let i = startIndex; i <= lastIndex; i++) {
        productsHits &&
          products.hits[i] &&
          _arr.push({
            ...products.hits[i],
            position: i + 1
          });
      }
      const productListCount = productsHits.length;
      this.setState({ defaultProductImpression: true }, () => {
        let _category = nextProps.brandPageDetails
          ? nextBrandName
          : nextProductList;
        AnalyticService.product.trackContentListing({
          item: _arr,
          authReducer,
          commonSettings,
          title: pageTitle,
          category: _category,
          productListCount
        });
        handleProductListingImpressions(_arr);
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.handleScrollPositionStoring(prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleFloatingPagination);
  }

  isBottom = el => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };

  _getAvailableSizes = item => {
    const itemColorsSizeOptions = get(item, "colors.colorSizeOptions", []);
    let availableSizes = itemColorsSizeOptions.filter(
      option => option.sizeLabel
    );

    const disResult = {};
    if (availableSizes) {
      availableSizes.forEach(item => {
        if (
          !disResult[item.sizeShortLabel] ||
          disResult[item.sizeShortLabel].sizeStock < item.sizeStock
        ) {
          disResult[item.sizeShortLabel] = item;
        }
      });
    }
    if (Object.keys(disResult).length) {
      availableSizes = Object.values(disResult);
    }
    let internationalSizes = {};
    item &&
      item.IsMultisize &&
      getInternationalSizes(availableSizes, internationalSizes);
    if (
      Object.keys(internationalSizes) &&
      Object.keys(internationalSizes).length
    ) {
      availableSizes = internationalSizes[Object.keys(internationalSizes)[0]];
    }
    return map(availableSizes, size => ({
      ...size,
      sizeLabel: size.sizeShortLabel || size.sizeLabel
    }));
  };

  handleFloatingPagination = debounce(() => {
    const {
      products,
      commonSettings,
      authReducer,
      pageTitle,
      brandPageDetails,
      breadcrumb,
      language,
      handleProductListingImpressions
    } = this.props;

    if (get(products, "hits[0].id", false) && this.listRef) {
      const { width: containerWidth } = this.listRef.getBoundingClientRect();
      const { width: elementWidth, height: elementHeight } =
        this.listRef.children[0].getBoundingClientRect();
      const itemPerRow = parseInt(containerWidth / elementWidth);

      const _scrolledItem =
        parseInt(window.pageYOffset / elementHeight) * itemPerRow;
      const startIndex = _scrolledItem || 0;
      const lastIndex = _scrolledItem + itemPerRow - 1 || 0;

      if (
        lastIndex < products.hits.length &&
        startIndex <= this.state.noOfRecordsPassed
      ) {
        const { productImpression } = this.state;
        let newImpressions = {};
        for (let i = startIndex; i <= lastIndex; i++) {
          if (
            products.hits[i] &&
            products.hits[i].id &&
            !productImpression.hasOwnProperty(products.hits[i].id)
          ) {
            newImpressions[products.hits[i].id] = {
              ...products.hits[i],
              position: i + 1
            };
          }
        }

        if (
          !isEmpty(newImpressions) &&
          !isEqual(productImpression, newImpressions)
        ) {
          let _category = brandPageDetails
            ? breadcrumb.brandsList.brandsName
            : breadcrumb.productList[`${language}_productListName`];

          AnalyticService.product.trackProductImpressions({
            _arr: Object.values(newImpressions),
            authReducer,
            commonSettings,
            pageTitle,
            _category
          });
          const impressionsByIndex = Object.values(newImpressions).toSorted(
            (a, b) => a.position - b.position
          );
          handleProductListingImpressions(impressionsByIndex);

          this.setState({ productImpression: newImpressions });
        }
      }
      this.setState({
        noOfRecordsPassed:
          _scrolledItem + itemPerRow > products.hits.length
            ? products.hits.length
            : _scrolledItem + itemPerRow
      });
    }
  }, 200);

  handleProductSelect = (productItem, isQuickLook = false) => {
    const { products } = this.props;
    const eventPayload = {
      item: productItem,
      listingTitle: products.en_title || products.title || ""
    };
    GAService.product.trackSelectProduct(eventPayload);
    if (isQuickLook) {
      // track view product
      GAService.product.trackViewProduct(eventPayload);
    }
  };

  getProducts = () => {
    const {
      products,
      gridValue,
      toggleQuicklook,
      language,
      saveToWishList,
      wishListAndCartBaseDataLoaded,
      translation,
      deleteFromWishList,
      moveToProductDetail,
      currencyCode,
      commonSettings,
      authReducer,
      pageTitle,
      breadcrumb,
      brandPageDetails,
      deleteWishListItemLoaded,
      configSyteSettings,
      wishListGeneralProducts
    } = this.props;
    const isDiscoveryButtonEnabled = configSyteSettings?.enableDiscoveryIcon;

    const productsHits = get(products, "hits");

    return map(productsHits, (item, index) => (
      <Listing
        key={item.id || index}
        gridValue={gridValue}
        language={language}
        getProductUrlName={getProductUrlName}
        item={item}
        _getAvailableSizes={this._getAvailableSizes}
        isPresent={this.isPresent}
        saveToWishList={saveToWishList}
        deleteFromWishList={deleteFromWishList}
        deleteWishListItemLoaded={deleteWishListItemLoaded}
        toggleQuicklook={toggleQuicklook}
        translation={translation}
        wishListBaseData={wishListGeneralProducts}
        wishListBaseDataLoaded={wishListAndCartBaseDataLoaded}
        index={index}
        currencyCode={currencyCode}
        commonSettings={commonSettings}
        moveToProductDetail={moveToProductDetail}
        authReducer={authReducer}
        brandPageDetails={brandPageDetails}
        pageTitle={pageTitle}
        breadcrumb={breadcrumb}
        isDiscoveryButtonEnabled={isDiscoveryButtonEnabled}
        algoliaQueryID={products.queryID}
        algoliaIndex={products.index}
        handleProductSelect={this.handleProductSelect}
      />
    ));
  };

  isPresent = item => {
    const { wishListGeneralProducts } = this.props;
    const isItemPresentInWishList = wishListGeneralProducts?.some(
      el => el.productId === item.id && (item.size || "" === el.size)
    );

    return isItemPresentInWishList;
  };

  handleScrollPositionStoring = prevProps => {
    const { location, products, gridValue } = this.props;
    const { products: prevProducts } = prevProps;
    const gotNewList = !isEqual(products.hits, prevProducts.hits);

    if (!location || products.hits.length <= gridValue || !gotNewList) return;

    const scrollPosition = get(this.props, "location.state.scrollPosition");
    if (!isServer && scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
  };

  render() {
    const { products, language } = this.props;
    const { noOfRecordsPassed } = this.state;
    const isArabicLang = language === "ar";

    return (
      <div className="container">
        {products && products.length && (
          <Headroom disableInlineStyles className="floatingPaginationWrapper">
            <div
              className={cn("floatingPagination", {
                active: noOfRecordsPassed >= 2,
                arabic: isArabicLang
              })}
              onClick={() => handleScrollTop("auto")}
            >
              <span>
                {noOfRecordsPassed}/ {products.hits.length}
              </span>
            </div>
          </Headroom>
        )}
        <ul
          className={cn("product_listing_wrap", {
            arabic: isArabicLang
          })}
          ref={node => (this.listRef = node)}
        >
          {this.getProducts()}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  breadcrumb: reduxState.breadcrumbReducer,
  language: reduxState.common.language,
  wishListAndCartBaseDataLoaded:
    reduxState.cartReducer.wishListAndCartGeneralLoaded,
  deleteWishListItemLoaded: reduxState.cartReducer.deleteWishListItemLoaded,
  currencyCode: get(reduxState, "common.settings.currencyCode", ""),
  commonSettings: reduxState.common.settings,
  authReducer: reduxState.authReducer,
  gridValue: reduxState.productListingReducer.gridValue,
  products: reduxState.productListingReducer.products,
  brandPageDetails: reduxState.productListingReducer.brandPageDetails,
  configSyteSettings: selectSyteSettings(reduxState),
  wishListGeneralProducts: selectWishListGeneralProducts(reduxState)
});

export default withRouter(connect(mapStateToProps, null)(ContentListing));
