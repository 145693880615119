import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import get from "lodash/get";
import { checkVideoURL, isIpadInSafari } from "../../util";
import { Image } from "../image";
import HoverImage from "./hoverImage";
import { appSettings } from "./../../config/app";
import ProductOverlayTag from "../product/product-overlayTag";
import { isServer, isMobile } from "../../util";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import videoThumbImage from "../../images/video_thumb_image.jpg";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import { isTablet } from "react-device-detect";
import {
  selectCdnImageSettings,
  selectCommonSettings
} from "../../util/selectors";
import { DiscountSection } from "../price/price-slab";
import { GAService } from "../../services/GA-service";

class ThumbSwiper extends Component {
  state = {
    nav1: null,
    nav2: null,
    fullScreenImg: false,
    image: "",
    activeVideoSlide: "",
    doubleTap: false,
    photoIndex: 0,
    isOpen: false
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    this.checkFirstVideoURL();
  }

  handleFullScreenImage = (image, idx) =>
    this.setState({
      isOpen: true,
      fullScreenImg: !this.state.fullScreenImg,
      image: image,
      photoIndex: idx,
      activeSlide: idx,
      doubleTap: false
    });

  checkThumbNailUrlType = (item, index, imgUrl) => {
    if (checkVideoURL(item.image)) {
      return <Image src={videoThumbImage} key={index} />;
    }

    return (
      <div>
        <Image
          src={`${imgUrl?.thumb}${item && item.image}`}
          lazyLoad={true}
          key={index}
        />
      </div>
    );
  };

  checkMobileContentUrlType = (item, idx, imgUrl) => {
    if (!isServer) {
      const screenHeight = window && parseInt(window.innerHeight * 0.7);
      const imageWidthMob = 2 * window.innerWidth;
      const imageHeightMob = parseInt(imageWidthMob * 1.5);
      const isTabletDevice = isIpadInSafari() || isTablet;
      const tabletImageWidth = { width: parseInt(screenHeight * 0.65) };
      const styles = {
        minHeight: screenHeight,
        ...(isTabletDevice && tabletImageWidth)
      };

      if (checkVideoURL(item.image)) {
        return (
          <video
            ref={video => (this[`mobVideoRef${idx}`] = video)}
            width="100%"
            height="100%"
            controls
            loop
            preload="metadata"
            muted
          >
            <source
              src={`${imgUrl?.main}${item && item.image}`}
              type="video/mp4"
            />
          </video>
        );
      }
      return (
        <div
          key={`${idx}`}
          className="item_preview_slide"
          onClick={() => {
            this.handleFullScreenImage(item && item.image, idx);
          }}
        >
          <img
            alt="settings"
            src={`${imgUrl?.main}${item && item.image}`}
            style={styles}
          />
        </div>
      );
    }
  };

  checkContentUrlType = (item, index, imgUrl) => {
    if (checkVideoURL(item.image)) {
      return (
        <video
          width="100%"
          height="100%"
          controls
          loop
          ref={video => (this[`videoRef${index}`] = video)}
        >
          <source
            src={`${imgUrl?.main}${item && item.image}`}
            type="video/mp4"
          />
        </video>
      );
    }
    return (
      <HoverImage
        key={(item && item.image) || index}
        url={imgUrl?.full}
        handleImageMouseMove={this.props.handleImageMouseMove || null}
        handleImageMouseOut={this.props.handleImageMouseOut || null}
        hoverImg={item && item.image}
        forwardRef={this.sliderContainer}
        lazyLoad={true}
        zoomedBlockRef={this.props.zoomedBlockRef}
      />
    );
  };

  checkFirstVideoURL = () => {
    const { imageList } = this.props;
    const _videoIndex =
      imageList && imageList.findIndex(item => checkVideoURL(item.image));
    if (typeof _videoIndex === "number" && _videoIndex !== -1) {
      this.setState({ videoIndex: _videoIndex });
    }
  };

  handleActiveVideoSlide = () => {
    const { videoIndex } = this.state;
    this.mobSlider.slickGoTo(videoIndex);
    this.setState({ activeSlide: videoIndex });
  };

  handleVideo = (currentSlide, nextSlide, children) => {
    const nextVideo = this[`videoRef${nextSlide}`];
    const currentVideo = this[`videoRef${currentSlide}`];
    if (children[nextSlide] && children[nextSlide].type === "video") {
      nextVideo.load();
      nextVideo.play();
    } else if (
      children[currentSlide] &&
      children[currentSlide].type === "video"
    ) {
      currentVideo.pause();
    }
  };

  handleShare = () => {
    navigator
      .share({
        url: window && window.location && window.location.href
      })
      .then(() => console.log("Successful share"))
      .catch(error => console.log("Error sharing", error));
  };

  handleMobVideo = (currentSlide, nextSlide, children) => {
    if (nextSlide === this.state.videoIndex) {
      this.setState({ activeSlide: this.state.videoIndex });
    }
    const nextVideo = this[`mobVideoRef${nextSlide}`];
    if (children[nextSlide] && children[nextSlide].type === "video") {
      // nextVideo.load();
      const a = nextVideo.play();
      a.then(() => {}).catch(() => {});
    } else if (
      children[currentSlide] &&
      children[currentSlide].type === "video"
    ) {
      // currentVideo.pause();
    }
  };

  handleSwipe = () => {
    const { activeSlide, videoIndex } = this.state;
    if (activeSlide === videoIndex) {
      this.setState({ activeSlide: "" });
    }
    this.handleImageInteration();
  };

  UNSAFE_componentWillMount = props => {
    this.clickTimeout = null;
  };

  handleClicks = () => {
    if (this.clickTimeout !== null) {
      this.setState({ doubleTap: !this.state.doubleTap });
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
    } else {
      this.clickTimeout = setTimeout(() => {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      }, 400);
    }
  };

  lightboxOnCloseRequest = () => {
    const { photoIndex } = this.state;
    this.mobSlider.slickGoTo(photoIndex, true);
    this.setState({ isOpen: false });
  };

  lightboxOnMovePrevRequest = () => {
    const { photoIndex } = this.state;
    const isFirstImage = photoIndex === 0 && this.props.imageList.length > 1;
    this.setState({
      photoIndex: !isFirstImage
        ? photoIndex >= 0
          ? photoIndex - 1
          : photoIndex
        : this.props.imageList.length - 1
    });
  };

  lightboxOnMoveNextRequest = () => {
    const { imageList } = this.props;
    const isLastImage =
      imageList.length - 1 === this.state.photoIndex &&
      this.props.imageList.length > 1;

    this.setState(prevState => ({
      photoIndex: !isLastImage
        ? imageList.length > prevState.photoIndex
          ? prevState.photoIndex + 1
          : prevState.photoIndex
        : 0
    }));
  };

  handleSocialShareClick = evt => {
    const { product } = this.props;
    let element = evt.target;
    while (element) {
      if (element.hasAttribute("data-share")) {
        const sharedBy = element.getAttribute("data-share");
        GAService.product.trackShareProduct({
          share_type: sharedBy,
          product_sku: get(product, "id", "")
        });
        break;
      }
      element = element.parentElement;
    }
  };

  handleImageInteration = () => {
    const { product } = this.props;
    GAService.product.trackProductImagesInteraction({
      product_sku: get(product, "id", "")
    });
  };

  render() {
    const {
      imageList,
      item,
      shareButton,
      translation,
      isQuickLookModal = false,
      language,
      configCdnImagesSettings,
      commonSettings,
      product
    } = this.props;
    const { activeSlide, videoIndex } = this.state;
    const { photoIndex, isOpen } = this.state;
    const thumbLen = isQuickLookModal ? 5 : 6;
    const lightboxMainSrc = `${configCdnImagesSettings?.main}${imageList[photoIndex].image}`;
    const isLastImage =
      imageList.length - 1 === photoIndex && imageList.length > 1;
    const lightboxNextSrc = !isLastImage
      ? get(
          imageList,
          `[${
            imageList.length > photoIndex ? photoIndex + 1 : photoIndex
          }].image`,
          ""
        )
      : imageList[0].image;

    const isFirstImage = photoIndex === 0 && imageList.length > 1;
    const lightboxPrevSrc = !isFirstImage
      ? get(
          imageList,
          `[${photoIndex >= 0 ? photoIndex - 1 : photoIndex}].image`,
          ""
        )
      : imageList[imageList.length - 1].image;
    const rtlSliderEnabled =
      language === LANGUAGE.arabic || language === LANGUAGE_ROUTE_KEY.arabic;

    const isMobileView = isMobile.any();
    return (
      <div className="item_detail_preview" id="item-preview">
        {isOpen && (
          <Lightbox
            mainSrc={lightboxMainSrc}
            clickOutsideToClose={false}
            nextSrc={lightboxNextSrc}
            prevSrc={lightboxPrevSrc}
            animationDisabled={true}
            imagePadding={0}
            onCloseRequest={this.lightboxOnCloseRequest}
            onMovePrevRequest={this.lightboxOnMovePrevRequest}
            onMoveNextRequest={this.lightboxOnMoveNextRequest}
          />
        )}

        <div className="item_preview_web">
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={
              imageList && imageList.length > thumbLen
                ? thumbLen
                : imageList.length
            }
            verticalSwiping={true}
            focusOnSelect={true}
            vertical={true}
            infinite={true}
            className={`thumb-side-gallery ${
              imageList && imageList.length > thumbLen ? "thumb_sliding" : ""
            }`}
            centerPadding="5px"
          >
            {imageList &&
              imageList.map((item, index) => (
                <div
                  className="thumb_item"
                  key={index}
                  onClick={this.handleImageInteration}
                >
                  {this.checkThumbNailUrlType(
                    item,
                    index,
                    configCdnImagesSettings
                  )}
                </div>
              ))}
          </Slider>
          <span ref={node => (this.sliderContainer = node)}>
            <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              className="thumb-main-gallery"
              fade={true}
              arrows={false}
              beforeChange={(currentSlide, nextSlide) => {
                this.handleVideo(
                  currentSlide,
                  nextSlide,
                  this.state.nav1.props.children
                );
              }}
            >
              {imageList &&
                imageList.map((item, index) =>
                  this.checkContentUrlType(item, index, configCdnImagesSettings)
                )}
            </Slider>
          </span>
        </div>

        <div className="item_preview_mobile">
          <Slider
            ref={slider => (this.mobSlider = slider)}
            rtl={rtlSliderEnabled}
            dots={true}
            infinite={true}
            speed={300}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            adaptiveHeight={false}
            onSwipe={this.handleSwipe}
            initialSlide={
              rtlSliderEnabled && imageList ? imageList.length - 1 : 0
            }
            beforeChange={(currentSlide, nextSlide) => {
              this.handleMobVideo(
                currentSlide,
                nextSlide,
                this.state.nav1.props.children
              );
            }}
          >
            {imageList &&
              imageList.map((item, idx) =>
                this.checkMobileContentUrlType(
                  item,
                  idx,
                  configCdnImagesSettings
                )
              )}
          </Slider>
          {videoIndex && activeSlide !== videoIndex && (
            <div className="playVideo" onClick={this.handleActiveVideoSlide} />
          )}
        </div>
        <div className="image_discount_block">
          <DiscountSection
            item={product}
            countrySettings={commonSettings}
            translation={translation}
            language={language}
            showOffText={!isMobileView}
            showDiscountPrefix={isMobileView}
          />
        </div>

        <ProductOverlayTag
          overlayLabel={item && item.overlayTagLabel}
          overlayTagBgColor={item && item.overlayTagBgColor}
          overlayTagLabelColor={item && item.overlayTagLabelColor}
        />
        {shareButton && (
          <div className="share_zoom_wrap">
            <div
              className="share_over"
              onClick={
                !isServer && navigator && navigator.share && isMobile.any()
                  ? this.handleShare
                  : null
              }
            >
              Share
              {!(
                !isServer &&
                navigator &&
                navigator.share &&
                isMobile.any()
              ) && (
                <div
                  className="social_share"
                  onClick={this.handleSocialShareClick}
                >
                  <div data-share="facebook">
                    <FacebookShareButton
                      url={!isServer ? window.location.href : ""}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={34} round />
                    </FacebookShareButton>
                  </div>
                  <div data-share="pinterest">
                    <PinterestShareButton
                      url={!isServer ? window.location.href : ""}
                      media={`${appSettings.imageBaseURL}${imageList[0].image}?w=800&h=858&quality=95&mode=crop`}
                      windowWidth={1000}
                      windowHeight={730}
                      className="Demo__some-network__share-button"
                    >
                      <PinterestIcon size={34} round />
                    </PinterestShareButton>
                  </div>
                  <div data-share="twitter">
                    <TwitterShareButton
                      url={!isServer ? window.location.href : ""}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </div>
                </div>
              )}
            </div>
            <span className="zoom_over">{translation.zoomText}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  configCdnImagesSettings: selectCdnImageSettings(state),
  commonSettings: selectCommonSettings(state)
});

export default connect(mapStateToProps)(ThumbSwiper);
