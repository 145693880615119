import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import BrandsSizeGuide from "../../components/size/size-chart-brandGuide";
import GeneralSizeGuide from "../../components/size/size-chart-generalGuide";
import Page from "../../components/page";
import SizeChartNavItem from "../../components/size/size-chart-nav";
import { changeLanguage } from "../../redux/actions/common.action";
import { fetchSizeChartData } from "../../redux/actions/sizeChart.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { seoTranslation } from "../../language/seo/en/seo";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { sizeChartTranslation } from "../../language/sizeChart/en/sizeChart";
import ssrConfig from "./ssrConfig";
import { GAService } from "../../services/GA-service";

class SizeChart extends Component {
  constructor(props) {
    super(props);
    const urlLang = get(props, "match.params.language", "").split("-")[0];
    this.state = {
      _brandSizeList: null,
      _genrealSizeList: null,
      _navItemArray: [],
      activeTab: "",
      brandList: [],
      generalList: [],
      isPopupActive: false,
      measurement: "centimeter",
      metaInfo: {},
      popupTitle: "",
      searchTxt: "",
      seoTitle: "",
      seoTranslated: seoTranslation[urlLang],
      sizeChartTranslation
    };
  }

  componentDidMount() {
    const { seoTranslated } = this.state;
    const { fetchSizeChartData, fullLanguage, language, meta } = this.props;
    fetchSizeChartData(fullLanguage);
    this.getLanguage(language);
    let seoTitle = "";
    let metaInfo = {};
    if (meta && meta[fullLanguage]) {
      seoTitle = `${meta[fullLanguage].title.trim()} | ${seoTranslated.bfl}`;
      metaInfo = language
        ? {
            [language]: {
              ...meta[fullLanguage],
              metaDescription: meta[fullLanguage].metaDescription.trim()
            }
          }
        : {};
    }
    this.setState({
      seoTitle,
      metaInfo
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeTab, seoTranslated } = this.state;
    const { fetchSizeChartData, fullLanguage, language, meta, sizeChart } =
      nextProps;
    if (
      sizeChart &&
      sizeChart.brandSizeChart &&
      activeTab !== Object.keys(sizeChart.brandSizeChart)[0]
    ) {
      const _navItemArray = Object.keys(sizeChart.brandSizeChart);
      this.setState({ _navItemArray, activeTab: _navItemArray[0] });
    }
    if (this.props.language !== nextProps.language) {
      this.getLanguage(nextProps.language);
      fetchSizeChartData(nextProps.fullLanguage);
      let nextSeoTitle = "";
      let nextMetaInfo = {};
      if (meta && meta[fullLanguage]) {
        nextSeoTitle = `${meta[fullLanguage].title.trim()} | ${
          seoTranslated.bfl
        }`;
        nextMetaInfo = language
          ? {
              [language]: {
                ...meta[fullLanguage],
                metaDescription: meta[fullLanguage].metaDescription.trim()
              }
            }
          : {};
      }
      this.setState({
        seoTitle: nextSeoTitle,
        metaInfo: nextMetaInfo
      });
    }
  }

  handlePopupActive = value => {
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
    this.setState({
      isPopupActive: value,
      _brandSizeList: value ? this.state._brandSizeList : null,
      _genrealSizeList: value ? this.state._genrealSizeList : null
    });
  };

  handleActiveTab = item => {
    this.setState({
      activeTab: item.toLowerCase(),
      searchTxt: ""
    });
  };

  handleSizeChart = (id, name, guideType, genHeading) => {
    const {
      sizeChart: { brandSizeChart, genericSizeChart }
    } = this.props;
    const { activeTab } = this.state;

    if (guideType === "brandSize") {
      const _brandArray =
        !!brandSizeChart &&
        !!brandSizeChart[`${activeTab}`].brandList.length &&
        brandSizeChart[`${activeTab}`].brandList.filter(
          item => item.brandId === id
        );
      !!_brandArray &&
        this.setState({
          _brandSizeList: _brandArray,
          popupTitle: !!brandSizeChart && brandSizeChart[`${activeTab}`].heading
        });
    }

    if (guideType === "generalSize") {
      const ActiveKey =
        !!brandSizeChart &&
        !!genericSizeChart[`${activeTab}`] &&
        Object.keys(genericSizeChart[`${activeTab}`]).filter(
          item => genericSizeChart[`${activeTab}`][item].name === name
        );
      const _brandArray2 =
        !!ActiveKey.length &&
        genericSizeChart[`${activeTab}`][ActiveKey[0]].list[id];
      !!_brandArray2 &&
        this.setState({
          _genrealSizeList: _brandArray2,
          popupTitle: !!brandSizeChart && genHeading
        });
    }
  };

  setSearchText = searchTxt => this.setState({ searchTxt });

  handlemeasurement = measureUnit => {
    this.setState({
      measurement: measureUnit
    });
  };

  getLanguage = language => {
    if (language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    Promise.all([
      import(`../../language/seo/${language}/seo`),
      import(`../../language/sizeChart/${language}/sizeChart`)
    ]).then(responses => {
      const [languageResponse, sizeChartResponse] = responses;
      this.setState({
        seoTranslated: languageResponse.seoTranslation,
        sizeChartTranslation: sizeChartResponse.sizeChartTranslation
      });
    });
  };

  handleListItemClick = data => {
    GAService.common.trackSizeChart(data);
  };

  render() {
    const {
      language,
      fullLanguage,
      sizeChart: { general, brandSizeChart, genericSizeChart }
    } = this.props;
    const {
      activeTab,
      _genrealSizeList,
      _brandSizeList,
      isPopupActive,
      popupTitle,
      measurement,
      searchTxt,
      sizeChartTranslation,
      seoTitle,
      metaInfo
    } = this.state;

    const catListNav = {
      men: sizeChartTranslation.men,
      women: sizeChartTranslation.women,
      kids: sizeChartTranslation.kids
    };

    return (
      <Page
        title={seoTitle}
        meta={metaInfo}
        language={fullLanguage}
        id="size-chart"
      >
        <div
          className={`sizeChart_wrapper ${language === "ar" ? "arabic" : ""}`}
        >
          <div className="sizeChart_heading">
            <h1 className="title">
              {!!general &&
                general[`${fullLanguage}`] &&
                general[`${fullLanguage}`].heading}
            </h1>
            <p className="small_title_text">
              <span>
                {!!general &&
                  general[`${fullLanguage}`] &&
                  general[`${fullLanguage}`].subHeading}
              </span>
            </p>
          </div>
          <nav className="sizeChart_tabs">
            <ul>
              {Object.keys(catListNav).map((item, index) => (
                <SizeChartNavItem
                  key={index}
                  item={catListNav[item]}
                  index={index}
                  activeTab={activeTab}
                  keyName={item}
                  handleActiveTab={this.handleActiveTab}
                />
              ))}
            </ul>
          </nav>
          <BrandsSizeGuide
            activeTab={activeTab}
            sizeChart={brandSizeChart}
            handlePopup={this.handlePopupActive}
            onListItemClick={this.handleListItemClick}
            handleSizeChart={this.handleSizeChart}
            guideType={"brandSize"}
            isPopupActive={isPopupActive}
            popupTitle={popupTitle}
            PopUpHtmlList={_brandSizeList}
            setSearchText={this.setSearchText}
            searchTxt={searchTxt}
            measurement={measurement}
            handlemeasurement={this.handlemeasurement}
            translation={sizeChartTranslation}
          />
          <GeneralSizeGuide
            activeTab={activeTab}
            handlePopup={this.handlePopupActive}
            onListItemClick={this.handleListItemClick}
            genericSizeChart={genericSizeChart}
            handleSizeChart={this.handleSizeChart}
            guideType={"generalSize"}
            isPopupActive={isPopupActive}
            sizeChart={genericSizeChart}
            popupTitle={popupTitle}
            PopUpHtmlList={_genrealSizeList}
            measurement={measurement}
            handlemeasurement={this.handlemeasurement}
            setSearchText={this.setSearchText}
            searchTxt={searchTxt}
          />
        </div>
      </Page>
    );
  }
}

SizeChart.need = ssrConfig;

const mapStateToProps = state => ({
  language: state.common.language,
  sizeChart: state.sizeChartReducer,
  fullLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english
});

const mapDispatchToProps = dispatch => ({
  fetchSizeChartData: bindActionCreators(fetchSizeChartData, dispatch),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch)
});

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(SizeChart);
