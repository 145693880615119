import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";

import { getDividedNumber } from "../../util/phoneNumber";
import { MEMBERSHIP_STYLE_CLASS } from "../../constants/loyalty-points";

import { OtpService } from "../../services";

import { verifyOtp } from "../../redux/actions/order.action";
import { fetchUserAndLoadData } from "../../redux/actions/authConfig.action";
import ModalBFL from "../modal/modal";
import MobileVerification from "../modal/modal-mobile-verification";
import { selectShowLoader } from "../../util/selectors";
import {
  hideLoadingIndicator,
  showLoadingIndicator
} from "../../redux/actions/common.action";
import yellowStarImage from "../../routes/my-account/images/yellow-star.svg";
import blackStarImage from "../../routes/my-account/images/black-star.svg";
import verifiedImage from "../../routes/my-account/images/verified.svg";

class DetailOverview extends React.Component {
  state = {
    toggleModal: false,
    valid: true,
    touched: false,
    PhoneOTP: "",
    otpValid: true,
    otpVerified: false,
    countryCode: "",
    showVerifySection: false,
    otp: "",
    areaCode: "",
    _phone: "",
    sendOtpCount: 0,
    slicePhoneLength: 0,
    userOriginCountry: [],
    phoneNumber: 0,
    localCode: 0,
    errorMessage: null
  };

  UNSAFE_componentWillMount() {
    const { _phone } = this.state;

    this.setState({ PhoneOTP: _phone });
  }

  UNSAFE_componentWillReceiveProps() {
    const { userOriginCountry, mobileCountryCode } = this.props;
    const { _phone } = this.state;

    this.setState({
      PhoneOTP: _phone,
      countryCode: mobileCountryCode,
      userOriginCountry
    });
  }

  componentDidMount() {
    const { _phone } = this.state;
    const { userOriginCountryData } = this.props;

    this.setState({
      PhoneOTP: _phone,
      countryCode:
        userOriginCountryData && userOriginCountryData.mobileCountryCode,
      userOriginCountry: userOriginCountryData
    });
  }

  handleCountryChange = (status, value) => {
    this.setState({ phoneNumber: value, valid: status, touched: true });
  };

  onSelectArea = (areaCode, phone) => {
    this.setState({ localCode: areaCode, phoneNumber: phone });
  };

  sendOtp = async () => {
    const { sendOtpCount } = this.state;
    const { showLoadingIndicator, hideLoadingIndicator } = this.props;

    this.setState({
      otpValid: true,
      sendOtpCount: sendOtpCount + 1,
      otp: "",
      errorMessage: null
    });

    try {
      showLoadingIndicator();
      await OtpService.sendOtp({ phone: this.getFullMobileNumber() });

      this.setState({ showVerifySection: true, otp: "" });
    } catch (error) {
      this.setState({
        errorMessage: error.response?.data?.message || error.message
      });
    } finally {
      hideLoadingIndicator();
    }
  };

  getFullMobileNumber = () => {
    const { mobileCountryCode } = this.props;
    const { localCode, phoneNumber } = this.state;

    return `${mobileCountryCode}${localCode}${phoneNumber}`;
  };

  verifyOtp = () => {
    const { verifyOtp } = this.props;
    const { otp } = this.state;
    verifyOtp({ code: otp, phone: this.getFullMobileNumber() }).then(resp => {
      (get(resp, "data.success") &&
        this.setState({ otpVerified: true, showVerifySection: false })) ||
        this.setState({ otpValid: false });
    });
  };

  handleOTPChange = otp => {
    this.setState({ otp });
  };

  handleToggleModal = () => {
    this.getPhoneNumberDetails();
    const { fetchUserAndLoadData } = this.props;
    const { otpVerified } = this.state;

    this.setState({
      toggleModal: !this.state.toggleModal,
      otpValid: true,
      sendOtpCount: 0,
      showVerifySection: false
    });

    if (otpVerified) {
      fetchUserAndLoadData();
    }
  };

  _getAreaCodes = (countryData, defaultCountry) => {
    const countryInfo =
      countryData &&
      countryData.find(country => country.countrySHORT === defaultCountry);
    return (
      countryInfo &&
      countryInfo.mobileLocalCode &&
      countryInfo.mobileLocalCode.split(",")
    );
  };

  getPhoneNumberDetails = () => {
    const { userInfo, userOriginCountryData } = this.props;
    const userPhone = get(userInfo, "phone", "");
    const phoneDetails = {
      phone: userPhone,
      mobileCountryCode: userOriginCountryData.mobileCountryCode,
      mobileLocalCode: userOriginCountryData.mobileLocalCode,
      mobileLocalNumberLength: userOriginCountryData.mobileLocalNumberLength
    };
    const { areaCode } = getDividedNumber(phoneDetails);
    const localCodeLength = (areaCode && areaCode.length) || 0;
    const countryCodeLength = get(
      userOriginCountryData,
      "mobileCountryCode",
      ""
    ).toString().length;
    const phoneCodesLength = localCodeLength + countryCodeLength;
    const localCode = userPhone
      ? userPhone.substring(countryCodeLength).slice(0, localCodeLength)
      : "";
    const phoneNumber = userPhone ? userPhone.substring(phoneCodesLength) : "";
    this.setState({
      phoneNumber,
      localCode
    });
  };

  getLoyaltyPointsStar = () => {
    const { loyaltyDetails } = this.props;

    switch (MEMBERSHIP_STYLE_CLASS[loyaltyDetails.memeberLevel]) {
      case "yellow":
        return yellowStarImage;
      case "black":
        return blackStarImage;

      default:
        return yellowStarImage;
    }
  };

  render() {
    const {
      userInfo,
      myAccountTranslation,
      orderSuccessTranslation,
      language,
      countryData,
      userOriginCountryData,
      loyaltyDetails,
      showLoader
    } = this.props;
    const {
      toggleModal,
      valid,
      touched,
      otpValid,
      otpVerified,
      showVerifySection,
      otp,
      sendOtpCount,
      phoneNumber,
      localCode,
      errorMessage
    } = this.state;
    const { mobileCountryCode, mobileLocalCode, mobileLocalNumberLength } =
      userOriginCountryData;
    const { phoneWithoutCountryCode } = getDividedNumber({
      phone: userInfo.phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    });

    return (
      <div>
        <div className="detail_overview">
          <div className="profile">
            <p>
              {userInfo.firstname &&
                `${userInfo.firstname.toLowerCase().charAt(0)}`}
              {userInfo.lastname &&
                `${userInfo.lastname.toLowerCase().charAt(0)}`}
            </p>
          </div>

          <div className="profile_detail">
            <h5>
              {userInfo.firstname && userInfo.firstname}{" "}
              {userInfo.lastname && userInfo.lastname}
              {loyaltyDetails && (
                <img
                  src={this.getLoyaltyPointsStar()}
                  alt="loyalty-star-icon"
                />
              )}
            </h5>

            <p>{userInfo.email}</p>
            {userInfo.phone && (
              <p className="mobile_number">
                <span>
                  +{mobileCountryCode} {phoneWithoutCountryCode}
                </span>
                <span
                  className="verify"
                  onClick={
                    !userInfo.isMobileVerified
                      ? this.handleToggleModal
                      : undefined
                  }
                >
                  {userInfo && userInfo.isMobileVerified ? (
                    <img src={verifiedImage} alt="verified" />
                  ) : (
                    myAccountTranslation && `[${myAccountTranslation.verify}]`
                  )}
                </span>
              </p>
            )}
          </div>
        </div>
        {userOriginCountryData && (
          <ModalBFL
            showModal={toggleModal}
            modalClass={cn(
              "generic_modal",
              "mobile_verification_modal",
              "wide_generic_modal",
              { arabic: language.includes("ar") }
            )}
          >
            <button className="cross_btn" onClick={this.handleToggleModal}>
              &times;
            </button>

            <MobileVerification
              readonly={true}
              orderSuccessTranslation={orderSuccessTranslation}
              valid={valid}
              touched={touched}
              otpValid={otpValid}
              otpVerified={otpVerified}
              phone={phoneNumber}
              countryCode={userOriginCountryData.mobileCountryCode}
              handleCountryChange={this.handleCountryChange}
              onSelectArea={this.onSelectArea}
              sendOtp={this.sendOtp}
              showVerifySection={showVerifySection}
              otp={otp}
              handleOTPChange={this.handleOTPChange}
              verifyOtp={this.verifyOtp}
              areaCode={localCode}
              countryData={countryData}
              mobileLocalNumberLength={
                userOriginCountryData.mobileLocalNumberLength
              }
              countrySHORT={userOriginCountryData.countrySHORT}
              sendOtpCount={sendOtpCount}
              errorMessage={errorMessage}
              showLoader={showLoader}
            />
          </ModalBFL>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  countryData: state.common.countryData,
  userOriginCountryData: state.common.userOriginCountryData,
  mobileLocalCode:
    state.common.settings && state.common.settings.mobileLocalCode,
  mobileLocalNumberLength:
    state.common.settings && state.common.settings.mobileLocalNumberLength,
  userInfo: state.authReducer,
  loyaltyDetails: get(
    state,
    "myAccountReducer.loyaltyPoints.LoyaltyStatics.loyaltyDetails",
    {}
  ),
  showLoader: selectShowLoader(state)
});

const mapDispatchToProps = {
  verifyOtp,
  fetchUserAndLoadData,
  showLoadingIndicator,
  hideLoadingIndicator
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailOverview);
