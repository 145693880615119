import React from "react";
import { withRouter } from "react-router-dom";

const overviewHeading = ({ handleGoBack, icon, headingName }) => {
  return (
    <div className="overview-heading">
      <h1>
        <span className="back_btn" onClick={handleGoBack} />
        <span className="icon-wrapper">
          <i className={`heading-icon ${icon}`} />
        </span>
        {headingName}
      </h1>
    </div>
  );
};

export default withRouter(overviewHeading);
