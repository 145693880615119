import React from "react";
import cn from "classnames";
import { getModalHeader } from "./getModalHeader";
import {
  QUICK_FILTER_SHOES,
  FOOTWEAR
} from "../../../../constants/quickFilter";
import { includesSubString } from "../../../../util";
import AnalyticService from "../../../../services/analytic-service";

export const ModalHeader = ({
  activeTranslation,
  filterStep,
  filterStepPath,
  joinedPath,
  maxStep
}) => {
  const getHeaderContent = () => {
    const modalHeader = getModalHeader(activeTranslation);
    let Component = modalHeader.default;
    if (filterStep) {
      const mainFlow = AnalyticService.quickFilter.getQuickFilterFlow(
        filterStepPath
      );
      const itemTypesStep = maxStep - 2;
      const mapStep =
        itemTypesStep < filterStep - 1 ? itemTypesStep : filterStep - 1;
      const subFlow =
        filterStepPath.length > itemTypesStep &&
        (includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
        includesSubString(joinedPath, FOOTWEAR)
          ? "shoes"
          : "clothing");
      const subFlowStep = filterStep - (itemTypesStep + 1);
      Component = subFlow
        ? modalHeader[mainFlow][mapStep][subFlow][subFlowStep]
        : modalHeader[mainFlow][mapStep];
    }
    return <Component />;
  };

  const isHeaderMobileMargin = filterStep >= maxStep - 1;

  return (
    <div
      className={cn("quick-modal-header-content", {
        headerMobileMargin: isHeaderMobileMargin
      })}
    >
      {getHeaderContent()}
    </div>
  );
};
