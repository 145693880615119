import React from "react";
import find from "lodash/find";
import { useSelector } from "react-redux";
import { DYNAMIC_MODULE_NAMES, LANGUAGE } from "../../../constants";
import { StorylyBanner } from "../../storyly";
import { LisaBanner } from "../../lisa";
import QuickFilters from "../../quick-filter";
import { isMobile } from "../../../util";
import { LiveFloatingBanner } from "../../live-floating-button";
import NewsLetterWrapper from "../../news-letter-wrapper";
import DynamicGamification from "../dynamic-gamefication";
import { SaleWrapper } from "../dynamic-sale";
import { selectLanguage } from "../../../util/selectors";

const DynamicModule = ({ content }) => {
  const { enable, custom, ...otherData } = content;
  const language = useSelector(selectLanguage);

  const isNotAvailable =
    isMobile.any() && !content?.enabledOn?.includes("resp");
  if (!enable || !custom || isNotAvailable) return null;

  const moduleName = (find(custom, { key: "moduleName" }) || {}).value;

  switch (moduleName) {
    case DYNAMIC_MODULE_NAMES.STORYLY:
      return <StorylyBanner bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.QUICK_FILTER:
      if (language === LANGUAGE.arabic) return null;
      return <QuickFilters bannerData={custom} />;
    case DYNAMIC_MODULE_NAMES.LISA:
      return <LisaBanner bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.LIVE_FLOATING_BUTTON:
      return <LiveFloatingBanner bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.NEWS_LETTER:
      return <NewsLetterWrapper bannerData={otherData} />;
    case DYNAMIC_MODULE_NAMES.BRAME_GAMIFICATION:
      return (
        <DynamicGamification bannerData={custom} generalData={otherData} />
      );
    case DYNAMIC_MODULE_NAMES.FLASH_SALE:
      return <SaleWrapper bannerData={custom} generalData={otherData} />;
    default:
      return null;
  }
};

export default DynamicModule;
