import React, { useRef, useState, useEffect } from "react";
import get from "lodash/get";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSubscribeEmail,
  resetNewsletterSubscriptionData
} from "../../redux/actions/footer.action";
import ThanksForSubscribingModal from "../modal/thank-for-subscribing";
import NewsLetterForm from "./news-letter-form";

const FooterNewsletter = props => {
  const formikFormRef = useRef();
  const { footerTranslation, isBanner } = props;
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const userLoggedIn = useSelector(state => state.authReducer.userLoggedIn);
  const settings = useSelector(state => state.common.settings);
  const countryId = get(settings, "countryId", "");
  const dispatch = useDispatch();
  const letKeepInTouch = get(footerTranslation, "letKeepInTouch", "");
  const signInToNewsLetter = get(footerTranslation, "signInToNewsLetter", "");

  const thankYouSubscribingMessage = get(
    footerTranslation,
    "thankYouSubscribingMessage",
    ""
  );
  const thankYouForSubs = get(footerTranslation, "thankYouForSubs", "");

  const onSubscribe = ({ email }) => {
    const bodyData = { email, countryId };
    dispatch(fetchSubscribeEmail(bodyData));
    formikFormRef.current.resetForm();
    setModalOpen(true);
  };

  const handleModal = () => setShowThankYouModal(!showThankYouModal);

  useEffect(() => {
    if (modalOpen) {
      handleModal();
    }
    return () => {
      dispatch(resetNewsletterSubscriptionData);
    };
  }, [modalOpen]);

  return (
    <>
      {showThankYouModal && (
        <ThanksForSubscribingModal
          openSubsModal={showThankYouModal}
          closeModal={handleModal}
          message={thankYouSubscribingMessage}
          messageHeading={thankYouForSubs}
        />
      )}
      <section className={cn("newsletter-col", { signedIn: !userLoggedIn })}>
        {(!userLoggedIn || isBanner) && (
          <>
            {!isBanner && (
              <>
                <p className="heading">{letKeepInTouch}</p>
                <p>{signInToNewsLetter}</p>
              </>
            )}

            <NewsLetterForm
              onSubmit={onSubscribe}
              translation={footerTranslation}
              formikFormRef={formikFormRef}
              isBanner={isBanner}
            />
          </>
        )}
      </section>
    </>
  );
};

export default FooterNewsletter;
