export const productListing = {
  items: "Items",
  item: "Item",
  view: "View",
  store: "Shop By Store",
  brandName: "Brand",
  catname: "Category",
  IsNew: "New Arrival",
  Promotion: "Promotion",
  "hierarchicalCategories.lvl0": "Category",
  "hierarchicalCategories.lvl1": "Category",
  "hierarchicalCategories.lvl2": "Category",
  "categories-0": "Category",
  "categories-1": "Category",
  "categories-2": "Category",
  "sizeHierarchicalFilter.lvl2": "Size",
  subcatname: "Type",
  priceBy: "Price",
  "attributes.Fabrics": "Fabrics",
  "attributes.Occasion": "Occasion",
  "attributes.Ideal For": "Ideal For",
  "attributes.Materials": "Materials",
  "attributes.Pattern & Print": "Pattern & Print",
  "attributes.Color Family": "Color",
  "attributes.Types of Sleeve": "Types of Sleeve",
  "attributes.Toys Age Group": "Shop By Age",
  sortBy: "Sort By",
  filterTitle: "Filter",
  clearAll: "Clear",
  back: "Back",
  clearALL: "CLEAR ALL",
  buttonTitle: "Show Results",
  show: "Show",
  results: "Results",
  promotion: "Promotion",
  viewing: "viewing",
  outOf: "out of",
  page: "Page",
  size: "Size",
  quantity: "Qty.",
  addToCart: "Add To Cart",
  addedToCart: "Added To Cart",
  selectSize: "Select Size",
  off: "off",
  rrp: "RRP",
  rrp_save: "Save",
  wishlistEmpty: "Wishlist is Empty!",
  wishListEmptyMessage:
    "You have no items in your wishlist. Start adding new items in your wishlist and checkout at once.",
  continueShopping: "Continue Shopping",
  linkToProductDetail: "See Product Details",
  myWishlist: "My Wishlist",
  oneSize: " Onesize",
  qtyErrText: "Quantity must be less than or equal to",
  outOfStock: "Out of Stock",
  range: "Range",
  clear: "Clear",
  resetFilter: "Reset Filter",
  chooseCategory: "Choose Category",
  save: "Save",
  sort: "Sort",
  highToLow: "Price high to low",
  lowToHigh: "Price low to high",
  relevance: "Relevance",
  quickLook: "Quicklook",
  sizeSelectionToProceed: "Select a size to proceed",
  IncOfVat: "Incl. of VAT",
  ShowAllSizes: "Show all Sizes",
  ShowLessSizes: "Show less Sizes",
  left: "left",
  searchResultsFor: "Search results for",
  searchBrandsPlaceholder: "Search Brands",
  addToBag: "ADD TO BAG",
  remove: "Remove",
  piecesLeft: "pieces left",
  soldOut: "Sold Out",
  sortItem: "Sort",
  apply: "Apply",
  in: "in",
  viewAll: "View All",
  youAlreadyHaveAddedTheAllowedMaxQuantity:
    "You already have the allowed max qty ",
  forThisItem: "for this item",
  noRecordFound: "No Records Found !",
  youCanAddMaxOf: "You can add max",
  youAlreadyAdded: "You already added maximum qty",
  availableForThisItem: "available for this item!",
  itemsMoreInsteadOf: "items more instead of",
  youAlreadyHad: "You already had",
  qtyItemInCart: "qty of this item in your cart",
  alreadyAdded: "You already have",
  theAvailableStock: "The available stock is",
  moreQty: "more qty!",
  docTitleForBrandsPage:
    "{BrandName} Products in Low Prices! Shop Online | Brands For Less",
  viewSimilar: "View Similar",
  notAvailableIn: "This product is not available in your country",
  retailPrice: "Retail Price",
  pieceLeft: "piece left"
};
