import React, { Fragment } from "react";
import Countdown from "react-countdown";
import ReactDOM from "react-dom";
import split from "lodash/split";

class CustomCountdown extends React.Component {
  renderer = (
    { completed, formatted },
    type,
    children,
    content,
    textStyles
  ) => {
    if (completed) {
      return null;
    }

    const { days, hours, minutes, seconds } = formatted;

    const renderSection = (number, name, type) => {
      const style = {
        backgroundColor: content.backgroundColor
      };
      return (
        <div className="bfl-countdown_section" {...(type === 1 && { style })}>
          {split(number, "").map((numString, index) => (
            <span
              key={`${name}_${index}`}
              className="bfl-countdown_box"
              style={{ ...(type !== 1 && style), color: content.color }}
            >
              {numString}
            </span>
          ))}
          {name && (
            <span
              className="bfl-countdown_text"
              style={{ ...(textStyles && textStyles) }}
            >
              {name === "days" ? `day${Number(number) === 1 ? "" : "s"}` : name}
            </span>
          )}
        </div>
      );
    };

    const getRenderSections = type => {
      const dots = (
        <span className="colon-dots" style={{ color: content.backgroundColor }}>
          :
        </span>
      );
      return (
        <>
          {days !== "00" && (
            <>
              {renderSection(days, "days", type)} {dots}
            </>
          )}
          {renderSection(hours, "hours", type)} {dots}
          {renderSection(minutes, "minutes", type)} {dots}
          {renderSection(seconds, "seconds", type)}
        </>
      );
    };

    switch (type) {
      case 1:
        return (
          <Fragment>
            {children}
            <div className="bfl-countdown_wrapper-1">
              {getRenderSections(1)}
            </div>
          </Fragment>
        );

      case 2:
      default:
        return (
          <Fragment>
            {children}
            <div className="bfl-countdown_wrapper-2">
              {getRenderSections(2)}
            </div>
          </Fragment>
        );
    }
  };

  render() {
    const { date, type, children, onCountdownCopmlete, content, textStyles } =
      this.props;
    return (
      <Countdown
        date={date}
        renderer={props =>
          this.renderer(props, type, children, content, textStyles)
        }
        onComplete={onCountdownCopmlete}
      />
    );
  }
}

if (typeof window !== "undefined") {
  ReactDOM.render(<CustomCountdown />, document.getElementById("root"));
}

export default CustomCountdown;
