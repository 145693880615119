import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import find from "lodash/find";
import cn from "classnames";
import Swiper from "react-id-swiper";
import {
  GRID_BY_SCREEN,
  LANGUAGE_ROUTE_KEY,
  MAIN_TAB_INDEX,
  SALE_SREEN
} from "../../../constants";
import {
  selectCdnImageSettings,
  selectCommonSettings,
  selectLanguage,
  selectLanguageSlug,
  selectRouteSlug
} from "../../../util/selectors";
import SaleTabs from "./SaleTabs";
import { SaleService } from "../../../services";
import Pagination from "../../page/pagination";
import { SaleComingSoon } from "./SaleComingSoon";
import BreadcrumbList from "../../breadcrumbs-list";
import { isMobile } from "../../../util";
import { filterSelectedFilters } from "./utils";
import { swiperParams } from "./constants";

export const SaleWrapper = ({ bannerData }) => {
  const history = useHistory();
  const [saleData, setSaleData] = useState(null);
  const [tabsData, setTabsData] = useState([]);
  const [heading, setHeading] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const [productTranslation, setProductTranslation] = useState(null);
  const routeSlug = useSelector(selectRouteSlug);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [gridValue, setGridValue] = useState(0);

  const pageContentValue = (find(bannerData, { key: "pageContent" }) || {})
    .value;

  const configCdnImagesSettings = useSelector(selectCdnImageSettings);
  const language = useSelector(selectLanguage);
  const shortLang = useSelector(selectLanguageSlug);
  const commonSettings = useSelector(selectCommonSettings);
  const countrySHORT = commonSettings?.countrySHORT?.toLowerCase() || "ae";
  const callbackSelectedFiltersRef = useRef(null);
  const tabsDataRef = useRef(null);
  const productTranslationRef = useRef(null);

  callbackSelectedFiltersRef.current = selectedFilters;
  tabsDataRef.current = tabsData;
  productTranslationRef.current = productTranslation;

  const DEFAULT_ITEM_INDEX = 0;

  useEffect(() => {
    setGridValueForProducts();

    (async () => {
      if (gridValue) {
        const translation = await loadTranslation();
        const data = await setDefaultSaleData();
        setIsFetching(false);
        setTabsDetails(data);
        setBreadcrumbs(getInitialBreadcrumbs(translation));
      }
    })();
  }, [gridValue]);

  const setGridValueForProducts = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= SALE_SREEN.LARGE_WIDTH) {
      setGridValue(GRID_BY_SCREEN.LARGE);
    } else if (windowWidth >= SALE_SREEN.MEDIUM_WIDTH) {
      setGridValue(GRID_BY_SCREEN.MEDIUM);
    } else if (windowWidth > SALE_SREEN.SMALL_WIDTH) {
      setGridValue(GRID_BY_SCREEN.SMALL);
    } else {
      setGridValue(GRID_BY_SCREEN.X_SMALL);
    }
  };

  const loadTranslation = async () => {
    const [listing, details, breadcrumbs] = await Promise.all([
      import(`../../../language/productListing/${shortLang}/productListing`),
      import(`../../../language/productDetail/${shortLang}/productDetail`),
      import(`../../../language/breadcrumb/${shortLang}/breadcrumb`)
    ]);
    const productTranslation = {
      ...listing.productListing,
      ...details.productDetail,
      ...breadcrumbs.breadcrumbTranslation
    };
    setProductTranslation(productTranslation);
    return productTranslation;
  };

  const setDefaultSaleData = async () => {
    const data = await SaleService.getDefaultPageData(
      pageContentValue,
      gridValue
    );
    const defaultData = data.sales[0]?.products;
    setSaleData(defaultData);
    setHeading(data.name);

    return data;
  };

  const onTabChange = async tabId => {
    const tabsData = tabsDataRef.current;
    const availableProductsForTab = tabsData[tabId - 1].productsCount;

    if (!availableProductsForTab) {
      setSaleData({ hits: [] });
      setCurrentTab(tabId);
      return;
    }

    if (tabId === MAIN_TAB_INDEX) {
      setDefaultSaleData();
    }
    if (tabsData[tabId - 1]) {
      const data = await SaleService.getSaleSpecificTabData(
        tabsData[tabId - 1].slug,
        pageContentValue,
        { gridValue }
      );
      setSaleData(data);
    }

    setCurrentTab(tabId);
    setBreadcrumbs(getInitialBreadcrumbs(productTranslationRef.current, tabId));
  };

  const onFilterClick = async ({ slug, queries }) => {
    const data = await SaleService.getSaleSpecificTabData(
      slug,
      pageContentValue,
      { ...queries, page: undefined, gridValue }
    );
    addBreadCrumb({ slug, queries });
    setSaleData(data);
  };

  const handlePaginationClick = async e => {
    const tabDetails = tabsData[currentTab - 1];

    const updatedSelectedFilters = {
      ...selectedFilters,
      page: e.selected,
      gridValue
    };

    window.scrollTo(0, 0);

    setSelectedFilters(updatedSelectedFilters);

    const data = await SaleService.getSaleSpecificTabData(
      tabDetails.slug,
      pageContentValue,
      updatedSelectedFilters
    );
    setSaleData(data);
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    setDefaultSaleData();
    setCurrentTab(1);
  };

  const handleTabClick = e => {
    setSelectedFilters({});
    onTabChange(+e.currentTarget.id);
  };

  const setTabsDetails = data => {
    const shortLang = LANGUAGE_ROUTE_KEY[language];
    const sales = data.sales;
    const tabsData = sales.map((sale, index) => {
      const tabTitle = `${shortLang}_title`;

      return {
        title: sale[tabTitle],
        from: sale.from,
        to: sale.to,
        id: index + 1,
        slug: sale.slug,
        mainTab: index === DEFAULT_ITEM_INDEX && sales.length > 1,
        productsCount: sale.productsCount,
        heading: sale.heading
      };
    });
    setTabsData(tabsData);
  };

  const showPagination = Boolean(
    tabsData[currentTab - 1] && saleData?.hits?.length
  );

  const getInitialBreadcrumbs = (translation, tabId = MAIN_TAB_INDEX) => [
    {
      name: translation?.home,
      onClick: () => history.push(`/${routeSlug}/`)
    },
    {
      name: translation?.flashSale,
      onClick: () => {
        setSelectedFilters({});
        onTabChange(tabId);
      }
    }
  ];

  const addBreadCrumb = ({ slug, queries }) => {
    setBreadcrumbs([
      ...breadcrumbs,
      {
        name: Object.values(queries)?.pop().split(">").pop(),
        onClick: () => {
          const filters = callbackSelectedFiltersRef.current;
          setSelectedFilters(filterSelectedFilters(filters, queries));
          onFilterClick({ slug, queries });
        }
      }
    ]);
  };

  if (isFetching) {
    return null;
  }

  const isMobileView = isMobile.any();
  return (
    <div>
      {
        <div
          className={cn("breadcrumb flash-sales", {
            arabic: language.includes("ar")
          })}
        >
          <div className="container large-container">
            <BreadcrumbList
              breadcrumbs={breadcrumbs}
              {...(isMobileView && {
                SwiperComponent: Swiper,
                swiperParams: {
                  ...swiperParams,
                  rtl: language.includes("ar") ? "rtl" : ""
                }
              })}
            />
          </div>
        </div>
      }
      {saleData ? (
        <>
          <SaleTabs
            saleDefaultData={saleData}
            configCdnImagesSettings={configCdnImagesSettings}
            currentTab={currentTab}
            onTabChange={onTabChange}
            tabsData={tabsData}
            language={language}
            translation={productTranslation}
            onFilterClick={onFilterClick}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            handleTabClick={handleTabClick}
          />
          {showPagination && (
            <Pagination
              products={saleData}
              handlePageClick={handlePaginationClick}
              language={shortLang}
              translation={productTranslation}
              history={history}
              countryShort={countrySHORT}
              clearAll={clearAllFilters}
              isSalePagination
            />
          )}
        </>
      ) : (
        <SaleComingSoon translation={productTranslation} heading={heading} />
      )}
    </div>
  );
};
