import React, { Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { isServer } from "../../../util";

const StoreLocatorDropDown = ({
  backClick,
  changeSelectedCountry,
  cityList,
  handleSelectedShop,
  selectedCountry,
  selectedShop,
  storeCountry
}) => {
  const deviceHeight = (!isServer && window.innerHeight - 60) || 439;
  return (
    <div className="mob-drop-down-container">
      <div className="selected-store">
        <span className="back-btn" onClick={backClick} />
        <div className="main-selected">
          {`${selectedShop.countryName || ""} ${
            selectedShop.countryName ? "," : ""
          }${selectedShop.name || ""}`}
        </div>
      </div>
      <div className="dropdown-mob-wrapper">
        <ul>
          <Scrollbars autoHeight autoHeightMax={deviceHeight}>
            {storeCountry &&
              storeCountry.map(item => (
                <Fragment key={item}>
                  <li
                    className={`country-name ${
                      item === selectedCountry ? "active" : ""
                    } `}
                    onClick={() => changeSelectedCountry(item)}
                  >
                    {item}
                  </li>
                  {item === selectedCountry &&
                    cityList &&
                    cityList.map((item, index) => {
                      return (
                        <Fragment key={item.city || index}>
                          <li className={`city-name`}>{item.city}</li>
                          {item.shops &&
                            !!item.shops.length &&
                            item.shops.map((shopItem, shopIndex) => (
                              <li
                                className={`shop-name`}
                                onClick={() =>
                                  handleSelectedShop({
                                    ...shopItem,
                                    city: item.city
                                  })
                                }
                                key={shopIndex}
                              >
                                {shopItem.name}
                              </li>
                            ))}
                        </Fragment>
                      );
                    })}
                </Fragment>
              ))}
          </Scrollbars>
        </ul>
      </div>
    </div>
  );
};

export default StoreLocatorDropDown;
