import React, { useEffect } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import cn from "classnames";
import {
  PRICE_CONVERSION_DECIMAL_VALUE,
  LOADER_TYPES
} from "../../../constants";
import * as priceUtil from "../../../util/priceUtil";

import HtmlParser from "../../html-parser";

import PayPalButton from "../../PayPalButton";
import TabbyButton from "../../tabby/TabbyButton";
import TamaraButton from "../../tamara/TamaraButton";
import Loader from "../../loader";
import {
  getShippingCharge,
  isFreeShippingForced,
  isFreeShippingReached,
  shippingMayBeFree
} from "../../../util/shipping";
import DynamicBannersWrapper from "../../dynamic/dynamic-banner";
import { isMobile } from "../../../util";
import InfoText from "../../info-text";

const CheckoutButtonFooter = ({
  total,
  translation,
  selectedDelivery,
  validateCheckout,
  placeOrder,
  order,
  callPlaceOrder,
  isCheckoutPage,
  codFee,
  currencyCode,
  useMyWallet,
  walletAmmount,
  commonSettings,
  transactionOrderTotal,
  onPayPalAuthorize,
  isPayPalSelected,
  payPalToken,
  _selectedPaymentOption,
  language,
  shippingAddress,
  isPayPalAllowed,
  isTabbySelected,
  isTamaraSelected,
  tabbyMode,
  tamaraMode,
  possibleToPlaceOrder,
  isPriceAvailable,
  checkoutPage,
  showCheckoutSummaryLoader,
  applyUseMyWallet,
  createOrderForPostPay,
  isPostPaySelected,
  cartSection,
  getSessionStatus,
  infoText,
  personalId,
  changePersonalIdStatus
}) => {
  const freeShippingLimit = get(selectedDelivery, "freeShippingLimit");
  const shippingCharge = get(selectedDelivery, "price");
  const freeShippingReachable = shippingMayBeFree(
    freeShippingLimit,
    shippingCharge
  );
  const freeShippingForced = isFreeShippingForced(
    freeShippingLimit,
    shippingCharge
  );
  const deliveryCharges =
    selectedDelivery &&
    getShippingCharge(freeShippingLimit, shippingCharge, total);
  const orderDiscount = order?.discount || 0;
  const fee = codFee || 0;
  const costAfterDiscount = total - orderDiscount + deliveryCharges;
  let transformedTotal = 0;

  if (costAfterDiscount > 0) {
    transformedTotal = costAfterDiscount + fee;
  }

  const walletAmmountTransformed =
    (useMyWallet &&
      walletAmmount &&
      (walletAmmount > transformedTotal ? transformedTotal : walletAmmount)) ||
    0;

  const showConversion =
    get(_selectedPaymentOption, "currencyCode") &&
    _selectedPaymentOption.currencyCode !==
      _selectedPaymentOption._paymentCurrency;

  const grandTotal = priceUtil.formatPriceByDecimal(
    transformedTotal - walletAmmountTransformed,
    PRICE_CONVERSION_DECIMAL_VALUE
  );

  const selectedPaymentCurrency = get(
    _selectedPaymentOption,
    "_paymentCurrency"
  );
  const en_modifiedPricePreviewHTML = get(
    commonSettings,
    "en_pricePreviewHTML",
    ""
  ).replace("{CURRENCY_CODE}", selectedPaymentCurrency);
  const ar_modifiedPricePreviewHTML = get(
    commonSettings,
    "ar_pricePreviewHTML",
    ""
  ).replace("{CURRENCY_CODE}", selectedPaymentCurrency);

  const stepsCompleted =
    order &&
    order.step1Completed &&
    order.step2Completed &&
    order.step3Completed;

  const showPayPalButton =
    isCheckoutPage && isPayPalAllowed && payPalToken && stepsCompleted;
  const vatText = get(commonSettings, `${language}_vatText`, "");
  const isPlaceOrderCommonBtnAvailable =
    !isPayPalSelected &&
    !isTabbySelected &&
    !isTamaraSelected &&
    !isPostPaySelected;

  useEffect(() => {
    if (grandTotal === 0 && useMyWallet) {
      applyUseMyWallet();
    }
  }, [grandTotal, useMyWallet]);

  const calculatedFree =
    (freeShippingReachable &&
      isFreeShippingReached(freeShippingLimit, total)) ||
    freeShippingForced;

  const isCartSectionAvailable = cartSection && !isMobile.any();
  const loaderInlineStyles = {
    textAlign: "center",
    width: "100%",
    top: "50%",
    left: " 50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent"
  };
  const isAdditionalTextInfo = isCheckoutPage && infoText && isMobile.any();

  return (
    <div className="cart_btm_strip cart_total_price cart_action">
      {isAdditionalTextInfo && (
        <>
          <InfoText text={infoText} />
          <div className="border_bottom mb-2 mt-2" />
        </>
      )}

      <div
        className={cn("order_price_summary", {
          "add-border position-relative": isCheckoutPage
        })}
      >
        {showCheckoutSummaryLoader ? (
          <Loader
            type={LOADER_TYPES.RING}
            width={25}
            height={25}
            visible
            style={loaderInlineStyles}
          />
        ) : (
          <>
            <div className="price_detail">
              <h5>{translation && translation.priceDetails}</h5>
              <p className="sub_total">
                {translation && translation.subTotal}
                <span>
                  {currencyCode || "AED"}{" "}
                  {priceUtil.shapePriceWithComma(
                    total,
                    commonSettings.decimalPlaces
                  )}
                </span>
              </p>
              {+get(order, "discount", 0) !== 0 &&
                get(order, "discount_type", "") !== "FREE_SHIPPING" && (
                  <p>
                    {translation && translation.discount}{" "}
                    <span className="green_text">
                      -{currencyCode || "AED"}{" "}
                      {priceUtil.shapePriceWithComma(
                        order.discount,
                        PRICE_CONVERSION_DECIMAL_VALUE
                      )}
                    </span>
                  </p>
                )}
              <p>
                {translation && translation.price_details_delivery}{" "}
                {get(order, "discount_type", "") === "FREE_SHIPPING" ? (
                  <span className="green_text">{translation.freeShipping}</span>
                ) : calculatedFree ? (
                  <span className="green_text">{translation.free}</span>
                ) : (
                  <span>
                    {currencyCode || "AED"}{" "}
                    {priceUtil.shapePriceWithComma(deliveryCharges)}
                  </span>
                )}
              </p>
              {!!codFee && (
                <p>
                  {translation && translation.codFee}{" "}
                  <span>
                    {currencyCode || "AED"}{" "}
                    {priceUtil.shapePriceWithComma(codFee)}
                  </span>
                </p>
              )}
              {useMyWallet && walletAmmount && (
                <p>
                  {translation && translation.wallet}{" "}
                  <span className="green_text">
                    -{currencyCode || "AED"}{" "}
                    {priceUtil.shapePriceWithComma(
                      walletAmmountTransformed,
                      PRICE_CONVERSION_DECIMAL_VALUE
                    )}
                  </span>
                </p>
              )}
            </div>
            <div className="total_price">
              <p>
                <span className="totalTxt">
                  {translation && translation.total}
                </span>
                {vatText && <span className="vat_price">{`(${vatText})`}</span>}
                <span className="Amount">
                  {currencyCode || "AED"}{" "}
                  {priceUtil.shapePriceWithComma(grandTotal)}
                </span>
              </p>
              <span className="excludingCharges">{`(${
                translation && translation.includeDeliveryCharges
              } )`}</span>
            </div>
          </>
        )}

        {showConversion && (
          <div className="extra_price_info extra_price_info_static">
            <div className="total_price_extra_text">
              {
                <span class="Amount">
                  {selectedPaymentCurrency}{" "}
                  {priceUtil.shapePriceWithComma(
                    priceUtil.formatPriceByDecimal(
                      grandTotal *
                        _selectedPaymentOption._paymentCurrencyExchangeRate,
                      PRICE_CONVERSION_DECIMAL_VALUE
                    )
                  )}
                </span>
              }
            </div>

            <HtmlParser
              html={
                language === "en"
                  ? en_modifiedPricePreviewHTML
                  : ar_modifiedPricePreviewHTML
              }
            />
          </div>
        )}

        {isCartSectionAvailable &&
          cartSection.map((section, key) => (
            <DynamicBannersWrapper
              section={section}
              key={key}
              bannerPageTitle={section.sectionTitle}
            />
          ))}

        <div className="total_price mCheckoutSign">
          <p>
            <span>{translation.totalToPay}</span>
            <span className="amount">
              <b>
                {currencyCode || "AED"}{" "}
                {priceUtil.shapePriceWithComma(
                  transformedTotal - walletAmmountTransformed,
                  PRICE_CONVERSION_DECIMAL_VALUE
                )}
              </b>
              {vatText && (
                <span className="inclusiveTax">{`(${vatText})`}</span>
              )}
            </span>
          </p>
        </div>
      </div>
      {showPayPalButton && (
        <div className="paypal-button hide_in_web">
          <PayPalButton
            token={payPalToken}
            transactionOrderTotal={transactionOrderTotal}
            onAuthorize={onPayPalAuthorize}
            id="mobile"
            color="gold"
            shippingAddress={shippingAddress}
            hidden={!isPayPalSelected}
          />
        </div>
      )}

      {isTabbySelected && isCheckoutPage && (
        <TabbyButton
          mode={tabbyMode}
          amount={transactionOrderTotal}
          shippingAddress={shippingAddress}
          deliveryCharges={deliveryCharges}
          buttonText={translation.placeOrderBtn}
          className="checkout_btn add_to_btn hide_in_web"
          checkoutPage={checkoutPage}
          grandTotal={grandTotal}
          stepsCompleted={stepsCompleted}
          getSessionStatus={getSessionStatus}
          personalId={personalId}
          changePersonalIdStatus={changePersonalIdStatus}
        />
      )}

      {isTamaraSelected && isCheckoutPage && (
        <TamaraButton
          mode={tamaraMode}
          amount={transactionOrderTotal}
          shippingAddress={shippingAddress}
          deliveryCharges={deliveryCharges}
          buttonText={translation.placeOrderBtn}
          className="checkout_btn add_to_btn hide_in_web"
          checkoutPage={checkoutPage}
          stepsCompleted={stepsCompleted}
          personalId={personalId}
          changePersonalIdStatus={changePersonalIdStatus}
        />
      )}

      {isPlaceOrderCommonBtnAvailable && (
        <>
          {placeOrder ? (
            <button
              disabled={!possibleToPlaceOrder}
              className={cn("checkout_btn", "add_to_btn", "hide_in_web", {
                disable_order_btn: !possibleToPlaceOrder
              })}
              onClick={callPlaceOrder}
            >
              {translation.placeOrderBtn}
            </button>
          ) : (
            <div className="cart_btn_group hideOnWeb">
              <button
                className={cn("checkout_btn", "add_to_btn", {
                  disabled: !isPriceAvailable
                })}
                disabled={!isPriceAvailable}
                onClick={validateCheckout}
              >
                {translation.checkout}
              </button>
            </div>
          )}
        </>
      )}

      {isCheckoutPage && isPostPaySelected && (
        <button
          className={cn("checkout_btn", "add_to_btn", "hide_in_web", {
            disable_order_btn: !stepsCompleted
          })}
          onClick={createOrderForPostPay}
        >
          {translation.placeOrderBtn}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isPriceAvailable: state.cartReducer.cart.every(product =>
    priceUtil.isPriceAvailableByUserIpcountry(product)
  ),
  showCheckoutSummaryLoader: state.checkoutReducer.showCheckoutSummaryLoader
});

export default connect(mapStateToProps)(CheckoutButtonFooter);
