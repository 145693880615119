import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import get from "lodash/get";
import map from "lodash/map";
import size from "lodash/size";
import { isMobileSafari } from "react-device-detect";

import {
  DROP_DOWN_SCROLL_TYPES,
  linkRegExp,
  ZIP_CODE_LENGTH_MAX,
  ZIP_CODE_LENGTH_MIN
} from "../../constants";
import { checkCountry, isMobile } from "../../util";
import { resetAPIError } from "../../redux/actions/homepage.action";
import { selectLanguageSlug, selectShowLoader } from "../../util/selectors";
import { getDividedNumber } from "../../util/phoneNumber";
import IntlInput from "./../../components/intl-tel-input/intl-tel-input";
import CustomDropdown from "../../components/genericDropDown";
import { isNil } from "../../util/common";
import { ButtonWithLoader } from "../../components/generalElements/buttons";
import { DropdownWithSearch } from "../../components/generalElements/select";
import MobileSwipeableList from "../../components/generalElements/mobileSwipeableList";
import FormActivityHandler from "../../components/formActionsHandler";
class AddressForm extends Component {
  state = { lastFocusedField: "" };

  static initialValues = {
    firstName: "",
    lastName: "",
    city: null,
    area: null,
    address: "",
    zipCode: "",
    phoneNumber: "",
    isDefaultAddress: false
  };

  static schema = (mobileLength, countryCode) =>
    Yup.object().shape({
      firstName: Yup.string().trim().min(1).required(),
      lastName: Yup.string(),
      country: Yup.object().required(),
      city: Yup.object().required(),
      area: Yup.object().required(),
      address: Yup.string()
        .trim()
        .min(15)
        .max(250)
        .test({
          test: (value = "") => !value.match(linkRegExp)
        })
        .required(),
      zipCode: Yup.string().when("isZipcodeAvailable", {
        is: true,
        then: Yup.string()
          .min(ZIP_CODE_LENGTH_MIN[countryCode])
          .max(ZIP_CODE_LENGTH_MAX[countryCode])
          .required()
      }),
      phoneNumber: Yup.string().min(mobileLength).max(mobileLength).required(),
      phoneArea: Yup.number(),
      isDefaultAddress: Yup.boolean().optional()
    });

  componentWillUnmount() {
    this.props.resetAPIError();
  }

  getCountry() {
    const { countryId, countryName } = this.props;
    if (countryId && countryName) {
      return { key: countryId, value: countryName };
    }
  }

  getCities() {
    const { citiesDetails } = this.props;
    if (citiesDetails) {
      return citiesDetails.map(city => {
        return {
          key: city.stateId,
          value: city.stateName
        };
      });
    }
  }

  handleZipCodeChange = (target, setFieldValue) => {
    const onlyNumbers = /^[0-9]{0,}$/;
    const { value, name } = target;
    const { countryCode } = this.props;

    if (
      onlyNumbers.test(value) &&
      value.match(onlyNumbers)[0].length <= ZIP_CODE_LENGTH_MAX[countryCode]
    ) {
      setFieldValue(name, value);
    }
    return false;
  };

  getCityPlaceholder() {
    const { checkoutTranslation, countryCode } = this.props;
    const { selectEmirate, selectOnlyCity, selectState } = checkoutTranslation;
    const cityTranslations = {
      UAE: selectEmirate,
      KSA: selectOnlyCity
    };
    return cityTranslations[countryCode] ?? selectState;
  }

  getFormattedUserData() {
    const {
      address: {
        firstname,
        lastname,
        areaId,
        area,
        cityId,
        city,
        countryId,
        country,
        addressLine1,
        zipcode,
        phone,
        default: isDefault
      },
      mobileLocalNumberLength,
      mobileCountryCode,
      mobileLocalCode,
      citiesDetails
    } = this.props;
    const phoneDetails = {
      phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { phoneWithoutCountryCode, areaCode } =
      getDividedNumber(phoneDetails);

    const addressData = {
      firstName: firstname,
      lastName: lastname,
      area: { key: areaId, value: area },
      city: { key: +cityId, value: city },
      country: { key: countryId, value: country },
      address: addressLine1,
      zipCode: zipcode || "",
      phoneArea: +areaCode,
      isDefaultAddress: isDefault
    };

    if (phoneWithoutCountryCode) {
      addressData.phoneNumber = phoneWithoutCountryCode.slice(areaCode.length);
    }
    const isValid = AddressForm.schema(mobileLocalNumberLength).isValidSync(
      addressData
    );
    const data = isValid
      ? addressData
      : {
          ...addressData,
          country: isNil(addressData.country.value)
            ? undefined
            : addressData.country,
          city: isNil(addressData.city.value) ? undefined : addressData.city,
          area: isNil(addressData.area.key) ? undefined : addressData.area
        };

    if (size(citiesDetails)) {
      const allStates = map(citiesDetails, "stateId");
      if (!allStates.includes(+cityId)) {
        delete data.city;
        delete data.area;
      }
    }
    return data;
  }

  getCityAreas = selectedCity => {
    const { citiesDetails } = this.props;
    if (citiesDetails.length) {
      const cityAreas = citiesDetails.find(
        city => city.stateId === selectedCity.key
      ).area;
      return this.getTransformedAreas(cityAreas);
    }
  };

  getTransformedAreas = areas =>
    areas.map(({ areaId, areaName }) => ({
      key: areaId,
      value: areaName
    }));

  setLastFocusedField = e => {
    const lastFocusedField = typeof e === "string" ? e : e.currentTarget.name;
    this.setState({
      lastFocusedField
    });
  };

  render() {
    const {
      closeModal,
      checkoutTranslation,
      submitButtonText,
      countryData,
      countrySHORT,
      mobileLocalNumberLength,
      onSubmit,
      handleCloseModal = closeModal,
      countryCode,
      isArabic,
      apiErrorDetail,
      resetAPIError,
      showLoader,
      onSubmitBtnClick
    } = this.props;
    const isMobileView = isMobile.any();

    const isZipcodeAvailable = checkCountry(
      ["USA", "IND", "PHL", "JPN"],
      countryCode
    );

    const areaTranslations = {
      KSA: checkoutTranslation.selectOnlyArea
    };
    const selectAreaTranslation =
      areaTranslations[countryCode] ?? checkoutTranslation.selectArea;

    return (
      <Fragment>
        <Formik
          onSubmit={onSubmit}
          validationSchema={() =>
            AddressForm.schema(mobileLocalNumberLength, countryCode)
          }
          initialValues={{
            ...AddressForm.initialValues,
            ...this.getFormattedUserData(),
            country: this.getCountry(),
            isZipcodeAvailable
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            submitCount
          }) => {
            const handleSelectChange = (key, valueName, array) => {
              const object = array.find(value => value.key === key);
              setFieldValue(valueName, object);
            };
            const isInputInvalid = valueKey =>
              Boolean(touched[valueKey] && errors && errors[valueKey]);

            const cityAreas = values.city ? this.getCityAreas(values.city) : [];

            const isShowGeneralError = () =>
              Object.entries(errors).length && submitCount;

            const isPhoneError = Boolean(
              !apiErrorDetail.success && !isShowGeneralError()
            );

            const changePhoneNumber = () => {
              if (!apiErrorDetail.success) resetAPIError();
            };

            const changePhoneArea = area => {
              setFieldValue("phoneArea", area);
              if (!apiErrorDetail.success) resetAPIError();
            };

            return (
              <form onSubmit={handleSubmit}>
                <div
                  className={cn("sign_in_container", "guest_register", {
                    arabic: isArabic
                  })}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className={cn("form-control", {
                        borderRed: isInputInvalid("firstName")
                      })}
                      name="firstName"
                      placeholder={checkoutTranslation.firstName}
                      onChange={handleChange}
                      value={values.firstName}
                      aria-label="First name"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("firstName") && (
                      <span className="error">
                        {checkoutTranslation.enterFirstName}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      placeholder={checkoutTranslation.lastName}
                      value={values.lastName}
                      onChange={handleChange}
                      aria-label="Last name"
                      onFocus={this.setLastFocusedField}
                    />
                  </div>
                  <div className="form-group">
                    <CustomDropdown
                      name="country"
                      data={[this.getCountry()]}
                      selectedItem={values.country}
                      containerClass="guest_user_select"
                      scrollbarMaxHeight={150}
                      // TODO: update CustomDropdown, so that should return object, not just key
                      handleChange={key =>
                        handleSelectChange(key, "country", [this.getCountry()])
                      }
                      onClickForEvent={this.setLastFocusedField}
                    />
                  </div>
                  <div className="form-group">
                    {!isMobileView ? (
                      <CustomDropdown
                        scrollType={DROP_DOWN_SCROLL_TYPES.DEFAULT}
                        name="city"
                        data={this.getCities()}
                        selectedItem={values.city || this.getCityPlaceholder()}
                        containerClass={cn("guest_user_select", {
                          borderRed: isInputInvalid("city")
                        })}
                        scrollbarMaxHeight={190}
                        handleChange={key => {
                          handleSelectChange(key, "city", this.getCities());
                          setFieldValue("area", selectAreaTranslation);
                        }}
                        withSearchInput
                        translation={checkoutTranslation}
                        onClickForEvent={this.setLastFocusedField}
                      />
                    ) : (
                      <MobileSwipeableList
                        containerClass={cn("guest_user_select", {
                          borderRed: isInputInvalid("city")
                        })}
                        data={this.getCities()}
                        selectedItem={values.city || this.getCityPlaceholder()}
                        handleChange={key => {
                          handleSelectChange(key, "city", this.getCities());
                          setFieldValue("area", selectAreaTranslation);
                        }}
                        headTranslation={checkoutTranslation.selectEmirate}
                        scrollbarMaxHeight={500}
                        placeholderTranslation={checkoutTranslation.typeHere}
                        withSearchInput
                        translation={checkoutTranslation}
                      />
                    )}
                    {isInputInvalid("city") && (
                      <span className="error">
                        {checkoutTranslation.cityAreaError}
                      </span>
                    )}
                  </div>
                  <div
                    className={cn("form-group", "guest_area", {
                      disabledDropdown: !Boolean(cityAreas.length)
                    })}
                  >
                    {!isMobileView ? (
                      <CustomDropdown
                        name="area"
                        scrollType={DROP_DOWN_SCROLL_TYPES.DEFAULT}
                        data={cityAreas}
                        selectedItem={values.area || selectAreaTranslation}
                        containerClass={cn("guest_user_select", {
                          borderRed: isInputInvalid("area")
                        })}
                        scrollbarMaxHeight={190}
                        handleChange={key =>
                          handleSelectChange(key, "area", cityAreas)
                        }
                        withSearchInput
                        translation={checkoutTranslation}
                        onClickForEvent={this.setLastFocusedField}
                      />
                    ) : (
                      <MobileSwipeableList
                        containerClass={cn("guest_user_select", {
                          borderRed: isInputInvalid("area")
                        })}
                        data={cityAreas}
                        selectedItem={values.area || selectAreaTranslation}
                        handleChange={key =>
                          handleSelectChange(key, "area", cityAreas)
                        }
                        disabled={!Boolean(cityAreas.length)}
                        headTranslation={checkoutTranslation.selectArea}
                        scrollbarMaxHeight={500}
                        placeholderTranslation={checkoutTranslation.typeHere}
                        withSearchInput
                        translation={checkoutTranslation}
                      />
                    )}

                    {isInputInvalid("area") && (
                      <span className="error">
                        {checkoutTranslation.cityAreaError}
                      </span>
                    )}
                  </div>
                  <div className="form-group guest_address">
                    <input
                      type="text"
                      className={cn("form-control", {
                        borderRed: isInputInvalid("address")
                      })}
                      name="address"
                      placeholder={checkoutTranslation.fullAddress}
                      value={values.address}
                      onChange={handleChange}
                      aria-label="Full address"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("address") && (
                      <span className="error">
                        {checkoutTranslation[`${errors[`address`]}`]}
                      </span>
                    )}
                  </div>
                  {isZipcodeAvailable && (
                    <div className="form-group">
                      <input
                        type="text"
                        className={cn("form-control", {
                          borderRed: isInputInvalid("zipCode")
                        })}
                        name="zipCode"
                        placeholder={checkoutTranslation.enterZipCode}
                        value={values.zipCode}
                        onChange={({ target }) =>
                          this.handleZipCodeChange(target, setFieldValue)
                        }
                        aria-label="Zip code"
                        onFocus={this.setLastFocusedField}
                      />
                      {isInputInvalid("zipCode") && (
                        <span className="error">
                          {checkoutTranslation.enterValidZipCode}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="form-group">
                    <IntlInput
                      valid={!isInputInvalid("phoneNumber")}
                      phone={values.phoneNumber}
                      areaCode={values.phoneArea}
                      handleCountryChange={(e, phone) =>
                        setFieldValue("phoneNumber", phone)
                      }
                      onSelectArea={changePhoneArea}
                      defaultCountry={countrySHORT}
                      countryData={countryData}
                      mobileLocalNumberLength={mobileLocalNumberLength}
                      onChange={changePhoneNumber}
                      onFocus={this.setLastFocusedField}
                    />

                    {isInputInvalid("phoneNumber") && (
                      <span className="error">
                        {checkoutTranslation.enterValidPhoneNumber}
                      </span>
                    )}
                  </div>
                  <div className="form-group checkbox_wrap default_address checkoutDefault">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="isDefaultAddress"
                      id="default_address"
                      checked={values.isDefaultAddress}
                      onChange={handleChange}
                      onFocus={this.setLastFocusedField}
                    />
                    <label className="checkbox_value" htmlFor="default_address">
                      {checkoutTranslation.setAsDefaultDeliveryAddress}
                    </label>
                  </div>
                  <div className="action_btns">
                    <div className="form-group">
                      <ButtonWithLoader
                        type="submit"
                        className="btn btn-default round_btn form_black_btn"
                        disabled={isSubmitting && isShowGeneralError()}
                        showLoader={showLoader}
                        {...(onSubmitBtnClick && { onClick: onSubmitBtnClick })}
                      >
                        {submitButtonText}
                      </ButtonWithLoader>
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-default round_btn form_white_btn"
                        onClick={() => handleCloseModal(true)}
                      >
                        {checkoutTranslation.cancel}
                      </button>
                    </div>
                  </div>
                  <div className="missing-field-interaction">
                    <span
                      className={
                        isShowGeneralError()
                          ? "error displayMessage"
                          : "hideMessage"
                      }
                    >
                      {checkoutTranslation.submitValidate}
                    </span>
                  </div>
                  {isPhoneError && (
                    <span className="error">{apiErrorDetail.message}</span>
                  )}
                  {isMobileSafari && <div className="my-5" />}
                </div>
                <FormActivityHandler
                  formName={"add/ edit address"}
                  lastFocusedFormField={this.state.lastFocusedField}
                />
              </form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  address: state.addressReducer,
  isArabic: selectLanguageSlug(state) === "ar",
  countryName: get(state, "common.settings.countryName"),
  countryId: get(state, "common.settings.countryId"),
  countryCode: get(state, "common.settings.countryCode"),
  citiesDetails: get(state, "common.settings.state"),
  countryData: get(state, "common.countryData"),
  countrySHORT: get(state, "common.settings.countrySHORT"),
  mobileLocalCode: get(state, "common.settings.mobileLocalCode"),
  mobileCountryCode: get(state, "common.settings.mobileCountryCode"),
  mobileLocalNumberLength: get(
    state,
    "common.settings.mobileLocalNumberLength"
  ),
  apiErrorDetail: state.homepage.APIErrorResponse,
  showLoader: selectShowLoader(state)
});

const mapDispatchToProps = {
  resetAPIError
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressForm)
);
