import React from "react";
import moment from "moment";
import CustomCountdown from "../custom-countdown";

const CountdownWrapper = ({
  content,
  removeBannerWrapper,
  imageListLength,
  removeCountdown
}) => {
  const { styles, date, styleType } = content;
  const countdownStyles = {
    ...styles,
    width: imageListLength <= 2 ? `${100 / imageListLength}%` : "50%",
    justifyContent: content.countdownOption
  };
  const isCountdownOver = moment().isAfter(date);
  const onCountdownCopmlete = () => {
    if (imageListLength === 1) {
      removeBannerWrapper();
    } else {
      removeCountdown(content.id);
    }
  };

  if (isCountdownOver) {
    onCountdownCopmlete();
    return null;
  }

  return (
    !isCountdownOver && (
      <div
        className="catalog_banner_section"
        style={countdownStyles}
        contentId={`countdown-${content.id}`}
      >
        <div
          id="bfl-countdown"
          className="bfl-countdown"
          data-date={date}
          data-type="0"
        >
          <CustomCountdown
            date={date}
            type={styleType}
            onCountdownCopmlete={onCountdownCopmlete}
            content={content}
          />
        </div>
      </div>
    )
  );
};

export default CountdownWrapper;
