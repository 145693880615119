import { lowerCase } from "lodash";
import GAExtensions from "../extensions";
import { getMenuTree } from "../utils";

class FooterService extends GAExtensions {
  trackFooterMenuClick = data => {
    const { parent, clickItem, clickLink } = data;
    const head_text = parent.en_title || parent.title || "";
    let click_text = clickItem.en_title || clickItem.title || "";
    click_text = lowerCase(click_text);
    this.pushToDataLayer({
      event: "nav_footer",
      click_text,
      nav_link: clickLink,
      nav_tree: getMenuTree(head_text, click_text)
    });
  };
}

export default FooterService;
