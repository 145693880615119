import get from "lodash/get";
import set from "lodash/set";
import {
  postDashboardWebApi,
  putDashboardWebApi,
  fetchWebApiWithParam
} from "../../webapis/apiResource";
import { getAccessToken, getGuestAccessToken } from "../../util/storeHelper";
import { BEConfig } from "../../config/env";
import { setToken, setTokenCheckoutAsGuest } from "./authConfig.action";
import {
  errorOnUserSide,
  handleFetchError,
  internalServerError
} from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import { fetchAddressBook, fetchUserAndLoadData } from "./authConfig.action";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import {
  SIGN_IN_MODAL,
  SHOW_SIGNUP_MODAL,
  HIDE_SIGNUP_MODAL,
  CLEAR_MODAL,
  HANDLE_TEXT_CHANGE,
  SHOW_FORGOTPASSWORD_MODAL,
  HIDE_FORGOTPASSWORD_MODAL,
  RESET_PASSWORD_TOKEN_VALIDATION,
  API_ERROR,
  RESET_API_ERROR,
  API_NOTIFICATION_MSG,
  RESET_NOTIFICATION_MSG,
  LOGIN_SUCCESS,
  RESET_PASSWORD_DATA,
  IS_HOME_PAGE,
  HOME_PAGE_ERROR,
  SET_REGISTRATION_DATA_STATUS,
  RESET_REGISTRATION_DATA_STATUS,
  SET_SIGNUP_LOGIN_TOKEN,
  SET_SOCIAL_LOGIN_FLOW,
  RESET_SOCIAL_LOGIN_FLOW,
  SET_SOCIAL_PHONE_CHECK_TOKEN,
  SET_ANALYTICS_DATA,
  SET_LAST_REGISTRATION_STATUS_CODE,
  SET_REGISTRATION_ERROR,
  SET_ADDRESS_OTP_ERROR,
  SET_ADDRESS_OTP_FLOW,
  SET_OTP_LOGIN_FLOW_BFL
} from "../constants";
import {
  IS_AUTH_FROM_CHECKOUT,
  CHECKOUT_ID,
  SIGNUP_CODES_FOR_MODAL
} from "../../constants";
import AnalyticService from "../../services/analytic-service";
import {
  AddressService,
  AuthService,
  GuestService,
  SocialAuthService,
  UserDetailsService
} from "../../services";
import { updateCheckoutById } from "./checkout.action";
import { getCartAndWishlistGeneralData } from "./cart.action";
import { GAService } from "../../services/GA-service";
import { getState } from "../store";

export const isHomePage = value => ({ type: IS_HOME_PAGE, value });
export const showModal = user => ({ type: SHOW_SIGNUP_MODAL });
export const clearModal = user => ({ type: CLEAR_MODAL });
export const showForgotPasswordModal = user => ({
  type: SHOW_FORGOTPASSWORD_MODAL
});
export const hideModal = user => ({ type: HIDE_SIGNUP_MODAL });
export const hideForgotPasswordModal = user => ({
  type: HIDE_FORGOTPASSWORD_MODAL
});
export const setresetPasswordTokenValidated = content => ({
  type: RESET_PASSWORD_TOKEN_VALIDATION,
  content
});
export const ApiError = content => ({ type: API_ERROR, content });
export const resetAPIError = () => ({ type: RESET_API_ERROR });
export const notificationMsg = message => ({
  type: API_NOTIFICATION_MSG,
  message
});
export const resetnotificationMsg = content => ({
  type: RESET_NOTIFICATION_MSG
});

export const homePageError = isError => ({ type: HOME_PAGE_ERROR, isError });

export const userProfile = data => ({ type: LOGIN_SUCCESS, data });
export const handleTextChange = (name, value, modelName, status) => {
  return { type: HANDLE_TEXT_CHANGE, name, value, modelName, status };
};
export const signInModal = signInModal => ({
  type: SIGN_IN_MODAL,
  signInModal
});
export const resetPasswordData = data => ({ type: RESET_PASSWORD_DATA });

export const setRegistrationDataStatus = data => ({
  type: SET_REGISTRATION_DATA_STATUS,
  payload: data
});

export const resetRegistrationDataStatus = () => ({
  type: RESET_REGISTRATION_DATA_STATUS
});

export const setSignUpLoginToken = token => ({
  type: SET_SIGNUP_LOGIN_TOKEN,
  payload: token
});

// const resetSignUpLoginToken = () => ({
//   type: RESET_SIGNUP_LOGIN_TOKEN
// });

export const setSocialPhoneCheckToken = token => ({
  type: SET_SOCIAL_PHONE_CHECK_TOKEN,
  payload: token
});

// const resetSocialPhoneCheckToken = () => ({
//   type: RESET_SOCIAL_PHONE_CHECK_TOKEN
// });

export const setSocialLoginFlow = flag => ({
  type: SET_SOCIAL_LOGIN_FLOW,
  payload: flag
});

export const setOTPLoginFlowBFL = flag => ({
  type: SET_OTP_LOGIN_FLOW_BFL,
  payload: flag
});

export const resetSocialLoginFlow = () => ({
  type: RESET_SOCIAL_LOGIN_FLOW
});

export const setAnalyticsData = data => ({
  type: SET_ANALYTICS_DATA,
  payload: data
});

const setLastRegistrationStatusCode = code => ({
  type: SET_LAST_REGISTRATION_STATUS_CODE,
  payload: code
});

const setRegistrationError = data => ({
  type: SET_REGISTRATION_ERROR,
  payload: data
});

export const resetRegistrationError = () => ({
  type: SET_REGISTRATION_ERROR,
  payload: ""
});

const loginCompleteCheckoutFirstStep = () => dispatch => {
  if (localStorage.getItem(IS_AUTH_FROM_CHECKOUT)) {
    const checkoutId = localStorage.getItem(CHECKOUT_ID);
    if (checkoutId) {
      dispatch(
        updateCheckoutById(checkoutId, {
          step1Completed: true,
          continueAsGuest: false
        })
      );
    } else {
      localStorage.removeItem(IS_AUTH_FROM_CHECKOUT);
      localStorage.removeItem(CHECKOUT_ID);
    }
  }
};

export const setAddressOtpError = error => ({
  type: SET_ADDRESS_OTP_ERROR,
  payload: error
});

export const setAddressOtpFlow = flowData => ({
  type: SET_ADDRESS_OTP_FLOW,
  payload: flowData
});

export const saveUser = (
  bodyData,
  history,
  language,
  country,
  selectedCountryName
) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.userHandle}/${BEConfig.customerApi.register}`;

  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          const customerId = get(response, "data.customerId", "");
          const country = getState().common.settings.countryCode;
          AnalyticService.auth.trackSignUp({
            data: bodyData,
            countryName: selectedCountryName,
            customerId,
            country
          });
          dispatch(hideModal());
          history.push(
            `/${language}-${
              (country && country.toLowerCase()) || "ae"
            }/registration/thank-you`
          );
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const guestUserCheckout = bodyData => async (dispatch, getState) => {
  const guestUserCheckoutBodyData = {
    ...bodyData,
    deliveryType: getState().checkoutReducer.checkoutData.deliveryType
  };
  dispatch(showLoadingIndicator());
  const phoneForOTP = guestUserCheckoutBodyData.phone;
  try {
    const response = await GuestService.guestRegistration(
      guestUserCheckoutBodyData
    );
    const otpRequired = response.data.otp_required;
    if (otpRequired) {
      const tokenForOTPVerification = response.data.token;
      dispatch(
        setAddressOtpFlow({
          phoneForOTP,
          verificationCallback: async otpCode => {
            try {
              const resp = await GuestService.completeRegistration({
                token: tokenForOTPVerification,
                otp: otpCode
              });

              if (!resp.data.success) {
                dispatch(setAddressOtpError(resp.data.message));
                return resp;
              }

              dispatch(hideModal());
              dispatch(setTokenCheckoutAsGuest(resp.data));
              return resp;
            } catch (error) {
              if (errorOnUserSide(error.response.status)) {
                dispatch(setAddressOtpError(error.response.data.message));
                return error.response;
              }
              handleFetchError(error, dispatch);
              return error.response;
            }
          },
          resendOTPCallback: async () => {
            try {
              const resp = await AddressService.resendOtp(
                tokenForOTPVerification
              );

              if (!resp.data.success) {
                dispatch(setAddressOtpError(resp.data.message));
                return resp;
              }

              return resp;
            } catch (error) {
              if (errorOnUserSide(error.response.status)) {
                dispatch(setAddressOtpError(error.response.data.message));
                return error.response;
              }
              handleFetchError(error, dispatch);
              return error.response;
            }
          }
        })
      );
      return {
        OTPNeeded: true
      };
    }
    if (response.status === 200 && response.data && response.data.success) {
      dispatch(hideModal());
      dispatch(setTokenCheckoutAsGuest(response.data));
    } else {
      dispatch(ApiError(response.data));
    }
    return response;
  } catch (error) {
    if (errorOnUserSide(error.response.status)) {
      dispatch(
        setAddressOtpFlow({ phoneForOTP, error: error.response.data.message })
      );
      return {
        OTPNeeded: true
      };
    }
    handleFetchError(error, dispatch);
    return error.response;
  } finally {
    dispatch(hideLoadingIndicator());
  }
};

export const authenticateUser = (
  bodyData,
  history,
  language,
  redirect = true,
  country
) => {
  language = languageFromPathName(language);
  const authPath = BEConfig.customerApi.loginUserHandle;
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${authPath}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, {
      ...bodyData
    })
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(loginCompleteCheckoutFirstStep());
          dispatch(userProfile(response.data));
          dispatch(setToken(response.data));
          dispatch(getCartAndWishlistGeneralData());
          dispatch(hideModal());
          if (redirect) {
            history.push(`/${language}-${country || "ae"}/`);
          }
        } else {
          if ("success" in response.data && !response.data?.success) {
            GAService.auth.trackLoginError({
              method: bodyData?.mode || "email",
              error: response.data?.message || ""
            });
          }
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.message;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const saveAddress = (bodyData, guest) => async (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  let type;
  if (parseInt(bodyData.collectionPointId) === -1) {
    type = "DELIVERY_ADDRESS";
  } else {
    type = getState().checkoutReducer.checkoutData.deliveryType;
  }
  const saveAddressBodyData = {
    ...bodyData,
    deliveryType: type
  };
  const phoneForOTP = saveAddressBodyData.phone;
  try {
    const [initFlow, verifyCall] = bodyData.addressId
      ? [
          AddressService.updateAddress.bind(AddressService),
          AddressService.completeUpdating.bind(AddressService)
        ]
      : [
          AddressService.addAddress.bind(AddressService),
          AddressService.completeAdding.bind(AddressService)
        ];

    const response = await initFlow(saveAddressBodyData, guest);

    const otpRequired = response.data.otp_required;
    const countryId = getState().common.settings.countryId;
    if (otpRequired) {
      const tokenForOTPVerification = response.data.token;
      dispatch(
        setAddressOtpFlow({
          phoneForOTP,
          verificationCallback: async otpCode => {
            try {
              const resp = await verifyCall(
                { token: tokenForOTPVerification, otp: otpCode },
                saveAddressBodyData.addressId
              );

              if (!resp.data.success) {
                dispatch(setAddressOtpError(resp.data.message));
                return resp;
              }

              dispatch(fetchAddressBook(countryId));
              return resp;
            } catch (error) {
              if (errorOnUserSide(error.response.status)) {
                dispatch(setAddressOtpError(error.response.data.message));
                return error.response;
              }
            }
          },
          resendOTPCallback: async () => {
            try {
              const resp = await AddressService.resendOtp(
                tokenForOTPVerification
              );

              if (!resp.data.success) {
                dispatch(setAddressOtpError(resp.data.message));
                return resp;
              }

              return resp;
            } catch (error) {
              if (errorOnUserSide(error.response.status)) {
                dispatch(setAddressOtpError(error.response.data.message));
                return error.response;
              }
            }
          }
        })
      );
      return {
        OTPNeeded: true
      };
    }
    if (response.status === 200 && response.data && response.data.success) {
      dispatch(fetchAddressBook(countryId));
      return response;
    } else {
      dispatch(ApiError(response.data));
    }
    internalServerError(response, dispatch); //api internal server error
    return response;
  } catch (error) {
    if (errorOnUserSide(error.response.status)) {
      dispatch(
        setAddressOtpFlow({ phoneForOTP, error: error.response.data.message })
      );
      return {
        OTPNeeded: true
      };
    }

    handleFetchError(error, dispatch);
    return error.response;
  } finally {
    dispatch(hideLoadingIndicator());
  }
};

export const resetPasswordEmailLink = bodyData => {
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.resetEmailLinkHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(null, apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(hideForgotPasswordModal());
          dispatch(notificationMsg(response.data.message));
          dispatch(hideModal());
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const tokenValidationService = bodyData => {
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.tokenValidationHandle}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApiWithParam(null, apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          const reduxData = {};
          reduxData.success = response.data.success;
          if (
            response &&
            response.data &&
            !response.data.success &&
            !!response.data.name
          ) {
            reduxData.message = "Your recent password link has been expired.";
          } else if (
            !response.data.success &&
            response.data.name === "TokenExpiredError"
          ) {
            reduxData.message = "Something Went Wrong.";
          } else {
            reduxData.message = "";
          }
          reduxData.email = response.data.data ? response.data.data.email : "";
          dispatch(setresetPasswordTokenValidated(reduxData));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const resetPasswordAPI = (bodyData, history, language, country) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.customerApi.protocol}${BEConfig.customerApi.baseURL}${BEConfig.customerApi.port}${BEConfig.customerApi.versionInfo}${BEConfig.customerApi.resetPasswordHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          if (history)
            history.push({
              pathname: `/${language}-${
                country || "ae"
              }/registration/thank-you/`,
              state: { detail: "fromResetPassword" }
            });
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const currentResetPasswordAPI = (
  bodyData,
  history,
  language,
  country
) => {
  language = languageFromPathName(language);
  return async dispatch => {
    dispatch(showLoadingIndicator());
    try {
      const response = await UserDetailsService.updateCurrentPassword(bodyData);
      if (response.status === 200 && response.data && response.data.success) {
        if (history)
          history.push({
            pathname: `/${language}-${country || "ae"}/registration/thank-you/`,
            state: { detail: "fromResetPassword" }
          });
      } else {
        dispatch(ApiError(response.data));
      }
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };
};

export const updateUserPassword = bodyData => {
  return async dispatch => {
    dispatch(showLoadingIndicator());
    try {
      const response = await UserDetailsService.updateCurrentPassword(bodyData);
      if (
        !(response.status === 200 && response.data && response.data.success)
      ) {
        dispatch(ApiError(response.data));
      }
      return response;
    } catch (err) {
      handleFetchError(err, dispatch);
      return err.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };
};

const passDataToAuthService = async (
  dispatch,
  data,
  authMethod,
  callBackOnSuccess
) => {
  dispatch(showLoadingIndicator());
  try {
    const response = await authMethod(data);
    const { token, code } = response;
    checkForRegistrationErrors(response, data);
    dispatch(setRegistrationDataStatus(response));
    if (token) {
      dispatch(setSignUpLoginToken(token));
    }
    if (code) {
      dispatch(setLastRegistrationStatusCode(code));
    }
    callBackOnSuccess && callBackOnSuccess(response);

    return response;
  } catch (error) {
    if (error?.response?.status === 403) {
      dispatch(setRegistrationError(error.response?.data?.message));
    } else {
      handleFetchError(error, dispatch);
    }
    return error.response;
  } finally {
    dispatch(hideLoadingIndicator());
  }
};

export const getUserDataStatus = data => async dispatch =>
  passDataToAuthService(dispatch, data, AuthService.getRegistrationStatus);

export const checkMobileStatus = data => async dispatch =>
  passDataToAuthService(dispatch, data, SocialAuthService.checkMobileStatus);

export const startRegistration = data => async (dispatch, getState) => {
  const store = getState();
  const { socialLoginFlow } = get(store, "homepage", {});

  return passDataToAuthService(
    dispatch,
    data,
    (socialLoginFlow ? SocialAuthService : AuthService).startRegistration
  );
};

const checkForRegistrationErrors = (response, data) => {
  const { code, message = "" } = response;
  const store = getState();
  const { analyticsData, socialLoginFlow } = get(store, "homepage", {});
  if (code && !SIGNUP_CODES_FOR_MODAL[code] && !response?.success) {
    // if Social login flow Errors
    if (socialLoginFlow && analyticsData?.loginFrom) {
      GAService.auth.trackLoginError({
        method: analyticsData?.loginFrom,
        error: message || ""
      });
    } else {
      // if normal Sign In/Up flow Errors
      const gaPayload = { method: "email", error: message || "" };
      if (data.email && data.isAgeVerified) {
        // To Check for Signup flow.
        GAService.auth.trackSignUpError(gaPayload);
      } else {
        GAService.auth.trackLoginError(gaPayload);
      }
    }
  }
};

const passDataToAnalytics = (response, registrationData, getState) => {
  const store = getState();
  const { socialLoginFlow } = get(store, "homepage", {});
  const customerId = get(response, "customerId", "");
  const country = get(store, "common.settings.countryCode");
  const countryData = get(store, "common.countryData");
  const countryId = get(store, "common.settings.countryId");
  const { countryName } =
    countryData.find(countryData => countryData.countryId === countryId) || {};
  AnalyticService.auth.trackSignUp({
    data: registrationData,
    countryName,
    customerId,
    country
  });
  // sign up via both email or social login
  GAService.auth.trackSignUpSuccess({
    isSocialLogin: socialLoginFlow,
    customerId,
    data: registrationData
  });
};

export const verifyReceivedOtp =
  (credentials, registrationData) => async (dispatch, getState) => {
    const store = getState();
    const { socialLoginFlow, analyticsData, otpLoginFlowBFL } = get(
      store,
      "homepage",
      {}
    );
    return passDataToAuthService(
      dispatch,
      credentials,
      (socialLoginFlow ? SocialAuthService : AuthService).completerRegistration,
      response => {
        if (response.success) {
          dispatch(loginCompleteCheckoutFirstStep());
          if (!otpLoginFlowBFL) {
            passDataToAnalytics(
              response,
              socialLoginFlow ? analyticsData : registrationData,
              getState
            );
          }
        }
      }
    );
  };

export const getUsedAccounts = data => async (dispatch, getState) => {
  const store = getState();
  const { socialLoginFlow } = get(store, "homepage", {});
  return passDataToAuthService(
    dispatch,
    data,
    (socialLoginFlow ? SocialAuthService : AuthService)
      .deactivatePreviousAccounts
  );
};

export const deactivatePreviousAccounts =
  (credentials, registrationData) => async (dispatch, getState) => {
    const store = getState();
    const { socialLoginFlow } = get(store, "homepage", {});
    return passDataToAuthService(
      dispatch,
      credentials,
      (socialLoginFlow ? SocialAuthService : AuthService).completerRegistration,
      response => {
        if (response.success) {
          dispatch(loginCompleteCheckoutFirstStep());
          passDataToAnalytics(response, registrationData, getState);
        }
      }
    );
  };

export const loginAndFetchUser = response => async (dispatch, getState) => {
  const store = getState();
  const { otpLoginFlowBFL } = store.homepage;
  let { analyticsData } = store.homepage;

  if (response.customerId && !analyticsData.resp?.data?.customerId) {
    set(analyticsData, "resp.data.customerId", response.customerId);
  }
  if (!otpLoginFlowBFL) {
    // Social auto login after user registeration through social
    GAService.auth.trackLoginSuccess({
      isLoggedIn: true,
      user_id: get(response, "customerId", ""),
      mode: get(analyticsData, "loginFrom", "")
    });
    AnalyticService.auth.trackSocialLogin(analyticsData);
  }
  dispatch(userProfile(response));
  dispatch(setToken(response));
  dispatch(fetchUserAndLoadData());
};

export const updateRegistrationDataStatusOnLogin = response => dispatch => {
  const { token, code } = response;
  dispatch(setRegistrationDataStatus(response));
  if (token) {
    dispatch(setSocialPhoneCheckToken(token));
  }
  if (code) {
    dispatch(setLastRegistrationStatusCode(code));
  }
};
