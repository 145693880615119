import ApiService from "./ApiService";
import { BEConfig } from "../config/env";

import { webapiPost } from "../webapis/core";

class OtpService extends ApiService {
  async sendOtp(data) {
    const response = await webapiPost(
      this.getAccessToken(),
      `${this.getBaseUrl()}/`,
      data,
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  async sendAppDownloadSms(phone) {
    const response = await webapiPost(
      this.getAccessToken(),
      `${this.getBaseUrl()}/app-download-sms`,
      { phone },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  getBaseUrl() {
    const {
      baseURL,
      protocol,
      port,
      versionInfo,
      otpHandle
    } = BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${otpHandle}`;

    return url;
  }
}

const instance = new OtpService();

export default instance;
