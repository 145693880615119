import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { SizeItem } from "../sizeItem";
import { setSize } from "../../../../redux/actions/quickFilter.action";

export const SizeColumn = ({ data, selectedSizes }) => {
  const dispatch = useDispatch();

  return Object.keys(data).map(groupName => (
    <Fragment key={groupName}>
      <h1 className="size-title">{groupName}</h1>
      <div className="d-flex justify-content-center flex-wrap size-column">
        {data[groupName].map(sizeObj => {
          let [sizeName, sizePath] = Object.entries(sizeObj)[0];
          const titleToPrettify = "NEWBORN";
          if (sizeName.toUpperCase().includes(titleToPrettify)) {
            sizeName = sizeName.toUpperCase().replace("NEWBORN", "NEW BORN");
          }
          return (
            <SizeItem
              key={sizeName}
              title={sizeName}
              selected={selectedSizes.includes(sizePath)}
              onClick={() => dispatch(setSize(sizePath))}
            />
          );
        })}
      </div>
    </Fragment>
  ));
};
