import { BEConfig } from "../../config/env";
import { fetchWebApi } from "../../webapis/apiResource";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  showLoaders,
  hideLoaders
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import {
  GET_PRODUCT_DETAILS,
  RESET_PRODUCT_DETAIL,
  GET_PDP_SIZE_CHART,
  HANDLE_SIZE_SELECTION,
  CLEAR_SIZE_SELECTION
} from "../constants";
import { ProductService } from "../../services";

export const getProductDetail = productDetail => ({
  type: GET_PRODUCT_DETAILS,
  productDetail
});

export const getSizeChart = data => ({ type: GET_PDP_SIZE_CHART, data });

export const resetProductDetail = () => ({ type: RESET_PRODUCT_DETAIL });

export const handleSizeSelection = value => ({
  type: HANDLE_SIZE_SELECTION,
  value
});
export const clearSizeSelection = () => ({ type: CLEAR_SIZE_SELECTION });

export const fetchProductDetail = (id, language, ipcountry) => {
  language = languageFromPathName(language);
  return async dispatch => {
    dispatch(showLoaders());

    try {
      const response = await ProductService.getProductById(id, ipcountry);
      if (
        response.status === 200 &&
        response.data &&
        response.data.success !== false
      ) {
        dispatch(getProductDetail(response.data));
      }
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoaders());
    }
  };
};

export const fetchSizeChart = (
  countryId,
  parentId,
  brandId,
  subcatid,
  language
) => {
  const {
    catalogApi: {
      protocol,
      baseURL,
      port,
      versionInfo,
      sizeChartHandle,
      pdpSizeChartHandle
    }
  } = BEConfig;
  const queryText = `countryId=${countryId}&parentId=${parentId}&brandId=${brandId}&subCategoryId=${subcatid}&language=${language}`;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${sizeChartHandle}${pdpSizeChartHandle}?${queryText}`;

  return async dispatch => {
    dispatch(showLoadingIndicator());
    try {
      const response = await fetchWebApi(null, apiURL);
      if (response.status === 200 && response.data) {
        dispatch(getSizeChart(response.data.data));
      }
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };
};
