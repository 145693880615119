import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import cn from "classnames";
import { getDividedNumber } from "../../../util/phoneNumber";
import IntlInput from "../../intl-tel-input/intl-tel-input";
import { selectShowLoader } from "../../../util/selectors";
import { ButtonWithLoader } from "../../generalElements/buttons";

class VerifyPhoneNumber extends Component {
  state = {
    valid: true,
    touched: false,
    areaCode: "",
    phone: ""
  };

  componentDidMount() {
    this.getPhoneNumberDetails();
  }

  handleCountryChange = (status, value) => {
    this.setState({ phone: value, valid: status, touched: true });
  };

  onSelectArea = (areaCode, phone) => {
    this.setState({ areaCode, phone, touched: true });
  };

  getPhoneNumberDetails = () => {
    const {
      phoneNumber,
      countryCodeLength,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    } = this.props;

    const phoneDetails = {
      phone: phoneNumber,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { areaCode } = getDividedNumber(phoneDetails);
    const localCodeLength = (areaCode && areaCode.length) || 0;
    const phoneCodesLength = localCodeLength + countryCodeLength;
    const phoneWithoutCodes = phoneNumber
      ? phoneNumber.substring(phoneCodesLength)
      : "";
    this.setState({
      phone: phoneWithoutCodes,
      areaCode,
      ...(phoneWithoutCodes && { touched: true })
    });
  };

  render() {
    const {
      orderSuccessTranslation,
      countrySHORT,
      countryData,
      mobileLocalNumberLength,
      mobileLocalCode,
      mobileCountryCode,
      onButtonClick,
      buttonText = orderSuccessTranslation.sendOtp,
      header,
      showLoader
    } = this.props;
    const { valid, touched, areaCode } = this.state;
    let { phone } = this.state;
    const phoneDetails = {
      phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { areaCode: phoneAreaCode = areaCode, phoneWithoutCountryCode } =
      getDividedNumber(phoneDetails);

    if (phone?.length > mobileLocalNumberLength) {
      phone = phoneWithoutCountryCode.slice(
        phoneAreaCode && phoneAreaCode.length
      );
    }
    const fullMobileNumber = `${mobileCountryCode}${areaCode}${phone}`;

    return (
      <div className="mobile_verify generic_modal_content">
        {header ? (
          header
        ) : (
          <h2>{orderSuccessTranslation.verifyMobileNumber}</h2>
        )}
        <div className="form-group phone_field">
          <IntlInput
            valid={(valid && touched && phone) || (valid && !touched && !phone)}
            phone={phone || ""}
            handleCountryChange={this.handleCountryChange}
            onSelectFlag={() => {}}
            areaCode={areaCode}
            defaultCountry={countrySHORT}
            countryData={countryData}
            onSelectArea={(area, phone) => this.onSelectArea(area, phone)}
            mobileLocalNumberLength={mobileLocalNumberLength}
          />
          {!valid && touched && (
            <span className="error">
              {orderSuccessTranslation.phoneNotValid}
            </span>
          )}
        </div>
        <ButtonWithLoader
          className={cn("round_btn form_black_btn small_btn", {
            disable_order_btn: !valid || !phone
          })}
          showLoader={showLoader}
          {...(valid && { onClick: () => onButtonClick(fullMobileNumber) })}
        >
          {buttonText}
        </ButtonWithLoader>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  countryData: state.common.countryData,
  countrySHORT: state.common.settings && state.common.settings.countrySHORT,
  mobileLocalCode: get(state, "common.settings.mobileLocalCode"),
  mobileLocalNumberLength: get(
    state,
    "common.settings.mobileLocalNumberLength"
  ),
  mobileCountryCode: get(state, "common.settings.mobileCountryCode"),
  showLoader: selectShowLoader(state)
});

export default connect(mapStateToProps)(VerifyPhoneNumber);
