export const GET_WISHLISTS = "GET_WISHLISTS";
export const SET_WISHLISTS_BASE_DATA = "SET_WISHLISTS_BASE_DATA";
export const SET_WISHLIST_LENGTH = "SET_WISHLIST_LENGTH";
export const SET_WISHLIST_PAGINATION = "SET_WISHLIST_PAGINATION";
export const UPDATE_WISHLISTS = "UPDATE_WISHLISTS";
export const DELETE_WISHLIST = "DELETE_WISHLIST";
export const EMPTY_WISHLIST_CART = "EMPTY_WISHLIST_CART";
export const SELECTED_SIZE = "selectedSize";
export const WISHLIST_BASE_DATA_LOADING = "WISHLIST_BASE_DATA_LOADING";
export const DELETE_WISHLIST_ITEM_LOADING = "DELETE_WISHLIST_ITEM_LOADING";
