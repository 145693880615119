import React from "react";

const MobileInUse = ({ translation, onVerifyClick, onChangeClick }) => (
  <div className="mobile_in_use m-4">
    <h2>{translation.phoneInUse}</h2>
    <p className="my-3 mobile_in_use_description">{translation.pleaseNote}</p>
    <div className="button_container">
      <button
        className="round_btn form_black_btn small_btn w-100"
        onClick={onVerifyClick}
      >
        {translation.verifyPhoneNumber}
      </button>
      <button
        className="round_btn form_black_btn small_btn w-100 mt-3"
        onClick={onChangeClick}
      >
        {translation.changePhoneNumber}
      </button>
    </div>
  </div>
);

export default MobileInUse;
