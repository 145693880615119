export const checkoutTranslation = {
  paymentMethods: {
    CC: {
      title: "Credit Card or Debit Card",
      description: "Pay with visa or mastercard",
      additionalDescription: "Pay with Visa or Mastercard or Mada"
    },
    COD: {
      title: "Cash On Delivery",
      description: "Pay in cash at the time of delivery"
    },
    CBD: {
      title: "Cash Before Delivery",
      description:
        "Our courier will arrive at your location to collect the cash within 24 hours."
    },
    TABBY_PAY_LATER: {
      title: "Tabby - Pay Later",
      description:
        "Get your order first, pay after 14 days. No credit card required, no extra fees"
    },
    TABBY_PAY_INSTALLMENT: {
      title: "Tabby - 4 interest-free payments",
      description: "No interest. All credit and debit cards accepted"
    },

    PAYPAL: {
      title: "Paypal",
      description: "The safer, easier way to pay"
    },
    APPLEPAY: {
      title: "Apple Pay"
    },
    POSTPAY: {
      title: "PostPay",
      description: "No interest. All credit and debit cards accepted"
    },
    TAMARA_PAY_BY_INSTALMENTS: {
      title: "Tamara - Split in up to 4 payments",
      description: "No interest. All credit and debit cards accepted"
    },
    TAMARA_PAY_NOW: {
      title: "Tamara - Pay Now",
      description: ""
    }
  },
  paymentMinimumAmountMessage:
    "Minimum purchase of ${currency} ${amount} is required to use this payment method.",
  paymentMaximumAmountMessage:
    "The maximum amount allowed for this payment method is ${currency} ${amount}",
  savedCards: "Saved cards",
  payUsingDifferentCard: "Pay using a different card",
  payUsingCard: "Pay using a card",
  payUsingSavedCards: "Pay Using Saved Cards",
  saveThisCardForFuture: "Save this card for future use",
  enterCVV: "Enter CVV",
  creditOrDebitCard: "Your saved debit and credit card",
  secureCheckout: "Secure Checkout",
  CCAnyCardUsd: "You can use any international card to pay in USD",
  CCLocalBankLBP:
    "You can use any local bank issued card to pay in LBP. Click 'PLACE ORDER' to continue the payment",
  Details: "Details",
  placeOrder: "PLACE ORDER",
  signInAs: "You are signed in as",
  signIn: "Sign In",
  change: "change",
  loyaltyPoints:
    "Sign In to your account earn loyalty points and win exciting rewards!",
  guestEmailSignText: "Checkout as a guest using just your email address",
  noLoyaltyPointsForGuest:
    "You do not earn any loyalty points when checking out as a guest",
  deliveryDetails: "Delivery",
  reviewDeliveryMethods:
    "Please review your delivery method and address before placing your order",
  standardDelivery: "Standard Delivery",
  standardDeliveryDates:
    "Delivery in 4-5 working days. Free shipping on orders above",
  expressDelivery: "Express Delivery",
  expressDeliveryDates:
    "Delivery in 1-2 working days. Subject to customs clearance",
  pickupFromStoreDelivery: "Pickup from store",
  pickupFromStoreDeliveryDates:
    "Pick your order from a Brand For Less store near you. Free pickup on orders above",
  searchAreaPlaceholder: "Search your area for a nearby store",
  changeAddress: "Change Address",
  addNewAddress: "Add New Address",
  saveAddress: "Save Address",
  selectedAreaPlaceholder: "Your Selected Area",
  verified: "Verified",
  firstName: "First Name",
  lastName: "Last Name",
  fullAddress: "Start typing your address",
  setAsDefaultDeliveryAddress: "Set as default delivery address",
  shopToThisAddress: "Shop to this address",
  cancel: "Cancel",
  payment: "Choose your payment",
  useMy: "Use my",
  credit: "credit",
  saveCards: "Your saved debit and credit card",
  nameOnCard: "Name on card",
  expiresOn: "Expires On",
  cardPromoMessage:
    "You already have an existing coupon code. Would you like to apply a new coupon?",
  visa: "Visa",
  visaEnd: " ending in",
  masterCard: "Mastercard",
  otherPaymentOptions: "Payment methods",
  cardNumber: "Card Number",
  expiryDate: "Expiry Date",
  saveCardForFutureUse: "Save this card for future use",
  saveThisCard: "Save this card",
  haveADiscount: "Have a Discount voucher?",
  myShoppingCart: "My Shopping Cart",
  secure: "100% Secure",
  url: "Brands For Less.com",
  copyright: "Copyright 2019. All Rights Reserved",
  privacyPolicy: "Privacy Policy",
  tnc: "Terms and Conditions",
  emailAddress: "Your Email Address",
  continueAsGuest: "Continue as a guest",
  enterCardOwnerName: "Enter card owner name",
  enterCardNumber: "Enter card number",
  enterVoucherCode: "Enter voucher code",
  apply: "Apply",
  continueButton: "Continue",
  shipToAddress: "Ship to this address",
  AddNewAddress: "Add New Address",
  guestCheckout: "Guest Checkout",
  EmptyVoucher: "Please enter a valid voucher code",
  validVoucher: "Voucher Applied Sucessfully.",
  invalidVoucher: "Invalid voucher code.",
  voucherApplied: "Voucher Already Applied",
  editAddress: "Edit Address",
  addressBook: "Address Book",
  cvvAlert: "The CVV number is the last three digits at the back of your card.",
  addNewCard: "Add Debit/Credit Card",
  additionalPrice: "An additional amount of",
  willBeApplicable: "will be added",
  addAddress: "Please add address.",
  back: "Back",
  workingHours: "Working Hours",
  selected: "Selected",
  cardNumberInvalid: "The card number you entered seems to be invalid",
  selectCity: "Select City/Province",
  cityAreaError: "Select your City/Area",
  enterFirstName: "Enter your first name",
  "address must be at most 250 characters":
    "Maximum of 250 characters are allowed for the Address",
  "address must be at least 15 characters":
    "Address must be minimum of 15 and maximum of 250 characters",
  "address is a required field": "Please enter your address",
  enterValidPhoneNumber: "Enter a valid Phone Number",
  fieldIsRequired: "Field is required",
  deliveryAddress: "DELIVERY ADDRESS",
  proceedToPayment: "Proceed to Payment",
  creditDebitCard: "Credit / Debit Card",
  addCreditDebitCard: "ADD CREDIT / DEBIT CARD",
  addCard: "ADD CARD",
  VisaMasterCardsAccept: "(We accept Visa and Master cards)",
  additionalFeeForService: "The additional fee for this service is",
  enterCvvCode: "Please enter the CVV code",
  card: "Card",
  paymentMethod: "Payment Method",
  addNewPaymentMethod: "ADD NEW PAYMENT METHOD",
  setDefault: "Set as default",
  default: "Default",
  edit: "Edit",
  delete: "Delete",
  subTotal: "Sub - total",
  voucherDiscount: "Voucher Discount",
  total: "Total",
  totalResp: "Total",
  walletCredit: "Wallet Credit",
  delivery: "Delivery",
  weAccept: "We Accept",
  paymentType: "PAYMENT TYPE",
  addAddressBtn: "ADD ADDRESS",
  haveACreditNote: "Have a credit note/discount voucher?",
  couponAndVoucher: "Coupons & Vouchers",
  enterCreditNote: "Enter the credit note / discount voucher",
  enterCode: "Enter code",
  Applybtn: "Apply",
  ApplybtnResp: "Apply",
  avialablePromoCodes: "Available Promo Codes",
  remove: "Remove",
  youSavedAdditional: "You saved additional",
  cardAlreadyExists: "Card Number Already Exists",
  SignInAnotherUser: "Sign in as another user",
  doYouWantToContinue: "Do you want to continue",
  yes: "Yes",
  no: "No",
  address: "Add Address",
  expiryMonth: "Expiry(MM)",
  expiryYear: "Expiry(YYYY)",
  codFee: "COD Fee",
  freeShiping: "Free Shipping",
  onYourOrder: "on your order",
  save: "Save",
  submitValidate: "There were some missing fields while submitting the form",
  freeShipping: "FREE SHIPPING",
  showAllCodes: "Show all codes",
  showLessCodes: "Show less codes",
  addPhoneHeading: "Add your contact number",
  selfPickup: "Collection Points",
  excludingTax: "EXCLUDING IMPORT TAXES (VAT) AND CUSTOMS DUTIES",
  taxNonRefundable: "Custom duties & taxes are non-refundable once charged",
  inStorePickup: "In-store Pickup",
  storeTemporaryUnavailable:
    "This store is temporarily not available for collection",
  paymentPopup1:
    "When you place an order using Cash before Delivery, our courier will arrive at your location to collect the payment.",
  paymentPopup2: "Once the payment is collected, your order will be confirmed.",
  paymentPopup3:
    "Your order will then be processed from our fulfillment center and delivered to your address.",
  enterIdNumber: "Enter your civil id",
  civilId: "Civil ID",
  errorPersonalId: "Please enter your valid civil id",
  selectArea: "Select City/Area",
  selectState: "Select State/Province",
  selectOnlyCity: "Select City",
  selectOnlyArea: "Select Area",
  selectEmirate: "Select your Emirate",
  enterZipCode: "Enter your Zip Code",
  enterValidZipCode: "Enter a valid Zipcode",
  newsLetter:
    "Be the first to know about new arrivals, sales & promos by submitting your email! You can opt out at any time.",
  redirectPrivacyPolicy: "Privacy Policy",
  free: "FREE",
  useThe: "Use the",
  fromMyWallet: "from my wallet",
  invalidCardNumber: "Please enter a valid card number",
  invalidExpiryDate: "Please enter a valid expiry date",
  invalidCvv: "Please enter valid cvv",
  invalidCardDetails: "Please enter valid card details",
  alreadyHaveAccount: "I already have an account",
  signInForFasterCheckout:
    "Sign in for faster checkout, earn loyalty points and win exciting rewards!",
  dontHaveAccount: "Don't have an account?",
  join: "Join",
  endingWith: "ending with",
  Ok: "Ok",
  alert: "Alert !",
  typeHere: "Type here to search!",
  noDataFound: "No data found",
  selectPickUpLocation: "Select pick-up location",
  distance: "Distance",
  kilometers: "km",
  noCollectionPointsForArea: "No collection points available for this area"
};
