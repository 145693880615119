export const SECTION_HEADER_TYPES = {
  HEADER_BOTTOM: "headerBottom",
  HEADER_SHOP_BY_STORE: "headerShopByStore"
};

export const SECTION_HEADER_TYPES_CMS = {
  HEADER_BOTTOM: "HEADER_BOTTOM",
  HEADER_SHOP_BY_STORE: "HEADER_SHOP_BY_STORE"
};

export const SECTION_SLUGS = {
  HEADER: "header",
  CHECKOUT: "checkout",
  PDP: "pdp",
  CART: "cart",
  ORDER_SUCCESS: "order-success"
};

export const SECTION_PDP_TYPES = {
  PDP_TOP: "pdpTop",
  PDP_SECTION_ONE: "pdpSectionOne",
  PDP_SECTION_TWO: "pdpSectionTwo"
};

export const SECTION_PDP_TYPES_CMS = {
  PDP_TOP: "PDP_TOP",
  PDP_SECTION_ONE: "PDP_SECTION_ONE",
  PDP_SECTION_TWO: "PDP_SECTION_TWO"
};

export const SECTION_CART_TYPES = {
  CART_TOP: "cartTop",
  CART_VAT: "cartVat"
};

export const SECTION_CART_TYPES_CMS = {
  CART_TOP: "CART_TOP",
  CART_VAT: "CART_VAT"
};

export const SECTION_CHECKOUT_TYPES = {
  CHECKOUT_TOP: "checkoutTop"
};

export const SECTION_CHECKOUT_TYPES_CMS = {
  CHECKOUT_TOP: "CHECKOUT_TOP"
};

export const SECTION_ORDER_SUCCESS_TYPES = {
  ORDER_SUCCESS_TOP: "orderSuccessTop",
  ORDER_SUCCESS_SECTION_ONE: "orderSuccessSectionOne"
};

export const SECTION_ORDER_SUCCESS_TYPES_CMS = {
  ORDER_SUCCESS_TOP: "ORDER_SUCCESS_TOP",
  ORDER_SUCCESS_SECTION_ONE: "ORDER_SUCCESS_SECTION_ONE"
};
