import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Img from "react-image";
import oopsImage from "../../images/layer-1@2x.png";
import ForMen from "../../images/for-men.jpg";
import ForWomen from "../../images/for-women.jpg";
import ForKids from "../../images/for-kids.jpg";
import { errorListing } from "../../language/error-page/en/error-page";
import { isServer, isMobile } from "../../util";
import { changeLanguage } from "../../redux/actions/common.action";
import { withPageTypeTracking } from "../../hocs";
import ssrConfig from "./ssrConfig";

class ErrorPage extends Component {
  state = {
    errorTranslation: errorListing
  };
  constructor(props) {
    super(props);
    if (props.staticContext) {
      props.staticContext.status = 404;
    }
  }

  UNSAFE_componentWillMount() {
    const { match, language, handleLanguageChange } = this.props;
    if (!isServer) {
      let languageParam =
        (match && match.params && match.params.language) || language;
      if (languageParam.includes("ar-") || languageParam.includes("ar")) {
        languageParam = "ar";
      } else {
        languageParam = "en";
      }
      handleLanguageChange(languageParam);
      this.getlanguage(language);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
    }
  }
  getlanguage = language => {
    const languageResponse = import(
      `../../language/error-page/${language}/error-page`
    );
    languageResponse.then(response => {
      this.setState({
        errorTranslation: response.errorListing
      });
    });
  };
  handleOnClick = event => {
    event.preventDefault();
    this.props.history.goBack();
  };

  buttonText = (is404, is503) => {
    const { errorTranslation } = this.state;
    if (is404 || (is503 && !isServer && isMobile.any())) {
      return errorTranslation.goBack;
    } else {
      return errorTranslation.retry;
    }
  };

  txt = () => {
    const { errorTranslation } = this.state;
    if (!isServer && isMobile.any()) {
      return errorTranslation.prevTextMobile;
    } else {
      return errorTranslation.prevText;
    }
  };

  render() {
    const { errorTranslation } = this.state;
    const { language, settings } = this.props;
    const { is404, is503, is500 } = this.props.isError;
    return (
      <Fragment>
        {(is404 || is503 || is500) && (
          <div className="error-wrap">
            <Img src={oopsImage} alt="Error Found" className="oops-image" />
            <p className="not-found-text">
              {is404
                ? errorTranslation.mainHead
                : is503
                ? errorTranslation.serviceHead
                : errorTranslation.serverErrorHead}
            </p>
            <p className="not-found-info">
              {is404
                ? errorTranslation.headDesc
                : is503
                ? errorTranslation.serviceDesc
                : errorTranslation.serverErrorDesc}
            </p>
            {is500 ? (
              <p className="not-found-info">
                {errorTranslation.serverErrorText}
              </p>
            ) : null}
            <button
              onClick={this.handleOnClick}
              className={`not-found-page-button ${is500 ? "errorBtn" : ""}`}
            >
              {is404 ? <span /> : null} {this.buttonText(is404, is503)}
            </button>
            <p
              className={
                is404 || is500 ? "notDisplay" : "not-found-info btnBelow"
              }
            >
              {this.txt()}
            </p>
          </div>
        )}
        {is404 && (
          <section className="shop-section">
            <div className="shop-section-wrapper">
              <h4 className="shop-section-desc">{errorTranslation.shopMsg}</h4>
              <ul className="image-list">
                <li>
                  <Link
                    to={`/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }/search?categories-0=Women,&sort=`}
                    replace
                  >
                    <Img src={ForWomen} alt="Women" />
                    <h5 className="img-desc">{errorTranslation.shopWomen}</h5>
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }/search?categories-0=Men,&sort=`}
                    replace
                  >
                    <Img src={ForMen} alt="Men" />
                    <h5 className="img-desc">{errorTranslation.shopMen}</h5>
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }/search?categories-0=Kids%20%26%20Babies,&sort=`}
                    replace
                  >
                    <Img src={ForKids} alt="Kids" />
                    <h5 className="img-desc">{errorTranslation.shopKids}</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        )}
      </Fragment>
    );
  }
}

ErrorPage.need = ssrConfig;

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  isError: reduxState.common.errorPage,
  redirectionData: reduxState.page.redirectionData
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withPageTypeTracking
)(ErrorPage);
