export const signUpTranslation = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Your Email",
  show: "Show",
  hide: "Hide",
  male: "Male",
  female: "Female",
  joinNow: "Join Now",
  password: "Password",
  forgotPassword: "Forgot Password?",
  signIn: "Sign In",
  dontHaveAccount: `Don't have an account?`,
  required: "Field is required.",
  passwordRequired: "Password must contain at least 8 characters",
  emailRequired: "Enter a valid Email Address",
  phoneRequired: "Enter a valid Phone Number",
  thankyouHelp1: "Thanks for registering with us!",
  thankyouHelp2:
    "Login using the same credentials you used to register and enjoy shopping with heavy discounts on your favorite brands.",
  forgotPasswordHelp1: "You set a new Password!",
  forgotPasswordHelp2:
    "Sign In using your new password and enjoy shopping with heavy discounts on your favorite brands.",
  newsLetter:
    "Be the first to know about new arrivals, sales & promos by submitting your email! You can opt out at any time.",
  privacyPolicy: "Privacy Policy",
  enterFirstName: "Enter your first name",
  submitValidate: "There were some missing fields while submitting the form",
  phoneInUse: "Sorry, Phone number already in use",
  pleaseNote:
    "Please note that we have found an existing account linked with this phone number. You can go back and create an account with different phone number or click on verify to continue with the same number",
  verifyPhoneNumber: "Verify Phone Number",
  changePhoneNumber: "Change Phone Number",
  forYourInformation:
    "For your information, below are the accounts associated with mobile number",
  clickingOn: "Clicking on",
  createAccount: "Create account",
  willAddNumber:
    "we will add this number to the new account and will be removed from the other account(s).",
  click: "Click",
  here: "here",
  toSetYourPassword: "to set your password."
};
