import React from "react";
import HtmlParser from "../../html-parser";

const Html = ({ title, html }) => {
  return (
    !!title &&
    !!html && (
      <div className={`htmldata`}>
        <p className="table_title">{title}</p>
        <HtmlParser html={html} />
      </div>
    )
  );
};

export default Html;
