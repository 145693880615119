import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ModalBFL from "../modal";
import {
  selectLanguageSlug,
  selectRegistrationDataStatus,
  selectRegistrationDataCode,
  selectRouteSlug,
  selectSignUpLoginToken,
  selectSocialPhoneCheckToken,
  selectSocialLoginFlow,
  selectLastRegistrationStatusCode,
  selectRegistrationError,
  selectShowLoader
} from "../../../util/selectors";
import SendOTP from "./send-otp";
import MobileInUse from "./mobile-in-use";
import ReAttachNumber from "./reattach-number";
import VerifyPhoneNumber from "./verifyPhoneNumber";
import {
  startRegistration,
  resetRegistrationDataStatus,
  getUsedAccounts,
  deactivatePreviousAccounts,
  checkMobileStatus,
  resetSocialLoginFlow,
  loginAndFetchUser,
  verifyReceivedOtp,
  resetRegistrationError
} from "../../../redux/actions/homepage.action";
import {
  IS_AUTH_FROM_CHECKOUT,
  SIGNUP_API_CODE_INDEX,
  SIGNUP_API_CODE_NAMES,
  SIGNUP_CODES_FOR_MODAL
} from "../../../constants";
import { GAService } from "../../../services/GA-service";

const SocialMobileNumberModal = () => {
  const [translations, setTranslations] = useState({
    orderSuccessTranslation: {},
    signUpTranslation: {},
    loginTranslation: {}
  });
  const { orderSuccessTranslation, signUpTranslation, loginTranslation } =
    translations;
  const [otpValid, setOtpValid] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const [mobileModalStep, setMobileModalStep] = useState(
    SIGNUP_API_CODE_INDEX.ALREADY_IN_USE
  );

  const language = useSelector(selectLanguageSlug);
  const routeSlug = useSelector(selectRouteSlug);
  const registrationDataStatus = useSelector(selectRegistrationDataStatus);
  const registrationDataCode = useSelector(selectRegistrationDataCode);
  const signUpLoginToken = useSelector(selectSignUpLoginToken);
  const socialPhoneCheckToken = useSelector(selectSocialPhoneCheckToken);
  const socialLoginFlow = useSelector(selectSocialLoginFlow);
  const lastRegistrationStatusCode = useSelector(
    selectLastRegistrationStatusCode
  );
  const registrationError = useSelector(selectRegistrationError);
  const showLoader = useSelector(selectShowLoader);

  const [showModal, setShowModal] = useState(!isEmpty(registrationDataStatus));

  const dispatch = useDispatch();
  const history = useHistory();

  const resendOtp = () => {
    dispatch(
      startRegistration({ token: socialPhoneCheckToken, phone: phoneNumber })
    );
    dispatch(resetRegistrationError());
    setOtpCode("");
    setOtpValid(true);
    setSendOtpCount(prevCount => prevCount + 1);
  };

  const startRegistrationProcess = () => {
    dispatch(
      startRegistration({ token: socialPhoneCheckToken, phone: phoneNumber })
    );
    setOtpCode("");
    setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_OTP);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onModalClose = () => {
    closeModal();
    dispatch(resetRegistrationError());
    setSendOtpCount(0);
    setOtpValid(true);
    dispatch(resetRegistrationDataStatus());
    dispatch(resetSocialLoginFlow());
    setMobileModalStep(null);
  };

  const onSuccessRegistration = response => {
    onModalClose();
    dispatch(loginAndFetchUser(response));
    let redirectionLink = `/${routeSlug}/`;
    if (localStorage.getItem(IS_AUTH_FROM_CHECKOUT)) {
      redirectionLink = `/${routeSlug}/checkout/${localStorage.getItem(
        "CHECKOUT_ID"
      )}/`;
    }
    history.push(redirectionLink);
  };

  const verifyOtpGetAccountsList = async () => {
    let otpValid = false;

    if (lastRegistrationStatusCode === SIGNUP_API_CODE_NAMES.OTP_REQUIRED) {
      const response = await dispatch(
        verifyReceivedOtp({ token: signUpLoginToken, otp: otpCode })
      );
      if (get(response, "success")) {
        GAService.auth.trackOTPVerificationSuccess();
        onSuccessRegistration(response);
      }
    } else {
      const response = await dispatch(
        getUsedAccounts({
          token: signUpLoginToken,
          otp: otpCode
        })
      );
      if (get(response, "accounts_using_phone_number")) {
        otpValid = true;
        setMobileModalStep(SIGNUP_API_CODE_INDEX.REATTACH_NUMBER);
      }
    }

    setOtpValid(otpValid);
  };

  const deactivateAccounts = async () => {
    const response = await dispatch(
      deactivatePreviousAccounts({
        token: signUpLoginToken
      })
    );
    if (get(response, "success")) {
      onSuccessRegistration(response);
    }
  };

  const verifyPhone = phone => {
    setPhoneNumber(phone);
    dispatch(checkMobileStatus({ token: socialPhoneCheckToken, phone }));
  };

  const setMobileModalStepNumber = () => {
    switch (registrationDataCode) {
      case SIGNUP_API_CODE_NAMES.OTP_REQUIRED:
        startRegistrationProcess();
        break;
      case SIGNUP_API_CODE_NAMES.MOBILE_REQUIRED:
        setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_NUMBER);
        setPhoneNumber("");
        break;
      case SIGNUP_API_CODE_NAMES.MOBILE_EXISTS_ANOTHER_ACCOUNT:
        setMobileModalStep(SIGNUP_API_CODE_INDEX.ALREADY_IN_USE);
        break;
      default:
        break;
    }
  };

  const onChangeClick = () => {
    setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_NUMBER);
  };

  const modalContentComponents = [
    <MobileInUse
      translation={signUpTranslation}
      onVerifyClick={startRegistrationProcess}
      onChangeClick={onChangeClick}
    />,
    <SendOTP
      orderSuccessTranslation={orderSuccessTranslation}
      phone={phoneNumber}
      otp={otpCode}
      handleOTPChange={value => {
        setOtpCode(value);
      }}
      verifyOtp={verifyOtpGetAccountsList}
      otpValid={otpValid}
      sendOtp={resendOtp}
      sendOtpCount={sendOtpCount}
      errorMessage={registrationError}
      showLoader={showLoader}
    />,
    <ReAttachNumber
      translation={signUpTranslation}
      phoneNumber={phoneNumber}
      linkedEmails={registrationDataStatus.accounts_using_phone_number || []}
      onReattachClick={deactivateAccounts}
      buttonActionText={loginTranslation.confirm}
    />,
    <VerifyPhoneNumber
      orderSuccessTranslation={orderSuccessTranslation}
      onButtonClick={verifyPhone}
      buttonText={orderSuccessTranslation.update}
      phoneNumber={phoneNumber}
      header={
        <>
          <h2>{loginTranslation.addYourNumber}</h2>
          <p className="text-center pb-2">
            {loginTranslation.pleaseEnterNumber}
          </p>
        </>
      }
    />
  ];

  const [modalContent, setModalContent] = useState(
    modalContentComponents[mobileModalStep]
  );

  const resetRegistrationFlow = () => {
    dispatch(resetRegistrationDataStatus());
  };

  useEffect(() => {
    const loadTranslation = async () => {
      const responses = await Promise.all([
        import(`../../../language/orderSuccess/${language}/orderSuccess`),
        import(`../../../language/signUp/${language}/signUp`),
        import(`../../../language/login/${language}/login`)
      ]);
      const { orderSuccessTranslation, signUpTranslation, loginTranslation } =
        responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
      setTranslations({
        orderSuccessTranslation,
        signUpTranslation,
        loginTranslation
      });
    };
    loadTranslation();
  }, [language]);

  useEffect(() => {
    setModalContent(modalContentComponents[mobileModalStep]);
  }, [
    mobileModalStep,
    translations,
    phoneNumber,
    otpCode,
    otpValid,
    socialLoginFlow,
    socialPhoneCheckToken,
    registrationError,
    sendOtpCount,
    showLoader
  ]);

  useEffect(() => {
    const otpVerificationAttempts =
      !isEmpty(registrationDataStatus) &&
      mobileModalStep === SIGNUP_API_CODE_INDEX.VERIFY_OTP;
    if (otpVerificationAttempts) {
      setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_OTP);
    } else if (
      (registrationDataCode ||
        registrationDataStatus.hasOwnProperty("success")) &&
      !registrationDataStatus.success
    ) {
      setMobileModalStepNumber();
      if (!SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code]) {
        setPhoneNumber("");
        setShowModal(false);
      }
    }
    if (
      !isEmpty(registrationDataStatus) &&
      SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code] &&
      !showModal
    ) {
      setShowModal(true);
    }
  }, [registrationDataCode, registrationDataStatus]);

  useEffect(() => {
    resetRegistrationFlow();
    dispatch(resetRegistrationError());
  }, []);

  return (
    <ModalBFL
      showModal={showModal}
      closeModal={onModalClose}
      modalClass={cn(
        {
          arabic: language.includes("ar")
        },
        "generic_modal mobile_verification_modal wide_generic_modal"
      )}
    >
      {modalContent}
      <button className="cross_btn" onClick={onModalClose}>
        &times;
      </button>
    </ModalBFL>
  );
};

export default SocialMobileNumberModal;
