import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setCommonData,
  setGeneralInfo,
  setIPCountryData
} from "../../../redux/actions/common.action";
import { SECTION_HEADER_TYPES } from "../../../constants/sections";
import { selectHeaderBannerSections } from "../../../util/selectors";
import DynamicBannersWrapper from "../../dynamic/dynamic-banner";
import { fetchHomepageSection } from "../../../redux/actions/page.action";
import { selectRouteSlug } from "../../../util/selectors";
import { LANGUAGE_ROUTE_KEY_MAP } from "../../../constants";
import { Image } from "../../image";

const BrandMenu = () => {
  const shopByStore = useSelector(state => selectHeaderBannerSections(state));
  const brandStores = shopByStore[SECTION_HEADER_TYPES.HEADER_SHOP_BY_STORE];

  if (!brandStores) return null;

  return (
    <div className="rest_nav_top top_brands_menu">
      <div className="store_list">
        {brandStores.map((row, key) => (
          <DynamicBannersWrapper
            section={row}
            key={key}
            bannerPageTitle={row.sectionTitle}
          />
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchHomepageSection,
  setCommonData,
  setGeneralInfo,
  setIPCountryData
};

export default withRouter(connect(null, mapDispatchToProps)(BrandMenu));
