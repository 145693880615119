import qs from "query-string";
import isArray from "lodash/isArray";

import { BEConfig } from "../config/env";
import { generateUUID } from "../util";

import { webapiGet } from "../webapis/core";

import ApiService from "./ApiService";

class CommonService extends ApiService {
  async getAllPromotions(config = {}) {
    const response = await webapiGet(
      this.getAccessToken(),
      `${this.getBaseUrl()}promotion?${qs.stringify(config)}`
    ).request;

    return response.data;
  }

  async getPromotion(name, config = {}) {
    const response = await webapiGet(
      this.getAccessToken(),
      `${this.getBaseUrl()}promotion/${name}?${qs.stringify(config)}`
    ).request;

    return response.data;
  }

  async getIpcountry() {
    const response = await webapiGet(
      this.getAccessToken(),
      `${this.getBaseUrl()}common/ipcountry`,
      this.getVisitorId()
    ).request;

    const { ipcountry } = response.data;

    return ipcountry;
  }

  async getCountryList(language) {
    const { settingsHandle } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${settingsHandle}`;
    const queryParams = qs.stringify({
      language
    });
    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      generateUUID()
    ).request;

    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  async getShortCountryList(language) {
    const { settingsHandle, shortCountryList } = BEConfig.commonApi;

    const apiURL = `${this.getBaseUrl()}${settingsHandle}${shortCountryList}`;
    const queryParams = qs.stringify({
      language
    });
    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      generateUUID()
    ).request;

    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  async getCountriesMobileDetails() {
    const { settingsHandle, mobileDetails } = BEConfig.commonApi;
    const apiURL = `${this.getBaseUrl()}${settingsHandle}`;
    const endpoint = `${apiURL}${mobileDetails}`;
    const response = await webapiGet(this.getAccessToken(), endpoint).request;

    if (!isArray(response.data)) throw new Error(response);

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.commonApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new CommonService();

export default instance;
