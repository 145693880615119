import { FREE_SHIPPING_VALUE } from "../../constants";

export const isFreeShippingForced = (freeShippingLimit, shippingCharge) =>
  !shippingCharge && freeShippingLimit === FREE_SHIPPING_VALUE;

export const getShippingCharge = (
  freeShippingLimit,
  shippingCharge,
  subTotal
) => {
  if (
    isFreeShippingForced(freeShippingLimit, shippingCharge) ||
    (freeShippingLimit && subTotal >= freeShippingLimit)
  ) {
    return 0;
  }
  return shippingCharge;
};

export const shippingMayBeFree = (freeShippingLimit, shippingCharge) =>
  Boolean(shippingCharge && freeShippingLimit !== FREE_SHIPPING_VALUE);

export const isFreeShippingReached = (freeShippingLimit, total) =>
  total >= freeShippingLimit;
