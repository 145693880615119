import React from "react";
import { DEFAULT_ITEM_QUANTITY } from "../../../constants";

const ProductSizeInfo = ({ translation, item }) => {
  const itemQuantity = item.quantity || DEFAULT_ITEM_QUANTITY;

  return (
    <div className="product_size_info">
      <p className="quantity_in_cart">
        {translation.quantity}:<span>{itemQuantity}</span>
      </p>
      {item.size && (
        <p className="size_in_cart">
          {translation.size}:<span>{item.size}</span>
        </p>
      )}
    </div>
  );
};

export default ProductSizeInfo;
