import axios from "axios";
import get from "lodash/get";
import { terminateSession } from "../../redux/actions/authConfig.action";
import {
  hideErrorPage,
  showErrorPage
} from "../../redux/actions/common.action";
import { removeUserEmailStorage, removeUserStorage } from "../browserStorage";

export const handleFetchError = (error, dispatch) => {
  const errorStatus = get(error, "response.status");

  if (dispatch && errorStatus && errorStatus >= 400) {
    dispatch(hideErrorPage());
    switch (errorStatus) {
      case 404:
        dispatch(showErrorPage("is404", true));
        break;

      case 409:
        removeUserStorage(document.domain);
        removeUserEmailStorage();
        dispatch(terminateSession());
        break;

      default:
        dispatch(showErrorPage("is503", true));
    }
  }

  if (axios.isCancel(error)) {
    console.error("Request cancelled:", error.message);
  } else {
    console.log(error);
  }
};

export const internalServerError = (res, dispatch) => {
  if (res.data && res.data.success === false && dispatch) {
    dispatch(hideErrorPage());
    dispatch(showErrorPage("is500", true));
  }
};

export const errorOnUserSide = errorCode =>
  errorCode && errorCode >= 400 && errorCode < 500;
