import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Headroom from "react-headroom";
import classnames from "classnames";
import get from "lodash/get";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import memoize from "memoize-one";

import {
  getBrandsData,
  changeLanguage,
  setSearchText,
  resetBrandsReducer,
  saveBreadcrumb,
  resetBreadCrumb
} from "../../redux/actions/common.action";
import { isServer } from "../../util";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

import { selectRouteSlug, selectLanguageSlug } from "../../util/selectors";

import { Image } from "../../components/image";
import TitleHeader from "../../components/brands/brands-header";
import CategoriesTabs from "../../components/brands/brands-categoriesTabs";
import Brands from "../../components/brands/brands-content";
import Page from "../../components/page";
import Breadcrumb from "../../components/breadcrumb";

import { shopByBrandsTranslation } from "../../language/shopByBrands/en/shopByBrands";

import brandPageLoadingImage from "../../images/brandPageLoadingImage.jpg";

import { selectBrandsData } from "./selectors";

import ssrConfig from "./ssrConfig";

class ShopByBrands extends Component {
  state = {
    selectedCategory: "All",
    selectedBrandChar: "A",
    shopByBrandsTranslation: shopByBrandsTranslation,
    yScrollPosition: 0
  };

  // TODO: research a way to remove deprecated methods without breaking breadcrumb
  UNSAFE_componentWillMount() {
    if (isServer) return null;

    const { match, getBrandsData, language, handleLanguageChange } = this.props;
    const { shopByBrandsTranslation } = this.state;

    const languageParam = match.params.language || language;
    const nextLanguage = languageParam.includes("ar") ? "ar" : "en";

    getBrandsData(nextLanguage);
    handleLanguageChange(nextLanguage);
    this.loadTranslation(nextLanguage);
    this.setBreadCrumb(shopByBrandsTranslation);
  }

  // TODO: research a way to remove deprecated methods without breaking breadcrumb
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getBrandsData, language } = this.props;

    if (nextProps.language !== language) {
      getBrandsData(nextProps.language);
      this.loadTranslation(nextProps.language);
    }
  }

  componentWillUnmount() {
    const { resetBrandsReducer } = this.props;

    resetBrandsReducer();
  }

  changeCategory = category => {
    const { brands } = this.props;

    const data = this.getCategoryData(brands, category);
    const sortedCategories = this.getSortedCategories(data);

    this.setState({
      selectedCategory: category,
      // TODO: we need to check for cases when the array is empty
      selectedBrandChar: sortedCategories.shift()
    });
  };

  scrollHandler = (event, key) => {
    if (event) {
      event.preventDefault();
    }

    const { yScrollPosition: previousYScrollPosition } = this.state;

    const targetDiv = document.querySelector(`#section${key}`);
    const height = document.querySelector(
      ".shop_all_brands_wrapper .headroom-wrapper"
    ).scrollHeight;

    let currentYScrollPosition;
    if (targetDiv) {
      currentYScrollPosition = targetDiv.offsetTop - height + 92;
    }
    this.setState({ yScrollPosition: currentYScrollPosition });

    if (
      previousYScrollPosition !== 0 &&
      previousYScrollPosition > currentYScrollPosition
    ) {
      currentYScrollPosition = currentYScrollPosition - 194;
    }
    window.scroll({
      top: currentYScrollPosition,
      left: 0,
      behavior: "smooth"
    });
    this.setState({ selectedBrandChar: key });
  };

  getCategoryData = memoize(
    (brands, selectedCategory) =>
      Array.isArray(brands) &&
      brands.find(brand => brand.seoName === selectedCategory)
  );

  loadTranslation = async language => {
    const data = await import(
      `../../language/shopByBrands/${language}/shopByBrands`
    );

    const { shopByBrandsTranslation } = data;

    this.setState({
      shopByBrandsTranslation
    });
    this.setBreadCrumb(shopByBrandsTranslation);
  };

  setBreadCrumb = translation => {
    const { saveBreadcrumb, resetBreadCrumb, routeSlug } = this.props;

    resetBreadCrumb();

    saveBreadcrumb({
      modelName: "brands",
      modelData: {
        brandsName: translation.brands,
        brandsUrl: `/${routeSlug}/brands/`
      }
    });
  };

  getSortedCategories = categoryData => {
    const num = [];
    const str = [];

    const categories = Object.keys(get(categoryData, "brands", {}));

    categories.forEach(item => (isNaN(item) ? str.push(item) : num.push(item)));

    const sortCategories = [...str, ...num];
    return sortCategories;
  };

  getData = memoize((brands, selectedCategory, searchText) => {
    const categoryData = this.getCategoryData(brands, selectedCategory);

    const tabsData =
      Array.isArray(brands) &&
      brands.map(brand => ({
        name: brand._id,
        seoName: brand.seoName
      }));

    const filteredCategoryData =
      searchText && get(categoryData, "brands")
        ? Object.keys(categoryData.brands).map(key =>
            categoryData.brands[key].filter(data =>
              data.name.toLowerCase().startsWith(searchText.toLowerCase())
            )
          )
        : [];

    const filteredResult = clone(
      filteredCategoryData.find(filteredData => !isEmpty(filteredData)) || []
    );

    const sortCategories = this.getSortedCategories(categoryData);

    return { sortCategories, filteredResult, categoryData, tabsData };
  });

  render() {
    const { brands, showLoader, language, setSearchText, searchText } =
      this.props;
    const { selectedCategory, selectedBrandChar, shopByBrandsTranslation } =
      this.state;
    const { sortCategories, filteredResult, categoryData, tabsData } =
      this.getData(brands, selectedCategory, searchText);
    const meta = {
      [language]: {
        metaDescription: shopByBrandsTranslation.metaDescription
      }
    };

    return (
      <Page
        title={shopByBrandsTranslation.ourBrands}
        meta={meta}
        language={language}
        id="shop-by-brands"
        indexable
      >
        <Breadcrumb />
        {!showLoader && !isEmpty(brands) ? (
          <div
            className={classnames("shop_all_brands_wrapper", {
              arabic: language === "ar"
            })}
          >
            <TitleHeader
              count={get(categoryData, "count", 0)}
              shopByBrandsTranslation={shopByBrandsTranslation}
            />
            <Headroom disableInlineStyles>
              <CategoriesTabs
                tabs={tabsData}
                selectedTab={selectedCategory}
                selectedBrandChar={selectedBrandChar}
                categoryData={sortCategories}
                changeCategory={this.changeCategory}
                scrollHandler={this.scrollHandler}
                searchText={searchText}
                setSearchText={setSearchText}
                shopByBrandsTranslation={shopByBrandsTranslation}
              />
            </Headroom>
            <div className="brands-wrapper">
              <div className="container padding-web-0">
                {searchText ? (
                  <Brands
                    groupName={searchText.charAt(0).toUpperCase()}
                    data={filteredResult}
                    selectedCategory={selectedCategory}
                  />
                ) : (
                  get(categoryData, "brands") &&
                  sortCategories.map(key => (
                    <Brands
                      key={key}
                      groupName={key}
                      data={categoryData.brands[key]}
                      selectedCategory={selectedCategory}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        ) : (
          <Image
            style={{ width: "100%" }}
            src={brandPageLoadingImage}
            atl="loading image"
          />
        )}
      </Page>
    );
  }
}

ShopByBrands.need = ssrConfig;

const mapStateToProps = state => ({
  brands: selectBrandsData(state),
  searchText: state.brandsReducer.searchText,
  showLoader: state.common.showLoader,
  routeSlug: selectRouteSlug(state),
  language: selectLanguageSlug(state)
});

const mapDispatchToProps = {
  getBrandsData,
  handleLanguageChange: changeLanguage,
  setSearchText,
  resetBrandsReducer,
  saveBreadcrumb,
  resetBreadCrumb
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(ShopByBrands);
