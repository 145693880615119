export const productDetail = {
  siteName: "Brands For Less",
  items: "item(s)",
  quantity: "Qty",
  only: "Only",
  itemsInStock: "Items In Stock",
  outOfStock: "Out Of Stock",
  deliveryInfo: "Delivery and returns Info",
  needSizing: "Need sizing help?",
  addToCart: "Add To Cart",
  genuine: "100% genuine",
  brands: "brands",
  cashOn: "Cash on",
  delivery: "delivery",
  free: "FREE SHIPPING",
  collect: "FREE COLLECTION",
  pickup: "FREE INSTORE PICKUP",
  returns: "Returns",
  size: "Size",
  addedToCart: "Added To Cart",
  RecentlyViewed: "You’ve Recently Viewed",
  details: "Details",
  productDetail: "Product Details",
  shippingInfo: "Shipping Info",
  qtyErrText: "Quantity must be less than or equal to",
  off: "off",
  rrp: "RRP",
  rrp_save: "Save",
  zoomText: "Rollover image to zoom",
  viewMore: "View More",
  viewLess: "View Less",
  shippingOptions: "Shipping Options",
  deliverTo: "Deliver to",
  type: "TYPE",
  when: "WHEN?",
  howMuch: "HOW MUCH?",
  howToReturnYourOrder: "How to Return Your Order",
  seeOur: "See our",
  returnsSmall: "returns",
  pageForMoreInfo: "page for more information",
  standardDelivery: "Standard Delivery",
  expressDelivery: "Express Delivery",
  selfPickup: "Collection Points",
  spendOver: "on orders above",
  shippingAbove: "Shipping above",
  similarProduct: "color available",
  similarProducts: "colors available",
  sizeSelectionToProceed: "Select a size to proceed",
  IncOfVat: "Incl. of VAT",
  ShowAllSizes: "Show all Sizes",
  ShowLessSizes: "Show less Sizes",
  sizesShownCountryText:
    "Sizes shown are <b><%= sizes %></b>. Please refer to the <span class='size-chart'>size chart</span>",
  left: "left",
  pieceLeft: "piece left",
  piecesLeft: "pieces left",
  selectQuantity: "Select Quantity",
  sizeGuide: "Size Guide",
  scrollToTop: "Scroll to top",
  deliveryAndShippingInfo: "Delivery and Shipping Information",
  deliveryAndReturn: "Delivery & Returns",
  productInformation: "Product Information",
  shortDescription: "Short Description",
  shopAndEarn: "Shop and earn up to",
  join: "Join",
  signInAndJoin:
    "and join our loyalty program and earn points on your purchase.",
  toEarnPoints: "loyalty program to earn points on your purchase.",
  youWillEarn: "You will earn up to",
  learnMore: "Learn more",
  rewardPoints: "reward points!",
  soldOut: "Sold Out",
  alreadyAddedToCart: "Already Added To Cart",
  selectSize: "Select Size",
  needSizeHelp: "Need sizing help?",
  signIn: "Sign In",
  earnLoyaltyPoints: " to earn loyalty points!",
  loyaltyPoints: "loyalty points when you complete this purchase",
  continueShopping: "Continue Shopping",
  viewCart: "View Cart",
  youCanAddMaxOf: "You can add max",
  youAlreadyAdded: "You already added maximum qty",
  availableForThisItem: "available for this item!",
  itemsMoreInsteadOf: "items more instead of",
  youAlreadyHad: "You already had",
  qtyItemInCart: "qty of this item in your cart",
  nowAdded: "now added",
  moreQty: "more qty!",
  forThisItem: "for this item",
  Ok: "Ok",
  alert: "Alert !",
  alreadyAdded: "You already have",
  selectSizeFirst: "Please select size first",
  Shop: "Shop",
  by: "by",
  availableIn: "Available in",
  bestPriceIn: "Best Price in",
  color: "color",
  theAvailableStock: "The available stock is",
  notAvailableIn: "This product is not available in your country",
  inStorePickUp: "Instore pickup",
  allOrders: "on all the orders",
  deliveryPrice: "FREE",
  viewSimilarStyles: "View Similar Styles",
  retailPrice: "Retail Price",
  tabbyPriceSnippet: {
    description: "4 interest-free payments.",
    description2: " Available for orders above",
    price: "AED 200",
    cartDescription: "Shop now pay later on orders above ",
    learnMore: "Learn more"
  },
  postPayPriceSnippet: {
    description: "3 interest-free payments.",
    description2: " Available for orders above",
    price: "AED 200",
    cartDescription: "Shop now pay later on orders above ",
    learnMore: "Learn more"
  },
  tamaraPriceSnippet: {
    description: "Split in up to 4 payments.",
    description2: " Available for orders above",
    price: "AED 999",
    cartDescription: "Shop now pay later on orders above ",
    learnMore: "Learn more"
  },
  blackTier: "Black Tier",
  comparableValueHeader: "Comparable Value",
  comparableValueInfo:
    "This comparable value is based on what the item, or similar item, was originally offered for at department stores and boutiques, which may have been reported to us by the manufacturer. Prices vary, but we think you’ll love ours.",
  interestFreeInstallments: "Interest - free installment available",
  startsIn: "Starts in",
  endsIn: "Ends in",
  buyNow: "Buy now",
  comingSoon: "Coming soon...",
  saleItemsLeft: "Item(s) left",
  referToSizeChart:
    "Please refer to the <span class='size-chart'>size chart</span>",
  addToWishlist: "add to wishlist",
  model: {
    height: "Height: <%= height %>cm",
    bust: "Bust: <%= bust %>cm",
    waist: "Waist: <%= waist %>cm",
    hips: "Hips: <%= hips %>cm",
    modelSize: "Model is wearing: <%= modelSizeLabel %>"
  }
};
