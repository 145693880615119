import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import AccountOverview from "./account-overview";
import MyDetails from "./my-details";
import MyOrders from "./my-orders";
import MyOrdersVersionReturn from "./my-orders-version-return";
import MyAddressBook from "./my-address-book";
import MyWallet from "./my-wallet";
import LoyaltyProgram from "./loyalty-program";
import SavedCards from "./saved-cards";
import SocialAccount from "./social-account";
import { changeLanguage } from "../../redux/actions/common.action";
import { logOut } from "../../redux/actions/authConfig.action";
import {
  fetchMyOrders,
  fetchEarnedLoyaltyPoints
} from "../../redux/actions/myAccount.action";
import RedeemGiftCard from "./redeem-gift-card";
import FeatureNotAvailable from "../../components/feature-not-available";

class Content extends Component {
  render() {
    const {
      currentTab,
      navText,
      myAccountTranslation,
      checkoutTranslation,
      checkoutTranslationEn,
      selectList,
      resetPasswordTranslation,
      language,
      myAccount,
      orderSuccessTranslation,
      history,
      logOut,
      subTab,
      deliveryOptions,
      noRecordImgUrl,
      handleGoBack,
      settings,
      fetchMyOrders,
      showMyOrdersLoader
    } = this.props;
    let _currentTab = currentTab;
    if (currentTab[currentTab.length - 1] === "/") {
      _currentTab = currentTab.slice(0, -1);
    }
    let contentToDisplay = null;
    switch (_currentTab) {
      case "account-overview":
        contentToDisplay = (
          <AccountOverview
            history={history}
            navText={navText}
            selectList={selectList}
            myAccountTranslation={myAccountTranslation}
            orderSuccessTranslation={orderSuccessTranslation}
            deliveryOptions={deliveryOptions}
            checkoutTranslation={checkoutTranslation}
            checkoutTranslationEn={checkoutTranslationEn}
          />
        );
        break;
      case "my-details":
        contentToDisplay = (
          <MyDetails
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            resetPasswordTranslation={resetPasswordTranslation}
            handleGoBack={handleGoBack}
          />
        );
        break;
      case "my-orders":
        contentToDisplay = settings?.enableReturnVersion ? (
          <MyOrdersVersionReturn
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            language={language}
            myOrders={myAccount.myOrders}
            deliveryOptions={deliveryOptions}
            selectList={selectList}
            noRecordImgUrl={noRecordImgUrl}
            handleGoBack={handleGoBack}
            fetchMyOrders={fetchMyOrders}
            country={
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }
            isShowLoader={showMyOrdersLoader}
          />
        ) : (
          <MyOrders
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            language={language}
            myOrders={myAccount.myOrders}
            deliveryOptions={deliveryOptions}
            selectList={selectList}
            noRecordImgUrl={noRecordImgUrl}
            handleGoBack={handleGoBack}
            fetchMyOrders={fetchMyOrders}
            country={
              (settings &&
                settings.countrySHORT &&
                settings.countrySHORT.toLowerCase()) ||
              "ae"
            }
            isShowLoader={showMyOrdersLoader}
          />
        );
        break;
      case "my-address-book":
        contentToDisplay = (
          <MyAddressBook
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            checkoutTranslation={checkoutTranslation}
            checkoutTranslationEn={checkoutTranslationEn}
            orderSuccessTranslation={orderSuccessTranslation}
            language={language}
            subTab={subTab}
            noRecordImgUrl={noRecordImgUrl}
            handleGoBack={handleGoBack}
          />
        );
        break;
      case "my-wallet":
        contentToDisplay = (
          <MyWallet
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            handleGoBack={handleGoBack}
          />
        );
        break;
      case "loyalty-program":
        contentToDisplay = (
          <LoyaltyProgram
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            handleGoBack={handleGoBack}
          />
        );
        break;
      case "saved-cards":
        contentToDisplay = (
          <SavedCards
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            noRecordImgUrl={noRecordImgUrl}
            checkoutTranslation={checkoutTranslation}
            handleGoBack={handleGoBack}
          />
        );
        break;
      case "redeem-gift-card":
        if (isEmpty(settings)) break;
        contentToDisplay = settings.enableRedeemGiftCard ? (
          <RedeemGiftCard
            navText={navText}
            myAccountTranslation={myAccountTranslation}
            noRecordImgUrl={noRecordImgUrl}
            checkoutTranslation={checkoutTranslation}
            handleGoBack={handleGoBack}
          />
        ) : (
          <FeatureNotAvailable
            message={myAccountTranslation.redeemGiftCardNotAvailable}
            buttonLabel={myAccountTranslation.continueShopping}
          />
        );
        break;
      // task BFL-6503 Need to disable “Social Account” section from the my account page, until the exact functionality is confirmed.
      // case "social-accounts":
      //   contentToDisplay = (
      //     <SocialAccount
      //       navText={navText}
      //       myAccountTranslation={myAccountTranslation}
      //       noRecordImgUrl={noRecordImgUrl}
      //       handleGoBack={handleGoBack}
      //     />
      //   );
      //   break;
      case "need-help":
        history.push(
          `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/need-help`
        );
        break;
      case "sign-out":
        logOut(history);
        break;
      default:
        return null;
    }
    return <>{contentToDisplay && contentToDisplay}</>;
  }
}

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  userInfo: reduxState.authReducer,
  myAccount: reduxState.myAccountReducer,
  deliveryOptions:
    reduxState.common.settings && reduxState.common.settings.deliveryOptions,
  currency:
    reduxState.common &&
    reduxState.common.settings &&
    reduxState.common.settings.currencyCode,
  countryId: get(reduxState, "common.settings.countryId"),
  showMyOrdersLoader: reduxState.myAccountReducer.showMyOrdersLoader
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  fetchMyOrders: bindActionCreators(fetchMyOrders, dispatch),
  fetchEarnedLoyaltyPoints: bindActionCreators(
    fetchEarnedLoyaltyPoints,
    dispatch
  ),
  logOut: bindActionCreators(logOut, dispatch)
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Content)
);
