export const MEMBERSHIP_STYLE_CLASS = {
  Yellow: "yellow",
  Black: "black"
};

export const LOYALTY_MEMBERSHIP_LEVELS = {
  yellow: "yellow",
  black: "black"
};

export const MIN_POINTS_FOR_REDEEM = 1000;
export const DEFAULT_POINTS_FOR_NEXT_LEVEL = 8000;

export const DELIVERY_LOYALTY_TIERS = { Black: "Black", Yellow: "Yellow" };

export const LOYALTY_ORDER_FROM_ONLINE_STORE = "Online Store";
