import React, { Component, Fragment } from "react";
import ApplyCoupons from "../applePay/applePay-couponsVoucher";
import * as priceUtil from "../../util/priceUtil";
class Voucher extends Component {
  render() {
    const {
      checkoutTranslation,
      VoucherCodes,
      isSameVoucherCode,
      isVoucherValid,
      applyVoucher,
      changeVoucher,
      order,
      currencyCode,
      removeAppliedVoucher,
      total,
      handleVoucherToggle,
      openVoucher,
      inValidVoucherMessage,
      binNo
    } = this.props;

    const isAppliedVoucher =
      order.voucherCode && (order && order.discount !== 0) && !binNo;

    return (
      <div
        className={`voucherHeading ${order.discount &&
          order.voucherCode &&
          "removeVoucher"}`}
        ref={WrapperRef => (this.voucherRef = WrapperRef)}
      >
        {isAppliedVoucher ? (
          <Fragment>
            {order &&
              order.discount !== 0 && <span className="green_round_check" />}
            <p
              onClick={
                !(total >= (order && order.discount_limit)) &&
                !order.voucherCode &&
                (() => handleVoucherToggle(true))
              }
              className="voucherApplied"
            >
              <span className="voucherCodeName">{order.voucherCode}</span>
              {order &&
              order.discount &&
              order.discount_type &&
              order.discount_type === "FREE_SHIPPING" ? (
                <span className="green_text">
                  {" "}
                  - {checkoutTranslation.freeShipping}
                </span>
              ) : order && order.discount !== 0 ? (
                <span>
                  {" "}
                  - {checkoutTranslation.youSavedAdditional} {currencyCode}{" "}
                  {priceUtil.shapePriceWithComma(order.discount)}
                </span>
              ) : null}
            </p>
            <span
              className="voucherRemove"
              onClick={() => {
                removeAppliedVoucher();
              }}
            >
              {checkoutTranslation.remove}
            </span>
          </Fragment>
        ) : (
          <p onClick={() => handleVoucherToggle(true)}>
            {checkoutTranslation.haveACreditNote.toUpperCase()}
          </p>
        )}
        {openVoucher && (
          <ApplyCoupons
            handleApplyCoupon={handleVoucherToggle}
            translation={checkoutTranslation}
            VoucherCodes={VoucherCodes}
            isSameVoucherCode={isSameVoucherCode}
            isVoucherValid={isVoucherValid}
            applyVoucher={applyVoucher}
            changeVoucher={changeVoucher}
            order={order}
            inValidVoucherMessage={inValidVoucherMessage}
          />
        )}
      </div>
    );
  }
}

export default Voucher;
