import React from "react";
import cn from "classnames";
import SmallFlashImage from "../../../images/small-flash.svg";

export const SalePersentageBlock = ({ productStock, percentageValue }) => (
  <div
    className={cn("sale-image-block", {
      "unavailable-product": !productStock
    })}
  >
    <img src={SmallFlashImage} alt="small-flash-image" />
    <p>{percentageValue}</p>
  </div>
);
