import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ModalBFL from "../modal";
import {
  selectLanguageSlug,
  selectRegistrationDataStatus,
  selectRegistrationDataCode,
  selectRouteSlug,
  selectSignUpLoginToken,
  selectRegistrationError,
  selectShowLoader
} from "../../../util/selectors";
import SendOTP from "./send-otp";
import MobileInUse from "./mobile-in-use";
import ReAttachNumber from "./reattach-number";
import {
  verifyReceivedOtp,
  startRegistration,
  resetRegistrationDataStatus,
  getUsedAccounts,
  deactivatePreviousAccounts,
  resetRegistrationError
} from "../../../redux/actions/homepage.action";
import {
  SIGNUP_API_CODE_INDEX,
  SIGNUP_API_CODE_NAMES,
  SIGNUP_CODES_FOR_MODAL
} from "../../../constants";
import { GAService } from "../../../services/GA-service";

const MobileNumberModal = ({
  userSignUpPhone,
  data,
  getPhoneNumberParts,
  focusOnPhoneInput
}) => {
  const [translations, setTranslations] = useState({
    orderSuccessTranslation: {},
    signUpTranslation: {}
  });
  const { orderSuccessTranslation, signUpTranslation } = translations;
  const { mobileCountryCode, areaCode } = getPhoneNumberParts(data);
  const [otpValid, setOtpValid] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const [mobileModalStep, setMobileModalStep] = useState(
    SIGNUP_API_CODE_INDEX.ALREADY_IN_USE
  );

  const language = useSelector(selectLanguageSlug);
  const routeSlug = useSelector(selectRouteSlug);
  const registrationDataStatus = useSelector(selectRegistrationDataStatus);
  const registrationDataCode = useSelector(selectRegistrationDataCode);
  const signUpLoginToken = useSelector(selectSignUpLoginToken);
  const registrationError = useSelector(selectRegistrationError);
  const showLoader = useSelector(selectShowLoader);

  const [showModal, setShowModal] = useState(!isEmpty(registrationDataStatus));

  const dispatch = useDispatch();
  const history = useHistory();

  const resendOtp = () => {
    dispatch(startRegistration({ ...data, isMobileVerified: false }));
    dispatch(resetRegistrationError());
    setOtpCode("");
    setOtpValid(true);
    setSendOtpCount(prevCount => prevCount + 1);
  };

  const startRegistrationProcess = () => {
    dispatch(startRegistration({ ...data, isMobileVerified: false }));
    setOtpCode("");
    setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_OTP);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onModalClose = () => {
    closeModal();
    dispatch(resetRegistrationError());
    resetRegistrationFlow();
    setSendOtpCount(0);
    setOtpValid(true);
    setMobileModalStep(null);
  };

  const onChangeClick = () => {
    onModalClose();
    focusOnPhoneInput();
  };

  const onSuccessRegistration = response => {
    onModalClose();
    history.push(`/${routeSlug}/registration/thank-you`);
  };

  const verifyOtpGetAccountsList = async () => {
    let otpValid = false;
    if (registrationDataStatus.code === SIGNUP_API_CODE_NAMES.OTP_REQUIRED) {
      const response = await dispatch(
        verifyReceivedOtp({ token: signUpLoginToken, otp: otpCode }, data)
      );
      if (get(response, "success")) {
        GAService.auth.trackOTPVerificationSuccess();
        onSuccessRegistration(response);
      }
    } else {
      const response = await dispatch(
        getUsedAccounts({
          token: signUpLoginToken,
          otp: otpCode
        })
      );
      if (get(response, "accounts_using_phone_number")) {
        otpValid = true;
        setMobileModalStep(SIGNUP_API_CODE_INDEX.REATTACH_NUMBER);
      }
    }
    setOtpValid(otpValid);
  };

  const deactivateAccounts = async () => {
    const response = await dispatch(
      deactivatePreviousAccounts(
        {
          token: signUpLoginToken
        },
        data
      )
    );
    if (get(response, "success")) {
      onSuccessRegistration(response);
    }
  };

  const setMobileModalStepNumber = () => {
    switch (registrationDataStatus.code) {
      case SIGNUP_API_CODE_NAMES.OTP_REQUIRED:
        startRegistrationProcess();
        break;
      case SIGNUP_API_CODE_NAMES.MOBILE_REQUIRED:
        setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_NUMBER);
        break;
      case SIGNUP_API_CODE_NAMES.MOBILE_EXISTS_ANOTHER_ACCOUNT:
        setMobileModalStep(SIGNUP_API_CODE_INDEX.ALREADY_IN_USE);
        break;
      default:
        break;
    }
  };

  const modalContentComponents = [
    <MobileInUse
      translation={signUpTranslation}
      onVerifyClick={startRegistrationProcess}
      onChangeClick={onChangeClick}
    />,
    <SendOTP
      orderSuccessTranslation={orderSuccessTranslation}
      countryCode={mobileCountryCode}
      phone={userSignUpPhone}
      otp={otpCode}
      handleOTPChange={value => {
        setOtpCode(value);
      }}
      verifyOtp={verifyOtpGetAccountsList}
      otpValid={otpValid}
      sendOtp={resendOtp}
      areaCode={areaCode}
      sendOtpCount={sendOtpCount}
      errorMessage={registrationError}
      showLoader={showLoader}
    />,
    <ReAttachNumber
      translation={signUpTranslation}
      phoneNumber={userSignUpPhone}
      linkedEmails={registrationDataStatus.accounts_using_phone_number || []}
      onReattachClick={deactivateAccounts}
    />
  ];

  const [modalContent, setModalContent] = useState(
    modalContentComponents[mobileModalStep]
  );

  const resetRegistrationFlow = () => dispatch(resetRegistrationDataStatus());

  useEffect(() => {
    const loadTranslation = async () => {
      const responses = await Promise.all([
        import(`../../../language/orderSuccess/${language}/orderSuccess`),
        import(`../../../language/signUp/${language}/signUp`)
      ]);
      const { orderSuccessTranslation, signUpTranslation } = responses.reduce(
        (acc, resp) => ({ ...acc, ...resp }),
        {}
      );
      setTranslations({
        orderSuccessTranslation,
        signUpTranslation
      });
    };
    loadTranslation();
  }, [language]);

  useEffect(() => {
    setModalContent(modalContentComponents[mobileModalStep]);
  }, [
    mobileModalStep,
    translations,
    userSignUpPhone,
    otpCode,
    otpValid,
    data,
    sendOtpCount
  ]);

  useEffect(() => {
    if (registrationDataCode && !registrationDataStatus.success) {
      setMobileModalStepNumber();
      !SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code] &&
        setShowModal(false);
    }
    if (
      !isEmpty(registrationDataStatus) &&
      SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code] &&
      !showModal
    ) {
      setShowModal(true);
    }
  }, [registrationDataCode, registrationDataStatus]);

  useEffect(() => {
    resetRegistrationFlow();
    dispatch(resetRegistrationError());
  }, []);

  return (
    <ModalBFL
      showModal={showModal}
      closeModal={onModalClose}
      modalClass={cn(
        {
          arabic: language.includes("ar")
        },
        "generic_modal mobile_verification_modal wide_generic_modal"
      )}
    >
      {modalContent}
      <button className="cross_btn" onClick={onModalClose}>
        &times;
      </button>
    </ModalBFL>
  );
};

export default MobileNumberModal;
