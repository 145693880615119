import template from "lodash/template";
import ReactHtmlParser from "react-html-parser";
import { Image } from "../image";

const ModelDetails = ({ translation, modelProfile }) => {
  const { height, bust, waist, hips, imageUrl, modelSizeLabel } = modelProfile;
  const modelHeight = ReactHtmlParser(
    template(translation.model.height)({
      height
    })
  );
  const modelBust = ReactHtmlParser(
    template(translation.model.bust)({
      bust
    })
  );
  const modelWaist = ReactHtmlParser(
    template(translation.model.waist)({
      waist
    })
  );
  const modelHips = ReactHtmlParser(
    template(translation.model.hips)({
      hips
    })
  );

  const modelSize = ReactHtmlParser(
    template(translation.model.modelSize)({
      modelSizeLabel
    })
  );

  return (
    <div className="model_info_block">
      <div className="image_block">
        <Image className="image" src={imageUrl} />
      </div>
      <div className="details_block">
        <span className="size_text">{modelSize}</span>
        <div className="details_text_block">
          <span>{modelHeight}</span>
          <span>{modelBust}</span>
          <span>{modelWaist}</span>
          <span>{modelHips}</span>
        </div>
      </div>
    </div>
  );
};

export default ModelDetails;
