import React, { useEffect, useState } from "react";
import cn from "classnames";
import { isServer } from "../../../util";

const checkIsScreen4GridOnly = () =>
  !isServer &&
  window.matchMedia("(min-width: 812px) and (max-width: 1024px)").matches;

const ContentHeader = ({
  products,
  translation,
  language,
  onGridChange,
  gridValue,
  isCategoryTitle,
  title,
  searchText,
  productSearchTitle,
  currentSelectedCategory
}) => {
  const [hide6GridOption, setHide6GridOption] = useState(false);
  let headerContent = !!productSearchTitle && productSearchTitle;
  let headerSpanContent = isCategoryTitle
    ? translation?.chooseCategory
    : `${products.nbHits} ${translation?.items}`;

  if (title) {
    headerContent = `${title} ${
      currentSelectedCategory && `: ${currentSelectedCategory}`
    }`;
    headerSpanContent = `${products.nbHits || 0} ${translation?.items}`;
  } else if (searchText) {
    headerContent = `${translation.searchResultsFor} "${searchText}"`;
  }

  useEffect(() => {
    const handleGridOptions = () => {
      if (checkIsScreen4GridOnly()) {
        onGridChange(4);
        setHide6GridOption(true);
      } else {
        setHide6GridOption(false);
      }
    };

    handleGridOptions();
    window.addEventListener("resize", handleGridOptions);

    return () => {
      window.removeEventListener("resize", handleGridOptions);
    };
  }, []);

  const arabic = language.includes("ar");

  return (
    <div className={cn("content_heading", { arabic })}>
      <div
        className={cn("container padding0", {
          "d-flex justify-content-center": hide6GridOption
        })}
      >
        <h1 className={cn({ "p-0": hide6GridOption })}>
          {headerContent}
          <span className="category_count">{headerSpanContent}</span>
        </h1>
        <p className={cn("view_grid", { "d-none": hide6GridOption })}>
          <span
            onClick={() => onGridChange(6)}
            className={cn("grid_6", { selected: gridValue === 6 })}
          />
          <span
            onClick={() => onGridChange(4)}
            className={cn("grid_4", { selected: gridValue !== 4 })}
          />
        </p>
      </div>
    </div>
  );
};
export default ContentHeader;
