import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import get from "lodash/get";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
  selectHomePageApiErrorData,
  selectLoyaltyProgramCurrentBalance,
  selectLoyaltyProgramMonetaryValue
} from "../../util/selectors";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import LoyaltyTable from "../../components/loyalty/loyalty-table";
import Loader from "../../components/loader";
import RedeemLoyalty from "../../components/loyalty/redeem-loyalty-points";
import RedeemLoyaltyMobile from "../../components/loyalty/redeem-loyalty-points/redeem-loyalty-points-mobile";
import LoyaltyGeneralInfo from "../../components/loyalty/loyalty-points-general-info";
import {
  changeLanguage,
  saveBreadcrumb
} from "../../redux/actions/common.action";
import {
  fetchEarnedLoyaltyPoints,
  fetchLoyaltyPointsTable,
  registerLoyaltyProgram
} from "../../redux/actions/myAccount.action";
import { LANGUAGE, LANGUAGE_ROUTE_KEY, LOADER_TYPES } from "../../constants";
import { getNeedHelpData } from "../../redux/actions/needHelp.action";
import HelpAccordion from "../help/accordion";
import { needHelp } from "../../language/needHelp/en/needHelp";
import { withRouter } from "react-router-dom";
import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";
import { isMobile } from "../../util";
import { AnalyticService } from "../../services";

const loaderStyle = {
  position: "relative",
  backgroundColor: "rgb(255 255 255 / 75%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

class LoyaltyProgram extends React.Component {
  state = {
    loyaltyCount: 1,
    needHelpTranslation: needHelp
  };

  UNSAFE_componentWillMount() {
    const {
      currentLanguage,
      settings,
      handleLanguageChange,
      getNeedHelpData,
      fullLanguage
    } = this.props;
    const countryId = get(settings, "countryId", "");

    handleLanguageChange(currentLanguage);
    this.getlanguage(currentLanguage);
    if (countryId) {
      getNeedHelpData(fullLanguage, countryId);
    }
  }

  componentDidMount() {
    const breadcrumbStore = {};
    const {
      pageName,
      saveBreadcrumb,
      fetchLoyaltyPointsTable,
      language,
      currencyCode,
      fetchEarnedLoyaltyPoints,
      countryId,
      isJoinedToLoyaltyProgram,
      customerId,
      loyaltyProgramCurrentBalance,
      loyaltyMonetaryValue
    } = this.props;
    breadcrumbStore.modelName = "category";
    breadcrumbStore.modelData = {
      categoryName: pageName,
      categoryUrl: pageName
    };
    saveBreadcrumb(breadcrumbStore);
    if (countryId && isJoinedToLoyaltyProgram) {
      fetchLoyaltyPointsTable(language, this.state.loyaltyCount, 4, countryId);
    }
    if (currencyCode) {
      fetchEarnedLoyaltyPoints(language, currencyCode, countryId);
    }

    if (loyaltyProgramCurrentBalance) {
      AnalyticService.customer.trackLoyaltyProgramView({
        customerId,
        language,
        countryId,
        smilePoints: loyaltyProgramCurrentBalance,
        loyaltyMonetaryValue,
        currencyCode
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loyaltyProgramCurrentBalance,
      language,
      customerId,
      countryId,
      currencyCode,
      loyaltyMonetaryValue
    } = this.props;
    const prevLoyaltyProgramCurrentBalance =
      prevProps.loyaltyProgramCurrentBalance;

    if (
      isUndefined(prevLoyaltyProgramCurrentBalance) &&
      loyaltyProgramCurrentBalance
    ) {
      AnalyticService.customer.trackLoyaltyProgramView({
        customerId,
        language,
        countryId,
        smilePoints: loyaltyProgramCurrentBalance,
        currencyCode,
        loyaltyMonetaryValue
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getNeedHelpData,
      language,
      settings,
      isJoinedToLoyaltyProgram,
      fetchLoyaltyPointsTable,
      fetchEarnedLoyaltyPoints
    } = this.props;
    const countryId = get(settings, "countryId", "");
    const nextCountryId = get(nextProps, "settings.countryId", "");

    if (nextProps.language !== language || countryId !== nextCountryId) {
      this.getlanguage(nextProps.language);
      if (nextCountryId) {
        getNeedHelpData(nextProps.fullLanguage, nextCountryId);
        if (!nextProps.isLoadingLoyaltyPoints) {
          fetchEarnedLoyaltyPoints(
            nextProps.language,
            nextProps.currencyCode,
            nextCountryId
          );
        }
      }
    }
    if (
      nextProps.isJoinedToLoyaltyProgram &&
      nextProps.isJoinedToLoyaltyProgram !== isJoinedToLoyaltyProgram
    ) {
      fetchLoyaltyPointsTable(
        nextProps.language,
        this.state.loyaltyCount,
        4,
        nextCountryId
      );
    }
  }

  handleLoyaltyCount = () => {
    this.setState({ loyaltyCount: this.state.loyaltyCount + 1 });
  };

  getlanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(
      `../../language/needHelp/${language}/needHelp`
    );
    languageResponse.then(response => {
      this.setState({
        needHelpTranslation: response.needHelp
      });
    });
  };

  onJoinLoyaltyProgram = () => {
    const {
      customerId,
      countryId,
      registerLoyaltyProgram,
      language,
      currencyCode
    } = this.props;

    registerLoyaltyProgram({ customerId, countryId }, language, currencyCode);
  };

  getLoyaltyContent = () => {
    const {
      loyaltyPoints,
      myAccountTranslation,
      apiErrorDetail,
      isJoinedToLoyaltyProgram
    } = this.props;

    const loyaltyDetails = loyaltyPoints.LoyaltyStatics?.loyaltyDetails;

    if (isUndefined(loyaltyDetails)) {
      return (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={loaderStyle}
        />
      );
    } else if (isNull(loyaltyDetails)) {
      return (
        <div className="loyalty-program-join">
          <LoyaltyGeneralInfo
            onJoinLoyaltyProgram={this.onJoinLoyaltyProgram}
            translation={myAccountTranslation}
            errorMessage={apiErrorDetail.message}
          />
        </div>
      );
    } else if (isJoinedToLoyaltyProgram) {
      const isMob = Boolean(isMobile.any());
      return (
        <div className="loyalty_program">
          {isMob ? (
            <RedeemLoyaltyMobile myAccountTranslation={myAccountTranslation} />
          ) : (
            <RedeemLoyalty myAccountTranslation={myAccountTranslation} />
          )}
        </div>
      );
    }
  };

  render() {
    const {
      myAccountTranslation,
      loyaltyPoints,
      needHelpContent,
      handleGoBack,
      settings,
      fetchLoyaltyPointsTable,
      language
    } = this.props;
    const { needHelpTranslation } = this.state;
    const _accordion = get(needHelpContent, "loyaltyProgram.content", []);

    return (
      <div className="overview-wrapper loyalty-program">
        <OverViewHeading
          headingName={myAccountTranslation.loyalty_program}
          icon="my_loyalty_icon"
          handleGoBack={handleGoBack}
        />

        {this.getLoyaltyContent()}

        <div className="transaction_section">
          <LoyaltyTable
            loyaltyInputs={loyaltyPoints.LoyaltyTable || {}}
            myAccountTranslation={myAccountTranslation}
            handleLoyaltyCount={this.handleLoyaltyCount}
            settings={settings}
            fetchLoyaltyPointsTable={fetchLoyaltyPointsTable}
            language={language}
          />
        </div>

        <div className="frequently_questions">
          {_accordion && _accordion.length && (
            <div className="faq_section">
              <h4>{needHelpTranslation.frequentlyAskedQuestions}</h4>
              {_accordion.map(tile => (
                <HelpAccordion
                  key={tile.title}
                  tile={tile}
                  accordionHeading={needHelp.frequentlyAskedQuestions}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  loyaltyPoints: state.myAccountReducer.loyaltyPoints,
  isLoadingLoyaltyPoints: state.myAccountReducer.isLoadingLoyaltyPoints,
  needHelpContent: state.needHelpReducer.needHelpContent,
  settings: state.common.settings,
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  fullLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  customerId: state.authReducer.customerId,
  countryId: state.common.settings.countryId,
  currencyCode: state.common.settings.currencyCode,
  apiErrorDetail: selectHomePageApiErrorData(state),
  loyaltyProgramCurrentBalance: selectLoyaltyProgramCurrentBalance(state),
  loyaltyMonetaryValue: selectLoyaltyProgramMonetaryValue(state)
});

const mapDispatchToProps = dispatch => ({
  fetchLoyaltyPointsTable: bindActionCreators(
    fetchLoyaltyPointsTable,
    dispatch
  ),
  fetchEarnedLoyaltyPoints: bindActionCreators(
    fetchEarnedLoyaltyPoints,
    dispatch
  ),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  getNeedHelpData: bindActionCreators(getNeedHelpData, dispatch),
  saveBreadcrumb(navData) {
    dispatch(saveBreadcrumb(navData));
  },
  registerLoyaltyProgram: bindActionCreators(registerLoyaltyProgram, dispatch)
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withLanguageFromUrl
)(LoyaltyProgram);
