export const contactUs = {
  yourName: "Your Name",
  emailID: "Email ID",
  phoneNumber: "Phone Number",
  sendNow: "Send Now",
  yourMessage: "Your message",
  address: "Address",
  phone: "Phone",
  email: "Email",
  brandsForLessLLC: "Brands For Less LLC",
  pOBoxNo80794Dubai: "P.O Box No: 80794, Dubai",
  conatctUs: "Contact Us",
  sendUsMessage: "Send Us Message",
  howCanWeHelpYou: "How can we help you",
  wedLoveToHearFromYou: "We’d love to hear from you",
  yourConcernRelatedTo: "Your concern related to"
};
