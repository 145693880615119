import React from "react";
import SyteTooltipCloseIcon from "../../../images/syte-tooltip-cross.svg";

const SyteTooltip = ({ onCrossClick, showCrossIcon, translation }) =>
  translation && (
    <div className="syte-discovery-tooltip">
      <div className="close-icon-container d-flex pt-2 px-2">
        {showCrossIcon && (
          <img
            src={SyteTooltipCloseIcon}
            alt="close tooltip"
            onClick={onCrossClick}
          />
        )}
      </div>
      <div className="tooltip-content px-3">
        <p className="mb-0">{translation.new}</p>
        <p className="mb-2">{translation.visualSearch}</p>
        <p>{translation.takePhotoOrUpload}</p>
      </div>
    </div>
  );

export default SyteTooltip;
