import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteWishListData,
  saveToWishList
} from "../../redux/actions/wishList.action";
import {
  selectAuthReducer,
  selectCommonSettings,
  selectLisaMinimized,
  selectRouteSlug
} from "../../util/selectors";
import {
  AnalyticService,
  CartService,
  LiSACommunicationService,
  LiSAService
} from "../../services";
import {
  fetchCartData,
  getCartAndWishlistGeneralData
} from "../../redux/actions/cart.action";
import {
  setLisaMinimized,
  setLisaPlayerIsActive
} from "../../redux/actions/lisa.action";
import {
  showLoadingIndicator,
  toastMsg
} from "../../redux/actions/common.action";
import {
  getActionString,
  getItemDetails,
  getLanguageSlugStore,
  getLisaMinimized,
  getLisaSectionConfig,
  getRouteSlugStore,
  hasStringifiedData,
  importTranslation,
  parseItemDataFromString
} from "../../util/lisa";
import { commonLoaderStyle, LOADER_TYPES, TOAST_TYPES } from "../../constants";
import { isMobile, getWishListGeneralDataStore, isPresent } from "../../util";
import { GAService } from "../../services/GA-service";
import Loader from "../loader";

export const lisaNavigate = ({ history, route, dispatch, library }) => {
  dispatch(setLisaMinimized(true));
  library.player.minimize();
  history.push(route);
};

export const LisaFrameConfigContainer = ({ customData, children }) => {
  const dispatch = useDispatch();
  const [lisaPlayer, setLisaPlayer] = useState(null);
  const [lisaLoading, setLisaLoading] = useState(false);
  const location = useLocation();
  const [initLocation] = useState(location.pathname);
  const history = useHistory();
  const routeSlug = useSelector(selectRouteSlug);
  const authReducer = useSelector(selectAuthReducer);
  const commonSettings = useSelector(selectCommonSettings);
  const lisaMinimized = useSelector(selectLisaMinimized);
  const languageSlug = useSelector(getLanguageSlugStore);

  const saveItemToWishList = async item => {
    await dispatch(saveToWishList(item));
    const wishlistData = getWishListGeneralDataStore();
    AnalyticService.cart.trackAddingToWishlist({
      item,
      wishlistData,
      authReducer,
      commonSettings
    });
    GAService.cart.trackAddProductToWishList({
      item
    });

    dispatch(getCartAndWishlistGeneralData());
  };

  const deleteFromWishList = async (productId, size = "") => {
    const wishListBaseData = getWishListGeneralDataStore();
    const wishListItem =
      !isEmpty(wishListBaseData) &&
      wishListBaseData.find(
        item => item.productId === productId && item.size === size
      );
    await dispatch(
      deleteWishListData(productId, (wishListItem && wishListItem._id) || "")
    );
    dispatch(getCartAndWishlistGeneralData());

    AnalyticService.cart.trackDeleteWishlist({
      item: wishListItem,
      wishList: wishListBaseData,
      authReducer,
      commonSettings
    });
  };

  const lisaConfigure = translations => {
    const { upcomingTitle, liveTitle, recordedTitle, ...otherData } =
      customData;
    LiSAService.libReInit({
      language: languageSlug,
      sections: getLisaSectionConfig(translations, {
        upcomingTitle,
        liveTitle,
        recordedTitle
      }),
      ...otherData,
      getPlayerSettings: library => {
        setLisaLoading(false);
        setLisaPlayer(library.player);
        return {
          [library.player.Event.PRODUCT_VIEW]: data => {
            const urlString = getActionString(data);
            const url = hasStringifiedData(urlString)
              ? parseItemDataFromString(urlString).url
              : urlString;
            const routeSlug = getRouteSlugStore();
            if (url) {
              const route = `/${routeSlug}${url}`;
              lisaNavigate({ dispatch, history, route, library });
            }
          },
          [library.player.Event.CART_VIEW]: url => {
            const routeSlug = getRouteSlugStore();
            const route = `/${routeSlug}${url}/`;
            lisaNavigate({ dispatch, history, route, library });
          },
          [library.player.Event.PRODUCT_ADD_TO_CART]: data => {
            const actionString = getActionString(data);
            const itemData = hasStringifiedData(actionString)
              ? parseItemDataFromString(actionString)
              : {};
            const [productId, sizeId] = [
              Number(itemData.productId || data.id),
              Number(itemData.sizeId || data.variantId || 0)
            ];
            const productData = [
              {
                productId,
                stockId: sizeId,
                quantity: 1
              }
            ];
            (async () => {
              const resp = await CartService.repopulateCart({
                productData,
                countryId: commonSettings.countryId
              });
              if (resp?.success) {
                const languageSlug = getLanguageSlugStore();
                const cartData = await dispatch(
                  fetchCartData(true, languageSlug)
                );
                const itemCount = cartData?.find(
                  item =>
                    item.productId === productId &&
                    (sizeId ? sizeId === item.sizeId : true)
                )?.quantity;

                itemCount &&
                  LiSACommunicationService.sendFailedItemAdding(itemCount);
                const isLisaMinimized = getLisaMinimized();
                if (isLisaMinimized || isMobile.any()) {
                  const translation = await importTranslation();
                  const message =
                    translation?.profileTranslation.itemAddedInCart;
                  dispatch(toastMsg(TOAST_TYPES.SUCCESS, message, true));
                }
              }
            })();
          },
          [library.player.Event.PRODUCT_GRANT_REACTION]: async data => {
            const itemData = {
              size: "",
              sizeType: null,
              ...getItemDetails(data)
            };
            if (!isPresent(itemData)) {
              saveItemToWishList(itemData);
              const isLisaMinimized = getLisaMinimized();
              if (isLisaMinimized || isMobile.any()) {
                const translation = await importTranslation();
                const message =
                  translation?.profileTranslation.itemAddedInWishlist;
                dispatch(toastMsg(TOAST_TYPES.SUCCESS, message, true));
              }
            }
          },
          [library.player.Event.PRODUCT_REVOKE_REACTION]: async data => {
            const itemData = {
              size: "",
              sizeType: null,
              ...getItemDetails(data)
            };
            if (isPresent(itemData)) {
              deleteFromWishList(itemData.productId, itemData.size);
              const isLisaMinimized = getLisaMinimized();
              if (isLisaMinimized || isMobile.any()) {
                const translation = await importTranslation();
                const message =
                  translation?.profileTranslation.itemRemovedFromWishlist;
                dispatch(toastMsg(TOAST_TYPES.ERROR, message, true));
              }
            }
          },
          [library.player.Event.BANNER_VIEW]: data => {
            const urlString = getActionString(data);
            const url = hasStringifiedData(urlString)
              ? parseItemDataFromString(urlString).url
              : urlString;
            const routeSlug = getRouteSlugStore();
            if (url) {
              const route = `/${routeSlug}${url}`;
              lisaNavigate({ dispatch, history, route, library });
            }
          },
          [library.player.Event.SHOW_START]: onShowStatusChange,
          [library.player.Event.SHOW_STOP]: onShowStatusChange,
          [library.player.Event.CLOSE]: () => {
            setTimeout(() => dispatch(setLisaPlayerIsActive(false)), 1000);
          }
        };
      },
      getBackdropSettings: library => {
        return {
          [library.player.library.backdrop.Event.CLICK]: () =>
            library.player.minimize()
        };
      }
    });
  };

  const onShowStatusChange = () => {
    const sameLocation = initLocation === window.location.pathname;
    sameLocation && reInitLisaWithDelay();
  };

  const reInitLisaWithDelay = async () => {
    const { profileTranslation } = await importTranslation();
    setTimeout(() => lisaConfigure(profileTranslation), 1000);
  };

  useEffect(() => {
    lisaMinimized && dispatch(setLisaMinimized(false));
    return () => {
      if (lisaPlayer) {
        dispatch(setLisaMinimized(true));
        dispatch(setLisaPlayerIsActive(true));
        lisaPlayer.minimize();
      }
    };
  }, [lisaPlayer]);

  useEffect(() => {
    (async () => {
      setLisaLoading(true);
      const { profileTranslation } = await importTranslation();
      lisaConfigure(profileTranslation);
    })();
  }, [routeSlug]);

  return lisaLoading ? (
    <>
      <Loader
        type={LOADER_TYPES.DOTS}
        size={16}
        qty={3}
        visible
        style={commonLoaderStyle}
      />
      {children}
    </>
  ) : (
    children
  );
};
