import get from "lodash/get";

import ApiService from "../ApiService";

import { BEConfig } from "../../config/env";

import { webapiPost, webapiGet, webapiPut } from "../../webapis/core";

class CheckoutService extends ApiService {
  async confirmPayPal({
    nonce,
    device_data,
    amount,
    shipping,
    billing,
    orderId,
    currencyCode,
    paymentToken,
    countryId,
    phone
  }) {
    const endpoint = this.getBaseUrl() + BEConfig.checkoutApi.paypalConfirm;

    if (!orderId) throw new Error("Incorrect orderId");

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      {
        payment_method_nonce: nonce,
        device_data,
        amount,
        shipping,
        billing,
        orderId,
        currencyCode,
        paymentToken,
        countryId,
        phone
      },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async getPayPalToken() {
    const endpoint = this.getBaseUrl() + BEConfig.checkoutApi.paypalToken;

    const response = await webapiGet(this.getAccessToken(), endpoint).request;

    return response.data;
  }

  async getAreebaCheckoutToken(body) {
    const endpoint =
      this.getBaseUrl() +
      get(BEConfig, "checkoutApi.createAreebaSessionIdHandle", "");

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      body,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async payByCheckoutCom(paymentData) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.checkoutApi.payCheckoutCom
    }`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      paymentData,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async getPaymentDetails(sessionId) {
    const endpoint = `${this.getBaseUrl()}/checkout-com/payment/${sessionId}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async saveCreditCard(sessionId) {
    const endpoint = `${this.getBaseUrl()}/checkout-com/credit-card`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { "cko-session-id": sessionId },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const {
      baseURL,
      protocol,
      port,
      versionInfo,
      checkoutHandle
    } = BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;

    return url;
  }
}

const instance = new CheckoutService();

export default instance;
