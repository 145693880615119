import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import * as priceUtil from "../../../util/priceUtil";
import RedeemExpiryDetailsPopup from "../../loyalty/redeem-loyalty-points/redeem-expiry-details-popup";
import { LANGUAGE_ROUTE_KEY } from "../../../constants";
import { AnalyticService } from "../../../services";
import { useSelector } from "react-redux";
import { selectCustomerId } from "../../../util/selectors";

const filterWalletAmount = (walletData, currency, countryId) =>
  filter(
    walletData,
    data => currency === data.currencyCode && data.countryId === countryId
  );

const MyWalletBalance = ({
  myAccountTranslation,
  walletAmount,
  golbalCurrency,
  countryId,
  expiryDetails,
  language
}) => {
  const customerId = useSelector(selectCustomerId);
  const { walletTotalBalance } = myAccountTranslation;
  let amountContent = (
    <>
      <p className="title_text">{walletTotalBalance}</p>
      <p className="amount_text">{golbalCurrency} 0</p>
    </>
  );
  let currencyCode = golbalCurrency;
  let calculatedWalletAmount = null;

  if (!isEmpty(walletAmount)) {
    const filteredWalletAmount = filterWalletAmount(
      walletAmount,
      golbalCurrency,
      countryId
    );

    calculatedWalletAmount = filteredWalletAmount.reduce((acc, value) => {
      acc += value.transactionAmount;
      return acc;
    }, 0);

    amountContent = filteredWalletAmount.map(wallet => {
      currencyCode = wallet.currencyCode;
      return (
        <div key={wallet.currencyCode}>
          <p className="title_text">{walletTotalBalance}</p>
          <p className="amount_text">{`${currencyCode} ${priceUtil.shapePriceWithComma(
            wallet.transactionAmount
          )}`}</p>
        </div>
      );
    });
  }

  const specificDateFormat =
    language === LANGUAGE_ROUTE_KEY.arabic && "YYYY MMM DD";
  const basicProps = {
    pointDescription: myAccountTranslation.fromYourWallet,
    expirationDescription: myAccountTranslation.willExpireOn,
    translation: myAccountTranslation,
    ...(specificDateFormat && { dateFormat: specificDateFormat })
  };

  useEffect(() => {
    if (calculatedWalletAmount !== null) {
      AnalyticService.customer.trackMyWalletView({
        countryId,
        language,
        customerId,
        myWalletAmount: calculatedWalletAmount
      });
    }
  }, [calculatedWalletAmount]);

  return (
    <div className="myWallet_balance_wrapper">
      {amountContent}
      {!isEmpty(expiryDetails) && (
        <>
          {Boolean(expiryDetails.earliestExpiryAmount) && (
            <div className="mt-1 mt-md-4">
              <RedeemExpiryDetailsPopup
                points={currencyCode + " " + expiryDetails.earliestExpiryAmount}
                expireDate={expiryDetails.earliestExpiryDate}
                {...basicProps}
              />
            </div>
          )}

          {Boolean(expiryDetails.expiryAmountNextMon) && (
            <div className="mt-1 mt-md-4">
              <RedeemExpiryDetailsPopup
                points={currencyCode + " " + expiryDetails.expiryAmountNextMon}
                expireDate={expiryDetails.expiryDateNextMon}
                {...basicProps}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MyWalletBalance;
