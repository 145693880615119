import React from "react";

const ChangeAddressButtons = ({
  toggleRespAddressModal,
  changeFromRespAddressBook,
  checkoutTranslation
}) => (
  <div className="mCheckoutSign mChangeAddress">
    <button
      className="round_btn form_white_btn"
      onClick={e => changeFromRespAddressBook(true, true)}
    >
      {checkoutTranslation.change}
    </button>
    <button
      className="mCheckoutSign round_btn form_white_btn"
      onClick={e => toggleRespAddressModal(true)}
    >
      {checkoutTranslation.addNewAddress}
    </button>
  </div>
);

export default ChangeAddressButtons;
