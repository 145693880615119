import React, { Component } from "react";
import head from "lodash/head";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import cn from "classnames";
import * as Sentry from "@sentry/browser";

import { TamaraService } from "../../services";

import {
  COUNTRIES_WITH_CIVIL_ID,
  MAX_CIVIL_ID_LENGTH,
  MIN_CIVIL_ID_LENGTH,
  PAYMENT_GATEWAYS,
  PAYMENT_MODES,
  TAMARA_PAYMENT_ID
} from "../../constants";
import {
  selectCountryShort,
  selectCountrySlug,
  selectLanguageSlug,
  selectCurrency
} from "../../util/selectors";
import { selectUserDataForOrder } from "./selectors";
import { confirmOrder } from "../../redux/actions/order.action";
import { setPaymentStatusResponse } from "../../redux/actions/checkout.action";
import { errorOnUserSide } from "../../util/errorHandler";

class TamaraButton extends Component {
  onStartPayment = async () => {
    const {
      checkoutPage,
      history,
      confirmOrder,
      language,
      order,
      currency,
      shippingAddress,
      countryShort,
      setPaymentStatusResponse,
      user,
      countrySlug,
      personalId,
      changePersonalIdStatus
    } = this.props;

    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(countrySlug);

    const isPersonalIdInvalid =
      personalId.length <= MAX_CIVIL_ID_LENGTH ||
      personalId.length >= MIN_CIVIL_ID_LENGTH;
    if (isPersonalIdRequired && isPersonalIdInvalid) {
      return changePersonalIdStatus();
    }

    let orderBody;
    let orderRecord;
    let paymentResult;
    try {
      checkoutPage.showLoader();

      orderBody = this.getOrderBody();

      const response = await confirmOrder(orderBody, history, language);
      const orderRecord = response.data;

      const isPossibleToBuy =
        get(response, "data.success") &&
        orderBody.paymentMode &&
        orderBody.transactionAmount !== 0;

      if (!isPossibleToBuy) {
        checkoutPage.setState({
          orderFailedText: orderRecord.message
        });
        checkoutPage.hideLoader();

        return;
      }

      const result = await TamaraService.startSession({
        order,
        user,
        currency,
        orderBody,
        orderRecord,
        shippingAddress,
        countryShort,
        language
      });

      localStorage.setItem(TAMARA_PAYMENT_ID, result.order_id);

      window.location = result.checkout_url;
    } catch (error) {
      const errorData = {
        type: "TAMARA PAYMENT FAIL",
        error,
        orderBody,
        createdOrderResponse: orderRecord?.data,
        paymentResult
      };

      console.error(errorData);
      Sentry.captureException(errorData);

      checkoutPage.hideLoader();

      if (errorOnUserSide(error.response.status)) {
        setPaymentStatusResponse(error.response.data);
        return;
      }
      checkoutPage.redirectToErrorPage();
    }
  };

  getOrderBody() {
    const { checkoutPage, countrySlug, personalId } = this.props;
    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(countrySlug);
    const orderBody = checkoutPage.getOrderBody();

    const enhancedOrderBody = {
      ...orderBody,
      paymentGateway: PAYMENT_GATEWAYS.TAMARA,
      paymentMode: orderBody.paymentMode.map(mode => {
        const tamaraModes = [
          PAYMENT_MODES.TAMARA_PAY_NOW,
          PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS
        ];

        if (tamaraModes.includes(mode)) return PAYMENT_MODES.TAMARA;

        return mode;
      }),
      paymentExtraInfo: head(orderBody.paymentMode),
      ...(isPersonalIdRequired && { personalId })
    };

    return enhancedOrderBody;
  }

  render() {
    const { buttonText, className, stepsCompleted, onButtonClick } = this.props;

    return (
      <button
        className={cn(className, {
          disable_order_btn: !stepsCompleted
        })}
        onClick={() => {
          this.onStartPayment();
          onButtonClick();
        }}
      >
        {buttonText}
      </button>
    );
  }
}

const mapStateToProps = state => ({
  order: state.checkoutReducer.checkoutData,
  countrySlug: selectCountrySlug(state),
  countryShort: selectCountryShort(state),
  language: selectLanguageSlug(state),
  currency: selectCurrency(state),
  user: selectUserDataForOrder(state)
});

const mapDispatchToProps = { confirmOrder, setPaymentStatusResponse };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TamaraButton);
