import { getAccessToken } from "../../util/storeHelper";
import { getVisitorId } from "./common.action";
import { BEConfig } from "../../config/env";
import {
  HANDLE_SEARCH_TEXT,
  SET_SUGGESTIONS,
  SET_TRENDING
} from "../constants";
import { fetchWebApi } from "../../webapis/apiResource";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";

export const handleSearchText = searchText => ({
  type: HANDLE_SEARCH_TEXT,
  searchText
});

export const setSearchSuggestions = (suggestions, products) => ({
  type: SET_SUGGESTIONS,
  suggestions,
  products
});

export const setSearchTrending = trending => ({ type: SET_TRENDING, trending });

export const getSearchTrending = (language = "en") => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.productsApi.protocol}${
    BEConfig.productsApi.baseURL
  }${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${
    BEConfig.productsApi.trendingProducts
  }`;
  return (dispatch, getState) => {
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(setSearchTrending(response.data.trending));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getSearchSuggestions = (searchText, language = "en") => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.productsApi.protocol}${
    BEConfig.productsApi.baseURL
  }${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${
    BEConfig.productsApi.productSuggestions
  }?searchText=${searchText}`;
  return (dispatch, getState) => {
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(
            setSearchSuggestions(
              response.data.suggestion,
              response.data.product
            )
          );
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const searchContent = searchTxt => {
  return dispatch => {
    //     index.search(
    //       {
    //         query: searchTxt,
    //         facetFilters: [[ `brandName:${searchTxt}`, `catname:${searchTxt}`, `subcatname:${searchTxt}` ]],
    //         facets: ['brandName', 'catname', 'subcatname' ],
    //       }, (err, content) => {
    //       if(content) {
    //         dispatch(setSearchContent(content));
    //       }
    //     });
  };
};

export const initSearchContent = searchTxt => {
  return dispatch => {
    //     index.search(
    //       {
    //         query: searchTxt,
    //         facetFilters: [[ `brandName:${searchTxt}`, `catname:${searchTxt}`, `subcatname:${searchTxt}` ]],
    //         facets: ['brandName', 'catname', 'subcatname' ],
    //       }, (err, content) => {
    //       if(content) {
    //         dispatch(setSearchContent(content));
    //       }
    //     });
  };
};
