export const mobileAppLang = {
  mainHead: "SMILE MORE!",
  mainSubheading: "All new redesigned App",
  mainDescription:
    "Improved search, chat support and more new features for easy shopping",
  qrCodeDescription:
    "Get a link on your mobile to download the Brands For Less app!",
  buttonText: "Send SMS",
  mobileReferenceHeader: "Keep Everything at Your FingerTips!",
  mobileReferenceHeaderSmall:
    "A Virtual Concierge App to Level - Up your Shopping Experience",
  mobHeadStore: "LOCATE STORE",
  mobDetailStore:
    "Find our stores near you. With over 35 stores across UAE, you can always shop your favorite collection with us.",
  mobHeadRegion: "SHOP BY REGION",
  mobDetailRegion:
    "Shop with us, anytime, anywhere! We want to spread the smile while shopping, reach to us wherever you're from!",
  mobHeadCustomer: "CUSTOMER SUPPORT",
  mobDetailCustomer:
    "Need help? We're here for you. Our friendly customer care will always be at your service. We will always reconnect.",
  mobHeadTrack: "TRACK SHIPMENT",
  mobDetailTrack:
    "Tracking your orders made easy. Don't settle for less, with just few clicks, you'll get an update with your orders.",
  listItemScan: "SCAN & SHOP",
  listDescScan:
    "Everything with us is easy.Find your favorite item with a quick barcode scan and our app will get it for you.",
  listItemBrands: "OVER 3,000 BRANDS",
  listDescBrands:
    "Whatever you want, whatever your style is. You can choose from over 3000 genuine high-quality brands with the latest trends and new items each week.",
  listItemBuy: "BUY. EARN. REDEEM",
  listDescBuy: "Shop and earn your smiles!",
  listDescShop:
    "Shopping with us is always rewarding! Buy the things you love, check your points and redeem your points!",
  enterValidPhoneNumber: "Enter a valid Phone Number",
  messageSentText: "The app download link has been successfully sent.",
  invalidPhoneNumberMessage: "Field should be a valid phone number.",
  toBrandsForLessAppFollowLink:
    "To get the Brands For Less App, follow this link",
  android: "Android",
  ios: "IOS"
};
