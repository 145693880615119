import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../images/cross_black_icon.svg";
import "./style.scss";

const Tooltip = ({ triggerComponent: Trigger, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !showTooltip) return;
    const eventsToHandle = ["click", "touchstart"];
    const eventHandler = e => {
      if (ref.current?.contains(e.target)) return;
      setShowTooltip(false);
    };
    eventsToHandle.forEach(type => {
      document.addEventListener(type, eventHandler);
    });
    return () => {
      eventsToHandle.forEach(type => {
        document.removeEventListener(type, eventHandler);
      });
    };
  }, [ref.current, showTooltip]);
  return (
    <>
      <Trigger
        onClick={() => {
          setShowTooltip(!showTooltip);
        }}
      />
      {showTooltip && (
        <div ref={ref} className="custom_tooltip position-absolute">
          <div className="tooltip_body position-relative">
            {children}
            <img
              className="close_tooltip_icon position-absolute"
              src={closeIcon}
              alt="close tooltip"
              onClick={() => setShowTooltip(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Tooltip;
