import React from "react";
import OverViewCard from "../../my-acc/myAcc-overview-card";
import BounceLoader from "../../bounce-loader";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import moment from "moment";
import * as priceUtil from "../../../util/priceUtil";
import { LoyaltyPointsService } from "../../../services";
import { LOYALTY_ORDER_FROM_ONLINE_STORE } from "../../../constants/loyalty-points";
import { ReactComponent as DownloadSvgIconComponent } from "../../../images/download-icon.svg";
import Loader from "../../loader";
import { LOADER_TYPES } from "../../../constants";

class LoyaltyTable extends React.Component {
  state = {
    count: 4,
    pageNum: 1,
    pageSize: 4,
    isEndOfResult: false,
    loyaltyCount: 4,
    dataFetching: false,
    downloadingDetails: {
      isDownloading: false,
      invoiceNo: ""
    },
    noData: false
  };

  handleViewMore = loyaltyTransactionCount => {
    const { pageNum, pageSize, isEndOfResult, loyaltyCount } = this.state;
    const { fetchLoyaltyPointsTable, language, settings } = this.props;
    this.setState({ count: loyaltyCount });
    this.props.handleLoyaltyCount();
    if (!isEndOfResult) {
      this.setState(
        {
          count: loyaltyCount + loyaltyTransactionCount,
          pageNum: pageNum + 1,
          dataFetching: true,
          showLoader: true
        },
        () =>
          fetchLoyaltyPointsTable(
            language,
            this.state.pageNum,
            pageSize,
            settings.countryId
          ).then(res => {
            this.setState({
              isEndOfResult:
                isEmpty(res.loyaltyPoints) ||
                res.loyaltyPoints?.length < pageSize,
              dataFetching: false,
              showLoader: false
            });
          })
      );
    }
  };

  handleViewLess = () => {
    this.setState({ count: 4, isEndOfResult: false });
  };

  getGroupedByDateData = () => {
    const { loyaltyInputs } = this.props;
    const { count } = this.state;
    return Object.entries(
      groupBy(loyaltyInputs.loyaltyPoints.slice(0, count), "invoiceDate")
    ).map(this.getRowContent);
  };

  downloadInvoice = async item => {
    this.setState({ noData: false });
    this.setDownloadingDetails(true, item.invoiceNo);

    const response = await LoyaltyPointsService.downloadInvoice({ ...item });
    if (!response.status) {
      this.setState({ noData: true });
    }

    this.setDownloadingDetails(false, item.invoiceNo);
  };

  setDownloadingDetails = (isDownloading, invoiceNo) => {
    this.setState({
      downloadingDetails: {
        isDownloading,
        invoiceNo
      }
    });
  };

  getRowContent = ([invoiceDate, invoicesOfDate]) => {
    const { myAccountTranslation, settings } = this.props;
    const { downloadingDetails, noData } = this.state;

    return (
      <>
        <h3>{moment(invoiceDate).format("DD MMM YYYY")}</h3>
        {invoicesOfDate.map(item => {
          const isDownloadingInvoice =
            downloadingDetails.isDownloading &&
            item.invoiceNo === downloadingDetails.invoiceNo;
          const isNoData =
            noData && item.invoiceNo === downloadingDetails.invoiceNo;
          const isAvailableToDownload =
            item.shopName !== LOYALTY_ORDER_FROM_ONLINE_STORE &&
            settings.enableDownloadInvoice &&
            item?.isDownloadEnabled;

          return (
            <div className="date_wise_transaction" key={item.invoiceNo}>
              <div className="invoice">
                <p>{item.mode || myAccountTranslation.invoiceNumber}</p>
                <p>{item.invoiceNo}</p>
                <p
                  className={`${
                    item.transactionType === "EARNING"
                      ? "positive_transaction_points"
                      : "negative_transaction_points"
                  }`}
                >
                  {item.pointsEarned}
                </p>
              </div>
              <div className="outlet_name">
                <p>{myAccountTranslation.storeName}</p>
                <p>{item.shopName || myAccountTranslation.online}</p>
                <p>
                  {item && item.currencyCode}{" "}
                  {priceUtil.shapePriceWithComma(
                    item.transactionNetAmount || item.netAmount
                  )}
                </p>
              </div>
              {isAvailableToDownload && (
                <div className="download-button">
                  <p onClick={() => this.downloadInvoice(item)}>
                    <DownloadSvgIconComponent />

                    {isDownloadingInvoice
                      ? this.getDownloadingPreview()
                      : myAccountTranslation.invoice}
                  </p>
                </div>
              )}
              {isNoData && (
                <p className="no-data">{myAccountTranslation.noData}</p>
              )}
            </div>
          );
        })}
      </>
    );
  };

  getDownloadingPreview = () => {
    const { myAccountTranslation } = this.props;

    return (
      <>
        {myAccountTranslation.downloading}
        <Loader
          type={LOADER_TYPES.DOTS}
          size={8}
          qty={3}
          visible
          style={loaderStyle}
        />
      </>
    );
  };

  render() {
    const { myAccountTranslation, loyaltyInputs } = this.props;
    const { isEndOfResult, dataFetching, showLoader } = this.state;
    let showMoreLessButton = null;
    const loyaltyPointsLength = loyaltyInputs.loyaltyPoints?.length;
    if ((loyaltyPointsLength > 4 && isEndOfResult) || dataFetching) {
      showMoreLessButton = (
        <button className="blue_font_btn" onClick={this.handleViewLess}>
          {myAccountTranslation.viewLessTransaction}
        </button>
      );
    } else if (loyaltyPointsLength > 3 && !isEndOfResult) {
      showMoreLessButton = (
        <button
          className="blue_font_btn"
          onClick={() =>
            this.handleViewMore(loyaltyInputs.loyaltyPoints.length)
          }
        >
          {myAccountTranslation.viewMoreTransaction}
        </button>
      );
    }

    return (
      <OverViewCard
        isTitleStrip={true}
        isBorder={true}
        title={myAccountTranslation.transactionHistory}
      >
        <div className="loyalty_table_wrapper">
          {loyaltyInputs.loyaltyPoints &&
          loyaltyInputs.loyaltyPoints.length !== 0 ? (
            <div>
              <div className="loyalty_table">
                <div className="transaction_table_mobile">
                  <div className="transaction_entry">
                    {this.getGroupedByDateData()}
                  </div>
                </div>
              </div>
              {showLoader ? (
                <BounceLoader color="#f8d000" size={10} qty={3} />
              ) : (
                showMoreLessButton
              )}
            </div>
          ) : (
            <div className="no_record_found">
              {" "}
              {loyaltyInputs.success === false
                ? "No Transactions Available"
                : ""}
            </div>
          )}
        </div>
      </OverViewCard>
    );
  }
}

const loaderStyle = {
  position: "relative",
  backgroundColor: "initial",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export default LoyaltyTable;
