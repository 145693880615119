import qs from "query-string";

import ApiService from "./ApiService";

import { BEConfig } from "../config/env";

import { webapiPost } from "../webapis/core";
import { getPrevModeStorage } from "../util/browserStorage";

class CatalogService extends ApiService {
  async getPageData(
    { url, countryId, language, storeId, limit, cookies },
    config = {}
  ) {
    const prevMode = getPrevModeStorage(cookies);
    const additionalQueriesForScheduler = prevMode?.replace("true", "") || "";
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;
    const endpoint = `${baseUrl}?${qs.stringify({
      countryId,
      language,
      storeId,
      limit
    })}${additionalQueriesForScheduler}`;
    const response = await webapiPost(
      this.getAccessToken(cookies),
      endpoint,
      {
        url
      },
      this.getVisitorId(),
      null,
      null,
      prevMode,
      config.ipcountry
    ).request;
    return response.data;
  }

  async getSaleCarouselData({ slug, dataType, countryId, language, storeId }) {
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;

    const endpoint = `${baseUrl}?${qs.stringify({
      countryId,
      language,
      storeId
    })}`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      {
        slug,
        dataType
      },
      this.getVisitorId()
    ).request;
    return response.data;
  }

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new CatalogService();

export default instance;
