import * as priceUtil from "../../util/priceUtil";
import "./style.scss";
const RoundedDiscount = ({ item, countrySettings }) => {
  const percentage = priceUtil.getDiscountPercent(item, countrySettings);
  const percentageValue = `${percentage}%`;
  const showDiscount = item.price?.old && Boolean(percentage);

  return showDiscount ? (
    <p className="item_discount_container">
      <span className="product-discount_view">-{percentageValue}</span>
    </p>
  ) : null;
};

export default RoundedDiscount;
