export const loginTranslation = {
  email: "Your Email Address",
  password: "Password",
  forgotPassword: "Forgot Password?",
  signIn: "Sign In",
  dontHaveAccount: `Don't have an account?`,
  join: "Join",
  joinSocialMedia: "or Join In via",
  loginSocialMedia: "or Sign In via",
  facebook: "Facebook",
  google: "Google",
  apple: "Apple",
  alreadyHaveAccount: "Already have an account?",
  required: "Field is required.",
  emailRequired: "Enter a valid Email Address.",
  firstNameRequired: "Enter your first name",
  OR: "OR",
  signInWithSocialAccount: "Sign In with your social account",
  show: "Show",
  showPass: "Show",
  hide: "Hide",
  inValidEmailOrPassword: "Invalid email or password",
  privacyPolicy: "Privacy Policy",
  termsAndConditions: "Terms & Conditions",
  youAgreeTo: "By creating your account, you agree to our",
  addYourNumber: "Add your mobile number",
  pleaseEnterNumber:
    "Please enter a valid phone number below to update your profile.",
  confirm: "Confirm",
  joinAccount: "Join",
  signInAccount: "Sign In"
};
