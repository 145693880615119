import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import ModalBFL from "../../modal/modal";
import {
  selectLanguageSlug,
  selectHomePageApiErrorData,
  selectUpdateError,
  selectPhoneUpdateFlowData,
  selectShowLoader
} from "../../../util/selectors";
import SendOTP from "./send-otp";
import MobileInUse from "./mobile-in-use";
import ReAttachNumber from "./reattach-number";
import {
  resetUpdateError,
  verifyReceivedOtp,
  getLinkedAccounts,
  deactivatePrevAccountsAndCompleteUpdate,
  sendOtpToVerifyNumber,
  resetPhoneUpdateFlowData
} from "../../../redux/actions/myAccount.action";
import { fetchUserAndLoadData } from "../../../redux/actions/authConfig.action";
import { resetAPIError } from "../../../redux/actions/homepage.action";
import {
  SIGNUP_API_CODE_INDEX,
  SIGNUP_API_CODE_NAMES,
  SIGNUP_CODES_FOR_MODAL
} from "../../../constants";
import { GAService } from "../../../services/GA-service";

const MyAccMobileNumberModal = ({
  updateUser,
  customerPhone,
  focusOnPhoneInput,
  phoneNumberParts
}) => {
  const [translations, setTranslations] = useState({
    orderSuccessTranslation: {},
    signUpTranslation: {},
    loginTranslation: {}
  });
  const { orderSuccessTranslation, signUpTranslation, loginTranslation } =
    translations;
  const { mobileCountryCode, areaCode } = phoneNumberParts;
  const [otpValid, setOtpValid] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const [mobileModalStep, setMobileModalStep] = useState(
    SIGNUP_API_CODE_INDEX.ALREADY_IN_USE
  );

  const language = useSelector(selectLanguageSlug);

  const userDataUpdateStatus = useSelector(selectHomePageApiErrorData);
  const updateError = useSelector(selectUpdateError);
  const phoneUpdateFlowData = useSelector(selectPhoneUpdateFlowData);
  const showLoader = useSelector(selectShowLoader);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const resendOtp = () => {
    if (!phoneUpdateFlowData?.token) {
      updateUser();
    } else {
      const { token } = phoneUpdateFlowData;
      dispatch(sendOtpToVerifyNumber({ token }));
    }
    setOtpCode("");
    setOtpValid(true);
    setSendOtpCount(prevCount => prevCount + 1);
  };

  const startVerificationProcess = () => {
    const { token } = userDataUpdateStatus;
    dispatch(sendOtpToVerifyNumber({ token }));
    setOtpCode("");
    setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_OTP);
  };

  const closeModal = () => setShowModal(false);

  const onModalClose = () => {
    closeModal();
    setOtpValid(true);
    dispatch(resetAPIError());
    dispatch(resetUpdateError());
    dispatch(resetPhoneUpdateFlowData());
    setOtpCode("");
    setSendOtpCount(0);
    setMobileModalStep(null);
  };

  const onChangeClick = () => {
    onModalClose();
    focusOnPhoneInput();
  };

  const onSuccessUpdate = () => {
    dispatch(fetchUserAndLoadData({ keepDeliverySettings: true }));
    onModalClose();
  };

  const verifyOtpGetAccountsList = async () => {
    let otpValid = false;
    if (userDataUpdateStatus.code === SIGNUP_API_CODE_NAMES.OTP_REQUIRED) {
      const { token } = userDataUpdateStatus;
      const response = await dispatch(
        verifyReceivedOtp({ token, otp: otpCode })
      );
      if (response.data?.success) {
        otpValid = true;
        GAService.auth.trackOTPVerificationSuccess();
        onSuccessUpdate();
      }
    } else {
      const { token } = phoneUpdateFlowData;
      const response = await dispatch(
        getLinkedAccounts({
          token,
          otp: otpCode
        })
      );
      if (get(response, "data.accounts_using_phone_number")) {
        otpValid = true;
        setMobileModalStep(SIGNUP_API_CODE_INDEX.REATTACH_NUMBER);
      }
    }
    setOtpValid(otpValid);
  };

  const deactivateAccounts = async () => {
    const { token } = phoneUpdateFlowData;
    const response = await dispatch(
      deactivatePrevAccountsAndCompleteUpdate({
        token
      })
    );
    if (get(response, "data.success")) {
      onSuccessUpdate();
    }
  };

  const setMobileModalStepNumber = () => {
    switch (userDataUpdateStatus.code) {
      case SIGNUP_API_CODE_NAMES.OTP_REQUIRED:
        dispatch(resetUpdateError());
        dispatch(resetPhoneUpdateFlowData());
        setMobileModalStep(SIGNUP_API_CODE_INDEX.VERIFY_OTP);
        break;
      case SIGNUP_API_CODE_NAMES.MOBILE_EXISTS_ANOTHER_ACCOUNT:
        setMobileModalStep(SIGNUP_API_CODE_INDEX.ALREADY_IN_USE);
        break;
      default:
        break;
    }
  };

  const modalContentComponents = [
    <MobileInUse
      translation={signUpTranslation}
      onVerifyClick={startVerificationProcess}
      onChangeClick={onChangeClick}
    />,
    <SendOTP
      orderSuccessTranslation={orderSuccessTranslation}
      countryCode={mobileCountryCode}
      phone={customerPhone}
      otp={otpCode}
      handleOTPChange={value => {
        setOtpCode(value);
      }}
      verifyOtp={verifyOtpGetAccountsList}
      otpValid={otpValid}
      sendOtp={resendOtp}
      areaCode={areaCode}
      sendOtpCount={sendOtpCount}
      errorMessage={updateError.message || ""}
      showLoader={showLoader}
    />,
    <ReAttachNumber
      translation={signUpTranslation}
      phoneNumber={customerPhone}
      linkedEmails={phoneUpdateFlowData.accounts_using_phone_number || []}
      onReattachClick={deactivateAccounts}
      buttonActionText={loginTranslation.confirm}
    />
  ];

  const [modalContent, setModalContent] = useState(
    modalContentComponents[mobileModalStep]
  );

  useEffect(() => {
    const loadTranslation = async () => {
      const responses = await Promise.all([
        import(`../../../language/orderSuccess/${language}/orderSuccess`),
        import(`../../../language/signUp/${language}/signUp`),
        import(`../../../language/login/${language}/login`)
      ]);
      const { orderSuccessTranslation, signUpTranslation, loginTranslation } =
        responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
      setTranslations({
        orderSuccessTranslation,
        signUpTranslation,
        loginTranslation
      });
    };
    loadTranslation();
  }, [language]);

  useEffect(() => {
    setModalContent(modalContentComponents[mobileModalStep]);
  }, [
    mobileModalStep,
    translations,
    customerPhone,
    otpCode,
    otpValid,
    updateError,
    userDataUpdateStatus,
    phoneUpdateFlowData,
    updateUser,
    sendOtpCount,
    showLoader
  ]);

  useEffect(() => {
    if (userDataUpdateStatus.code && !userDataUpdateStatus.success) {
      setMobileModalStepNumber();
      !SIGNUP_CODES_FOR_MODAL[userDataUpdateStatus.code] && setShowModal(false);
    }
    if (
      !userDataUpdateStatus.success &&
      SIGNUP_CODES_FOR_MODAL[userDataUpdateStatus.code] &&
      !showModal
    ) {
      setShowModal(true);
    }
  }, [userDataUpdateStatus]);

  return (
    <ModalBFL
      showModal={showModal}
      closeModal={onModalClose}
      modalClass={cn(
        {
          arabic: language.includes("ar")
        },
        "generic_modal mobile_verification_modal wide_generic_modal"
      )}
    >
      {modalContent}
      <button className="cross_btn" onClick={onModalClose}>
        &times;
      </button>
    </ModalBFL>
  );
};

export default MyAccMobileNumberModal;
