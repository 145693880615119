import React, { Fragment } from "react";
import cn from "classnames";

const overViewCard = props => {
  const {
    isTitleStrip,
    isBorder,
    shadow,
    title,
    action,
    handleAction,
    className
  } = props;
  const titleContent =
    action && handleAction ? (
      <>
        <div className="col-8">
          <span className="text-left">{title}</span>
        </div>
        <div className="col-4">
          <span className="text-right cursor" onClick={() => handleAction()}>
            {action}
          </span>
        </div>
      </>
    ) : (
      <div className="col-12">
        <span className="text-left">{title}</span>
      </div>
    );

  return (
    <div className={cn("myAcc-card-wrapper", className)}>
      <Fragment>
        {isTitleStrip ? (
          <div className={`card-title`}>
            <div className="row">{titleContent}</div>
          </div>
        ) : null}
        {
          <div
            className={`cardbody ${isBorder ? "border" : ""} ${
              shadow ? "card_shadow" : ""
            }`}
          >
            {props.children}
          </div>
        }
      </Fragment>
    </div>
  );
};

export default overViewCard;
