import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { selectCurrency } from "../../../util/selectors";

const PriceBlock = ({
  translation,
  price,
  minusBeforePrice = false,
  spanClassName,
  paragraphClassName
}) => {
  const currencyCode = useSelector(selectCurrency) || "AED";
  const minusChar = "-";

  return (
    <p className={cn({ [paragraphClassName]: paragraphClassName })}>
      <span>{translation}</span>
      <span className={cn({ [spanClassName]: spanClassName })}>
        {minusBeforePrice && minusChar}
        {currencyCode} {price}
      </span>
    </p>
  );
};

export default PriceBlock;
