import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RoundOrderImg from "../../round-img";
import ModalBFL from "../../modal/modal";
import CancelOrderAlert from "../../cancel-order-alert";
import {
  cancelOrder,
  resetCancelOrder,
  setCancelOrder
} from "../../../redux/actions/myAccount.action";
import { Link } from "react-router-dom";
import { getProductUrlName } from "../../../util";
import * as priceUtil from "../../../util/priceUtil";
import { selectRouteSlug } from "../../../util/selectors";
import AnalyticService from "../../../services/analytic-service";
import HtmlParser from "../../html-parser";
import { GAService } from "../../../services/GA-service";

class orderDetail extends React.Component {
  state = {
    cancelOrderModal: false,
    cancelReason: "",
    quantitySelected: ""
  };
  handleSelectChange = selected => {
    const { setCancelOrder, email, order, orderItem } = this.props;
    const { quantitySelected } = this.state;
    this.setState({ cancelReason: selected });
    setCancelOrder("reason", selected);
    setCancelOrder("orderId", order.orderId);
    setCancelOrder("email", email);
    setCancelOrder("orderItems", [
      {
        ...orderItem,
        productId: orderItem.pId,
        quantity: quantitySelected || 1
      }
    ]);
  };
  handleQuantityChange = selected => {
    const { setCancelOrder, orderItem } = this.props;
    const updatedCancelOrder = { ...orderItem };
    updatedCancelOrder.quantity = selected;
    setCancelOrder("orderItems", [
      { ...updatedCancelOrder, productId: orderItem.pId, quantity: selected }
    ]);
    setCancelOrder("quantity", selected.toString());
    this.setState({ quantitySelected: selected });
  };
  handleTextArea = e => {
    const { setCancelOrder } = this.props;
    setCancelOrder("comment", e.target.value);
  };
  toggleCancelOrderModal = value => {
    const { order } = this.props;
    this.setState({ cancelOrderModal: value });
    if (!this.state.cancelOrderModal) {
      this.props.resetCancelOrder();
      this.setState({ cancelReason: "", quantitySelected: "" });
    }
    if (value) {
      GAService.product.trackOrderCancellationStart(order.orderId);
    }
  };
  validateCancelOrderForm = e => {
    const { OrderDetailR, setCancelOrder } = this.props;
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    _OrdersKey.forEach(key => {
      setCancelOrder(key, OrderDetailR.cancelOrder[key]);
    });
  };
  handleCancelOrder = orderItem => {
    const {
      cancelOrderAction,
      OrderDetailR,
      language,
      activePage,
      order,
      setforcePage,
      authReducer,
      settings
    } = this.props;
    this.validateCancelOrderForm();
    if (orderItem && orderItem.quantity <= 1) {
      OrderDetailR.errors.quantity.valid = true;
      OrderDetailR.errors.quantity.touched = true;
    }
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    if (
      _OrdersKey.every(
        key =>
          OrderDetailR.errors[key].valid && OrderDetailR.errors[key].touched
      )
    ) {
      cancelOrderAction(
        {
          ...OrderDetailR.cancelOrder,
          quantity: parseInt(OrderDetailR.cancelOrder.quantity),
          currencyCode: order.CurrencyCode,
          lang: language
        },
        activePage
      ).then(() => {
        setforcePage && setforcePage();
        this.toggleCancelOrderModal(false);
      });
      AnalyticService.order.trackSingleOrderCancellation({
        orderItem,
        authReducer,
        commonSettings: settings
      });
    }
  };
  render() {
    const {
      myAccountTranslation,
      orderItem,
      order,
      language,
      OrderDetailR,
      settings,
      routeSlug
    } = this.props;
    const { cancelOrderModal, cancelReason, quantitySelected } = this.state;
    const ProductTitle =
      getProductUrlName && getProductUrlName(orderItem.proname || "");

    return (
      <div className="order_detail_wrapper">
        <div className="row">
          <div className="col-2 order_img">
            <Link
              to={`/${routeSlug}/${ProductTitle ? ProductTitle : "---"}/${
                orderItem.pId
              }/p/`}
            >
              <RoundOrderImg imgSrc={`${orderItem.image}`} />
            </Link>
          </div>
          <div className="col-3 product_info">
            <Link
              to={`/${routeSlug}/${ProductTitle ? ProductTitle : "---"}/${
                orderItem.pId
              }/p/`}
            >
              <div>
                <p className="brand_text">{orderItem.brandname}</p>
                <p className="product_text">{orderItem.proname}</p>

                <div className="product_detail_text">
                  {!!orderItem && orderItem.size && (
                    <p className="order_size">
                      <span>{myAccountTranslation.size}</span>
                      <span>{orderItem.size}</span>
                    </p>
                  )}
                  <p className="order_price">
                    <span>{order.CurrencyCode} </span>
                    <span>
                      {priceUtil.shapePriceWithComma(
                        orderItem.transactionPrice
                      )}
                    </span>
                  </p>
                </div>
              </div>
              {orderItem?.f_message && (
                <HtmlParser html={orderItem.f_message} />
              )}
            </Link>
            <div className="returnItemWrapperWeb">
              {orderItem &&
                !orderItem.isReturn &&
                orderItem.isReturn === false && (
                  <p className="returnItem">
                    {myAccountTranslation.itemCannotReturn}
                  </p>
                )}
            </div>
          </div>
          <div className="product_quantity">
            {!!orderItem && orderItem.quantity > 0 && (
              <p>
                {myAccountTranslation.quantity} {orderItem.quantity}
              </p>
            )}
          </div>
          <div className="returnItemWrapperMob">
            {orderItem &&
              !orderItem.isReturn &&
              orderItem.isReturn === false && (
                <p className="returnItem">
                  {myAccountTranslation.itemCannotReturn}
                </p>
              )}
          </div>
          <div className="order_status">
            <div className="product_status">
              {/*  To Active the text color in delivered add class => delivered_text  */}
              {orderItem.f_action === "CANCEL" && (
                <div
                  className="form_black_border_btn round_btn cancel_order_btn"
                  onClick={() => this.toggleCancelOrderModal(true)}
                >
                  {myAccountTranslation.cancel}
                </div>
              )}
            </div>
          </div>
        </div>

        <ModalBFL
          showModal={cancelOrderModal}
          modalClass={`generic_modal cancel_order_modal ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <button
            className="cross_btn"
            onClick={e => this.toggleCancelOrderModal(false)}
          >
            &times;
          </button>
          <CancelOrderAlert
            myAccountTranslation={myAccountTranslation}
            handleCancelOrder={this.handleCancelOrder}
            cancelReason={cancelReason}
            quantitySelected={quantitySelected}
            handleTextArea={this.handleTextArea}
            handleSelectChange={this.handleSelectChange}
            OrderDetail={OrderDetailR}
            orderItem={orderItem}
            handleQuantityChange={this.handleQuantityChange}
            toggleCancelOrderModal={() => this.toggleCancelOrderModal(false)}
          />
        </ModalBFL>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  language: state.common.language,
  email: state.authReducer.email,
  OrderDetailR: state.myAccountReducer.OrderDetail,
  settings: state.common.settings,
  authReducer: state.authReducer,
  routeSlug: selectRouteSlug(state)
});
const mapStateToDispatch = dispatch => ({
  setCancelOrder: bindActionCreators(setCancelOrder, dispatch),
  cancelOrderAction: bindActionCreators(cancelOrder, dispatch),
  resetCancelOrder: bindActionCreators(resetCancelOrder, dispatch)
});
export default connect(mapStateToProps, mapStateToDispatch)(orderDetail);
