export const hideMegaMenu = e => {
  let activeLi = "active-li";
  let parent = e.currentTarget.parentElement;

  while (!parent.classList.contains(activeLi)) {
    parent = parent.parentElement;
  }
  if (parent.classList.contains(activeLi)) {
    parent.classList.remove(activeLi);
    parent.parentElement.nextElementSibling.classList.remove("active");
  }

  return null;
};

export const highlight = e => {
  const highlighted = "highlighted";
  const tlm = "top-level-menu";
  const highlightedEl = document.querySelector(`.${highlighted}`);
  let parent = e.currentTarget.parentElement;

  while (!parent.classList.contains(tlm)) {
    parent = parent.parentElement;
  }
  if (parent.classList.contains(tlm)) {
    highlightedEl && highlightedEl.classList.remove(highlighted);
    parent.querySelector(".arrow").classList.add(highlighted);
  }

  return null;
};

export const getElementHeight = domElement => {
  if (!domElement) return null;
  const { height } = domElement.getBoundingClientRect();
  return height;
};

export const getChildrenHeight = parentDomElement => {
  if (!parentDomElement) return null;
  return Array.from(parentDomElement.children)
    .slice(0, 2)
    .reduce((acc, child) => {
      acc += getElementHeight(child);
      return acc;
    }, 0);
};

export const addScriptToBody = (src, onLoad = null, preventReAdding) => {
  const scriptTags =
    preventReAdding && document.querySelectorAll(`script[src="${src}"]`);

  if (scriptTags?.length) return;

  const script = document.createElement("script");
  script.src = src;
  script.onload = onLoad;
  document.body.appendChild(script);
  return script;
};

export const removeScriptFromBody = script => {
  document.body.removeChild(script);
};
