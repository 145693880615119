import React from "react";

export const ModalBigCard = React.memo(({ title, subTitle, image }) => (
  <>
    <div className="modal-big-card">
      <div
        className="zoomedImage"
        style={{
          backgroundImage: `url(${image})`
        }}
      />
      <div className="big-card-description">
        <h3>{title.toUpperCase()}</h3>
        <p>{subTitle}</p>
      </div>
    </div>
  </>
));
