import React from "react";
import { Link } from "react-router-dom";
import PriceSlab from "../../price/price-slab";

const CartItemDetails = ({
  currentProductUrl,
  item,
  isAvailableInCountry,
  translation,
  currencyCode,
  commonSettings,
  productTranslation,
  language,
  showDiscountSlab
}) => (
  <>
    <div className="brand_desc">
      <Link to={currentProductUrl} className="detailLink">
        <h5>{item.brandName}</h5>
      </Link>
      <Link to={currentProductUrl} className="detailLink">
        <p>{item.title}</p>
      </Link>
    </div>

    {isAvailableInCountry && (
      <PriceSlab
        item={item}
        translation={translation}
        language={language}
        currencyCode={currencyCode}
        settings={commonSettings}
        showDiscountSlab={showDiscountSlab}
        showRetailPrice
        showDiscount
      />
    )}

    {!isAvailableInCountry && (
      <span className="error">{productTranslation.notAvailableIn}</span>
    )}
  </>
);

export default CartItemDetails;
