import queryString from "query-string";
import get from "lodash/get";
import { lowerCase } from "lodash";

import { fetchWebApi } from "../../webapis/apiResource";
import { BEConfig } from "../../config/env";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  getVisitorId,
  hideLoaders,
  showLoaders
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import {
  languageFromPathName,
  urlFilterMap,
  reverseUrlFilterMap,
  filterMap,
  incrementPageNoInQueryString,
  decrementPageNoInQueryString,
  isQueryForFilter,
  splitServiceQueries,
  handleAmpersandUrl,
  categoriesContainerHandler,
  decodeURLRecursively,
  getSearchTextFromQuery,
  checkUrlSearchMultiCategory
} from "../../util";
import { getAccessToken } from "../../util/storeHelper";
import {
  SET_PRODUCTS,
  HANDLE_FACET_CHANGE,
  HANDLE_GRID_CHANGE,
  HANDLE_PAGE_CHANGE,
  HANDLE_RANGE_CHANGE,
  TOGGLE_QUICKLOOK_VIEW,
  TOGGLE_APP_ONLY_ITEM_MODAL,
  HANDLE_SEARCH_TEXT,
  SET_PAGE_ROUTE,
  DELETE_FACET,
  HANDLE_SORT_CHANGE,
  RESET_PRODUCT_LIST,
  SET_INITIAL_STATE,
  HANDLE_ASSISTIVE_FILTER_CLICK,
  RESET_RANGE_CHANGE,
  HANDLE_GENERATED_URL,
  HANDLE_URL_CHANGE,
  SET_MOBILE_CURRENT_FILTER_SELECTION
} from "../constants";
import {
  PARENT_CATEGORY_KEY_NAME,
  BRAND_NAME,
  BRAND_NAME_URL_SUFFIX,
  LANGUAGE_ROUTE_KEY_MAP,
  DEFAULT_PAGE_LOADER_CONFIG
} from "../../constants";

import { AnalyticService, StorageService } from "../../services";
import { GAService } from "../../services/GA-service";

export const setPageRoute = () => ({ type: SET_PAGE_ROUTE });

export const deleteFacet = facetName => ({ type: DELETE_FACET, facetName });

export const setProducts = (content, facetName, updateRangeFlag) => ({
  type: SET_PRODUCTS,
  content,
  facetName,
  updateRangeFlag
});

export const handleFacetChange = (facet, subFacet, checked) => ({
  type: HANDLE_FACET_CHANGE,
  facet,
  subFacet,
  checked
});

export const handleGridChange = gridValue => {
  StorageService.setGridSize(gridValue);

  return {
    type: HANDLE_GRID_CHANGE,
    gridValue
  };
};

export const handleSortChange = sort => ({ type: HANDLE_SORT_CHANGE, sort });

export const handleURLChange = url => ({ type: HANDLE_URL_CHANGE, url });

export const handlePageChange = page => ({ type: HANDLE_PAGE_CHANGE, page });

export const toggleQuicklookView = showQuicklookModal => ({
  type: TOGGLE_QUICKLOOK_VIEW,
  showQuicklookModal
});
export const toggleAppOnlyItemModal = ({ showAppOnlyItemModal, product }) => ({
  type: TOGGLE_APP_ONLY_ITEM_MODAL,
  showAppOnlyItemModal,
  product
});

export const handleRangeChange = range => ({
  type: HANDLE_RANGE_CHANGE,
  range
});

const handleSearchText = searchText => ({
  type: HANDLE_SEARCH_TEXT,
  searchText
});

export const resetProductList = () => ({ type: RESET_PRODUCT_LIST });

export const setInitialState = () => ({ type: SET_INITIAL_STATE });

export const resetRangeChanged = () => ({ type: RESET_RANGE_CHANGE });

export const handleGeneratedUrls = ({ data }) => ({
  type: HANDLE_GENERATED_URL,
  data
});

/* We have to move these local methods to node server BE */
const _getQueryParams = (
  facets,
  page,
  range,
  searchText,
  facetName,
  addRange,
  url = ""
) => {
  let queryCreated = "?";
  const facetKeys = Object.keys(facets);
  facetKeys.forEach((facetKey, pidx) => {
    let filteredSubKey = Object.keys(facets[facetKey]).filter(
      facetSubkey => facets[facetKey][facetSubkey].checked
    );
    if (
      filteredSubKey.length &&
      !(url.indexOf(filterMap[facetKey]) >= 0 || url.indexOf(facetKey) >= 0)
    ) {
      queryCreated += `${encodeURIComponent(facetKey)}=`;
      const subFacetKeys = Object.keys(facets[facetKey]).filter(
        facetSubkey => facets[facetKey][facetSubkey].checked
      );
      subFacetKeys &&
        subFacetKeys.forEach((facetSubkey, idx) => {
          if (facets[facetKey][facetSubkey].checked) {
            queryCreated += facetSubkey;
            if (subFacetKeys.length - 1 !== idx) {
              queryCreated += ",";
            }
          }
        });
      if (facetKeys.length - 1 !== pidx) {
        queryCreated += "&";
      }
    }
  });
  if (addRange && range && (range.min || range.max)) {
    queryCreated += `${
      queryCreated !== "?"
        ? `&min=${range.min}&max=${range.max}`
        : `min=${range.min}&max=${range.max}`
    }`;
  }
  //queryCreated += searchText ? `&searchText=${searchText}` : '';
  queryCreated += searchText
    ? queryCreated[queryCreated.length - 1] === "&" || queryCreated === "?"
      ? `q=${searchText}`
      : `&q=${searchText}`
    : "";
  queryCreated =
    page && !facetName
      ? `${queryCreated}${
          queryCreated === "?" ? `page=${page}` : `&page=${page}`
        }`
      : queryCreated;

  if (queryCreated && queryCreated[queryCreated.length - 1] === "&") {
    queryCreated = queryCreated.substring(queryCreated.length - 1, -1);
  }
  return queryCreated;
};

export const getSelectedFacetsFromURL = URL => {
  URL = urlFilterMap(URL);
  let selectedFacets = {};
  URL.split(/&(?=\S)/g).forEach(cat => {
    const catChunks = cat.split("=");
    if (cat && catChunks.length > 1) {
      let subCategories = categoriesContainerHandler(catChunks[1]).filter(
        a => a
      );
      const facet = catChunks[0].replace("?", "");
      if (subCategories.length) {
        const decodedFacet = decodeURIComponent(facet);
        selectedFacets[decodedFacet] = [];
        subCategories.forEach(subCategory =>
          selectedFacets[decodedFacet].push(decodeURIComponent(subCategory))
        );
      }
    }
  });
  transformPageFacetFromArrayOfStringToNumber(selectedFacets);
  return selectedFacets;
};

const transformPageFacetFromArrayOfStringToNumber = selectedFacets => {
  if (!(selectedFacets && selectedFacets.page)) {
    // don't do anything - no page info in search query
    return;
  }
  if (Array.isArray(selectedFacets.page) && selectedFacets.page.length > 0) {
    // page number from search query comes as [<string>] - so we try parse it as number
    let page = selectedFacets.page.shift();
    if (isNaN(page)) {
      page = 0;
    } else {
      page = parseInt(page);
    }
    selectedFacets.page = page;
  }
};

export const getUpdatedFacets = (facets, selectedFacets) => {
  const keys = Object.keys(facets || {});
  keys &&
    keys.length &&
    keys.forEach(facetKey => {
      Object.keys(facets[facetKey]).forEach(facetSubkey => {
        facets[facetKey][facetSubkey] = {
          checked:
            selectedFacets[facetKey] &&
            selectedFacets[facetKey].indexOf(facetSubkey) !== -1,
          value:
            facets[facetKey][facetSubkey].value || facets[facetKey][facetSubkey]
        };
      });
    });
};

export const setMinMax = (products, query) => {
  const currentPriceStats =
    products.facets_stats && products.facets_stats.currentPrice;
  const _query =
    query &&
    query
      .split("&")
      .filter(item => item.includes("min") || item.includes("max"));
  let _min = 0,
    _max = 0;
  if (query) {
    _min =
      _query &&
      _query.length &&
      _query.find(item => item.includes("min")) &&
      parseInt(_query.find(item => item.includes("min")).split("=")[1]);
    _max =
      _query &&
      _query.length &&
      _query.find(item => item.includes("max")) &&
      parseInt(_query.find(item => item.includes("max")).split("=")[1]);
  }

  products.range = {
    min: !!_min ? _min : currentPriceStats && currentPriceStats.min,
    max: !!_max ? _max : currentPriceStats && currentPriceStats.max
  };

  if (_min || _max) products.rangeChanged = true;
};

export const getSortKey = products => {
  return (
    (typeof products.sort === "object" &&
      products.sort.length &&
      products.sort[0].toLowerCase()) ||
    (products.sort && products.sort.toLowerCase().trim()) ||
    null
  );
};

export const getProductsData = (
  facetName,
  history,
  query,
  searchText,
  language,
  showLoader = true,
  isLanguageChanged = false,
  oldUrl,
  browserBack,
  isProductListingPage = false,
  country,
  doNotPushHistory = false,
  historyState
) => {
  console.log("getProductsData");
  query = urlFilterMap(query);
  let apiURL = `${BEConfig.productsApi.protocol}${BEConfig.productsApi.baseURL}${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${BEConfig.productsApi.productsPageHandle}`;
  const isQuickFilterResult =
    checkUrlSearchMultiCategory(history.location.search) ||
    (doNotPushHistory && checkUrlSearchMultiCategory(query));
  if (isQuickFilterResult) {
    const { protocol, baseURL, port, versionInfo, getPageHandle } =
      BEConfig.catalogApi;
    apiURL = `${protocol}${baseURL}${port}${versionInfo}${getPageHandle}`;
  }

  return (dispatch, getState) => {
    showLoader && dispatch(showLoaders());
    let routeForHistory = "";
    const isBrandsPage =
      false &&
      !facetName &&
      checkAndHandleBrandsPageChanges(dispatch, getState);
    const reducerState = getState();
    const { products } = reducerState.productListingReducer;
    const { language: defaultLanguage } = reducerState.common;
    const { storeId } = reducerState.page && reducerState.page.homepageState;
    searchText = (reducerState.search && reducerState.search.searchText) || "";
    const locationState = history.location?.state;
    const countryId =
      reducerState.common.settings && reducerState.common.settings.countryId;
    oldUrl = products.url ? "" : oldUrl;
    const countryName = get(reducerState, "common.settings.countryName");
    let queryCreated;

    language = languageFromPathName(language || defaultLanguage);

    if (isLanguageChanged) {
      queryCreated = oldUrl;
    } else if ((browserBack && !query) || doNotPushHistory) {
      queryCreated = "?";
    } else if (searchText && isProductListingPage) {
      queryCreated = _getQueryParams("", "", "", searchText, "", "", "");
    } else {
      queryCreated = _getQueryParams(
        products.facets,
        products.page,
        products.range,
        searchText,
        facetName,
        products.rangeChanged,
        products.url
      );
    }

    const selectedFacets = getSelectedFacetsFromURL(query || queryCreated);
    const _querySearchText = getSearchTextFromQuery(query || queryCreated);
    queryCreated = incrementPageNoInQueryString(queryCreated);

    let historyPush;
    if (isProductListingPage && searchText) {
      historyPush = `/${language}-${country}/search${
        query ||
        `${queryCreated}${
          isLanguageChanged
            ? ""
            : `${!!getSortKey(products) ? `&sort=${getSortKey(products)}` : ""}`
        }`
      }`;
    } else {
      historyPush = `${history.location.pathname}${
        query ||
        `${queryCreated}${
          isLanguageChanged
            ? ""
            : `${!!getSortKey(products) ? `&sort=${getSortKey(products)}` : ""}`
        }`
      }`;
      if (
        historyPush === `/${language}-${country}/search?` ||
        historyPush === `/${language}-${country}/search/?`
      ) {
        historyPush = `/${language}-${country}/`;
        routeForHistory = historyPush;
      }
    }

    const isSearchRequest = history.location.pathname.includes("/search");
    if (!isBrandsPage && products.page === 0) {
      const newPath = historyPush.replace(/#/g, "%23");
      const prevPath = history.location.pathname + history.location.search;
      if (!isSearchRequest || (isSearchRequest && prevPath !== newPath)) {
        routeForHistory = newPath;
      }
    }
    if (isBrandsPage) {
      /**
       * Brands Page need special treatment for urls
       * 1. Url for history push needs to remove brand and parent category from query strings
       * 2. Url for api needs to carry brand and parent category info irrespective of other query params
       */

      // sanitize url for brandspage
      historyPush = sanitizeUrlForBrandsPage(historyPush);
      if (query && queryCreated) {
        // this is a page reload - append 'queryCreated' to 'query'
        query = `${query}&${queryCreated.slice(1)}`; // slice coz 'queryCreated' starts with '?'
      }
    }

    historyPush = reverseUrlFilterMap(historyPush);
    /**
     * check for current path in url or historypush string is same or not
     * if it is same, we do'nt need to push in history.
     */
    const isCurrentHistorySame =
      historyPush ===
      (history && history.location && history.location.pathname + "?");

    /**
     * Makes redirect for current page to let it recognise content that uses filters
     * (filter tools for products)
     */

    // Taking query params from history url arrived
    const [historyURL = "", queriesFromHistory = ""] = historyPush.split("?");

    // merging historical query params with created queries based on products listings
    const mergedQuery = decodeURLRecursively(
      _mergeQuery(_mergeQuery(queriesFromHistory, queryCreated), query)
    );

    if (
      !browserBack &&
      !isCurrentHistorySame &&
      isQueryForFilter(mergedQuery)
    ) {
      // Defining does next route uses filters
      const serviceQueries = splitServiceQueries(mergedQuery);

      // Creating the final url to push next to history
      const nextRouteWithServicesQueries = `${historyURL}${serviceQueries}`;
      routeForHistory = nextRouteWithServicesQueries;
    }

    // brandName query adding here to let algolia search right products
    if (products.url && !searchText) {
      queryCreated = queryCreated
        ? `?${products.url}&${queryCreated.replace("?", "")}`
        : `?${products.url}`;
    }

    queryCreated = `${queryCreated}${
      !!getSortKey(products) && !doNotPushHistory
        ? `&sort=${getSortKey(products)}`
        : ""
    }`;
    /**
     * Page number in query string is decremented because when we reload a url, we receive query variable,
     * otherwise querystring. Since products page is zero indexed while humans are one-indexed, we decrement
     * the url to fetch correct data reflect that in pagination. @see BFL-748 for more info.
     */
    // query = query && deleteServiceQueries(query);
    // queryCreated = queryCreated && deleteServiceQueries(queryCreated);

    let apiURLHelper = decrementPageNoInQueryString(
      _mergeQuery(query, queryCreated)
    );
    apiURLHelper =
      apiURLHelper.indexOf("?") === -1 ? `?${apiURLHelper}` : apiURLHelper;
    apiURLHelper = handleAmpersandUrl(urlFilterMap(apiURLHelper));

    if (routeForHistory) {
      const [pathname, search] = routeForHistory
        .replace(/&(?=\s)/g, "%26")
        .split("?");

      !doNotPushHistory &&
        history.push({
          pathname,
          search,
          state: historyState
        });
    }

    const fetchData = () => {
      if (isQuickFilterResult) {
        const historyQuery = decrementPageNoInQueryString(
          doNotPushHistory ? query : history.location.search
        );
        const pathname = history.location.pathname.replace(
          /\/\w{2}-\w{2}\//,
          ""
        );

        return fetchWebApi(
          getAccessToken(getState),
          `${apiURL}?${queryString.stringify({
            url: pathname + urlFilterMap(historyQuery),
            countryId,
            language: LANGUAGE_ROUTE_KEY_MAP[language],
            storeId: storeId || 0
          })}`,
          getVisitorId()
        );
      } else {
        const query = apiURLHelper
          .replace("q=", "searchText=")
          .replaceAll("&&", "&")
          .replaceAll("#", "%23");

        return fetchWebApi(
          getAccessToken(getState),
          apiURL + query,
          getVisitorId(),
          language,
          countryId,
          storeId || 0
        );
      }
    };

    return fetchData()
      .then(response => {
        if (response.status === 200 && response.data) {
          if (isQuickFilterResult) {
            response.data = response.data.data;
          }
          const itemCount = get(response, "data.nbHits", "0");
          searchText = searchText.toLowerCase();
          if (searchText && locationState?.search?.isSearch) {
            const productSearchPayload = {
              search_type: "text",
              number_of_search_results: itemCount
            };
            let seachCategory = "direct"; // if no category selected (or when user enter instead of select)
            let searchTerm = searchText; // if no seach term, then add the clicked text i.e., actual search text
            if (
              !isProductListingPage &&
              locationState &&
              locationState.search
            ) {
              const { search_category, search_term = "" } =
                locationState.search;
              searchTerm = search_term;
              seachCategory = search_category;
            }
            GAService.header.trackProductSearch({
              ...productSearchPayload,
              search_category: seachCategory.toLowerCase(),
              ...(searchTerm && { search_term: lowerCase(searchTerm) }),
              click_text: searchText
            });
            if (+itemCount === 0) {
              GAService.header.trackEmptySearchResult(searchText);
            }
            GAService.header.trackViewSearchResult({
              search_term: searchText,
              search_category: seachCategory,
              ...productSearchPayload
            });
            AnalyticService.product.trackProductSearch({
              text: searchText,
              itemCount,
              countryName
            });
          }
          getUpdatedFacets(response.data.facets, selectedFacets);
          setMinMax(response.data, history.location.search);

          const searchFromUrl = decodeURLRecursively(
            getSearchTextFromQuery(history.location.search)
          );
          const sameSearchText =
            searchFromUrl === decodeURLRecursively(_querySearchText);
          const currentSearchText =
            isSearchRequest && !sameSearchText
              ? searchFromUrl
              : _querySearchText;

          dispatch(handleSearchText(currentSearchText));
          dispatch(
            setProducts({ ...response.data, selectedFacets }, facetName)
          );
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        showLoader && dispatch(hideLoaders());
      });
  };
};

const _mergeQuery = (urlQuery, apiQuery) => {
  let urlSegments = getQuerySegmentsFromUrl(urlQuery);
  let apiSegments = getQuerySegmentsFromUrl(apiQuery);
  let _query = { ...urlSegments, ...apiSegments };
  let finalQuery = "";

  Object.keys(_query).forEach(key => {
    let value = _query[key];
    finalQuery = `${finalQuery}&${key}=${encodeURIComponent(value)}`;
  });

  if (finalQuery) {
    finalQuery = `?${finalQuery.slice(1)}`; // to remove '&' from beginning, and add '?'
  }
  return finalQuery;
};

const getQuerySegmentsFromUrl = urlQuery => {
  let _query = {};
  if (urlQuery) {
    urlQuery = urlQuery.startsWith("?") ? urlQuery.slice(1) : urlQuery; // remove '?'
    urlQuery = urlQuery.split(/&(?=\S)/g);
    urlQuery.forEach(segment => {
      segment = segment.split("=");
      let [key, value] = segment;
      if (key && value) {
        _query[key] = value;
      }
    });
  }
  return _query;
};

export const getBrandsListProductsData = (query, language) => {
  language = languageFromPathName(language);
  query = urlFilterMap(query);
  const apiURL = `${BEConfig.productsApi.protocol}${BEConfig.productsApi.baseURL}${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${BEConfig.productsApi.productsPageHandle}`;
  return (dispatch, getState) => {
    const reducerState = getState();
    const { products } = reducerState.productListingReducer;
    const { storeId } = reducerState.page && reducerState.page.homepageState;
    const countryId =
      reducerState.common.settings && reducerState.common.settings.countryId;
    let queryCreated = _getQueryParams(
      products.facets,
      products.page,
      products.range,
      null,
      null,
      products.rangeChanged,
      products.url
    );
    queryCreated = _mergeQuery(query, queryCreated);
    const selectedFacets = getSelectedFacetsFromURL(queryCreated);
    //const _querySearchText = _getSearchTextFromQuery(query || queryCreated);

    if (products.url) {
      queryCreated = queryCreated
        ? `?${products.url}&${queryCreated.replace("?", "")}`
        : `?${products.url}`;
    }
    queryCreated = `${queryCreated}${
      !!getSortKey(products) ? `&sort=${getSortKey(products)}` : ""
    }`;
    /**
     * Page number in query string is decremented because when we reload a url, we receive query variable,
     * otherwise querystring. Since products page is zero indexed while humans are one-indexed, we decrement
     * the url to fetch correct data reflect that in pagination. @see BFL-748 for more info.
     */
    let apiURLHelper = `${decrementPageNoInQueryString(queryCreated)}`;
    apiURLHelper =
      apiURLHelper.indexOf("?") === -1 ? `?${apiURLHelper}` : apiURLHelper;
    apiURLHelper = urlFilterMap(apiURLHelper);

    dispatch(resetProductList());
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      null,
      `${apiURL}${apiURLHelper
        .replace(/\bq=\b/g, "searchText=")
        .replace(/&&/g, "&")}`,
      null,
      language,
      countryId,
      storeId || 0
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          getUpdatedFacets(response.data.facets, selectedFacets);
          setMinMax(response.data, query || queryCreated);
          dispatch(setProducts({ ...response.data, selectedFacets }, null));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchGeneratedUrls = showLoader => {
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.generatedUrlHandle}`;
  return dispatch => {
    showLoader && dispatch(showLoadingIndicator());
    return fetchWebApi(null, apiURL)
      .then(response => {
        showLoader && dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(handleGeneratedUrls(response.data));
        }
        return response;
      })
      .catch(error => {
        showLoader && dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleAssistiveFilterClick = val => ({
  type: HANDLE_ASSISTIVE_FILTER_CLICK,
  val
});

const checkAndHandleBrandsPageChanges = (dispatch, getState) => {
  const { brandPageDetails } = getState().productListingReducer;
  const isBrandsPage = !!brandPageDetails;
  if (isBrandsPage) {
    const { parentCategory, brandName } = brandPageDetails;
    if (parentCategory) {
      dispatch(
        handleFacetChange(PARENT_CATEGORY_KEY_NAME, parentCategory, true)
      );
    }
    if (brandName) {
      dispatch(handleFacetChange(BRAND_NAME, brandName, true));
    }
  }
  return isBrandsPage;
};

const sanitizeUrlForBrandsPage = dirtyUrl => {
  let sanitizedUrl;
  // remove brand and parentCategory query parameters
  if (dirtyUrl) {
    dirtyUrl = dirtyUrl.split(`${BRAND_NAME_URL_SUFFIX}?`);
    sanitizedUrl = dirtyUrl[0];
    if (dirtyUrl.length === 2) {
      dirtyUrl = dirtyUrl[1];
      dirtyUrl = dirtyUrl.split(/&+/);
      dirtyUrl = dirtyUrl.filter(
        segment =>
          !(
            segment.startsWith(BRAND_NAME) ||
            segment.startsWith(PARENT_CATEGORY_KEY_NAME)
          )
      );
      dirtyUrl = dirtyUrl.join("&");
    }
  }
  return dirtyUrl
    ? `${sanitizedUrl}${BRAND_NAME_URL_SUFFIX}?${dirtyUrl}`
    : `${sanitizedUrl}${BRAND_NAME_URL_SUFFIX}`;
};

export const setMobilecurrentFilterSelected = filter => ({
  type: SET_MOBILE_CURRENT_FILTER_SELECTION,
  payload: filter
});
