import React from "react";
import map from "lodash/map";
import { HOMEPAGE_SECTION_TYPES } from "../../constants";
import AllBrands from "../../components/all-brands";
import Banner from "../../components/banner";
import CatalogCarousal from "../../components/catalog-carousal";
import NewArrivalSection from "../../components/new-arrival";
import ProductCarousel from "../../components/product/product-carousel";
import ShopByStore from "../../components/shop-by-stores";
import TopCategories from "../../components/categories";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";

const HomepageSections = ({
  homepageSections,
  language,
  routelanguage,
  settings,
  homepageTitle
}) =>
  map(homepageSections, (section, index) => {
    const key = `homepage_section_${index}`;
    switch (section.type) {
      case HOMEPAGE_SECTION_TYPES.TOP_BANNER_CONTENT:
        return (
          <Banner key={key} language={language} topBannerContent={section} />
        );
      case HOMEPAGE_SECTION_TYPES.NEWEST_ARRIVAL:
        return (
          <NewArrivalSection
            key={key}
            language={language}
            newestArrival={section}
            routelanguage={routelanguage}
            settings={settings}
          />
        );
      case HOMEPAGE_SECTION_TYPES.TOP_CATEGORIES:
        return (
          <TopCategories
            key={key}
            language={language}
            routelanguage={routelanguage}
            settings={settings}
            topCategories={section}
          />
        );
      case HOMEPAGE_SECTION_TYPES.SHOP_BY_BRANDS:
        return (
          <AllBrands key={key} language={language} shopByBrands={section} />
        );
      case HOMEPAGE_SECTION_TYPES.SHOP_BY_STORE:
        return (
          <ShopByStore
            key={key}
            language={language}
            settings={settings}
            shopByStore={section}
          />
        );
      case HOMEPAGE_SECTION_TYPES.IMAGE_CAROUSEL:
        return (
          <CatalogCarousal
            key={key}
            carousalContent={section}
            language={language}
            routelanguage={routelanguage}
            settings={settings}
          />
        );
      case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
        return (
          <ProductCarousel
            key={key}
            content={section}
            language={language}
            bannerPageTitle={homepageTitle}
            isSaleCarousel={false}
          />
        );
      case HOMEPAGE_SECTION_TYPES.DYNAMIC_BANNERS:
        return (
          <DynamicBannersWrapper
            key={key}
            section={section}
            bannerPageTitle={homepageTitle}
          />
        );
      default:
        return null;
    }
  });

export default HomepageSections;
