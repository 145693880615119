import React from "react";
import classnames from "classnames";

const HierarchicalCategoriesItem = ({
  facetName,
  subFacetName,
  subFacetValue,
  handleItemChecked,
  showRadio
}) => {
  const subFacetNames = subFacetName.split(">");

  return (
    <li
      className="categories_item_li"
      id={`${facetName}:${subFacetName}`}
      onClick={e => handleItemChecked(e, !subFacetValue.checked)}
    >
      <span id={`${facetName}:${subFacetName}`}>{`${
        subFacetNames.length > 2
          ? subFacetNames[2]
          : subFacetNames[1] || subFacetNames[0]
      }`}</span>
      {showRadio && (
        <span
          className={classnames(
            subFacetValue.checked && "selected_option",
            "option"
          )}
        >
          <input
            id={`${facetName}:${subFacetName}`}
            type="radio"
            className={classnames(
              subFacetValue.checked && "checked_radio",
              "custom_radio"
            )}
            onClick={e => handleItemChecked(e, !subFacetValue.checked)}
          />
          <label
            id={`${facetName}:${subFacetName}`}
            className="radio_option_value"
          />
        </span>
      )}
    </li>
  );
};

export default HierarchicalCategoriesItem;
