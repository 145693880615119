import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { emailValidationRegExp } from "../../../constants";
import cn from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { selectRouteSlug } from "../../../util/selectors";
import FormActivityHandler from "../../formActionsHandler";

class CheckoutGuestSignIn extends Component {
  state = { lastFocusedField: "" };

  static initialValues = {
    email: "",
    isChecked: true
  };

  static schema = Yup.object().shape({
    email: Yup.string().trim().matches(emailValidationRegExp).required()
  });

  setLastFocusedField = e =>
    this.setState({
      lastFocusedField: e.currentTarget.name
    });

  render() {
    const { checkoutTranslation, translation, routeSlug, onSubmit } =
      this.props;

    return (
      <Formik
        initialValues={CheckoutGuestSignIn.initialValues}
        onSubmit={onSubmit}
        validationSchema={CheckoutGuestSignIn.schema}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          const isInputInvalid = valueKey =>
            Boolean(touched[valueKey] && errors && errors[valueKey]);

          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  className={cn("form-control", {
                    borderRed: isInputInvalid("email")
                  })}
                  placeholder={checkoutTranslation.emailAddress}
                  onChange={handleChange}
                  aria-label="Email"
                  onFocus={this.setLastFocusedField}
                />
                {isInputInvalid("email") && (
                  <span className="error">{translation.emailRequired}</span>
                )}
                <div className="guest_signin_checkbox">
                  <div className="form-group checkbox_wrap square_chkBox default_address">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="isChecked"
                      id="form-checkbox"
                      onChange={handleChange}
                      checked={values.isChecked}
                      onFocus={this.setLastFocusedField}
                    />
                    <label className="checkbox_value" htmlFor="form-checkbox">
                      {checkoutTranslation.newsLetter}
                      <a
                        href={`/${routeSlug}/privacy-policy/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {checkoutTranslation.redirectPrivacyPolicy}
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-default round_btn form_white_btn"
              >
                {checkoutTranslation.continueAsGuest}
              </button>
              <FormActivityHandler
                formName={"checkout sign in guest"}
                lastFocusedFormField="email"
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(CheckoutGuestSignIn);
