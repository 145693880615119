export const productDetailSeed = {
  id: "",
  store: "",
  storeId: 0,
  title: "",
  transactionPrice: 0.0,
  currentPrice: 0.0,
  oldPrice: 0.0,
  stock: 0,
  productDetails: "",
  moreDetails: "",
  captionImageURL: "",
  overlayTagBgColor: "#009933",
  overlayTagLabel: "",
  overlayTagLabelColor: "#FFFFFF",
  overlayTag2BgColor: "#000000",
  overlayTag2Label: null,
  overlayTag2LabelColor: "#FFFFFF",
  pstock: 0,
  brandId: 0,
  brandName: "",
  catid: 0,
  catname: "",
  subcatid: 0,
  subcatname: "",
  colors: {
    colorSizeOptions: [
      {
        colorId: 0,
        colorLabel: null,
        sizeId: 0,
        sizeLabel: "",
        sizeStock: 0
      },
      {
        colorId: 0,
        colorLabel: null,
        sizeId: 0,
        sizeLabel: "",
        sizeStock: 0
      },
      {
        colorId: 0,
        colorLabel: null,
        sizeId: 0,
        sizeLabel: "",
        sizeStock: 0
      },
      {
        colorId: 0,
        colorLabel: null,
        sizeId: 0,
        sizeLabel: "",
        sizeStock: 0
      },
      {
        colorId: 0,
        colorLabel: null,
        sizeId: 0,
        sizeLabel: "",
        sizeStock: 0
      }
    ]
  },
  images: {
    productImageURLs: [
      {
        image: ""
      }
    ]
  },
  sizes: null,
  createDate: "2019-03-05T09:55:02.923",
  priceBy: ""
};
