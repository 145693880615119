export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_MOBILE_CURRENT_FILTER_SELECTION =
  "SET_MOBILE_CURRENT_FILTER_SELECTION";
export const HANDLE_FACET_CHANGE = "HANDLE_FACET_CHANGE";
export const HANDLE_GRID_CHANGE = "HANDLE_GRID_CHANGE";
export const SET_PAGE_ROUTE = "SET_PAGE_ROUTE";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const HANDLE_RANGE_CHANGE = "HANDLE_RANGE_CHANGE";
export const HANDLE_URL_CHANGE = "HANDLE_URL_CHANGE";
export const TOGGLE_QUICKLOOK_VIEW = "TOGGLE_QUICKLOOK_VIEW";
export const TOGGLE_APP_ONLY_ITEM_MODAL = "TOGGLE_APP_ONLY_ITEM_MODAL";
export const HANDLE_SEARCH_TEXT = "HANDLE_SEARCH_TEXT";
export const DELETE_FACET = "DELETE_FACET";
export const HANDLE_SORT_CHANGE = "HANDLE_SORT_CHANGE";
export const RESET_PRODUCT_LIST = "RESET_PRODUCT_LIST";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const HANDLE_ASSISTIVE_FILTER_CLICK = "HANDLE_ASSISTIVE_FILTER_CLICK";
export const RESET_RANGE_CHANGE = "RESET_RANGE_CHANGE";
export const HANDLE_GENERATED_URL = "HANDLE_GENERATED_URL";
