import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import UserAddress from "./user-address";
import { COUNTRY_SELECTION_MAP } from "../../../constants";
import DeleteModal from "../../delete-modal";
import { getDividedNumber } from "../../../util/phoneNumber";

class AddressBook extends React.Component {
  state = {
    countryFlag: "",
    confirmationPopUP: false,
    deleteId: "",
    countryShortName: "",
    sliceNumberLength: 0,
    isDeleteModalActive: false
  };

  componentDidMount() {
    this.updateStateWithCountryData();
  }

  componentDidUpdate(prevProps) {
    const { countryData } = this.props;

    if (!isEqual(prevProps.countryData, countryData)) {
      this.updateStateWithCountryData();
    }
  }

  updateStateWithCountryData = () => {
    const {
      countryData,
      address,
      mobileLocalCode,
      mobileLocalNumberLength,
      mobileCountryCode
    } = this.props;
    const phoneDetails = {
      phone: address.phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { areaCode } = getDividedNumber(phoneDetails);

    const areaCodeLength =
      ((areaCode && areaCode.length) || 0) + mobileLocalNumberLength;

    const country = countryData.find(countryItems => {
      return countryItems.countryId === address.countryId;
    });
    country &&
      this.setState({
        sliceNumberLength: areaCodeLength,
        countryFlag: COUNTRY_SELECTION_MAP[country.countryCode],
        countryShortName: country.countrySHORT
      });
  };

  toggleAddressModal = value => this.setState({ confirmationPopUP: value });

  handleDeleteAddress = id =>
    this.setState({ confirmationPopUP: true, deleteId: id });

  confirmDeleteAddress = () =>
    this.props.handleDeleteAddress(this.state.deleteId);

  handleCloseModal = value => this.setState({ isDeleteModalActive: value });

  render() {
    const {
      address,
      handleEditAddress,
      handleDefaultAddress,
      index,
      myAccountTranslation,
      handleDeleteAddress,
      orderSuccessTranslation,
      language,
      state,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    } = this.props;
    const { countryFlag, countryShortName, isDeleteModalActive } = this.state;
    const addressCityId = get(address, "cityId");
    const selectedCity =
      addressCityId &&
      !isEmpty(state) &&
      state.find(obj => obj.stateId === parseInt(addressCityId));

    const addressAreaId = get(address, "areaId");
    const selectedArea =
      addressAreaId &&
      selectedCity &&
      !isEmpty(selectedCity.area) &&
      selectedCity.area.find(obj => obj.areaId === parseInt(addressAreaId));

    const phoneDetails = {
      phone: address.phone,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
    const { phoneWithoutCountryCode: phoneNumber } =
      getDividedNumber(phoneDetails);

    return (
      <div className="address_book_card">
        <UserAddress
          myAccountTranslation={myAccountTranslation}
          name={`${address.firstname} ${address.lastname}`}
          addressId={address.addressId}
          addressLine1={address.addressLine1}
          addressLine2={address.addressLine2}
          area={(selectedArea && selectedArea.areaName) || address.area || ""}
          city={(selectedCity && selectedCity.stateName) || address.city || ""}
          zipCode={address.zipcode}
          country={address.country}
          phone={phoneNumber}
          isMobileVerified={address.isMobileVerified}
          orderSuccessTranslation={orderSuccessTranslation}
          language={language}
          countryFlag={countryFlag}
          mobileCountryCode={mobileCountryCode}
          countryShortName={countryShortName}
        />
        {address.default ? (
          <div className="form-group checkbox_wrap default_address checkoutDefault">
            <input
              type="checkbox"
              className="custom_checkbox"
              name="default"
              checked={address.default}
              id={address.addressId}
              readOnly
            />
            <label className="checkbox_value" htmlFor={address.addressId}>
              {myAccountTranslation.thisIsDefaultAddress}
            </label>
          </div>
        ) : (
          <div className="form-group checkbox_wrap default_address checkoutDefault">
            <input
              type="checkbox"
              className="custom_checkbox"
              name="default"
              id={address.addressId}
              checked={address.default}
              onChange={e => handleDefaultAddress(e, index, address)}
            />
            <label className="checkbox_value" htmlFor={address.addressId}>
              {myAccountTranslation.setAsDefaultAddress}
            </label>
          </div>
        )}
        <div className="address_action">
          <button
            className="round_btn form_black_border_btn"
            onClick={() => {
              handleEditAddress({
                ...address,
                phone: phoneNumber
              });
            }}
          >
            {myAccountTranslation.editAddress}
          </button>
          <button
            className="delete"
            onClick={() => {
              this.handleDeleteAddress(address.addressId);
              this.handleCloseModal(true);
            }}
          >
            {myAccountTranslation.delete}
          </button>
        </div>
        <DeleteModal
          language={language}
          titleTxt={myAccountTranslation.deleteAddressTitle}
          bodyTxt={myAccountTranslation.deleteAddress}
          cancelBtnTxt={myAccountTranslation.cancel}
          deleteBtnTxt={myAccountTranslation.delete}
          isDeleteModalActive={isDeleteModalActive}
          handleCloseModal={this.handleCloseModal}
          handleDeleteItem={handleDeleteAddress}
          itemId={address.addressId}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryData: state.common.countryData,
  state: state.common.settings.state,
  mobileLocalNumberLength:
    state.common.settings && state.common.settings.mobileLocalNumberLength,
  mobileLocalCode:
    state.common.settings && state.common.settings.mobileLocalCode,
  language: state.common.language
});

export default connect(mapStateToProps, null)(AddressBook);
