import React from "react";
import cn from "classnames";
import template from "lodash/template";
import ReactHtmlParser from "react-html-parser";
import Ruler from "../../images/icons/ruler.svg";

const SizeFormatMessage = ({
  translation,
  sizeIndication,
  handleSizeChart,
  withNoBorder
}) => {
  return (
    <div
      className={cn("size-spec", {
        with_no_border: withNoBorder
      })}
    >
      <div>
        <img src={Ruler} alt="size helper text" className="ruler-icon" />
      </div>
      <div onClick={handleSizeChart}>
        {ReactHtmlParser(
          template(translation.sizesShownCountryText)({
            sizes: sizeIndication?.sizes
          })
        )}
      </div>
    </div>
  );
};

export default SizeFormatMessage;
