import {
  SELECT_QUESTION_LIST,
  CHANGE_QUESTION_LIST,
  REMOVE_ORDER_IMG,
  SELECT_ORDER_ID,
  SENDUS_HANDLE_TEXT_CHANGE,
  GET_SENDUS_QUESITONS,
  SENDUS_IMG_HASH,
  SENDUS_RESET_FORM,
  SENDUS_SUBMITFORM_MODAL
} from "../constants";

import { BEConfig } from "../../config/env";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  getVisitorId
} from "./common.action";
import { fetchWebApi } from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import axios from "axios";
import get from "lodash/get";

export const selectQuestionList = (id, groupName, innerText) => ({
  type: SELECT_QUESTION_LIST,
  id,
  groupName,
  innerText
});
export const handleTextChange = (name, value, hash = null) => ({
  type: SENDUS_HANDLE_TEXT_CHANGE,
  name,
  value,
  hash
});
export const changeQuestionList = groupName => ({
  type: CHANGE_QUESTION_LIST,
  groupName
});
export const removeOrderImg = selectImg => {
  return { type: REMOVE_ORDER_IMG, selectImg };
};
export const selectOrderId = orderId => ({ type: SELECT_ORDER_ID, orderId });
export const getQuesitons = content => ({
  type: GET_SENDUS_QUESITONS,
  content
});
export const setImgHash = hash => ({ type: SENDUS_IMG_HASH, hash });
export const resetForm = () => ({ type: SENDUS_RESET_FORM });
export const sumbmitMsgModal = value => ({
  type: SENDUS_SUBMITFORM_MODAL,
  value
});
export const getQuestionsList = language => {
  language = languageFromPathName(language);
  const {
    sendUsEmailApi: { baseURL, protocol, versionInfo, sendUsEmailHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${versionInfo}${sendUsEmailHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (
          response.data &&
          !!response.data &&
          !!Object.keys(response.data).length
        ) {
          dispatch(getQuesitons(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getImgHash = (formData, name, value) => {
  const {
    zendeskApi: {
      baseURL,
      protocol,
      versionInfo,
      zendeskApiUploadFileHandle,
      Authorization
    }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${versionInfo}${zendeskApiUploadFileHandle}`;
  const body = { file: formData, filename: `${value.name}` };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    axios
      .post(apiURL, body, {
        headers: {
          Authorization: `${Authorization}`
        }
      })
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 201 && response.data) {
          dispatch(setImgHash(response.data.upload.token));
          dispatch(handleTextChange(name, value, response.data.upload.token));
        }
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const formSubmit = (bodyData, customerId) => {
  const {
    zendeskApi: {
      baseURL,
      protocol,
      versionInfo,
      zendeskApiUploadFormHandle,
      userformID
    }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${versionInfo}${zendeskApiUploadFormHandle}`;
  const data = {
    subject: get(bodyData, "comment.body", ""),
    comment: {
      ...bodyData.comment,
      body: get(bodyData, "userForm.message", "")
    },
    requester: {
      name: get(bodyData, "userForm.name", ""),
      email: get(bodyData, "userForm.email", "")
    },
    custom_fields: [
      {
        id: userformID.Description, //Description
        value: bodyData.userForm.message
      },
      {
        id: userformID.Subject, //Subject
        value: bodyData.subject
      },
      {
        id: userformID.CustomerID, //Customer ID
        value: customerId
      },
      {
        id: userformID.OrderID, //OrderID
        value: bodyData.userForm.orderId
      },
      {
        id: userformID.Mobile, // Mobile
        value: bodyData.userForm.phone
      },
      {
        id: userformID.TicketType, // Ticket type
        value: ""
      },
      {
        id: userformID.GuestEmail, // Gusest Email
        value: bodyData.userForm.email
      },
      {
        id: userformID.Name, // Gusest Name
        value: bodyData.userForm.name
      }
    ]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    axios
      .post(apiURL, { request: { ...data } })
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 201 && response.data) {
          dispatch(resetForm());
          dispatch(sumbmitMsgModal(true));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
