import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { TILE_DATA } from "../../constants";
import RightArrowImg from "../../images/arrow-point-to-right.svg";
import { Image } from "../../components/image";
import { selectRouteSlug } from "../../util/selectors";

const NeedHelpCategories = ({ needHelpTranslation, routeSlug }) => {
  return (
    <div className="category_section">
      <h4>{needHelpTranslation.topics}</h4>
      <ul>
        {TILE_DATA.map((category, index) => (
          <li key={category.data}>
            <Link to={`/${routeSlug}${category.link}`}>
              <Image src={category.imgSource} alt="helpIcons" />
              <h5>
                {needHelpTranslation[category.data]}
                <Image src={RightArrowImg} alt="helpIcons" />
              </h5>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(NeedHelpCategories)
);
