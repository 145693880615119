import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Notifications from "./components/notifications";
import AuthContent from "./routes/route-management/authRoutes";
import Loader from "./components/loader";
import {
  tokenGenerator,
  showPrevMode,
  tokenGeneratorCheckoutGuest,
  setEmail
} from "./redux/actions/authConfig.action";
import {
  getUserStorage,
  getPrevModeStorage,
  getGuestUserStorage,
  getUserEmail
} from "./util/browserStorage";

//var reactPlugin = new ReactPlugin();

class Gatekeeper extends Component {
  state = { accessToken: "" };

  UNSAFE_componentWillMount() {
    const { createToken, showPrevMode, createGuestToken, setEmail } =
      this.props;
    const accessToken = getUserStorage();
    const guestAccessToken = getGuestUserStorage();
    const isPreviewMode = getPrevModeStorage();
    const userStoredEmail = getUserEmail();
    this.setState({ accessToken });
    if (accessToken) {
      createToken(accessToken);
    }
    if (guestAccessToken) {
      createGuestToken(guestAccessToken);
    }
    if (isPreviewMode) {
      showPrevMode(true);
    }
    if (userStoredEmail) {
      setEmail(userStoredEmail);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isUserLoggedIn } = this.props;
    if (nextProps.isUserLoggedIn !== isUserLoggedIn) {
      this.setState({ accessToken: nextProps.isUserLoggedIn });
    }
  }

  render() {
    const { pageLoaderParams } = this.props;
    const showContent = <AuthContent />;
    return (
      <Fragment>
        <Notifications />
        <Loader {...pageLoaderParams} width={35} height={35} />
        {showContent}
      </Fragment>
    );
  }
}

const mapStateToProps = reduxState => ({
  isUserLoggedIn: reduxState.authReducer.userLoggedIn,
  pageLoaderParams: reduxState.common.pageLoader
});

const mapDispatchToProps = dispatch => ({
  createToken: bindActionCreators(tokenGenerator, dispatch),
  createGuestToken: bindActionCreators(tokenGeneratorCheckoutGuest, dispatch),
  showPrevMode: bindActionCreators(showPrevMode, dispatch),
  setEmail: bindActionCreators(setEmail, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Gatekeeper);
