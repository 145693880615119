import {
  ALL_CATEGORY_NAME,
  BRAND_NAME_URL_SUFFIX,
  PARENT_CATEGORY_SEO_KEY_NAME,
  BRAND_NAME_SEO_KEY_NAME
} from "../../../constants";

import { parseToSafeString } from "../../../util";

const getBrandUrlWithCategorySlug = (data, selectedCategory) => {
  const seoParentCategory = data[PARENT_CATEGORY_SEO_KEY_NAME] || data.category;

  const catSlug =
    selectedCategory === ALL_CATEGORY_NAME
      ? ALL_CATEGORY_NAME
      : seoParentCategory;
  const brandName = data[BRAND_NAME_SEO_KEY_NAME] || data.name;

  const brandRegex = /{brand}/;

  let url = `${BRAND_NAME_URL_SUFFIX}/{brand}/`;

  const brand = parseToSafeString(brandName);
  const category = parseToSafeString(catSlug);

  if (catSlug === ALL_CATEGORY_NAME) {
    url = url.replace(brandRegex, () => {
      return brand;
    });
  } else {
    url = `/${category}${BRAND_NAME_URL_SUFFIX}/${brand}/`;
  }

  return url;
};

export default getBrandUrlWithCategorySlug;
