import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { OtpService } from "../../../services";

import MobileVerification from "../../modal/modal-mobile-verification";
import ModalBFL from "../../modal/modal";
import { verifyOtp } from "../../../redux/actions/order.action";
import { fetchAddressBook } from "../../../redux/actions/authConfig.action";
import { selectShowLoader } from "../../../util/selectors";
import {
  hideLoadingIndicator,
  showLoadingIndicator
} from "../../../redux/actions/common.action";

class UserAddress extends React.Component {
  state = {
    toggleModal: false,
    valid: true,
    touched: false,
    PhoneOTP: "",
    otpValid: true,
    otpVerified: false,
    countryCode: "",
    showVerifySection: false,
    otp: "",
    areaCode: "",
    _phone: "",
    errorMessage: null
  };

  componentDidMount() {
    const { _phone } = this.state;
    this.handleUserInfo();
    this.setState({ PhoneOTP: _phone });
  }

  handleCountryChange = (status, value) =>
    this.setState({ PhoneOTP: value, valid: status, touched: true });

  sendOtp = async () => {
    const { countryCode, showLoadingIndicator, hideLoadingIndicator } =
      this.props;
    const { PhoneOTP, areaCode } = this.state;

    this.setState({ otpValid: true, otp: "", errorMessage: null });

    try {
      showLoadingIndicator();
      await OtpService.sendOtp({ phone: countryCode + areaCode + PhoneOTP });

      this.setState({ showVerifySection: true });
    } catch (error) {
      this.setState({
        errorMessage: error.response?.data?.message || error.message
      });
    } finally {
      hideLoadingIndicator();
    }
  };

  verifyOtp = async () => {
    const { verifyOtp, countryCode, addressId } = this.props;
    const { otp, areaCode, PhoneOTP } = this.state;

    try {
      const response = await verifyOtp({
        addressId,
        code: otp,
        phone: countryCode + areaCode + PhoneOTP
      });
      const verifyState = get(response, "data.success")
        ? { otpVerified: true, showVerifySection: false }
        : { otpValid: false };

      this.setState(verifyState);
    } catch (error) {
      this.setState({ otpValid: false });
    }
  };

  onSelectArea = (areaCode, phone) =>
    this.setState({ areaCode, PhoneOTP: phone });

  handleOTPChange = otp => this.setState({ otp });

  handleToggleModal = () => {
    this.setState({
      toggleModal: !this.state.toggleModal,
      showVerifySection: false
    });
    const {
      countryData,
      countryShortName,
      phone,
      mobileLocalNumberLength,
      countryId
    } = this.props;
    const { otpVerified } = this.state;
    const areaCodes = this._getAreaCodes(countryData, countryShortName);
    let areaCodeLength = 0;

    if (areaCodes && areaCodes.length) {
      areaCodes.forEach(areaCode => {
        if (phone.slice(0, areaCode.length) === areaCode) {
          areaCodeLength = areaCode.length;
          this.setState({ areaCode });
        }
      });
    }
    if (phone && phone.length >= mobileLocalNumberLength) {
      let _phone = phone.substring(areaCodeLength);
      this.setState({ PhoneOTP: _phone });
    }
    if (otpVerified) {
      this.props.fetchAddressBook(countryId);
    }
  };

  _getAreaCodes = (countryData, defaultCountry) => {
    const countryInfo =
      !isEmpty(countryData) &&
      countryData.find(country => country.countrySHORT === defaultCountry);
    return (
      !isEmpty(countryInfo.mobileLocalCode) &&
      countryInfo.mobileLocalCode.split(",")
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(this.props, "phone") !== nextProps.phone) {
      this.handleUserInfo();
      this.setState({
        otpVerified: false,
        showVerifySection: false
      });
    }
  }

  handleUserInfo = () => {
    const { phone, mobileLocalNumberLength, mobileLocalCode } = this.props;
    if (phone && phone.length !== mobileLocalNumberLength) {
      let _areaCode = mobileLocalCode && mobileLocalCode.split(",");
      let areaCodeLength = _areaCode && _areaCode[0].length;
      let _phone = (phone && phone.substring(areaCodeLength)) || phone;
      this.setState({
        _phone: _phone,
        areaCode: _areaCode && _areaCode[0].length
      });
    } else {
      if (phone && phone.length === mobileLocalNumberLength) {
        this.setState({ _phone: phone });
      }
    }
  };

  render() {
    const {
      name,
      addressLine1,
      addressLine2,
      area,
      city,
      zipCode,
      country,
      phone,
      myAccountTranslation,
      isMobileVerified,
      language,
      orderSuccessTranslation,
      countryFlag,
      countryShortName,
      countryData,
      mobileLocalNumberLength,
      mobileCountryCode,
      showLoader
    } = this.props;

    let _phone = phone.substring(4);
    const {
      toggleModal,
      valid,
      touched,
      otpValid,
      otpVerified,
      PhoneOTP,
      showVerifySection,
      otp,
      areaCode,
      errorMessage
    } = this.state;
    let phoneNumberLength = mobileLocalNumberLength + areaCode.length;

    return (
      <div className="user_address">
        <h5>{name}</h5>
        <address>
          {addressLine1}
          {addressLine2 && `${addressLine2} `} {area && `${area}`} {city}{" "}
          {zipCode},<br />
          {countryFlag && (
            <img className="country-flag" alt="flag" src={countryFlag} />
          )}
          {country && <div className="country">{country}</div>} <br />
          {phone && phone !== 0 && (
            <>
              {`+${mobileCountryCode} ${
                phone && phone.length > phoneNumberLength ? _phone : phone
              }`}
              <button
                className="verify"
                onClick={this.handleToggleModal}
                disabled={isMobileVerified}
              >
                {isMobileVerified
                  ? [myAccountTranslation.verified]
                  : myAccountTranslation && `[${myAccountTranslation.verify}]`}
              </button>
            </>
          )}
        </address>
        {!isMobileVerified && (
          <ModalBFL
            showModal={toggleModal}
            modalClass={classnames(
              {
                arabic: language.includes("ar")
              },
              "generic_modal mobile_verification_modal wide_generic_modal"
            )}
          >
            <button className="cross_btn" onClick={this.handleToggleModal}>
              &times;
            </button>
            {(PhoneOTP || touched) && (
              <MobileVerification
                orderSuccessTranslation={orderSuccessTranslation}
                valid={valid}
                touched={touched}
                otpValid={otpValid}
                otpVerified={otpVerified}
                phone={PhoneOTP}
                countryCode={mobileCountryCode}
                handleCountryChange={this.handleCountryChange}
                onSelectFlag={() => {}}
                onSelectArea={this.onSelectArea}
                sendOtp={this.sendOtp}
                showVerifySection={showVerifySection}
                otp={otp}
                handleOTPChange={this.handleOTPChange}
                verifyOtp={this.verifyOtp}
                language={language}
                countrySHORT={countryShortName}
                countryData={countryData}
                mobileLocalNumberLength={mobileLocalNumberLength}
                areaCode={areaCode}
                errorMessage={errorMessage}
                readonly={false}
                showLoader={showLoader}
              />
            )}
          </ModalBFL>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryData: state.common.countryData,
  countryId: state.common.settings && state.common.settings.countryId,
  countryCode: state.common.settings && state.common.settings.mobileCountryCode,
  mobileLocalNumberLength:
    state.common.settings && state.common.settings.mobileLocalNumberLength,
  mobileLocalCode:
    state.common.settings && state.common.settings.mobileLocalCode,
  showLoader: selectShowLoader(state)
});

const mapStateToDispatch = {
  verifyOtp,
  fetchAddressBook,
  showLoadingIndicator,
  hideLoadingIndicator
};

export default connect(mapStateToProps, mapStateToDispatch)(UserAddress);
