import React from "react";
import isEmpty from "lodash/isEmpty";
import HtmlParser from "../../components/html-parser";
import AccordionTile from "./accordion-tile";

const AccordianStatic = ({ data }) => (
  <div className="static_html_pages">
    <div className="container">
      <div className="static_template">
        <HtmlParser html={data.content.html} />
      </div>
      <div className="p-42">
        {!isEmpty(data.content.accord) && (
          <div className="accordion_template">
            {data.content.accord.map(item => (
              <AccordionTile item={item} key={"accordion_" + item.title} />
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default AccordianStatic;
