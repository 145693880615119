export const getModifiedInStoreShopName = (instore, index, translation) => {
  const countClassName =
    instore.capacity > 0
      ? "inStoreDropDownCount"
      : "inStoreDropDownCountDisabled";
  const count = index + 1;

  const storeNameClassName =
    instore.capacity <= 0 ? "storeName" : "storeNameBold";
  const storeName = `${instore.name}, ${instore.address}`;

  return {
    ...instore,
    value: `
      <div class="inStoreDropDown">
          <div class="dropdown_content_container inStoreDropDownView" id="Your Selected Area">
              <div class="inStoreDropDownViewList disabled">
                  <div class=${countClassName}>${count}</div>
                  <div>
                      <p class="storeCategory">${instore.storeCategory}</p>
                      <p class=${storeNameClassName}>${storeName}</p>
                      <p class="workingHours">${instore.extraInfo}</p>
                      ${
                        instore.capacity <= 0
                          ? `<p class="unavailable">${translation.storeTemporaryUnavailable}</p>`
                          : ""
                      }
                  </div>
              </div>
              <hr>
          </div>
      </div>
    `
  };
};

export const getCollectionPointsMarkup = (point, index, translation) => {
  const countClassName =
    point.avl_limit > 0
      ? "collectionPointDropDownCount"
      : "inStoreDropDownCountDisabled";

  const storeNameClassName =
    point.avl_limit <= 0 ? "storeName" : "storeNameBold";

  const count = index + 1;
  const workingHoursMarkup = point.workingHours
    ? point.workingHours.reduce((acc, info, index) => {
        acc += `<p key=${index} class="workingHours">${info}</p>`;
        return acc;
      }, "")
    : "";
  return {
    collectionPointId: point.collectionPointId,
    disableOption: point.avl_limit <= 0,
    value: `
      <div class="collectionPointDropDown">
          <div class="dropdown_content_container collectionPointDropDownView" id="Your Selected Area">
              <div class="collectionPointDropDownViewList disabled">
                  <div class=${countClassName}>${count}</div>
                  <div class="w-100">
                      <p class="storeCategory">${
                        point.station_name
                      } <span class="distance">${point.distance}</span></p>
                      <p class=${storeNameClassName}>${point.address_info}</p>
                      ${
                        point.avl_limit <= 0
                          ? `<p class="unavailable">${translation.storeTemporaryUnavailable}</p>`
                          : workingHoursMarkup
                      }
                  </div>
              </div>
              <hr>
          </div>
      </div>
    `
  };
};
