import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import get from "lodash/get";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import AddressBook from "../../components/address/address-book";
import ModalBFL from "../../components/modal/modal";
import { setAddress } from "../../redux/actions/checkout.action";
import { saveAddress } from "../../redux/actions/homepage.action";
import { resetAddress } from "../../redux/actions/address.action";
import { deleteAddress } from "../../redux/actions/authConfig.action";
import AddNewAddress from "./add-new-address";
import EmptyCart from "../../components/cart/cart-empty";
import DeleteModal from "../../components/delete-modal";
import AddressForm from "./address-form";
import { MODAL_DIALOG_OPTIONS } from "../../constants";
import AddAddressMobileNumber from "../../components/modal/modal-mobile-verification/addAddressMobileNumber";
import { GAService } from "../../services/GA-service";

class MyAddressBook extends Component {
  state = {
    openAddressModal: false,
    addNewAddress: false,
    isCancelModalActive: false,
    showOTPModal: false
  };
  componentDidMount() {
    if (this.props.subTab) {
      this.handleAddNewAddress();
    }
  }

  handleEditAddress = address => {
    this.setState({ openAddressModal: true });
    this.props.setAddress(address);
  };
  toggleAddressModal = value => {
    this.setState({ openAddressModal: value });
    if (value === false) {
      this.props.resetAddress();
    }
  };
  handleDefaultAddress = async (e, index, address) => {
    const { addressBook, saveAddress } = this.props;

    if (addressBook[index]) {
      addressBook[index].addressLine2 = addressBook[index].addressLine2 || "";
      const response = await saveAddress({
        ...address,
        default: e.target.checked,
        lastname: address.lastname || ""
      });

      if (response.OTPNeeded) {
        this.setState({
          showOTPModal: true
        });
        return false;
      }
    }
  };
  handleAddNewAddress = () => {
    GAService.common.trackAddNewAddressCTA();
    this.setState({ addNewAddress: !this.state.addNewAddress });
  };
  updateAddress = async address => {
    const { saveAddress, countryId } = this.props;
    if (address) {
      const _address = {
        ...address,
        firstname: `${address.firstname}`.trim(),
        lastname: `${address.lastname}`.trim(),
        address: `${address.address}`.trim(),
        addressLine1: `${address.addressLine1}`.trim(),
        addressLine2: address.addressLine2
          ? `${address.addressLine2}`.trim()
          : "",
        area: `${address.area}`.trim(),
        country: `${address.country}`.trim(),
        city: `${address.city}`.trim(),
        cityId: address.cityId || 0,
        countryId,
        phone: address.dialCode + address.phone
      };
      const response = await saveAddress(_address);

      if (response.OTPNeeded) {
        this.setState({
          showOTPModal: true
        });
        return false;
      }

      this.props.resetAddress();
      return true;
    }
  };
  handleCloseModal = (value, actionValue) => {
    const { resetAddress } = this.props;
    if (actionValue === MODAL_DIALOG_OPTIONS.yes) {
      resetAddress();
      this.handleAddNewAddress();
    }
    this.setState({ isCancelModalActive: value });
  };

  componentWillUnmount() {
    this.props.resetAddress();
  }

  onSubmit = async data => {
    const { address } = this.props;
    const addressData = this.getTransformedAddressData(data, address.addressId);

    const successUpdate = await this.updateAddress(addressData);
    this.toggleAddressModal(!successUpdate);
  };

  getTransformedAddressData = (
    {
      address,
      area,
      phoneArea,
      city,
      country,
      isDefaultAddress,
      firstName,
      lastName,
      phoneNumber,
      zipCode
    },
    id
  ) => {
    const areaCode = phoneArea || "";

    return {
      addressLine1: address.trim(),
      area: area.value,
      areaCode,
      areaId: area.key,
      cityId: city.key,
      country: country.value,
      countryId: country.key,
      default: isDefaultAddress,
      dialCode: this.props.mobileCountryCode,
      firstname: firstName,
      lastname: lastName,
      phone: areaCode + phoneNumber,
      zipcode: zipCode.toString(),
      addressId: id
    };
  };

  onCloseAddressOTP = () => {
    this.setState({ showOTPModal: false });
  };
  onSubmitBtnClick = (isNew = false) => {
    const { checkoutTranslationEn } = this.props;
    const { saveAddress, AddNewAddress } = checkoutTranslationEn;
    const ctaText = isNew ? AddNewAddress : saveAddress;
    GAService.common.trackCTAClick(ctaText, "button");
  };

  render() {
    const {
      addressBook,
      language,
      myAccountTranslation,
      checkoutTranslation,
      deleteAddress,
      orderSuccessTranslation,
      mobileCountryCode,
      noRecordImgUrl,
      handleGoBack,
      countryId,
      resetAddress
    } = this.props;
    const {
      openAddressModal,
      addNewAddress,
      isCancelModalActive,
      showOTPModal
    } = this.state;
    return (
      <div className="overview-wrapper">
        <OverViewHeading
          headingName={myAccountTranslation.myAddressBook}
          icon="my_address_icon"
          handleGoBack={handleGoBack}
        />

        {addNewAddress ? (
          <div className="width715_wrapper add_new_address">
            <AddNewAddress
              handleAddNewAddress={this.handleAddNewAddress}
              updateAddress={address => this.updateAddress(address)}
              checkoutTranslation={checkoutTranslation}
              language={language}
              handleCloseModal={this.handleCloseModal}
              onSubmitBtnClick={() => this.onSubmitBtnClick(true)}
            />
          </div>
        ) : (
          <div className="width860_wrapper">
            {addressBook && addressBook.length ? (
              <>
                {addressBook.filter(address => address.countryId === countryId)
                  .length ? (
                  <div className="address_wrapper flex">
                    {addressBook
                      .filter(address => address.countryId === countryId)
                      .map((address, index) => (
                        <AddressBook
                          checkoutTranslation={checkoutTranslation}
                          myAccountTranslation={myAccountTranslation}
                          orderSuccessTranslation={orderSuccessTranslation}
                          index={index}
                          address={address}
                          handleEditAddress={this.handleEditAddress}
                          handleDefaultAddress={this.handleDefaultAddress}
                          handleDeleteAddress={deleteAddress}
                          key={address.addressId}
                          language={language}
                          mobileCountryCode={mobileCountryCode || ""}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="address_wrapper flex align-center">
                    <div className="form-group address_empty">
                      <EmptyCart
                        noRecordHeading={
                          myAccountTranslation.noSavedAddressHeading
                        }
                        noRecordDesc={myAccountTranslation.noSavedAddressDesc}
                        noRecordImgUrl={noRecordImgUrl}
                        language={language}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <input
                    type="button"
                    className="round_btn form_black_btn account_cta"
                    value={myAccountTranslation.addNewAddress}
                    onClick={this.handleAddNewAddress}
                  />
                </div>
              </>
            ) : (
              <div className="address_wrapper flex align-center">
                <div className="form-group address_empty">
                  <EmptyCart
                    noRecordHeading={myAccountTranslation.noSavedAddressHeading}
                    noRecordDesc={myAccountTranslation.noSavedAddressDesc}
                    noRecordImgUrl={noRecordImgUrl}
                    language={language}
                  />
                  <input
                    type="button"
                    className="round_btn form_black_btn account_cta"
                    value={myAccountTranslation.addNewAddress}
                    onClick={this.handleAddNewAddress}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <ModalBFL
          showModal={openAddressModal}
          modalClass={`login_modal change_address_modal ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <button
            className="cross_btn"
            onClick={e => this.toggleAddressModal(false)}
          >
            &times;
          </button>
          <h2>{checkoutTranslation.editAddress}</h2>
          <Scrollbars autoHeight autoHeightMin={200} autoHeightMax={500}>
            <AddressForm
              handleContinueAsGuest={e => this.toggleAddressModal(false)}
              closeModal={e => this.toggleAddressModal(false)}
              checkoutTranslation={checkoutTranslation}
              myAccountTranslation={myAccountTranslation}
              language={language}
              alreadyLoggedIn={true}
              submitButtonText={checkoutTranslation.saveAddress}
              showConfirmationModal={true}
              onSubmit={this.onSubmit}
              handleCloseModal={() => this.toggleAddressModal(false)}
              onSubmitBtnClick={() => this.onSubmitBtnClick(false)}
            />
          </Scrollbars>
        </ModalBFL>
        <AddAddressMobileNumber
          showModal={showOTPModal}
          onSuccessOtp={() => {
            resetAddress();
            this.toggleAddressModal(false);
            this.setState(() => ({ addNewAddress: false }));
          }}
          closeModal={this.onCloseAddressOTP}
        />
        <DeleteModal
          language={language}
          titleTxt={checkoutTranslation.address}
          bodyTxt={checkoutTranslation.doYouWantToContinue}
          cancelBtnTxt={checkoutTranslation.yes}
          deleteBtnTxt={checkoutTranslation.no}
          isDeleteModalActive={isCancelModalActive}
          handleCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  address: state.addressReducer,
  addressBook: get(state, "authReducer.addressBook", []),
  countryId: state.common.settings.countryId,
  mobileCountryCode: get(state, "common.settings.mobileCountryCode", "")
});

const mapStateToDispatch = dispatch => ({
  setAddress: bindActionCreators(setAddress, dispatch),
  saveAddress: bindActionCreators(saveAddress, dispatch),
  resetAddress: bindActionCreators(resetAddress, dispatch),
  deleteAddress: bindActionCreators(deleteAddress, dispatch)
});

export default connect(mapStateToProps, mapStateToDispatch)(MyAddressBook);
