import get from "lodash/get";
import find from "lodash/find";
import { AVAILABLE_PAYMENT_TYPE, SHOW_ALL } from "../../constants";

export const getSocialAccountObject = (socialAccounts, mode) => {
  const socialAccountDetails = get(socialAccounts, "socialAccountDetails", []);
  const socialAccountObject = find(socialAccountDetails, { mode });
  return socialAccountObject;
};

export const getCardList = (savedCardDisplayType, checkoutCardsList) => {
  switch (savedCardDisplayType) {
    case AVAILABLE_PAYMENT_TYPE.CHECKOUTCOM:
      return checkoutCardsList;

    case "":
    default:
      return [];
  }
};

export const trimObjectValues = values =>
  Object.keys(values).reduce(
    (acc, key) => ((acc[key] = values[key].trim?.() || values[key]), acc),
    {}
  );

export const getMonthsValue = filterMonth =>
  filterMonth === SHOW_ALL ? 0 : filterMonth;
