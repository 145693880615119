import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../../util";
import "./style.scss";

const LoyaltyAlerts = ({
  translation,
  outOfStock,
  loyaltyPoints,
  token,
  language,
  isUserLoggedIn,
  isJoinedToLoyaltyProgram,
  routeSlug
}) => {
  const isMob = isMobile.any();
  const joinLink = isMob
    ? `/${routeSlug}/my-account/loyalty-program/`
    : `/${routeSlug}/my-account/account-overview/`;

  const getInfoForLoyaltyAlert = () => {
    if (token && isJoinedToLoyaltyProgram && isUserLoggedIn) {
      return (
        <div className="loyalty_wrap">
          <p>
            {translation.youWillEarn} <span>{loyaltyPoints}</span>{" "}
            {translation.loyaltyPoints}.
            <Link
              className="learn-more-text"
              to={`/${routeSlug}/loyaltyprogram/`}
            >
              {translation.learnMore}
            </Link>
          </p>
        </div>
      );
    } else if (!isUserLoggedIn) {
      return (
        <div className="loyalty_wrap">
          <p>
            <Link className="loyalty-join" to={`/${routeSlug}/login/`}>
              {translation.signIn}
              <span className="extra_space"> </span>
            </Link>

            {translation.signInAndJoin}
            <Link
              className="learn-more-text"
              to={`/${routeSlug}/loyaltyprogram/`}
            >
              {translation.learnMore}
            </Link>
          </p>
        </div>
      );
    } else if (token && isUserLoggedIn && !isJoinedToLoyaltyProgram) {
      return (
        <div className="loyalty_wrap">
          <p>
            <Link className="loyalty-join" to={joinLink}>
              {translation.join}
            </Link>

            {translation.toEarnPoints}
            <Link
              className="learn-more-text"
              to={`/${routeSlug}/loyaltyprogram/`}
            >
              {translation.learnMore}
            </Link>
          </p>
        </div>
      );
    }
  };

  return (
    <>
      {outOfStock && (
        <p className="quantity_unavailable">{translation.outOfStockAlertMsg}</p>
      )}
      {getInfoForLoyaltyAlert()}
    </>
  );
};

export default LoyaltyAlerts;
