import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import DynamicBannersWrapper from "../../dynamic/dynamic-banner";
import { getBaseUrl } from "../../../util";
import { isAppOnlyAvailableItem } from "../../../util/product";
import PriceSlab from "../../price/price-slab";
class ContentDescription extends Component {
  static defaultProps = {
    isProductAvailableInCountry: true
  };

  state = {
    isMobile: ""
  };

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth });
  }

  truncateProductTitle = (title, characters) => {
    const { showEllipses } = this.props;

    if (title && title.length > characters && showEllipses) {
      return title.substring(0, characters) + "...";
    }

    return title;
  };

  render() {
    const { isMobile } = this.state;
    const {
      item,
      gridSize,
      translation,
      language,
      noPrice,
      currencyCode,
      getBrandProductLink,
      commonSettings,
      getProductUrlName,
      Redirection,
      removeInnerLink,
      showDiscountSlab,
      isProductAvailableInCountry,
      handleAppOnlyItem,
      pdpFirstSection,
      showRetailPrice,
      cartOnDiscount
    } = this.props;

    const ProductTitle = getProductUrlName(
      get(item, "en_title", "") || get(item, "title", "")
    );
    const id = item && (item.pId || item.id);
    const vatText = get(commonSettings, `${language}_vatText`, "");

    const ProductDescriptionWrapperTag = props =>
      removeInnerLink ? (
        <div {...props} />
      ) : (
        <Link
          onClick={event => {
            if (isAppOnlyAvailableItem(item)) {
              event.preventDefault();
              handleAppOnlyItem(item);
            }
          }}
          to={`${getBaseUrl(this.props)}/${ProductTitle || "---"}/${id}/p/`}
          {...props}
        />
      );

    const BrandLinkTag = props => {
      const brandLink = getBrandProductLink && getBrandProductLink(item);
      return brandLink ? (
        <Link to={brandLink} {...props} />
      ) : (
        <Fragment {...props} />
      );
    };

    return (
      <Fragment>
        {Redirection ? (
          <ProductDescriptionWrapperTag className="product_detail_link">
            <div className="view_content">
              <BrandLinkTag>
                <h5 className="pdp_brand">{item && item.brandName}</h5>
              </BrandLinkTag>
              <h1 className="product_title">{item?.title}</h1>
              {isProductAvailableInCountry && !noPrice && (
                <Fragment>
                  {Boolean(item && (item.currentPrice || item.oldPrice)) && (
                    <PriceSlab
                      item={item}
                      gridSize={gridSize}
                      translation={translation}
                      language={language}
                      currencyCode={currencyCode}
                      settings={commonSettings}
                      showDiscountSlab={showDiscountSlab}
                      showRetailPrice={showRetailPrice}
                      showDiscount={cartOnDiscount}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </ProductDescriptionWrapperTag>
        ) : (
          <>
            <div className="view_content">
              <BrandLinkTag>
                <h5 className="pdp_brand">{item.brandName}</h5>
              </BrandLinkTag>
              <h1>
                {gridSize === 4
                  ? this.truncateProductTitle(
                      item.title,
                      isMobile > 1024 ? 76 : 46
                    )
                  : this.truncateProductTitle(item.title, 42)}
              </h1>
              {isProductAvailableInCountry && !noPrice && (
                <Fragment>
                  {Boolean(item && (item.currentPrice || item.oldPrice)) && (
                    <PriceSlab
                      item={item}
                      gridSize={gridSize}
                      translation={translation}
                      language={language}
                      currencyCode={currencyCode}
                      settings={commonSettings}
                      showRetailPrice={showRetailPrice}
                      isFromPdp
                    />
                  )}
                  {vatText && (
                    <span className="vat_price">{`(${vatText})`}</span>
                  )}
                </Fragment>
              )}
            </div>

            {pdpFirstSection && (
              <>
                {pdpFirstSection.map((section, key) => (
                  <DynamicBannersWrapper
                    section={section}
                    key={key}
                    bannerPageTitle={section.sectionTitle}
                  />
                ))}
              </>
            )}
          </>
        )}
      </Fragment>
    );
  }
}

export default ContentDescription;
