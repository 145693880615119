import qs from "query-string";

import ApiService from "./ApiService";

import { BEConfig } from "../config/env";

import { webapiGet, webapiPost } from "../webapis/core";
import { getDispatch } from "../redux/store";
import { PRODUCT_CAROUSEL_ITEMS_LIMIT } from "../constants";
import {
  hideLoaders,
  hideLoadingIndicator,
  showLoaders,
  showLoadingIndicator
} from "../redux/actions/common.action";
import { handleFetchError } from "../util/errorHandler";

class SaleService extends ApiService {
  async fetchCarouselData({ slug, dataType, countryId, language, storeId }) {
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.getPageHandle}`;
    const limit = PRODUCT_CAROUSEL_ITEMS_LIMIT;

    const endpoint = `${baseUrl}?${qs.stringify({
      countryId,
      language,
      storeId,
      limit
    })}`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      {
        slug,
        dataType
      },
      this.getVisitorId()
    ).request;
    return response.data;
  }

  async fetchDefaultPageData({ slug, gridValue }) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.catalogApi.flashSaleInfo
    }/${slug}?gridValue=${gridValue}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async fetchSpecificTabData({ tabSlug, saleSlug, queries }) {
    const endpoint = qs.stringifyUrl({
      url: `${this.getBaseUrl()}${
        BEConfig.catalogApi.flashSaleProducts
      }/${saleSlug}/${tabSlug}`,
      query: {
        ...queries
      }
    });

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getCatalogSaleCarouselData = async ({
    slug,
    dataType,
    countryId,
    language,
    storeId
  }) => {
    const dispatch = getDispatch();
    dispatch(showLoadingIndicator());

    try {
      const response = await this.fetchCarouselData({
        slug,
        dataType,
        countryId,
        language,
        storeId
      });
      const { hits } = response;

      const carouselData = {
        enable: true,
        searchUrl: slug,
        type: "product-carousel",
        [language]: { imageList: hits }
      };

      return carouselData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

  getDefaultPageData = async (slug, gridValue) => {
    const dispatch = getDispatch();
    dispatch(showLoaders());

    try {
      const response = await this.fetchDefaultPageData({
        slug,
        gridValue
      });

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoaders());
    }
  };

  getSaleSpecificTabData = async (tabSlug, saleSlug, queries) => {
    const dispatch = getDispatch();
    dispatch(showLoaders());

    try {
      const response = await this.fetchSpecificTabData({
        tabSlug,
        saleSlug,
        queries
      });

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoaders());
    }
  };

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new SaleService();

export default instance;
