import { BEConfig } from "../../config/env";
import { putDashboardWebApi } from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import { CartService } from "../../services";

import {
  showLoadingIndicator,
  hideLoadingIndicator,
  getVisitorId
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import {
  GET_CART_LIST,
  UPDATE_CART_LIST,
  DELETE_CART_LIST,
  UPDATE_CART_SIZE_QTY,
  RESET_PRODUCT_MERGE,
  SET_SAVING_INDICATOR,
  CLEAR_PREV_VALUE
} from "../constants";
import { languageFromPathName } from "../../util";
import { sameSizeItemsFormat } from "../../util/cart";
import {
  CLEAR_CART_AND_WISHLIST_GENERAL,
  DELETE_WISHLIST_ITEM_LOADING,
  SET_CART_AND_WISHLIST_GENERAL,
  WISHLIST_GENERAL_LOADING
} from "../constants/cart";
export const getCartList = (cart, isProductMerged) => ({
  type: GET_CART_LIST,
  cart,
  isProductMerged
});

export const updateCartList = cart => ({ type: UPDATE_CART_LIST, cart });

export const setSavingIndicator = value => ({
  type: SET_SAVING_INDICATOR,
  value
});

export const deleteCartList = (productId, _id) => ({
  type: DELETE_CART_LIST,
  productId,
  _id
});

export const updateSizeQty = (productId, selectType, value) => ({
  type: UPDATE_CART_SIZE_QTY,
  productId,
  selectType,
  value
});

export const clearPrevValue = cartArray => ({
  type: CLEAR_PREV_VALUE,
  cartArray
});

export const deleteCartData =
  (productId, id = "", showLoader = true) =>
  async dispatch => {
    if (showLoader) dispatch(showLoadingIndicator());

    try {
      const response = await CartService.deleteCartData(productId, id);
      dispatch(deleteCartList(productId, id));

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      if (showLoader) dispatch(hideLoadingIndicator());
    }
  };

export const deletePurchasedCartData = (orderItems, userLoggedIn, language) => {
  return async dispatch => {
    language = languageFromPathName(language);

    const cartData = await CartService.fetchCartData(language, userLoggedIn);

    const listForDeleting = cartData.filter(el =>
      orderItems.find(item => item.pId === el.productId)
    );
    listForDeleting.forEach(item =>
      dispatch(deleteCartData(item.productId, item._id))
    );
  };
};

export const fetchCartData =
  (showLoader = true, language, isProductMerged = false) =>
  async (dispatch, getState) => {
    if (showLoader) dispatch(showLoadingIndicator());

    language = languageFromPathName(language);

    const userLoggedIn = getState().authReducer.userLoggedIn;

    try {
      const cartData = await CartService.fetchCartData(language, userLoggedIn);
      dispatch(getCartList(cartData, isProductMerged));

      return cartData;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const saveToCart = (item, updateReducer = true) => {
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, cartPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${cartPageHandle}`;
  return (dispatch, getState) => {
    const sizeFormattedItem = sameSizeItemsFormat(
      item,
      getState().cartReducer.cart
    );
    dispatch(setSavingIndicator(true));

    const token = getAccessToken(getState);
    return putDashboardWebApi(token, apiURL, sizeFormattedItem, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setSavingIndicator(false));
          if (updateReducer) dispatch(updateCartList(sizeFormattedItem));
        }
        return response;
      })
      .catch(error => {
        dispatch(setSavingIndicator(false));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const resetProductMerge = () => ({
  type: RESET_PRODUCT_MERGE
});

const wishListGeneralLoading = () => ({
  type: WISHLIST_GENERAL_LOADING
});

export const deleteWishListGeneralLoading = () => ({
  type: DELETE_WISHLIST_ITEM_LOADING
});

export const getCartAndWishlistGeneralData = () => async dispatch => {
  try {
    dispatch(wishListGeneralLoading());

    const { data } = await CartService.getCartAndWishlistGeneralData();

    dispatch(setCartAndWishlistGeneralData(data));
  } catch (error) {
    console.error(error);
  }
};

const setCartAndWishlistGeneralData = cartAndWishlistGeneralData => ({
  type: SET_CART_AND_WISHLIST_GENERAL,
  cartAndWishlistGeneralData
});

export const clearCartAndWishlistGeneralData = () => ({
  type: CLEAR_CART_AND_WISHLIST_GENERAL
});
