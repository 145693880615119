import intersectionWith from "lodash/intersectionWith";
import isEmpty from "lodash/isEmpty";
import { getDataAsObject } from "../common";
export const getInternationalSizes = (
  availableSizes,
  internationalSizes = {}
) => {
  if (availableSizes && availableSizes.length) {
    availableSizes.forEach(size => {
      if (size.sizeinfo) {
        const sizeInfo = getDataAsObject(size.sizeinfo);
        sizeInfo &&
          sizeInfo.length &&
          sizeInfo.forEach(info => {
            if (info && info.sizeGroupOptions && info.sizeGroupOptions.length) {
              info.sizeGroupOptions
                .sort(function (a, b) {
                  var sizeGroupNamePrev = a.sizeGroupName3.toUpperCase();
                  var sizeGroupNameCurr = b.sizeGroupName3.toUpperCase();
                  return sizeGroupNamePrev < sizeGroupNameCurr
                    ? -1
                    : sizeGroupNamePrev > sizeGroupNameCurr
                    ? 1
                    : 0;
                })
                .forEach(option => {
                  if (internationalSizes[option.sizeGroupName3]) {
                    if (
                      !internationalSizes[option.sizeGroupName3].find(
                        interOption => interOption.sizeLabel === option.sizes
                      )
                    ) {
                      internationalSizes[option.sizeGroupName3].push({
                        sizeId: size.sizeId,
                        colorId: size.colorId,
                        sizeLabel: option.sizes,
                        sizeStock: size.sizeStock,
                        upc: size.upc,
                        onlineStock: size.onlineStock
                      });
                    }
                  } else {
                    internationalSizes[option.sizeGroupName3] = [
                      {
                        sizeId: size.sizeId,
                        colorId: size.colorId,
                        sizeLabel: option.sizes,
                        sizeStock: size.sizeStock,
                        upc: size.upc,
                        onlineStock: size.onlineStock
                      }
                    ];
                  }
                });
            }
          });
      }
    });
  }

  return internationalSizes;
};

export const normalizeCartValues = ({
  item,
  attrNamePstock = "pstock",
  attrNameQuantity = "quantity"
}) => {
  let newPstock = item[attrNamePstock];
  let newQuantity = item[attrNameQuantity];
  if (typeof item[attrNamePstock] !== "number" || item[attrNamePstock] < 0) {
    newPstock = 0;
  }
  if (
    typeof item[attrNameQuantity] !== "number" ||
    item[attrNameQuantity] <= 0
  ) {
    newQuantity = 1;
  }

  const newColorArray =
    item.colors &&
    item.colors.colorSizeOptions &&
    item.colors.colorSizeOptions.length &&
    item.colors.colorSizeOptions.map(item => {
      if (typeof item.sizeStock !== "number" || item.sizeStock < 0) {
        return { ...item, sizeStock: 0 };
      }
      return item;
    });

  return {
    ...item,
    colors: { colorSizeOptions: newColorArray },
    [attrNamePstock]: newPstock,
    [attrNameQuantity]: newQuantity
  };
};

export const sameSizeItemsFormat = (item, cart) => {
  const sameItemFromCart = cart.find(
    cartItem =>
      cartItem.sizeId === item.sizeId && cartItem.productId === item.productId
  );

  return {
    ...item,
    size: sameItemFromCart ? sameItemFromCart.size : item.size,
    sizeId: sameItemFromCart ? sameItemFromCart.sizeId : item.sizeId,
    sizeType: sameItemFromCart ? sameItemFromCart.sizeType : item.sizeType
  };
};

export const getCart = (cartFromLocalStorage, cart) => {
  const result = intersectionWith(
    cart,
    cartFromLocalStorage,
    (first, second) => first.productId === second.productId
  );

  return !isEmpty(result) ? result : null;
};
