export const paymentErrorTranslation = {
  pageTitle: "Transaction Unsuccessful",
  pageMessage:
    "There appears to be a problem with the credit card used and the transaction could not be completed. For more details about the transaction failure, please see the message below that we have received from the bank.",
  pageMessageForBank:
    "You can either call your credit card issuer and resolve the problem with your current credit card and retry purchasing the items again, or please feel free to try again with a different credit card.",
  bankResponse: "Bank Response",
  declined: "Declined",
  continueShopping: "Continue Shopping",
  contactText:
    "If there is any problem with your order, please contact our customer care at",
  or: "or",
  retryPayment: "Retry Payment",
  defaultErrorMessage:
    "The transaction could not be processed. Please try again"
};
