import React from "react";
import cn from "classnames";
import { CircleFlag } from "react-circle-flags";
import { MEMBERSHIP_STYLE_CLASS } from "../../constants/loyalty-points";

const CardInfo = props => {
  const {
    loyaltyDetails,
    myAccountTranslation: translation,
    countryCode,
    currencyCode,
    countryShortCode
  } = props;

  const currentCardLevel = MEMBERSHIP_STYLE_CLASS[loyaltyDetails.memeberLevel];

  const cardLevel =
    currentCardLevel === "yellow" ? translation.yellow : translation.black;

  return (
    <div className="card-info-mobile-wrapper">
      <div className="card-info-mobile">
        <h3
          className={cn("tier-info-mobile", {
            yellow: currentCardLevel === "yellow",
            black: currentCardLevel === "black"
          })}
        >
          {cardLevel} {translation.tier}
        </h3>
        <p className="country-info-mobile">
          <span>
            <CircleFlag
              countryCode={countryShortCode?.toLowerCase()}
              width={18}
            />
          </span>
          {countryCode} | {currencyCode}
        </p>
      </div>
    </div>
  );
};

export default CardInfo;
