import React from "react";
import Modal from "react-modal";
import { tabbyPromotionUrl } from "../../../constants";
import crossIcon from "../../../images/cross_black_icon.svg";
import { isMobile } from "../../../util";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: isMobile.any() ? "90%" : "37%",
    height: "65%"
  }
};

export const TabbyInfoModal = ({
  modalOpen,
  onRequestClose,
  language,
  amount,
  currency
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      style={customStyles}
      onRequestClose={onRequestClose}
      id="tabby-info-modal"
    >
      <img
        src={crossIcon}
        alt="cross-icon"
        className="cross-icon"
        onClick={onRequestClose}
      />
      <iframe
        width="100%"
        height="100%"
        src={`${tabbyPromotionUrl}/${language}/?price=${amount}&currency=${currency}`}
        style={{ border: "none", overflow: "none" }}
      />
    </Modal>
  );
};
