import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import cn from "classnames";
import { Image } from "../image";

const FooterAcceptMoney = ({
  defaultCountry,
  currency,
  translation,
  footerTranslation,
  footerWeb
}) => {
  const weAccept = get(translation, "weAccept", "We Accept");
  const countryPaymentOptions = get(defaultCountry, "paymentOptions", {});
  const currencyPaymentOptions = get(currency, "paymentOptions", {});
  const options = !defaultCountry
    ? currencyPaymentOptions
    : countryPaymentOptions;
  const optionsWithImages = filter(options, option => option.image);

  return (
    <>
      <div className={cn("we_accept", { we_accept_footer: footerWeb })}>
        <div className="title">
          {footerWeb ? (
            <p className="heading">{footerTranslation.paymentMethods} </p>
          ) : (
            weAccept
          )}
        </div>
        <div className="we-accept-payments">
          <ul>
            {map(optionsWithImages, (options, index) => (
              <li key={index}>
                <Image src={options.image} alt="cardAccept" lazyLoad />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  currency: state.common.settings
});

export default withRouter(connect(mapStateToProps)(FooterAcceptMoney));
