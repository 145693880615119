import React from "react";
import RoundOrderImg from "../../round-img";
const MobileOrderedItem = ({
  orderSuccessTranslation,
  items,
  order,
  language,
  moveToProductDetail,
  currencyCode,
  commonSettings,
  continueAsGuest,
  showGuestOrders,
  handleToggleGuestOrders
}) => (
  <div className="ordered_data_wrapper">
    <h3>{orderSuccessTranslation.youOrdered}</h3>
    <p className="total_item">
      {items.length} {orderSuccessTranslation.items}
    </p>
    <div className="ordered_product_detail">
      <div className="product_img">
        {items.length > 1 ? (
          <div>
            <RoundOrderImg
              imgSrc={`${items[0].image}`}
              intialClass={`initialProduct`}
            />
            <RoundOrderImg
              imgSrc={`${items[1].image}`}
              overlay={true}
              productCount={items.length - 2}
              myAccountTranslation={orderSuccessTranslation}
            />
          </div>
        ) : (
          <div>
            <RoundOrderImg
              imgSrc={`${items[0].image}`}
              intialClass={`initialProduct`}
            />
          </div>
        )}
      </div>
      <div className="order_info">
        <h5>
          {orderSuccessTranslation.orderID}: {order.orderId}
        </h5>
        <p>
          {orderSuccessTranslation.totalAmount} :{" "}
          <b>
            {currencyCode} {order.transactionAmount}
          </b>
        </p>
      </div>
      {continueAsGuest ? (
        <span
          className={`showGuestOrders ${showGuestOrders ? "active" : ""}`}
          onClick={handleToggleGuestOrders}
        />
      ) : null}
    </div>
  </div>
);

export default MobileOrderedItem;
