import React from "react";

export const getModalHeader = t => {
  const headerTitlesObj = {
    default: () => (
      <>
        <h1>{t.bestFit}</h1>
        <p>{t.uniqueStyle}</p>
      </>
    ),
    adults: [
      () => <h1>{t.lookingFor}</h1>,
      {
        clothing: [
          () => (
            <>
              <h1>{t.selectWhatYouLove}</h1>
              <p>{t.discoverBestForYou}</p>
            </>
          ),
          () => (
            <>
              <h1>{t.typicallySize}</h1>
              <p>{t.fittingClothing}</p>
            </>
          )
        ],
        shoes: [
          () => (
            <>
              <h1>{t.tapStyles}</h1>
              <p>{t.perfectPairForYou}</p>
            </>
          ),
          () => (
            <>
              <h1>{t.yourShoeSize}</h1>
              <p>{t.saveTime}</p>
            </>
          )
        ]
      }
    ],
    kids: [
      () => <h1>{t.styleWardrobe}</h1>,
      () => <h1>{t.lookingFor}</h1>,
      {
        clothing: [
          () => (
            <>
              <h1>{t.whatTheyLove}</h1>
              <p>{t.discoverBestForThem}</p>
            </>
          ),
          () => (
            <>
              <h1>{t.ageOfChild}</h1>
              <p>{t.perfectFit}</p>
            </>
          )
        ],
        shoes: [
          () => (
            <>
              <h1>{t.perfectPairForThem}</h1>
              <p>{t.discoverStyles}</p>
            </>
          ),
          () => (
            <>
              <h1>{t.shoeSize}</h1>
              <p>{t.perfectFit}</p>
            </>
          )
        ]
      }
    ]
  };
  return {
    ...headerTitlesObj,
    toddlers: [() => <h1>{t.styleWardrobe}</h1>, ...headerTitlesObj.kids]
  };
};
