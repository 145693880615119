import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import QuickFiltersModal from "./quickFilterModal";
import {
  toggleQuickFilerModal,
  setQuickFilterData
} from "../../redux/actions/quickFilter.action";
import withClientOnlyRender from "../../hocs/withClientOnlyRender";
import AnalyticService from "../../services/analytic-service";
import QuickFilterBanner from "././quick-filter-banner";

const QuickFilters = ({
  megaMenuList,
  toggleQuickFilerModal,
  setQuickFilterData,
  language,
  quickFilterStepData,
  bannerData
}) => {
  const [activeTranslation, setActiveTranslation] = useState(null);

  useEffect(() => {
    if (megaMenuList.length) {
      setQuickFilterData(megaMenuList, language);
    }
  }, [megaMenuList]);

  useEffect(() => {
    const loadTranslation = async () => {
      const quickFiltersObj = await import(
        `../../language/quickFilters/${language || "en"}/quickFilters`
      );
      setActiveTranslation(quickFiltersObj.quickFiltersTranslation);
    };
    loadTranslation();
  }, [language]);

  const quickFilterClick = () => {
    AnalyticService.quickFilter.trackQuickFilterClick({
      homePageBannerClick: true
    });
  };

  if (!megaMenuList.length || !activeTranslation) return null;
  return (
    <>
      <QuickFilterBanner
        toggleQuickFilerModal={() => {
          toggleQuickFilerModal();
          quickFilterClick();
        }}
        bannerData={bannerData}
      />
      <QuickFiltersModal activeTranslation={activeTranslation} />
    </>
  );
};

const mapStateToProps = ({ megaMenuReducer, common, quickFilterReducer }) => ({
  megaMenuList: megaMenuReducer.megaMenuList,
  language: common.language,
  quickFilterStepData: quickFilterReducer.quickFilterStepData
});
const mapDispatchToProps = {
  toggleQuickFilerModal,
  setQuickFilterData
};

export default compose(
  withClientOnlyRender,
  connect(mapStateToProps, mapDispatchToProps)
)(QuickFilters);
