import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import cn from "classnames";
import { DEFAULT_POINTS_FOR_NEXT_LEVEL } from "../../../constants/loyalty-points";
import { selectLanguageSlug, selectRouteSlug } from "../../../util/selectors";
import LoyaltyProgressBar from "../loyalty-points-progress-bar";
import ProgressBarWrapper from "../loyalty-progress-bar-wrapper";

class LoyaltyPointsOverview extends Component {
  render() {
    const {
      myAccountTranslation: translation,
      loyaltyDetails: {
        cardNumber,
        pointsValue,
        accuralPoints,
        pointNeededForNextLevel,
        memeberLevel,
        currentBalance
      },
      currencyCode,
      isArabic,
      routeSlug
    } = this.props;

    return (
      <div
        className={cn("loyalty-points-wrapper", {
          arabic: isArabic
        })}
      >
        <div className="loyalty-points-header">
          <div>
            <p className="card-number-title">{translation.cardNumber}</p>
            {cardNumber ? (
              <p className="card-number">
                {cardNumber.match(/.{1,4}/g).join(" ")}
              </p>
            ) : (
              <p className="card-number">{translation.onlineMember}</p>
            )}
          </div>
          <div className="points">
            <p className="bold">{translation.youCurrentlyHave}</p>
            <p className="smile-points">
              {currentBalance} {translation.smilePoints}
            </p>
            <p className="points-count">
              {currencyCode} {pointsValue}
            </p>
          </div>
        </div>

        <ProgressBarWrapper
          loyaltyDetails={get(this.props, "loyaltyDetails", {})}
          isArabic={isArabic}
          translation={translation}
        >
          <LoyaltyProgressBar
            maxTierPoints={
              pointNeededForNextLevel || DEFAULT_POINTS_FOR_NEXT_LEVEL
            }
            availablePoints={accuralPoints}
            stepsPerTierAmount={8}
            memberLevel={memeberLevel}
          />
        </ProgressBarWrapper>

        <div className="benefits-info">
          <a
            href={`/${routeSlug}/loyaltyprogram/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <b> {translation.seeBenefits}</b>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isArabic: selectLanguageSlug(state) === "ar",
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(LoyaltyPointsOverview);
