import React, { useMemo } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import { Image } from "../image";
import { LANGUAGE_ROUTE_KEY } from "../../constants";
import { UserAgentManager } from "../../util";
import swiperParams from "./swiperParams";

const ShopByStore = ({ shopByStore, language, settings }) => {
  const mainTitle = get(shopByStore, [language, "mainTitle"]);
  const storeList = get(shopByStore, [language, "storeList"]);
  const filteredStoreList = filter(storeList, store => store.enabled);

  const storeMap = useMemo(
    () => {
      const mapObject = {};
      forEach(settings.stores, store => {
        const storeURL = get(store, "name", "")
          .toLowerCase()
          .replace(/ /g, "-");
        mapObject[store.mid] = storeURL.includes("brands-for-less")
          ? `/`
          : `/${storeURL}/`;
      });
      return mapObject;
    },
    [settings.stores]
  );

  return (
    <div>
      {!isEmpty(filteredStoreList) && (
        <div className="shop_by_stores">
          <div className="container position_relative">
            <div className="shop_by_store_wrapper">
              <h2 className="vertical_text">{mainTitle}</h2>
              <Swiper
                className="item"
                {...{
                  ...swiperParams,
                  rtl: language === "arabic" ? "rtl" : ""
                }}
              >
                {map(filteredStoreList, (storeItem, i) => (
                  <div key={storeItem._id || i} className="item-content">
                    <Link
                      to={`/${LANGUAGE_ROUTE_KEY[language]}-${(settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                        "ae"}${storeMap[storeItem.storeId]}`}
                    >
                      <div className="brand_img">
                        <Image
                          src={
                            !UserAgentManager.isDesktop() &&
                            storeItem.responsiveImageLink
                              ? storeItem.responsiveImageLink
                              : storeItem.imageLink
                          }
                          alt="brand store"
                        />
                        <div className="logo_overlay">
                          <Image
                            src={storeItem.hoverImageLink}
                            alt="brand-logo"
                          />
                        </div>
                        <div className="mobile-logo-strip">
                          <Image
                            src={storeItem.MobileHoverImage}
                            alt="tchibo-strip"
                          />
                        </div>
                      </div>
                    </Link>
                    <div className="brand_desc">
                      <h4 className="heading">{storeItem.title}</h4>
                      <p>{storeItem.subTitle}</p>
                    </div>
                  </div>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopByStore;
