export const myCart = {
  items: "Items",
  item: "Item",
  myCart: "My Cart",
  orderSummary: "Order Summary",
  weAccept: "We Accept",
  only: "Only",
  awayFromFreeShipping: "away from FREE SHIPPING!",
  eligibleFreeShipping: "Congratulations, you are qualified for",
  freeShipping: "FREE SHIPPING",
  moveToMyWishlist: "Save for later",
  removeTxt: "Remove",
  addMoreToWishlist: "Add More From Wishlist",
  size: "Size",
  quantity: "Qty",
  priceDetails: "Price Details",
  subTotal: "Sub - Total",
  delivery: "Delivery",
  discount: "Discount",
  total: "Total",
  checkout: "Checkout",
  youWillEarn: "You will earn up to",
  learnMore: "Learn more",
  join: "Join",
  signInAndJoin:
    "and join our loyalty program and earn points on your purchase.",
  toEarnPoints: "loyalty program to earn points on your purchase.",
  loyaltyPoints: "loyalty points when you complete this purchase",
  voucherCode: "Got a coupon or voucher code?",
  codeApply: "You can apply the code during payment",
  cartEmpty: "Hey it feels so light!",
  noItemsInCart: "There is nothing in your cart. Let's add some items.",
  continueShopping: "Continue Shopping",
  off: "off",
  RecentlyViewed: "You’ve Recently Viewed",
  cartWishlistHeading: "Add items from your wishlist",
  DELIVERY_ADDRESS: "Standard Delivery",
  EXPRESS_DELIVERY: "Express Delivery",
  standardDescription: "Delivery in 4-5 working days",
  express: "Express Delivery",
  expressDescription: "Delivery in 1-2 working days",
  SELF_PICKUP: "Collection Points",
  pickupFromStoreDescription:
    "Pick your order from a Brands For Less store near you",
  qtyErrText: "Quantity must be less than or equal to",
  save: "Save",
  outOfStock: "Out of Stock",
  prizeChange: "Price Updated",
  qtyChange: "Stock changed",
  checkoutPage: "checkout page",
  placeOrderBtn: "Place Order",
  outOfStockAlertMsg:
    "There are some changes in your cart, please review your cart before proceeding",
  ViewAllWishlistItems: "View all Wishlist items",
  codFee: "COD Fee",
  wallet: "Wallet",
  vat: "VAT",
  IncOfVat: "(Incl. of VAT)",
  signIn: "Sign In",
  earnLoyaltyPoints: "to earn loyalty points.",
  applePay: "Apple Pay",
  excludeDeliveryCharges: "excluding delivery charges",
  includeDeliveryCharges: "Including delivery charges",
  deliverTo: "Delivered to",
  creditCardDiscountVoucher: "Have a credit note / discount voucher ?",
  deliveryOption: "DELIVERY OPTION",
  couponAndVoucher: "Coupons & Vouchers",
  enterCreditNote: "Enter the credit note / discount voucher",
  Applybtn: "Apply",
  avialablePromoCodes: "AVAILABLE PROMO CODES",
  enterCode: "Enter code",
  selectQuantity: "Select Quantity",
  selectSize: "Select Size",
  needSizeHelp: "Need sizing help?",
  piecesLeft: "pieces left",
  freeShippingAbove: "FREE Shipping on orders above",
  totalToPay: "Total to pay",
  incTax: "Incl. of VAT",
  quant: "Quantity",
  itemCannotReturn: "This item cannot be returned / exchanged",
  theRemainingStockForThisItem: "The stock now is changed from",
  to: "to",
  lastSymbol: "",
  excludingTax: "EXCLUDING IMPORT TAXES (VAT) AND CUSTOMS DUTIES",
  taxNonRefundable: "Custom duties & taxes are non-refundable once charged",
  STORE_PICKUP: "In-store Pickup",
  freePickup: "FREE PICKUP",
  freePickupOnAllOrders: "FREE Pickup on all the orders",
  free: "FREE",
  yourOrderIsEligibleForFreeCollection:
    "Your order is eligible for FREE COLLECTION",
  yourOrderIsEligibleForFreeShipping:
    "Your order is eligible for FREE SHIPPING",
  yourOrderIsEligibleForFreeInstorePickup:
    "Your order is eligible for FREE INSTORE PICKUP",
  notAvailable: "Not available",
  notAvailableIn: "This product is not available in your country",
  ok: "OK",
  price_details_delivery: "Delivery",
  standard_delivery: "Delivery: Standard Delivery",
  rrp: "RRP",
  rrp_save: "Save"
};
