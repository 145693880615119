import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import get from "lodash/get";

import { ENV_CONFIG } from "../../config/env";

import { seoTranslation } from "../../language/seo/en/seo";

import { getBrand } from "../../util";
import { getSEOStructuredLogo } from "../../util/seo";

import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";

import { fetchHomepageSection } from "../../redux/actions/page.action";
import {
  changeLanguage,
  setPageIndexable
} from "../../redux/actions/common.action";
import { LANGUAGE_ROUTE_KEY_MAP } from "../../constants";
import { replaceSeoKeys } from "../../util/common";
import {
  selectCdnImageSettings,
  selectSEOSettings
} from "../../util/selectors";

class Page extends Component {
  state = {
    seoTranslation: seoTranslation
  };

  async loadSEOTranslations(language) {
    const translation = await import(`../../language/seo/${language}/seo`);

    this.setState({
      seoTranslation: translation.seoTranslation
    });
    defaultDescription = `${translation.seoTranslation.defaultPageDescription}`;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { language } = this.props;

    if (nextProps.language !== language) {
      this.loadSEOTranslations(nextProps.language);
    }
  }

  componentDidMount() {
    const {
      currentLanguage,
      handleLanguageChange,
      indexable,
      shopByStore,
      fetchHomepageSection,
      storeId,
      countryId,
      language,
      setPageIndexable
    } = this.props;

    if (!shopByStore && language && countryId && storeId) {
      fetchHomepageSection(language, countryId, storeId, "shopByStore");
    }

    handleLanguageChange(currentLanguage);

    this.loadSEOTranslations(currentLanguage);
    setPageIndexable(indexable);
  }

  getMetaTags(
    {
      title,
      description,
      image,
      metaBannerImage,
      contentType,
      twitter,
      noCrawl,
      published,
      updated,
      category,
      tags,
      meta,
      language,
      indexable = false
    },
    pathname,
    countrySEOSettings
  ) {
    const { title: metaPageTitle, metaDescription: metaPageDescription } =
      (meta && (meta[language] || meta[LANGUAGE_ROUTE_KEY_MAP[language]])) ||
      {};

    const theTitle = title
      ? (title + " | " + defaultTitle).substring(0, 60)
      : metaPageTitle || defaultTitle;
    const theDescription = description
      ? description.substring(0, 155)
      : metaPageDescription || defaultDescription;
    const theImage = image ? `${SITE_URL}${image}` : metaBannerImage;

    const theLocalizedMeta =
      (meta &&
        language &&
        (meta[language] || meta[LANGUAGE_ROUTE_KEY_MAP[language]])) ||
      {};

    let replacedDescription = replaceSeoKeys(
      theLocalizedMeta.metaDescription || theDescription,
      countrySEOSettings
    );

    const metaTags = [
      {
        itemprop: "name",
        content: replaceSeoKeys(
          theLocalizedMeta.title || theTitle,
          countrySEOSettings
        )
      },
      {
        itemprop: "description",
        content: replacedDescription
      },
      {
        itemprop: "image",
        content: theLocalizedMeta.metaImage || theImage
      },
      {
        name: "description",
        content: replacedDescription
      },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: defaultTwitter },
      {
        name: "twitter:title",
        content: replaceSeoKeys(
          theLocalizedMeta.title || theTitle,
          countrySEOSettings
        )
      },
      {
        name: "twitter:description",
        content: replacedDescription
      },
      { name: "twitter:creator", content: twitter || defaultTwitter },
      {
        name: "twitter:image:src",
        content: theLocalizedMeta.metaImage || theImage
      },
      {
        property: "og:title",
        content: replaceSeoKeys(
          theLocalizedMeta.title || theTitle,
          countrySEOSettings
        )
      },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: SITE_URL + pathname },
      {
        property: "og:image",
        content: theLocalizedMeta.metaImage || theImage
      },
      { property: "og:image:width", content: "600" },
      { property: "og:image:height", content: "600" },
      {
        property: "og:description",
        content: replacedDescription
      },
      { property: "og:site_name", content: defaultTitle },
      { property: "fb:app_id", content: FACEBOOK_APP_ID }
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }

    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }

    if (indexable) {
      /*
       *  Although google indexes and follows link by default, it is explicitly requested by client
       *  to add these values @see BFL-729
       */
      metaTags.push({ name: "robots", content: "index,follow" });
    } else {
      metaTags.push({ name: "robots", content: "noindex,follow" });
    }

    return metaTags;
  }

  render() {
    const {
      children,
      id,
      className,
      countryCode,
      location,
      match,
      storeId,
      shopByStore,
      countrySEOSettings,
      countrySettings,
      ...rest
    } = this.props;
    const { seoTranslation } = this.state;

    defaultTitle = `${seoTranslation.bfl} - ${seoTranslation.onlineShopping} ${countryCode}`;

    const logoStructuredData = getSEOStructuredLogo(
      getBrand(shopByStore, storeId, rest.language)
    );

    let title = replaceSeoKeys(rest.title, countrySEOSettings);

    const meta = this.getMetaTags(rest, location.pathname, countrySEOSettings);

    return (
      <div id={id} className={className}>
        <Helmet
          htmlAttributes={{
            lang: "en",
            itemscope: undefined,
            itemtype: `http://schema.org/${rest.schema || "WebPage"}`
          }}
          title={title}
          meta={meta}
          {...(rest.indexable && {
            script: [helmetJsonLdProp(logoStructuredData)]
          })}
        />
        {children}
      </div>
    );
  }
}

const SITE_URL = ENV_CONFIG.baseURL;
const FACEBOOK_APP_ID = "XXXXXXXXX";
let defaultTitle = `${seoTranslation.bfl} - ${seoTranslation.onlineShopping} UAE`;
let defaultDescription = `${seoTranslation.defaultPageDescription}`;
const defaultTwitter = "";

const mapStateToProps = state => ({
  language: state.common.language,
  countryCode: get(state, "common.settings.countryCode"),
  storeId: state.page.homepageState.storeId,
  countryId: state.page.homepageState.countryId,
  shopByStore: state.page.storeList,
  metaBannerImage: get(selectCdnImageSettings(state), "metaBannerImage"),
  countrySEOSettings: selectSEOSettings(state)
});

const mapDispatchToProps = {
  fetchHomepageSection,
  handleLanguageChange: changeLanguage,
  setPageIndexable
};

export default compose(
  withRouter,
  withLanguageFromUrl,
  connect(mapStateToProps, mapDispatchToProps)
)(Page);
