import React from "react";
import { IS_AUTH_FROM_CHECKOUT, CHECKOUT_ID } from "../../../constants";
import { Link } from "react-router-dom";
import { GAService } from "../../../services/GA-service";

const CheckoutSignInUser = ({ routeSlug, translation, params }) => {
  const setAuthFromCheckout = (isSignIn = false) => {
    localStorage.setItem(IS_AUTH_FROM_CHECKOUT, true);
    localStorage.setItem(CHECKOUT_ID, params.id);
    GAService.auth.trackSignCTA(isSignIn);
  };

  return (
    <div className="checkout-sign-in-user">
      <h2>{translation.alreadyHaveAccount}</h2>
      <p className="sign-in-description">
        {translation.signInForFasterCheckout}
      </p>
      <Link
        className="btn btn-default round_btn form_black_btn"
        id="checkout-sign-in-btn"
        to={`/${routeSlug}/login/`}
        onClick={() => setAuthFromCheckout(true)}
      >
        {translation.signIn}
      </Link>
      <p className="sign-in-join">
        {translation.dontHaveAccount}
        <Link
          to={`/${routeSlug}/sign-up/`}
          onClick={() => setAuthFromCheckout(false)}
        >
          {" "}
          {translation.join}
        </Link>
      </p>
    </div>
  );
};

export default CheckoutSignInUser;
