import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import ModalBFL from "../modal/modal";
import {
  toggleQuickFilerModal,
  parseProductsSizes,
  resetQuickFilterSelection,
  getAdditionalFilterData
} from "../../redux/actions/quickFilter.action";
import { selectLanguageSlug } from "../../util/selectors";
import { isMobile } from "../../util";
import {
  ProgressBar,
  ModalHeader,
  QuickFilterModalContent
} from "./components";
import { LANGUAGE_ROUTE_KEY } from "../../constants";

const QuickFiltersModal = ({ activeTranslation }) => {
  const dispatch = useDispatch();
  const {
    showQuickFilterModal,
    quickFilterStep: filterStep,
    quickFilterStepData: filterStepData,
    quickFilterStepPath: filterStepPath,
    quickFilterProducts,
    maxStepBySelectedCategory: maxStep
  } = useSelector(state => state.quickFilterReducer);
  const language = useSelector(selectLanguageSlug);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(
    () => {
      const lvl2SizeFilter = get(quickFilterProducts, [
        "facets",
        "sizeHierarchicalFilter.lvl2"
      ]);
      if (filterStep === maxStep && !isEmpty(lvl2SizeFilter)) {
        dispatch(parseProductsSizes(lvl2SizeFilter));
      }
    },
    [quickFilterProducts.facets, filterStep]
  );

  useEffect(
    () => {
      if (filterStepData && isEmpty(filterStepData.children)) {
        dispatch(getAdditionalFilterData(filterStepData.redirectUrl, language));
      }
    },
    [filterStepPath]
  );

  const joinedPath = filterStepPath.join("");
  const isMob = Boolean(isMobile.any());

  return (
    <ModalBFL
      showModal={showQuickFilterModal}
      modalClass={"generic_modal quick-filter-modal"}
    >
      <div className="d-flex flex-column h-100">
        <div
          className={cn({
            arabic: language === LANGUAGE_ROUTE_KEY.arabic
          })}
        >
          {!!filterStep && (
            <ProgressBar
              filterStep={filterStep}
              maxStep={maxStep}
              isMob={isMob}
            />
          )}
          <ModalHeader
            activeTranslation={activeTranslation}
            filterStep={filterStep}
            filterStepPath={filterStepPath}
            joinedPath={joinedPath}
            maxStep={maxStep}
          />
          <button
            className="cross_btn close-modal-btn"
            onClick={() => {
              dispatch(toggleQuickFilerModal());
              setSelectedOption(null);
              dispatch(resetQuickFilterSelection());
            }}
          >
            &times;
          </button>
        </div>
        <QuickFilterModalContent
          joinedPath={joinedPath}
          isMob={isMob}
          filterStepPath={filterStepPath}
          filterStepData={filterStepData}
          language={language}
          selectedOption={selectedOption}
          activeTranslation={activeTranslation}
          setSelectedOption={setSelectedOption}
        />
      </div>
    </ModalBFL>
  );
};

export default QuickFiltersModal;
