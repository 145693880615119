import React from "react";

const FloatingMessage = ({ translation, count }) => {
  return (
    <h5 className="floating_message">
      {count} {translation && translation.itemAddedInCart}
    </h5>
  );
};

export default FloatingMessage;
