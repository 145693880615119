import React from "react";
import moment from "moment";
import { isMobile } from "../../../util";
import { isDateInPast } from "../../../util/common";
import CustomCountdown from "../../custom-countdown";
import FlashImage from "./assets/images/flash.gif";

const SaleTabContent = ({
  data,
  isFlashImage,
  translation,
  productsLength,
  dateTo,
  dateFrom
}) => {
  const isMobileView = isMobile.any();
  const showTimer = Boolean(!isMobileView && productsLength);
  const alreadyStarted = isDateInPast(dateFrom);
  const timeToShow = alreadyStarted ? dateTo : dateFrom;

  return (
    <div className="sale-tab tabs-wrapper">
      {!isMobileView && isFlashImage && (
        <div className="flash">
          <img src={FlashImage} alt="flash-image" className="flash-image" />
        </div>
      )}
      <div className="sale-live">
        <h2>{data?.heading.slice(0, 16)}</h2>
        <span className="date">{moment(timeToShow).format("MMM DD")}</span>{" "}
        <span className="time">{moment(timeToShow).format("hh:mm A")}</span>
      </div>
      {showTimer && (
        <div className="timer">
          <h2 className="timer-title">
            {alreadyStarted ? translation.endsIn : translation.startsIn}
          </h2>
          <CustomCountdown
            date={timeToShow}
            type={1}
            content={{ backgroundColor: "black", color: "white" }}
            textStyles={{ top: 30, color: "black" }}
          />
        </div>
      )}
    </div>
  );
};

export default SaleTabContent;
