import React from "react";
import cn from "classnames";
import ReactHtmlParser from "react-html-parser";
import Ruler from "../../images/icons/ruler.svg";

const SizeHelpMessage = ({ translation, handleSizeChart, withNoBorder }) => {
  return (
    <div
      className={cn("size-spec", {
        with_no_border: withNoBorder
      })}
    >
      <div>
        <img src={Ruler} alt="size helper text" className="ruler-icon" />
      </div>
      <div onClick={handleSizeChart}>
        <p>
          {translation.needSizeHelp}{" "}
          {ReactHtmlParser(translation.referToSizeChart)}
        </p>
      </div>
    </div>
  );
};

export default SizeHelpMessage;
