import React, { memo } from "react";
import HtmlParser from "../../components/html-parser";

const HtmlStatic = ({ data }) => (
  <div className="static_html_pages">
    <div className="container">
      <div className="static_template">
        <HtmlParser html={data.content.html} />
      </div>
    </div>
  </div>
);

export default memo(HtmlStatic);
