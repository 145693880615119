import React from "react";
import map from "lodash/map";
import { Image } from "../../components/image";

const ConnectWithUs = ({ staticContent }) => (
  <section className="col-lg-12 col-sm-12 padding0 footer-connect">
    <span className="connect">
      {staticContent.connect}
      {map(staticContent.socialMediaLinks, socialMediaLink => (
        <span key={socialMediaLink.id}>
          <a
            href={socialMediaLink.redirectLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={socialMediaLink.img} alt="image" lazyLoad />
          </a>
        </span>
      ))}
    </span>
    <p className="copyright">{staticContent.copyRight}</p>
  </section>
);

export default ConnectWithUs;
