import { getState } from "../redux/store";
import { getVisitorId } from "../redux/actions/common.action";

import { selectLanguageSlug } from "../util/selectors";

import {
  getGuestUserStorage,
  getPrevModeStorage
} from "../util/browserStorage";
import { BROWSER_STORAGE } from "../redux/constants";

class ApiService {
  getBaseUrl() {
    throw new Error("abstract method");
  }

  getAccessToken(cookies) {
    const state = getState();

    return (
      state.authReducer.accessToken ||
      state.authReducer.accessGuestToken ||
      cookies?.[BROWSER_STORAGE.ACCESS_TOKEN] ||
      getGuestUserStorage()
    );
  }

  getVisitorId() {
    return getVisitorId();
  }

  getLanguage() {
    const state = getState();

    const language = selectLanguageSlug(state);

    return language;
  }

  getIsPreviewStatus(...args) {
    return getPrevModeStorage(...args);
  }
}

export default ApiService;
