import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import cn from "classnames";
import get from "lodash/get";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import { Scrollbars } from "react-custom-scrollbars";
import { SearchInput } from "../select";
import CircleRadio from "../../circle-radio";

const mobileDropdownSortCallback = (a, b, selectedItemKey) => {
  if (a.key === selectedItemKey) return -1;
  if (b.key === selectedItemKey) return 1;
  return 0;
};

const MobileSwipeableList = ({
  containerClass,
  selectedItem,
  disabled,
  headTranslation,
  placeholderTranslation,
  scrollbarMaxHeight,
  handleChange,
  data,
  withSearchInput,
  translation
}) => {
  const [open, setOpen] = useState(false);
  const [startYPosition, setStartYPosition] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const drawerRef = useRef(null);

  const handleTouchStart = e => {
    setStartYPosition(e.touches[0].clientY);
  };

  const handleTouchMove = e => {
    const deltaYPosition = e.touches[0].clientY - startYPosition;
    if (deltaYPosition > 50) {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    const drawerClassList = drawerRef.current.classList;
    drawerClassList.add("closing");
    setTimeout(() => {
      setOpen(false);
      withSearchInput && resetSearchInputData();
      drawerClassList.remove("closing");
    }, 400);
  };

  const valueToParse = get(selectedItem, "value");
  const spanContent = valueToParse
    ? ReactHtmlParser(valueToParse)
    : selectedItem;

  const onSearchValueChange = value => {
    const filteredData = data.filter(item =>
      item.value.toLowerCase().includes(value.toLowerCase())
    );
    setSearchValue(value);
    setFilteredData(filteredData);
  };

  const resetSearchInputData = () => {
    setSearchValue("");
    setFilteredData([...data]);
  };

  let dropdownData = [...filteredData];

  if (selectedItem.key) {
    dropdownData.sort((a, b) =>
      mobileDropdownSortCallback(a, b, selectedItem.key)
    );
  }

  useEffect(() => {
    if (Array.isArray(data) && !isEqual(filteredData, data)) {
      setFilteredData([...data]);
    }
  }, [data]);

  return (
    <div
      className={cn("dropdown_container", containerClass, {
        filter_open: open,
        disabled
      })}
      onClick={() => !open && setOpen(true)}
    >
      <div className={cn("drawer_wrapper", { expiry_details: selectedItem })}>
        <p
          className={cn({
            expiry_details_content_container: selectedItem
          })}
        >
          {spanContent}
        </p>
        {open && (
          <div className="drawer_content">
            <div className="overlay_class"></div>
            <div
              className={cn("drawer", {
                open
              })}
              ref={drawerRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              <div className="drawer_heading">
                <p>{headTranslation}</p>
                <div className="cross_button" onClick={closeDrawer}></div>
              </div>
              <div>
                {withSearchInput && (
                  <SearchInput
                    searchValue={searchValue}
                    onSearchValueChange={onSearchValueChange}
                    placeholder={placeholderTranslation}
                  />
                )}

                <Scrollbars
                  autoHide
                  autoHeight
                  autoHeightMin={25}
                  autoHeightMax={scrollbarMaxHeight || 250}
                  renderTrackHorizontal={props => (
                    <div
                      {...props}
                      style={{ display: "none" }}
                      className="track-horizontal"
                    />
                  )}
                >
                  {dropdownData.length ? (
                    <ul className="dropdown_content_container" id={name}>
                      {map(dropdownData, (item, index) => {
                        const isChecked = selectedItem?.key === item?.key;
                        return (
                          <li
                            key={item?.key || index}
                            onClick={() => {
                              handleChange(item?.key || item);
                              closeDrawer();
                            }}
                          >
                            {item.value ? ReactHtmlParser(item.value) : item}
                            <div className="circle_radio_wrapper">
                              <CircleRadio
                                isChecked={isChecked}
                                index={index}
                                name="dropdown-circle-radio"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p className="no_data_found">{translation.noDataFound}</p>
                  )}
                </Scrollbars>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSwipeableList;
