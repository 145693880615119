import React, { useEffect, useState, useCallback } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import HtmlParser from "../../components/html-parser";
import { selectRouteSlug } from "../../util/selectors";
import AccordionTile from "./accordion-tile";
import { GAService } from "../../services/GA-service";

const CombAccordianStatic = ({ history, data, location, routeSlug }) => {
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    const url = location.pathname;
    const newActiveTab = url.includes(data[0].url) ? "0" : "1";
    setActiveTab(newActiveTab);
  }, []);

  const toggle = useCallback(
    tab => {
      const tabUrl = tab === "0" ? data[0].url : data[1].url;
      const resultUrl = `/${routeSlug}${tabUrl}`;
      history.push(resultUrl);

      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  return (
    <div className="static_html_pages">
      <div className="container">
        <div className="static_template">
          <HtmlParser html={data[parseInt(activeTab)].content.html} />
        </div>
        <div className="comb_accordion_template">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "0"
                })}
                onClick={() => toggle("0")}
              >
                <span className="shipping_and_delivery_icon" /> {data[0].title}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1"
                })}
                onClick={() => toggle("1")}
              >
                <span className="return_and_cancellation_icon" />{" "}
                {data[1].title}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="0">
              {!isEmpty(data[0].content.accord) && (
                <div>
                  {data[0].content.accord.map(item => (
                    <AccordionTile
                      item={item}
                      key={"accordion_" + item.title}
                      accordionHeading={data[activeTab]?.title}
                    />
                  ))}
                </div>
              )}
            </TabPane>
            <TabPane tabId="1">
              {!isEmpty(data[1].content.accord) && (
                <div>
                  {data[1].content.accord.map((item, index) => {
                    return (
                      <AccordionTile
                        item={item}
                        key={"_accordion_" + index}
                        accordionHeading={data[activeTab]?.title}
                      />
                    );
                  })}
                </div>
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = reduxState => ({
  routeSlug: selectRouteSlug(reduxState)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(CombAccordianStatic);
