import { appSettings } from "../../config/app";
import { ENV_CONFIG } from "../../config/env";

const SITE_URL = ENV_CONFIG.baseURL;

const getProductImages = item => {
  if (!item) return "";
  return item.images && item.images.productImageURLs.length
    ? item.images.productImageURLs.map(
        img => appSettings.imageBaseURL + img.image
      )
    : item.captionImageURL;
};

const getSEOBeautifiedUrl = url => (url.endsWith("/") ? url : url + "/");

export const getSEOStructuredProduct = (
  item,
  location,
  currencyCode,
  language,
  outOfStock
) => {
  language = language.includes("ar") ? "ar" : "en";
  const schemaUrl = "https://schema.org/";
  const parentCategory = item[language + "_parentCategory"]
    ? item[language + "_parentCategory"] + ">"
    : "";
  const category = `${parentCategory}${item[language + "_catname"]}`;
  const productAvailability = outOfStock ? "OutOfStock" : "InStock"; //additional SEO product availability options are described on the site: https://schema.org/ItemAvailability
  return {
    "@context": schemaUrl,
    "@type": "Product",
    name: item.title,
    image: getProductImages(item),
    brand: {
      "@type": "Brand",
      name: item.brandName
    },
    category,
    offers: {
      "@type": "Offer",
      url: SITE_URL + location.pathname,
      priceCurrency: currencyCode,
      price: item.currentPrice,
      availability: schemaUrl + productAvailability
    }
  };
};

export const getSEOStructuredLogo = brand => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  url: SITE_URL,
  logo: brand.storeLogoImageLink || brand.iconImageLink
});

export const getSEOStructuredBreadcrumbs = breadCrumbsList => {
  const itemListElement = breadCrumbsList.map((item, index) => {
    const url = SITE_URL + (item.url && getSEOBeautifiedUrl(item.url));
    return {
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": item.url ? url : item.name,
        name: item.name,
        ...(item.url && { url })
      }
    };
  });
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement
  };
};
