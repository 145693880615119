import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { IS_SWITCHED_FROM_USER } from "../../../constants";
import { selectAuthReducer, selectLanguageSlug } from "../../../util/selectors";
import {
  resetMapDeliveryAddress,
  resetStandardDeliveryAddress,
  resetCardList,
  resetInStoreDeliveryAddress,
  resetCreditCards,
  setPaymentData,
  resetIsPickUpFormValid
} from "../../../redux/actions/checkout.action";
import { checkoutTranslation } from "../../../language/checkoutFlow/en/checkoutFlow";
import { resetAddress } from "../../../redux/actions/address.action";
import { settings_cart } from "../../../config/app";
import { AnalyticService } from "../../../services";
import { resetAPIError } from "../../../redux/actions/homepage.action";
import { setWalletData } from "../../../redux/actions/myAccount.action";
import { logOut } from "../../../redux/actions/authConfig.action";
import {
  selectDeliverySettings,
  selectIsUserLoggedIn,
  selectOrder
} from "./selectors";
import CheckoutFirstStep from "./CheckoutFirstStep";
import { GAService } from "../../../services/GA-service";

const CheckoutSignIn = ({
  changeCheckoutData,
  resetAddAddress,
  resetMyWallet,
  unselectUseMyWallet,
  onChangeUserField
}) => {
  const [translation, setTranslation] = useState(checkoutTranslation);
  const language = useSelector(selectLanguageSlug);
  const [guest, setGuest] = useState(false);
  const [openEmailField, setOpenEmailField] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const deliverySettings = useSelector(selectDeliverySettings);

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const userInfo = useSelector(selectAuthReducer);

  const { step1Completed, continueAsGuest, guestEmail } =
    useSelector(selectOrder);

  const email = userInfo.email || guestEmail;

  useEffect(() => {
    const loadLanguage = async () => {
      if (language) {
        const checkoutResponse = await import(
          `../../../language/checkoutFlow/${language}/checkoutFlow`
        );

        setTranslation(checkoutResponse.checkoutTranslation);
      }
    };

    loadLanguage();
  }, [language]);

  useEffect(() => {
    changeCheckoutData("guestEmailConsent", true, false);
  }, []);

  const toggleChange = () => {
    setGuest(false);
    if (!openEmailField) {
      AnalyticService.checkout.trackCheckoutStepFlow({
        step: 1,
        name: "Login"
      });
    }
    onChangeUserField(!openEmailField);
    setOpenEmailField(!openEmailField);
  };

  const closeSection = () => setOpenEmailField(false);

  const handleCheckoutData = () => {
    const filteredDeliverySettings =
      !isEmpty(deliverySettings) &&
      deliverySettings.filter(setting => setting.paymentType === settings_cart);
    const deliveryType = get(filteredDeliverySettings, "[0].deliveryType", 0);

    changeCheckoutData("step1Completed", false, false);
    changeCheckoutData("address", null, false);
    changeCheckoutData("addressId", null, false);
    changeCheckoutData("guestEmail", "", false);
    changeCheckoutData("customerId", 0, false);
    changeCheckoutData("continueAsGuest", false, false);
    changeCheckoutData("step2Completed", false, false);
    changeCheckoutData("step3Completed", false, true);
    changeCheckoutData("deliveryType", deliveryType, true);
    changeCheckoutData("guestEmailConsent", true, false);
  };

  const handleResetActions = () => {
    dispatch(resetStandardDeliveryAddress());
    dispatch(resetMapDeliveryAddress());
    dispatch(resetInStoreDeliveryAddress());
    dispatch(resetAddress());
    dispatch(resetCardList());
    resetMyWallet();
    dispatch(setPaymentData());
    dispatch(setWalletData());
    dispatch(logOut(false, true));
    dispatch(resetCreditCards());
    dispatch(resetIsPickUpFormValid());
  };

  const handleSignInAsOtherUser = () => {
    dispatch(resetAPIError());
    resetAddAddress();

    unselectUseMyWallet();

    handleCheckoutData();

    handleResetActions();

    onChangeUserField(false);

    localStorage.setItem(IS_SWITCHED_FROM_USER, true);
    GAService.auth.trackLogoutSuccess();
  };

  const submitGuestEmail = data => {
    changeCheckoutData("guestEmail", data.email, false);
    changeCheckoutData("guestEmailConsent", data.isChecked, false);
    changeCheckoutData("continueAsGuest", true, true);
    dispatch(resetStandardDeliveryAddress());
    dispatch(resetMapDeliveryAddress());
    dispatch(resetInStoreDeliveryAddress());
    dispatch(resetAddress());
    closeSection();
    GAService.checkout.trackContinueAsGuest();
  };

  const renderEmail = () => {
    const checkoutPrefix = `${
      translation[continueAsGuest ? "guestCheckout" : "signInAs"]
    } - `;

    if (email && (continueAsGuest || isUserLoggedIn)) {
      return (
        <p className="user_email">
          {checkoutPrefix}
          <span>{email}</span>
        </p>
      );
    }

    return translation.signIn;
  };

  return (
    <div className="checkout_steps">
      <div
        className="steps_heading"
        onClick={step1Completed ? toggleChange : undefined}
      >
        <h4>
          {step1Completed && <span className="green_round_check" />}{" "}
          <span className="serialNo">1.</span>
          {renderEmail()}
          {step1Completed && <span>{translation.change}</span>}
        </h4>
      </div>

      {(!step1Completed || openEmailField) && (
        <div className="steps_content">
          {!step1Completed && (!continueAsGuest || !guest) && (
            <CheckoutFirstStep
              isUserLoggedIn={isUserLoggedIn}
              params={params}
              submitGuestEmail={submitGuestEmail}
              guest={guest}
            />
          )}
          {step1Completed && (
            <div className="sign_in_another_user">
              <span onClick={handleSignInAsOtherUser}>
                {translation.SignInAnotherUser}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckoutSignIn;
