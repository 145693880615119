import React from "react";
import get from "lodash/get";

const ViewAddress = ({
  slicePhoneLength = 0,
  address,
  selectedArea,
  selectedCity,
  countryName,
  showEditAddress,
  openEditAddressModal,
  checkoutTranslation,
  mobileCountryCode,
  handleEditAddress,
  continueAsGuest,
  countryShortCode
}) => (
  <>
    <address>
      <h4>{`${address.firstname} ${address.lastname || ""}`}</h4>
      {`${address.addressLine1}`}
      <br />
      {`${(selectedArea && selectedArea.areaName) || address.area || ""}, ${
        (selectedCity && selectedCity.stateName) || address.city || ""
      }, `}
      {`${get(address, "zipcode", "") || ""}`}
      <br />
      {address.country || countryName}
      <br />
      <span className={"iti-flag d-inline-block " + countryShortCode} /> +
      {mobileCountryCode}{" "}
      {address && address.phone && address.phone.slice(-slicePhoneLength)}{" "}
      &nbsp;
    </address>
    {Boolean(showEditAddress && openEditAddressModal && continueAsGuest) && (
      <button
        className="round_btn form_white_btn edit-address"
        onClick={openEditAddressModal}
      >
        {checkoutTranslation && checkoutTranslation.editAddress}
      </button>
    )}
    {Boolean(showEditAddress && continueAsGuest) && (
      <button
        className="guest-edit-address"
        onClick={() =>
          handleEditAddress &&
          handleEditAddress({
            ...address,
            phone: address.phone.slice(-slicePhoneLength)
          })
        }
      >
        {checkoutTranslation && checkoutTranslation.editAddress}
      </button>
    )}
  </>
);

export default ViewAddress;
