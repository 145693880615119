import React from "react";
import debounce from "lodash/debounce";

export const SearchInput = ({
  searchValue,
  onSearchValueChange,
  placeholder
}) => {
  const onChangeHandler = debounce(onSearchValueChange, 100);

  return (
    <div
      className="search-input-wrapper"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <input
        type="text"
        value={searchValue}
        placeholder={placeholder}
        onChange={e => onChangeHandler(e.target.value)}
      />
      <div className="search_icon"></div>
    </div>
  );
};
