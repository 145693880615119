import React from "react";
import cn from "classnames";
import { isMobile } from "../../../util";

const MapAreaDescription = ({
  name,
  address,
  phone,
  selected,
  extraInfo,
  saveMapAddress,
  place,
  mapIconURL,
  translation,
  showCustomIcon,
  workingHours,
  activeCollectionPoint,
  setActiveCollectionPoint
}) => (
  <div
    className={`map_description`}
    onClick={
      place.avl_limit > 0 && saveMapAddress
        ? e => {
            activeCollectionPoint && setActiveCollectionPoint(null);
            saveMapAddress(e, place);
          }
        : isMobile.any()
        ? () => setActiveCollectionPoint(place)
        : undefined
    }
  >
    <span
      className={cn({
        location_indicator: !showCustomIcon,
        location_indicator_custom: showCustomIcon,
        selected_location: selected,
        map_icon_bg: !Boolean(mapIconURL),
        inactive_point: place.avl_limit <= 0
      })}
      style={{ backgroundImage: `url(${cn({ [mapIconURL]: mapIconURL })})` }}
    />
    <div
      className={cn("on_hover_des", {
        display: selected || place === activeCollectionPoint
      })}
    >
      <div className="location_description">
        <h5>{name}</h5>
        <p>{address}</p>
        <p>{phone}</p>
        {place.avl_limit <= 0 && (
          <div>
            <p className="unavailable">
              {translation.storeTemporaryUnavailable}
            </p>
          </div>
        )}
        {selected ? (
          <p className="selected_location_btn checked">
            {translation.selected}
          </p>
        ) : null}
      </div>
    </div>
  </div>
);

export default MapAreaDescription;
