import React from "react";

export const SurveyInfo = ({ surveyInfo }) => (
  <div className="survey-wrapper">
    <h2 className="header">{surveyInfo.heading}</h2>
    <p className="additional-survey-info">{surveyInfo.subHeading}</p>

    <button
      className="survey-btn round_btn"
      onClick={() => window.open(surveyInfo.link, "_blank")}
    >
      {surveyInfo.buttonText}
    </button>
  </div>
);
