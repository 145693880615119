import React from "react";
import get from "lodash/get";
import includes from "lodash/includes";

const SyteSimilarItems = props => {
  const { pId, subcatid, imageUrl, configSyteSettings } = props;
  const similarItems = configSyteSettings?.enableSimilarItems;
  const shopTheLook = configSyteSettings?.enableShopTheLook;
  const recommendation = configSyteSettings?.enableRecommendation;
  const showSimilarItems = !includes(
    process.env.REACT_APP_EXCLUDED_SUBCAT_IDS,
    subcatid
  );
  return (
    Boolean(pId) && (
      <>
        {similarItems && showSimilarItems && (
          <div
            id="syte-similar-items-container"
            data-sku={pId}
            data-image-src={imageUrl}
          />
        )}
        {shopTheLook && (
          <div
            id="syte-shop-the-look-container"
            data-sku={pId}
            data-image-src={imageUrl}
          />
        )}
        {recommendation && (
          <div
            id="syte-personalization-container"
            data-sku={pId}
            data-placement="PDP"
          />
        )}
      </>
    )
  );
};

export default SyteSimilarItems;
