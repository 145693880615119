import React from "react";
import LazyLoad from "react-lazy-load";
import Img from "react-image";

import defaultImageSrc from "../../images/default.jpg";
import defaultNewImageSrc from "../../images/default_new.jpg";
import defaultProductSrc from "../../images/product_default.gif";
import defaultLogoSrc from "../../images/default-logo.svg";
import colorsAvailableImage from "../../images/availableColorsPlaceholder.png";

export const Image = ({
  lazyLoad,
  colorAvailable,
  showDefaultImage,
  showNewDefaultImage,
  showNewLogoDefaultImage,
  loader,
  onLoadImage,
  id,
  ...props
}) => {
  const imgContent = (
    <Img
      {...props}
      decode={false}
      onLoad={onLoadImage}
      loader={
        loader || (
          <img
            src={
              colorAvailable
                ? colorsAvailableImage
                : showDefaultImage
                ? defaultProductSrc
                : showNewDefaultImage
                ? `${defaultNewImageSrc}`
                : showNewLogoDefaultImage && id == "main_header_logo"
                ? defaultLogoSrc
                : defaultImageSrc
            }
            alt="default"
          />
        )
      }
    />
  );

  return (
    <>
      {lazyLoad ? (
        <LazyLoad
          debounce={false}
          offsetTop={500}
          offsetBottom={500}
          className="lazy-load-wrapper"
        >
          {imgContent}
        </LazyLoad>
      ) : (
        imgContent
      )}
    </>
  );
};
