import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import FooterAcceptMoney from "./footer-accept-money";
import CountrySelector from "./country-selector";
import AppDownloads from "./app-download-options";
import { fetchSubscribeEmail } from "../../redux/actions/footer.action";
import NewsLetterForm from "./news-letter-form";

const FooterTopMobile = props => {
  const formikFormRef = useRef();
  const { footerTranslation, staticContent, preferencesTranslation } = props;
  const userLoggedIn = useSelector(state => state.authReducer.userLoggedIn);
  const settings = useSelector(state => state.common.settings);
  const countryId = get(settings, "countryId", "");
  const dispatch = useDispatch();
  const letKeepInTouch = get(footerTranslation, "letKeepInTouch", "");
  const signInToNewsLetter = get(footerTranslation, "signInToNewsLetter", "");
  const csHeading = get(staticContent, "csHeading", "");
  const csEmail = get(staticContent, "csEmail", "");
  const csNumber = get(staticContent, "csNumber", "");

  const onSubscribe = ({ email }) => {
    const bodyData = { email, countryId };
    dispatch(fetchSubscribeEmail(bodyData));
    formikFormRef.current.resetForm();
  };

  return (
    <>
      <section className="contact_section">
        <div className="container">
          <div className="row footer_top_wrap align-left">
            <section className="col-xs-12 footer_first_col padding0">
              {!userLoggedIn && (
                <>
                  <h3 className="subscrp_heading">{letKeepInTouch}</h3>
                  <p>{signInToNewsLetter}</p>
                  <NewsLetterForm
                    onSubmit={onSubscribe}
                    translation={footerTranslation}
                    formikFormRef={formikFormRef}
                  />
                </>
              )}
            </section>
            <section className="col-xs-12 footer_second_col info padding-m-0 customer_support">
              <div className="customer_support">
                <h3>{csHeading}</h3>
                <a href={`tel:${csNumber}`}>
                  <span className="phone" />
                  <span className="d-inline-block direction-ltr">
                    {csNumber}
                  </span>
                </a>
                <a className="bfl_email" href={`mailto:${csEmail}`}>
                  <span className="email" /> {csEmail}
                </a>
              </div>
              <div className="mobile_apps_links">
                <CountrySelector
                  translation={preferencesTranslation}
                  footerTranslation={footerTranslation}
                />
                <AppDownloads />
              </div>
            </section>
            <section className="footer_third_col padding-right0 padding-m-0">
              <AppDownloads />
              <FooterAcceptMoney translation={footerTranslation} />
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterTopMobile;
