import { createSelector } from "reselect";
import _ from "lodash";

export const selectBrandsData = createSelector(
  store => store.brandsReducer.brands,
  data => {
    if (_.isEmpty(data)) return [];

    const categories = _.chain(data)
      .uniqBy("category")
      .map(brand => ({
        name: brand.category,
        seoName: brand.seo_parentCategoryName || brand.category
      }))
      .filter("name")
      .value();

    const brandsData = categories.map(category => {
      const categoryBrands = _.chain(data)
        .filter(product => product.category === category.name)
        .uniqBy("name")
        .value();

      const groupedBrands = groupBrands(categoryBrands);

      return {
        _id: category.name,
        seoName: category.seoName,
        brands: groupedBrands
      };
    });

    const allCategoryBrands = _.uniqBy(data, "name");

    brandsData.unshift({
      _id: "All",
      seoName: "All",
      count: allCategoryBrands.length,
      brands: groupBrands(allCategoryBrands)
    });

    return brandsData;
  }
);

const groupBrands = brands =>
  _.chain(brands)
    .sort((brand1, brand2) => {
      const name1 = brand1.name.toLowerCase();
      const name2 = brand2.name.toLowerCase();

      if (name1 < name2) {
        return -1;
      }
      if (name1 > name2) {
        return 1;
      }

      return 0;
    })
    .groupBy(brand => _.head(brand.name).toUpperCase())
    .value();
