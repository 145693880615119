import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { hideMegaMenu, highlight } from "../../../util/dom";
import { GAService } from "../../../services/GA-service";

const List = ({ item, countryShort, language }) => {
  const {
    subTitle,
    redirectUrl,
    color: { value },
    bold
  } = item;
  const navLinkUrl = `/${language}-${
    countryShort ? countryShort.toLowerCase() : "ae"
  }${redirectUrl}`;
  return (
    <li
      onClick={e => {
        document.querySelector("#search_input").value = "";
        highlight(e);
      }}
    >
      <Link to={navLinkUrl}>
        <span
          className={cn(
            {
              "font-weight-bold": bold
            },
            "list-text"
          )}
          style={value && { color: value }}
          onClick={e => {
            hideMegaMenu(e);
            GAService.header.trackLevel2SubMenuClick(item, navLinkUrl);
          }}
        >
          {subTitle}
        </span>
      </Link>
    </li>
  );
};

export default List;
