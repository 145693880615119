import React from "react";
import cn from "classnames";

export const ShopAllCard = React.memo(({ selected, title, className }) => (
  <div className={cn("quick-filter-card-wrapper rounded", className)}>
    <div className="modal-rounded-card shop-all-card">
      <div className={cn("checkbox-icon", { selected })} />
      <div className="shop-all-card-description h-100 d-flex justify-content-center px-3">
        <h3 className="my-auto">{title}</h3>
      </div>
    </div>
  </div>
));

export const ShopAllLi = ({ onClick, title, ...others }) => (
  <li key={title} onClick={onClick}>
    <ShopAllCard title={title} {...others} />
  </li>
);
