import React from "react";
import cn from "classnames";

const GoldLabel = React.memo(({ item, placement, language }) => {
  const goldLabelImage = item[`${language}_${placement}LabelImage`];
  if (!goldLabelImage) return null;
  return (
    <div className={cn("gold-label", placement)}>
      <img src={goldLabelImage} alt="gold label" />
    </div>
  );
});
export default GoldLabel;
