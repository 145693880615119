import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NavList from "../../components/my-acc/myAcc-nav-list";
import Page from "../../components/page/index";
import RightPanel from "./rightPanel";
import DetailOverview from "../../components/my-detail-overview";
import { myAccountTranslation } from "../../language/myAccount/en/myAccount";
import { navListTranslation } from "../../language/myAccount/en/myAccountNavList";
import { checkoutTranslation as checkoutTranslationEn } from "../../language/checkoutFlow/en/checkoutFlow";
import { resetPasswordTranslation } from "../../language/resetPassword/en/resetPassword";
import {
  changeLanguage,
  saveBreadcrumb,
  resetBreadCrumb,
  getCountryList,
  setUserOriginCountry
} from "../../redux/actions/common.action";
import noRecordImg from "../../images/myAccountEmptyState.svg";
import { isServer } from "../../util";
import { Link } from "react-router-dom";
import { ENV_CONFIG } from "../../config/env";
import { logOut } from "../../redux/actions/authConfig.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { seoTranslation } from "../../language/seo/en/seo";
import { LANGUAGE_ROUTE_KEY_MAP, LANGUAGE_SHORT } from "../../constants";
import { GAService } from "../../services/GA-service";

const getSubRoute = url =>
  (url || "my-account/account-overview").split("my-account/")[1];

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myAccountTranslation: myAccountTranslation,
      navListTranslation: navListTranslation,
      checkoutTranslation: checkoutTranslationEn,
      resetPasswordTranslation: resetPasswordTranslation,
      listSelected: 1,
      listSelect: "",
      navText: "Account Overview",
      isToggle: false,
      subTab: false,
      userMobileCountryCode: "",
      userOriginCountry: ""
    };
  }
  selectList = (listId, navText, subTab = false, isPushTrue = true) => {
    let _listId = listId;
    if (listId[listId.length - 1] === "/") {
      _listId = listId.slice(0, -1);
    }
    const mobileToggle = _listId !== "account-overview";
    this.setState({
      listSelect: _listId,
      subTab: subTab,
      isToggle: mobileToggle
    });
    const { history, language, settings, logOut } = this.props;

    if (
      !isServer &&
      history &&
      settings &&
      settings.countrySHORT &&
      isPushTrue
    ) {
      if (_listId === "need-help") {
        history.push(
          `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/need-help/`
        );
      } else if (_listId === "sign-out") {
        logOut(history);
        GAService.auth.trackLogoutSuccess();
      } else {
        history.push(
          `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/my-account/${_listId}/`
        );
      }
    }
  };

  handleGoBack = () => {
    const { history } = this.props;
    history.goBack(-1);
  };

  UNSAFE_componentWillMount() {
    const { resetBreadCrumb } = this.props;
    resetBreadCrumb && resetBreadCrumb();
  }

  componentDidMount() {
    const {
      match,
      language,
      handleLanguageChange,
      saveBreadcrumb,
      userInfo,
      countryData,
      getCountryList
    } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    let subRouteName = getSubRoute(match.url);
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.loadLanguage(language);
    const breadcrumbStore = {
      modelName: "myAccount",
      modelData: {
        myAccountName: "My Account",
        myAccountUrl: `/my-account/${subRouteName}`
      }
    };
    saveBreadcrumb && saveBreadcrumb(breadcrumbStore);
    if (subRouteName) {
      this.selectList(subRouteName, "", false, false);
    }
    const { countryId } = userInfo;

    if (!isEmpty(countryData)) {
      const isExistUserCountryData =
        countryId &&
        countryData.find(country => country.countryId === countryId);
      if (!isExistUserCountryData) {
        getCountryList();
      }
    } else {
      getCountryList();
    }

    if (userInfo && !isEmpty(countryData)) {
      this.manageUserOriginCountry();
    }
  }

  componentDidUpdate(prevProps) {
    const { isToggle } = this.state;
    const { userInfo, history, language, settings } = this.props;
    if (!userInfo.accessToken || userInfo.accessToken === "") {
      const languageTransformed = `/${language}-${
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
        "ae"
      }/`;
      const location = history && history.location && history.location.pathname;
      history.push(
        `${languageTransformed}login/?${location.replace(
          languageTransformed,
          ""
        )}`
      );
    }
    const pathMyOrder = history.location.pathname
      .split("/")
      .some(string => string === "my-orders");
    const pathAccountoverview = history.location.pathname
      .split("/")
      .some(string => string === "account-overview");
    if (pathMyOrder && !isToggle) {
      this.setState({ isToggle: true });
    } else if (pathAccountoverview && isToggle) {
      this.setState({ isToggle: false });
    }

    window.onpopstate = e => {
      if (
        e &&
        e.currentTarget.location.pathname.includes("/login") &&
        e.currentTarget.location.search.includes("?my-account")
      ) {
        window.location.replace("/");
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      pageName,
      match,
      saveBreadcrumb,
      resetBreadCrumb,
      userInfo,
      countryData
    } = this.props;

    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps.language);
    }
    if (!isServer) {
      let subRouteName = getSubRoute(match.url);
      let nextSubRouteName = getSubRoute(nextProps.match.url);

      const breadcrumbStore = {
        modelName: "myAccount",
        modelData: {
          myAccountName: (pageName && `/${pageName}`) || "My Account",
          myAccountUrl:
            (pageName && `/${pageName}`) || `/my-account/${subRouteName}`
        }
      };
      resetBreadCrumb && resetBreadCrumb();
      saveBreadcrumb && saveBreadcrumb(breadcrumbStore);

      if (subRouteName !== nextSubRouteName) {
        this.setState({ listSelect: nextSubRouteName });
      }
    }

    if (userInfo && !isEmpty(countryData)) {
      this.manageUserOriginCountry();
    }
  }

  manageUserOriginCountry = () => {
    const { userInfo, countryData, setUserOriginCountry } = this.props;
    const { userMobileCountryCode } = this.state;
    try {
      const updatedUserInfo = {
        ...userInfo,
        countryId: userInfo.countryId || ENV_CONFIG.countryId
      };
      if (updatedUserInfo.countryId && !userMobileCountryCode.length) {
        const userOriginCountry = countryData.find(
          item => item.countryId === updatedUserInfo.countryId
        );
        this.setState({
          userMobileCountryCode: userOriginCountry.mobileCountryCode,
          userOriginCountry
        });
        setUserOriginCountry(userOriginCountry);
      }
    } catch (error) {}
  };

  loadLanguage = async language => {
    const responses = await Promise.all([
      import(`../../language/myAccount/${language}/myAccount`),
      import(`../../language/myAccount/${language}/myAccountNavList`),
      import(`../../language/checkoutFlow/${language}/checkoutFlow`),
      import(`../../language/resetPassword/${language}/resetPassword`),
      import(`../../language/orderSuccess/${language}/orderSuccess`)
    ]);
    const [
      accountResponse,
      navListResponse,
      checkOutResponse,
      resetPasswordResponse,
      orderSuccessResponse
    ] = responses;
    this.setState({
      myAccountTranslation: accountResponse.myAccountTranslation,
      navListTranslation: navListResponse.navListTranslation,
      checkoutTranslation: checkOutResponse.checkoutTranslation,
      resetPasswordTranslation: resetPasswordResponse.resetPasswordTranslation,
      orderSuccessTranslation: orderSuccessResponse.orderSuccessTranslation
    });
  };

  handleToggle = () => this.setState({ isToggle: !this.state.isToggle });

  render() {
    const {
      myAccountTranslation,
      checkoutTranslation,
      navListTranslation: { navList },
      listSelect,
      navText,
      isToggle,
      resetPasswordTranslation,
      orderSuccessTranslation,
      subTab,
      userMobileCountryCode,
      userOriginCountry
    } = this.state;
    const { language, userInfo, settings } = this.props;
    return (
      <Page
        id="my-account"
        language={
          language === LANGUAGE_SHORT["en"]
            ? LANGUAGE_ROUTE_KEY_MAP["en"]
            : LANGUAGE_ROUTE_KEY_MAP["ar"]
        }
        title={seoTranslation.accountTitle}
      >
        <Fragment>
          <div className="container padding0">
            <div
              className={`myAcc-wrapper ${
                language.includes("ar-") || language.includes("ar")
                  ? "arabic"
                  : ""
              }`}
            >
              <div
                className={`myAcc-sidenav ${isToggle ? "hideNavMobile" : ""} `}
                onClick={this.handleToggle}
              >
                <div
                  className={`list-wrapper ${
                    language === "ar" ? "arabic" : ""
                  }`}
                >
                  <div className="account_user_profile">
                    <DetailOverview
                      orderSuccessTranslation={orderSuccessTranslation}
                      userInfo={userInfo}
                      myAccountTranslation={myAccountTranslation}
                      language={language}
                      mobileCountryCode={userMobileCountryCode}
                      userOriginCountry={userOriginCountry}
                    />
                    <Link
                      to={`/${language}-${
                        (settings &&
                          settings.countrySHORT &&
                          settings.countrySHORT.toLowerCase()) ||
                        "ae"
                      }/my-account/my-details/`}
                    >
                      <button>{myAccountTranslation.edit}</button>
                    </Link>
                  </div>
                  <ul>
                    {navList
                      .filter(
                        nav =>
                          settings[nav.checkIsEnabledBy] ||
                          !nav?.checkIsEnabledBy
                      )
                      .map(navList => (
                        <NavList
                          key={navList.subRouteName}
                          subRouteName={navList.subRouteName}
                          navText={navList["nav-text"]}
                          iconclass={navList["icon-class"]}
                          selectList={() =>
                            this.selectList(
                              navList.subRouteName,
                              navList["nav-text"]
                            )
                          }
                          isActive={listSelect.includes(navList.subRouteName)}
                          hideInMobile={navList.hideInMobile}
                        />
                      ))}
                  </ul>
                </div>
              </div>
              <div className="myAcc-overview">
                <RightPanel
                  currentTab={listSelect}
                  navText={navText}
                  selectList={this.selectList}
                  subTab={subTab}
                  myAccountTranslation={myAccountTranslation}
                  checkoutTranslation={checkoutTranslation}
                  checkoutTranslationEn={checkoutTranslationEn}
                  resetPasswordTranslation={resetPasswordTranslation}
                  orderSuccessTranslation={orderSuccessTranslation}
                  isToggle={isToggle}
                  language={language}
                  noRecordImgUrl={noRecordImg}
                  handleGoBack={this.handleGoBack}
                />
              </div>
            </div>
          </div>
        </Fragment>
      </Page>
    );
  }
}

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  userInfo: reduxState.authReducer,
  countryData: reduxState.common && reduxState.common.countryData,
  mobileCountryCode:
    reduxState.common.settings && reduxState.common.settings.mobileCountryCode
});

const mapDispatchToProps = {
  getCountryList,
  handleLanguageChange: changeLanguage,
  logOut,
  resetBreadCrumb,
  saveBreadcrumb,
  setUserOriginCountry
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(MyAccount);
