import QuestionList from "././question-about-list";
import React from "react";
// TODO: Looks like we can remove this QuestionList component and move all code here.

const QuestionAbout = ({
  list,
  listId,
  isSelected,
  handleSelectList,
  groupName,
  handleChange,
  language,
  translation
}) => {
  const listKeys =
    !!list &&
    !!Object.keys(list) &&
    Object.keys(list).filter(
      item => item !== "_id" && !!list[`${item}`] && list[`${item}`]
    );
  return (
    <ul className="question_list">
      {!!listKeys &&
        listKeys.map(item => {
          return (
            <QuestionList
              key={!!list[`${item}`].id && list[`${item}`].id}
              isSelected={isSelected}
              innerText={
                !!list[`${item}`].description && language !== "ar"
                  ? list[`${item}`].description
                  : list[`${item}`].ar_description
              }
              id={`${!!list[`${item}`] && list[`${item}`].id}`}
              handleSelectList={handleSelectList}
              handleChange={handleChange}
              listId={listId}
              groupName={groupName}
              translation={translation}
            />
          );
        })}
    </ul>
  );
};

export default QuestionAbout;
