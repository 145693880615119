import React from "react";
import { MEMBERSHIP_STYLE_CLASS } from "../../../constants/loyalty-points";
import { LOYALTY_MEMBERSHIP_LEVELS } from "../../../constants/loyalty-points";
import blackStar from "../../../routes/my-account/images/black-star.svg";
import black from "../../../routes/my-account/images/small-star-active-black.svg";
import yellow from "../../../routes/my-account/images/small-star-active-yellow.svg";

const LoyaltyProgressBarMobile = ({
  availablePoints,
  maxTierPoints,
  memberLevel
}) => {
  const progressRatio = availablePoints / maxTierPoints;
  const yellowLevel = LOYALTY_MEMBERSHIP_LEVELS.yellow;
  return (
    <div className="loyalty-progress-bar-mobile">
      <div className="loyalty-stars">
        {MEMBERSHIP_STYLE_CLASS[memberLevel] === yellowLevel ? (
          <img src={yellow} alt="" />
        ) : (
          <img src={black} alt="" />
        )}
        <img src={blackStar} alt="" />
      </div>
      <div className={`progress-bar ${MEMBERSHIP_STYLE_CLASS[memberLevel]}`}>
        <div
          className="progress-bar-fill"
          style={{ width: `${Math.floor(100 * progressRatio)}%` }}
        />
      </div>
      <div className="points">
        <p>0</p>
        <p>
          {availablePoints}/{maxTierPoints}
        </p>
        <p>{maxTierPoints}</p>
      </div>
    </div>
  );
};

export default LoyaltyProgressBarMobile;
