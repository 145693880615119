export const resetPasswordTranslation = {
  currentPassword: "Current Password",
  error_text: "Password must be same",
  matchErrorText: "Your new passwords must match",
  hint_text: "Must be 8 or more characters",
  incorrectCredentialsError: "Your current password is wrong",
  invalidNewPasswordError: "Your new password contains an invalid value",
  placeholder_1: "New password",
  placeholder_2: "Confirm new password",
  required: "Field is required.",
  resetPassword: "Reset Password",
  passwordRequired: "Password must contain at least 8 characters",
  submit: "Submit",
  notDifferentPasswords: "New password must be different than the old one"
};
