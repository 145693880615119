import React from "react";
import * as priceUtil from "../../../util/priceUtil";

const DeliveryPrice = ({
  checkoutTranslation,
  discountType,
  isFreeShipping,
  calculatedFree,
  currency,
  selectedDelivery
}) => {
  const calculatedDeliveryPrice =
    selectedDelivery && !calculatedFree
      ? priceUtil.shapePriceWithComma(selectedDelivery.price)
      : 0;

  let deliveryPriceProps = {
    children: `${currency} ${calculatedDeliveryPrice}`
  };

  if (discountType && isFreeShipping) {
    deliveryPriceProps = {
      children: checkoutTranslation.freeShipping,
      className: "green_text"
    };
  } else if (calculatedFree) {
    deliveryPriceProps = {
      children: checkoutTranslation.free,
      className: "green_text"
    };
  }

  return (
    <p>
      <span>{checkoutTranslation.delivery}</span>
      <span {...deliveryPriceProps} />
    </p>
  );
};

export default DeliveryPrice;
