import React, { useCallback } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import map from "lodash/map";
import { COUNTRY_SELECTION_MAP, DELIVERY_TYPES } from "../../constants";
import ModalBFL from "../modal/modal";
import * as priceUtil from "../../util/priceUtil";
import TierLabel from "../tier-label";
import { DELIVERY_LOYALTY_TIERS } from "../../constants/loyalty-points";
import { isFreeShippingForced, shippingMayBeFree } from "../../util/shipping";

const ShippingOptions = ({
  commonSettings,
  handleToggleModal,
  isModalShow,
  language,
  modalData,
  routeSlug,
  translation
}) => {
  const generateDeliveryTypeInfo = useCallback(
    (deliveryType, showFreeMessage = false) => {
      const {
        standardDelivery,
        selfPickup,
        inStorePickUp,
        free,
        collect,
        pickup
      } = translation;
      switch (deliveryType) {
        case DELIVERY_TYPES.DELIVERY_ADDRESS:
          return showFreeMessage ? free : standardDelivery;
        case DELIVERY_TYPES.SELF_PICKUP:
          return showFreeMessage ? collect : selfPickup;
        case DELIVERY_TYPES.STORE_PICKUP:
          return showFreeMessage ? pickup : inStorePickUp;
        default:
          return null;
      }
    },
    [translation]
  );

  const generateShippingChargeInfo = useCallback(
    (freeShippingLimit, shippingCharge) =>
      isFreeShippingForced(freeShippingLimit, shippingCharge)
        ? translation.deliveryPrice
        : `${commonSettings.currencyCode} ${priceUtil.shapePriceWithComma(
            shippingCharge
          )}`,
    [translation, commonSettings]
  );

  const generateSpendOverInfo = useCallback(
    item =>
      isFreeShippingForced(item.freeShippingLimit, item.shippingCharge)
        ? translation.allOrders
        : `${translation.spendOver} ${
            commonSettings.currencyCode
          } ${priceUtil.shapePriceWithComma(item.freeShippingLimit)}`,
    [translation, commonSettings]
  );

  return (
    <ModalBFL
      showModal={isModalShow}
      modalClass={cn("generic_modal", "shipping_option_modal", {
        arabic: language.includes("ar")
      })}
    >
      <Scrollbars autoHeight autoHeightMin={200} autoHeightMax={530}>
        <button className="cross_btn" onClick={handleToggleModal}>
          &times;
        </button>
        <div className="shipping_modal_header">
          {translation.shippingOptions}
        </div>
        <div className="shipping_option">
          <div className="deliver_to">{translation.deliverTo}</div>
          <div className="info">
            <img
              alt="flag"
              className="country_flag_img"
              src={
                COUNTRY_SELECTION_MAP[
                  commonSettings && commonSettings.countryCode
                ]
              }
            />
            {commonSettings && commonSettings.countryName}
          </div>
        </div>
        <div className="modal_body">
          <ul className="table_data">
            {map(modalData, (item, index) => (
              <li className="shipping_modal_wrapper" key={index}>
                <span>{generateDeliveryTypeInfo(item.deliveryType)}</span>
                <span className="shipping_modal_info position-relative">
                  <span className="amnt">
                    {generateShippingChargeInfo(
                      item.freeShippingLimit,
                      item.shippingCharge
                    )}
                  </span>
                  <span>
                    {item.deliveryMessage ||
                      item[`${language}_deliveryMessage`]}
                  </span>
                  {(isFreeShippingForced(
                    item.freeShippingLimit,
                    item.shippingCharge
                  ) ||
                    shippingMayBeFree(
                      item.freeShippingLimit,
                      item.shippingCharge
                    )) && (
                    <span className="free_block">
                      <span id="shipping_price">
                        {generateDeliveryTypeInfo(item.deliveryType, true)}
                      </span>{" "}
                      {generateSpendOverInfo(item)}
                    </span>
                  )}
                  {item.tier === DELIVERY_LOYALTY_TIERS.Black && (
                    <TierLabel translation={translation} />
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="modal_body_mobile">
          <ul className="table_data">
            {map(modalData, (item, key) => (
              <li key={item.deliveryType || key}>
                <div className="common_col left_col">
                  <div className="common_heading">
                    {item.deliveryType === DELIVERY_TYPES.DELIVERY_ADDRESS
                      ? translation.standardDelivery
                      : translation.selfPickup}
                  </div>
                  <span>
                    {item.deliveryMessage ||
                      item[`${language}_deliveryMessage`]}
                  </span>
                </div>
                <div className="common_col right_col">
                  <div className="common_heading">
                    {commonSettings && commonSettings.currencyCode}{" "}
                    {item.shippingCharge}
                  </div>
                  <span>
                    <span className="free">{translation.free}</span>{" "}
                    {translation.spendOver}{" "}
                    {commonSettings && commonSettings.currencyCode}{" "}
                    {item.freeShippingLimit}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="modal_footer">
          <span className="info_icon" />
          <span className="info_text">{translation.howToReturnYourOrder} </span>
          <div className="lower_info_text">
            {translation.seeOur}{" "}
            <Link target="_blank" to={`/${routeSlug}/return-cancellation/`}>
              {translation.returnsSmall}
            </Link>{" "}
            {translation.pageForMoreInfo}
          </div>
        </div>
      </Scrollbars>
    </ModalBFL>
  );
};

export default ShippingOptions;
