import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    zIndex: "15"
  }
};

class ModalBfl extends React.Component {
  render() {
    const {
      showModal,
      closeModal,
      modalClass,
      overlayClassName,
      modalCustomStyle
    } = this.props;
    const classes = `Modal ${modalClass}`;
    return (
      <div className="modal_wrapper">
        <Modal
          isOpen={showModal}
          onRequestClose={closeModal}
          className={classes}
          overlayClassName={`overlay ${
            !!overlayClassName ? overlayClassName : ""
          }`}
          contentLabel="Example Modal"
          style={{ ...customStyles, ...modalCustomStyle }}
          closeTimeoutMS={200}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

Modal.setAppElement("#root");

export default ModalBfl;
