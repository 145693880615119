import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import ModalBFL from "../modal";
import {
  selectAddressOtpError,
  selectAddressOtpFlow,
  selectLanguageSlug
} from "../../../util/selectors";
import SendOTP from "./send-otp";
import ThankYou from "./thankyou";
import { setAddressOtpError } from "../../../redux/actions/homepage.action";
import { GAService } from "../../../services/GA-service";

const steps = { SEND_OTP: "SEND_OTP", THANK_YOU: "THANK_YOU" };

const modalCustomStyle = {
  overlay: {
    zIndex: 99
  }
};

const AddAddressMobileNumber = ({
  showModal,
  onSuccessOtp,
  closeModal,
  verifyOnly
}) => {
  const dispatch = useDispatch();
  const [orderSuccessTranslation, setOrderSuccessTranslation] = useState({});
  const [otpValid, setOtpValid] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const [mobileModalStep, setMobileModalStep] = useState(steps.SEND_OTP);
  const [showVerifyButtonLoader, setShowVerifyButtonLoader] = useState(false);

  const language = useSelector(selectLanguageSlug);
  const errorMessage = useSelector(selectAddressOtpError);
  const {
    verificationCallback: verifyOtp,
    resendOTPCallback: resendOtp,
    phoneForOTP: phoneNumber
  } = useSelector(selectAddressOtpFlow);

  const onResendOtp = () => {
    setOtpCode("");
    setOtpValid(true);
    setSendOtpCount(prevCount => prevCount + 1);
    resendOtp();
  };

  const onModalClose = () => {
    closeModal();
    setOtpCode("");
    setSendOtpCount(0);
    setOtpValid(true);
    setMobileModalStep(steps.SEND_OTP);
    dispatch(setAddressOtpError(""));
  };

  const onVerify = async () => {
    if (!verifyOtp) return;
    setShowVerifyButtonLoader(true);
    const resp = await verifyOtp(otpCode);
    if (resp.data?.success) {
      onSuccessOtp(resp);
      GAService.auth.trackOTPVerificationSuccess();
      if (verifyOnly) {
        onModalClose();
      } else {
        setMobileModalStep(steps.THANK_YOU);
      }
    }
    setShowVerifyButtonLoader(false);
  };

  const modalContentComponents = {
    [steps.SEND_OTP]: (
      <SendOTP
        orderSuccessTranslation={orderSuccessTranslation}
        phone={phoneNumber}
        otp={otpCode}
        handleOTPChange={setOtpCode}
        verifyOtp={onVerify}
        otpValid={otpValid}
        sendOtp={onResendOtp}
        sendOtpCount={sendOtpCount}
        errorMessage={errorMessage}
        showLoader={showVerifyButtonLoader}
      />
    ),
    [steps.THANK_YOU]: (
      <ThankYou orderSuccessTranslation={orderSuccessTranslation} />
    )
  };

  const [modalContent, setModalContent] = useState(
    modalContentComponents[mobileModalStep]
  );

  useEffect(() => {
    const loadTranslation = async () => {
      const translation = await import(
        `../../../language/orderSuccess/${language}/orderSuccess`
      );

      setOrderSuccessTranslation(translation.orderSuccessTranslation);
    };
    loadTranslation();
  }, [language]);

  useEffect(() => {
    setModalContent(modalContentComponents[mobileModalStep]);
  }, [
    mobileModalStep,
    orderSuccessTranslation,
    otpCode,
    otpValid,
    sendOtpCount,
    errorMessage,
    phoneNumber,
    showVerifyButtonLoader
  ]);

  return (
    <ModalBFL
      showModal={showModal}
      closeModal={onModalClose}
      modalCustomStyle={modalCustomStyle}
      modalClass={cn(
        {
          arabic: language.includes("ar")
        },
        "generic_modal mobile_verification_modal wide_generic_modal address_otp"
      )}
    >
      {modalContent}
      <button className="cross_btn" onClick={onModalClose}>
        &times;
      </button>
    </ModalBFL>
  );
};

export default AddAddressMobileNumber;
