import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import get from "lodash/get";
import AllBrandsMobile from "../all-brands/m-brand-items";
import { Image } from "../image";
import { selectRouteSlug } from "../../util/selectors";

const AllBrands = ({ shopByBrands, language }) => {
  const brandLogos = get(shopByBrands, [language, "brandLogos"]);
  const ctaDisplay = get(shopByBrands, [language, "ctaDisplay"], "");
  const redirectLinkByBrand = get(
    shopByBrands,
    [language, "ctaRedirection"],
    "/brands"
  );
  const routeSlag = useSelector(selectRouteSlug);
  const subTitle = get(shopByBrands, [language, "subTitle"], "");
  const title = get(shopByBrands, [language, "title"], "");

  return (
    <div>
      {!isEmpty(brandLogos) && (
        <div className="all_brands container">
          <div className="row">
            <div className="brands_details col-sm-12">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
            <div className="brand_listing col-sm-12">
              <ul className="show_web">
                {brandLogos.map((item, i) => (
                  <li key={item._id || i}>
                    <Link
                      to={`/${routeSlag}${item.redirectionLink}`}
                      className="brand_img_wrap"
                    >
                      <Image src={item.imageLink} alt="all-brand-logo-nike" />
                    </Link>
                    <div className="brand_content">
                      <h4>{item.brandName}</h4>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <AllBrandsMobile shopBybrand={brandLogos} routeSlag={routeSlag} />
              <div className="brand_cta">
                <Link
                  to={`/${routeSlag}${redirectLinkByBrand}`}
                  className="white section_cta round_btn"
                >
                  {ctaDisplay}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllBrands;
