import React from "react";
import { Link } from "react-router-dom";

const getActualYear = stringWithYear =>
  stringWithYear.replace(/2(\d+){3}/, new Date().getFullYear());

const SecondaryFooter = ({ checkoutTranslation, routelanguage, settings }) => (
  <div className="secondary_heading sec_footer">
    <ul className="left_edge">
      <li>{checkoutTranslation.url}</li>
      <li>{getActualYear(checkoutTranslation.copyright)}</li>
    </ul>
    <ul className="right_edge">
      <li>
        <Link
          to={`/${routelanguage}-${(settings &&
            settings.countrySHORT &&
            settings.countrySHORT.toLowerCase()) ||
            "ae"}/privacy-policy/`}
        >
          {checkoutTranslation.privacyPolicy}
        </Link>
      </li>
      <li>
        <Link
          to={`/${routelanguage}-${(settings &&
            settings.countrySHORT &&
            settings.countrySHORT.toLowerCase()) ||
            "ae"}/terms-and-conditions/`}
        >
          {checkoutTranslation.tnc}
        </Link>
      </li>
    </ul>
  </div>
);

export default SecondaryFooter;
