import React from "react";

const LoyaltyRedeemInfoMobile = ({
  translation,
  currencyCode,
  loyaltyDetails
}) => {
  return (
    <div className="loyalty-points-info-mobile">
      <p className="current-balance-mobile">{translation.youCurrentlyHave}</p>
      <h2 className="points-count-mobile">
        {currencyCode} {loyaltyDetails.pointsValue}
      </h2>
      <p className="smile-points-mobile">
        {loyaltyDetails.currentBalance} {translation.smilePoints}
      </p>
      <p className="points-explanation">
        {loyaltyDetails.usedPointsToConvert} {translation.smilePoints} ={" "}
        {currencyCode} {loyaltyDetails.earnedAmountFromConvert}
      </p>
    </div>
  );
};

export default LoyaltyRedeemInfoMobile;
