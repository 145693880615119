import React, { Fragment } from "react";
import get from "lodash/get";
import HtmlParser from "../../components/html-parser";
import { LANGUAGE_ROUTE_KEY_MAP } from "../../constants";

const Banner = ({ topBannerContent, language }) => {
  const languageKey = LANGUAGE_ROUTE_KEY_MAP[language] || language;
  const html = get(topBannerContent, [languageKey, "defaultTemplate"], "");

  return (
    <Fragment>
      <div className="top_banner_wrapper">
        <HtmlParser html={html} />
      </div>
    </Fragment>
  );
};

export default Banner;
