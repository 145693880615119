import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";
import classnames from "classnames";
import FooterBottomMobile from "./footer-bottom-mobile";
import FooterAcceptMoney from "./footer-accept-money";
import ConnectWithUs from "./connect-with-us";
import FooterTopMobile from "./footer-top-mob";

class FooterWrapMobile extends Component {
  state = {
    isToggle: false
  };
  handleToggle = () =>
    this.setState({ isToggle: !this.state.isToggle }, () => {
      setTimeout(() => {
        this.scrollToBottom();
      }, 780);
    });
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    const { isToggle } = this.state;
    const {
      setFooterSpace,
      footerTranslation,
      staticContent,
      settings,
      routelanguage
    } = this.props;

    return (
      <Fragment>
        <div
          className={classnames("footer_wrap_m", {
            footer_open: isToggle,
            setFooterSpace: setFooterSpace && isMobile
          })}
          ref={el => {
            this.el = el;
          }}
        >
          <h4 className="main_wrap_head" onClick={this.handleToggle}>
            {footerTranslation && footerTranslation.moreAboutBrandsForLess}
          </h4>
          <div className="footer_wrap_content">
            <FooterTopMobile
              footerTranslation={footerTranslation}
              staticContent={staticContent}
            />
            <FooterAcceptMoney />
            <FooterBottomMobile
              footerTranslation={footerTranslation}
              routelanguage={routelanguage}
              settings={settings}
              staticContent={staticContent}
            />
            <ConnectWithUs staticContent={staticContent} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FooterWrapMobile;
