import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import SyteCameraIcon from "../../../images/syte-camera.svg";
import SyteCameraMobileIcon from "../../../images/syte-camera-mobile.svg";
import SyteDiscoveryListIcon from "../../../images/syte-discovery-listing.svg";
import SyteDiscoveryListMobileIcon from "../../../images/syte-discovery-listing-mobile.svg";
import SyteDiscoveryPDPOrangeWTextIcon from "../../../images/syte-discovery-product-orange-w-text.svg";
import SyteDiscoveryPDPOrangeMobileIcon from "../../../images/syte-discovery-product-orange-mobile.svg";
import { isArabic, encodeSpace } from "../../../util";
import { SYTE_DISCOVERY_LOCATION } from "../../../constants";
import SyteTooltip from "../syte-tooltip";
import withSyteApiStatus from "../../../hocs/withSyteApiStatus";

export const SyteSearchButton = withSyteApiStatus(
  ({
    className,
    mobile = false,
    children,
    onButtonRender,
    syteAppIsReady,
    ...otherProps
  }) => {
    useEffect(() => {
      if (syteAppIsReady) {
        onButtonRender && onButtonRender();
      }
    }, [syteAppIsReady]);

    return (
      syteAppIsReady && (
        <button
          className={classnames(
            "--syte-start-camera-upload",
            "btn btn-link syte-search-btn",
            className
          )}
          data-camera-button-placement="search-bar"
          {...otherProps}
        >
          <img
            src={mobile ? SyteCameraMobileIcon : SyteCameraIcon}
            alt="search by image"
          />
          {children}
        </button>
      )
    );
  }
);

export const SyteDiscoveryButton = withSyteApiStatus(
  ({
    productImgPath,
    sku,
    placement = "main-image",
    location,
    mobile = false,
    className,
    text,
    details,
    handleClick,
    syteAppIsReady
  }) => {
    let iconImage;
    switch (location) {
      case SYTE_DISCOVERY_LOCATION.LISTING:
        if (mobile) {
          iconImage = SyteDiscoveryListMobileIcon;
        } else {
          iconImage = SyteDiscoveryListIcon;
          className += " hover";
        }
        break;
      case SYTE_DISCOVERY_LOCATION.PDP:
      default:
        iconImage = mobile
          ? SyteDiscoveryPDPOrangeMobileIcon
          : SyteDiscoveryPDPOrangeWTextIcon;
        break;
    }

    const clickHandler = e => {
      e.preventDefault();
      if (handleClick && details) {
        handleClick(details);
      }
    };

    if (!Boolean(syteAppIsReady)) return null;

    return (
      <button
        className={classnames(
          "syte-discovery",
          "btn btn-link syte-discovery-btn",
          className
        )}
        data-image-src={encodeSpace(productImgPath)}
        data-sku={sku}
        data-placement={placement}
        onClick={clickHandler}
      >
        <img src={iconImage} alt="search similar" />
        <span className="syte-discovery-btn-text">{text}</span>
      </button>
    );
  }
);

const mapStateToProps = reduxState => ({
  language: reduxState.common.language
});

export const SyteSearchWithTooltip = connect(mapStateToProps)(
  ({ mobile, language, className, handleClick }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [translation, setTranslation] = useState(null);

    const hideTooltip = () => {
      setShowTooltip(false);
    };

    const arabic = isArabic(language);
    useEffect(() => {
      const updateTranslation = async () => {
        const response = await import(
          `../../../language/userPreferences/${language}/userPreferences`
        );
        setTranslation(response.preferencesTranslation);
      };
      updateTranslation();
    }, [language]);

    return (
      <div
        className={classnames("syte-search-w-tooltip", {
          "d-inline-block responsive": mobile,
          className,
          arabic
        })}
      >
        <SyteSearchButton
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={hideTooltip}
          mobile={mobile}
          onClick={handleClick}
        />
        <div className="position-relative">
          {translation && showTooltip && (
            <SyteTooltip onCrossClick={hideTooltip} translation={translation} />
          )}
        </div>
      </div>
    );
  }
);
