import React from "react";
import isArray from "lodash/isArray";
import MyWalletTransactionCard from "../myAcc-transaction-card";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../loader";
import { LOADER_TYPES, commonLoaderStyle } from "../../../constants";

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "rgb(255 255 255 / 75%)"
};
const MyWalletTransaction = ({
  myAccountTranslation,
  walletHistoryDetails,
  fetchMoreHistory,
  fullWalletHistoryLoaded
}) => (
  <div className="transaction_wrapper positive">
    <div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
      {isArray(walletHistoryDetails) ? (
        <InfiniteScroll
          dataLength={
            (walletHistoryDetails && walletHistoryDetails.length) || 0
          }
          next={fetchMoreHistory}
          hasMore={Boolean(
            walletHistoryDetails &&
              walletHistoryDetails.length &&
              !fullWalletHistoryLoaded
          )}
          loader={
            <Loader
              type={LOADER_TYPES.RING}
              width={25}
              height={25}
              visible
              style={loaderStyle}
            />
          }
          scrollableTarget="scrollableDiv"
        >
          {walletHistoryDetails && walletHistoryDetails.length !== 0 ? (
            walletHistoryDetails.map(walletHistory => (
              <MyWalletTransactionCard
                key={walletHistory.date}
                myAccountTranslation={myAccountTranslation}
                walletHistory={walletHistory}
              />
            ))
          ) : (
            <div className="no_record_found">No Transactions Available</div>
          )}
        </InfiniteScroll>
      ) : (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={loaderStyle}
        />
      )}
    </div>
  </div>
);
export default MyWalletTransaction;
