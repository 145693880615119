import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { CheckoutComService } from "../../../../services";

import {
  changeCheckoutData,
  clearBinCode,
  loadCreditCards,
  removeAppliedVoucher,
  setBinCode,
  setCheckoutComFormValidationStatus,
  updateDbCheckoutData,
  setSummaryRecalculationInProgress,
  resetSummaryRecalculationInProgress
} from "../../../../redux/actions/checkout.action";

import Loader from "../../../loader";

import DeleteModal from "../../../delete-modal";

import SavedCardList from "./SavedCardList";
import Form from "./Form";

import "./style.scss";
import { MODAL_DIALOG_OPTIONS, LOADER_TYPES } from "../../../../constants";
import { checkoutTranslation } from "../../../../language/checkoutFlow/en/checkoutFlow";
import { GAService } from "../../../../services/GA-service";

class CreditCardForm extends Component {
  static mobileViewMode = {
    CARD_LIST: 0,
    CARD_FORM: 1
  };

  state = {
    isLoaded: CheckoutComService.isSdkLoaded,
    isFormRendered: false,
    savingCardEnabled: false,
    invalidFields: [],
    matchedResult: null,
    isVoucherConfirmModal: false
  };

  setMobileViewMode(mode) {
    const { creditCardOptions = {}, updateDbCheckoutData } = this.props;

    const dataToUpdate = {
      ...creditCardOptions,
      mobileViewMode: mode
    };

    updateDbCheckoutData("creditCardOptions", dataToUpdate);
  }

  async componentDidMount() {
    const { userLoggedIn, loadCreditCards } = this.props;

    if (!CheckoutComService.isSdkLoaded) {
      await CheckoutComService.loadSdk();
      this.setState({ isLoaded: true });
    }

    if (userLoggedIn) await loadCreditCards();
  }

  componentWillUnmount() {
    const {
      removeAppliedVoucher,
      clearBinCode,
      changeVoucherStatus,
      order,
      voucherCodes
    } = this.props;

    if (order.voucherCode) {
      const bankSpecificCodes = voucherCodes.filter(
        voucher => voucher.isBankSpecific
      );
      const isBankSpecificCodeApplied = bankSpecificCodes.find(
        code => code.voucherCode === order.voucherCode
      );
      if (isBankSpecificCodeApplied) {
        changeVoucherStatus(null);
        removeAppliedVoucher();
      }
      clearBinCode();
    }
  }

  onFrameBlur = async (element, isFormValid) => {
    const {
      binNo,
      setBinCode,
      setSummaryRecalculationInProgress,
      resetSummaryRecalculationInProgress,
      order
    } = this.props;

    if (element === "card-number" && isFormValid) {
      setSummaryRecalculationInProgress();
      const { bin } = await CheckoutComService.submitCheckoutComForm();
      if ((binNo || order.voucherNo) && binNo !== bin) {
        this.compareBinCodes(bin);
        setBinCode(bin);
      } else {
        resetSummaryRecalculationInProgress();
      }
    }
  };

  onValidationChanged = async event => {
    const {
      setCheckoutComFormValidationStatus,
      checkoutComFormValidationStatus,
      removeAppliedVoucher,
      setBinCode,
      clearBinCode,
      changeVoucherStatus,
      setSummaryRecalculationInProgress,
      binNo
    } = this.props;

    if (checkoutComFormValidationStatus !== event.isValid) {
      setCheckoutComFormValidationStatus(event.isValid);
    }
    if (event.isValid) {
      setSummaryRecalculationInProgress();
      const { bin } = await CheckoutComService.submitCheckoutComForm();
      setBinCode(bin);
      this.compareBinCodes(bin);
    } else {
      if (binNo) {
        removeAppliedVoucher();
        clearBinCode();
        changeVoucherStatus(null);
      }
    }
  };

  compareBinCodes = async binCode => {
    const {
      voucherCodes,
      resetSummaryRecalculationInProgress,
      clearBinCode,
      preApplyVoucher
    } = this.props;
    if (!isEmpty(voucherCodes) && binCode) {
      const bankSpecificCodes = voucherCodes.filter(
        voucher => voucher.isBankSpecific
      );

      const matchedResult = bankSpecificCodes.find(
        voucher =>
          !isEmpty(voucher.binNo) && voucher.binNo.split(",").includes(binCode)
      );
      const voucherDetails =
        matchedResult && (await preApplyVoucher(matchedResult));
      if (voucherDetails && voucherDetails.data.success) {
        this.setState({ matchedResult }, this.checkIsAnotherVoucherApplied);
        return;
      } else {
        clearBinCode();
      }
    }
    resetSummaryRecalculationInProgress();
  };

  checkIsAnotherVoucherApplied = () => {
    const { order } = this.props;
    const { matchedResult } = this.state;

    const isAnotherVoucherApplied =
      order.voucherCode && order.voucherCode !== matchedResult.voucherCode;

    if (isAnotherVoucherApplied) {
      this.setState({ isVoucherConfirmModal: true });
    } else {
      this.changeVoucher(matchedResult.voucherCode);
    }
  };

  applyConfirm = () => {
    const { matchedResult } = this.state;
    this.changeVoucher(matchedResult.voucherCode);
    this.setState({ isVoucherConfirmModal: false });
  };

  handleFormReady = () => {
    this.setState({ isFormRendered: true });
  };

  handleChangeSavingCardCheckbox = () => {
    const { creditCardOptions = {}, updateDbCheckoutData } = this.props;

    const savingCardEnabled = get(creditCardOptions, "savingCardEnabled");

    updateDbCheckoutData("creditCardOptions", {
      ...creditCardOptions,
      savingCardEnabled: !savingCardEnabled
    });
  };

  onFormFocus = () => {
    const {
      creditCardOptions = {},
      updateDbCheckoutData,
      removeAppliedVoucher,
      binNo
    } = this.props;
    if (creditCardOptions.selectedCard) {
      updateDbCheckoutData("creditCardOptions", {
        ...creditCardOptions,
        selectedCard: null
      });

      if (binNo) {
        removeAppliedVoucher();
      }
    }
  };

  getMobileViewModeOption = () => {
    const { creditCardList } = this.props;
    const hasSavedCards = !!creditCardList.length;
    return hasSavedCards
      ? CreditCardForm.mobileViewMode.CARD_LIST
      : CreditCardForm.mobileViewMode.CARD_FORM;
  };

  frameValidationChanged = e => {
    const { invalidFields } = this.state;
    const isElemAlreadyIncluded = invalidFields.includes(e.element);

    if (e.isValid && isElemAlreadyIncluded) {
      const filteredFields = invalidFields.filter(elem => elem !== e.element);

      this.setState({ invalidFields: filteredFields });
    } else if (!e.isValid && !e.isEmpty && !isElemAlreadyIncluded) {
      const filteredFields = [...invalidFields, e.element];
      const errorMsg = this.getErrorFieldMessage(e.element, true);
      GAService.checkout.trackPaymentError({
        payment_method: "cc",
        error_message: errorMsg
      });
      this.setState({ invalidFields: filteredFields });
    }
  };

  getErrorFieldMessage = (element, isRequiredInEnglish = false) => {
    const { translation } = this.props;
    const translationData = isRequiredInEnglish
      ? checkoutTranslation
      : translation;

    const errors = {
      "card-number": translationData.invalidCardNumber,
      "expiry-date": translationData.invalidExpiryDate,
      cvv: translationData.invalidCvv
    };

    return errors[element];
  };

  getErrorMessageSection = () => {
    const { invalidFields } = this.state;
    const { translation } = this.props;

    return (
      <p className="error">
        {invalidFields.length > 1
          ? translation.invalidCardDetails
          : this.getErrorFieldMessage(invalidFields[0])}
      </p>
    );
  };

  changeVoucher = async voucherName => {
    const { changeCheckoutData, applyVoucher } = this.props;
    const code = (voucherName || "").trim();

    await changeCheckoutData("voucherCode", code);
    await applyVoucher(null, true);
  };

  onCloseModal = (_, option) => {
    const { resetSummaryRecalculationInProgress, clearBinCode } = this.props;
    this.setState({ isVoucherConfirmModal: false });
    if (option === MODAL_DIALOG_OPTIONS.yes) {
      resetSummaryRecalculationInProgress();
      clearBinCode();
    }
  };

  render() {
    const {
      creditCardOptions,
      translation,
      creditCardList,
      userLoggedIn,
      removeAppliedVoucher,
      language,
      changeVoucherStatus,
      binNo,
      handleVoucherAppliedMessage,
      order,
      user
    } = this.props;
    const { isLoaded, isFormRendered, invalidFields, isVoucherConfirmModal } =
      this.state;

    const savingCardEnabled = get(
      creditCardOptions,
      "savingCardEnabled",
      false
    );

    const mobileViewMode = get(
      creditCardOptions,
      "mobileViewMode",
      this.getMobileViewModeOption()
    );

    if (!translation) return null;

    const cardInputText = creditCardList.length
      ? translation.payUsingDifferentCard
      : translation.payUsingCard;
    const hasSavedCards = !!creditCardList.length;

    return (
      <div className="credit-card-form">
        {hasSavedCards && (
          <SavedCardList
            className={cn({
              "d-none-tablet":
                mobileViewMode !== CreditCardForm.mobileViewMode.CARD_LIST
            })}
            translation={translation}
            compareBinCodes={this.compareBinCodes}
            removeAppliedVoucher={removeAppliedVoucher}
            changeVoucherStatus={changeVoucherStatus}
            binNo={binNo}
            handleVoucherAppliedMessage={handleVoucherAppliedMessage}
          />
        )}

        {mobileViewMode === CreditCardForm.mobileViewMode.CARD_LIST && (
          <div
            className="mobile-view-switcher"
            onClick={() =>
              this.setMobileViewMode(CreditCardForm.mobileViewMode.CARD_FORM)
            }
          >
            {cardInputText}
          </div>
        )}

        {isLoaded && (
          <div
            className={cn("checkout-com-form", {
              "d-none": !isFormRendered,
              "d-none-tablet":
                mobileViewMode !== CreditCardForm.mobileViewMode.CARD_FORM,
              "pt-md-1": !hasSavedCards,
              "without-cards": !hasSavedCards
            })}
          >
            <div className="title d-none-tablet">{cardInputText}</div>

            <Form
              order={order}
              user={user}
              onFormRendered={this.handleFormReady}
              onValidationChanged={this.onValidationChanged}
              onFocus={this.onFormFocus}
              frameValidationChanged={this.frameValidationChanged}
              onFrameBlur={this.onFrameBlur}
            />

            {Boolean(invalidFields.length) && this.getErrorMessageSection()}

            {userLoggedIn && (
              <div
                className="save-for-future"
                onClick={this.handleChangeSavingCardCheckbox}
              >
                <div
                  className={cn("checkbox", { checked: savingCardEnabled })}
                />

                <div className="text">{translation.saveThisCardForFuture}</div>
              </div>
            )}
          </div>
        )}

        {!isFormRendered &&
          mobileViewMode === CreditCardForm.mobileViewMode.CARD_FORM && (
            <div className="loader-wrapper">
              <Loader
                type={LOADER_TYPES.RIPPLE}
                width={100}
                height={100}
                visible
              />
            </div>
          )}

        {hasSavedCards &&
          mobileViewMode === CreditCardForm.mobileViewMode.CARD_FORM && (
            <div
              className="mobile-view-switcher"
              onClick={() =>
                this.setMobileViewMode(CreditCardForm.mobileViewMode.CARD_LIST)
              }
            >
              {translation.payUsingSavedCards}
            </div>
          )}

        {isVoucherConfirmModal && (
          <DeleteModal
            language={language}
            bodyTxt={translation.cardPromoMessage}
            cancelBtnTxt={translation.no}
            deleteBtnTxt={translation.yes}
            isDeleteModalActive={isVoucherConfirmModal}
            handleCloseModal={this.onCloseModal}
            handleDeleteItem={this.applyConfirm}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  checkoutComFormValidationStatus:
    state.checkoutReducer.checkoutComFormValidationStatus,
  creditCardOptions: state.checkoutReducer.checkoutData.creditCardOptions,
  creditCardList: state.checkoutReducer.creditCardList,
  userLoggedIn: state.authReducer.userLoggedIn,
  voucherCodes: state.checkoutReducer.VoucherCodes,
  binNo: state.checkoutReducer.binNo,
  language: state.common.language,
  user: state.authReducer
});

const mapDispatchToProps = {
  setCheckoutComFormValidationStatus,
  updateDbCheckoutData,
  loadCreditCards,
  changeCheckoutData,
  removeAppliedVoucher,
  setBinCode,
  clearBinCode,
  setSummaryRecalculationInProgress,
  resetSummaryRecalculationInProgress
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
