import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import cn from "classnames";
import FooterContact from "./footer-contact";
import FooterWeb from "./footer-web.js";
import FooterWrapMobile from "./m-footer-wrapped";
import { footerTranslation } from "../../language/footer/en/footer";
import { preferencesTranslation } from "../../language/userPreferences/en/userPreferences";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import { getFooterData } from "../../redux/actions/footer.action";

class Footer extends Component {
  state = {
    footerTranslation,
    preferencesTranslation,
    showFooterTop: true
  };

  componentDidMount() {
    const languageParam = this.props.language;
    const { footerContent } = this.props;
    languageParam && this.getLanguage(languageParam);
    this.props.settings &&
      this.props.settings.countryId &&
      footerContent(
        languageParam === LANGUAGE_ROUTE_KEY.arabic
          ? LANGUAGE.arabic
          : LANGUAGE.english,
        this.props.settings.countryId
      );

    if (isMobile) {
      this.setState({ showFooterTop: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { language, settings, footerContent } = this.props;
    if (
      language !== nextProps.language ||
      (nextProps.settings && nextProps.settings.countryId) !==
        (settings && settings.countryId)
    ) {
      this.getLanguage(nextProps.language);
      const languageParam = nextProps.language;
      nextProps.settings &&
        nextProps.settings.countryId &&
        footerContent(
          languageParam === LANGUAGE_ROUTE_KEY.arabic
            ? LANGUAGE.arabic
            : LANGUAGE.english,
          nextProps.settings.countryId
        );
    }
  }

  getLanguage = language => {
    Promise.all([
      import(`../../language/footer/${language}/footer`),
      import(`../../language/userPreferences/${language}/userPreferences`)
    ]).then(responses => {
      const [languageResponse, languageResponsePreference] = responses;
      this.setState({
        footerTranslation: languageResponse.footerTranslation,
        preferencesTranslation:
          languageResponsePreference.preferencesTranslation
      });
    });
  };

  render() {
    const { footerTranslation, preferencesTranslation } = this.state;
    const {
      language,
      setFooterSpace,
      hideInMobile,
      hideFooterInMobile,
      staticContent,
      settings
    } = this.props;

    return (
      <div
        className={cn({
          arabic: language === LANGUAGE_ROUTE_KEY.arabic,
          hideHeaderFooter: hideInMobile,
          hideFooterInMobile: hideFooterInMobile
        })}
      >
        <footer>
          <FooterWrapMobile
            setFooterSpace={setFooterSpace}
            footerTranslation={footerTranslation}
            staticContent={staticContent}
            routelanguage={language}
            settings={settings}
          />
          <>
            <FooterContact
              staticContent={staticContent}
              footerTranslation={footerTranslation}
            />
            <FooterWeb
              footerTranslation={footerTranslation}
              preferencesTranslation={preferencesTranslation}
            />
          </>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings,
  staticContent: state.footerReducer.staticContent
});

const mapDispatchToProps = dispatch => ({
  footerContent: bindActionCreators(getFooterData, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
