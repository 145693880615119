import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import SelectedFilterContent from "./selected-filter-content";
import {
  handleFacetChange,
  getProductsData,
  handlePageChange,
  resetRangeChanged,
  handleSortChange
} from "../../redux/actions/productlisting.action";

class SelectedFilter extends Component {
  clearFacet(e, facetArray) {
    const { handleFacetChange, getProductsData, history, match, language } =
      this.props;
    const facets = e.target.id.split(":");
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    if (facetArray) {
      facetArray.forEach(subFacet =>
        handleFacetChange(facets[0], subFacet, false)
      );
    } else {
      handleFacetChange(facets[0], facets[1], false);
    }
    getProductsData(
      facets[0],
      history,
      null,
      null,
      languageParam,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    );
  }

  clearAll() {
    const {
      selectedFilters,
      handleFacetChange,
      getProductsData,
      history,
      handlePageChange,
      handleSwitchFilter,
      match,
      resetRangeChanged,
      language,
      handleSortChange
    } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    selectedFilters.forEach(filter => {
      handleFacetChange(filter.facet, filter.subFacet, false);
    });
    handlePageChange(0);
    handleSortChange("");
    resetRangeChanged && resetRangeChanged();
    getProductsData(
      null,
      history,
      "",
      null,
      languageParam,
      true,
      null,
      null,
      null,
      null,
      null,
      null,
      { clearFilter: true }
    );
    handleSwitchFilter(false);
  }

  render() {
    const { selectedFilters, translation, language, colors } = this.props;

    return (
      <Fragment>
        {
          <SelectedFilterContent
            filterContent={selectedFilters}
            clearFacet={this.clearFacet.bind(this)}
            clearAll={this.clearAll.bind(this)}
            translation={translation}
            language={language}
            colors={colors}
          />
        }
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  handleFacetChange: bindActionCreators(handleFacetChange, dispatch),
  getProductsData: bindActionCreators(getProductsData, dispatch),
  handlePageChange: bindActionCreators(handlePageChange, dispatch),
  resetRangeChanged: bindActionCreators(resetRangeChanged, dispatch),
  handleSortChange: bindActionCreators(handleSortChange, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(SelectedFilter));
