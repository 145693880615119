import React, { Component } from "react";
import cn from "classnames";
import { client, dataCollector, paypalCheckout } from "braintree-web";
import { connect } from "react-redux";
import get from "lodash/get";
import split from "lodash/split";

import { ENV_CONFIG } from "../../config/env";

class PayPalButton extends Component {
  static defaultProps = {
    color: "black"
  };

  client = null;
  dataCollector = null;
  checkout = null;

  componentDidMount() {
    this.attachBrainTree();
  }

  componentWillUnmount() {
    const { handleButtonRendered } = this.props;

    if (handleButtonRendered) {
      handleButtonRendered(false);
    }
  }

  async attachBrainTree() {
    const { handleButtonRendered, id, color, token, onButtonClick } =
      this.props;

    try {
      this.client = await client.create({
        authorization: token
      });

      this.dataCollector = await dataCollector.create({
        client: this.client,
        paypal: true
      });

      this.checkout = await paypalCheckout.create({
        client: this.client
      });

      if (handleButtonRendered) {
        handleButtonRendered(true);
      }

      await window.paypal.Button.render(
        {
          env: ENV_CONFIG.paypal_env,
          payment: this.payment,
          onAuthorize: this.onAuthorize,
          onCancel: this.onCancel,
          onError: this.onError,
          onClick: onButtonClick,
          style: {
            color,
            shape: "rect",
            label: "paypal",
            size: "responsive",
            height: 43
          }
        },
        `#paypal-button-${id}`
      );
    } catch (error) {
      console.error("Failed to attach Braintree", error);
    }
  }

  payment = () => {
    const { currency, transactionOrderTotal, shippingAddress } = this.props;
    const {
      addressLine1,
      addressLine2,
      city,
      area,
      countryCode,
      firstname,
      lastname,
      phone,
      zipcode,
      station_name
    } = shippingAddress;

    const selectedArea = get(split(area, " "), "0", "NA");
    const recipientName = station_name
      ? `S2S ${station_name}`
      : `${firstname} ${lastname}`;
    const addressesProps = {
      enableShippingAddress: true,
      shippingAddressEditable: false,
      shippingAddressOverride: {
        recipientName,
        ...(addressLine1 && { line1: addressLine1 }),
        ...(addressLine2 && { line2: addressLine2 }),
        ...(zipcode && { postalCode: zipcode }),
        state: selectedArea,
        city,
        phone,
        countryCode
      }
    };

    return this.checkout.createPayment({
      flow: "checkout",
      currency,
      amount: transactionOrderTotal,
      ...addressesProps
    });
  };

  onAuthorize = async data => {
    const { onAuthorize } = this.props;

    const payload = await this.checkout.tokenizePayment(data);

    onAuthorize({
      ...payload,
      device_data: this.dataCollector.deviceData,
      creationDate: Date.now()
    });
  };

  onCancel = data => {
    console.log("checkout.js payment cancelled", JSON.stringify(data, 0, 2));
  };

  onError = err => {
    console.error("checkout.js error", err);
  };

  render() {
    const { id, className, hidden } = this.props;

    return (
      <div
        className={cn(hidden && "d-none", className)}
        id={`paypal-button-${id}`}
      />
    );
  }
}

const mapStateToProps = state => ({
  currency: get(state, "common.settings.currencyCode")
});

export default connect(mapStateToProps)(PayPalButton);
