import React, { useState, useEffect } from "react";
import moment from "moment";
import queryString from "query-string";
import cn from "classnames";
import get from "lodash/get";
import template from "lodash/template";
import ReactPaginate from "react-paginate";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import RecentOrder from "../../components/my-acc/myAcc-recent-order";
import CustomDropdown from "../../components/genericDropDown";
import EmptyCart from "../../components/cart/cart-empty";
import Loader from "../../components/loader";
import * as priceUtil from "../../util/priceUtil";
import { getMonthsValue } from "../../util/my-account";
import { LOADER_TYPES, SHOW_ALL } from "../../constants";
import { StorageService } from "../../services";

const loaderStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "rgb(255 255 255 / 75%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 4
};

const MyOrders = ({
  location,
  fetchMyOrders,
  language,
  history,
  country,
  myAccountTranslation,
  myOrders,
  deliveryOptions,
  noRecordImgUrl,
  handleGoBack,
  isShowLoader
}) => {
  const orderHistoryValue = StorageService.getOrdersHistoryFrom();
  const [orderSummary, setOrderSummary] = useState(orderHistoryValue || "");
  const [filterMonth, setFilterMonth] = useState(orderHistoryValue || "6");
  const { page } = queryString.parse(location.search, {
    parseNumbers: true
  });
  const dropDownData = [
    { key: "1", value: myAccountTranslation.past1month },
    { key: "2", value: myAccountTranslation.past2month },
    { key: "6", value: myAccountTranslation.past6month },
    { key: "12", value: myAccountTranslation.past12month },
    { key: SHOW_ALL, value: myAccountTranslation.showAll }
  ];
  const defaultValue =
    dropDownData.find(item => {
      return orderSummary === item.key;
    }) || dropDownData[dropDownData.length - 3];
  const orders = get(myOrders, "order", []);
  const orderCount = get(myOrders, "order[0].totalRowCount");

  useEffect(() => {
    setTimeout(() => {
      if (page) {
        fetchMyOrders(language, page, getMonthsValue(filterMonth));
      } else {
        history.replace(`${location.pathname}?page=${1}`);
      }
    }, 1000);
  }, [page, filterMonth, language]);

  const handleOrderSummary = key => {
    history.push(`${location.pathname}?page=${1}`);
    setOrderSummary(key);
    setFilterMonth(key);
    StorageService.setOrdersHistoryFrom(key);
  };

  const handlePageClick = e => {
    history.push(`${location.pathname}?page=${e.selected + 1}`);
  };

  const absenceHistoryMessage = () =>
    filterMonth === SHOW_ALL
      ? myAccountTranslation.noOrderDesc
      : `${myAccountTranslation.thereAreNoOrders} ${defaultValue.value}`;
  return (
    <div className="overview-wrapper position-relative">
      <OverViewHeading
        headingName={myAccountTranslation.myOrders}
        icon="my_order_icon"
        handleGoBack={handleGoBack}
      />
      <div className="main_order_container">
        <div className="order_numbering">
          <p className="text">
            {ReactHtmlParser(
              template(myAccountTranslation.showingOrders)({
                orderCount,
                order:
                  orderCount > 1
                    ? myAccountTranslation.orders
                    : myAccountTranslation.order
              })
            )}
          </p>
          <CustomDropdown
            name="orderSummary"
            data={dropDownData}
            defaultSelectValue={defaultValue}
            selectedItem={defaultValue}
            containerClass={"order_summary_dropdown"}
            scrollbarMaxHeight={150}
            handleChange={handleOrderSummary}
          />
        </div>

        {Boolean(orderCount) &&
          orders &&
          orders.map((order, index) => (
            <div key={index} className="recentOrderWeb">
              {(filterMonth !== SHOW_ALL
                ? moment(moment().subtract(filterMonth, "months")._d).isBefore(
                    order.createDate
                  )
                : true) && (
                <RecentOrder
                  accordion={true}
                  orderId={order.orderId}
                  totalAmount={`${
                    order.CurrencyCode
                  } ${priceUtil.shapePriceWithComma(order.transactionAmount)} `}
                  orderPlaced={moment.utc(order.createDate).format("LLL")}
                  estimatedDelivery={
                    order.deliveryDate
                      ? moment.utc(order.deliveryDate).format("LLL")
                      : ""
                  }
                  myAccountTranslation={myAccountTranslation}
                  language={language}
                  order={order}
                  deliveryOptions={deliveryOptions}
                  activePage={page}
                />
              )}
            </div>
          ))}
      </div>

      {!Boolean(orderCount) && !isShowLoader && (
        <div className="main_order_container">
          <EmptyCart
            noRecordDesc={absenceHistoryMessage()}
            language={language}
            noRecordImgUrl={noRecordImgUrl}
            buttonText={myAccountTranslation.startShopping}
            buttonLink={`/${language}-${
              (country && country.toLowerCase()) || "ae"
            }`}
          />
        </div>
      )}

      {isShowLoader && (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={loaderStyle}
        />
      )}

      {orderCount > 5 && (
        <div
          className={cn(
            {
              arabic: language.includes("ar")
            },
            "pagination_wrapper recentOrderWeb"
          )}
        >
          <div className="paginate_display">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={orderCount / 5}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              forcePage={page - 1}
              containerClassName={"pagination"}
              subContainerClassNsame={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(MyOrders);
