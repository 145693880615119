import { pushGaProductClick, createObjForGA } from "../../util/analytics";
import get from "lodash/get";

class CheckoutAnalyticService {
  trackCheckout = trackData => {
    const actionField = get(trackData, "actionField", {});
    const orderItems = get(trackData, "orderItems", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const deliveryType = get(trackData, "deliveryType", "");
    const totalAmount = get(trackData, "totalAmount", 0);
    const { ecommerce, outerObj } = createObjForGA.multipleNetCoreItems(
      orderItems,
      authReducer,
      commonSettings
    );
    const analyticData = {
      ...outerObj,
      deliveryType,
      Total: totalAmount,
      total_amount: totalAmount,
      sub_total: totalAmount
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
  };

  trackCheckoutStepOne = trackData => {
    const actionField = get(trackData, "actionField", {});
    const orderItems = get(trackData, "orderItems", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const deliveryType = get(trackData, "deliveryType", "");
    const totalAmount = get(trackData, "totalAmount", []);

    const { ecommerce, outerObj } = createObjForGA.multipleNetCoreItems(
      orderItems,
      authReducer,
      commonSettings
    );
    const analyticData = {
      ...outerObj,
      deliveryType,
      Total: totalAmount,
      total_amount: totalAmount,
      sub_total: totalAmount
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, actionField);
    pushGaProductClick.pushNTShippingAddress({ ...analyticData });
  };

  trackCheckoutStepTwo = trackData => {
    const orderItems = get(trackData, "orderItems", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const deliveryType = get(trackData, "deliveryType", "");
    const totalAmount = get(trackData, "totalAmount", []);
    const address = get(trackData, "address", "");
    const addressCountry = get(address, "country", "");
    const addressCity = get(address, "city", "");
    const addressArea = get(address, "area", "");
    const addressAddressLine = get(address, "addressLine1", "");
    const myCartTranslation = get(trackData, "myCartTranslation", "");

    const { ecommerce, outerObj } = createObjForGA.multipleNetCoreItems(
      orderItems,
      authReducer,
      commonSettings
    );

    const netCoreOuterObj = {
      ...outerObj,
      country: addressCountry,
      city: addressCity,
      area: addressArea,
      address1: addressAddressLine,
      address2: ""
    };
    const analyticData = {
      ...outerObj,
      deliveryType,
      Total: totalAmount,
      total_amount: totalAmount,
      sub_total: totalAmount
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, {
      step: 2,
      option: get(myCartTranslation, deliveryType)
    });
    pushGaProductClick.pushNTShippingAddress(netCoreOuterObj);
  };

  trackCheckoutStepThree = trackData => {
    const orderItems = get(trackData, "orderItems", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const selectedPayment = get(trackData, "selectedPayment", "");
    const checkoutTranslation = get(trackData, "checkoutTranslation", {});
    const { ecommerce, outerObj } = createObjForGA.multipleItems(
      orderItems,
      authReducer,
      commonSettings
    );
    const analyticData = {
      ...outerObj,
      selectedPayment
    };

    pushGaProductClick.pushGaCheckout(ecommerce, analyticData, {
      step: 3,
      option: selectedPayment?.toString()
    });
  };

  trackApplyVoucher = trackData => {
    const coupenCode = get(trackData, "voucherNumber", "");
    const discount = get(trackData, "discount", "");
    const coupenStatus = get(trackData, "coupenStatus", false);
    pushGaProductClick.pushGa({
      event: "couponRedemption",
      coupon_code: coupenCode,
      coupon_discount_value: discount,
      coupon_status: coupenStatus
    });
  };

  trackAddressGuest = trackData => {
    const guestCustomerId = get(trackData, "guestCustomerId", "");
    const firstName = get(trackData, "address.firstname", "");
    const lastName = get(trackData, "address.lastname", "");
    const countryName = get(trackData, "commonSettings.countryName", "");
    const emailConsent = get(trackData, "order.guestEmailConsent", false);
    const email = get(trackData, "order.guestEmail", "");

    pushGaProductClick.pushGa({
      event: "guest",
      countryName,
      guestDetails: {
        firstName,
        lastName,
        emailConsent,
        email,
        customerId: guestCustomerId
      }
    });
  };

  trackCheckoutStepFlow = ({ step, name }) => {
    pushGaProductClick.pushGa({
      event: "checkoutStepFlow",
      step,
      name
    });
  };
}

export default CheckoutAnalyticService;
