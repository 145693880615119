import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";

import { getProductUrl, getProductUrlName, itemsInCart } from "../../../util";
import {
  selectCdnImageSettings,
  selectCommonSettings,
  selectCurrency,
  selectLanguageSlug
} from "../../../util/selectors";
import { selectCartDataDetails } from "./selectors";

import ContentImage from "../../content/content-image";
import ContentDescription from "../../content/content-description";
import CartSummaryHeading from "./cart-summary-heading";
import ProductSizeInfo from "./product-size-info";

const HEIGHT_WITHOUT_SHOW_MORE_LOGIC = 350;
const TIMEOUT_SCROLL_TIME = 300;

const MobileCheckoutCartSummary = ({ translation, onProductClick }) => {
  const [toggleViewMoreProducts, setToggleViewMoreProducts] = useState(false);
  const [isShowMoreProductsButton, setIsShowViewMoreButton] = useState(true);
  const [viewMoreHeight, setViewMoreHeight] = useState(0);

  const wrapperRef = useRef(null);

  const language = useSelector(selectLanguageSlug);
  const currencyCode = useSelector(selectCurrency);
  const commonSettings = useSelector(selectCommonSettings);
  const configCdnImagesSettings = useSelector(selectCdnImageSettings);
  const cart = useSelector(selectCartDataDetails);
  const itemsInCartQuantity = itemsInCart(cart);
  const toggleViewMoreProductsText = toggleViewMoreProducts
    ? translation.showLessItems
    : translation.showAllItems;

  const productsWrapperHeight = wrapperRef?.current?.clientHeight;

  const scrollToBottom = () =>
    wrapperRef?.current?.scrollIntoView({ behavior: "smooth" });

  const onViewMoreClick = () =>
    setToggleViewMoreProducts(
      toggleViewMoreProducts => !toggleViewMoreProducts
    );

  useEffect(() => {
    if (viewMoreHeight !== productsWrapperHeight) {
      setViewMoreHeight(productsWrapperHeight);
      setIsShowViewMoreButton(
        productsWrapperHeight > HEIGHT_WITHOUT_SHOW_MORE_LOGIC
      );
    }
  }, [productsWrapperHeight, toggleViewMoreProducts]);

  useEffect(() => {
    if (toggleViewMoreProducts) {
      const timeout = setTimeout(() => {
        scrollToBottom();
      }, TIMEOUT_SCROLL_TIME);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [toggleViewMoreProducts]);

  return (
    <div className="cart_hover_dropdown_wrapper mCheckoutSign">
      <CartSummaryHeading
        translation={translation}
        itemsInCartQuantity={itemsInCartQuantity}
      />
      {itemsInCartQuantity && (
        <div
          className={cn("inner_wrapper", {
            expand: toggleViewMoreProducts
          })}
          ref={wrapperRef}
        >
          <div className="items_in_user_cart">
            {cart.map((item, key) => {
              const redirectionItemLink = getProductUrl(item, false);

              return (
                <div key={item.productId + key} className="user_cart_item">
                  <div onClick={onProductClick}>
                    <Link to={redirectionItemLink}>
                      <ContentImage
                        img={item.captionImageURL}
                        url={configCdnImagesSettings?.thumb}
                        showDefaultImage={true}
                      />
                    </Link>
                  </div>
                  <div className="cart_item_content">
                    <ContentDescription
                      item={item}
                      translation={translation}
                      language={language}
                      currencyCode={currencyCode}
                      commonSettings={commonSettings}
                      showEllipses={false}
                      Redirection={true}
                      getProductUrlName={getProductUrlName}
                    />
                    <ProductSizeInfo translation={translation} item={item} />
                  </div>
                </div>
              );
            })}
            {isShowMoreProductsButton && (
              <p className="view_more" onClick={onViewMoreClick}>
                <span>{toggleViewMoreProductsText}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileCheckoutCartSummary;
