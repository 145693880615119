import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { isMobile } from "../../util";
import { changeLanguage } from "../../redux/actions/common.action";
import { contactUs } from "../../language/contactUs/en/contactUs";
import CustomDropdown from "../../components/genericDropDown";
import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

class ContactUs extends React.Component {
  state = {
    translation: contactUs
  };
  getlanguage = language => {
    const languageResponse = import(
      `../../language/contactUs/${language}/contactUs`
    );
    languageResponse.then(response => {
      this.setState({
        translation: response.contactUs
      });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
    }
  }

  UNSAFE_componentWillMount() {
    const { currentLanguage, handleLanguageChange } = this.props;

    handleLanguageChange(currentLanguage);
    this.getlanguage(currentLanguage);
  }

  render() {
    const { translation } = this.state;
    const { language } = this.props;
    const isMob = !!(isMobile.any() && isMobile.any().length);
    return (
      <Fragment>
        {isMob ? (
          <div
            className={`contact_us_top_banner ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <h1 className="banner_heading"> {translation.conatctUs} </h1>
            <p>
              {translation.howCanWeHelpYou}?{translation.wedLoveToHearFromYou}
            </p>
          </div>
        ) : null}
        <div
          className={`contact_us_container ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          {!isMob ? (
            <h3 className="contact_us_heading"> {translation.conatctUs} </h3>
          ) : (
            <h3 className="contact_us_heading">
              {" "}
              {translation.sendUsMessage}{" "}
            </h3>
          )}
          <div className="contact_us_wrapper">
            <form className="user_input_section">
              <div className="form-group">
                <input
                  type="text"
                  placeholder={translation.yourName}
                  className="form-control"
                  aria-label="Your name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder={translation.emailID}
                  className="form-control"
                  aria-label="Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  placeholder={translation.phoneNumber}
                  type="text"
                  className="form-control"
                  aria-label="Phone number"
                  required
                />
              </div>
              <div className="form-group">
                <CustomDropdown
                  defaultSelectValue={
                    translation && translation.yourConcernRelatedTo
                  }
                  containerClass={"withBorder"}
                  scrollbarMaxHeight={150}
                />
              </div>
              <textarea placeholder={translation.yourMessage} rows="8" />
              <button className="round_btn">{translation.sendNow}</button>
            </form>
            <div className="detail_section_wrapper">
              <div className="detail_section">
                <h4>{translation.address}</h4>
                <address>
                  {translation.brandsForLessLLC},<br />
                  {translation.pOBoxNo80794Dubai}
                </address>
                <h4 className="phone_heading">{translation.phone}</h4>
                <p className="phone_number">+971 4 880 6768</p>
                <h4 className="email_heading">{translation.email}</h4>
                <p className="email">customercare-bflgroup-ae</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch)
});

export default compose(
  withRouter,
  withLanguageFromUrl,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(ContactUs);
