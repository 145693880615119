import React from "react";
import ModalBFL from "../modal/modal";
import congratulationImg from "../../images/congratulation.svg";

class ThanksForSubscribingModal extends React.Component {
  render() {
    const { openSubsModal, closeModal, message, messageHeading } = this.props;
    return (
      <div>
        <ModalBFL
          overlayClassName="thankYouSubsOverlay"
          showModal={openSubsModal}
          modalClass={`subscriptionModal`}
        >
          <div className="close-btn">
            <span onClick={closeModal} className="cross-icon" />
          </div>
          <div className="thankYouSubscribe_modal">
            <img src={congratulationImg} alt="congrats" />
            <span className="modal-text">{messageHeading}</span>
            <p>{message}</p>
          </div>
        </ModalBFL>
      </div>
    );
  }
}

export default ThanksForSubscribingModal;
