import React, { Component } from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import {
  getPageApi,
  clearRedirectionData
} from "../../../redux/actions/page.action";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../../constants";
import AccordionTile from "../../../routes/static-html-pages/accordion-tile";
import { TabContent, TabPane, NavItem, NavLink } from "reactstrap";
import DeliveredTo from "../delivered-to";
import ProductDeliveryInfo from "../../product/product-delivery-info";
import withLanguageFromUrl from "../../../hocs/withLanguageFromUrl";

class DeliveryAndReturn extends Component {
  state = { activeTab: "0" };
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  componentDidMount() {
    const { getPageApi, countryId, storeId, settings, currentLanguage } =
      this.props;
    const language =
      currentLanguage === LANGUAGE_ROUTE_KEY.arabic
        ? LANGUAGE.arabic
        : LANGUAGE.english;
    countryId &&
      getPageApi("shipping-delivery", language, countryId, storeId || "");
    if (!countryId && settings) {
      settings.countryId &&
        getPageApi(
          "shipping-delivery",
          language,
          settings.countryId,
          storeId || ""
        );
    }
  }
  componentWillUnmount() {
    this.props.clearApiData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getPageApi, countryId, match, settings, storeId, location } =
      this.props;
    const { currentLanguage } = nextProps;
    let pageName = nextProps.match.url.toLowerCase();
    pageName = pageName + location.search;
    pageName = pageName.replace("/en/", "");
    pageName = pageName.replace("/ar/", "");
    const lang =
      currentLanguage === LANGUAGE_ROUTE_KEY.arabic
        ? LANGUAGE.arabic
        : LANGUAGE.english;

    if (
      nextProps.match.url !== match.url ||
      nextProps.countryId !== countryId
    ) {
      nextProps.countryId &&
        getPageApi(
          pageName,
          lang,
          nextProps.countryId,
          nextProps.storeId || ""
        );
    } else if (nextProps.settings) {
      if (
        !countryId &&
        get(nextProps, "settings.countryId") !==
          (settings && settings.countryId) &&
        nextProps.storeId !== storeId
      ) {
        get(nextProps, "settings.countryId") &&
          getPageApi(
            pageName,
            lang,
            nextProps.settings.countryId,
            nextProps.storeId || ""
          );
      }
    }
  }
  render() {
    const {
      redirectionData,
      closeDeliveryReturnModal,
      translation,
      modalData,
      commonSettings,
      showCheckBox
    } = this.props;

    return (
      <div
        className={`slideRight deliveryReturn back ${classnames({
          back: this.state.activeTab === "1"
        })}`}
      >
        <h3>
          {translation.deliveryAndReturn}
          <span
            className={`${classnames({
              crossAgain: this.state.activeTab === "0"
            })}`}
            onClick={() => closeDeliveryReturnModal(false)}
          />
          {this.state.activeTab === "1" && (
            <span
              className="crossBack "
              onClick={() => {
                this.toggle("0");
              }}
            />
          )}
        </h3>
        <div className="deliveryReturnTabs">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "0" })}
              onClick={() => {
                this.toggle("0");
              }}
            >
              {translation.delivery}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              {translation.returns}
            </NavLink>
          </NavItem>
        </div>
        <div className="deliveryReturnTabContent">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane className="deliveryContent" tabId="0">
              <ProductDeliveryInfo
                modalData={modalData}
                translation={translation}
                commonSettings={commonSettings}
                showCheckBox={showCheckBox}
              />
            </TabPane>
            <TabPane className="returnContent" tabId="1">
              <DeliveredTo commonSettings={commonSettings} />
              {redirectionData &&
              redirectionData.data &&
              redirectionData.data.length &&
              redirectionData.data[1].content.accord &&
              redirectionData.data[1].content.accord.length ? (
                <div>
                  {redirectionData &&
                    redirectionData.data &&
                    redirectionData.data.length &&
                    redirectionData.data[1].content.accord.map(
                      (item, index) => (
                        <AccordionTile
                          item={item}
                          key={"_accordion_" + index}
                          accordionHeading={
                            +this.state.activeTab
                              ? translation.returns
                              : translation.delivery
                          }
                        />
                      )
                    )}
                </div>
              ) : null}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  redirectionData: state.page.redirectionData,
  countryId: state.page.homepageState.countryId,
  storeId: state.page.homepageState.storeId,
  settings: state.common.settings,
  language: state.common.language
});

const mapStateToDispatch = dispatch => ({
  getPageApi(pageName, language, countryId, storeId) {
    dispatch(getPageApi(pageName, language, countryId, storeId));
  },
  clearApiData(pageName, language) {
    dispatch(clearRedirectionData(pageName, language));
  }
});

export default compose(
  connect(mapStateToProps, mapStateToDispatch),
  withRouter,
  withLanguageFromUrl
)(DeliveryAndReturn);
