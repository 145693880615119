import React from "react";
import get from "lodash/get";
import { GAService } from "../../services/GA-service";
import ConnectWithUsWeb from "../../components/footer/connect-with-us-web";
import contactPhone from "../../images/contact-phone.svg";
import contactEmail from "../../images/contact-email.svg";
import contactWhatsapp from "../../images/contact-whatsapp.svg";
import { Image } from "../../components/image";
import {
  WHATSAPP_NUMBER,
  WHATSAPP_LINK,
  WHATSAPP_NAME
} from "../../constants/common";

const FooterContact = props => {
  const { staticContent, footerTranslation } = props;
  const csNumber = get(staticContent, "csNumber", "");
  const csTime = get(staticContent, "csTime", "");
  const csEmail = get(staticContent, "csEmail", "");
  const EMAIL = "email";

  const handleSocialClick = social_domain => {
    GAService.common.trackSocialClicks({ social_domain });
  };

  return (
    <section className="footer-contact">
      <div className="container">
        <div className="row footer-contact-wrap">
          <div className="first-column column">
            <a href={`tel:${csNumber}`}>
              <Image src={contactPhone} className="contactPhone" />
              <p className="direction-ltr contact-phone">{csNumber}</p>
              <p className="contact-timing">{csTime} </p>
            </a>
          </div>
          <div className="second-column column">
            <p>
              <a
                className="contact-email"
                href={`mailto:${csEmail}`}
                onClick={() => handleSocialClick(EMAIL)}
              >
                <Image src={contactEmail} className="contactEmail" />
                <span className="email" /> {csEmail}
              </a>
            </p>
          </div>
          <div className="third-column column">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={WHATSAPP_LINK}
              onClick={() => handleSocialClick(WHATSAPP_NAME)}
            >
              <Image src={contactWhatsapp} className="contactWhatsapp" />
              <p className="phone direction-ltr">{WHATSAPP_NUMBER}</p>
              <p className="message">({footerTranslation.reachUsOnWhatsapp})</p>
            </a>
          </div>
          <div className="fourth-column column">
            <ConnectWithUsWeb staticContent={staticContent} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterContact;
