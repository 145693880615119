import get from "lodash/get";
import { fetchWebApi, postDashboardWebApi } from "../../webapis/apiResource";
import { GET_FOOTER } from "../constants";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { getPrevModeStorage } from "../../util/browserStorage";
import AnalyticService from "../../services/analytic-service";
import {
  SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  RESET_NEWS_LETTER_SUBSCRIPTION_DATA
} from "../constants/footer";
import { GAService } from "../../services/GA-service";

export const getFooter = ({ result }) => ({ type: GET_FOOTER, result });

export const setNewsletterSubscriptionData = newsletter => ({
  type: SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  newsletter
});

export const resetNewsletterSubscriptionData = () => ({
  type: RESET_NEWS_LETTER_SUBSCRIPTION_DATA
});

export const getFooterData = (language, countryId) => {
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.footerHandle}/?countryId=${countryId}&language=${language}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      null,
      apiURL,
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.data && response.data.code === 200) {
          dispatch(getFooter({ ...response.data.data }));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const thankYouSubscribingModal = payload => {
  const apiURL = `${BEConfig.subscribeNowApi.protocol}${BEConfig.subscribeNowApi.baseURL}/${BEConfig.subscribeNowApi.subscribeNowHandle}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(null, apiURL, payload, null, null, true)
      .then(response => {
        dispatch(hideLoadingIndicator());
        return true;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return false;
      });
  };
};

export const fetchSubscribeEmail = bodyData => async (dispatch, getState) => {
  const {
    customerApi: { baseURL, protocol, port, versionInfo, subscribeEmailHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${subscribeEmailHandle}`;
  dispatch(showLoadingIndicator());
  const { common } = getState();
  const { countryCode } = common.settings;
  try {
    const response = await postDashboardWebApi(
      null,
      apiURL,
      bodyData,
      null,
      null,
      false
    );
    if (response.status === 200 && response.data) {
      const customerId = get(response, "data.customerId", "");
      const userType = get(response, "data.userType", "");
      const email = get(bodyData, "email", "");
      const newsletterSubscription = {
        email,
        countryCode,
        countryName: countryCode,
        customerId,
        userType
      };
      dispatch(setNewsletterSubscriptionData(newsletterSubscription));
      AnalyticService.common.trackNewsletterSubscription(
        newsletterSubscription
      );
      GAService.common.trackNewsletterSubscription();
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(hideLoadingIndicator());
  }
};
