import { changeLanguage } from "../../redux/actions/common.action";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;
      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  }
};
