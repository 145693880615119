import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { COUNTRY_SELECTION_MAP } from "../../constants";
import { Image } from "../image";

import CartDeliveryType from "../cart/cart-deliveryType";
import ApplyCoupons from "./applePay-couponsVoucher";
class ApplePay extends Component {
  state = {
    applyCoupon: false
  };
  handleApplyCoupon = value => {
    this.setState({
      applyCoupon: value
    });
  };
  render() {
    const { commonSettings, handleApplePay, translation } = this.props;
    const { applyCoupon } = this.state;
    return (
      <>
        <div className="ApplePay">
          <h4 className="slideTitle">
            <i className="icon_cross" onClick={() => handleApplePay(false)} />
            <span>{translation && translation.applePay}</span>
          </h4>
          <div className="applePay_card_wrapper">
            <div className="applePay_card selectedCountry">
              <span className="floatedTxt">
                {translation && translation.deliverTo}
              </span>
              <div>
                <Image
                  className="country_flag_img"
                  src={
                    COUNTRY_SELECTION_MAP[
                      commonSettings && commonSettings.countryCode
                    ]
                  }
                />
                <span>{commonSettings && commonSettings.countryName}</span>
              </div>
            </div>
          </div>
          <div className="applePay_card_wrapper">
            <div
              className="applePay_card"
              onClick={() => this.handleApplyCoupon(true)}
            >
              <p>{translation && translation.creditCardDiscountVoucher}</p>
              <i className="applePay_icon_rightArrow" />
            </div>
          </div>
          <div className="applePayDeliveryType_wrapper">
            <div className="applePayDeliveryType">
              <p className="title">
                {translation && translation.deliveryOption}
              </p>
              <CartDeliveryType commonSettings={commonSettings} />
            </div>
          </div>
          <button className="applePay_btn">Buy with Pay</button>
        </div>
        {applyCoupon && (
          <ApplyCoupons
            handleApplyCoupon={this.handleApplyCoupon}
            translation={translation}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  commonSettings: state.common.settings
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ApplePay)
);
