import React from "react";
import Html from "../size/size-chart-popupHtml";
import SizeChartPopup from "../size/size-chart-popup";

const SizeChart = ({
  activeTab,
  handleSizeChartPopup,
  isBrandType,
  isSizeChart,
  isSizeChartPopupActive,
  sizeChart
}) => (
  <SizeChartPopup
    active={isSizeChartPopupActive}
    activeTab={activeTab}
    handlePopup={handleSizeChartPopup}
    popupTitle={isSizeChart && sizeChart[0].heading}
  >
    {isBrandType
      ? !!sizeChart.length &&
        isSizeChart &&
        sizeChart[0].list.map(item => (
          <Html
            key={item.heading}
            title={`${item.heading} ${
              !!sizeChart[0].subCategoryName
                ? `- ${sizeChart[0].subCategoryName}`
                : ""
            }`}
            html={item.html}
          />
        ))
      : !!sizeChart.length &&
        isSizeChart &&
        sizeChart[0] && (
          <Html
            title={`${sizeChart[0].brandName} ${
              !!sizeChart[0].subCategoryName
                ? `- ${sizeChart[0].subCategoryName}`
                : ""
            }`}
            html={sizeChart[0].html}
          />
        )}
  </SizeChartPopup>
);

export default SizeChart;
