import {
  GET_FOOTER,
  SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  RESET_NEWS_LETTER_SUBSCRIPTION_DATA
} from "../constants/footer";

const initialState = {
  footerList: [],
  staticContent: {},
  newsletterSubscription: {
    email: ""
  }
};

const getFooter = (state, { result: { footerList, staticContent } }) => ({
  ...state,
  footerList,
  staticContent
});

const setNewsletterSubscription = (state, action) => {
  return {
    ...state,
    newsletterSubscription: action.newsletter
  };
};

const resetNewsletterSubscription = (state, action) => {
  return {
    ...state,
    newsletterSubscription: initialState.newsletterSubscription
  };
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOOTER:
      return getFooter(state, action);
    case SET_NEWS_LETTER_SUBSCRIPTION_DATA:
      return setNewsletterSubscription(state, action);
    case RESET_NEWS_LETTER_SUBSCRIPTION_DATA:
      return resetNewsletterSubscription(state, action);
    default:
      return state;
  }
};
export default footerReducer;
