import React, { useEffect, useRef, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import map from "lodash/map";
import forEach from "lodash/forEach";
import MapAreaDescription from "./map_area_description";
import { ENV_CONFIG } from "../../../config/env";
import { getWindowOrientation } from "../../../util";

let orientation;

const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  forEach(places, place => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      const newOrientation = getWindowOrientation();
      if (orientation !== newOrientation) {
        orientation = newOrientation;
        map.fitBounds(bounds);
      }
    });
  });
};

const recalculateFitting = (map, bounds) => {
  // Fit map to bounds
  map.fitBounds(bounds);

  if (map.getZoom() > 14) {
    map.setZoom(14);
  }
};

const GoogleMap = props => {
  const {
    stores,
    selectedStore,
    lat,
    lng,
    zoom,
    language,
    autoCompleteInput,
    saveMapAddress,
    mapContainerHeight,
    translation,
    customIcon = false,
    onMapBoundsMove,
    recalculationTrigger,
    activeCollectionPoint,
    setActiveCollectionPoint
  } = props;

  const mapInstanceRef = useRef(null);

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = useCallback((map, maps, places) => {
    const bounds = getMapBounds(map, maps, places);
    recalculateFitting(map, bounds);

    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
    if (!mapInstanceRef.current) {
      mapInstanceRef.current = { map, maps };
    }
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      const { map, maps } = mapInstanceRef.current;
      if (!stores?.length) return;
      const bounds = getMapBounds(map, maps, stores);
      recalculateFitting(map, bounds);
    }
  }, [recalculationTrigger]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: mapContainerHeight || "500px", width: "100%" }}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: ENV_CONFIG.googleMap, language: language }}
        center={{ lat, lng }}
        defaultZoom={zoom || 150}
        onGoogleApiLoaded={({ map, maps }) =>
          lat === 0 &&
          lng === 0 &&
          apiIsLoaded(map, maps, stores, autoCompleteInput)
        }
        onChange={onMapBoundsMove}
        options={{ gestureHandling: "greedy" }}
      >
        {map(stores, (place, index) => {
          return (
            <MapAreaDescription
              key={index}
              showCustomIcon={customIcon}
              lat={place.lat}
              lng={place.lng}
              selected={
                selectedStore &&
                (place.collectionPointId === selectedStore.collectionPointId ||
                  (selectedStore.station_id &&
                    place.station_id === selectedStore.station_id))
              }
              address={place.address_info}
              name={place.station_name}
              phone={place.phone}
              extraInfo={place.extra_info}
              place={place}
              saveMapAddress={saveMapAddress}
              mapIconURL={place.mapIconURL}
              translation={translation}
              workingHours={place.workingHours}
              activeCollectionPoint={activeCollectionPoint}
              setActiveCollectionPoint={setActiveCollectionPoint}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
