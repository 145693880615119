import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import get from "lodash/get";
import Swiper from "react-id-swiper";
import { selectLanguage, selectRouteSlug } from "../../../util/selectors";
import { Link } from "react-router-dom";
import loaderIcon from "../../../images/loading-icon.svg";

import {
  BULLET_TYPES,
  LANGUAGE,
  BANNER_ROW_TYPE,
  CONTROLLER_TYPES
} from "../../../constants";
import { isMobile, isServer } from "../../../util";
import DynamicVideo from "../dynamic-video";
import { getRedirectionUrl, isLinkStartsFromHTTPS } from "../../../util/common";
import BannerLoaderImage from "../../banner/banner-loader-image";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";

const getElement = (el, className) =>
  !isServer && el && el.querySelector(className);

export const DynamicSlider = ({
  section,
  bannerModuleName,
  bannerPageTitle
}) => {
  const language = useSelector(state => selectLanguage(state));
  const routeSlug = useSelector(selectRouteSlug);
  const [imageLoading, setImageLoading] = useState(true);
  const imageLinkRef = useRef();
  const sliderRef = useRef(null);
  const { controllerStyles, indicatorStyles } = section;

  const activeBullet =
    !isServer &&
    sliderRef?.current?.querySelector(`.slider-bullet-active${section.id}`);

  const sliders = get(section, "imageList", []);

  const swiperAutoPlay = {
    autoplay: {
      delay: section.autoPlay
    }
  };

  const setActiveBulletColor = () => {
    const activeBullet =
      !isServer &&
      sliderRef?.current?.querySelector(`.slider-bullet-active${section.id}`);

    if (activeBullet) {
      activeBullet.style.background = indicatorStyles.indicatorColor;
    }
  };

  useEffect(() => {
    onSlideChange();

    if (section.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  }, [activeBullet, sliders.length]);

  const setControllerStyles = () => {
    const nextButton = getElement(imageLinkRef.current, ".swiper-button-next");
    const prevButton = getElement(imageLinkRef.current, ".swiper-button-prev");

    if (nextButton && prevButton && controllerStyles) {
      nextButton.style.background = controllerStyles.backgroundControllerColor;
      prevButton.style.background = controllerStyles.backgroundControllerColor;
    }
  };

  const onSlideChange = () => {
    const allBullets =
      !isServer &&
      sliderRef?.current?.querySelectorAll(`.slider-bullet-${section.id}`);

    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background = indicatorStyles.backgroundIndicatorColor;
      });
      setActiveBulletColor();
    }
  };

  const paginationForSwiper = {
    pagination: {
      el: ".swiper-pagination",
      renderBullet: (index, className) => {
        return `<span class='${className} ${section.indicatorType} slider-bullet-${section.id}'></span>`;
      },
      bulletActiveClass: `swiper-pagination-bullet-active slider-bullet-active${section.id}`,
      clickable: true
    },
    on: {
      slideChange: onSlideChange
    }
  };

  const navigation = {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  };

  const swiperParams = {
    ...(section.controllerType !== CONTROLLER_TYPES.NONE &&
      !isMobile.any() && { navigation }),
    ...(section.autoPlay && section.autoPlay !== "none" && swiperAutoPlay),
    ...(section.indicatorType &&
      section.indicatorType !== BULLET_TYPES.NONE &&
      paginationForSwiper),
    lazy: {
      loadPrevNext: true,
      enabled: true
    }
  };

  const languageSpecifiedData = sliders.filter(el =>
    el.hasOwnProperty(language)
  );

  const slidersData = isMobile.any()
    ? sliders.filter(el => el[language]?.enabledOn?.includes("resp"))
    : sliders;

  const isNotAvailable =
    isMobile.any() && !section?.enabledOn?.includes("resp");

  if (!languageSpecifiedData.length || !slidersData.length || isNotAvailable) {
    return null;
  }

  const sectionStyle = {
    ...section.styles,
    maxWidth: section.width?.value || "100%",
    margin: "0 auto"
  };

  const firstSlider = sliders[0][language];

  return (
    <div
      style={{ maxWidth: firstSlider.width }}
      className="slider-wrapper"
      ref={sliderRef}
    >
      <div
        className="dynamic-slider-module"
        ref={imageLinkRef}
        style={sectionStyle}
      >
        <div
          style={{
            paddingBottom: `${firstSlider.ratio}%`,
            position: "relative"
          }}
        >
          <Swiper
            {...swiperParams}
            loop={slidersData.length > 1}
            rtl={language === LANGUAGE.arabic ? "rtl" : ""}
          >
            {slidersData.map((slider, index) => {
              const sliderData = slider[language];
              const redirectionLink = sliderData.redirectionLink;
              const isExternalLink =
                redirectionLink && isLinkStartsFromHTTPS(redirectionLink);
              const redirectionPath = isExternalLink
                ? redirectionLink
                : `/${routeSlug}${redirectionLink}`;
              const linkTarget = isExternalLink ? "_blank" : "_self";

              const dataForAnalytic = {
                bannerModuleName: bannerModuleName?.toUpperCase(),
                bannerRowName: section.title?.toUpperCase(),
                index: index + 1,
                imageUrl: sliderData.imageURL || sliderData.videoUrl,
                pageName: bannerPageTitle,
                imageListData: slider
              };

              if (slider.type === BANNER_ROW_TYPE.VIDEO) {
                return (
                  <div key={index} className={cn("slider-wrapper")}>
                    <DynamicVideo
                      styles={slider.styles}
                      data={sliderData}
                      linkTarget={linkTarget}
                      redirectionPath={redirectionPath}
                      index={index}
                      dataForAnalytic={dataForAnalytic}
                    />
                  </div>
                );
              }

              return (
                <div key={index} className={cn("slider-wrapper")}>
                  <Link
                    to={getRedirectionUrl(isExternalLink, redirectionPath)}
                    target={linkTarget}
                    className={cn("link-wrapper", {
                      "disabled-link": !redirectionLink
                    })}
                    data-banner-link={redirectionLink}
                    data-banner-index={index}
                  >
                    {imageLoading && (
                      <BannerLoaderImage loaderIcon={loaderIcon} />
                    )}
                    <BannerIntersectionObserver data={dataForAnalytic}>
                      <img
                        src={sliderData.imageURL}
                        alt="banner-image"
                        className="banner-image"
                        loading="lazy"
                        onLoad={() => {
                          setImageLoading(false);
                          imageLinkRef.current.style.background = "none";
                        }}
                      />
                    </BannerIntersectionObserver>
                  </Link>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
