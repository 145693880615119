import React from "react";
import find from "lodash/find";
import { useSelector } from "react-redux";
import { selectCustomerId, selectLanguageSlug } from "../../../util/selectors";

const DynamicGamification = ({ bannerData, generalData }) => {
  const src = (find(bannerData, { key: "iframeURL" }) || {}).value;

  const languageSlug = useSelector(selectLanguageSlug);
  const customerId = useSelector(selectCustomerId);

  const modifiedSrc = `${src}?lang=${languageSlug}&userId=${customerId}`;
  const styles = {
    ...generalData.styles,
    maxWidth: generalData.width?.value || "100%",
    margin: `${generalData.styles.marginTop}px auto ${generalData.styles.marginBottom}px`,
    height: "90vh"
  };

  return (
    <div className="gamification_wrapper" style={styles}>
      <iframe
        width="100%"
        height="100%"
        src={modifiedSrc}
        style={{ border: "none", overflow: "none" }}
      />
    </div>
  );
};

export default DynamicGamification;
