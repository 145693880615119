import React from "react";
import { useSelector } from "react-redux";
import * as priceUtil from "../../../util/priceUtil";
import { selectTotalCartPrice } from "./selectors";
import { selectCurrency } from "../../../util/selectors";
import { selectCommonSettingsDetails } from "../mobile-order-payment-summary/selectors";
import { DEFAULT_ITEM_QUANTITY } from "../../../constants";

const CartSummaryHeading = ({ translation, itemsInCartQuantity }) => {
  const totalCartPrice = useSelector(selectTotalCartPrice);
  const currencyCode = useSelector(selectCurrency);
  const { decimalPlaces } = useSelector(selectCommonSettingsDetails);

  const itemsQuantityText =
    itemsInCartQuantity <= DEFAULT_ITEM_QUANTITY
      ? translation.item
      : translation.items;

  const totalPrice =
    totalCartPrice &&
    priceUtil.shapePriceWithComma(totalCartPrice, decimalPlaces);

  return (
    <h4 className="cart_dropdown_heading">
      {itemsInCartQuantity} {itemsQuantityText}: <b>{translation.totalResp} </b>
      {translation.excludingDelivery}{" "}
      <span className="total">
        {currencyCode} {totalPrice}
      </span>
    </h4>
  );
};

export default CartSummaryHeading;
