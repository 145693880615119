import React, { useState, useEffect } from "react";
import cn from "classnames";
import filter from "lodash/filter";
import { compose } from "redux";
import { connect } from "react-redux";
import { withClientOnlyRender } from "../../hocs";
import { ReactComponent as InstallmentsIcon } from "../../images/interest-free-installments-mobile.svg";
import { isTouchScreen } from "../../util";
import { convertWithComma, shapePriceWithComma } from "../../util/priceUtil";
import { PostPayService } from "../../services";
import PriceSnippet from "./PriceSnippet/PriceSnippet.js";
import BwPostPayIcon from "../../images/payment/payment_modes/POSTPAY_BLACK_NEW.svg";
import BwTabbyIcon from "../../images/payment/payment_modes/TABBY_BLACK.svg";
import colorizedTabbyIcon from "../../images/payment/payment_modes/TABBY_COLOR.svg";
import colorizedPosPayIcon from "../../images/payment/payment_modes/POSTPAY_WHITE.svg";
import tamaraIcon from "../../images/payment/payment_modes/TAMARA_LOGO.svg";
import { PAYMENT_MODES } from "../../constants";
import { TamaraInfoModal } from "../tamara/tamaraInfoModal";
import { TabbyInfoModal } from "../tabby/tabby-info-modal";
import { selectLanguage } from "../../util/selectors";

const Accordion = ({ translation, children }) => {
  const [accordionOpened, setAccordionOpened] = useState(false);
  return (
    <div
      className={cn("installments-container", {
        "expanded-content": accordionOpened
      })}
    >
      <div
        className="d-flex installments-header"
        onClick={() => setAccordionOpened(!accordionOpened)}
      >
        <InstallmentsIcon className="installments-icon" />
        <h4 className="installments-title">
          {translation.interestFreeInstallments}
        </h4>
      </div>
      <div className="installments-content">{children}</div>
    </div>
  );
};

const InterestFreeInstallmentsSection = ({
  item,
  translation,
  isTabbySupported,
  isPostPaySupported,
  isTamaraSupported,
  commonSettings,
  language
}) => {
  const [isTouch, setIsTouch] = useState(isTouchScreen());

  const tabbyPayLater = filter(commonSettings.deliverySettings, {
    paymentType: PAYMENT_MODES.TABBY_PAY_INSTALLMENT
  });

  const postPayPayLater = filter(commonSettings.deliverySettings, {
    paymentType: PAYMENT_MODES.POSTPAY
  });

  const tamaraSettings = filter(commonSettings.deliverySettings, {
    paymentType: PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS
  });

  useEffect(() => {
    const resizeHandler = () => {
      setIsTouch(isTouchScreen());
    };
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  if (![isTabbySupported, isPostPaySupported, isTamaraSupported].some(Boolean))
    return null;

  const CommonWrapperWeb = props => (
    <div className="d-flex mt-4 " children={props.children} />
  );
  const CommonWrapperMobile = props => (
    <div className={cn("installments-container expanded-content")}>
      <div className="installments-content" children={props.children} />
    </div>
  );
  const InstallmentsWrapper = isTouch ? CommonWrapperMobile : CommonWrapperWeb;

  const cartPrice = shapePriceWithComma(
    item.transactionPrice,
    commonSettings.decimalPlaces
  );
  const normalPrice = convertWithComma(
    item.currentPrice || item.price || 0,
    commonSettings
  );

  const price = 100 * (item.transactionPrice ? cartPrice : normalPrice);
  const priceForTabbySnippet = Number(normalPrice) || item.currentPrice;

  return (
    <InstallmentsWrapper translation={translation}>
      {isTabbySupported && (
        <PriceSnippet
          paymentSystemIcon={isTouch ? colorizedTabbyIcon : BwTabbyIcon}
          product={item}
          translation={translation.tabbyPriceSnippet}
          minAmountForPayment={tabbyPayLater[0].minAmountForPayment}
          price={price}
          normalPrice={priceForTabbySnippet}
          paymentMode={PAYMENT_MODES.TABBY}
          ModalComponent={TabbyInfoModal}
        />
      )}

      {isPostPaySupported && (
        <PriceSnippet
          paymentSystemIcon={isTouch ? colorizedPosPayIcon : BwPostPayIcon}
          product={item}
          translation={translation.postPayPriceSnippet}
          paymentService={PostPayService}
          minAmountForPayment={postPayPayLater[0].minAmountForPayment}
          price={price}
          normalPrice={normalPrice}
          paymentMode={PAYMENT_MODES.POSTPAY}
        />
      )}

      {isTamaraSupported && (
        <PriceSnippet
          paymentSystemIcon={tamaraIcon}
          product={item}
          translation={translation.tamaraPriceSnippet}
          minAmountForPayment={tamaraSettings[0]?.minAmountForPayment}
          price={price}
          normalPrice={normalPrice}
          paymentMode={PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS}
          ModalComponent={TamaraInfoModal}
          payIconClassName={
            !isTouch ? (language.includes("ar") ? "ml-3" : "mr-3") : ""
          }
        />
      )}
    </InstallmentsWrapper>
  );
};

const mapStateToProps = state => ({
  commonSettings: state.common.settings,
  language: selectLanguage(state)
});

export default compose(
  withClientOnlyRender,
  connect(mapStateToProps)
)(InterestFreeInstallmentsSection);
