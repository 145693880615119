import React, { Component, Fragment } from "react";
import { getTrabsformedSubfacet } from "../../../util/itemsFilter";
import {
  getColorHex,
  checkUrlSearchMultiCategory,
  isArray
} from "../../../util";
import { withRouter } from "react-router-dom";

const mergeQuickFiltersSubFacet = filterContent => {
  const mergedFilterContent = {};
  filterContent.forEach(item => {
    const subFacetTitle = getTrabsformedSubfacet(item.subFacet);
    if (mergedFilterContent[subFacetTitle]) {
      mergedFilterContent[subFacetTitle] = {
        ...mergedFilterContent[subFacetTitle],
        subFacet: [].concat(
          mergedFilterContent[subFacetTitle].subFacet,
          item.subFacet
        )
      };
    } else {
      mergedFilterContent[subFacetTitle] = item;
    }
  });
  return Object.values(mergedFilterContent);
};

class SelectedFilterContent extends Component {
  render() {
    const {
      filterContent,
      clearFacet,
      clearAll,
      translation,
      language,
      colors,
      location
    } = this.props;
    const isQuickFilterData = checkUrlSearchMultiCategory(location.search);
    const _arr = (isQuickFilterData
      ? mergeQuickFiltersSubFacet(filterContent)
      : filterContent
    ).map((item, index) => {
      const subFacet = isArray(item.subFacet)
        ? item.subFacet[0]
        : item.subFacet;
      const isColorFilter = item.facet === "attributes.Color Family";
      const colorHex = getColorHex(colors, subFacet, language);

      return (
        <li className="filter-container" key={item.facet + index}>
          {isColorFilter && (
            <span
              className="content-color"
              style={{ backgroundColor: colorHex ? `${colorHex}` : "none" }}
            />
          )}
          <span className="filter-content">
            {getTrabsformedSubfacet(subFacet)}
          </span>
          <span
            className="cross-icon"
            id={`${item.facet}:${subFacet}`}
            onClick={e =>
              clearFacet(e, isArray(item.subFacet) && item.subFacet)
            }
          />
        </li>
      );
    });
    return (
      <Fragment>
        {filterContent &&
          !!filterContent.length && (
            <div className="selected_filter">
              <div
                className={`parent-filter-container container padding0 position_relative ${
                  language.includes("ar-") || language.includes("ar")
                    ? "arabic"
                    : ""
                }`}
              >
                <div className="row selected_filter_container">
                  <ul>{_arr}</ul>
                  {!!_arr.length && (
                    <div
                      className="filter-container  clear-all-btn"
                      onClick={clearAll}
                    >
                      <span className="filter-content">
                        {translation && translation.clearALL}
                      </span>
                      <span className="cross-icon" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}

export default withRouter(SelectedFilterContent);
