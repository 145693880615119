import React, { Component } from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

import { CheckoutComService } from "../../../../services";

import creditCardIcon from "../../../../images/payment/cards/credit-card.svg";
import visaIcon from "../../../../images/payment/cards/Visa.svg";
import masterCardIcon from "../../../../images/payment/cards/MasterCard.svg";

class Form extends Component {
  static defaultFormConfig = {
    publicKey: CheckoutComService.getPublicKey(),
    localization: {
      expiryMonthPlaceholder: "MM",
      expiryYearPlaceholder: "YY",
      cvvPlaceholder: "• • •",
      cardNumberPlaceholder: "• • • •  • • • •  • • • •  • • • •",
      textAlign: "center"
    }
  };

  state = {
    paymentMethod: "default"
  };

  onPaymentMethodChange = event => {
    const { paymentMethod } = event;

    this.setState({
      paymentMethod: paymentMethod || "default"
    });
  };

  getPaymentIcon() {
    const { paymentMethod } = this.state;

    const methodMap = {
      default: creditCardIcon,
      Visa: visaIcon,
      Mastercard: masterCardIcon
    };

    return methodMap[paymentMethod] || methodMap.default;
  }

  isResponsiveView() {
    const breakpoint = 812;

    return window.innerWidth <= breakpoint;
  }

  getConfig() {
    const userData = this.getUserData();

    const fontSize = `${this.isResponsiveView() ? 12 : 16}px`;

    const config = {
      ...Form.defaultFormConfig,
      cardholder: userData,
      style: {
        base: {
          fontSize,
          letterSpacing: "1px"
        },
        placeholder: {
          base: {
            color: "#BFBFBF",
            fontSize
          }
        },
        invalid: {
          color: "red"
        },
        focus: {
          color: "#13395e !important"
        }
      }
    };

    return config;
  }

  getUserData() {
    const { user, order } = this.props;

    return order.continueAsGuest
      ? {
          name: `${order.address.firstname} ${order.address.lastname}`,
          phone: `+${order.address.phone}`
        }
      : {
          name: `${user.firstname} ${user.lastname}`,
          phone: `+${user.phone}`
        };
  }

  render() {
    const {
      onFormRendered,
      onValidationChanged,
      onFocus,
      frameValidationChanged,
      onFrameBlur
    } = this.props;

    return (
      <Frames
        config={this.getConfig()}
        ready={onFormRendered}
        paymentMethodChanged={this.onPaymentMethodChange}
        cardValidationChanged={onValidationChanged}
        frameFocus={onFocus}
        frameValidationChanged={frameValidationChanged}
        frameBlur={e => onFrameBlur(e.element, Frames.isCardValid())}
      >
        <div className="frames-wrapper">
          <div className="card-input card-number-input">
            <div className="card-input-title">Card Number</div>

            <div className="input-wrapper">
              <div className="icon-card-number-wrapper">
                <img
                  className="icon-card-number"
                  src={this.getPaymentIcon()}
                  alt="PAN"
                />
              </div>

              <CardNumber />
            </div>
          </div>

          <div className="card-input expiry-data-number-input">
            <div className="card-input-title">Expiry</div>

            <div className="input-wrapper">
              <ExpiryDate />
            </div>
          </div>

          <div className="card-input cvv-number-input">
            <div className="card-input-title">CVV</div>

            <div className="input-wrapper">
              <Cvv />
            </div>
          </div>
        </div>
      </Frames>
    );
  }
}

export default Form;
