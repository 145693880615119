import React from "react";

const category = ({
  handleItemChecked,
  handleMobileFilter,
  hierarchicalCategories,
  catName
}) => {
  return (
    <span
      onClick={e => {
        handleMobileFilter(true);
        handleItemChecked(e, hierarchicalCategories, catName, true);
      }}
      aria-label={hierarchicalCategories}
    >
      {catName}
    </span>
  );
};

export default category;
