import { get, isArray, isEmpty } from "lodash";

export const getMenuTree = (...levels) => {
  const correctFormat = string => string.toLowerCase().trim();
  const formattedLevels = levels.map(correctFormat);
  return formattedLevels.join(" > ");
};

const getOutOfStockSizes = arr => {
  return arr
    .filter(eachSize => eachSize.sizeStock === 0)
    .map(eachSize => eachSize.sizeLabel);
};

export const sanitizeStringValue = value =>
  typeof value === "string"
    ? value.trim() === ""
      ? undefined
      : value.toLowerCase()
    : value;

export const getCommerceDataForListingFormat = (
  data,
  isStepsFromCartData = false
) => {
  const createObjectForGA = (item, index = 0) => {
    const item_name = item.en_title || item.title || "";
    const item_brand = item.en_brandName || item.brandName || "";
    const item_category = item.en_parentCategory || item.parentCategory || "";
    const item_category2 = item.en_catname || item.catname || "";
    const item_category3 = item.en_subcatname || item.subcatname || "";
    const promotion_name =
      item.en_promotion?.join("|") ||
      item.en_overlayTagLabel ||
      item.overlayTagLabel ||
      "";
    let item_id = item.pId || item.productId || item.id || "";
    item_id = `${item_id}`;
    const currentPrice = +(
      item.currentPrice ||
      item.transactionPrice ||
      item.price?.current ||
      item.price ||
      0
    );
    const oldPrice = item.oldPrice || item.price?.old || item._price?.old || 0;
    const price = +(oldPrice || currentPrice);

    let discountVal = 0;

    let color = [];
    const COLOR_ATTR = "Color Family";
    const colorObj = item.attributes?.attributes?.find(
      each => each.en_attribute === COLOR_ATTR || each.name === COLOR_ATTR
    );
    if (!isEmpty(colorObj)) {
      color = colorObj.value?.map(
        colorItem => colorItem?.en_value || colorItem?.name
      );
    } else {
      color =
        item.attributes?.["en_Color Family"] ||
        item.attributes?.["ar_Color Family"] ||
        [];
    }

    const discount =
      get(item, "price.discountPercentage", 0) ||
      get(item, "_price.discountPercentage", 0) ||
      get(item, "discount", 0) ||
      0;

    if (discount !== 0) {
      discountVal = oldPrice - currentPrice;
    }
    const inStock = get(item, "pstock", 0);
    const currency = get(item, "currency", "");
    const position = get(item, "position", null);
    const itemIndex = index + 1;
    const colorSizeOptions = get(item, "colors.colorSizeOptions");
    const sizeDataWithAllSizesParam = get(item, "allSizes");
    let outOfStockSizes = "";
    if (colorSizeOptions) {
      outOfStockSizes = getOutOfStockSizes(colorSizeOptions);
      outOfStockSizes = outOfStockSizes.join("|");
    } else if (sizeDataWithAllSizesParam) {
      outOfStockSizes = sizeDataWithAllSizesParam
        .filter(eachSize => eachSize.stock === 0)
        .map(unavailSize => unavailSize.sizeLabel)
        ?.join("|");
    }
    let itemOrigin = "product";
    if (item.origin) {
      itemOrigin = item.origin || itemOrigin;
    }

    // for all the events after you view an product i.e, add to cart/ wishlist/ checkout/ order etc
    let quantitativeData = {};
    if (isStepsFromCartData) {
      const quantity = get(item, "quantity", "");
      const size = get(item, "size", "");
      quantitativeData = {
        quantity,
        size
      };
    }

    const productObject = {
      item_id,
      item_name,
      affiliation: "brandsforless",
      item_brand,
      item_category,
      item_category2,
      item_category3,
      item_category4: "auto applied",
      currency,
      discount: discountVal,
      price,
      promotion_id: promotion_name,
      promotion_name: promotion_name,
      out_of_stock_sizes: outOfStockSizes,
      item_color: color.join("|"),
      item_stock: inStock,
      index: position ? position + 1 : itemIndex,
      item_origin: itemOrigin,
      ...quantitativeData,
      currentPrice // will remove in the returned data.
    };

    const finalObject = {};
    Object.entries(productObject).forEach(
      ([key, value]) => (finalObject[key] = sanitizeStringValue(value))
    );

    return finalObject;
  };

  const getTotalPriceOfSingleItem = (currentPrice, product) => {
    let price = currentPrice;
    if (product?.quantity && product?.quantity > 1) {
      price = currentPrice * product?.quantity;
    }
    return price;
  };

  if (isArray(data)) {
    let totalPrice = 0;
    const payload = data.map((item, index) => {
      const { currentPrice, ...product } = createObjectForGA(item, index);
      const singleProductPrice = getTotalPriceOfSingleItem(
        currentPrice,
        product
      );
      totalPrice += singleProductPrice;
      return product;
    });
    return { payload, totalPrice };
  } else {
    const { currentPrice, ...product } = createObjectForGA(data, 0);
    const singleProductPrice = getTotalPriceOfSingleItem(currentPrice, product);
    return { payload: product, totalPrice: singleProductPrice };
  }
};

export const extractMainDomain = url => {
  const urlWithoutQuery = url.split("?")[0];
  // Use the URL constructor to extract the domain
  const hostname = new URL(urlWithoutQuery).hostname;
  // Remove the "www." prefix and the top-level domain (e.g., ".com")
  const parts = hostname.split(".");
  if (parts.length > 2) {
    return parts.slice(1, parts.length - 1).join(".");
  }
  return hostname;
};
