import React, { Fragment } from "react";
import { Image } from "../../image";
import LoyaltyGift from "../../../images/loyalty-gift.png";
import { LOADER_TYPES, commonLoaderStyle } from "../../../constants";
import Loader from "../../loader";
import { isArabic } from "../../../util";

const LoyaltyEarned = ({
  isLoyaltyPointsFetched,
  orderSuccessTranslation,
  loyaltyMissed,
  loyaltyPoints,
  language
}) => (
  <div className="loyalty_earned">
    {!isLoyaltyPointsFetched ? (
      <Loader
        type={LOADER_TYPES.RING}
        width={25}
        height={25}
        visible
        style={commonLoaderStyle}
      />
    ) : loyaltyMissed ? (
      <p className="missed_loyalty">
        {orderSuccessTranslation.missedLoyaltyPoints}{" "}
        {orderSuccessTranslation.order}.
        {orderSuccessTranslation.plsRegisterAndEarn}{" "}
        {orderSuccessTranslation.loyaltyPoints}{" "}
        {orderSuccessTranslation.fromNextOrder}.
      </p>
    ) : (
      <Fragment>
        <Image
          src={LoyaltyGift}
          className="loyalty_gift_image"
          atl="image loyalty"
        />
        <h5>{orderSuccessTranslation.loyaltyPoints}</h5>
        <p>
          {orderSuccessTranslation.youWillEarnUpTo}
          <span>
            {loyaltyPoints}{" "}
            {orderSuccessTranslation[
              isArabic(language) ? "points" : "loyaltyPoints"
            ].toLowerCase()}
          </span>
        </p>
      </Fragment>
    )}
  </div>
);

export default LoyaltyEarned;
