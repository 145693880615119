import React from "react";
import moment from "moment";
import exclamationMark from "../../../images/exclamation-mark.svg";

const RedeemExpiryDetailsPopup = ({
  points,
  expireDate,
  translation,
  pointDescription = "Smile Points",
  expirationDescription = "will expire on",
  dateFormat = "DD MMM YYYY"
}) => (
  <div className="redeem-expiry-details-block">
    <div className="details-popup">
      <img
        className="popup-image"
        src={exclamationMark}
        alt="exclamation-mark"
      />
      <div className="popup-text">
        <p>
          <b>
            {" "}
            {points} {pointDescription || translation.smilePoints}
          </b>{" "}
        </p>

        {expireDate && (
          <p>
            <span>{expirationDescription || translation.willExpireOn}</span>{" "}
            <span className="date">
              {moment(expireDate).format(dateFormat)}
            </span>
          </p>
        )}
      </div>
    </div>
  </div>
);

export default RedeemExpiryDetailsPopup;
