import ApiService from "./ApiService";
import { webapiPost, webapiGet } from "../webapis/core";
import { BEConfig } from "../config/env";

class ProductService extends ApiService {
  getProductById(id, ipcountry) {
    const {
      productsApi: { baseURL, protocol, port, versionInfo, productsPageHandle }
    } = BEConfig;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${productsPageHandle}/id/${id}`;

    return webapiGet(
      this.getAccessToken(),
      apiURL,
      this.getVisitorId(),
      this.getLanguage(),
      null,
      null,
      null,
      null,
      ipcountry
    ).request;
  }

  getProducts(productsIds = []) {
    const {
      productsApi: {
        baseURL,
        protocol,
        port,
        versionInfo,
        productsPageHandle,
        productsById
      }
    } = BEConfig;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${productsPageHandle}${productsById}`;

    return webapiPost(
      this.getAccessToken(),
      apiURL,
      productsIds,
      this.getVisitorId(),
      this.getLanguage()
    ).request;
  }
}

const instance = new ProductService();
export default instance;
