import isEmpty from "lodash/isEmpty";
import { DELIVERY_TYPES, PAYMENT_MODES } from "../../constants";

export const checkPaymentOptionSelection = selectedPayment =>
  selectedPayment.includes("COD") ||
  selectedPayment.includes("CC") ||
  selectedPayment.includes("PAYPAL") ||
  selectedPayment.includes("TABBY_PAY_LATER") ||
  selectedPayment.includes("TABBY_PAY_INSTALLMENT") ||
  selectedPayment.includes("POSTPAY");

export const alreadySelected = (order, option) =>
  order.selectedPayment &&
  order.selectedPaymentOption &&
  order.selectedPayment.includes(option.paymentType) &&
  order.selectedPaymentOption._paymentGateway === option._paymentGateway;

export const isCommonPaymentNotSelected = selectedPayment =>
  selectedPayment &&
  !["CC", "COD", "PAYPAL", "CBD"].some(item => selectedPayment.includes(item));

export const getCheckoutInfoText = (
  possibleToPlaceOrder,
  infoText,
  order,
  deliveryAddressFromMap,
  inStoreAddressFromMap,
  isChangeUserFieldOpen,
  checkoutReducer
) => {
  const firstStepCompleted = order.step1Completed;
  const isUserChanging = isChangeUserFieldOpen && firstStepCompleted;
  const { standardDeliveryAddress, isPickUpFormValid } = checkoutReducer;

  if (possibleToPlaceOrder || !infoText || isUserChanging) return "";

  const secondStepCompleted = order.step2Completed;
  const thirdStepCompleted = order.step3Completed;

  const standardDeliveryStepMissed =
    firstStepCompleted &&
    !secondStepCompleted &&
    order.deliveryType === DELIVERY_TYPES.DELIVERY_ADDRESS;

  const selfDeliveryMissed =
    !secondStepCompleted && order.deliveryType === DELIVERY_TYPES.SELF_PICKUP;

  const storePickUpDeliveryMissed =
    !secondStepCompleted && order.deliveryType === DELIVERY_TYPES.STORE_PICKUP;

  const isSelfPickUpAddressSelected =
    selfDeliveryMissed && deliveryAddressFromMap?.addressLine1;

  const isStorePickUpAddressSelected =
    storePickUpDeliveryMissed && inStoreAddressFromMap?.addressLine1;

  const isPaymentMissed =
    firstStepCompleted && secondStepCompleted && !thirdStepCompleted;

  const isCCSelected =
    order.selectedPaymentOption?.paymentType === PAYMENT_MODES.CC;

  const isStandardDeliveryCompleted =
    standardDeliveryStepMissed && !isEmpty(standardDeliveryAddress);
  const isSelfPickUpFormCompleted =
    selfDeliveryMissed && isPickUpFormValid.selfPickUp;
  const isStorePickUpFormCompleted =
    storePickUpDeliveryMissed && isPickUpFormValid.storePickUp;

  const isProceedToPayment =
    isStandardDeliveryCompleted ||
    isSelfPickUpFormCompleted ||
    isStorePickUpFormCompleted;

  if (!firstStepCompleted) return infoText.notLoggedIn;
  if (isProceedToPayment) return infoText.proceedToPayment;
  if (isSelfPickUpAddressSelected || isStorePickUpAddressSelected) {
    return infoText.missingNameAndPhone;
  }
  if (standardDeliveryStepMissed) return infoText.missingStandardDelivery;
  if (selfDeliveryMissed) return infoText.missingCollectionPoint;
  if (storePickUpDeliveryMissed) return infoText.missingStore;
  if (isCCSelected) return infoText.missingCardDetails;
  if (isPaymentMissed) return infoText.missingPaymentMethod;
};
