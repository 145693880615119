import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import onClickOutside from "react-onclickoutside";
import { compose } from "redux";

import { globalScrollHandler } from "../../util";

import MobileMenu from "../mobile/mobile-menu";
import Search from "../../components/search";
import UserProfile from "./profile-options";

import searchIcon from "../../images/search-icon.svg";
import { SyteSearchWithTooltip } from "../syte/syte-button";
import AnalyticService from "../../services/analytic-service";
import { GAService } from "../../services/GA-service";

class MobileHeader extends PureComponent {
  state = {
    searchOpen: false,
    menuOpen: false
  };

  searchRef = null;
  stopListeningHistory = null;

  componentDidMount() {
    const { history } = this.props;

    this.stopListeningHistory = history.listen(this.onLocationChange);
  }

  componentWillUnmount() {
    this.stopListeningHistory();
  }

  onLocationChange = location => {
    const isSearchPage = location.pathname.includes("/search");

    if (!isSearchPage) this.setSearchActiveStatus(false);
  };

  setSearchActiveStatus = status => {
    const { searchOpen } = this.state;
    if (searchOpen === status) return;

    if (searchOpen && this.searchRef) {
      this.searchRef.clearSearchInput();
    }

    this.setState({
      searchOpen: status
    });
  };

  toggleSearchActiveStatus = () => {
    const { searchOpen } = this.state;
    if (this.searchRef) {
      this.searchRef.handleInitialSearch();
    }
    this.setSearchActiveStatus(!searchOpen);
  };

  toggleSearchVisibility = () => {
    const { searchOpen } = this.state;
    if (!searchOpen) {
      this.toggleSearchActiveStatus();
    } else {
      this.setState({ searchOpen: !searchOpen });
    }
  };

  handleMenuOpen = value => {
    this.setState({ menuOpen: value }, () => {
      globalScrollHandler(this.wrapperRef, value);

      if (!this.isSearchPage()) this.setSearchActiveStatus(false);
    });
  };

  handleClickOutside = () => {
    if (!this.isSearchPage()) this.setSearchActiveStatus(false);
  };

  handleClick = event => {
    const isClickedOnHeader = event.target.className === "mobile_header";

    if (isClickedOnHeader) {
      this.handleClickOutside(false);
    }
  };

  closeMenu = () => this.handleMenuOpen(false);

  setWrapperRef = node => (this.wrapperRef = node || this.wrapperRef);

  isSearchPage() {
    const { location } = this.props;

    return location.pathname.includes("/search/");
  }

  onMobileSearchPressEnter = e => {
    const { searchOpen } = this.state;
    if (searchOpen && e.key === "Enter" && e.target.value) {
      this.setState({ searchOpen: false });
    }
  };

  handleSyteSearch = () => {
    AnalyticService.syte.trackSyteSearchHeader();
    GAService.header.trackSyteSearchInteraction();
  };

  render() {
    const {
      language,
      authReducer,
      countryCode,
      countryShort,
      history,
      openCountryModal,
      logOut,
      toggleLanguage,
      megaMenuTranslation,
      configSyteSettings,
      routeSlug
    } = this.props;

    const { searchOpen, menuOpen } = this.state;

    const isArabic = language.includes("ar");

    const isCameraSearchEnabled = configSyteSettings?.enableCameraSearch;

    const storeLogoUri = `/${routeSlug}/`;

    return (
      <div
        className={classnames(
          "m_head_wrap",
          "m_header",
          {
            overlay_menu: menuOpen
          },
          isArabic ? "arabic" : "english"
        )}
        onClick={this.handleClick}
      >
        <div className="mobile_header_wrap">
          <div className="mobile_header">
            <div className="mobile_logo">
              <span
                className="hamburger_menu"
                onClick={() => this.handleMenuOpen(true)}
              />
              <Link
                to={storeLogoUri}
                onClick={() =>
                  GAService.header.trackHomeLogoClick(storeLogoUri)
                }
                className={classnames(
                  "store_logo",
                  isArabic ? "arabicStoreLogo" : "engStoreLogo"
                )}
              />
            </div>
            <div className="user_settings">
              {isCameraSearchEnabled && (
                <SyteSearchWithTooltip
                  mobile
                  handleClick={this.handleSyteSearch}
                />
              )}

              <div
                className="search_bar"
                onClick={this.toggleSearchVisibility}
              />
              {searchOpen && (
                <div
                  className={classnames(
                    "search_popup",
                    searchOpen ? "search_active" : "search_inactive"
                  )}
                  onKeyUp={this.onMobileSearchPressEnter}
                >
                  <span
                    className="back_btn"
                    onClick={this.toggleSearchActiveStatus}
                  >
                    <img src={searchIcon} alt="arrow back" />
                  </span>
                  <Search
                    expandSection={searchOpen}
                    setRef={ref => (this.searchRef = ref)}
                  />
                  <span
                    className="cross-btn"
                    onClick={this.toggleSearchActiveStatus}
                  >
                    &times;
                  </span>
                </div>
              )}
              <UserProfile />
            </div>
          </div>
        </div>
        <div className="menuOverlay" onClick={this.closeMenu} />
        {menuOpen && (
          <div
            ref={this.setWrapperRef}
            className={classnames(
              "slide_menu",
              menuOpen ? "slide_true" : "slide_false",
              isArabic ? "arabic" : "english"
            )}
          >
            <span className="menuCross" onClick={this.closeMenu} />
            <MobileMenu
              language={language}
              authReducer={authReducer}
              countryCode={countryCode}
              countryShort={countryShort}
              closeMenu={this.closeMenu}
              history={history}
              openCountryModal={openCountryModal}
              logOut={logOut}
              toggleLanguage={toggleLanguage}
              megaMenuTranslation={megaMenuTranslation}
            />
          </div>
        )}
      </div>
    );
  }
}
export default compose(withRouter, onClickOutside)(MobileHeader);
