import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import {
  hideLoadingIndicator,
  showLoadingIndicator
} from "../../redux/actions/common.action";
import { POST_MESSAGE } from "../../constants";
import { getBaseUrl } from "../../util";
import { getSubModuleIframeUrl } from "../../util/submodule";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";

const RedeemGiftCard = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector(state => state.common?.settings);
  const configCdnDomainSettings = useSelector(
    state => state.common?.configSettings.cdnDomain
  );

  const language = useSelector(state => state.common?.languagee || "en");
  const [isLoadedIframe, setIsLoadedIframe] = useState(false);
  const { myAccountTranslation, handleGoBack } = props;
  const baseUrl = getBaseUrl({ language, settings });

  const handleMessageEventListener = event => {
    if (event.data === POST_MESSAGE.HANDLE_CONTINUE_SHOPPING) {
      history.push(baseUrl + "/");
    }
  };

  useEffect(() => {
    if (!isLoadedIframe) {
      dispatch(showLoadingIndicator());
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessageEventListener);
    return () => {
      window.removeEventListener("message", handleMessageEventListener);
    };
  }, [language]);

  const handleIframeOnload = () => {
    if (!isLoadedIframe) {
      dispatch(hideLoadingIndicator());
      setIsLoadedIframe(true);
    }
  };

  return (
    <div className="overview-wrapper">
      <OverViewHeading
        headingName={myAccountTranslation.redeemGiftCard}
        icon="my_redeem_gift_card_icon"
        handleGoBack={handleGoBack}
      />
      <div className="gift_card_section">
        <iframe
          onLoad={handleIframeOnload}
          src={getSubModuleIframeUrl({
            domain: configCdnDomainSettings?.submodule,
            page: "redeem-gift-card"
          })}
          id="sub_module_iframe"
        />
      </div>
    </div>
  );
};
export default RedeemGiftCard;
