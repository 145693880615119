import { lowerCase } from "lodash";
import { getState } from "../../redux/store";
import { isServer } from "../../util";
import { sanitizeStringValue } from "./utils";

class GAExtensions {
  STATIC_COUNTRY_CODE = {
    us: "usa",
    sa: "ksa",
    ph: "phl",
    lb: "lbn",
    kw: "kwt",
    ae: "uae",
    om: "omn",
    mt: "mlt",
    in: "ind",
    jo: "jor",
    bh: "bhr",
    qa: "qat"
  };

  getCountryCodeFromURL = () => {
    const slug = window.location?.pathname?.split("/")?.[1];
    const code = slug && slug.split("-")[1];
    return code ? this.STATIC_COUNTRY_CODE[code] : undefined;
  };
  pushToDataLayer = data => {
    const { language, countryCode, pageType = "" } = this.getConfigData();
    if (!isServer) {
      const country = countryCode || this.getCountryCodeFromURL() || "";
      const payload = {
        language_cd: language,
        country_cd: this.sanitizeString(country),
        page_type: lowerCase(pageType),
        ...data
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(payload);
    }
  };

  getConfigData = () => {
    const store = getState();
    const { language, settings } = store.common;
    const { pageType } = store.page;
    const { countryCode, currencyCode } = settings;

    return {
      language,
      countryCode,
      currencyCode: this.sanitizeString(currencyCode),
      pageType
    };
  };

  sanitizeString = sanitizeStringValue;
}

export default GAExtensions;
