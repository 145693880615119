import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DeliveredTo from "../../delivery/delivered-to";
import CartDeliveryType from "../../cart/cart-deliveryType";

class ProductDeliveryInfo extends Component {
  render() {
    const { commonSettings, modalData, translation, showCheckBox } = this.props;
    return (
      <div className="">
        <DeliveredTo
          commonSettings={commonSettings}
          translation={translation}
        />
        <div className="applePayDeliveryType_wrapper">
          <div className="applePayDeliveryType">
            <CartDeliveryType
              modalData={modalData}
              translation={translation}
              commonSettings={commonSettings}
              showCheckBox={showCheckBox}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  commonSettings: state.common.settings
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ProductDeliveryInfo)
);
