export const storeLocatorTranslation = {
  currentlyExploringStudio:
    "English CURRENTLY EXPLORING STUDIO BRANDS FOR LESS IN UAE",
  useMyCurrentLocation: "STORES NEARBY ME",
  address: "ADDRESS:",
  email: "Email:",
  getDirection: "Get Direction",
  showStore: "Show Store",
  timings: "TIMINGS:",
  contact: "CONTACT:",
  storeLocator: "Store Locator",
  storeHeader: "SEARCH FOR OUR STORES",
  locateStore: "Locate Store",
  workingHours: "Working Hours",
  selected: "Selected",
  noStoresFound: "No Stores Found",
  ok: "Ok",
  locationAccess:
    "Please allow the site to access your current location and reload the page"
};
