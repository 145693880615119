import React, { Component } from "react";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/en-au";
import template from "lodash/template";
import ReactHtmlParser from "react-html-parser";
import {
  DEFAULT_POINTS_FOR_NEXT_LEVEL,
  MEMBERSHIP_STYLE_CLASS
} from "../../../constants/loyalty-points";

class ProgressBarWrapper extends Component {
  getAdditionalText = () => {
    const {
      loyaltyDetails: { memeberLevel },
      translation
    } = this.props;

    switch (MEMBERSHIP_STYLE_CLASS[memeberLevel]) {
      case "yellow":
        return translation.upgradeToBlackTier;
      case "black":
        return translation.retainOnBlackTier;

      default:
        return "";
    }
  };

  getPointsToNextLevel = () => {
    const {
      loyaltyDetails: { pointNeededForNextLevel, accuralPoints }
    } = this.props;

    const pointsResult =
      (pointNeededForNextLevel || DEFAULT_POINTS_FOR_NEXT_LEVEL) -
      accuralPoints;

    return pointsResult < 0 ? 0 : pointsResult;
  };

  render() {
    const {
      loyaltyDetails: {
        pointNeededForNextLevel,
        cutoffDate,
        memeberLevel,
        accuralPoints
      },
      translation
    } = this.props;

    return (
      <>
        <div className="additional-info">
          <p>
            <b style={{ fontFamily: "FuturaPT-Medium" }}>
              {this.getAdditionalText()}{" "}
            </b>{" "}
            <span className="collecting-points">
              {translation.byCollecting}
            </span>
            {this.getPointsToNextLevel()}
            <span className="collecting-points">
              {" "}
              {translation.moreSmilePoints}
            </span>
            <span>{moment(cutoffDate).format("DD MMM YYYY")}.</span>
          </p>
        </div>

        {this.props.children}

        <div
          className={`collected-points-info ${MEMBERSHIP_STYLE_CLASS[memeberLevel]}`}
        >
          <p>
            {ReactHtmlParser(
              template(translation.collectedOutOf)({
                accuralPoints,
                pointNeededForNextLevel:
                  pointNeededForNextLevel || DEFAULT_POINTS_FOR_NEXT_LEVEL
              })
            )}
          </p>
        </div>
      </>
    );
  }
}

export default ProgressBarWrapper;
