import React from "react";
import cn from "classnames";

export const SizeItem = React.memo(({ title, selected, onClick }) => (
  <button
    type="button"
    className={cn("btn size-item", { selected })}
    onClick={onClick}
  >
    {title}
  </button>
));
