import get from "lodash/get";
import ApiService from "./ApiService";
import { BEConfig } from "../config/env";
import { webapiGet, webapiDelete, webapiPost } from "../webapis/core";
import { getDispatch, getState } from "../redux/store";
import { selectCurrency } from "../util/selectors";
import { fetchCartData, saveToCart } from "../redux/actions/cart.action";

class CartService extends ApiService {
  async fetchCartData(language) {
    const endpoint = `${this.getBaseUrl()}`;
    const token = this.getAccessToken();

    const response = await webapiGet(
      token,
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (get(response, "data.success", false))
      throw new Error(JSON.stringify(response));

    return response.data;
  }

  async deleteCartData(productId, id) {
    const endpoint = `${this.getBaseUrl()}/${productId}/${id}`;

    const response = await webapiDelete(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response;
  }

  async repopulateCart({ productData, countryId }) {
    const endpoint = `${this.getBaseUrl()}/repopulate`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { productData, countryId },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, cartPageHandle } =
      BEConfig.wishListApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${cartPageHandle}`;

    return url;
  }

  getCurrency() {
    const state = getState();
    return selectCurrency(state);
  }

  changeCartItemQuantity(quantity, item) {
    const language = this.getLanguage();
    const dispatch = getDispatch();

    const body = { quantity, ...item };

    dispatch(saveToCart(body, false))
      .then(resp => {
        if (resp && resp.data.success) {
          dispatch(fetchCartData(true, language, false));
        }
      })
      .catch(console.error);
  }

  async getCartAndWishlistGeneralData() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.wishListApi;

    const endpoint = `${protocol}${baseURL}${port}${versionInfo}count`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response;
  }
}

const instance = new CartService();

export default instance;
