import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import some from "lodash/some";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import cn from "classnames";
import Scrollbars from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import uniqBy from "lodash/uniqBy";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import qs from "query-string";
import { PAYMENT_MODES } from "../../constants/payment";
import ThumbSwiper from "../../components/thumb-swiper";
import ContentDescription from "../content/content-description";
import ProductSize from "../product/product-size";
import CustomDropdown from "../genericDropDown";
import { getInternationalSizes } from "../../util/cart";
import {
  addAnimate,
  getBrandUrl,
  getProductUrlName,
  globalScrollHandler,
  handleScrollTop,
  isServer,
  isTouchScreen,
  getWindowOrientation,
  getBaseUrl,
  isArabic
} from "../../util";
import {
  isAvailableInCountry,
  determineIsSizeChart,
  filterDeliverySettings,
  getAllowedSizeQuantity,
  getColorSizeOptions,
  getCountOfItemInCart,
  getItemImageList,
  getQuantityList,
  getSizesInStock,
  getAvailableSizesFromProduct
} from "../../util/add-to-cart";
import {
  selectCdnImageSettings,
  selectPDPBannerSections,
  selectRouteSlug,
  selectSyteSettings
} from "../../util/selectors";

import {
  fetchSizeChart,
  handleSizeSelection
} from "../../redux/actions/productDetail.action";
import {
  LANGUAGE_ROUTE_KEY,
  LANGUAGE,
  ONE_SIZE,
  LOADER_TYPES,
  commonLoaderStyle
} from "../../constants";
import SlideBottomToTop from "../../components/slide-bottom-to-top";
import DeliveryAndReturn from "../delivery/delivery-and-return-info";
import ProductMultiSize from "../product/product-multi-size";
import PriceSlab from "../../components/price/price-slab";
import DeleteModal from "../delete-modal";

import { getSEOStructuredProduct } from "../../util/seo";
import { getChildrenHeight, getElementHeight } from "../../util/dom";
import {
  getLoyalityPoints,
  setSizeType
} from "../../redux/actions/common.action";
import { fetchCartData } from "../../redux/actions/cart.action";
import { SyteDiscoveryButton } from "../syte/syte-button";
import Loader from "../../components/loader";

import ProductQualityDetail from "./product-quality-details";
import ProductSizeDetails from "./product-size-details";
import ShippingOptions from "./shipping-options";
import SizeChart from "./size-chart";
import RelatedProducts from "./related-products";
import AnalyticService from "../../services/analytic-service";
import GoldLabel from "../gold-label";

import { getLoyaltyPointsBody } from "../../util/loyalty-points";
import { withDeliverySettingsFetching } from "../../hocs";
import InterestFreeInstallmentsSection from "./interest-free-installments-section";
import DynamicBannersWrapper from "../dynamic/dynamic-banner";
import AdditionalSizeInfo from "../additional-size-info";
import {
  ProductDefaultAttributeList,
  ProductDefaultAttributeListNoName
} from "./attributeHandlers";
import SyteDiscoveryBanner from "../syte/syte-discovery-banner";
import { GAService } from "../../services/GA-service";
import ModelDetails from "./model-details";
import { isMobile } from "../../util";

class AddToCart extends Component {
  state = {
    activeTab: "",
    wishlistAnimationClass: "",
    addSpaceClass: false,
    AddToCartButton: false,
    availableSizes: [],
    backgroundImage: "",
    backgroundPosition: "",
    showCartAdditionSuccessMessage: false,
    cartQuantity: null,
    defaultAddToCart: false,
    defaultCountry: null,
    defaultSizeType: "",
    deliveryReturnModal: false,
    internationalSizes: {},
    isHover: false,
    showShippingOptions: false,
    isSizeChartPopupActive: false,
    itemAddedToCart: false,
    maxQuantityAddedMessage: false,
    maxQuantityThatCanBeAdded: "",
    openAccordion: false,
    openAddToCartSuccessPopUp: false,
    openMultipleSizes: false,
    openQuantity: false,
    qtySelectSize: false,
    qtySelectSizeWeb: false,
    quantityOverflowMessage: false,
    quantityOverflowModal: false,
    selectedSizeLabelInternational: "",
    selectedSizeType: "",
    showAddedToCardText: false,
    showViewMore: true,
    showWhiteStrip: false,
    sytePortalRoot: null,
    goldLabelPortalRoot: null,
    toggleViewMore: true,
    viewMoreHeight: 0,
    windowOrientation: "",
    isProcessOfAddingItem: false
  };

  zoomedBlockRef = React.createRef();
  wrapperRef = React.createRef();

  componentDidMount() {
    const { internationalSizes, viewMoreHeight } = this.state;
    const {
      countryId,
      fetchSizeChart,
      item,
      item: { brandId, en_parentCategory, subcatid, colors, IsMultisize },
      language,
      onSizeChange,
      selectedSize,
      selectedSizeId,
      selectedSizeType
    } = this.props;

    const parentId = Object.keys(parent).filter(
      item => parent[item] === en_parentCategory.toLowerCase()
    );

    const fullLanguage =
      language === LANGUAGE_ROUTE_KEY.arabic
        ? LANGUAGE.arabic
        : LANGUAGE.english;

    fetchSizeChart(
      countryId,
      parseInt(parentId[0]),
      brandId,
      subcatid,
      fullLanguage
    );

    const pHeight = get(this, "wrapperRef.current.clientHeight", 0);

    if (viewMoreHeight !== pHeight) {
      this.setState({
        viewMoreHeight: pHeight,
        showViewMore: pHeight > 150,
        addSpaceClass: pHeight > 150
      });
    }

    window.addEventListener("scroll", this.handleWishListScroll);

    window.addEventListener("resize", this.handleResize);

    const availableSizes = getAvailableSizesFromProduct(colors);

    const sizeLabel = get(availableSizes, "[0].sizeLabel");
    if (sizeLabel === ONE_SIZE) {
      this.setState({ qtySelectSizeWeb: true });
    }

    let nextInternationalSizes = internationalSizes;
    if (item && IsMultisize) {
      nextInternationalSizes = getInternationalSizes(
        availableSizes,
        internationalSizes
      );
    }

    this.setDefaultSizeType(nextInternationalSizes);

    const selectedSizeObject = (
      nextInternationalSizes[selectedSizeType] || []
    ).find(s => s.sizeId === selectedSizeId);

    const nextSelectedSizeLabelInternational = (
      selectedSizeObject ? selectedSizeObject.sizeLabel : selectedSize
    ).trim();
    let payload = { item, availableSizes };
    const internationalSizesKeys = Object.keys(internationalSizes).sort();
    const sizeType = !isEmpty(selectedSizeType)
      ? selectedSizeType
      : internationalSizesKeys[0];
    if (!isEmpty(internationalSizesKeys)) {
      payload = {
        item,
        sizeType,
        availableSizes: nextInternationalSizes[sizeType]
      };
    }
    this.checkforSizesOutOfStock(payload);
    if (
      selectedSize !== nextSelectedSizeLabelInternational &&
      selectedSizeObject
    ) {
      onSizeChange(
        nextSelectedSizeLabelInternational,
        selectedSizeObject.sizeStock,
        false,
        selectedSizeId
      );
    }

    this.setState({
      // the DOM element for the Syte.ai button integration with a mobile view
      sytePortalRoot: document.getElementById("item-preview"),
      internationalSizes: nextInternationalSizes,
      availableSizes,
      selectedSizeLabelInternational: nextSelectedSizeLabelInternational,
      orientation: getWindowOrientation()
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      viewMoreHeight,
      selectedSizeType,
      internationalSizes,
      wishlistAnimationClass,
      goldLabelPortalRoot
    } = this.state;
    const {
      selectedSizeId,
      selectedSize,
      onSizeChange,
      wishListAndCartBaseDataLoaded,
      deleteWishListItemLoaded,
      cartItems,
      currency,
      commonSettings,
      getLoyalityPoints,
      userLoggedIn,
      isJoinedToLoyaltyProgram
    } = this.props;
    const { cartItems: prevCartItems } = prevProps;

    const pHeight = get(this, "wrapperRef.current.clientHeight", 0);
    const isShouldUpdateLoyaltyState =
      cartItems.length &&
      prevCartItems.length &&
      prevCartItems.length !== cartItems.length &&
      currency &&
      userLoggedIn &&
      isJoinedToLoyaltyProgram;

    if (viewMoreHeight !== pHeight) {
      this.setState({
        viewMoreHeight: pHeight,
        showViewMore: pHeight > 150,
        addSpaceClass: pHeight > 150
      });
    }

    if (isShouldUpdateLoyaltyState) {
      const body = getLoyaltyPointsBody(currency, cartItems, commonSettings);

      await getLoyalityPoints(body);
    }

    if (selectedSizeType && prevState.selectedSizeType !== selectedSizeType) {
      const internationalSizesKeys = Object.keys(internationalSizes);
      if (internationalSizesKeys && internationalSizesKeys.length) {
        const availableSizes = internationalSizes[selectedSizeType];
        this.setState({ availableSizes });
      }
      const selectedSizeObject = find(
        internationalSizes[selectedSizeType] || [],
        { sizeId: selectedSizeId }
      );

      const selectedSizeLabelInternational = (
        selectedSizeObject ? selectedSizeObject.sizeLabel : selectedSize
      ).trim();

      if (
        selectedSize !== selectedSizeLabelInternational &&
        selectedSizeObject
      ) {
        onSizeChange(
          selectedSizeLabelInternational,
          selectedSizeObject.sizeStock,
          false,
          selectedSizeId
        );
      }

      this.setState({ selectedSizeLabelInternational });
    }

    if (
      wishlistAnimationClass &&
      ((!prevProps.wishListAndCartBaseDataLoaded &&
        wishListAndCartBaseDataLoaded) ||
        (!prevProps.deleteWishListItemLoaded && deleteWishListItemLoaded))
    ) {
      this.setState({ wishlistAnimationClass: "" });
    }
    if (!goldLabelPortalRoot) {
      const rootElement = document.getElementById("price-slab");
      rootElement &&
        this.setState({
          goldLabelPortalRoot: document.getElementById("price-slab")
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { wishlistAnimationClass, selectedSizeType, internationalSizes } =
      this.state;
    const {
      cart,
      clearSizeSelection,
      handleSizeSelection,
      item,
      selectedQty,
      selectedSize,
      selectedSizeId,
      onSizeChange,
      clearSelectedSize
    } = this.props;
    if (nextProps.selectedSize !== selectedSize) {
      handleSizeSelection(false);
      this._addAnimate(
        nextProps.item,
        false,
        nextProps.wishListBaseData,
        wishlistAnimationClass,
        nextProps.selectedSize,
        true
      );
    }
    if (
      nextProps.selectedSize !== selectedSize ||
      nextProps.selectedQty !== selectedQty ||
      get(nextProps, "cart.length", 0) !== get(cart, "length", 0)
    ) {
      this.setState({
        maxQuantityAddedMessage: false,
        quantityOverflowMessage: false
      });
    }
    const countryHasChanged = !isEqual(
      get(nextProps.item, "countryId"),
      get(item, "countryId")
    );
    const itemHasChanged =
      nextProps.item && item && nextProps.item.id !== item.id;

    if (itemHasChanged || countryHasChanged) {
      clearSizeSelection();
      this.setState({ qtySelectSizeWeb: false });
      const { countryId, language, fetchSizeChart } = this.props;
      const parentId = Object.keys(parent).filter(
        item => parent[item] === nextProps.item.en_parentCategory.toLowerCase()
      );
      const fullLanguage =
        language === LANGUAGE_ROUTE_KEY.arabic
          ? LANGUAGE.arabic
          : LANGUAGE.english;
      fetchSizeChart(
        countryId,
        parseInt(parentId[0]),
        nextProps.item.brandId,
        nextProps.item.subcatid,
        fullLanguage
      );
      const colorSizeOptions = get(
        nextProps.item.colors,
        "colorSizeOptions",
        []
      );
      let availableSizes = colorSizeOptions.filter(option => option.sizeLabel);
      let nextInternationalSizes = internationalSizes;

      if (nextProps.item && nextProps.item.IsMultisize) {
        nextInternationalSizes = getInternationalSizes(availableSizes, {});
      }

      const internationalSizesKeys = Object.keys(nextInternationalSizes);
      if (internationalSizesKeys && internationalSizesKeys.length) {
        availableSizes = nextInternationalSizes[selectedSizeType];
      }
      this.checkforSizesOutOfStock({
        availableSizes,
        item: nextProps.item,
        sizeType: selectedSizeType
      });
      const sizeLabel = get(availableSizes, "[0].sizeLabel");
      if (sizeLabel === ONE_SIZE) {
        this.setState({ qtySelectSizeWeb: true });
      }
      const isSizeAvailable = this.checkIsSizeAvailable(
        availableSizes,
        selectedSize
      );
      if (!isSizeAvailable) {
        clearSelectedSize();
      }
      this.setState({
        internationalSizes: nextInternationalSizes,
        availableSizes
      });
    }
    if (nextProps.selectedSizeType !== selectedSizeType) {
      this.setState({ selectedSizeType: nextProps.selectedSizeType });
    }
    if (
      nextProps.selectedSizeId !== selectedSizeId ||
      nextProps.selectedSize !== selectedSize
    ) {
      const selectedSizeObject = find(
        internationalSizes[nextProps.selectedSizeType] || [],
        { sizeId: nextProps.selectedSizeId }
      );
      const selectedSizeLabelInternational = (
        selectedSizeObject
          ? selectedSizeObject.sizeLabel
          : nextProps.selectedSize
      ).trim();
      // For Ga event
      let sizeRegion = "";
      if (!isEmpty(internationalSizes)) {
        sizeRegion = nextProps.selectedSizeType;
      } else {
        const sizeIndication = this.getSizeIndication(nextProps.item);
        sizeRegion = sizeIndication?.sizes;
      }
      const gaPayload = {
        size: nextProps.selectedSize,
        product_sku: get(nextProps.item, "id", ""),
        size_region: sizeRegion
      };
      if (
        selectedSize !== selectedSizeLabelInternational &&
        selectedSizeObject
      ) {
        gaPayload.size = selectedSizeLabelInternational;
        onSizeChange(
          selectedSizeLabelInternational,
          selectedSizeObject.sizeStock,
          false,
          nextProps.selectedSizeId
        );
      }
      if (gaPayload.size.length) {
        GAService.product.trackProductSizeSelected(gaPayload);
      }
      this.setState({ selectedSizeLabelInternational });
    }
  }

  componentWillUnmount() {
    const { defaultSizeType } = this.state;
    const { clearSizeSelection } = this.props;

    globalScrollHandler(this.actionRef, false);

    window.removeEventListener("scroll", this.handleWishListScroll);
    window.removeEventListener("resize", this.handleResize);

    clearSizeSelection();
    if (defaultSizeType) {
      this.setSelectedSizeType(defaultSizeType);
    }
  }

  isOutOfStock = (availableSizes, item) => {
    const sizesInStock = getSizesInStock(availableSizes);
    return item.pstock <= 0 || (sizesInStock && !sizesInStock.length);
  };

  checkforSizesOutOfStock = ({
    availableSizes = [],
    item,
    sizeType = null
  }) => {
    const isOutOfStock = this.isOutOfStock(availableSizes, item);
    let sizeRegion = undefined;
    if (sizeType) {
      sizeRegion = sizeType;
    } else {
      const sizeIndication = this.getSizeIndication(item);
      if (sizeIndication?.sizes) {
        sizeRegion = sizeIndication.sizes;
      }
    }
    if (isOutOfStock) {
      GAService.product.trackProductsOutOfStock({
        sizeRegion,
        productId: item.id,
        availSizes: availableSizes
      });
    }
  };

  checkIsSizeAvailable = (availableSizes, selectedSize) => {
    const size = availableSizes.find(size => size.sizeLabel === selectedSize);
    return Boolean(size && size.sizeStock);
  };

  toggleViewMore = e =>
    this.setState(state => ({ toggleViewMore: !state.toggleViewMore }));

  setDefaultSizeType = internationalSizes => {
    const { setSizeType } = this.props;
    const internationalSizesKeys = Object.keys(internationalSizes).sort();
    if (internationalSizesKeys && internationalSizesKeys.length) {
      const defaultSizeType = internationalSizesKeys[0];
      setSizeType(defaultSizeType);
      this.setState({
        selectedSizeType: defaultSizeType,
        defaultSizeType
      });
    }
  };

  handleWishListScroll = () => {
    if (this.topWishListSticky) {
      if (window.pageYOffset >= 460 && !this.state.showWhiteStrip) {
        this.setState({ showWhiteStrip: true });
      } else if (window.pageYOffset <= 460 && this.state.showWhiteStrip) {
        this.setState({ showWhiteStrip: false });
      }
    }
  };

  handleImageMouseMove = state => this.setState(state);

  setSelectedSizeType = selectedSizeType => {
    const { setSizeType } = this.props;
    setSizeType(selectedSizeType);
  };

  handleImageMouseOut = () =>
    this.setState({ backgroundImage: "", isHover: false });

  toggleShippingOptionsModal = () =>
    this.setState(state => ({
      showShippingOptions: !state.showShippingOptions
    }));

  handleQtyModal = (value, availableSizes) => {
    const { selectedSize } = this.props;
    if (availableSizes && availableSizes.length) {
      if (selectedSize === "") {
        this.setState({ qtySelectSize: true }, () => {
          setTimeout(() => this.setState({ qtySelectSize: false }), 3000);
        });
      } else {
        globalScrollHandler(this.actionRef, value);
        this.setState(state => ({ openQuantity: !state.openQuantity }));
      }
    } else {
      globalScrollHandler(this.actionRef, value);
      this.setState(state => ({
        openQuantity: !state.openQuantity,
        qtySelectSize: false
      }));
    }
  };

  handleSelectSizeFirst = availableSizes => {
    const { selectedSize } = this.props;
    this.setState({
      qtySelectSizeWeb: availableSizes && availableSizes.length && !selectedSize
    });
  };

  handleDeliveryReturnModal = value => {
    globalScrollHandler(this.actionRef, value);
    this.setState({ deliveryReturnModal: value });
  };

  handleMultipleSizesModal = value => {
    this.setState({
      openMultipleSizes: value,
      defaultAddToCart: false,
      qtySelectSize: false
    });
    if (!isServer) {
      const screenWidth = window.screen.width;
      if (screenWidth < 813) {
        globalScrollHandler(this.actionRef, value);
      }
    }
  };

  handleToggleAccordion = () =>
    this.setState(state => ({ openAccordion: !state.openAccordion }));

  changeProduct = (item, itemIndex) => {
    const { history, routeSlug, handleSizeSelection } = this.props;
    handleSizeSelection(false);
    GAService.product.trackSelectProduct({
      item: { ...item, position: itemIndex }
    });
    history.push(
      `/${routeSlug}/${getProductUrlName(item.en_title)}/${item.id}/p/`
    );
  };

  handleSizeChartPopup = value => {
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
    this.setState({ isSizeChartPopupActive: value });
  };

  handleSizeChart = () => {
    this.handleSizeChartPopup(true);
  };

  getBrandProductLink = item => {
    const { routeSlug } = this.props;
    const brandUrl = item && getBrandUrl(item);
    return brandUrl ? `/${routeSlug}${brandUrl}` : "";
  };

  handleGoBack = () => {
    const { history, location, language, commonSettings } = this.props;
    this.setState({ isToggle: false });
    const { gclid } = qs.parse(location.search);
    const goHome = history.length <= 2 || gclid;
    if (goHome) {
      history.push(getBaseUrl({ language, commonSettings }) + "/");
    } else {
      history.goBack();
    }
  };

  handleAddToCartText = () => {
    this.setState({ showAddedToCardText: true }, () => {
      setTimeout(() => this.setState({ showAddedToCardText: false }), 1500);
    });
  };

  handleDefaultAddToCart = value => {
    this.setState({ defaultAddToCart: value, AddToCartButton: true });
  };

  handleSizeDropDown = () => {
    this.setState({ AddToCartButton: false });
  };

  _addAnimate = (
    item,
    isClicked,
    wishList,
    wishlistAnimationClass,
    selectedSize,
    isDetailPage
  ) => {
    const value = addAnimate(
      item,
      isClicked,
      wishList,
      wishlistAnimationClass,
      selectedSize,
      isDetailPage
    );
    this.setState({ wishlistAnimationClass: value });
  };

  openSuccessDropDown = value => {
    if (!isServer) {
      const screenWidth = window.screen.width;
      if (screenWidth < 813) {
        this.setState({ itemAddedToCart: value }, () => {
          globalScrollHandler(this.actionRef, value);
        });
      }
    }
  };

  closeSuccessDropDown = () => {
    this.setState({ itemAddedToCart: false }, () => {
      globalScrollHandler(this.actionRef, false);
    });
  };

  handleSizeChange = async (
    sizeLabel,
    sizeStock,
    defaultAddToCart,
    selectedQty,
    availableSizes,
    allowedQuantity,
    internationalSizes
  ) => {
    const {
      maxQuantityAddedMessage,
      quantityOverflowMessage,
      selectedSizeType
    } = this.state;
    const { onSizeChange, item, cart } = this.props;
    let selectedSizeObject;
    // resolving does item have international size types or single sizes instead
    if (Object.keys(internationalSizes).length) {
      selectedSizeObject = internationalSizes[selectedSizeType].find(
        size => size.sizeLabel.trim() === sizeLabel.trim()
      );
    } else {
      selectedSizeObject = availableSizes.find(
        size => size.sizeLabel.trim() === sizeLabel.trim()
      );
    }

    const checkCartQuantity = await this.handleQuantityAddition(
      item,
      cart,
      selectedQty,
      availableSizes,
      sizeLabel,
      allowedQuantity,
      internationalSizes
    );

    onSizeChange &&
      onSizeChange(
        sizeLabel,
        sizeStock,
        checkCartQuantity.isOverflow ? false : defaultAddToCart,
        selectedSizeObject
      );
    if (!isServer) {
      const screenWidth = window.screen.width;
      if (screenWidth < 813) {
        defaultAddToCart &&
          (!maxQuantityAddedMessage || !quantityOverflowMessage) &&
          !checkCartQuantity.isOverflow &&
          this.openSuccessDropDown(true);
      }
    }
  };

  handleQuantityAddition = async (
    item,
    cart1,
    selectedQty,
    availableSizes,
    selectedSize,
    allowedQuantity,
    internationalSizes
  ) => {
    const { selectedSizeType } = this.state;
    const { fetchCartData } = this.props;
    const cart = await fetchCartData();

    const returnValue = {
      isOverflow: false,
      msg: "",
      qty: 0
    };
    const oneSize =
      item.colors &&
      item.colors.colorSizeOptions &&
      item.colors.colorSizeOptions.filter(data => data.sizeLabel);
    const cartItems =
      cart &&
      cart.filter(cartItem => {
        return cartItem.productId === item.id;
      });
    const cartQuantity =
      (cartItems && cartItems.find(items => items.size === selectedSize)) || 0;
    const cartQuantity1 =
      (cartItems && cartItems.filter(items => items.size === selectedSize)) ||
      0;
    const totalQty =
      cartQuantity1 &&
      cartQuantity1
        .map(item => item.quantity)
        .reduce((total, num) => total + +num, 0);
    const itemQuantity =
      availableSizes &&
      availableSizes.find(sizes => sizes.sizeLabel.trim() === selectedSize);
    if (oneSize && oneSize.length) {
      if (internationalSizes && Object.keys(internationalSizes).length) {
        if (cartItems && cartItems.length) {
          const itemDetails =
            internationalSizes &&
            internationalSizes[
              selectedSizeType || Object.keys(internationalSizes)[0]
            ].find(item => item.sizeLabel.trim() === selectedSize);
          const cartQty =
            cartItems &&
            cartItems.reduce((total, item) => {
              if (item.sizeId === (itemDetails && itemDetails.sizeId)) {
                return total + item.quantity;
              }
              return total;
            }, 0);

          if (cart && !cart.length) {
            return returnValue;
          } else if (
            cartQty + selectedQty <=
            (itemDetails && itemDetails.sizeStock)
          ) {
            returnValue.isOverflow = false;
            returnValue.msg = "";
            returnValue.qty = selectedQty;
            this.setState({
              openAddToCartSuccessPopUp: true,
              quantityOverflowModal: true
            });
          } else {
            let _allowedQuantity = itemDetails && itemDetails.sizeStock;
            let quantityThatCanBeAddedSafely = _allowedQuantity - cartQty;
            if (quantityThatCanBeAddedSafely > 0) {
              this.setState({
                quantityOverflowMessage: true,
                maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
                openAddToCartSuccessPopUp: false,
                quantityOverflowModal: true,
                cartQuantity: cartQty
              });
              returnValue.isOverflow = true;
              returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
              returnValue.qty = quantityThatCanBeAddedSafely;
              this.openSuccessDropDown(false);
            } else {
              this.setState({
                maxQuantityAddedMessage: true,
                openAddToCartSuccessPopUp: false,
                quantityOverflowModal: true,
                cartQuantity: cartQty
              });
              returnValue.isOverflow = true;
              returnValue.msg = `You already have added the allowed max quantity (${_allowedQuantity}) for this item`;
              returnValue.qty = 0;
              this.openSuccessDropDown(false);
            }
          }
        }
      } else {
        if (cart && !cart.length) {
          return returnValue;
        } else if (
          +totalQty + selectedQty <=
          (itemQuantity && itemQuantity.sizeStock)
        ) {
          returnValue.isOverflow = false;
          returnValue.msg = "";
          returnValue.qty = selectedQty;
          this.setState({
            openAddToCartSuccessPopUp: true,
            quantityOverflowModal: true
          });
        } else {
          let _allowedQuantity = itemQuantity && itemQuantity.sizeStock;
          let quantityThatCanBeAddedSafely =
            _allowedQuantity - (cartQuantity && +totalQty);
          if (quantityThatCanBeAddedSafely > 0) {
            this.setState({
              quantityOverflowMessage: true,
              maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
              openAddToCartSuccessPopUp: false,
              quantityOverflowModal: true,
              cartQuantity: totalQty
            });
            returnValue.isOverflow = true;
            returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
            returnValue.qty = quantityThatCanBeAddedSafely;
            this.openSuccessDropDown(false);
          } else {
            this.setState({
              maxQuantityAddedMessage: true,
              openAddToCartSuccessPopUp: false,
              quantityOverflowModal: true,
              cartQuantity: totalQty
            });
            returnValue.isOverflow = true;
            returnValue.msg = `You already have added the allowed max quantity (${_allowedQuantity}) for this item`;
            returnValue.qty = 0;
            this.openSuccessDropDown(false);
          }
        }
      }
    } else {
      if (
        ((cartItems && cartItems[0] && cartItems[0].quantity) || 0) +
          selectedQty <=
        allowedQuantity
      ) {
        this.setState({ openAddToCartSuccessPopUp: true });
        return true;
      } else {
        let quantityThatCanBeAddedSafely =
          allowedQuantity -
          ((cartItems && cartItems[0] && cartItems[0].quantity) || 0);
        if (quantityThatCanBeAddedSafely > 0) {
          this.setState({
            quantityOverflowMessage: true,
            maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
            openAddToCartSuccessPopUp: false,
            quantityOverflowModal: true,
            cartQuantity:
              (cartItems && cartItems[0] && cartItems[0].quantity) || 0
          });
          returnValue.isOverflow = true;
          returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
          returnValue.qty = quantityThatCanBeAddedSafely;
          this.openSuccessDropDown(false);
        } else {
          this.setState({
            maxQuantityAddedMessage: true,
            openAddToCartSuccessPopUp: false,
            quantityOverflowModal: true,
            cartQuantity:
              (cartItems && cartItems[0] && cartItems[0].quantity) || 0
          });
          returnValue.isOverflow = true;
          returnValue.msg = `You already have added the allowed max quantity (${allowedQuantity}) for this item`;
          returnValue.qty = 0;
          this.openSuccessDropDown(false);
        }
      }
    }
    return returnValue;
  };

  handleCloseModal = value => {
    this.setState({ quantityOverflowModal: value });
  };

  goToCart = () => {
    const { history, routeSlug } = this.props;
    history.push(`/${routeSlug}/my-cart/`);
  };

  cartSuccessDropDownMessage = (
    item,
    selectedQty,
    selectedSize,
    internationalSizes
  ) => {
    const { selectedSizeType } = this.state;
    const { cart } = this.props;
    const oneSize = get(item, "colors.colorSizeOptions", []).filter(
      data => data.sizeLabel
    );
    const cartItems = (cart || []).filter(
      cartItem => cartItem.productId === item.id
    );
    const cartQuantity = find(cartItems, { size: selectedSize }) || 0;
    if (oneSize && oneSize.length) {
      if (internationalSizes && Object.keys(internationalSizes).length) {
        const itemDetails =
          internationalSizes &&
          internationalSizes[
            selectedSizeType || Object.keys(internationalSizes)[0]
          ].find(item => item.sizeLabel.trim() === selectedSize);
        const cartQty =
          cartItems &&
          cartItems.reduce((total, item) => {
            if (item.sizeId === (itemDetails && itemDetails.sizeId)) {
              return total + item.quantity;
            }
            return total;
          }, 0);
        this.setCartQuantity(cartQty, selectedQty);
      } else {
        this.setCartQuantity(
          cartQuantity && cartQuantity.quantity,
          selectedQty
        );
      }
    } else {
      const cartItemsQuantity = get(cartItems, "[0].quantity", 0);
      if (cartItemsQuantity > 0) {
        this.setState({
          showCartAdditionSuccessMessage: true,
          cartQuantity: cartItemsQuantity
        });
      } else {
        this.setState({ showCartAdditionSuccessMessage: false });
      }
    }
  };

  setCartQuantity = (cartQty, selectedQty) => {
    if (cartQty && cartQty + selectedQty > 1) {
      this.setState({
        showCartAdditionSuccessMessage: true,
        cartQuantity: cartQty
      });
    } else {
      this.setState({ showCartAdditionSuccessMessage: false });
    }
  };

  isPresent = item => {
    const { wishListBaseData, selectedSize } = this.props;
    const res =
      wishListBaseData &&
      wishListBaseData.length &&
      wishListBaseData.some(
        wishItem =>
          wishItem.productId === item.id && wishItem.size === selectedSize
      );

    return Boolean(res);
  };

  isPresentCart = item => {
    const { cart, selectedQty } = this.props;
    return (
      cart &&
      cart.length &&
      cart.find(cartItem => {
        return (
          cartItem.productId === item.id &&
          cartItem.size === this.selectedSizeLabelInternational &&
          (cartItem.quantity || 1) === selectedQty
        );
      })
    );
  };

  addToWishListMoEngage = () => {
    const { item, wishListBaseData, authReducer, commonSettings } = this.props;
    AnalyticService.cart.trackAddingToWishlist({
      item,
      wishlistData: wishListBaseData,
      authReducer,
      commonSettings
    });
  };

  addToWishList = e => {
    e.preventDefault();

    const {
      item,
      selectedSize,
      wishListBaseData,
      deleteFromWishList,
      saveToWishList,
      wishList,
      authReducer,
      commonSettings
    } = this.props;

    const { selectedSizeType, wishlistAnimationClass } = this.state;
    if (this.isPresent(item)) {
      deleteFromWishList(item.id, selectedSize, selectedSizeType);
      AnalyticService.cart.trackDeleteWishlist({
        item,
        wishList,
        authReducer,
        commonSettings
      });
      GAService.cart.removeProductFromWishList(item);
    } else {
      saveToWishList(item, selectedSize, selectedSizeType);
      GAService.cart.trackAddProductToWishList({
        item,
        wishListData: { size: selectedSize }
      });
      this.addToWishListMoEngage();
    }

    this._addAnimate(
      item,
      true,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      true
    );
  };

  handleSyteSearch = () => {
    const { item } = this.props;
    AnalyticService.syte.trackSyteSearchProductPage(item);
  };

  onAddItem = async ({ allowedQuantity }) => {
    const {
      availableSizes,
      internationalSizes,
      selectedSizeLabelInternational,
      selectedSizeType
    } = this.state;
    const {
      cart,
      errShowQtyTextBox,
      handleAddToCart,
      handleSizeSelection,
      item,
      selectedQty
    } = this.props;
    this.setState({ isProcessOfAddingItem: true });

    const presentItem = this.isPresentCart(item);

    const countOfItemInCart = getCountOfItemInCart(
      cart,
      item,
      selectedSizeLabelInternational
    );

    if (
      !presentItem &&
      !errShowQtyTextBox &&
      allowedQuantity &&
      (availableSizes && availableSizes.length
        ? selectedSizeLabelInternational
        : true)
    ) {
      const validationResult = await this.handleQuantityAddition(
        item,
        cart,
        selectedQty,
        availableSizes,
        selectedSizeLabelInternational,
        allowedQuantity,
        internationalSizes
      );
      if (validationResult.isOverflow) {
        this.setState({ isProcessOfAddingItem: false });

        return;
      }
      this.cartSuccessDropDownMessage(
        item,
        selectedQty,
        selectedSizeLabelInternational,
        internationalSizes
      );
      this.handleAddToCartText();
      this.handleDefaultAddToCart(true);
      this.openSuccessDropDown(true);
      handleAddToCart(
        item,
        (internationalSizes[selectedSizeType] &&
          internationalSizes[selectedSizeType].find(
            size => size.sizeLabel === selectedSizeLabelInternational
          )) ||
          selectedSizeLabelInternational,
        selectedQty,
        selectedSizeType,
        countOfItemInCart,
        allowedQuantity
      );
    } else if (presentItem && !errShowQtyTextBox) {
      handleSizeSelection(false);
      const validationResult = await this.handleQuantityAddition(
        item,
        cart,
        selectedQty,
        availableSizes,
        selectedSizeLabelInternational,
        allowedQuantity,
        internationalSizes
      );
      if (validationResult.isOverflow) {
        this.setState({ isProcessOfAddingItem: false });
        return;
      }
      this.cartSuccessDropDownMessage(
        item,
        selectedQty,
        selectedSizeLabelInternational,
        internationalSizes
      );
      this.handleDefaultAddToCart(true);
      this.handleAddToCartText();
      this.openSuccessDropDown(true);

      handleAddToCart(
        item,
        (internationalSizes[selectedSizeType] &&
          internationalSizes[selectedSizeType].find(
            size => size.sizeLabel === selectedSizeLabelInternational
          )) ||
          selectedSizeLabelInternational,
        selectedQty,
        selectedSizeType,
        countOfItemInCart,
        allowedQuantity
      );
    } else if (!errShowQtyTextBox) {
      handleSizeSelection(true);
      this.handleMultipleSizesModal(true);
      this.handleDefaultAddToCart(true);
    }
    this.setState({ isProcessOfAddingItem: false });
  };

  _handleAddToCart = (...args) => {
    setTimeout(async () => {
      const { 1: sizeLabelFromArgs } = args;
      const {
        availableSizes,
        internationalSizes,
        selectedSizeLabelInternational
      } = this.state;
      const { cart, handleAddToCart, item, selectedQty, selectedSize } =
        this.props;
      const allowedQuantity = getAllowedSizeQuantity(
        availableSizes,
        selectedSize,
        item
      );

      const sizeLabel =
        selectedSizeLabelInternational || sizeLabelFromArgs || "";

      const validationResult = await this.handleQuantityAddition(
        item,
        cart,
        selectedQty,
        availableSizes,
        sizeLabel,
        allowedQuantity,
        internationalSizes
      );
      if (validationResult.isOverflow) {
        return;
      }
      this.cartSuccessDropDownMessage(
        item,
        selectedQty,
        sizeLabel,
        internationalSizes
      );
      handleAddToCart(...args);
    }, 300);
  };

  handleResize = () => {
    const { orientation } = this.state;
    const newOrientation = getWindowOrientation();
    if (orientation !== newOrientation) {
      this.setState({ orientation: newOrientation });
    }
  };

  handlePortalWrapping = (component, portalRoot) =>
    isTouchScreen() && portalRoot
      ? ReactDOM.createPortal(component, portalRoot)
      : component;

  isOneLined = domElement => {
    const acceptedDelta = 0.1;
    return (
      domElement &&
      getElementHeight(domElement) - getChildrenHeight(domElement) <
        acceptedDelta * getElementHeight(domElement)
    );
  };

  isTabbyInstallmentSupported() {
    const { commonSettings } = this.props;

    return some(commonSettings.deliverySettings, {
      paymentType: PAYMENT_MODES.TABBY_PAY_INSTALLMENT
    });
  }

  isPostPayInstallmentSupported() {
    const { commonSettings } = this.props;
    return some(commonSettings.deliverySettings, {
      paymentType: PAYMENT_MODES.POSTPAY
    });
  }

  isTamaraInstallmentSupported() {
    const { commonSettings } = this.props;

    return some(commonSettings.deliverySettings, {
      paymentType: PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS
    });
  }

  getInfoForLoyaltyAlert = () => {
    const {
      accessToken,
      isJoinedToLoyaltyProgram,
      userLoggedIn,
      language,
      routeSlug,
      translation,
      loyaltyPoints
    } = this.props;

    if (accessToken && isJoinedToLoyaltyProgram && userLoggedIn) {
      return (
        <p className="earnPoints">
          {translation.youWillEarn} <span>{loyaltyPoints}</span>{" "}
          {translation.loyaltyPoints}.
          <Link
            className="learn-more-text"
            to={`/${language}-ae/loyaltyprogram/`}
          >
            {translation.learnMore}
          </Link>
        </p>
      );
    } else if (!userLoggedIn) {
      return (
        <p className="earnPoints">
          <Link className="loyalty-join" to={`/${routeSlug}/login/`}>
            {translation.signIn}
          </Link>

          {translation.signInAndJoin}
          <Link
            className="learn-more-text"
            to={`/${language}-ae/loyaltyprogram/`}
          >
            {translation.learnMore}
          </Link>
        </p>
      );
    } else if (accessToken && userLoggedIn && !isJoinedToLoyaltyProgram) {
      return (
        <p className="earnPoints">
          <Link
            className="loyalty-join"
            to={`/${routeSlug}/my-account/loyalty-program/`}
          >
            {translation.join}
          </Link>
          {translation.toEarnPoints}
          <Link
            className="learn-more-text"
            to={`/${language}-ae/loyaltyprogram/`}
          >
            {translation.learnMore}
          </Link>
        </p>
      );
    }
  };

  getSizeIndication = item => {
    const sizeShownAttr = item?.attributes?.attributes?.find(
      item =>
        item?.name?.toLowerCase() === "size shown" ||
        item?.en_attribute?.toLowerCase() === "size shown"
    );

    if (sizeShownAttr) {
      const sizeSpecs = {};

      sizeSpecs["isSizeShown"] = true;
      sizeSpecs["sizes"] = sizeShownAttr?.value?.[0]?.name || "";

      return sizeSpecs;
    }
  };

  render() {
    const {
      activeTab,
      wishlistAnimationClass,
      addSpaceClass,
      availableSizes,
      backgroundImage,
      backgroundPosition,
      cartQuantity,
      defaultAddToCart,
      deliveryReturnModal,
      internationalSizes,
      isHover,
      isSizeChartPopupActive,
      itemAddedToCart,
      maxQuantityAddedMessage,
      maxQuantityThatCanBeAdded,
      openAccordion,
      openAddToCartSuccessPopUp,
      openMultipleSizes,
      openQuantity,
      qtySelectSize,
      qtySelectSizeWeb,
      quantityOverflowMessage,
      quantityOverflowModal,
      selectedSizeLabelInternational,
      selectedSizeType,
      showAddedToCardText,
      showCartAdditionSuccessMessage,
      showShippingOptions,
      showViewMore,
      showWhiteStrip,
      sytePortalRoot,
      goldLabelPortalRoot,
      toggleViewMore,
      viewMoreHeight,
      isProcessOfAddingItem
    } = this.state;

    const {
      addedToCart,
      available,
      cart,
      cartItems,
      commonSettings,
      countryId,
      currencyCode,
      errShowQtyTextBox,
      handleSizeSelection,
      isPageIndexable,
      isSaving,
      item,
      language,
      location,
      onBlurChange,
      onQtyChange,
      onQtyTextChange,
      onSizeChange,
      routeSlug,
      selectedQty,
      selectedSize,
      showQtyTextBox,
      sizeChart,
      sizeSelection,
      translation,
      pdpSections,
      configCdnImagesSettings,
      configSyteSettings
    } = this.props;
    const deliverySettings = get(commonSettings, "deliverySettings", []);
    const filteredDeliverySettings = filterDeliverySettings(deliverySettings);
    const uniqDeliverySettings = uniqBy(
      filteredDeliverySettings,
      "deliveryType"
    );
    const sizesInStock = getSizesInStock(availableSizes);

    const allowedQuantity = getAllowedSizeQuantity(
      availableSizes,
      selectedSize,
      item
    );
    const quantityList = getQuantityList(allowedQuantity);
    const selectedItemQuantity = quantityList.length ? selectedQty : 1;
    const imageList = getItemImageList(item);
    const isSizeChart = determineIsSizeChart(sizeChart);
    const isBrandType =
      isSizeChart && Object.keys(sizeChart[0]).includes("list");
    const countOfItemInCart = getCountOfItemInCart(
      cart,
      item,
      selectedSizeLabelInternational
    );
    const colorSizeOptions = getColorSizeOptions(
      internationalSizes,
      selectedSizeType,
      availableSizes
    );
    const isProductAvailableInCountry =
      item.is_price_available_in_user_country &&
      isAvailableInCountry(available, countryId);

    const isOutOfStock =
      item.pstock <= 0 ||
      (sizesInStock && !sizesInStock.length) ||
      allowedQuantity === 0;

    const seoStructuredProductData = getSEOStructuredProduct(
      item,
      location,
      currencyCode,
      language,
      isOutOfStock
    );

    const maxQuantityAddedMessageBody = `${translation.alreadyAdded} ${allowedQuantity} ${translation.qtyItemInCart}. ${translation.theAvailableStock} ${allowedQuantity}.`;

    const quantityOverflowMessageBody = `${translation.alreadyAdded} ${cartQuantity} ${translation.qtyItemInCart}. ${translation.theAvailableStock} ${allowedQuantity}. ${translation.youCanAddMaxOf} ${maxQuantityThatCanBeAdded} ${translation.moreQty}.`;

    const cartAdditionSuccessMessage = `${translation.alreadyAdded} ${cartQuantity} ${translation.qtyItemInCart}, ${translation.nowAdded} ${selectedQty} ${translation.moreQty}`;

    const syteButton = (
      <SyteDiscoveryButton
        sku={item.id}
        productImgPath={`${configCdnImagesSettings?.main}${
          item && item.captionImageURL
        }`}
        placement="pdp-swiper"
        mobile={true}
        text={translation.viewSimilarStyles}
        handleClick={this.handleSyteSearch}
        details={item}
      />
    );

    const goldLabel = (
      <GoldLabel item={item} language={language} placement="pdp" />
    );

    const showSizeOptions = colorSizeOptions.length > 0;

    const showStockLimit =
      allowedQuantity &&
      allowedQuantity <= 5 &&
      availableSizes &&
      !availableSizes.length;

    const showLocationDropdown =
      item &&
      item.IsMultisize &&
      internationalSizes &&
      Object.keys(internationalSizes) &&
      Object.keys(internationalSizes).length;

    const showSelectSizeFirstError =
      (sizeSelection &&
        !selectedSize &&
        availableSizes &&
        availableSizes.length &&
        sizesInStock.length &&
        !qtySelectSizeWeb) ||
      (qtySelectSizeWeb &&
        selectedSizeLabelInternational === "" &&
        availableSizes &&
        availableSizes.length &&
        sizesInStock.length);

    const isDiscoveryButtonEnabled = configSyteSettings?.enableDiscoveryIcon;
    const isDiscoveryBannerEnabled =
      configSyteSettings?.enableShopSimilarSection;
    const renderSyteButton =
      isDiscoveryButtonEnabled &&
      this.handlePortalWrapping(syteButton, sytePortalRoot);
    const renderGoldLabel =
      goldLabel && this.handlePortalWrapping(goldLabel, goldLabelPortalRoot);
    const sizeIndication = this.getSizeIndication(item);

    const attributesWithSpecialStyles = ["bullets", "attributes"];
    const productDefaultStyledAttributes = {
      ...item.attributes
    };
    attributesWithSpecialStyles.forEach(
      key => delete productDefaultStyledAttributes[key]
    );

    const productSizeExtraData = !isEmpty(productDefaultStyledAttributes) && (
      <li
        className={cn("border border-white h-auto sticky_item", {
          multi_size: item?.IsMultisize
        })}
      >
        <ProductDefaultAttributeListNoName
          attributes={productDefaultStyledAttributes}
          listItemClassName="size_extra_data px-0 py-1 d-flex align-items-center"
        />
      </li>
    );

    const productExtraData = (
      <div className="product_extra_data">
        <ProductDefaultAttributeList
          attributes={productDefaultStyledAttributes}
          listItemClassName="px-0 pt-3 d-flex align-items-center"
        />
      </div>
    );

    const isModelDetailsAllInfo =
      item?.modelProfile &&
      item.modelProfile.height &&
      item.modelProfile.bust &&
      item.modelProfile.waist &&
      item.modelProfile.hips &&
      item.modelProfile.imageUrl &&
      item.modelProfile.modelSizeLabel;
    const renderModelInfo = isModelDetailsAllInfo && (
      <ModelDetails
        translation={translation}
        modelProfile={item.modelProfile}
      />
    );

    return (
      <Fragment>
        {isPageIndexable && (
          <Helmet script={[helmetJsonLdProp(seoStructuredProductData)]} />
        )}
        <div className="quickview_divider flex">
          <div
            className={cn("product_top_wish_list", {
              whiteStrip: showWhiteStrip
            })}
          >
            <span className="back_icon" onClick={this.handleGoBack} />
            <p className="productHighlight">
              <span>{item.brandName}</span>
              <span>{item.title}</span>
            </p>
            {isProductAvailableInCountry && (
              <span
                className={cn(
                  "mobile-view",
                  "wishlist-icon",
                  wishlistAnimationClass,
                  {
                    wishlistIcon:
                      !wishlistAnimationClass && this.isPresent(item),
                    wishlistedIcon:
                      !wishlistAnimationClass && !this.isPresent(item)
                  }
                )}
                onClick={e => {
                  if (!wishlistAnimationClass) {
                    this.addToWishList(e, internationalSizes);
                  }
                }}
              />
            )}
          </div>

          {renderSyteButton}

          <ThumbSwiper
            imageList={imageList}
            item={{
              overlayTagLabel: item.overlayTagLabel,
              overlayTagBgColor: item.overlayTagBgColor,
              overlayTagLabelColor: item.overlayTagLabelColor
            }}
            product={item}
            zoom={true}
            height={1232}
            width={822}
            shareButton={true}
            translation={translation}
            handleImageMouseMove={this.handleImageMouseMove}
            handleImageMouseOut={this.handleImageMouseOut}
            zoomedBlockRef={this.zoomedBlockRef}
            language={language}
          />
          <div
            className={cn("item_details_wrapper", {
              forced_one_line: !this.isOneLined(goldLabelPortalRoot)
            })}
            ref={topWishList => (this.topWishListSticky = topWishList)}
          >
            {isHover && (
              <div
                ref={this.zoomedBlockRef}
                className="zoom-container"
                style={{
                  backgroundPosition: backgroundPosition,
                  backgroundImage: `url(${backgroundImage})`
                }}
              />
            )}
            <ContentDescription
              item={item}
              translation={translation}
              currencyCode={currencyCode}
              getBrandProductLink={this.getBrandProductLink}
              commonSettings={commonSettings}
              Redirection={false}
              getProductUrlName={getProductUrlName}
              isProductAvailableInCountry={isProductAvailableInCountry}
              language={language}
              pdpFirstSection={pdpSections?.pdpSectionOne}
              showRetailPrice
            />
            {renderGoldLabel}
            {isProductAvailableInCountry && (
              <div className="item_info">
                {showStockLimit && (
                  <span className="stock_limit">{`${translation.only} ${allowedQuantity} ${translation.itemsInStock}`}</span>
                )}
                {isOutOfStock && (
                  <span className="stock_limit">{translation.outOfStock}</span>
                )}
                <span
                  className="return_info"
                  onClick={this.toggleShippingOptionsModal}
                >
                  {translation.deliveryInfo}
                </span>
                {!!isSizeChart && (
                  <span className="sizing_help" onClick={this.handleSizeChart}>
                    {translation.needSizing}
                  </span>
                )}
              </div>
            )}
            {Boolean(countryId) && (
              <ProductSizeDetails
                isProductAvailableInCountry={isProductAvailableInCountry}
                toggleViewMore={toggleViewMore}
                addSpaceClass={addSpaceClass}
                sizeSelection={sizeSelection}
                selectedSize={selectedSize}
                viewMoreHeight={viewMoreHeight}
                colorSizeOptions={colorSizeOptions}
                onSizeChange={(...args) =>
                  onSizeChange(...args, selectedSizeType)
                }
                selectedSizeLabelInternational={selectedSizeLabelInternational}
                language={language}
                handleSizeSelection={handleSizeSelection}
                showViewMore={showViewMore}
                wrapperRef={this.wrapperRef}
                toggleViewMoreDetails={this.toggleViewMore}
                translation={translation}
                sizeIndication={sizeIndication}
              />
            )}
            {showSizeOptions && (
              <div className="productSizes mobile-view">
                <p
                  onClick={() => {
                    this.handleMultipleSizesModal(true);
                    this.handleSizeDropDown();
                  }}
                >
                  <span className="selected_size">
                    {selectedSizeLabelInternational
                      ? `${selectedSizeLabelInternational}`
                      : `${translation.size}`}
                  </span>
                </p>
                <div
                  className={cn("slideTop", "multipleSizeContainer", {
                    "slideUp fullHeight": openMultipleSizes,
                    slideBack: !openMultipleSizes
                  })}
                  ref={sizeRef => (this.sizeRef = sizeRef)}
                >
                  <div
                    className="backOverlay"
                    onClick={() => this.handleMultipleSizesModal(false)}
                  />
                  <>
                    <p className="sizeText">{translation.selectSize}</p>
                    <span
                      className="mCrossIcon"
                      onClick={() => this.handleMultipleSizesModal(false)}
                    />
                  </>
                  <Scrollbars
                    autoHeight
                    autoHeightMin={120}
                    autoHeightMax={320}
                  >
                    <SlideBottomToTop>
                      {item && item.IsMultisize ? (
                        <div className="multiSizeWrapper">
                          <ProductMultiSize
                            sizeTypes={Object.keys(internationalSizes)}
                            handleChange={this.setSelectedSizeType}
                            colorSizeOption={colorSizeOptions}
                            cartItems={cartItems}
                            id={item.id}
                            onMultiSizeChange={(
                              sizeLabel,
                              sizeStock,
                              defaultAddToCart
                            ) =>
                              this.handleSizeChange(
                                sizeLabel,
                                sizeStock,
                                defaultAddToCart,
                                selectedQty,
                                availableSizes,
                                allowedQuantity,
                                internationalSizes
                              )
                            }
                            selectedSize={selectedSizeLabelInternational}
                            language={language}
                            showStockMobile={true}
                            showStock={false}
                            translation={translation}
                            handleMultipleSizesModal={
                              this.handleMultipleSizesModal
                            }
                            defaultAddToCart={defaultAddToCart}
                            handleAddToCart={this._handleAddToCart}
                            items={item}
                            selectedQty={selectedQty}
                            allowedQuantity={allowedQuantity}
                            firstSizeType={selectedSizeType}
                            handleDefaultAddToCart={this.handleDefaultAddToCart}
                            presentWithoutQuantity={countOfItemInCart}
                            internationalSizes={internationalSizes}
                            productSizeExtraData={productSizeExtraData}
                          />
                          <AdditionalSizeInfo
                            translation={translation}
                            sizeIndication={sizeIndication}
                            handleSizeChart={this.handleSizeChart}
                          />
                        </div>
                      ) : (
                        <div className="one_size_item">
                          <ProductSize
                            cartItems={cartItems}
                            id={item.id}
                            colorSizeOption={colorSizeOptions}
                            selectedSize={selectedSizeLabelInternational}
                            language={language}
                            showStockMobile
                            showStock={false}
                            translation={translation}
                            handleMultipleSizesModal={
                              this.handleMultipleSizesModal
                            }
                            defaultAddToCart={defaultAddToCart}
                            item={item}
                            selectedQty={selectedQty}
                            allowedQuantity={allowedQuantity}
                            firstSizeType={selectedSizeType}
                            handleDefaultAddToCart={this.handleDefaultAddToCart}
                            presentWithoutQuantity={countOfItemInCart}
                            onSizeChange={(
                              sizeLabel,
                              sizeStock,
                              defaultAddToCart
                            ) =>
                              this.handleSizeChange(
                                sizeLabel,
                                sizeStock,
                                defaultAddToCart,
                                selectedQty,
                                availableSizes,
                                allowedQuantity,
                                internationalSizes
                              )
                            }
                            handleAddToCart={this._handleAddToCart}
                            productSizeExtraData={productSizeExtraData}
                          />
                          <AdditionalSizeInfo
                            translation={translation}
                            sizeIndication={sizeIndication}
                            handleSizeChart={this.handleSizeChart}
                          />
                        </div>
                      )}
                    </SlideBottomToTop>
                  </Scrollbars>
                </div>
              </div>
            )}
            {isProductAvailableInCountry && (
              <div className="item_extra_info">
                {showLocationDropdown && (
                  <CustomDropdown
                    name="location"
                    data={Object.keys(internationalSizes).sort()}
                    selectedItem={
                      selectedSizeType ||
                      Object.keys(internationalSizes).sort()[0]
                    }
                    containerClass="location_dropdown"
                    scrollbarMaxHeight={150}
                    handleChange={this.setSelectedSizeType}
                  />
                )}
              </div>
            )}
            {showSelectSizeFirstError && (
              <p className="size_selection_message disPlayNone">
                {translation.sizeSelectionToProceed}
              </p>
            )}
            {qtySelectSize && (
              <p className="qtySizeSelect">{translation.selectSizeFirst}</p>
            )}
            {productExtraData}
            {isProductAvailableInCountry && (
              <div
                className="action_container"
                ref={actionRef => (this.actionRef = actionRef)}
              >
                <div className="quantity">
                  {availableSizes && availableSizes.length && (
                    <em
                      className={
                        selectedSizeLabelInternational !== ""
                          ? "displayNone"
                          : "selectSizeFirst"
                      }
                      onClick={() => this.handleSelectSizeFirst(availableSizes)}
                    />
                  )}
                  <span className="qty_text">{translation.quantity}.</span>
                  {!showQtyTextBox ? (
                    <CustomDropdown
                      data={getQuantityList(allowedQuantity || 1)}
                      defaultSelectValue={selectedItemQuantity}
                      selectedItem={selectedItemQuantity}
                      handleChange={quantity =>
                        onQtyChange(quantity, allowedQuantity)
                      }
                      containerClass={"quantity_dropdown"}
                      scrollbarMaxHeight={150}
                      canDropdownToggle={!isOutOfStock}
                    />
                  ) : (
                    <input
                      type="text"
                      value={selectedQty}
                      className={cn("dropdown-custom-text", {
                        borderRed: errShowQtyTextBox
                      })}
                      onChange={e =>
                        onQtyTextChange(e.target.value, allowedQuantity)
                      }
                      onBlur={() => onBlurChange()}
                      aria-label="Quantity"
                    />
                  )}
                </div>
                <div className="quantitySelectMobile">
                  <div
                    className="qtyInput"
                    onClick={() => this.handleQtyModal(true, availableSizes)}
                  >
                    <span className="qty_text">{translation.quantity}</span>
                    {errShowQtyTextBox
                      ? allowedQuantity >= selectedItemQuantity
                        ? selectedItemQuantity
                        : onQtyChange(1, allowedQuantity)
                      : selectedItemQuantity}
                  </div>
                </div>
                {errShowQtyTextBox && (
                  <span className="error respError">{errShowQtyTextBox}</span>
                )}
                <div
                  className={cn(
                    "slideTop quantityContainer",
                    openQuantity ? "slideUp" : "slideBack"
                  )}
                >
                  <div
                    className="backOverlay"
                    onClick={() => this.handleQtyModal(false)}
                  />
                  <Scrollbars
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={285}
                  >
                    <SlideBottomToTop>
                      <ul className="dropdown_content_container">
                        <li>{translation.selectQuantity}</li>
                        {map(quantityList, item => (
                          <li
                            className={
                              typeof selectedQty === "object" &&
                              selectedQty.value === item.value
                                ? "selected_value"
                                : selectedQty === item
                                ? "selected_value"
                                : null
                            }
                            key={item.key || item}
                            onClick={() => {
                              onQtyChange(item.key || item, allowedQuantity);
                              this.handleQtyModal();
                            }}
                          >
                            {item.value ? ReactHtmlParser(item.value) : item}
                          </li>
                        ))}
                      </ul>
                    </SlideBottomToTop>
                  </Scrollbars>
                </div>
                <div
                  className={cn("slideTop fullHeight itemAddedToCartWrapper", {
                    slideUp: itemAddedToCart,
                    slideBack: !itemAddedToCart
                  })}
                >
                  <div
                    onClick={this.closeSuccessDropDown}
                    className="backOverlay"
                  />
                  <div className="itemAddedToCart">
                    <div className="productName">
                      <p>
                        {item.brandName}
                        <span>{item.title}</span>
                      </p>
                    </div>
                    <div className="productPrice">
                      <p>
                        <span className="item_added">
                          {showCartAdditionSuccessMessage
                            ? cartAdditionSuccessMessage
                            : translation.addedToCart}
                        </span>
                      </p>
                      <PriceSlab
                        item={item}
                        translation={translation}
                        language={language}
                        currencyCode={currencyCode}
                        settings={commonSettings}
                        isFromPdp
                      />
                    </div>
                    <div className="itemAddedCta">
                      <button id="continue_btn" onClick={this.handleGoBack}>
                        {translation.continueShopping}
                      </button>
                      <Link id="view_cart_btn" to={`/${routeSlug}/my-cart/`}>
                        {translation.viewCart}
                      </Link>
                    </div>
                  </div>
                </div>
                <button
                  className={cn("add_to_btn", {
                    added_to_cart:
                      this.isPresentCart(item) &&
                      (showAddedToCardText || addedToCart) &&
                      openAddToCartSuccessPopUp
                  })}
                  onClick={() => {
                    if (!showAddedToCardText && !addedToCart) {
                      this.onAddItem({
                        allowedQuantity
                      });
                    }
                  }}
                  disabled={isOutOfStock || isProcessOfAddingItem}
                >
                  {isSaving && (
                    <Loader
                      type={LOADER_TYPES.RING}
                      width={25}
                      height={25}
                      visible
                      style={loaderStyle}
                    />
                  )}
                  {(!showAddedToCardText && !addedToCart) || isSaving
                    ? translation.addToCart
                    : translation.addedToCart}
                </button>
                <span
                  className={cn("wishlist-icon", wishlistAnimationClass, {
                    wishlistIcon:
                      !wishlistAnimationClass && this.isPresent(item),
                    wishlistedIcon:
                      !wishlistAnimationClass && !this.isPresent(item)
                  })}
                  onClick={e => {
                    if (!wishlistAnimationClass) {
                      this.addToWishList(e, internationalSizes);
                    }
                  }}
                />
                {errShowQtyTextBox && (
                  <span className="error disPlayNone">{errShowQtyTextBox}</span>
                )}
              </div>
            )}
            {!isMobile.any() && renderModelInfo}
            {isDiscoveryBannerEnabled && (
              <div
                className={cn("pdp_discovery_banner", {
                  arabic: language.includes("ar")
                })}
              >
                <SyteDiscoveryBanner
                  sku={item.id}
                  productImgPath={`${configCdnImagesSettings?.main}${item?.captionImageURL}`}
                  placement="pdp-discovery-banner"
                  handleClick={this.handleSyteSearch}
                  details={item}
                />
              </div>
            )}
            {isMobile.any() && renderModelInfo}
            {isProductAvailableInCountry && (
              <InterestFreeInstallmentsSection
                item={item}
                translation={translation}
                isTabbySupported={this.isTabbyInstallmentSupported()}
                isPostPaySupported={this.isPostPayInstallmentSupported()}
                isTamaraSupported={this.isTamaraInstallmentSupported()}
              />
            )}
            <RelatedProducts
              handleChangeProduct={this.changeProduct}
              item={item}
              url={configCdnImagesSettings}
              routeSlug={routeSlug}
              translation={translation}
            />
            {pdpSections.pdpSectionTwo &&
              pdpSections.pdpSectionTwo.map((section, key) => (
                <DynamicBannersWrapper
                  section={section}
                  key={key}
                  bannerPageTitle={section.sectionTitle}
                />
              ))}

            <ProductQualityDetail
              attributes={item.attributes}
              productDetails={item.productDetails}
              moreDetails={item.moreDetails}
              translation={translation}
              itemId={item.id}
            />
            <div className="mobile-view productExtraInfo">
              <div
                className={`productInformation ${
                  openAccordion ? "openProdInfo" : "closeProdInfo"
                }`}
              >
                <div className={`title`} onClick={this.handleToggleAccordion}>
                  <h5>{translation.productInformation}</h5>
                </div>
                <div className="content">
                  <ProductQualityDetail
                    attributes={item.attributes}
                    productDetails={item.productDetails}
                    moreDetails={item.moreDetails}
                    translation={translation}
                    itemId={item.id}
                  />
                </div>
              </div>
              <div className="productDelivery">
                <div
                  className="title"
                  onClick={() => this.handleDeliveryReturnModal(true)}
                >
                  <h5>{translation.deliveryAndShippingInfo}</h5>
                </div>
              </div>
              {deliveryReturnModal && (
                <DeliveryAndReturn
                  translation={translation}
                  closeDeliveryReturnModal={this.handleDeliveryReturnModal}
                  modalData={uniqDeliverySettings}
                  commonSettings={commonSettings}
                  showCheckBox={false}
                />
              )}

              {!!isSizeChart && (
                <div className="sizeChart">
                  <div className="title" onClick={this.handleSizeChart}>
                    <h5>{translation.sizeGuide}</h5>
                  </div>
                </div>
              )}

              {this.getInfoForLoyaltyAlert()}

              <button
                className="scrollToTop"
                onClick={() => handleScrollTop("smooth")}
              >
                {translation.scrollToTop}
              </button>
            </div>
          </div>
          {showShippingOptions && (
            <ShippingOptions
              commonSettings={commonSettings}
              handleToggleModal={this.toggleShippingOptionsModal}
              isModalShow={showShippingOptions}
              language={language}
              modalData={uniqDeliverySettings}
              routeSlug={routeSlug}
              translation={translation}
            />
          )}
          {isSizeChart && (
            <SizeChart
              activeTab={activeTab}
              handleSizeChartPopup={this.handleSizeChartPopup}
              isBrandType={isBrandType}
              isSizeChart={isSizeChart}
              isSizeChartPopupActive={isSizeChartPopupActive}
              sizeChart={sizeChart}
            />
          )}
        </div>
        {quantityOverflowMessage && (
          <DeleteModal
            bodyTxt={quantityOverflowMessageBody}
            cancelBtnTxt={translation.Ok}
            handleCloseModal={this.handleCloseModal}
            isDeleteModalActive={quantityOverflowModal}
            language={language}
          />
        )}
        {maxQuantityAddedMessage && (
          <DeleteModal
            bodyTxt={maxQuantityAddedMessageBody}
            cancelBtnTxt={translation.Ok}
            goToCart={() => this.goToCart()}
            handleCloseModal={this.handleCloseModal}
            isDeleteModalActive={quantityOverflowModal}
            language={language}
            titleTxt={translation.alert}
            viewCart={translation.viewCart}
          />
        )}
      </Fragment>
    );
  }
}

const parent = {
  0: "home",
  1: "men",
  2: "women",
  3: "kids"
};

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "rgb(255 255 255 / 75%)"
};

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  available: state.productDetailReducer.productDetail.available,
  cartItems: state.cartReducer.cart,
  commonSettings: state.common.settings,
  countryId: state.common.settings.countryId,
  isPageIndexable: state.common.isPageIndexable,
  isSaving: state.cartReducer.isSaving,
  routeSlug: selectRouteSlug(state),
  selectedSizeType: state.common.selectedSizeType,
  sizeChart: state.productDetailReducer.sizeChart,
  sizeSelection: state.productDetailReducer.sizeSelection,
  userLoggedIn: state.authReducer.userLoggedIn,
  wishListAndCartBaseDataLoaded: state.cartReducer.wishListAndCartGeneralLoaded,
  deleteWishListItemLoaded: state.cartReducer.deleteWishListItemLoaded,
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  accessToken: state.authReducer.accessToken,
  currency: state.common.settings.currencyCode,
  pdpSections: selectPDPBannerSections(state),
  configCdnImagesSettings: selectCdnImageSettings(state),
  configSyteSettings: selectSyteSettings(state)
});

const mapDispatchToProps = {
  fetchSizeChart,
  handleSizeSelection,
  setSizeType,
  fetchCartData,
  getLoyalityPoints
};

export default compose(
  withDeliverySettingsFetching,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddToCart);
