import React, { Fragment } from "react";

const FilterFooter = ({ translation, closeFilter, products }) => (
  <Fragment>
    <button className="m-filter_footer" onClick={closeFilter}>
      {translation && translation.show} {products.nbHits}{" "}
      {translation && translation.results}
    </button>
  </Fragment>
);

export default FilterFooter;
