import React, { Component, Fragment } from "react";
import MobileMenuCategories from "../mobile-menu-categories";
import AccountSettings from "../../menu-account-settings";
import BrandMenu from "../../brands/brand-menu";

class MobileMenu extends Component {
  state = { show: true, showLanguage: false };

  handleToggle = () => ({});

  handleMobileCategory = page => {
    this.setState({ show: page !== 1 ? false : true });
  };

  toggleLanguageList = value => {
    this.setState({ showLanguage: value });
  };

  openLink = redirectionLink => {
    let redirectionUrl = redirectionLink;
    const { closeMenu, history, language, countryShort } = this.props;
    if (
      redirectionLink &&
      redirectionLink[redirectionLink.length - 1] !== "/"
    ) {
      redirectionUrl = redirectionLink + "/";
    }
    history.push(
      `/${language}-${(countryShort && countryShort.toLowerCase()) ||
        "ae"}${redirectionUrl}`
    );
    closeMenu();
  };

  render() {
    const {
      language,
      authReducer: { userLoggedIn, firstname, lastname },
      countryCode,
      countryShort,
      closeMenu,
      openCountryModal,
      logOut,
      toggleLanguage,
      megaMenuTranslation
    } = this.props;
    const { show, showLanguage } = this.state;
    return (
      <Fragment>
        <div
          className={`mega_menu_wrapper ${
            language.includes("ar-") || language.includes("ar")
              ? "arabic"
              : "english"
          }`}
        >
          {show &&
            !showLanguage && (
              <div
                className={`profile_desc ${userLoggedIn ? "logged_user" : ""}`}
              >
                {userLoggedIn ? (
                  <h3>
                    {megaMenuTranslation.hi}, {firstname} {lastname}
                  </h3>
                ) : (
                  <div className="user_profile">
                    <p className="login">
                      <span onClick={() => this.openLink("/login/")}>
                        {megaMenuTranslation.signIn}
                      </span>
                      <span className="vertical_separator" />
                      <span onClick={() => this.openLink("/sign-up")}>
                        {megaMenuTranslation.signUp}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          {!showLanguage && (
            <MobileMenuCategories
              pageChange={this.handleMobileCategory}
              openLink={this.openLink}
            />
          )}
          {show &&
            !showLanguage && (
              <div className="mm_stores">
                <h3>{megaMenuTranslation.shopByStore}</h3>
                <BrandMenu
                  handleToggle={this.handleToggle}
                  brandMegaMenu={true}
                  closeMenu={closeMenu}
                  handleSelectStore={() => {}}
                />
              </div>
            )}
          {(show || showLanguage) && (
            <AccountSettings
              userLoggedIn={userLoggedIn}
              language={language}
              countryCode={countryCode}
              countryShort={countryShort}
              openLink={this.openLink}
              openCountryModal={openCountryModal}
              closeMenu={closeMenu}
              logOut={logOut}
              toggleLanguageList={this.toggleLanguageList}
              toggleLanguage={toggleLanguage}
              megaMenuTranslation={megaMenuTranslation}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default MobileMenu;
