import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import size from "lodash/size";
import map from "lodash/map";
import get from "lodash/get";
import { selectRouteSlug } from "../../util/selectors";
import FooterNewsletter from "./footer-newsletter";
import FooterAcceptMoney from "./footer-accept-money";
import AppDownloads from "./app-download-options";
import FindAStore from "../../images/find-a-store.svg";
import FindAStoreIcon from "../../images/find-a-store-small-icon.svg";
import { STORE_LOCATOR_LINK } from "../../constants/common";
import CountrySelector from "./country-selector";
import { GAService } from "../../services/GA-service";

const FooterWeb = props => {
  const { footerTranslation, preferencesTranslation } = props;
  const footerList = useSelector(state => state.footerReducer.footerList);
  const language = useSelector(state => state.common.language);
  const settings = useSelector(state => state.common.settings);
  const staticContent = useSelector(state => state.footerReducer.staticContent);
  const routeSlug = useSelector(selectRouteSlug);
  const countryShort = get(settings, "countrySHORT", "ae").toLowerCase();

  const renderChildItemTitle = childItem => {
    if (childItem.redirectionLink === STORE_LOCATOR_LINK) {
      return (
        <div className="store-locator-sec">
          <img src={FindAStore} className="find-a-store" />
          {childItem.title}
          <img src={FindAStoreIcon} className="find-a-store-gps" />
        </div>
      );
    } else {
      return childItem.title;
    }
  };

  return (
    <>
      <section className="main-footer">
        <div className="container">
          <div className="row">
            <div className="first-column">
              <section className="footer-links">
                {size(footerList) &&
                  map(footerList, item => (
                    <section key={item.uid} className="footer-col alignRight">
                      <p className="heading">
                        {item.children && !!item.children.length ? (
                          item.title
                        ) : (
                          <Link to={`/${routeSlug}${item.redirectionLink}`}>
                            {item.title}
                          </Link>
                        )}
                      </p>
                      {size(item) && (
                        <ul className="footer_anim">
                          {map(item.children, childItem => {
                            const redirectionLink =
                              childItem.redirectionLink.trim();
                            const childItemLink = `/${routeSlug}${redirectionLink}`;
                            return (
                              <li key={childItem.uid}>
                                <Link
                                  to={childItemLink}
                                  onClick={() => {
                                    GAService.footer.trackFooterMenuClick({
                                      parent: item,
                                      clickItem: childItem,
                                      clickLink: childItemLink
                                    });
                                  }}
                                >
                                  {renderChildItemTitle(childItem)}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </section>
                  ))}
              </section>
            </div>

            <div className="second-column">
              <FooterNewsletter footerTranslation={footerTranslation} />
              <FooterAcceptMoney
                defaultCountry={settings}
                footerTranslation={footerTranslation}
                footerWeb={true}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div className="row footer-bottom-layout">
            <AppDownloads />
          </div>
          <div className="row footer-bottom-country-selector">
            <CountrySelector
              translation={preferencesTranslation}
              footerTranslation={footerTranslation}
            />
            <p className="copyright">{staticContent.copyRight}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterWeb;
