import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { UserDetailsService } from "../../../services";
import { SOCIAL_SIGNUP_MODES } from "../../../constants";
const GoogleSignInBtn = ({
  socialCallback,
  buttonLabel,
  className = "google"
}) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const googleUserInfo = await UserDetailsService.getGoogleUserData(
        tokenResponse.access_token
      );
      socialCallback(SOCIAL_SIGNUP_MODES.GOOGLE, googleUserInfo.data);
    },
    onError: errorResponse =>
      socialCallback(SOCIAL_SIGNUP_MODES.GOOGLE, errorResponse)
  });
  return (
    <button
      onClick={() => googleLogin()}
      className={className}
      data-label={SOCIAL_SIGNUP_MODES.GOOGLE}
    >
      {buttonLabel}
    </button>
  );
};
export default GoogleSignInBtn;
