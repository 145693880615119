import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

import { selectRouteSlug } from "../../util/selectors";
import { GAService } from "../../services/GA-service";

const JoinSignToggle = ({ loginButtonText, signupButtonText, routeSlug }) => {
  const location = useLocation();

  return (
    <div className="login-toggle">
      <Link
        to={`/${routeSlug}/login/`}
        className={cn("login-form-button", {
          active: location.pathname.includes("login")
        })}
        replace
        onClick={() => GAService.auth.trackSignCTA(true)}
      >
        {loginButtonText}
      </Link>

      <Link
        to={`/${routeSlug}/sign-up/`}
        className={cn("login-form-button", {
          active: location.pathname.includes("sign-up")
        })}
        replace
        onClick={() => GAService.auth.trackSignCTA(false)}
      >
        {signupButtonText}
      </Link>
    </div>
  );
};

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(JoinSignToggle);
