import React, { Component, Fragment } from "react";
import WishlistItem from "./wishlist-item";

class WishlistContent extends Component {
  render() {
    const {
      wishlistSize,
      productData,
      language,
      productTranslation,
      deleteWishListData,
      getSizeValue,
      addToCart,
      moveToProductDetail,
      isShow,
      saveItem,
      handleGoBack
    } = this.props;
    return (
      <Fragment>
        <div
          className={`heading_container mob_whislistHeading ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <div className="content_heading content_mobile_heading">
            <div className="container padding0">
              <h2>
                {productTranslation && productTranslation.myWishlist}
                {wishlistSize === 1 ? (
                  <span>
                    {wishlistSize}{" "}
                    {productTranslation && productTranslation.item}
                  </span>
                ) : (
                  <span>
                    {wishlistSize}{" "}
                    {productTranslation && productTranslation.items}
                  </span>
                )}
                <i className="icon_cross" onClick={handleGoBack} />
              </h2>
            </div>
          </div>
        </div>
        <div
          className={`wishlist_content ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <div
            className={`wishlist_items_wrapper  ${!isShow ? "viewLess" : ""}`}
          >
            {productData &&
              productData.length &&
              productData.map((item, index) => (
                <WishlistItem
                  item={item}
                  key={item._id}
                  productTranslation={productTranslation}
                  deleteWishListData={deleteWishListData}
                  getSizeValue={getSizeValue}
                  addToCart={(...args) => addToCart(...args, index)}
                  moveToProductDetail={moveToProductDetail}
                  saveItem={saveItem}
                  showVat={false}
                />
              ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WishlistContent;
