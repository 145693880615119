import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  facebookAppId,
  googleAppId,
  appleId,
  appleRedirectUri,
  facebookSDKVersion
} from "./../../config/app";
import {
  hideModal,
  setRegistrationDataStatus,
  setSocialPhoneCheckToken,
  authenticateUser,
  handleTextChange,
  setSocialLoginFlow,
  setAnalyticsData
} from "../../redux/actions/homepage.action";
import AnalyticService from "../../services/analytic-service";
import {
  IS_AUTH_FROM_CHECKOUT,
  SIGNUP_CODES_FOR_MODAL,
  CHECKOUT_ID,
  SOCIAL_SIGNUP_MODES
} from "../../constants";

import {
  fetchUserAndLoadData,
  setPrevPath
} from "../../redux/actions/authConfig.action";
import { fetchCartData } from "../../redux/actions/cart.action";
import { removeDeliverySettings } from "../../redux/actions/common.action";
import SocialMobileNumberModal from "./modal-mobile-verification/social-mobile-number-modal";
import {
  selectSocialLoginFlow,
  selectRouteSlug,
  selectCountrySlug
} from "../../util/selectors";
import { getDecodedJWT } from "../../util/jwt";
import { API_ERROR_MESSAGES, MAX_API_RECALL_COUNT } from "../../constants/api";
import GoogleSignInBtn from "../google/google-sign-in";
import { GAService } from "../../services/GA-service";

const SocialLoginModal = ({
  translation,
  textFor,
  language,
  socialTextChange,
  history,
  loginUser,
  hideModal,
  changeCustomerId,
  fetchUserAndLoadData,
  redirection,
  setPrevPath,
  setRegistrationDataStatus,
  setSocialPhoneCheckToken,
  setSocialLoginFlow,
  socialLoginFlow,
  routeSlug,
  fetchCartData,
  countrySlug,
  removeDeliverySettings,
  setAnalyticsData
}) => {
  const prevPath = useSelector(state => state.authReducer.prevPath);
  const settings = useSelector(state => state.common.settings);

  /**
   * Returns the email of apple ID user
   * @param {object} authorization
   * @param {object} user
   * @returns {string}
   */
  const getAppleUserEmail = (authorization, user) => {
    if (user && user.email) {
      return user.email;
    } else {
      const decodedToken = getDecodedJWT(authorization.id_token);
      return decodedToken?.email || "";
    }
  };

  const login = ({
    email,
    mode,
    password,
    firstname,
    countryId,
    response,
    reloadCount = 0
  }) => {
    loginUser(
      {
        email,
        mode,
        password,
        firstname,
        countryId
      },
      history,
      language,
      false,
      countrySlug
    ).then(resp => {
      const code = resp?.data?.code;
      if (SIGNUP_CODES_FOR_MODAL[code]) {
        setRegistrationDataStatus(resp?.data);
        setSocialLoginFlow(true);
        const { token } = resp?.data;
        if (token) {
          setSocialPhoneCheckToken(token);
        }
        setAnalyticsData({
          response: { ...response, email: email },
          settings,
          resp,
          loginFrom: mode
        });
      } else if (resp?.data && !resp.data.success) {
        return;
      } else if (resp === API_ERROR_MESSAGES.NETWORK_ERROR) {
        if (reloadCount <= MAX_API_RECALL_COUNT) {
          login({
            email,
            mode,
            password,
            firstname,
            countryId,
            response,
            reloadCount: reloadCount + 1
          });
        }
      } else {
        // normal social signin
        GAService.auth.trackLoginSuccess({
          mode,
          user_id: resp.data?.customerId,
          isLoggedIn: resp.data?.success
        });
        AnalyticService.auth.trackSocialLogin({
          response: { ...response, email: email },
          settings,
          resp,
          loginFrom: mode
        });
        fetchUserAndLoadData();
        if (changeCustomerId) {
          changeCustomerId(resp?.data?.customerId);
          return;
        }
        if (localStorage.getItem(IS_AUTH_FROM_CHECKOUT)) {
          removeDeliverySettings();
          history.push(
            `/${routeSlug}/checkout/${localStorage.getItem(CHECKOUT_ID)}/`
          );
          return;
        }

        if (prevPath && setPrevPath) {
          removeDeliverySettings();
          history.push(prevPath);
          setPrevPath && setPrevPath("");
          return;
        }
        if (redirection) history.push(redirection);
      }
    });
  };

  const appleCallback = response => {
    const { authorization, user } = response;
    const redirectUrl = `/${routeSlug}/sign-up/`;
    if (authorization && authorization?.id_token) {
      login({
        email: getAppleUserEmail(authorization, user),
        mode: SOCIAL_SIGNUP_MODES.APPLE,
        password: getDecodedJWT(authorization.id_token).sub,
        firstname: user?.name?.firstName || "signUp",
        countryId: settings.countryId || 236,
        response
      });
    } else {
      socialTextChange("firstname", "", "signUp");
      hideModal();
      history.push(redirectUrl);
    }
  };

  const socialCallback = (loginFrom, response) => {
    response = response.profileObj || response;
    const redirectUrl = `/${routeSlug}/sign-up/`;
    if (!response.status && !response.email) {
      socialTextChange("firstname", response.name || "", "signUp");
    } else if (response && !response.email && !response.error) {
      socialTextChange("firstname", response.name || "", "signUp");
      hideModal();
      history.push(redirectUrl);
    } else if (response && response.email && !response.error) {
      login({
        email: response.email,
        mode: loginFrom,
        password: response.userID || response.sub,
        firstname: response.name,
        countryId: settings.countryId || 236,
        response
      });
    }
  };

  const handleSocialLoginClick = e => {
    const target = e.target;
    if (target.tagName === "BUTTON" && target.hasAttribute("data-label")) {
      const method = target.getAttribute("data-label");
      if (textFor === "login") {
        GAService.auth.trackSignInMethod(method);
      } else {
        GAService.auth.trackJoinMethod(method);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    document.body.appendChild(script);
    script.onload = () => {
      window.AppleID.auth.init({
        clientId: appleId,
        scope: "name email",
        redirectURI: appleRedirectUri,
        state: "Initial user authentication request",
        // nonce : '[NONCE]',
        usePopup: true
      });
    };
  }, []);

  return (
    <>
      {socialLoginFlow && <SocialMobileNumberModal />}
      <div className="social_media_login">
        {textFor === "login" ? (
          <>
            <p className="webCheckoutSign">{translation.loginSocialMedia}</p>
            <p className="mCheckoutSign loginJoinText">
              <Link to={`/${routeSlug}/forgot-password/`}>
                {translation.forgotPassword}
              </Link>
              /<Link to={`/${routeSlug}/sign-up/`}>{translation.join}</Link>
            </p>
          </>
        ) : (
          <>
            <p className="webCheckoutSign">{translation.joinSocialMedia}</p>
            <p className="mCheckoutSign loginJoinText">
              <Link to={`/${routeSlug}/forgot-password/`}>
                {translation.forgotPassword}
              </Link>
              /<Link to={`/${routeSlug}/login/`}>{translation.signIn}</Link>
            </p>
          </>
        )}

        <div className="mCheckoutSign socialLogin">
          <p className="checkoutSeparator">
            <span>{translation.OR}</span>
          </p>
          <p>{translation.signInWithSocialAccount}</p>
        </div>
        <div className="social_btn_wrap" onClick={handleSocialLoginClick}>
          <FacebookLogin
            version={facebookSDKVersion}
            appId={facebookAppId}
            fields="name,email,picture"
            callback={response =>
              socialCallback(SOCIAL_SIGNUP_MODES.FACEBOOK, response)
            }
            disableMobileRedirect={true}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                className="facebook"
                data-label={SOCIAL_SIGNUP_MODES.FACEBOOK}
              >
                {translation.facebook}
              </button>
            )}
          />
          <GoogleSignInBtn
            socialCallback={socialCallback}
            buttonLabel={translation.google}
          />
          <button
            onClick={async () => {
              try {
                const data = await window.AppleID.auth.signIn();
                appleCallback(data);
              } catch (error) {
                console.log({ error });
              }
            }}
            className="apple"
            data-label={SOCIAL_SIGNUP_MODES.APPLE}
          >
            {translation.apple}
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  socialLoginFlow: selectSocialLoginFlow(state),
  routeSlug: selectRouteSlug(state),
  currencyCode: state.common.settings.currencyCode,
  countrySlug: selectCountrySlug(state)
});

const mapDispatchToProps = dispatch => ({
  setPrevPath: bindActionCreators(setPrevPath, dispatch),
  loginUser: bindActionCreators(authenticateUser, dispatch),
  fetchUserAndLoadData: bindActionCreators(fetchUserAndLoadData, dispatch),
  hideModal() {
    dispatch(hideModal());
  },
  socialTextChange(name, value, modelName) {
    dispatch(handleTextChange(name, value, modelName));
  },
  setRegistrationDataStatus: bindActionCreators(
    setRegistrationDataStatus,
    dispatch
  ),
  setSocialPhoneCheckToken: bindActionCreators(
    setSocialPhoneCheckToken,
    dispatch
  ),
  setSocialLoginFlow: bindActionCreators(setSocialLoginFlow, dispatch),
  setAnalyticsData: bindActionCreators(setAnalyticsData, dispatch),
  fetchCartData: bindActionCreators(fetchCartData, dispatch),
  removeDeliverySettings: bindActionCreators(removeDeliverySettings, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginModal);
