import React, { Component } from "react";

import ProductCard from "../size-chart-productCard";
import List from "../size-chart-list";
import { GENERIC_SIZE_CHART } from "../../../constants";
import SizeChartPopup from "../size-chart-popup";
import Html from "../size-chart-popupHtml";
import { isMobile } from "../../../util";

class GeneralGuide extends Component {
  state = {
    isAccordionActive: false,
    imgBlockSubCategory: ""
  };

  handleAccordion = () => {
    this.setState({
      isAccordionActive: !this.state.isAccordionActive
    });
  };
  handleImgBlockSubCatName = name => {
    this.setState({
      imgBlockSubCategory: name
    });
  };

  handleListItemClick = item => {
    const { activeTab, genericSizeChart, onListItemClick } = this.props;
    const clothing = genericSizeChart[activeTab].find(
      each => each.name === GENERIC_SIZE_CHART.clothing
    );

    onListItemClick({
      click_text: item.heading,
      size_chart_tree: `${activeTab} > ${clothing.heading} > ${item.heading}`
    });
  };

  render() {
    const {
      handlePopup,
      genericSizeChart,
      activeTab,
      handleSizeChart,
      guideType,
      isPopupActive,
      popupTitle,
      PopUpHtmlList,
      setSearchText,
      searchTxt
    } = this.props;

    const { isAccordionActive, imgBlockSubCategory } = this.state;

    const isMob = !!(isMobile.any() && isMobile.any().length);

    let clothing, imageBlock1, imageBlock2, imageBlock3;
    !!genericSizeChart &&
      genericSizeChart[activeTab] &&
      genericSizeChart[activeTab].forEach(item => {
        switch (item.name) {
          case GENERIC_SIZE_CHART.clothing:
            clothing = item;
            break;
          case GENERIC_SIZE_CHART.imageBlock1:
            imageBlock1 = item;
            break;
          case GENERIC_SIZE_CHART.imageBlock2:
            imageBlock2 = item;
            break;
          case GENERIC_SIZE_CHART.imageBlock3:
            imageBlock3 = item;
            break;
          default:
            return null;
        }
      });

    const ClothingImgUrl = !!clothing && clothing.imageUrl;
    const imageBlock1Url = !!imageBlock1 && imageBlock1.imageUrl;
    const imageBlock2Url = !!imageBlock2 && imageBlock2.imageUrl;
    const imageBlock3Url = !!imageBlock3 && imageBlock3.imageUrl;

    let filterList =
      !!clothing &&
      !!clothing.list &&
      !!clothing.list.length &&
      clothing.list.filter(
        item =>
          item.heading.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1
      );
    return (
      <>
        <div className="sizeCard_wrapper general">
          {ClothingImgUrl && (
            <div
              className="sizeChart_wrapper_img"
              style={{ backgroundImage: `url(${ClothingImgUrl})` }}
            />
          )}
          <div className="general_sizes">
            <p className="sizeCard_title">{clothing && clothing.heading}</p>
            <div className="sizes_list">
              <p className="sizes_list_title">
                {clothing && clothing.subHeading}
              </p>
              {!isMob &&
                clothing &&
                clothing.list &&
                !!clothing.list.length && (
                  <ul>
                    {clothing.list.map((item, index) => (
                      <List
                        brandName={clothing.name}
                        brandId={index}
                        key={index}
                        handlePopup={handlePopup}
                        handleItemClick={() => this.handleListItemClick(item)}
                        handleSizeChart={handleSizeChart}
                        guideType={guideType}
                        bodyText={item.heading}
                        popupHeading={clothing.heading}
                      />
                    ))}
                  </ul>
                )}
            </div>
          </div>
          {isMob && !!filterList && (
            <div className="mob_list">
              <div
                className={`accordion_trigger ${
                  isAccordionActive ? "open" : ""
                }`}
                onClick={this.handleAccordion}
              >
                <i className="sizeChart_angle_down" />
              </div>
              {isAccordionActive && (
                <>
                  <div className="brand-search">
                    <input
                      type="text"
                      name="searchText"
                      className="search-text-area"
                      value={searchTxt || ""}
                      onChange={e => setSearchText(e.target.value, guideType)}
                      placeholder="Search for brands"
                      aria-label="Search for brands"
                    />
                    <span className="search_icon" />
                  </div>
                  <div className="brands_list mobile">
                    <ul>
                      {!!filterList &&
                        !!filterList.length &&
                        filterList.map((item, index) => (
                          <List
                            brandName={clothing.name}
                            brandId={index}
                            key={index}
                            handlePopup={handlePopup}
                            handleItemClick={() =>
                              this.handleListItemClick(item)
                            }
                            handleSizeChart={handleSizeChart}
                            guideType={guideType}
                            bodyText={item.heading}
                            popupHeading={clothing.heading}
                          />
                        ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="productWise_sizes">
            {imageBlock1 &&
              imageBlock1.list &&
              !!imageBlock1.list.length &&
              imageBlock1.list.map((item, index) => (
                <ProductCard
                  handlePopup={handlePopup}
                  handleSizeChart={handleSizeChart}
                  bgImage={imageBlock1Url}
                  title={imageBlock1.heading}
                  subHeading={imageBlock1.subCategoryName}
                  key={index}
                  brandId={index}
                  guideType={guideType}
                  brandName={imageBlock1.name}
                  handleImgBlockSubCatName={this.handleImgBlockSubCatName}
                  popupHeading={imageBlock1.heading}
                />
              ))}
            {imageBlock2 &&
              imageBlock2.list &&
              !!imageBlock2.list.length &&
              imageBlock2.list.map((item, index) => (
                <ProductCard
                  handlePopup={handlePopup}
                  handleSizeChart={handleSizeChart}
                  bgImage={imageBlock2Url}
                  marginClass={`mtb_10`}
                  title={imageBlock2.heading}
                  subHeading={imageBlock2.subCategoryName}
                  key={index}
                  brandId={index}
                  guideType={guideType}
                  brandName={imageBlock2.name}
                  handleImgBlockSubCatName={this.handleImgBlockSubCatName}
                  popupHeading={imageBlock2.heading}
                />
              ))}
            {imageBlock3 &&
              imageBlock3.list &&
              !!imageBlock3.list.length &&
              imageBlock3.list.map((item, index) => (
                <ProductCard
                  handlePopup={handlePopup}
                  handleSizeChart={handleSizeChart}
                  bgImage={imageBlock3Url}
                  marginClass={`mtb_10`}
                  title={imageBlock3.heading}
                  subHeading={imageBlock3.subCategoryName}
                  key={index}
                  brandId={index}
                  guideType={guideType}
                  brandName={imageBlock3.name}
                  handleImgBlockSubCatName={this.handleImgBlockSubCatName}
                  popupHeading={imageBlock3.heading}
                />
              ))}
          </div>
        </div>
        {!!PopUpHtmlList && (
          <SizeChartPopup
            active={isPopupActive}
            handlePopup={handlePopup}
            activeTab={activeTab}
            popupTitle={popupTitle}
          >
            <Html
              title={`${PopUpHtmlList.heading} ${
                !!imgBlockSubCategory ? `- ${imgBlockSubCategory}` : ""
              }`}
              html={PopUpHtmlList.html}
            />
          </SizeChartPopup>
        )}
      </>
    );
  }
}

export default GeneralGuide;
