import React from "react";
import moment from "moment";
import get from "lodash/get";
import template from "lodash/template";
import ReactHTMLParser from "react-html-parser";
import * as priceUtil from "../../../util/priceUtil";
import { renderPaymentModeMessage } from "../../../util/index";

const OrderSuccessSummary = props => {
  const {
    orderSuccessTranslation,
    order,
    history,
    language,
    continueAsGuest,
    countryShort
  } = props;
  const paymentMode = get(order, "paymentMode", []);
  const paymentExtraInfo = get(order, "paymentExtraInfo", "");
  return (
    <div className="order_summary_wrapper">
      <div className="order_success_grid">
        <div className="order_col">
          <h5>{orderSuccessTranslation.orderID}</h5>:
          <p>
            {order.orderId}
            {!continueAsGuest ? (
              <span
                className="order_blue_font"
                onClick={() =>
                  history.push(
                    `/${language}-${
                      (countryShort && countryShort.toLowerCase()) || "ae"
                    }/my-account/my-orders/`
                  )
                }
              >
                {orderSuccessTranslation.viewOrder}
              </span>
            ) : null}
          </p>
        </div>
        <div className="order_col">
          <h5>{orderSuccessTranslation.totalAmount}</h5>:
          <p>
            {order.currencyCode}{" "}
            {priceUtil.shapePriceWithComma(order.transactionAmount)}
            {/*<span className="vat_price">({orderSuccessTranslation.IncOfVat})</span>*/}
          </p>
        </div>
        {ReactHTMLParser(
          template(orderSuccessTranslation.orderPlacedOnTemplate)({
            date: moment.utc(order.createDate).format("LL"),
            time: moment.utc(order.createDate).format("LT")
          })
        )}
      </div>
      <div className="order_success_grid payment_mode">
        <h5>
          {orderSuccessTranslation.paymentMode}
          <strong>:</strong>
        </h5>
        <p>
          {renderPaymentModeMessage(
            paymentMode,
            paymentExtraInfo,
            orderSuccessTranslation
          )}
        </p>
      </div>
      <div className="order_success_grid deliveryMode">
        <h5>
          {orderSuccessTranslation.deliveryMode}
          <strong>:</strong>
        </h5>
        <p> {orderSuccessTranslation[order.deliveryType]}</p>
        {order.deliveryDate ? (
          <p className="green_text">
            {orderSuccessTranslation.estimatedDelivery}{" "}
            {moment(order.deliveryDate).format("Do MMM, YYYY")}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default OrderSuccessSummary;
