import { ORDER_HANDED_TO_SHIPPER } from "../../constants";

export const getReturnWebUrl = ({ returnWebUrl, order, language }) => {
  let url = null;
  if (returnWebUrl) {
    url = returnWebUrl.replace("LANGUAGE", language);
    url = replaceUrlQueryParamValues(url, order);
  }
  return url;
};
export const replaceUrlQueryParamValues = (url, data) => {
  Object.keys(data).forEach(key => {
    if (url.includes(`api__${key}__`)) {
      url = url.replace(`api__${key}__`, data[key]);
    }
  });
  return url;
};

//Should be refactored
export const getTrackOrderWebUrl = ({
  trackOrderWebUrl,
  trackOrderDetails,
  language
}) => {
  let url = null;
  if (trackOrderWebUrl) {
    url = trackOrderWebUrl.replace("LANGUAGE", language);
    url = replaceUrlQueryParamValues(url, trackOrderDetails);
  }
  return url;
};

export const checkIsOrderHandedToShipper = orderStatusDetails =>
  orderStatusDetails?.find(
    order => order.statusMessage === ORDER_HANDED_TO_SHIPPER
  )?.status;
