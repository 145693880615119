import React from "react";
import BounceLoader from "../../components/bounce-loader";
import { withPageTypeTracking } from "../../hocs";

const PaymentProcessing = () => (
  <div className="payment-processing">
    <div>
      <BounceLoader color="#f8d000" size={10} qty={3} />
    </div>
    <div className="text">
      <div>Processing ...</div>
      <div>Please do not press back or refresh the page</div>
    </div>
  </div>
);

export default withPageTypeTracking(PaymentProcessing);
