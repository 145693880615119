import { _countryStoreSelection, languageFromPathName } from "../../util";
import { getStoreLocatorList } from "../../redux/actions/storeLocator.action";
import {
  getCountrySettings,
  setCommonData,
  changeLanguage,
  getConfigurationSettings
} from "../../redux/actions/common.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getPageApi } from "../../redux/actions/page.action";
import { BROWSER_STORAGE } from "../../redux/constants";
import { handleSectionsTask } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;
      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  },
  settings: {
    asyncTask: ({ store, params = {}, url }) => {
      const { language } = params;
      return store.dispatch(
        getCountrySettings(languageFromPathName(language), null, url)
      );
    },
    priority: 2
  },
  fetchHomePage: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getPageApi(
          "/",
          language,
          _country && _country.countryId,
          storeId || _bflStoreId,
          null,
          cookies
        )
      );
    },
    priority: 4
  },
  fetchMegaMenu: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      if (
        url.includes("?prevMode=") ||
        url.includes("&prevMode=") ||
        cookies["prev_mode"]
      ) {
        return Promise.resolve();
      }
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getMegaMenuData(
          languageFromPathName(language),
          storeId || _bflStoreId,
          _country && _country.countryId,
          cookies
        )
      );
    },
    priority: 5
  },
  getStoreLocatorList: {
    asyncTask: ({ store, params = {} }) => {
      const { common } = store.getState();
      const { language, settings } = common;
      return store.dispatch(getStoreLocatorList(language, settings.countryId));
    },
    priority: 6
  },
  fetchHeaderSectins: {
    asyncTask: arg => handleSectionsTask(arg, SECTION_SLUGS.HEADER),
    priority: 7
  },
  configuration: {
    asyncTask: ({ store }) => store.dispatch(getConfigurationSettings()),
    priority: 8
  }
};
