import get from "lodash/get";

import { PAYMENT_GATEWAYS } from "../../../constants";

import { CheckoutComService } from "../../../services";

const checkoutFlow = async function ({
  orderBody,
  orderId,
  paymentToken,
  continueAsGuest
}) {
  const {
    confirmOrder,
    language,
    history,
    creditCardOptions,
    selectedCardCvv,
    countryId,
    match,
    authReducer: user,
    order,
    countrySHORT
  } = this.props;

  const customer = getUserData(user, order);

  const shipping = {
    address: {
      address_line1: order.address.addressLine1,
      address_line2: order.address.addressLine2,
      city: order.address.city,
      state: order.address.area,
      zip: order.address.zipcode,
      country: countrySHORT
    },
    phone: {
      number: order.address.phone
    }
  };

  const newCardFlow = async () => {
    const checkoutComToken = await CheckoutComService.generateTokenFromForm();

    return CheckoutComService.pay({
      token: checkoutComToken,
      amount: orderBody.paymentAmount,
      currency: orderBody.paymentCurrency,
      order_id: orderId,
      saveCard: get(creditCardOptions, "savingCardEnabled", false),
      customer,
      shipping,
      redirectionQueries: {
        paymentToken,
        transactionOrderTotal: orderBody.transactionOrderTotal,
        currencyCode: orderBody.currencyCode,
        countryId,
        checkoutId: match.params.id,
        ...(continueAsGuest && { continueAsGuest })
      }
    });
  };

  const savedCardFlow = () => {
    return CheckoutComService.pay({
      card_id: creditCardOptions.selectedCard,
      cvv: selectedCardCvv,
      amount: orderBody.paymentAmount,
      currency: orderBody.paymentCurrency,
      order_id: orderId,
      customer,
      shipping,
      redirectionQueries: {
        paymentToken,
        transactionOrderTotal: orderBody.transactionOrderTotal,
        currencyCode: orderBody.currencyCode,
        countryId,
        checkoutId: match.params.id
      }
    });
  };

  const isCardSelected = Boolean(get(creditCardOptions, "selectedCard"));

  const flow = isCardSelected ? savedCardFlow : newCardFlow;

  try {
    const result = await flow();

    if (result.approved) {
      await confirmOrder(
        {
          ...orderBody,
          paymentGateWay: PAYMENT_GATEWAYS.CHECKOUT_COM,
          paymentReferenceNo: result.id,
          orderId,
          isPaymentSuccess: true,
          paymentToken
        },
        history,
        language,
        true
      );

      this.changeCheckoutData("orderPlaced", true);
      this.redirectToSuccessPage(orderId);

      return;
    } else if (result.status === "Pending") {
      window.location.href = result._links.redirect.href;
    } else {
      throw new Error("Payment failed");
    }
  } catch (error) {
    handleError.call(this, error);
  } finally {
    this.hideLoader();
  }
};

const handleError = function (error) {
  console.error(error);

  this.redirectToErrorPage({
    message: "default"
  });
};

const getUserData = (user, order) => {
  return order.continueAsGuest
    ? {
        email: order.guestEmail,
        name: `${order.address.firstname} ${order.address.lastname}`
      }
    : {
        email: user.email,
        name: `${user.firstname} ${user.lastname}`
      };
};

export default checkoutFlow;
