import React from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";

import { LANGUAGE } from "../../constants";
import { Image } from "../../components/image";
import { catalogCarousalSwiperConfig } from "./swiperConfigs";

const CatalogCarousal = ({
  carousalContent,
  language,
  routelanguage,
  settings
}) => (
  <div className="catalog_image_carousel_section">
    {carousalContent[language] &&
      carousalContent[language].imageList.length > 0 && (
        <section className="favourite_brands">
          <div className="catalogue-slider-wrap">
            <div className="head_with_desc">
              <h2>{carousalContent[language].title}</h2>
              <h6>{carousalContent[language].subTitle}</h6>
            </div>
            <ul>
              <Swiper
                {...catalogCarousalSwiperConfig}
                rtl={language === LANGUAGE.arabic ? "rtl" : ""}
              >
                {carousalContent[language].imageList.map(image => (
                  <li key={image.imageLink}>
                    <Link
                      to={`/${routelanguage}-${(settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                        "ae"}${image.redirectionLink}`}
                    >
                      <Image
                        src={`${
                          image.imageLink
                        }?quality=100&w=720&h=1080&mode=crop`}
                      />
                    </Link>
                    <div>
                      <h4>{image.title}</h4>
                      <p>{image.subTitle}</p>
                    </div>
                  </li>
                ))}
              </Swiper>
            </ul>
          </div>
        </section>
      )}
  </div>
);

export default CatalogCarousal;
