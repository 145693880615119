import React from "react";
import { Link } from "react-router-dom";
import ContentImage from "../../content/content-image";

const CartItemImage = ({
  currentProductUrl,
  item,
  isAvailableInCountry,
  isOutOfStock,
  translation,
  product,
  isStockChanged,
  url,
  children
}) => (
  <Link to={currentProductUrl} className="detailLink">
    <div className="cart_img_wrap">
      <ContentImage
        img={item && item.captionImageURL}
        url={url?.thumb}
        showDefaultImage
      />

      {isAvailableInCountry ? (
        <>
          {isOutOfStock && (
            <div className="out_of_stock_block">
              <p>{translation && translation.outOfStock}</p>
            </div>
          )}
          {isStockChanged && (
            <div className="out_of_stock_block">
              <p>{translation && translation.qtyChange}</p>
            </div>
          )}

          {Boolean(product._priceChange) &&
            !product._quantityChanged &&
            !product._OutOfStock && (
              <div className="out_of_stock_block">
                <p>{translation && translation.prizeChange}</p>
              </div>
            )}
        </>
      ) : (
        <div className="out_of_stock_block">
          <p>{translation && translation.notAvailable}</p>
        </div>
      )}
      {children}
    </div>
  </Link>
);

export default CartItemImage;
