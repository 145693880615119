import withSyteApiStatus from "../../../hocs/withSyteApiStatus";
import { encodeSpace } from "../../../util";

const SyteDiscoveryBanner = withSyteApiStatus(
  ({
    sku,
    placement,
    productImgPath,
    handleClick,
    details,
    syteAppIsReady,
    ...otherProps
  }) => {
    const clickHandler = e => {
      e.preventDefault();
      if (handleClick && details) {
        handleClick(details);
      }
    };

    if (!Boolean(syteAppIsReady)) return null;

    return (
      <div
        id="syte-discovery-banner"
        data-image-src={encodeSpace(productImgPath)}
        data-sku={sku}
        data-placement={placement}
        onClick={clickHandler}
        {...otherProps}
      ></div>
    );
  }
);

export default SyteDiscoveryBanner;
