import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash/isEmpty";
import Page from "../../components/page";
import Login from "../../components/modal/login";
import SocialLoginModal from "../../components/modal/social-login";
import { loginTranslation } from "../../language/login/en/login";
import {
  changeLanguage,
  getConfigurationSettings,
  getCountrySettings
} from "../../redux/actions/common.action";
import { withRouter } from "react-router-dom";
import JoinSignToggle from "../../components/join-sign-toggle";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { seoTranslation } from "../../language/seo/en/seo";
import { GAService } from "../../services/GA-service";

class SignInPage extends Component {
  state = {
    loginTranslation: loginTranslation
  };

  componentDidMount() {
    const {
      handleLanguageChange,
      match,
      language,
      countryData,
      getCountrySettings,
      getConfigurationSettings
    } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.getLanguage({ language: languageParam });
    if (isEmpty(countryData)) {
      getCountrySettings();
      getConfigurationSettings();
    }
  }

  showForgotPasswordModal = () => {
    GAService.common.trackCTAClick(loginTranslation.forgotPassword);
    const { history, language, settings } = this.props;
    history.push(
      `/${language}-${
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
        "ae"
      }/forgot-password/`
    );
  };
  toggleToJoin = () => {
    const { history, language, settings } = this.props;
    GAService.auth.trackSignCTA(false);
    history.push(
      `/${language}-${
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
        "ae"
      }/sign-up/`
    );
  };

  getLanguage(nextProps) {
    let language = nextProps.language;
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(
      `../../language/login/${language}/login.js`
    );
    languageResponse.then(response => {
      this.setState({ loginTranslation: response.loginTranslation });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getLanguage(nextProps);
    }
  }
  render() {
    const { language, history, location, settings } = this.props;
    const { loginTranslation } = this.state;

    return (
      <Page
        title={seoTranslation.signInTitle}
        meta={{
          [language]: { metaDescription: seoTranslation.defaultPageDescription }
        }}
      >
        <div
          className={
            language.includes("ar-") || language.includes("ar")
              ? "register_user_page arabic"
              : "register_user_page"
          }
        >
          <div className="signup_layout footer-included">
            <JoinSignToggle
              loginButtonText={loginTranslation.signIn}
              signupButtonText={loginTranslation.join}
            />
            <div className="padding_with_footer">
              <Login
                translation={loginTranslation}
                showForgotModal={this.showForgotPasswordModal}
                redirection={
                  location.search && location.search.split("?")[1]
                    ? `/${language}-${
                        (settings &&
                          settings.countrySHORT &&
                          settings.countrySHORT.toLowerCase()) ||
                        "ae"
                      }/${location.search.split("?")[1]}`
                    : `/${language}-${
                        (settings &&
                          settings.countrySHORT &&
                          settings.countrySHORT.toLowerCase()) ||
                        "ae"
                      }/`
                }
              />
            </div>
            <SocialLoginModal
              translation={loginTranslation}
              language={language}
              history={history}
              textFor="login"
              redirection={
                location.search && location.search.split("?")[1]
                  ? `/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }/${location.search.split("?")[1]}`
                  : `/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }/`
              }
            />
            <div className="login_footer">
              <p>
                {loginTranslation.dontHaveAccount}{" "}
                <span onClick={this.toggleToJoin}>
                  {loginTranslation.joinAccount}
                </span>
              </p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings,
  countryData: state.common.countryData
});

const mapStateToDispatch = dispatch => ({
  handleLanguageChange(name) {
    dispatch(changeLanguage(name));
  },
  getCountrySettings() {
    dispatch(getCountrySettings());
  },
  getConfigurationSettings() {
    dispatch(getConfigurationSettings());
  }
});

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapStateToDispatch)
)(SignInPage);
