export const seoTranslation = {
  lang: "en",
  // Title text cobinations
  bfl: "Brands For Less",
  bestPriceIn: "Best Prices in",
  buy: "Buy",
  onlineBestpriceIn: "Online - Best Prices in",
  products: "Products",
  inLowPrice: "in Low Prices!",
  shopOnline: "Shop Online",
  ourStores: "Our Stores",
  onlineShopping: "Online shopping",

  // Meta Descriotion Text
  withoutStoreMetaDesc:
    "Pay less for quality, branded products! Shop online on Brands For Less.",
  withSotreMetaDesc: "make your purchase online!",

  //Meta Description product Listing
  PlpMetaDesc1: "Quality, branded products in low prices! Shop",
  PlpMetaDesc2: "online and make your purchase on Brands For Less.",

  //Meta Description Store Locator
  storeLocatorMeta: "Search for the neares Brands For Less store.",

  //Default Description for Page Component
  defaultPageDescription:
    "Brands For Less is the official franchisee of the German brand Tchibo. Each BFL and Tchibo outlet is designed and stocked to ensure customer satisfaction and happiness because of less price for branded items and for the hospitality.",

  cartPageTitle: "Brandsforless.com | Cart",
  checkoutPageTitle: "Brandsforless.com | Checkout",
  wishlistPageTitle: "Brandsforless.com | Wishlist",
  sendUnEmailTitle: "Contact us -  Brands For Less Customer Service",
  signInTitle: "Brandsforless.com | Sign in",
  joinTitle: "Brandsforless.com | Join",
  wishlistTitle: "Brandsforless.com | Wishlist",
  trackOrderTitle: "Brandsforless.com | Track Order",
  accountTitle: "Brandsforless.com | My Account",

  en: {
    // Title text cobinations
    bfl: "Brands For Less",
    bestPriceIn: "Best Prices in",
    buy: "Buy",
    onlineBestpriceIn: "Online - Best Prices in",
    products: "Products",
    inLowPrice: "in Low Prices!",
    shopOnline: "Shop Online",
    ourStores: "Our Stores",
    onlineShopping: "Online shopping",
    online: "Online",
    onlineAtBestPriceIn: "online at the best price in",
    genuineProducts: "✓ 100% Genuine Products",
    fastShipping: "✓ Fast Shipping",
    cashOnDelivery: "✓ Cash on Delivery",
    easyReturns: "✓ Easy Returns",
    // Meta Descriotion Text
    withoutStoreMetaDesc:
      "Pay less for quality, branded products! Shop online on Brands For Less.",
    withSotreMetaDesc: "make your purchase online!",

    //Meta Description product Listing
    PlpMetaDesc1: "Quality, branded products in low prices! Shop",
    PlpMetaDesc2: "online and make your purchase on Brands For Less.",

    //Meta Description Store Locator
    storeLocatorMeta: "Search for the neares Brands For Less store.",

    //Default Description for Page Component
    defaultPageDescription:
      "Brands For Less is the official franchisee of the German brand Tchibo. Each BFL and Tchibo outlet is designed and stocked to ensure customer satisfaction and happiness because of less price for branded items and for the hospitality.",

    // product detail page
    selectSizeFirst: "Please select size first",
    Shop: "Shop",
    by: "by",
    availableIn: "Available in",
    color: "color",
    theAvailableStock: "The available stock is",
    alreadyHaveThisItemInCart:
      "You already have QUANTITY same items in the cart. Please view the cart and change its quantity there",
    sendUnEmailDescription:
      "Contact us to answer any question you have about Brands For Less Address, Contact numbers, feedback & your orders"
  },
  ar: {
    // Title text cobinations
    bfl: "براندز فور لس",
    bestPriceIn: "الأسعار الأفضل في",
    buy: "تسوق",
    onlineBestpriceIn: "أونلاين، الأسعار الأفضل في",
    products: "برودكتس",
    inLowPrice: "في انخفاض الأسعار!",
    shopOnline: "تسوق عبر الإنترنت",
    ourStores: "متاجرنا",
    onlineShopping: "التسوق عبر الانترنت",
    online: "عبر الإنترنت",
    onlineAtBestPriceIn: "عبر الإنترنت بأفضل سعر في",
    genuineProducts: "✓ 100% منتجات أصلية بنسبة",
    fastShipping: "✓ الشحن السريع",
    cashOnDelivery: "✓ النقود عند التسليم",
    easyReturns: "✓ عودة سهلة",
    // Meta Descriotion Text
    withoutStoreMetaDesc:
      "ادفع أقل مقابل جودة المنتجات ذات العلامات التجارية! تسوق عبر الإنترنت على العلامات التجارية لأقل",
    withSotreMetaDesc: "جعل الشراء عبر الإنترنت!",

    //Meta Description product Listing
    PlpMetaDesc1: "منتجات الماركات العالمية بأقل الأسعار! تسوق منتجات",
    PlpMetaDesc2: "أونلاين عبر براندز فور لس",

    //Meta Description Store Locator
    storeLocatorMeta: "البحث عن أقرب العلامات التجارية لتخزين أقل.",

    //Default Description for Page Component
    defaultPageDescription:
      "تعتبر Brands For Less الامتياز الرسمي للعلامة التجارية الألمانية Tchibo. تم تصميم وتخزين كل منفذ BFL و Tchibo لضمان رضا العملاء وسعادتهم بسبب انخفاض سعر السلع ذات العلامات التجارية والضيافة.",

    //product detail
    selectSizeFirst: "يرجى اختيار الحجم أولا",
    Shop: "متجر",
    by: "بواسطة",
    availableIn: "متوفر في",
    color: "اللون",
    theAvailableStock: "المخزون المتاح هو",
    alreadyHaveThisItemInCart:
      "You already have QUANTITY same items in the cart. Please view the cart and change its quantity there"
  }
};
