import React from "react";
import ReactHtmlParser from "react-html-parser";
const SearchResults = ({ item, moveToProductListing }) => (
  <li
    onClick={e =>
      moveToProductListing(
        item &&
          item.query &&
          item.query.value &&
          item.query.value.replace("<em>", "").replace("</em>", "")
      )
    }
  >
    {ReactHtmlParser(
      (item &&
        item.query &&
        item.query.value &&
        item.query.value.replace(/em>/g, "b>")) ||
        "NA"
    )}
  </li>
);

export default SearchResults;
