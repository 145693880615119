import React, { Component } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  getDeliverySettings,
  getDeliverySettingsIfNeeded,
  removeDeliverySettings
} from "../redux/actions/common.action";

const withDeliverySettingsFetching = WrappedComponent => {
  const EnhancedComponent = class extends Component {
    componentDidMount() {
      const { getDeliverySettingsIfNeeded, countryId } = this.props;
      countryId && getDeliverySettingsIfNeeded();
    }

    componentDidUpdate(prevProps) {
      const {
        getDeliverySettings,
        language,
        removeDeliverySettings,
        continueAsGuest,
        userLoggedIn,
        countryId,
        currencyCode
      } = this.props;

      const removeAndRefetchDeliverySettings =
        (prevProps.userLoggedIn && !userLoggedIn) ||
        (!prevProps.continueAsGuest && continueAsGuest) ||
        language !== prevProps.language;

      const isCountryDataUpdated =
        countryId && currencyCode && countryId !== prevProps.countryId;
      if (removeAndRefetchDeliverySettings || isCountryDataUpdated) {
        removeDeliverySettings();
        if (countryId) {
          getDeliverySettings();
        }
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

const mapStateToProps = state => ({
  language: state.common.language,
  continueAsGuest: state.checkoutReducer.checkoutData.continueAsGuest,
  userLoggedIn: state.authReducer.userLoggedIn,
  countryId: state.common.settings.countryId,
  currencyCode: state.common.settings.currencyCode
});

const mapDispatchToProps = {
  getDeliverySettings,
  getDeliverySettingsIfNeeded,
  removeDeliverySettings
};

export default WrappedComponent =>
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withDeliverySettingsFetching
  )(WrappedComponent);
