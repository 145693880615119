import { Cookies } from "react-cookie";
import { BROWSER_STORAGE } from "../../redux/constants";
const cookies = new Cookies();

const addOneYear = date => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 1);
  return dateCopy;
};

export const getDefaultCookieOptions = () => ({
  path: "/",
  expires: new Date(addOneYear(Date.now()))
});

export const setCookie = (key, value) => {
  cookies.set(key, value, {
    path: "/"
  });
};

export const getCookie = key => cookies.get(key);

export const setUserStorage = (token, domain) => {
  cookies.set(BROWSER_STORAGE.ACCESS_TOKEN, token, getDefaultCookieOptions());
};

export const setUserEmailStorage = email => {
  cookies.set(BROWSER_STORAGE.USER_EMAIL, email, getDefaultCookieOptions());
};

export const setGuestUserStorage = (token, domain) => {
  cookies.set(
    BROWSER_STORAGE.GUEST_ACCESS_TOKEN,
    token,
    getDefaultCookieOptions()
  );
};

export const getUserStorage = () => cookies.get(BROWSER_STORAGE.ACCESS_TOKEN);
export const getUserEmail = () => cookies.get(BROWSER_STORAGE.USER_EMAIL);
export const getGuestUserStorage = () =>
  cookies.get(BROWSER_STORAGE.GUEST_ACCESS_TOKEN);

export const removeUserStorage = domain => {
  cookies.set(BROWSER_STORAGE.ACCESS_TOKEN, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN);
};

export const removeGuestUserStorage = domain => {
  cookies.set(BROWSER_STORAGE.GUEST_ACCESS_TOKEN, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.GUEST_ACCESS_TOKEN);
};

export const removeUserEmailStorage = domain => {
  cookies.set(BROWSER_STORAGE.USER_EMAIL, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.USER_EMAIL);
};

export const setPrevModeStorage = (token, domain) => {
  if (token) {
    cookies.set(BROWSER_STORAGE.PREV_MODE, token, {
      path: "/"
    });
  }
};

export const getPrevModeStorage = myCookies =>
  myCookies
    ? myCookies[BROWSER_STORAGE.PREV_MODE]
    : cookies.get(BROWSER_STORAGE.PREV_MODE);

export const removePrevModeStorage = domain => {
  cookies.set(BROWSER_STORAGE.PREV_MODE, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.PREV_MODE);
};

// TODO : domain should be come from ENV
export const setUserCountryStorage = (token, domain) => {
  cookies.set(BROWSER_STORAGE.USER_COUNTRY, token, getDefaultCookieOptions());
};

export const setUserStoreStorage = storeId =>
  cookies.set(BROWSER_STORAGE.USER_STORE, storeId, getDefaultCookieOptions());

export const getUserCountryStorage = () =>
  cookies.get(BROWSER_STORAGE.USER_COUNTRY);

export const removeUserCountryStorage = domain => {
  cookies.set(BROWSER_STORAGE.USER_COUNTRY, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.USER_COUNTRY);
};

export const setUserCountrySelectionStorage = (token, domain) => {
  cookies.set(
    BROWSER_STORAGE.USER_COUNTRY_SELECTION,
    token,
    getDefaultCookieOptions()
  );
};

export const getUserCountrySelectionStorage = () =>
  cookies.get(BROWSER_STORAGE.USER_COUNTRY_SELECTION);

export const removeUserCountrySelectionStorage = domain => {
  cookies.set(BROWSER_STORAGE.USER_COUNTRY_SELECTION, "", {
    path: "/",
    maxAge: 0
  });
  cookies.remove(BROWSER_STORAGE.USER_COUNTRY_SELECTION);
};
