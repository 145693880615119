import React, { useState, useEffect } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { isServer } from "../util";

const setHandler = callback => ({
  set: function(obj, prop, value) {
    if (prop === "isReady") {
      callback && callback();
    }

    obj[prop] = value;

    return true;
  },
  get: (target, key) => {
    if (key !== "__isProxy") {
      return target[key];
    }

    return true;
  }
});

const _observe = (obj, callback) => {
  if (!obj.__isProxy) {
    const ret = new Proxy(obj, setHandler(callback));
    return ret;
  }

  return obj;
};

const withSyteApiStatus = Component => {
  const EnhancedComponent = props => {
    const initValue = Boolean(
      !isServer && window.SyteApp && window.SyteApp.isReady
    );
    const [syteAppIsReady, setSyteAppIsReady] = useState(initValue);
    const setReady = () => {
      if (!syteAppIsReady) {
        setSyteAppIsReady(true);
      }
    };

    useEffect(() => {
      if (!isServer && !syteAppIsReady) {
        const syteLoadedHandler = () => {
          if (!isServer && window.SyteApp) {
            window.SyteApp = _observe(window.SyteApp, () => {
              setReady();
            });
          }
        };
        window.addEventListener("syteapp_is_ready", setReady);
        window.addEventListener("syte_ai_is_loaded", syteLoadedHandler);

        return () => {
          window.removeEventListener("syteapp_is_ready", setReady);
          window.addEventListener("syte_ai_is_loaded", syteLoadedHandler);
        };
      }
    }, []);

    return (
      <>
        <Component {...props} syteAppIsReady={syteAppIsReady} />
      </>
    );
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withSyteApiStatus;
