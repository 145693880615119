import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isServer } from "../../../util";

class WishlistPagination extends Component {
  getPageQuery = page => {
    const { history } = this.props;
    let q;
    if (history.location.search === "") {
      q = `?page=${page}`;
    } else if (
      history.location.search &&
      history.location.search.indexOf("page=") === -1
    ) {
      q = `${history.location.search}&page=${page}`;
    } else {
      q = history.location.search
        .split("&")
        .filter(r => r.indexOf("page=") === -1)
        .join("&");

      if (page !== 1) {
        q = q.length > 0 ? `${q}&page=${page}` : `?page=${page}`;
      }
    }
    return `${history.location.pathname}` + q;
  };

  handlePageClick = ({ selected }) => {
    const { history, location } = this.props;
    history.push(`${location.pathname}?page=${selected + 1}`);
  };

  render() {
    const {
      wishList,
      language,
      translation,
      wishListLength,
      wishListPagination: { page, pagesAmount, nPerPage }
    } = this.props;
    let screenWidth;
    if (!isServer) {
      screenWidth = document.body.clientWidth;
    }

    return (
      wishListLength > nPerPage && (
        <div
          className={`pagination_wrapper pagination_wishlist ${
            language.includes("ar") ? "arabic" : ""
          }`}
        >
          <p className="paginate_items_number">
            {translation && translation.viewing} {wishList.length}{" "}
            {translation && translation.outOf} {wishListLength}{" "}
            {translation && translation.items}
          </p>
          <div className="paginate_display">
            {screenWidth > 1025 && (
              <span className="page_count">
                {translation && translation.page}{" "}
                {language.includes("ar") ? (
                  <span>
                    {" "}
                    {pagesAmount}/{page}
                  </span>
                ) : (
                  <span>
                    {" "}
                    {page}/{pagesAmount}
                  </span>
                )}
              </span>
            )}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagesAmount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              forcePage={page - 1}
              containerClassName={"pagination"}
              subContainerClassNsame={"pages pagination"}
              activeClassName={"active"}
              hrefBuilder={page => this.getPageQuery(page)}
            />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  wishList: state.wishListReducer.wishList,
  wishListLength: state.wishListReducer.length,
  wishListPagination: state.wishListReducer.pagination
});

export default connect(mapStateToProps)(withRouter(WishlistPagination));
