export const swiperParams = {
  dots: false,
  slidesPerView: 4,
  rebuildOnUpdate: true,
  spaceBetween: 14,
  breakpoints: {
    800: {
      slidesPerView: "auto"
    },
    600: {
      slidesPerView: "auto"
    },
    320: {
      slidesPerView: "auto",
      spaceBetween: 5
    }
  }
};
