import { fetchWebApi } from "../../webapis/apiResource";
import { GET_NEED_HELP } from "../constants";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { getPrevModeStorage } from "../../util/browserStorage";

export const getNeedHelp = result => ({ type: GET_NEED_HELP, result });

export const getNeedHelpData = (language, countryId) => {
  const apiURL = `${BEConfig.catalogApi.protocol}${
    BEConfig.catalogApi.baseURL
  }${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${
    BEConfig.catalogApi.needHelpHandle
  }/?countryId=${countryId}&language=${language}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      null,
      apiURL,
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (
          response.data &&
          response.data.code === 200 &&
          response.data &&
          response.data.data
        ) {
          dispatch(getNeedHelp({ ...response.data.data }));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
