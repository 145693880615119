import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import { MAIN_TAB_INDEX, UNSWIPEABLE_COUNT } from "../../../constants";
import * as priceUtil from "../../../util/priceUtil";
import { getProductUrlName, isMobile, isTabletView } from "../../../util";
import {
  selectCommonSettings,
  selectLanguageSlug,
  selectRouteSlug
} from "../../../util/selectors";
import { isDateInPast } from "../../../util/common";
import { getProductDetailPageLink } from "../../../util/product";
import { SwiperProductSize } from "../../product/product-size";
import ContentImage from "../../content/content-image";
import PriceSlab from "../../price/price-slab";
import SaleTabContent from "./SaleTabContent";
import { SaleMobileTimer } from "./SaleMobileTimer";
import { SalePersentageBlock } from "./SalePersentageBlock";
import { SaleProductPriceBlock } from "./SaleProductPriceBlock";
import { SaleWishList } from "./SaleWishList";
import { SaleComingSoon } from "./SaleComingSoon";
import { swiperParams } from "./constants";
import SaleWishlistButton from "./SaleWishlistButton";

const SaleTabs = ({
  saleDefaultData,
  configCdnImagesSettings,
  currentTab,
  tabsData,
  language,
  translation,
  onFilterClick,
  selectedFilters,
  setSelectedFilters,
  handleTabClick
}) => {
  const isMobileView = isMobile.any();
  const routeSlug = useSelector(selectRouteSlug);
  const commonSettings = useSelector(selectCommonSettings);
  const shortLang = useSelector(selectLanguageSlug);
  const { hits: products } = saleDefaultData;
  const tabDetails = tabsData[currentTab - 1];
  const isMainTab = currentTab === MAIN_TAB_INDEX;

  const { assistiveFilter } = saleDefaultData;
  const assistiveFilterKey = assistiveFilter || "hierarchicalCategories.lvl0";
  const assistiveFiltersText = products?.length
    ? Object.keys(saleDefaultData.facets[assistiveFilterKey] || {})
    : [];

  const showMobileTimer = Boolean(isMobileView && tabDetails?.productsCount);

  const onFilterClickHandler = filter => {
    const updatedSelectedFilters = {
      ...selectedFilters,
      [assistiveFilterKey]: filter
    };
    setSelectedFilters(updatedSelectedFilters);

    onFilterClick({ slug: tabDetails.slug, queries: updatedSelectedFilters });
  };

  return (
    <div className="tabs-container">
      {isMobileView && <h3 className="mobile-heading">{tabDetails?.title}</h3>}
      <div className="tabs">
        {tabsData.map((tab, i) => (
          <button
            key={tab.id}
            id={tab.id}
            disabled={currentTab === tab.id}
            onClick={handleTabClick}
            className="tabs-button"
          >
            <SaleTabContent
              data={tab}
              isFlashImage={tab.mainTab}
              dateTo={tab.to}
              dateFrom={tab.from}
              translation={translation}
              productsLength={tab.productsCount}
            />
          </button>
        ))}
      </div>
      {!isMobileView && (
        <>
          <div className="page-title">
            <h2>{tabDetails?.title}</h2>
          </div>

          {Boolean(assistiveFiltersText.length) && (
            <div className="assistive-filters flex-wrap container">
              {assistiveFiltersText.map((filter, index) => (
                <span key={index} onClick={() => onFilterClickHandler(filter)}>
                  {filter.split(">").pop()}
                </span>
              ))}
            </div>
          )}
        </>
      )}
      {showMobileTimer && (
        <SaleMobileTimer
          dateTo={tabDetails?.to}
          dateFrom={tabDetails?.from}
          translation={translation}
        />
      )}
      {isMobileView &&
        Boolean(assistiveFiltersText.length) &&
        (() => {
          let MobileAssistiveFilterWrapper = Swiper;
          let mobileAssistiveFilterWrapperProps = {
            rtl: language.includes("ar") ? "rtl" : "",
            ...swiperParams
          };

          if (assistiveFiltersText.length <= UNSWIPEABLE_COUNT) {
            MobileAssistiveFilterWrapper = Fragment;
            mobileAssistiveFilterWrapperProps = {};
          }

          return (
            <div className="assistive-filters">
              <MobileAssistiveFilterWrapper
                {...mobileAssistiveFilterWrapperProps}
              >
                {assistiveFiltersText.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => onFilterClickHandler(filter)}
                  >
                    {filter.split(">").pop()}
                  </span>
                ))}
              </MobileAssistiveFilterWrapper>
            </div>
          );
        })()}
      <div
        className={cn("content container large-container", {
          arabic: routeSlug.includes("ar")
        })}
      >
        {tabsData.map((tab, i) => {
          const isSaleAlreadyStarted = isDateInPast(tab.from);

          return (
            <div key={i}>
              {currentTab === tab.id && (
                <div className="products-wrapper product_listing_wrap">
                  {tabDetails.productsCount ? (
                    products.map(product => {
                      product.oldPrice = product.price.old;
                      product.currentPrice = product.price.current;
                      const { percentageDiscount, percentage } =
                        priceUtil.getItemDiscountPersentage(product, language);
                      const percentageValue =
                        product.oldPrice && percentageDiscount;
                      const productTitle =
                        getProductUrlName(
                          product.en_title || product.title || ""
                        ) || "---";
                      const link = getProductDetailPageLink(
                        { language: shortLang, commonSettings },
                        productTitle,
                        product
                      );
                      const productSizes = product?.displaySizes;

                      return (
                        <div
                          key={product.id}
                          className={cn("product", {
                            "hover_view isMobileDevice":
                              isMobileView || isTabletView()
                          })}
                        >
                          <Link to={link}>
                            <div className="product-image">
                              <div
                                className={cn({
                                  "unavailable-product": !product?.pstock
                                })}
                              >
                                <ContentImage
                                  img={product.captionImageURL}
                                  url={configCdnImagesSettings?.list}
                                  showDefaultImage
                                />
                              </div>
                              {Boolean(percentage) && (
                                <SalePersentageBlock
                                  productStock={product?.pstock}
                                  percentageValue={percentageValue}
                                />
                              )}

                              {(isSaleAlreadyStarted || isMobileView) && (
                                <SaleWishList product={product} />
                              )}

                              {!product?.pstock && (
                                <p className="out-of-stock">
                                  {translation?.soldOut}
                                </p>
                              )}
                            </div>
                            <div className="product-sizes">
                              <div
                                className={cn("product-size-wrapper", {
                                  "unavailable-product": !product?.pstock
                                })}
                              >
                                <SwiperProductSize
                                  displaySizes={productSizes}
                                  isMobileView={isMobileView}
                                />
                              </div>
                            </div>
                            <h3 className="product-title">
                              {product.brandName}
                            </h3>
                            <p className="product-description">
                              {product.title}
                            </p>

                            <SaleProductPriceBlock
                              product={product}
                              translation={translation}
                              settings={commonSettings}
                              isMainTab={isMainTab}
                              dateFrom={tabDetails?.from}
                            />

                            {Boolean(+product?.price?.rrp?.price) && (
                              <PriceSlab
                                item={product}
                                translation={translation}
                                language={language}
                                currencyCode={product.currency}
                                settings={commonSettings}
                                showDiscountSlab={false}
                                showRetailPrice
                                isSalePage
                              />
                            )}
                          </Link>
                          {!isSaleAlreadyStarted && !isMobileView && (
                            <SaleWishlistButton
                              product={product}
                              addToTranslation={translation.addToWishlist}
                            />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <SaleComingSoon translation={translation} />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SaleTabs;
