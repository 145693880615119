import { BROWSER_STORAGE } from "../../redux/constants";
import {
  _countryStoreSelection,
  decrementPageNoInQueryString,
  languageFromPathName
} from "../../util";
import {
  changeLanguage,
  getConfigurationSettings,
  getCountrySettings,
  setCommonData,
  setDeviceType,
  showLoadingIndicator,
  showPageLoader
} from "../../redux/actions/common.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getPageApi } from "../../redux/actions/page.action";
import { handleSectionsTask } from "../../redux/actions/section.action";
import { SECTION_SLUGS } from "../../constants/sections";
import { DEFAULT_PAGE_LOADER_CONFIG } from "../../constants";

export default {
  setLanguage: {
    asyncTask: ({ store, params = {} }) => {
      const { language } = params;
      return store.dispatch(changeLanguage(language));
    },
    priority: 1
  },
  settings: {
    asyncTask: ({ store, params = {}, url }) => {
      const { language } = params;
      return store.dispatch(
        getCountrySettings(languageFromPathName(language), null, url)
      );
    },
    priority: 2
  },
  getPageApi: {
    asyncTask: ({
      store,
      params = {},
      url,
      cookies,
      ipcountry,
      deviceType
    }) => {
      const { common } = store.getState();
      const { language } = params;

      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      store.dispatch(setDeviceType(deviceType));

      const {
        _country: selectionCountry,
        storeId,
        _bflStoreId
      } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      let _country = null,
        countryAE = {},
        selectedCountry = null,
        selectedIPCountry = null,
        countryCookie = null,
        countryData = common.countryData || [];

      countryData.map(_country => {
        if (
          url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
          url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
        ) {
          selectedCountry = _country;
        }
        if (_country.countrySHORT === userCountry) {
          countryCookie = _country;
        }
        if (_country.countrySHORT === "AE") {
          countryAE = _country;
        }
        if (
          common.ipCountryData &&
          common.ipCountryData.countrySHORT &&
          common.ipCountryData.countrySHORT === _country.countrySHORT
        ) {
          selectedIPCountry = _country;
        }
        return null;
      });

      if (selectedCountry) {
        _country = selectedCountry;
      } else if (!selectedCountry) {
        if (countryCookie) {
          _country = countryCookie;
        } else if (selectedIPCountry) {
          _country = selectedIPCountry;
        } else {
          _country = countryAE;
        }
      }

      let pageName =
        url.split(`/${language}/`)[1] || url.split(`/${language}/`)[0];

      pageName = decrementPageNoInQueryString(pageName);

      return store.dispatch(
        getPageApi(
          pageName,
          language,
          (_country || selectionCountry || {}).countryId,
          storeId || _bflStoreId,
          ipcountry,
          cookies
        )
      );
    },
    priority: 4
  },
  fetchMegaMenu: {
    asyncTask: ({ store, params = {}, queryParams = {}, url, cookies }) => {
      const { language } = params;
      const { common } = store.getState();
      const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

      const { _country, storeId, _bflStoreId } = _countryStoreSelection(
        store,
        url,
        userCountry,
        common,
        setCommonData
      );

      return store.dispatch(
        getMegaMenuData(
          languageFromPathName(language),
          storeId || _bflStoreId,
          _country && _country.countryId,
          cookies
        )
      );
    },
    priority: 6
  },
  fetchHeaderSectins: {
    asyncTask: arg => handleSectionsTask(arg, SECTION_SLUGS.HEADER),
    priority: 7
  },

  configuration: {
    asyncTask: ({ store }) => store.dispatch(getConfigurationSettings()),
    priority: 8
  },

  setLoader: {
    asyncTask: ({ store }) => {
      return (
        store.dispatch(showPageLoader({ ...DEFAULT_PAGE_LOADER_CONFIG })),
        store.dispatch(showLoadingIndicator())
      );
    },
    priority: 8
  }
};
