import React from "react";
import cn from "classnames";
import * as priceUtil from "../../../util/priceUtil";
import { isDateInPast } from "../../../util/common";

export const SaleProductPriceBlock = ({
  product,
  translation,
  settings,
  isMainTab,
  dateFrom
}) => {
  const oldPrice = product.price.old;
  const currentPrice = product.price.current;

  const cartPrice = priceUtil.shapePriceWithComma(
    product.transactionPrice,
    settings.decimalPlaces
  );
  const normalPrice = priceUtil.convertWithComma(currentPrice || 0, settings);

  const isDiscount = oldPrice && currentPrice !== oldPrice;

  const isBuyNowBtn =
    isDateInPast(dateFrom) && Boolean(product?.pstock) && isMainTab;

  return (
    <div className="price-block">
      <p>
        {product.currency && (
          <span
            className={cn("new-price", {
              "red-color": isDiscount
            })}
          >
            {product.currency}{" "}
            {product.transactionPrice ? cartPrice : normalPrice}
          </span>
        )}
        {isDiscount && (
          <span className="old-price">
            {product.currency} {oldPrice}
          </span>
        )}
      </p>
      {isBuyNowBtn && <button className="buy-now">{translation.buyNow}</button>}
    </div>
  );
};
