import { get, isEmpty, lowerCase } from "lodash";
import GAExtensions from "../extensions";
import { LOGGED_IN, LOGGED_OFF, LOGGED_OUT } from "../constants";

class AuthGAService extends GAExtensions {
  /**
   * @param {boolean} isSignIn Indicates whether the action is triggered for sign-in (true) or join (false).
   * @description This method is called for two events: join_click and sign_in_click.
   * @default false
   */
  trackSignCTA = (isSignIn = false) => {
    let eventObject = { event: "join_click" };
    if (isSignIn) {
      eventObject = { event: "sign_in_click" };
    }
    this.pushToDataLayer(eventObject);
  };
  /**
   * @param {string} method Sign in method
   * @default 'email'
   */
  trackSignInMethod = (method = "email") => {
    this.pushToDataLayer({
      event: "sign_in",
      method
    });
  };

  /**
   * @param {string} method Sign Up method
   * @default 'email'
   */
  trackJoinMethod = (method = "email") => {
    this.pushToDataLayer({
      event: "join_now",
      method
    });
  };

  /**
   * Track login success event. - for social/ email signin
   * @param {object} payload - { isLoggedIn, user_id, mode='email' }
   */
  trackLoginSuccess = payload => {
    const { isLoggedIn, user_id, mode = "email" } = payload;
    this.pushToDataLayer({
      event: "login",
      user_id: `${user_id}`,
      method: mode,
      login_status: isLoggedIn ? LOGGED_IN : LOGGED_OFF
    });
  };

  /**
   * Track sign up success event. - for social/ email signup
   * @param {object} payload - { isSocialLogin, customerId, data }
   */
  trackSignUpSuccess = payload => {
    const { isSocialLogin, customerId, data } = payload;
    const authData = {
      method: isSocialLogin ? get(data, "loginFrom", "") : "email",
      newsletter_subscription: get(data, "newsLetter", false).toString()
    };
    this.pushToDataLayer({
      event: "sign_up",
      login_status: isSocialLogin ? LOGGED_IN : LOGGED_OFF,
      // as with social-login, user will be loggedIn automatically
      user_id: `${customerId}` || undefined,
      ...authData
    });
  };

  trackLogoutSuccess = () => {
    this.pushToDataLayer({
      event: "logout_success",
      login_status: LOGGED_OUT
    });
  };
  trackForgotPassword = () => {
    this.pushToDataLayer({
      event: "forgot_password"
    });
  };
  trackOTPRequest = () => {
    this.pushToDataLayer({
      event: "otp_request",
      method: "mobile"
    });
  };
  trackResentOTP = () => {
    this.pushToDataLayer({
      event: "resend_otp",
      method: "mobile"
    });
  };
  trackOTPVerificationSuccess = () => {
    this.pushToDataLayer({
      event: "otp_verification",
      method: "mobile"
    });
  };
  trackLoginError = payload => {
    const { method = "email", error = "" } = payload;
    this.pushToDataLayer({
      event: "login_error",
      method,
      login_error_message: this.sanitizeString(error)
    });
  };

  /**
   * Tracks login form errors based on the provided error object.
   * @param {object} errorObj - Object containing field-error pairs: { [field]: [error_message] }
   */
  trackLoginFormError = (errorObj = {}) => {
    const errorMessages = Object.values(errorObj);
    if (!isEmpty(errorMessages)) {
      errorMessages.forEach(eachErr => {
        this.trackLoginError({ method: "email", error: eachErr });
      });
    }
  };
  trackSignUpError = payload => {
    const { method = "email", error } = payload;
    this.pushToDataLayer({
      event: "signup_error",
      method,
      signup_error_message: error
    });
  };
  trackSignUpFormError = (errorObj = {}) => {
    const errorMessages = Object.values(errorObj);
    if (!isEmpty(errorMessages)) {
      errorMessages.forEach(eachErr => {
        this.trackSignUpError({ method: "email", error: eachErr });
      });
    }
  };
}

export default AuthGAService;
