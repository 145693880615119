"use strict";
/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.helmetJsonLdProp = exports.jsonLdScriptProps = exports.JsonLd = void 0;
var React = require("react");
function JsonLd(props) {
    return React.createElement("script", __assign({}, jsonLdScriptProps(props.item, props)));
}
exports.JsonLd = JsonLd;
function jsonLdScriptProps(item, options) {
    if (options === void 0) { options = {}; }
    return {
        type: "application/ld+json",
        dangerouslySetInnerHTML: {
            __html: JSON.stringify(item, safeJsonLdReplacer, options.space),
        },
    };
}
exports.jsonLdScriptProps = jsonLdScriptProps;
function helmetJsonLdProp(item, options) {
    if (options === void 0) { options = {}; }
    return {
        type: "application/ld+json",
        innerHTML: JSON.stringify(item, safeJsonLdReplacer, options.space),
    };
}
exports.helmetJsonLdProp = helmetJsonLdProp;
var ESCAPE_ENTITIES = Object.freeze({
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&apos;",
});
var ESCAPE_REGEX = new RegExp("[" + Object.keys(ESCAPE_ENTITIES).join("") + "]", "g");
var ESCAPE_REPLACER = function (t) {
    return ESCAPE_ENTITIES[t];
};
/**
 * A replacer for JSON.stringify to strip JSON-LD of illegal HTML entities
 * per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
 */
var safeJsonLdReplacer = (function () {
    // Replace per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
    // Solution from https://stackoverflow.com/a/5499821/864313
    return function (_, value) {
        switch (typeof value) {
            case "object":
                // Omit null values.
                if (value === null) {
                    return undefined;
                }
                return value; // JSON.stringify will recursively call replacer.
            case "number":
            case "boolean":
            case "bigint":
                return value; // These values are not risky.
            case "string":
                return value.replace(ESCAPE_REGEX, ESCAPE_REPLACER);
            default: {
                // We shouldn't expect other types.
                isNever(value);
                // JSON.stringify will remove this element.
                return undefined;
            }
        }
    };
})();
// Utility: Assert never
function isNever(_) { }
