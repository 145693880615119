import React from "react";
import isString from "lodash/isString";

const CloseClearFilter = ({
  translation,
  closeFilter,
  currentFilterSelected
}) => {
  const currentFilterTitle =
    currentFilterSelected &&
    isString(currentFilterSelected) &&
    translation[currentFilterSelected];

  return (
    <div className="mobile_filter_list_head">
      <span className="close" onClick={closeFilter} />
      <em className="filterTitle">
        {currentFilterTitle || translation.filterTitle}
      </em>
      <em className="sizeFilterTitle">
        {translation.size} {translation.filterTitle}
      </em>
      <span>{translation && translation.back}</span>
    </div>
  );
};

export default CloseClearFilter;
