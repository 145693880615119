import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCountryList } from "../../redux/actions/common.action";
import UserPreferencesModal from "../modal/modal";
import UserPreferences from "../../components/user-preferences";
import { CircleFlag } from "react-circle-flags";

class CountrySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggle: false,
      openUserPreferenceModal: false,
      country: {}
    };
  }

  closeModal = e => {
    e && e.stopPropagation();
    this.setState({ openUserPreferenceModal: false });
  };

  openModal = e => {
    e && e.stopPropagation();
    const { getCountryList, language } = this.props;
    getCountryList(language);

    this.setState({ openUserPreferenceModal: true });
  };

  render() {
    const { openUserPreferenceModal, country } = this.state;
    const { language, settings, translation, footerTranslation } = this.props;
    const countryShortCode = settings?.countrySHORT || country?.countrySHORT;
    return (
      <section className="country_selector_dropdown">
        <div className="currency_change" onClick={this.openModal}>
          <span className="shopping_from">
            {footerTranslation.shoppingFrom}
          </span>
          {countryShortCode && (
            <span>
              <CircleFlag
                className="country_image"
                loading="lazy"
                countryCode={countryShortCode.toLowerCase()}
                width={18}
              />
            </span>
          )}
          {((country && country.countryCode) ||
            (settings && settings.countryCode)) && (
            <span className="country_currency">
              <span>
                {(country && country.countryCode) ||
                  (settings && settings.countryCode)}
              </span>{" "}
              |{" "}
              <span>
                {(country && country.currencyCode) ||
                  (settings && settings.currencyCode)}
              </span>
            </span>
          )}
          <span className="arrow-down" />
          <UserPreferencesModal
            modalClass={`generic_modal country_selection_modal ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
            showModal={openUserPreferenceModal}
            overlayClassName="countryOverlay"
            closeModal={this.closeModal}
          >
            <button className="cross_btn" onClick={this.closeModal}>
              &times;
            </button>
            {openUserPreferenceModal && (
              <UserPreferences
                userPreferencesTranslation={translation}
                language={language}
                closeModal={this.closeModal}
              />
            )}
          </UserPreferencesModal>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings,
  countryId: state.page.homepageState.countryId
});

const mapDispatchToProps = { getCountryList };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CountrySelector)
);
