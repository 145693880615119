import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccessToken, selectRouteSlug } from "../util/selectors";

const withAuthenticationChecking = Component => {
  const EnhancedComponent = props => {
    const history = useHistory();
    const location = useLocation();
    const accessToken = useSelector(selectAccessToken);
    const routeSlug = useSelector(selectRouteSlug);

    if (!accessToken && props.redirectionData?.data?.authorization) {
      history.replace(
        `/${routeSlug}/login/?${location.pathname.replace(
          `/${routeSlug}/`,
          ""
        )}${location.search}`
      );
      return null;
    }
    return <Component {...props} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withAuthenticationChecking;
