import filter from "lodash/filter";
import map from "lodash/map";

import { getInternationalSizes } from "../cart";
import { getDataAsObject } from "../common";

export const getLoyaltyPointsBody = (
  currency = "AED",
  cart,
  commonSettings
) => ({
  currencyCode: currency,
  orderItems: map(cart, item => {
    let availableSizes = filter(
      item.colors?.colorSizeOptions,
      option => option.sizeId === item.sizeId
    );
    const internationalSizes = {};

    getInternationalSizes(availableSizes, internationalSizes);
    if (
      item.IsMultisize &&
      Object.keys(internationalSizes) &&
      Object.keys(internationalSizes).length
    ) {
      availableSizes =
        internationalSizes[item.sizeType] ||
        internationalSizes[Object.keys(internationalSizes)[0]];
    }
    const size =
      availableSizes &&
      availableSizes.find(option => option.sizeId === item.sizeId);
    return {
      _id: item._id,
      productId: item.productId,
      quantity: (item.quantity && parseInt(item.quantity)) || 1,
      colorid: size ? size.colorId : 0,
      sizeid: size ? size.sizeId : 0,
      size: item.size,
      price: item.currentPrice || item.price,
      upc: size ? size?.upc : null,
      onlineStock:
        size && size?.onlineStock ? getDataAsObject(size.onlineStock) : null,
      transactionPrice: item.currentPrice,
      transactionOldPrice: item.oldPrice,
      oldPrice: item.oldPrice
    };
  })
});
