import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import filter from "lodash/filter";
import get from "lodash/get";
import template from "lodash/template";

import { PAYMENT_GATEWAYS, PAYMENT_MODES } from "../../../constants";

import { changeCheckoutData } from "../../../redux/actions/checkout.action";

import {
  selectCountryId,
  selectCountrySlug,
  selectLanguageSlug
} from "../../../util/selectors";
import { alreadySelected } from "../../../util/checkout";

import CreditCardForm from "./CreditCardForm";

import ccIcon from "../../../images/payment/payment_modes/CC.svg";
import codIcon from "../../../images/payment/payment_modes/COD.svg";
import paypalIcon from "../../../images/payment/payment_modes/PAYPAL.svg";
import tabbyIcon from "../../../images/payment/payment_modes/TABBY_COLOR.svg";
import postPayIcon from "../../../images/payment/payment_modes/POSTPAY.svg";
import checkedIcon from "./assets/checked.svg";
import tamaraIcon from "../../../images/payment/payment_modes/TAMARA_LOGO.svg";

import "./style.scss";
import { PostPayForm } from "./PostPayForm/PostPayForm";
import TabbyCheckout from "../TabbyCheckout";
import PaymentDetails from "./PaymentDetails";

class PaymentMethodList extends Component {
  state = {
    modalOpen: false
  };
  handleClickPaymentMethod(option) {
    const { order, onPaymentOptionChange, changeSelectedPayment } = this.props;

    if (!order.step3Completed || !alreadySelected(order, option)) {
      changeSelectedPayment(option.paymentType);
      onPaymentOptionChange(option);
    }
  }

  componentDidMount() {
    const { resetPaymentStep } = this.props;

    resetPaymentStep();
  }

  getMethodDetails(option) {
    const {
      translation,
      totalAmount,
      codFee,
      resetPaymentStep,
      order,
      countryId,
      language,
      currency
    } = this.props;

    const KSAId = 199;

    const data = {
      CC: {
        icon: ccIcon,
        ...translation.paymentMethods[PAYMENT_MODES.CC],
        ...(countryId === KSAId && {
          description:
            translation.paymentMethods[PAYMENT_MODES.CC].additionalDescription
        })
      },
      COD: {
        icon: codIcon,
        ...translation.paymentMethods[PAYMENT_MODES.COD]
      },
      CBD: {
        ...translation.paymentMethods[PAYMENT_MODES.CBD]
      },
      TABBY_PAY_LATER: {
        icon: tabbyIcon,
        hoverInfo: (
          <PaymentDetails
            language={language}
            totalAmount={totalAmount}
            currency={currency}
            paymentType="payLater"
            paymentGateway={PAYMENT_GATEWAYS.TABBY}
          />
        ),
        ...translation.paymentMethods[PAYMENT_MODES.TABBY_PAY_LATER]
      },
      TABBY_PAY_INSTALLMENT: {
        icon: tabbyIcon,
        hoverInfo: (
          <PaymentDetails
            language={language}
            totalAmount={totalAmount}
            currency={currency}
            paymentType="installments"
            paymentGateway={PAYMENT_GATEWAYS.TABBY}
          />
        ),
        ...translation.paymentMethods[PAYMENT_MODES.TABBY_PAY_INSTALLMENT]
      },
      TAMARA_PAY_NOW: {
        icon: tamaraIcon,
        ...translation.paymentMethods[PAYMENT_MODES.TAMARA_PAY_NOW]
      },
      TAMARA_PAY_BY_INSTALMENTS: {
        icon: tamaraIcon,
        hoverInfo: (
          <PaymentDetails
            language={language}
            totalAmount={totalAmount}
            currency={currency}
            paymentType="installments"
            paymentGateway={PAYMENT_GATEWAYS.TAMARA}
          />
        ),
        ...translation.paymentMethods[PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS]
      },
      PAYPAL: {
        icon: paypalIcon,
        ...translation.paymentMethods[PAYMENT_MODES.PAYPAL]
      },
      APPLEPAY: {
        ...translation.paymentMethods[PAYMENT_MODES.APPLEPAY]
      },
      POSTPAY: {
        icon: postPayIcon,
        ...translation.paymentMethods[PAYMENT_MODES.POSTPAY]
      }
    };

    const paymentMethodDetails = data[option.paymentType];

    const minAmountNotReached =
      option.minAmountForPayment &&
      totalAmount - codFee < option.minAmountForPayment;
    const maxAmountReached =
      option.maxAmountForPayment &&
      totalAmount - codFee > option.maxAmountForPayment;
    const methodShouldBeDisabled = minAmountNotReached || maxAmountReached;

    if (methodShouldBeDisabled) {
      Object.assign(paymentMethodDetails, {
        disabled: true,
        disabledMessage: template(
          translation[
            minAmountNotReached
              ? "paymentMinimumAmountMessage"
              : "paymentMaximumAmountMessage"
          ]
        )({
          amount:
            option[
              minAmountNotReached
                ? "minAmountForPayment"
                : "maxAmountForPayment"
            ],
          currency: option._paymentCurrency
        })
      });
      if (alreadySelected(order, option)) {
        resetPaymentStep();
      }
    }

    return paymentMethodDetails;
  }

  renderPaymentCurrencyText = option => {
    if (option._paymentGateway === PAYMENT_GATEWAYS.AREEBA) {
      return ` (in ${option._paymentCurrency})`;
    }
    if (option._paymentCurrency !== option.currencyCode) {
      return ` (in ${option._paymentCurrency})`;
    }

    return;
  };

  render() {
    const {
      order,
      paymentOptions,
      translation,
      language,
      applyVoucher,
      changeVoucherStatus,
      handleVoucherAppliedMessage,
      currency,
      totalAmount,
      countrySlug,
      preApplyVoucher
    } = this.props;

    const selectedPayment = get(order, "selectedPayment", []);
    const selectedPaymentGateway = get(
      order,
      "selectedPaymentOption._paymentGateway"
    );

    const countryShort = countrySlug.toUpperCase();

    return (
      <div
        className={cn("payment-method-list", {
          arabic: language === "ar"
        })}
      >
        <div className="list">
          {filter(
            paymentOptions,
            option => option.paymentType !== "APPLE_PAY"
          ).map(option => {
            const isSelected =
              selectedPayment.includes(option.paymentType) &&
              selectedPaymentGateway === option._paymentGateway;

            const details = this.getMethodDetails(option);
            if (!details) return null;

            const isCreditCardSelected =
              isSelected && option.paymentType === "CC";

            const isPostPaySelected =
              isSelected && option.paymentType === PAYMENT_MODES.POSTPAY;

            const isTabbyPaymentSelected =
              isSelected &&
              option.paymentType === PAYMENT_MODES?.TABBY_PAY_INSTALLMENT;

            const key = cn(option.paymentType, option._paymentGateway);

            return (
              <div
                className={cn("payment-method", {
                  selected: isSelected && order.step3Completed,
                  disabled: details.disabled
                })}
                onClick={() => {
                  if (!details.disabled) this.handleClickPaymentMethod(option);
                }}
                key={cn(option.paymentType, option._paymentGateway)}
              >
                {isSelected && order.step3Completed && (
                  <img
                    src={checkedIcon}
                    className="checked-icon"
                    alt="checked-icon"
                  />
                )}

                <div className="header">
                  <div className="title-block">
                    <div className="title">
                      {details.title}
                      {details.hoverInfo}
                      {this.renderPaymentCurrencyText(option)}
                    </div>

                    {details.description && (
                      <div className="description">{details.description}</div>
                    )}

                    {details.disabledMessage && (
                      <div className="disabled-message">
                        {details.disabledMessage}
                      </div>
                    )}
                  </div>

                  {details.icon && (
                    <img
                      className={cn(
                        "payment-method-icon",
                        option.paymentType.toLowerCase()
                      )}
                      src={details.icon}
                      alt="payment-method-icon"
                    />
                  )}
                </div>

                {isPostPaySelected &&
                  option._paymentGateway === PAYMENT_GATEWAYS.POSTPAY && (
                    <div className="body">
                      <PostPayForm
                        locale={language}
                        currency={currency}
                        totalAmount={totalAmount}
                        countryShort={countryShort}
                      />
                    </div>
                  )}

                {!details.disabled &&
                  isCreditCardSelected &&
                  option._paymentGateway === PAYMENT_GATEWAYS.CHECKOUT_COM && (
                    <div className="body">
                      <CreditCardForm
                        translation={translation}
                        applyVoucher={applyVoucher}
                        order={order}
                        changeVoucherStatus={changeVoucherStatus}
                        handleVoucherAppliedMessage={
                          handleVoucherAppliedMessage
                        }
                        preApplyVoucher={preApplyVoucher}
                      />
                    </div>
                  )}
                {isTabbyPaymentSelected && (
                  <TabbyCheckout
                    currency={currency}
                    totalAmount={totalAmount}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  order: state.checkoutReducer.checkoutData,
  language: selectLanguageSlug(state),
  countrySlug: selectCountrySlug(state),
  countryId: selectCountryId(state)
});

const mapDispatchToProps = {
  changeCheckoutData
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList);
