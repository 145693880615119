import React from "react";

import { Image } from "../../image";

import confirmCheck from "../../../images/yellow_check.svg";

const ThankYou = ({ orderSuccessTranslation }) => (
  <div className="thankyou_modal">
    <Image src={confirmCheck} alt="confirm_order" className="confirm_check" />

    <h1>{orderSuccessTranslation.thankYou}</h1>
    <p>{orderSuccessTranslation.yourNumberVerified}</p>
  </div>
);

export default ThankYou;
