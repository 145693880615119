import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import find from "lodash/find";
import get from "lodash/get";
import SetUserPreferences from "./set-user-preferences";
import {
  setUserCountrySelectionStorage,
  setUserCountryStorage
} from "../../util/browserStorage";
import {
  setCommonData,
  getStatesData,
  getDeliverySettingsIfNeeded,
  getCountrySettings,
  resetConfigurationSettings
} from "../../redux/actions/common.action";
import { resetLoyaltyProgramInfo } from "../../redux/actions/myAccount.action";
import { resetMapFilterData } from "../../redux/actions/checkout.action";
import { isServer, _countryStoreSelectionViaModal } from "../../util";
import { fetchUserAndLoadData } from "../../redux/actions/authConfig.action";
import { AnalyticService } from "../../services";
import { selectCustomerId } from "../../util/selectors";
import { GAService } from "../../services/GA-service";

class UserPreferences extends Component {
  state = {
    defaultCountry: null,
    freeShipping: 0,
    currencyCode: "",
    shippingCharge: 0,
    showButtonLoader: false
  };

  componentDidMount() {
    const {
      settings,
      countryData,
      getDeliverySettingsIfNeeded,
      deliverySettings
    } = this.props;
    this.setDefaultCountry();
    const freeShipping = this.getFreeShipping(settings, countryData);
    const shippingCharge = this.getShippingCharge(deliverySettings);

    getDeliverySettingsIfNeeded();
    this.setState({
      freeShipping,
      countryCode: settings.currencyCode,
      shippingCharge
    });
  }

  componentDidUpdate(prevProps) {
    const { countryData } = this.props;
    if (
      prevProps.countryData &&
      countryData &&
      prevProps.countryData.length !== countryData.length
    ) {
      this.setDefaultCountry();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryData, settings } = this.props;

    const currencyAndShippingNeedToBeUpdated =
      settings.countryCode !== nextProps.settings.countryCode ||
      (!settings.deliverySettings && nextProps.settings.deliverySettings) ||
      countryData.length !== nextProps.countryData.length;

    if (currencyAndShippingNeedToBeUpdated) {
      const freeShipping = this.getFreeShipping(
        nextProps.settings,
        nextProps.countryData
      );
      const shippingCharge = this.getShippingCharge(nextProps.deliverySettings);
      const allDataAvailable =
        nextProps.countryData.length && nextProps.deliverySettings;
      this.setState({
        currencyCode: nextProps.settings.currencyCode,
        ...(allDataAvailable && { freeShipping, shippingCharge })
      });
    }
  }

  getFreeShipping = (settings, countryData) =>
    get(
      countryData.find(item => item.countryId === settings.countryId),
      "freeShippingUpto",
      0
    );

  getShippingCharge = deliverySettings =>
    get(
      find(deliverySettings, {
        paymentType: "CC",
        deliveryType: "DELIVERY_ADDRESS"
      }),
      "shippingCharge",
      0
    );
  setDefaultCountry = () => {
    const { countryData, ipCountryData } = this.props;
    let url = !isServer && window.location.href.toLowerCase();
    let countryAE = {},
      selectedCountry = null,
      selectedIPCountry = null;
    countryData.map(_country => {
      if (
        url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
        url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
      ) {
        selectedCountry = _country;
      }
      if (_country.countrySHORT === "AE") {
        countryAE = _country;
      }
      if (
        ipCountryData &&
        ipCountryData.countrySHORT &&
        ipCountryData.countrySHORT === _country.countrySHORT
      ) {
        selectedIPCountry = _country;
      }
      return null;
    });

    if (selectedCountry) {
      this.setState({ defaultCountry: selectedCountry });
    } else if (!selectedCountry && selectedIPCountry) {
      this.setState({ defaultCountry: selectedIPCountry });
    } else {
      this.setState({ defaultCountry: countryAE });
    }
  };

  onChangeHandlerUserPreferences = country => {
    const { countryData } = this.props;
    const _selectedCountry = find(countryData, { countrySHORT: country });
    const freeShipping = get(_selectedCountry, "freeShippingUpto", 0);
    const shippingCharge = get(_selectedCountry, "shippingCharge", 0);
    const _currencyCode = get(_selectedCountry, "currencyCode", "");

    this.setState({
      defaultCountry: _selectedCountry,
      freeShipping,
      currencyCode: _currencyCode,
      shippingCharge
    });
  };

  submit = async e => {
    e?.stopPropagation();
    const { defaultCountry } = this.state;
    const {
      setCommonData,
      closeModal,
      resetLoyaltyProgramInfo,
      fetchUserAndLoadData,
      getCountrySettings,
      language,
      resetConfigurationSettings,
      resetMapFilterData,
      customerId
    } = this.props;
    this.setState({ showButtonLoader: true });
    resetLoyaltyProgramInfo();
    resetMapFilterData();
    const selectedCountryId = defaultCountry.countryId;
    const { data } = await getCountrySettings(
      language,
      selectedCountryId,
      null
    );
    resetConfigurationSettings();
    const [countryData] = data;
    const { countryCode } = countryData;
    setCommonData(countryData);
    fetchUserAndLoadData({ keepDeliverySettings: true });
    this.redirect(countryData);
    this.setState({ showButtonLoader: false });
    AnalyticService.common.trackCountryChange({
      countryId: countryData.countryId,
      language,
      customerId,
      countryName: countryData.countryName
    });
    GAService.common.trackCountryPreference(countryCode);
    closeModal();
  };

  redirect = defaultCountry => {
    const { countryData, ipCountryData, getStatesData, language, history } =
      this.props;

    _countryStoreSelectionViaModal(
      defaultCountry,
      countryData,
      ipCountryData,
      getStatesData,
      language,
      history,
      setCommonData,
      setUserCountryStorage,
      setUserCountrySelectionStorage
    );
  };

  render() {
    const {
      userPreferencesTranslation,
      language,
      countryData,
      ipCountryData,
      settings
    } = this.props;
    const {
      defaultCountry,
      freeShipping,
      currencyCode,
      shippingCharge,
      showButtonLoader
    } = this.state;
    return (
      <div
        className={`preference_modal_container generic_modal_content ${
          language.includes("ar-") || language.includes("ar") ? "arabic" : ""
        }`}
      >
        <SetUserPreferences
          userPreferencesTranslation={userPreferencesTranslation}
          defaultCountry={defaultCountry}
          onChangeHandler={this.onChangeHandlerUserPreferences}
          submit={this.submit}
          countryData={countryData}
          ipCountryData={ipCountryData}
          freeShipping={freeShipping}
          currencyCode={currencyCode || settings.currencyCode}
          defaultCurrency={
            (defaultCountry && defaultCountry.currencyCode) || "AED"
          }
          language={language}
          shippingCharge={shippingCharge}
          showButtonLoader={showButtonLoader}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryData: state.common.countryData,
  ipCountryData: state.common.ipCountryData,
  language: state.common.language,
  settings: state.common.settings,
  deliverySettings: state.common.settings.deliverySettings,
  customerId: selectCustomerId(state)
});

const mapDispatchToProps = {
  setCommonData,
  getStatesData,
  fetchUserAndLoadData,
  resetLoyaltyProgramInfo,
  getDeliverySettingsIfNeeded,
  getCountrySettings,
  resetConfigurationSettings,
  resetMapFilterData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPreferences)
);
