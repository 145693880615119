import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { selectLanguageSlug } from "../../../util/selectors";
import { dynamicModulesTranslation } from "../../../language/dynamicModules/en/dynamicModules";

const DEFAULT_MIN_HEIGHT = 150;

const ShowMoreHtmlWrapper = ({ children, showMoreContentHeight }) => {
  const language = useSelector(selectLanguageSlug);
  const [translation, setTranslation] = useState(dynamicModulesTranslation);
  const [showMoreStatus, setShowMoreStatus] = useState(false);
  const [isShowMoreAllowed, setIsShowMoreAllowed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const clientHeight = ref?.current?.clientHeight;
    const minClientHeight = showMoreContentHeight ?? DEFAULT_MIN_HEIGHT;
    setIsShowMoreAllowed(clientHeight > minClientHeight);
  }, []);

  useEffect(() => {
    (async () => {
      const response = await import(
        `../../../language/dynamicModules/${language}/dynamicModules`
      );
      setTranslation(response.dynamicModulesTranslation);
    })();
  }, [language]);

  const maxHeight = useMemo(() => {
    if (!isShowMoreAllowed || showMoreStatus) {
      return "fit-content";
    }
    return showMoreContentHeight ? `${showMoreContentHeight}px` : undefined;
  }, [showMoreContentHeight, isShowMoreAllowed, showMoreStatus]);

  return (
    <div className="show_more_html_wrapper" ref={ref}>
      <div
        className={cn("html_text", {
          html_text_full: showMoreStatus || !isShowMoreAllowed
        })}
        style={{ maxHeight }}
      >
        {children}
      </div>
      {isShowMoreAllowed && (
        <Fragment>
          {showMoreStatus ? (
            <div className={cn("show_button", "show_less_button")}>
              <a onClick={() => setShowMoreStatus(false)}>
                {translation.showLess}
              </a>
            </div>
          ) : (
            <div className={cn("show_button", "show_more_button")}>
              <a onClick={() => setShowMoreStatus(true)}>
                {translation.showMore}
              </a>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ShowMoreHtmlWrapper;
