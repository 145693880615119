import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import map from "lodash/map";
import { selectRouteSlug } from "../../util/selectors";
import { Image } from "../image";

const TopCategories = ({ history, language, routeSlug, topCategories }) => {
  const categories = get(topCategories, [language, "categories"]);
  const subTitle = get(topCategories, [language, "subTitle"], "");
  const title = get(topCategories, [language, "title"], "");

  const handleCategoryRedirection = itemName => {
    history.push(`/${routeSlug}${itemName}`);
  };

  return (
    <div>
      {!isEmpty(categories) && (
        <div className="top_categories">
          <div className="container">
            <div className="head_with_desc">
              <h2>{title}</h2>
              <p className="heading_desc">{subTitle}</p>
            </div>
            <ul className="clearfix categories_listing">
              {map(categories, (item, i) => (
                <li className="item-content" key={item._id || i}>
                  <div
                    className="inner_items"
                    onClick={() =>
                      handleCategoryRedirection(item.redirectionLink)
                    }
                  >
                    <div className="img_wrap">
                      <Image src={item.imageLink} alt="top-categories" />
                    </div>
                    <h5>{item.categoryName}</h5>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToprops = state => ({
  routeSlug: selectRouteSlug(state)
});

export default withRouter(
  connect(
    mapStateToprops,
    null
  )(TopCategories)
);
