import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import * as priceUtil from "../../../util/priceUtil";
import RetailPriceTooltip from "../../retail-price-tooltip";
import { getOptionalByValue, isMobile } from "../../../util";
import { selectMessagesFromConfigs } from "../../../util/selectors";
import ModalBfl from "../../modal/modal";
import PriceSection from "../price-with-client-render";
import { withClientOnlyRender } from "../../../hocs";
import questionMark from "../../../images/rrp_tooltip_mark.svg";

export const DiscountSection = ({
  item,
  countrySettings,
  translation,
  language,
  showOffText = true,
  showDiscountPrefix = false,
  discountPrefix = "-"
}) => {
  const percentage = priceUtil.getDiscountPercent(item, countrySettings);
  const isArabic = language.includes("ar");
  const prefix = showDiscountPrefix ? discountPrefix : "";
  const percentageValue =
    !prefix && isArabic ? `%${percentage}${prefix}` : `${prefix}${percentage}%`;
  const showDiscount = item.price?.old && percentage;

  return showDiscount ? (
    <p className="item_discount">
      <span className="product-discount">
        {percentageValue} {translation && showOffText && translation.off}
      </span>
    </p>
  ) : null;
};

const RetailPriceInfo = ({ isResponsiveView, language }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const message = useSelector(selectMessagesFromConfigs);

  return isResponsiveView ? (
    <>
      <img
        src={questionMark}
        alt="question-mark"
        className="question-mark"
        onClick={() => {
          setShowTooltip(true);
        }}
      />
      {showTooltip && (
        <ModalBfl
          showModal={showTooltip}
          modalClass={cn("generic_modal", "retail-price-modal", {
            arabic: language.includes("ar"),
            "responsive-view": isResponsiveView
          })}
        >
          <div className="tooltip-content">
            <div className="header-wrapper">
              <p className="header">{message?.pdpRrpPopupTitle}</p>

              <span
                className="cross_button"
                onClick={() => setShowTooltip(false)}
              >
                &times;
              </span>
            </div>
            <p className="mb-2 main-text">{message?.pdpRrpPopupContent}</p>
          </div>
        </ModalBfl>
      )}
    </>
  ) : (
    <span className="retail-price-wrapper">
      <img
        src={questionMark}
        alt="question-mark"
        className="question-mark"
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
      />
      {showTooltip && <RetailPriceTooltip message={message} isArrow />}
    </span>
  );
};

const RRPSection = ({
  rrp,
  save,
  percentage,
  currencyCode,
  translation,
  isFromPdp,
  language
}) => {
  const isArabic = language.includes("ar");
  const percentageValue = isArabic ? `%${percentage}` : `${percentage}%`;
  const isResponsiveView = isMobile.any();

  return (
    <div className="rrp_section">
      {rrp && (
        <span className={cn("rrp_value", { rrp_value_inline: isFromPdp })}>
          <span className="rrp_text">{translation.rrp}</span>
          {currencyCode} {rrp}
        </span>
      )}
      {save && (
        <span className="rrp_save">
          {translation.rrp_save} {currencyCode} {save}
        </span>
      )}
      {percentage && (
        <span className="rrp_percentage">
          {percentageValue} {translation && translation.off}
        </span>
      )}
      {isFromPdp && (
        <RetailPriceInfo
          language={language}
          isResponsiveView={isResponsiveView}
        />
      )}
    </div>
  );
};

const PriceSlab = ({
  item,
  gridSize,
  translation,
  language,
  currencyCode,
  showDiscountSlab = true,
  settings = {},
  isPdp,
  showRetailPrice,
  isFromPdp,
  isSalePage,
  showDiscount
}) => {
  const cartPrice = priceUtil.shapePriceWithComma(
    item.transactionPrice,
    settings.decimalPlaces
  );
  const normalPrice = priceUtil.convertWithComma(
    item.price?.current || item.price || 0,
    settings
  );
  const retailPrice = priceUtil.convertWithComma(
    item.retailPrice || 0,
    settings
  );

  const PriceInfo = isPdp
    ? withClientOnlyRender(() => (
        <PriceSection
          currencyCode={currencyCode}
          item={item}
          cartPrice={cartPrice}
          normalPrice={normalPrice}
          countrySettings={settings}
        />
      ))
    : () => (
        <PriceSection
          currencyCode={currencyCode}
          item={item}
          cartPrice={cartPrice}
          normalPrice={normalPrice}
          countrySettings={settings}
        />
      );

  const rrpProps = useMemo(() => {
    if (!item.price?.rrp) return;
    return {
      ...getOptionalByValue({
        value: item.price.rrp.savedPrice,
        keyName: "save",
        callback: priceUtil.convertWithComma
      }),
      ...getOptionalByValue({
        value: item.price.rrp.discountPercentage,
        keyName: "percentage"
      }),
      ...getOptionalByValue({
        value: item.price.rrp.price,
        keyName: "rrp",
        callback: priceUtil.convertWithComma
      })
    };
  }, [
    item.price?.rrp?.savedPrice,
    item.price?.rrp?.discountPercentage,
    item.price?.rrp?.price
  ]);

  const showRRP = !isEmpty(rrpProps) && showRetailPrice;

  return (
    <div
      id="price-slab"
      className={`item_price_slab ${
        language && (language.includes("ar-") || language.includes("ar"))
          ? "arabic"
          : ""
      }`}
    >
      {!isSalePage && <PriceInfo />}

      {showDiscountSlab && !isFromPdp && showDiscount && (
        <DiscountSection
          item={item}
          countrySettings={settings}
          translation={translation}
          language={language}
        />
      )}
      {showRRP && (
        <RRPSection
          {...rrpProps}
          isFromPdp={isFromPdp}
          translation={translation}
          currencyCode={currencyCode}
          language={language}
        />
      )}
    </div>
  );
};

export default PriceSlab;
