import { BEConfig } from "../../config/env";
import { fetchWebApi, postDashboardWebApi } from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  getVisitorId
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import { setCheckoutOrder, setCheckoutRequestData } from "./checkout.action";
import { tokenGeneratorCheckoutGuest } from "./authConfig.action";
import { getGuestUserStorage } from "../../util/browserStorage";

import { SET_ORDER_DATA, SET_ORDER_STATUS } from "../constants";
import { COUNTRY_CODE, COUPON_CODE } from "../constants/common";
import { setCookie, getCookie } from "../../util/browserStorage";
import { selectRouteSlug } from "../../util/selectors";
import { GAService } from "../../services/GA-service";

export const setOrder = order => ({ type: SET_ORDER_DATA, order });

export const setOrderStatus = order => ({ type: SET_ORDER_STATUS, order });

export const confirmOrder = (bodyData, history, language, CC = false) => {
  language = languageFromPathName(language);
  const {
    checkoutApi: {
      baseURL,
      protocol,
      port,
      versionInfo,
      checkoutOrderHandle,
      confirmOrderHandle
    }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${
    CC ? confirmOrderHandle : checkoutOrderHandle
  }`;

  return (dispatch, getState) => {
    const { countryId, countryCode } = getState().common.settings;
    const { voucherCode } = getState().checkoutReducer.checkoutData;
    const routeSlug = selectRouteSlug(getState());
    setCookie(COUNTRY_CODE, countryCode);
    if (!CC) setCookie(COUPON_CODE, voucherCode);

    const updatedBody = {
      ...bodyData,
      countryId: countryId || bodyData.countryId,
      couponCode: getCookie(COUPON_CODE),
      countryCode: getCookie(COUNTRY_CODE)
    };
    dispatch(setCheckoutRequestData(updatedBody));
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      updatedBody,
      getVisitorId()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          if (history && language && response.data.orderId) {
            dispatch(setCheckoutOrder(response.data));
          }
        } else {
          throw new Error(response.data?.error?.message);
        }
        return response;
      })
      .catch(error => {
        console.error(error);
        const originLocation = window.location.origin;
        const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;

        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);

        window.location.href = failedPageUrl;

        return error.response;
      });
  };
};

export const getOrderData = (orderID, language, needToSetOrder = true) => {
  language = languageFromPathName(language);
  const {
    customerApi: { baseURL, protocol, port, versionInfo, orderHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${orderHandle}/${orderID}`;
  return async (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    const userLoggedIn = getState().authReducer.userLoggedIn;
    if (userLoggedIn === false) {
      dispatch(tokenGeneratorCheckoutGuest(getGuestUserStorage()));
    }
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (
          response.data &&
          response.data.success &&
          response.data.order &&
          needToSetOrder
        ) {
          dispatch(setOrder(response.data.order));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getOrderStatusData = (token, language) => {
  const {
    customerApi: { baseURL, protocol, port, versionInfo, orderStatus }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${orderStatus}?token=${token}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.data && response.data.success && response.data) {
          dispatch(setOrderStatus(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const sendOtp = bodyData => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, otpHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${otpHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState) || getGuestUserStorage(),
      apiURL,
      bodyData,
      getVisitorId()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const verifyOtp = bodyData => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, verifyOtpHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${verifyOtpHandle}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState) || getGuestUserStorage(),
      apiURL,
      bodyData,
      getVisitorId()
    )
      .then(response => {
        if (response.data?.success) {
          GAService.auth.trackOTPVerificationSuccess();
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getCCOrderDetails = ({ token, orderId }) => {
  const {
    customerApi: { baseURL, protocol, port, userOrderDetails }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}/api/v6/${userOrderDetails}`;

  return async dispatch => {
    dispatch(showLoadingIndicator());
    try {
      const bodyData = { token, orderId };

      const response = await postDashboardWebApi(null, apiURL, { ...bodyData });
      if (response.data && response.status === 200) {
        dispatch(setOrder(response.data));
      }
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.message;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };
};
