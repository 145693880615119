import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import ResetPassword from "../../components/modal/reset-password";
import ModalBFL from "../../components/modal/modal";
import MyDetailsForm from "./my-details-form";
import {
  addToWallet,
  handleUserInfo,
  myAccountDetails,
  updateUser,
  resetUpdateError
} from "../../redux/actions/myAccount.action";
import {
  resetPasswordData,
  resetAPIError
} from "../../redux/actions/homepage.action";
import DeleteModal from "../../components/delete-modal";
import AnalyticService from "../../services/analytic-service";
import MyAccMobileNumberModal from "../../components/modal/modal-mobile-verification/my-acc-mobile-number-modal";
import {
  selectHomePageApiErrorMessage,
  selectHomePageApiErrorCode,
  selectUpdateError
} from "../../util/selectors";
import { SIGNUP_CODES_FOR_MODAL } from "../../constants";
import { GAService } from "../../services/GA-service";

class MyDetails extends Component {
  state = {
    resetPasswordModal: false,
    areaCode: "",
    disabled: true,
    isConfirmModalActive: false,
    slicePhoneLength: 0,
    detailsData: null,
    updatedMyDetails: {}
  };

  phoneNumberRef = React.createRef();

  toggleResetPasswordModal = value => {
    this.setState({ resetPasswordModal: value });
    this.props.resetPasswordData();
    if (value === false) {
      this.props.resetPasswordData();
      const { addToWallet } = this.props;
      addToWallet("apiErrorResponse", "");
    }
  };

  componentDidMount() {
    this.props.myAccountDetails(this.props.authReducer);
  }

  UNSAFE_componentWillMount() {
    const { authReducer, userOriginCountryData } = this.props;
    const _areaCode = get(userOriginCountryData, "mobileLocalCode", "").split(
      ","
    );
    const code = get(authReducer, "phone", "").substring(
      0,
      _areaCode && _areaCode[0].length
    );
    this.setState({ areaCode: code || (_areaCode && _areaCode[0]) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { authReducer, userOriginCountryData } = this.props;
    const _areaCode = get(userOriginCountryData, "mobileLocalCode", "").split(
      ","
    );
    const _areaCodeLength = get(_areaCode, "[0]", "").length;
    const slicePhoneLength =
      get(userOriginCountryData, "mobileLocalNumberLength", 0) +
      _areaCodeLength;
    const code = get(nextProps, "authReducer.phone", "")
      .slice(-slicePhoneLength)
      .slice(0, _areaCodeLength);
    if (this.state.slicePhoneLength === 0 && slicePhoneLength) {
      this.setState({
        areaCode: code || (_areaCode && _areaCode[0]),
        slicePhoneLength
      });
    }
    if (!isEqual(authReducer, nextProps.authReducer)) {
      this.props.myAccountDetails(nextProps.authReducer);
    }
  }

  onConfirmModal = async () => {
    const {
      updateUser,
      myDetails,
      userOriginCountryData,
      authReducer,
      selectedCountryMobileDetails
    } = this.props;
    const {
      detailsData: {
        firstname,
        lastname,
        email,
        phone,
        gender,
        areaCode,
        birthday
      }
    } = this.state;

    const changes = [...this.state.detailsData?.changes];

    const customerId = get(authReducer, "customerId", "");

    const countryData = isEmpty(selectedCountryMobileDetails)
      ? userOriginCountryData
      : selectedCountryMobileDetails;

    const mobileCountryCode = get(countryData, "mobileCountryCode", "");
    const fullPhoneNumber = `${mobileCountryCode}${areaCode}${phone}`;
    const previousPhone = myDetails.info?.phone;

    if (fullPhoneNumber !== previousPhone) {
      changes.push("phone");
    }

    const isMobileVerified =
      authReducer.phone === fullPhoneNumber && authReducer.isMobileVerified;
    const updatedMyDetails = {
      firstname,
      lastname,
      email,
      gender,
      countryId: countryData.countryId,
      isMobileVerified,
      phone: fullPhoneNumber,
      nationality: myDetails.info.nationality,
      birthday,
      changes
    };

    const resp = await updateUser(updatedMyDetails);
    this.setState({ disabled: true, updatedMyDetails });
    if (resp.data?.success) {
      GAService.common.trackAccountInfoSaved();
      AnalyticService.auth.trackProfileUpdate({ updatedMyDetails, customerId });
    }
  };

  setConfirmModalState = value => {
    this.setState({ isConfirmModalActive: value });
  };

  getAccountPhoneDetails() {
    const { userOriginCountryData } = this.props;
    const { mobileCountryCode, mobileLocalCode, mobileLocalNumberLength } =
      userOriginCountryData || {};

    return {
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };
  }

  onFormikSubmit = (detailsData, previousData) => {
    const phoneDetails = ["phone", "areaCode", "countryCode"];
    const changes = Object.keys(detailsData).filter(
      key => detailsData[key] !== previousData[key]
    );

    const changesWithoutProneDetails = changes.filter(
      value => !phoneDetails.includes(value)
    );

    this.setState({
      detailsData: { ...detailsData, changes: changesWithoutProneDetails }
    });
    this.setConfirmModalState(true);
  };

  resetApiErrors = () => {
    const { resetAPIError, resetUpdateError } = this.props;
    resetAPIError();
    resetUpdateError();
  };

  focusOnPhoneInput = () => {
    if (this.phoneNumberRef.current) {
      //timeout for the hide modal animation done
      setTimeout(() => {
        this.phoneNumberRef.current.focus();
      }, 300);
    }
  };

  render() {
    const {
      authReducer,
      myDetails,
      myAccountTranslation,
      language,
      resetPasswordTranslation,
      countryData,
      handleGoBack,
      userOriginCountryData,
      selectedCountryMobileDetails,
      homePageApiErrorMessage,
      homePageApiErrorCode,
      updateUser,
      updateError
    } = this.props;
    const {
      resetPasswordModal,
      isConfirmModalActive,
      detailsData,
      updatedMyDetails
    } = this.state;

    const phoneNumberErrorToBePassed =
      (homePageApiErrorCode &&
        !SIGNUP_CODES_FOR_MODAL[homePageApiErrorCode] &&
        homePageApiErrorMessage) ||
      updateError.message;
    const fullPhoneNumber = `${selectedCountryMobileDetails?.mobileCountryCode}${detailsData?.areaCode}${detailsData?.phone}`;

    return (
      <div className="overview-wrapper">
        <OverViewHeading
          headingName={myAccountTranslation.myDetails}
          icon="my_details_icon"
          handleGoBack={handleGoBack}
        />
        <MyAccMobileNumberModal
          data={myDetails.info || {}}
          customerPhone={fullPhoneNumber}
          phoneNumberParts={this.getAccountPhoneDetails()}
          focusOnPhoneInput={this.focusOnPhoneInput}
          updateUser={() => updateUser(updatedMyDetails)}
        />
        {myDetails.info.email && (
          <MyDetailsForm
            accountDetails={myDetails}
            accountPhoneDetails={this.getAccountPhoneDetails()}
            myAccountTranslation={myAccountTranslation}
            onSubmit={this.onFormikSubmit}
            toggleResetPasswordModal={() => this.toggleResetPasswordModal(true)}
            countryData={countryData}
            userOriginCountryData={userOriginCountryData}
            selectedCountryMobileDetails={selectedCountryMobileDetails}
            resetAPIError={this.resetApiErrors}
            forwardedRef={this.phoneNumberRef}
            language={language}
            isAbleToSetBirthday={!Boolean(myDetails.info.birthday)}
            {...(phoneNumberErrorToBePassed && {
              errorMessage: homePageApiErrorMessage || updateError.message
            })}
          />
        )}

        <ModalBFL
          showModal={resetPasswordModal}
          modalClass={cn("generic_modal", "reset-password-modal", {
            arabic: language.includes("ar")
          })}
        >
          <button
            className="cross_btn"
            onClick={e => this.toggleResetPasswordModal(false)}
          >
            &times;
          </button>
          <div className="generic_modal_content">
            <ResetPassword
              currentPassword={!!authReducer.BFLUser}
              token={authReducer.accessToken}
              email={myDetails.info.email}
              language={language}
              resetPasswordTranslation={resetPasswordTranslation}
              toggleResetPasswordModal={() =>
                this.toggleResetPasswordModal(false)
              }
            />
          </div>
        </ModalBFL>
        <DeleteModal
          language={language}
          titleTxt={myAccountTranslation.saveDetailsTitle}
          bodyTxt={myAccountTranslation.saveDetails}
          cancelBtnTxt={myAccountTranslation.cancel}
          deleteBtnTxt={myAccountTranslation.save}
          isDeleteModalActive={isConfirmModalActive}
          handleCloseModal={this.setConfirmModalState}
          handleDeleteItem={this.onConfirmModal}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authReducer: state.authReducer,
  countryData: state.common.countryData,
  countryId: get(state, "common.settings.countryId", ""),
  language: state.common.language,
  mobileLocalNumberLength: get(
    state,
    "common.settings.mobileLocalNumberLength",
    0
  ),
  myDetails: state.myAccountReducer.myDetails,
  userOriginCountryData: state.common.userOriginCountryData,
  selectedCountryMobileDetails: state.common.selectedCountryMobileDetails,
  homePageApiErrorMessage: selectHomePageApiErrorMessage(state),
  homePageApiErrorCode: selectHomePageApiErrorCode(state),
  updateError: selectUpdateError(state)
});

const mapDispatchToProps = {
  addToWallet,
  handleUserInfo,
  myAccountDetails,
  resetPasswordData,
  updateUser,
  resetAPIError,
  resetUpdateError
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails);
