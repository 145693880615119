import {
  QUERY_STRING_CONSTANTS,
  COUNTRY_NAME_SEO,
  SEO_BRAND_TITLE_FORMAT,
  SEO_BRAND_DESCRIPTION_FORMAT
} from "../constants";
import includes from "lodash/includes";
import replace from "lodash/replace";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import get from "lodash/get";
import split from "lodash/split";
import trim from "lodash/trim";

export const getMetaTitleDescription = (
  redirectionData,
  history,
  settings,
  brandPageDetails
) => {
  let title = get(redirectionData, "meta.title", "");
  let description = get(redirectionData, "meta.metaDescription", "");
  let meta = {};
  const countryCode = get(settings, "countryCode", "");
  const query = new URLSearchParams(history.location.search);
  const brand = query.get("brand");
  const promotion = query.get("promotion");
  const occasion = query.get("occasion");
  if (brandPageDetails) {
    const seoBrandName = get(brandPageDetails, "seoBrandName", "");
    title = replace(SEO_BRAND_TITLE_FORMAT, "BRAND_NAME_SEO", seoBrandName);
    description = replace(
      SEO_BRAND_DESCRIPTION_FORMAT,
      "BRAND_NAME_SEO",
      seoBrandName
    );
  }
  if (brand) {
    const brandCount = split(brand, ",").length;
    if (brandCount > 1) {
      title = replace(title, "BRAND_NAME_SEO", "");
      description = replace(description, "BRAND_NAME_SEO", "");
    }
    const modifiedBrand = startCase(toLower(brand));
    title = replace(title, "BRAND_NAME_SEO", modifiedBrand);
    description = replace(description, "BRAND_NAME_SEO", modifiedBrand);
  }
  if (promotion) {
    const promotionCount = split(promotion, ",").length;
    if (promotionCount > 1) {
      title = replace(title, "PROMOTION_NAME_POST_HYPHEN", "");
      description = replace(description, "PROMOTION_NAME", "");
    }
    const modifiedPromotionPostHyphen = startCase(toLower(promotion)) + " -";
    const modifiedPromotion = startCase(toLower(promotion)) + "!";
    title = replace(
      title,
      "PROMOTION_NAME_POST_HYPHEN",
      modifiedPromotionPostHyphen
    );
    description = replace(description, "PROMOTION_NAME", modifiedPromotion);
  }
  if (occasion) {
    const modifiedOccasion = startCase(toLower(occasion));
    title = replace(title, "OCCASION_SEO", modifiedOccasion);
    description = replace(description, "OCCASION_SEO", modifiedOccasion);
  }

  meta = { title, description };
  const seoTitleDescrp = formatTitleDescription(meta, countryCode);
  return seoTitleDescrp;
};

export const formatTitleDescription = (meta, countryCode) => {
  let metaTitle = get(meta, "title", "");
  let metaDescrp = get(meta, "description", "");
  let titleDescrp = {};
  QUERY_STRING_CONSTANTS.forEach(queryString => {
    if (includes(metaTitle, queryString)) {
      metaTitle = replace(replace(metaTitle, queryString, ""), /  +/g, " ");
    }
    if (includes(meta.description, queryString)) {
      metaDescrp = replace(replace(metaDescrp, queryString, ""), /  +/g, " ");
    }
  });
  metaDescrp = replace(metaDescrp, COUNTRY_NAME_SEO, countryCode);
  metaTitle = replace(trim(metaTitle), /  +/g, " ");
  metaDescrp = replace(trim(metaDescrp), /  +/g, " ");
  titleDescrp = { title: metaTitle, description: metaDescrp };
  return titleDescrp;
};
