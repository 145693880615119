import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import OverViewCard from "../../components/my-acc/myAcc-overview-card";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import MyWalletBalance from "../../components/my-acc/myAcc-wallet-balance";
import MyWalletUsp from "../../components/my-acc/myAcc-wallet-usp";
import MyWalletTransaction from "../../components/my-acc/myAcc-wallet-transaction";
import {
  fetchEarnedLoyaltyPoints,
  fetchMyWallet,
  fetchMyWalletHistory,
  fetchWalletExpiryDetails
} from "../../redux/actions/myAccount.action";
import {
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  WALLET_FAQ_ACCORDION
} from "../../constants";
import { needHelp } from "../../language/needHelp/en/needHelp";
import {
  changeLanguage,
  saveBreadcrumb
} from "../../redux/actions/common.action";
import { getNeedHelpData } from "../../redux/actions/needHelp.action";
import withLanguageFromUrl from "../../hocs/withLanguageFromUrl";
import { getSubModuleIframeUrl } from "../../util/submodule";
import { selectSubmoduleURL } from "../../util/selectors";

class MyWallet extends Component {
  state = {
    captchaValue: "",
    voucherCode: "",
    needHelpTranslation: needHelp,
    pageNum: 1
  };

  UNSAFE_componentWillMount() {
    const {
      language,
      fullLanguage,
      settings,
      golbalCurrency,
      currentLanguage,
      isJoinedToLoyaltyProgram,
      fetchMyWallet,
      handleLanguageChange,
      getNeedHelpData,
      fetchWalletExpiryDetails
    } = this.props;
    handleLanguageChange(currentLanguage);
    this.getlanguage(currentLanguage);
    if (isJoinedToLoyaltyProgram) {
      fetchMyWallet &&
        fetchMyWallet(language, golbalCurrency && golbalCurrency);
      fetchWalletExpiryDetails(language, golbalCurrency, settings.countryId);
    }
    settings &&
      settings.countryId &&
      getNeedHelpData(fullLanguage, settings.countryId);
  }

  componentDidMount() {
    const breadcrumbStore = {};
    const {
      pageName,
      saveBreadcrumb,
      golbalCurrency,
      language,
      fetchEarnedLoyaltyPoints,
      settings
    } = this.props;
    breadcrumbStore.modelName = "category";
    breadcrumbStore.modelData = {
      categoryName: pageName,
      categoryUrl: pageName
    };
    saveBreadcrumb(breadcrumbStore);
    if (golbalCurrency) {
      fetchEarnedLoyaltyPoints(language, golbalCurrency, settings.countryId);
    }
  }
  isRegisteredOnLoyalty = (prevProps, nextProps) =>
    !nextProps.isJoinedToLoyaltyProgram &&
    nextProps.isJoinedToLoyaltyProgram !== prevProps.isJoinedToLoyaltyProgram;

  isUpdatedGolbalCurrency = (prevGolbalCurrency, nextGolbalCurrency) =>
    prevGolbalCurrency !== nextGolbalCurrency;

  shouldFetchEarnedLoyaltyPoints = (prevProps, nextProps) =>
    (this.isUpdatedGolbalCurrency(
      prevProps.golbalCurrency,
      nextProps.golbalCurrency
    ) &&
      !nextProps.isLoadingLoyaltyPoints) ||
    (this.isRegisteredOnLoyalty(prevProps, nextProps) &&
      !nextProps.isLoadingLoyaltyPoints);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      golbalCurrency,
      fetchMyWallet,
      getNeedHelpData,
      language,
      settings,
      isJoinedToLoyaltyProgram,
      fetchWalletExpiryDetails,
      fetchEarnedLoyaltyPoints
    } = this.props;
    const countryId = get(settings, "countryId", "");
    const nextGolbalCurrency = get(nextProps, "golbalCurrency", "");
    const nextCountryId = get(nextProps, "settings.countryId", "");
    const needUpdateGeneralData =
      nextProps.language !== language ||
      (countryId && nextCountryId && nextCountryId !== countryId) ||
      (!countryId && nextCountryId);

    if (needUpdateGeneralData) {
      this.getlanguage(nextProps.language);
      if (nextCountryId) {
        getNeedHelpData(nextProps.fullLanguage, nextCountryId);
      }
    }
    if (nextGolbalCurrency) {
      if (this.shouldFetchEarnedLoyaltyPoints(this.props, nextProps)) {
        fetchEarnedLoyaltyPoints(
          nextProps.language,
          nextGolbalCurrency,
          nextCountryId
        );
      } else if (
        nextProps.isJoinedToLoyaltyProgram &&
        nextProps.isJoinedToLoyaltyProgram !== isJoinedToLoyaltyProgram
      ) {
        fetchMyWallet(nextProps.language, nextGolbalCurrency);
        fetchWalletExpiryDetails(
          nextProps.language,
          nextGolbalCurrency,
          nextCountryId
        );
      }
    }
  }

  getlanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(
      `../../language/needHelp/${language}/needHelp`
    );
    languageResponse.then(response => {
      this.setState({
        needHelpTranslation: response.needHelp
      });
    });
  };

  fetchMoreHistory = () => {
    const { golbalCurrency, language, fetchMyWalletHistory } = this.props;

    const newPageNum = this.state.pageNum + 1;
    this.setState({ pageNum: newPageNum });

    fetchMyWalletHistory(language, golbalCurrency, newPageNum);
  };

  render() {
    const {
      myAccountTranslation,
      wallet,
      golbalCurrency,
      handleGoBack,
      settings,
      fullWalletHistoryLoaded,
      walletExpiryDetails,
      language,
      isLoadingLoyaltyPoints,
      isJoinedToLoyaltyProgram,
      submoduleUrl
    } = this.props;

    const transactionsTableTitle = (
      <>
        <span>{myAccountTranslation.transactionHistory}</span>
      </>
    );

    return (
      <div className="overview-wrapper">
        <OverViewHeading
          headingName={myAccountTranslation.myWallet}
          icon="my_wallet_icon"
          handleGoBack={handleGoBack}
        />
        <div className="my_wallet_container">
          <div className="container wallet_data_container mb-xl-3">
            <div className="row wallet_row">
              <div className="col col-12 col-xl-5 d-flex flex-column ballance_col">
                <MyWalletBalance
                  myAccountTranslation={myAccountTranslation}
                  golbalCurrency={golbalCurrency}
                  walletAmount={wallet.walletAmount}
                  countryId={settings.countryId}
                  expiryDetails={walletExpiryDetails}
                  language={language}
                />
              </div>
              <div className="col col-12 col-xl-7 px-0 d-flex flex-column">
                <OverViewCard
                  isTitleStrip={true}
                  title={transactionsTableTitle}
                >
                  <MyWalletTransaction
                    fetchMoreHistory={this.fetchMoreHistory}
                    fullWalletHistoryLoaded={fullWalletHistoryLoaded}
                    myAccountTranslation={myAccountTranslation}
                    walletHistoryDetails={wallet && wallet.walletHistoryDetails}
                    isJoinedToLoyaltyProgram={isJoinedToLoyaltyProgram}
                    isLoadingLoyaltyPoints={isLoadingLoyaltyPoints}
                  />
                </OverViewCard>
              </div>
            </div>
          </div>
          <MyWalletUsp myAccountTranslation={myAccountTranslation} />
          {submoduleUrl && (
            <iframe
              src={getSubModuleIframeUrl({
                domain: submoduleUrl,
                page: WALLET_FAQ_ACCORDION
              })}
              id="sub_module_iframe"
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  wallet: state.myAccountReducer.wallet,
  isLoadingLoyaltyPoints: state.myAccountReducer.isLoadingLoyaltyPoints,
  language: state.common.language,
  golbalCurrency: state.common.settings.currencyCode,
  needHelpContent: state.needHelpReducer.needHelpContent,
  settings: state.common.settings,
  fullLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  fullWalletHistoryLoaded: state.myAccountReducer.fullWalletHistoryLoaded,
  walletExpiryDetails: state.myAccountReducer.walletExpiryDetails,
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  submoduleUrl: selectSubmoduleURL(state)
});
const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  fetchMyWallet,
  fetchMyWalletHistory,
  getNeedHelpData,
  saveBreadcrumb,
  fetchWalletExpiryDetails,
  fetchEarnedLoyaltyPoints
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withLanguageFromUrl
)(MyWallet);
