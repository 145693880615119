import React from "react";
const SizeInternational = ({ header, colorSizeOption, handleSizeChange }) => {
  return (
    <div className="international_sizes">
      <h5>{header}</h5>
      <ul className="size_list">
        {colorSizeOption &&
          colorSizeOption.map((item, index) => {
            const a = Object.keys(item);
            return (
              <li
                key={index}
                className={`${
                  a[0] && item[a[0]].checked ? "size_box_color" : ""
                }`}
                onClick={() =>
                  handleSizeChange(
                    "level2",
                    item,
                    header,
                    item[a[0]].subFacet,
                    !item[a[0]].checked
                  )
                }
              >
                {a[0]}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default SizeInternational;
