import {
  SET_SEARCH_DATA,
  HANDLE_SEARCH_TEXT,
  SET_SUGGESTIONS,
  SET_TRENDING
} from "../constants";

const initialState = {
  searchText: "",
  hits: [],
  nbPages: 0,
  hitsPerPage: 0,
  suggestions: [],
  trending: [],
  products: []
};

const handleSearchText = (state, { searchText = "" }) => ({
  ...state,
  searchText
});

const searchSearchData = (state, { searchResult }) => ({
  ...state,
  ...searchResult
});

const setSearchSuggestions = (state, { suggestions, products }) => ({
  ...state,
  suggestions,
  products
});

const setSearchTrending = (state, { trending }) => ({ ...state, trending });

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SEARCH_TEXT:
      return handleSearchText(state, action);
    case SET_SEARCH_DATA:
      return searchSearchData(state, action);
    case SET_SUGGESTIONS:
      return setSearchSuggestions(state, action);
    case SET_TRENDING:
      return setSearchTrending(state, action);
    default:
      return state;
  }
};

export default commonReducer;
