import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import debounce from "lodash/debounce";
import get from "lodash/get";
import cn from "classnames";

import { appSettings } from "./../../config/app";
import { ONE_SIZE } from "../../constants";
import { SECTION_PDP_TYPES, SECTION_SLUGS } from "../../constants/sections";

import {
  getProductUrlName,
  isServer,
  removeSpecialCharacters,
  selectedItemSizeId,
  decodeURLRecursively
} from "../../util";
import { getInternationalSizes } from "../../util/cart";
import {
  selectPDPBannerSections,
  selectSyteSettings,
  selectWishListGeneralData,
  selectIsCheckedLoyaltyProgram
} from "../../util/selectors";

import AnalyticService from "../../services/analytic-service";
import AlgoliaInsightsService from "../../services/AlgoliaInsightsService";

import {
  changeLanguage,
  getLoyalityPoints,
  resetBreadCrumb,
  saveBreadcrumb,
  setRecentlyViewed
} from "../../redux/actions/common.action";
import {
  clearSizeSelection,
  fetchProductDetail,
  resetProductDetail
} from "../../redux/actions/productDetail.action";
import {
  deleteWishListData,
  saveToWishList,
  getWishListBaseData
} from "../../redux/actions/wishList.action";
import {
  fetchCartData,
  getCartAndWishlistGeneralData,
  saveToCart
} from "../../redux/actions/cart.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getRecentlyViewed } from "../../redux/actions/common.action";
import { fetchGeneratedUrls } from "../../redux/actions/productlisting.action";
import { getBannerSections } from "../../redux/actions/section.action";

import ErrorPage from "../error-page";

import AddToCart from "../../components/add-to-cart";
import Breadcrumb from "../../components/breadcrumb";
import { Image } from "../../components/image";
import Page from "../../components/page";
import RecentlyViewed from "../../components/recent-views";
import SyteSimilarItems from "../../components/syte";

import { productDetail as productDetailEng } from "../../language/productDetail/en/productDetail";
import productDetailLoaderImage from "../../images/product_detail_loading.png";
import { seoTranslation } from "../../language/seo/en/seo";

import ssrConfig from "./ssrConfig";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import { fetchEarnedLoyaltyPoints } from "../../redux/actions/myAccount.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { GAService } from "../../services/GA-service";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    const urlLang = get(props, "match.params.language").split("-")[0];

    this.state = {
      productDetailTranslation: productDetailEng,
      selectedSize: "",
      selectedSizeId: null,
      selectedQty: 1,
      showQtyTextBox: false,
      errShowQtyTextBox: "",
      addedToCart: false,
      currentSize: "",
      errPage: false,
      seoTranslated: seoTranslation[urlLang]
    };
  }

  async componentDidMount() {
    const {
      match,
      handleLanguageChange,
      fetchGeneratedUrls,
      resetBreadCrumb,
      productDetail,
      getBannerSections,
      countryId
    } = this.props;

    resetBreadCrumb();
    fetchGeneratedUrls();

    this.setState({ currentSize: "" });

    const { language, id } = match.params;

    if (countryId) {
      await Promise.all([
        this.fetchProductDataDetail(id, language),
        getBannerSections(SECTION_SLUGS.PDP)
      ]);
    }

    handleLanguageChange(language);
    this.loadLanguage(language);

    const _colorSizeOptions = get(productDetail, "colors.colorSizeOptions", []);
    const availableSizes = _colorSizeOptions.filter(option => option.sizeLabel);
    const _sizeLabel = get(availableSizes, "0.sizeLabel");
    const _sizeId = get(availableSizes, "0.sizeId");
    if (_sizeLabel === ONE_SIZE) {
      this.setState({ selectedSize: _sizeLabel, selectedSizeId: _sizeId });
    }
  }

  fetchProductDataDetail = async (id, language) => {
    const {
      fetchProductDetail,
      authReducer,
      commonSettings,
      saveBreadcrumb,
      currencyCode,
      sysGeneratedUrl,
      location,
      countryId,
      userLoggedIn,
      fetchEarnedLoyaltyPoints
    } = this.props;
    const { errPage } = this.state;
    try {
      const response = await fetchProductDetail(id, language);
      if (response && response.data && response.status === 200) {
        const { id, title, en_title } = response.data;
        setRecentlyViewed(response.data);
        this.handleSaveBreadCrumb(sysGeneratedUrl, this.props.language);
        if (errPage) {
          this.setState({ errPage: false });
        }

        saveBreadcrumb({
          modelName: "product",
          modelData: {
            productName: removeSpecialCharacters(
              getProductUrlName(en_title || title)
            ),
            productUrl: `/${language}/${getProductUrlName(
              en_title || title
            )}/${id}/p/`
          }
        });

        if (userLoggedIn && countryId) {
          fetchEarnedLoyaltyPoints(language, currencyCode, countryId);
        }

        AnalyticService.product.trackProductDetails({
          response,
          authReducer,
          commonSettings,
          location
        });
        GAService.product.trackViewProduct({
          item: response.data
        });

        AlgoliaInsightsService.trackProductDetailView({ productId: id });

        try {
          const recentlyViewed = getRecentlyViewed();
          const item = get(response, "data", {});
          AnalyticService.product.trackProductDetailImpressions({
            item,
            authReducer,
            commonSettings,
            recentlyViewed
          });
        } catch {}
      } else {
        this.setState({ errPage: true });
      }
    } catch (error) {
      this.setState({ errPage: true });
    }
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isServer) {
      const {
        match,
        fetchProductDetail,
        resetProductDetail,
        authReducer,
        commonSettings,
        saveBreadcrumb,
        sysGeneratedUrl,
        language,
        location,
        getBannerSections,
        countryId,
        fetchEarnedLoyaltyPoints,
        userLoggedIn
      } = this.props;
      const _nextPropsColorSizeOptions = get(
        nextProps.productDetail,
        "colors.colorSizeOptions",
        []
      );
      const availableSizes = _nextPropsColorSizeOptions.filter(
        option => option.sizeLabel
      );
      const _sizeLabel = get(availableSizes, "0.sizeLabel");
      if (_sizeLabel === ONE_SIZE) {
        const _sizeId = get(availableSizes, "0.sizeId");
        this.setState({ selectedSize: _sizeLabel, selectedSizeId: _sizeId });
      }
      if (nextProps.countryId && nextProps.countryId !== this.props.countryId) {
        const { language, id } = nextProps.match.params;
        await Promise.all([
          this.fetchProductDataDetail(id, language),
          getBannerSections(SECTION_SLUGS.PDP)
        ]);
      }
      if (nextProps.language !== this.props.language) {
        this.props.resetBreadCrumb();
        this.loadLanguage(nextProps.language);
        Promise.all([
          fetchProductDetail(nextProps.match.params.id, nextProps.language),
          getBannerSections(SECTION_SLUGS.PDP)
        ]).then(([productDetail]) => {
          this.replaceProductNameInUrl(
            productDetail.data,
            nextProps.match.params
          );
        });
      }

      if (
        (sysGeneratedUrl &&
          nextProps.sysGeneratedUrl &&
          sysGeneratedUrl.length !== nextProps.sysGeneratedUrl.length) ||
        language !== nextProps.language
      ) {
        const lng = language !== nextProps.language ? nextProps.language : null;
        this.handleSaveBreadCrumb(nextProps.sysGeneratedUrl, lng);
      }

      if (
        nextProps.countryId &&
        nextProps.countryId !== countryId &&
        userLoggedIn
      ) {
        fetchEarnedLoyaltyPoints(
          nextProps.language,
          nextProps.currencyCode,
          nextProps.countryId
        );
      }
      if (
        (nextProps.match &&
          nextProps.match.params &&
          nextProps.match.params.id !== match.params.id) ||
        nextProps.language !== this.props.language
      ) {
        resetProductDetail();
        fetchProductDetail(nextProps.match.params.id, nextProps.language).then(
          response => {
            if (response && response.data) {
              this.handleSaveBreadCrumb(
                nextProps.sysGeneratedUrl,
                nextProps.language
              );
              const { id, title, en_title } = response.data;

              saveBreadcrumb({
                modelName: "product",
                modelData: {
                  productName: getProductUrlName(en_title || title),
                  productUrl: `/${nextProps.language}/${getProductUrlName(
                    en_title || title
                  )}/${id}/p/`
                }
              });
              this.setState({
                selectedSize: "",
                selectedQty: 1,
                showQtyTextBox: false,
                errShowQtyTextBox: ""
              });
              if (userLoggedIn) {
                fetchEarnedLoyaltyPoints(
                  nextProps.language,
                  nextProps.currencyCode,
                  nextProps.countryId
                );
              }

              AnalyticService.product.trackProductDetails({
                response,
                authReducer,
                commonSettings,
                location
              });
              GAService.product.trackViewProduct({
                item: response.data
              });
            }
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resetBreadCrumb,
      match,
      productDetail,
      isCheckedLoyaltyProgram,
      getLoyalityPoints,
      currencyCode,
      isJoinedToLoyaltyProgram
    } = this.props;
    const { selectedQty, selectedSize } = this.state;
    const nextPropsLanguage = get(match, "params.language").split("-")[0];
    const isLoyaltyStatusRefetched =
      isCheckedLoyaltyProgram &&
      prevProps.isCheckedLoyaltyProgram !== isCheckedLoyaltyProgram;
    window.onpopstate = e => {
      if (nextPropsLanguage && prevProps.language !== nextPropsLanguage) {
        resetBreadCrumb();
        window.history.go(0);
      }
    };

    if (isJoinedToLoyaltyProgram && isLoyaltyStatusRefetched) {
      const loyalityPointsBody = this._getLoyalityPointsBody(
        currencyCode,
        [productDetail],
        selectedQty,
        selectedSize
      );

      getLoyalityPoints(loyalityPointsBody);
    }
  }

  loadLanguage = async language => {
    const translation = await import(
      `../../language/productDetail/${
        language.includes("ar") ? "ar" : "en"
      }/productDetail`
    );

    this.setState({
      productDetailTranslation: translation.productDetail
    });
  };

  replaceProductNameInUrl = (item, params) => {
    const { history } = this.props;

    const url = getProductUrlName(item.en_title || item.title);
    const language = params.language;

    history.replace(`/${language}/${url}/${item.id}/p/`);
  };

  _getLoyalityPointsBody = (currency = "AED", cart, quantity = 1, size = 0) => {
    return {
      currencyCode: currency,
      orderItems:
        cart &&
        !!cart.length &&
        cart.map(item => {
          let availableSizes = get(item, "colors.colorSizeOptions", []).filter(
            option => option.sizeLabel
          );
          const internationalSizes = getInternationalSizes(availableSizes);
          if (
            item.IsMultisize &&
            Object.keys(internationalSizes) &&
            Object.keys(internationalSizes).length
          ) {
            availableSizes =
              internationalSizes[item.sizeType] ||
              internationalSizes[Object.keys(internationalSizes)[0]];
          }
          const { colorId, sizeId } = availableSizes.find(
            option => option.sizeLabel === size
          ) || { colorId: 0, sizeId: 0 };
          return {
            _id: item._id,
            colorid: colorId,
            oldPrice: item.oldPrice,
            price: item.currentPrice || item.price,
            productId: item.productId || item.id,
            quantity: quantity,
            sizeid: sizeId,
            transactionPrice: item.currentPrice
          };
        })
    };
  };

  onSizeChange = (
    selectedSize,
    sizeStock,
    AddToCartButton = false,
    selectedSizeId
  ) => {
    selectedSize = selectedSize.trim();
    this.setState({ selectedSize, selectedSizeId, showQtyTextBox: false });
    this.handleAddedToCartButton(selectedSize, AddToCartButton);
    this.onQtyTextChange(this.state.selectedQty, sizeStock);
  };

  handleAddedToCartButton = (selectedSize, AddToCartButton) => {
    const { currentSize } = this.state;

    const isResponsive = window.innerWidth < 1024;

    if (selectedSize && isResponsive && !currentSize && AddToCartButton) {
      this.setState({ addedToCart: true, currentSize: selectedSize }, () => {
        setTimeout(() => this.setState({ addedToCart: false }), 2000);
      });
    }
  };

  onQtyChange = (selectedQty, allowedQuantity) => {
    this.onQtyTextChange(selectedQty, allowedQuantity);
  };

  onQtyTextChange = (selectedQty, pstock) => {
    if (selectedQty !== "" && !isNaN(selectedQty)) {
      selectedQty = Number.parseInt(selectedQty);
      if (selectedQty <= pstock) {
        this.setState({ selectedQty: selectedQty });
      } else {
        this.setState({ selectedQty: 1 });
      }
    } else if (selectedQty === "") {
      this.setState({ selectedQty: 1 });
    }
  };

  saveToWishList = async (item, selectedSizeValue = "", sizeType = "") => {
    const { id, _id } = item;
    const { saveToWishList, getCartAndWishlistGeneralData } = this.props;
    const selectedSize = selectedItemSizeId(item, sizeType, selectedSizeValue);

    const wishListData = {
      productId: id,
      _id: _id,
      size: selectedSizeValue,
      sizeId: selectedSize && selectedSize.sizeId,
      sizeType: sizeType || null
    };

    await saveToWishList(wishListData);
    getCartAndWishlistGeneralData();
  };

  deleteFromWishList = async (productId, size = "") => {
    const {
      wishListBaseData,
      deleteWishListData,
      getCartAndWishlistGeneralData
    } = this.props;
    const wishListItem =
      wishListBaseData &&
      wishListBaseData.length &&
      wishListBaseData.find(
        item => item.productId === productId && item.size === size
      );
    if (deleteWishListData) {
      await deleteWishListData(
        productId,
        (wishListItem && wishListItem._id) || ""
      );
      getCartAndWishlistGeneralData();
    }
  };

  delayedWishlistHandler = handler => debounce(handler, 1000);

  handleAddToCart = async (
    item,
    size,
    quantity,
    sizeType,
    presentQuantity,
    allowedQuantity
  ) => {
    const { saveToCart, fetchCartData, authReducer, commonSettings, language } =
      this.props;
    const sizelabel =
      (size && typeof size === "object" && size.sizeLabel) || size;
    const selectedSize = selectedItemSizeId(item, sizeType, sizelabel);
    const cartData = {
      productId: item.id,
      quantity,
      size: sizelabel,
      sizeId: selectedSize && selectedSize.sizeId,
      sizeType
    };

    if (
      presentQuantity &&
      allowedQuantity &&
      presentQuantity >= allowedQuantity
    ) {
      cartData.quantity = 0;
    }

    let color;
    const colorSizeOptions = get(item, "colors.colorSizeOptions", []);
    if (size && colorSizeOptions.length) {
      color = colorSizeOptions.find(e => e.sizeLabel === size);
    }
    AnalyticService.cart.trackAddToCart({
      item,
      cartData,
      authReducer,
      commonSettings,
      color
    });
    GAService.cart.trackAddProductToCart({
      item,
      cartData,
      color
    });

    const res = await saveToCart(cartData);

    if (res.data && res.data.success) fetchCartData(true, language);
  };

  componentWillUnmount() {
    const { resetProductDetail } = this.props;
    resetProductDetail();
  }

  onBlurChange = () => {
    const { selectedQty } = this.state;
    if (selectedQty === "") {
      this.setState({ showQtyTextBox: false, selectedQty: 1 });
    }
  };

  handleSaveBreadCrumb = (sysGeneratedUrl, _language) => {
    const { productDetail, commonSettings, language, saveBreadcrumb } =
      this.props;

    const lng = _language === null ? language : _language;
    const _title = `${lng}_title`;
    const lvl0 = `en_parentCategory`,
      lvl1 = `en_catname`,
      lvl2 = `en_subcatname`;
    let urlLvl0 = {},
      urlLvl1 = {},
      urlLvl2 = {};
    let filterUrlLvl0 = "",
      filterUrlLvl1 = "",
      filterUrlLvl2 = "";
    let catUrlLvl0 = "",
      catUrlLvl1 = "",
      catUrlLvl2 = "";
    productDetail &&
      Object.keys(productDetail).length &&
      Object.keys(productDetail)
        .filter(key => key === lvl0 || key === lvl1 || key === lvl2)
        .forEach(key => {
          if (key === lvl0) {
            filterUrlLvl0 = `hierarchicalCategories.lvl0=${encodeURIComponent(
              productDetail[key]
            )}`; // hierarchical keys
            catUrlLvl0 = `categories-0=${encodeURIComponent(
              productDetail[key]
            )}`; // Categories keys
            urlLvl0 =
              sysGeneratedUrl &&
              (sysGeneratedUrl.find(
                item => item.filterCondition === filterUrlLvl0
              ) ||
                sysGeneratedUrl.find(
                  item => item.filterCondition === catUrlLvl0
                )); // Get matched Object with filter condition
          } else if (key === lvl1) {
            const filterKey = `${productDetail[lvl0]} > ${productDetail[key]}`; // Create filter condition
            filterUrlLvl1 = `${filterUrlLvl0}&hierarchicalCategories.lvl1=${encodeURIComponent(
              filterKey
            )}`;
            catUrlLvl1 = `${catUrlLvl0}&categories-1=${encodeURIComponent(
              filterKey
            )}`;
            urlLvl1 =
              sysGeneratedUrl &&
              (sysGeneratedUrl.find(
                item => item.filterCondition === filterUrlLvl1
              ) ||
                sysGeneratedUrl.find(
                  item => item.filterCondition === catUrlLvl1
                ));
          } else if (key === lvl2) {
            const filterKey = `${productDetail[lvl0]} > ${productDetail[lvl1]} > ${productDetail[key]}`; // Create filter condition
            filterUrlLvl2 = `${filterUrlLvl1}&hierarchicalCategories.lvl2=${encodeURIComponent(
              filterKey
            )}`;
            catUrlLvl2 = `${catUrlLvl1}&categories-2=${encodeURIComponent(
              filterKey
            )}`;
            urlLvl2 =
              sysGeneratedUrl &&
              (sysGeneratedUrl.find(
                item => item.filterCondition === filterUrlLvl2
              ) ||
                sysGeneratedUrl.find(
                  item =>
                    decodeURLRecursively(item.filterCondition) ===
                    decodeURLRecursively(catUrlLvl2)
                ));
          }
        });

    saveBreadcrumb({
      modelName: "productList",
      modelData: {
        ar_productListName:
          urlLvl0 && Object.keys(urlLvl0).length && urlLvl0["ar_title"]
            ? urlLvl0["ar_title"]
            : "",
        en_productListName:
          urlLvl0 && Object.keys(urlLvl0).length && urlLvl0["en_title"]
            ? urlLvl0["en_title"]
            : "",
        productListUrl:
          urlLvl0 && Object.keys(urlLvl0).length
            ? `/${lng}-${
                (commonSettings &&
                  commonSettings.countrySHORT &&
                  commonSettings.countrySHORT.toLowerCase()) ||
                "ae"
              }${urlLvl0.url}`
            : ""
      }
    });

    saveBreadcrumb({
      modelName: "productListLvl1",
      modelData: {
        productListLvl1Name:
          urlLvl1 && Object.keys(urlLvl1).length && urlLvl1[_title]
            ? urlLvl1[_title]
            : "",
        productListLvl1Url:
          urlLvl1 && Object.keys(urlLvl1).length
            ? `/${lng}-${
                (commonSettings &&
                  commonSettings.countrySHORT &&
                  commonSettings.countrySHORT.toLowerCase()) ||
                "ae"
              }${urlLvl1.url}`
            : ""
      }
    });

    saveBreadcrumb({
      modelName: "productListLvl2",
      modelData: {
        productListLvl2Name:
          urlLvl2 && Object.keys(urlLvl2).length && (urlLvl0 || urlLvl1)
            ? urlLvl2[_title]
                ?.replace(
                  `${(urlLvl0?.[_title] ? urlLvl0 : urlLvl1)[_title]}`,
                  ""
                )
                .trim()
            : "",
        productListLvl2Url:
          urlLvl2 && Object.keys(urlLvl2).length
            ? `/${lng}-${
                (commonSettings &&
                  commonSettings.countrySHORT &&
                  commonSettings.countrySHORT.toLowerCase()) ||
                "ae"
              }${urlLvl2.url}`
            : ""
      }
    });
  };

  clearSelectedSize = () => this.setState({ selectedSize: "" });

  render() {
    const {
      productDetailTranslation,
      showQtyTextBox,
      errShowQtyTextBox,
      selectedSize,
      selectedSizeId,
      selectedQty,
      addedToCart,
      errPage,
      seoTranslated
    } = this.state;
    const {
      language,
      cart = [],
      wishListBaseData = [],
      productDetail,
      history,
      commonSettings,
      currencyCode,
      loyaltyPoints,
      clearSizeSelection,
      pdpSections,
      configSyteSettings
    } = this.props;

    const ProductTitle = getProductUrlName(
      productDetail.en_title || productDetail.title || ""
    );
    const countryCode = get(commonSettings, "countryCode", "");
    const productId = get(productDetail, "id", "");
    const subcatid = get(productDetail, "subcatid", "");
    const syteImageUrl = get(productDetail, "captionImageURL", "");

    const metaInfo = language
      ? {
          [language]: {
            title: `${seoTranslated.buy} ${productDetail.brandName} ${
              ProductTitle ? ProductTitle.replace(/-/g, " ") : ""
            } ${seoTranslated.online} ${productDetail.title ? `|` : ""} ${
              seoTranslated.bfl
            }`,
            metaDescription: `${seoTranslated.Shop} ${
              productDetail.brandName
            } ${ProductTitle ? ProductTitle : "---"} ${
              seoTranslated.onlineAtBestPriceIn
            } ${
              commonSettings &&
              commonSettings.countryCode &&
              commonSettings.countryCode
            }. ${seoTranslated.genuineProducts} ${seoTranslated.fastShipping} ${
              seoTranslated.cashOnDelivery
            } ${seoTranslated.easyReturns}`,
            metaImage: `${appSettings.imageBaseURL}${productDetail.captionImageURL}?w=600&h=600&quality=80&mode=crop`
          }
        }
      : {};

    const pdpSectionTop = pdpSections[SECTION_PDP_TYPES.PDP_TOP];

    return (
      <>
        {pdpSectionTop && (
          <>
            {pdpSectionTop.map((section, key) => (
              <DynamicBannersWrapper
                section={section}
                key={key}
                bannerPageTitle={section.sectionTitle}
              />
            ))}
          </>
        )}

        <Page
          title={metaInfo[language].title}
          meta={metaInfo}
          language={language}
          id="product-detail"
          indexable
        >
          {!errPage && !isServer && (
            <Fragment>
              {productDetail && productDetail.id && (
                <Fragment>
                  <Breadcrumb />
                  <div
                    className={cn("product_detail_wrapper", {
                      arabic: language.includes("ar")
                    })}
                  >
                    <Fragment>
                      <AddToCart
                        history={history}
                        language={language}
                        item={productDetail}
                        shareButton
                        translation={productDetailTranslation}
                        onQtyChange={this.onQtyChange}
                        onSizeChange={this.onSizeChange}
                        onQtyTextChange={this.onQtyTextChange}
                        showQtyTextBox={showQtyTextBox}
                        errShowQtyTextBox={errShowQtyTextBox}
                        selectedSize={selectedSize}
                        selectedSizeId={selectedSizeId}
                        selectedQty={selectedQty}
                        wishListBaseData={wishListBaseData}
                        cart={cart}
                        handleAddToCart={this.handleAddToCart}
                        saveToWishList={this.delayedWishlistHandler(
                          this.saveToWishList
                        )}
                        deleteFromWishList={this.delayedWishlistHandler(
                          this.deleteFromWishList
                        )}
                        currencyCode={currencyCode}
                        loyaltyPoints={loyaltyPoints}
                        addedToCart={addedToCart}
                        onBlurChange={this.onBlurChange}
                        clearSizeSelection={clearSizeSelection}
                        countryCode={countryCode}
                        clearSelectedSize={this.clearSelectedSize}
                      />
                      <SyteSimilarItems
                        key={countryCode}
                        pId={productId}
                        subcatid={subcatid}
                        imageUrl={`${appSettings.imageBaseURL}${syteImageUrl}`}
                        configSyteSettings={configSyteSettings}
                      />
                      <RecentlyViewed
                        translation={productDetailTranslation}
                        language={language}
                        history={history}
                      />
                    </Fragment>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
          {errPage && <ErrorPage language={language} />}
        </Page>
      </>
    );
  }
}

ProductDetail.need = ssrConfig;

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  breadcrumb: state.breadcrumbReducer,
  cart: state.cartReducer.cart,
  commonSettings: state.common.settings,
  currencyCode: get(state, "common.settings.currencyCode"),
  countryId: get(state, "common.settings.countryId"),
  language: state.common.language,
  loyaltyPoints: state.common.loyaltyPoints,
  productDetail: state.productDetailReducer.productDetail,
  sysGeneratedUrl: state.productListingReducer.sysGeneratedUrl,
  wishListBaseData: selectWishListGeneralData(state),
  pdpSections: selectPDPBannerSections(state),
  userLoggedIn: state.authReducer.userLoggedIn,
  isCheckedLoyaltyProgram: selectIsCheckedLoyaltyProgram(state),
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  configSyteSettings: selectSyteSettings(state)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  fetchProductDetail,
  resetProductDetail,
  saveToWishList,
  deleteWishListData,
  saveToCart,
  fetchCartData,
  saveBreadcrumb,
  getWishListBaseData,
  getLoyalityPoints,
  fetchGeneratedUrls,
  clearSizeSelection,
  resetBreadCrumb,
  getMegaMenuData,
  getBannerSections,
  getCartAndWishlistGeneralData,
  fetchEarnedLoyaltyPoints
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductDetail);
