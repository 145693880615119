import React from "react";
import PropTypes from "prop-types";
import "./css/Ring.scss";
import "./css/common-loader.scss";
import BounceLoader from "../../bounce-loader";

const Dots = props =>
  props.fullscreen ? buildFullScreenHtml(props) : buildInlineScreenHtml(props);

const buildFullScreenHtml = props => (
  <div className="bfl-loader-wrapper" style={{ background: props.background }}>
    <div className="loader-container">
      <BounceLoader
        color={props.bounceLoaderColor || "#f8d000"}
        size={props.size}
        qty={props.qty}
      />
    </div>
  </div>
);

const buildInlineScreenHtml = props => (
  <div className="inline-loader-container">
    <BounceLoader
      color={props.bounceLoaderColor || "#f8d000"}
      size={props.size}
      qty={props.qty}
    />
  </div>
);

Dots.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullscreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

Dots.defaultProps = {
  size: 16,
  qty: 3,
  background: "rgba(0,0,0,0.70)"
};

export default Dots;
