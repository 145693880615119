import React from "react";
import { COUNTRY_SELECTION_MAP } from "../../../constants";
import { Image } from "../../image";

const DeliveredTo = ({ commonSettings, translation }) => (
  <div className="applePay_card_wrapper">
    <div className="applePay_card selectedCountry">
      <span className="floatedTxt">Delivered to</span>
      <div>
        <Image
          className="country_flag_img"
          src={
            COUNTRY_SELECTION_MAP[commonSettings && commonSettings.countryCode]
          }
          alt="flagImage"
        />
        <span>{commonSettings && commonSettings.countryName}</span>
      </div>
    </div>
  </div>
);

export default DeliveredTo;
