import React, { useState } from "react";
import { TabbyInfoModal } from "../../tabby/tabby-info-modal";
import hoverInfoIcon from "./assets/hover_info.svg";
import { PAYMENT_GATEWAYS } from "../../../constants";
import { TamaraInfoModal } from "../../tamara/tamaraInfoModal";

const PaymentDetails = ({
  paymentGateway,
  totalAmount,
  currency,
  language,
  paymentType
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = () => setModalOpen(true);
  const hideModal = () => setModalOpen(false);

  const detailsData = {
    [PAYMENT_GATEWAYS.TABBY]: {
      imageProps: {
        "data-tabby-info": paymentType,
        "data-tabby-language": language
      },
      modal: (
        <TabbyInfoModal
          modalOpen={modalOpen}
          onRequestClose={hideModal}
          language={language}
          amount={totalAmount}
          currency={currency}
        />
      )
    },
    [PAYMENT_GATEWAYS.TAMARA]: {
      modal: (
        <TamaraInfoModal
          modalOpen={modalOpen}
          onRequestClose={hideModal}
          language={language}
          amount={totalAmount}
          currency={currency}
        />
      )
    }
  }[paymentGateway];
  return (
    <span className="icon-wrapper">
      <img
        className="hover-info-icon"
        src={hoverInfoIcon}
        alt="payment-details-icon"
        onClick={showModal}
        {...detailsData.imageProps}
      />
      {modalOpen && detailsData.modal}
    </span>
  );
};
export default PaymentDetails;
