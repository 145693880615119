export const PAYMENT_GATEWAYS = {
  AREEBA: "AREEBA",
  CHECKOUT_COM: "CHECKOUT",
  TABBY: "TABBY",
  POSTPAY: "POSTPAY",
  TAMARA: "TAMARA"
};

export const PAYMENT_MODES = {
  TABBY_PAY_LATER: "TABBY_PAY_LATER",
  TABBY_PAY_INSTALLMENT: "TABBY_PAY_INSTALLMENT",
  TABBY: "TABBY",
  POSTPAY: "POSTPAY",
  TAMARA: "TAMARA",
  TAMARA_PAY_NOW: "TAMARA_PAY_NOW",
  TAMARA_PAY_BY_INSTALMENTS: "TAMARA_PAY_BY_INSTALMENTS",
  APPLEPAY: "APPLEPAY",
  PAYPAL: "PAYPAL",
  CBD: "CBD",
  COD: "COD",
  CC: "CC"
};

export const POSTPAY_PAYMENT_STATUS = {
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  DENIED: "DENIED"
};

export const EXPIRY_MONTHS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
];

export const CARD_TYPES = {
  MasterCard: "master_card",
  Visa: "visa_card",
  Amex: "amex"
};

export const RESET_PASSWORD_API_ERRORS = {
  INCORECT_CREDENTIALS: "Incorrect Credentials",
  INVALID_NEW_PASSWORD: "newPassword contains an invalid value"
};

export const CARD_TYPE_VALIDATORS = [
  {
    name: "Amex",
    pattern: /^3[47]/,
    valid_length: [15]
  },
  {
    name: "Diners Club",
    pattern: /^3[068]/,
    valid_length: [14]
  },
  {
    name: "JCB",
    pattern: /^35(2[89]|[3-8][0-9])/,
    valid_length: [16]
  },
  {
    name: "laser",
    pattern: /^(6304|630[69]|6771)/,
    valid_length: [16, 17, 18, 19]
  },
  {
    name: "visa_electron",
    pattern: /^(4026|417500|4508|4844|491(3|7))/,
    valid_length: [16]
  },
  {
    name: "Visa",
    pattern: /^4[0-9]/,
    valid_length: [16]
  },
  {
    name: "MasterCard",
    pattern: /^5[1-5]/,
    valid_length: [16]
  },
  {
    name: "MasterCard",
    pattern: /^36/,
    valid_length: [14, 16]
  },
  {
    name: "Maestro",
    pattern:
      /^(5018|5020|5038|5044|5046|5049|5048|6002|6038|6304|6220|6759|676[1-3])/,
    valid_length: [20]
  },
  {
    name: "discover",
    pattern:
      /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    valid_length: [16]
  }
];
