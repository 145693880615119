import React, { useCallback, useEffect, useState } from "react";
import get from "lodash/get";
import HtmlParser from "../../html-parser";
import Loader from "../../loader";
import DeleteModal from "../../delete-modal";
import { LOADER_TYPES } from "../../../constants";

const StoreLocatorDetails = ({
  storeLocatorTranslation,
  selectedShop,
  mapVisible,
  language
}) => {
  const [showGeoLoader, setShowGeoLoader] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [userGeolocation, setUserGeolocation] = useState(null);

  const findGeolocation = () => {
    setShowGeoLoader(true);

    !navigator.geolocation
      ? console.error("Geolocation is not supported by your browser")
      : navigator.geolocation.getCurrentPosition(success, error);
  };

  useEffect(() => {
    findGeolocation();
  }, []);

  const success = position => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setUserGeolocation({ latitude, longitude });
    setShowGeoLoader(false);
  };

  const error = () => setShowGeoLoader(false);

  const selectedShopTime = get(selectedShop, "extraInfo").replace(
    /\n/g,
    "</br>"
  );

  const getLocationElement = userGeolocation ? (
    <a
      className="get-direction"
      href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${
        selectedShop.lat
      }%2C${selectedShop.lng}&origin=${userGeolocation.latitude}%2C${
        userGeolocation.longitude
      }`}
      target="_blank"
    >
      {mapVisible
        ? storeLocatorTranslation.showStore
        : storeLocatorTranslation.getDirection}
    </a>
  ) : (
    <button
      className="get-direction"
      onClick={() => setShowPermissionModal(true)}
    >
      {mapVisible
        ? storeLocatorTranslation.showStore
        : storeLocatorTranslation.getDirection}
    </button>
  );

  return (
    <div className={`store-locator-details-container`}>
      <div className={`store-location-name`}>
        {`${selectedShop.name}`.toLocaleLowerCase() || ""}
      </div>
      <div className="store-name">
        {`${selectedShop.description}`.toLocaleLowerCase() || ""}
      </div>
      <div className="address-header">{storeLocatorTranslation.address}</div>
      <div className="address-details">
        <div className="address">{selectedShop.address || ""}</div>
        {(selectedShop.country || selectedShop.city) && (
          <div className="store-email">
            {selectedShop.city}, {selectedShop.country}
          </div>
        )}
        {selectedShop.email && (
          <div className="store- email">
            {storeLocatorTranslation.email}
            {selectedShop.email}
          </div>
        )}
      </div>

      {showGeoLoader ? (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={{ marginTop: "20px" }}
        />
      ) : (
        <div className="get-direction-block">{getLocationElement}</div>
      )}

      <DeleteModal
        bodyTxt={storeLocatorTranslation.locationAccess}
        cancelBtnTxt={storeLocatorTranslation.ok}
        handleCloseModal={() => setShowPermissionModal(false)}
        isDeleteModalActive={showPermissionModal}
        language={language}
      />

      <div className="timings-contact-wrapper">
        {
          <div className="timings-card">
            <div className="timings-card-title">
              {storeLocatorTranslation.timings}
            </div>
            <div className="timings-card-row">
              <HtmlParser html={selectedShopTime} />
            </div>
          </div>
        }
        {selectedShop.phone && (
          <div className="timings-card">
            <div className="timings-card-title">
              {storeLocatorTranslation.contact}
            </div>
            <div className="timings-card-row">{selectedShop.phone}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreLocatorDetails;
