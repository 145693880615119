import React from "react";
import { useSelector } from "react-redux";
import { PRICE_CONVERSION_DECIMAL_VALUE } from "../../../constants";
import * as priceUtil from "../../../util/priceUtil";
import { selectCommonSettingsDetails } from "./selectors";
import HtmlParser from "../../html-parser";

const getReplacedPreviewHTML = (html = "", paymentCurrency) =>
  html && paymentCurrency && html.replace("{CURRENCY_CODE}", paymentCurrency);

const ConversionPrice = ({
  paymentCurrency,
  grandTotal,
  paymentCurrencyExchangeRate
}) => {
  const { pricePreviewHTML } = useSelector(selectCommonSettingsDetails);

  const modifiedPricePreviewHTML = getReplacedPreviewHTML(
    pricePreviewHTML,
    paymentCurrency
  );

  const amount = priceUtil.shapePriceWithComma(
    grandTotal * paymentCurrencyExchangeRate,
    PRICE_CONVERSION_DECIMAL_VALUE
  );

  return (
    <div className="extra_price_info">
      <div className="total_price_extra_text">
        <span className="total_amount">
          {paymentCurrency} {amount}
        </span>
      </div>
      <HtmlParser html={modifiedPricePreviewHTML} />
    </div>
  );
};

export default ConversionPrice;
