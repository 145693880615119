import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import cn from "classnames";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import { emailValidationRegExp } from "../../constants";
import { getCountryList } from "../../redux/actions/common.action";
import { getDividedNumber } from "../../util/phoneNumber";
import {
  daysInMonth,
  years,
  months,
  getDividedBirthday,
  transformBirthdayValue,
  sliceBirthdayIndex
} from "../../util/birthday";
import { trimObjectValues } from "../../util/my-account";
import IntlInput from "./../../components/intl-tel-input/intl-tel-input";
import CustomDropdown from "../../components/genericDropDown";
import { ButtonWithLoader } from "../../components/generalElements/buttons";
import { selectShowLoader } from "../../util/selectors";
import BirthdayGift from "../../images/birthday-gift.svg";
import FormActivityHandler from "../../components/formActionsHandler";

class MyDetailsForm extends Component {
  static initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    areaCode: "",
    gender: "",
    countryCode: ""
  };

  state = {
    selectedYear: "",
    selectedMonth: "",
    selectedDay: "",
    lastFocusedField: ""
  };

  static defaultProps = {
    userOriginCountryData: {}
  };

  static schema = mobileLength =>
    Yup.object().shape({
      firstname: Yup.string().min(1).trim().required(),
      lastname: Yup.string(),
      email: Yup.string().trim().matches(emailValidationRegExp).required(),
      phone: Yup.string().min(mobileLength).max(mobileLength).required(),
      areaCode: Yup.string(),
      gender: Yup.string(),
      countryCode: Yup.number()
    });

  componentDidUpdate(prevProps) {
    const {
      language,
      accountDetails: {
        info: { birthday }
      }
    } = this.props;
    const { selectedMonth, selectedDay, selectedYear } = this.state;
    const prevLanguage = prevProps.language;

    if (prevLanguage !== language && selectedMonth) {
      const currentMonth = months.find(
        month => month[prevLanguage] === selectedMonth
      )[language];

      this.setState({ selectedMonth: currentMonth });
    }

    const isBirthdayDataSet = selectedDay && selectedYear && selectedMonth;
    if (birthday && !isBirthdayDataSet) {
      const { selectedYear, selectedMonth, selectedDay } = getDividedBirthday(
        birthday,
        language
      );

      this.setState({
        selectedYear,
        selectedMonth,
        selectedDay
      });
    }
  }

  getAccountData() {
    const {
      accountDetails: {
        info: { firstname, lastname, email, phone, gender, birthday }
      },
      accountPhoneDetails
    } = this.props;

    const { phoneWithoutCountryCode, areaCode = 0 } = getDividedNumber({
      ...accountPhoneDetails,
      phone
    });

    const detailsData = {
      firstname,
      lastname: lastname || "",
      email,
      phone: phoneWithoutCountryCode.slice(areaCode.length),
      areaCode,
      ...(gender && { gender }),
      birthday: birthday?.slice(0, sliceBirthdayIndex) || "",
      countryCode: accountPhoneDetails.mobileCountryCode
    };
    const isValid = MyDetailsForm.schema(
      accountPhoneDetails.mobileLocalNumberLength
    ).isValidSync(detailsData);

    return isValid ? detailsData : MyDetailsForm.initialValues;
  }

  areValuesSame = values => {
    const { isAbleToSetBirthday } = this.props;
    const { selectedYear, selectedMonth, selectedDay } = this.state;
    const accountData = this.getAccountData();
    const isDobSelected =
      isAbleToSetBirthday && selectedYear && selectedMonth && selectedDay;

    return (
      isEqual(trimObjectValues(accountData), trimObjectValues(values)) &&
      !isDobSelected
    );
  };

  onFormSubmit = data => {
    const {
      onSubmit,
      accountDetails: {
        info: { birthday }
      },
      language
    } = this.props;

    const { selectedDay, selectedMonth, selectedYear } = this.state;
    let dob = birthday || "";

    if (selectedDay && selectedMonth && selectedYear) {
      const day = transformBirthdayValue(selectedDay);
      const month = (
        months.find(month => month[language] === selectedMonth) || {}
      ).number;

      dob = `${selectedYear}-${month}-${day}`;
    }

    const changedData = { ...data, birthday: dob };
    onSubmit(trimObjectValues(changedData), this.getAccountData());
  };

  setLastFocusedField = e => {
    const lastFocusedField = typeof e === "string" ? e : e.currentTarget.name;
    this.setState({
      lastFocusedField
    });
  };

  render() {
    const {
      myAccountTranslation,
      countryData,
      userOriginCountryData,
      toggleResetPasswordModal,
      accountDetails,
      selectedCountryMobileDetails,
      errorMessage,
      resetAPIError,
      forwardedRef,
      language,
      isAbleToSetBirthday,
      showLoader
    } = this.props;

    const { selectedYear, selectedDay, selectedMonth } = this.state;

    return (
      <Formik
        onSubmit={this.onFormSubmit}
        validationSchema={() =>
          MyDetailsForm.schema(
            selectedCountryMobileDetails.mobileLocalNumberLength
          )
        }
        enableReinitialize
        initialValues={{ ...this.getAccountData() }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => {
          const isInputInvalid = valueKey =>
            Boolean(touched[valueKey] && errors && errors[valueKey]);
          const valuesAreSame = this.areValuesSame(values);

          const onChangeAreaCode = area => {
            if (area !== values.areaCode) {
              setFieldValue("phone", "");
              setFieldValue("areaCode", area);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="user_basic_details">
                <div className="form-group">
                  <input
                    name="firstname"
                    type="text"
                    placeholder={myAccountTranslation.firstName}
                    className={cn("form-control", {
                      borderRed: isInputInvalid("firstname")
                    })}
                    value={values.firstname}
                    onChange={handleChange}
                    aria-label="First name"
                    onFocus={this.setLastFocusedField}
                  />
                  {isInputInvalid("firstname") && (
                    <span className="error">
                      {accountDetails.errors.firstname.message}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    name="lastname"
                    type="text"
                    placeholder={myAccountTranslation.lastName}
                    className="form-control"
                    value={values.lastname}
                    onChange={handleChange}
                    aria-label="Last name"
                    onFocus={this.setLastFocusedField}
                  />
                </div>
                <div className="form-group">
                  <input
                    name="email"
                    type="text"
                    placeholder={myAccountTranslation.emailAddress}
                    className="form-control"
                    value={values.email}
                    onChange={handleChange}
                    readOnly={true}
                    aria-label="Email"
                    onFocus={this.setLastFocusedField}
                  />
                </div>
                <div className="form-group">
                  <IntlInput
                    valid={!isInputInvalid("phone")}
                    phone={values.phone}
                    handleCountryChange={(e, phone) => {
                      errorMessage && resetAPIError();
                      setFieldValue("phone", phone);
                    }}
                    areaCode={values.areaCode}
                    countryData={countryData}
                    defaultCountry={userOriginCountryData.countrySHORT}
                    mobileLocalNumberLength={
                      userOriginCountryData.mobileLocalNumberLength
                    }
                    onSelectArea={onChangeAreaCode}
                    withCountrySelection
                    forwardedRef={forwardedRef}
                    onSelectFlag={(_, flagData) =>
                      setFieldValue("countryCode", Number(flagData.dialCode))
                    }
                    onFocus={this.setLastFocusedField}
                  />
                  {(isInputInvalid("phone") || errorMessage) && (
                    <span className="error">
                      {errorMessage || accountDetails.errors.phone.message}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <div className="radio_group flex">
                    <span>{myAccountTranslation.gender}</span>
                    <p>
                      <input
                        name="gender"
                        type="radio"
                        id="maleRadioInput"
                        className={cn("custom_radio", {
                          checked_radio: values.gender === "Male"
                        })}
                        value="Male"
                        onChange={handleChange}
                        onFocus={this.setLastFocusedField}
                      />
                      <label htmlFor="maleRadioInput">
                        {myAccountTranslation.male}
                      </label>
                    </p>
                    <p>
                      <input
                        name="gender"
                        type="radio"
                        id="femaleRadioInput"
                        className={cn("custom_radio", {
                          checked_radio: values.gender === "Female"
                        })}
                        value="Female"
                        onChange={handleChange}
                        onFocus={this.setLastFocusedField}
                      />
                      <label htmlFor="femaleRadioInput">
                        {myAccountTranslation.female}
                      </label>
                    </p>
                  </div>
                </div>
                <div className="form-group birthday-details">
                  <h4 id="date-of-birth">
                    {myAccountTranslation.dateOfBirth}{" "}
                  </h4>
                  <div className="selects-block">
                    <CustomDropdown
                      canDropdownToggle={isAbleToSetBirthday}
                      name="year"
                      data={years}
                      selectedItem={selectedYear || myAccountTranslation.year}
                      containerClass={cn("location_dropdown year", {
                        disabled: !isAbleToSetBirthday
                      })}
                      scrollbarMaxHeight={100}
                      handleChange={selectedYear => {
                        this.setState({ selectedYear });
                      }}
                      onClickForEvent={this.setLastFocusedField}
                    />
                    <CustomDropdown
                      canDropdownToggle={isAbleToSetBirthday}
                      name="months"
                      data={map(months, language)}
                      selectedItem={selectedMonth || myAccountTranslation.month}
                      containerClass={cn("location_dropdown month", {
                        disabled: !isAbleToSetBirthday
                      })}
                      scrollbarMaxHeight={100}
                      handleChange={selectedMonth =>
                        this.setState({ selectedMonth })
                      }
                      onClickForEvent={this.setLastFocusedField}
                    />
                    <CustomDropdown
                      canDropdownToggle={isAbleToSetBirthday}
                      name="day"
                      data={daysInMonth}
                      selectedItem={selectedDay || myAccountTranslation.dd}
                      containerClass={cn("location_dropdown day", {
                        disabled: !isAbleToSetBirthday
                      })}
                      scrollbarMaxHeight={100}
                      handleChange={selectedDay =>
                        this.setState({ selectedDay })
                      }
                      onClickForEvent={this.setLastFocusedField}
                    />
                  </div>
                  <div className="surprise-info">
                    <img src={BirthdayGift} alt="birthday-gift" />
                    <span>{myAccountTranslation.mayGiveYouSurprise}</span>
                  </div>
                </div>
                <div className="form-group">
                  <ButtonWithLoader
                    type="submit"
                    className={cn("round_btn form_black_btn", {
                      disabled_btn: valuesAreSame
                    })}
                    disabled={valuesAreSame}
                    showLoader={showLoader}
                  >
                    {myAccountTranslation.updateDetails}
                  </ButtonWithLoader>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="round_btn form_white_btn"
                    onClick={toggleResetPasswordModal}
                  >
                    {myAccountTranslation.setNewPassword}
                  </button>
                </div>
              </div>
              <FormActivityHandler
                formName={"my account details"}
                lastFocusedFormField={this.state.lastFocusedField}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({ showLoader: selectShowLoader(state) });

const mapDispatchToProps = {
  getCountryList
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDetailsForm);
