import { createSelector } from "reselect";
import get from "lodash/get";

import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";

export const selectCommon = store => store.common;
export const selectLanguageSlug = store => store.common.language;

export const selectHomePageApiErrorData = store =>
  store.homepage.APIErrorResponse;
export const selectHomePageApiErrorMessage = createSelector(
  selectHomePageApiErrorData,
  error => error.message
);
export const selectHomePageApiErrorCode = createSelector(
  selectHomePageApiErrorData,
  error => error.code
);

export const selectRegistrationDataStatus = store =>
  store.homepage.registrationDataStatus;
export const selectRegistrationDataCode = createSelector(
  selectRegistrationDataStatus,
  data => data.code
);
export const selectSignUpLoginToken = store => store.homepage.signUpLoginToken;
export const selectSocialPhoneCheckToken = store =>
  store.homepage.socialPhoneCheckToken;
export const selectSocialLoginFlow = store => store.homepage.socialLoginFlow;
export const selectLastRegistrationStatusCode = store =>
  store.homepage.lastRegistrationStatusCode;
export const selectRegistrationError = store =>
  store.homepage.registrationError;
export const selectAddressOtpFlow = store => store.homepage.addressOtpFlow;
export const selectAddressOtpError = createSelector(
  selectAddressOtpFlow,
  otpFlow => otpFlow.error
);

export const selectCommonSettings = store => store.common.settings;
export const selectShowLoader = store => store.common.showLoader;
export const selectPageLoaderEnabled = createSelector(
  selectCommon,
  commonState => commonState.pageLoader.visible
);

export const selectUpdateError = store => store.myAccountReducer.updateError;
export const selectPhoneUpdateFlowData = store =>
  store.myAccountReducer.phoneUpdateFlowData;

export const selectLoyaltyStatics = store =>
  store.myAccountReducer.loyaltyPoints.LoyaltyStatics;

export const selectLoyaltyDetails = createSelector(
  selectLoyaltyStatics,
  loyaltyStatics => loyaltyStatics.loyaltyDetails
);

export const selectLoyaltyProgramCurrentBalance = createSelector(
  selectLoyaltyDetails,
  loyaltyDetails => loyaltyDetails?.currentBalance
);
export const selectLoyaltyProgramMonetaryValue = createSelector(
  selectLoyaltyDetails,
  loyaltyDetails => loyaltyDetails?.pointsValue
);

export const selectWalletData = store => store.myAccountReducer.wallet;

export const selectLanguage = createSelector(selectLanguageSlug, languageSlug =>
  languageSlug === LANGUAGE_ROUTE_KEY.arabic
    ? LANGUAGE.arabic
    : LANGUAGE.english
);

export const selectIsArabic = createSelector(selectLanguage, language =>
  language.includes(LANGUAGE_ROUTE_KEY.arabic)
);

export const selectCountrySlug = createSelector(
  selectCommonSettings,
  settings => get(settings, "countrySHORT", "ae").toLowerCase()
);

export const selectCountryId = createSelector(selectCommonSettings, settings =>
  get(settings, "countryId")
);

export const selectRouteSlug = createSelector(
  selectLanguageSlug,
  selectCountrySlug,
  (language, country) => `${language}-${country}`
);

export const selectCurrency = createSelector(selectCommonSettings, settings =>
  get(settings, "currencyCode", "AED")
);

export const selectWishListBaseData = state =>
  state.wishListReducer.wishListBaseData;

export const selectWishListGeneralData = state =>
  state.cartReducer.cartAndWishlistGeneralData?.wishList?.products;

export const selectLisaReducer = state => state.lisaReducer;
export const selectLisaMinimized = createSelector(
  selectLisaReducer,
  lisaReducer => lisaReducer.minimized
);
export const selectLisaPlayerIsActive = createSelector(
  selectLisaReducer,
  lisaReducer => lisaReducer.playerIsActive
);

export const selectAuthReducer = store => store.authReducer;
export const selectAddressReducer = store => store.addressReducer;

export const selectAccessToken = createSelector(
  selectAuthReducer,
  authReducer => authReducer.accessToken
);
export const selectShowAppOnlyItemModalState = store =>
  store.productListingReducer.appOnlyItemModal;

export const selectSectionsReducer = store => store.sectionReducer;

export const selectHeaderBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.headerSections
);

export const selectPDPBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.pdpSections
);

export const selectCartBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.cartSections
);

export const selectCheckoutBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.checkoutSections
);

export const selectOrderSuccessBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.orderSuccessSections
);

export const selectCdnImageSettings = store =>
  store.common.configSettings?.cdnImages;

export const selectSEOSettings = store => store.common.settings?.seo;

export const selectMessagesFromConfigs = store =>
  store.common.configSettings?.messages;

export const selectSyteSettings = store => store.common.configSettings?.syte;

export const selectWishListGeneralProducts = store =>
  store.cartReducer.cartAndWishlistGeneralData?.wishList?.products;

export const selectWishListGeneralCount = store =>
  store.cartReducer.cartAndWishlistGeneralData?.wishList?.count;

export const selectCartGeneralCount = store =>
  store.cartReducer.cartAndWishlistGeneralData?.cart?.count;

export const selectCheckoutReducer = store => store.checkoutReducer;
export const selectCheckoutData = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.checkoutData
);
export const selectPaymentStatusResponse = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.paymentStatusResponse
);

export const selectCartData = state => state.cartReducer.cart;

export const selectWishList = state => state.wishListReducer.wishList;

export const selectOrderFetchingLoader = store =>
  store.myAccountReducer.showMyOrdersLoader;

export const selectDeviceType = store => store.common.deviceType;

export const selectIsCheckedLoyaltyProgram = store =>
  store.common.isCheckedLoyaltyProgram;

export const selectTabbyPaymentError = store =>
  store?.common?.configSettings?.messages?.tabbyPaymentError;

const selectPage = store => store.page;

export const selectCartReducer = state => state.cartReducer;

export const selectWishListAndCartGeneralLoaded = createSelector(
  selectCartReducer,
  cart => cart.wishListAndCartGeneralLoaded
);

export const selectDeleteWishListItemLoaded = createSelector(
  selectCartReducer,
  cart => cart.deleteWishListItemLoaded
);

export const selectPageMeta = createSelector(selectPage, page => page.meta);

export const selectCountryCode = createSelector(
  selectCommonSettings,
  settings => get(settings, "countryCode", "")
);

export const selectCountryShort = createSelector(
  selectCommonSettings,
  settings => get(settings, "countrySHORT", "")
);

export const selectPageAnalitic = createSelector(
  selectPage,
  page => page.analyticData
);

export const selectCustomerId = state => state.authReducer.customerId;

export const selectSettings = store => store.common.configSettings;

export const selectIsConfigSettingsLoaded = store =>
  store.common.isConfigSettingsLoaded;

export const selectOTPLoginFlowBFL = store => store.homepage.otpLoginFlowBFL;

export const selectCdnDomain = createSelector(
  selectSettings,
  settings => settings?.cdnDomain
);

export const selectSubmoduleURL = createSelector(
  selectCdnDomain,
  domain => domain?.submodule
);

export const selectCheckoutInfoText = createSelector(
  selectSettings,
  settings => settings.checkoutValidationMsg
);

export const selectIsPickUpFormValid = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.isPickUpFormValid
);
