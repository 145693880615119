import { LANGUAGE_ROUTE_KEY } from "../../constants";
import React, { Component } from "react";
import get from "lodash/get";
import { CircleFlag } from "react-circle-flags";
import {
  getBaseUrl,
  isServer,
  getStoreMap,
  getDeviceType,
  isTouchScreen,
  isMobile
} from "../../util";
import {
  getCountryList,
  getStatesData,
  changeLanguage as handleLanguageChange,
  setCommonData,
  setGeneralInfo,
  setIPCountryData,
  getCountrySettings
} from "../../redux/actions/common.action";
import { fetchHomepageSection } from "../../redux/actions/page.action";
import {
  getUserCountrySelectionStorage,
  getUserCountryStorage,
  setUserCountryStorage
} from "../../util/browserStorage";

import { SECTION_HEADER_TYPES } from "../../constants/sections";
import CategoryMenu from "../categories/category-menu";
import Headroom from "react-headroom";
import { Image } from "../image";
import { Link } from "react-router-dom";
import MobileHeader from "./mobile-header";
import Search from "../../components/search";
import UserPreferences from "../../components/user-preferences";
import UserPreferencesModal from "../modal/modal";
import UserProfile from "./profile-options";
import classnames from "classnames";
import { connect } from "react-redux";
import { handleSearchText } from "../../redux/actions/search.action";
import { logOut } from "../../redux/actions/authConfig.action";
import { getMegaMenuData as megaMenuContent } from "../../redux/actions/megaMenu.action";
import { megaMenuTranslation } from "../../language/megaMenu/en/megaMenu";
import { preferencesTranslation } from "../../language/userPreferences/en/userPreferences";
import { withRouter } from "react-router-dom";
import { removeCountrySelectionDropdown } from "../../util";
import {
  selectHeaderBannerSections,
  selectRouteSlug,
  selectSyteSettings
} from "../../util/selectors";
import { clearBannersSectionsData } from "../../redux/actions/page.action";
import { getState } from "../../redux/store";
import DynamicBannersWrapper from "../dynamic/dynamic-banner";
import { getCartAndWishlistGeneralData } from "../../redux/actions/cart.action";
import { GAService } from "../../services/GA-service";

class Header extends Component {
  state = {
    openUserPreferenceModal: false,
    userPreferencesTranslation: preferencesTranslation,
    megaMenuTranslation: megaMenuTranslation,
    country: {},
    defaultCountry: null,
    isFirstViewClosed: true, // <-- SET START VALUE isFirstViewClosed TO THE false WHEN THE <CountryFirstView/> IN A RENDER METHOD DOWN BELOW WILL BE UNCOMMENTED AGAIN!!!
    flag: true
  };

  activeListElementRef = null;

  loadModal = () => {
    const { countryData, ipCountryData } = this.props;

    let url = !isServer && window.location.href.toLowerCase();

    const setPreferenceModal = (countryData = []) => {
      let countryAE = {},
        selectedCountry = null,
        selectedIPCountry = null,
        cookieCountry = null;

      if (countryData) {
        countryData.map(_country => {
          if (
            url.includes(`/en-${_country.countrySHORT.toLowerCase()}`) ||
            url.includes(`/ar-${_country.countrySHORT.toLowerCase()}`)
          ) {
            selectedCountry = _country;
          }

          if (_country.countrySHORT === "AE") {
            countryAE = _country;
          }

          if (
            ipCountryData &&
            ipCountryData.countrySHORT &&
            ipCountryData.countrySHORT === _country.countrySHORT
          ) {
            selectedIPCountry = _country;
          }

          if (_country.countrySHORT === getUserCountryStorage()) {
            cookieCountry = _country;
          }
          return null;
        });
      }

      if (selectedCountry) {
        setUserCountryStorage(selectedCountry.countrySHORT, document.domain);
        this.setState({ country: selectedCountry });
      } else if (!selectedCountry && cookieCountry) {
        setUserCountryStorage(cookieCountry.countrySHORT, document.domain);
        this.setState({ country: cookieCountry });
      } else if (!selectedCountry && !cookieCountry && selectedIPCountry) {
        setUserCountryStorage(selectedIPCountry.countrySHORT, document.domain);
        this.setState({ country: selectedIPCountry });
      } else {
        setUserCountryStorage(countryAE.countrySHORT, document.domain);
        this.setState({ country: countryAE });
      }
    };

    countryData.length && setPreferenceModal(countryData);
  };

  componentDidMount() {
    const {
      megaMenu,
      shopByStore,
      fetchHomepageSection,
      storeId,
      countryId,
      language
    } = this.props;

    if (!shopByStore && language && countryId && storeId) {
      fetchHomepageSection(language, countryId, storeId, "shopByStore");
    }

    if (language) {
      this.getlanguage(language);
    }

    this.loadModal();

    if (!megaMenu.length) {
      const { settings, megaMenuContent, countryId } = this.props;
      let _flag = false,
        storeID = null,
        _bflStoreID = null,
        _storeMap = getStoreMap(settings);
      let url = !isServer && window.location.href.toLowerCase();

      if (settings && settings.stores) {
        settings.stores.map(store => {
          if (url.includes(_storeMap[store.mid])) {
            storeID = store.mid;
            _flag = true;
          }
          if (store.name.toLocaleLowerCase().includes("brands")) {
            _bflStoreID = store.mid;
          }
          return null;
        });
      }

      (storeID || _bflStoreID) &&
        countryId &&
        megaMenuContent(language, _flag ? storeID : _bflStoreID, countryId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      countryData,
      getCountrySettings,
      getCountryList,
      language,
      settings,
      getCartAndWishlistGeneralData
    } = this.props;

    const nextCountryId = nextProps.settings.countryId;

    if (nextProps.language && nextProps.language !== language) {
      this.getlanguage(nextProps.language);
      if (countryData.length <= 1) {
        getCountrySettings(nextProps.language);
      } else {
        getCountryList(nextProps.language);
      }
    }

    if (nextCountryId && nextCountryId !== settings.countryId) {
      getCartAndWishlistGeneralData();
    }
    if (
      (nextProps.countryData && nextProps.countryData.length) !==
      (countryData && countryData.length)
    ) {
      this.loadModal();
    }
  }

  componentDidUpdate() {
    const { megaMenu } = this.props;
    const { flag } = this.state;
    megaMenu.forEach((menuItems, index) => {
      Object.keys(menuItems).forEach(element => {
        if (element.includes("col")) {
          if (element.includes("col") && menuItems[`${element}`] !== null) {
            if (
              Array.isArray(menuItems[`${element}`]) &&
              !!menuItems[`${element}`].length
            ) {
              flag && this.setState({ flag: false });
            }
            if (
              !Array.isArray(menuItems[`${element}`]) &&
              typeof menuItems[`${element}`] === "object" &&
              !!menuItems[`${element}`].keys
            ) {
              flag && this.setState({ flag: false });
            }
          }
        }
      });
    });
  }

  getlanguage = language => {
    const languageResponse = import(
      `../../language/userPreferences/${language}/userPreferences`
    );

    languageResponse.then(response => {
      this.setState({
        userPreferencesTranslation: response.preferencesTranslation
      });
    });

    const megaMenulanguageResponse = import(
      `../../language/megaMenu/${language}/megaMenu`
    );

    megaMenulanguageResponse.then(response => {
      this.setState({
        megaMenuTranslation: response.megaMenuTranslation
      });
    });
  };

  toggleLanguage = () => {
    const {
      handleLanguageChange,
      history,
      location,
      language,
      settings,
      clearBannersSectionsData
    } = this.props;
    const { arabic, english } = LANGUAGE_ROUTE_KEY;
    const _language = language === arabic ? english : arabic;
    const locationChunks = location.pathname.split("/").splice(2);

    if (locationChunks && locationChunks.includes("search")) {
      const searchUrl = `/${_language}-${settings.countrySHORT.toLowerCase()}/search/${
        location.search
      }`;
      history.push(searchUrl);
    } else {
      history.push(
        `/${_language}-${settings.countrySHORT.toLowerCase()}/${locationChunks.join(
          "/"
        )}${location.search}`
      );
    }
    GAService.header.trackChangeLanguage(_language);
    handleLanguageChange(_language);
    clearBannersSectionsData();
  };

  openModal = e => {
    e && e.stopPropagation();
    const { getCountryList, language, settings } = this.props;
    const { country } = this.state;
    const countrySelection = (settings || country).countryCode;
    const currencySelection = (settings || country).currencyCode;
    GAService.header.trackNavTop(`${countrySelection} | ${currencySelection}`);

    getCountryList(language);
    this.setState({ openUserPreferenceModal: true });
  };

  closeModal = e => {
    e && e.stopPropagation();
    this.setState({ openUserPreferenceModal: false });
  };

  closeFirstView = e => {
    e && e.stopPropagation();
    this.setState({ isFirstViewClosed: true });
  };

  handleChange = (e, redirectionLink) => {
    e.stopPropagation();
    const { history, handleSearchText } = this.props;
    const baseUrl = getBaseUrl(this.props);
    let currentPathname = `${baseUrl}${redirectionLink}`;

    handleSearchText("");

    history.push(currentPathname);
  };

  handleMegaMenuShow = activeLi => {
    this.activeListElementRef = activeLi;

    const node = this[`${activeLi}`];
    node && this.handleMegaMenuHover(node, true);
  };

  handleMegaMenuHide = activeLi => {
    const node = this[`${activeLi}`];
    node && this.handleMegaMenuHover(node, false);
  };

  timerId = null;
  hoverDelay = 300;
  handleMegaMenuHover = (node, hoverIn) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
    if (hoverIn) {
      this.timerId = setTimeout(() => {
        if (node && hoverIn) {
          node.classList.add("active-li", "navigation_menu_element_hover");
          node.parentElement.nextElementSibling &&
            node.parentElement.nextElementSibling.classList.add("active");
        }
        this.timerId = null;
      }, this.hoverDelay);
    } else {
      node.classList.contains("active-li") &&
        node.classList.remove("active-li");

      node.classList.contains("navigation_menu_element_hover") &&
        node.classList.remove("navigation_menu_element_hover");

      node.parentElement.nextElementSibling &&
        node.parentElement.nextElementSibling.classList.remove("active");
    }
  };

  handleMegaMenuHighlights = index => {
    const highlighted = "highlighted";
    const elements = document.querySelectorAll(".arrow-wrapper .arrow");

    elements.forEach(el => {
      el.classList.remove(highlighted);
    });
    document.getElementById(`arrow-${index}`).classList.add(highlighted);
  };

  openFirstView = () => {
    const userCountrySelected = getUserCountrySelectionStorage();
    const { isFirstViewClosed } = this.state;
    return userCountrySelected || isFirstViewClosed;
  };

  render() {
    const {
      isToggle,
      openUserPreferenceModal,
      userPreferencesTranslation,
      country,
      megaMenuTranslation,
      isFirstViewClosed,
      flag
    } = this.state;
    const {
      language,
      megaMenu,
      settings,
      authReducer,
      history,
      logOut,
      hideInMobile,
      headerBannerSections,
      configSyteSettings,
      routeSlug
    } = this.props;
    const currLang =
      language === LANGUAGE_ROUTE_KEY.english ? "عربى" : "ENGLISH";
    const userCountrySelected = getUserCountrySelectionStorage();
    const headerBottomSections =
      headerBannerSections[SECTION_HEADER_TYPES.HEADER_BOTTOM];

    const baseUrl = getBaseUrl(this.props);
    const appDownloadUrl = `${baseUrl}/app-download/`;
    const deviceTypeSSR = get(getState(), "common.deviceType", "");
    const deviceType = deviceTypeSSR || getDeviceType();
    const logoImageUrls = settings.cdn?.logo;
    const countryShortCode = settings?.countrySHORT || country?.countrySHORT;
    const countrySelection = (settings || country).countryCode;
    const currencySelection = (settings || country).currencyCode;
    const categoryMenu =
      !!megaMenu.length &&
      megaMenu.map((menuItems, index) => {
        return (
          <li
            key={menuItems.uid || index}
            className={classnames(
              {
                new: !index,
                offer: menuItems.isSPS
              },
              "top-level-menu"
            )}
            onClick={e => e.stopPropagation()}
            ref={el => (this[`activeLi` + index] = el)}
            onMouseEnter={() => {
              !flag && this.handleMegaMenuShow(`activeLi` + index);
            }}
            onMouseLeave={() => {
              !flag && this.handleMegaMenuHide(`activeLi` + index);
            }}
          >
            <span
              className="arrow-wrapper"
              onClick={e => {
                this.handleChange(e, menuItems.redirectionLink);
                this.handleMegaMenuHighlights(index);
                GAService.header.trackMegaMenuClick(menuItems);
              }}
            >
              <span className={`${!index ? "new_icon" : ""}`} />
              <span aria-label={index} id={`arrow-${index}`} className="arrow">
                <a
                  href={getBaseUrl(this.props) + menuItems.redirectionLink}
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  {menuItems.headerTitle}
                </a>
              </span>
            </span>
            {!flag && (
              <CategoryMenu
                isDisplay={
                  !isServer && !(userCountrySelected || isFirstViewClosed)
                }
                menuList={menuItems}
                countryShort={settings && settings.countrySHORT}
                language={language}
              />
            )}
          </li>
        );
      });

    return (
      <>
        <UserPreferencesModal
          modalClass={`generic_modal country_selection_modal ${
            language.includes("ar") ? "arabic" : ""
          }`}
          showModal={openUserPreferenceModal}
          overlayClassName="countryOverlay"
          closeModal={this.closeModal}
        >
          <button className="cross_btn" onClick={this.closeModal}>
            &times;
          </button>
          {openUserPreferenceModal && (
            <UserPreferences
              userPreferencesTranslation={userPreferencesTranslation}
              language={language}
              closeModal={this.closeModal}
            />
          )}
        </UserPreferencesModal>
        <div
          className={`header_wrap ${
            language === LANGUAGE_ROUTE_KEY.arabic ? "arabic" : ""
          } ${!!hideInMobile ? "hideHeaderFooter" : ""}`}
        >
          {/* <div className="countryFirstViewMob">
            {!isServer &&
            !(userCountrySelected || isFirstViewClosed) &&
            removeCountrySelectionDropdown(history) ? (
              <CountryFirstView
                userPreferencesTranslation={userPreferencesTranslation}
                language={language}
                closeModal={this.closeFirstView}
                hideInMobile={hideInMobile}
              />
            ) : null}
          </div> */}
          <Headroom
            disableInlineStyles
            onUnpin={() => {
              !flag && this.handleMegaMenuHide(this.activeListElementRef);
            }}
          >
            <header>
              <MobileHeader
                language={language}
                history={history}
                authReducer={authReducer}
                countryCode={settings && settings.countryCode}
                countryShort={settings && settings.countrySHORT}
                openCountryModal={this.openModal}
                logOut={logOut}
                toggleLanguage={this.toggleLanguage}
                megaMenuTranslation={megaMenuTranslation}
                configSyteSettings={configSyteSettings}
                routeSlug={routeSlug}
              />
              <section className="web_header">
                {/* {!isServer &&
              !(userCountrySelected || isFirstViewClosed) &&
              removeCountrySelectionDropdown(history) ? (
                <CountryFirstView
                  userPreferencesTranslation={userPreferencesTranslation}
                  language={language}
                  closeModal={this.closeFirstView}
                  hideInMobile={hideInMobile}
                />
              ) : null} */}
                <section
                  className={`top_section ${
                    isToggle ? "brand-logo-visible" : null
                  }`}
                >
                  <div className="container padding0 position_relative">
                    {!isToggle && (
                      <div className="rest_nav_top row">
                        <div className="header_logo_wrap padding0 align-left">
                          <Link
                            to={`${baseUrl}/`}
                            onClick={() => {
                              GAService.header.trackHomeLogoClick(
                                `${baseUrl}/`
                              );
                            }}
                          >
                            <span className="current_logo">
                              <Image
                                src={logoImageUrls?.[deviceType]}
                                alt="logo"
                                id="main_header_logo"
                                showNewLogoDefaultImage={true}
                              />
                            </span>
                          </Link>
                          {!isTouchScreen() && <Search />}
                        </div>
                        <div className="header_right_section">
                          <div className="header_country_specific">
                            <button className="download_app">
                              <span />
                              <Link
                                to={appDownloadUrl}
                                className="download_app"
                                onClick={() =>
                                  GAService.header.trackNavTop(
                                    preferencesTranslation.downloadMobile,
                                    appDownloadUrl
                                  )
                                }
                              >
                                {userPreferencesTranslation.downloadMobile}
                              </Link>
                            </button>
                            {removeCountrySelectionDropdown(history) && (
                              <div
                                className="currency_change"
                                onClick={this.openModal}
                              >
                                {countryShortCode && (
                                  <span>
                                    <CircleFlag
                                      countryCode={countryShortCode.toLowerCase()}
                                      width={18}
                                      className="country_image"
                                    />
                                  </span>
                                )}
                                {((settings && settings.countryCode) ||
                                  (country && country.countryCode)) && (
                                  <p className="country_currency">
                                    <span>{countrySelection}</span>
                                    <span>{currencySelection}</span>
                                  </p>
                                )}
                                <span className="arrow-down" />
                              </div>
                            )}
                            <span
                              className="language_change"
                              onClick={this.toggleLanguage}
                            >
                              <span className="lang">{currLang}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section className="bottom_section">
                  <div className="container">
                    <div className="row">
                      <div
                        className="menuLeft position_static padding0 overflow-hidden"
                        style={{ height: "51px" }}
                      >
                        <ul className="navigation_menu">{categoryMenu}</ul>
                        <div className="category-menu-overlay" />
                      </div>
                      <div className="menuRight padding0">
                        <UserProfile
                          language={language}
                          handleToggle={this.handleToggle}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </header>
          </Headroom>
        </div>
        {headerBottomSections && (
          <>
            {headerBottomSections.map((section, key) => (
              <DynamicBannersWrapper
                section={section}
                key={key}
                bannerPageTitle={section.sectionTitle}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  countryData: state.common.countryData,
  ipCountryData: state.common.ipCountryData,
  isProductListingPage: state.productListingReducer.isProductListingPage,
  megaMenu: state.megaMenuReducer.megaMenuList,
  settings: state.common.settings,
  storeId: state.page.homepageState.storeId,
  shopByStore: state.page.storeList,
  showModal: state.homepage.showModal,
  generalInfo: state.common.generalInfo,
  showLoader: state.common.showLoader,
  countryId: state.page.homepageState.countryId,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  headerBannerSections: selectHeaderBannerSections(state),
  configSyteSettings: selectSyteSettings(state),
  routeSlug: selectRouteSlug(state)
});

const mapDispatchToProps = {
  fetchHomepageSection,
  getCountryList,
  getCountrySettings,
  getStatesData,
  handleLanguageChange,
  handleSearchText,
  logOut,
  megaMenuContent,
  setCommonData,
  setGeneralInfo,
  setIPCountryData,
  clearBannersSectionsData,
  getCartAndWishlistGeneralData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
