import React, { Component } from "react";
import { connect } from "react-redux";
import { getCardList } from "../../util/my-account";
import { AVAILABLE_PAYMENT_TYPE } from "../../constants/checkout";
import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import OverViewCard from "../../components/my-acc/myAcc-overview-card";
import SaveCard from "../../components/saved-cards";
import { toggleAddNewCardTab } from "../../redux/actions/myAccount.action";
import {
  handleTextChange,
  resetSavedCard
} from "../../redux/actions/payment.action";
import EmptyCart from "../../components/cart/cart-empty";
import CreditDebitCard from "../../components/creditDebitCard";
import {
  deleteCreditCard,
  loadCreditCards
} from "../../redux/actions/checkout.action";

class SavedCards extends Component {
  state = {
    newCard: false
  };

  componentDidMount() {
    const { loadCreditCards, userLoggedIn } = this.props;
    if (this.props.subTab) {
      this.handleNewAddress(false);
    }
    userLoggedIn && loadCreditCards();
  }

  componentWillUnmount() {
    this.handleNewAddress(false);
  }

  handleRemoveCard = id => {
    const { deleteCreditCard } = this.props;

    deleteCreditCard(id);
  };

  handleNewAddress = value => {
    this.props.toggleAddNewCardTab(value);
    if (value === false) {
      this.props.resetSavedCard();
    }
  };

  handleSaveCard = () => {
    const { payment, customerId, handleTextChange, resetSavedCard } =
      this.props;
    Object.keys(payment.errors).forEach(key => {
      handleTextChange(key, payment[key]);
    });

    if (
      !Object.keys(payment.errors).every(
        key =>
          key === "cvv" ||
          (payment.errors[key].valid && payment.errors[key].touched)
      )
    ) {
      return;
    }
    payment.customerId = customerId;
    payment.cardNumber =
      payment.cardNumber.slice(0, payment.cardNumber.length - 10) +
      payment.cardNumber.slice(
        payment.cardNumber.length - 4,
        payment.cardNumber.length
      );
    payment.isVerified = false;

    delete payment.errors;
    delete payment.cvv;

    this.handleNewAddress(false);
    resetSavedCard();
  };

  render() {
    const {
      myAccountTranslation,
      language,
      noRecordImgUrl,
      checkoutTranslation,
      addNewCardTab,
      handleGoBack,
      checkoutCardsList,
      savedCardDisplayType
    } = this.props;

    const cardList = getCardList(savedCardDisplayType, checkoutCardsList);

    return (
      <div className="overview-wrapper">
        <OverViewHeading
          headingName={myAccountTranslation.savedCards}
          icon="my_save_cards_icon"
          handleGoBack={handleGoBack}
        />
        {!addNewCardTab ? (
          savedCardDisplayType && cardList.length ? (
            <div className="web_container card_container">
              <div className="card-wrapper">
                {cardList.map(card => (
                  <OverViewCard shadow key={card.id || card.cardId}>
                    <SaveCard
                      language={language}
                      myAccountTranslation={myAccountTranslation}
                      card={card}
                      handleRemoveCard={this.handleRemoveCard}
                    />
                  </OverViewCard>
                ))}
              </div>
            </div>
          ) : (
            <EmptyCart
              noRecordDesc={myAccountTranslation.noCardDesc}
              language={language}
              noRecordImgUrl={noRecordImgUrl}
              buttonClick={() => this.handleNewAddress(true)}
            />
          )
        ) : (
          <>
            <div className="add_new_card">
              <div className="other_payment_options">
                <div className="other_payment_details">
                  <CreditDebitCard
                    checkoutTranslation={checkoutTranslation}
                    hideCvv
                  />
                  <div className="save_card_btn">
                    <button
                      className="round_btn btn"
                      onClick={() => {
                        this.handleSaveCard();
                      }}
                    >
                      Save Card
                    </button>
                    <button
                      className="round_btn btn cancel_btn"
                      onClick={() => this.handleNewAddress(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customerId: state.authReducer.customerId,
  language: state.common.language,
  payment: state.paymentReducer,
  addNewCardTab: state.myAccountReducer.addNewCardTab,
  checkoutCardsList: state.checkoutReducer.creditCardList,
  userLoggedIn: state.authReducer.userLoggedIn,
  savedCardDisplayType: state.common.settings.savedCardDisplayType
});

const mapStateToDispatch = {
  handleTextChange,
  resetSavedCard,
  toggleAddNewCardTab,
  loadCreditCards,
  deleteCreditCard
};
export default connect(mapStateToProps, mapStateToDispatch)(SavedCards);
