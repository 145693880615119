import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import * as priceUtil from "../../../util/priceUtil";
import {
  BULLET_TYPES,
  CONTROLLER_TYPES,
  LANGUAGE,
  LOADER_TYPES,
  SWIPER_V9_URL,
  commonLoaderStyle
} from "../../../constants";

import {
  getAvailableDisplaySizes,
  getProductUrlName,
  isMobile,
  isServer
} from "../../../util";
import {
  selectRouteSlug,
  selectLanguage,
  selectLanguageSlug,
  selectCdnImageSettings
} from "../../../util/selectors";
import { isPriceAvailableByUserIpcountry } from "../../../util/priceUtil";
import { withClientOnlyRender } from "../../../hocs";

import {
  changeLanguage,
  getCatalogCarouselData
} from "../../../redux/actions/common.action";

import ContentImage from "../../content/content-image";
import PriceSlab from "../../price/price-slab";

import { productCarouselSwiperConfig } from "./swiperConfigs";
import useOnScrollHandling from "../../../custom-hooks/intersection-observer";
import Loader from "../../loader";
import { getAutoScrollConfig } from "../../../util/carousel";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";
import "swiper_V9/swiper-bundle.min.css";
import { addScriptToBody } from "../../../util/dom";
import { SaleService } from "../../../services";

import { SwiperProductSize } from "../../product/product-size";
import { SalePersentageBlock } from "../../dynamic/dynamic-sale/SalePersentageBlock";
import { GAService } from "../../../services/GA-service";

const truncateProductTitle = (title, characters) => {
  if (title && title.length > characters)
    return title.substring(0, characters) + "...";
  else {
    return title;
  }
};

const getElement = (el, className) =>
  !isServer && el && el.querySelector(className);

function CatalogProductCarousel(props) {
  const {
    language,
    slug,
    settings,
    currencyCode,
    history,
    getImageList,
    productTranslation,
    content,
    countryId,
    getCatalogCarouselData,
    configCdnImagesSettings,
    bannerModuleName,
    bannerPageTitle,
    isSaleCarousel
  } = props;

  const catalogCarouselRef = useRef(null);
  const swiperElRef = useRef(null);
  const { isIntersecting } = useOnScrollHandling(catalogCarouselRef);
  const { isIntersecting: isIntersectingWithProduct } = useOnScrollHandling(
    catalogCarouselRef,
    {
      threshold: 1,
      constantTracking: true
    }
  );
  const [imageList, setImageList] = useState([]);
  const [isCarouselFetched, setIsCarouselFetched] = useState(false);
  const [loadedScriptStatus, setLoadedScriptStatus] = useState(false);
  const data = content[language];
  const indicatorStyles = content.indicatorStyles;
  const activeBullet = catalogCarouselRef?.current?.querySelector(
    `.bullet-active${content.id}`
  );

  const isPagination = content.indicatorType !== BULLET_TYPES.NONE;

  const swiperNavPrevId = `swiper-button-prev-${content.id}`;
  const swiperNavNextId = `swiper-button-next-${content.id}`;

  useEffect(() => {
    addScriptToBody(SWIPER_V9_URL, () => setLoadedScriptStatus(true), true);
  }, []);

  useEffect(() => {
    if (swiperElRef?.current && (loadedScriptStatus || window.Swiper)) {
      const paginationForSwiper = {
        pagination: {
          el: ".swiper-pagination",
          renderBullet: (index, className) => {
            return `<span class='${className} ${content.indicatorType} product-carousel-bullet bullet${content.id}'></span>`;
          },
          bulletActiveClass: `swiper-pagination-bullet-active bullet-active${content.id}`,
          clickable: true
        },
        on: {
          slideChange: onSlideChange
        }
      };

      const navigation = {
        nextEl: `#${swiperNavNextId}`,
        prevEl: `#${swiperNavPrevId}`
      };
      const autoScrollConfig = getAutoScrollConfig(content?.autoScroll);

      const isArabic = language === LANGUAGE.arabic;

      const slidesOffsetAfter = isMobile.any() ? 170 : 300;

      const params = {
        loop: !isEmpty(autoScrollConfig),
        ...autoScrollConfig,
        ...productCarouselSwiperConfig,
        ...(!isMobile.any() && { navigation }),
        ...(isPagination && paginationForSwiper),
        ...(isArabic && { slidesOffsetAfter })
      };

      const swiper = new Swiper(swiperElRef.current, params);

      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [content.id, imageList.length, language, loadedScriptStatus]);

  useEffect(() => {
    if (isIntersecting) {
      isSaleCarousel ? getSaleCarouselData() : getPageData(data.searchUrl);
    }
  }, [isIntersecting]);

  useEffect(() => {
    let timeout = null;

    const handleImpressions = () => {
      if (
        !isEmpty(imageList) &&
        isIntersectingWithProduct &&
        catalogCarouselRef
      ) {
        timeout = setTimeout(() => {
          const refCurrent = catalogCarouselRef.current;
          const parent = refCurrent?.querySelector(".swiper-wrapper");
          if (!isEmpty(parent)) {
            // exact size of viewport
            const { width: containerWidth } = parent.getBoundingClientRect();
            // exact size of first product in a row , to calculate.
            const { width: elementWidth } =
              parent.children?.[0]?.getBoundingClientRect();
            const visibleProductsCount = Math.round(
              containerWidth / elementWidth
            );
            const visibleProducts = imageList.slice(0, visibleProductsCount);
            GAService.product.trackViewItemListImpressions({
              _arr: visibleProducts,
              products: { title: data.title }
            });
          }
        }, 1000);
      } else {
        clearTimeout(timeout);
      }
    };

    if (isIntersectingWithProduct) {
      timeout = setTimeout(handleImpressions, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [imageList, isIntersectingWithProduct]);

  useEffect(() => {
    onSlideChange();
  }, [activeBullet, imageList.length]);

  const setControllerStyles = () => {
    const carouselWrapper = !isServer && catalogCarouselRef?.current;

    const nextButton = getElement(carouselWrapper, `#${swiperNavNextId}`);
    const prevButton = getElement(carouselWrapper, `#${swiperNavPrevId}`);

    if (nextButton && prevButton && content.controllerStyles) {
      nextButton.style.background =
        content.controllerStyles.backgroundControllerColor;
      prevButton.style.background =
        content.controllerStyles.backgroundControllerColor;
    }
  };
  const setActiveBulletColor = () => {
    const activeBullet = catalogCarouselRef?.current?.querySelector(
      `.bullet-active${content.id}`
    );
    if (activeBullet) {
      activeBullet.style.background = indicatorStyles.indicatorColor;
    }
  };

  const onSlideChange = () => {
    const allBullets = catalogCarouselRef?.current?.querySelectorAll(
      `.bullet${content.id}`
    );
    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background = indicatorStyles.backgroundIndicatorColor;
      });
      setActiveBulletColor();
    }
  };

  const getPageData = async searchUrl => {
    const response = await getCatalogCarouselData({
      url: searchUrl,
      countryId,
      language,
      storeId: 2
    });
    const imageList = getImageList(response[language]);

    setImageList(imageList);

    if (content.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  };

  const getSaleCarouselData = async () => {
    const slug = content[language].flashSalePageSlug;

    const response = await SaleService.getCatalogSaleCarouselData({
      slug,
      dataType: content.bannerType,
      countryId,
      language,
      storeId: 2
    });

    const imageList = getImageList(response[language]);

    setImageList(imageList);
    setIsCarouselFetched(true);

    if (content.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  };

  const rowStyles = {
    ...content.styles,
    maxWidth: content.width?.value || "100%",
    marginLeft: "auto",
    marginRight: "auto"
  };

  const viewAllLink = isSaleCarousel ? data.redirectionUrl : data.searchUrl;

  if (isCarouselFetched && !Boolean(imageList.length)) {
    return null;
  }

  const handleProductSelect = item => {
    GAService.product.trackSelectProduct({
      item,
      listingTitle: item.en_title
    });
  };

  return (
    <div
      className={cn(
        "catalog_product_carousel_section dynamic_product_carousel",
        {
          arabic: language === LANGUAGE.arabic
        }
      )}
      id={content.id}
      ref={catalogCarouselRef}
    >
      {imageList.length ? (
        <>
          {data.title && (
            <div className="head_with_desc">
              <h2>{data.title}</h2>
              <button
                className="view-all"
                onClick={() => history.push(`/${slug}${viewAllLink}`)}
              >
                {productTranslation.viewAll}
              </button>
            </div>
          )}
          <div className="catalogue-slider-wrap" style={rowStyles}>
            <div
              className="swiper carousel-slider"
              ref={swiperElRef}
              id={`swiper-${content.id}`}
            >
              <div className="swiper-wrapper">
                {imageList.map((product, index) => {
                  const isPriceAvailable =
                    isPriceAvailableByUserIpcountry(product);
                  const dataForAnalytic = {
                    bannerModuleName: bannerModuleName?.toUpperCase(),
                    bannerRowName: content.title?.toUpperCase(),
                    index: index + 1,
                    url: product.captionImageURL
                      ? `${configCdnImagesSettings?.list}${product.captionImageURL}`
                      : "",
                    pageName: bannerPageTitle
                  };
                  const redirectionLink = `/${slug}/${
                    getProductUrlName(product.en_title || product.title) ||
                    "product-detail"
                  }/${product.id}/p/`;
                  const { percentageDiscount, percentage } =
                    priceUtil.getItemDiscountPersentage(product, language);
                  const percentageValue =
                    product.price?.old && percentageDiscount;
                  const salePersentageBlock =
                    isSaleCarousel && Boolean(percentage);
                  const saleOutOfStock = !product?.pstock && isSaleCarousel;

                  return (
                    <div
                      className="catalogue_carousel_wrapper swiper-slide"
                      key={product.id}
                    >
                      <div className="catalogue_item">
                        <div className="catalogue_img_wrap">
                          <BannerIntersectionObserver data={dataForAnalytic}>
                            <Link
                              to={redirectionLink}
                              className="carousel-link"
                              onClick={() => handleProductSelect(product)}
                              data-banner-link={redirectionLink}
                              data-banner-index={index}
                            >
                              <span
                                className={cn({
                                  "unavailable-product": saleOutOfStock
                                })}
                              >
                                <ContentImage
                                  img={product.captionImageURL}
                                  url={configCdnImagesSettings?.list}
                                  overlayLabel={product.overlayTagLabel}
                                  overlayTagBgColor={product.overlayTagBgColor}
                                  overlayTagLabelColor={
                                    product.overlayTagLabelColor
                                  }
                                  item={product}
                                  showDiscountSlab={!isSaleCarousel}
                                />
                              </span>

                              {saleOutOfStock && (
                                <p className="out-of-stock">
                                  {productTranslation?.soldOut}
                                </p>
                              )}
                              {salePersentageBlock && (
                                <SalePersentageBlock
                                  productStock={product?.pstock}
                                  percentageValue={percentageValue}
                                />
                              )}
                            </Link>
                          </BannerIntersectionObserver>
                        </div>
                        <div className="catalogue_content">
                          <div className="product-size-wrapper">
                            <SwiperProductSize
                              displaySizes={getAvailableDisplaySizes(product)}
                            />
                          </div>

                          <h4>{product.brandName}</h4>
                          <p>{truncateProductTitle(product.title, 50)}</p>

                          {isPriceAvailable && (
                            <PriceSlab
                              item={product}
                              translation={productTranslation}
                              language={language}
                              currencyCode={currencyCode}
                              settings={settings}
                              showRetailPrice
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!isMobile.any() && (
                <>
                  <div
                    className="swiper-button-prev"
                    id={swiperNavPrevId}
                  ></div>
                  <div
                    className="swiper-button-next"
                    id={swiperNavNextId}
                  ></div>
                </>
              )}
              {isPagination && <div className="swiper-pagination"></div>}
            </div>
          </div>
        </>
      ) : (
        <Loader
          type={LOADER_TYPES.DOTS}
          size={16}
          qty={3}
          visible
          style={commonLoaderStyle}
        />
      )}
    </div>
  );
}

const mapStateToProps = store => ({
  settings: store.common.settings,
  currencyCode: get(store, "common.settings.currencyCode"),
  slug: selectRouteSlug(store),
  language: selectLanguage(store),
  languageSlug: selectLanguageSlug(store),
  countryId: store.common.settings?.countryId,
  configCdnImagesSettings: selectCdnImageSettings(store)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  getCatalogCarouselData
};

export default compose(
  withClientOnlyRender,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CatalogProductCarousel);
