import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";

import { SYTE_DISCOVERY_LOCATION } from "../../../constants";
import {
  addAnimate,
  isMobile,
  getBaseUrl,
  isTouchScreen,
  isTabletView,
  isServer,
  getAvailableDisplaySizes
} from "../../../util";
import { isAppOnlyAvailableItem } from "../../../util/product";
import {
  selectCdnImageSettings,
  selectShowAppOnlyItemModalState
} from "../../../util/selectors";

import { toggleAppOnlyItemModal } from "../../../redux/actions/productlisting.action";

import AnalyticService from "../../../services/analytic-service";
import AlgoliaInsightsService from "../../../services/AlgoliaInsightsService";

import ContentDescription from "../content-description";
import ContentImage from "../content-image";
import { SwiperProductSize } from "../../product/product-size";
import { SyteDiscoveryButton } from "../../syte/syte-button";
import GoldLabel from "../../gold-label";
import * as priceUtil from "../../../util/priceUtil";

class Listing extends Component {
  state = {
    wishlistAnimationClass: ""
  };

  componentDidUpdate(prevProps) {
    const { wishListBaseDataLoaded, deleteWishListItemLoaded } = this.props;
    const { wishlistAnimationClass } = this.state;
    if (
      wishlistAnimationClass &&
      ((!prevProps.wishListBaseDataLoaded && wishListBaseDataLoaded) ||
        (!prevProps.deleteWishListItemLoaded && deleteWishListItemLoaded))
    ) {
      this.setState({ wishlistAnimationClass: "" });
    }
  }

  _addAnimate = (
    item,
    isClicked,
    wishListBaseData,
    wishlistAnimationClass,
    selectedSize,
    isDetailPage
  ) => {
    const value = addAnimate(
      item,
      isClicked,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      isDetailPage
    );
    this.setState({ wishlistAnimationClass: value });
  };

  gtm(e, data, index) {
    data[0].position = index;
    const {
      authReducer,
      commonSettings,
      breadcrumb,
      brandPageDetails,
      language,
      pageTitle
    } = this.props;
    let _category = brandPageDetails
      ? breadcrumb.brandsList.brandsName
      : breadcrumb.productList[`${language}_productListName`];

    AnalyticService.product.trackProductListingDetailImpressions({
      item: data,
      authReducer,
      commonSettings,
      title: pageTitle,
      category: _category
    });
  }

  toggleSaveToWishList = item => {
    const { wishlistAnimationClass } = this.state;
    const {
      saveToWishList,
      deleteFromWishList,
      wishListBaseData,
      isPresent,
      authReducer,
      commonSettings
    } = this.props;

    if (!isPresent(item)) {
      saveToWishList(item, `${item.id}`, "content_image", "favorite_icon");
    } else {
      deleteFromWishList(item.id);
      AnalyticService.cart.trackDeleteWishlist({
        item,
        wishListBaseData,
        authReducer,
        commonSettings
      });
    }
    this._addAnimate(
      item,
      true,
      wishListBaseData,
      wishlistAnimationClass,
      "",
      false
    );
  };

  handleSyteSearch = item => {
    const { pageTitle } = this.props;
    AnalyticService.syte.trackSyteSearch({
      item,
      pageTitle
    });
  };

  getScrollPosition = () => (isServer ? null : window.scrollY);

  onWishlistIconClick = e => {
    const { wishlistAnimationClass } = this.state;
    const { item } = this.props;

    e.preventDefault();
    e.stopPropagation();
    if (!wishlistAnimationClass) {
      this.toggleSaveToWishList(item);
    }
  };

  onClick = event => {
    const {
      item,
      index,
      history,
      location,
      algoliaQueryID,
      algoliaIndex,
      appOnlyItemModalState,
      toggleAppOnlyItemModal,
      handleProductSelect
    } = this.props;
    const { showAppOnlyItemModal } = appOnlyItemModalState;

    if (isAppOnlyAvailableItem(item)) {
      event.preventDefault();
      toggleAppOnlyItemModal({
        showAppOnlyItemModal: !showAppOnlyItemModal,
        product: item
      });
    } else {
      AlgoliaInsightsService.trackProductClick({
        productId: item.id,
        position: index + 1,
        queryID: algoliaQueryID,
        algoliaIndex
      });
      handleProductSelect({
        ...item,
        position: index
      });
      this.gtm(event, [item], index);
      history.replace(
        {
          pathname: location.pathname,
          search: location.search
        },
        {
          scrollPosition: this.getScrollPosition()
        }
      );
    }
  };

  handleContextMenu = (event, item) => {
    if (isAppOnlyAvailableItem(item) && event.button > 0) {
      event.preventDefault();
    }
  };

  render() {
    const { wishlistAnimationClass } = this.state;
    const {
      gridValue,
      language,
      getProductUrlName,
      item,
      _getAvailableSizes,
      isPresent,
      toggleQuicklook,
      translation,
      index,
      currencyCode,
      commonSettings,
      isDiscoveryButtonEnabled,
      configCdnImagesSettings,
      handleProductSelect
    } = this.props;

    const isMob = Boolean(isMobile.any() && isMobile.any().length);
    const ProductTitle =
      getProductUrlName(item.en_title || item.title || "") || "---";
    const mobileTabletView = cn({
      "mobile-tablet-view": isMob || isTabletView()
    });
    const wishListIconcn = cn(
      "wishlist-icon",
      mobileTabletView,
      wishlistAnimationClass,
      {
        wishlistIcon: !wishlistAnimationClass && isPresent(item),
        wishlistedIcon: !wishlistAnimationClass && !isPresent(item)
      }
    );

    const availableDisplaySizes = getAvailableDisplaySizes(item);
    const availableColorSizeOptions = _getAvailableSizes(item);
    const isTouch = isTouchScreen();
    const getListingGridClassName = () => {
      return `listing_grid ${
        gridValue === 4
          ? `four-view ${index > 24 ? "secondFold" : ""}`
          : `six-view ${index > 24 ? "secondFold" : ""}`
      }`;
    };
    const getProductDetailPageLink = () => {
      let url = getBaseUrl(this.props);
      if (!isAppOnlyAvailableItem(item)) {
        url = url.concat(`/${ProductTitle}/${item.id}/p/`);
      }
      return url;
    };

    return (
      <>
        <li className={getListingGridClassName()}>
          <Link
            onClick={this.onClick}
            to={getProductDetailPageLink()}
            className="hover_view_content"
            onContextMenu={event => this.handleContextMenu(event, item)}
          >
            <div
              className={cn("position-relative", {
                hover_layer: !mobileTabletView
              })}
            >
              <GoldLabel item={item} language={language} placement="plp" />

              <div
                id="hover_view_product_image"
                className={cn("hover_view", {
                  isMobileDevice: mobileTabletView
                })}
              >
                <div className={`listing_image_wrap ${item.id}`}>
                  <div className="listing_hover_content">
                    {!!item.oldPrice && (
                      <div
                        className={cn("discount_mobile_icon", {
                          "d-none": !mobileTabletView
                        })}
                      >
                        -{priceUtil.getDiscountPercent(item, commonSettings)}%
                      </div>
                    )}
                    {item.is_price_available_in_user_country && (
                      <span
                        className={`wishlist-icon ${wishListIconcn}`}
                        onClick={this.onWishlistIconClick}
                      />
                    )}
                    {isDiscoveryButtonEnabled &&
                      (isTouch || mobileTabletView) && (
                        <SyteDiscoveryButton
                          sku={item.id}
                          details={item}
                          productImgPath={`${configCdnImagesSettings?.list}${item.captionImageURL}`}
                          placement="product-listing"
                          location={SYTE_DISCOVERY_LOCATION.LISTING}
                          className={cn("listing-mobile", mobileTabletView)}
                          mobile
                          handleClick={this.handleSyteSearch}
                        />
                      )}
                    <span
                      className="quickview_btn"
                      onClick={e => {
                        e.preventDefault();
                        handleProductSelect(
                          {
                            ...item,
                            position: index,
                            origin: "quicklook"
                          },
                          true
                        );
                        AnalyticService.product.trackProductQuickLook(item);
                        toggleQuicklook(true, index, e);
                      }}
                    >
                      {translation.quickLook}
                    </span>
                  </div>
                  <ContentImage
                    img={item.captionImageURL}
                    url={configCdnImagesSettings?.list}
                    overlayLabel={item.overlayTagLabel}
                    overlayTagBgColor={item.overlayTagBgColor}
                    overlayTagLabelColor={item.overlayTagLabelColor}
                    showDiscountSlab={!mobileTabletView}
                    item={item}
                    translation={translation}
                    language={language}
                    showDefaultImage
                    lazyLoad={true}
                  />
                </div>
                {item.is_price_available_in_user_country && (
                  <span
                    className={`m_visible ${wishListIconcn}`}
                    onClick={this.onWishlistIconClick}
                  />
                )}
              </div>
              <div id="hover_view_product_size" className="hover_view">
                <div className="hover_view_content">
                  {isDiscoveryButtonEnabled && !isTouch && (
                    <SyteDiscoveryButton
                      sku={item.id}
                      details={item}
                      productImgPath={
                        configCdnImagesSettings?.list + item.captionImageURL
                      }
                      placement="product-listing"
                      location={SYTE_DISCOVERY_LOCATION.LISTING}
                      className="listing position-relative"
                      text={translation.viewSimilar}
                      handleClick={this.handleSyteSearch}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="product-size-wrapper">
              <SwiperProductSize
                displaySizes={availableDisplaySizes}
                gridSize={gridValue}
              />
            </div>
            <ContentDescription
              item={item}
              gridSize={gridValue}
              translation={translation}
              language={language}
              currencyCode={currencyCode}
              showEllipses={true}
              getProductUrlName={getProductUrlName}
              Redirection={true}
              commonSettings={commonSettings}
              removeInnerLink
              isProductAvailableInCountry={
                item.is_price_available_in_user_country
              }
              showRetailPrice
              showDiscountSlab={!mobileTabletView}
            />
          </Link>
        </li>
      </>
    );
  }
}

const mapStateToProps = state => ({
  appOnlyItemModalState: selectShowAppOnlyItemModalState(state),
  configCdnImagesSettings: selectCdnImageSettings(state)
});
const mapDispatchToProps = {
  toggleAppOnlyItemModal
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Listing);
