import React, { useEffect, useRef } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { getProductUrlName } from "../../util";
import ContentImage from "../content/content-image";
import { Link } from "react-router-dom";
import useOnScrollHandling from "../../custom-hooks/intersection-observer";
import { GAService } from "../../services/GA-service";

const RelatedProducts = ({
  handleChangeProduct,
  item,
  routeSlug,
  translation,
  url
}) => {
  const relatedProductsRef = useRef(null);
  const { isIntersecting: isIntersected } = useOnScrollHandling(
    relatedProductsRef,
    {
      threshold: 1,
      constantTracking: true
    }
  );

  useEffect(() => {
    let timeout = null;

    const handleImpressions = () => {
      if (!isEmpty(item.relatedProducts) && relatedProductsRef) {
        timeout = setTimeout(() => {
          if (!isEmpty(relatedProductsRef.current)) {
            GAService.product.trackViewRelatedProducts(item.relatedProducts);
          }
        }, 1000);
      } else {
        clearTimeout(timeout);
      }
    };

    if (isIntersected) {
      timeout = setTimeout(handleImpressions, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [item.relatedProducts, isIntersected]);

  return (
    <React.Fragment>
      {item && item.relatedProducts && item.relatedProducts.length && (
        <div className="related_product_items" ref={relatedProductsRef}>
          <p>
            {item.relatedProducts.length}
            <span>
              {item.relatedProducts.length === 1
                ? translation.similarProduct
                : translation.similarProducts}
            </span>
          </p>
          {map(item.relatedProducts, (relatedItem, index) => (
            <div
              className="product_item"
              key={index}
              onClick={e => handleChangeProduct(relatedItem, index)}
            >
              <Link
                to={`/${routeSlug}/${getProductUrlName(relatedItem.en_title)}/${
                  relatedItem.id
                }/p/`}
              >
                <ContentImage
                  url={url?.thumb}
                  img={relatedItem.captionImageURL}
                  lazyLoad={true}
                  colorAvailable={true}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default RelatedProducts;
