import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Page from "../../components/page";
import { resetPasswordTranslation } from "../../language/resetPassword/en/resetPassword";
import { changeLanguage } from "../../redux/actions/common.action";
import {
  tokenValidationService,
  resetPasswordAPI,
  handleTextChange,
  resetAPIError
} from "../../redux/actions/homepage.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

class ResetPassword extends React.Component {
  state = {
    error: false,
    resetPasswordTranslation: resetPasswordTranslation,
    errorMsg: ""
  };

  getlanguage(nextProps) {
    const resetPasswordResponse = import(
      `../../language/resetPassword/${nextProps.language}/resetPassword.js`
    );
    resetPasswordResponse.then(response => {
      this.setState({
        resetPasswordTranslation: response.resetPasswordTranslation
      });
    });
  }

  componentDidMount() {
    const {
      handleLanguageChange,
      sendTokenValidation,
      match,
      location,
      language
    } = this.props;
    let languageParam = (match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }

    handleLanguageChange(languageParam);
    sendTokenValidation({ token: location.search.split("=")[1] });
    this.getlanguage({ language: languageParam });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps);
    }
  }

  handleTextChange = e => {
    this.props.handleTextChange(e.target.name, e.target.value, "resetPassword");
    this.props.resetAPIError();
    this.setState({ error: false });
  };

  validateForm = () => {
    const { resetPassword } = this.props;
    const _key = Object.keys(resetPassword).filter(key => key !== "errors");
    _key.forEach(key =>
      this.handleTextChange({
        target: {
          name: key,
          value: resetPassword[key]
        }
      })
    );
  };

  handleSubmit = () => {
    const { resetPassword, settings } = this.props;
    const { error } = this.state;
    if (!error) this.validateForm();
    else this.checkForSamePassword();
    if (
      resetPassword.errors["password"].valid &&
      resetPassword.errors["password"].touched &&
      resetPassword.errors["confirmPassword"].valid &&
      resetPassword.errors["confirmPassword"].touched &&
      !error
    ) {
      const apiData = {};
      apiData.token = this.props.location.search.split("=")[1];
      apiData.email = this.props.tokenValidated.email;
      apiData.password = resetPassword.password;
      this.props.sendResetPasswordAPI(
        apiData,
        this.props.history,
        this.props.language,
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
          "ae"
      );
    }
  };

  checkForSamePassword = () => {
    const { resetPassword } = this.props;
    if (resetPassword.password !== resetPassword.confirmPassword) {
      this.setState({
        error: true,
        errorMsg: this.state.resetPasswordTranslation.error_text
      });
    } else if (
      resetPassword.password === "" ||
      resetPassword.confirmPassword === ""
    ) {
      this.setState({ error: false });
    }
  };
  render() {
    const { resetPassword, language, apiErrorDetail, settings } = this.props;
    const { resetPasswordTranslation, error, errorMsg } = this.state;
    return this.props.tokenValidated.success ? (
      <Page>
        <div>
          <div
            className={`register_user_page ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : "english"
            }`}
          >
            <div className="signup_layout reset_pass_page">
              <h4>{resetPasswordTranslation.resetPassword}</h4>
              <form>
                <div className="form-group">
                  <input
                    type="password"
                    className={
                      !resetPassword.errors.password.valid
                        ? "form-control borderRed"
                        : "form-control"
                    }
                    id="password"
                    name="password"
                    placeholder={resetPasswordTranslation.placeholder_1}
                    onChange={this.handleTextChange}
                    aria-label="New password"
                  />
                  {!resetPassword.errors.password.valid ? (
                    <span className="error">
                      {resetPasswordTranslation.required}
                    </span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={
                      !resetPassword.errors.password.valid || error
                        ? "form-control borderRed"
                        : "form-control"
                    }
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder={resetPasswordTranslation.placeholder_2}
                    onChange={this.handleTextChange}
                    onBlur={this.checkForSamePassword}
                    aria-label="Confirm new password"
                  />
                  {/*<span className="hint">{resetPasswordTranslation.hint_text}</span>*/}
                  {!resetPassword.errors.confirmPassword.valid ? (
                    <span className="error">
                      {resetPasswordTranslation.required}
                    </span>
                  ) : null}

                  {error ? <span className="error">{errorMsg}</span> : null}
                </div>
                <button
                  type="button"
                  className="btn btn-default round_btn form_black_btn"
                  onClick={() => this.handleSubmit()}
                >
                  {resetPasswordTranslation.submit}
                </button>
              </form>
              {!apiErrorDetail.success ? (
                <div className="form-error">
                  <span className="error">{apiErrorDetail.message}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Page>
    ) : (
      <Page>
        <div>
          <h3>
            {this.props.history.push({
              pathname: `/${language}-${
                (settings &&
                  settings.countrySHORT &&
                  settings.countrySHORT.toLowerCase()) ||
                "ae"
              }/error-page/`,
              state: { detail: this.props.tokenValidated.message }
            })}
          </h3>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  tokenValidated: state.homepage.resetpassword,
  resetPassword: state.homepage.resetPassword,
  language: state.common.language,
  settings: state.common.settings,
  apiErrorDetail: state.homepage.APIErrorResponse
});

const mapDispatchToProps = dispatch => ({
  sendTokenValidation: bindActionCreators(tokenValidationService, dispatch),
  sendResetPasswordAPI: bindActionCreators(resetPasswordAPI, dispatch),
  handleTextChange: bindActionCreators(handleTextChange, dispatch),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  resetAPIError: bindActionCreators(resetAPIError, dispatch)
});

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
