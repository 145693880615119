import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { SizeItem } from "../sizeItem";
import { setSize } from "../../../../redux/actions/quickFilter.action";

export const SizeTabs = ({
  data,
  activeSizeTab,
  setActiveSizeTab,
  selectedSizes
}) => {
  const dispatch = useDispatch();

  if (!activeSizeTab || !data[activeSizeTab]) return null;

  const tabSizes = data[activeSizeTab];
  const tabTitles = Object.keys(data);

  return (
    <div className="d-flex flex-column">
      <ul className="d-flex justify-content-center mb-2 shoes-tabs">
        {tabTitles.map(title => (
          <li
            key={title}
            className={cn("size-title shoes-format", {
              selected: title === activeSizeTab
            })}
            onClick={() => setActiveSizeTab(title)}
          >
            {title}
          </li>
        ))}
      </ul>
      <div className="d-flex justify-content-center flex-wrap size-column">
        {tabSizes.map(sizeOb => {
          const [sizeName, sizePath] = Object.entries(sizeOb)[0];
          return (
            <SizeItem
              key={sizeName}
              title={sizeName}
              selected={selectedSizes.includes(sizePath)}
              onClick={() => dispatch(setSize(sizePath))}
            />
          );
        })}
      </div>
    </div>
  );
};
