import React from "react";

const accountNavList = props => {
  const { navText, iconclass, selectList, isActive, hideInMobile } = props;
  return (
    <li
      className={`nav-list ${isActive ? "active" : ""} ${
        hideInMobile ? "hideInMobile" : ""
      }`}
      onClick={selectList}
    >
      <span className="icon-wrapper">
        <i className={`nav-icon ${iconclass}`} />
      </span>
      <span className="nav-text">{navText}</span>
    </li>
  );
};

export default accountNavList;
