import React, { Component, Fragment } from "react";
import StoreLocatorDropDown from "./store-locator-dropdown-mob";

class StoreLocatorHeaderMob extends Component {
  state = { showDropDown: false };

  changeShowDropDown = () =>
    this.setState({ showDropDown: !this.state.showDropDown });

  handleSelectedShop = selectedShop => {
    this.props.changeSelectedShop(selectedShop);
    this.changeShowDropDown();
  };

  render() {
    const { showDropDown } = this.state;
    const {
      useCurrentLocation,
      storeLocatorTranslation,
      storeCountry,
      changeSelectedCountry,
      cityList,
      selectedCountry,
      selectedShop
    } = this.props;
    return (
      <Fragment>
        <div className={`store-header-conatiner`}>
          <div className="current-locator-wrapper-mobile">
            <p className="info">{storeLocatorTranslation.locateStore}</p>
            <span className="current-loc-icon" onClick={useCurrentLocation} />
          </div>
          <div className={`store-header-wrapper`}>
            <h2 className="store-locator-heading">
              {storeLocatorTranslation.storeHeader}
            </h2>
            <div className={`store-locator-dropdown-box-wrapper`}>
              <div
                className={`store-locator-dropdown-box`}
                onClick={this.changeShowDropDown}
              >
                <div className={`inner-content`}>
                  <span className="mainSelected">
                    {`${selectedShop.countryName || ""} ${
                      selectedShop.countryName ? "," : ""
                    }${selectedShop.name || ""}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDropDown ? (
          <div
            className={`storelocator_dropdown_mob ${
              showDropDown
                ? "storelocator_dropdown_active"
                : "storelocator_dropdown_inactive"
            }`}
          >
            <StoreLocatorDropDown
              expandSection={showDropDown}
              storeCountry={storeCountry}
              cityList={cityList}
              changeSelectedCountry={changeSelectedCountry}
              selectedCountry={selectedCountry}
              selectedShop={selectedShop}
              handleSelectedShop={this.handleSelectedShop}
              backClick={this.changeShowDropDown}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default StoreLocatorHeaderMob;
