import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { breadcrumbTranslation } from "../../language/breadcrumb/en/breadcrumb";
import { changeLanguage } from "../../redux/actions/common.action";
import { getPageApi } from "../../redux/actions/page.action";
import { LANGUAGE_ROUTE_KEY_MAP } from "./../../constants";
import { setHomeBreadcrumb } from "../../redux/actions/breadcrumb";
import { getBreadcrumbLvlUrl, getBreadcrumbsList } from "../../util/breadcrumb";
import { getSEOStructuredBreadcrumbs } from "../../util/seo";
import { checkUrlSearchMultiCategory } from "../../util";

class Breadcrumb extends Component {
  state = {
    breadcrumbTranslation: breadcrumbTranslation
  };

  componentDidMount() {
    const { match, language, handleLanguageChange } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.getlanguage(language);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
    }
  }

  getlanguage = language => {
    const languageResponseBreadcrum = import(
      `../../language/breadcrumb/${language}/breadcrumb`
    );
    languageResponseBreadcrum.then(response => {
      this.setState({
        breadcrumbTranslation: response.breadcrumbTranslation
      });
      this.props.setHomeBreadcrumb(response.breadcrumbTranslation);
    });
  };

  getProductData = url => {
    const { getPageApi, language, settings, countryId, storeId } = this.props;
    const _langCountry = `/${language}-${
      (settings &&
        settings.countrySHORT &&
        settings.countrySHORT.toLowerCase()) ||
      "ae"
    }/`;
    let pageName = url.split(_langCountry);
    getPageApi &&
      countryId &&
      pageName[1] &&
      getPageApi(
        pageName[1],
        LANGUAGE_ROUTE_KEY_MAP[language],
        countryId,
        storeId
      );
    return false;
  };

  render() {
    const { breadcrumb, language, settings, isPageIndexable, location } =
      this.props;
    const lvl1Url = getBreadcrumbLvlUrl(breadcrumb.productList);
    const lvl2Url = getBreadcrumbLvlUrl(breadcrumb.productListLvl1);
    const lvl3Url = getBreadcrumbLvlUrl(breadcrumb.productListLvl2);

    const seoStructuredBreadcrumbs = getSEOStructuredBreadcrumbs(
      getBreadcrumbsList(breadcrumb, language)
    );
    const isQuickFilterData = checkUrlSearchMultiCategory(location.search);

    return (
      <>
        {isPageIndexable && (
          <Helmet script={[helmetJsonLdProp(seoStructuredBreadcrumbs)]} />
        )}
        <div
          className={`breadcrumb ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          {
            <div className="container">
              <ul className="breadcrumb-list">
                {breadcrumb.home.homeName && (
                  <li className="content">
                    <Link
                      to={`/${language}-${
                        (settings &&
                          settings.countrySHORT &&
                          settings.countrySHORT.toLowerCase()) ||
                        "ae"
                      }/`}
                    >
                      {breadcrumb.home.homeName}
                    </Link>
                  </li>
                )}
                {breadcrumb.category.categoryName && (
                  <li className="content">
                    {breadcrumb.category.categoryUrl ? (
                      <Link to={breadcrumb.category.categoryUrl}>
                        {breadcrumb.category.categoryName}
                      </Link>
                    ) : (
                      <>{breadcrumb.category.categoryName}</>
                    )}
                  </li>
                )}

                {breadcrumb.subCategory.subCategoryName && (
                  <li className="content">
                    <Link to={breadcrumb.subCategory.subCategoryUrl}>
                      {breadcrumb.subCategory.subCategoryName}
                    </Link>
                  </li>
                )}
                {breadcrumb.needHelp.needHelpName && (
                  <li className="content">
                    <Link to={breadcrumb.needHelp.needHelpUrl}>
                      {breadcrumb.needHelp.needHelpName}
                    </Link>
                  </li>
                )}
                {breadcrumb.needHelp.needHelpOptName && (
                  <li className="content">
                    {breadcrumb.needHelp.needHelpOptName}
                  </li>
                )}
                {breadcrumb.sendUsEmail.sendUsEmailName && (
                  <li className="content">
                    <Link to={breadcrumb.sendUsEmail.sendUsEmailUrl}>
                      {breadcrumb.sendUsEmail.sendUsEmailName}
                    </Link>
                  </li>
                )}
                {breadcrumb.brands.brandsName && (
                  <li className="content">{breadcrumb.brands.brandsName}</li>
                )}
                {breadcrumb.brandsList.brandsName && (
                  <li className="content">
                    {breadcrumb.brandsList &&
                    breadcrumb.brandsList.brandsUrl ? (
                      <Link to={breadcrumb.brandsList.brandsUrl}>
                        {breadcrumb.brandsList.brandsName}
                      </Link>
                    ) : (
                      <>{breadcrumb.brandsList.brandsName}</>
                    )}
                  </li>
                )}
                {breadcrumb.brandsListLvl1.brandsName && (
                  <li className="content">
                    {breadcrumb.brandsListLvl1.brandsName}
                  </li>
                )}

                {/* product level0*/}
                {breadcrumb.productList[
                  `${
                    language === "ar"
                      ? "ar_productListName"
                      : "en_productListName"
                  }`
                ] && (
                  <li className="content">
                    {breadcrumb.productListLvl1 &&
                    breadcrumb.productListLvl1.productListLvl1Url &&
                    breadcrumb.productListLvl1.productListLvl1Url.includes(
                      `${
                        breadcrumb.productList.productListUrl.split("/")[2]
                      }`.toLowerCase()
                    ) &&
                    !!breadcrumb.productList.productListUrl.split("/")[2] ? ( // check level1 url includes level0 url
                      <Link
                        to={lvl1Url}
                        onClick={e =>
                          this.getProductData(
                            breadcrumb.productList.productListUrl,
                            e
                          )
                        }
                      >
                        {
                          breadcrumb.productList[
                            `${
                              language === "ar"
                                ? "ar_productListName"
                                : "en_productListName"
                            }`
                          ]
                        }
                      </Link>
                    ) : (
                      <>
                        {
                          breadcrumb.productList[
                            `${
                              language === "ar"
                                ? "ar_productListName"
                                : "en_productListName"
                            }`
                          ]
                        }
                      </>
                    )}
                  </li>
                )}

                {/* product level1*/}
                {breadcrumb.productListLvl1.productListLvl1Name && (
                  <li className="content">
                    {!isQuickFilterData &&
                    breadcrumb.productListLvl2 &&
                    breadcrumb.productListLvl2.productListLvl2Url &&
                    breadcrumb.productListLvl2.productListLvl2Url.includes(
                      `${breadcrumb.productListLvl1.productListLvl1Url}`.toLowerCase()
                    ) ? (
                      <Link
                        onClick={e =>
                          this.getProductData(
                            breadcrumb.productListLvl1.productListLvl1Url,
                            e
                          )
                        }
                        to={lvl2Url}
                      >
                        {breadcrumb.productListLvl1.productListLvl1Name}
                      </Link>
                    ) : (
                      <>{breadcrumb.productListLvl1.productListLvl1Name}</>
                    )}
                  </li>
                )}

                {/* product level2*/}
                {breadcrumb.productListLvl2.productListLvl2Name &&
                  !isQuickFilterData && (
                    <li className="content">
                      {breadcrumb.product && breadcrumb.product.productUrl ? (
                        <Link
                          to={lvl3Url}
                          onClick={e =>
                            this.getProductData(
                              breadcrumb.productListLvl2.productListLvl2Url,
                              e,
                              "lvl2"
                            )
                          }
                        >
                          {breadcrumb.productListLvl2.productListLvl2Name}
                        </Link>
                      ) : (
                        <>{breadcrumb.productListLvl2.productListLvl2Name}</>
                      )}
                    </li>
                  )}
                {/* product Detail productName */}
                {breadcrumb.product.productName && (
                  <li className="content">{breadcrumb.product.productName}</li>
                )}
                {breadcrumb.trackShipment.trackShipmentName && (
                  <li className="content">
                    {breadcrumb.trackShipment.trackShipmentName}
                  </li>
                )}
              </ul>
            </div>
          }
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  breadcrumb: state.breadcrumbReducer,
  language: state.common.language,
  settings: state.common.settings,
  countryId: state.page.homepageState.countryId,
  storeId: state.page.homepageState.storeId,
  isPageIndexable: state.common.isPageIndexable
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  setHomeBreadcrumb: bindActionCreators(setHomeBreadcrumb, dispatch),
  getPageApi: bindActionCreators(getPageApi, dispatch)
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
);
