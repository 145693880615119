import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  hideModal,
  resetAPIError,
  showModal
} from "../../redux/actions/homepage.action";
import {
  resetPasswordEmailLink,
  handleTextChange
} from "../../redux/actions/homepage.action";
import { loginTranslation } from "../../language/login/en/login";
class ForgotPassword extends React.Component {
  state = {
    loginTranslation: loginTranslation
  };
  backButtonHandler = () => {
    this.props.showLoginModal();
    this.props.closeForgotPasswordModal();
  };
  validateForm = () => {
    const { forgotPassword } = this.props;
    this.handleTextChange({
      target: {
        name: "email",
        value: forgotPassword["email"]
      }
    });
  };
  handleSubmit = e => {
    const { forgotPassword, sendEmailLink, language, commonSettings } =
      this.props;
    this.validateForm();
    if (
      forgotPassword.errors["email"].valid &&
      forgotPassword.errors["email"].touched
    ) {
      sendEmailLink({
        email: forgotPassword.email,
        lang: language,
        country: commonSettings.countrySHORT.toLowerCase()
      });
    }
    e.preventDefault();
  };
  closeForgotPassword = () => {
    this.props.closeModal();
    this.props.closeForgotPasswordModal();
  };
  handleTextChange = e => {
    this.props.handleTextChange(
      e.target.name,
      e.target.value,
      "forgotPassword"
    );
    this.props.resetAPIError();
  };
  componentWillUnmount() {
    this.props.resetAPIError();
  }

  render() {
    const {
      forgotPassword,
      translation,
      apiErrorDetail,
      toggleSignInTab,
      hideLoginFooter
    } = this.props;
    return (
      <div className="modal_spacing forgot_reset_password_wrapper">
        <div className="modal_spacing_wrap">
          <div className="login_modal_content">
            <button className="cross_btn" onClick={this.closeForgotPassword}>
              &times;
            </button>
            <button className="back_btn" onClick={this.backButtonHandler} />
            <h2>{translation.forgotPassword}</h2>
            <form onSubmit={this.handleSubmit}>
              <p className="forgot_password_head">
                {translation.forgotPasswordText}
              </p>
              <div className="form-group">
                <input
                  type="email"
                  className={
                    !forgotPassword.errors.email.valid
                      ? "form-control borderRed"
                      : "form-control"
                  }
                  name="email"
                  id="email"
                  placeholder={translation.emailPlaceHolder}
                  onChange={this.handleTextChange}
                  aria-label="Email"
                />
                {!forgotPassword.errors.email.valid ? (
                  <span className="error">{translation.required}</span>
                ) : null}
              </div>
              <button
                type="submit"
                className="btn btn-default round_btn form_black_btn"
              >
                {translation.submit}
              </button>
            </form>
            {!apiErrorDetail.success ? (
              <div className="form-error">
                <span className="error">{apiErrorDetail.message}</span>
              </div>
            ) : null}
          </div>
        </div>
        {hideLoginFooter && (
          <div className="login_footer">
            <p>
              {" "}
              {loginTranslation.dontHaveAccount}{" "}
              <span onClick={toggleSignInTab}>{loginTranslation.join}</span>
            </p>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  forgotPassword: state.homepage.forgotPassword,
  apiErrorDetail: state.homepage.APIErrorResponse,
  language: state.common.language,
  commonSettings: state.common.settings
});
const mapDispatchToProps = dispatch => ({
  showLoginModal: bindActionCreators(showModal, dispatch),
  sendEmailLink(data) {
    dispatch(resetPasswordEmailLink(data));
  },
  handleTextChange(name, value, modelName) {
    dispatch(handleTextChange(name, value, modelName));
  },
  resetAPIError() {
    dispatch(resetAPIError());
  },
  closeModal() {
    dispatch(hideModal());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
