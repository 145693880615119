import { isEmpty } from "lodash";
import GAExtensions from "../extensions";

class BannerService extends GAExtensions {
  PROMOTION_FIELDS = [
    "creative_name",
    "creative_slot",
    "promotion_id",
    "promotion_name"
  ];
  trackViewBanner = payload => {
    this.pushToDataLayer({
      event: "view_banner",
      ...payload
    });
  };

  trackBannerClick = payload => {
    this.pushToDataLayer({
      event: "select_banner",
      ...payload
    });
  };

  trackPromotionBannerEvent = (event, attributes) => {
    const payload = {};
    attributes.map(({ key, value }) => {
      if (this.PROMOTION_FIELDS.includes(key)) {
        payload[key] = value;
      }
    });
    if (!isEmpty(payload)) {
      this.pushToDataLayer({
        event,
        ecommerce: {
          ...payload
        }
      });
    }
  };
  trackViewPromotionBanner = attributes => {
    this.trackPromotionBannerEvent("view_promotion", attributes);
  };
  trackPromotionBannerClick = attributes => {
    this.trackPromotionBannerEvent("select_promotion", attributes);
  };
}

export default BannerService;
