export const trackOrderTranslation = {
  guestTrackText: "Are you a guest user? Track your order here.",
  tractYourOrder: "Track Your Order",
  alreadyHaveAnAccount: "Already have an account?",
  signIn: "Sign In",
  orderId: "Order ID",
  totalAmount: "Total Amount",
  orderPlacedOn: "Order placed on",
  deliveryAddress: "Delivery Address",
  telephone: "Tel",
  eyeOnOrder: "We’ve got an eye on your order - all the way to your door.",
  trackOrder: "Track Order",
  emailAddress: "Email Address",
  orderNumber: "Order Number",
  IncOfVat: "Incl. of VAT",
  orderCancelled: "Order Cancelled",
  trackAnotherOrder: "Track another order? Enter your order ID & Email below.",
  noRecordFound: "No record found, please check the details you have entered.",
  trackShipment: "Track Shipment",
  trackNumber: "Tracking Number"
};
