import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { connect } from "react-redux";
import {
  getRangeObject,
  getTrabsformedSubfacet
} from "../../../util/itemsFilter";
import { isServer, getSelectedFiltersWithItems, isMobile } from "../../../util";
import FilterContent from "../filter-content";

const FilterHandle = ({
  item,
  translation,
  isToggle,
  filterToggled,
  handleFilterToggle,
  className,
  setHierarchy,
  sizeFilterStyle,
  setSearchInit,
  currency,
  handleSizeFilter,
  handleFilterItemChecked,
  intialPriceMaxvalue,
  updatedRangeObject,
  products,
  isShouldShowTextForFilters,
  clearRangeOnly
}) => {
  let selectedFilter = "";

  let categSelectedFilter = "";
  const hckeys = [
    "hierarchicalCategories.lvl0",
    "hierarchicalCategories.lvl1",
    "hierarchicalCategories.lvl2"
  ];

  const subFacet = products.facets[item];
  const selectedFacets = get(
    products,
    ["selectedFacets", item],
    products[item]
  );
  const selectedFacetsHc = get(products, "selectedFacets", products);
  const selectedFacetsLength = getSelectedFiltersWithItems(
    products,
    item
  ).length;
  const range = products.range;
  const convertedPriceRange = products.range && getRangeObject(products.range);

  const selFacetsKey =
    selectedFacetsHc &&
    Object.keys(selectedFacetsHc).length &&
    Object.keys(selectedFacetsHc).filter(
      key => key === hckeys[2] || key === hckeys[1] || key === hckeys[0]
    );

  const responsiveView =
    !isServer && isMobile.any() && isShouldShowTextForFilters;

  if (selectedFacets && responsiveView) {
    selectedFilter = getSelectedFiltersWithItems(products, item).map(facet =>
      getTrabsformedSubfacet(facet)
    );
  }

  if (selFacetsKey && selFacetsKey.length && responsiveView) {
    const deepestKey = selFacetsKey[selFacetsKey.length - 1];
    categSelectedFilter = selectedFacetsHc[deepestKey] || null;
  }

  const getUnique = list =>
    list.filter((item, i, ar) => ar.indexOf(item) === i);

  return (
    <h4
      className={cn(
        {
          size_filter:
            isToggle &&
            filterToggled === item &&
            item === "sizeHierarchicalFilter.lvl2"
        },
        {
          filter_open: isToggle && filterToggled === item,
          filter_close_inner: !(isToggle && filterToggled === item)
        },
        {
          items_selected: selectedFacetsLength
        }
      )}
      onClick={e => handleFilterToggle(item, e, "size")}
    >
      {item !== "priceBy" ? (
        <span>
          {`${translation[item]}`}
          {Boolean(selectedFacetsLength) && (
            <strong
              className={cn({ hideOnMobile: translation[item] === "Category" })}
            >
              <b>[</b>
              {(selectedFilter && getUnique(selectedFilter).toString()) ||
                selectedFacetsLength}
              <b>]</b>
            </strong>
          )}
          {!isEmpty(selFacetsKey) && translation[item] === "Category" && (
            <strong>{categSelectedFilter}</strong>
          )}
        </span>
      ) : (
        <span>
          {`${translation[item]}`}{" "}
          <strong>{`(${currency} ${convertedPriceRange.min}-${convertedPriceRange.max})`}</strong>
        </span>
      )}
      <span className="filter_count" />
      {isToggle && filterToggled === item && (
        <FilterContent
          facetName={item}
          subFacet={subFacet}
          handleFilterToggle={handleFilterToggle}
          range={range}
          className={className}
          translation={translation}
          setHierarchy={setHierarchy}
          sizeFilterStyle={sizeFilterStyle}
          setSearchInit={setSearchInit}
          handleSizeFilter={handleSizeFilter}
          handleFilterItemChecked={handleFilterItemChecked}
          intialPriceMaxvalue={intialPriceMaxvalue}
          clearRangeOnly={clearRangeOnly}
        />
      )}
    </h4>
  );
};

const mapStateToProps = reduxState => ({
  products: reduxState.productListingReducer.products,
  currency:
    reduxState.common.settings && reduxState.common.settings.currencyCode
});

export default connect(mapStateToProps)(FilterHandle);
