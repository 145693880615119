import { getBaseUrl } from "..";

/**
 * Get the decoded object from the given token
 * @param {object} item
 * @returns {boolean}
 */
export const isAppOnlyAvailableItem = item => Boolean(item.isAppOnlyPurchase);

export const getProductDetailPageLink = (props, productTitle, item) => {
  let url = getBaseUrl(props);

  if (!isAppOnlyAvailableItem(item)) {
    url = url.concat(`/${productTitle}/${item.id}/p/`);
  }
  return url;
};
