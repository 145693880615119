const translation = {
  success: {
    title: "You’re all set!",
    description:
      "You have successfully added items from the Smart Mirror to your cart.",
    moreSteps: "Few more steps to complete your purchase.",
    cartButton: "go to cart"
  },
  processing: "We are processing your request..."
};

export default translation;
