export const preferencesTranslation = {
  setPreference: "Set Preferences",
  selectRegionCurrency: "Select your region and currency",
  freeShipping: "Free Shipping for orders above",
  hassleFree: "Hassle Free Return",
  shopIn: "Shop In",
  currency: "Currency",
  apply: "Apply",
  shopOverMultipleBrands: "Shop Over 3000 brands.",
  offOnRetailPrice: "Upto 80% OFF on original retail prices.",
  currentCountry: "You're shopping from",
  canShopFrom: "You can also shop from",
  AED: "AED",
  BHD: "BHD",
  KWD: "KWD",
  OMR: "OMR",
  SAR: "SAR",
  weAccept: "We Accept",
  youAreShoppingFrom: "You are shopping from",
  countryMOTO:
    "Choose another country or region to see content specific to your location and shop online.",
  continue: "Continue",
  close: "Close",
  downloadMobile: "Download our mobile app",
  searchPlaceHolder: "Search for items or brands",
  matchingKeywords: "Matching Keywords",
  recommendedProducts: "Recommended products",
  recent: "Recent",
  trending: "Trending",
  new: "NEW!",
  visualSearch: "Visual Search",
  takePhotoOrUpload: "Take a photo or upload an image to find similar items.",
  freeShippingForAllTheOrders: "FREE SHIPPING on all the orders"
};
