import React, { Component, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { isServer } from "../../../util";

class StoreLocatorHeaderWeb extends Component {
  state = { showDropDown: false };

  componentDidMount() {
    !isServer &&
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    !isServer &&
      document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setLeftWrapperRef = node => (this.leftWrapperRef = node);
  setRightWrapperRef = node => (this.rightWrapperRef = node);

  setBoxRef = node => (this.boxRef = node);

  changeShowDropDown = () =>
    this.setState({ showDropDown: !this.state.showDropDown });

  handleClickOutside = event => {
    if (
      this.leftWrapperRef &&
      !this.leftWrapperRef.contains(event.target) &&
      this.rightWrapperRef &&
      !this.rightWrapperRef.contains(event.target)
    ) {
      if (!(this.boxRef && this.boxRef.contains(event.target))) {
        this.changeShowDropDown();
      }
    }
  };

  handleSelectedShop = selectedShop => {
    this.props.changeSelectedShop(selectedShop);
    this.changeShowDropDown();
  };

  render() {
    const { showDropDown } = this.state;
    const {
      useCurrentLocation,
      storeLocatorTranslation,
      storeCountry,
      changeSelectedCountry,
      cityList,
      selectedCountry,
      selectedShop
    } = this.props;
    const _totalStoresCount =
      cityList &&
      cityList.map(key => key["shops"].length).reduce((a, b) => a + b, 0);
    return (
      <div className={`store-header-conatiner`}>
        <div className={`store-header-wrapper`}>
          <h2 className="store-locator-heading">
            {storeLocatorTranslation.storeHeader}
          </h2>
          <div className={`store-locator-dropdown-box-wrapper`}>
            <div
              className={`store-locator-dropdown-box`}
              ref={this.setBoxRef}
              onClick={this.changeShowDropDown}
            >
              <div className={`inner-content`}>
                <span className="mainSelected">
                  {`${selectedShop.countryName || ""}${
                    selectedShop.countryName ? "," : ""
                  } ${selectedShop.name || ""}`}
                </span>
                {_totalStoresCount > 1 && (
                  <span className="otherLocation">
                    {" "}
                    {_totalStoresCount - 1} Other store location in{" "}
                    <em>{selectedCountry}</em>
                  </span>
                )}
              </div>
            </div>
            <div className={`store-locator-button-wrapper`}>
              <button onClick={useCurrentLocation}>
                <span className="current-location-icon" />
                {storeLocatorTranslation.useMyCurrentLocation}
              </button>
            </div>
          </div>
          {showDropDown && (
            <div className={`store-locator-dropdown-wrapper`}>
              <ul className={`left-col`} ref={this.setLeftWrapperRef}>
                <Scrollbars autoHeight autoHeightMax={439}>
                  {storeCountry &&
                    storeCountry.map(item => (
                      <li
                        key={item}
                        className={`city-name ${
                          item === selectedCountry ? "active" : ""
                        } `}
                        onClick={() => changeSelectedCountry(item)}
                      >
                        {item}
                      </li>
                    ))}
                </Scrollbars>
              </ul>
              <ul className={`right-col`} ref={this.setRightWrapperRef}>
                <Scrollbars autoHeight autoHeightMax={439}>
                  {cityList &&
                    cityList.map((item, index) => {
                      return (
                        <Fragment key={item.city || index}>
                          <li className={`city-name`}>{item.city}</li>
                          {item.shops &&
                            !!item.shops.length &&
                            item.shops.map((shopItem, shopIndex) => (
                              <li
                                onClick={() =>
                                  this.handleSelectedShop({
                                    ...shopItem,
                                    city: item.city
                                  })
                                }
                                key={shopItem.shopId || shopIndex}
                              >
                                {shopItem.name}
                              </li>
                            ))}
                        </Fragment>
                      );
                    })}
                </Scrollbars>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StoreLocatorHeaderWeb;
