import { BEConfig } from "../../config/env";
import { DEFAULT_STORE_ID, LOADER_TYPES } from "../../constants";
import {
  CLEAR_BANNERS_SECTIONS,
  CLEAR_META,
  CLEAR_REDIRECTION_DATA,
  HOME_PAGE_DATA,
  HOME_PAGE_SECTION_DATA,
  REDIRECTION_DATA,
  SET_ANALITIC_DATA,
  SET_META,
  SET_PAGE_TYPE,
  SET_STORE_LIST
} from "../../constants/page";
import { CatalogService } from "../../services";
import { urlFilterMap } from "../../util";
import { getPrevModeStorage } from "../../util/browserStorage";
import { handleFetchError } from "../../util/errorHandler";
import { fetchWebApi } from "../../webapis/apiResource";
import {
  hideLoadingIndicator,
  hidePageLoader,
  showLoadingIndicator,
  showPageLoader
} from "./common.action";

export const homePageData = content => ({ type: HOME_PAGE_DATA, content });

export const homePageSectionData = (sectionName, content) => ({
  type: HOME_PAGE_SECTION_DATA,
  sectionName,
  content
});

export const clearBannersSectionsData = () => ({
  type: CLEAR_BANNERS_SECTIONS
});

export const fetchHomepageSection = (
  language,
  countryId,
  storeId,
  section,
  myCookies
) => {
  language = language.includes("ar") ? "arabic" : "english";
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.homePageHandle}${BEConfig.catalogApi.getSection}?countryId=${countryId}&storeId=${storeId}&language=${language}&section=${section}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      null,
      apiURL,
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage(myCookies)
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(homePageSectionData(section, response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const redirectionData = data => ({ type: REDIRECTION_DATA, data });

export const clearRedirectionData = data => ({
  type: CLEAR_REDIRECTION_DATA,
  data
});

// Temporary solution for shopByStore
export const setStoreList = (lang, data) => {
  const storeList = {
    enable: true,
    [lang]: {
      mainTitle: "Shop by Store",
      storeList: data
    },
    type: "shopByStore"
  };

  return {
    type: SET_STORE_LIST,
    storeList
  };
};

export const setMeta = meta => ({
  type: SET_META,
  meta
});

export const setAnaliticData = analyticData => ({
  type: SET_ANALITIC_DATA,
  analyticData
});

export const setPageType = pageType => ({
  type: SET_PAGE_TYPE,
  pageType
});

export const clearMeta = () => ({ type: CLEAR_META });

export const getPageApi =
  (pageName, lang, countryId, storeId, ipcountry, cookies) =>
  async dispatch => {
    const language = lang.includes("ar") ? "arabic" : "english";
    const isHomePageCall = pageName === "/";

    if (isHomePageCall) {
      dispatch(clearMeta());
    }

    dispatch(
      showPageLoader({
        type: LOADER_TYPES.DOTS,
        fullscreen: true,
        size: 16,
        qty: 3,
        background: "rgba(251, 251, 253, 0.4)"
      })
    );
    dispatch(showLoadingIndicator());

    try {
      const response = await CatalogService.getPageData(
        {
          url: urlFilterMap(pageName).replace(/\+/g, "%2B"),
          countryId,
          language,
          storeId: storeId || DEFAULT_STORE_ID,
          cookies
        },
        { ipcountry }
      );

      const meta = response?.meta || response?.data?.meta;
      const analyticData = {
        variants: response?.data?.variants,
        analytics: response?.analytics
      };

      if (meta) {
        dispatch(setMeta(meta));
      }

      dispatch(setAnaliticData(analyticData));

      dispatch(hideLoadingIndicator());

      if (isHomePageCall) {
        const data = response?.data;
        const content = {
          sections: data?.content,
          countryId: data?.countryId,
          storeId: data?.storeId || DEFAULT_STORE_ID,
          title: data?.title || ""
        };
        dispatch(homePageData(content));
      } else {
        dispatch(redirectionData(response));
      }

      return response;
    } catch (error) {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(
        hidePageLoader({
          type: LOADER_TYPES.DOTS,
          fullscreen: true,
          size: 16,
          qty: 3
        })
      );
    }
  };
