import React from "react";
import Scrollbars from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import classnames from "classnames";
import { getQuantityList } from "../../../util/add-to-cart";
import CustomDropdown from "../../genericDropDown";
import SlideBottomToTop from "../../slide-bottom-to-top";
import { getDataAsObject } from "../../../util/common";

const getQtyList = TEMP_QTY =>
  TEMP_QTY > 10
    ? [...new Array(10).keys()].map((x, i) => {
        if (i === 9) return "10+";
        return x + 1;
      })
    : [...new Array(TEMP_QTY || 1).keys()].map(x => x + 1);

const SizeQtyBlock = ({
  translation,
  item,
  onQtyChange,
  size,
  sizeType,
  showQtyTextBox,
  selectedQty = 1,
  errShowQtyTextBox,
  onQtyTextChange,
  handleTextQtySubmit,
  pstock,
  handleQtyModal,
  openQuantity,
  handleMultipleSizesModal,
  onBlurChange,
  itemQuantityChanged,
  remainingItemStock,
  isMultisize,
  allowedQuantity
}) => {
  let _currentSizeTypeStrings = [],
    currentSizes = [],
    productInfo;
  if (item) {
    currentSizes = item.map(size => ({
      ...size,
      sizeinfo: (size.sizeinfo && getDataAsObject(size.sizeinfo)) || null,
      sizeType
    }));

    _currentSizeTypeStrings = currentSizes
      .map(size => {
        try {
          let currentSizeType = size.sizeinfo[0].sizeGroupOptions.filter(
            sizeGroupOption => sizeGroupOption.sizeGroupName3 === sizeType
          )[0];

          if (isMultisize && currentSizeType)
            return `${currentSizeType.sizes.trim()} ${
              currentSizeType.sizeGroupName3
            }`;
          return size.sizeLabel;
        } catch (e) {
          return size.sizeLabel;
        }
      })
      .filter(str => str);
    productInfo = currentSizes[0];
    if (isMultisize) {
      productInfo = currentSizes.find(currSize => {
        try {
          let resultSize = currSize.sizeinfo[0].sizeGroupOptions.filter(
            sizeOption => sizeOption.sizes.trim() === size.split(" ")[0]
          );

          return Boolean(resultSize.length);
        } catch (e) {
          return false;
        }
      });
    }
  }

  const selectedQuantity = getQtyList(allowedQuantity).length ? selectedQty : 1;

  const toggle = (e, val) => {
    e && e.stopPropagation();
    handleMultipleSizesModal(val);
  };

  let _remainingItemStock = [];

  for (let i = 1; i <= remainingItemStock; i++) {
    _remainingItemStock.push(i);
  }

  const quantitiesData =
    !itemQuantityChanged &&
    (_remainingItemStock && _remainingItemStock.length
      ? getQtyList(remainingItemStock)
      : getQtyList(allowedQuantity));

  const wholeQuantitiesDataArray = getQuantityList(allowedQuantity);

  return (
    <div className="size_qty_wrapper">
      <div className="cart_item_qty">
        <div className="item_qty">{translation && translation.quantity} :</div>
        <div
          className={classnames("dropdown_wrapper cart_Qty", {
            itemQtyChanged: itemQuantityChanged
          })}
        >
          {!showQtyTextBox ? (
            <CustomDropdown
              data={quantitiesData}
              selectedItem={selectedQuantity}
              handleChange={
                !itemQuantityChanged &&
                (quantity => onQtyChange(quantity, allowedQuantity))
              }
              defaultSelectValue={selectedQuantity}
              containerClass="quantity_dropdown"
              scrollbarMaxHeight={270}
            />
          ) : (
            <input
              type="number"
              value={selectedQty}
              className={classnames("dropdown-custom-text", {
                borderRed: errShowQtyTextBox
              })}
              onChange={
                !itemQuantityChanged &&
                (e => onQtyTextChange(e.target.value, allowedQuantity))
              }
              onBlur={onBlurChange}
              maxLength="3"
              aria-label="Quantity"
            />
          )}
        </div>
      </div>

      <div className="quantitySelectMobile">
        <div
          className="cartQuantity"
          onClick={!itemQuantityChanged ? () => handleQtyModal(true) : null}
        >
          <div className="item_qty">
            {translation && translation.quantity} :
          </div>
          <span>{selectedQuantity}</span>
        </div>
      </div>
      <div
        className={`slideTop quantityContainer ${
          openQuantity ? "slideUp" : "slideBack"
        }`}
      >
        <div className="backOverlay" onClick={() => handleQtyModal(false)} />
        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={285}>
          <SlideBottomToTop>
            <ul className="dropdown_content_container">
              <li>{translation.quant}</li>
              {wholeQuantitiesDataArray.map(item => (
                <li
                  className={
                    typeof selectedQty === "object" &&
                    selectedQty &&
                    selectedQty.value === item.value
                      ? "selected_value"
                      : selectedQty === item
                      ? "selected_value"
                      : null
                  }
                  key={item.key ? item.key : item}
                  onClick={() => {
                    onQtyChange(item.key ? item.key : item, allowedQuantity);
                    handleQtyModal(false);
                  }}
                >
                  {item.value ? ReactHtmlParser(item.value) : item}
                </li>
              ))}
            </ul>
          </SlideBottomToTop>
        </Scrollbars>
      </div>
      {!!_currentSizeTypeStrings && !!_currentSizeTypeStrings.length && (
        <div
          className="item_size"
          onClick={e => {
            toggle(e, true);
          }}
        >
          <span>{translation && translation.size} : </span>
          <span className="selectedSize">{size}</span>
        </div>
      )}
      {!errShowQtyTextBox && showQtyTextBox && selectedQty && (
        <button
          className="add_to_btn"
          onClick={() => handleTextQtySubmit(+selectedQty, allowedQuantity)}
        >
          {translation.save}
        </button>
      )}
      {errShowQtyTextBox && <span className="error">{errShowQtyTextBox}</span>}
    </div>
  );
};

export default SizeQtyBlock;
