import React, { Component } from "react";
import "swiper/css/swiper.css";

import GateKeeper from "../app/gateKeeper";
import { UserAgentManager } from "./util";
import "./css/main.scss";
class App extends Component {
  render() {
    UserAgentManager.setView(
      typeof window !== "undefined" && window.__user_agent__
        ? window.__user_agent__
        : this.props.userAgent
    );
    return (
      <div id="app">
        {/*Blank listener to trigger Outside click in Ipad*/}
        <div id="content" onClick={() => {}}>
          <GateKeeper />
        </div>
      </div>
    );
  }
}

export default App;
