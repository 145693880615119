import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCountryShort, selectLanguageSlug } from "../../util/selectors";
import { addScriptToBody } from "../../util/dom";
import { isProduction } from "../../util";

export const TAMARA_BASE_URL = isProduction()
  ? "https://cdn.tamara.co"
  : "https://cdn-sandbox.tamara.co";

const tamaraWidgetSrc = `${TAMARA_BASE_URL}/widget-v2/tamara-widget.js`;

export const TAMARA_PUBLIC_KEY = process.env.REACT_APP_TAMARA_WIDGET_PUBLIC_KEY;

export const TAMARA_WIDGET_TYPES = {
  CUSTOM_TEMPLATE: 0,
  KNOW_MORE_BADGE_ONLY: 1,
  SPLIT_AMOUNT_PRODUCT_PAGE: 2,
  SPLIT_AMOUNT_PRODUCT_PAGE: 3,
  TAMARA_BADGE_ONLY: 4
};

const TamaraWidget = ({
  amount,
  type = TAMARA_WIDGET_TYPES.TAMARA_BADGE_ONLY
}) => {
  const widgetProps = {
    "inline-type": type,
    ...(amount && { amount })
  };

  const language = useSelector(selectLanguageSlug);
  const countryCode = useSelector(selectCountryShort);

  useEffect(() => {
    window.tamaraWidgetConfig = {
      lang: language,
      country: countryCode,
      publicKey: TAMARA_PUBLIC_KEY
    };
    addScriptToBody(tamaraWidgetSrc, null, true);
  }, []);

  useEffect(() => {
    window.tamaraWidgetConfig.lang = [language];
    window.tamaraWidgetConfig.country = [countryCode];

    window.TamaraWidgetV2.refresh();
  }, [language, countryCode]);

  return <tamara-widget type="tamara-summary" {...widgetProps}></tamara-widget>;
};

export default TamaraWidget;
