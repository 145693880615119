import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleAppId, sentry_DSN } from "./app/config/app";

import { getPrevModeStorage } from "./app/util/browserStorage";

import createStore from "./app/redux/store";
import App from "./app/app";

const { store, history } = createStore();

const isPreviewMode = getPrevModeStorage();

const Application = (
  <Provider store={store}>
    <GoogleOAuthProvider clientId={googleAppId}>
      <BrowserRouter history={history}>
        <App userAgent={navigator.userAgent} />
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
);

Sentry.init({
  environment: process.env.REACT_APP_ENV || "development",
  dsn: sentry_DSN,
  beforeSend: function (event, hint) {
    if (hint && hint.originalException instanceof Event) {
      event.extra.isTrusted = hint.originalException.isTrusted;
      event.extra.detail = hint.originalException["detail"];
      event.extra.type = hint.originalException.type;
    }
    return event;
  }
});

const root = document.querySelector("#root");
const isServerSideRenderedMode = root.hasChildNodes() && !isPreviewMode;

if (isServerSideRenderedMode) {
  hydrate(Application, root);
} else {
  render(Application, root);
}

export { store, history };
