export const productListingSeed = {
  hits: [],
  nbHits: 0,
  page: 0,
  nbPages: 0,
  hitsPerPage: 0,
  processingTimeMS: 0,
  facets: {
    catname: {
      Men: { value: 0 }
    },
    priceBy: {
      "AED 25 to AED 50": { value: 0 }
    },
    brandName: {
      Mohito: { value: 0 }
    },
    subcatname: {
      "Tops & Tees": { value: 0 }
    },
    store: {
      "Brands For Less": { value: 0 }
    },
    overlayTagLabel: { NEW: { value: 0 } },
    "attributes.Fabrics": {
      "100% Cotton": { value: 0 }
    },
    "attributes.Occasion": {
      Casual: { value: 0 }
    },
    "attributes.Ideal For": {
      Women: { value: 0 }
    },
    "attributes.Materials": {
      Plastic: { value: 0 }
    },
    "attributes.Color Family": {
      Black: { value: 0 }
    },
    "colors.colorSizeOptions.sizeLabel": {
      11: { value: 0 }
    }
  },
  facets_stats: {
    "colors.colorSizeOptions.sizeLabel": { min: 4, max: 52, avg: 35, sum: 4716 }
  },
  exhaustiveFacetsCount: true,
  exhaustiveNbHits: true,
  query: "",
  params:
    "filters=currentPrice%3E0%20AND%20currentPrice%3C%3D0&facetFilters=%5B%5D&facets=%5B%22catname%22%2C%22subcatname%22%2C%22brandName%22%2C%22priceBy%22%2C%22colors.colorSizeOptions.sizeLabel%22%2C%22attributes.Fabrics%22%2C%22attributes.Occasion%22%2C%22attributes.Ideal%20For%22%2C%22attributes.Materials%22%2C%22attributes.Color%20Family%22%2C%22overlayTagLabel%22%5D",
  range: { min: "0", max: "0" },
  selectedFacets: { min: ["0"], max: ["0"] }
};

export const emptyProductSeed = {
  "Loading...": { value: 4 },
  "Loading... ": { value: 5 },
  "Loading...  ": { value: 6 },
  "Loading...   ": { value: 7 },
  "Loading...    ": { value: 8 }
};
