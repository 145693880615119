import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { AnalyticService } from "../../../services";
import useOnScrollHandling from "../../../custom-hooks/intersection-observer";
import {
  selectAuthReducer,
  selectCountrySlug,
  selectDeviceType,
  selectLanguage,
  selectPageAnalitic
} from "../../../util/selectors";
import { isServer } from "../../../redux/store";
import { getDeviceType } from "../../../util";
import { GAService } from "../../../services/GA-service";

const BannerIntersectionObserver = ({ data, children }) => {
  const bannerRef = useRef(null);

  const { isIntersecting } = useOnScrollHandling(bannerRef, {
    threshold: 0.75,
    constantTracking: true
  });

  const { imageListData = {}, ...analyticsData } = data;

  const countrySlug = useSelector(state => selectCountrySlug(state));
  const deviceTypeSSR = useSelector(state => selectDeviceType(state));
  const deviceType = deviceTypeSSR || getDeviceType();
  const language = useSelector(selectLanguage);
  const { customerId } = useSelector(selectAuthReducer);
  const analyticData = useSelector(selectPageAnalitic);
  const dataForAnalytic = {
    ...analyticsData,
    country: countrySlug.toUpperCase(),
    pageUrl: !isServer && window.location.href,
    device: deviceType?.toUpperCase(),
    language
  };

  useEffect(() => {
    if (isIntersecting) {
      AnalyticService.banner.pushGaGTMProductImpressions(dataForAnalytic);
      if (!isEmpty(imageListData)) {
        if (imageListData.bannerId && imageListData.bannerTitle) {
          GAService.banner.trackViewBanner({
            banner_id: imageListData.bannerId,
            banner_name: imageListData.bannerTitle
          });
        }
        if (imageListData?.attributes) {
          const payload = Array.isArray(imageListData?.attributes)
            ? imageListData?.attributes
            : [];
          if (Boolean(payload.length)) {
            GAService.banner.trackViewPromotionBanner(payload);
          }
        }
      }
      if (analyticData.analytics?.variant) {
        AnalyticService.banner.pushStormlyBannerImpressions(
          dataForAnalytic,
          analyticData.analytics?.variant,
          customerId
        );
      }
    }
  }, [isIntersecting]);

  const handleBannerClick = () => {
    if (!isEmpty(imageListData)) {
      if (imageListData.bannerId) {
        GAService.banner.trackBannerClick({
          banner_id: imageListData.bannerId,
          banner_name: imageListData.bannerTitle || ""
        });
      }
      if (imageListData?.attributes) {
        const payload = Array.isArray(imageListData?.attributes)
          ? imageListData?.attributes
          : [];
        if (Boolean(payload.length)) {
          GAService.banner.trackPromotionBannerClick(payload);
        }
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%"
      }}
      ref={bannerRef}
      onClick={handleBannerClick}
    >
      {children}
    </div>
  );
};

export default BannerIntersectionObserver;
