import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import cn from "classnames";
import {
  toggleQuickFilerModal,
  setQuickFilterStepPath,
  applySelectedCategories,
  resetQuickFilterSelection,
  getSelectedProductsPage
} from "../../../../redux/actions/quickFilter.action";
import { getBaseUrl } from "../../../../util";
import { LANGUAGE_ROUTE_KEY } from "../../../../constants";

export const QuickFilterModalFooter = ({
  activeTranslation,
  setSelectedOption,
  stepWithMultiSelection,
  selectedOption,
  isMob,
  language,
  quickFilterStep,
  selectedCategories,
  maxStepBySelectedCategory,
  selectedSizes,
  onNextClick,
  onBackClick
}) => {
  const history = useHistory();
  const { settings: commonSettings } = useSelector(state => state.common);
  const dispatch = useDispatch();

  const nextButtonIsDisabled = quickFilterStep
    ? (quickFilterStep < stepWithMultiSelection && !selectedOption) ||
      (quickFilterStep === stepWithMultiSelection &&
        !selectedCategories.length) ||
      (quickFilterStep === maxStepBySelectedCategory && !selectedSizes.length)
    : isMob && !selectedOption;

  const goToSelectedProductsPage = () => {
    dispatch(toggleQuickFilerModal());
    dispatch(
      getSelectedProductsPage(history, getBaseUrl({ language, commonSettings }))
    );
    dispatch(resetQuickFilterSelection());
  };

  const nextBtnClickHandler = () => {
    if (onNextClick) {
      onNextClick();
    }
    switch (quickFilterStep) {
      case stepWithMultiSelection:
        dispatch(applySelectedCategories());
        break;
      case maxStepBySelectedCategory:
        goToSelectedProductsPage();
        break;
      default:
        setSelectedOption(null);
        dispatch(setQuickFilterStepPath(selectedOption));
    }
  };

  const backBtnClickHandler = () => {
    if (onBackClick) {
      onBackClick();
    }
    setSelectedOption(null);
    dispatch(setQuickFilterStepPath(-1));
  };

  return (
    <div
      className={cn("buttons-container", {
        arabic: language === LANGUAGE_ROUTE_KEY.arabic
      })}
    >
      {!!quickFilterStep && (
        <button
          className="btn step-btn back-btn d-block"
          onClick={backBtnClickHandler}
        >
          {activeTranslation.back}
        </button>
      )}
      <button
        className="btn step-btn next-btn d-block"
        onClick={nextBtnClickHandler}
        disabled={nextButtonIsDisabled}
      >
        {activeTranslation.next}
      </button>
    </div>
  );
};
