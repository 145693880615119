import jwt_decode from "jwt-decode";

/**
 * Get the decoded object from the given token
 * @param {string} token
 * @returns {object}
 */
export const getDecodedJWT = token => {
  return jwt_decode(token);
};
