import React, { Component } from "react";
import classnames from "classnames";
import ProductSize from "../product-size";
import { TabContent, TabPane, NavItem, NavLink } from "reactstrap";

class ProductMultiSize extends Component {
  state = { activeTab: null };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  componentDidUpdate() {
    const { firstSizeType } = this.props;
    const { activeTab } = this.state;

    if (!activeTab && firstSizeType) {
      this.setState({
        activeTab: firstSizeType
      });
    }
  }

  render() {
    const {
      sizeTypes,
      cartItems,
      id,
      onMultiSizeChange,
      selectedSize,
      language,
      showStockMobile,
      showStock,
      translation,
      handleMultipleSizesModal,
      items,
      selectedQty,
      internationalSizes,
      presentWithoutQuantity,
      handleAddToCart,
      allowedQuantity,
      defaultAddToCart,
      handleChange,
      productSizeExtraData
    } = this.props;

    const { activeTab } = this.state;

    return (
      <div>
        <ul className="multiSizeTabs">
          {sizeTypes.map(sizeType => (
            <NavItem key={sizeType}>
              <NavLink
                className={classnames({
                  active: activeTab === sizeType
                })}
                onClick={() => {
                  this.toggle(sizeType);
                }}
              >
                {sizeType}
              </NavLink>
            </NavItem>
          ))}
        </ul>
        <div className="multiSizeTabsContent">
          <TabContent activeTab={activeTab}>
            {sizeTypes.map((sizeType, index) => (
              <TabPane tabId={sizeType} key={sizeType}>
                <ProductSize
                  cartItems={cartItems}
                  id={id}
                  colorSizeOption={internationalSizes[sizeType]}
                  onSizeChange={(...args) => {
                    handleChange(sizeType);
                    onMultiSizeChange(...args, sizeType);
                  }}
                  selectedSize={selectedSize}
                  language={language}
                  showStockMobile={showStockMobile}
                  showStock={showStock}
                  translation={translation}
                  handleMultipleSizesModal={handleMultipleSizesModal}
                  item={items}
                  selectedQty={selectedQty}
                  presentWithoutQuantity={presentWithoutQuantity}
                  firstSizeType={sizeType}
                  handleAddToCart={handleAddToCart}
                  allowedQuantity={allowedQuantity}
                  defaultAddToCart={defaultAddToCart}
                  productSizeExtraData={productSizeExtraData}
                />
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    );
  }
}

export default ProductMultiSize;
