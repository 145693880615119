import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import cn from "classnames";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import {
  emailValidationRegExp,
  SIGNUP_CODES_FOR_MODAL,
  SIGNUP_API_CODE_NAMES
} from "../../constants";
import {
  selectRouteSlug,
  selectHomePageApiErrorData,
  selectRegistrationDataStatus,
  selectSocialLoginFlow
} from "../../util/selectors";
import {
  daysInMonth,
  years,
  months,
  transformBirthdayValue
} from "../../util/birthday";
import {
  saveUser,
  resetAPIError,
  getUserDataStatus,
  resetRegistrationDataStatus,
  setSocialLoginFlow
} from "../../redux/actions/homepage.action";
import IntlInput from "./../../components/intl-tel-input/intl-tel-input";
import MobileNumberModal from "./modal-mobile-verification/mobile-number-modal";
import CustomDropdown from "../genericDropDown";
import { GAService } from "../../services/GA-service";
import FormActivityHandler from "../formActionsHandler";

class SignUpModal extends Component {
  formikFormRef = React.createRef();
  formikPhoneInputRef = React.createRef();

  state = {
    isShowPassword: false,
    selectedDay: "",
    selectedMonth: "",
    selectedYear: "",
    lastFocusedField: ""
  };

  static initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    areaCode: "",
    gender: "",
    isAgeVerified: false,
    isNewsLetter: true
  };

  static schema = mobileLength =>
    Yup.object().shape({
      firstname: Yup.string()
        .min(1)
        .trim() // not working
        .required(),
      lastname: Yup.string(),
      email: Yup.string().trim().matches(emailValidationRegExp).required(),
      password: Yup.string().min(8).max(32).trim().required(),
      phone: Yup.string().min(mobileLength).max(mobileLength).required(),
      areaCode: Yup.string(),
      gender: Yup.string().required(),
      isAgeVerified: Yup.boolean().oneOf([true]),
      isNewsLetter: Yup.boolean()
    });

  toggleShowPassword = () =>
    this.setState(prevState => ({
      isShowPassword: !prevState.isShowPassword
    }));

  componentWillUnmount() {
    const { resetAPIError, resetRegistrationDataStatus, setSocialLoginFlow } =
      this.props;
    resetAPIError();
    resetRegistrationDataStatus();
    setSocialLoginFlow(true);
  }

  componentDidUpdate(prevProps) {
    const { language } = this.props;
    const { selectedMonth } = this.state;

    if (prevProps.countrySHORT !== this.props.countrySHORT) {
      this.formikFormRef.current.resetForm();
      this.setState({ isShowPassword: false });
    }
    if (prevProps.language !== language && selectedMonth) {
      const currentMonth = this.months.find(
        month => month[prevProps.language] === selectedMonth
      )[language];

      this.setState({ selectedMonth: currentMonth });
    }
  }

  getPhoneNumberParts = ({ phone, areaCode: phoneArea }) => {
    const { defaultAreaCode, mobileCountryCode } = this.props;
    const areaCode = phoneArea || get(defaultAreaCode, "[0]", "");
    return { mobileCountryCode, areaCode, phone };
  };

  getUserSignUpData = values => {
    const {
      firstname,
      lastname,
      email,
      password,
      gender,
      isNewsLetter,
      isAgeVerified
    } = values;

    const { countryId, signUp, language } = this.props;

    let birthday = "";

    const { selectedDay, selectedMonth, selectedYear } = this.state;
    if (selectedDay && selectedMonth && selectedYear) {
      const day = transformBirthdayValue(selectedDay);
      const month = (
        months.find(month => month[language] === selectedMonth) || {}
      ).number;

      birthday = `${selectedYear}-${month}-${day}`;
    }

    const { mobileCountryCode, areaCode, phone } =
      this.getPhoneNumberParts(values);

    const signUpTransformed = {
      ...signUp,
      firstname,
      lastname,
      email,
      password,
      phone: `${mobileCountryCode}${areaCode}${phone}`,
      areaCode,
      gender,
      newsLetter: isNewsLetter,
      dialCode: mobileCountryCode,
      countryId,
      isAgeVerified,
      errors: null,
      ...(birthday && { birthday })
    };
    return signUpTransformed;
  };

  checkRegistration = data => {
    const { getUserDataStatus } = this.props;
    getUserDataStatus(data);
  };

  focusOnPhoneInput = () => {
    if (this.formikPhoneInputRef.current) {
      //timeout for the hide modal animation done
      setTimeout(() => {
        this.formikPhoneInputRef.current.focus();
      }, 300);
    }
  };

  handleErrorOccuredInForm = errorObj => {
    GAService.auth.trackSignUpFormError(errorObj);
  };

  onSubmit = submitData => {
    submitData.firstname = submitData.firstname.trim();
    const { setSocialLoginFlow } = this.props;
    setSocialLoginFlow(false);
    this.checkRegistration(this.getUserSignUpData(submitData));
  };

  setLastFocusedField = e => {
    const lastFocusedField = typeof e === "string" ? e : e.currentTarget.name;
    this.setState({
      lastFocusedField
    });
  };

  render() {
    const {
      signUp,
      translation,
      apiErrorDetail,
      countryData,
      countrySHORT,
      mobileLocalNumberLength,
      routeSlug,
      registrationDataStatus,
      socialLoginFlow,
      language,
      myAccountTranslation
    } = this.props;
    const { isShowPassword, selectedDay, selectedMonth, selectedYear } =
      this.state;

    const errorMessage =
      !isEmpty(registrationDataStatus) &&
      registrationDataStatus.code &&
      !SIGNUP_CODES_FOR_MODAL[registrationDataStatus.code]
        ? registrationDataStatus.message
        : !apiErrorDetail.success && apiErrorDetail.message;

    return (
      <div>
        <Formik
          innerRef={this.formikFormRef}
          onSubmit={this.onSubmit}
          validationSchema={() => SignUpModal.schema(mobileLocalNumberLength)}
          initialValues={SignUpModal.initialValues}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            submitCount
          }) => {
            const isInputInvalid = valueKey =>
              Boolean(touched[valueKey] && errors && errors[valueKey]);

            const isShowGeneralError = () =>
              Object.entries(errors).length && submitCount;

            return (
              <>
                {!socialLoginFlow && (
                  <MobileNumberModal
                    userSignUpPhone={get(
                      this.getUserSignUpData(values),
                      "phone"
                    )}
                    data={this.getUserSignUpData(values)}
                    getPhoneNumberParts={this.getPhoneNumberParts}
                    focusOnPhoneInput={this.focusOnPhoneInput}
                  />
                )}
                <form onSubmit={handleSubmit} className="signUp">
                  <div className="form-group">
                    <input
                      type="text"
                      className={cn("form-control", {
                        borderRed: isInputInvalid("firstname")
                      })}
                      name="firstname"
                      placeholder={translation.firstName}
                      value={values.firstname}
                      onChange={handleChange}
                      aria-label="First name"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("firstname") && (
                      <span className="error">
                        {translation.enterFirstName}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      placeholder={translation.lastName}
                      value={values.lastname}
                      onChange={handleChange}
                      aria-label="Last name"
                      onFocus={this.setLastFocusedField}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className={cn("form-control", {
                        borderRed: isInputInvalid("email")
                      })}
                      name="email"
                      placeholder={translation.email}
                      value={values.email}
                      onChange={handleChange}
                      aria-label="Email"
                      onFocus={this.setLastFocusedField}
                    />
                    {isInputInvalid("email") && (
                      <span className="error">{translation.emailRequired}</span>
                    )}
                  </div>

                  <div className="form-group e-pwd">
                    <input
                      type={isShowPassword ? "text" : "password"}
                      className={cn("form-control", {
                        borderRed: isInputInvalid("password")
                      })}
                      name="password"
                      placeholder={translation.password}
                      value={values.password}
                      onChange={handleChange}
                      aria-label="Password"
                      onFocus={this.setLastFocusedField}
                    />
                    <span
                      className="show-span"
                      onClick={this.toggleShowPassword}
                    >
                      {isShowPassword
                        ? `${translation.hide}`
                        : `${translation.show}`}
                    </span>
                    {isInputInvalid("password") && (
                      <span className="error">
                        {translation.passwordRequired}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    {countrySHORT && (
                      <IntlInput
                        name={"phone"}
                        valid={!isInputInvalid("phone")}
                        phone={values.phone}
                        dialCode={signUp.dialCode}
                        areaCode={values.areaCode}
                        handleCountryChange={(e, phone) =>
                          setFieldValue("phone", phone)
                        }
                        onSelectArea={area => setFieldValue("areaCode", area)}
                        defaultCountry={countrySHORT}
                        mobileLocalNumberLength={mobileLocalNumberLength}
                        countryData={countryData}
                        forwardedRef={this.formikPhoneInputRef}
                        onFocus={this.setLastFocusedField}
                      />
                    )}
                    {isInputInvalid("phone") && (
                      <span className="error">{translation.phoneRequired}</span>
                    )}
                  </div>

                  <div className="radio_group flex">
                    <p>
                      <input
                        name="gender"
                        type="radio"
                        id="maleRadioInput"
                        className={cn("custom_radio", {
                          checked_radio: values.gender === "Male"
                        })}
                        value="Male"
                        onChange={handleChange}
                        onFocus={this.setLastFocusedField}
                      />
                      <label htmlFor="maleRadioInput">{translation.male}</label>
                    </p>
                    <p>
                      <input
                        name="gender"
                        type="radio"
                        id="femaleRadioInput"
                        className={cn("custom_radio", {
                          checked_radio: values.gender === "Female"
                        })}
                        value="Female"
                        onChange={handleChange}
                        onFocus={this.setLastFocusedField}
                      />
                      <label htmlFor="femaleRadioInput">
                        {translation.female}
                      </label>
                    </p>
                    {isInputInvalid("gender") && (
                      <span className="error">{translation.required}</span>
                    )}
                  </div>

                  <div className="birthday">
                    <h4 id="date-of-birth">
                      {myAccountTranslation.dateOfBirth}{" "}
                      <span>({myAccountTranslation.optional})</span>{" "}
                    </h4>
                    <p>{myAccountTranslation.mayGiveYouSurprise}</p>
                    <div className="selects-block">
                      <CustomDropdown
                        name="year"
                        data={years}
                        selectedItem={selectedYear || myAccountTranslation.year}
                        containerClass="location_dropdown year"
                        scrollbarMaxHeight={100}
                        handleChange={selectedYear =>
                          this.setState({ selectedYear })
                        }
                        onClickForEvent={this.setLastFocusedField}
                      />
                      <CustomDropdown
                        name="months"
                        data={map(months, language)}
                        selectedItem={
                          selectedMonth || myAccountTranslation.month
                        }
                        containerClass="location_dropdown month"
                        scrollbarMaxHeight={100}
                        handleChange={selectedMonth =>
                          this.setState({ selectedMonth })
                        }
                        onClickForEvent={this.setLastFocusedField}
                      />
                      <CustomDropdown
                        name="day"
                        data={daysInMonth}
                        selectedItem={selectedDay || myAccountTranslation.dd}
                        containerClass="location_dropdown day"
                        scrollbarMaxHeight={100}
                        handleChange={selectedDay =>
                          this.setState({ selectedDay })
                        }
                        onClickForEvent={this.setLastFocusedField}
                      />
                    </div>
                  </div>

                  <div className="form-group checkbox_wrap square_chkBox default_address age_verification">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="isAgeVerified"
                      checked={values.isAgeVerified}
                      id="isAgeVerified"
                      onChange={handleChange}
                      onFocus={this.setLastFocusedField}
                    />
                    <label className="checkbox_value" htmlFor="isAgeVerified">
                      {myAccountTranslation.verifiedBirthday}
                    </label>
                    <br />

                    {isInputInvalid("isAgeVerified") && (
                      <span className="error">{translation.required}</span>
                    )}
                  </div>

                  <div className="form-group checkbox_wrap square_chkBox default_address">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="isNewsLetter"
                      checked={values.isNewsLetter}
                      id="isNewsLetter"
                      onChange={handleChange}
                      onFocus={this.setLastFocusedField}
                    />
                    <label className="checkbox_value" htmlFor="isNewsLetter">
                      {myAccountTranslation.subscribeForNewsLetter}
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-default round_btn form_black_btn"
                    onClick={() => GAService.auth.trackJoinMethod()}
                  >
                    {translation.joinNow}
                  </button>
                  <div>
                    <span
                      className={
                        isShowGeneralError()
                          ? "error displayMessage"
                          : "hideMessage"
                      }
                    >
                      {translation.submitValidate}
                    </span>
                  </div>
                  <FormActivityHandler
                    formName={"sign up form"}
                    lastFocusedFormField={this.state.lastFocusedField}
                    onErrors={this.handleErrorOccuredInForm}
                  />
                </form>
              </>
            );
          }}
        </Formik>
        {errorMessage && (
          <div className="form-error">
            <span className="error">{errorMessage}</span>
            {registrationDataStatus.code ===
              SIGNUP_API_CODE_NAMES.EMAIL_ALREADY_EXISTS && (
              <span className="error">
                {". "}
                {translation.click}{" "}
                <Link
                  className="forgot_password"
                  to={`/${routeSlug}/forgot-password/`}
                >
                  {translation.here}
                </Link>{" "}
                {translation.toSetYourPassword}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

SignUpModal.propTypes = {
  createUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  signUp: state.homepage.signUp,
  countryId: state.common.settings && state.common.settings.countryId,
  mobileCountryCode:
    state.common.settings && state.common.settings.mobileCountryCode,
  defaultAreaCode:
    state.common.settings &&
    state.common.settings.mobileLocalCode &&
    state.common.settings.mobileLocalCode.split(","),
  language: state.common.language,
  countryData: state.common.countryData,
  apiErrorDetail: selectHomePageApiErrorData(state),
  countrySHORT: state.common.settings && state.common.settings.countrySHORT,
  mobileLocalNumberLength:
    state.common.settings && state.common.settings.mobileLocalNumberLength,
  routeSlug: selectRouteSlug(state),
  registrationDataStatus: selectRegistrationDataStatus(state),
  socialLoginFlow: selectSocialLoginFlow(state)
});

const mapDispatchToProps = {
  createUser: saveUser,
  resetAPIError,
  getUserDataStatus,
  resetRegistrationDataStatus,
  setSocialLoginFlow
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpModal)
);
