import React, { useEffect } from "react";
import classnames from "classnames";

const FilterLeftPane = ({ list, activeSlideKeyLvl1, handleSizeLvl1Change }) => {
  useEffect(() => () => handleSizeLvl1Change(""), []);
  return (
    <div className="filterLeftPane">
      <ul>
        {list.map((item, index) => (
          <li
            key={item}
            className={classnames({
              active:
                activeSlideKeyLvl1 === item ||
                (!activeSlideKeyLvl1 && index === 0)
            })}
            onClick={
              !activeSlideKeyLvl1 && index === 0
                ? handleSizeLvl1Change(item)
                : () => handleSizeLvl1Change(item)
            }
          >
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterLeftPane;
