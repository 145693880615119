import { flatten, isArray, isEmpty, lowerCase } from "lodash";
import { getCommerceDataForListingFormat } from "../utils";
import GAExtensions from "../extensions";

class ProductGAService extends GAExtensions {
  // completed
  trackSelectProduct = data => {
    let { item, listingTitle = null } = data;
    let listingInfo = {};
    if (listingTitle) {
      listingTitle = this.sanitizeString(listingTitle);
      listingInfo = {
        item_list_name: listingTitle,
        item_list_id: listingTitle
      };
    }
    let { payload: product } = getCommerceDataForListingFormat(item);
    product = { ...product, ...listingInfo };
    this.pushToDataLayer({
      event: "select_item",
      ecommerce: {
        items: [product]
      }
    });
  };

  // completed
  trackViewProduct = data => {
    let { item, listingTitle = null, origin = null } = data;
    let listingInfo = {};
    if (listingTitle) {
      listingTitle = this.sanitizeString(listingTitle);
      listingInfo = {
        item_list_name: listingTitle,
        item_list_id: listingTitle
      };
    }
    if (origin) {
      item.origin = origin;
    }
    let { payload: product, totalPrice } =
      getCommerceDataForListingFormat(item);
    product = { ...product, ...listingInfo };
    const { currencyCode } = this.getConfigData();

    this.pushToDataLayer({
      event: "view_item",
      ecommerce: {
        currency: currencyCode,
        value: totalPrice,
        items: [product]
      }
    });
  };
  trackViewRelatedProducts = arrData => {
    const { currencyCode } = this.getConfigData();
    const { payload: products } = getCommerceDataForListingFormat(arrData);
    this.pushToDataLayer({
      event: "view_item_list",
      ecommerce: {
        currency: currencyCode,
        items: products
      }
    });
  };
  trackOrderCancellationStart = orderId => {
    this.pushToDataLayer({
      event: "cancel_order_start",
      order_id: orderId
    });
  };
  trackOrderCancellationSuccess = orderId => {
    this.pushToDataLayer({
      event: "cancel_order_success",
      order_id: orderId
    });
  };
  trackProductsSort = data => {
    const { sort_by = "", sort_location } = data;
    this.pushToDataLayer({
      event: "sort",
      sort_by: lowerCase(sort_by),
      sort_location
    });
  };
  trackProductSizeSelected = data => {
    const { size, size_region, product_sku } = data;
    let sizeType = {};
    if (size_region) sizeType.size_region = lowerCase(size_region);
    this.pushToDataLayer({
      event: "select_size",
      size: (size || "")?.toLowerCase(),
      product_sku: `${product_sku}`,
      ...sizeType
    });
  };
  trackShareProduct = data => {
    const { share_type, product_sku } = data;
    this.pushToDataLayer({
      event: "share_product",
      share_type,
      product_sku: `${product_sku}`
    });
  };
  trackProductsOutOfStock = data => {
    const { productId, sizeRegion = "", availSizes } = data;
    this.pushToDataLayer({
      event: "out_of_stock",
      size_region: this.sanitizeString(sizeRegion),
      product_sku: `${productId}`,
      size: availSizes
        ?.filter(option => !option.sizeStock)
        ?.map(option => option.sizeLabel)
        ?.join("|")
        ?.toLowerCase()
    });
  };
  trackProductImagesInteraction = ({ product_sku }) => {
    this.pushToDataLayer({
      event: "image_interaction",
      product_sku: `${product_sku}`
    });
  };
  trackPaymentMethodInfo = payment_method => {
    this.pushToDataLayer({
      event: "payment_method_info",
      cta_name: "learn more",
      payment_method
    });
  };

  trackListingAutoFilter = data => {
    const {
      selectedFilters,
      resultCount = 0,
      searchText,
      enTranslation
    } = data;
    let filterObj = {};

    selectedFilters.forEach(({ facet, subFacet }) => {
      if (!filterObj[facet]) filterObj[facet] = [];
      const value = this.sanitizeFilterValue(subFacet);
      filterObj[facet].push(value);
    });
    let allCategories = Object.keys(filterObj).map(each => enTranslation[each]);
    allCategories = [...new Set(allCategories)].join("|").toLowerCase();
    const allValues = flatten(Object.values(filterObj)).join("|").toLowerCase();
    Object.keys(filterObj).forEach(each => {
      this.pushToDataLayer({
        event: "filter_auto_applied",
        number_of_filter_results: resultCount,
        filter_location: !isEmpty(searchText)
          ? "search results"
          : "product search",
        filter_by: lowerCase(enTranslation[each]),
        filter_value: filterObj[each]?.join("|"),
        filter_all_categories: allCategories,
        filter_all_values: allValues,
        filter_by_category: filterObj[each]?.join("|")
      });
    });
  };

  sanitizeFilterValue = value => {
    const subFacetArr = value.split(">");
    if (!isEmpty(subFacetArr)) {
      return subFacetArr[
        (subFacetArr.length && subFacetArr.length !== 7
          ? subFacetArr.length - 1
          : subFacetArr.length - 4) || 0
      ]
        ?.trim()
        ?.toLowerCase();
    }
  };

  trackListingFilterApplied = data => {
    const {
      selectedFilters,
      resultCount = 0,
      searchText,
      enTranslation,
      appliedFacet
    } = data;
    let filterObj = {};

    selectedFilters.forEach(({ facet, subFacet }) => {
      if (!filterObj[facet]) filterObj[facet] = [];
      const value = this.sanitizeFilterValue(subFacet);
      filterObj[facet].push(value);
    });

    let allCategories = Object.keys(filterObj).map(each => enTranslation[each]);
    allCategories = [...new Set(allCategories)].join("|").toLowerCase();
    const allValues = flatten(Object.values(filterObj)).join("|").toLowerCase();
    const filterValue = this.sanitizeFilterValue(appliedFacet[1]);

    this.pushToDataLayer({
      event: "filter",
      number_of_filter_results: resultCount,
      filter_location: !isEmpty(searchText)
        ? "search results"
        : "product search",
      filter_by: lowerCase(enTranslation[appliedFacet[0]]),
      filter_value: filterValue,
      filter_all_categories: allCategories,
      filter_all_values: allValues,
      filter_by_category: filterObj[appliedFacet[0]]?.join("|")?.toLowerCase()
    });
  };

  trackViewItemListImpressions = data => {
    const {
      _arr,
      products,
      searchText = "",
      searchListingStaticHeading = ""
    } = data;
    const { payload } = getCommerceDataForListingFormat(
      _arr.map((product, index) => {
        product.position = product.position ? product.position - 1 : index;
        return product;
      })
    );

    let listingIdentifier =
      products.en_title || products.title || products.headingH1 || "";
    if (!isEmpty(searchText)) {
      listingIdentifier = `${searchListingStaticHeading} ${searchText}`;
    }
    listingIdentifier = this.sanitizeString(listingIdentifier);
    const listingInfo = {
      item_list_name: listingIdentifier,
      item_list_id: listingIdentifier
    };
    const { currencyCode } = this.getConfigData();
    // item_list_id can be the same as item_list_name if no other unique identifier exist. - from @majid.ecens
    const payloadRevised = payload.map(each => ({ ...each, ...listingInfo }));
    this.pushToDataLayer({
      event: "view_item_list",
      ecommerce: {
        currency: currencyCode,
        ...listingInfo,
        items: payloadRevised
      }
    });
  };
}

export default ProductGAService;
