import React, { useMemo } from "react";
import ListWrapper from "./ListWrapper";
import MenuBanner from "./menuBanner";

const MenuListWrapper = ({ col, countryShort, language, headerTitles }) => {
  const filteredCol = useMemo(() => {
    return Array.isArray(col)
      ? col.reduce((acc, item) => {
          if (!item.isDisabled) {
            const filteredChildren = Array.isArray(item.children)
              ? item.children
                  .filter(child => !child.isDisabled)
                  .map(child => ({
                    ...child,
                    ...headerTitles,
                    subTitle1: item.subTitle,
                    en_subTitle1: item.en_subTitle
                  }))
              : item.children;
            acc.push({ ...item, children: filteredChildren, ...headerTitles });
          }
          return acc;
        }, [])
      : col;
  }, [col]);

  if (!filteredCol) return null;

  return Array.isArray(filteredCol) ? (
    <ListWrapper
      col={filteredCol}
      countryShort={countryShort}
      language={language}
    />
  ) : (
    <MenuBanner
      col={filteredCol}
      countryShort={countryShort}
      language={language}
    />
  );
};

export default MenuListWrapper;
