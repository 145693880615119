import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { getCountryList } from "../../../redux/actions/common.action";
import {
  cancelOrder,
  setCancelOrder,
  resetCancelOrder,
  trackOrder
} from "../../../redux/actions/myAccount.action";

import AnalyticService from "../../../services/analytic-service";

import OrderCard from "../../order";
import CancelOrderAlert from "../../cancel-order-alert";
import ModalBFL from "../../modal/modal";
import OrderDetail from "../myAcc-order-detail";
import DeliveryDetails from "../myAcc-delivery-details";
import TrackOrderTimeline from "../../track-order-timelines";

const deliveryTypes = ["DELIVERY_ADDRESS", "EXPRESS_DELIVERY", "SELF_PICKUP"];

class RecentOrder extends Component {
  state = {
    cancelOrderModal: false,
    accordionActive: false,
    cancelReason: "",
    showTrackOrder: false,
    cancelStatus: false,
    currentTrackOrderData: ""
  };
  handleSelectChange = selected => {
    const { setCancelOrder, email, orderId, order } = this.props;
    this.setState({ cancelReason: selected });
    setCancelOrder("reason", selected);
    setCancelOrder("orderId", orderId);
    setCancelOrder("email", email);
    const requestArr = [];
    order.items.map(obj => {
      requestArr.push({ ...obj, productId: obj.pId });
      return null;
    });
    setCancelOrder("orderItems", requestArr);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCancelStatus(nextProps);
    const { activePage } = this.props;
    if (nextProps.activePage !== activePage) {
      this.setState({ accordionActive: false });
    }
  }
  componentDidMount() {
    this.handleCancelStatus();
  }

  handleCancelStatus = nextProps => {
    const { order } = this.props;
    let selectedOrder =
      !!nextProps && !!nextProps.order ? nextProps.order : order;
    let _flag = true;
    !!selectedOrder &&
      selectedOrder.items.map(({ quantity, cancelQty }) => {
        const status = quantity === 0 && cancelQty > quantity;
        _flag = _flag && status;
        return null;
      });
    this.setState({ cancelStatus: _flag });
  };
  handleTrackOrder = () => {
    const { email, orderId, trackOrder } = this.props;
    this.setState({ showTrackOrder: !this.state.showTrackOrder });
    if (!this.state.showTrackOrder)
      //trackOrder({email : "areej_here@hotmail.com", orderId : "A2649610038"});
      trackOrder({ email: email, orderId: orderId }).then(response => {
        this.setState({ currentTrackOrderData: response.data });
      });
  };
  handleTextArea = e => {
    const { setCancelOrder } = this.props;
    setCancelOrder("comment", e.target.value);
  };
  toggleCancelOrderModal = value => {
    this.setState({ cancelOrderModal: value, cancelReason: "" });
    if (!this.state.cancelOrderModal) {
      this.props.resetCancelOrder();
    }
  };
  validateCancelOrderForm = e => {
    const { OrderDetailR, setCancelOrder } = this.props;
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    _OrdersKey.forEach(key => {
      setCancelOrder(key, OrderDetailR.cancelOrder[key]);
    });
  };
  handleCancelOrder = () => {
    const {
      cancelOrderAction,
      OrderDetailR,
      order,
      language,
      activePage,
      authReducer,
      commonSettings
    } = this.props;
    this.validateCancelOrderForm();
    OrderDetailR.errors.quantity.valid = true;
    OrderDetailR.errors.quantity.touched = true;
    const _OrdersKey = Object.keys(OrderDetailR.errors).filter(
      key => key !== "comment"
    );
    if (
      _OrdersKey.every(
        key =>
          OrderDetailR.errors[key].valid && OrderDetailR.errors[key].touched
      )
    ) {
      const cancelOrderRequestBody = {
        ...OrderDetailR.cancelOrder,
        quantity: parseInt(OrderDetailR.cancelOrder.quantity),
        currencyCode: order.CurrencyCode,
        lang: language
      };
      cancelOrderAction(cancelOrderRequestBody, activePage).then(() => {
        this.toggleCancelOrderModal(false);
        this.setState({ cancelStatus: true });
      });
      AnalyticService.order.trackMulipleOrderCancellation({
        orderDetails: OrderDetailR,
        authReducer,
        commonSettings
      });
    }
  };
  handleShowAccordion = async () => {
    const { accordionActive } = this.state;
    const { getCountryList, language } = this.props;

    if (!accordionActive) {
      await getCountryList(language);
    }

    this.setState({ accordionActive: !this.state.accordionActive });
  };

  render() {
    const {
      accordion,
      orderId,
      activePage,
      totalAmount,
      orderPlaced,
      estimatedDelivery,
      myAccountTranslation,
      language,
      handleAction,
      order,
      OrderDetailR,
      deliveryOptions,
      countryData
    } = this.props;
    const {
      accordionActive,
      cancelOrderModal,
      cancelReason,
      showTrackOrder,
      cancelStatus,
      currentTrackOrderData
    } = this.state;
    let deliveryOption = "";
    if (deliveryOptions) {
      if (order.deliveryType === deliveryTypes[0]) {
        deliveryOption = deliveryOptions[0];
      } else if (order.deliveryType === deliveryTypes[1]) {
        deliveryOption = deliveryOptions[1];
      } else {
        deliveryOption = deliveryOptions[2];
      }
    }

    let AllOrderCancelled = true;
    order &&
      order.items.length &&
      order.items.forEach(item => {
        if (item.quantity !== 0) {
          AllOrderCancelled = false;
        }
      });
    return (
      <div className="margin-bottom29">
        <div className="recent_order_Wrapper">
          <div className="row">
            <div className="col-lg-9 col-sm-12">
              <OrderCard
                orderId={orderId}
                totalAmount={totalAmount}
                orderPlaced={orderPlaced}
                estimatedDelivery={
                  estimatedDelivery ||
                  (deliveryOption &&
                    moment(order.createDate)
                      .add(deliveryOption.estimatedDeliveryDays, "d")
                      .format("LLL"))
                }
                myAccountTranslation={myAccountTranslation}
                orderItems={order.items || []}
                orderStatus={order.status}
              />
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="order_action_wrapper">
                {accordion ? (
                  <>
                    {!cancelStatus ? (
                      <>
                        <div
                          className="form_black_border_btn track_order_btn"
                          onClick={this.handleTrackOrder}
                        >
                          {myAccountTranslation.trackOrder}
                        </div>
                        {((order.status && order.status === "ORDER RECEIVED") ||
                          order.status === "IN PROCESS") &&
                        !AllOrderCancelled ? (
                          <div
                            className="form_black_border_btn cancel_order_btn"
                            onClick={() => this.toggleCancelOrderModal(true)}
                          >
                            {myAccountTranslation.cancelOrder}
                          </div>
                        ) : order.status &&
                          (order.status === "DELIVERED" ||
                            order.status === "RETURNED" ||
                            order.status === "HANDED TO SHIPPER") ? (
                          <div className="form_black_border_btn cancel_order_btn cancelled">
                            {myAccountTranslation[order.status]}
                          </div>
                        ) : (
                          <div className="form_black_border_btn cancel_order_btn cancelled">
                            {myAccountTranslation.orderCancel}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="form_black_border_btn track_order_btn"
                          onClick={this.handleTrackOrder}
                        >
                          {myAccountTranslation.trackOrder}
                        </div>
                        {order.status &&
                        (order.status === "DELIVERED" ||
                          order.status === "RETURNED") ? (
                          <div className="form_black_border_btn cancel_order_btn cancelled">
                            {myAccountTranslation[order.status]}
                          </div>
                        ) : (
                          <div className="form_black_border_btn cancel_order_btn cancelled">
                            {myAccountTranslation.orderCancel}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <button onClick={handleAction}>
                    <i className="order_icon_arrowRight" />
                  </button>
                )}
              </div>
            </div>
            {accordion && (
              <span
                className={`accordian_trigger_icon ${
                  accordionActive ? "active" : ""
                }`}
                onClick={this.handleShowAccordion}
              />
            )}
          </div>
          <ModalBFL
            showModal={cancelOrderModal}
            modalClass={`generic_modal cancel_order_modal ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <button
              className="cross_btn"
              onClick={e => this.toggleCancelOrderModal(false)}
            >
              &times;
            </button>
            <CancelOrderAlert
              myAccountTranslation={myAccountTranslation}
              handleCancelOrder={this.handleCancelOrder}
              cancelReason={cancelReason}
              handleTextArea={this.handleTextArea}
              handleSelectChange={this.handleSelectChange}
              OrderDetail={OrderDetailR}
              toggleCancelOrderModal={() => this.toggleCancelOrderModal(false)}
            />
          </ModalBFL>
        </div>
        {showTrackOrder && (
          <TrackOrderTimeline
            trackOrder={currentTrackOrderData}
            handleTrackOrder={this.handleTrackOrder}
            showCrossIcon={true}
          />
        )}
        {accordionActive && (
          <>
            {order.items.map(orderItem => (
              <OrderDetail
                key={orderItem.pId}
                myAccountTranslation={myAccountTranslation}
                orderItem={orderItem}
                order={order}
                activePage={activePage}
              />
            ))}
            <DeliveryDetails
              myAccountTranslation={myAccountTranslation}
              order={order}
              countryName={
                order.shippingCountry &&
                countryData.find(
                  country => country.countryCode === order.shippingCountry
                ).countryName
              }
              countryCode={
                order.shippingCountry &&
                countryData.find(
                  country => country.countryCode === order.shippingCountry
                ).mobileCountryCode
              }
            />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  email: state.authReducer.email,
  OrderDetailR: state.myAccountReducer.OrderDetail,
  language: state.common.language,
  trackOrderR: state.myAccountReducer.trackOrder,
  countryData: state.common.countryData,
  authReducer: state.authReducer,
  commonSettings: state.common.settings
});
const mapStateToDispatch = dispatch => ({
  setCancelOrder: bindActionCreators(setCancelOrder, dispatch),
  cancelOrderAction: bindActionCreators(cancelOrder, dispatch),
  resetCancelOrder: bindActionCreators(resetCancelOrder, dispatch),
  trackOrder: bindActionCreators(trackOrder, dispatch),
  getCountryList: bindActionCreators(getCountryList, dispatch)
});
export default connect(mapStateToProps, mapStateToDispatch)(RecentOrder);
