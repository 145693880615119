import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Image } from "../../image";

const EmptyCart = ({
  language,
  buttonClick,
  noRecordImgUrl,
  noRecordHeading,
  noRecordDesc,
  buttonText,
  buttonLink
}) => (
  <div
    className={cn(language.includes("ar") && "arabic", "wishlist empty_cart")}
  >
    <Image src={noRecordImgUrl} alt="No Record Found" />
    <h2 className="no_record_heading">{noRecordHeading}</h2>
    <p>{noRecordDesc}</p>
    {buttonText && (
      <>
        {buttonLink ? (
          <Link to={buttonLink + "/"}>
            <button className="round_btn btn">{buttonText}</button>
          </Link>
        ) : (
          <button onClick={buttonClick} className="round_btn btn">
            {buttonText}
          </button>
        )}
      </>
    )}
  </div>
);

export default EmptyCart;
