import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import ContentImage from "../content/content-image";
import { getRecentlyViewed } from "../../redux/actions/common.action";
import { getProductUrlName, getBaseUrl } from "../../util";
import AnalyticService from "../../services/analytic-service";
import { GAService } from "../../services/GA-service";
import { selectCdnImageSettings } from "../../util/selectors";

class RecentlyViewed extends Component {
  state = {
    recentlyViewed: []
  };

  componentDidMount() {
    const recentlyViewed = getRecentlyViewed();
    this.setState({ recentlyViewed });
  }

  moveToProductDetail(item) {
    if (item) {
      const id = item.id || item.productId;
      const { language, history, authReducer, commonSettings } = this.props;
      const ProductTitle = getProductUrlName(item.en_title || item.title || "");
      history &&
        history.push(
          `/${language}-${
            (commonSettings &&
              commonSettings.countrySHORT &&
              commonSettings.countrySHORT.toLowerCase()) ||
            "ae"
          }/${ProductTitle ? ProductTitle : "---"}/${id}/p/`
        );
      GAService.product.trackSelectProduct({
        item,
        listingTitle: item.en_title
      });
      AnalyticService.product.trackMoveToProductDetail({
        item,
        authReducer,
        commonSettings
      });
    }
  }

  render() {
    const { translation, language, configCdnImagesSettings } = this.props;
    const { recentlyViewed } = this.state;

    params.rtl = language.includes("ar") ? "rtl" : "";
    return (
      <Fragment>
        {recentlyViewed && recentlyViewed.length ? (
          <div className="recentlyViewed">
            <h2 className="section_heading">{translation.RecentlyViewed}</h2>
            <div className="recently_viewed_slider_wrap">
              <Swiper {...params}>
                {recentlyViewed.map((item, index) => (
                  <div
                    className="img_grid_wrapper"
                    onClick={e => this.moveToProductDetail(item)}
                    key={index}
                  >
                    <Link
                      to={`${getBaseUrl(this.props)}/${getProductUrlName(
                        item.en_title || item.title
                      )}/${item.id}/p/`}
                    >
                      <ContentImage
                        url={configCdnImagesSettings?.thumb}
                        img={item && item.captionImageURL}
                      />
                    </Link>
                  </div>
                ))}
              </Swiper>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const params = {
  dots: false,
  slidesPerView: 6,
  rebuildOnUpdate: true,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    1024: {
      slidesPerView: 9,
      spaceBetween: 8
    },
    800: {
      slidesPerView: 5
    },
    600: {
      slidesPerView: 4.5
    },
    475: {
      slidesPerView: 3.5
    }
  }
};

const mapStateToProps = state => ({
  commonSettings: state.common.settings,
  configCdnImagesSettings: selectCdnImageSettings(state)
});

export default connect(mapStateToProps)(RecentlyViewed);
