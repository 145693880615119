import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  UNMINIMIZED_NAME
} from "../../constants";
import { SECTION_SLUGS } from "../../constants/sections";
import {
  changeLanguage,
  resetBreadCrumb
} from "../../redux/actions/common.action";
import { homePageError, isHomePage } from "../../redux/actions/homepage.action";
import ErrorPage from "../error-page/index";
import Page from "../../components/page";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { handleSearchText } from "../../redux/actions/search.action";
import { getBannerSections } from "../../redux/actions/section.action";
import { getPageApi } from "../../redux/actions/page.action";
import { seoTranslation } from "../../language/seo/en/seo";
import QuickFilters from "../../components/quick-filter";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import AnalyticService from "../../services/analytic-service";
import HomepageSections from "./homepageSections";
import { selectPageMeta } from "../../util/selectors";
import { getDeviceType } from "../../util";
import ssrConfig from "./ssrConfig";

class HomePage extends Component {
  static unminimizedName = UNMINIMIZED_NAME.HOME_PAGE;

  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      seoTranslated: seoTranslation[urlLang],
      homePageEvent: true,
      isRenderLiveAnimation: false,
      reFetchedPageContent: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { language, authReducer } = this.props;
    const { homePageEvent } = this.state;
    if (language !== nextProps.language) {
      this.getLanguage(nextProps.language);
    }
    if (nextProps.authReducer.accessToken && nextProps.isHomePageStatus) {
      if (authReducer.email !== nextProps.authReducer.email) {
        const email = get(nextProps, "authReducer.email", "");
        AnalyticService.common.trackHomePageStatus(email);
      }
    } else {
      if (homePageEvent) {
        this.setState({
          homePageEvent: false
        });
        AnalyticService.common.trackHomePageEvent();
      }
    }
  }
  UNSAFE_componentWillMount() {
    const { resetBreadCrumb, handleSearchText, isHomePage } = this.props;
    resetBreadCrumb && resetBreadCrumb();
    handleSearchText && handleSearchText("");
    isHomePage(true);
  }

  componentDidMount() {
    const {
      language,
      countryId,
      storeId,
      settings,
      history,
      countryData,
      getBannerSections,
      deviceTypeSSR,
      homepageSections,
      getPageApi
    } = this.props;
    const { reFetchedPageContent } = this.state;
    let pathname = history.location.pathname;
    const deviceType = deviceTypeSSR || getDeviceType();
    if (homepageSections.length) {
      if (!homepageSections[0][deviceType] && !reFetchedPageContent) {
        getPageApi("/", language, countryId, storeId);
        this.setState({
          reFetchedPageContent: true
        });
      }
    }

    getBannerSections(SECTION_SLUGS.HEADER);

    if (Object.keys(settings).length) {
      let lang = language,
        isFound = false,
        _storeList = [];
      if (lang.includes("ar")) {
        lang = "ar";
      } else {
        lang = "en";
      }

      settings &&
        settings.stores &&
        settings.stores.forEach(_store => {
          let _storeURL = _store.name;
          _storeURL = _storeURL.toLowerCase();
          _storeURL = _storeURL.replace(/ /g, "-");
          if (_storeURL.includes("brands-for-less")) {
            _storeList.push(`/`);
          } else {
            _storeList.push(`/${_storeURL}`);
            _storeList.push(`/${_storeURL}/`);
          }
        });

      countryData.forEach(_country => {
        if (
          pathname === `/${lang}-${_country.countrySHORT.toLowerCase()}` ||
          _storeList.some(url =>
            pathname.includes(
              `/${lang}-${_country.countrySHORT.toLowerCase()}${url}`
            )
          )
        ) {
          isFound = true;
        }
      });

      if (!isEmpty(countryData) && !isFound) {
        history.push(`/${lang}-${settings.countrySHORT.toLowerCase()}/`);
      }
    }
    this.getLanguage(language);
    setTimeout(() => {
      this.setState({ isRenderLiveAnimation: true });
    }, 3000);
  }

  componentWillUnmount() {
    const { isHomePage, homePageError } = this.props;
    isHomePage(false);
    homePageError(false);
  }

  getLanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(`../../language/seo/${language}/seo`);
    languageResponse.then(response => {
      this.setState({ seoTranslated: response.seoTranslation });
    });
  };

  getMetaData = () => {
    const { meta } = this.props;

    const { seoTranslated } = this.state;

    if (!isEmpty(meta)) {
      const cmsTitle = meta.title?.trim();

      const defaultDescription = `${seoTranslated.withoutStoreMetaDesc} ${seoTranslated.bestPriceIn}`;

      const metaDescription =
        meta.metaDescription?.trim() || defaultDescription;

      const title = `${seoTranslated.bfl} | ${cmsTitle}`;

      return { title, metaDescription };
    }
  };

  render() {
    const {
      homepageSections,
      language,
      routelanguage,
      settings,
      isHomePageError,
      countryId,
      meta,
      homepageTitle
    } = this.props;
    const metaData = (homepageSections?.length && this.getMetaData()) || {};
    const { firework } = settings;
    const { isRenderLiveAnimation } = this.state;
    const enableQuickFilter = get(
      settings,
      "enableOrDisableQuickFilter",
      false
    );

    return (
      <div
        className={`App ${
          routelanguage === LANGUAGE_ROUTE_KEY.arabic ? "arabic" : null
        } `}
      >
        {!isHomePageError ? (
          <Page
            title={meta.title}
            meta={{ [language]: metaData }}
            language={language}
            id="homepage"
            indexable
          >
            {enableQuickFilter && language !== LANGUAGE.arabic && (
              <QuickFilters />
            )}
            {countryId && (
              <HomepageSections
                homepageSections={homepageSections}
                language={language}
                routelanguage={routelanguage}
                settings={settings}
                homepageTitle={homepageTitle}
              />
            )}
          </Page>
        ) : (
          <ErrorPage language={language} />
        )}
      </div>
    );
  }
}

HomePage.need = ssrConfig;

const mapStateToProps = state => ({
  showModal: state.homepage.showModal,
  isHomePageError: state.homepage.isHomePageError,
  isHomePageStatus: state.homepage.isHomePage,
  homepageSections: state.page.homepageState.sections,
  homepageTitle: state.page.homepageState.title,
  meta: selectPageMeta(state),
  showLoader: state.common.showLoader,
  settings: state.common.settings,
  countryData: state.common.countryData,
  language:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  routelanguage: state.common.language,
  countryId: state.page.homepageState.countryId,
  reduxStore: state,
  authReducer: state.authReducer,
  cart: state.cartReducer.cart,
  deviceTypeSSR: state.common.deviceType,
  storeId: state.page.homepageState.storeId
});

const mapDispatchToProps = dispatch => ({
  resetBreadCrumb: bindActionCreators(resetBreadCrumb, dispatch),
  handleSearchText: bindActionCreators(handleSearchText, dispatch),
  isHomePage: bindActionCreators(isHomePage, dispatch),
  homePageError: bindActionCreators(homePageError, dispatch),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch),
  getBannerSections: bindActionCreators(getBannerSections, dispatch),
  getPageApi: bindActionCreators(getPageApi, dispatch)
});

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  connect(mapStateToProps, mapDispatchToProps),
  withPageTypeTracking
)(HomePage);
