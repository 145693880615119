import React, { Component } from "react";
import List from "../size-chart-list";
import SizeChartPopup from "../size-chart-popup";
import Html from "../size-chart-popupHtml";
import { isMobile } from "../../../util";

class BrandSizeGuide extends Component {
  state = {
    isAccordionActive: false,
    listLimit: 18,
    more: false
  };

  handleAccordion = () =>
    this.setState({ isAccordionActive: !this.state.isAccordionActive });

  handleMoreOrLess = val => {
    this.setState({
      more: !this.state.more
    });
  };

  _sort = (a, b) => {
    var nameA = a.brandName.trim().toLowerCase();
    var nameB = b.brandName.trim().toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  handleListItemClick = item => {
    const { activeTab, sizeChart, onListItemClick } = this.props;
    onListItemClick({
      click_text: item.brandName,
      size_chart_tree: `${activeTab} > ${sizeChart[activeTab].heading} > ${item.brandName}`
    });
  };

  render() {
    const {
      activeTab,
      sizeChart,
      handlePopup,
      handleSizeChart,
      guideType,
      isPopupActive,
      popupTitle,
      PopUpHtmlList,
      setSearchText,
      searchTxt,
      translation
    } = this.props;

    const { isAccordionActive, more, listLimit } = this.state;

    const isMob = !!(isMobile.any() && isMobile.any().length);

    let imgUrl =
      !!sizeChart &&
      !!activeTab &&
      sizeChart[`${activeTab.toLowerCase()}`].imageUrl;

    let filterList =
      (!!sizeChart &&
        !!activeTab &&
        !!sizeChart[`${activeTab}`].brandList.length &&
        sizeChart[activeTab].brandList.filter(
          item =>
            item.brandName.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1
        )) ||
      [];

    filterList = filterList.sort(this._sort);

    const ListLimit =
      !!filterList &&
      !!filterList.length &&
      filterList.filter((item, index) =>
        index <= listLimit - 1 ? item : null
      );

    return (
      <>
        <div className="sizeCard_wrapper brand">
          {imgUrl && (
            <div
              className="sizeChart_wrapper_img"
              style={{ backgroundImage: `url(${imgUrl})` }}
            />
          )}
          <p className="sizeCard_title">
            {!!sizeChart && !!activeTab && sizeChart[`${activeTab}`].heading}
          </p>
          {!isMob && (
            <div>
              <div className="brands_list">
                <ul>
                  {!!ListLimit.length &&
                    ListLimit.map((item, index) => (
                      <List
                        brandName={item.brandName}
                        brandId={item.brandId}
                        key={item.brandId}
                        handlePopup={handlePopup}
                        handleItemClick={() => this.handleListItemClick(item)}
                        handleSizeChart={handleSizeChart}
                        guideType={guideType}
                        bodyText={item.brandName}
                      />
                    ))}
                </ul>
                {ListLimit.length > 17 && ListLimit.length < 19 && !more && (
                  <button className="more_btn" onClick={this.handleMoreOrLess}>
                    {translation.more} <sub>+</sub>
                  </button>
                )}
              </div>
              {!!filterList &&
                !!filterList.length &&
                filterList.length > 18 &&
                more && (
                  <div className="brands_list_expand">
                    <ul>
                      {!!filterList &&
                        !!filterList.length &&
                        filterList.map((item, index) =>
                          index > listLimit - 1 && more ? (
                            <List
                              brandName={item.brandName}
                              brandId={item.brandId}
                              key={item.brandId}
                              handlePopup={handlePopup}
                              handleItemClick={() =>
                                this.handleListItemClick(item)
                              }
                              handleSizeChart={handleSizeChart}
                              guideType={guideType}
                              bodyText={item.brandName}
                            />
                          ) : null
                        )}
                    </ul>
                    {!!filterList &&
                      !!filterList.length &&
                      filterList.length > 18 &&
                      more && (
                        <button
                          className="more_btn"
                          onClick={this.handleMoreOrLess}
                        >
                          {translation.less} <sub>-</sub>
                        </button>
                      )}
                  </div>
                )}
            </div>
          )}
        </div>

        {isMob && !!filterList && (
          <div className="mob_list">
            <div
              className={`accordion_trigger ${isAccordionActive ? "open" : ""}`}
              onClick={this.handleAccordion}
            >
              <i className="sizeChart_angle_down" />
            </div>
            {isAccordionActive && (
              <>
                <div className="brand-search">
                  <input
                    type="text"
                    name="searchText"
                    className="search-text-area"
                    value={searchTxt || ""}
                    onChange={e => setSearchText(e.target.value, guideType)}
                    placeholder="Search for brands"
                    aria-label="Search for brands"
                  />
                  <span className="search_icon" />
                </div>
                <div className="brands_list mobile">
                  <ul>
                    {!!filterList &&
                      !!filterList.length &&
                      filterList.map((item, index) => (
                        <List
                          brandName={item.brandName}
                          brandId={item.brandId}
                          key={item.brandId}
                          handlePopup={handlePopup}
                          handleItemClick={() => this.handleListItemClick(item)}
                          handleSizeChart={handleSizeChart}
                          guideType={guideType}
                          bodyText={item.brandName}
                        />
                      ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}

        {!!PopUpHtmlList && (
          <SizeChartPopup
            active={isPopupActive}
            handlePopup={handlePopup}
            activeTab={activeTab}
            popupTitle={popupTitle}
          >
            {!!PopUpHtmlList.length &&
              !!PopUpHtmlList[0].subCategory.length &&
              PopUpHtmlList[0].subCategory.map((item, index) => (
                <Html
                  key={index}
                  title={`${PopUpHtmlList[0].brandName} ${
                    !!item.subCategoryName ? ` - ${item.subCategoryName}` : ""
                  }`}
                  html={item.html}
                />
              ))}
          </SizeChartPopup>
        )}
      </>
    );
  }
}

export default BrandSizeGuide;
