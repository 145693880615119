import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TabbyService } from "../../../services";
import {
  showPageLoader,
  hidePageLoader
} from "../../../redux/actions/common.action";
import { DEFAULT_PAGE_LOADER_CONFIG } from "../../../constants";
import "./tabby.scss";

const TabbyCheckout = ({
  currency,
  totalAmount,
  showPageLoader,
  hidePageLoader
}) => {
  const [script, setScript] = useState(null);

  const loadCheckoutScript = async () => {
    showPageLoader(DEFAULT_PAGE_LOADER_CONFIG);
    try {
      let option = {
        currency,
        totalAmount
      };
      const script = await TabbyService.loadCheckoutScript(option);
      return script;
    } finally {
      setTimeout(() => {
        hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG);
      }, 1000);
    }
  };

  useEffect(() => {
    (async () => {
      const script = await loadCheckoutScript();
      setScript(script);
    })();
  }, []);

  useEffect(() => {
    return async () => {
      script && (await hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG));
      script?.parentNode?.removeChild(script);
    };
  }, [script]);

  return <div id="tabbyCard"></div>;
};
const mapDispatchToProps = {
  showPageLoader,
  hidePageLoader
};

export default connect(null, mapDispatchToProps)(TabbyCheckout);
