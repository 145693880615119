import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import map from "lodash/map";
import cn from "classnames";
import { selectRouteSlug } from "../../util/selectors";
import { GAService } from "../../services/GA-service";

class FooterBottomMobile extends Component {
  state = {
    footerToggleList: []
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { footerToggleList } = this.state;
    const isNeedToFetchFooterList =
      !isEqual(this.props.footerList, nextProps.footerList) ||
      !footerToggleList.length;

    if (isNeedToFetchFooterList) {
      const { footerList } = nextProps;
      let footerToggleList = [];
      if (footerList && !!footerList.length) {
        footerList.forEach((element, index) => {
          footerToggleList.push({ clicked: false });
        });
        this.setState({ footerToggleList });
      }
    }
  }

  handleToggle = (e, index) => {
    const { footerToggleList } = this.state;
    footerToggleList[index].clicked = !footerToggleList[index].clicked;
    this.setState({ footerToggleList });
  };

  render() {
    const { footerToggleList } = this.state;
    const { footerList, routeSlug } = this.props;

    return (
      <section className="footer_bottom mobile_view">
        <div className="container">
          <div className="row">
            <section className="col-lg-7 col-sm-12 padding0">
              {footerList.length && footerToggleList.length && (
                <section className="row footer-links">
                  {map(footerList, (item, index) => {
                    const itemHasChildren = Boolean(
                      get(item, "children.length")
                    );

                    return (
                      <section
                        key={item.uid}
                        className={cn("col-lg-3", "col-sm-12", {
                          clicked: footerToggleList[index]?.clicked
                        })}
                      >
                        <h4
                          className={cn("heading", {
                            "find-store": !itemHasChildren
                          })}
                          id="helpYou"
                          onClick={e => this.handleToggle(e, index)}
                        >
                          {itemHasChildren ? (
                            item.title
                          ) : (
                            <Link to={`/${routeSlug}${item.redirectionLink}`}>
                              {item.title}
                            </Link>
                          )}
                        </h4>
                        {itemHasChildren && (
                          <ul className="footer_anim">
                            {item.children.map(childItem => {
                              const redirectionLink =
                                childItem.redirectionLink.trim();
                              const childItemLink = `/${routeSlug}${redirectionLink}`;
                              return (
                                <li key={childItem.uid}>
                                  <Link
                                    to={childItemLink}
                                    onClick={() => {
                                      GAService.footer.trackFooterMenuClick({
                                        parent: item,
                                        clickItem: childItem,
                                        clickLink: childItemLink
                                      });
                                    }}
                                  >
                                    {childItem.title}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </section>
                    );
                  })}
                </section>
              )}
            </section>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  footerList: state.footerReducer.footerList,
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(FooterBottomMobile);
