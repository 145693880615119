import { Cookies } from "react-cookie";
import { success, error } from "react-notification-system-redux";
import capitalize from "lodash/capitalize";
import get from "lodash/get";

import { getAccessToken } from "../../util/storeHelper";
import {
  generateUUID,
  languageFromPathName,
  isServer,
  getCountryNameFromUrl
} from "../../util";
import { toastNotificationOpts } from "../../util/toast";
import { handleFetchError } from "../../util/errorHandler";
import {
  getUserCountryStorage,
  getDefaultCookieOptions
} from "../../util/browserStorage";
import { selectLanguageSlug } from "../../util/selectors";

import { BEConfig, ENV_CONFIG } from "../../config/env";
import { fetchWebApi, postDashboardWebApi } from "../../webapis/apiResource";

import { ApiError } from "./homepage.action";
import { handleURLChange } from "./productlisting.action";
import { getDeviceInfo, getDataAsObject } from "../../util/common";

import {
  VISITOR_ID,
  SHOW_LOADER,
  HIDE_LOADER,
  CHANGE_LANGUAGE,
  SAVE_BREADCRUMB_DATA,
  COMMON_DATA,
  SET_GENERAL_INFO,
  SET_BRANDS_DATA,
  RECENTLY_VIEWED,
  SUBSCRIBE_NOW,
  SET_STATES_DATA,
  SET_LOYALITY_POINTS,
  SET_SEARCH_TEXT,
  RESET_BREADCRUMB_DATA,
  RESET_BRANDS_DATA,
  SHOW_ERROR_PAGE,
  HIDE_ERROR_PAGE,
  ERROR_NOT_FOUND,
  USER_ORIGIN_COUNTRY,
  SET_BRAND_PAGE_URLS,
  CHANGE_IS_HOME_PAGE_REDUX,
  SHOW_PAGE_LOADER,
  HIDE_PAGE_LOADER,
  SET_PAYPAL_TOKEN,
  SET_PAGE_INDEXABLE,
  SET_COLORS_DATA,
  SET_SIZE_TYPE,
  SET_ORDER_COUNTRY,
  RESET_ORDER_STATE,
  SET_COUNTRIES_MOBILE_DETAILS,
  SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  SET_LOYALTY_PROGRAM_STATUS,
  SET_DELIVERY_SETTINGS,
  REMOVE_DELIVERY_SETTINGS,
  SET_DEVICE_TYPE,
  SET_CONFIG_SETTINGS,
  RESET_CONFIG_SETTINGS,
  SET_IS_CHECKED_LOYALTY_PROGRAM
} from "../constants";
import {
  PARENT_CATEGORY_NAME,
  BRAND_NAME,
  PARENT_CATEGORY_KEY_NAME,
  PRODUCT_CAROUSEL_ITEMS_LIMIT,
  TOAST_TYPES,
  DEFAULT_PAGE_LOADER_CONFIG
} from "../../constants";

import { CheckoutService, CommonService, CatalogService } from "../../services";
import { resetQuickFilter } from "./quickFilter.action";
import { setStoreList } from "./page.action";

const cookies = new Cookies();

export const showErrorPage = (key, value) => ({
  type: SHOW_ERROR_PAGE,
  key,
  value
});

export const hideErrorPage = () => ({ type: HIDE_ERROR_PAGE });

export const errorNotFound = () => ({ type: ERROR_NOT_FOUND });

export const showLoadingIndicator = () => ({ type: SHOW_LOADER });

export const hideLoadingIndicator = () => ({ type: HIDE_LOADER });

export const showPageLoaderStart = payload => ({
  type: SHOW_PAGE_LOADER,
  payload
});

export const showPageLoaderEnd = payload => ({
  type: HIDE_PAGE_LOADER,
  payload
});

export const showPageLoader = payload => async dispatch => {
  dispatch(showPageLoaderStart(payload));
};
export const hidePageLoader = payload => async dispatch => {
  dispatch(showPageLoaderEnd(payload));
};

export const showLoaders =
  (pageLoaderConfig = DEFAULT_PAGE_LOADER_CONFIG) =>
  dispatch => {
    dispatch(showLoadingIndicator());
    dispatch(showPageLoader(pageLoaderConfig));
  };

export const hideLoaders =
  (pageLoaderConfig = DEFAULT_PAGE_LOADER_CONFIG) =>
  dispatch => {
    dispatch(hideLoadingIndicator());
    dispatch(hidePageLoader(pageLoaderConfig));
  };

export const updateLanguageName = name => ({
  type: CHANGE_LANGUAGE,
  name
});

export const changeLanguage = languageKey => async (dispatch, getState) => {
  const currentLanguage = selectLanguageSlug(getState());
  try {
    const isSameLanguageAsCurrent = languageKey.includes(currentLanguage);
    if (isSameLanguageAsCurrent) return;

    await dispatch(resetQuickFilter());
    await dispatch(updateLanguageName(languageKey));
    await dispatch(getCountrySettings(languageKey));
    dispatch(getConfigurationSettings);
  } catch (error) {
    console.error(error);
  }
};

export const saveBreadcrumb = navData => ({
  type: SAVE_BREADCRUMB_DATA,
  navData
});

export const resetBreadCrumb = () => ({ type: RESET_BREADCRUMB_DATA });

export const setCommonData = settings => ({ type: COMMON_DATA, settings });

export const setIPCountryData = ipCountryData => ({
  type: COMMON_DATA,
  ipCountryData
});

export const setCountryListData = countryData => ({
  type: COMMON_DATA,
  countryData
});

export const setImageUrlsData = imageUrlsData => ({
  type: COMMON_DATA,
  imageUrlsData
});

export const setGeneralInfo = generalInfo => ({
  type: SET_GENERAL_INFO,
  generalInfo
});

export const setIpcountry = ipcountry => ({
  type: COMMON_DATA,
  ipcountry
});

export const setBrandsData = brands => ({ type: SET_BRANDS_DATA, brands });
export const setSearchText = text => ({ type: SET_SEARCH_TEXT, text });

export const setStatesData = state => ({ type: SET_STATES_DATA, state });

export const setColorsData = state => ({ type: SET_COLORS_DATA, state });

export const setLoyalityPoints = loyaltyPoints => ({
  type: SET_LOYALITY_POINTS,
  loyaltyPoints
});

export const setLoyaltyProgramStatus = isJoinedToLoyaltyProgram => ({
  type: SET_LOYALTY_PROGRAM_STATUS,
  isJoinedToLoyaltyProgram
});

export const setIsCheckedLoyaltyProgram = isCheckedLoyaltyProgram => ({
  type: SET_IS_CHECKED_LOYALTY_PROGRAM,
  isCheckedLoyaltyProgram
});

export const subscribeNow = email => ({ type: SUBSCRIBE_NOW, email });

export const changeIsHomePageRedux = isHomePage => ({
  type: CHANGE_IS_HOME_PAGE_REDUX,
  isHomePage
});

export const resetBrandsReducer = () => ({ type: RESET_BRANDS_DATA });

export const setUserOriginCountry = userOriginCountry => ({
  type: USER_ORIGIN_COUNTRY,
  userOriginCountry
});

export const setDeliverySettings = deliverySettings => ({
  type: SET_DELIVERY_SETTINGS,
  payload: deliverySettings
});

export const removeDeliverySettings = () => dispatch => {
  dispatch(setCountryListData([]));
  dispatch({
    type: REMOVE_DELIVERY_SETTINGS
  });
};

export const resetOrderState = () => ({
  type: RESET_ORDER_STATE
});

export const toastMsg = (toastType, message, noTitle) => {
  const toastConfig = toastNotificationOpts(
    !noTitle && capitalize(toastType),
    message
  );
  const toastAction = toastType === TOAST_TYPES.SUCCESS ? success : error;

  return dispatch => dispatch(toastAction(toastConfig));
};

export const getVisitorId = () => {
  const visitorId = cookies.get(VISITOR_ID);
  if (visitorId) return visitorId;

  const newVisitorId = generateUUID();
  cookies.set(VISITOR_ID, newVisitorId, getDefaultCookieOptions());

  return newVisitorId;
};

export const updateVisitorId = () => {
  const visitorId = generateUUID();
  cookies.set(VISITOR_ID, visitorId, getDefaultCookieOptions());

  return visitorId;
};

export const setRecentlyViewed = product => {
  let recentLyViewed = localStorage.getItem(RECENTLY_VIEWED);
  recentLyViewed = (recentLyViewed && getDataAsObject(recentLyViewed)) || [];
  if (!recentLyViewed.find(prod => prod.id === product.id)) {
    recentLyViewed.unshift(product);
    if (recentLyViewed.length > 12) {
      recentLyViewed.splice(12, recentLyViewed.length);
    }
    localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(recentLyViewed));
  }
};

export const getRecentlyViewed = () => {
  const recentlyViewed = localStorage.getItem(RECENTLY_VIEWED);
  return (recentlyViewed && getDataAsObject(recentlyViewed)) || [];
};

export const getBrandsData = language => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.productsApi.protocol}${BEConfig.productsApi.baseURL}${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${BEConfig.productsApi.brandsPageHandle}`;
  return async (dispatch, getState) => {
    dispatch(showLoadingIndicator());

    try {
      const response = await fetchWebApi(
        getAccessToken(getState),
        apiURL,
        getVisitorId(),
        language
      );

      dispatch(hideLoadingIndicator());

      dispatch(setBrandsData(response.data));

      return response;
    } catch (error) {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
      return error.response;
    }
  };
};

export const getLoyalityPoints =
  (body, showLoader = false) =>
  async (dispatch, getState) => {
    const apiURL = `${BEConfig.productsApi.protocol}${BEConfig.productsApi.baseURL}${BEConfig.productsApi.port}${BEConfig.productsApi.versionInfo}${BEConfig.productsApi.loyalityPointsHandle}`;
    const { countryId } = getState().common.settings;
    const updatedBody = { ...body, countryId };
    if (showLoader) dispatch(showLoadingIndicator());
    try {
      const response = await postDashboardWebApi(
        getAccessToken(getState),
        apiURL,
        updatedBody,
        getVisitorId()
      );
      if (response.status === 200 && response.data && response.data.success) {
        dispatch(setLoyalityPoints(response.data.loyaltyPoints));
      }

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      if (showLoader) {
        dispatch(hideLoadingIndicator());
      }
    }
  };

export const getLoyalityPointsV2 =
  (body, showLoader = false) =>
  async (dispatch, getState) => {
    const apiURL = `${BEConfig.productsApi.protocol}${BEConfig.productsApi.baseURL}${BEConfig.productsApi.port}api/v5/products/loyalty-points`;
    const { countryId } = getState().common.settings;
    // const updatedBody = { ...body, countryId };
    if (showLoader) dispatch(showLoadingIndicator());
    try {
      const response = await postDashboardWebApi(
        getAccessToken(getState),
        apiURL,
        body,
        getVisitorId()
      );
      if (response.status === 200 && response.data && response.data.success) {
        dispatch(setLoyalityPoints(response.data.loyaltyPoints));
      }

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      if (showLoader) {
        dispatch(hideLoadingIndicator());
      }
    }
  };

export const getCommonData = (countryId, language) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.commonApi.protocol}${BEConfig.commonApi.baseURL}${BEConfig.commonApi.port}${BEConfig.commonApi.versionInfo}${BEConfig.commonApi.settingsHandle}/${countryId}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setCommonData(response.data));
          window &&
            window.navigator &&
            window.navigator.geolocation &&
            window.navigator.geolocation.getCurrentPosition(position => {
              dispatch(
                setGeneralInfo({
                  longitude:
                    position.coords && position.coords.longitude.toString(),
                  latitude:
                    position.coords && position.coords.latitude.toString()
                })
              );
            });
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const getLanguage = lang =>
  lang && lang.includes("ar") ? "arabic" : "english";

const mapMobileDetails = countries =>
  countries.map(
    ({
      countryId,
      countrySHORT,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength,
      countryName
    }) => ({
      countryId,
      countrySHORT,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength,
      countryName
    })
  );

const getVersionedCommonApiBaseUrl = () => {
  const { protocol, baseURL, port, versionInfo } = BEConfig.commonApi;
  return `${protocol}${baseURL}${port}${versionInfo}`;
};

export const getCountrySettings = (language, id, url, showLoader) => {
  const lang = getLanguage(language);
  const shortCountryNameFromUrl = getCountryNameFromUrl(
    isServer ? url || `/${language}` : window.location.href
  );
  const countryFromCookies = (getUserCountryStorage() || "").toLowerCase();
  const countryIdentifier =
    id || shortCountryNameFromUrl || countryFromCookies || ENV_CONFIG.countryId;
  const { settingsHandle, countryByIdSetting } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${settingsHandle}${countryByIdSetting}${countryIdentifier}?language=${lang}`;
  return (dispatch, getState) => {
    const isLoaderAlreadyActive = getState().common.pageLoader?.visible;
    const needToShowLoader = !isLoaderAlreadyActive && showLoader;
    needToShowLoader && dispatch(showLoaders());
    return fetchWebApi(getAccessToken(getState), apiURL, generateUUID())
      .then(response => {
        if (response.status === 200 && response.data) {
          const shapedData = [response.data];
          dispatch(setStoreList(lang, response.data.storeList));
          dispatch(setCountryListData(shapedData));
          response.data = shapedData;
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        needToShowLoader && dispatch(hideLoaders());
      });
  };
};

export const getOrderCountryById = (id, language) => {
  const lang = getLanguage(language);

  const { settingsHandle, countryByIdSetting } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${settingsHandle}${countryByIdSetting}${id}?language=${lang}`;
  return (dispatch, getState) =>
    fetchWebApi(getAccessToken(getState), apiURL, generateUUID())
      .then(response => {
        if (response.status === 200 && response.data) {
          const shapedData = response.data;
          dispatch({ type: SET_ORDER_COUNTRY, payload: shapedData });
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
};

export const getCountryList = language => async (dispatch, getState) => {
  const lang = getLanguage(language);
  const { countryData } = getState().common;

  if (countryData.length > 1) return countryData;

  try {
    const countryDataList = await CommonService.getShortCountryList(lang);
    dispatch(setCountryListData(countryDataList));

    return countryDataList;
  } catch (error) {
    handleFetchError(error, dispatch);
    return error.response;
  }
};

export const getStatesData = (countryId, language = "en") => {
  const lang = languageFromPathName(language);
  const { statesHandle } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${statesHandle}/${countryId}`;

  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL, getVisitorId(), lang)
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setStatesData(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getColors = () => {
  const { colors } = BEConfig.commonApi;
  const apiURL = `${getVersionedCommonApiBaseUrl()}${colors}`;

  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setColorsData(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const subscribeMail = bodyData => {
  const { protocol, baseURL, subscribeNowHandle } = BEConfig.subscribeNowApi;
  const apiURL = `${protocol}${baseURL}/${subscribeNowHandle}`;

  return dispatch => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(null, apiURL, bodyData)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
        } else {
          dispatch(ApiError(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchAndSetBrandPageUrlsFromSeoSafeUrls = (
  seoSafeParentCategoryName,
  seoSafeBrandName,
  languageProp,
  loader = true
) => {
  return (dispatch, getState) => {
    const language = languageFromPathName(languageProp);
    const { protocol, baseURL, port, versionInfo, seoUnsafeBrandUrlsEndpoint } =
      BEConfig.commonApi;
    let apiURL = `${protocol}${baseURL}${port}${versionInfo}${seoUnsafeBrandUrlsEndpoint}`;
    let query = brandPageUrlsFromSeoSafeUrlsQueryCreator(
      seoSafeParentCategoryName,
      seoSafeBrandName
    );

    if (query) {
      apiURL = `${apiURL}${query}`;
    }
    loader && dispatch(showLoaders());

    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.success !== false
        ) {
          dispatch(setBrandPageUrlsActionCreator(response.data));
          const q = getSeoSafeBrandUrls(response.data, language);
          q && dispatch(handleURLChange(q));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        loader && dispatch(hideLoaders());
      });
  };
};

const brandPageUrlsFromSeoSafeUrlsQueryCreator = (
  seoSafeParentCategoryName,
  seoSafeBrandName
) => {
  let queryArr = [];

  if (seoSafeParentCategoryName) {
    queryArr.push(`${PARENT_CATEGORY_NAME}=${seoSafeParentCategoryName}`);
  }
  if (seoSafeBrandName) {
    queryArr.push(`${BRAND_NAME}=${seoSafeBrandName}`);
  }

  const query = queryArr.join("&");
  return query ? `?${query}` : query;
};

export const setBrandPageUrlsActionCreator = ({ parentCategory, brand }) => ({
  type: SET_BRAND_PAGE_URLS,
  parentCategory,
  brand
});

const getSeoSafeBrandUrls = (response, language) => {
  let query = [];
  if (response) {
    if (response.parentCategory) {
      let keyName = language ? `${language}_pcatname` : "pcatname";
      let parentCat = response.parentCategory[keyName];
      if (parentCat) {
        parentCat = encodeURIComponent(parentCat);
        query.push(`${PARENT_CATEGORY_KEY_NAME}=${parentCat}`);
      }
    }
    if (response.brand) {
      let keyName = language ? `${language}_brandname` : "brandname";
      let brand = response.brand[keyName];
      if (brand) {
        brand = encodeURIComponent(brand);
        query.push(`${BRAND_NAME}=${brand}`);
      }
    }
  }
  return query.join("&");
};

export const loadIpcountry = () => async (dispatch, getState) => {
  try {
    const ipcountry = await CommonService.getIpcountry();

    dispatch(setIpcountry(ipcountry));
  } catch (error) {
    console.error(error);
  }
};

export const updateDeviceInfo = () => dispatch => {
  const deviceInfo = getDeviceInfo();
  deviceInfo && dispatch(setGeneralInfo({ deviceInfo }));
};

export const getPayPalToken = () => async dispatch => {
  const token = await CheckoutService.getPayPalToken();
  token && dispatch({ type: SET_PAYPAL_TOKEN, payload: token });
};

export const setPageIndexable = indexable => ({
  type: SET_PAGE_INDEXABLE,
  payload: indexable
});

export const setSizeType = sizeType => ({
  type: SET_SIZE_TYPE,
  payload: sizeType
});

export const setCountriesMobileDetails = details => ({
  type: SET_COUNTRIES_MOBILE_DETAILS,
  payload: details
});
export const setSelectedCountryMobileDetails = details => ({
  type: SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  payload: details
});

export const getCountriesMobileDetails = () => async dispatch => {
  try {
    const details = await CommonService.getCountriesMobileDetails();
    dispatch(setCountriesMobileDetails(details));
  } catch (error) {
    console.error(error);
  }
};

export const getMobileDetailsFromCountryList = language => async dispatch => {
  try {
    const countries = await CommonService.getShortCountryList(
      getLanguage(language)
    );
    const details = mapMobileDetails(countries);
    dispatch(setCountriesMobileDetails(details));
  } catch (error) {
    console.error(error);
  }
};

export const getDeliverySettings = currency => async (dispatch, getState) => {
  const { settingsHandle, deliverySettings } = BEConfig.commonApi;
  const state = getState();
  const stateCurrencyCode = get(state, "common.settings.currencyCode", "AED");
  const language = get(state, "common.language", "en");
  const apiURL = `${getVersionedCommonApiBaseUrl()}${settingsHandle}${deliverySettings}?currencyCode=${
    currency || stateCurrencyCode
  }`;
  dispatch(showLoadingIndicator());
  try {
    const response = await fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    );

    if (response.status === 200 && response.data) {
      dispatch(setDeliverySettings(response.data));
    }
    return response;
  } catch (error) {
    handleFetchError(error, dispatch);
    return error.response;
  } finally {
    dispatch(hideLoadingIndicator());
  }
};

export const getDeliverySettingsIfNeeded =
  currency => async (dispatch, getState) => {
    const state = getState();
    const { deliverySettings } = state.common.settings;
    if (!deliverySettings) {
      dispatch(getDeliverySettings(currency));
    }
  };

export const getCatalogCarouselData =
  ({ url, countryId, language, storeId }) =>
  async dispatch => {
    dispatch(showLoadingIndicator());

    try {
      const searchUrl = url.substring(1);
      const limit = PRODUCT_CAROUSEL_ITEMS_LIMIT;

      const response = await CatalogService.getPageData({
        url: searchUrl,
        countryId,
        language,
        storeId,
        limit
      });
      const { hits } = response.data;

      const carouselData = {
        enable: true,
        searchUrl: url,
        type: "product-carousel",
        [language]: { imageList: hits }
      };

      return carouselData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const setDeviceType = type => ({
  type: SET_DEVICE_TYPE,
  payload: type
});

const setConfigurationSettings = configs => ({
  type: SET_CONFIG_SETTINGS,
  configs
});

export const resetConfigurationSettings = () => ({
  type: RESET_CONFIG_SETTINGS
});

export const getConfigurationSettings = () => async (dispatch, getState) => {
  const { protocol, baseURL, port, versionInfo, configurations } =
    BEConfig.catalogApi;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${configurations}`;
  try {
    const response = await fetchWebApi(getAccessToken(getState), apiURL);

    if (response.status === 200 && response.data) {
      dispatch(setConfigurationSettings(response.data));
    }
    return response;
  } catch (error) {
    handleFetchError(error, dispatch);
    return error.response;
  }
};
