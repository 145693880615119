import React from "react";
import moment from "moment";
import Barcode from "react-barcode";
import cn from "classnames";
import { CircleFlag } from "react-circle-flags";
import { MEMBERSHIP_STYLE_CLASS } from "../../constants/loyalty-points";
import { LOYALTY_MEMBERSHIP_LEVELS } from "../../constants/loyalty-points";
import { isMobile, isMobileFooterView } from "../../util";
import bflIcon from "./shops-images/bfl-logo.svg";
import tflIcon from "./shops-images/tfl-logo.svg";
import hflIcon from "./shops-images/hfl-logo.svg";
import tchiboIcon from "./shops-images/tchibo-logo.svg";
import mmIcon from "./shops-images/muy-mucho-logo.svg";

class LoyaltyCard extends React.Component {
  render() {
    const {
      loyaltyDetails,
      myAccountTranslation: translation,
      countryCode,
      currencyCode,
      isArabic,
      routeSlug,
      countryShortCode
    } = this.props;
    const isMob = Boolean(isMobileFooterView() && isMobile.any());

    const cardLevel =
      MEMBERSHIP_STYLE_CLASS[loyaltyDetails.memeberLevel] === "yellow"
        ? translation.yellowTier
        : translation.blackTier;

    const blackCardLevel = LOYALTY_MEMBERSHIP_LEVELS.black;

    const showCutoffDate =
      Boolean(loyaltyDetails.cutoffDate) &&
      MEMBERSHIP_STYLE_CLASS[loyaltyDetails.memeberLevel] === blackCardLevel;

    return (
      <div
        className={cn("loyalty_card", {
          arabic: isArabic
        })}
      >
        <div
          className={`card-top-part ${
            MEMBERSHIP_STYLE_CLASS[loyaltyDetails.memeberLevel]
          }`}
        >
          <div className="card-info">
            <h3 className="tier-info">{cardLevel}</h3>
            <p className="country-info ">
              <span>
                <CircleFlag
                  countryCode={countryShortCode?.toLowerCase()}
                  width={18}
                />
              </span>
              {countryCode} | {currencyCode}
            </p>
          </div>
          {showCutoffDate && (
            <p className="cutoff-date">
              {translation.tierValidTo}{" "}
              {moment(loyaltyDetails.cutoffDate).format("MMM YYYY")}
            </p>
          )}
          <div className="benefits-info">
            <a
              href={`/${routeSlug}/loyaltyprogram/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <b> {translation.seeBenefits}</b>
            </a>
          </div>
        </div>
        <div className="card-bottom-part">
          <div className="bar-code">
            <Barcode
              value={loyaltyDetails.cardNumber}
              background="white"
              width={isMob ? 2.6 : 3.3}
              height={50}
              textAlign="right"
              fontSize={15}
              fontOptions="bold"
            />
          </div>
          <div className="loyalty-shops">
            <img src={bflIcon} alt="brand-for-less-icon" />
            <img src={tflIcon} alt="toys-for-less-icon" />
            <img src={tchiboIcon} alt="tchibo-icon" />
            <img src={hflIcon} alt="home-for-less-icon" />
            <img src={mmIcon} alt="muy-mucho-icon" />
          </div>
        </div>
      </div>
    );
  }
}

export default LoyaltyCard;
