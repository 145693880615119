import React, { useState, useCallback } from "react";
import HtmlParser from "../../components/html-parser";
import { GAService } from "../../services/GA-service";

const AccordionTile = ({ item, accordionHeading = "" }) => {
  const [isContent, setIsContent] = useState(false);
  const toggleContent = useCallback(
    () => setIsContent(!isContent),
    [isContent]
  );

  const handleAccordClick = () => {
    GAService.common.trackAccordionEvent({
      clickAccordion: item,
      accordion_name: accordionHeading,
      isActive: !isContent
    });
    toggleContent();
  };

  return (
    <div className="accordion">
      <div className="tile_acc" onClick={handleAccordClick}>
        <h4 className="accordion_heading">{item.title}</h4>
        <span className={`toggle-banner ${isContent ? "minus" : "plus"}`} />
      </div>
      {isContent && (
        <div className="accordion_content">
          <HtmlParser html={item.html} />
        </div>
      )}
    </div>
  );
};

export default AccordionTile;
