import React from "react";

const titleheader = ({ count, shopByBrandsTranslation }) => (
  <div className="content_heading">
    <div className="container">
      <h3 className="title">
        {shopByBrandsTranslation.shopByBrandTitle}{" "}
        {count > 0 ? (
          <span className="title-brands-count">
            {" "}
            {count} {shopByBrandsTranslation.total}
          </span>
        ) : (
          ""
        )}
      </h3>
    </div>
  </div>
);

export default titleheader;
