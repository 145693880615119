import { isEmpty, lowerCase } from "lodash";
import GAExtensions from "../extensions";
import { getCommerceDataForListingFormat } from "../utils";

class CartGAService extends GAExtensions {
  trackMiniCartHover = count => {
    if (Boolean(count)) {
      // only if the there is more than a single products.
      this.pushToDataLayer({
        event: "view_mini_cart"
      });
    }
  };

  trackAddProductToCart = data => {
    const { item, cartData = {} } = data;
    const { currencyCode } = this.getConfigData();
    const { payload: product, totalPrice } = getCommerceDataForListingFormat(
      { ...item, ...cartData },
      true
    );
    this.pushToDataLayer({
      event: "add_to_cart",
      ecommerce: {
        value: totalPrice,
        currency: currencyCode,
        items: [product]
      }
    });
  };

  trackAddProductToWishList = (data, isAfterCart = false) => {
    const { item, wishListData = {} } = data;
    const { currencyCode } = this.getConfigData();
    let { payload: product, totalPrice } = getCommerceDataForListingFormat(
      {
        ...item,
        ...wishListData
      },
      isAfterCart
    );
    if (!isEmpty(wishListData) && wishListData.size) {
      product.size = wishListData.size;
    }

    this.pushToDataLayer({
      event: "add_to_wishlist",
      ecommerce: {
        value: totalPrice,
        currency: currencyCode,
        items: [product]
      }
    });
  };

  removeProductFromWishList = product => {
    const productId = product.id || product.productId || "";
    const productName = product.en_title || product.title || "";
    this.pushToDataLayer({
      event: "remove_from_wishlist",
      item_id: `${productId}`,
      item_name: this.sanitizeString(productName)
    });
  };

  trackViewCartPage = data => {
    const { payload: products, totalPrice } = getCommerceDataForListingFormat(
      data,
      true
    );
    const { currencyCode } = this.getConfigData();
    this.pushToDataLayer({
      event: "view_cart",
      ecommerce: {
        value: totalPrice,
        currency: currencyCode,
        items: products
      }
    });
  };

  trackRemoveFromCart = data => {
    const { currencyCode } = this.getConfigData();
    const { payload: product, totalPrice } = getCommerceDataForListingFormat(
      data,
      true
    );

    this.pushToDataLayer({
      event: "remove_from_cart",
      ecommerce: {
        value: totalPrice,
        currency: currencyCode,
        items: [product]
      }
    });
  };
}

export default CartGAService;
