import { getState } from "../redux/store";
import { isServer } from "../util";
import { webapiPost } from "../webapis/core";
import ApiService from "./ApiService";
import { BEConfig } from "../config/env";

export class PostPayService extends ApiService {
  _isSdkLoaded = false;

  get client() {
    return window.postpay;
  }

  get sdkStatus() {
    return this._isSdkLoaded;
  }

  get isSdkLoaded() {
    return this._isSdkLoaded;
  }

  init() {
    const state = getState();
    const countrySHORT = state.common.settings.countrySHORT;

    this.client.init({
      merchantId: process.env.REACT_APP_POST_PAY_MERCHANT_ID || "",
      sandbox: true,
      theme: "light",
      locale: countrySHORT
    });
  }

  loadSdk() {
    if (this._isSdkLoaded) return Promise.resolve();

    return new Promise((res, rej) => {
      const script = document.createElement("script");
      script.src = "https://cdn.postpay.io/v1/js/postpay.js";

      if (!isServer) {
        window.postpayAsyncInit = () => {
          this.init();
        };
      }

      script.onload = () => {
        this._isSdkLoaded = true;

        res();
      };
      script.onerror = rej;

      document.body.appendChild(script);
    });
  }

  getBaseUrl() {
    const {
      baseURL,
      protocol,
      port,
      versionInfo,
      checkoutHandle
    } = BEConfig.checkoutApi;
    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
    return url;
  }

  async createOrder(order) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.checkoutApi.postpayVerifyPayment
    }`;
    const response = await webapiPost(this.getAccessToken(), endpoint, order)
      .request;

    return response;
  }
}

const instance = new PostPayService();

export default instance;
