import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import isEmpty from "lodash/isEmpty";
import { Image } from "../image";

const AllBrandsMobile = ({ shopBybrand, routeSlag, language }) => {
  params.rtl = language === "arabic" ? "rtl" : "";
  return (
    <Fragment>
      <Swiper {...params}>
        {!isEmpty(shopBybrand) &&
          shopBybrand.map((brandLogos, index) => (
            <div
              className="item-content brands_section"
              key={brandLogos._id || index}
            >
              <Link
                to={`/${routeSlag}${brandLogos.redirectionLink}`}
                className="brand_img_wrap"
              >
                <Image src={brandLogos.imageLink} alt="all-brand-logo-nike" />
              </Link>
              <div className="brand_content">
                <h5>{brandLogos.brandName}</h5>
                <p>{brandLogos.description}</p>
              </div>
            </div>
          ))}
      </Swiper>
    </Fragment>
  );
};

const params = {
  dots: true,
  slidesPerView: 2,
  rebuildOnUpdate: true,
  spaceBetween: 10,
  breakpoints: {
    800: {
      slidesPerView: 4,
      spaceBetween: 10
    },
    600: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    450: {
      slidesPerView: 2,
      spaceBetween: 10
    }
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  }
};

export default AllBrandsMobile;
