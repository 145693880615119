export const getTrabsformedSubfacet = subFacet => {
  const subItems = subFacet && subFacet.split(">");
  return subItems[
    (subItems.length && subItems.length !== 7
      ? subItems.length - 1
      : subItems.length - 4) || 0
  ];
};

export const getValidFacets = (facets = {}) => {
  //TODO: remove console.error after update data to valid
  const filteredEntries = Object.entries(facets).filter(([key]) => {
    if (!key.includes("  ")) return true;
    console.error(key);
    return false;
  });
  return Object.fromEntries(filteredEntries);
};

export const getFilterName = item => item.split(">")[0].trim();

export const getRangeObject = rangeObj => {
  const min = Math.round(rangeObj.min);
  const max = Math.round(rangeObj.max);
  return { min, max };
};
