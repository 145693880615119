import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import classnames from "classnames";
import SizeInternational from "../../size/size-international";
import { Scrollbars } from "react-custom-scrollbars";
import orderBy from "lodash/orderBy";
import {
  getSizeKeys,
  getMappedSizeObject,
  getSortedLvl0SizeObject,
  getSelectedSizeIndexObj,
  getSelectedFilters,
  getLvl0FilterArray,
  getLvl1FilterArray,
  getLvl2FilterObject,
  getLvl2FilterArray,
  getLvl2FilterData,
  getLvl2FilterDataArray,
  getLvl3FilterData
} from "../../../util/sizes";
import FilterRightPane from "../../filter/filter-content/filterRightPane";
import FilterLeftPane from "../../filter/filter-content/filterLeftPane";

class SizeCategoryTabs extends Component {
  state = {
    activeSlideKeyLvl1: "",
    activeSlideKeyLvl2: "",
    sizeOption: "",
    selectedLvl0Data: "",
    lvl0Slides: "",
    lvl1Slides: "",
    activeLvl: 0,
    slider1Width: 0,
    slider2Width: 0,
    slider1ArrowVisible: true,
    slider2ArrowVisible: true
  };

  componentDidUpdate() {
    this.setSliderWidth();
    this.setArrowVisible(this.props);
  }

  componentDidMount() {
    this.setSliderWidth();
    this.setArrowVisible(this.props);
  }

  refCallback1 = node => {
    if (node) {
      this.slider1Ref = node;
    }
  };

  refCallback2 = node => {
    if (node) {
      this.slider2Ref = node;
    }
  };

  calculateTotalOfInnerSlidesWidth = node => {
    //50 to accomodate slider btn
    let totalWidth = 50;
    if (
      node.innerSlider &&
      node.innerSlider.list &&
      node.innerSlider.list.childNodes &&
      node.innerSlider.list.childNodes.length &&
      node.innerSlider.list.childNodes[0].childNodes &&
      node.innerSlider.list.childNodes[0].childNodes.length
    ) {
      node.innerSlider.list.childNodes[0].childNodes.forEach(item => {
        if (item && item.clientWidth) {
          totalWidth += item.clientWidth;
        }
      });
    }
    return totalWidth;
  };

  setSliderWidth = () => {
    const { slider1Width, slider2Width } = this.state;
    let _slider1Width = slider1Width;
    let _slider2Width = slider2Width;
    if (this.slider1Ref) {
      _slider1Width = this.calculateTotalOfInnerSlidesWidth(this.slider1Ref);
    }
    if (this.slider2Ref) {
      _slider2Width = this.calculateTotalOfInnerSlidesWidth(this.slider2Ref);
    }
    if (_slider1Width !== slider1Width || _slider2Width !== slider2Width) {
      this.setState({
        slider1Width: _slider1Width,
        slider2Width: _slider2Width
      });
      let max = Math.max(300, _slider1Width, _slider2Width);
      this.props.sliderMaxWidth(max);
    }
  };

  setArrowVisible = props => {
    const { maxSliderWidth } = props;
    const {
      slider1Width,
      slider2Width,
      slider1ArrowVisible,
      slider2ArrowVisible
    } = this.state;

    let _slider1ArrowVisible = slider1Width > maxSliderWidth ? true : false;
    let _slider2ArrowVisible = slider2Width > maxSliderWidth ? true : false;

    if (
      slider1ArrowVisible !== _slider1ArrowVisible ||
      slider2ArrowVisible !== _slider2ArrowVisible
    ) {
      this.setState({
        slider1ArrowVisible: _slider1ArrowVisible,
        slider2ArrowVisible: _slider2ArrowVisible
      });
    }
  };

  handleActiveLevel = (activeLvl, item) => {
    this.setState({ activeLvl, selectedLvl0Data: item });
  };

  handleSizeLvl1Change = item =>
    this.setState({ activeSlideKeyLvl1: item, sizeOption: "" });

  handleSizeOptionChange = item => this.setState({ sizeOption: item });

  showFilterType = (selectedFilters, item) => {
    if (selectedFilters[item]) {
      return selectedFilters[item].toString();
    } else {
      return "All";
    }
  };

  handleLevelChange = e => {
    const { activeLvl } = this.state;
    const { handleSizeFilter, handleFilterToggle } = this.props;
    if (activeLvl) {
      this.setState({ activeLvl: activeLvl - 1 });
    } else {
      handleSizeFilter(false, false, true);
      handleFilterToggle(e, "", "mobile");
    }
  };

  sortByOrder = items => {
    try {
      return orderBy(items, ["sortOrderlvl2"], ["asc"]);
    } catch (error) {
      return items;
    }
  };

  render() {
    const {
      handleSizeChange,
      selectedSizeFilter,
      subFacet,
      handleFilterToggle,
      screenHeight
    } = this.props;
    const {
      activeLvl,
      activeSlideKeyLvl1,
      selectedLvl0Data,
      sizeOption,
      slider1ArrowVisible,
      slider2ArrowVisible
    } = this.state;
    let sizeFilterKeys = getSizeKeys(subFacet);
    const obj = getSortedLvl0SizeObject(
      getMappedSizeObject(subFacet, sizeFilterKeys)
    );

    sizeFilterKeys = sizeFilterKeys.sort();
    const selectedSizeIndex = getSelectedSizeIndexObj(obj, selectedSizeFilter);

    const selectedFilters = getSelectedFilters(subFacet);

    const lvl0Array = getLvl0FilterArray(obj);

    const lvl1Array = getLvl1FilterArray(obj, selectedSizeIndex.lvl0);
    const lvl2Obj = getLvl2FilterObject(
      obj,
      lvl1Array,
      selectedSizeIndex.lvl0,
      selectedSizeIndex.lvl1
    );

    const lvl2Array = getLvl2FilterArray(lvl2Obj);
    const level2Data = getLvl2FilterData(
      obj,
      selectedLvl0Data,
      activeSlideKeyLvl1
    );
    const _level2Data = getLvl2FilterDataArray(level2Data);

    const level3Data = getLvl3FilterData(
      obj,
      selectedLvl0Data,
      activeSlideKeyLvl1,
      sizeOption
    );

    const settingslvl0 = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      speed: 500,
      // slidesToShow: lvl0Array && lvl0Array.length > 4 ? 4 : lvl0Array.length || 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      // focusOnSelect: true,
      arrows: slider1ArrowVisible
    };

    const isFilterSingle = lvl1Array.length === 1;

    const settingslvl1 = {
      className: classnames("slider variable-width", {
        "fit-content-track": isFilterSingle
      }),
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      // slidesToShow: lvl1Array && lvl1Array.length > 4 ? 4 : lvl1Array.length || 1,
      slidesToScroll: 1,
      variableWidth: true,
      // focusOnSelect: true,
      arrows: slider2ArrowVisible
    };
    const screenWidth = document.body.clientWidth;
    return (
      <Fragment>
        {screenWidth > 800 ? (
          <li className="size_category_wrapper" ref={this.refCallback}>
            <div className="size_categories">
              <Slider
                {...settingslvl0}
                style={{ overflow: "hidden" }}
                ref={this.refCallback1}
              >
                {lvl0Array.map((item, index) => (
                  <div
                    key={index}
                    className={`size_categories_elements ${
                      index === selectedSizeIndex.lvl0 ? "tab_active" : ""
                    }`}
                    onClick={() => {
                      handleSizeChange("level0", item);
                      // this.handleSetActiveSlideKey(index, 'lvl0');
                    }}
                    title={item}
                  >
                    {item}
                  </div>
                ))}
              </Slider>
            </div>
            <div className="product_categories_wrapper">
              <div className="categories_listing">
                <Slider {...settingslvl1} ref={this.refCallback2}>
                  {lvl1Array.map((item, index) => (
                    <div
                      key={index}
                      className={`categories_listing_content ${
                        index === selectedSizeIndex.lvl1 ? "list_active" : ""
                      }`}
                      onClick={() => {
                        handleSizeChange("level1", item);
                        // this.handleSetActiveSlideKey(index, 'lvl1');
                      }}
                      title={item}
                    >
                      {item}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="size_selector">
              <Scrollbars autoHeight autoHeightMin={120} autoHeightMax={350}>
                {lvl2Array.map((item, index) => (
                  <SizeInternational
                    key={index}
                    header={item}
                    colorSizeOption={this.sortByOrder(lvl2Obj[item])}
                    handleSizeChange={handleSizeChange}
                  />
                ))}
              </Scrollbars>
            </div>
          </li>
        ) : (
          <div
            className="sizeFilterWrapper"
            style={{ height: screenHeight - 50 }}
          >
            <span
              className="size-chart-close-button"
              onClick={this.handleLevelChange}
            />
            {activeLvl === 0 &&
              lvl0Array.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`size_categories_elements ${
                      index === selectedSizeIndex.lvl0 ? "tab_active" : ""
                    }`}
                    onClick={() => {
                      handleSizeChange("level0", item);
                      this.handleActiveLevel(1, item);
                    }}
                    title={item}
                  >
                    <p className="sizeText">{item}</p>
                    <p className="selctedFilterText">
                      {this.showFilterType(selectedFilters, item)}
                    </p>
                  </li>
                );
              })}
            {activeLvl === 1 && (
              <Fragment>
                <div
                  className="sizeFilter_tabgroup"
                  style={{ height: screenHeight - 101 }}
                >
                  <FilterLeftPane
                    list={lvl1Array}
                    activeSlideKeyLvl1={activeSlideKeyLvl1}
                    handleSizeLvl1Change={this.handleSizeLvl1Change}
                    height={screenHeight}
                  />
                  <FilterRightPane
                    list={_level2Data}
                    l3list={this.sortByOrder(level3Data)}
                    handleSizeOptionChange={this.handleSizeOptionChange}
                    sizeOption={sizeOption}
                    handleSizeChange={handleSizeChange}
                    height={screenHeight}
                  />
                </div>
                <button
                  className="viewItems round_btn form_black_btn"
                  onClick={e => handleFilterToggle("", e, "apply")}
                >
                  View Items
                </button>
              </Fragment>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}
export default SizeCategoryTabs;
