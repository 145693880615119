import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import cn from "classnames";
import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { showModal, signInModal } from "../../redux/actions/homepage.action";
import { logOut } from "../../redux/actions/authConfig.action";
import BrandMenu from "../brands/brand-menu";
import CartHoverDropdown from "../cart/cart-hover-dropdown";
import { profileTranslation } from "../../language/profile/en/profile";
import { deleteCartData, fetchCartData } from "../../redux/actions/cart.action";
import {
  checkoutCart,
  resetCreditCards
} from "../../redux/actions/checkout.action";
import { isMobile, isServer, getBaseUrl } from "../../util";
import { getInternationalSizes } from "../../util/cart";
import { removeGuestUserStorage } from "../../util/browserStorage";
import { getDataAsObject } from "../../util/common";
import { settings_cart } from "../../config/app";
import {
  changeLanguage,
  getDeliverySettings,
  getDeliverySettingsIfNeeded
} from "../../redux/actions/common.action";
import { resetMyAccountDetails } from "../../redux/actions/myAccount.action";

import { resetTokenGeneratorCheckoutGuest } from "../../redux/actions/authConfig.action";
import AnalyticService from "../../services/analytic-service";
import withClientOnlyRender from "../../hocs/withClientOnlyRender";
import {
  selectCartGeneralCount,
  selectWishListGeneralCount,
  selectCdnImageSettings
} from "../../util/selectors";
import Loader from "../loader";
import { GOOGLE_MAPS_APIS_SRC, LOADER_TYPES } from "../../constants";
import { GAService } from "../../services/GA-service";
import { addScriptToBody } from "../../util/dom";

class UserProfile extends Component {
  state = {
    profileTranslation: profileTranslation,
    isToggle: false,
    openStore: false,
    isHover: false,
    needToUpdateCartData: false,
    isAddedNewProducts: false,
    isProfileHovered: false
  };
  hoverTimeout = null;

  handleMouseOver = () => {
    this.hoverTimeout = setTimeout(() => {
      GAService.header.trackNavClick("User Profile");
      clearTimeout(this.hoverTimeout);
    }, 850);
  };
  handleMouseOut = () => {
    clearTimeout(this.hoverTimeout);
  };

  handleClick = (isSignIn = false) => {
    this.setState({ mouseEnter: false });
    GAService.auth.trackSignCTA(isSignIn);
  };

  handleMyAccountClick = () => {
    if (isMobile.any()) {
      const { mouseEnter } = this.state;
      this.setState({ mouseEnter: !mouseEnter, openStore: false });
    }
  };

  handleToggleMyAccount = value => {
    if (!isMobile.any()) {
      this.setState({ mouseEnter: value, openStore: false });
    }
  };

  handleToggleStore = value => {
    if (!isMobile.any()) {
      this.setState({ openStore: value, mouseEnter: false });
    }
  };

  handleSelectStore = () => {
    this.setState({ openStore: false, mouseEnter: false });
  };

  handleStoreClick = () => {
    if (isMobile.any()) {
      const { openStore } = this.state;
      this.setState({ openStore: !openStore, mouseEnter: false });
    }
  };

  deleteCartList = (e, item) => {
    const { deleteCartData, authReducer, commonSettings } = this.props;

    let color;
    if (item.sizeId && item && item.colors && item.colors.colorSizeOptions) {
      color = item.colors.colorSizeOptions.find(e => e.sizeId === item.sizeId);
    }
    AnalyticService.cart.trackRemoveFromCart({
      item,
      authReducer,
      commonSettings,
      color
    });
    GAService.cart.trackRemoveFromCart({ ...item, color });

    deleteCartData(item.productId, item._id);
  };

  UNSAFE_componentWillMount() {
    const { match, language, handleLanguageChange } = this.props;
    if (!isServer) {
      let languageParam =
        (match && match.params && match.params.language) || language;
      if (languageParam.includes("ar-") || languageParam.includes("ar")) {
        languageParam = "ar";
      } else {
        languageParam = "en";
      }
      handleLanguageChange(languageParam);
      this.getlanguage(language);
    }
  }

  checkIsCartPageLocation() {
    const { location } = this.props;

    return location.pathname.includes("/my-cart");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      countryId,
      storeId,
      wishListAndCartBaseDataLoaded,
      cartGeneralCount
    } = this.props;
    const countryChanged = nextProps.countryId !== countryId;
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
    } else if (countryChanged || nextProps.storeId !== storeId) {
      this.setState({ openStore: false });
    }

    if (countryId && countryChanged) {
      this.setState({ needToUpdateCartData: true });
    }

    const isAddedNewProducts =
      wishListAndCartBaseDataLoaded &&
      nextProps.cartGeneralCount > cartGeneralCount;

    if (isAddedNewProducts) {
      const isCartPage = this.checkIsCartPageLocation();
      !isCartPage && this.setStatusOnAddingNewProduct(true);
    }
  }

  setStatusOnAddingNewProduct = isAddedNewProducts =>
    this.setState({ isAddedNewProducts });

  componentDidMount() {
    document.addEventListener(
      "click",
      this.handleClickOutsideProfile.bind(this)
    );
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      this.handleClickOutsideProfile.bind(this)
    );
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleClickOutsideProfile(e) {
    if (
      this.profileMenuRef &&
      !this.profileMenuRef.contains(e.target) &&
      !(
        e.target.className &&
        e.target.className.includes &&
        e.target.className.includes("dropdown_onlogin")
      )
    ) {
      this.setState({ mouseEnter: false });
    }
  }

  handleScroll = () => {
    if (this.state.mouseEnter) {
      const position = this.profileMenuRef.getBoundingClientRect();
      if (!(position.top >= 0 && position.bottom <= window.innerHeight)) {
        this.setState({ mouseEnter: false });
      }
    }
  };

  getlanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const profileResponse = import(
      `../../language/profile/${language}/profile.js`
    );
    profileResponse.then(response => {
      this.setState({ profileTranslation: response.profileTranslation });
    });
  };

  moveToCart = () => {
    const { history, language, commonSettings, getDeliverySettings, currency } =
      this.props;

    getDeliverySettings(currency);

    history.push(
      `/${language}-${
        (commonSettings &&
          commonSettings.countrySHORT &&
          commonSettings.countrySHORT.toLowerCase()) ||
        "ae"
      }/my-cart/`
    );
  };

  handleToggle = () => this.setState({ isToggle: !this.state.isToggle });

  onMouseEnterCart = e => {
    const {
      cartGeneralCount,
      cart,
      getDeliverySettingsIfNeeded,
      fetchCartData,
      wishListAndCartBaseDataLoaded
    } = this.props;

    const isNeedToFetchCartAfterCountryChanged =
      this.state.needToUpdateCartData && cartGeneralCount;
    if (
      (wishListAndCartBaseDataLoaded && cartGeneralCount && isEmpty(cart)) ||
      isNeedToFetchCartAfterCountryChanged
    ) {
      this.setState({ isLoadingStatusForCartIcon: true });
      fetchCartData().then(() =>
        this.setState({ isLoadingStatusForCartIcon: false })
      );

      this.setState({ needToUpdateCartData: false });
    }
    this.onCartHover(e.currentTarget, true);
    getDeliverySettingsIfNeeded();
  };
  onMouseLeaveCart = e => {
    this.onCartHover(e.currentTarget, false);
  };

  timerId = null;
  hoverDelay = 300;
  onCartHover = (node, isHover) => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
      return;
    }
    if (isHover) {
      this.timerId = setTimeout(() => {
        if (node && isHover) {
          GAService.cart.trackMiniCartHover(this.props?.cartGeneralCount);
          const wrapper = node.querySelector(".cart_hover_dropdown_wrapper");
          wrapper && wrapper.classList.add("active");
        }
        this.timerId = null;
      }, this.hoverDelay);
    } else {
      const wrapper = node.querySelector(".cart_hover_dropdown_wrapper");
      wrapper && wrapper.classList.remove("active");
    }
  };

  resetUserCredentials = () => {
    const {
      accessToken,
      history,
      logOut,
      resetMyAccountDetails,
      resetCreditCards
    } = this.props;
    if (accessToken) {
      logOut(history, true);
      GAService.auth.trackLogoutSuccess();
      resetMyAccountDetails();
      resetCreditCards();
    }
  };

  validateCheckout = () => {
    const {
      cart,
      currency,
      checkoutCart,
      deliverySettings,
      history,
      language,
      addressBook,
      commonSettings,
      resetTokenGeneratorCheckoutGuest
    } = this.props;
    const { selectedDelivery } = this.state;

    removeGuestUserStorage("");
    resetTokenGeneratorCheckoutGuest();

    const filteredDeliverySettings = filter(
      deliverySettings,
      setting => setting.paymentType === settings_cart
    );

    const filteredAddresses = filter(
      addressBook,
      addressBook =>
        addressBook.default === true ||
        addressBook.default === "true" ||
        addressBook.default === "Y"
    );

    const deliveryType =
      (selectedDelivery && selectedDelivery.key) ||
      get(filteredDeliverySettings, "[0].deliveryType", 0);

    const addressId = map(
      filteredAddresses,
      address => address.addressId
    ).sort()[filteredAddresses.length - 1];

    const orderToValidate = {
      deliveryType,
      addressId,
      currencyCode: currency,
      orderItems: map(cart, item => {
        const colorSizeOptions = get(item, "colors.colorSizeOptions", []);
        let availableSizes = colorSizeOptions.filter(
          option => option.sizeId === item.sizeId
        );
        let internationalSizes = {};

        getInternationalSizes(availableSizes, internationalSizes);

        if (
          item.IsMultisize &&
          Object.keys(internationalSizes) &&
          Object.keys(internationalSizes).length
        ) {
          availableSizes =
            internationalSizes[item.sizeType] ||
            internationalSizes[Object.keys(internationalSizes)[0]];
        }
        const { colorId, sizeId, upc, onlineStock } = availableSizes.find(
          option => option.sizeId === item.sizeId
        ) || { colorId: 0, sizeId: 0 };

        return {
          productId: item.productId,
          quantity: (item.quantity && parseInt(item.quantity)) || 1,
          colorid: colorId,
          sizeid: sizeId,
          size: item.size,
          price: item.currentPrice || item.price,
          upc: upc,
          onlineStock: (onlineStock && getDataAsObject(onlineStock)) || null,
          transactionPrice: item.currentPrice || item.price,
          transactionOldPrice: item.oldPrice,
          oldPrice: item.oldPrice
        };
      })
    };
    addScriptToBody(GOOGLE_MAPS_APIS_SRC, null, true);

    checkoutCart(
      orderToValidate,
      history,
      language,
      null,
      get(commonSettings, "countrySHORT", "ae").toLowerCase(),
      cart
    );
  };

  handleNavClick = clickTextThumb => {
    GAService.header.trackMyAccountOptionsClicked(
      profileTranslation[clickTextThumb]
    );
  };

  render() {
    const {
      userName,
      accessToken,
      language,
      wishListCount,
      cart,
      currency,
      commonSettings,
      location,
      configCdnImagesSettings,
      cartGeneralCount
    } = this.props;
    const {
      mouseEnter,
      profileTranslation,
      openStore,
      isAddedNewProducts,
      isLoadingStatusForCartIcon
    } = this.state;
    const isMob = !!(isMobile.any() && isMobile.any().length);

    return (
      <div className="profile_options">
        <div className="brand_stores">
          <div
            className={
              openStore
                ? `store_menu_wrapper open_stores`
                : `store_menu_wrapper`
            }
            onMouseEnter={() => this.handleToggleStore(true)}
            onMouseLeave={() => this.handleToggleStore(false)}
            onClick={this.handleStoreClick}
          >
            <span className="hamburger_menu" />
            <span>{profileTranslation.shopByStores}</span>
            <div className="brandMenuWrapper">
              <BrandMenu
                handleSelectStore={this.handleSelectStore}
                handleToggle={this.handleToggle}
              />
            </div>
          </div>
          <div className={`store_overlay ${openStore ? "active" : ""}`}>
            &nbsp;
          </div>
        </div>

        <div
          className={
            mouseEnter
              ? `profile dropdown_onlogin ${
                  accessToken ? "user_after_login" : ""
                }`
              : `profile ${accessToken ? "user_after_login" : ""}`
          }
          ref={ref => (this.profileMenuRef = ref)}
          onMouseEnter={() => {
            this.handleToggleMyAccount(true);
            this.handleMouseOver();
          }}
          onMouseLeave={() => {
            this.handleToggleMyAccount(false);
            this.handleMouseOut();
          }}
          onClick={this.handleMyAccountClick}
        >
          <div className="header_dropdown">
            <div className="headerDropdownWrapper">
              <ul
                className={
                  accessToken
                    ? "user_profile_dropdown logged_in"
                    : "user_profile_dropdown"
                }
              >
                <li>
                  <div>
                    {accessToken ? (
                      <a className="signed_in">
                        <div className="greet-user">
                          <Fragment>
                            <span>{profileTranslation.hello} </span>
                            {`${userName && userName.replace("Welcome", "Hi")}`}
                          </Fragment>{" "}
                        </div>
                      </a>
                    ) : (
                      <Link
                        to={`/${language}-${
                          (commonSettings &&
                            commonSettings.countrySHORT &&
                            commonSettings.countrySHORT.toLowerCase()) ||
                          "ae"
                        }/login/`}
                        onClick={() => this.handleClick(true)}
                        className="user_greeting"
                      >
                        <span className="user_sign_in" id="signIn">
                          {profileTranslation.signIn}
                        </span>
                      </Link>
                    )}
                    {accessToken ? (
                      <a
                        id="signUp"
                        onClick={() => this.resetUserCredentials()}
                      >
                        <Fragment>
                          <span>{profileTranslation.signOut}</span>
                        </Fragment>
                      </a>
                    ) : (
                      <Link
                        to={`/${language}-${
                          (commonSettings &&
                            commonSettings.countrySHORT &&
                            commonSettings.countrySHORT.toLowerCase()) ||
                          "ae"
                        }/sign-up/`}
                        onClick={() => this.handleClick(false)}
                      >
                        <span className="user_join">
                          {profileTranslation.join}
                        </span>
                      </Link>
                    )}
                  </div>
                </li>
                <li>
                  <Link
                    to={
                      accessToken
                        ? `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/my-account/account-overview/`
                        : `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/login/?my-account/account-overview/`
                    }
                    onClick={() => this.handleNavClick("myAccount")}
                  >
                    {profileTranslation.myAccount}
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      accessToken
                        ? `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/my-account/my-orders/`
                        : `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/login/?my-account/my-orders/`
                    }
                    onClick={() => this.handleNavClick("myOrders")}
                  >
                    {profileTranslation.myOrders}
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      accessToken
                        ? `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/my-account/my-orders/`
                        : `/${language}-${
                            (commonSettings &&
                              commonSettings.countrySHORT &&
                              commonSettings.countrySHORT.toLowerCase()) ||
                            "ae"
                          }/track-order/`
                    }
                    onClick={() => this.handleNavClick("trackOrders")}
                  >
                    {profileTranslation.trackOrders}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Link
          to={{
            pathname: `${getBaseUrl(this.props)}/wishlist/`,
            state: { prevLocation: location.pathname + location.search }
          }}
          className="favorite"
        >
          <span className="favorite_icon" />
          {!!wishListCount && (
            <span className="items_in_bucket">{wishListCount}</span>
          )}
        </Link>
        <div
          className="cart"
          onMouseEnter={this.onMouseEnterCart}
          onMouseLeave={this.onMouseLeaveCart}
        >
          {isLoadingStatusForCartIcon && !isMob && (
            <Loader
              type={LOADER_TYPES.DOTS}
              visible
              style={loaderStyle}
              size={10}
            />
          )}
          <div
            className={cn({
              invisible: isLoadingStatusForCartIcon && !isMob
            })}
          >
            <span className="cart_icon" onClick={this.moveToCart} />
            {Boolean(cartGeneralCount) && (
              <Fragment>
                <span className="items_in_bucket" onClick={this.moveToCart}>
                  {cartGeneralCount >= 100 ? "99+" : cartGeneralCount}
                </span>
                {!isMob && (
                  <CartHoverDropdown
                    language={language}
                    translation={profileTranslation}
                    cart={cart}
                    deleteCartList={this.deleteCartList}
                    validateCheckout={this.validateCheckout}
                    currencyCode={currency}
                    moveToCart={this.moveToCart}
                    showCrossIcon={true}
                    commonSettings={commonSettings}
                    isAddedNewProducts={isAddedNewProducts}
                    setStatusOnAddingNewProduct={
                      this.setStatusOnAddingNewProduct
                    }
                    configCdnImagesSettings={configCdnImagesSettings}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const loaderStyle = {
  top: "0px",
  left: "15px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "inherit",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const mapStateToProps = state => ({
  accessToken: state.authReducer.accessToken,
  userName: state.authReducer.firstname,
  language: state.common.language,
  wishListCount: selectWishListGeneralCount(state),
  cart: state.cartReducer.cart,
  currency: state.common.settings && state.common.settings.currencyCode,
  deliverySettings:
    state.common.settings && state.common.settings.deliverySettings,
  addressBook: state.authReducer.addressBook,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  configCdnImagesSettings: selectCdnImageSettings(state),
  countryId: state.common.settings.countryId,
  storeId: state.page.homepageState.storeId,
  cartGeneralCount: selectCartGeneralCount(state),
  wishListAndCartBaseDataLoaded: state.cartReducer.wishListAndCartGeneralLoaded
});

const mapDispatchToProps = disptach => ({
  handleLanguageChange: bindActionCreators(changeLanguage, disptach),
  showModal: bindActionCreators(showModal, disptach),
  signInModal: bindActionCreators(signInModal, disptach),
  logOut: bindActionCreators(logOut, disptach),
  deleteCartData: bindActionCreators(deleteCartData, disptach),
  checkoutCart: bindActionCreators(checkoutCart, disptach),
  fetchCartData: bindActionCreators(fetchCartData, disptach),
  resetMyAccountDetails: bindActionCreators(resetMyAccountDetails, disptach),
  resetCreditCards: bindActionCreators(resetCreditCards, disptach),
  resetTokenGeneratorCheckoutGuest: bindActionCreators(
    resetTokenGeneratorCheckoutGuest,
    disptach
  ),
  getDeliverySettingsIfNeeded: bindActionCreators(
    getDeliverySettingsIfNeeded,
    disptach
  ),
  getDeliverySettings: bindActionCreators(getDeliverySettings, disptach)
});

export default compose(
  withClientOnlyRender,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(UserProfile);
