export const GET_CART_LIST = "GET_CART_LIST";
export const UPDATE_CART_LIST = "UPDATE_CART_LIST";
export const DELETE_CART_LIST = "DELETE_CART_LIST";
export const UPDATE_CART_SIZE_QTY = "UPDATE_CART_SIZE_QTY";
export const RESET_PRODUCT_MERGE = "RESET_PRODUCT_MERGE";
export const SET_SAVING_INDICATOR = "SET_SAVING_INDICATOR";
export const CLEAR_PREV_VALUE = "CLEAR_PREV_VALUE";
export const SET_CART_AND_WISHLIST_GENERAL = "SET_CART_AND_WISHLIST_GENERAL";
export const CLEAR_CART_AND_WISHLIST_GENERAL =
  "CLEAR_CART_AND_WISHLIST_GENERAL";
export const WISHLIST_GENERAL_LOADING = "WISHLIST_GENERAL_LOADING";
export const DELETE_WISHLIST_ITEM_LOADING = "DELETE_WISHLIST_ITEM_LOADING";
