import {
  GET_WISHLISTS,
  SET_WISHLISTS_BASE_DATA,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_PAGINATION,
  UPDATE_WISHLISTS,
  DELETE_WISHLIST,
  DELETE_WISHLIST_ITEM_LOADING,
  SELECTED_SIZE,
  WISHLIST_BASE_DATA_LOADING
} from "../constants";

const initialState = {
  wishList: [],
  wishListBaseData: [],
  wishListBaseDataLoaded: false,
  deleteWishListItemLoaded: false,
  length: 0,
  pagination: {},
  wishListAndCartGeneralLoaded: false,
  deleteWishListItemLoaded: false
};

const getWishList = (state, { wishList }) => ({ ...state, wishList });
const setWishlistLength = (state, { length }) => ({ ...state, length });
const wishListBaseDataLoading = state => ({
  ...state,
  wishListBaseDataLoaded: false
});
const deleteWishListItemLoading = state => ({
  ...state,
  deleteWishListItemLoaded: false
});
const setWishlistBaseData = (state, { wishListBaseData }) => ({
  ...state,
  wishListBaseData,
  wishListBaseDataLoaded: true
});
const setWishlistPagination = (state, { pagination }) => ({
  ...state,
  pagination
});

const updateWishList = (state, { wishList }) => ({
  ...state,
  wishListBaseData: [wishList, ...state.wishListBaseData]
});

const deleteWishList = (state, { id }) => {
  const _wishList = state.wishListBaseData.filter(o => o._id !== id);
  return {
    ...state,
    wishListBaseData: _wishList,
    length: _wishList.length,
    deleteWishListItemLoaded: true
  };
};

const selectedSize = (state, { selectedSizeValue, sizeId, sizeType, _id }) => ({
  ...state,
  wishList: state.wishList.map(product => {
    if (product._id === _id) {
      return { ...product, size: selectedSizeValue, sizeId, sizeType };
    }
    return product;
  })
});

const wishListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WISHLISTS:
      return getWishList(state, action);
    case SET_WISHLISTS_BASE_DATA:
      return setWishlistBaseData(state, action);
    case WISHLIST_BASE_DATA_LOADING:
      return wishListBaseDataLoading(state);
    case SET_WISHLIST_LENGTH:
      return setWishlistLength(state, action);
    case SET_WISHLIST_PAGINATION:
      return setWishlistPagination(state, action);
    case UPDATE_WISHLISTS:
      return updateWishList(state, action);
    case DELETE_WISHLIST:
      return deleteWishList(state, action);
    case DELETE_WISHLIST_ITEM_LOADING:
      return deleteWishListItemLoading(state);
    case SELECTED_SIZE:
      return selectedSize(state, action);
    default:
      return state;
  }
};

export default wishListReducer;
