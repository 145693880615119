export const errorListing = {
  mainHead: "This page could not be found",
  headDesc: "This page either doesn’t exist, or it moved somewhere else",
  clickHere: "Click here",
  goBack: "Go Back",
  prevText: "To go back to the previous page and try again",
  prevTextMobile: "Go back to previous page and try again",
  shopMsg: "Perhaps you would like to try one of these pages.",
  shopWomen: "SHOP FOR WOMEN",
  shopMen: "SHOP FOR MEN",
  shopKids: "SHOP FOR KIDS",
  serviceHead: "Service Not Available",
  serviceDesc: "There is some problem with the service which is being accessed",
  serverErrorHead: "Something went wrong",
  serverErrorDesc: "The server has been deserted for a while.",
  serverErrorText: "Please be patient or try again later.",
  retry: "Retry"
};
