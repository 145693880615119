import {
  SET_CHECKOUT_DATA,
  SET_CHECKOUT_ORDER,
  CHANGE_CHECKOUT_DATA,
  CLEAR_CHECKOUT_DATA,
  SET_MAP_FILTER,
  MAP_DELIVERY_ADDRESS_CHECKOUT,
  SAVE_CARD_FOR_FUTURE,
  SET_PAYMENT_DATA,
  SELECTED_SAVED_CARD_DATA,
  POPULATE_VOUCHER_CODES,
  REMOVE_APPLIED_VOUCHER,
  HANDLE_CARD_ADDITION,
  M_SELECTED_SAVED_CARD_DATA,
  CLEAR_GUEST_EMAIL,
  CHANGE_MAP_ADDRESS_PHONE,
  NUMBER_EN,
  RESET_MAP_DELIVERY_ADDRESS,
  MINIMUM_TWO_CHARS_EN,
  SET_STANDARD_DELIVERY_ADDRESS,
  RESET_STANDARD_DELIVERY_ADDRESS,
  SET_MAP_DELIVERY_ADDRESS,
  RESET_CARD_LIST,
  INSTORE_DELIVERY_ADDRESS_CHECKOUT,
  SET_INSTORE_PICKUP_LIST,
  RESET_INSTORE_DELIVERY_ADDRESS,
  SET_CHECKOUT_REQUEST_DATA,
  SET_INSTORE_DELIVERY_ADDRESS,
  SET_CHECKOUT_COM_FORM_VALIDATION_STATUS,
  SET_CREDIT_CARDS,
  RESET_CREDIT_CARDS,
  SET_SELECTED_CARD_CVV,
  CLEAR_SELECTED_CARD_CVV,
  SET_BIN_CODE,
  CLEAR_BIN_CODE,
  SHOW_CHECKOUT_SUMMARY_LOADER,
  HIDE_CHECKOUT_SUMMARY_LOADER,
  SET_SUMMARY_RECALCULATION_IN_PROGRESS,
  RESET_PAYMENT_METHOD,
  SET_PAYMENT_STATUS_RESPONSE,
  SET_IS_PICK_UP_FORM_VALID,
  RESET_IS_PICK_UP_FORM_VALID,
  RESET_MAP_FILTER_DATA
} from "../constants";
import validator from "validator";
import get from "lodash/get";
import trim from "lodash/trim";

const initialState = {
  checkoutData: {},
  checkoutOrder: {},
  mapFilterData: {},
  mapDeliveryAddress: {
    firstname: "",
    areaId: "",
    addressLine1: "",
    collectionPointId: "",
    phone: "",
    cityId: "" || 0,
    area: "",
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      },
      firstname: {
        valid: true,
        validWhen: true,
        check: "isByteLength",
        message: MINIMUM_TWO_CHARS_EN,
        options: {
          min: 2,
          max: undefined
        }
      }
    },
    areaCode: "",
    site_id: "",
    lat: "",
    lng: ""
  },
  standardDeliveryAddress: {},
  inStoreDeliveryAddress: {
    firstname: "",
    areaId: "",
    addressLine1: "",
    collectionPointId: "",
    phone: "",
    cityId: "" || 0,
    area: "",
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      },
      firstname: {
        valid: true,
        validWhen: true,
        check: "isByteLength",
        message: MINIMUM_TWO_CHARS_EN,
        options: {
          min: 2,
          max: undefined
        }
      }
    },
    areaCode: "",
    site_id: "",
    lat: "",
    lng: ""
  },
  inStoreList: [],
  isSavedCard: false,
  savedCardList: [],
  selectedSavedCard: null,
  selectedMSavedCard: null,
  areaCode: "",
  checkoutComFormValidationStatus: false,
  creditCardList: [],
  selectedCardCvv: "",
  binNo: "",
  showCheckoutSummaryLoader: false,
  summaryRecalculationInProgress: false,
  paymentStatusResponse: {},
  isPickUpFormValid: {
    storePickUp: false,
    selfPickUp: false
  }
};

const setCheckoutData = (state, action) => ({
  ...state,
  checkoutData: { ...state.checkoutData, ...action.checkoutData }
});

const setPaymentData = state => ({
  ...state,
  checkoutData: {
    ...state.checkoutData,
    selectedPayment: [],
    useMyWallet: false
  }
});
const setCheckoutOrder = (state, { apiResponse }) => ({
  ...state,
  checkoutOrder: apiResponse
});
const setMapFilter = (state, { apiResponse }) => ({
  ...state,
  mapFilterData: apiResponse
});
const setDeliveryAddFromMap = (state, { address }) => ({
  ...state,
  mapDeliveryAddress: {
    ...state.mapDeliveryAddress,
    areaId: address.bfl_area_id || -1,
    addressLine1: address.station_name.trim(),
    addressLine2: address.address_info.trim(),
    collectionPointId: address.station_id || address.collectionPointId,
    // phone : address.phone || '',
    cityId: address.bfl_state_id || -1,
    area: address.area_name.trim(),
    lat: address.lat,
    lng: address.lng,
    site_id: address.site_id,
    city: address?.city,
    workingHours: address.workingHours
  }
});

const setInstoreDeliveryAddFromMap = (state, { address }) => ({
  ...state,
  inStoreDeliveryAddress: {
    ...state.inStoreDeliveryAddress,
    areaId: address.bfl_area_id,
    addressLine1: trim(get(address, "station_name", "")).concat(
      ", ",
      trim(get(address, "address_info", ""))
    ),
    station_name: trim(get(address, "station_name", "")),
    addressLine2: trim(get(address, "address_info", "")),
    collectionPointId: address.station_id,
    cityId: get(address, "stateId", 0),
    extra_info: trim(get(address, "extra_info", "")),
    lat: address.lat,
    lng: address.lng,
    site_id: address.site_id,
    id: address.id,
    city: address?.city
  }
});

const setInStorePickupData = (state, action) => ({
  ...state,
  inStoreList: action.storeList
});

const changeCheckoutData = (state, { name, value }) => ({
  ...state,
  checkoutData: {
    ...state.checkoutData,
    [name]: value
  }
});

const clearCheckoutData = state => ({ ...state, checkoutData: {} });

const resetCardList = state => ({ ...state, savedCardList: [] });

const saveCardForFuture = (state, { value }) => ({
  ...state,
  isSavedCard: value
});

const selectedSavedCardData = (state, { selectedData }) => ({
  ...state,
  selectedSavedCard: selectedData
});

const selectedMSavedCardData = (state, { selectedData }) => ({
  ...state,
  selectedMSavedCard: selectedData,
  selectedSavedCard: null
});

const handleGuestEmailRemoval = state => ({
  ...state,
  checkoutData: { ...state.checkoutData, guestEmail: "" }
});

const populateVoucherCodes = (state, { VoucherCodes }) => ({
  ...state,
  VoucherCodes
});

const removeAppliedVoucher = state => ({
  ...state,
  checkoutData: {
    ...state.checkoutData,
    voucherCode: "",
    discount: 0,
    discount_type: "",
    voucherNo: ""
  }
});

const handleCardAddition = (state, { paymentDetails }) => ({
  ...state,
  savedCardList: [...state.savedCardList, { ...paymentDetails }]
});

const handleIntlInputTextChange = (state, action) => {
  if (action.deliveryType === "SELF_PICKUP") {
    let transformedState = {
      ...state,
      mapDeliveryAddress: {
        ...state.mapDeliveryAddress,
        [action.name]: action.value
      }
    };
    if (transformedState.mapDeliveryAddress.errors[action.name]) {
      transformedState.mapDeliveryAddress.errors = {
        ...state.mapDeliveryAddress.errors,
        [action.name]: state.mapDeliveryAddress.errors[action.name]
          ? {
              ...state.mapDeliveryAddress.errors[action.name],
              touched: true,
              valid: state.mapDeliveryAddress.errors[action.name].check
                ? validator[state.mapDeliveryAddress.errors[action.name].check](
                    action.value ? action.value.toString() : "",
                    state.mapDeliveryAddress.errors[action.name].options
                  ) ===
                    state.mapDeliveryAddress.errors[action.name].validWhen &&
                  (typeof action.status !== "undefined" ? action.status : true)
                : true
            }
          : { touched: true }
      };
    }
    return transformedState;
  } else if (action.deliveryType === "STORE_PICKUP") {
    let transformedState = {
      ...state,
      inStoreDeliveryAddress: {
        ...state.inStoreDeliveryAddress,
        [action.name]: action.value
      }
    };
    if (transformedState.inStoreDeliveryAddress.errors[action.name]) {
      transformedState.inStoreDeliveryAddress.errors = {
        ...state.inStoreDeliveryAddress.errors,
        [action.name]: state.inStoreDeliveryAddress.errors[action.name]
          ? {
              ...state.inStoreDeliveryAddress.errors[action.name],
              touched: true,
              valid: state.inStoreDeliveryAddress.errors[action.name].check
                ? validator[
                    state.inStoreDeliveryAddress.errors[action.name].check
                  ](
                    action.value ? action.value.toString() : "",
                    state.mapDeliveryAddress.errors[action.name].options
                  ) ===
                    state.mapDeliveryAddress.errors[action.name].validWhen &&
                  (typeof action.status !== "undefined" ? action.status : true)
                : true
            }
          : { touched: true }
      };
    }
    return transformedState;
  }
};

const resetMapDeliveryAddress = state => ({
  ...state,
  mapDeliveryAddress: {
    firstname: "",
    areaId: "",
    addressLine1: "",
    collectionPointId: "",
    phone: "",
    cityId: "" || 0,
    area: "",
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      },
      firstname: {
        valid: true,
        validWhen: true,
        check: "isByteLength",
        message: MINIMUM_TWO_CHARS_EN,
        options: {
          min: 2,
          max: undefined
        }
      }
    },
    areaCode: "",
    lat: "",
    lng: ""
  }
});

const resetInStoreDeliveryAddress = state => ({
  ...state,
  inStoreDeliveryAddress: {
    firstname: "",
    areaId: "",
    addressLine1: "",
    collectionPointId: "",
    phone: "",
    cityId: "" || 0,
    area: "",
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      },
      firstname: {
        valid: true,
        validWhen: true,
        check: "isByteLength",
        message: MINIMUM_TWO_CHARS_EN,
        options: {
          min: 2,
          max: undefined
        }
      }
    },
    areaCode: "",
    site_id: "",
    lat: "",
    lng: ""
  }
});

const resetStandardDeliveryAddress = state => ({
  ...state,
  standardDeliveryAddress: {}
});

const setStandardDeliveryAddress = (state, action) => ({
  ...state,
  standardDeliveryAddress: action.address
});

const setMapDeliveryAddress = (state, { address }) => ({
  ...state,
  mapDeliveryAddress: {
    ...state.mapDeliveryAddress,
    areaId: address?.areaId,
    addressLine1: trim(get(address, "addressLine1", "")),
    addressLine2: trim(get(address, "addressLine2", "")),
    collectionPointId: get(address, "collectionPointId"),
    phone: get(address, "phone", ""),
    firstname: get(address, "firstname", ""),
    lastname: get(address, "lastname", ""),
    cityId: get(address, "cityId", 0),
    area: get(address, "area", "").trim(),
    extra_info: get(address, "extra_info", "").trim(),
    lat: get(address, "lat", ""),
    lng: get(address, "lng", ""),
    site_id: get(address, "site_id", "")
  }
});

const setInstoreDeliveryAddress = (state, { address }) => ({
  ...state,
  inStoreDeliveryAddress: {
    ...state.inStoreDeliveryAddress,
    areaId: address?.bfl_area_id,
    addressLine1: trim(get(address, "addressLine1", "")),
    addressLine2: trim(get(address, "addressLine2", "")),
    collectionPointId: get(address, "collectionPointId"),
    phone: get(address, "phone", ""),
    firstname: get(address, "firstname", ""),
    lastname: get(address, "lastname", ""),
    cityId: get(address, "cityId", 0),
    area: get(address, "area", "").trim(),
    extra_info: get(address, "extra_info", "").trim(),
    lat: get(address, "lat", ""),
    lng: get(address, "lng", ""),
    site_id: get(address, "site_id", ""),
    station_name: trim(get(address, "station_name", "")),
    id: get(address, "id"),
    city: address?.city || ""
  }
});

const setCheckoutRequestData = (state, action) => ({
  ...state,
  checkoutRequestData: action.checkoutRequestData
});

const setCheckoutComFormValidationStatus = (state, action) => ({
  ...state,
  checkoutComFormValidationStatus: action.payload
});

const setCreditCards = (state, action) => ({
  ...state,
  creditCardList: action.payload
});

const resetCreditCards = state => ({
  ...state,
  creditCardList: initialState.creditCardList
});

const resetMapFilterData = state => ({
  ...state,
  mapFilterData: {}
});

const setSelectedCardCvv = (state, action) => ({
  ...state,
  selectedCardCvv: action.payload
});

const clearSelectedCardCvv = state => ({
  ...state,
  selectedCardCvv: ""
});

const setBinCode = (state, action) => {
  return {
    ...state,
    binNo: action.binNo
  };
};

const clearBinCode = state => ({
  ...state,
  binNo: ""
});

const showCheckoutSummaryLoadingIndicator = state => ({
  ...state,
  showCheckoutSummaryLoader: true
});

const hideCheckoutSummaryLoadingIndicator = state => ({
  ...state,
  showCheckoutSummaryLoader: false
});
const setSummaryRecalculationInProgress = (state, action) => ({
  ...state,
  summaryRecalculationInProgress: action.payload
});

const resetPaymentMethod = state => ({
  ...state,
  checkoutData: {
    ...state.checkoutData,
    selectedPayment: [],
    selectedPaymentOption: {}
  }
});

const setPaymentStatusResponse = (state, action) => ({
  ...state,
  paymentStatusResponse: action.payload
});

const setIsPickUpFormValid = (state, { formName, isFormValid }) => ({
  ...state,
  isPickUpFormValid: {
    ...state.isPickUpFormValid,
    [formName]: isFormValid
  }
});

const resetIsPickUpFormValid = state => ({
  ...state,
  isPickUpFormValid: initialState.isPickUpFormValid
});

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHECKOUT_DATA:
      return setCheckoutData(state, action);
    case SET_PAYMENT_DATA:
      return setPaymentData(state, action);
    case CHANGE_CHECKOUT_DATA:
      return changeCheckoutData(state, action);
    case CLEAR_CHECKOUT_DATA:
      return clearCheckoutData(state);
    case SET_CHECKOUT_ORDER:
      return setCheckoutOrder(state, action);
    case SET_MAP_FILTER:
      return setMapFilter(state, action);
    case MAP_DELIVERY_ADDRESS_CHECKOUT:
      return setDeliveryAddFromMap(state, action);
    case SAVE_CARD_FOR_FUTURE:
      return saveCardForFuture(state, action);
    case POPULATE_VOUCHER_CODES:
      return populateVoucherCodes(state, action);
    case SELECTED_SAVED_CARD_DATA:
      return selectedSavedCardData(state, action);
    case M_SELECTED_SAVED_CARD_DATA:
      return selectedMSavedCardData(state, action);
    case REMOVE_APPLIED_VOUCHER:
      return removeAppliedVoucher(state);
    case HANDLE_CARD_ADDITION:
      return handleCardAddition(state, action);
    case CLEAR_GUEST_EMAIL:
      return handleGuestEmailRemoval(state, action);
    case CHANGE_MAP_ADDRESS_PHONE:
      return handleIntlInputTextChange(state, action);
    case RESET_MAP_DELIVERY_ADDRESS:
      return resetMapDeliveryAddress(state);
    case RESET_INSTORE_DELIVERY_ADDRESS:
      return resetInStoreDeliveryAddress(state);
    case SET_STANDARD_DELIVERY_ADDRESS:
      return setStandardDeliveryAddress(state, action);
    case RESET_STANDARD_DELIVERY_ADDRESS:
      return resetStandardDeliveryAddress(state);
    case SET_MAP_DELIVERY_ADDRESS:
      return setMapDeliveryAddress(state, action);
    case SET_INSTORE_DELIVERY_ADDRESS:
      return setInstoreDeliveryAddress(state, action);
    case RESET_CARD_LIST:
      return resetCardList(state);
    case INSTORE_DELIVERY_ADDRESS_CHECKOUT:
      return setInstoreDeliveryAddFromMap(state, action);
    case SET_INSTORE_PICKUP_LIST:
      return setInStorePickupData(state, action);
    case SET_CHECKOUT_REQUEST_DATA:
      return setCheckoutRequestData(state, action);
    case SET_CHECKOUT_COM_FORM_VALIDATION_STATUS:
      return setCheckoutComFormValidationStatus(state, action);
    case SET_CREDIT_CARDS:
      return setCreditCards(state, action);
    case RESET_CREDIT_CARDS:
      return resetCreditCards(state);
    case SET_SELECTED_CARD_CVV:
      return setSelectedCardCvv(state, action);
    case CLEAR_SELECTED_CARD_CVV:
      return clearSelectedCardCvv(state, action);
    case SET_BIN_CODE:
      return setBinCode(state, action);
    case CLEAR_BIN_CODE:
      return clearBinCode(state, action);
    case SHOW_CHECKOUT_SUMMARY_LOADER:
      return showCheckoutSummaryLoadingIndicator(state);
    case HIDE_CHECKOUT_SUMMARY_LOADER:
      return hideCheckoutSummaryLoadingIndicator(state);
    case SET_SUMMARY_RECALCULATION_IN_PROGRESS:
      return setSummaryRecalculationInProgress(state, action);
    case RESET_PAYMENT_METHOD:
      return resetPaymentMethod(state);
    case SET_PAYMENT_STATUS_RESPONSE:
      return setPaymentStatusResponse(state, action);
    case SET_IS_PICK_UP_FORM_VALID:
      return setIsPickUpFormValid(state, action);
    case RESET_IS_PICK_UP_FORM_VALID:
      return resetIsPickUpFormValid(state);
    case RESET_MAP_FILTER_DATA:
      return resetMapFilterData(state);

    default:
      return state;
  }
};

export default checkoutReducer;
