import React, { Component } from "react";
import get from "lodash/get";
import cn from "classnames";
import { getDividedNumber } from "../../../util/phoneNumber";
import loyaltyRedeemPresent from "../../../images/loyalty-redeem-present.svg";

class LoyaltyRedeemInfo extends Component {
  render() {
    const {
      loyaltyDetails,
      userInfo,
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength,
      currencyCode,
      isArabic,
      translation
    } = this.props;

    const phoneDetails = {
      phone: get(userInfo, "phone", ""),
      mobileCountryCode,
      mobileLocalCode,
      mobileLocalNumberLength
    };

    const { phoneWithoutCountryCode } = getDividedNumber(phoneDetails);

    return (
      <div
        className={cn("loyalty-redeem-info", {
          arabic: isArabic
        })}
      >
        <div className="user-info info-rectangle">
          <h4 className="user-name">
            {userInfo.firstname} {userInfo.lastname && userInfo.lastname}
          </h4>

          <p>{userInfo.email}</p>
          <p className="user-phone">
            +{mobileCountryCode} {phoneWithoutCountryCode}
          </p>
        </div>
        <div className="loyalty-points-info info-rectangle">
          <p className="current-balance">{translation.youCurrentlyHave}</p>
          <p className="smile-points">
            {loyaltyDetails.currentBalance} {translation.smilePoints}
          </p>
          <h2 className="points-count">
            {currencyCode} {loyaltyDetails.pointsValue}
          </h2>
          <img
            className="loyalty-present-img"
            src={loyaltyRedeemPresent}
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default LoyaltyRedeemInfo;
