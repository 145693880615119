import React, { Component } from "react";
import Notifications from "react-notification-system-redux";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import styles from "./styles";

class ToastrNotification extends Component {
  render() {
    const { notifications } = this.props;
    return (
      <div className="parent-div">
        <Notifications notifications={notifications} style={styles} />
      </div>
    );
  }
}

ToastrNotification.propTypes = {
  notifications: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  notifications: state.notifications
});

export default connect(mapStateToProps)(ToastrNotification);
