import React from "react";
import cn from "classnames";
import { isArabic, getColorHex } from "../../../util";
import { Image } from "../../image";

const MobFilterItem = ({
  facetName,
  subFacetName,
  subFacetValue,
  handleToggle,
  language,
  colors,
  storeList
}) => {
  const subItems = subFacetName && subFacetName.split(" > ");
  const _subFacetName = subItems[(subItems.length && subItems.length - 1) || 0];

  const isColorFilter = facetName === "attributes.Color Family";
  const colorHex = getColorHex(colors, subFacetName, language);

  const isStoreFilter = facetName === "store";
  const trimmedLowCaseSubFacetName = (subFacetName || "").trim().toLowerCase();
  const storeItem =
    isStoreFilter &&
    storeList.find(
      item => item.title.trim().toLowerCase() === trimmedLowCaseSubFacetName
    );

  return (
    <li
      className={cn({ checked: subFacetValue.checked }, "filter_item_mobile", {
        weight_600: subFacetValue.checked && isArabic(language)
      })}
    >
      {isColorFilter && (
        <span
          className="color"
          style={{
            backgroundColor: colorHex,
            margin: language === "ar" ? "0 0 0 10px" : "0 10px 0 0"
          }}
        />
      )}
      <span
        id={`${facetName}:${subFacetName}`}
        onClick={e => {
          e && e.preventDefault() && e.stopPropagation();
          handleToggle(e, !subFacetValue.checked, "mobile");
        }}
      >
        {_subFacetName}
      </span>
      {isStoreFilter && storeItem ? (
        <Image
          className="store_img"
          style={{ margin: language === "ar" ? "0 0 0 10px" : "0 10px 0 0" }}
          src={storeItem.iconImageLink}
          alt={storeItem.title}
        />
      ) : (
        <span>{subFacetValue.value}</span>
      )}
    </li>
  );
};

export default MobFilterItem;
