import React from "react";
import { isDateInPast } from "../../../util/common";
import CustomCountdown from "../../custom-countdown";

export const SaleMobileTimer = ({ dateTo, dateFrom, translation }) => {
  const alreadyStarted = isDateInPast(dateFrom);
  const timeToShow = alreadyStarted ? dateTo : dateFrom;

  return (
    <div className="mobile-timer">
      <h2 className="timer-text">
        {alreadyStarted ? translation.endsIn : translation.startsIn}
      </h2>
      <CustomCountdown
        date={timeToShow}
        type={1}
        content={{ backgroundColor: "black", color: "white" }}
        textStyles={{ top: 30, color: "black" }}
      />
    </div>
  );
};
