import get from "lodash/get";
import { getState } from "../../redux/store";
import {
  selectLanguageSlug,
  selectLisaMinimized,
  selectRouteSlug,
  selectWishListBaseData
} from "../selectors";
import { getDataAsObject } from "../common";
export const getItem = payload => get(payload, ["item", "data"]);

export const getItemId = item => get(item, ["origin", "id"]);

export const getUrlString = item => get(item, ["url", "*"]);

export const getActionString = item => get(item, ["action"]);

export const parseItemDataFromId = itemId => getDataAsObject(itemId);

export const parseItemDataFromString = stringified =>
  getDataAsObject(stringified);

export const getPositionStyle = (customProps, units = "") => {
  const { position } = customProps;
  if (!position || !position.includes(":")) return null;

  return position
    .replaceAll(" ", "")
    .split(",")
    .reduce((acc, pair) => {
      const [key, value] = pair.split(":");
      acc[key] = value + units;
      return acc;
    }, {});
};

export const getLiveFloatingButtonUrl = (url, routeSlug) =>
  `/${routeSlug}${url?.startsWith("/") ? url : `/${url}`}`;

export const hasStringifiedData = string => string?.includes(":");

export const getItemDetails = data => {
  const actionString = getActionString(data);
  return hasStringifiedData(actionString)
    ? parseItemDataFromString(actionString)
    : { productId: Number(data.id) };
};

export const getWishListBaseDataStore = () =>
  selectWishListBaseData(getState());

export const getRouteSlugStore = () => selectRouteSlug(getState());

export const getLanguageSlugStore = () => selectLanguageSlug(getState());

export const getLisaMinimized = () => selectLisaMinimized(getState());

export const importTranslation = async () =>
  await import(`../../language/profile/${getLanguageSlugStore()}/profile.js`);

export const getLisaSectionConfig = (
  translations,
  { upcomingTitle, liveTitle, recordedTitle }
) => [
  {
    limit: 3,
    order: -1,
    state: "live",
    tag: "LIVE",
    title: liveTitle || translations?.currentlyLive
  },
  {
    limit: 5,
    order: 1,
    state: "preShow",
    tag: "UPCOMING",
    title: upcomingTitle || translations?.upcomingShows
  },
  {
    limit: 10,
    order: -1,
    state: "replay",
    tag: "RECORDED",
    title: recordedTitle || translations?.discoverMoreLivestreams
  }
];
