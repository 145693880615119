import React from "react";
import find from "lodash/find";

const QuickFilerBanner = ({ bannerData, toggleQuickFilerModal }) => {
  const imageUrl = (find(bannerData, { key: "image" }) || {}).value;

  if (!imageUrl) return null;

  return (
    <div className="quick-filter-banner" onClick={toggleQuickFilerModal}>
      <img src={imageUrl} alt="quick-filter" />
    </div>
  );
};

export default QuickFilerBanner;
