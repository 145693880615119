import React, { Component, Fragment } from "react";
import { ProductInfoListItem } from "./attributeHandlers";

class ProductQualityDetail extends Component {
  state = {
    toggleViewMore: true,
    showViewMore: true,
    viewMoreHeight: 0
  };
  toggleViewMore = e =>
    this.setState({ toggleViewMore: !this.state.toggleViewMore });

  showDetails = list =>
    list.map((item, index) => (
      <li key={index}>{`${item.name} ${item.value ? ": " : ""}${
        item.value ? item.value[0].name : ""
      }`}</li>
    ));

  showDetailsAttributes = list =>
    list.map((item, index) => {
      const values = item.value
        ? item.value.map(val => val.name).join(", ")
        : "";
      return (
        <tr key={index}>
          <td>{`${item.name}`}</td>
          <td>{values}</td>
        </tr>
      );
    });

  componentDidMount() {
    let pHeight = 0;
    let ulHeight = 0;
    const { viewMoreHeight } = this.state;
    if (
      this.productDetailsWrappperRef &&
      this.productDetailsWrappperRef.clientHeight
    ) {
      pHeight = this.productDetailsWrappperRef.clientHeight;
    }
    if (
      this.productDetailsAttributesWrappperRef &&
      this.productDetailsAttributesWrappperRef.clientHeight
    ) {
      ulHeight = this.productDetailsAttributesWrappperRef.clientHeight;
    }
    if (viewMoreHeight !== pHeight + ulHeight) {
      this.setState({
        viewMoreHeight: pHeight + ulHeight,
        showViewMore: pHeight + ulHeight > 80 ? true : false
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { itemId } = this.props;
    if (itemId !== nextProps.itemId) {
      this.setState({ toggleViewMore: true });
    }
  }

  componentDidUpdate(prevProps) {
    let pHeight = 0;
    let ulHeight = 0;
    const { viewMoreHeight } = this.state;
    if (
      this.productDetailsWrappperRef &&
      this.productDetailsWrappperRef.clientHeight
    ) {
      pHeight = this.productDetailsWrappperRef.clientHeight;
    }
    if (
      this.productDetailsAttributesWrappperRef &&
      this.productDetailsAttributesWrappperRef.clientHeight
    ) {
      ulHeight = this.productDetailsAttributesWrappperRef.clientHeight;
    }
    if (viewMoreHeight !== pHeight + ulHeight) {
      this.setState({
        viewMoreHeight: pHeight + ulHeight,
        showViewMore: pHeight + ulHeight > 80 ? true : false
      });
    }
  }

  productDetailsWrappperRefCallback = element => {
    if (element) {
      this.productDetailsWrappperRef = element;
    }
  };

  productDetailsAttributesWrappperRefCallback = element => {
    if (element) {
      this.productDetailsAttributesWrappperRef = element;
    }
  };

  render() {
    const { viewMoreHeight } = this.state;
    const styleObj = {
      maxHeight: viewMoreHeight + 50
    };
    const { attributes, productDetails, translation } = this.props;
    const { bullets, attributes: productAttributesAsTable } = attributes;

    const productBullets = bullets?.values;
    return (
      <div className="product_quality_detail">
        {(productDetails || productAttributesAsTable || productBullets) && (
          <Fragment>
            <div className="detail">
              <h2 id="detailInfo" className="selected_tab">
                {translation.productDetail}
              </h2>
              <h2 className="infoHeading">{translation.shortDescription}</h2>
              <div className="view_product_quality expand" style={styleObj}>
                {productDetails && (
                  <Fragment>
                    <p
                      className="product_quality_listing"
                      ref={this.productDetailsWrappperRefCallback}
                    >
                      {productDetails}
                    </p>
                    <p className="infoHeading">{translation.details}</p>
                  </Fragment>
                )}
                <ul
                  className="product_quality_listing"
                  ref={this.productDetailsAttributesWrappperRefCallback}
                >
                  {Array.isArray(productBullets) &&
                    productBullets.map((bullet, index) => (
                      <ProductInfoListItem
                        key={bullet.text + index}
                        {...bullet}
                      />
                    ))}
                  <table className="attribute_table">
                    <tbody>
                      {productAttributesAsTable &&
                        this.showDetailsAttributes(productAttributesAsTable)}
                    </tbody>
                  </table>
                </ul>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default ProductQualityDetail;
