export const myAccountTranslation = {
  firstName: "First Name",
  lastName: "Last Name",
  emailAddress: "Email address",
  nationality: "Nationality",
  gender: "Gender",
  male: "Male",
  female: "Female",
  updateDetails: "Update Details",
  setNewPassword: "Set New Password",
  verified: "Verified",
  verify: "Verify",
  setAsDefaultAddress: "Set as default delivery address",
  editAddress: "Edit Address",
  delete: "Delete",
  thisIsDefaultAddress: "This is your default delivery address",
  addNewAddress: "Add New Address",
  addNewPaymentMethod: "Add New Payment Method",
  name: "Name",
  expire: "Exp",
  editCard: "Edit Card",
  remove: "Remove",
  googlePlus: "Google+",
  createdOn: "created on",
  youAreSignedInAs: "You are signed in as",
  disconnect: "Disconnect",
  showing: "Showing",
  connect: "Connect",
  order: "order",
  orders: "orders",
  from: "from",
  showingOrders:
    "<span><b>Showing <%= orderCount %> <%= order %></b> from</span>",
  trackOrder: "Track Order",
  cancelOrder: "Cancel Order",
  cancelItem: "Cancel Item",
  return: "Return",
  more: "MORE",
  orderID: "Order ID:",
  totalAmount: "Total Amount:",
  orderPlacedOn: "Order placed on :",
  estimatedDeliveryTime: "Estimated delivery by",
  size: "Size:",
  quantity: "Qty:",
  deliveryDetails: "Delivery Details",
  paymentDetails: "Payment Details",
  orderTotal: "Order Total",
  subTotal: "Sub Total",
  deliveryFee: "Delivery Fee",
  free: "Free",
  total: "Total",
  myDetails: "My Details",
  edit: "edit",
  loyaltyPoints: "Loyalty Points",
  view: "view",
  viewBenefits: "view benefits",
  myRecentOrder: "My Recent Orders",
  viewAll: "view all",
  myAddressBook: "My Address Book",
  paymentMethod: "Payment Method",
  tier: "Tier",
  black: "Black",
  yellow: "Yellow",
  yellowTier: "Yellow Tier",
  blackTier: "Black Tier",
  seeBenefits: "See benefits",
  retainOnBlackTier: "Retain on Black Tier",
  upgradeToBlackTier: "Upgrade to Black Tier",
  byCollecting: "by Collecting",
  moreSmilePoints: "More Smile Points by",
  collectedOutOf:
    '<b><%= accuralPoints %></b>&nbsp;<span class="collected-out">collected out of</span>&nbsp;<span><%= pointNeededForNextLevel %></span>',
  cardNumber: "Card number",
  smilePoints: "Points",
  tierValidTo: "Tier valid up to",
  pointsWillBeCredited:
    "Loyalty Points redeemed will be credited to your wallet",
  smileMore: "Smile More, Loyalty Program",
  discoverSmile:
    "Discover a smile full of ways to earn points, receive rewards and save.",
  joinNow: "Join Now",
  points: "points",
  nextRewardAt: "next reward at",
  yourCardNumber: "Card number",
  youCurrentlyHave: "You currently have",
  rewardSmile: "Reward Smiles",
  nextRewardGoal: "Next reward goal",
  memberSince: "Member since",
  lastUpdatedOn: "Last updated on",
  redeemLoyaltyPoints: "Redeem Loyalty Points",
  redeemPointsBtn: "Redeem Points",
  minPointsMsg: "Minimum Points required to Redeem is 1000",
  voucherType: "Voucher Type",
  redeemOption: "Redemption Option",
  submit: "Submit",
  cancel: "Cancel",
  invoiceNumber: "Invoice No.",
  storeName: "Store Name",
  transactionDate: "Transaction Date",
  transactionPoint: "Transaction Point",
  transactionValue: "Transaction Value",
  online: "Online",
  offline: "Offline",
  page: "Page",
  viewMoreTransaction: "View more Transactions",
  viewLessTransaction: "View less Transactions",
  vouchersIssued: "Vouchers will be issued in multiples of",
  redeemLoyaltyProgram: "Redeem Loyalty Points",
  redeemed: "Redeem",
  walletTotalBalance: "Total Available Balance",
  InstantCheckout: "Instant Checkout",
  InstantCheckoutSubTitle: "One-click, easy and fast checkout",
  ConsolidatedMoney: "Consolidated Money",
  ConsolidatedMoneySubTitle:
    "Vouchers, credit notes and loyalty points in one place",
  MoreBenifits: "Many More Benefits",
  MoreBenifitsSubTitle: "Benefits and offers on using My Wallet",
  addToWalletCta: "Add to Wallet",
  transactionHistory: "Transaction History",
  cerditNote: "Credit Note",
  orderId: "Order ID",
  addVoucherCreditNote: "Add Voucher/Credit Note",
  enterCode: "Enter Code",
  past1month: "1 month ago",
  past2month: "2 months ago",
  past6month: "6 months ago",
  past12month: "1 year ago",
  showAll: "Show All",
  cancellationReason: "Select reason for cancellation",
  cancellationReason1: "Incorrect Size Ordered",
  cancellationReason2: "Product Not Required Anymore",
  cancellationReason3: "Cash Issue",
  cancellationReason4: "Ordered By Mistake",
  cancellationReason5: "Want To Change Style/Color",
  reasonRequired: "Reason Required",
  quantityRequired: "Quantity Required",
  commentRequired: "Comment required",
  COD: "Cash on delivery",
  CBD: "Cash Before Delivery",
  VOUCHER: "Voucher",
  WALLET: "Wallet",
  Earn: "Earn",
  CC: "Credit / Debit Card",
  APPLE_PAY: "Apple Pay",
  morePointsToRedeemYour: "more points to redeem your",
  voucher: "voucher",
  onlineMember: "Online Member",
  loyalty_card: "Present this card at stores to earn loyalty points",
  loyaltySmileMore: "Smile More",
  loyalty_program: "Loyalty Program",
  estimateDelivery: "Estimated delivery by ",
  courierName: "Courier Name",
  myOrders: "My Orders",
  deleteAddress: "Are you sure you want to delete this address?",
  yes: "Yes",
  detail: "Detail",
  walletNote: "Do you have voucher or credit note to add to your wallet ?",
  socialAccount: "Social Accounts",
  savedCards: "Saved Cards",
  redeemGiftCard: "Redeem Gift Card",
  redeemGiftCardNotAvailable:
    "Redeem gift card is not available in your country",
  continueShopping: "Continue Shopping",
  myWallet: "My Wallet",
  loyaltyProgram: "My Loyalty Program",
  accountOverview: "Account Overview",
  selectQuantity: "Qty.",
  noOrdersHeading: "No orders placed yet",
  thereAreNoOrders: "There are no orders placed in the",
  thereAreNoOrdersLongTerm: "There are no orders placed in the",
  noOrderDesc: "You currently have no order. Click below to start shopping.",
  startShopping: "Start Shopping",
  noCardHeading: "Add a new card",
  noCardDesc: "There are no saved cards linked to this account.",
  addNewCard: "Add New Card",
  noSocialHeading: "Social account not linked",
  noSocialDesc:
    "Use your social media accounts to make it even easier to log in.",
  connectOnFacebook: "Connect On Facebook",
  connectOnGoogle: "Connect On Google",
  noSavedAddressHeading: "Add a new address",
  noSavedAddressDesc:
    "You currently have no saved address. Get started by adding one",
  orderCancel: "Order Cancelled",
  itemCancel: "Item Cancelled",
  "ORDER RECEIVED": "Order Received",
  "IN PROCESS": "Order in Process",
  "HANDED TO SHIPPER": "Handed To Shipper",
  "OUT FOR DELIVERY": "Out For Delivery",
  DELIVERED: "Delivered",
  RETURNED: "Returned",
  CANCELLED: "Cancelled",
  typeComment: "Type Comment...",
  redeemPoints:
    "You will receive the  loyalty voucher on your registered email",
  creditNote: "Credit Note",
  voucherCode: "Voucher Code",
  IncOfVat: "Incl. Of VAT",
  deleteAddressTitle: "Delete Address",
  removeSavedCardTitle: "Remove Saved Card",
  surelyRemoveCard: "Are you sure you want to remove this card?",
  voucherAddAlert: "Do you have voucher or credit note to add to your wallet?",
  saveAddressTitle: "Save Address",
  saveDetailsTitle: "Save Details",
  saveAddress: "Are you sure you want to save this address?",
  saveDetails: "Are you sure you want to update details?",
  save: "Save",
  delinkAccountTitle: "Delink Account",
  delinkAccount: "Do you want to continue?",
  no: "No",
  cancellationRequested: "Cancellation Requested",
  cancelled: "Cancel Requested",
  orderCancellation: "Order Cancellation",
  additionalComment: "Additional Comment",
  inValidVoucherCode: "Invalid voucher code",
  itemCannotReturn: "This item cannot be returned / exchanged",
  Discount: "Discount",
  freeShipping: "FREE SHIPPING",
  codFee: "COD Fee",
  PAYPAL: "PayPal",
  deliveryType: "Delivery Type",
  paymentStatus: "Payment Status",
  DELIVERY_ADDRESS: "Standard Delivery",
  EXPRESS_DELIVERY: "Express Delivery",
  SELF_PICKUP: "Collection Points",
  STORE_PICKUP: "In-store Pickup",
  PAYMENT_PENDING: "Payment Pending",
  PAYMENT_COMPLETED: "Payment Completed",
  PAYMENT_FAILED: "Payment Failed",
  "PAYMENT PENDING": "Payment Pending",
  "PAYMENT COMPLETED": "Payment Completed",
  "PAYMENT FAILED": "Payment Failed",
  TABBY_PAY_LATER: "Tabby - Pay Later",
  TABBY_PAY_INSTALLMENT: "Tabby - 4 interest-free payments",
  dateOfBirth: "Date of Birth",
  optional: "Optional",
  mayGiveYouSurprise: "We may give you a surprise on your birthday!",
  verifiedBirthday: "I certify that I am 18 years of age or older",
  subscribeForNewsLetter:
    "I agree to receive updates on new arrivals, exclusive promotions, deals, or recommendations via Email/SMS.",
  dd: "DD",
  month: "Month",
  year: "YYYY",
  fromYourWallet: "from your wallet",
  willExpireOn: "will expire on",
  fieldIsRequired: "Field is required",
  POSTPAY: "PostPay",
  invoice: "Invoice",
  downloading: "Downloading...",
  noData: "Invoice Not Found",
  TAMARA_PAY_BY_INSTALMENTS_2: "Tamara - 2 installments",
  TAMARA_PAY_BY_INSTALMENTS_3: "Tamara - 3 installments",
  TAMARA_PAY_BY_INSTALMENTS_4: "Tamara - 4 installments"
};
