export const getBreadcrumbLvlUrl = lvl => {
  const url =
    (lvl && Object.entries(lvl).find(([key]) => key.includes("Url"))[1]) || "";

  return url.endsWith("/") ? url : url + "/";
};

export const getBreadcrumbsList = (breadcrumbs, language) => {
  const breadcrumbsKeys = [
    "home",
    "category",
    "subCategory",
    "needHelp",
    "sendUsEmail",
    "brands",
    "brandsList",
    "brandsListLvl1",
    "productList",
    "productListLvl1",
    "productListLvl2",
    "product",
    "trackShipment",
    "myAccount"
  ];
  const list = [];
  breadcrumbsKeys.forEach((key, i) => {
    const breadcrumbKey = key.includes("brands") ? "brands" : key;
    const localePrefix = key === "productList" ? language + "_" : "";
    const name = breadcrumbs[key][`${localePrefix}${breadcrumbKey}Name`];
    const optName = breadcrumbs[key][`${localePrefix}${breadcrumbKey}OptName`];
    if (name) {
      list.push({
        name,
        url: breadcrumbs[key][breadcrumbKey + "Url"]
      });
    }
    if (optName) {
      list.push({
        name: optName
      });
    }
  });
  return list;
};
