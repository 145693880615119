export const footerTranslation = {
  letKeepInTouch: "Let's keep in touch",
  signInToNewsLetter:
    "Sign up to our newsletter to receive promotions and more.",
  moreAboutBrandsForLess: "More About Brands for less",
  thankYouSubscribingMessage:
    "You’ve been added to our mailing list. You’ll be among the first to hear about our new arrivals, exclusive promotion & latest trend on styles. Enjoy!",
  thankYouForSubs: "Thank you for subscribing",
  enterYourEmail: "Enter your email",
  subscribe: "Subscribe",
  shoppingFrom: "Shopping From",
  weAccept: "We Accept",
  enterValidEmail: "Please enter valid email!",
  enterEmail: "Please enter email!",
  paymentMethods: "Payment Methods",
  reachUsOnWhatsapp: "Reach us on Whatsapp",
  findAStore: "Find a store"
};
