import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { GAService } from "../../services/GA-service";

const FormActivityHandler = ({
  formName,
  lastFocusedFormField = "",
  onErrors = null,
  onFormStart = null
}) => {
  const [ready, setReady] = useState(false);
  // Grab values and submitForm from context
  const { errors, dirty, isSubmitting, isValid, submitCount } =
    useFormikContext();

  const prevRef = useRef({
    errors,
    dirty,
    submitCount: 0,
    lastFocusedFormField
  });

  const lastFocusedFieldRef = useRef({
    lastFocusedFormField
  });

  lastFocusedFieldRef.current.lastFocusedFormField = lastFocusedFormField;

  useEffect(() => {
    const { submitCount: prevCount, dirty: prevDirty } = prevRef.current;
    // Only when user click on submit, it should trigger event.
    if (prevCount !== submitCount) {
      setReady(true);
    }
    // check if the form is dirty or not [For Form start event]
    if (prevDirty !== dirty && dirty) {
      GAService.common.trackFormStart(formName);
      onFormStart && onFormStart();
    }

    // save the previous values, for next interation.
    prevRef.current.submitCount = submitCount;
    prevRef.current.dirty = dirty;
  }, [submitCount, dirty]);

  useEffect(() => {
    return () => {
      if (
        prevRef.current.submitCount === 0 &&
        lastFocusedFieldRef.current.lastFocusedFormField
      ) {
        GAService.common.trackFormAbandonment(
          formName,
          lastFocusedFieldRef.current.lastFocusedFormField
        );
      }
    };
  }, []);

  // To handle Errors in Form
  useEffect(() => {
    const errorKeys = Object.keys(errors);
    let errorStatus = errorKeys.some(key => errors[key] !== "");
    if (errorStatus && ready) {
      const errorsObj = errorKeys.reduce((acc, name) => {
        acc[name] = errors[name];
        return acc;
      }, {});
      GAService.common.trackFormError(errorsObj);
      onErrors && onErrors(errorsObj);
      setReady(false);
    }
  }, [errors, ready]);

  // To check if the form is completed or not, when user submit a form.
  useEffect(() => {
    if (isValid && isSubmitting && dirty && submitCount > 0) {
      GAService.common.trackFormComplete(formName);
    }
  }, [isValid, isSubmitting, dirty]);
  return null;
};

export default FormActivityHandler;
