import React from "react";
import cn from "classnames";
import filter from "lodash/filter";
import { getDividedNumber } from "../../../util/phoneNumber";
import ModalBFL from "../../modal/modal";
import ViewAddress from "../../address/viewAddress";

const MobileAddressBook = ({
  openRespAddressBook,
  checkoutTranslation,
  addressBook,
  updateAddress,
  mobileCountryCode,
  address,
  addressId,
  changeFromRespAddressBook,
  language,
  deleteAddress,
  handleEditAddress,
  handleDefaultAddress,
  toggleRespAddressModal,
  countryId,
  slicePhoneLength = 0,
  countryShortCode,
  mobileLocalNumberLength,
  mobileLocalCode
}) => (
  <ModalBFL
    overlayClassName="respDeliveryOverlay"
    showModal={openRespAddressBook}
    modalClass={cn("slideRight", "back", "respAddressBook", {
      arabic: language.includes("ar")
    })}
  >
    <span
      className="crossBack"
      onClick={() => changeFromRespAddressBook(false)}
    />
    <h3>{checkoutTranslation.addressBook}</h3>
    <div className="respAddressBookContent">
      {filter(addressBook, address => address.countryId === countryId).map(
        (addressBookAddress, index) => {
          const phoneDetails = {
            phone: addressBookAddress.phone,
            mobileCountryCode,
            mobileLocalCode,
            mobileLocalNumberLength
          };
          const { areaCode, phoneWithoutCountryCode } = getDividedNumber(
            phoneDetails
          );
          const updatedAddressBook = {
            ...addressBookAddress,
            phone: phoneWithoutCountryCode
          };

          const slicePhoneLength =
            ((areaCode && areaCode.length) || 0) + mobileLocalNumberLength;

          return (
            <div className="addressCount" key={addressBookAddress.addressId}>
              <div
                className="selectAddressBook"
                onClick={() => {
                  updateAddress(addressBookAddress);
                  changeFromRespAddressBook(false);
                }}
              >
                <input
                  type="radio"
                  className={cn("custom_radio", {
                    checked_radio:
                      address.addressId === addressBookAddress.addressId
                  })}
                  value={addressBookAddress.addressId}
                  onChange={() => updateAddress(addressBookAddress)}
                />
                <div className="radio_option_value">
                  <ViewAddress
                    slicePhoneLength={slicePhoneLength}
                    address={updatedAddressBook}
                    checkoutTranslation={checkoutTranslation}
                    mobileCountryCode={mobileCountryCode}
                    countryShortCode={countryShortCode}
                  />
                </div>
              </div>
              <div className="editActions">
                {addressBookAddress.default ? (
                  <button>{checkoutTranslation.default}</button>
                ) : (
                  <div
                    className="form-group checkbox_wrap default_address checkoutDefault"
                    onClick={e => {
                      handleDefaultAddress(e, index, addressBookAddress);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="default"
                      defaultChecked={addressBookAddress.default}
                      id="default_address1"
                    />
                    <label
                      className="checkbox_value"
                      htmlFor="default_address1"
                    >
                      {checkoutTranslation.setDefault}
                    </label>
                  </div>
                )}
                <div className="editDelete">
                  <span
                    className="edit"
                    onClick={() =>
                      handleEditAddress({
                        ...addressBookAddress,
                        phone: addressBookAddress.phone.slice(-slicePhoneLength)
                      })
                    }
                  >
                    {checkoutTranslation.edit}
                  </span>
                  <span
                    className="delete"
                    onClick={() => deleteAddress(addressBookAddress.addressId)}
                  >
                    {checkoutTranslation.delete}
                  </span>
                </div>
              </div>
            </div>
          );
        }
      )}

      {!Boolean(addressBook.length) && (
        <p className="noRecordFound">No Records</p>
      )}

      <button
        className="round_btn form_black_btn"
        onClick={e => toggleRespAddressModal(true)}
      >
        {checkoutTranslation.AddNewAddress}
      </button>
    </div>
  </ModalBFL>
);

export default MobileAddressBook;
