import React, { useState, useEffect } from "react";
import {
  STATIC_KIDS_STYLE_ICONS_PATH,
  KIDS,
  QUICK_FILTER_SHOES,
  FOOTWEAR
} from "../../../../constants/quickFilter";
import kidsShoesPlaceholder from "../../../../images/quick-filter/kids-shoes.svg";
import kidsClothingPlaceholder from "../../../../images/quick-filter/kids-clothing.svg";
import { includesSubString } from "../../../../util";

export const withLazyImageDiv = (Component, filterStepPath) => props => {
  const [loadedImg, setLoadedImg] = useState(null);
  let { title, image, ...otherProps } = props;

  useEffect(
    () => {
      if (!image) {
        const joinedPath = filterStepPath.join("");
        const importPath = STATIC_KIDS_STYLE_ICONS_PATH[joinedPath];
        const imageName = title.replaceAll(/(\s|&)/gi, "");
        import(`../../../../${importPath}/${imageName}.png`)
          .then(img => {
            setLoadedImg(img.default);
          })
          .catch(err => {
            console.warn("Quick Filter image loading failed", err);
            if (includesSubString(joinedPath, KIDS)) {
              const isShoesFlow =
                includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
                includesSubString(joinedPath, FOOTWEAR);

              setLoadedImg(
                isShoesFlow ? kidsShoesPlaceholder : kidsClothingPlaceholder
              );
            }
          });
      } else {
        setLoadedImg(image);
      }
    },
    [title, image]
  );

  return <Component image={loadedImg} title={title} {...otherProps} />;
};
