import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { LoaderTypes } from "./loaderTypes";
import { LOADER_TYPES } from "../../constants";

const Loader = props => {
  const [isExpired, setIsExpiredStatus] = useState(false);

  useEffect(() => {
    let timerId;

    if (props.hideAfter > 0) {
      timerId = setTimeout(() => {
        setIsExpiredStatus(true);
      }, props.hideAfter);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  });

  if (!props.visible || isExpired) {
    return null;
  }

  const LoaderComponent = LoaderTypes[props.type];

  return (
    <div className={cn("loader-wrapper", props.className)} style={props.style}>
      <LoaderComponent {...props} />
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.oneOf([
    LOADER_TYPES.RIPPLE,
    LOADER_TYPES.RING,
    LOADER_TYPES.DOTS
  ]),
  style: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hideAfter: PropTypes.number,
  fullscreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

Loader.defaultProps = {
  type: LOADER_TYPES.RIPPLE,
  style: {},
  className: "",
  visible: false,
  hideAfter: 0,
  fullscreen: false
};

export default Loader;
