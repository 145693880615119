import React from "react";
import classnames from "classnames";
import _ from "lodash";

import CustomDropdown from "../../genericDropDown";

const CategoriesTabs = ({
  tabs,
  selectedTab,
  categoryData,
  changeCategory,
  scrollHandler,
  selectedBrandChar,
  searchText,
  setSearchText,
  shopByBrandsTranslation
}) => (
  <div className="brands-tabs-wrapper">
    <div className="container">
      <div className="desktop-brands-filter">
        <div className="brand-category">
          <div className="brands-tabs">
            <ul>
              {Array.isArray(tabs) &&
                tabs.map(tab => (
                  <li
                    key={tab.seoName}
                    className={classnames({
                      active: selectedTab === tab.seoName
                    })}
                    onClick={() => changeCategory(tab.seoName)}
                  >
                    <span className="tabs-text">{tab.name}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="brand-search">
            <input
              type="text"
              name="searchText"
              className="search-text-area"
              value={searchText || ""}
              onChange={e => setSearchText(e.target.value)}
              placeholder={shopByBrandsTranslation.searchForBrands}
              aria-label="Search"
            />
            <span className="search_icon" />
          </div>
        </div>
        <div className="brands-filter">
          <ul>
            {Array.isArray(categoryData) &&
              categoryData.map(key => (
                <li
                  key={key}
                  className={classnames({ active: key === selectedBrandChar })}
                >
                  <p className="tabs-text">
                    <a
                      href={`#section${key}`}
                      onClick={e => scrollHandler(e, key)}
                    >
                      <span>{key}</span>
                    </a>
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="mobile-brands-filter">
        <div className="dropdown-wrapper">
          <CustomDropdown
            name="Your Selected brand"
            data={_.map(tabs, "name")}
            selectedItem={selectedTab}
            defaultSelectValue={selectedTab}
            handleChange={name => {
              const tabData = _.find(tabs, tab => tab.name === name);

              changeCategory(tabData.seoName);
            }}
            containerClass="brands_dropdown"
            scrollbarMaxHeight={150}
          />
        </div>
        <div className="brand-search">
          <input
            type="text"
            name="searchText"
            className="search-text-area"
            value={searchText || ""}
            onChange={e => setSearchText(e.target.value)}
            placeholder={shopByBrandsTranslation.searchForBrands}
            aria-label="Search"
          />
          <span className="search_icon" />
        </div>
      </div>
    </div>
  </div>
);

export default CategoriesTabs;
