import get from "lodash/get";
import {
  GET_CART_LIST,
  UPDATE_CART_LIST,
  DELETE_CART_LIST,
  UPDATE_CART_SIZE_QTY,
  SET_CHECKOUT_DATA,
  RESET_PRODUCT_MERGE,
  SET_SAVING_INDICATOR,
  CLEAR_PREV_VALUE
} from "../constants";
import { normalizeCartValues } from "../../util/cart";
import {
  CLEAR_CART_AND_WISHLIST_GENERAL,
  DELETE_WISHLIST_ITEM_LOADING,
  SET_CART_AND_WISHLIST_GENERAL,
  WISHLIST_GENERAL_LOADING
} from "../constants/cart";
import { itemsInCart } from "../../util";

const getUpdatedGeneralCartCount = (state, cart) => ({
  cartAndWishlistGeneralData: {
    ...state.cartAndWishlistGeneralData,
    cart: { ...state.cartAndWishlistGeneralData.cart, count: itemsInCart(cart) }
  }
});

const initialState = {
  cart: [],
  isSaving: false,
  cartAndWishlistGeneralData: {
    cart: { count: 0 },
    wishList: { count: 0, products: null }
  },
  wishListAndCartGeneralLoaded: false,
  deleteWishListItemLoaded: false
};

const getCartList = (state, { cart, isProductMerged }) => ({
  ...state,
  cart: cart.map(item => {
    const normalizedItem = normalizeCartValues({ item });
    const prevItem = state.cart.find(el => {
      return el._id === get(normalizedItem, "_id");
    });
    const prevValue =
      prevItem && !isProductMerged ? { prevValue: prevItem.prevValue } : {};

    return {
      ...normalizedItem,
      ...prevValue,
      isProductMerged
    };
  }),
  ...getUpdatedGeneralCartCount(state, cart)
});

const updateCartList = (state, { cart }) => ({
  ...state,
  cart: [cart, ...state.cart],
  ...getUpdatedGeneralCartCount(state, [cart, ...state.cart])
});

const deleteCartList = (state, { _id }) => {
  const _cart = state.cart.filter(o => o._id !== _id);
  return {
    ...state,
    cart: _cart,
    ...getUpdatedGeneralCartCount(state, _cart)
  };
};

const selectedSize = (state, { productId, selectType, value }) => ({
  ...state,
  cart: state.cart.map(item => {
    if (item._id === productId) {
      return {
        ...item,
        [selectType]: value,
        prevValue: selectType === "size" ? {} : item.prevValue
      };
    } else {
      return item;
    }
  })
});

const removePrevValueFromItem = (state, action) => {
  const newArr = state.cart.map(item => {
    const orderItem = action.checkoutData.orderItems.find(
      val => item.productId === val.productId && item.size === val.size
    );
    return orderItem?.currentStock > 0
      ? {
          ...item,
          prevValue: item.quantity > orderItem.currentStock ? item.quantity : {}
        }
      : item;
  });

  return {
    ...state,
    cart: newArr,
    ...getUpdatedGeneralCartCount(state, newArr)
  };
};

const clearPrevValue = (state, { cartArray }) => {
  const updatedCartItems = cartArray.map(item => ({
    ...item,
    prevValue: {}
  }));

  return {
    ...state,
    cart: updatedCartItems
  };
};

const resetProductMerge = state => {
  const resetedCart = state.cart.map(product => {
    if (product.isProductMerged) {
      const { isProductMerged, ...resetedProduct } = product;
      return resetedProduct;
    }
    return product;
  });

  return { ...state, cart: resetedCart };
};

const setSavingIndicator = (state, { value }) => ({
  ...state,
  isSaving: value
});

export const wishListGeneralLoading = state => ({
  ...state,
  wishListAndCartGeneralLoaded: false
});

const deleteWishListGeneralLoading = state => ({
  ...state,
  deleteWishListItemLoaded: false
});

const setCartAndWishlistGeneralData = (
  state,
  { cartAndWishlistGeneralData }
) => ({
  ...state,
  cartAndWishlistGeneralData,
  wishListAndCartGeneralLoaded: true
});

const removeCartAndWishlistGeneral = state => ({
  ...state,
  cartAndWishlistGeneralData: initialState.cartAndWishlistGeneralData,
  cart: []
});

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CART_LIST:
      return getCartList(state, action);
    case UPDATE_CART_LIST:
      return updateCartList(state, action);
    case DELETE_CART_LIST:
      return deleteCartList(state, action);
    case UPDATE_CART_SIZE_QTY:
      return selectedSize(state, action);
    case SET_CHECKOUT_DATA:
      return removePrevValueFromItem(state, action);
    case RESET_PRODUCT_MERGE:
      return resetProductMerge(state);
    case SET_SAVING_INDICATOR:
      return setSavingIndicator(state, action);
    case CLEAR_PREV_VALUE:
      return clearPrevValue(state, action);
    case SET_CART_AND_WISHLIST_GENERAL:
      return setCartAndWishlistGeneralData(state, action);
    case CLEAR_CART_AND_WISHLIST_GENERAL:
      return removeCartAndWishlistGeneral(state);
    case WISHLIST_GENERAL_LOADING:
      return wishListGeneralLoading(state);
    case DELETE_WISHLIST_ITEM_LOADING:
      return deleteWishListGeneralLoading(state);

    default:
      return state;
  }
};

export default cartReducer;
