import React, { Fragment } from "react";
import cn from "classnames";

import IntlInput from "../../intl-tel-input/intl-tel-input";

import SendOTP from "./send-otp";
import ThankYou from "./thankyou";
import { ButtonWithLoader } from "../../generalElements/buttons";

const MobileVerification = ({
  orderSuccessTranslation,
  valid,
  phone,
  handleCountryChange,
  onSelectFlag,
  sendOtp,
  showVerifySection,
  countryCode,
  otp,
  handleOTPChange,
  verifyOtp,
  otpValid,
  otpVerified,
  touched,
  countrySHORT,
  countryData,
  mobileLocalNumberLength,
  onSelectArea,
  areaCode,
  sendOtpCount,
  errorMessage,
  readonly,
  showLoader
}) => (
  <Fragment>
    {showVerifySection ? (
      <SendOTP
        orderSuccessTranslation={orderSuccessTranslation}
        countryCode={countryCode}
        phone={countryCode + areaCode + phone}
        otp={otp}
        handleOTPChange={handleOTPChange}
        verifyOtp={verifyOtp}
        otpValid={otpValid}
        sendOtp={sendOtp}
        areaCode={areaCode}
        sendOtpCount={sendOtpCount}
        errorMessage={errorMessage}
        showLoader={showLoader}
      />
    ) : otpVerified ? (
      <ThankYou orderSuccessTranslation={orderSuccessTranslation} />
    ) : (
      <div className="mobile_verify generic_modal_content">
        <h2>{orderSuccessTranslation.verifyMobileNumber}</h2>
        <div className="form-group phone_field">
          <IntlInput
            readonly={readonly}
            valid={valid && (touched || phone)}
            phone={phone || ""}
            handleCountryChange={handleCountryChange}
            onSelectFlag={onSelectFlag}
            areaCode={areaCode}
            defaultCountry={countrySHORT}
            countryData={countryData}
            onSelectArea={(area, phone) => onSelectArea(area, phone)}
            mobileLocalNumberLength={mobileLocalNumberLength}
          />
          {Boolean(!valid && touched) && (
            <span className="error">
              {orderSuccessTranslation.phoneNotValid}
            </span>
          )}

          {errorMessage && <span className="error">{errorMessage}</span>}
        </div>
        <ButtonWithLoader
          className={cn("round_btn", "form_black_btn", "small_btn", {
            disable_order_btn: !valid
          })}
          onClick={e => {
            if (valid) sendOtp(e);
          }}
          showLoader={showLoader}
        >
          {orderSuccessTranslation.sendOtp}
        </ButtonWithLoader>
      </div>
    )}
  </Fragment>
);

export default MobileVerification;
