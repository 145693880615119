import React from "react";
import BFLLogo from "../../images/bflLogo.svg";
import BFLLogoArabic from "../../images/bflLogoArabic.svg";
import { Image } from "../image";
const SecondaryHeader = ({ moveToCart, checkoutTranslation, language }) => (
  <div className="secondary_heading">
    <div className="section_heading_wrapper">
      <p className="left_edge" onClick={moveToCart}>
        {checkoutTranslation.myShoppingCart}
      </p>
      <p className="center_edge">
        <Image
          src={
            language.includes("ar-") || language.includes("ar")
              ? BFLLogoArabic
              : BFLLogo
          }
          alt="BFL"
          showNewLogoDefaultImage={true}
        />
      </p>
      <p className="right_edge">{checkoutTranslation.secure}</p>
    </div>
  </div>
);

export default SecondaryHeader;
