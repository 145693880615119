import React, { Fragment, Component } from "react";
import visaCardImg from "../../images/visa_card_big.png";
import masterCardImg from "../../images/master_card_big.png";
import genericCardBig from "../../images/generic_third_card_big.png";
import DeleteModal from "../delete-modal";
import { Image } from "../image";

class SaveCard extends Component {
  state = {
    isDeleteModalActive: false
  };

  handleCloseModal = value => {
    this.setState({ isDeleteModalActive: value });
  };

  render() {
    const {
      myAccountTranslation,
      card,
      handleRemoveCard,
      language
    } = this.props;
    const lastFourCardDigits = card.cardNumber.slice(-4);
    const { isDeleteModalActive } = this.state;
    return (
      <Fragment>
        <div className="payment_card">
          {card &&
          (card.cardName === "visa_card" ||
            card.cardName === "VisaCard" ||
            card.cardName === "Visa") ? (
            <Image src={visaCardImg} alt="visa card" />
          ) : card &&
          (card.cardName === "master_card" ||
            card.cardName === "MasterCard") ? (
            <Image src={masterCardImg} alt="master card" />
          ) : (
            <Image src={genericCardBig} alt="generic card" />
          )}
          <p className="card_num">
            <span>XXXX XXXX XXXX</span>{" "}
            {card && card.cardNumber ? lastFourCardDigits : ""}
          </p>
          <div className="card_detail">
            {card &&
              card.cardHolderName && (
                <p>
                  {myAccountTranslation.name}
                  <span>{card.cardHolderName}</span>
                </p>
              )}
          </div>
        </div>
        <div className="payment_card_action">
          {/*<button>{myAccountTranslation.editCard}</button>*/}
          <button
            onClick={() => {
              this.handleCloseModal(true);
            }}
          >
            {myAccountTranslation.remove}
          </button>
        </div>
        <DeleteModal
          language={language}
          titleTxt={myAccountTranslation.removeSavedCardTitle}
          bodyTxt={myAccountTranslation.surelyRemoveCard}
          cancelBtnTxt={myAccountTranslation.cancel}
          deleteBtnTxt={myAccountTranslation.delete}
          isDeleteModalActive={isDeleteModalActive}
          handleCloseModal={this.handleCloseModal}
          handleDeleteItem={handleRemoveCard}
          itemId={card.id || card.cardId}
        />
      </Fragment>
    );
  }
}

export default SaveCard;
