import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import {
  HELP_DATA_TILES,
  NEED_HELP_TOPIC_MAP,
  TILE_DATA
} from "../../constants";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import {
  changeLanguage,
  saveBreadcrumb
} from "../../redux/actions/common.action";
import HelpAccordion from "./accordion";
import { Image } from "../../components/image";
import NeedHelpCategories from "./helpCategories";
import NeedHelpFooter from "./footer";
import NeedHelpHeader from "./header";
import Page from "../../components/page";
import RightArrowImg from "../../images/arrow-point-to-right.svg";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getNeedHelpData } from "../../redux/actions/needHelp.action";
import { needHelp } from "../../language/needHelp/en/needHelp";
import { seoTranslation } from "../../language/seo/en/seo";
import {
  withAnalyticPageBrowseTracking,
  withLanguageFromUrl,
  withPageTypeTracking
} from "../../hocs";
import ssrConfig from "./helpSsrConfig";
import { GAService } from "../../services/GA-service";

class Help extends Component {
  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      needHelpTranslation: needHelp,
      seoTranslated: seoTranslation[urlLang]
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
      nextProps.settings &&
        nextProps.settings.countryId &&
        this.props.getNeedHelpData(
          nextProps.fullLanguage,
          nextProps.settings.countryId
        );
    } else if (
      (nextProps.settings && nextProps.settings.countryId) !==
      (this.props.settings && this.props.settings.countryId)
    ) {
      this.getlanguage(nextProps.language);
      nextProps.settings &&
        nextProps.settings.countryId &&
        this.props.getNeedHelpData(
          nextProps.fullLanguage,
          nextProps.settings.countryId
        );
    }
  }

  componentDidMount() {
    const {
      saveBreadcrumb,
      language,
      settings,
      getNeedHelpData,
      fullLanguage
    } = this.props;
    const { needHelpTranslation } = this.state;
    this.getlanguage(language);
    saveBreadcrumb &&
      saveBreadcrumb({
        modelName: "needHelp",
        modelData: {
          needHelpName: needHelpTranslation.needHelp,
          needHelpUrl: `/${language}-${
            (settings &&
              settings.countrySHORT &&
              settings.countrySHORT.toLowerCase()) ||
            "ae"
          }/need-help`
        }
      }) &&
      saveBreadcrumb({
        modelName: "product",
        modelData: {
          productName: "",
          productUrl: ""
        }
      }) &&
      saveBreadcrumb({
        modelName: "productList",
        modelData: {
          productListName: "",
          productListUrl: ""
        }
      });
    settings &&
      settings.countryId &&
      getNeedHelpData(fullLanguage, settings.countryId);
  }

  getlanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(
      `../../language/needHelp/${language}/needHelp`
    );
    languageResponse.then(response => {
      this.setState({
        needHelpTranslation: response.needHelp
      });
    });
    const seoResponse = import(`../../language/seo/${language}/seo`);
    seoResponse.then(response => {
      this.setState({ seoTranslated: response.seoTranslation });
    });
  };

  UNSAFE_componentWillMount() {
    const { currentLanguage, handleLanguageChange } = this.props;

    handleLanguageChange(currentLanguage);
  }

  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  onCoverHTML = (contentList, link, data) => {
    let _contentList = [];
    const { language, settings } = this.props;
    contentList &&
      contentList.length &&
      contentList.map((item, index) => {
        if (item.onCoverFlag) {
          _contentList.push(
            <p key={index}>
              <Link
                to={`/${language}-${
                  (settings &&
                    settings.countrySHORT &&
                    settings.countrySHORT.toLowerCase()) ||
                  "ae"
                }${link}?op=${index}`}
                onClick={() => this.handleViewAllClick(data)}
              >
                {" "}
                {item.title}{" "}
              </Link>
            </p>
          );
        }
        return null;
      });
    return _contentList;
  };

  handleViewAllClick = data => {
    GAService.common.trackNeedHelpViewAllFaqClick(needHelp[data]);
  };

  render() {
    const { needHelpTranslation, seoTranslated } = this.state;
    const { language, needHelpContent, staticContent, settings } = this.props;
    let _accordion = [];
    Object.keys(NEED_HELP_TOPIC_MAP).map(topicObjectKey => {
      needHelpContent[NEED_HELP_TOPIC_MAP[topicObjectKey]] &&
        needHelpContent[NEED_HELP_TOPIC_MAP[topicObjectKey]].content.map(
          tile => {
            if (tile.faqFlag) {
              _accordion.push(tile);
            }
            return null;
          }
        );
      return null;
    });

    let _card = [];
    _card =
      TILE_DATA &&
      TILE_DATA.map((data, index) => {
        return (
          <div key={index}>
            <span className="card_name">
              <Image src={data.imgSource} alt="image" />
              <h4> {needHelpTranslation[data.data]} </h4>
            </span>
            <span className="card_details">
              <h4> {needHelpTranslation[data.data]} </h4>
              {needHelpContent[data.data] &&
                this.onCoverHTML(
                  needHelpContent[data.data].content,
                  data.link,
                  data.data
                )}
              <span>
                <Link
                  to={`/${language}-${
                    (settings &&
                      settings.countrySHORT &&
                      settings.countrySHORT.toLowerCase()) ||
                    "ae"
                  }${data.link}`}
                  onClick={() => this.handleViewAllClick(data.data)}
                >
                  <h6>{needHelpTranslation.viewAll}</h6>
                </Link>
                <Image src={RightArrowImg} alt="image" />
              </span>
            </span>
          </div>
        );
      });
    const meta =
      needHelpContent &&
      needHelpContent.general &&
      needHelpContent.general.meta;
    let seoTitle = "";
    let metaInfo = {};
    if (meta && meta.metaDescription && meta.title) {
      let metaDescInfo = `${meta.metaDescription.trim()}`;
      seoTitle = `${meta.title.trim()} | ${seoTranslated.bfl}`;
      metaInfo = language
        ? {
            [language]: {
              ...meta,
              metaDescription: metaDescInfo
            }
          }
        : {};
    }

    return (
      <Page title={seoTitle} meta={metaInfo} language={language} id="need-help">
        <Fragment>
          <div
            className={`help_container container ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <NeedHelpHeader needHelpTranslation={needHelpTranslation} />
            <div className="help_cards">{_card}</div>
            <NeedHelpCategories needHelpTranslation={needHelpTranslation} />
            {_accordion && _accordion.length > 0 && (
              <div className="faq_section">
                <h4>{needHelpTranslation.frequentlyAskedQuestions}</h4>
                {_accordion.map((tile, index) => (
                  <HelpAccordion
                    tile={tile}
                    key={index}
                    accordionHeading={needHelp.frequentlyAskedQuestions}
                  />
                ))}
              </div>
            )}

            <NeedHelpFooter
              needHelpTranslation={needHelpTranslation}
              helpDataTiles={HELP_DATA_TILES}
              staticContent={staticContent}
              language={language}
              settings={settings}
            />
          </div>
        </Fragment>
      </Page>
    );
  }
}

Help.need = ssrConfig;

const mapStateToProps = state => ({
  language: state.common.language,
  needHelpContent: state.needHelpReducer.needHelpContent,
  settings: state.common.settings,
  fullLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  staticContent: state.footerReducer.staticContent
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  getNeedHelpData: bindActionCreators(getNeedHelpData, dispatch),
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch),
  saveBreadcrumb(navData) {
    dispatch(saveBreadcrumb(navData));
  }
});

export default compose(
  withRouter,
  withLanguageFromUrl,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(Help);
