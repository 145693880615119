import React from "react";
import * as priceUtil from "../../../util/priceUtil";

const CartSubTotal = ({ translation, total, currencyCode, commonSettings }) => (
  <div className="subtotal">
    {translation.subtotal}{" "}
    <span>
      {" "}
      {`${currencyCode} `}
      {total
        ? priceUtil.shapePriceWithComma(total, commonSettings.decimalPlaces)
        : 0}
    </span>
  </div>
);

export default CartSubTotal;
