import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isNumber from "lodash/isNumber";
import isEmpty from "lodash/isEmpty";

import { getProductUrlName, isServer } from "../../util";
import {
  getCookie,
  getGuestUserStorage,
  removeGuestUserStorage,
  setCookie,
  setGuestUserStorage
} from "../../util/browserStorage";
import {
  selectCdnImageSettings,
  selectIsCheckedLoyaltyProgram,
  selectOrderSuccessBannerSections,
  selectRouteSlug
} from "../../util/selectors";
import { getDividedNumber } from "../../util/phoneNumber";
import { getDataAsObject } from "../../util/common";

import {
  PAYMENT_GATEWAYS,
  PRODUCT_PURCHASE_EXECUTED,
  LOADER_TYPES,
  POSTPAY_PAYMENT_STATUS,
  TABBY_PAYMENT_ID,
  TAMARA_PAYMENT_ID,
  TABBY_PAYMENT_STATUS,
  IS_ORDER_CONFIRMED,
  CONFIRMED_ORDER_ID
} from "../../constants";
import { SECTION_SLUGS } from "../../constants/sections";

import {
  changeLanguage,
  getLoyalityPoints,
  getOrderCountryById,
  showPageLoader,
  hidePageLoader,
  resetOrderState
} from "../../redux/actions/common.action";
import { logOut } from "../../redux/actions/authConfig.action";
import { resetAddress } from "../../redux/actions/address.action";
import {
  getOrderData,
  sendOtp,
  verifyOtp,
  confirmOrder
} from "../../redux/actions/order.action";
import {
  deleteCartData,
  deletePurchasedCartData,
  fetchCartData
} from "../../redux/actions/cart.action";
import { getBannerSections } from "../../redux/actions/section.action";

import { Image } from "../../components/image";
import OrderSuccessSummary from "../../components/order/order-success-summary";
import MobileOrderedItem from "../../components/mobile/mobile-ordered-item";
import ContactInfo from "../../components/contact-info";
import OrderedItem from "../../components/order/ordered_item";
import ShippingAddress from "../../components/shipping-address";
import LoyaltyEarned from "../../components/loyalty/loyalty-earned";

import { checkoutTranslation } from "../../language/checkoutFlow/en/checkoutFlow";
import { orderSuccessTranslation } from "../../language/orderSuccess/en/orderSuccess";
import AnalyticService from "../../services/analytic-service";
import {
  CheckoutComService,
  AlgoliaInsightsService,
  TabbyService,
  TamaraService
} from "../../services";

import confirmCheck from "../../images/yellow_check.svg";
import { SurveyInfo } from "../../components/survey-info";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import { fetchEarnedLoyaltyPoints } from "../../redux/actions/myAccount.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { GAService } from "../../services/GA-service";
import { COUPON_CODE } from "../../redux/constants";

const deliveryTypes = ["DELIVERY_ADDRESS", "EXPRESS_DELIVERY", "SELF_PICKUP"];

class OrderSuccess extends Component {
  state = {
    checkoutTranslation: checkoutTranslation,
    orderSuccessTranslation: orderSuccessTranslation,
    continueAsGuest: true,
    showGuestOrders: false,
    slicePhoneLength: 0,
    areaCodeLength: 0,
    countryDetails: {},
    isLoyaltyPointsFetched: false
  };

  async confirmOrder() {
    const { match, language, confirmOrder, history, location, routeSlug } =
      this.props;

    const languageParam = get(match, "params.language", language).includes("ar")
      ? "ar"
      : "en";

    const query = new URLSearchParams(location.search);

    const isCheckout = query.get("type") === "checkout-com";
    const isPostPay = query.get("paymentGateWay") === PAYMENT_GATEWAYS.POSTPAY;
    const isTabby = query.get("paymentGateWay") === PAYMENT_GATEWAYS.TABBY;
    const isTamara = query.get("paymentGateWay") === PAYMENT_GATEWAYS.TAMARA;

    if (isPostPay) {
      if (query.get("status") === POSTPAY_PAYMENT_STATUS.APPROVED) {
        return confirmOrder(
          {
            lang: languageParam,
            paymentGateWay: PAYMENT_GATEWAYS.POSTPAY,
            transactionOrderTotal: query.get("transactionOrderTotal"),
            orderId: match.params.orderId,
            paymentToken: query.get("paymentToken"),
            currencyCode: query.get("currencyCode"),
            isPaymentSuccess: true,
            paymentReferenceNo: query.get("paymentReferenceNo"),
            countryId: query.get("countryId")
          },
          history,
          language,
          true
        );
      } else {
        const originLocation = window.location.origin;
        const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;
        window.location.href = failedPageUrl;
      }
    }

    if (isCheckout) {
      if (query.get("result") === "success") {
        const sessionId = query.get("cko-session-id");
        const shouldCardBeSaved = query.get("saveCard");

        const payment = await CheckoutComService.getPaymentDetails(sessionId);
        if (!payment) return;

        if (shouldCardBeSaved && getDataAsObject(shouldCardBeSaved)) {
          CheckoutComService.saveCreditCard(sessionId);
        }

        return confirmOrder(
          {
            lang: languageParam,
            paymentGateWay: PAYMENT_GATEWAYS.CHECKOUT_COM,
            transactionOrderTotal: query.get("transactionOrderTotal"),
            orderId: match.params.orderId,
            paymentToken: query.get("paymentToken"),
            currencyCode: query.get("currencyCode"),
            isPaymentSuccess: true,
            paymentReferenceNo: payment.id,
            countryId: query.get("countryId")
          },
          history,
          language,
          true
        );
      }
    }
    if (isTabby) {
      try {
        const paymentId = localStorage.getItem(TABBY_PAYMENT_ID);
        if (!paymentId) {
          throw new Error("Tabby payment ID not found");
        }
        const response = await TabbyService.verifyPayment(paymentId);
        const isPaymentSuccess =
          response &&
          (response.status === TABBY_PAYMENT_STATUS.CLOSED ||
            response.status === TABBY_PAYMENT_STATUS.AUTHORIZED);

        if (isPaymentSuccess) {
          const confirmOrderBody = {
            lang: languageParam,
            paymentGateWay: PAYMENT_GATEWAYS.TABBY,
            transactionOrderTotal: query.get("transactionOrderTotal"),
            orderId: match.params.orderId,
            paymentToken: query.get("paymentToken"),
            currencyCode: query.get("currencyCode"),
            isPaymentSuccess: true,
            paymentReferenceNo: paymentId,
            countryId: query.get("countryId")
          };

          return confirmOrder(confirmOrderBody, history, language, true);
        } else {
          history.replace(`/${routeSlug}/payment-error`);
        }
      } catch (error) {
        console.error(error);

        history.replace(`/${routeSlug}/payment-error`);
      }
    }
    if (isTamara) {
      try {
        const paymentId = localStorage.getItem(TAMARA_PAYMENT_ID);
        if (!paymentId) {
          throw new Error("Tamara payment ID not found");
        }

        return await confirmOrder(
          {
            lang: languageParam,
            paymentGateWay: PAYMENT_GATEWAYS.TAMARA,
            transactionOrderTotal: query.get("transactionOrderTotal"),
            orderId: match.params.orderId,
            paymentToken: query.get("paymentToken"),
            currencyCode: query.get("currencyCode"),
            isPaymentSuccess: true,
            paymentReferenceNo: paymentId,
            countryId: query.get("countryId")
          },
          history,
          language,
          true
        );
      } catch (error) {
        console.error(error);

        history.replace(`/${routeSlug}/payment-error`);
      }
    }
  }

  async UNSAFE_componentWillMount() {
    if (isServer) return;

    const {
      match,
      language,
      handleLanguageChange,
      location,
      logOut,
      resetAddress
    } = this.props;

    let languageParam = get(match, "params.language", language);

    if (languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }

    handleLanguageChange(languageParam);
    this.loadLanguage(language);

    const query = new URLSearchParams(location.search);
    const currencyCode = query.get("currencyCode");

    resetAddress();
    this.showLoader();

    if (query && match.params.orderId) {
      const isPendingOrderConfirm =
        !localStorage.getItem(IS_ORDER_CONFIRMED) ||
        !localStorage.getItem(CONFIRMED_ORDER_ID) ||
        localStorage.getItem(CONFIRMED_ORDER_ID) != match.params.orderId;

      if (isPendingOrderConfirm) {
        const response = await this.confirmOrder();
        if (response?.data.success) {
          localStorage.setItem(IS_ORDER_CONFIRMED, true);
          localStorage.setItem(CONFIRMED_ORDER_ID, match.params.orderId);
        }
      }
      await this.onOrderSuccess(
        match.params.orderId,
        languageParam,
        currencyCode
      );
      if (query.get("continueAsGuest")) {
        const tokenForOtp = getGuestUserStorage();
        await logOut(false);
        setGuestUserStorage(tokenForOtp);
      }
    }

    const continueAsGuest = Boolean(
      query.get("continueAsGuest") && query.get("continueAsGuest").trim()
    );

    this.setState({ continueAsGuest, isLoyaltyPointsFetched: continueAsGuest });
  }

  async componentDidMount() {
    const { match, fetchCartData, commonSettings, getBannerSections } =
      this.props;

    if (commonSettings.countryId) {
      await getBannerSections(SECTION_SLUGS.ORDER_SUCCESS);
    }
    await fetchCartData(true, match.params.language);
  }

  showLoader = () => {
    const { showPageLoader } = this.props;
    showPageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
  };

  hideLoader = () => {
    const { hidePageLoader } = this.props;
    hidePageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
  };

  async componentDidUpdate(prevProps) {
    const {
      order,
      commonSettings,
      orderCountryData,
      language,
      isCheckedLoyaltyProgram,
      isJoinedToLoyaltyProgram,
      getLoyalityPoints,
      currency
    } = this.props;
    const { countryDetails } = this.state;
    const countryWasChanged = commonSettings.countryId !== order.countryId;
    const isLoyaltyStatusRefetched =
      isCheckedLoyaltyProgram &&
      prevProps.isCheckedLoyaltyProgram !== isCheckedLoyaltyProgram;

    if (isJoinedToLoyaltyProgram && isLoyaltyStatusRefetched) {
      await getLoyalityPoints({
        currencyCode: currency || "AED",
        orderItems:
          order &&
          order.items.map(item => ({
            ...item,
            oldPrice: item.transactionOldPrice
          }))
      });
      this.setState({ isLoyaltyPointsFetched: true });
    } else if (isLoyaltyStatusRefetched && !isJoinedToLoyaltyProgram) {
      this.setState({ isLoyaltyPointsFetched: true });
    }

    if (
      isNumber(order.countryId) &&
      countryWasChanged &&
      !orderCountryData.countryId
    ) {
      this.props.getOrderCountryById(order.countryId, language);
    }
    if (isEmpty(countryDetails)) {
      if (countryWasChanged && !isEmpty(orderCountryData)) {
        this.setState({ countryDetails: orderCountryData });
      } else if (!countryWasChanged && !isEmpty(commonSettings)) {
        this.setState({
          countryDetails: commonSettings
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getOrderData,
      match,
      order,
      getBannerSections,
      commonSettings,
      fetchCartData
    } = this.props;

    const {
      countryDetails: {
        mobileCountryCode,
        mobileLocalNumberLength,
        mobileLocalCode
      }
    } = this.state;
    if (order.shippingMob) {
      const phoneDetails = {
        phone: order.shippingMob,
        mobileCountryCode,
        mobileLocalCode,
        mobileLocalNumberLength
      };

      const { areaCode } = getDividedNumber(phoneDetails);
      const areaCodeLength = (areaCode && areaCode.length) || 0;
      const slicePhoneLength = areaCodeLength + mobileLocalNumberLength;
      this.setState({ slicePhoneLength, areaCodeLength });
    }

    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps.language);
      getBannerSections(SECTION_SLUGS.ORDER_SUCCESS);
      getOrderData(match.params.orderId, nextProps.language);
    }

    if (nextProps.commonSettings.countryId !== commonSettings.countryId) {
      getBannerSections(SECTION_SLUGS.ORDER_SUCCESS);
      fetchCartData(true, match.params.language);
    }
  }

  componentWillUnmount() {
    const { location, logOut, resetOrderState, history } = this.props;

    localStorage.removeItem(IS_ORDER_CONFIRMED);
    localStorage.removeItem(CONFIRMED_ORDER_ID);
    const query = new URLSearchParams(location.search);
    if (query.get("continueAsGuest")) {
      removeGuestUserStorage("");
      logOut(history, true);
    }
    resetOrderState();
  }

  deleteDataFromCart = () => {
    const {
      language,
      order,
      deletePurchasedCartData,
      authReducer: { userLoggedIn }
    } = this.props;
    const orderItems = get(order, "items");

    deletePurchasedCartData(orderItems, userLoggedIn, language);
  };

  onOrderSuccess = async (orderId, language, currencyCode) => {
    try {
      const {
        getOrderData,
        authReducer,
        commonSettings,
        checkout,
        fetchEarnedLoyaltyPoints
      } = this.props;

      const response = await getOrderData(orderId, language);

      if (!response.data) return;

      const { order } = response.data;

      this.deleteDataFromCart();

      if (authReducer.userLoggedIn) {
        fetchEarnedLoyaltyPoints(language, currencyCode, order.countryId);
      }

      const productPurchaseExecutedCookieQuery = `${PRODUCT_PURCHASE_EXECUTED}:${order.orderId}`;
      const executed = getCookie(productPurchaseExecutedCookieQuery);
      const isTrackedByAnalytic = executed === "false" || !executed;
      if (isTrackedByAnalytic) {
        AlgoliaInsightsService.trackProductPurchase({
          productIdList: order.items.map(product => product.pId.toString())
        });
        AnalyticService.product.trackProductPurchase({
          order,
          authReducer,
          commonSettings,
          checkout
        });
        GAService.checkout.trackProductPurchase({
          orderData: order,
          coupon: getCookie(COUPON_CODE) || ""
        });
        setCookie(productPurchaseExecutedCookieQuery, true);
      }
    } catch (error) {
    } finally {
      this.hideLoader();
    }
  };

  async loadLanguage(language) {
    const responses = await Promise.all([
      import(`../../language/checkoutFlow/${language}/checkoutFlow`),
      import(`../../language/orderSuccess/${language}/orderSuccess`)
    ]);
    const [checkoutResponse, orderSuccessResponse] = responses;
    this.setState({
      checkoutTranslation: checkoutResponse.checkoutTranslation,
      orderSuccessTranslation: orderSuccessResponse.orderSuccessTranslation
    });
  }

  sendOtp = phone => {
    const { sendOtp, match } = this.props;

    return sendOtp({ phone, orderId: match.params.orderId });
  };

  verifyOtp = async (code, phone, orderId) => {
    const { verifyOtp, language, order, getOrderData } = this.props;

    const resp = await verifyOtp({
      code,
      phone,
      orderId,
      customerId: order.customerId
    });

    await getOrderData(orderId, language);

    return resp;
  };

  moveToProductDetail = (item, e) => {
    e.stopPropagation();
    if (!get(item, "pId")) return;

    const { history, authReducer, commonSettings, routeSlug } = this.props;

    const ProductTitle = getProductUrlName(item.en_title || item.title || "");

    history.push(
      `/${routeSlug}/${ProductTitle ? ProductTitle : "---"}/${item.pId}/p/`
    );
    AnalyticService.product.trackMoveToProductDetail({
      item,
      authReducer,
      commonSettings
    });
  };

  handleToggleGuestOrders = () =>
    this.setState({ showGuestOrders: !this.state.showGuestOrders });

  render() {
    const {
      orderSuccessTranslation,
      continueAsGuest,
      showGuestOrders,
      slicePhoneLength,
      areaCodeLength,
      countryDetails,
      isLoyaltyPointsFetched
    } = this.state;
    const {
      savedCardList,
      language,
      order,
      deliveryOptions,
      currency,
      loyaltyPoints,
      history,
      staticContent,
      commonSettings,
      authReducer: { userLoggedIn },
      routeSlug,
      orderSuccessSections,
      configCdnImagesSettings
    } = this.props;
    const { ar_survey, en_survey } = commonSettings;
    const { orderSuccessTop, orderSuccessSectionOne } = orderSuccessSections;

    const {
      mobileCountryCode,
      countrySHORT,
      mobileLocalNumberLength,
      mobileLocalCode,
      countryName
    } = countryDetails;
    let deliveryOption = "";
    if (deliveryOptions) {
      if (order.deliveryType === deliveryTypes[0]) {
        deliveryOption = deliveryOptions[0];
      } else if (order.deliveryType === deliveryTypes[1]) {
        deliveryOption = deliveryOptions[1];
      } else {
        deliveryOption = deliveryOptions[2];
      }
    }

    const surveyInfo = language.includes("ar") ? ar_survey : en_survey;

    return (
      <div
        className={cn("order_success_container", {
          arabic: language.includes("ar")
        })}
      >
        {orderSuccessTop &&
          orderSuccessTop.map((section, key) => (
            <DynamicBannersWrapper
              section={section}
              key={key}
              bannerPageTitle={section.sectionTitle}
            />
          ))}
        <div className="order_success_content">
          <Image
            src={confirmCheck}
            alt="confirm_order"
            className="confirm_check"
          />

          <div className="success_heading border_bottom">
            <h2>{orderSuccessTranslation.thankYouForOrdering}</h2>
            <p className="heading_desc">
              {orderSuccessTranslation.confirmationOnEmail}
            </p>
          </div>

          <div
            className="userOrderedItems"
            onClick={this.handleToggleGuestOrders}
          >
            <MobileOrderedItem
              order={order}
              items={order && order.items}
              orderSuccessTranslation={orderSuccessTranslation}
              language={language}
              moveToProductDetail={this.moveToProductDetail}
              currencyCode={currency}
              commonSettings={commonSettings}
              continueAsGuest={continueAsGuest}
              showGuestOrders={showGuestOrders}
              handleToggleGuestOrders={this.handleToggleGuestOrders}
            />
          </div>

          {Boolean(continueAsGuest && showGuestOrders) && (
            <OrderedItem
              items={order && order.items}
              orderSuccessTranslation={orderSuccessTranslation}
              language={language}
              moveToProductDetail={this.moveToProductDetail}
              currencyCode={order.currencyCode}
              commonSettings={commonSettings}
              configCdnImagesSettings={configCdnImagesSettings}
            />
          )}

          <div
            className={cn("order_summary_container", {
              guest: continueAsGuest
            })}
          >
            <h3>{orderSuccessTranslation.orderSummary}</h3>

            <OrderSuccessSummary
              orderSuccessTranslation={orderSuccessTranslation}
              order={order}
              deliveryOption={deliveryOption}
              currencyCode={currency}
              history={history}
              language={language}
              continueAsGuest={continueAsGuest}
              countryShort={countrySHORT}
            />

            <div className="ordered_list_with_address flex">
              <div className="ordered_items_wrapper">
                <h3>{orderSuccessTranslation.youOrdered}</h3>

                <OrderedItem
                  items={order && order.items}
                  orderSuccessTranslation={orderSuccessTranslation}
                  language={language}
                  moveToProductDetail={this.moveToProductDetail}
                  currencyCode={order.currencyCode}
                  commonSettings={commonSettings}
                  configCdnImagesSettings={configCdnImagesSettings}
                />

                {!continueAsGuest && (
                  <button
                    className="order_blue_font"
                    onClick={() =>
                      history.push(
                        `/${routeSlug}/${
                          userLoggedIn
                            ? "my-account/my-orders"
                            : "login/?my-account/my-orders"
                        }`
                      )
                    }
                  >
                    {orderSuccessTranslation.viewAllOrder}
                  </button>
                )}
              </div>

              <div className="ship_address_wrapper">
                <h3>{orderSuccessTranslation.shipTo}: </h3>

                <ShippingAddress
                  order={order}
                  orderSuccessTranslation={orderSuccessTranslation}
                  countryName={countryName}
                  mobileCountryCode={mobileCountryCode}
                  mobileLocalCode={mobileLocalCode}
                  mobileLocalNumberLength={mobileLocalNumberLength}
                />
                <LoyaltyEarned
                  isLoyaltyPointsFetched={isLoyaltyPointsFetched}
                  loyaltyPoints={loyaltyPoints}
                  loyaltyMissed={!userLoggedIn || !Boolean(loyaltyPoints)}
                  orderSuccessTranslation={orderSuccessTranslation}
                  language={language}
                />
                <div className="right-bottom-content">
                  <div>
                    <ContactInfo
                      orderSuccessTranslation={orderSuccessTranslation}
                      customerCare={staticContent}
                    />

                    <ul className="help_desk">
                      <li>
                        <a href={`/${routeSlug}/need-help/`}>
                          {orderSuccessTranslation.needHelp}
                        </a>
                      </li>

                      <li>
                        <Link
                          to={
                            continueAsGuest
                              ? `/${routeSlug}/track-order/`
                              : `/${routeSlug}/my-account/my-orders/`
                          }
                        >
                          {orderSuccessTranslation.trackOrder}
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {surveyInfo && (
                    <div className="survey-block">
                      <SurveyInfo surveyInfo={surveyInfo} />
                    </div>
                  )}
                </div>
                {orderSuccessSectionOne &&
                  orderSuccessSectionOne.map((section, key) => (
                    <DynamicBannersWrapper
                      section={section}
                      key={key}
                      bannerPageTitle={section.sectionTitle}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="continue_btn">
          <Link to={`/${routeSlug}/`}>
            <button className="round_btn form_black_btn">
              {orderSuccessTranslation.continueShopping}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  order: state.orderReducer.order,
  orderCountryData: state.orderReducer.orderCountryData,
  deliveryOptions: get(state, "common.settings.deliveryOptions"),
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  currency: get(state, "common.settings.currencyCode"),
  countryData: state.common.countryData,
  mobileLocalCode: get(state, "common.settings.mobileLocalCode"),
  loyaltyPoints: state.common.loyaltyPoints,
  cart: state.cartReducer.cart,
  staticContent: state.footerReducer.staticContent,
  customerId: state.authReducer.customerId,
  checkout: state.checkoutReducer.checkoutData,
  routeSlug: selectRouteSlug(state),
  orderSuccessSections: selectOrderSuccessBannerSections(state),
  configCdnImagesSettings: selectCdnImageSettings(state),
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  isCheckedLoyaltyProgram: selectIsCheckedLoyaltyProgram(state)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  getOrderData,
  sendOtp,
  verifyOtp,
  confirmOrder,
  deleteCartData,
  fetchCartData,
  getLoyalityPoints,
  logOut,
  resetAddress,
  getOrderCountryById,
  showPageLoader,
  hidePageLoader,
  deletePurchasedCartData,
  resetOrderState,
  getBannerSections,
  fetchEarnedLoyaltyPoints
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderSuccess);
