import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import qs from "query-string";
import get from "lodash/get";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import debounce from "lodash/debounce";
import remove from "lodash/remove";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import cn from "classnames";

import {
  CheckoutService,
  AnalyticService,
  PostPayService
} from "../../services";

import "../../util/encryption";

import { CURRENCY_CODE, TOTAL_AMOUNT_FOR_PAYMENT } from "../../redux/constants";
import {
  PRICE_CONVERSION_DECIMAL_VALUE,
  PAYMENT_GATEWAYS,
  PRODUCT_PURCHASE_EXECUTED,
  CHECKOUT_ID,
  IS_AUTH_FROM_CHECKOUT,
  VOUCHER,
  WALLET,
  POST_PAY_CURRENCY_MULTIPLIER,
  DEFAULT_POSTAL_CODE,
  PAYMENT_MODES,
  LOADER_TYPES,
  DELIVERY_TYPES,
  MAX_CIVIL_ID_LENGTH,
  MIN_CIVIL_ID_LENGTH,
  COUNTRIES_WITH_CIVIL_ID,
  GOOGLE_MAPS_APIS_SRC
} from "../../constants";
import { settings_cart } from "./../../config/app";

import { isServer, getShippingLimitMessage, isMobile } from "../../util";
import {
  removeGuestUserStorage,
  setCookie,
  getCookie
} from "../../util/browserStorage";
import {
  selectCountrySlug,
  selectRouteSlug,
  selectLanguageSlug,
  selectCheckoutBannerSections,
  selectCdnImageSettings,
  selectIsCheckedLoyaltyProgram,
  selectTabbyPaymentError,
  selectPaymentStatusResponse,
  selectCheckoutInfoText
} from "../../util/selectors";
import * as priceUtil from "../../util/priceUtil";
import { sleep } from "../../util/common";

import {
  changeLanguage,
  getLoyalityPoints,
  showPageLoader,
  hidePageLoader,
  getStatesData,
  updateDeviceInfo,
  showLoadingIndicator,
  getPayPalToken
} from "../../redux/actions/common.action";
import {
  deleteCartData,
  fetchCartData,
  getCartAndWishlistGeneralData,
  saveToCart
} from "../../redux/actions/cart.action";
import {
  handleTextChange,
  resetSavedCard
} from "../../redux/actions/payment.action";
import {
  setUserInfo,
  fetchUserAndLoadData,
  logOut
} from "../../redux/actions/authConfig.action";
import {
  fetchCheckoutData,
  updateCheckout,
  changeCheckoutData,
  deleteCheckoutItem,
  applyVoucher,
  setAddress,
  fetchMapFilterData,
  mapDeliveryAddressCheckout,
  handleSelectedSavedCard,
  clearCheckoutData,
  setStandardDeliveryAddress,
  inStoreDeliveryAddressCheckout,
  fetchInStorePickupData,
  resetMapDeliveryAddress,
  resetInStoreDeliveryAddress,
  handleMSelectedSavedCard,
  removeAppliedVoucher,
  voucherList,
  loadCreditCards,
  clearBinCode,
  resetSummaryRecalculationInProgress,
  resetPaymentMethod,
  setPaymentStatusResponse
} from "../../redux/actions/checkout.action";
import {
  fetchMyWallet,
  fetchEarnedLoyaltyPoints
} from "../../redux/actions/myAccount.action";
import {
  showModal,
  signInModal,
  showForgotPasswordModal,
  clearModal,
  hideForgotPasswordModal,
  hideModal,
  saveAddress,
  guestUserCheckout
} from "../../redux/actions/homepage.action";
import { getBannerSections } from "../../redux/actions/section.action";
import { getStoreLocatorList } from "../../redux/actions/storeLocator.action";
import { confirmOrder } from "../../redux/actions/order.action";
import { resetAddress } from "../../redux/actions/address.action";
import SecondaryHeader from "../../components/secondary-header";
import SecondaryFooter from "../../components/secondary-footer";
import CheckoutSignIn from "../../components/checkout/checkout-sign-in";
import CartHoverDropdown from "../../components/cart/cart-hover-dropdown";
import DeliveryDetails from "../../components/delivery/delivery-details";
import CheckoutPayment from "../../components/checkout-payment";
import DeliveryPricePreview from "../../components/order/order-delivery-price-preview";
import LoyaltyAlerts from "../../components/loyalty/loyalty-alert";
import FooterAcceptMoney from "../../components/footer/footer-accept-money";
import MobileOrderSummary from "../../components/mobile/mobile-order-payment-summary";
import MobileCheckoutCartSummary from "../../components/mobile/mobile-checkout-cart-summary";
import Voucher from "../../components/voucher";
import PayPalButton from "../../components/PayPalButton";
import TabbyButton from "../../components/tabby/TabbyButton";
import TamaraButton from "../../components/tamara/TamaraButton";
import { loginTranslation } from "../../language/login/en/login";
import { myCart } from "../../language/myCart/en/myCart";
import { checkoutTranslation } from "../../language/checkoutFlow/en/checkoutFlow";
import { profileTranslation } from "../../language/profile/en/profile";
import { forgotPasswordTranslation } from "../../language/forgotPassword/en/forgotPassword";
import { seoTranslation } from "../../language/seo/en/seo";
import CheckoutButtonFooter from "../../components/checkout/checkout-btn-footer";
import withAreebaPayment from "../../hocs/withAreebaPayment";
import {
  checkPaymentOptionSelection,
  getCheckoutInfoText,
  isCommonPaymentNotSelected
} from "../../util/checkout";
import {
  getShippingCharge,
  isFreeShippingForced,
  isFreeShippingReached,
  shippingMayBeFree
} from "../../util/shipping";

import {
  withAnalyticPageBrowseTracking,
  withDeliverySettingsFetching,
  withPageTypeTracking
} from "../../hocs";

import { checkoutFlow } from "./flows";
import Page from "../../components/page";
import { SECTION_SLUGS } from "../../constants/sections";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import DeleteModal from "../../components/delete-modal";
import AddAddressMobileNumber from "../../components/modal/modal-mobile-verification/addAddressMobileNumber";
import { GAService } from "../../services/GA-service";
import { addScriptToBody } from "../../util/dom";

class Checkout extends Component {
  state = {
    checkoutTranslation: checkoutTranslation,
    myCartTranslation: myCart,
    loginTranslation: loginTranslation,
    profileTranslation: profileTranslation,
    forgotPasswordTranslation: forgotPasswordTranslation,
    isVoucherValid: null,
    isVoucherEmpty: false,
    voucherCode: "",
    isSameVoucherCode: false,
    orderId: "",
    walletCapable: false,
    openSection: false,
    netCoreEvent: false,
    gtmEvent: false,
    openVoucher: false,
    isCheckout: false,
    isShowLoyaltyAlert: false,
    isTabbySession: false,
    showOTPModal: false,
    interruptedByOTP: false,
    onSuccessOtp: null,
    isChangeUserFieldOpen: false,
    personalId: "",
    mapsLoaded: false
  };

  isFetchingPaypal = false;
  paymentWrapperRef = createRef();

  async UNSAFE_componentWillMount() {
    if (isServer) return;

    const script = addScriptToBody(
      GOOGLE_MAPS_APIS_SRC,
      () => {
        this.setState({ mapsLoaded: true });
      },
      true
    );
    if (!script) {
      this.setState({ mapsLoaded: true });
    }
    const { match, handleLanguageChange } = this.props;

    const { language } = match.params;

    handleLanguageChange(language);
    this.loadLanguage(language);
  }

  refetchCheckoutData = async () => {
    const {
      match,
      fetchCheckoutData,
      history,
      currency,
      addressBook,
      countrySlug,
      routeSlug,
      resetPaymentMethod,
      countryId,
      fetchEarnedLoyaltyPoints,
      userLoggedIn
    } = this.props;

    const { language, id } = match.params;

    if (id) {
      const response = await fetchCheckoutData(
        id,
        history,
        language,
        countrySlug
      );

      resetPaymentMethod();

      const data = response && response.data;

      if (data) {
        if (!isEmpty(addressBook)) {
          this.setAddress(addressBook);
        }
        if (data.customerId > 0 && !data.step1Completed) {
          this.changeCheckoutData("step1Completed", true, true);
        }
        this.clearDiscount(false);
        this.changeCheckoutData("voucherCode", "", false);
        if (userLoggedIn) {
          countryId && fetchEarnedLoyaltyPoints(language, currency, countryId);
        } else {
          this.setState({ isShowLoyaltyAlert: true });
        }
      }
    } else {
      history.push(`/${routeSlug}/my-cart/`);
    }
  };

  async componentDidMount() {
    const {
      fetchMyWallet,
      language,
      currency,
      voucherList,
      commonSettings,
      state,
      updateDeviceInfo,
      getStatesData,
      loadCreditCards,
      userLoggedIn,
      countryId,
      getBannerSections,
      isJoinedToLoyaltyProgram
    } = this.props;

    this.loadPayPalScript(() => console.log("PayPal loaded"));
    if (countryId) {
      getBannerSections(SECTION_SLUGS.CHECKOUT);
      await this.refetchCheckoutData();
    }

    updateDeviceInfo();
    if (userLoggedIn) {
      isJoinedToLoyaltyProgram && fetchMyWallet(language, currency);
      loadCreditCards();
    }
    const currencyCode = commonSettings && commonSettings.currencyCode;

    if (currencyCode) {
      voucherList({
        currencyCode: currencyCode
      });
    }

    if (commonSettings.countryId && isEmpty(state)) {
      getStatesData(commonSettings.countryId, language);
    }
    setCookie(PRODUCT_PURCHASE_EXECUTED, false);
    localStorage.removeItem(CHECKOUT_ID);
    localStorage.removeItem(IS_AUTH_FROM_CHECKOUT);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      order,
      addressBook,
      voucherList,
      commonSettings,
      countryCode,
      fetchCartData,
      _address,
      setStandardDeliveryAddress,
      deliveryAddressFromMap,
      authReducer,
      payPalToken,
      userLoggedIn,
      accessGuestToken,
      loadCreditCards,
      fetchMyWallet,
      language,
      getBannerSections,
      fetchEarnedLoyaltyPoints,
      isJoinedToLoyaltyProgram
    } = this.props;

    const npOrderItems = get(nextProps, "order.orderItems", []);
    const npAuthReducer = get(nextProps, "authReducer", {});
    const npCommonSettings = get(nextProps, "commonSettings", {});
    const npDeliveryType = get(nextProps, "order.deliveryType", "");
    const totalAmount = this.getTotal(npOrderItems);

    const orderLengthChanged =
      nextProps.order &&
      npOrderItems &&
      order.orderItems &&
      npOrderItems.length !== order.orderItems.length;
    if (nextProps.commonSettings.countryId !== commonSettings.countryId) {
      this.refetchCheckoutData();
    }
    if (nextProps.authReducer.customerId !== authReducer.customerId) {
      let stateToUpdate = { walletCapable: false };

      if (nextProps.authReducer.userLoggedIn) {
        stateToUpdate = { ...stateToUpdate, useMyWallet: order.useMyWallet };
        loadCreditCards();
      }

      this.setState(stateToUpdate);
    }

    if (
      (userLoggedIn || accessGuestToken) &&
      !payPalToken &&
      !this.isFetchingPaypal
    ) {
      this.getPayPal();
      this.isFetchingPaypal = true;
    }

    const { netCoreEvent, gtmEvent } = this.state;

    const isLanguageChanged = this.props.language !== nextProps.language;
    const isCurrencyChanged =
      commonSettings.currencyCode !== nextProps.commonSettings.currencyCode;
    const needToFetchSections =
      nextProps.commonSettings.countryId &&
      (isLanguageChanged || isCurrencyChanged);

    if (isLanguageChanged) {
      this.loadLanguage(nextProps.language);
      fetchCartData(true, nextProps.language);
    }
    if (isCurrencyChanged) {
      voucherList({ currencyCode: nextProps.commonSettings.currencyCode });
    }
    if (needToFetchSections) {
      getBannerSections(SECTION_SLUGS.CHECKOUT);
    }

    const loyaltyProgramStatusChanged =
      nextProps.isJoinedToLoyaltyProgram !== isJoinedToLoyaltyProgram;

    const userDataShouldBeRefetch =
      userLoggedIn &&
      (isCurrencyChanged || orderLengthChanged || loyaltyProgramStatusChanged);

    if (userDataShouldBeRefetch) {
      nextProps.isJoinedToLoyaltyProgram &&
        fetchMyWallet(language, nextProps.currency);

      fetchEarnedLoyaltyPoints(
        nextProps.language,
        nextProps.currency,
        nextProps.countryId
      );
    }

    if (addressBook.length !== nextProps.addressBook.length) {
      const filteredAddresses =
        Array.isArray(nextProps.addressBook) &&
        nextProps.addressBook.filter(
          addressBook =>
            addressBook.default === true ||
            addressBook.default === "true" ||
            addressBook.default === "Y"
        );

      if (filteredAddresses && deliveryAddressFromMap.addressLine1) {
        setStandardDeliveryAddress(filteredAddresses[0]);
      }
    }

    const npAddressBook = get(nextProps, "addressBook", []);
    const npdeliveryType = get(nextProps, "checkoutData.deliveryType", "");
    const npCountryCode = get(nextProps, "countryCode", "");

    if (
      (addressBook &&
        npAddressBook &&
        npdeliveryType === "DELIVERY_ADDRESS" &&
        addressBook.length !== npAddressBook.length) ||
      (countryCode && npCountryCode !== countryCode)
    ) {
      this.setAddress(npAddressBook, nextProps);
    }

    if (
      !isEmpty(order) &&
      !isEmpty(nextProps.order) &&
      nextProps.order.orderItems &&
      (nextProps.order.orderItems[0].brandName ||
        nextProps.order.orderItems[0].captionImageURL) &&
      !gtmEvent
    ) {
      const actionField = {};
      GAService.checkout.trackInitiateCheckout({
        items: npOrderItems,
        totalAmount
      });
      AnalyticService.checkout.trackCheckout({
        actionField,
        orderItems: npOrderItems,
        authReducer: npAuthReducer,
        commonSettings: npCommonSettings,
        deliveryType: npDeliveryType,
        totalAmount
      });

      this.setState({
        gtmEvent: true
      });
    }

    if (
      order &&
      nextProps.order &&
      nextProps.order.step1Completed !== order.step1Completed &&
      nextProps.order.step1Completed
    ) {
      const actionField = { step: 1, option: "login" };

      AnalyticService.checkout.trackCheckoutStepOne({
        actionField,
        orderItems: npOrderItems,
        authReducer: npAuthReducer,
        commonSettings: npCommonSettings,
        deliveryType: npDeliveryType,
        totalAmount
      });
    }
    if (
      order &&
      nextProps.order &&
      this.props.order.step2Completed !== nextProps.order.step2Completed &&
      nextProps.order.step2Completed
    ) {
      const { myCartTranslation } = this.state;
      if (_address.firstname) {
        AnalyticService.checkout.trackCheckoutStepTwo({
          orderItems: npOrderItems,
          authReducer: npAuthReducer,
          commonSettings: npCommonSettings,
          address: _address,
          myCartTranslation,
          deliveryType: npDeliveryType,
          totalAmount
        });
      }
      GAService.checkout.trackAddShippingInfo({
        items: npOrderItems,
        deliveryType: myCart[npDeliveryType],
        totalAmount
      });
      this.setState({
        netCoreEvent: true
      });
    }

    if (
      order &&
      nextProps.order &&
      nextProps.order.step3Completed !== order.step3Completed &&
      nextProps.order.step3Completed
    ) {
      const selectedPayment = get(nextProps, "order.selectedPayment", "");
      const { checkoutTranslation } = this.state;
      AnalyticService.checkout.trackCheckoutStepThree({
        orderItems: npOrderItems,
        authReducer: npAuthReducer,
        commonSettings: npCommonSettings,
        selectedPayment,
        checkoutTranslation
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      order,
      currency,
      commonSettings,
      state,
      language,
      checkoutData,
      updateCheckout,
      isJoinedToLoyaltyProgram,
      getLoyalityPoints,
      isCheckedLoyaltyProgram
    } = this.props;
    const isUseMyWalletNeedToBeUpdated =
      order &&
      order.useMyWallet &&
      order.useMyWallet !== prevProps.order.useMyWallet;

    const prevCountryId = prevProps.commonSettings.countryId;
    const needGetStatesData =
      !prevCountryId && commonSettings.countryId && isEmpty(state);

    const isLoyaltyStatusRefetched =
      isCheckedLoyaltyProgram &&
      prevProps.isCheckedLoyaltyProgram !== isCheckedLoyaltyProgram;
    if (needGetStatesData) {
      getStatesData(commonSettings.countryId, language);
    }

    if (isUseMyWalletNeedToBeUpdated) {
      this.setState({ useMyWallet: order.useMyWallet });
    }

    const savedCurrency = getCookie(CURRENCY_CODE);
    const currencyCookieShouldBeUpdated =
      (!savedCurrency && currency) || (currency && savedCurrency !== currency);

    if (currencyCookieShouldBeUpdated) {
      setCookie(CURRENCY_CODE, currency);
    }

    const paymentOptionWasUnselected =
      prevProps.order.selectedPayment &&
      !isEqual(prevProps.order.selectedPayment, order.selectedPayment) &&
      isEmpty(order.selectedPayment);

    if (paymentOptionWasUnselected) {
      this.applyVoucher(null, false, true);
    }
    if (commonSettings.countryId && checkoutData?.addressId) {
      const isUpdatedCountry =
        commonSettings.countryId !== prevProps.commonSettings.countryId;
      const isUpdatedAddress =
        Number(checkoutData?.addressId) !==
        Number(prevProps.checkoutData?.addressId);
      if (isUpdatedAddress || isUpdatedCountry) {
        updateCheckout();
      }
    }
    if (isJoinedToLoyaltyProgram && isLoyaltyStatusRefetched) {
      const loyalityPointsBody = this.getLoyaltyPointsBody(
        currency,
        order.orderItems,
        commonSettings
      );

      await getLoyalityPoints(loyalityPointsBody);
      this.setState({ isShowLoyaltyAlert: true });
    } else if (isLoyaltyStatusRefetched && !isJoinedToLoyaltyProgram) {
      this.setState({ isShowLoyaltyAlert: true });
    }
  }

  getPayPal = debounce(() => {
    this.props.getPayPalToken();
  }, 500);

  setAddress = (addressBook, nextProps) => {
    const { commonSettings } = this.props;
    const accessToken = getCookie("access_token");

    if (isEmpty(addressBook)) return;

    const latestAddedAddress =
      addressBook.find(
        address => address.addressId === get(nextProps, "_address.addressId")
      ) || {};
    const countryAddressBook =
      addressBook.find(
        address => address.countryId === commonSettings.countryId
      ) || {};

    const addressId =
      latestAddedAddress.addressId || countryAddressBook.addressId;

    const address =
      addressId && addressBook.find(address => address.addressId === addressId);
    if (address && !get(nextProps, "order.continueAsGuest") && accessToken) {
      this.updateAddress(address, null, null, false);
    }
  };

  loadLanguage = language => {
    if (language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }

    const forgotPasswordLangResponse = import(
      `../../language/forgotPassword/${language}/forgotPassword`
    );
    const languageResponse = import(`../../language/myCart/${language}/myCart`);
    const languageResponseProduct = import(
      `../../language/checkoutFlow/${language}/checkoutFlow`
    );
    const loginResponse = import(`../../language/login/${language}/login`);
    const profileResponse = import(
      `../../language/profile/${language}/profile.js`
    );

    forgotPasswordLangResponse.then(response =>
      this.setState({
        forgotPasswordTranslation: response.forgotPasswordTranslation
      })
    );
    languageResponseProduct.then(response =>
      this.setState({
        checkoutTranslation: response.checkoutTranslation
      })
    );
    languageResponse.then(response =>
      this.setState({
        myCartTranslation: response.myCart
      })
    );
    loginResponse.then(response =>
      this.setState({
        loginTranslation: response.loginTranslation
      })
    );
    profileResponse.then(response =>
      this.setState({ profileTranslation: response.profileTranslation })
    );
  };

  changeCheckoutData = (key, value, updateDB = true) => {
    if (!key) return;

    const {
      changeCheckoutData,
      updateCheckout,
      setUserInfo,
      order,
      userInfo,
      logOut,
      removeAppliedVoucher
    } = this.props;
    const { useMyWallet } = this.state;

    if (key !== "selectedPayment") {
      changeCheckoutData(key, value);
    }

    if (value && key === "customerId") {
      changeCheckoutData("step1Completed", true);

      this.handleVoucherAppliedMessage();

      removeAppliedVoucher();
    } else if (key === "continueAsGuest" && value) {
      setUserInfo({
        userProfile: {
          ...userInfo,
          email: order.guestEmail
        }
      });

      changeCheckoutData("step1Completed", true);
      changeCheckoutData("step2Completed", false);
      changeCheckoutData("address", null);

      this.handleVoucherAppliedMessage();

      removeAppliedVoucher();

      logOut(false);
    } else if (key === "selectedPayment") {
      if (["CC", "COD", "PAYPAL", "CBD"].includes(value)) {
        const paymentMode = [value];

        if (useMyWallet && order.wallet) {
          paymentMode.push(WALLET);
        }
        if (order.discount) {
          paymentMode.push(VOUCHER);
        }

        changeCheckoutData(key, paymentMode);
        changeCheckoutData("step3Completed", true);
      } else {
        changeCheckoutData("step3Completed", false);
      }
    }

    if (updateDB) {
      updateCheckout();
    }
  };

  updatedAddress = data => {
    const addressLine1 = data.addressLine1 || "";
    const address = {
      ...data,
      firstname: `${data.firstname}`.trim(),
      lastname: `${data.lastname}`.trim(),
      address: `${data.address}`.trim(),
      area: `${data.area}`.trim(),
      country: `${data.country}`.trim(),
      city: (data.city && data.city.trim()) || "",
      addressLine1: addressLine1.trim()
    };

    return address;
  };

  setSelectAddressData = (address, order) => {
    const { setStandardDeliveryAddress } = this.props;

    switch (order.deliveryType) {
      case "DELIVERY_ADDRESS":
        setStandardDeliveryAddress(address);
        break;
      default:
    }
  };

  async updateAddress(
    address,
    step,
    pickupFromStore,
    checkGuestFlow = true,
    onSuccessOtpCallback
  ) {
    if (!address) return;

    let successfullyUpdated = true;

    const {
      saveAddress,
      fetchUserAndLoadData,
      setAddress,
      commonSettings,
      order,
      guestUserCheckout
    } = this.props;

    address = this.updatedAddress(address);

    if (!address.areaId) {
      address.areaId = -1;
    }

    address.countryId = commonSettings.countryId || "236";
    const standardDeliverySelected =
      address.deliveryType === DELIVERY_TYPES.DELIVERY_ADDRESS;

    if (standardDeliverySelected) {
      this.setState(() => ({ interruptedByOTP: false }));
    }

    if (
      (checkGuestFlow && order.continueAsGuest) ||
      (pickupFromStore && order.continueAsGuest)
    ) {
      const response = await guestUserCheckout({
        email: order.guestEmail,
        ...address,
        deliveryType: order.deliveryType
      });

      const commonGuestAddressUpdateFlow = response => {
        if (get(response, "data.success")) {
          this.updateAddressAndCustomer(
            "customerId",
            response.data.customerId,
            {
              ...address,
              addressId: response.data.addressId
            }
          );
          const guestCustomerId = get(response, "data.customerId", "");
          AnalyticService.checkout.trackAddressGuest({
            address,
            guestCustomerId,
            commonSettings,
            order
          });
        } else {
          successfullyUpdated = false;
        }
      };

      if (!order.step2Completed && response.OTPNeeded) {
        this.setState({
          showOTPModal: true,
          ...(standardDeliverySelected && {
            interruptedByOTP: true
          }),
          onSuccessOtp: (...args) => {
            setTimeout(() => {
              commonGuestAddressUpdateFlow(...args);
              this.onAddressUpdated();
            }, 200);
            onSuccessOtpCallback?.();
          }
        });
        return;
      }

      commonGuestAddressUpdateFlow(response);
    } else {
      const response = await saveAddress({ ...address }, order.continueAsGuest);

      const addressSetting = addressId => {
        this.changeCheckoutData(
          "address",
          {
            ...address,
            addressId,
            errors: []
          },
          false
        );

        this.changeCheckoutData("addressId", addressId, false);

        this.setSelectAddressData(address, order);

        setAddress({
          ...address,
          addressId
        });
      };

      const commonAddressUpdateFlow = response => {
        if (get(response, "data.success")) {
          fetchUserAndLoadData({ keepDeliverySettings: true });

          const addressId = response.data.addressId || address.addressId;

          addressSetting(addressId);

          if (step === "step2") {
            this.changeCheckoutData("step2Completed", true);

            this.setState({ addAddress: false });
          }
        }
      };
      if (!order.step2Completed && response.OTPNeeded) {
        this.setState({
          showOTPModal: true,
          ...(standardDeliverySelected && {
            interruptedByOTP: true
          }),
          onSuccessOtp: (...args) => {
            setTimeout(() => {
              commonAddressUpdateFlow(...args);
              this.onAddressUpdated();
            }, 200);
            onSuccessOtpCallback?.();
          }
        });
        if (standardDeliverySelected) {
          addressSetting(address.addressId);
        }
        return;
      }

      onSuccessOtpCallback?.();
      commonAddressUpdateFlow(response);
    }
    return successfullyUpdated;
  }

  updateAddressAndCustomer = (key, value, address) => {
    const { setAddress } = this.props;

    this.changeCheckoutData(key, value, false);
    this.changeCheckoutData("address", address);
    this.changeCheckoutData("step1Completed", true, false);

    const updatedAddress = this.updatedAddress(address);

    setAddress(updatedAddress);
  };

  getTotal = cart => {
    return (
      cart &&
      cart.length &&
      cart
        .map(
          item => (item.transactionPrice || item.price) * (item.quantity || 1)
        )
        .reduce((total, num) => total + num, 0)
    );
  };

  getTotalAED = cart => {
    return (
      cart &&
      cart.length &&
      cart
        .map(item => item.price * (item.quantity || 1))
        .reduce((total, num) => total + num, 0)
    );
  };

  getModifiedDeliveryOption = (
    option,
    myCartTranslation,
    currency,
    language,
    total
  ) => {
    const freeShippingReachable = shippingMayBeFree(
      option.freeShippingLimit,
      option.shippingCharge
    );
    const freeShippingReached =
      freeShippingReachable &&
      isFreeShippingReached(option.freeShippingLimit, total);
    const freeShippingForced = isFreeShippingForced(
      option.freeShippingLimit,
      option.shippingCharge
    );
    const isFreeShipping = freeShippingForced || freeShippingReached;

    let shippingChargeMessage = `${
      currency || "AED"
    } ${priceUtil.shapePriceWithComma(option.shippingCharge)}`;
    let shippingLimitMessage =
      freeShippingReachable && !freeShippingReached
        ? `${myCartTranslation.freeShippingAbove} ${
            currency || "AED"
          } ${priceUtil.shapePriceWithComma(option.freeShippingLimit)}`
        : "";

    if (isFreeShipping) {
      shippingLimitMessage = getShippingLimitMessage(option, myCartTranslation);
      if (freeShippingForced) {
        shippingChargeMessage = myCartTranslation.free;
      }
    }

    return {
      ...option,
      key: option.deliveryType,
      paymentType: option.paymentType,
      price: option.shippingCharge,
      value: `<h5>${
        myCartTranslation && myCartTranslation[option.deliveryType]
      } 
                  <span class=${cn({ "crossed-text": freeShippingReached })}> ${
        !freeShippingForced && freeShippingReached ? shippingChargeMessage : ""
      }</span>
                <span class=${cn({ green_color_text: isFreeShipping })}> (${
        isFreeShipping ? myCartTranslation.free : shippingChargeMessage
      })</span>  
                </h5>
                <p class="deliveryMessage">${
                  language === "en"
                    ? option.en_deliveryTypeMessage
                    : option.ar_deliveryTypeMessage
                }</p>
              <p class="deliveryMessage">${
                language === "en"
                  ? option.en_deliveryMessage
                  : option.ar_deliveryMessage
              }</p>
              <p class='shippingCharge ${
                option.deliveryType
              }'>${shippingLimitMessage}</p> `
    };
  };

  toggleToJoin = () => {
    const { signInModal, showModal } = this.props;

    signInModal(false);
    showModal();
  };

  showForgotPasswordModal = () => {
    const { clearModal, showForgotPasswordModal } = this.props;

    showForgotPasswordModal();
    clearModal();
  };

  hideForgotPasswordModal = () => {
    const { hideModal, hideForgotPasswordModal } = this.props;

    hideForgotPasswordModal();
    hideModal();
  };

  changeVoucher = async (e, voucherNameFromList) => {
    const { changeCheckoutData, resetSummaryRecalculationInProgress } =
      this.props;
    const code = (e.target?.value || voucherNameFromList || "").trim();

    if (code && code === this.state.voucherCode) {
      this.setState({ isSameVoucherCode: true });
      resetSummaryRecalculationInProgress();
      return;
    }

    await changeCheckoutData("voucherCode", code);

    if (e.target?.value) {
      this.handleVoucherAppliedMessage();
    }
  };

  changePersonalId = e => {
    if (e.target.value.length > MAX_CIVIL_ID_LENGTH) return;
    this.setState({
      personalId: e.target.value,
      isPersonalId: !e.target.value
    });
  };

  getAreaCodes = (countryData, defaultCountry) => {
    const countryInfo =
      countryData &&
      countryData.find(country => country.countrySHORT === defaultCountry);

    return (
      countryInfo &&
      countryInfo.mobileLocalCode &&
      countryInfo.mobileLocalCode.split(",")
    );
  };

  onAddressUpdated = () => {
    this.changeCheckoutData("step2Completed", true);
    this.movePaymentSectionIntoView();
  };

  moveToNextStep = async () => {
    const {
      countryData,
      countrySHORT,
      order,
      deliveryAddressFromMap,
      authReducer,
      mobileCountryCode,
      commonSettings,
      standardDeliveryAddress,
      inStoreAddressFromMap
    } = this.props;

    const areaCodes = this.getAreaCodes(countryData, countrySHORT);

    const sliceNumberLength =
      commonSettings && commonSettings.mobileLocalNumberLength;

    const areaCode = !isEmpty(areaCodes) ? areaCodes[0] : "";

    const finalAreaCode = deliveryAddressFromMap.areaCode.length
      ? deliveryAddressFromMap.areaCode
      : areaCode;
    const finalInstoreAreaCode = inStoreAddressFromMap.areaCode.length
      ? inStoreAddressFromMap.areaCode
      : areaCode;

    const modifiedPhone = deliveryAddressFromMap.phone.slice(
      -sliceNumberLength
    );
    const modifiedPhoneInstore = inStoreAddressFromMap.phone.slice(
      -sliceNumberLength
    );

    const deliveryAddressFromMapTransformed = {
      ...deliveryAddressFromMap,
      phone:
        modifiedPhone && `${mobileCountryCode}${finalAreaCode}${modifiedPhone}`,
      firstname: deliveryAddressFromMap.firstname || "NA",
      lastname: deliveryAddressFromMap.lastname || "",
      country: authReducer.country || ""
    };
    const inStoreAddressFromMapTransformed = {
      ...inStoreAddressFromMap,
      phone:
        modifiedPhoneInstore &&
        `${mobileCountryCode}${finalInstoreAreaCode}${modifiedPhoneInstore}`,
      firstname: inStoreAddressFromMap.firstname || "NA",
      lastname: inStoreAddressFromMap.lastname || "",
      country: authReducer.country || ""
    };

    if (deliveryAddressFromMap && order.deliveryType === "SELF_PICKUP") {
      deliveryAddressFromMapTransformed.default = false;
      const isUpdated = await this.updateAddress(
        deliveryAddressFromMapTransformed,
        null,
        true
      );

      if (!isUpdated) {
        this.changeCheckoutData("step2Completed", false);
        return;
      }

      this.onAddressUpdated();
    } else if (inStoreAddressFromMap && order.deliveryType === "STORE_PICKUP") {
      inStoreAddressFromMapTransformed.default = false;
      const isUpdated = await this.updateAddress(
        inStoreAddressFromMapTransformed,
        null,
        true
      );

      if (!isUpdated) {
        this.changeCheckoutData("step2Completed", false);
        return;
      }

      this.onAddressUpdated();
    } else if (
      get(order, "address.countryId") === commonSettings.countryId &&
      get(standardDeliveryAddress, "addressLine1")
    ) {
      this.changeCheckoutData("step2Completed", true);

      this.movePaymentSectionIntoView();

      this.setState({ addAddress: false });
    } else {
      this.setState({ addAddress: true });
    }
    let bodyData = this.getOrderBody(true);
    if (
      (order, "discount") &&
      parseFloat(bodyData.transactionOrderTotal) === 0
    ) {
      this.changeCheckoutData("step3Completed", true, false);
    }
  };

  async movePaymentSectionIntoView() {
    await sleep(150);
    this.paymentWrapperRef.current.scrollIntoView({ behavior: "smooth" });
  }

  getOrderTotalDiscountFeeParams = (
    order,
    deliverySettings,
    paymentCurrencyExchangeRate,
    _selectedPaymentOption
  ) => {
    const { useMyWallet } = this.state;
    const { wallet, currency, language } = this.props;

    let transformedWallet =
      wallet &&
      wallet.walletAmount &&
      wallet.walletAmount.length &&
      wallet.walletAmount.find(amount => amount.currencyCode === currency);

    transformedWallet =
      transformedWallet && transformedWallet.transactionAmount;
    let orderSubTotal = this.getTotal(order.orderItems || []);

    let orderSubTotalAED = this.getTotalAED(order.orderItems || []);

    orderSubTotal = priceUtil.formatPriceByDecimal(
      orderSubTotal,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    let shippingSelected =
      deliverySettings.length &&
      deliverySettings.find(
        option =>
          option.deliveryType === order.deliveryType &&
          ((order.selectedPayment &&
            order.selectedPayment.indexOf(option.paymentType) !== -1) ||
            option.paymentType === settings_cart)
      );

    const shippingCharges = priceUtil.formatPriceByDecimal(
      shippingSelected.shippingCharge,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    const cod = get(_selectedPaymentOption, "cod_fee", 0);

    const cod_fee = priceUtil.formatPriceByDecimal(
      cod,
      PRICE_CONVERSION_DECIMAL_VALUE
    );
    const floatSubTotal = parseFloat(orderSubTotal);
    const freeShippingLimit = get(shippingSelected, "freeShippingLimit", 0);
    const shippingCharge = parseFloat(
      getShippingCharge(freeShippingLimit, shippingCharges, floatSubTotal)
    );

    let orderTotal = floatSubTotal + shippingCharge + parseFloat(cod_fee);
    let orderTotalAED =
      parseFloat(orderSubTotalAED) + shippingCharge + parseFloat(cod_fee);
    const totalForWallet = orderTotal - (order?.discount || 0);
    const transactionWalletAmount =
      (useMyWallet &&
        transformedWallet &&
        (totalForWallet < transformedWallet
          ? totalForWallet
          : transformedWallet)) ||
      0;

    order.discount = priceUtil.formatPriceByDecimal(
      order.discount,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    const orderDiscount = get(order, "discount", 0);

    orderTotal =
      (orderTotal - transactionWalletAmount > 0
        ? orderTotal - transactionWalletAmount
        : 0) -
        orderDiscount >
      0
        ? (orderTotal - transactionWalletAmount > 0
            ? orderTotal - transactionWalletAmount
            : 0) - orderDiscount
        : 0;
    orderTotalAED =
      (orderTotalAED - transactionWalletAmount > 0
        ? orderTotalAED - transactionWalletAmount
        : 0) -
        orderDiscount >
      0
        ? (orderTotalAED - transactionWalletAmount > 0
            ? orderTotalAED - transactionWalletAmount
            : 0) - orderDiscount
        : 0;

    const transactionOrderTotal = priceUtil.formatPriceByDecimal(
      orderTotal,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    const paymentCurrency = get(
      _selectedPaymentOption,
      "_paymentCurrency",
      currency
    );
    const selectedPaymentAmount =
      get(_selectedPaymentOption, "_paymentCurrencyExchangeRate", 1) *
      transactionOrderTotal;

    const paymentAmount = priceUtil.formatPriceByDecimal(
      selectedPaymentAmount,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    return {
      lang: language,
      orderSubTotal: orderSubTotalAED,
      transactionOrderSubTotal: parseFloat(orderSubTotal),
      shippingCharges: shippingCharge,
      transactionShippingCharges: shippingCharge,
      orderTotal: orderTotalAED,
      transactionOrderTotal: transactionOrderTotal,
      discount: order.discount
        ? (+order.discount).toFixed(PRICE_CONVERSION_DECIMAL_VALUE)
        : 0,
      transactionDiscount: +order.discount,
      codFee: cod_fee,
      transactionCodFee: cod_fee,
      transactionWalletAmount: transactionWalletAmount,
      baseCurrencyExchangeRate: paymentCurrencyExchangeRate,
      paymentCurrency,
      paymentAmount
    };
  };

  getLoyaltyPointsBody = (currency, items) => ({
    currencyCode: currency,
    orderItems:
      items &&
      items.length &&
      items.map(item => ({
        productId: item.productId,
        quantity: item.quantity,
        colorid: item.colorId || 0,
        sizeid: item.sizeId,
        price: item.oldPrice || item.currentPrice,
        transactionPrice: item.transactionPrice || 0,
        oldPrice: item.oldPrice || item.transactionOldPrice,
        promoType: item.promoType,
        transactionOldPrice: priceUtil.formatPriceByDecimal(
          item.oldPrice,
          PRICE_CONVERSION_DECIMAL_VALUE
        ),
        upc: item.upc,
        onlineStock: item.onlineStock
      }))
  });

  getOrderBody = forVoucher => {
    const {
      order,
      currency,
      deliverySettings,
      generalInfo,
      checkoutReducer,
      loyaltyDetails
    } = this.props;

    if (
      order &&
      order.step1Completed &&
      (forVoucher || order.step3Completed) &&
      !order.orderPlaced
    ) {
      const filteredDeliverySettings =
        deliverySettings &&
        deliverySettings.length &&
        deliverySettings.filter(
          setting =>
            (order.selectedPayment &&
              order.selectedPayment.indexOf(setting.paymentType) !== -1) ||
            setting.paymentType === settings_cart
        );
      const _uniqueDeliveryOptions = uniqBy(
        filteredDeliverySettings,
        "deliveryType"
      );
      const paymentCurrencyExchangeRate = get(
        checkoutReducer,
        "checkoutData.selectedPaymentOption._paymentCurrencyExchangeRate"
      );

      const _selectedPaymentOption = get(
        checkoutReducer,
        "checkoutData.selectedPaymentOption",
        {}
      );

      const _paymentGateway = get(
        checkoutReducer,
        "checkoutData.selectedPaymentOption._paymentGateway",
        ""
      );

      const tier = get(loyaltyDetails, "memeberLevel", "Yellow");
      return {
        ...this.getOrderTotalDiscountFeeParams(
          order,
          _uniqueDeliveryOptions,
          paymentCurrencyExchangeRate,
          _selectedPaymentOption
        ),
        paymentMode:
          order.selectedPayment &&
          !!order.selectedPayment.length &&
          order.selectedPayment.filter(payment => payment),
        deliveryType: order.deliveryType,
        continueAsGuest: order.continueAsGuest,
        shippingAddressId:
          (order.address && order.address.addressId) || order.addressId || 0,
        voucherNo: order.voucherNo && order.voucherNo.trim(),
        voucherCode: order.voucherCode && order.voucherCode.trim(),
        discount_type: order.discount_type || "",
        paymentGateway: _paymentGateway,
        isMob: false,
        ip: generalInfo.ip || "Not Allowed",
        appVersion: generalInfo.appVersion,
        longitude: generalInfo.longitude || "Not Allowed",
        latitude: generalInfo.latitude || "Not Allowed",
        tier,
        deviceInfo: generalInfo.deviceInfo || "Not Allowed",
        ...this.getLoyaltyPointsBody(currency, order.orderItems)
      };
    } else {
      return null;
    }
  };

  getTotalDiscount = (discountData, bodyData, voucherCode) => {
    let discount = 0;
    const { discount_type, disc_value, discount_limit } = discountData;
    const {
      transactionOrderSubTotal,
      orderItems,
      transactionShippingCharges,
      transactionOrderTotal
    } = bodyData;

    const { commonSettings } = this.props;

    if (discount_type === "FREE_SHIPPING") {
      discount =
        discount_limit === 0
          ? transactionShippingCharges
          : transactionOrderSubTotal >= discount_limit
          ? transactionShippingCharges
          : 0;
    } else if (discount_type === "DISC_PER") {
      discount = !discount_limit
        ? ((transactionOrderSubTotal * disc_value.percent) / 100).toFixed(
            PRICE_CONVERSION_DECIMAL_VALUE
          ) || 0
        : transactionOrderSubTotal >= discount_limit
        ? ((transactionOrderSubTotal * disc_value.percent) / 100).toFixed(
            PRICE_CONVERSION_DECIMAL_VALUE
          ) || 0
        : 0;
      discount =
        disc_value.max_discount && discount > disc_value.max_discount
          ? disc_value.max_discount
          : discount;
    } else if (discount_type === "DISC_AMOUNT") {
      const valueToBeDeducted =
        transactionOrderSubTotal > disc_value
          ? disc_value
          : transactionOrderSubTotal;
      discount = !discount_limit
        ? valueToBeDeducted
        : transactionOrderSubTotal >= discount_limit
        ? valueToBeDeducted
        : 0;
    } else if (discount_type === "ITEM_DISC_PER") {
      orderItems &&
        orderItems.length &&
        orderItems.forEach(item => {
          if (item.promoType.toLowerCase() === voucherCode.toLowerCase()) {
            discount += !discount_limit
              ? (((item.transactionPrice * disc_value.percent) / 100).toFixed(
                  PRICE_CONVERSION_DECIMAL_VALUE
                ) || 0) * item.quantity
              : transactionOrderSubTotal >= discount_limit
              ? (((item.transactionPrice * disc_value.percent) / 100).toFixed(
                  PRICE_CONVERSION_DECIMAL_VALUE
                ) || 0) * item.quantity
              : 0;
          }
        });
      discount =
        disc_value.max_discount && discount > disc_value.max_discount
          ? disc_value.max_discount
          : discount;
    } else if (discount_type === "DISC_AMOUNT_TOTAL") {
      const value =
        transactionOrderTotal > disc_value.transactionamount
          ? disc_value.transactionamount
          : transactionOrderTotal;
      discount = !discount_limit
        ? value
        : transactionOrderTotal >= discount_limit
        ? value
        : 0;
    }

    const converted = priceUtil.convertDiscountPriceByRoundType(
      discount,
      commonSettings
    );

    return converted;
  };

  applyVoucher = (e, isCouponFlow, isUnselectedCard) => {
    e && e.preventDefault();
    const {
      order,
      applyVoucher,
      changeCheckoutData,
      updateCheckout,
      authReducer,
      resetSummaryRecalculationInProgress,
      removeAppliedVoucher,
      VoucherCodes
    } = this.props;

    const { voucherCode, useMyWallet } = this.state;

    const isGuestLogin = !authReducer.userLoggedIn;
    const trimmedOrderVoucher = (order?.voucherCode || "").trim();
    if (!trimmedOrderVoucher && !isUnselectedCard) {
      this.setState({ isVoucherEmpty: true });
      return;
    } else {
      this.setState({ isVoucherEmpty: false });
    }

    if (
      trimmedOrderVoucher &&
      trimmedOrderVoucher === voucherCode &&
      !isCouponFlow
    ) {
      this.setState({
        isSameVoucherCode: order.voucherCode === voucherCode && order.voucherNo
      });
      return;
    }

    if (trimmedOrderVoucher) {
      this.changeCheckoutData("discount", 0, false);

      let bodyData = this.getOrderBody(true);
      const paymentModeBody = [VOUCHER];

      bodyData = {
        ...bodyData,
        paymentMode: paymentModeBody
      };

      applyVoucher(bodyData, isGuestLogin).then(response => {
        const selectedPayment =
          order.selectedPayment?.filter(value => value) || [];
        let amount = 0;
        bodyData = this.getOrderBody(true);
        let optionalStateUpdate = {};
        if (
          response?.data?.success &&
          typeof response.data.disc_value !== "undefined"
        ) {
          this.changeCheckoutData("step3Completed", false, false);
          amount = this.getTotalDiscount(
            response.data,
            bodyData,
            trimmedOrderVoucher
          );
          const voucherNumber = get(response, "data.voucherNo", "");
          const appliedVoucherData = VoucherCodes.find(
            each => each.voucherNo === voucherNumber
          );
          const coupenStatus = get(response, "data.success", false);
          this.changeCheckoutData("discount", amount ? amount : 0, false);
          this.changeCheckoutData(
            "discount_limit",
            response.data?.discount_limit,
            false
          );

          changeCheckoutData("selectedPayment", [
            ...new Set([
              ...(order.selectedPayment || []),
              amount ? VOUCHER : ""
            ])
          ]);
          if (amount) {
            optionalStateUpdate = {
              ...optionalStateUpdate,
              openVoucher: false
            };
            const voucherCode =
              order.voucherCode || voucherNumber.split("_")[0] || "";
            AnalyticService.checkout.trackApplyVoucher({
              voucherNumber,
              discount: amount,
              coupenStatus
            });
            GAService.checkout.trackApplyVoucher({
              code: voucherCode,
              voucherData: get(appliedVoucherData, "voucherText", {})
            });
            this.changeCheckoutData(
              "discount_type",
              response.data?.discount_type,
              false
            );
            this.changeCheckoutData(
              "voucherNo",
              response.data.voucherNo,
              false
            );
            this.changeCheckoutData("voucherCode", order.voucherCode, false);
          } else {
            this.clearVoucherAndDiscountWithoutDBUpdating();
          }
          resetSummaryRecalculationInProgress();
          if (amount && parseFloat(bodyData.transactionOrderTotal) === 0) {
            this.changeCheckoutData("step3Completed", true, false);

            changeCheckoutData("selectedPayment", [
              ...new Set([
                ...(order.selectedPayment || []),
                amount ? VOUCHER : ""
              ])
            ]);

            AnalyticService.checkout.trackApplyVoucher({
              voucherNumber,
              discount: amount,
              coupenStatus
            });
            GAService.checkout.trackApplyVoucher({
              code: voucherCode,
              voucherData: get(appliedVoucherData, "voucherText", {})
            });
            this.changeCheckoutData(
              "discount_type",
              response.data?.discount_type,
              false
            );
            this.changeCheckoutData(
              "voucherNo",
              response.data.voucherNo,
              false
            );
            this.changeCheckoutData("step3Completed", true, false);
            optionalStateUpdate = {
              ...optionalStateUpdate,
              walletCapable: true
            };
            let selectedPaymentOnApplyVoucher = [VOUCHER];
            if (useMyWallet) {
              selectedPaymentOnApplyVoucher = [WALLET, VOUCHER];
            }
            changeCheckoutData &&
              changeCheckoutData(
                "selectedPayment",
                selectedPaymentOnApplyVoucher
              );
          } else {
            this.changeCheckoutData("step3Completed", false, false);
            if (
              !isEmpty(selectedPayment) &&
              (checkPaymentOptionSelection(selectedPayment) ||
                selectedPayment.indexOf("CBD") !== -1)
            ) {
              this.changeCheckoutData("step3Completed", true, false);
            } else {
              this.changeCheckoutData("step3Completed", false, false);
            }
          }
        } else {
          this.clearDiscount();
          removeAppliedVoucher();
          if (
            parseFloat(bodyData.transactionOrderTotal) !== 0 &&
            isCommonPaymentNotSelected(selectedPayment)
          ) {
            this.changeCheckoutData("step3Completed", false, false);
          }
        }
        const isSameVoucherCode =
          order.voucherCode === voucherCode && order.voucherNo;
        this.setState({
          ...optionalStateUpdate,
          isVoucherValid: response.data.success && amount,
          inValidVoucherMessage: response.data.message || "",
          isSameVoucherCode,
          voucherCode: (amount && trimmedOrderVoucher) || ""
        });
        updateCheckout && updateCheckout();
      });
    }
  };

  preApplyVoucher = voucherDetails => {
    const { applyVoucher, authReducer } = this.props;
    const { voucherCode, voucherNo } = voucherDetails || {};
    const isGuestLogin = !authReducer.userLoggedIn;
    const trimmedOrderVoucher = (voucherCode || "").trim();
    if (trimmedOrderVoucher) {
      let bodyData = this.getOrderBody(true);
      const paymentModeBody = [VOUCHER];
      bodyData = {
        ...bodyData,
        paymentMode: paymentModeBody,
        voucherCode,
        voucherNo
      };
      return applyVoucher(bodyData, isGuestLogin);
    }
    return Promise.resolve();
  };

  clearVoucherAndDiscountWithoutDBUpdating = () => {
    this.changeCheckoutData("discount_type", "", false);
    this.changeCheckoutData("voucherNo", "", false);
    this.changeCheckoutData("voucherCode", "", false);
  };

  clearDiscount = (updateDb = true) => {
    this.changeCheckoutData("discount_type", "", false);
    this.changeCheckoutData("discount", 0, false);
    this.changeCheckoutData("voucherNo", "", false);
    this.changeCheckoutData("discount_limit", "", updateDb);
  };

  handleVoucherAppliedMessage = () => {
    this.setState({
      isVoucherValid: null,
      isSameVoucherCode: false,
      voucherCode: ""
    });
  };

  validateAddressForm = e => {
    const { payment, handleTextChange, selectedSavedCard } = this.props;

    const paymentKeysFiltered =
      (selectedSavedCard &&
        Object.keys(payment.errors).filter(key => key !== "cardNumber")) ||
      Object.keys(payment.errors);

    paymentKeysFiltered.forEach(key => {
      handleTextChange(key, payment[key]);
    });

    e && e.preventDefault();
  };

  showLoader = () => {
    const { showPageLoader } = this.props;
    showPageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
  };
  hideLoader = () => {
    const { hidePageLoader } = this.props;
    hidePageLoader({ type: LOADER_TYPES.DOTS, fullscreen: true });
  };
  checkIfPersonalIdRequired = (isPersonalIdRequired, personalId) => {
    return isPersonalIdRequired
      ? personalId.length >= MIN_CIVIL_ID_LENGTH &&
          personalId.length <= MAX_CIVIL_ID_LENGTH
      : true;
  };
  validatePlaceOrder = (data, isPersonalIdRequired) => {
    const { personalId } = this.state;
    isPersonalIdRequired && this.setState({ isPersonalId: !personalId.length });
    this.checkIfPersonalIdRequired(isPersonalIdRequired, personalId) &&
      this.placeOrder(data, isPersonalIdRequired);
  };
  changePersonalIdStatus = () => {
    const { personalId } = this.state;
    this.setState({ isPersonalId: !personalId.length });
  };

  placeOrder = async (data, isPersonalIdRequired) => {
    try {
      const { history, language, confirmOrder, payment, countrySlug, binNo } =
        this.props;

      const { personalId } = this.state;

      this.setState({ isCheckout: true });

      this.validateAddressForm();

      const orderBody = this.getOrderBody();

      if (!orderBody) return;

      const isCreditCardPaymentMethod = orderBody.paymentMode.includes("CC");
      const isCheckoutComCreditCardPaymentMethod =
        orderBody.paymentGateway === PAYMENT_GATEWAYS.CHECKOUT_COM;
      const isPayPalPaymentMethod = orderBody.paymentMode.includes("PAYPAL");

      const enhancedOrderBody = {
        ...orderBody,
        ...(isPersonalIdRequired && { personalId }),
        binNo
      };

      if (isCreditCardPaymentMethod && !isCheckoutComCreditCardPaymentMethod) {
        const paymentKeys = Object.keys(payment.errors).filter(
          key => key !== "cardName"
        );

        const isValidated = paymentKeys.every(key => {
          if (enhancedOrderBody.paymentGateway === PAYMENT_GATEWAYS.AREEBA) {
            return true;
          } else {
            const error = payment.errors[key];
            return error.valid && error.touched;
          }
        });

        if (!isValidated) return;
      }
      this.showLoader();

      const response = await confirmOrder(enhancedOrderBody, history, language);
      const isPossibleToBuy =
        get(response, "data.success") &&
        enhancedOrderBody.paymentMode &&
        enhancedOrderBody.transactionAmount !== 0;

      if (!isPossibleToBuy) {
        this.setState({ orderFailedText: response.data.message });
        this.hideLoader();

        return;
      }

      const { orderId, paymentToken } = response.data;

      setCookie(
        TOTAL_AMOUNT_FOR_PAYMENT,
        enhancedOrderBody.transactionOrderTotal || 0
      );

      const { continueAsGuest } = enhancedOrderBody;

      if (isCreditCardPaymentMethod) {
        this.handleCCPaymentRequest({
          payment,
          orderBody: enhancedOrderBody,
          orderId,
          language,
          paymentToken,
          countrySlug,
          continueAsGuest
        });
      } else if (isPayPalPaymentMethod) {
        const { paypal } = data;

        this.processPayPalPayment({ orderId, paymentToken, data: paypal });
      } else {
        this.changeCheckoutData("orderPlaced", true);
        this.hideLoader();
        this.redirectToSuccessPage(orderId);
      }
    } catch (error) {
      this.hideLoader();
      this.redirectToErrorPage();
    }
  };

  handleCCPaymentRequest = paymentData => {
    const { checkoutReducer } = this.props;

    const paymentGateway = get(
      checkoutReducer,
      "checkoutData.selectedPaymentOption._paymentGateway"
    );

    switch (paymentGateway) {
      case PAYMENT_GATEWAYS.AREEBA:
        return this.handleAreebaPaymentGateway(paymentData);

      default:
        return checkoutFlow.call(this, paymentData);
    }
  };

  handleAreebaPaymentGateway = arg => {
    const { orderBody, orderId, paymentToken } = arg;

    this.processAreebaPaymentRequest(orderBody, orderId, paymentToken);
  };

  processAreebaPaymentRequest = async (orderBody, orderId, paymentToken) => {
    const { showLoadingIndicator } = this.props;
    showLoadingIndicator();
    const amount = get(orderBody, "paymentAmount", 0);
    const currency = get(orderBody, "paymentCurrency", 0);
    const param = {
      currency,
      amount,
      id: paymentToken
    };

    const arrebaTknResp = await CheckoutService.getAreebaCheckoutToken(param);

    window.Checkout.configure({
      merchant: get(arrebaTknResp, "merchant", ""),
      order: {
        amount,
        currency,
        description: "My order",
        id: paymentToken
      },
      session: {
        id: get(arrebaTknResp, "session.id", "")
      },
      transaction: {
        reference: orderId
      },
      interaction: {
        operation: "PURCHASE",
        merchant: {
          name: "BFL-Online Store",
          address: {
            line1: "Address Line 1",
            line2: "Address Line 2"
          }
        },
        displayControl: {
          billingAddress: "HIDE",
          customerEmail: "HIDE",
          orderSummary: "SHOW",
          shipping: "HIDE"
        }
      }
    });

    window.Checkout.showLightbox();
    return false;
  };

  async processPayPalPayment({ orderId, paymentToken, data }) {
    const {
      checkoutData: { address },
      commonSettings: { currencyCode, countryId }
    } = this.props;

    try {
      const phone = `+${address.phone}`;

      const orderBody = this.getOrderBody();

      await CheckoutService.confirmPayPal({
        ...data,
        amount: orderBody.transactionOrderTotal,
        orderId,
        currencyCode,
        shipping: {
          countryName: address.country,
          firstName: address.firstname,
          lastName: address.lastname,
          region: address.area,
          locality: address.city,
          streetAddress: address.addressLine1
        },
        paymentToken,
        countryId,
        phone
      });
      this.hideLoader();
      this.changeCheckoutData("orderPlaced", true);

      this.redirectToSuccessPage(orderId);
    } catch (err) {
      this.hideLoader();
      console.error(err);
    }
  }

  redirectToSuccessPage(orderId) {
    const { history, order, routeSlug } = this.props;

    history.push(
      `/${routeSlug}/order-success/${orderId}?${qs.stringify({
        continueAsGuest: order.continueAsGuest || ""
      })}`
    );
  }

  redirectToErrorPage(queries = {}) {
    const { history, match, routeSlug } = this.props;

    history.push(
      `/${routeSlug}/payment-error?${qs.stringify({
        ...queries,
        checkoutId: match.params.id
      })}`
    );
  }

  deleteCartList = async (e, item) => {
    e.preventDefault();

    const {
      order,
      deleteCheckoutItem,
      fetchCheckoutData,
      history,
      language,
      deleteCartData,
      authReducer,
      commonSettings,
      countrySlug
    } = this.props;

    AnalyticService.cart.trackRemoveFromCartInCheckout({
      item,
      authReducer,
      commonSettings
    });
    GAService.cart.trackRemoveFromCart(item);

    if (!(item.productId && order._id)) return;

    const response = await deleteCartData(item.productId, item._id);
    if (!get(response, "data.success")) return;

    const result = await deleteCheckoutItem(order._id, item._id);
    if (!get(result, "data.success")) return;

    fetchCheckoutData(order._id, history, language, countrySlug);
  };

  moveToCart = (redirect = true) => {
    const {
      history,
      logOut,
      order,
      cart,
      saveToCart,
      resetSavedCard,
      routeSlug
    } = this.props;

    if (order.continueAsGuest) {
      logOut(false, true);
      removeGuestUserStorage("");

      if (Array.isArray(cart)) {
        cart.forEach(cartItem => {
          saveToCart(cartItem);
        });
      }
    }
    resetSavedCard();

    if (redirect) {
      history.push(`/${routeSlug}/my-cart/`);
    }
  };

  resetMyWallet = () => {
    this.setState({ useMyWallet: false });
  };

  changeUseMyWallet = () => {
    const { useMyWallet } = this.state;
    if (!useMyWallet) {
      GAService.checkout.trackPaymentTypeSelected("e-wallet");
    }
    this.setState({ useMyWallet: !useMyWallet }, () => {
      this.applyUseMyWallet();
    });
  };

  unselectUseMyWallet = () => {
    const { order, changeCheckoutData } = this.props;

    const paymentWithoutWallet = (order.selectedPayment || []).filter(
      payment => payment !== WALLET
    );

    this.setState({ useMyWallet: false });
    this.changeCheckoutData("useMyWallet", false, true);
    changeCheckoutData("selectedPayment", paymentWithoutWallet);
  };

  applyUseMyWallet = () => {
    const { useMyWallet } = this.state;
    const { changeCheckoutData, order, updateCheckout } = this.props;
    const selectedPayment = order.selectedPayment || [];

    this.changeCheckoutData("useMyWallet", useMyWallet, false);
    changeCheckoutData(
      "selectedPayment",
      useMyWallet
        ? [...new Set([...selectedPayment, WALLET])]
        : remove(selectedPayment, payment => payment !== WALLET)
    );
    const body = this.getOrderBody(true);

    this.setState({
      walletCapable:
        (body && parseFloat(body.transactionOrderTotal) === 0) || false
    });
    if (body && parseFloat(body.transactionOrderTotal) === 0) {
      this.changeCheckoutData("step3Completed", true, true);

      if (useMyWallet && order.discount) {
        const selectedPaymentOnChangeUseMyWallet = ["WALLET", VOUCHER];

        changeCheckoutData(
          "selectedPayment",
          selectedPaymentOnChangeUseMyWallet
        );
      } else {
        changeCheckoutData(
          "selectedPayment",
          useMyWallet
            ? [WALLET]
            : selectedPayment &&
                selectedPayment.splice(order.selectedPayment.indexOf(WALLET), 1)
        );
      }
    }
    updateCheckout();
  };

  mapDeliveryAddressCheckout = address => {
    const { mapDeliveryAddressCheckout } = this.props;

    mapDeliveryAddressCheckout(address);

    this.setState({ addAddress: false });
  };

  OpenSignInModal = () => {
    const { showModal } = this.props;

    showModal();
  };

  toggleChange = state => {
    this.setState({
      openSection:
        typeof state === "undefined" ? !this.state.openSection : state
    });
  };

  inStoreDeliveryAddressCheckout = address => {
    const { inStoreDeliveryAddressCheckout } = this.props;

    inStoreDeliveryAddressCheckout(address);

    this.setState({ addAddress: false });
  };

  componentWillUnmount() {
    const {
      clearCheckoutData,
      resetAddress,
      history,
      resetMapDeliveryAddress,
      resetInStoreDeliveryAddress,
      getCartAndWishlistGeneralData
    } = this.props;

    clearCheckoutData();
    resetAddress();
    resetMapDeliveryAddress();
    resetInStoreDeliveryAddress();

    const shouldGetToCart = history.location.pathname?.includes("/my-cart");

    if (shouldGetToCart) {
      getCartAndWishlistGeneralData();
    }
  }

  changeDelivery = delivery => {
    const { changeCheckoutData } = this.props;
    this.setState({ interruptedByOTP: false });

    changeCheckoutData("deliveryType", delivery);
  };

  handleVoucherToggle = value => {
    this.setState({ openVoucher: value });

    if (value) {
      this.handleVoucherAppliedMessage();
    }
  };

  resetAddAddress = () => {
    this.setState({ addAddress: false });
  };

  changeSelectedPayment = value => {
    const { useMyWallet } = this.state;
    const { changeCheckoutData, order } = this.props;
    const discountApplied = get(order, "discount", 0);
    let selectedPaymentOnChange = [];
    let _selectedPaymentOnChange = [];
    if (useMyWallet && discountApplied) {
      selectedPaymentOnChange = [WALLET, VOUCHER];
    } else if (!useMyWallet && discountApplied) {
      selectedPaymentOnChange = [VOUCHER];
    } else if (useMyWallet && !discountApplied) {
      selectedPaymentOnChange = [WALLET];
    }
    _selectedPaymentOnChange = selectedPaymentOnChange.concat(value);
    this.changeCheckoutData("step3Completed", true, false);

    changeCheckoutData("selectedPayment", _selectedPaymentOnChange);
    this.setState({ orderFailedText: "" });
  };

  loadPayPalScript = onLoadCallback => {
    const paypalTags = document.querySelectorAll(
      'script[src*="paypalobjects"]'
    );

    if (paypalTags.length) return;

    const script = document.createElement("script");
    script.src = "https://www.paypalobjects.com/api/checkout.min.js";
    script.setAttribute("log-level", "warn");
    script.setAttribute("data-version-4", "");
    script.setAttribute("async", "");
    if (onLoadCallback) {
      script.onload = onLoadCallback;
    }
    document.head.appendChild(script);
  };

  changeVoucherStatus = status => {
    this.setState({ isVoucherValid: status });
  };

  resetPaymentStep = () => {
    const { changeCheckoutData } = this.props;

    changeCheckoutData("selectedPaymentOption", null);
    changeCheckoutData("selectedPayment", []);
    changeCheckoutData("step3Completed", false);
  };

  createOrderForPostPay = async orderBody => {
    const {
      match,
      order,
      userInfo,
      countrySHORT,
      routeSlug,
      language,
      history,
      confirmOrder,
      checkoutData,
      countryId
    } = this.props;
    const { currencyCode, paymentAmount, deliveryType, shippingCharges } =
      orderBody;
    const { orderFailedText, orderId, personalId } = this.state;
    const { orderItems } = order;

    const { email } = userInfo;
    const order_id = match.params.id;
    const cartItems = this.getItemsForPostPay(orderItems);
    const originLocation = window.location.origin;
    const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;

    const { firstname, lastname, addressLine1, city, zipcode } =
      checkoutData.address;

    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(routeSlug);
    const isPersonalIdInvalid =
      personalId.length < MIN_CIVIL_ID_LENGTH &&
      personalId.length > MAX_CIVIL_ID_LENGTH;
    if (isPersonalIdRequired && isPersonalIdInvalid) {
      return this.setState({ isPersonalId: !personalId.length });
    }

    const deliveryAddress = {
      first_name: firstname,
      last_name: lastname,
      line1: addressLine1,
      city: city,
      country: countrySHORT,
      postal_code: zipcode || DEFAULT_POSTAL_CODE
    };

    try {
      this.showLoader();

      const { data: confirmOrderData } = await confirmOrder(
        {
          ...orderBody,
          paymentGateWay: PAYMENT_GATEWAYS.POSTPAY,
          paymentReferenceNo: order_id,
          orderId,
          isPaymentSuccess: true
        },
        history,
        language
      );

      if (confirmOrderData.success) {
        const successPageUrl = `${originLocation}/${routeSlug}/order-success/${
          confirmOrderData?.orderId
        }?${qs.stringify({
          continueAsGuest: order.continueAsGuest || "",
          countryId,
          currencyCode,
          isPaymentSuccess: true,
          paymentGateWay: PAYMENT_GATEWAYS.POSTPAY,
          paymentToken: confirmOrderData.paymentToken,
          transactionOrderTotal: paymentAmount,
          paymentReferenceNo: confirmOrderData.paymentToken
        })}`;

        const orderForPostPay = {
          order_id: confirmOrderData?.paymentToken,
          total_amount: paymentAmount * POST_PAY_CURRENCY_MULTIPLIER,
          tax_amount: 0,
          currency: currencyCode,
          shipping: {
            name: deliveryType,
            amount: shippingCharges * POST_PAY_CURRENCY_MULTIPLIER,
            address: deliveryAddress
          },
          customer: {
            email: email || checkoutData.guestEmail
          },
          items: cartItems,
          merchant: {
            confirmation_url: successPageUrl,
            cancel_url: failedPageUrl
          }
        };

        const { data } = await PostPayService.createOrder(orderForPostPay);

        this.changeCheckoutData("orderPlaced", true);

        window.location.href = data.redirectUrl + `?locale=${language}`;

        orderFailedText && this.setState({ orderFailedText: "" });
      } else {
        throw new Error(confirmOrderData.message);
      }
    } catch (error) {
      console.log({ error });
      const errorMessage = error.response?.data?.message || error.message;
      GAService.checkout.trackPaymentError({
        payment_method: PAYMENT_MODES.COD,
        error_message: errorMessage
      });
      this.setState({
        orderFailedText: errorMessage
      });
    } finally {
      this.hideLoader();
    }
  };

  getItemsForPostPay = items =>
    items.map(item => ({
      reference: item.id.toString(),
      name: item.title,
      unit_price: item.currentPrice * POST_PAY_CURRENCY_MULTIPLIER,
      qty: item.quantity
    }));
  handleCloseModal = () => {
    const { setPaymentStatusResponse } = this.props;

    this.setState({ isTabbySession: false });
    setPaymentStatusResponse({});
  };

  getSessionStatus = value => {
    if (value === "rejected") this.setState({ isTabbySession: true });
  };

  onCloseAddressOTP = () => {
    this.setState({ showOTPModal: false });
  };

  onChangeUserField = isChangeUserFieldOpen =>
    this.setState({ isChangeUserFieldOpen });

  handlePayButtonClick = () => {
    const { order } = this.props;
    GAService.checkout.trackAddPaymentInfo({
      orderData: order
    });
  };

  render() {
    const {
      myCartTranslation,
      checkoutTranslation,
      loginTranslation,
      profileTranslation,
      forgotPasswordTranslation,
      isVoucherValid,
      isSameVoucherCode,
      useMyWallet,
      walletCapable,
      addAddress,
      openSection,
      inValidVoucherMessage,
      isVoucherEmpty,
      openVoucher,
      orderFailedText,
      isShowLoyaltyAlert,
      showOTPModal,
      interruptedByOTP,
      isTabbySession,
      isChangeUserFieldOpen,
      personalId,
      isPersonalId
    } = this.state;

    const {
      language,
      order,
      deliverySettings,
      history,
      userInfo,
      forgotPasswordModal,
      countryName,
      state,
      wallet,
      addressBook,
      mobileCountryCode,
      locatorList,
      resetAddress,
      setAddress,
      currency,
      loyaltyPoints,
      countryCode,
      fetchMapFilterData,
      mapFilterData,
      deliveryAddressFromMap,
      userLoggedIn,
      commonSettings,
      savedCardList,
      handleSelectedSavedCard,
      payment,
      handleTextChange,
      resetSavedCard,
      cart,
      VoucherCodes,
      removeAppliedVoucher,
      handleMSelectedSavedCard,
      changeCheckoutData,
      fetchInStorePickupData,
      checkoutReducer,
      payPalToken,
      countryId,
      countrySHORT,
      inStoreAddressFromMap,
      checkoutComFormValidationStatus,
      selectedCardCvv,
      creditCardOptions,
      binNo,
      isJoinedToLoyaltyProgram,
      routeSlug,
      checkoutSections,
      configCdnImagesSettings,
      tabbyPaymentError,
      paymentStatusResponse,
      infoText,
      countrySlug
    } = this.props;

    const _selectedPaymentOption = get(
      checkoutReducer,
      "checkoutData.selectedPaymentOption",
      {}
    );

    const filteredDeliverySettings = filter(deliverySettings, {
      paymentType: settings_cart
    });

    const filteredPaymentSettings = filter(deliverySettings, {
      deliveryType: order.deliveryType
    });

    const _uniqueDeliveryOptions = uniqBy(
      filteredDeliverySettings,
      "deliveryType"
    );

    const total = this.getTotal(order.orderItems || []);
    const deliveryOptionsGenerated =
      _uniqueDeliveryOptions &&
      _uniqueDeliveryOptions.map(option =>
        this.getModifiedDeliveryOption(
          option,
          myCartTranslation,
          currency,
          language,
          total
        )
      );

    const filteredPaymentGenerated = map(filteredPaymentSettings, option =>
      this.getModifiedDeliveryOption(
        option,
        myCartTranslation,
        currency,
        language,
        total
      )
    );

    const codFee = get(_selectedPaymentOption, "cod_fee", 0);
    const countryWallet =
      wallet &&
      wallet.walletAmount &&
      wallet.walletAmount.length &&
      wallet.walletAmount.find(amount => amount.currencyCode === currency);
    let _store = [];
    countryCode &&
      locatorList &&
      locatorList.filter(item => {
        if (item.country === countryCode) {
          _store = [..._store, ...item.shops];
        }
        return null;
      });

    const selectedDelivery = find(deliveryOptionsGenerated, {
      key: order.deliveryType
    });

    let _VoucherCodes =
      VoucherCodes &&
      VoucherCodes.filter(
        codes => codes.expiryDate >= new Date().toISOString()
      );

    const stepsCompleted =
      order.step1Completed && order.step2Completed && order.step3Completed;

    const deliveryCharges =
      selectedDelivery &&
      getShippingCharge(
        selectedDelivery.freeShippingLimit,
        selectedDelivery.shippingCharge,
        total
      );

    let transformedTotal = 0;
    const orderDiscount = get(order, "discount", 0);
    const valueToPay = total - orderDiscount + deliveryCharges;
    if (valueToPay > 0) {
      transformedTotal = total - orderDiscount + deliveryCharges + codFee;
    }

    const _walletAmount = get(countryWallet, "transactionAmount", 0);

    const walletAmountTransformed =
      (useMyWallet &&
        _walletAmount &&
        (_walletAmount > transformedTotal
          ? transformedTotal
          : _walletAmount)) ||
      0;

    const grandTotal = priceUtil.formatPriceByDecimal(
      transformedTotal - walletAmountTransformed,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    const orderBody = this.getOrderBody();

    const isPersonalIdRequired = COUNTRIES_WITH_CIVIL_ID.includes(countrySlug);

    const isPayPalSelected = (order.selectedPayment || []).includes("PAYPAL");
    const isTabbySelected =
      (order.selectedPayment || []).includes(PAYMENT_MODES.TABBY_PAY_LATER) ||
      (order.selectedPayment || []).includes(
        PAYMENT_MODES.TABBY_PAY_INSTALLMENT
      );
    const isTamaraSelected =
      (order.selectedPayment || []).includes(PAYMENT_MODES.TAMARA_PAY_NOW) ||
      (order.selectedPayment || []).includes(
        PAYMENT_MODES.TAMARA_PAY_BY_INSTALMENTS
      );
    const isPostPaySelected = (order.selectedPayment || []).includes(
      PAYMENT_GATEWAYS.POSTPAY
    );

    const tabbyMode =
      isTabbySelected &&
      ((order.selectedPayment || []).includes(PAYMENT_MODES.TABBY_PAY_LATER)
        ? "pay_later"
        : "installments");
    const tamaraMode =
      isTamaraSelected &&
      ((order.selectedPayment || []).includes(PAYMENT_MODES.TAMARA_PAY_NOW)
        ? "pay_now"
        : "pay_by_installments");
    const isCreditCardSelected = (order.selectedPayment || []).includes("CC");
    const isCreditCardCheckoutComGateway =
      get(orderBody, "paymentGateway") === PAYMENT_GATEWAYS.CHECKOUT_COM;

    const possibleToPlaceOrder = (() => {
      let result =
        stepsCompleted &&
        !order.orderPlaced &&
        !isPayPalSelected &&
        !isTabbySelected &&
        !isTamaraSelected;

      if (isCreditCardSelected && isCreditCardCheckoutComGateway) {
        const selectedCardExist = Boolean(
          get(creditCardOptions, "selectedCard")
        );
        const isSelectedCardCvvValid = selectedCardCvv >= 3;

        const isCheckoutComReadyToPlaceOrder =
          checkoutComFormValidationStatus ||
          (selectedCardExist && isSelectedCardCvvValid);

        result = result && isCheckoutComReadyToPlaceOrder;
      }

      return result;
    })();

    const { station_name } = inStoreAddressFromMap;
    const shippingAddress = {
      ...order.address,
      countryCode: countrySHORT,
      ...(order.deliveryType === "STORE_PICKUP" && { station_name })
    };

    const isPayPalAllowed = filteredPaymentGenerated.some(
      item => item.paymentType.toUpperCase() === "PAYPAL"
    );

    const isPlaceOrderCommonBtnAvailable =
      !isPayPalSelected &&
      !isTabbySelected &&
      !isTamaraSelected &&
      !isPostPaySelected;
    const topCheckoutSection = checkoutSections.checkoutTop;

    const showPaymentErrorPopup =
      (isTabbySession && tabbyPaymentError) ||
      (!isEmpty(paymentStatusResponse) && !paymentStatusResponse.success);

    const showPaymentErrorPopupMessage =
      paymentStatusResponse.message || tabbyPaymentError;

    const infoCheckoutText =
      isMobile.any() &&
      getCheckoutInfoText(
        possibleToPlaceOrder,
        infoText,
        order,
        deliveryAddressFromMap,
        inStoreAddressFromMap,
        isChangeUserFieldOpen,
        checkoutReducer
      );

    return (
      <Page title={seoTranslation.checkoutPageTitle}>
        <div
          className={cn("checkout_flow", { arabic: language.includes("ar") })}
        >
          <SecondaryHeader
            moveToCart={() => this.moveToCart()}
            checkoutTranslation={checkoutTranslation}
            language={language}
          />
          {topCheckoutSection &&
            topCheckoutSection.map((section, key) => (
              <DynamicBannersWrapper
                section={section}
                key={key}
                bannerPageTitle={section.sectionTitle}
              />
            ))}

          <div className="grid_wrapper">
            <div className="grid_content_wrapper">
              <h3>
                <span
                  className="mCrossIcon"
                  onClick={() => this.moveToCart()}
                />
                {checkoutTranslation.secureCheckout}
              </h3>
              <div className="checkout_flow_content">
                <CheckoutSignIn
                  changeCheckoutData={this.changeCheckoutData}
                  resetAddAddress={this.resetAddAddress}
                  resetMyWallet={this.resetMyWallet}
                  unselectUseMyWallet={this.unselectUseMyWallet}
                  onChangeUserField={this.onChangeUserField}
                />
                <Voucher
                  checkoutTranslation={checkoutTranslation}
                  VoucherCodes={_VoucherCodes}
                  changeVoucher={this.changeVoucher}
                  applyVoucher={this.applyVoucher}
                  changeSelectedPayment={value => {
                    this.changeCheckoutData("selectedPayment", value);
                  }}
                  isVoucherValid={isVoucherValid}
                  isSameVoucherCode={isSameVoucherCode}
                  order={order}
                  currencyCode={commonSettings.currencyCode}
                  removeAppliedVoucher={removeAppliedVoucher}
                  handleVoucherAppliedMessage={this.handleVoucherAppliedMessage}
                  total={total}
                  handleVoucherToggle={this.handleVoucherToggle}
                  openVoucher={openVoucher}
                  inValidVoucherMessage={inValidVoucherMessage}
                  onPaymentOptionChange={value => {
                    this.changeCheckoutData("selectedPaymentOption", value);
                  }}
                  binNo={binNo}
                />
                {this.state.mapsLoaded && (
                  <DeliveryDetails
                    stores={_store}
                    open={order.step1Completed && !order.step2Completed}
                    order={order}
                    changeSelectedDelivery={delivery => {
                      this.changeDelivery(delivery);
                    }}
                    handleContinueAsGuest={value => {
                      this.changeCheckoutData("continueAsGuest", value, false);
                    }}
                    updateAddress={(address, step, customerId, ...rest) =>
                      customerId
                        ? this.updateAddressAndCustomer(
                            "customerId",
                            customerId,
                            address
                          )
                        : this.updateAddress(address, step, customerId, ...rest)
                    }
                    alreadyLoggedIn={userInfo.userLoggedIn}
                    changeSelectedStore={value => {
                      this.changeCheckoutData("selectedStore", value);
                    }}
                    checkoutTranslation={checkoutTranslation}
                    moveToNextStep={
                      interruptedByOTP
                        ? () => this.setAddress(addressBook)
                        : this.moveToNextStep
                    }
                    language={language}
                    countryName={countryName}
                    state={state}
                    addressBook={
                      addressBook &&
                      addressBook.length &&
                      addressBook.filter(
                        address =>
                          address.countryId === commonSettings.countryId
                      )
                    }
                    mobileCountryCode={mobileCountryCode}
                    resetAddress={resetAddress}
                    setAddress={setAddress}
                    deliveryOptions={deliveryOptionsGenerated}
                    currencyCode={currency}
                    countryCode={countryCode}
                    fetchMapFilterData={fetchMapFilterData}
                    mapFilterData={mapFilterData}
                    mapDeliveryAddressCheckout={address =>
                      this.mapDeliveryAddressCheckout(address)
                    }
                    deliveryAddressFromMap={deliveryAddressFromMap}
                    commonSettings={commonSettings}
                    addAddress={addAddress}
                    email={userInfo.email || order.guestEmail}
                    changeCheckoutData={changeCheckoutData}
                    resetAddAddress={this.resetAddAddress}
                    fetchInStorePickupData={fetchInStorePickupData}
                    onPaymentOptionChange={value => {
                      this.changeCheckoutData("selectedPaymentOption", value);
                    }}
                  />
                )}
                <AddAddressMobileNumber
                  showModal={showOTPModal}
                  onSuccessOtp={(...args) => {
                    this.state.onSuccessOtp?.(...args);
                    this.setState({ interruptedByOTP: false });
                  }}
                  closeModal={this.onCloseAddressOTP}
                  verifyOnly
                />
                <CheckoutPayment
                  totalAmount={grandTotal}
                  savedCardList={savedCardList}
                  handleSelectedSavedCard={handleSelectedSavedCard}
                  inStoreDeliveryAddressCheckout={address =>
                    this.inStoreDeliveryAddressCheckout(address)
                  }
                  handleMSelectedSavedCard={handleMSelectedSavedCard}
                  checkoutTranslation={checkoutTranslation}
                  open={order.step2Completed}
                  order={order}
                  paymentOptions={filteredPaymentGenerated}
                  changeVoucher={this.changeVoucher}
                  applyVoucher={this.applyVoucher}
                  changeSelectedPayment={value => {
                    this.changeSelectedPayment(value);
                  }}
                  isVoucherValid={isVoucherValid}
                  changeVoucherStatus={this.changeVoucherStatus}
                  isVoucherEmpty={isVoucherEmpty}
                  inValidVoucherMessage={inValidVoucherMessage}
                  isSameVoucherCode={isSameVoucherCode}
                  wallet={wallet}
                  currency={currency}
                  payment={payment}
                  countryId={countryId}
                  handleTextChange={handleTextChange}
                  resetSavedCard={resetSavedCard}
                  changeUseMyWallet={this.changeUseMyWallet}
                  language={language}
                  walletCapable={walletCapable}
                  VoucherCodes={VoucherCodes}
                  onPaymentOptionChange={value => {
                    this.changeCheckoutData("selectedPaymentOption", value);
                    GAService.checkout.trackPaymentTypeSelected(
                      value.paymentType
                    );
                  }}
                  _selectedPaymentOption={_selectedPaymentOption}
                  wrapperRef={this.paymentWrapperRef}
                  codFee={codFee}
                  handleVoucherAppliedMessage={this.handleVoucherAppliedMessage}
                  resetPaymentStep={this.resetPaymentStep}
                  preApplyVoucher={this.preApplyVoucher}
                  getOrderBody={this.getOrderBody}
                  deliveryCharges={deliveryCharges}
                  isPersonalIdRequired={isPersonalIdRequired}
                  personalId={personalId}
                  changePersonalId={this.changePersonalId}
                  isPersonalId={isPersonalId}
                />
              </div>
              <div className="order-buttons">
                {isPlaceOrderCommonBtnAvailable && (
                  <button
                    className={cn(
                      "round_btn",
                      "form_black_btn",
                      {
                        disable_order_btn: !possibleToPlaceOrder
                      },
                      "place_order_btn"
                    )}
                    disabled={!possibleToPlaceOrder}
                    onClick={() => {
                      this.validatePlaceOrder({}, isPersonalIdRequired);
                      this.handlePayButtonClick();
                    }}
                  >
                    {checkoutTranslation.placeOrder}
                  </button>
                )}

                {isTabbySelected && (
                  <TabbyButton
                    mode={tabbyMode}
                    amount={get(orderBody, "transactionOrderTotal")}
                    shippingAddress={shippingAddress}
                    deliveryCharges={deliveryCharges}
                    buttonText={checkoutTranslation.placeOrder}
                    className="round_btn form_black_btn place_order_btn"
                    checkoutPage={this}
                    stepsCompleted={stepsCompleted}
                    getSessionStatus={this.getSessionStatus}
                    onButtonClick={this.handlePayButtonClick}
                    personalId={personalId}
                    changePersonalIdStatus={this.changePersonalIdStatus}
                  />
                )}

                {isTamaraSelected && (
                  <TamaraButton
                    mode={tamaraMode}
                    amount={get(orderBody, "transactionOrderTotal")}
                    shippingAddress={shippingAddress}
                    deliveryCharges={deliveryCharges}
                    buttonText={checkoutTranslation.placeOrder}
                    className="round_btn form_black_btn place_order_btn"
                    checkoutPage={this}
                    stepsCompleted={stepsCompleted}
                    onButtonClick={this.handlePayButtonClick}
                    personalId={personalId}
                    changePersonalIdStatus={this.changePersonalIdStatus}
                  />
                )}

                {isPayPalAllowed && payPalToken && (
                  <PayPalButton
                    token={payPalToken}
                    transactionOrderTotal={get(
                      orderBody,
                      "transactionOrderTotal"
                    )}
                    id="web"
                    onAuthorize={paypalData =>
                      this.validatePlaceOrder(
                        { paypal: paypalData },
                        isPersonalIdRequired
                      )
                    }
                    onButtonClick={this.handlePayButtonClick}
                    shippingAddress={shippingAddress}
                    hidden={!isPayPalSelected}
                  />
                )}

                {isPostPaySelected && (
                  <button
                    className={cn("round_btn form_black_btn place_order_btn", {
                      disable_order_btn: !stepsCompleted
                    })}
                    disabled={!stepsCompleted}
                    onClick={() => {
                      this.createOrderForPostPay(orderBody);
                      this.handlePayButtonClick();
                    }}
                  >
                    {checkoutTranslation.placeOrder}
                  </button>
                )}
              </div>
              {orderFailedText && (
                <p className="orderFailedText">
                  Unable to place the order. {this.state.orderFailedText}
                </p>
              )}
            </div>

            <MobileOrderSummary
              total={total}
              checkoutTranslation={checkoutTranslation}
              selectedDelivery={selectedDelivery}
              useMyWallet={useMyWallet}
            />
            <div className="grid_side_wrapper">
              <h4>{myCartTranslation.orderSummary}</h4>
              <CartHoverDropdown
                translation={profileTranslation}
                language={language}
                cart={
                  order.orderItems &&
                  order.orderItems.length &&
                  order.orderItems.map(item => {
                    return {
                      ...item,
                      title:
                        (cart &&
                          cart.length &&
                          cart.find(
                            cartItem => cartItem.productId === item.productId
                          ) &&
                          cart.find(
                            cartItem => cartItem.productId === item.productId
                          ).title) ||
                        item.title
                    };
                  })
                }
                deleteCartList={this.deleteCartList}
                currencyCode={currency}
                placeOrder
                cartSubTotal
                commonSettings={commonSettings}
                showCrossIcon={false}
                logOutGuestUser={() => this.moveToCart(false)}
                fromCheckout
                showDiscountSlab={false}
                configCdnImagesSettings={configCdnImagesSettings}
              />
              <MobileCheckoutCartSummary
                translation={profileTranslation}
                onProductClick={() => this.moveToCart(false)}
              />
              <CheckoutButtonFooter
                applyUseMyWallet={this.applyUseMyWallet}
                total={total}
                translation={myCartTranslation}
                selectedDelivery={selectedDelivery}
                placeOrder
                order={order}
                callPlaceOrder={() =>
                  this.validatePlaceOrder({}, isPersonalIdRequired)
                }
                freeShippingUpto={
                  selectedDelivery && selectedDelivery.freeShippingLimit
                }
                isCheckoutPage
                possibleToPlaceOrder={possibleToPlaceOrder}
                codFee={codFee}
                currencyCode={currency}
                useMyWallet={useMyWallet}
                walletAmmount={countryWallet && countryWallet.transactionAmount}
                commonSettings={commonSettings}
                transactionOrderTotal={get(orderBody, "transactionOrderTotal")}
                onPayPalAuthorize={paypalData =>
                  this.validatePlaceOrder(
                    { paypal: paypalData },
                    isPersonalIdRequired
                  )
                }
                isPayPalSelected={isPayPalSelected}
                payPalToken={payPalToken}
                _selectedPaymentOption={_selectedPaymentOption}
                language={language}
                shippingAddress={shippingAddress}
                isPayPalAllowed={isPayPalAllowed}
                isTabbySelected={isTabbySelected}
                isTamaraSelected={isTamaraSelected}
                tabbyMode={tabbyMode}
                tamaraMode={tamaraMode}
                checkoutPage={this}
                createOrderForPostPay={() =>
                  this.createOrderForPostPay(orderBody)
                }
                isPostPaySelected={isPostPaySelected}
                getSessionStatus={this.getSessionStatus}
                infoText={infoCheckoutText}
                personalId={personalId}
                changePersonalIdStatus={this.changePersonalIdStatus}
              />
              {isShowLoyaltyAlert && (
                <LoyaltyAlerts
                  language={language}
                  token={userInfo.accessToken}
                  loyaltyPoints={loyaltyPoints}
                  translation={myCartTranslation}
                  OpenSignInModal={this.OpenSignInModal}
                  guest={order && order.continueAsGuest}
                  isUserLoggedIn={userLoggedIn}
                  isJoinedToLoyaltyProgram={isJoinedToLoyaltyProgram}
                  routeSlug={routeSlug}
                />
              )}

              <div className="payment_options mCheckout">
                <FooterAcceptMoney
                  defaultCountry={commonSettings}
                  translation={checkoutTranslation}
                />
              </div>
            </div>
          </div>
          <SecondaryFooter
            checkoutTranslation={checkoutTranslation}
            routelanguage={language}
            settings={commonSettings}
          />
        </div>

        {showPaymentErrorPopup && (
          <DeleteModal
            titleTxt={checkoutTranslation.alert}
            bodyTxt={showPaymentErrorPopupMessage}
            cancelBtnTxt={checkoutTranslation.Ok}
            isDeleteModalActive={showPaymentErrorPopup}
            handleCloseModal={this.handleCloseModal}
            language={language}
          />
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.authReducer,
  language: selectLanguageSlug(state),
  order: state.checkoutReducer.checkoutData,
  deliverySettings: get(state, "common.settings.deliverySettings", []),
  currency: get(state, "common.settings.currencyCode"),
  mobileCountryCode: get(state, "common.settings.mobileCountryCode"),
  countryCode: get(state, "common.settings.countryCode"),
  forgotPasswordModal: state.homepage.forgotPasswordModal,
  generalInfo: state.common.generalInfo,
  countryName: get(state, "common.settings.countryName"),
  state: get(state, "common.settings.state"),
  countryId: get(state, "common.settings.countryId", ""),
  payment: state.paymentReducer,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  locatorList: state.storeLocatorReducer.locatorList,
  wallet: state.myAccountReducer.wallet,
  addressBook: state.authReducer.addressBook,
  mapFilterData: state.checkoutReducer.mapFilterData,
  deliveryAddressFromMap: state.checkoutReducer.mapDeliveryAddress,
  loyaltyPoints: state.common.loyaltyPoints,
  loyaltyDetails: get(
    state,
    "myAccountReducer.loyaltyPoints.LoyaltyStatics.loyaltyDetails",
    {}
  ),
  savedCardList: state.checkoutReducer.savedCardList,
  selectedSavedCard: state.checkoutReducer.selectedSavedCard,
  cart: state.cartReducer.cart,
  VoucherCodes: state.checkoutReducer.VoucherCodes,
  _address: state.addressReducer,
  countryData: state.common.countryData,
  countrySHORT: get(state, "common.settings.countrySHORT"),
  standardDeliveryAddress: state.checkoutReducer.standardDeliveryAddress,
  // duplicate for order: state.checkoutReducer.checkoutData; need to update
  checkoutData: state.checkoutReducer.checkoutData,
  inStoreAddressFromMap: state.checkoutReducer.inStoreDeliveryAddress,
  checkoutReducer: state.checkoutReducer,
  countrySlug: selectCountrySlug(state),
  routeSlug: selectRouteSlug(state),
  payPalToken: state.common.payPalToken,
  userLoggedIn: state.authReducer.userLoggedIn,
  accessGuestToken: state.authReducer.accessGuestToken,
  checkoutComFormValidationStatus:
    state.checkoutReducer.checkoutComFormValidationStatus,
  creditCardOptions: state.checkoutReducer.checkoutData.creditCardOptions,
  selectedCardCvv: state.checkoutReducer.selectedCardCvv,
  binNo: state.checkoutReducer.binNo,
  isJoinedToLoyaltyProgram: state.common.isJoinedToLoyaltyProgram,
  checkoutSections: selectCheckoutBannerSections(state),
  configCdnImagesSettings: selectCdnImageSettings(state),
  isCheckedLoyaltyProgram: selectIsCheckedLoyaltyProgram(state),
  tabbyPaymentError: selectTabbyPaymentError(state),
  paymentStatusResponse: selectPaymentStatusResponse(state),
  infoText: selectCheckoutInfoText(state)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  fetchCheckoutData,
  updateCheckout,
  changeCheckoutData,
  setUserInfo,
  showModal,
  signInModal,
  showForgotPasswordModal,
  clearModal,
  hideForgotPasswordModal,
  confirmOrder,
  hideModal,
  deleteCheckoutItem,
  deleteCartData,
  saveAddress,
  fetchUserAndLoadData,
  logOut,
  applyVoucher,
  handleTextChange,
  getStoreLocatorList,
  fetchMyWallet,
  setAddress,
  resetAddress,
  resetMapDeliveryAddress,
  resetInStoreDeliveryAddress,
  fetchMapFilterData,
  mapDeliveryAddressCheckout,
  getLoyalityPoints,
  handleSelectedSavedCard,
  handleMSelectedSavedCard,
  resetSavedCard,
  saveToCart,
  clearCheckoutData,
  guestUserCheckout,
  voucherList,
  removeAppliedVoucher,
  fetchCartData,
  setStandardDeliveryAddress,
  inStoreDeliveryAddressCheckout,
  fetchInStorePickupData,
  showPageLoader,
  hidePageLoader,
  getStatesData,
  updateDeviceInfo,
  showLoadingIndicator,
  getPayPalToken,
  loadCreditCards,
  clearBinCode,
  resetSummaryRecalculationInProgress,
  resetPaymentMethod,
  getBannerSections,
  fetchEarnedLoyaltyPoints,
  getCartAndWishlistGeneralData,
  setPaymentStatusResponse
};

export default compose(
  withDeliverySettingsFetching,
  withRouter,
  withAreebaPayment,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout);
