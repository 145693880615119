const style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px"
    },
    success: {
      // Applied only to the success notification item
      color: "#ffffff",
      backgroundColor: "rgba(69,148,69,0.9)",
      fontWeight: "bold"
    },
    error: {
      // Applied only to the error notification item
      color: "#ffffff",
      backgroundColor: "rgba(199, 47, 47, 0.75)"
    }
  }
};

export default style;
