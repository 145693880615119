import {
  CLEAR_BANNERS_SECTIONS,
  CLEAR_REDIRECTION_DATA,
  HOME_PAGE_DATA,
  HOME_PAGE_SECTION_DATA,
  REDIRECTION_DATA,
  SET_META,
  CLEAR_META,
  SET_STORE_LIST,
  SET_ANALITIC_DATA,
  SET_PAGE_TYPE
} from "../../constants/page";

const initialState = {
  homepageState: {
    storeId: "",
    countryId: "",
    sections: []
  },
  redirectionData: {},
  storeList: [],
  meta: {},
  analyticData: {
    variants: [],
    analytics: null
  },
  pageType: ""
};

const homePageData = (state, action) => ({
  ...state,
  homepageState: {
    ...state.homepageState,
    ...action.content
  }
});

const homePageSectionData = (state, { sectionName, content }) => ({
  ...state,
  homepageState: {
    ...state.homepageState,
    countryId: content.countryId,
    storeId: content.storeId,
    [sectionName]: content[sectionName]
  }
});

const clearBannersSectionsData = state => {
  return {
    ...state,
    homepageState: {
      ...state.homepageState,
      sections: []
    },
    redirectionData: {}
  };
};

const redirectionData = (state, { data }) => ({
  ...state,
  redirectionData: data
});
const clearRedirectionData = state => ({
  ...state,
  redirectionData: initialState.redirectionData
});

const setStoreList = (state, { storeList }) => ({
  ...state,
  storeList
});

const setMeta = (state, { meta }) => ({
  ...state,
  meta
});

const clearMeta = state => ({
  ...state,
  meta: {}
});

const setAnaliticData = (state, { analyticData }) => ({
  ...state,
  analyticData
});

const setPageType = (state, { pageType }) => ({
  ...state,
  pageType
});

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_DATA:
      return homePageData(state, action);
    case HOME_PAGE_SECTION_DATA:
      return homePageSectionData(state, action);
    case CLEAR_BANNERS_SECTIONS:
      return clearBannersSectionsData(state);
    case REDIRECTION_DATA:
      return redirectionData(state, action);
    case CLEAR_REDIRECTION_DATA:
      return clearRedirectionData(state, action);
    case SET_STORE_LIST:
      return setStoreList(state, action);
    case SET_META:
      return setMeta(state, action);
    case CLEAR_META:
      return clearMeta(state);
    case SET_ANALITIC_DATA:
      return setAnaliticData(state, action);
    case SET_PAGE_TYPE:
      return setPageType(state, action);

    default:
      return state;
  }
};

export default pageReducer;
