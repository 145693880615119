import { get, isEmpty, lowerCase } from "lodash";
import GAExtensions from "../extensions";
import { extractMainDomain } from "../utils";
import { LOGGED_IN, LOGGED_OFF } from "../constants";

class CommonGAService extends GAExtensions {
  trackCountryPreference = selected_country => {
    selected_country = selected_country.toLowerCase();
    this.pushToDataLayer({ event: "country_preference", selected_country });
  };
  trackCTAClick = (cta_name, cta_type = "link") => {
    this.pushToDataLayer({
      event: "cta_click",
      cta_name: lowerCase(cta_name),
      cta_type
    });
  };
  trackNewsletterSubscription = () => {
    this.pushToDataLayer({
      event: "newsletter_subscription"
    });
  };
  trackSizeChart = data => {
    const { click_text, size_chart_tree } = data;
    this.pushToDataLayer({
      event: "size_chart",
      click_text: lowerCase(click_text),
      size_chart_tree: size_chart_tree
    });
  };
  trackAccountInfoSaved = () => {
    this.pushToDataLayer({
      event: "my_account_info_saved"
    });
  };
  trackAccordionEvent = ({ clickAccordion, isActive, accordion_name }) => {
    let event = isActive ? "accordion_expand" : "accordion_collapse";
    const click_text = clickAccordion.en_title || clickAccordion.title || "";
    this.pushToDataLayer({
      event,
      click_text: lowerCase(click_text),
      accordion_name: lowerCase(accordion_name)
    });
  };

  trackNeedHelpViewAllFaqClick = category => {
    this.pushToDataLayer({
      event: "view_all_faq",
      faq_category: lowerCase(category)
    });
  };

  trackSelectFaq = ({ category, faqItem, isActive }) => {
    const title = lowerCase(faqItem.en_longTitle || faqItem.longTitle || "");
    const categoryName = lowerCase(category);
    this.trackAccordionEvent({
      clickAccordion: { title },
      isActive,
      accordion_name: categoryName
    });
  };
  trackFormStart = (form_name = "") => {
    this.pushToDataLayer({
      event: "form_start",
      form_name
    });
  };
  trackFormAbandonment = (form_name = "", form_field_name = "") => {
    this.pushToDataLayer({
      event: "form_abandonment",
      form_name,
      form_field_name
    });
  };
  trackSocialClicks = platform => {
    const domain =
      platform.social_domain || extractMainDomain(platform.redirectLink);
    this.pushToDataLayer({
      event: "social_click",
      social_platform: domain
    });
  };
  trackErrorOccured = (error_name = "", error_message = "") => {
    this.pushToDataLayer({
      event: "error_occured",
      error_name: error_name.toLowerCase(),
      error_message: error_message.toLowerCase()
    });
  };
  trackFormComplete = (form_name = "") => {
    this.pushToDataLayer({
      event: "form_complete",
      form_name
    });
  };
  trackFormError = (errorObj = {}) => {
    if (!isEmpty(errorObj)) {
      const fields = Object.keys(errorObj);
      fields.forEach(field => {
        this.trackErrorOccured(field, errorObj[field]);
      });
    }
  };
  trackNearByStoreChanged = store => {
    this.pushToDataLayer({
      event: "locate_nearby_store",
      store_country: (store.en_country || store.country || "").toLowerCase(),
      store_city: lowerCase(get(store, "city", "")),
      store_name: lowerCase(get(store, "name", ""))
    });
  };
  trackPageView = payload => {
    const { pageTitle, type, customerId, userLoggedIn } = payload;
    this.pushToDataLayer({
      login_status: userLoggedIn ? LOGGED_IN : LOGGED_OFF,
      page_name: lowerCase(pageTitle),
      user_id: customerId,
      page_type: lowerCase(type)
    });
  };
  trackPageViewEvent = payload => {
    this.pushToDataLayer({
      event: "page_view",
      page_type: lowerCase(payload.type || undefined),
      page_name: lowerCase(payload.pageTitle || undefined)
    });
  };
  trackAddNewAddressCTA = () => {
    this.pushToDataLayer({
      event: "add_new_address"
    });
  };
  trackBrandPage = ({ brand_quantity, brand_name }) => {
    this.pushToDataLayer({
      event: "brand_stock",
      brand_quantity,
      brand_name
    });
  };
}

export default CommonGAService;
