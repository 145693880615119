import React, { FC, Fragment } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import DynamicBannersWrapper from "@/components/dynamic/dynamicBanner";
// import { useLanguage, useRouteSlug } from "@/hooks";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import PriceSlab from "../../components/price/price-slab";
import { isAppOnlyAvailableItem } from "../../util/product";
import {
  selectCommonSettings,
  selectLanguageSlug,
  selectRouteSlug
} from "../../util/selectors";
import "./style.scss";

const TRUNCATE_PRODUCT_TITLE_CHAR = {
  SMALL: 42,
  MEDIUM: 46,
  LARGE: 76
};

const LARGE_WIDTH_SIZE = 1024;

const ContentDescriptionV2 = ({
  product,
  gridSize,
  noPrice,
  getBrandProductLink,
  Redirection,
  removeInnerLink,
  isProductAvailableInCountry,
  handleAppOnlyItem,
  pdpFirstSection,
  discountLabelVisible,
  priceLabelVisible,
  retailPriceVisible,
  showEllipses
}) => {
  const language = useSelector(selectLanguageSlug);
  const routeSlug = useSelector(selectRouteSlug);
  const commonSettings = useSelector(selectCommonSettings);
  const vatText = get(commonSettings, `${language}_vatText`, "");

  const ProductDescriptionWrapperTag = props =>
    removeInnerLink ? (
      <div {...props} />
    ) : (
      <Link
        onClick={event => {
          if (isAppOnlyAvailableItem(product)) {
            event.preventDefault();
            handleAppOnlyItem(product);
          }
        }}
        to={`/${routeSlug}/${product.url}`}
        {...props}
      />
    );

  const BrandLinkTag = props => {
    const brandLink = getBrandProductLink && getBrandProductLink(product);
    return brandLink ? (
      <Link to={brandLink} {...props} />
    ) : (
      <Fragment {...props} />
    );
  };

  const truncateProductTitle = (title, characters) => {
    if (title && title.length > characters && showEllipses) {
      return title.substring(0, characters) + "...";
    }

    return title;
  };

  if (Redirection) {
    return (
      <ProductDescriptionWrapperTag className="product_detail_link">
        <div className="view_content">
          <BrandLinkTag>
            <h5 className="pdp_brand">{product.brand?.label}</h5>
          </BrandLinkTag>
          <h1 className="product_title">{product.title?.label}</h1>
          {isProductAvailableInCountry && !noPrice && (
            <Fragment>
              {Boolean(product?.price?.current || product?.price?.old) && (
                <PriceSlab
                  product={product}
                  discountLabelVisible={discountLabelVisible}
                  priceLabelVisible={priceLabelVisible}
                  retailPriceVisible={retailPriceVisible}
                />
              )}
            </Fragment>
          )}
        </div>
      </ProductDescriptionWrapperTag>
    );
  }

  return (
    <Fragment>
      <div className="view_content">
        <BrandLinkTag>
          <h5 className="pdp_brand">{product.brand.label}</h5>
        </BrandLinkTag>
        <h1>
          {gridSize === 4
            ? truncateProductTitle(
                product.title,
                window.innerWidth > LARGE_WIDTH_SIZE
                  ? TRUNCATE_PRODUCT_TITLE_CHAR.LARGE
                  : TRUNCATE_PRODUCT_TITLE_CHAR.MEDIUM
              )
            : truncateProductTitle(
                product.title,
                TRUNCATE_PRODUCT_TITLE_CHAR.SMALL
              )}
        </h1>
        {isProductAvailableInCountry && !noPrice && (
          <Fragment>
            {Boolean(product?.price?.current || product?.price?.old) && (
              <PriceSlab
                product={product}
                discountLabelVisible={discountLabelVisible}
                priceLabelVisible={priceLabelVisible}
                retailPriceVisible={retailPriceVisible}
              />
            )}
            {vatText && <span className="vat_price">{`(${vatText})`}</span>}
          </Fragment>
        )}
      </div>

      {pdpFirstSection &&
        pdpFirstSection.map((section, key) => (
          <DynamicBannersWrapper
            section={section}
            key={key}
            bannerPageTitle={section.sectionTitle}
          />
        ))}
    </Fragment>
  );
};

export default ContentDescriptionV2;
