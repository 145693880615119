import React from "react";
import cn from "classnames";
import SyteTooltipCloseIcon from "../../images/syte-tooltip-cross.svg";

const RetailPriceTooltip = ({
  onCrossClick,
  showCrossIcon,
  isArrow,
  isResponsive,
  message
}) => (
  <div
    className={cn("retail-price-tooltip", {
      "tooltip-arrow": isArrow,
      "responsive-view": isResponsive
    })}
  >
    <div className="tooltip-content">
      <div className="header-wrapper">
        <p className="header">{message?.pdpRrpPopupTitle}</p>

        {showCrossIcon && (
          <img
            src={SyteTooltipCloseIcon}
            alt="close tooltip"
            onClick={onCrossClick}
          />
        )}
      </div>
      <p className="mb-2 main-text">{message?.pdpRrpPopupContent}</p>
    </div>
  </div>
);

export default RetailPriceTooltip;
