import { createSelector } from "reselect";
import { DEFAULT_ITEM_QUANTITY } from "../../../constants";
import { selectCartData, selectCheckoutData } from "../../../util/selectors";

export const selectOrderItems = createSelector(
  selectCheckoutData,
  checkoutData => checkoutData?.orderItems
);

export const selectCartDataDetails = createSelector(
  selectOrderItems,
  selectCartData,
  (orderItems, cart) =>
    orderItems?.map(orderItem => {
      const title =
        cart?.find(item => item?.productId === orderItem.productId)?.title ||
        orderItem.title;

      return {
        ...orderItem,
        title
      };
    })
);

export const selectTotalCartPrice = createSelector(
  selectCartDataDetails,
  cartData => {
    const itemsPrice = cartData?.map(
      item => item.currentPrice * (item.quantity || DEFAULT_ITEM_QUANTITY)
    );
    const totalPrice = itemsPrice?.reduce((total, num) => total + num, 0);

    return totalPrice;
  }
);
