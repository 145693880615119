import React from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import map from "lodash/map";

import { getProductUrlName } from "../../../util";
import * as priceUtil from "../../../util/priceUtil";
import ContentImage from "../../content/content-image";
import ContentDescription from "../../content/content-description";

const OrderedItem = ({
  orderSuccessTranslation,
  items,
  language,
  moveToProductDetail,
  currencyCode,
  commonSettings,
  configCdnImagesSettings
}) => (
  <div className="ordered_item_wrap">
    <p className="total_item">
      {items.length} {orderSuccessTranslation.items}
    </p>
    <Scrollbars autoHeight autoHeightMin={160} autoHeightMax={355}>
      <div className="order_counts">
        {map(items, (item, index) => (
          <div className="ordered_item flex" key={`item_${item.pId}_${index}`}>
            <Link to={`#`} onClick={e => moveToProductDetail(item, e)}>
              <ContentImage
                img={item.image}
                url={configCdnImagesSettings?.thumb}
              />
            </Link>
            <div className="ordered_item_desc">
              <ContentDescription
                item={item}
                noPrice
                currencyCode={currencyCode}
                commonSettings={commonSettings}
                showEllipses
                Redirection
                getProductUrlName={getProductUrlName}
                language={language}
              />
              <p>
                <span className="title">{orderSuccessTranslation.price}</span>{" "}
                <span>
                  {currencyCode}{" "}
                  {priceUtil.shapePriceWithComma(
                    item.transactionPrice || item.price
                  )}
                </span>
              </p>
              {item.size && (
                <p>
                  <span className="title">{orderSuccessTranslation.size}</span>{" "}
                  <span>{item.size || "NA"}</span>
                </p>
              )}
              <p>
                <span className="title">
                  {orderSuccessTranslation.quantity}
                </span>{" "}
                <span>{item.quantity}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </Scrollbars>
  </div>
);

export default OrderedItem;
