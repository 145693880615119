import {
  CREATE_USER,
  HANDLE_TEXT_CHANGE,
  SESSION_TERMINATED,
  CLEAR_MODAL,
  SIGN_IN_MODAL,
  SHOW_SIGNUP_MODAL,
  HIDE_SIGNUP_MODAL,
  AUTHENTICATE_USER,
  REQUIRED_EN,
  NUMBER_EN,
  EMAIL_EN,
  SHOW_FORGOTPASSWORD_MODAL,
  HIDE_FORGOTPASSWORD_MODAL,
  RESET_PASSWORD_TOKEN_VALIDATION,
  API_ERROR,
  RESET_API_ERROR,
  API_NOTIFICATION_MSG,
  RESET_NOTIFICATION_MSG,
  RESET_PASSWORD_DATA,
  IS_HOME_PAGE,
  HOME_PAGE_ERROR,
  SET_REGISTRATION_DATA_STATUS,
  RESET_REGISTRATION_DATA_STATUS,
  SET_SIGNUP_LOGIN_TOKEN,
  RESET_SIGNUP_LOGIN_TOKEN,
  SET_SOCIAL_LOGIN_FLOW,
  RESET_SOCIAL_LOGIN_FLOW,
  SET_SOCIAL_PHONE_CHECK_TOKEN,
  RESET_SOCIAL_PHONE_CHECK_TOKEN,
  SET_ANALYTICS_DATA,
  SET_LAST_REGISTRATION_STATUS_CODE,
  SET_REGISTRATION_ERROR,
  SET_ADDRESS_OTP_ERROR,
  SET_ADDRESS_OTP_FLOW,
  SET_OTP_LOGIN_FLOW_BFL
} from "../constants";
import { emailValidationRegExp } from "../../constants";

import validator from "validator";

const initialState = {
  showModal: false,
  isHomePage: false,
  isHomePageError: false,
  signInModal: true,
  forgotPasswordModal: false,
  resetpassword: {
    success: true,
    message: "",
    email: ""
  },
  signUp: {
    firstname: "",
    lastname: "",
    gender: "",
    nationality: 1,
    countryId: "",
    phone: "",
    email: "",
    password: "",
    dialCode: "",
    areaCode: "",
    newsLetter: true,
    errors: {
      firstname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      lastname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      email: {
        valid: true,
        validWhen: true,
        check: "matches",
        options: emailValidationRegExp,
        message: EMAIL_EN
      },
      password: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      },
      gender: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      }
    }
  },
  login: {
    email: "",
    password: "",
    errors: {
      email: {
        valid: true,
        validWhen: true,
        message: EMAIL_EN,
        check: "matches",
        options: emailValidationRegExp
      },
      password: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      }
    }
  },
  APIErrorResponse: {
    success: true,
    message: ""
  },
  forgotPassword: {
    APINotificationMsg: "",
    email: "",
    errors: {
      email: {
        valid: true,
        validWhen: true,
        check: "matches",
        options: emailValidationRegExp,
        message: EMAIL_EN
      }
    }
  },
  resetPassword: {
    password: "",
    confirmPassword: "",
    currentPassword: "",
    errors: {
      password: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      confirmPassword: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      currentPassword: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      }
    }
  },
  initialResetPassword: {
    password: "",
    confirmPassword: "",
    currentPassword: "",
    errors: {
      password: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      confirmPassword: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      currentPassword: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      }
    }
  },
  homepageState: {
    storeId: "",
    countryId: "",
    sections: []
  },
  registrationDataStatus: {},
  signUpLoginToken: "",
  socialPhoneCheckToken: "",
  socialLoginFlow: true,
  otpLoginFlowBFL: false,
  analyticsData: {},
  lastRegistrationStatusCode: "",
  registrationError: "",
  addressOtpFlow: { error: "" }
};

const showLoginModal = state => ({
  ...state,
  showModal: true,
  APIErrorResponse: initialState.APIErrorResponse
});

const changeSignInModal = (state, { signInModal }) => ({
  ...state,
  signInModal
});

const hideForgotPasswordModal = state => ({
  ...state,
  forgotPasswordModal: false,
  forgotPassword: {
    ...initialState.forgotPassword
  }
});
const showForgotPasswordModal = state => ({
  ...state,
  forgotPasswordModal: true
});
const setresetPasswordTokenValidated = (state, action) => ({
  ...state,
  resetpassword: action.content
});
const setAPIErrorResponse = (state, action) => ({
  ...state,
  APIErrorResponse: action.content
});
const resetAPIErrorResponse = (state, action) => ({
  ...state,
  APIErrorResponse: initialState.APIErrorResponse
});
const setAPINotificationMsg = (state, action) => ({
  ...state,
  showModal: initialState.showModal,
  forgotPassword: {
    ...state.forgotPassword,
    APINotificationMsg: action.message
  }
});
const resetAPINotificationMsg = state => ({
  ...state,
  forgotPassword: {
    ...state.forgotPassword,
    APINotificationMsg: initialState.forgotPassword.APINotificationMsg
  }
});

const hideLoginModal = state => ({ ...state, showModal: false });

const clearModal = state => ({
  ...state,
  signUp: {
    ...initialState.signUp
  },
  login: {
    ...initialState.login
  }
});

const createUser = state => ({ ...state });

const authenticateUser = state => ({ ...state });

const handleTextChange = (state, action) => {
  let transformedState = {
    ...state,
    [action.modelName]: {
      ...state[action.modelName],
      [action.name]: typeof action.value === "undefined" ? "" : action.value
    }
  };
  if (transformedState[action.modelName].errors[action.name]) {
    let valid = true;
    const { check, validWhen } = state[action.modelName].errors[action.name];

    if (check) {
      const checkArgs = [
        action.value ? action.value.toString() : "",
        state[action.modelName].errors[action.name].options
      ];
      valid =
        validator[check](...checkArgs) === validWhen &&
        (typeof action.status !== "undefined" ? action.status : true);
    }

    transformedState[action.modelName].errors = {
      ...state[action.modelName].errors,
      [action.name]: state[action.modelName].errors[action.name]
        ? {
            ...state[action.modelName].errors[action.name],
            touched: true,
            valid
          }
        : { touched: true }
    };
  }
  return transformedState;
};

const homePageError = (state, action) => ({
  ...state,
  isHomePageError: action.isError
});

const resetPasswordData = state => ({
  ...state,
  resetPassword: state.initialResetPassword,
  APIErrorResponse: initialState.APIErrorResponse
});

const isHomePage = (state, { value }) => ({ ...state, isHomePage: value });

const setRegistrationDataStatus = (state, { payload }) => ({
  ...state,
  registrationDataStatus: payload
});

const resetRegistrationDataStatus = state => ({
  ...state,
  registrationDataStatus: initialState.registrationDataStatus
});

const setSignUpLoginToken = (state, { payload }) => ({
  ...state,
  signUpLoginToken: payload
});

const resetSignUpLoginToken = state => ({
  ...state,
  registrationDataStatus: initialState.signUpLoginToken
});

const setSocialLoginFlow = (state, { payload }) => ({
  ...state,
  socialLoginFlow: payload
});
const resetSocialLoginFlow = state => ({
  ...state,
  socialLoginFlow: initialState.socialLoginFlow
});

const setSocialPhoneCheckToken = (state, { payload }) => ({
  ...state,
  socialPhoneCheckToken: payload
});

const resetSocialPhoneCheckToken = state => ({
  ...state,
  socialPhoneCheckToken: initialState.socialPhoneCheckToken
});

export const setAnalyticsData = (state, { payload }) => ({
  ...state,
  analyticsData: payload
});

export const setLastRegistrationStatusCode = (state, { payload }) => ({
  ...state,
  lastRegistrationStatusCode: payload
});

export const setRegistrationError = (state, { payload }) => ({
  ...state,
  registrationError: payload
});

const setAddressOtpError = (state, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, error: action.payload }
});

const setAddressOtpFlow = (state, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, ...action.payload }
});

const setOTPLoginFlowBFL = (state, action) => ({
  ...state,
  otpLoginFlowBFL: action.payload
});

// const clearBannersSectionsData = state => {
//   return {
//     ...state,
//     homepageState: {
//       ...state.homepageState,
//       sections: []
//     }
//   };
// };

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return state;
    case SHOW_SIGNUP_MODAL:
      return showLoginModal(state);
    case HIDE_SIGNUP_MODAL:
      return hideLoginModal(state);
    case SIGN_IN_MODAL:
      return changeSignInModal(state, action);
    case CLEAR_MODAL:
      return clearModal(state);
    case SHOW_FORGOTPASSWORD_MODAL:
      return showForgotPasswordModal(state);
    case HIDE_FORGOTPASSWORD_MODAL:
      return hideForgotPasswordModal(state);
    case CREATE_USER:
      return createUser(state, action);
    case AUTHENTICATE_USER:
      return authenticateUser(state, action);
    case HANDLE_TEXT_CHANGE:
      return handleTextChange(state, action);
    case HOME_PAGE_ERROR:
      return homePageError(state, action);
    case RESET_PASSWORD_TOKEN_VALIDATION:
      return setresetPasswordTokenValidated(state, action);
    case API_ERROR:
      return setAPIErrorResponse(state, action);
    case RESET_API_ERROR:
      return resetAPIErrorResponse(state);
    case API_NOTIFICATION_MSG:
      return setAPINotificationMsg(state, action);
    case RESET_NOTIFICATION_MSG:
      return resetAPINotificationMsg(state);
    case RESET_PASSWORD_DATA:
      return resetPasswordData(state);
    case IS_HOME_PAGE:
      return isHomePage(state, action);
    case SET_REGISTRATION_DATA_STATUS:
      return setRegistrationDataStatus(state, action);
    case RESET_REGISTRATION_DATA_STATUS:
      return resetRegistrationDataStatus(state);
    case SET_SIGNUP_LOGIN_TOKEN:
      return setSignUpLoginToken(state, action);
    case RESET_SIGNUP_LOGIN_TOKEN:
      return resetSignUpLoginToken(state);
    case SET_SOCIAL_LOGIN_FLOW:
      return setSocialLoginFlow(state, action);
    case RESET_SOCIAL_LOGIN_FLOW:
      return resetSocialLoginFlow(state);
    case SET_SOCIAL_PHONE_CHECK_TOKEN:
      return setSocialPhoneCheckToken(state, action);
    case RESET_SOCIAL_PHONE_CHECK_TOKEN:
      return resetSocialPhoneCheckToken(state);
    case SET_ANALYTICS_DATA:
      return setAnalyticsData(state, action);
    case SET_LAST_REGISTRATION_STATUS_CODE:
      return setLastRegistrationStatusCode(state, action);
    case SET_REGISTRATION_ERROR:
      return setRegistrationError(state, action);
    case SET_ADDRESS_OTP_ERROR:
      return setAddressOtpError(state, action);
    case SET_ADDRESS_OTP_FLOW:
      return setAddressOtpFlow(state, action);
    case SET_OTP_LOGIN_FLOW_BFL:
      return setOTPLoginFlowBFL(state, action);

    default:
      return state;
  }
};

export default homepageReducer;
