import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Page from "../../components/page";
import Breadcrumb from "../../components/breadcrumb";
import HtmlStatic from "./html-static";
import AccordianStatic from "./accordian-static";
import CombAccordianStatic from "./comb_accordian-static";
import {
  saveBreadcrumb,
  resetBreadCrumb
} from "../../redux/actions/common.action";
import { withPageTypeTracking } from "../../hocs";
import { seoTranslation } from "../../language/seo/en/seo";
import { selectPageMeta } from "../../util/selectors";
class StaticHtmlPage extends Component {
  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      seoTranslated: seoTranslation[urlLang]
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { language } = this.props;
    if (language !== nextProps.language) {
      this._setBreadCrumb();
      this.getLanguage(nextProps.language);
    }
  }

  componentDidMount() {
    const { language } = this.props;
    this._setBreadCrumb();
    this.getLanguage(language);
  }

  getLanguage = language => {
    if (language.includes("ar-") || language.includes("ar")) {
      language = "ar";
    } else {
      language = "en";
    }
    const languageResponse = import(`../../language/seo/${language}/seo`);
    languageResponse.then(response => {
      this.setState({ seoTranslated: response.seoTranslation });
    });
  };
  _setBreadCrumb = () => {
    const breadcrumbStore = {};
    const {
      pageName,
      StaticContent,
      saveBreadcrumb,
      resetBreadCrumb,
      language
    } = this.props;
    const _categoryName = this.getStaticPageBreadCrumb(
      pageName,
      StaticContent,
      language
    );
    resetBreadCrumb && resetBreadCrumb();
    breadcrumbStore.modelName = "category";
    breadcrumbStore.modelData = {
      categoryName: _categoryName,
      categoryUrl: ""
    };
    saveBreadcrumb(breadcrumbStore);
  };

  getStaticPageBreadCrumb = (pageName, StaticContent, language) => {
    const staticPageBreadCrumb =
      !!StaticContent &&
      (Array.isArray(StaticContent)
        ? StaticContent.find(item => item.url.includes(pageName))
        : StaticContent);
    const staticBreadCrumbName =
      !!staticPageBreadCrumb.meta &&
      staticPageBreadCrumb.meta[language] &&
      staticPageBreadCrumb.meta[language].title;
    return staticBreadCrumbName;
  };
  render() {
    const { language, StaticContent, history, routeLanguage, meta } =
      this.props;
    const { seoTranslated } = this.state;
    let seoTitle = "";
    let metaInfo = {};
    if (
      StaticContent &&
      Array.isArray(StaticContent) &&
      StaticContent.length &&
      language
    ) {
      let pathname = history.location.pathname;
      let currentStaticPage = StaticContent.find(content =>
        pathname.includes(content.url)
      );
      let metaDescInfo = `${
        currentStaticPage &&
        currentStaticPage.meta &&
        currentStaticPage.meta[language] &&
        currentStaticPage.meta[language].metaDescription.trim()
      }`;
      if (
        currentStaticPage &&
        currentStaticPage.meta &&
        currentStaticPage.meta[language]
      ) {
        seoTitle = `${currentStaticPage.meta[language].title.trim()} | ${
          seoTranslated.bfl
        }`;
        metaInfo = language
          ? {
              [routeLanguage]: {
                ...currentStaticPage.meta[language],
                metaDescription: metaDescInfo
              }
            }
          : {};
      }
    } else if (StaticContent?.meta?.[language]) {
      let metaDescInfo = `${StaticContent.meta[
        language
      ].metaDescription.trim()}`;
      seoTitle = `${StaticContent.meta[language].title.trim()} | ${
        seoTranslated.bfl
      }`;
      metaInfo = language
        ? {
            [routeLanguage]: {
              ...StaticContent.meta[language],
              metaDescription: metaDescInfo
            }
          }
        : {};
    } else if (!isEmpty(meta)) {
      seoTitle = `${meta.title.trim()} | ${seoTranslated.bfl}`;
      metaInfo = language
        ? {
            [routeLanguage]: meta
          }
        : {};
    }
    return (
      <Page title={seoTitle} meta={metaInfo} language={language} id="static">
        <div
          className={
            language === "arabic" ? "static_arabic static_Page_arabic" : ""
          }
        >
          <Breadcrumb />
          {StaticContent && StaticContent.pageType === "html" ? (
            <HtmlStatic data={StaticContent} />
          ) : null}
          {StaticContent &&
          StaticContent[0] &&
          StaticContent[0].pageType === "comb_accordion" ? (
            <CombAccordianStatic data={StaticContent} />
          ) : null}
          {StaticContent && StaticContent.pageType === "accordion" ? (
            <AccordianStatic data={StaticContent} />
          ) : null}
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  redirectionData: state.page.redirectionData,
  meta: selectPageMeta(state)
});

const mapStateToDispatch = dispatch => ({
  saveBreadcrumb(navData) {
    dispatch(saveBreadcrumb(navData));
  },
  resetBreadCrumb() {
    dispatch(resetBreadCrumb());
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapStateToDispatch),
  withPageTypeTracking
)(StaticHtmlPage);
