import React, { Component } from "react";
import Modal from "react-modal";
import ThumbSwiper from "../../components/thumb-swiper";
import QuickLookModalContent from "./quicklook-modal-content";
import { addAnimate } from "../../util";
import get from "lodash/get";
import { ONE_SIZE } from "../../constants";
import { GAService } from "../../services/GA-service";

class QuicklookModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSize: "",
      selectedQty: 1,
      showQtyTextBox: false,
      errShowQtyTextBox: "",
      selectedInternational: "",
      showAddedToCardText: false,
      toggleViewMore: true,
      showViewMore: true,
      viewMoreHeight: 0,
      wishlistAnimationClass: "",
      product: props.product || "",
      quantityOverflowMessage: false,
      maxQuantityAddedMessage: false,
      maxQuantityThatCanBeAdded: "",
      qtySelectSizeQL: false,
      cartQuantity: null,
      toggleQuicklook: ""
    };
  }

  toggleViewButton = e => {
    return this.setState({ toggleViewMore: !this.state.toggleViewMore });
  };

  UNSAFE_componentWillMount() {
    this.props.handleSizeSelection(false);
  }

  componentWillUnmount() {
    this.props.clearSizeSelection();
  }

  componentDidMount() {
    this.changeHeight();
    const { product } = this.props;
    const _colorSizeOptions = get(product, "colors.colorSizeOptions", []);
    let availableSizes = _colorSizeOptions.filter(option => option.sizeLabel);
    const _sizeLabel = get(availableSizes, "0.sizeLabel");
    if (_sizeLabel === ONE_SIZE) {
      this.setState({ selectedSize: _sizeLabel });
    }
  }

  toggleQuickLookState = () => {
    this.setState({ toggleQuicklook: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { wishlistAnimationClass } = this.state;
    const { product, selectedSize, clearSizeSelection } = this.props;
    if (nextProps.product && product && nextProps.product.id !== product.id) {
      this.setState({ product: nextProps.product });
      this.setState({
        maxQuantityAddedMessage: false,
        quantityOverflowMessage: false
      });
      clearSizeSelection();
    }
    if (
      nextProps.selectedSize !== selectedSize ||
      nextProps.product.id !== product.id
    ) {
      this._addAnimate(
        nextProps.product,
        false,
        nextProps.wishListBaseData,
        wishlistAnimationClass,
        nextProps.selectedSize,
        true
      );
    }
  }

  changeHeight = () => {
    let pHeight = 0;
    const { viewMoreHeight } = this.state;
    if (this.wrapperRef && this.wrapperRef.clientHeight) {
      pHeight = this.wrapperRef.clientHeight;
    }
    if (viewMoreHeight !== pHeight) {
      this.setState({ viewMoreHeight: pHeight, showViewMore: pHeight > 150 });
    }
  };

  componentDidUpdate(prevProps) {
    const { wishListBaseDataLoaded, deleteWishListItemLoaded } = this.props;
    const { wishlistAnimationClass } = this.state;
    if (
      wishlistAnimationClass &&
      ((!prevProps.wishListBaseDataLoaded && wishListBaseDataLoaded) ||
        (!prevProps.deleteWishListItemLoaded && deleteWishListItemLoaded))
    ) {
      this.setState({ wishlistAnimationClass: "" });
    }
    this.changeHeight();
  }

  refCallback = element => {
    if (element && element.children && element.children.length) {
      this.wrapperRef = element.children[0];
      this.changeHeight();
    }
  };

  onSizeChange = (selectedSize, sizeStock) => {
    const { handleSizeSelection, product } = this.props;
    const { selectedInternational } = this.state;
    this.setState({ selectedSize });
    handleSizeSelection(false);
    this.setState({
      maxQuantityAddedMessage: false,
      quantityOverflowMessage: false
    });
    GAService.product.trackProductSizeSelected({
      size: selectedSize,
      product_sku: get(product, "id", ""),
      size_region: selectedInternational || undefined
    });
    this.onQtyTextChange(this.state.selectedQty, sizeStock);
  };

  setInternationalSelected = selectedInternational => {
    this.setState({ selectedInternational });
    this.props.clearSizeSelection();
  };

  handleSelectSizeFirstQL = availableSizes => {
    const { selectedSize } = this.props;
    if (availableSizes && availableSizes.length) {
      if (!selectedSize) {
        this.setState({ qtySelectSizeQL: true });
      } else {
        this.setState({ qtySelectSizeQL: false });
      }
    } else {
      this.setState({ qtySelectSizeQL: false });
    }
  };

  onQtyChange = (selectedQty, allowedQuantity) => {
    this.onQtyTextChange(selectedQty, allowedQuantity);
  };

  onQtyTextChange = (selectedQty, pstock) => {
    this.setState({
      maxQuantityAddedMessage: false,
      quantityOverflowMessage: false
    });
    if (selectedQty !== "" && !isNaN(selectedQty)) {
      selectedQty = Number.parseInt(selectedQty);
      if (selectedQty <= pstock) {
        this.setState({ selectedQty: selectedQty });
      } else {
        this.setState({ selectedQty: 1 });
      }
    } else if (selectedQty === "") {
      this.setState({ selectedQty: 1 });
    }
  };

  handleSizeChange = () => {
    this.setState({
      selectedSize: "",
      selectedQty: 1,
      errShowQtyTextBox: "",
      showQtyTextBox: false,
      qtySelectSizeQL: false,
      selectedInternational: ""
    });
  };

  handleAddToCart = (product, selectedSize, selectedQty, sizeType) => {
    this.setState({
      selectedQty: selectedQty,
      errShowQtyTextBox: "",
      showQtyTextBox: false
    });
    this.props.addToCart(product, selectedSize, selectedQty, sizeType);
  };

  handleAddToCartText = () => {
    this.setState({ showAddedToCardText: true }, () => {
      setTimeout(() => this.setState({ showAddedToCardText: false }), 2000);
    });
  };

  _addAnimate = (
    item,
    isClicked,
    wishListBaseData,
    wishlistAnimationClass,
    selectedSize,
    isDetailPage
  ) => {
    const value = addAnimate(
      item,
      isClicked,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      isDetailPage
    );
    this.setState({ wishlistAnimationClass: value });
  };

  _resetProduct = value => {
    const { handelSwipperButtonClick } = this.props;
    this.setState({ product: "" }, () => {
      handelSwipperButtonClick(value);
    });
  };

  onBlurChange = e => {
    const { selectedQty } = this.state;
    if (selectedQty === "") {
      this.setState({ showQtyTextBox: false, selectedQty: 1 });
    }
  };

  handleQuantityAddition = (
    item,
    cart,
    selectedQty,
    availableSizes,
    selectedSize,
    allowedQuantity,
    internationalSizes
  ) => {
    const { selectedInternational } = this.state;
    const returnValue = {
      isOverflow: false,
      msg: "",
      qty: 0
    };
    const oneSize =
      item.colors &&
      item.colors.colorSizeOptions &&
      item.colors.colorSizeOptions.filter(data => data.sizeLabel);
    const cartItems =
      cart &&
      cart.filter(cartItem => {
        return cartItem.productId === item.id;
      });
    const cartQuantity =
      (cartItems && cartItems.find(items => items.size === selectedSize)) || 0;
    const cartQuantity1 =
      (cartItems && cartItems.filter(items => items.size === selectedSize)) ||
      0;
    const totalQty =
      cartQuantity1 &&
      cartQuantity1
        .map(item => item.quantity)
        .reduce((total, num) => total + num, 0);
    const itemQuantity =
      availableSizes &&
      availableSizes.find(sizes => sizes.sizeLabel === selectedSize);

    if (oneSize && oneSize.length) {
      if (internationalSizes && Object.keys(internationalSizes).length) {
        if (cartItems && cartItems.length) {
          const itemDetails =
            internationalSizes &&
            internationalSizes[
              selectedInternational || Object.keys(internationalSizes)[0]
            ].find(item => item.sizeLabel.trim() === selectedSize);
          const cartQty =
            cartItems &&
            cartItems.reduce((total, item) => {
              if (item.sizeId === (itemDetails && itemDetails.sizeId)) {
                return total + item.quantity;
              }
              return total;
            }, 0);

          if (cart && !cart.length) {
            return returnValue;
          } else if (
            cartQty + selectedQty <=
            (itemDetails && itemDetails.sizeStock)
          ) {
            returnValue.isOverflow = false;
            returnValue.msg = "";
            returnValue.qty = selectedQty;
            this.setState({
              openAddToCartSuccessPopUp: true,
              quantityOverflowModal: true
            });
          } else {
            let _allowedQuantity = itemDetails && itemDetails.sizeStock;
            let quantityThatCanBeAddedSafely = _allowedQuantity - cartQty;
            if (quantityThatCanBeAddedSafely > 0) {
              this.setState({
                quantityOverflowMessage: true,
                maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
                cartQuantity: cartQty
              });
              returnValue.isOverflow = true;
              returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
              returnValue.qty = quantityThatCanBeAddedSafely;
            } else {
              this.setState({
                maxQuantityAddedMessage: true,
                cartQuantity: cartQty
              });
              returnValue.isOverflow = true;
              returnValue.msg = `You already have added the allowed max quantity (${_allowedQuantity}) for this item`;
              returnValue.qty = 0;
            }
          }
        }
      } else {
        if (cart && !cart.length) {
          return returnValue;
        } else if (
          +totalQty + selectedQty <=
          (itemQuantity && itemQuantity.sizeStock)
        ) {
          returnValue.isOverflow = false;
          returnValue.msg = "";
          returnValue.qty = selectedQty;
          this.setState({
            openAddToCartSuccessPopUp: true,
            quantityOverflowModal: true
          });
        } else {
          let _allowedQuantity = itemQuantity && itemQuantity.sizeStock;
          let quantityThatCanBeAddedSafely =
            _allowedQuantity - (cartQuantity && +totalQty);
          if (quantityThatCanBeAddedSafely > 0) {
            this.setState({
              quantityOverflowMessage: true,
              maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
              cartQuantity: totalQty
            });
            returnValue.isOverflow = true;
            returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
            returnValue.qty = quantityThatCanBeAddedSafely;
          } else {
            this.setState({
              maxQuantityAddedMessage: true,
              cartQuantity: totalQty
            });
            returnValue.isOverflow = true;
            returnValue.msg = `You already have added the allowed max quantity (${_allowedQuantity}) for this item`;
            returnValue.qty = 0;
          }
        }
      }
    } else {
      if (
        ((cartItems && cartItems[0] && cartItems[0].quantity) || 0) +
          selectedQty <=
        allowedQuantity
      ) {
        return true;
      } else {
        let _allowedQuantity = allowedQuantity;
        let quantityThatCanBeAddedSafely =
          _allowedQuantity -
          ((cartItems && cartItems[0] && cartItems[0].quantity) || 0);
        if (quantityThatCanBeAddedSafely > 0) {
          this.setState({
            quantityOverflowMessage: true,
            maxQuantityThatCanBeAdded: quantityThatCanBeAddedSafely,
            cartQuantity:
              (cartItems && cartItems[0] && cartItems[0].quantity) || 0
          });
          returnValue.isOverflow = true;
          returnValue.msg = `You can add max of ${quantityThatCanBeAddedSafely} items more instead of ${selectedQty}`;
          returnValue.qty = quantityThatCanBeAddedSafely;
        } else {
          this.setState({
            maxQuantityAddedMessage: true,
            cartQuantity:
              (cartItems && cartItems[0] && cartItems[0].quantity) || 0
          });
          returnValue.isOverflow = true;
          returnValue.msg = `You already have added the allowed max quantity (${allowedQuantity}) for this item`;
          returnValue.qty = 0;
        }
      }
    }
    return returnValue;
  };

  updateQuickLookModalToggleQuickLook = showQuicklookModal => {
    this.props.updateProductListingToggleQuickLook(showQuicklookModal);
  };

  render() {
    const {
      showQuicklookModal,
      toggleQuicklook,
      selectedIndex,
      products,
      translation,
      saveToWishList,
      wishListBaseData,
      cart,
      addToCart,
      language,
      deleteFromWishList,
      moveToProductDetail,
      currencyCode,
      sizeSelection,
      handleSizeSelection,
      commonSettings
    } = this.props;
    const {
      selectedSize,
      selectedQty,
      showQtyTextBox,
      errShowQtyTextBox,
      selectedInternational,
      toggleViewMore,
      showViewMore,
      viewMoreHeight,
      showAddedToCardText,
      wishlistAnimationClass,
      product,
      quantityOverflowMessage,
      maxQuantityAddedMessage,
      maxQuantityThatCanBeAdded,
      qtySelectSizeQL,
      cartQuantity
    } = this.state;
    return (
      <Modal
        isOpen={showQuicklookModal}
        ariaHideApp={false}
        onRequestClose={() => toggleQuicklook(false, selectedIndex)}
        shouldCloseOnOverlayClick={false}
        overlayClassName="quickLookOverlay"
        className={"quickLookModal"}
      >
        {selectedIndex !== 0 ? (
          <div
            className="swiper-button-prev"
            tabIndex="0"
            role="button"
            aria-label="Previous slide"
            aria-disabled="false"
            onClick={() => {
              this._resetProduct(selectedIndex - 1);
              // handelSwipperButtonClick(selectedIndex - 1);
              this.handleSizeChange();
            }}
          />
        ) : null}
        <div
          className={`cross_btn ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
          onClick={() => toggleQuicklook(false, selectedIndex)}
        />
        <div
          className={`quickview_divider flex ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          {product && (
            <ThumbSwiper
              imageList={
                (product &&
                  product.images &&
                  product.images.productImageURLs && [
                    { image: product.captionImageURL },
                    ...product.images.productImageURLs
                  ]) || [{ image: product && product.captionImageURL }]
              }
              item={product}
              height={579}
              width={386}
              isQuickLookModal
              language={language}
              product={product}
            />
          )}
          <QuickLookModalContent
            product={product}
            onSizeChange={this.onSizeChange}
            onQtyChange={this.onQtyChange}
            selectedSize={selectedSize}
            selectedQty={selectedQty}
            translation={translation}
            language={language}
            wishListBaseData={wishListBaseData}
            cart={cart}
            saveToWishList={saveToWishList}
            deleteFromWishList={deleteFromWishList}
            addToCart={addToCart}
            handleAddToCart={this.handleAddToCart}
            showQtyTextBox={showQtyTextBox}
            errShowQtyTextBox={errShowQtyTextBox}
            onQtyTextChange={this.onQtyTextChange}
            moveToProductDetail={moveToProductDetail}
            currencyCode={currencyCode}
            selectedInternational={selectedInternational}
            setInternationalSelected={this.setInternationalSelected}
            sizeSelection={sizeSelection}
            handleSizeSelection={handleSizeSelection}
            commonSettings={commonSettings}
            toggleViewMore={toggleViewMore}
            showViewMore={showViewMore}
            viewMoreHeight={viewMoreHeight}
            toggleViewButton={this.toggleViewButton}
            refCallback={this.refCallback}
            handleAddToCartText={this.handleAddToCartText}
            showAddedToCardText={showAddedToCardText}
            wishlistAnimationClass={wishlistAnimationClass}
            _addAnimate={this._addAnimate}
            onBlurChange={this.onBlurChange}
            quantityOverflowMessage={quantityOverflowMessage}
            maxQuantityAddedMessage={maxQuantityAddedMessage}
            maxQuantityThatCanBeAdded={maxQuantityThatCanBeAdded}
            handleQuantityAddition={this.handleQuantityAddition}
            handleSelectSizeFirstQL={this.handleSelectSizeFirstQL}
            qtySelectSizeQL={qtySelectSizeQL}
            cartQuantity={cartQuantity}
            updateQuickLookModalToggleQuickLook={
              this.updateQuickLookModalToggleQuickLook
            }
            showQuicklookModal={showQuicklookModal}
          />
        </div>
        {products !== selectedIndex + 1 ? (
          <div
            className="swiper-button-next"
            tabIndex="0"
            role="button"
            aria-label="Next slide"
            aria-disabled="false"
            onClick={() => {
              this._resetProduct(selectedIndex + 1);
              /*handelSwipperButtonClick(selectedIndex + 1);*/
              this.handleSizeChange();
            }}
          />
        ) : null}
      </Modal>
    );
  }
}

Modal.setAppElement("#root");

export default QuicklookModal;
