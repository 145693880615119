import React from "react";
import get from "lodash/get";
import includes from "lodash/includes";
import * as priceUtil from "../../../util/priceUtil";
import { renderPaymentModeMessage } from "../../../util/index";

const deliveryDetail = ({
  myAccountTranslation,
  order,
  countryName,
  countryCode
}) => {
  const paymentMode = get(order, "paymentMode", []);
  const paymentExtraInfo = get(order, "paymentExtraInfo", "");

  return (
    <div className="delivery_order_detail_wrapper">
      <div className="delivery_detail">
        <p className="title_text">{myAccountTranslation.deliveryDetails}</p>
        <address>
          <p>{`${order.shippingName && order.shippingName}, ${
            order.shippingAddress ? order.shippingAddress : ""
          }`}</p>
          <p>
            {" "}
            {`${order.shippingState ? order.shippingState : ""}, ${
              countryName ? countryName : ""
            }`}
            {order.shippingZip && `${", " + order.shippingZip}`}
          </p>
        </address>
        <p className="mobile_text">
          {`+${
            order.shippingMob && order.shippingMob.startsWith(countryCode)
              ? ""
              : countryCode
          }${
            order.shippingMob && order.shippingMob.startsWith(countryCode)
              ? order.shippingMob.replace(
                  order.shippingMob[3],
                  " " + order.shippingMob[3]
                )
              : " " + order.shippingMob
          }`}
        </p>
        <div className="all_details_my_orders">
          <table>
            <tbody>
              <tr>
                <td>
                  <p className="title_text">
                    {myAccountTranslation.paymentDetails}
                  </p>
                </td>
                <td>
                  <p className="card_detail">
                    <span className="card_number">
                      {renderPaymentModeMessage(
                        paymentMode,
                        paymentExtraInfo,
                        myAccountTranslation
                      )}
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="title_text">
                    {myAccountTranslation.deliveryType}
                  </p>
                </td>
                <td>
                  <p className="green_text">
                    {order && myAccountTranslation[order.deliveryType]}
                  </p>
                </td>
              </tr>
              {includes(order.paymentMode, "CBD") && (
                <tr>
                  <td>
                    <p className="title_text">
                      {myAccountTranslation.paymentStatus}
                    </p>
                  </td>
                  <td>
                    <p
                      className={`${
                        get(order, "paymentStatus", "") !== "PAYMENT_COMPLETED"
                          ? "red_text"
                          : "green_text"
                      }`}
                    >
                      {get(order, "paymentStatus", "") &&
                        myAccountTranslation[order.paymentStatus]}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="order_detail">
        <p className="title_text">{myAccountTranslation.orderTotal}</p>
        <div className="row no-gutters">
          <div className="col-7">
            <p className="text-left text-grey">
              {myAccountTranslation.subTotal}
            </p>
          </div>
          <div className="col-5">
            <p className="text-right text-amount">{`${
              order.CurrencyCode
            } ${priceUtil.shapePriceWithComma(order.transactionSubtotal)}`}</p>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-6">
            <p className="text-left text-grey">
              {myAccountTranslation.deliveryFee}
            </p>
          </div>
          <div className="col-6">
            {!!order.transactionDiscount &&
            order.discountType === "FREE_SHIPPING" ? (
              <p className="text-right text-grey free">
                {myAccountTranslation.freeShipping}
              </p>
            ) : (
              <p className="text-right text-grey">{`${
                order.CurrencyCode
              } ${priceUtil.shapePriceWithComma(
                order.transactionShippingCharge
              )}`}</p>
            )}
          </div>
        </div>
        {!!order.transactionWalletAmount && (
          <div className="row no-gutters">
            <div className="col-5">
              <p className="text-left text-grey">
                {myAccountTranslation.WALLET}
              </p>
            </div>
            <div className="col-7">
              <p className="text-right text-grey free">{`-${
                order.CurrencyCode
              } ${priceUtil.shapePriceWithComma(
                order.transactionWalletAmount
              )}`}</p>
            </div>
          </div>
        )}
        {!!order.transactionCodFee && (
          <div className="row no-gutters">
            <div className="col-7">
              <p className="text-left text-grey">
                {myAccountTranslation.codFee}
              </p>
            </div>
            <div className="col-5">
              <p className="text-right text-grey">{`${
                order.CurrencyCode
              } ${priceUtil.shapePriceWithComma(order.transactionCodFee)}`}</p>
            </div>
          </div>
        )}
        {!!order.transactionDiscount &&
          order.discountType &&
          order.discountType !== "FREE_SHIPPING" && (
            <div className="row no-gutters">
              <div className="col-5">
                <p className="text-left text-grey">
                  {myAccountTranslation.Discount}
                </p>
              </div>
              <div className="col-7">
                <p className="text-right text-grey free">{`-${
                  order.CurrencyCode
                } ${priceUtil.shapePriceWithComma(
                  order.transactionDiscount
                )}`}</p>
              </div>
            </div>
          )}

        <div className="row no-gutters total">
          <div className="col-5">
            <p className="text-left text-grey">
              {myAccountTranslation.total}
              {/*<span className="vat_price">({myAccountTranslation.IncOfVat})</span>*/}
            </p>
          </div>
          <div className="col-7">
            <p className="text-right text-amount">{`${
              order.CurrencyCode
            } ${priceUtil.shapePriceWithComma(order.transactionAmount)} `}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default deliveryDetail;
