import React from "react";

import { DROP_DOWN_SCROLL_TYPES } from "../../../constants";

import uniqBy from "lodash/uniqBy";

import CustomDropdown from "../../genericDropDown";

const DeliveryPricePreview = ({
  deliveryOptions,
  selectedDelivery,
  changeSelectedDelivery
}) => {
  const uniqueDeliveryOptions = uniqBy(deliveryOptions, "key");

  const updatedDeliveryOptions =
    Boolean(selectedDelivery) &&
    uniqueDeliveryOptions.filter(item => item.key !== selectedDelivery.key);

  const defaultSelectedOption = selectedDelivery || deliveryOptions?.[0];

  return (
    <div className="order_delivery_price_wrapper">
      <CustomDropdown
        scrollType={DROP_DOWN_SCROLL_TYPES.DROP_DOWN_WITHOUT_INLINE_SCROLL}
        containerClass="main_head"
        data={updatedDeliveryOptions}
        selectedItem={selectedDelivery}
        handleChange={changeSelectedDelivery}
        defaultSelectValue={defaultSelectedOption}
      />
    </div>
  );
};

export default DeliveryPricePreview;
