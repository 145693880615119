import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  changeSelectedCountry,
  changeSelectedShop,
  getStoreLocatorList
} from "../../redux/actions/storeLocator.action";
import {
  changeLanguage,
  getCountryList
} from "../../redux/actions/common.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import GoogleMap from "../../components/google/google-map";
import Page from "../../components/page";
import StoreLocatorDetails from "../../components/store-locator/store-locator-details";
import StoreLocatorHeader from "../../components/store-locator/store-locator-header";
import StoreLocatorThumbSwiper from "../../components/store-locator/store-locator-thumb-swiper";
import { isServer } from "../../util";
import { seoTranslation } from "../../language/seo/en/seo";
import { storeLocatorTranslation } from "../../language/storeLocator/en/storeLocator";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import ssrConfig from "./ssrConfig";
import { GAService } from "../../services/GA-service";
import { addScriptToBody } from "../../util/dom";
import { GOOGLE_MAPS_APIS_SRC } from "../../constants";

class StoreLocator extends Component {
  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      mapVisible: false,
      storeLocatorTranslation: storeLocatorTranslation,
      latitude: 0,
      longitude: 0,
      seoTranslated: seoTranslation[urlLang]
    };
  }

  async componentDidMount() {
    const {
      countryId,
      getCountryList,
      getStoreLocatorList,
      handleLanguageChange,
      language,
      match
    } = this.props;
    if (!isServer) {
      let languageParam = (match.params && match.params.language) || language;
      if (languageParam.includes("ar-") || languageParam.includes("ar")) {
        languageParam = "ar";
      } else {
        languageParam = "en";
      }
      if (countryId) {
        await getCountryList(language);
        getStoreLocatorList(languageParam, countryId);
      }
      addScriptToBody(GOOGLE_MAPS_APIS_SRC, null, true);
      handleLanguageChange(languageParam);
      this.loadLanguage(language);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        success => {
          this.setState({
            latitude: success.coords.latitude,
            longitude: success.coords.longitude
          });
        },
        failure => {
          this.setState({ message: failure.message });
        }
      );
    } else {
      this.state({
        isGeolocation: "Geolocation is not supported by this browser."
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryId, getStoreLocatorList, language } = this.props;
    if (nextProps.language !== language) {
      this.loadLanguage(nextProps.language);
    }
  }

  useCurrentLocation = (lat, lng, zoom) => {
    this.setState({ mapVisible: true, lat, lng, zoom });
  };

  loadLanguage = async language => {
    const [storeLocatorResponse, seoTranslationResponse] = await Promise.all([
      import(`../../language/storeLocator/${language}/storeLocator`),
      import(`../../language/seo/${language}/seo`)
    ]);

    this.setState({
      storeLocatorTranslation: storeLocatorResponse.storeLocatorTranslation,
      seoTranslated: seoTranslationResponse.seoTranslation
    });
  };

  async componentDidUpdate(prevProps) {
    const { countryId, language, getCountryList, getStoreLocatorList } =
      this.props;

    if (prevProps.language !== language || prevProps.countryId !== countryId) {
      await getCountryList(language);
      getStoreLocatorList(language, countryId);
    }
  }

  changeSelectedShop = (shopItem, zoom) => {
    this.setState({ lat: shopItem.lat, lng: shopItem.lng, zoom });
    GAService.common.trackNearByStoreChanged(shopItem);
    this.props.changeSelectedShop(shopItem);
  };

  render() {
    const {
      language,
      storeCountry,
      changeSelectedCountry,
      cityList,
      selectedCountry,
      selectedShop,
      countryCode,
      locatorList
    } = this.props;
    const {
      mapVisible,
      storeLocatorTranslation,
      lat,
      lng,
      zoom,
      latitude,
      longitude,
      seoTranslated
    } = this.state;
    let _store = [];
    countryCode &&
      locatorList &&
      locatorList.filter(item => {
        if (
          item.countryId === countryCode ||
          item.country === selectedCountry
        ) {
          _store = [..._store, ...item.shops];
        }
        return null;
      });

    !!_store.length &&
      _store.forEach(item => {
        if (item.id) {
          item["station_id"] = item.id;
          delete item.id;
        }
      });
    const mapContainerHeight =
      !isServer && document.body.clientWidth > 813 ? "350px" : "225px";
    const metaInfo = language
      ? {
          [language]: {
            title: `${seoTranslated.ourStores} | ${seoTranslated.bfl}`,
            metaDescription: `${seoTranslated.storeLocatorMeta}`
          }
        }
      : {};

    return (
      <Page
        title={metaInfo[language].title}
        meta={metaInfo}
        language={language}
        id="store-locator"
        indexable
      >
        <div
          className={`store-locator-container ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <StoreLocatorHeader
            storeCountry={storeCountry}
            selectedCountry={selectedCountry}
            cityList={cityList}
            selectedShop={selectedShop}
            useCurrentLocation={() =>
              this.useCurrentLocation(latitude || 0, longitude || 0, 16)
            }
            storeLocatorTranslation={storeLocatorTranslation}
            changeSelectedCountry={changeSelectedCountry}
            changeSelectedShop={this.changeSelectedShop}
          />

          {!!selectedShop && Object.keys(selectedShop).length ? (
            <div className={`container padding-web-0 store-locator-body`}>
              <div className="store_preview">
                {!mapVisible && (
                  <StoreLocatorThumbSwiper selectedShop={selectedShop} />
                )}
                {mapVisible && (
                  <div className="map_container">
                    <GoogleMap
                      stores={_store}
                      selectedStore={selectedShop}
                      lat={+lat}
                      lng={+lng}
                      zoom={+zoom}
                      mapContainerHeight={mapContainerHeight}
                      translation={storeLocatorTranslation}
                    />
                  </div>
                )}
              </div>
              <div className="store_detail">
                <StoreLocatorDetails
                  mapVisible={mapVisible}
                  selectedShop={selectedShop}
                  storeLocatorTranslation={storeLocatorTranslation}
                  language={language}
                />
              </div>
            </div>
          ) : (
            <div className="noStoresFound">
              {" "}
              <span>{storeLocatorTranslation.noStoresFound}</span>
            </div>
          )}
        </div>
      </Page>
    );
  }
}
StoreLocator.need = ssrConfig;

const mapStateToProps = reduxState => ({
  language: reduxState.common.language,
  stores: reduxState.common.settings && reduxState.common.settings.shops,
  countryCode:
    reduxState.common.settings && reduxState.common.settings.countryCode,
  countryId: reduxState.common.settings && reduxState.common.settings.countryId,
  generalInfo: reduxState.common.generalInfo,
  storeCountry: reduxState.storeLocatorReducer.storeCountry,
  cityList: reduxState.storeLocatorReducer.cityList,
  selectedCountry: reduxState.storeLocatorReducer.selectedCountry,
  selectedShop:
    reduxState.storeLocatorReducer &&
    reduxState.storeLocatorReducer.selectedShop,
  locatorList: reduxState.storeLocatorReducer.locatorList
});

const mapDispatchToProps = {
  changeSelectedCountry,
  changeSelectedShop,
  getCountryList,
  getMegaMenuData,
  getStoreLocatorList,
  handleLanguageChange: changeLanguage
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(StoreLocator);
