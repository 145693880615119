import React from "react";
import { connect } from "react-redux";
import { selectCdnImageSettings } from "../../util/selectors";
import ContentImage from "../content/content-image";
const roundImg = ({
  imgSrc,
  overlay,
  productCount,
  intialClass,
  myAccountTranslation,
  configCdnImagesSettings
}) => {
  return (
    <div className={`img_wrapper ${!!intialClass ? intialClass : ""}`}>
      {overlay && <div className="img_overlay" />}
      {!!productCount && (
        <p className="order_count">
          {" "}
          {`+ ${productCount}`} {myAccountTranslation.more}{" "}
        </p>
      )}
      <ContentImage
        img={`${imgSrc}`}
        url={configCdnImagesSettings?.myOrder}
        lazyLoad={true}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  configCdnImagesSettings: selectCdnImageSettings(state)
});

export default connect(mapStateToProps)(roundImg);
