import React, { Fragment } from "react";
import cn from "classnames";
import "./style.scss";

const BreadcrumbList = ({
  breadcrumbs,
  delimiter,
  SwiperComponent = Fragment,
  swiperParams
}) => (
  <ul className="breadcrumb-list">
    <SwiperComponent {...swiperParams}>
      {breadcrumbs.map((breadcrumb, index) => {
        const notClickable = index === breadcrumbs.length - 1;
        return (
          <li key={breadcrumb.name} className="content">
            {!!index && delimiter}
            <span
              className={cn("breadcrumb-item-content", {
                "no-click": notClickable
              })}
              {...(!notClickable && {
                onClick: breadcrumb.onClick
              })}
            >
              {breadcrumb.name}
            </span>
          </li>
        );
      })}
    </SwiperComponent>
  </ul>
);

export default BreadcrumbList;
