import { createSelector } from "reselect";
import { get } from "lodash";
import {
  selectAuthReducer,
  selectCheckoutData,
  selectCommonSettings
} from "../../../util/selectors";

export const selectDeliverySettings = createSelector(
  selectCommonSettings,
  commonSettings => get(commonSettings, "deliverySettings", [])
);

export const selectIsUserLoggedIn = createSelector(
  selectAuthReducer,
  authReducer => get(authReducer, "userLoggedIn")
);

export const selectOrder = createSelector(selectCheckoutData, checkoutData => ({
  step1Completed: checkoutData?.step1Completed,
  guestEmail: checkoutData?.guestEmail,
  continueAsGuest: checkoutData?.continueAsGuest,
  orderItems: checkoutData?.orderItems
}));
