import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import ModalBFL from "../../modal/modal";
import CustomDropdown from "../../genericDropDown";
import {
  getLoyaltyEarnedPoints,
  redeemLoyaltyPoints
} from "../../../redux/actions/myAccount.action";
import { AnalyticService } from "../../../services";
import {
  selectCustomerId,
  selectLoyaltyProgramCurrentBalance,
  selectShowLoader,
  selectLoyaltyProgramMonetaryValue
} from "../../../util/selectors";
import { ButtonWithLoader } from "../../generalElements/buttons";

class RedeemLoyaltyPointsBtn extends React.Component {
  state = {
    redeemPopup: false,
    congratePopup: false,
    redeemSelectedPints: "Select Option",
    redeemOption: [],
    selectedPoints: "",
    selectedTransactionAmount: "",
    selectedCurrencyCode: "",
    transformedEarnedPoints: [],
    isEmptyRedeemSelect: false
  };
  changeRedeemPoints = selected => {
    const optionSelected = selected.split(" ");
    this.setState({
      redeemSelectedPints: selected,
      selectedPoints: optionSelected[0],
      selectedTransactionAmount: optionSelected[3],
      selectedCurrencyCode: optionSelected[4]
    });
  };

  handleRedeemPoints = value => {
    this.setState({ redeemPopup: value });
  };

  handleCongrate = () => {
    const {
      redeemLoyaltyPoints,
      language,
      loyaltyPoints,
      countryId,
      customerId,
      currentBalance,
      currencyCode,
      loyaltyMonetaryValue
    } = this.props;
    const { selectedPoints, selectedTransactionAmount, selectedCurrencyCode } =
      this.state;

    if (!selectedPoints) {
      this.setState({ isEmptyRedeemSelect: true });
      return;
    }

    redeemLoyaltyPoints({
      language: language,
      currencyCode: selectedCurrencyCode,
      points: selectedPoints,
      transactionAmount: selectedTransactionAmount,
      pageSize: loyaltyPoints.LoyaltyTable.loyaltyPoints.length + 1,
      countryId: countryId
    }).then(async () => {
      this.handleRedeemPoints(false);
      this.setState({ isEmptyRedeemSelect: false });
      AnalyticService.customer.trackLoyaltyRedeemConfirmClick({
        countryId,
        smilePoints: currentBalance,
        customerId,
        language,
        currencyCode,
        loyaltyMonetaryValue,
        selectedRedemptionPoints: selectedPoints,
        selectedRedemptionTransactionAmount: selectedTransactionAmount
      });
      await this.setLoyaltyEarnedPoints();
    });
  };

  async componentDidMount() {
    await this.setLoyaltyEarnedPoints();
  }

  setLoyaltyEarnedPoints = async () => {
    const { getLoyaltyEarnedPoints, countryId, currencyCode, language } =
      this.props;

    const { earnedPoints } = await getLoyaltyEarnedPoints(
      countryId,
      currencyCode,
      language
    );

    const transformedEarnedPoints = earnedPoints.map(
      value =>
        `${value.Points} Points - ${parseFloat(value.TransactionAmount)} ${
          value.currencyCode
        }`
    );

    this.setState({ transformedEarnedPoints });
  };

  onCloseModal = () => {
    this.handleRedeemPoints(false);
    this.setState({ isEmptyRedeemSelect: false });
  };

  render() {
    const {
      myAccountTranslation,
      language,
      children = myAccountTranslation.redeemed,
      countryId,
      currentBalance,
      customerId,
      showLoader,
      currencyCode,
      loyaltyMonetaryValue
    } = this.props;

    const {
      redeemPopup,
      redeemSelectedPints,
      transformedEarnedPoints,
      isEmptyRedeemSelect
    } = this.state;

    return (
      <>
        <button
          className={cn(
            "redeem_btn redeem_btn_mobile round_btn form_black_btn ",
            {
              disable_order_btn: !transformedEarnedPoints.length
            }
          )}
          onClick={() => {
            this.handleRedeemPoints(true);
            AnalyticService.customer.trackLoyaltyRedeemButtonClick({
              countryId,
              smilePoints: currentBalance,
              customerId,
              language,
              currencyCode,
              loyaltyMonetaryValue
            });
          }}
        >
          {children}
        </button>
        <ModalBFL
          showModal={redeemPopup}
          modalClass={cn("generic_modal redeem_loyalty_modal", {
            arabic: language.includes("ar")
          })}
        >
          <button className="cross_btn" onClick={this.onCloseModal}>
            &times;
          </button>
          <div className="redeem_option_wrapper generic_modal_content">
            <h2>{myAccountTranslation.redeemLoyaltyProgram}</h2>
            <p>{myAccountTranslation.redeemOption}</p>
            <CustomDropdown
              name={"redeem_option"}
              data={transformedEarnedPoints}
              selectedItem={redeemSelectedPints}
              containerClass={"redeem_options"}
              scrollbarMaxHeight={150}
              handleChange={this.changeRedeemPoints}
            />
            {isEmptyRedeemSelect && (
              <p className="error">{myAccountTranslation.fieldIsRequired}</p>
            )}
          </div>
          <ButtonWithLoader
            className="redeem_option_btn round_btn form_black_btn"
            onClick={this.handleCongrate}
            showLoader={showLoader}
          >
            {myAccountTranslation.redeemed}
          </ButtonWithLoader>
          <div className="redeem_option_footer">
            <p>{myAccountTranslation.pointsWillBeCredited}</p>
          </div>
        </ModalBFL>
      </>
    );
  }
}
const mapStateToProps = state => ({
  language: state.common.language,
  setting: state.common.settings,
  loyaltyPoints: state.myAccountReducer.loyaltyPoints,
  countryId: state.common.settings.countryId,
  currencyCode: state.common.settings.currencyCode,
  customerId: selectCustomerId(state),
  currentBalance: selectLoyaltyProgramCurrentBalance(state),
  showLoader: selectShowLoader(state),
  loyaltyMonetaryValue: selectLoyaltyProgramMonetaryValue(state)
});
const mapDispatchToProps = {
  redeemLoyaltyPoints,
  getLoyaltyEarnedPoints
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemLoyaltyPointsBtn);
