import React from "react";
import cn from "classnames";
import MenuListWrapper from "./menuListWrapper";

const CategoryMenu = ({
  menuList: { col1, col2, col3, col4, col5, col6, headerTitle, en_headerTitle },
  countryShort,
  language,
  isDisplay
}) => {
  const listWrapper = [col1, col2, col3];
  const bannerWrapper = [col4, col5, col6];
  const headerTitles = {
    headerTitle,
    en_headerTitle
  };
  // List Wrapper
  const listWrap = listWrapper.map((col, index) => (
    <MenuListWrapper
      col={col}
      key={index}
      countryShort={countryShort}
      language={language}
      headerTitles={headerTitles}
    />
  ));

  // Banner Wrapper
  const findIndex = bannerWrapper.findIndex(
    col => col && !!Array.isArray(col.images)
  );

  const lastBannerSelected =
    findIndex > -1 ? bannerWrapper.slice(0, findIndex + 1) : bannerWrapper;

  const promotionCategory = lastBannerSelected.map((col, index) => (
    <MenuListWrapper
      col={col}
      key={index}
      countryShort={countryShort}
      language={language}
      headerTitles={headerTitles}
    />
  ));

  return (
    <>
      <div
        className={cn("category-menu-bg", {
          "category-menu-bg-toggle": !isDisplay
        })}
      />
      <div
        className={cn("category-menu", {
          "category-menu-bg-toggle": !isDisplay
        })}
      >
        <section className="container padding0">
          <div className="list-wrapper">{listWrap}</div>
          <div className="promotion-category">{promotionCategory}</div>
        </section>
      </div>
    </>
  );
};

export default CategoryMenu;
