import React, { Component } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getInternationalSizes } from "../../../util/cart";
import { getColorSizeOptions } from "../../../util/add-to-cart";
import {
  validateProduct,
  getProductUrlName,
  getBaseUrl,
  isMobile
} from "../../../util";
import { isPriceAvailableByUserIpcountry } from "../../../util/priceUtil";
import { isItemAvailableInCountry } from "../../../util/common";

import { resetProductMerge } from "../../../redux/actions/cart.action";
import SizeQtyBlock from "../item-size-and-quantity";
import CartItemImage from "./cart-item-image";
import CartItemDetails from "./cart-item-details";
import AddItemToWishlistWeb from "./add-item-to-wishlist-web";
import AddItemToWishlistMob from "./add-item-to-wishlist-mob";
import { DiscountSection } from "../../price/price-slab";
import { selectCdnImageSettings } from "../../../util/selectors";

class ItemsInCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQty: (props.item && props.item.quantity) || 1,
      showQtyTextBox: false,
      errShowQtyTextBox: "",
      openQuantity: false,
      openMultipleSizes: false,
      isOutOfStock: false,
      isLimitReachedAfterProductMerge: false,
      isStockChanged: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { item, checkout, changeCartItemQuantity, resetProductMerge } =
      nextProps;
    const product = validateProduct(checkout, item);

    const isOutOfStock = Boolean(
      !item.pstock || (product._OutOfStock && !!product._OutOfStock)
    );
    const isLimitReachedAfterProductMerge =
      Boolean(item.quantity > getQuantity(item)) && !isOutOfStock;
    const allowedQuantity = item.size ? getQuantity(item) : item.pstock || 0;
    const itemStockLeft = get(checkout, "orderItems", []).find(
      checkoutItem => checkoutItem._id === item._id
    );
    const remainingItemStock = get(
      itemStockLeft,
      "currentStock",
      allowedQuantity
    );
    const isStockChanged =
      Boolean(item.prevValue && item.prevValue > remainingItemStock) &&
      !isOutOfStock;

    if (item.isProductMerged) {
      resetProductMerge();
      return { selectedQty: item.quantity };
    }

    if (isStockChanged && item.quantity > remainingItemStock) {
      changeCartItemQuantity(remainingItemStock);
    }

    return {
      isOutOfStock,
      isLimitReachedAfterProductMerge,
      isStockChanged
    };
  }

  handleCartItemQuantityChange = (quantity, allowedQuantity, hitAPI = true) => {
    const { changeCartItemQuantity } = this.props;
    if (quantity === "10+") {
      this.setState({
        showQtyTextBox: true,
        selectedQty: 10,
        errShowQtyTextBox: ""
      });
      return null;
    }
    this.setState({ selectedQty: quantity });
    this.onQtyTextChange(quantity, allowedQuantity);
    hitAPI && quantity && changeCartItemQuantity(quantity);
  };

  handleCartItemSizeChange = (exactSizeInfo, allowedQuantity, selectedQty) => {
    const { changeCartItemSize } = this.props;
    this.onQtyTextChange(selectedQty, allowedQuantity);
    changeCartItemSize(exactSizeInfo);
  };

  clearSelectedQuantity = () => {
    this.setState({ selectedQty: 0 });
  };

  handleTextQtySubmit = (quantity, allowedQuantity) => {
    const { translation, language, changeCartItemQuantity } = this.props;

    let errShowQtyTextBox = language.includes("en")
      ? `${translation.qtyErrText} ${allowedQuantity}`
      : `${allowedQuantity} ${translation.qtyErrText}`;

    if (quantity <= allowedQuantity) {
      errShowQtyTextBox = "";
      changeCartItemQuantity(quantity);
    }

    this.setState({ showQtyTextBox: false, errShowQtyTextBox });
  };

  validateQuantity = (quantity, allowedQuantity) => {
    const { translation, language } = this.props;

    let errShowQtyTextBox = language.includes("en")
      ? `${translation.qtyErrText} ${allowedQuantity}`
      : `${allowedQuantity} ${translation.qtyErrText}`;

    if (quantity <= allowedQuantity) {
      errShowQtyTextBox = "";
    }

    this.setState({ errShowQtyTextBox });
  };

  onQtyTextChange = (selectedQty, allowedQuantity) => {
    const { changeSelectedDelivery, deliveryType } = this.props;
    if (deliveryType !== "SELF_PICKUP") {
      changeSelectedDelivery();
    }
    const isStartsWithZero =
      selectedQty && selectedQty.toString().startsWith("0");

    const isSelectedQtyTooLong =
      selectedQty.toString().length > allowedQuantity.toString().length;

    if (isStartsWithZero || isSelectedQtyTooLong) {
      return;
    }

    if (selectedQty !== "" && !isNaN(selectedQty)) {
      this.setState({
        selectedQty: selectedQty
      });

      if (!this.state.showQtyTextBox)
        this.validateQuantity(selectedQty, allowedQuantity);
      return null;
    }

    if (selectedQty === "") {
      this.setState({ selectedQty: " ", errShowQtyTextBox: "" });
      return null;
    } else if (!isNaN(selectedQty) && Number.parseInt(selectedQty) > 0) {
      if (selectedQty <= allowedQuantity) {
        this.setState({
          selectedQty: Number.parseInt(selectedQty),
          errShowQtyTextBox: ""
        });
        return null;
      } else {
        this.setState({
          selectedQty: Number.parseInt(selectedQty)
        });
        return null;
      }
    }
  };

  handleQtyModal = value => {
    this.setState({ openQuantity: value });
  };

  handleMultipleSizesModal = value => {
    this.setState({ openMultipleSizes: value });
  };

  remainingSizeStock = size => {
    const { item } = this.props;
    const productInfo = get(item, "colors.colorSizeOptions", []).find(
      option => option.sizeLabel === size && option.sizeStock
    );

    return productInfo && productInfo.sizeStock;
  };

  onBlurChange = () => {
    const { selectedQty } = this.state;
    const { changeCartItemQuantity } = this.props;

    if (selectedQty === "" || selectedQty < 1) {
      this.setState({ showQtyTextBox: false, selectedQty: 1 });
      changeCartItemQuantity(1);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const _obj = { ...this.state };
    if (_obj.errShowQtyTextBox !== nextState.errShowQtyTextBox) {
      this.props.isCheckoutEnable(nextState && nextState.errShowQtyTextBox);
    }
    return true;
  }

  componentWillUnmount() {
    const { selectedQty } = this.state;
    const { changeCartItemQuantity } = this.props;

    if (selectedQty === "") {
      this.setState({ showQtyTextBox: false, selectedQty: 1 });
      changeCartItemQuantity(1);
    }
  }

  isWishListed = item => {
    const { wishListBaseData } = this.props;

    const wishlist = Array.isArray(wishListBaseData) ? wishListBaseData : [];

    return wishlist.some(
      wishItem =>
        wishItem.productId === item.productId && wishItem.size === item.size
    );
  };

  removeCartItem = () => {
    const { item, deleteCartListData } = this.props;

    this.clearSelectedQuantity();
    deleteCartListData(item);
  };

  handleAddToWishList = () => {
    const { item, saveToWishList } = this.props;

    if (!this.isWishListed(item)) {
      saveToWishList(item);
    }
  };

  render() {
    const {
      item,
      translation,
      productTranslation,
      language,
      currencyCode,
      checkout,
      commonSettings,
      selectedSizeType,
      configCdnImagesSettings
    } = this.props;

    const {
      showQtyTextBox,
      errShowQtyTextBox,
      openQuantity,
      openMultipleSizes,
      isOutOfStock,
      isStockChanged,
      selectedQty
    } = this.state;
    const countryId = get(commonSettings, "countryId", "");
    const isAvailableInCountry =
      isPriceAvailableByUserIpcountry(item) &&
      isItemAvailableInCountry(item, countryId);
    const internationalSizes = getInternationalSizes(item);
    const availableSizes = (
      isEmpty(internationalSizes)
        ? get(item, "colors.colorSizeOptions", []).filter(
            o => o.sizeLabel && o.sizeStock
          )
        : internationalSizes[item.sizeType] ||
          internationalSizes[Object.keys(internationalSizes)[0]]
    ).filter(size => {
      if (item.IsMultisize) {
        return size.sizeinfo !== null;
      }
      return true;
    });

    const allowedQuantity = item.size ? getQuantity(item) : item.pstock || 0;
    const product = validateProduct(checkout, item);

    const itemStockLeft = get(checkout, "orderItems", []).find(
      checkoutItem => checkoutItem._id === item._id
    );

    const remainingItemStock = get(
      itemStockLeft,
      "currentStock",
      allowedQuantity
    );

    const ProductTitle = getProductUrlName(item.en_title || item.title || "");

    const currentProductUrl = `${getBaseUrl(this.props)}/${
      ProductTitle || "---"
    }/${item.productId}/p/`;

    const selectedQuantity =
      (product._quantityChanged ? remainingItemStock : selectedQty) ||
      item.quantity;

    const showInnerPriceDiscount = Boolean(isMobile.any());

    return (
      <div
        className={cn(
          "cart_item",
          isOutOfStock || !isAvailableInCountry
            ? "unavailable"
            : "NoBorder out_of_stock_handler"
        )}
        ref={cartCard => (this.cartCard = cartCard)}
      >
        <div className="cart_item_info">
          <CartItemImage
            currentProductUrl={currentProductUrl}
            item={item}
            url={configCdnImagesSettings}
            isAvailableInCountry={isAvailableInCountry}
            isOutOfStock={isOutOfStock}
            translation={translation}
            product={product}
            isStockChanged={isStockChanged}
          >
            {showInnerPriceDiscount && (
              <div className="image_inner_discount">
                <DiscountSection
                  item={item}
                  countrySettings={commonSettings}
                  translation={productTranslation}
                  language={language}
                />
              </div>
            )}
          </CartItemImage>

          <div className="item_detail">
            <CartItemDetails
              currentProductUrl={currentProductUrl}
              item={item}
              isAvailableInCountry={isAvailableInCountry}
              translation={translation}
              currencyCode={currencyCode}
              commonSettings={commonSettings}
              productTranslation={productTranslation}
              language={language}
              showDiscountSlab={!showInnerPriceDiscount}
            />

            {isAvailableInCountry && (
              <>
                <SizeQtyBlock
                  pstock={item.pstock}
                  showQtyTextBox={showQtyTextBox}
                  selectedQty={selectedQuantity}
                  errShowQtyTextBox={errShowQtyTextBox}
                  onQtyTextChange={this.onQtyTextChange}
                  onSizeChange={this.handleCartItemSizeChange}
                  onQtyChange={this.handleCartItemQuantityChange}
                  handleTextQtySubmit={this.handleTextQtySubmit}
                  item={availableSizes}
                  isMultisize={item.IsMultisize}
                  size={
                    item.IsMultisize
                      ? `${item.size.trim()} ${
                          item.sizeType || selectedSizeType
                        }`
                      : item.size || ""
                  }
                  sizeType={item.sizeType || selectedSizeType}
                  quantity={item.quantity || 1}
                  translation={translation}
                  productTranslation={productTranslation}
                  openQuantity={openQuantity}
                  handleQtyModal={this.handleQtyModal}
                  openMultipleSizes={openMultipleSizes}
                  handleMultipleSizesModal={this.handleMultipleSizesModal}
                  remainingSizeStock={this.remainingSizeStock}
                  onBlurChange={this.onBlurChange}
                  itemQuantityChanged={product._OutOfStock}
                  remainingItemStock={remainingItemStock}
                  allowedQuantity={allowedQuantity}
                />
                {!isOutOfStock && item.prevValue > item.quantity && (
                  <span className="remaining_stock">
                    {translation.theRemainingStockForThisItem}{" "}
                    {(itemStockLeft && itemStockLeft.quantity) ||
                      item.prevValue}{" "}
                    {translation.to} {remainingItemStock}{" "}
                    {translation.lastSymbol}
                  </span>
                )}
                {item && !item.isReturn && item.isReturn === false && (
                  <p className="returnItem">{translation.itemCannotReturn}</p>
                )}
              </>
            )}

            <AddItemToWishlistWeb
              item={item}
              isAvailableInCountry={isAvailableInCountry}
              translation={translation}
              isWishListed={this.isWishListed}
              handleAddToWishList={this.handleAddToWishList}
              removeCartItem={this.removeCartItem}
            />
          </div>
        </div>

        <AddItemToWishlistMob
          item={item}
          translation={translation}
          isWishListed={this.isWishListed}
          handleAddToWishList={this.handleAddToWishList}
          removeCartItem={this.removeCartItem}
        />

        <div className="cross-icon" onClick={this.removeCartItem} />
      </div>
    );
  }
}

const getQuantity = productObj => {
  const productSizeLabel = productObj && productObj.size;

  if (productSizeLabel === "") {
    return get(productObj, "colors.colorSizeOptions[0].sizeStock", 0);
  }
  if (productObj.IsMultisize) {
    return getQuantityForMultisizeItem(productObj, productSizeLabel);
  }

  const productInfoBySizeStock = get(
    productObj,
    "colors.colorSizeOptions",
    []
  ).find(colorSizeOption => colorSizeOption.sizeLabel === productObj.size);

  return productInfoBySizeStock ? productInfoBySizeStock.sizeStock : 0;
};

const getQuantityForMultisizeItem = (item, productSizeLabel) => {
  const availableSizes = item.colors.colorSizeOptions.filter(
    option => option.sizeLabel
  );
  const internationalSizes = getInternationalSizes(availableSizes, {});

  const colorSizeOptions = getColorSizeOptions(
    internationalSizes,
    item.sizeType,
    availableSizes
  );

  const stockQty = colorSizeOptions.find(
    item => item.sizeLabel.trim() === productSizeLabel.trim()
  );

  return stockQty ? stockQty.sizeStock : 0;
};

const mapStateToProps = state => ({
  selectedSizeType: state.common.selectedSizeType,
  configCdnImagesSettings: selectCdnImageSettings(state)
});

const mapDispatchToProps = {
  resetProductMerge
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ItemsInCart)
);
