import head from "lodash/head";

import { BEConfig } from "../config/env";
import { getState } from "../redux/store";
import qs from "query-string";

import { selectRouteSlug } from "../util/selectors";
import { webapiPost, webapiPut } from "../webapis/core";
import ApiService from "./ApiService";
import { PAYMENT_GATEWAYS, TAMARA_PAYMENT_ID, VOUCHER } from "../constants";

class TamaraService extends ApiService {
  startSession = async ({
    order,
    orderBody,
    user,
    currency,
    countryShort,
    orderRecord,
    shippingAddress,
    language
  }) => {
    const {
      checkoutApi: { tamaraSession }
    } = BEConfig;

    const sessionData = this.getSessionData({
      order,
      orderBody,
      user,
      currency,
      countryShort,
      orderRecord,
      shippingAddress,
      language
    });

    const response = await webapiPost(
      this.getAccessToken(),
      `${this.getBaseUrl()}${tamaraSession}`,
      sessionData,
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  };

  async verifyPayment({ orderId }) {
    const response = await webapiPut(
      this.getAccessToken(),
      `${this.getBaseUrl()}${BEConfig.checkoutApi.tamaraAuthorize}`,
      { orderId },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getSessionData({
    order,
    orderBody,
    user,
    currency,
    countryShort,
    orderRecord,
    shippingAddress,
    language
  }) {
    return {
      order_reference_id: String(orderRecord.orderId),
      total_amount: {
        amount: orderBody.transactionOrderTotal,
        currency
      },
      description: "BFL store order",
      country_code: countryShort,
      payment_type: head(order.selectedPayment),
      locale: language === "en" ? "en_US" : "ar_SA",
      items: order.orderItems.map(item => ({
        reference_id: String(item.id),
        type: "Physical",
        name: item.title,
        sku: String(item.id),
        item_url: item.url,
        quantity: item.quantity,
        unit_price: {
          amount: item.currentPrice,
          currency
        },
        total_amount: {
          amount: (item.quantity * item.currentPrice).toFixed(2),
          currency
        }
      })),
      consumer: order.continueAsGuest
        ? {
            email: order.guestEmail,
            first_name: order.address.firstname,
            last_name: order.address.lastname,
            phone_number: `+${order.address.phone}`
          }
        : {
            email: user.email,
            first_name: user.firstname,
            last_name: user.lastname,
            phone_number: `+${user.phone}`
          },
      shipping_address: {
        first_name: shippingAddress.firstname,
        last_name: shippingAddress.lastname,
        line1: shippingAddress.addressLine1,
        city: shippingAddress.city,
        country_code: shippingAddress.countryCode,
        phone_number: shippingAddress.phone
      },
      tax_amount: {
        amount: orderBody.codFee,
        currency
      },
      shipping_amount: {
        amount: orderBody.shippingCharges,
        currency
      },
      ...(orderBody.paymentMode.includes(VOUCHER) && {
        discount: {
          name: "Voucher",
          amount: {
            amount: orderBody.transactionDiscount,
            currency: currency
          }
        }
      }),
      merchant_url: this.getMerchantUrls(orderRecord, orderBody)
    };
  }

  getMerchantUrls(orderRecord, orderBody) {
    const state = getState();
    const routeSlug = selectRouteSlug(state);
    const originLocation = window.location.origin;
    const { countryId } = state.common.settings;
    const { _id } = state.checkoutReducer.checkoutData;

    const successPageUrl = `${originLocation}/${routeSlug}/order-success/${
      orderRecord?.orderId
    }?${qs.stringify({
      continueAsGuest: orderBody.continueAsGuest || "",
      countryId,
      currencyCode: orderBody.paymentCurrency,
      paymentGateWay: PAYMENT_GATEWAYS.TAMARA,
      paymentToken: orderRecord.paymentToken,
      transactionOrderTotal: orderBody.transactionOrderTotal,
      paymentReferenceNo: orderRecord.paymentToken
    })}`;

    const failedPageUrl = `${originLocation}/${routeSlug}/payment-error`;
    const cancelUrl = `${originLocation}/${routeSlug}/checkout/${_id}`;

    return {
      success: successPageUrl,
      failure: failedPageUrl,
      cancel: cancelUrl
    };
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo, checkoutHandle } =
      BEConfig.checkoutApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
    return url;
  }
}

const instance = new TamaraService();

export default instance;
