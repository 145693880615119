import React from "react";
import cn from "classnames";
import { MEMBERSHIP_STYLE_CLASS } from "../../../constants/loyalty-points";
import blackStar from "../../../routes/my-account/images/black-star.svg";

const LoyaltyProgressBar = ({
  availablePoints,
  maxTierPoints,
  stepsPerTierAmount,
  memberLevel
}) => {
  const progressRatio = availablePoints / maxTierPoints;
  // const filledStepIndex = Math.floor(progressRatio * 10) - 1;
  const filledStepIndex =
    (availablePoints * stepsPerTierAmount) / maxTierPoints;
  const progressStarsRow = Array.from(Array(stepsPerTierAmount - 1).keys()).map(
    (_, index) => (
      <div
        key={index}
        className={cn("star-icon", {
          filled: index + 1 <= filledStepIndex
        })}
        style={{
          left: `${Math.floor(((index + 1) * 100) / stepsPerTierAmount) - 1}%`
        }}
      />
    )
  );

  return (
    <div className="progress-container">
      <div className={`progress-bar ${MEMBERSHIP_STYLE_CLASS[memberLevel]}`}>
        {progressStarsRow}
        <div
          className="progress-bar-fill"
          style={{ width: `${Math.floor(100 * progressRatio)}%` }}
        />
      </div>
      <img src={blackStar} alt="black-loyalty-star" />
    </div>
  );
};

export default LoyaltyProgressBar;
