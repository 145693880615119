import React from "react";
import cn from "classnames";
import { isArabic } from "../../util";
import SupportImg from "../../images/support.svg";

const NeedHelpFooter = ({
  needHelpTranslation,
  helpDataTiles,
  staticContent,
  language,
  settings
}) => (
  <div className="help_text">
    <div>
      <p className={cn({ rtl: isArabic(language) })}>
        {needHelpTranslation.stillNeedHelp}
      </p>
      <h4>{needHelpTranslation.wereHereToHelp}</h4>
    </div>
    <div className="help_options">
      {helpDataTiles &&
        helpDataTiles.map((listItem, index) => {
          return listItem.onClick ? (
            <button onClick={listItem.onClick} key={index}>
              <div
                className={listItem.class}
                style={{
                  backgroundColor: listItem.backgroundColor,
                  color: listItem.color
                }}
              >
                <span />
                <h6>{needHelpTranslation[listItem.data]}</h6>
              </div>
            </button>
          ) : (
            <a
              href={
                listItem.redirectionLink
                  ? listItem.redirectionLink
                  : `/${language}-${
                      (settings &&
                        settings.countrySHORT &&
                        settings.countrySHORT.toLowerCase()) ||
                      "ae"
                    }${listItem.link}`
              }
              key={index}
              target={listItem.target ? "" : "_blank"}
            >
              <div
                className={listItem.class}
                style={{
                  backgroundColor: listItem.backgroundColor,
                  color: listItem.color
                }}
              >
                <span />
                <h6>{needHelpTranslation[listItem.data]}</h6>
              </div>
            </a>
          );
        })}
    </div>
    <div className="support_details">
      <a href={`tel:${staticContent && staticContent.csNumber}`}>
        <img src={SupportImg} alt="support" />
        <span>{staticContent && staticContent.csNumber}</span>
      </a>
      <p>{staticContent && staticContent.csTime}</p>
    </div>
  </div>
);
export default NeedHelpFooter;
