import isArray from "lodash/isArray";
import { BEConfig } from "../../config/env";
import {
  fetchWebApi,
  putDashboardWebApi,
  deleteWebApi
} from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  getVisitorId
} from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import { languageFromPathName } from "../../util";
import {
  GET_WISHLISTS,
  SET_WISHLISTS_BASE_DATA,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_PAGINATION,
  UPDATE_WISHLISTS,
  DELETE_WISHLIST,
  EMPTY_WISHLIST_CART,
  SELECTED_SIZE,
  WISHLIST_BASE_DATA_LOADING,
  DELETE_WISHLIST_ITEM_LOADING
} from "../constants";
import { deleteWishListGeneralLoading } from "./cart.action";

export const getWishList = wishList => ({
  type: GET_WISHLISTS,
  wishList
});
export const setWishListBaseData = wishListBaseData => ({
  type: SET_WISHLISTS_BASE_DATA,
  wishListBaseData
});
export const setWishListPagination = pagination => ({
  type: SET_WISHLIST_PAGINATION,
  pagination
});

export const setWishListLength = length => ({
  type: SET_WISHLIST_LENGTH,
  length
});

export const updateWishList = wishList => ({
  type: UPDATE_WISHLISTS,
  wishList
});

export const deleteWishList = (productId, id) => ({
  type: DELETE_WISHLIST,
  productId,
  id
});

export const wishListBaseDataLoading = () => ({
  type: WISHLIST_BASE_DATA_LOADING
});

export const deleteWishListItemLoading = () => ({
  type: DELETE_WISHLIST_ITEM_LOADING
});

export const emptyWishListAndCart = () => ({ type: EMPTY_WISHLIST_CART });

export const deleteWishListData = (productId, id = "") => {
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, wishListPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}/${productId}/${
    id || ""
  }`;
  return (dispatch, getState) => {
    dispatch(deleteWishListGeneralLoading());
    return deleteWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data.success) {
          dispatch(deleteWishList(productId, id));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const getWishListBaseData = (language, loader = true) => {
  language = languageFromPathName(language);
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, wishListPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}/?q=list`;
  return (dispatch, getState) => {
    loader && dispatch(showLoadingIndicator());
    dispatch(wishListBaseDataLoading());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        loader && dispatch(hideLoadingIndicator());
        if (response.data && isArray(response.data)) {
          dispatch(setWishListBaseData(response.data));
          dispatch(setWishListLength(response.data.length));
        } else {
          dispatch(setWishListBaseData([]));
          dispatch(setWishListLength(0));
        }
        return response;
      })
      .catch(error => {
        loader && dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchWishListData = (language, pageQuery, loader = true) => {
  language = languageFromPathName(language);
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, wishListPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}${
    pageQuery ? "/" + pageQuery : ""
  }`;
  return (dispatch, getState) => {
    loader && dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        loader && dispatch(hideLoadingIndicator());
        if (response.data) {
          const { totalItems, ...pagination } = response.data.pagination;
          dispatch(getWishList(response.data.wishlist));
          dispatch(setWishListPagination(pagination));
          dispatch(setWishListLength(totalItems));
        }
        return response;
      })
      .catch(error => {
        loader && dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const saveToWishList = (
  bodyData,
  updateReducer = true,
  loader = true
) => {
  const apiURL = `${BEConfig.wishListApi.protocol}${BEConfig.wishListApi.baseURL}${BEConfig.wishListApi.port}${BEConfig.wishListApi.versionInfo}${BEConfig.wishListApi.wishListPageHandle}`;

  return (dispatch, getState) => {
    loader && dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      bodyData,
      getVisitorId()
    )
      .then(response => {
        if (response.status === 200 && response.data) {
          if (updateReducer) dispatch(updateWishList(bodyData));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        loader && dispatch(hideLoadingIndicator());
      });
  };
};

export const selectedSize = (
  selectedSizeValue,
  productId,
  sizeId,
  sizeType,
  _id
) => {
  return {
    type: SELECTED_SIZE,
    selectedSizeValue,
    productId,
    sizeId,
    sizeType,
    _id
  };
};
