import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import classnames from "classnames";

import { AnalyticService, TamaraService } from "../../services";

import {
  handleCardAddition,
  toggleAddNewCardTab
} from "../../redux/actions/myAccount.action";
import {
  handleTextChange,
  resetSavedCard
} from "../../redux/actions/payment.action";
import {
  clearBinCode,
  setSummaryRecalculationInProgress
} from "../../redux/actions/checkout.action";

import * as priceUtil from "../../util/priceUtil";

import ModalBFL from "../modal/modal";
import { Image } from "../../components/image";
import VoucherList from "../voucher/voucher-list";

import PaymentMethodList from "./PaymentMethodList";
import FooterAcceptMoney from "../footer/footer-accept-money";

import CBDPopup from "../../images/payment.png";
import visaCardImg from "../../images/visa_card.png";
import masterCardImg from "../../images/master_card.png";
import maestroImg from "../../images/maestro_card.png";
import genericImage from "../../images/generic_third_card.png";
import infoIcon from "../../images/info_icon_filled.svg";
import Tooltip from "../generalElements/tooltip";
import { MAX_CIVIL_ID_LENGTH, MIN_CIVIL_ID_LENGTH } from "../../constants";

class CheckoutPayment extends Component {
  state = {
    openSection: false,
    addNewCard: false,
    paymentMethod: false,
    selectedDefaultCard: "",
    selectedMDefaultCard: "",
    showCvv: true,
    cardAlreadyExists: false,
    toggleViewMore: true,
    showViewMore: true,
    viewMoreHeight: 0,
    voucherContainerRef: "",
    voucherInputValue: "",
    showCivilIdModal: false
  };

  componentDidMount() {
    const { showCvv } = this.state;

    const isResponsive = window.innerWidth > 1024;
    if (isResponsive) {
      this.setState({ showCvv: !showCvv });
    }

    this.handleViewMore();
  }

  componentDidUpdate(prevProps) {
    this.handleViewMore();
    const { isVoucherValid, order, binNo, checkoutTranslation } = this.props;

    const isVoucherDifferent =
      prevProps.order.voucherCode !== order.voucherCode;

    if (prevProps.isVoucherValid !== isVoucherValid && isVoucherValid) {
      const voucherInputValue = binNo ? "" : order.voucherCode;

      this.setState({ voucherInputValue });
    }
    const isVoucherWasCleared =
      isVoucherDifferent && !order.voucherCode && !order.voucherNo;
    const isSelectedVoucherInvalid = isVoucherDifferent && !isVoucherValid;

    if (isVoucherWasCleared || isSelectedVoucherInvalid) {
      this.setState({ voucherInputValue: "" });
    }

    if (!prevProps.order.step2Completed && order.step2Completed) {
      AnalyticService.checkout.trackCheckoutStepFlow({
        step: 3,
        name: checkoutTranslation.payment
      });
    }
  }

  handleViewMore() {
    const { viewMoreHeight } = this.state;

    const pHeight = get(this, "wrapperRef.clientHeight", 0);

    if (viewMoreHeight !== pHeight) {
      this.setState({
        viewMoreHeight: pHeight,
        showViewMore: pHeight > 110
      });
    }
  }

  toggleChange = () => {
    this.setState(state => ({ openSection: !state.openSection }));
  };

  changeRestFields = (name, value) => {
    const { handleTextChange } = this.props;
    if (name === "cvv") {
      const onlyNumbers = /^[0-9]{0,3}$/;
      if (onlyNumbers.test(value)) {
        handleTextChange(name, value);
      }
    } else {
      handleTextChange(name, value);
    }

    return false;
  };

  handleAddNewCardModal = value => {
    const { handleSelectedSavedCard, handleMSelectedSavedCard } = this.props;
    const { selectedDefaultCard, selectedMDefaultCard } = this.state;

    this.setState({ addNewCard: value, cardAlreadyExists: false });
    if (value) {
      handleSelectedSavedCard("");
      handleMSelectedSavedCard("");
    } else {
      handleSelectedSavedCard(selectedDefaultCard);
      handleMSelectedSavedCard(selectedMDefaultCard);
    }
  };

  handleSelectedCard = id => {
    this.setState({ selectedDefaultCard: id });
  };

  handleMSelectedCard = cardNo => {
    this.setState({ selectedMDefaultCard: cardNo });
  };

  handleNewAddress = value => {
    const { toggleAddNewCardTab, resetSavedCard } = this.props;

    toggleAddNewCardTab(value);
    if (!value) {
      resetSavedCard();
    }
  };

  handleSaveCard = () => {
    const {
      payment,
      customerId,
      handleTextChange,
      resetSavedCard,
      savedCardList,
      handleCardAddition
    } = this.props;
    const errorKeys = Object.keys(payment.errors);
    errorKeys.forEach(key => handleTextChange(key, payment[key]));

    const paymentKeys = errorKeys.filter(
      key => key !== "cardName" && key !== "cvv"
    );
    const isValid = paymentKeys.every(key => {
      const error = payment.errors[key];

      return error.valid && error.touched;
    });

    if (!isValid) return;

    payment.customerId = customerId;
    payment.isVerified = false;

    delete payment.errors;
    delete payment.cvv;

    const cardAlreadyExists = (savedCardList || []).some(
      card => card.cardNumber === payment.cardNumber
    );

    if (!cardAlreadyExists) {
      this.setState({ cardAlreadyExists: false });
      handleCardAddition(payment);
      this.handleNewAddress(false);
      this.handleAddNewCardModal(false);
      resetSavedCard();
    } else {
      this.setState({ cardAlreadyExists: true });
    }
  };

  handleCardSelection = (e, value) => {
    e.stopPropagation();
    this.setState({ paymentMethod: value });
  };

  voucherDivRef = element => {
    if (element) {
      this.wrapperRef = element.children[0].children[0].children[1];
    }
  };

  handleToggleViewMore = () =>
    this.setState(state => ({ toggleViewMore: !state.toggleViewMore }));

  getCardImageFromCardName = cardName => {
    if (["master_card", "MasterCard"].includes(cardName)) {
      return masterCardImg;
    } else if (["visa_card", "VisaCard", "Visa"].includes(cardName)) {
      return visaCardImg;
    } else if (["maestro_card", "MaestroCard"].includes(cardName)) {
      return maestroImg;
    }

    return genericImage;
  };

  getCardTranslationFromCardName = cardName => {
    const { checkoutTranslation } = this.props;

    if (["master_card", "MasterCard"].includes(cardName)) {
      return checkoutTranslation.masterCard;
    } else if (["visa_card", "VisaCard", "Visa"].includes(cardName)) {
      return checkoutTranslation.visa;
    }

    return "";
  };

  handleToggleModal = () =>
    this.setState({ isModalShow: !this.state.isModalShow });

  onApplyVoucher = async e => {
    const { changeVoucher, applyVoucher } = this.props;
    const { voucherInputValue } = this.state;

    await changeVoucher({}, voucherInputValue);
    await applyVoucher(e);
  };

  setShowCivilIdModal = showCivilIdModal => {
    this.setState({ showCivilIdModal });
  };

  render() {
    const {
      open,
      order,
      paymentOptions,
      changeSelectedPayment,
      checkoutTranslation,
      applyVoucher,
      changeVoucher,
      wallet,
      currency,
      changeUseMyWallet,
      language,
      isVoucherValid,
      isSameVoucherCode,
      isVoucherEmpty,
      VoucherCodes,
      inValidVoucherMessage,
      walletCapable,
      onPaymentOptionChange,
      countryId,
      _selectedPaymentOption,
      wrapperRef,
      totalAmount,
      binNo,
      clearBinCode,
      codFee,
      changeVoucherStatus,
      setSummaryRecalculationInProgress,
      handleVoucherAppliedMessage,
      resetPaymentStep,
      preApplyVoucher,
      getOrderBody,
      deliveryCharges,
      commonSettings,
      isPersonalIdRequired,
      personalId,
      changePersonalId,
      isPersonalId,
      configurations
    } = this.props;
    const {
      addNewCard,
      paymentMethod,
      showCvv,
      cardAlreadyExists,
      toggleViewMore,
      showViewMore,
      isModalShow,
      voucherInputValue,
      showCivilIdModal
    } = this.state;

    const countryWallet = get(wallet, "walletAmount", []).find(
      amount =>
        amount.currencyCode === currency && amount.countryId === countryId
    );

    const isWalletAvailable =
      countryWallet && countryWallet.transactionAmount > 0;

    const selectedPaymentGateway = get(
      _selectedPaymentOption,
      "_paymentGateway",
      ""
    );

    const _VoucherCodes =
      VoucherCodes &&
      VoucherCodes.filter(
        codes => codes.expiryDate >= new Date().toISOString()
      );

    const isPersonalIdValid =
      personalId.length >= MIN_CIVIL_ID_LENGTH &&
      personalId.length <= MAX_CIVIL_ID_LENGTH;

    return (
      <div className="checkout_steps paymentDetails" ref={wrapperRef}>
        <div className="steps_heading">
          <h4
            className={classnames({
              notOpened: !order.step2Completed,
              noWallet: !countryWallet
            })}
          >
            {order.step3Completed && <span className="green_round_check" />}
            <span className="serialNo">3.</span> {checkoutTranslation.payment}
          </h4>

          {order.step2Completed && countryWallet && isWalletAvailable && (
            <p className="info_alert useWalletAmount checkbox_wrap checkoutDefault">
              <input
                type="checkbox"
                checked={order.useMyWallet}
                className="custom_checkbox"
                name="default"
                id="payment-extra-points"
                onClick={changeUseMyWallet}
              />
              <label className="checkbox_value" htmlFor="payment-extra-points">
                {checkoutTranslation.useThe}{" "}
                <span>
                  {countryWallet.currencyCode}{" "}
                  {priceUtil.shapePriceWithComma(
                    countryWallet.transactionAmount
                  )}
                </span>{" "}
                {checkoutTranslation.fromMyWallet}
              </label>
            </p>
          )}
        </div>
        {open && (
          <div className="steps_content checkout_payment_wrap">
            <div className="inner_content checkout_payment">
              {countryWallet && isWalletAvailable && (
                <p className="info_alert checkbox_wrap">
                  <input
                    type="checkbox"
                    checked={order.useMyWallet}
                    className="custom_checkbox"
                    name="default"
                    id="payment-extra-points-web"
                    onClick={changeUseMyWallet}
                  />
                  <label
                    className="checkbox_value"
                    htmlFor="payment-extra-points-web"
                  >
                    {checkoutTranslation.useThe}{" "}
                    <span>
                      {countryWallet.currencyCode}{" "}
                      {priceUtil.shapePriceWithComma(
                        countryWallet.transactionAmount
                      )}
                    </span>{" "}
                    {checkoutTranslation.fromMyWallet}
                  </label>
                </p>
              )}

              {!walletCapable && order && (
                <PaymentMethodList
                  totalAmount={totalAmount}
                  paymentOptions={paymentOptions}
                  selectedPaymentGateway={selectedPaymentGateway}
                  onPaymentOptionChange={onPaymentOptionChange}
                  changeSelectedPayment={changeSelectedPayment}
                  translation={checkoutTranslation}
                  // TODO: remove the props below after removing CCAvenue
                  addNewCard={addNewCard}
                  cardAlreadyExists={cardAlreadyExists}
                  paymentMethod={paymentMethod}
                  showCvv={showCvv}
                  getCardImageFromCardName={this.getCardImageFromCardName}
                  getCardTranslationFromCardName={
                    this.getCardTranslationFromCardName
                  }
                  handleCardSelection={this.handleCardSelection}
                  handleAddNewCardModal={this.handleAddNewCardModal}
                  changeRestFields={this.changeRestFields}
                  handleSaveCard={this.handleSaveCard}
                  handleSelectedCard={this.handleSelectedCard}
                  handleMSelectedCard={this.handleMSelectedCard}
                  applyVoucher={applyVoucher}
                  codFee={codFee}
                  changeVoucherStatus={changeVoucherStatus}
                  handleVoucherAppliedMessage={handleVoucherAppliedMessage}
                  resetPaymentStep={resetPaymentStep}
                  currency={currency}
                  preApplyVoucher={preApplyVoucher}
                  getOrderBody={getOrderBody}
                  deliveryCharges={deliveryCharges}
                />
              )}

              {isPersonalIdRequired && (
                <div className="personalId_container">
                  <div
                    className={classnames(
                      "mb-lg-3 mb-2 d-flex align-items-center",
                      {
                        "font-weight-bold": !language.includes("ar")
                      }
                    )}
                  >
                    <span className="error_color">*</span>
                    {checkoutTranslation.civilId}

                    <div className="position-relative d-flex align-items-center">
                      <Tooltip
                        triggerComponent={props => (
                          <img
                            className="mx-2 info_icon d-none-resp"
                            src={infoIcon}
                            alt="Civil ID"
                            {...props}
                          />
                        )}
                      >
                        <h6>{configurations?.messages?.civilIdPopupTitle}</h6>
                        <p>{configurations?.messages?.civilIdPopupContent}</p>
                      </Tooltip>
                      <img
                        className="mx-2 info_icon d-none-web"
                        src={infoIcon}
                        alt="Civil ID"
                        onClick={() => {
                          this.setShowCivilIdModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    value={personalId}
                    className={classnames("form-control", {
                      invalid: isPersonalId && !isPersonalIdValid
                    })}
                    placeholder={checkoutTranslation.enterIdNumber}
                    onChange={e => {
                      let inputValue = e.target.value;
                      let digitOnly = /^\d+$/;
                      if (digitOnly.test(inputValue) || inputValue === "") {
                        changePersonalId(e);
                      }
                    }}
                  />

                  {isPersonalId && !isPersonalIdValid && (
                    <span className="error error_color">
                      <span>*</span> {checkoutTranslation.errorPersonalId}
                    </span>
                  )}
                  <ModalBFL
                    showModal={showCivilIdModal}
                    modalClass={classnames("generic_modal civil_id_modal", {
                      arabic: language.includes("ar")
                    })}
                    closeModal={() => this.setShowCivilIdModal(false)}
                  >
                    <div className="civil_id_modal_body">
                      <h6>{configurations?.messages?.civilIdPopupTitle}</h6>
                      <p>{configurations?.messages?.civilIdPopupContent}</p>
                    </div>
                    <button
                      className="cross_btn"
                      onClick={() => this.setShowCivilIdModal(false)}
                    >
                      &times;
                    </button>
                  </ModalBFL>
                </div>
              )}

              <div className="voucher_code">
                <p>{checkoutTranslation.haveADiscount}</p>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      value={voucherInputValue}
                      className="form-control"
                      placeholder={checkoutTranslation.enterVoucherCode}
                      onChange={e =>
                        this.setState({ voucherInputValue: e.target.value })
                      }
                      aria-label="Voucher code"
                    />
                    <input
                      type="button"
                      value={checkoutTranslation.apply}
                      className={classnames("round_btn", "submit_voucher", {
                        disable_voucher_btn: !order
                      })}
                      disabled={!order || !voucherInputValue}
                      onClick={this.onApplyVoucher}
                    />
                    {!binNo && (
                      <>
                        {!isSameVoucherCode && isVoucherValid !== null && (
                          <p
                            className={classnames(
                              "applyVoucher_msg",
                              isVoucherValid ? "applied" : "invalid"
                            )}
                          >
                            {isVoucherValid
                              ? checkoutTranslation.validVoucher
                              : inValidVoucherMessage ||
                                checkoutTranslation.invalidVoucher}
                          </p>
                        )}
                        {isVoucherEmpty && (
                          <p className="applyVoucher_msg invalid">
                            {checkoutTranslation.EmptyVoucher}
                          </p>
                        )}
                        {isSameVoucherCode && (
                          <p className="applyVoucher_msg invalid">
                            {checkoutTranslation.voucherApplied}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
              <ModalBFL
                showModal={isModalShow}
                modalClass={`generic_modal payment_option_modal ${
                  language.includes("ar-") || language.includes("ar")
                    ? "arabic"
                    : ""
                }`}
              >
                <div class="cbd-icon">
                  <Image src={CBDPopup} atl="cash before delivery" />
                </div>
                <div class="cbd-content">
                  <h2>{checkoutTranslation.CBD}</h2>
                  <ul
                    className={classnames(
                      "cbd-steps",
                      language.includes("ar") ? "arabic" : "english"
                    )}
                  >
                    <li>{checkoutTranslation.paymentPopup1}</li>
                    <li>{checkoutTranslation.paymentPopup2}</li>
                    <li>{checkoutTranslation.paymentPopup3}</li>
                  </ul>
                </div>

                <button className="cross_btn" onClick={this.handleToggleModal}>
                  &times;
                </button>
              </ModalBFL>
              {VoucherCodes && VoucherCodes.length > 0 && (
                <div className="available_promo_codes" ref={this.voucherDivRef}>
                  <VoucherList
                    clearBinCode={clearBinCode}
                    translation={checkoutTranslation}
                    VoucherCodes={_VoucherCodes}
                    applyVoucher={applyVoucher}
                    changeVoucher={changeVoucher}
                    showViewMore={showViewMore}
                    toggleViewMore={toggleViewMore}
                    handleToggleViewMore={this.handleToggleViewMore}
                    styleObj={{
                      maxHeight: "100%"
                    }}
                    order={order}
                    language={language}
                    isVoucherValid={isVoucherValid}
                    setSummaryRecalculationInProgress={
                      setSummaryRecalculationInProgress
                    }
                  />
                </div>
              )}
            </div>
            <div className="m_payment_options_checkout">
              <FooterAcceptMoney
                defaultCountry={commonSettings}
                translation={checkoutTranslation}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customerId: state.authReducer.customerId,
  payment: state.paymentReducer,
  addNewCardTab: state.myAccountReducer.addNewCardTab,
  binNo: state.checkoutReducer.binNo,
  commonSettings: state.common.settings,
  configurations: state.common.configSettings
});

const mapStateToDispatch = {
  handleTextChange,
  resetSavedCard,
  toggleAddNewCardTab,
  handleCardAddition,
  clearBinCode,
  setSummaryRecalculationInProgress
};

export default connect(mapStateToProps, mapStateToDispatch)(CheckoutPayment);
