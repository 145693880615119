import React from "react";
import cn from "classnames";

export const ModalMediumCard = React.memo(({ selected, title, image }) => (
  <div className="quick-filter-card-wrapper">
    <div
      className="modal-medium-card"
      style={{
        backgroundImage: `url(${image})`
      }}
    >
      <div className={cn("checkbox-icon", { selected })} />
      <div className="medium-card-description mobile-only">
        <h3>{title}</h3>
      </div>
    </div>
    <div className="medium-card-description">
      <h3>{title}</h3>
    </div>
  </div>
));
