import React from "react";
import cn from "classnames";

export const ProgressBar = React.memo(({ filterStep, maxStep, isMob }) => (
  <div className="qf-progress-bar-wrapper">
    <div
      className={cn("qf-progress-bar", {
        "qf-progress-bar-mobile": isMob
      })}
    >
      {Array.from({ length: maxStep }).map((elem, index) => (
        <div
          key={index}
          className={cn("progress-bar-lines", {
            active: filterStep - 1 === index
          })}
        />
      ))}
    </div>
    {!isMob && (
      <>
        {filterStep} / {maxStep}
      </>
    )}
  </div>
));
