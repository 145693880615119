import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import crossIcon from "../../../images/cross_black_icon.svg";
import { isMobile } from "../../../util";
import { selectCountryShort } from "../../../util/selectors";
import { hideLoaders, showLoaders } from "../../../redux/actions/common.action";
import { TAMARA_BASE_URL, TAMARA_PUBLIC_KEY } from "../TamaraWidget";

const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: isMobile.any() ? "90%" : "37%",
    height: "65%"
  }
};

const tamaraWidgetIframeUrl = `${TAMARA_BASE_URL}/widget-v2/tamara-widget.html`;

export const TamaraInfoModal = ({ modalOpen, onRequestClose, language }) => {
  const country = useSelector(selectCountryShort);
  const dispatch = useDispatch();
  const src = qs.stringifyUrl({
    url: tamaraWidgetIframeUrl,
    query: {
      lang: language,
      country,
      public_key: TAMARA_PUBLIC_KEY
    }
  });
  useEffect(() => {
    dispatch(showLoaders());
  }, []);

  return (
    <Modal
      isOpen={modalOpen}
      style={style}
      onRequestClose={onRequestClose}
      id="tamara-info-modal"
    >
      <img
        src={crossIcon}
        alt="cross-icon"
        className="cross-icon"
        onClick={onRequestClose}
      />
      <iframe
        width="100%"
        height="100%"
        src={src}
        style={{ border: "none", overflow: "none" }}
        onLoad={() => {
          dispatch(hideLoaders());
        }}
      />
    </Modal>
  );
};
