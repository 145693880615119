import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Image } from "../../image";
import GroupCategories from "./group-categories";

class ListComponent extends Component {
  state = {
    noGroup: [],
    groupData: {},
    selectedGroup: []
  };
  UNSAFE_componentWillMount() {
    const { data, isLevel2 } = this.props;
    if (isLevel2) {
      this.setData(data);
    } else {
      this.setState({ noGroup: data, groupData: {} });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLevel2) {
      this.setData(nextProps.data);
    } else {
      this.setState({ noGroup: nextProps.data, groupData: {} });
    }
  }

  setData = data => {
    const groupData = {};
    const noGroup = [];
    data.forEach(item => {
      if (item.groupBy) {
        groupData[item.groupBy] = groupData[item.groupBy]
          ? [...groupData[item.groupBy], item]
          : [item];
      } else {
        noGroup.push(item);
      }
    });
    this.setState({ groupData, noGroup });
  };

  render() {
    const {
      handleClick,
      header,
      backBtnClick,
      isLevel2,
      page3Title,
      carousalList
    } = this.props;
    const { noGroup, groupData } = this.state;
    const groupDataKeys = groupData && Object.keys(groupData);
    return (
      <Fragment>
        <Fragment>
          <ul
            className={`sub_categories_wrapper ${
              handleClick ? "slide_layer" : ""
            }`}
          >
            <h3>
              <span className="back_btn" onClick={() => backBtnClick()} />
              {header}
            </h3>
            {isLevel2 &&
              carousalList &&
              !!carousalList.length && (
                <div>
                  <div className="mobile_menu_carousel">
                    <Slider
                      dots
                      infinite
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      arrows={false}
                      adaptiveHeight={false}
                      autoplay
                    >
                      {carousalList &&
                        carousalList.map((item, idx) => (
                          <div key={`${idx}`} className="item_preview_slide">
                            <Image src={item.imageUrl} />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
              )}
            {isLevel2 &&
              noGroup &&
              !!noGroup.length &&
              noGroup.map(item => (
                <li key={item.uid} onClick={() => handleClick(item)}>
                  <span>{item.subTitle}</span>
                  <span className="menu_img">
                    <Image src={item.imageUrl || ""} atl="categories menu" />
                  </span>
                </li>
              ))}
          </ul>
          {!!groupDataKeys.length &&
            groupDataKeys.map(key => (
              <GroupCategories
                key={key}
                title={key}
                dataList={groupData[key]}
                isLevel2={isLevel2}
                handleClick={handleClick}
                backBtnClick={backBtnClick}
              />
            ))}
        </Fragment>
        {!isLevel2 && (
          <GroupCategories
            title={page3Title}
            dataList={noGroup}
            isLevel2={isLevel2}
            handleClick={handleClick}
            backBtnClick={backBtnClick}
          />
        )}
      </Fragment>
    );
  }
}

export default ListComponent;
