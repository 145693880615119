import React, { Component } from "react";
import HtmlParser from "../../components/html-parser";
import { GAService } from "../../services/GA-service";
class HelpAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultContent: props.defaultOpen ? true : false
    };
  }

  toggleContent = contentName => {
    const { accordionHeading = "", tile } = this.props;
    GAService.common.trackSelectFaq({
      category: accordionHeading,
      faqItem: tile,
      isActive: !this.state[contentName]
    });
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));
  };

  render() {
    const { defaultContent } = this.state;
    const { tile, onClick } = this.props;
    return (
      <div className="accordion">
        <div
          className="accordion_card_title"
          onClick={() => {
            onClick && onClick(tile, !defaultContent);
            this.toggleContent("defaultContent");
          }}
        >
          <h4 className="accordion_heading">{tile.longTitle}</h4>
          <span
            className={`toggle-banner ${defaultContent ? "minus" : "plus"}`}
          />
        </div>
        {defaultContent && (
          <div className="accordion_content">
            <HtmlParser html={tile.answer} />
          </div>
        )}
      </div>
    );
  }
}

export default HelpAccordion;
