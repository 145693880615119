export const PRICE_CONVERSION_DECIMAL_VALUE = 2;

export const AVAILABLE_PAYMENT_TYPE = {
  CHECKOUTCOM: "CHECKOUTCOM"
};

export const VOUCHER = "VOUCHER";

export const WALLET = "WALLET";

export const MIN_GRAND_TOTAL_FOR_PERSONAL_ID = 1000;

export const REQUIRED_PERSONAL_ID_LENGTH = 10;

export const DEFAULT_PERSONAL_ID_LENGTH = 0;

export const MIN_CIVIL_ID_LENGTH = 1;
export const MAX_CIVIL_ID_LENGTH = 10;

export const POST_PAY_CURRENCY_MULTIPLIER = 100;

export const DEFAULT_POSTAL_CODE = "00000";

export const TABBY_PAYMENT_STATUS = {
  CLOSED: "CLOSED",
  AUTHORIZED: "AUTHORIZED"
};

export const IS_ORDER_CONFIRMED = "IS_ORDER_CONFIRMED";
export const CONFIRMED_ORDER_ID = "CONFIRMED_ORDER_ID";

export const COUNTRIES_WITH_CIVIL_ID = ["om"];
