import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

class SizeChartPopup extends Component {
  render() {
    const { active, handlePopup, activeTab, popupTitle } = this.props;
    return (
      <div className={`side_sizeChart ${active ? "active" : ""}`}>
        <div
          className={`${active ? "overlay" : ""}`}
          onClick={() => handlePopup(false)}
        />
        <div className={`chart_wrapper ${active ? "active" : ""}`}>
          <div className="popupHeading">
            <p>
              <span>{activeTab}</span> {popupTitle}
            </p>
            <div className="close" onClick={() => handlePopup(false)}>
              &times;
            </div>
          </div>
          <Scrollbars style={{ height: `100vh` }}>
            {this.props.children}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default SizeChartPopup;
