import React, { PureComponent } from "react";
import { withCookies, Cookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import debounce from "lodash/debounce";
import get from "lodash/get";
import classnames from "classnames";
import { preferencesTranslation } from "../../language/userPreferences/en/userPreferences";

import {
  searchContent,
  initSearchContent,
  handleSearchText,
  getSearchTrending,
  getSearchSuggestions
} from "../../redux/actions/search.action";
import { getProductsData } from "../../redux/actions/productlisting.action";

import {
  getProductUrlName,
  decodeURLRecursively,
  getSearchTextFromQuery
} from "../../util";
import { getDataAsObject } from "../../util/common";

import SearchResults from "./searchResults";
import { SyteSearchWithTooltip } from "../syte/syte-button/index";
import AnalyticService from "../../services/analytic-service";
import {
  selectCdnImageSettings,
  selectRouteSlug,
  selectSyteSettings
} from "../../util/selectors";
import { percentageRegEx } from "../../constants";
import { GAService } from "../../services/GA-service";

class Search extends PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    displayResult: false,
    searchHistory: [],
    userPreferencesTranslation: preferencesTranslation
  };
  _isMounted = false;

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { commonSettings, getProductsData, history, language } = nextProps;
    const genericSearch = this.genericSearch;

    const nextSearchText = decodeURLRecursively(nextProps.searchText);
    const currentSearchText = decodeURLRecursively(this.props.searchText);

    const searchTextWasChanged =
      nextSearchText && nextSearchText !== currentSearchText;
    const lastSearchSymbolWasDeleted = currentSearchText && !nextSearchText;

    if (this.props.language !== language && nextSearchText) {
      const country = get(commonSettings, "countrySHORT", "ae").toLowerCase();

      getProductsData(
        null,
        history,
        null,
        nextSearchText,
        language,
        null,
        null,
        null,
        null,
        true,
        country
      );
    }

    if (lastSearchSymbolWasDeleted && genericSearch.value) {
      genericSearch.value = "";
    }

    if (searchTextWasChanged) {
      this.onSearchTextChangeActions(nextSearchText);
      return;
    }

    if (!this._isMounted) return;
  }

  UNSAFE_componentWillMount() {
    const { cookies, expandSection } = this.props;
    this.setState({
      searchHistory: cookies.get("searchHistory") || [],
      displayResult: expandSection
    });
  }

  componentDidMount() {
    const { language } = this.props;

    if (language) {
      this.loadLanguage(language);
    }
    this._isMounted = true;
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(preProp) {
    if (preProp.language !== this.props.language) {
      this.loadLanguage(this.props.language);
    }
  }

  loadLanguage = async language => {
    const response = await import(
      `../../language/userPreferences/${language}/userPreferences`
    );

    this.setState(
      {
        userPreferencesTranslation: response.preferencesTranslation
      },
      () => {
        this.genericSearch.placeholder = get(
          response,
          "preferencesTranslation.searchPlaceHolder"
        );
      }
    );
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ displayResult: false });
    }
  };

  handleSearch = (e, value = "") => {
    e.preventDefault();
  };

  setCookie = cookieData => {
    const { cookies } = this.props;

    cookies.set("searchHistory", cookieData);
    this.setState({ searchHistory: cookieData });
  };

  handleInitialSearch = () => {
    GAService.header.trackSearchInputInteraction();
    const { initSearchContent, getSearchTrending, match, language } =
      this.props;
    const currentLanguage = get(match, "params.language", language);
    const languageParam = currentLanguage.includes("ar") ? "ar" : "en";
    this.setState({ displayResult: true });
    initSearchContent();
    getSearchTrending(languageParam);
  };

  handleTextChange = e => {
    const { isProductListingPage, history, routeSlug, homapageReducer } =
      this.props;

    const targetValue = e.target.value;
    const singleSpacedValue = targetValue.replace(/  +/g, " ").trimStart();
    const isValueWithSpecialChar = percentageRegEx.test(singleSpacedValue);
    if (!isValueWithSpecialChar) {
      this.genericSearch.value = singleSpacedValue;
      this.debouncedSearchTextChangeHandler(singleSpacedValue.trim());
      this.setState({ displayResult: !Boolean(isProductListingPage) });
    }

    if (!targetValue && !homapageReducer.isHomePage) {
      history.push(`/${routeSlug}/`);
    }
  };

  debouncedSearchTextChangeHandler = debounce(value => {
    const { handleSearchText } = this.props;
    handleSearchText(value);
  }, 400);

  onSearchTextChangeActions = value => {
    const {
      getProductsData,
      history,
      location,
      language,
      commonSettings,
      isProductListingPage,
      getSearchSuggestions,
      homapageReducer
    } = this.props;

    const country = get(commonSettings, "countrySHORT", "ae").toLowerCase();
    homapageReducer.isHomePage && getSearchSuggestions(value, language);
    const sameSearchText =
      decodeURLRecursively(value) ===
      decodeURLRecursively(getSearchTextFromQuery(location.search));

    if (isProductListingPage && !sameSearchText) {
      getProductsData(
        null,
        history,
        null,
        value,
        language,
        true,
        null,
        null,
        null,
        true,
        country
      );
    }
  };

  applySearch = value => {
    this._setCookie(value);
    this.moveToProductListing(value.trim());
    this.genericSearch.blur();
  };

  handleKeyPress = e => {
    const eventTargetValue = e.target?.value;
    if (e.key === "Enter" && eventTargetValue) {
      !percentageRegEx.test(eventTargetValue) &&
        this.applySearch(eventTargetValue);
    }
  };

  _setCookie = value => {
    if (!value.trim()) {
      return null;
    }

    const { cookies } = this.props;

    let cookieData;

    const _cookiesStringify = JSON.stringify(cookies.get("searchHistory"));

    if (_cookiesStringify) {
      const _cookiesParsed =
        _cookiesStringify && getDataAsObject(_cookiesStringify);
      cookieData =
        _cookiesParsed.indexOf(value) !== -1
          ? [..._cookiesParsed]
          : [..._cookiesParsed, value.replace(/<\/?[^>]+(>|$)/g, "")];
    } else {
      cookieData = [value];
    }
    this.setCookie(cookieData);
  };

  deleteHistory = (e, deleteItem) => {
    const { searchHistory } = this.state;
    this.setCookie(searchHistory.filter(item => item !== deleteItem));

    if (e) e.stopPropagation();
  };

  moveToProductDetail = (item, search_category = null) => {
    const { history, language, settings, recommendedProducts } = this.props;

    const id = item.id || item.productId;

    this.setState({ displayResult: false });

    const countrySlug = get(settings, "countrySHORT", "ae").toLowerCase();

    GAService.header.trackRecommendedProductSearch({
      search_term: this.genericSearch.value,
      search_category,
      productItem: item,
      number_of_search_results: recommendedProducts.length
    });
    history.push(
      `/${language}-${countrySlug}/${getProductUrlName(
        item.en_title || item.title
      )}/${id}/p/`
    );
  };

  moveToProductListing = (value, search_category = "direct") => {
    const { history, language, handleSearchText, settings, location } =
      this.props;

    this.setState({ displayResult: false });
    this._setCookie(value);
    handleSearchText(decodeURIComponent(value.replace(/<\/?[^>]+(>|$)/g, "")));

    const countrySlug = get(settings, "countrySHORT", "ae").toLowerCase();
    history.push({
      pathname: `/${language}-${countrySlug}/search/`,
      search: `?q=${decodeURIComponent(value.replace(/<\/?[^>]+(>|$)/g, ""))}`,
      state: {
        from: location.pathname,
        search: {
          isSearch: true,
          search_category,
          search_term: this.genericSearch.value
        }
      }
    });

    if (value) {
      this.genericSearch.value = value.replace(/<(.|\n)*?>/g, "").trim();
    }
  };

  clearSearchInput() {
    const { handleSearchText, searchContent } = this.props;

    this.genericSearch.value = "";
    handleSearchText(null);
    searchContent(null);
  }

  clearGenericSearch = () => {
    const { language, history, getProductsData, commonSettings } = this.props;

    const country = get(commonSettings, "countrySHORT", "ae").toLowerCase();

    if (this.genericSearch.value) {
      this.clearSearchInput();

      getProductsData(
        null,
        history,
        null,
        null,
        language,
        null,
        null,
        null,
        null,
        true,
        country
      );
    }
  };

  handleSyteSearch = () => {
    GAService.header.trackSyteSearchInteraction();
    AnalyticService.syte.trackSyteSearchHeader();
  };

  render() {
    const { displayResult, searchHistory, userPreferencesTranslation } =
      this.state;
    const {
      isProductListingPage,
      searchText,
      trending,
      suggestions,
      recommendedProducts,
      setRef,
      settings,
      configCdnImagesSettings,
      configSyteSettings
    } = this.props;

    if (setRef) setRef(this);
    const inputDefaultValue =
      (searchText && decodeURIComponent(searchText)) || "";
    const isCameraSearchEnabled = configSyteSettings?.enableCameraSearch;

    const searchClickBtnHandler = () => {
      if (!percentageRegEx.test(this.genericSearch.value)) {
        displayResult
          ? this.clearGenericSearch()
          : this.genericSearch.value &&
            this.applySearch(this.genericSearch.value);
      }
    };

    return (
      <div
        ref={node => (this.wrapperRef = node)}
        className={classnames("search", displayResult && "expand_search")}
      >
        {isCameraSearchEnabled && (
          <SyteSearchWithTooltip handleClick={this.handleSyteSearch} />
        )}
        <form onSubmit={this.handleSearch}>
          <input
            id="search_input"
            type="text"
            defaultValue={inputDefaultValue}
            onFocus={this.handleInitialSearch}
            onBlur={this.handleBlur}
            onChange={this.handleTextChange}
            onKeyPress={this.handleKeyPress}
            ref={genericSearch => (this.genericSearch = genericSearch)}
            autoComplete="off"
            aria-label="Search"
          />
          <span className="search_icon" onClick={searchClickBtnHandler} />
        </form>
        {displayResult && !isProductListingPage && (
          <div className="searchContainer">
            <div className="search_wrapper">
              <Scrollbars
                className="scroll_search"
                autoHeight
                autoHeightMin={215}
                autoHeightMax={445}
              >
                {searchText && this.genericSearch.value ? (
                  <div className="search_matching_recommended">
                    <div className="search_matching">
                      <h5>{userPreferencesTranslation.matchingKeywords}</h5>
                      <ul>
                        {suggestions.map(item => (
                          <SearchResults
                            key={item.query.value}
                            item={item}
                            moveToProductListing={val =>
                              this.moveToProductListing(
                                val,
                                preferencesTranslation.matchingKeywords
                              )
                            }
                          />
                        ))}
                      </ul>
                    </div>
                    <div className="search_recommended">
                      <h5>{userPreferencesTranslation.recommendedProducts}</h5>
                      <ul>
                        {recommendedProducts.map(item => (
                          <li
                            key={item.id}
                            onClick={() =>
                              this.moveToProductDetail(
                                item,
                                preferencesTranslation.recommendedProducts
                              )
                            }
                          >
                            <p className="search_img">
                              <img
                                src={`${configCdnImagesSettings?.search}${item.captionImageURL}`}
                                alt=""
                              />
                            </p>
                            <p className="search_data">
                              {item.brandName}
                              <span>{item.title}</span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    {searchHistory && (
                      <div className="search_recent_wrap">
                        <h5>{userPreferencesTranslation.recent}</h5>
                        <ul className="search_trending search_recent">
                          {searchHistory
                            .slice(0)
                            .reverse()
                            .map(item => (
                              <li
                                key={item}
                                onClick={() =>
                                  this.moveToProductListing(
                                    item,
                                    preferencesTranslation.recent
                                  )
                                }
                              >
                                {item}
                                <button
                                  className="cross_btn"
                                  onClick={e => this.deleteHistory(e, item)}
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    <h5>{userPreferencesTranslation.trending}</h5>
                    <ul className="search_trending">
                      {trending.map(item => (
                        <li
                          key={item.query.value}
                          onClick={e =>
                            this.moveToProductListing(
                              item.query.value,
                              preferencesTranslation.trending
                            )
                          }
                        >
                          {get(item, "query.value", "")}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  commonSettings: reduxState.common.settings,
  searchResults: reduxState.search.hits,
  trending: reduxState.search.trending || [],
  suggestions: reduxState.search.suggestions || [],
  recommendedProducts: reduxState.search.products || [],
  searchText: reduxState.search.searchText,
  isProductListingPage: reduxState.productListingReducer.isProductListingPage,
  authReducer: reduxState.authReducer,
  language: reduxState.common.language,
  settings: reduxState.common.settings,
  homapageReducer: reduxState.homepage,
  routeSlug: selectRouteSlug(reduxState),
  configCdnImagesSettings: selectCdnImageSettings(reduxState),
  configSyteSettings: selectSyteSettings(reduxState)
});

const mapDispatchToProps = {
  searchContent,
  handleSearchText,
  getProductsData,
  initSearchContent,
  getSearchTrending,
  getSearchSuggestions
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(Search))
);
