export const sendUsEmailTranslation = {
  sendusEmail: "Send Us Email",
  sendUsEmailTitle: "Send us an Email",
  getBackTitleSmall: "We’ll get back to you as soon as we can.",
  questionTitle: "What is your question about?",
  alreadyPlaced: "Already placed the order",
  wantToPlace: "Want to place an order",
  anyCommentsFeedback: "Any other Comments, Feedback",
  moreSpecifically: "And more specifically…",
  notReciviedItem: "Not Received item",
  wrongItemDelivered: "Wrong item Delivered",
  infoItemOerderd: "Information of item ordered",
  receivedDmageItem: "Received Damage item",
  orderId: "Order ID",
  name: "Name",
  email: "Email",
  message: "Message",
  Browse: "Browse",
  BrowseText:
    "In case of damaged products, please attach a picture for the same.",
  send: "Send",
  thankYou: "Thankyou for sending us an email.",
  yourNumberVerified: "We will revert you back shortly.",
  listOfOrder: "List of Orders",
  date: "Date",
  Value: "Value",
  fileFormat: "Image format not supoorted",
  filesize: "File size exceeds 2mb",
  change: "Change",
  imageSelected: "Image Already selected"
};
