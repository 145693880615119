import React, { Component, Fragment } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { BEConfig } from "../../config/env";

import { sleep } from "../../util/common";

import { OtpService } from "../../services";

import { mobileAppLang } from "../../language/mobileAppReference/en/mobileAppReference";

import { sendOtp } from "../../redux/actions/order.action";
import { handleTextChange } from "../../redux/actions/address.action";
import {
  handleAppDownloadInfo,
  handleUserInfo
} from "../../redux/actions/myAccount.action";
import { resetAPIError } from "../../redux/actions/homepage.action";
import {
  changeLanguage,
  getCountryList
} from "../../redux/actions/common.action";

import Page from "../../components/page";
import { Image } from "../../components/image";
import IntlInput from "../../components/intl-tel-input/intl-tel-input";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

import CustomerSupport from "../../images/customer_support_01.svg";
import LocateStore from "../../images/locate_store_01.svg";
import ShopRegion from "../../images/shop_by_region_01.svg";
import TrackShipment from "../../images/track_shipment_01.svg";
import QRCode from "../../images/qrcode-app.png";
import MobileApp from "../../images/mobile-app.png";
import WishlistCard from "../../images/card.png";
import iphone from "../../images/iphoneX.png";
import mobileAppView from "../../images/mobileAppView.png";

class AppDownload extends Component {
  state = {
    mobileAppDownloadTranslation: mobileAppLang,
    errorMessage: null,
    mobileNumber: "",
    areaCode: null,
    showMessageSentText: false
  };

  componentDidMount() {
    const {
      match,
      language,
      handleLanguageChange,
      mobileLocalCode,
      getCountryList
    } = this.props;
    let _areaCode = mobileLocalCode && mobileLocalCode.split(",");
    this.setState({ areaCode: _areaCode && _areaCode[0] });

    handleLanguageChange(match.params.language);
    this.getlanguage(language);
    getCountryList(language);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { areaCode } = this.state;
    let _areaCode =
      nextProps.mobileLocalCode && nextProps.mobileLocalCode.split(",");
    if (!areaCode) {
      this.setState({ areaCode: _areaCode && _areaCode[0] });
    }
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps.language);
      this.props.handleAppDownloadInfo("phone", "", true);
    }
  }

  getlanguage = language => {
    const languageResponse = import(
      `../../language/mobileAppReference/${language}/mobileAppReference`
    );
    languageResponse.then(response => {
      this.setState({
        mobileAppDownloadTranslation: response.mobileAppLang
      });
    });
  };

  sendMessage = async () => {
    const { areaCode, mobileNumber, errorMessage } = this.state;
    const {
      mobileCountryCode,
      mobileLocalNumberLength,
      handleAppDownloadInfo
    } = this.props;

    if (!mobileNumber) return handleAppDownloadInfo("phone", "", false);
    if (mobileNumber.length !== mobileLocalNumberLength) return;

    const fullPhoneNumber = `${mobileCountryCode}${areaCode}${mobileNumber}`;

    try {
      if (errorMessage) this.setState({ errorMessage: null });

      await OtpService.sendAppDownloadSms(fullPhoneNumber);

      handleAppDownloadInfo("phone", "", true);

      this.setState({ showMessageSentText: true, mobileNumber: "" });
      await sleep(2000);
      this.setState({ showMessageSentText: false });
    } catch (error) {
      this.setState({
        errorMessage: error.response?.data?.message || error.message
      });
    }
  };

  handleCountryChange = (status, value) => {
    this.setState({ mobileNumber: value });
    this.props.handleAppDownloadInfo("phone", value, status);
  };

  onSelectFlag = (a, b) => {
    this.props.handleTextChange("phone", "");
    this.props.handleTextChange("dialCode", b.dialCode);
  };

  onSelectArea = area => {
    this.props.handleTextChange("phone", "");
    this.props.handleTextChange("areaCode", area);
    this.setState({ areaCode: area });
  };

  render() {
    const {
      mobileAppDownloadTranslation,
      areaCode,
      showMessageSentText,
      errorMessage
    } = this.state;
    const {
      language,
      countrySHORT,
      countryData,
      mobileLocalNumberLength,
      appDownloadPhone
    } = this.props;
    return (
      <Page
        id="app-download"
        language={language === "en" ? "english" : "arabic"}
      >
        <Fragment>
          <div
            className={`download-page-container ${
              language.includes("ar-") || language.includes("ar")
                ? "arabic"
                : ""
            }`}
          >
            <div className="download-upper-container">
              <div className="download-page-wrap position_rel">
                <h1>{mobileAppDownloadTranslation.mainHead}</h1>
                <h3 className="main-subheading">
                  {mobileAppDownloadTranslation.mainSubheading}
                </h3>
                <p className="main-description">
                  {mobileAppDownloadTranslation.mainDescription}
                </p>
                <div className="playstoreIconContainer ">
                  <section className="download_options">
                    <a
                      href={BEConfig.commonSettings.ios}
                      target="_blank"
                      className="apple_store"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>
                    <a
                      href={BEConfig.commonSettings.android}
                      target="_blank"
                      className="google_play"
                      rel="noopener noreferrer"
                    >
                      {" "}
                    </a>
                  </section>
                </div>
                <div className="mobileAppContainer">
                  <Image src={WishlistCard} className="wishlistCard" />
                  <Image src={MobileApp} className="mobile-app-image" />
                </div>
              </div>
            </div>
            <section className="qr-code-section">
              <div className="download-page-wrap">
                <div className="qrCode-Container">
                  <Image src={QRCode} className="qrcode-app" />
                </div>
                <div className="qrCode-desc-container">
                  <h3 className="qrcode-description">
                    {mobileAppDownloadTranslation.qrCodeDescription}
                  </h3>
                  <div className="form-group">
                    <IntlInput
                      valid={appDownloadPhone.errors.phone.valid}
                      phone={appDownloadPhone.info.phone || ""}
                      handleCountryChange={this.handleCountryChange}
                      areaCode={areaCode}
                      onSelectFlag={(a, b) => this.onSelectFlag(a, b)}
                      countryData={countryData}
                      defaultCountry={countrySHORT}
                      mobileLocalNumberLength={mobileLocalNumberLength}
                      onSelectArea={area => this.onSelectArea(area)}
                    />

                    {errorMessage && (
                      <span className="error">{errorMessage}</span>
                    )}
                    {!this.props.appDownloadPhone.errors.phone.valid && (
                      <span className="error">
                        {mobileAppDownloadTranslation.invalidPhoneNumberMessage}
                      </span>
                    )}
                    {showMessageSentText && (
                      <span className="success-message">
                        {mobileAppDownloadTranslation.messageSentText}
                      </span>
                    )}
                  </div>
                  <button className="Send-Button" onClick={this.sendMessage}>
                    {mobileAppDownloadTranslation.buttonText}
                  </button>
                </div>
              </div>
            </section>

            <div className="mobileReference-mid">
              <h3 className="mobileReference-header ">
                {mobileAppDownloadTranslation.mobileReferenceHeader}
              </h3>
              <h4 className="mobileReference-headerSmall ">
                {mobileAppDownloadTranslation.mobileReferenceHeaderSmall}
              </h4>
              <div className="mobileReference-details">
                <div className="mobileRef-feature mobCenter">
                  <div className="mobileRef-feature-details rightMargin">
                    <Image
                      src={LocateStore}
                      alt="Locate store"
                      className="IconHandle"
                    />
                    <span className="mob-Img" />
                    <h5 className="mob-head">
                      {mobileAppDownloadTranslation.mobHeadStore}
                    </h5>
                    <p className="mob-detail">
                      {mobileAppDownloadTranslation.mobDetailStore}
                    </p>
                  </div>

                  <div className="mobileRef-feature-details rightMargin">
                    <Image
                      src={ShopRegion}
                      alt="Shop by region"
                      className="IconHandle"
                    />
                    <span className="mob-Img" />
                    <h5 className="mob-head">
                      {mobileAppDownloadTranslation.mobHeadRegion}
                    </h5>
                    <p className="mob-detail">
                      {mobileAppDownloadTranslation.mobDetailRegion}
                    </p>
                  </div>
                </div>

                <div className="mobileRef-feature">
                  <Image src={mobileAppView} alt="Mobile App View" />
                </div>

                <div className="mobileRef-feature  mobCenter">
                  <div className="mobileRef-feature-details leftMargin">
                    <Image
                      src={CustomerSupport}
                      alt="Customer Support"
                      className="IconHandle"
                    />
                    <span className="mob-Img" />
                    <h5 className="mob-head">
                      {mobileAppDownloadTranslation.mobHeadCustomer}
                    </h5>
                    <p className="mob-detail">
                      {mobileAppDownloadTranslation.mobDetailCustomer}
                    </p>
                  </div>

                  <div className="mobileRef-feature-details leftMargin">
                    <Image
                      src={TrackShipment}
                      alt="Track "
                      className="IconHandle"
                    />
                    <span className="mob-Img" />
                    <h5 className="mob-head">
                      {mobileAppDownloadTranslation.mobHeadTrack}
                    </h5>
                    <p className="mob-detail">
                      {mobileAppDownloadTranslation.mobDetailTrack}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-outer-wrapper">
              <div className="bottom-inner-wrapper">
                <div className="option-list">
                  <ul>
                    <li>
                      <div className="ellipse">
                        <Image src={iphone} className="iphone-img" />
                      </div>
                      <div className="list-desc">
                        <h5>{mobileAppDownloadTranslation.listItemScan}</h5>
                        <h6>{mobileAppDownloadTranslation.listDescScan}</h6>
                      </div>
                    </li>

                    <li>
                      <div className="list-desc">
                        <h5>{mobileAppDownloadTranslation.listItemBrands}</h5>
                        <h6>{mobileAppDownloadTranslation.listDescBrands}</h6>
                      </div>
                      <div className="ellipse">
                        <Image src={iphone} className="iphone-img" />
                      </div>
                    </li>

                    <li>
                      <div className="ellipse">
                        <Image src={iphone} className="iphone-img" />
                      </div>
                      <div className="list-desc">
                        <h5>{mobileAppDownloadTranslation.listItemBuy}</h5>
                        <h6 className="topDesc">
                          {mobileAppDownloadTranslation.listDescBuy}
                        </h6>
                        <h6>{mobileAppDownloadTranslation.listDescShop}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Page>
    );
  }
}

const mapStateToProps = reduxState => ({
  authReducer: reduxState.authReducer,
  localAreaCode: reduxState.homepage.signUp.areaCode,
  language: reduxState.common.language,
  myDetails: reduxState.myAccountReducer.myDetails,
  appDownloadPhone: reduxState.myAccountReducer.appDownloadPhone,
  countryData: reduxState.common.countryData,
  countrySHORT:
    reduxState.common.settings && reduxState.common.settings.countrySHORT,
  mobileCountryCode:
    reduxState.common.settings && reduxState.common.settings.mobileCountryCode,
  mobileLocalCode:
    reduxState.common &&
    reduxState.common.settings &&
    reduxState.common.settings.mobileLocalCode,
  mobileLocalNumberLength:
    reduxState.common.settings &&
    reduxState.common.settings.mobileLocalNumberLength
});

const mapDispatchToProps = dispatch => ({
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  sendOtp: bindActionCreators(sendOtp, dispatch),
  handleTextChange: bindActionCreators(handleTextChange, dispatch),
  handleUserInfo: bindActionCreators(handleUserInfo, dispatch),
  handleAppDownloadInfo: bindActionCreators(handleAppDownloadInfo, dispatch),
  resetAPIError() {
    dispatch(resetAPIError());
  },
  getCountryList: bindActionCreators(getCountryList, dispatch)
});

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(AppDownload);
