import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import ProductListing from "../../routes/product-listing";
import { fetchAndSetBrandPageUrlsFromSeoSafeUrls } from "../../redux/actions/common.action";
import { getBrandsListProductsData } from "../../redux/actions/productlisting.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { handleSearchText } from "../../redux/actions/search.action";
import { setInitialState } from "../../redux/actions/productlisting.action";
import { isServer } from "../../util";
import ssrConfig from "./ssrConfig";
import { GAService } from "../../services/GA-service";

class BrandListing extends React.Component {
  state = {
    showListing: true
  };
  UNSAFE_componentWillMount() {
    const { setInitialState } = this.props;
    !isServer && setInitialState && setInitialState();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, handleSearchText, setInitialState } = this.props;

    if (location.pathname !== nextProps.location.pathname) {
      this.setState({ showListing: true });
      setInitialState && setInitialState();
      handleSearchText && handleSearchText("");
    } else {
      this.setState({ showListing: false });
    }
  }
  componentWillUnmount() {
    const { setInitialState } = this.props;
    setInitialState && setInitialState();
    // TODO
    // this.props.clearApiData();
  }

  componentDidUpdate(prevProps) {
    const { products, match } = this.props;
    if (products.queryID && prevProps.products.queryID !== products.queryID) {
      const brand_name =
        (
          match?.params?.brandName.replace("-", " ") ||
          decodeURIComponent(products.url.replace("brandName=", ""))
        )?.toLowerCase() || undefined;
      GAService.common.trackBrandPage({
        brand_quantity: products.nbHits,
        brand_name
      });
    }
  }

  render() {
    const { showListing } = this.state;
    const { products, language } = this.props;
    const isProductHits =
      products &&
      products.hits &&
      products.hits.length &&
      products.hits.some(ph => ph.id);

    return (
      <div>
        {isProductHits ? (
          <ProductListing
            content={products}
            language={language}
            showListing={showListing}
            pageName={null}
            isBrandPage={true}
          />
        ) : (
          <ProductListing
            language={language}
            showListing={showListing}
            pageName={null}
            isBrandPage={true}
          />
        )}
      </div>
    );
  }
}

BrandListing.need = ssrConfig;

const mapStateToProps = state => ({
  settings: state.common.settings,
  countryData: state.common.countryData,
  ipCountryData: state.common.ipCountryData,
  loader: state.common.showLoader,
  language:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  routeLanguage: state.common.language,
  products: state.productListingReducer.products
});

const mapStateToDispatch = dispatch => ({
  getMegaMenuData: bindActionCreators(getMegaMenuData, dispatch),
  getBrandsListProductsData: bindActionCreators(
    getBrandsListProductsData,
    dispatch
  ),
  fetchAndSetBrandPageUrlsFromSeoSafeUrls: bindActionCreators(
    fetchAndSetBrandPageUrlsFromSeoSafeUrls,
    dispatch
  ),
  handleSearchText: bindActionCreators(handleSearchText, dispatch),
  setInitialState: bindActionCreators(setInitialState, dispatch)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapStateToDispatch)
)(BrandListing);
