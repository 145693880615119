import React, { Component } from "react";
import { connect } from "react-redux";
import AddressForm from "./address-form";

class AddNewAddress extends Component {
  onSubmit = async data => {
    const { updateAddress, handleAddNewAddress } = this.props;
    const addressData = this.getTransformedAddressData(data);

    const successUpdate = await updateAddress(addressData);
    successUpdate && handleAddNewAddress();
  };

  getDefaultCountryPhoneArea() {
    const { mobileLocalCode } = this.props;
    return mobileLocalCode.split(",")[0];
  }

  getTransformedAddressData = ({
    address,
    area,
    phoneArea = "",
    city,
    country,
    isDefaultAddress,
    firstName,
    lastName,
    phoneNumber,
    zipCode
  }) => {
    const areaCode = phoneArea || this.getDefaultCountryPhoneArea();

    return {
      addressLine1: address.trim(),
      area: area.value,
      areaCode,
      areaId: area.key,
      cityId: city.key,
      country: country.value,
      countryId: country.key,
      default: isDefaultAddress,
      dialCode: this.props.mobileCountryCode,
      firstname: firstName,
      lastname: lastName,
      phone: areaCode + phoneNumber,
      zipcode: zipCode.toString()
    };
  };

  render() {
    const {
      handleAddNewAddress,
      language,
      checkoutTranslation,
      handleCloseModal,
      onSubmitBtnClick
    } = this.props;
    return (
      <AddressForm
        closeModal={handleAddNewAddress}
        checkoutTranslation={checkoutTranslation}
        language={language}
        submitButtonText={checkoutTranslation.AddNewAddress}
        handleCloseModal={handleCloseModal}
        onSubmit={this.onSubmit}
        onSubmitBtnClick={onSubmitBtnClick}
      />
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  mobileCountryCode: state.common.settings.mobileCountryCode,
  mobileLocalCode: state.common.settings.mobileLocalCode
});

export default connect(mapStateToProps)(AddNewAddress);
