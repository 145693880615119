"use strict";
/**
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonLdScriptProps = exports.JsonLd = exports.helmetJsonLdProp = void 0;
var json_ld_1 = require("./json-ld");
Object.defineProperty(exports, "helmetJsonLdProp", { enumerable: true, get: function () { return json_ld_1.helmetJsonLdProp; } });
Object.defineProperty(exports, "JsonLd", { enumerable: true, get: function () { return json_ld_1.JsonLd; } });
Object.defineProperty(exports, "jsonLdScriptProps", { enumerable: true, get: function () { return json_ld_1.jsonLdScriptProps; } });
