import React from "react";

const CartHeader = ({ translation, count }) => (
  <h4>
    {translation.myCart}
    <span>
      {count} {count === 1 ? translation.item : translation.items}
    </span>
  </h4>
);

export default CartHeader;
