import React from "react";
import map from "lodash/map";
import { Image } from "../../components/image";
import { GAService } from "../../services/GA-service";

const ConnectWithUsWeb = ({ staticContent }) => {
  const handleSocialClick = mediaObj => {
    GAService.common.trackSocialClicks(mediaObj);
  };

  return (
    <section className="connect-with-us">
      <p className="head">{staticContent.connect}</p>
      <p className="connect">
        {map(staticContent.socialMediaLinks, socialMediaLink => (
          <span
            key={socialMediaLink.id}
            onClick={() => handleSocialClick(socialMediaLink)}
          >
            <a
              href={socialMediaLink.redirectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={socialMediaLink.img}
                alt="social media link"
                lazyLoad
              />
            </a>
          </span>
        ))}
      </p>
    </section>
  );
};

export default ConnectWithUsWeb;
