import React, { useEffect, useState } from "react";
import {
  DEFAULT_CURRENCY_CODE,
  FREE_SHIPPING,
  PRICE_CONVERSION_DECIMAL_VALUE
} from "../../../constants";
import HtmlParser from "../../html-parser";
import * as priceUtil from "../../../util/priceUtil";
import {
  getShippingCharge,
  isFreeShippingForced,
  isFreeShippingReached
} from "../../../util/shipping";
import { useSelector } from "react-redux";
import {
  selectCheckoutData,
  selectCurrency,
  selectLanguageSlug,
  selectWalletData
} from "../../../util/selectors";
import {
  selectOrderCodFee,
  selectOrderDiscountDetails,
  selectPaymentOptionDetails,
  selectCommonSettingsDetails,
  selectTransactionWalletAmount
} from "./selectors";
import ConversionPrice from "./conversion-price";
import DeliveryPrice from "./delivery-price";
import PriceBlock from "./price-block";

const MobileOrderSummary = ({
  total,
  checkoutTranslation,
  selectedDelivery,
  useMyWallet
}) => {
  const [transformedTotal, setTransformedTotal] = useState(0);
  const [walletAmountTransformed, setWalletAmountTransformed] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const order = useSelector(selectCheckoutData);
  const codFee = useSelector(selectOrderCodFee);
  const currencyCode = useSelector(selectCurrency);
  const language = useSelector(selectLanguageSlug);
  const { discount, discountType } = useSelector(selectOrderDiscountDetails);
  const { paymentCurrency, paymentCurrencyCode, paymentCurrencyExchangeRate } =
    useSelector(selectPaymentOptionDetails);
  const { pricePreviewBoxHTML, decimalPlaces } = useSelector(
    selectCommonSettingsDetails
  );
  const totalWalletAmount = useSelector(selectTransactionWalletAmount);

  const freeShippingLimit = selectedDelivery?.freeShippingLimit;

  const deliveryPrice = selectedDelivery?.price;

  const freeShippingForced = isFreeShippingForced(
    freeShippingLimit,
    deliveryPrice
  );

  const shippingCharge = getShippingCharge(
    freeShippingLimit,
    deliveryPrice,
    total
  );

  const valueToPay = total + shippingCharge - discount;

  useEffect(() => {
    if (valueToPay > 0) {
      setTransformedTotal(valueToPay + codFee);
    }

    const isWalletApplied = useMyWallet && totalWalletAmount;
    const isWalletCoverTotal = totalWalletAmount > transformedTotal;
    const amountAfterAppliedWallet = isWalletCoverTotal
      ? transformedTotal
      : totalWalletAmount;

    const walletAmountTransformed =
      (isWalletApplied && amountAfterAppliedWallet) || 0;

    const grandTotal = priceUtil.formatPriceByDecimal(
      transformedTotal - walletAmountTransformed,
      PRICE_CONVERSION_DECIMAL_VALUE
    );

    setGrandTotal(grandTotal);
    setWalletAmountTransformed(walletAmountTransformed);
  }, [valueToPay, totalWalletAmount, useMyWallet, transformedTotal, codFee]);

  const showConversion =
    paymentCurrencyCode && paymentCurrencyCode !== paymentCurrency;

  const calculatedFree =
    freeShippingForced || isFreeShippingReached(freeShippingLimit, total);

  const currency = currencyCode || DEFAULT_CURRENCY_CODE;
  const isFreeShipping = discountType === FREE_SHIPPING;
  const subTotalPrice = priceUtil.shapePriceWithComma(total, decimalPlaces);
  const discountPrice = priceUtil.shapePriceWithComma(
    order?.discount,
    PRICE_CONVERSION_DECIMAL_VALUE
  );
  const walletAmount = priceUtil.shapePriceWithComma(walletAmountTransformed);
  const codFeeAmount = priceUtil.shapePriceWithComma(codFee);
  const grandTotalAmount = priceUtil.shapePriceWithComma(grandTotal);

  return (
    <div className="order-summary-resp mCheckoutSign">
      <PriceBlock
        translation={checkoutTranslation.subTotal}
        price={subTotalPrice}
      />

      {discountType && !isFreeShipping && (
        <PriceBlock
          translation={checkoutTranslation?.voucherDiscount}
          price={discountPrice}
          spanClassName="green_text"
          minusBeforePrice
        />
      )}

      <DeliveryPrice
        checkoutTranslation={checkoutTranslation}
        discountType={discountType}
        isFreeShipping={isFreeShipping}
        calculatedFree={calculatedFree}
        currency={currency}
        selectedDelivery={selectedDelivery}
      />

      {useMyWallet && (
        <PriceBlock
          translation={checkoutTranslation?.walletCredit}
          price={walletAmount}
          spanClassName="green_text"
          minusBeforePrice
        />
      )}

      {Boolean(codFee) && (
        <PriceBlock
          translation={checkoutTranslation?.codFee}
          price={codFeeAmount}
        />
      )}

      <PriceBlock
        translation={checkoutTranslation?.total}
        price={grandTotalAmount}
        paragraphClassName="total"
      />

      {showConversion && (
        <ConversionPrice
          paymentCurrency={paymentCurrency}
          language={language}
          grandTotal={grandTotal}
          paymentCurrencyExchangeRate={paymentCurrencyExchangeRate}
        />
      )}

      <div className="extra_price_info">
        <HtmlParser html={pricePreviewBoxHTML} />
      </div>
    </div>
  );
};

export default MobileOrderSummary;
