import get from "lodash/get";
import { DELIVERY_TYPES } from "../../constants";
import { getDataAsObject } from "../common";
export const filterDeliverySettings = deliverySettings =>
  deliverySettings.filter(
    ({ paymentType, deliveryType }) =>
      paymentType &&
      deliveryType &&
      paymentType === "CC" &&
      (deliveryType === DELIVERY_TYPES.DELIVERY_ADDRESS ||
        deliveryType === DELIVERY_TYPES.SELF_PICKUP ||
        deliveryType === DELIVERY_TYPES.STORE_PICKUP)
  );

export const getQuantityList = quantity =>
  Array.from(Array(quantity), (el, index) => index + 1);

export const getSizesInStock = availableSizes =>
  availableSizes &&
  availableSizes.length &&
  availableSizes.filter(option => option.sizeStock);

export const getAllowedSizeQuantity = (availableSizes, selectedSize, item) => {
  let allowedSizeQuantity = 0;
  if (selectedSize) {
    const sizeObject =
      availableSizes &&
      availableSizes.length &&
      availableSizes.find(
        as => as.sizeLabel && as.sizeLabel.trim() === selectedSize
      );
    allowedSizeQuantity = sizeObject ? sizeObject.sizeStock : 0;
  } else {
    allowedSizeQuantity = item.pstock || 0;
  }
  return allowedSizeQuantity;
};

export const isAvailableInCountry = (countryArray, countryId) =>
  Boolean(
    countryArray &&
      getDataAsObject(countryArray)?.find(c => c.countryId === countryId)
  );

export const getItemImageList = item =>
  item && item.images && item.images.productImageURLs
    ? [{ image: item.captionImageURL }, ...item.images.productImageURLs]
    : [{ image: item.captionImageURL }];

export const getCountOfItemInCart = (
  cart,
  item,
  selectedSizeLabelInternational
) => {
  let countOfItemInCart = 0;
  if (cart && cart.length) {
    const itemInCart = cart.find(
      cartItem =>
        cartItem.productId === item.id &&
        cartItem.size === selectedSizeLabelInternational
    );
    countOfItemInCart = itemInCart ? itemInCart.quantaty : 0;
  }
  return countOfItemInCart;
};

export const getColorSizeOptions = (
  internationalSizes,
  selectedSizeType,
  availableSizes
) => {
  const internationalSizesKeys = Object.keys(internationalSizes);
  let colorSizeOptions = [];
  if (internationalSizes[selectedSizeType]) {
    colorSizeOptions = internationalSizes[selectedSizeType];
  } else if (internationalSizesKeys.length) {
    colorSizeOptions = internationalSizes[internationalSizesKeys[0]];
  } else {
    colorSizeOptions = availableSizes;
  }
  return colorSizeOptions;
};

export const determineIsSizeChart = sizeChart =>
  !!Object.keys(sizeChart).length &&
  typeof sizeChart[0] === "object" &&
  !!Object.keys(sizeChart[0]).length;

export const getAvailableSizesFromProduct = colors =>
  get(colors, "colorSizeOptions", []).filter(option => option.sizeLabel);
