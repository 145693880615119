import React from "react";
import { Image } from "../../image";
const GroupCategories = ({
  title,
  dataList,
  handleClick,
  backBtnClick,
  isLevel2
}) => (
  <div className={`groupByCategories ${handleClick ? "slide_layer" : ""}`}>
    {title && <h3>{title}</h3>}
    <ul className="mobileMenu-groupCategories">
      {dataList &&
        !!dataList.length &&
        dataList.map(item => (
          <li key={item.uid} onClick={() => handleClick(item)}>
            <span className="imgWrap">
              <Image src={item.imageUrl || ""} atl="categories menu" />
            </span>
            <span className="nameTxt">{item.subTitle}</span>
          </li>
        ))}
    </ul>
  </div>
);

export default GroupCategories;
