import React, { useState } from "react";
import { connect } from "react-redux";
import {
  clearBinCode,
  setSummaryRecalculationInProgress
} from "../../../redux/actions/checkout.action";
import VoucherList from "../../voucher/voucher-list";

const ApplePayApplyCoupons = ({
  handleApplyCoupon,
  translation,
  VoucherCodes,
  isSameVoucherCode,
  isVoucherValid,
  applyVoucher,
  changeVoucher,
  order,
  inValidVoucherMessage,
  clearBinCode,
  language,
  setSummaryRecalculationInProgress
}) => {
  const [voucherValue, setVoucherValue] = useState("");

  const onVoucherInputChange = event => {
    setVoucherValue(event.target.value);
  };

  const onApplyButtonClick = async e => {
    await changeVoucher({}, voucherValue);
    applyVoucher(e);
  };

  return (
    <div className="ApplePay">
      <h4 className="slideTitle">
        <i
          className="ApplePay_icon_back"
          onClick={() => handleApplyCoupon(false)}
        />
        <span>{translation && translation.couponAndVoucher}</span>
      </h4>
      <div className="applePay_appyVoucher">
        <p className="applyVoucherTitle">
          {translation && translation.enterCreditNote.toUpperCase()}
        </p>
        <input
          type="text"
          value={voucherValue}
          className="form-control"
          placeholder={translation && translation.enterCode}
          onChange={onVoucherInputChange}
          aria-label="Voucher code"
        />
        <button className="voucherApply_btn" onClick={onApplyButtonClick}>
          {translation && translation.ApplybtnResp}
        </button>
        {!isSameVoucherCode ? (
          isVoucherValid !== null ? (
            <p
              className={`applyVoucher_msg ${
                isVoucherValid ? "applied" : "invalid"
              }`}
            >
              {isVoucherValid
                ? translation.validVoucher
                : inValidVoucherMessage
                ? inValidVoucherMessage
                : translation.invalidVoucher}
            </p>
          ) : null
        ) : null}
        {isSameVoucherCode ? (
          <p className={`applyVoucher_msg invalid`}>
            {translation.voucherApplied}
          </p>
        ) : null}
      </div>
      <VoucherList
        handleApplyCoupon={handleApplyCoupon}
        translation={translation}
        VoucherCodes={VoucherCodes}
        applyVoucher={applyVoucher}
        changeVoucher={changeVoucher}
        clearBinCode={clearBinCode}
        order={order}
        language={language}
        isVoucherValid={isVoucherValid}
        setSummaryRecalculationInProgress={setSummaryRecalculationInProgress}
      />
    </div>
  );
};

const mapDispatchToProps = {
  clearBinCode,
  setSummaryRecalculationInProgress
};

const mapStateToProps = state => ({
  language: state.common.language
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplePayApplyCoupons);
