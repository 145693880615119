import { connect } from "react-redux";
import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
import classnames from "classnames";
import {
  handleFacetChange,
  getProductsData,
  handleAssistiveFilterClick
} from "../../redux/actions/productlisting.action";

const params = {
  dots: false,
  slidesPerView: 4,
  rebuildOnUpdate: true,
  spaceBetween: 14,
  breakpoints: {
    800: {
      slidesPerView: "auto"
    },
    600: {
      slidesPerView: "auto"
    },
    320: {
      slidesPerView: "auto",
      spaceBetween: 5
    }
  }
};

class AssistiveFilterTitle extends PureComponent {
  setHierarchy = (clearFacet, facetItem) => {
    const {
      handleFacetChange,
      getProductsData,
      history,
      language,
      handleAssistiveFilterClick
    } = this.props;

    if (clearFacet) {
      handleAssistiveFilterClick(true);
      setTimeout(() => {
        handleFacetChange(clearFacet, facetItem, true);
        getProductsData &&
          getProductsData(clearFacet, history, null, null, language);
      }, 300);
    }
  };

  checkNoReplacementChars = str => {
    const replacementSpecChar = String.fromCharCode(0xfffd);
    return !str.includes(replacementSpecChar);
  };

  checkAcceptableTitleArray = tittleArray =>
    tittleArray.length &&
    tittleArray.length <= 3 &&
    this.checkNoReplacementChars(tittleArray[tittleArray.length - 1]);

  render() {
    const { language } = this.props;
    const { assistiveFilter, facets } = this.props.products;
    const data = (facets && facets[assistiveFilter]) || {};

    const _levelCategory = Object.keys(data).map((key, index) => {
      const _titleArray = key.split(" > ");

      if (this.checkAcceptableTitleArray(_titleArray)) {
        const title = _titleArray[_titleArray.length - 1];

        return (
          <span
            key={index}
            onClick={() => this.setHierarchy(assistiveFilter, key)}
          >
            {title}
          </span>
        );
      }
      return null;
    });

    const _levelCategoryMobile = Object.keys(data)
      .map((key, index) => {
        const _titleArray = key.split(" > ");

        if (this.checkAcceptableTitleArray(_titleArray)) {
          const title = _titleArray[_titleArray.length - 1];

          return (
            <div
              className="item"
              key={index}
              onClick={() => this.setHierarchy(assistiveFilter, key)}
            >
              {title}
            </div>
          );
        }
        return null;
      })
      .filter(Boolean);

    const isArabic = language.includes("ar");
    params.rtl = isArabic ? "rtl" : "";

    return (
      <div
        className={classnames("assistive_filters_wrapper", {
          arabic: isArabic
        })}
      >
        <div className="container chooseCategory_wrapper">
          <div className="assistiveFiltersResp">
            <Swiper {...params}>{_levelCategoryMobile}</Swiper>
          </div>
          <div className="assistive_content chooseCategory">
            {_levelCategory}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  handleFacetChange,
  getProductsData,
  handleAssistiveFilterClick
};

export default connect(null, mapDispatchToProps)(AssistiveFilterTitle);
