import React, { useState } from "react";
import { getRangeObject } from "../../util/itemsFilter";
import RangeSelector from "./range-selector";

const RangeDragerCard = ({
  currency,
  translationRange,
  minValue,
  maxValue,
  rangeMax,
  rangeMin,
  changeRangeComplete
}) => {
  const [rangeValue, setRange] = useState({ max: rangeMax, min: rangeMin });

  const convertedPriceRange = getRangeObject(rangeValue);

  return (
    <div className="range_drager_wrap">
      <p>
        <span>{translationRange}</span> {currency || "AED"}{" "}
        {convertedPriceRange.min} - {currency || "AED"}{" "}
        {convertedPriceRange.max}
      </p>
      <div className="range_drager">
        <RangeSelector
          minValue={minValue}
          maxValue={maxValue}
          value={rangeValue}
          changeRange={setRange}
          changeRangeComplete={changeRangeComplete}
        />
      </div>
    </div>
  );
};

const MemoizedRangeDragerCard = React.memo(RangeDragerCard);

export default MemoizedRangeDragerCard;
