import React from "react";
import get from "lodash/get";
import filter from "lodash/filter";
import { Link } from "react-router-dom";
import { isDateInFuture, isDateInPast } from "../../../util/common";

const menuBanner = ({ col, countryShort, language }) => {
  const { type, images } = col;
  const schedulers = get(col, "schedulers", []);
  const enabledSchedulers = filter(schedulers, "enabled");

  const activeScheduler = enabledSchedulers.find(
    scheduler => isDateInPast(scheduler.from) && isDateInFuture(scheduler.to)
  );

  const megaMenuImages = activeScheduler ? activeScheduler.images : images;
  return !!images ? (
    <div className="menuWrapper">
      {megaMenuImages.map(img => (
        <div
          className={`${type + img.className + ` order-` + img.pos}`}
          key={img.pos}
          onClick={e => {
            if (e) {
              const parent = e.currentTarget.offsetParent.parentElement;
              parent.parentElement.nextElementSibling &&
                parent.parentElement.nextElementSibling.classList.remove(
                  "active"
                );
              parent.classList.contains("active-li") &&
                parent.classList.remove("active-li");
            }
          }}
        >
          <Link
            to={`/${language}-${
              (countryShort && countryShort.toLowerCase()) || "ae"
            }${img.redirectionUrl}`}
          >
            <img src={img.imageUrl} loading="lazy" alt={img.alt} />
          </Link>
        </div>
      ))}
    </div>
  ) : null;
};
export default menuBanner;
