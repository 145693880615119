import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  resetAPIError,
  resetnotificationMsg
} from "../../redux/actions/homepage.action";
import { changeLanguage } from "../../redux/actions/common.action";
import { forgotPasswordTranslation } from "../../language/forgotPassword/en/forgotPassword";
import { resetPasswordEmailLink } from "../../redux/actions/homepage.action";
import FormActivityHandler from "../../components/formActionsHandler";
import Page from "../../components/page";
import ModalBFL from "../../components/modal";
import { emailValidationRegExp } from "../../constants";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import { GAService } from "../../services/GA-service";

class ForgotPassword extends React.Component {
  state = {
    forgotPasswordTranslation: forgotPasswordTranslation,
    lastFocusedField: "",
    isEmailValidate: false
  };

  static initialValues = {
    email: ""
  };

  static schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(emailValidationRegExp, {
        message: forgotPasswordTranslation.forgotPasswordEmail
      })
      .required(forgotPasswordTranslation.forgotPasswordEmail)
  });

  componentDidMount() {
    const { handleLanguageChange, match, language } = this.props;
    let languageParam = (match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    handleLanguageChange(languageParam);
    this.getlanguage({ language: languageParam });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.getlanguage(nextProps);
    }
  }

  handleForgotEmailError = e => {
    this.setState({
      isEmailValidate: false,
      lastFocusedField: e.currentTarget.name
    });
  };

  onSubmit = ({ email }) => {
    const { language, sendEmailLink, commonSettings } = this.props;

    GAService.auth.trackForgotPassword();
    sendEmailLink(
      {
        email,
        lang: language,
        country: commonSettings.countrySHORT.toLowerCase()
      },
      "staticPage"
    );
  };

  getlanguage(nextProps) {
    const forgotPasswordResponse = import(
      `../../language/forgotPassword/${nextProps.language}/forgotPassword.js`
    );
    forgotPasswordResponse.then(response => {
      this.setState({
        forgotPasswordTranslation: response.forgotPasswordTranslation
      });
    });
  }

  closeMessageModal = () => {
    this.props.resetnotificationMsg();
  };

  componentWillUnmount() {
    this.props.resetAPIError();
  }

  render() {
    const { forgotPasswordTranslation, isEmailValidate } = this.state;
    const { language, apiErrorDetail, message } = this.props;
    return (
      <Page>
        <div
          className={
            language.includes("ar-") || language.includes("ar")
              ? "register_user_page arabic"
              : "register_user_page"
          }
        >
          <div className="signup_layout forgot_pwd_page">
            <h2>{forgotPasswordTranslation.forgotPassword}</h2>
            <Formik
              initialValues={ForgotPassword.initialValues}
              onSubmit={this.onSubmit}
              validationSchema={ForgotPassword.schema}
              validateOnChange={false}
            >
              {({
                errors,
                isValidating,
                touched,
                values,
                handleChange,
                handleSubmit,
                setErrors
              }) => {
                const isInputInvalid = valueKey =>
                  Boolean(touched[valueKey] && errors && errors[valueKey]);

                useEffect(() => {
                  if (errors)
                    this.setState({
                      isEmailValidate: true
                    });
                }, [errors, isValidating]);

                useEffect(() => {
                  if (!apiErrorDetail.success)
                    setErrors({ email: apiErrorDetail.message });
                }, [apiErrorDetail.success]);

                return (
                  <form onSubmit={handleSubmit}>
                    <p>{forgotPasswordTranslation.forgotPasswordText}</p>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder={forgotPasswordTranslation.emailPlaceHolder}
                        onChange={values => {
                          handleChange(values);
                          this.props.resetAPIError();
                        }}
                        onFocus={this.handleForgotEmailError}
                        aria-label="Email"
                        value={values.email}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-default round_btn form_black_btn"
                    >
                      {forgotPasswordTranslation.submit}
                    </button>
                    <FormActivityHandler
                      formName={"forgot password"}
                      lastFocusedFormField={this.state.lastFocusedField}
                    />
                    {isEmailValidate && !!isInputInvalid("email") ? (
                      <div className="form-error">
                        <span className="error">
                          {forgotPasswordTranslation.forgotPasswordEmail}
                        </span>
                      </div>
                    ) : !apiErrorDetail.success ? (
                      <div className="form-error">
                        <span className="error">{apiErrorDetail.message}</span>
                      </div>
                    ) : null}
                  </form>
                );
              }}
            </Formik>
          </div>
          {message !== "" ? (
            <ModalBFL showModal modalClass="message_modal">
              {message}
              <button
                type="button"
                className="btn btn-default round_btn form_black_btn"
                onClick={() => this.closeMessageModal()}
              >
                Close
              </button>
            </ModalBFL>
          ) : null}
        </div>
      </Page>
    );
  }
}
const mapStateToProps = state => ({
  language: state.common.language,
  apiErrorDetail: state.homepage.APIErrorResponse,
  message: state.homepage.forgotPassword.APINotificationMsg,
  commonSettings: state.common.settings
});

const mapDispatchToProps = dispatch => ({
  sendEmailLink: bindActionCreators(resetPasswordEmailLink, dispatch),
  handleLanguageChange: bindActionCreators(changeLanguage, dispatch),
  resetAPIError: bindActionCreators(resetAPIError, dispatch),
  resetnotificationMsg: bindActionCreators(resetnotificationMsg, dispatch)
});

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword);
