import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Headroom from "react-headroom";
import cn from "classnames";
import debounce from "lodash/debounce";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import qs from "query-string";
import { compose } from "redux";
import { StorageService, AlgoliaInsightsService } from "../../services";

import {
  BRAND_NAME,
  BRAND_NAME_URL_SUFFIX,
  PARENT_CATEGORY_KEY_NAME,
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  PLP_CMS_DATA_SLUGS,
  languageCountryRegExp,
  UNMINIMIZED_NAME
} from "../../constants";

import {
  getProductUrlName,
  urlFilterMap,
  filterMap,
  languageFromPathName,
  selectedItemSizeId,
  _handleHckeys,
  categoriesContainerHandler,
  reverseUrlFilterMap,
  checkUrlSearchMultiCategory,
  isServer,
  handleContinueShoppingButtonUrl
} from "../../util";
import { getUserCountrySelectionStorage } from "../../util/browserStorage";

import { getMetaTitleDescription } from "../../util/seo.util";

import AnalyticService from "../../services/analytic-service";

import {
  showLoadingIndicator,
  hideLoadingIndicator,
  changeLanguage as handleLanguageChange,
  fetchAndSetBrandPageUrlsFromSeoSafeUrls,
  getColors
} from "../../redux/actions/common.action";
import {
  saveToWishList,
  deleteWishListData,
  getWishListBaseData
} from "../../redux/actions/wishList.action";
import {
  setInitialState,
  handleURLChange,
  getProductsData,
  handleGridChange,
  handlePageChange,
  toggleQuicklookView,
  toggleAppOnlyItemModal,
  setPageRoute,
  setProducts,
  getSelectedFacetsFromURL,
  getUpdatedFacets,
  setMinMax,
  handleFacetChange,
  handleRangeChange,
  fetchGeneratedUrls
} from "../../redux/actions/productlisting.action";
import {
  saveToCart,
  fetchCartData,
  getCartAndWishlistGeneralData
} from "../../redux/actions/cart.action";
import {
  handleSizeSelection,
  clearSizeSelection
} from "../../redux/actions/productDetail.action";
import { setHomeBreadcrumb } from "../../redux/actions/breadcrumb";
import { clearRedirectionData } from "../../redux/actions/page.action";

import HtmlParser from "../../components/html-parser";
import Page from "../../components/page";
import ContentHeader from "../../components/content/content-header";
import SelectedFilter from "../../components/selected-filter";
import Breadcrumb from "../../components/breadcrumb";
import ContentListing from "../../components/content/content-listing";
import MobileFilterTypes from "../../components/mobile/m-filter";
import Filtertypes from "../../components/filter/filters";
import Pagination from "../../components/page/pagination";
import QuicklookModal from "../../components/quicklook-modal";
import AssistiveFilterTitle from "../../components/assistive-filter-title";

import { breadcrumbTranslation } from "../../language/breadcrumb/en/breadcrumb";
import { seoTranslation } from "../../language/seo/en/seo";
import { productListing } from "../../language/productListing/en/productListing";
import {
  withAnalyticPageBrowseTracking,
  withDeliverySettingsFetching,
  withLanguageFromUrl,
  withPageTypeTracking
} from "../../hocs";
import { isAppOnlyAvailableItem } from "../../util/product";
import ModalBFL from "../../components/modal/modal";
import AppOnlyItemInfo from "../../components/modal/app-only-item";
import {
  selectShowAppOnlyItemModalState,
  selectWishListGeneralData,
  selectCdnImageSettings
} from "../../util/selectors";
import DynamicBannersWrapper from "../../components/dynamic/dynamic-banner";
import { GAService } from "../../services/GA-service";

export class ProductListing extends Component {
  static unminimizedName = UNMINIMIZED_NAME.PRODUCT_LISTING;

  constructor(props) {
    super(props);
    const urlLang =
      props.match.params.language && props.match.params.language.split("-")[0];
    this.state = {
      productTranslation: productListing,
      selectedIndex: null,
      url: "",
      isFilterSwitched: false,
      isResponsive: false,
      isWeb: true,
      isCategoryTitle: true,
      breadcrumbTranslation: breadcrumbTranslation,
      seoTranslated: seoTranslation[urlLang],
      hcKeys: "",
      toggleQuicklook: "",
      isBrandPageFlow: false,
      isGTMEvtTriggered: false
    };
  }

  async componentDidMount() {
    const {
      history,
      setPageRoute,
      getProductsData,
      searchText,
      handleLanguageChange,
      toggleQuicklookView,
      toggleAppOnlyItemModal,
      content,
      fetchGeneratedUrls,
      sysGeneratedUrl,
      isBrandPage,
      showListing,
      currentLanguage,
      location,
      getColors,
      colors,
      handleGridChange,
      gridValue,
      commonSettings
    } = this.props;
    window.history.scrollRestoration = "manual";
    if (commonSettings.countryId) {
      if (isEmpty(colors)) getColors();
      if (isEmpty(sysGeneratedUrl)) fetchGeneratedUrls();
    }
    handleLanguageChange(currentLanguage);
    this.loadLanguage(currentLanguage);
    toggleQuicklookView(false);
    toggleAppOnlyItemModal({ showAppOnlyItemModal: false, product: null });

    const queryArray = location.search.split("&");
    const index = queryArray.findIndex(value => value.indexOf("brandName"));
    if (index !== -1 && queryArray.length === 1) {
      this.setState({
        title: queryArray[index].replace("?", "").replace("brandName=", "")
      });
    }
    setPageRoute();
    if (content && !showListing) {
      this.setData(content, isBrandPage);
    } else {
      const browserback = history.location.search.includes("?brandName");
      if (commonSettings.countryId) {
        this.setState(() => ({ isBrandPageFlow: true }));
        await this.handleChangesForBrandsPagePromise(currentLanguage, false);
        this.setState(() => ({ isBrandPageFlow: false }));

        const productsCallShouldBeAvoided =
          location.pathname.includes("/search") && !location.state;

        !productsCallShouldBeAvoided &&
          (await getProductsData(
            null,
            history,
            history.location.search,
            searchText,
            currentLanguage,
            true,
            false,
            null,
            browserback,
            false,
            null,
            true
          ));
      }
    }
    window.scrollTo(0, 0);

    handleContinueShoppingButtonUrl();
    const storedGridValue = StorageService.getGridSize();
    if (storedGridValue) {
      handleGridChange(storedGridValue);
    }
  }

  handleChangesForBrandsPagePromise = (language, matchPassed, loader) => {
    const { match, fetchAndSetBrandPageUrlsFromSeoSafeUrls } = this.props;

    const actualmatch = matchPassed || match;
    if (
      match.params &&
      actualmatch &&
      actualmatch.url &&
      actualmatch.url.includes(BRAND_NAME_URL_SUFFIX)
    ) {
      return fetchAndSetBrandPageUrlsFromSeoSafeUrls(
        actualmatch.params.categoryName,
        actualmatch.params.brandName,
        language,
        loader
      );
    } else {
      return Promise.resolve();
    }
  };

  updateProductListingToggleQuickLook = showQuicklookModal => {
    const { toggleQuicklookView } = this.props;

    this.setState({ toggleQuicklook: !showQuicklookModal });
    toggleQuicklookView(false);
  };

  setData = (content, isBrandPage = false) => {
    const { setProducts, history, location } = this.props;
    const { translatedSearchParams } = content;

    const simplifiedNewSearch = reverseUrlFilterMap(translatedSearchParams);
    if (simplifiedNewSearch) {
      let simplifiedNewSearchWithURLPage = simplifiedNewSearch;
      const pageParam = simplifiedNewSearch.match(/page=\d+/);
      if (pageParam) {
        const page = parseInt(pageParam[0].replace("page=", ""));
        simplifiedNewSearchWithURLPage = simplifiedNewSearch.replace(
          /page=\d+/,
          `page=${page + 1}`
        );
      }
      history.replace(
        {
          pathname: location.pathname,
          search: simplifiedNewSearchWithURLPage
        },
        location.state
      );
    }
    const selectedFacets = getSelectedFacetsFromURL(
      simplifiedNewSearch || content.urlFilterCondition || content.url || ""
    );
    if (!isBrandPage) {
      getUpdatedFacets(content.facets, selectedFacets);
      setMinMax(content, content.urlFilterCondition || content.url);
    }
    this.setState({ url: content.url }, () => {
      setProducts({ ...content, ...selectedFacets }, null, true);
    });
  };

  componentWillUnmount() {
    const { setInitialState, setPageRoute } = this.props;

    setPageRoute();
    setInitialState();

    window.history.scrollRestoration = "auto";

    /*
     * resetting the browser history movements eventListener from the global object
     */
    window.onpopstate = () => {};
  }

  getSeoSafeBrandUrls = (brandPageDetails, language) => {
    let query = [];
    if (brandPageDetails.parentCategory) {
      let keyName = language ? `${language}_parentCategory` : "parentCategory";
      let parentCat = brandPageDetails[keyName];
      if (parentCat) {
        parentCat = encodeURIComponent(parentCat);
        query.push(`${PARENT_CATEGORY_KEY_NAME}=${parentCat}`);
      }
    }
    if (brandPageDetails.brandName) {
      let keyName = language ? `${language}_brandName` : "brandName";
      let brand = brandPageDetails[keyName];
      if (brand) {
        brand = encodeURIComponent(brand);
        query.push(`${BRAND_NAME}=${brand}`);
      }
    }
    return query.join("&");
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      history,
      getProductsData,
      location,
      content,
      products,
      handleURLChange,
      showListing,
      commonSettings,
      fetchGeneratedUrls,
      getColors,
      redirectionData
    } = this.props;

    const nextCountryId = nextProps.commonSettings.countryId;
    const isNewQuery = content?.queryID !== nextProps.content?.queryID;
    const isNeedToSetNewData =
      nextProps.content &&
      content &&
      (isNewQuery ||
        content.countryId !== nextProps.content.countryId ||
        content.en_title !== nextProps.content.en_title ||
        redirectionData?.url !== nextProps.redirectionData?.url);

    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps.language);

      if (nextProps.brandPageDetails) {
        const language = languageFromPathName(nextProps.language);
        const url = this.getSeoSafeBrandUrls(
          nextProps.brandPageDetails,
          language
        );
        url && handleURLChange(url);
      }
    }
    const isSpecialPage = ["/search", "/brands/", "/list"].some(slug =>
      location.pathname.includes(slug)
    );
    const isUpdatedCountry = commonSettings.countryId !== nextCountryId;
    if (
      (nextProps.showListing && showListing !== nextProps.showListing) ||
      (isSpecialPage && isUpdatedCountry)
    ) {
      const { match, currentLanguage } = nextProps;
      const browserback = history.location.search.includes("?brandName");
      if (nextCountryId) {
        if (isEmpty(nextProps.colors)) {
          getColors();
        }
        if (isEmpty(nextProps.sysGeneratedUrl)) {
          fetchGeneratedUrls();
        }
      }
      this.setState(() => ({ isBrandPageFlow: true }));
      await this.handleChangesForBrandsPagePromise(currentLanguage, match);
      this.setState(() => ({ isBrandPageFlow: false }));
      if (nextCountryId) {
        await getProductsData(
          null,
          history,
          history.location.search,
          "",
          currentLanguage,
          true,
          false,
          null,
          browserback,
          false
        );
      }
    }

    if (isNeedToSetNewData) {
      this.setState({ url: nextProps.content.url }, () => {
        this.setData(nextProps.content, nextProps.isBrandPage);
      });
    }

    const lvl0 =
      !!nextProps.products &&
      nextProps.products.facets["hierarchicalCategories.lvl0"];
    const lvl0Keys = !!lvl0 && Object.keys(lvl0);
    const isLvl0 =
      !!lvl0Keys &&
      lvl0Keys.length &&
      lvl0Keys.some(category => !!lvl0[`${category}`].checked);
    if (
      (!!lvl0Keys && lvl0Keys.length <= 1) ||
      (isLvl0 && this.state.isCategoryTitle)
    ) {
      this.setState({ isCategoryTitle: false });
    } else if (
      !!lvl0Keys &&
      lvl0Keys.length > 1 &&
      !isLvl0 &&
      !this.state.isCategoryTitle
    ) {
      this.setState({ isCategoryTitle: true });
    }
    if (products.query !== nextProps.products.query) {
      this.setBreadcrumbCategoryTitle(nextProps.products.query);
    }
    if (
      location &&
      location.search &&
      location.search.search("categories") === -1
    ) {
      this.setState({ isWeb: true });
    }

    this.setState({ hcKeys: _handleHckeys(nextProps) });
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      getProductsData,
      searchText,
      currentLanguage,
      location,
      clearRedirectionData,
      products
    } = this.props;

    const { isGTMEvtTriggered } = this.state;
    if (!isEqual(products?.facets, prevProps.products?.facets)) {
      const { analyticsData } = this.getSelectedFilter();
      if (!isGTMEvtTriggered && !isEmpty(analyticsData.selectedFilters)) {
        GAService.product.trackListingAutoFilter({
          selectedFilters: analyticsData.selectedFilters,
          resultCount: get(products, "nbHits", 0),
          searchText,
          enTranslation: productListing
        });
      }
      this.setState({ isGTMEvtTriggered: true });
    }

    window.onpopstate = () => {
      const url = location.pathname;
      AnalyticService.common.trackPageBrowse(url);

      const browserLanguageFromURL = languageFromPathName(
        (document.location.pathname.match(languageCountryRegExp) || [])[0]
      );

      if (currentLanguage !== browserLanguageFromURL) {
        clearRedirectionData();
      } else {
        getProductsData(
          null,
          history,
          window.location.search,
          searchText,
          currentLanguage,
          true,
          false,
          null,
          true,
          true,
          null,
          true
        );
      }
    };
  }

  loadLanguage = async language => {
    const { setHomeBreadcrumb } = this.props;

    const [languageResponse, languageResponseBreadcrum, languageResponseSeo] =
      await Promise.all([
        import(`../../language/productListing/${language}/productListing`),
        import(`../../language/breadcrumb/${language}/breadcrumb`),
        import(`../../language/seo/${language}/seo`)
      ]);

    setHomeBreadcrumb(languageResponseBreadcrum.breadcrumbTranslation);
    this.setState({
      productTranslation: languageResponse.productListing,
      breadcrumbTranslation: languageResponseBreadcrum.breadcrumbTranslation,
      seoTranslated: languageResponseSeo.seoTranslation
    });
  };

  handlePageClick = (e, page) => {
    const { getProductsData, handlePageChange, history, searchText, language } =
      this.props;
    handlePageChange(page || e.selected);
    getProductsData(null, history, null, searchText, language);
    handleContinueShoppingButtonUrl();
  };

  onGridChange = value => {
    const { handleGridChange } = this.props;

    return handleGridChange(value);
  };

  toggleQuickLook = (toggleState, selectedIndex, event) => {
    const { products, toggleQuicklookView } = this.props;
    this.setState({ selectedIndex });
    if (isAppOnlyAvailableItem(products.hits[selectedIndex])) {
      return toggleAppOnlyItemModal({
        showAppOnlyItemModal: !this.props.showAppOnlyItemModal,
        product: products.hits[selectedIndex]
      });
    }
    if (event) event.stopPropagation();

    toggleQuicklookView(toggleState);

    if (toggleState) {
      const selectedProduct = products.hits[selectedIndex];

      AlgoliaInsightsService.trackProductClick({
        productId: selectedProduct.id,
        position: selectedIndex + 1,
        queryID: products.queryID,
        algoliaIndex: products.index
      });
    }
  };

  handelSwipperButtonClick = selectedIndex => {
    const { products, en_title } = this.props;
    this.setState({ selectedIndex });
    const gaPayload = {
      item: {
        ...products["hits"][selectedIndex],
        position: selectedIndex
      },
      listingTitle: en_title
    };
    GAService.product.trackSelectProduct(gaPayload);
    GAService.product.trackViewProduct(gaPayload);
  };

  addToWishListMoEngage = (item, wishListData) => {
    const { authReducer, commonSettings, products } = this.props;
    AnalyticService.cart.trackAddingToWishlist({
      item,
      wishListData,
      authReducer,
      commonSettings
    });
    const itemIndex = products.hits?.findIndex(
      listingItem => listingItem.id === item.id
    );
    GAService.cart.trackAddProductToWishList({
      item: { ...item, position: itemIndex },
      wishListData
    });
  };

  saveToWishList = async (
    { id, selectedSizeValue = "" },
    itemContainer,
    childContainer,
    targetContainer,
    e,
    size = ""
  ) => {
    e && e.stopPropagation();

    const { saveToWishList, products, getCartAndWishlistGeneralData } =
      this.props;
    const wishListData = {
      productId: id,
      size: selectedSizeValue || size
    };

    await saveToWishList(wishListData, true, false);
    getCartAndWishlistGeneralData();

    const productList = get(products, "hits", []);
    const item = find(productList, { id: wishListData.productId });
    this.addToWishListMoEngage(item, wishListData);
  };

  deleteFromWishList = async (productId, e, size = "") => {
    e && e.stopPropagation && e.stopPropagation();
    const {
      wishListGeneralProducts,
      deleteWishListData,
      getCartAndWishlistGeneralData,
      products
    } = this.props;
    const { selectedIndex } = this.state;

    const wishListItem =
      !isEmpty(wishListGeneralProducts) &&
      wishListGeneralProducts.find(
        item => item.productId === productId && item.size === size
      );

    await deleteWishListData(
      productId,
      (wishListItem && wishListItem._id) || ""
    );
    const productList = get(products, "hits", []);
    const item = find(productList, { id: productId });
    GAService.cart.removeProductFromWishList({
      ...item,
      position: selectedIndex
    });

    getCartAndWishlistGeneralData();
  };

  delayedWishlistHandler = handler => debounce(handler, 1000);

  addToCart = (item, size, quantity, sizeType) => {
    const { saveToCart, fetchCartData, authReducer, commonSettings, language } =
      this.props;
    const { selectedIndex } = this.state;
    const sizelabel =
      (size && typeof size === "object" && size.sizeLabel) || size;
    const selectedSize = selectedItemSizeId(item, sizeType, sizelabel);
    const cartData = {
      productId: item.id,
      quantity,
      size: sizelabel,
      sizeId: selectedSize && selectedSize.sizeId,
      sizeType
    };

    let color;
    if (size && item && item.colors && item.colors.colorSizeOptions) {
      color = item.colors.colorSizeOptions.find(e => e.sizeLabel === size);
    }
    AnalyticService.cart.trackAddToCart({
      item,
      cartData,
      authReducer,
      commonSettings,
      color
    });
    GAService.cart.trackAddProductToCart({
      item: { ...item, position: selectedIndex, origin: "quicklook" },
      cartData,
      color
    });

    saveToCart(cartData).then(res => {
      if (res.data && res.data.success) fetchCartData(false, language);
    });
  };

  moveToProductDetail = (item, e, pushToHistory = true) => {
    e && e.stopPropagation();
    if (item && item.id) {
      const { history, language, authReducer, commonSettings } = this.props;
      const ProductTitle = getProductUrlName(item.en_title || item.title || "");
      if (pushToHistory) {
        history.push(
          `/${language}-${
            (commonSettings &&
              commonSettings.countrySHORT &&
              commonSettings.countrySHORT.toLowerCase()) ||
            "ae"
          }/${ProductTitle ? ProductTitle : "---"}/${item.id}/p/`
        );
      }
      AnalyticService.product.trackMoveToProductDetail({
        item,
        authReducer,
        commonSettings
      });
    }
  };

  showLoading = state => {
    const { hideLoadingIndicator, showLoadingIndicator } = this.props;
    if (state) {
      showLoadingIndicator();
    } else {
      hideLoadingIndicator();
    }
  };

  handleSwitchFilter = value => {
    this.setState({
      isWeb: value,
      isCategoryTitle: !value
    });
  };

  handleMobileFilter = (value, e) => {
    this.setState({
      isResponsive: value,
      isWeb: !value,
      isCategoryTitle: value
    });
  };

  clearAll = (page = 0) => {
    const { selectedFilters } = this.getSelectedFilter();
    const {
      handleFacetChange,
      getProductsData,
      history,
      handlePageChange,
      match,
      language
    } = this.props;
    let languageParam =
      (match && match.params && match.params.language) || language;
    if (languageParam.includes("ar-") || languageParam.includes("ar")) {
      languageParam = "ar";
    } else {
      languageParam = "en";
    }
    selectedFilters.forEach(filter => {
      handleFacetChange(filter.facet, filter.subFacet, false);
    });
    handlePageChange(page);
    getProductsData(
      null,
      history,
      "",
      null,
      languageParam,
      true,
      false,
      null,
      true
    );
    this.handleSwitchFilter(false);
  };

  getSelectedFilter = () => {
    const { products, brandPageDetails, location } = this.props;
    const facets = products.facets || [];
    const facetKeys = Object.keys(facets);
    const selectedFilters = [],
      categoriesSelected = [],
      subCategoriesSelected = [];
    const analyticsData = {
      selectedFilters: []
    };
    const url = (products && products.url) || "";
    facetKeys.forEach(faceKey => {
      Object.keys(facets[faceKey]).forEach(subFacetKey => {
        if (facets[faceKey][subFacetKey].checked) {
          if (faceKey === "hierarchicalCategories.lvl0")
            categoriesSelected.push(this._getTrabsformedSubfacet(subFacetKey));
          if (faceKey === "hierarchicalCategories.lvl2")
            subCategoriesSelected.push(
              this._getTrabsformedSubfacet(subFacetKey)
            );
          if (
            brandPageDetails &&
            (PARENT_CATEGORY_KEY_NAME === faceKey || BRAND_NAME === faceKey)
          ) {
            return;
          }
          if (
            !(
              url.indexOf(filterMap[faceKey]) >= 0 ||
              url.indexOf(encodeURIComponent(faceKey)) >= 0
            )
          ) {
            const filterData = {
              facet: faceKey,
              subFacet: subFacetKey,
              color: faceKey === "attributes.Color Family" ? subFacetKey : null
            };

            selectedFilters.push(filterData);
            analyticsData.selectedFilters.push(filterData);
          }
        }
        if (faceKey === "priceBy") {
          const filterParams = qs.parse(location.search);
          const priceValues = `min-${filterParams.min}/max-${filterParams.max}`;
          const priceFilterAlreadyExists = analyticsData.selectedFilters.find(
            filter =>
              filter.facet === faceKey && filter.subFacet === priceValues
          );

          if (
            !priceFilterAlreadyExists &&
            filterParams.min &&
            filterParams.max
          ) {
            analyticsData.selectedFilters.push({
              facet: faceKey,
              subFacet: priceValues,
              color: null
            });
          }
        }
      });
    });

    return {
      selectedFilters,
      categoriesSelected,
      subCategoriesSelected,
      analyticsData
    };
  };

  _getTrabsformedSubfacet = subFacet => {
    const subItems = subFacet && subFacet.split(">");
    return subItems[(subItems.length && subItems.length - 1) || 0];
  };

  resetIsResponsive = () => {
    this.setState({ isResponsive: false });
  };

  isPageIndexable = (searchQuery, isBrandPage) => {
    let isIndexable = true;
    const { history } = this.props;
    const link = history.location.pathname;
    var noIndexParams = ["promotion", "new-arrivals"];
    let c = noIndexParams.filter(param => link.indexOf(param) !== -1);
    if (c.length > 0) return !isIndexable;

    if (
      isBrandPage ||
      (searchQuery &&
        Array.isArray(searchQuery) &&
        searchQuery.length === 1 &&
        searchQuery.join().match(/brand(Name)?=/))
    ) {
      isIndexable = true;
    } else {
      isIndexable = !(Array.isArray(searchQuery)
        ? searchQuery.join("")
        : searchQuery);
    }
    return isIndexable;
  };

  getPageTitleForBrandsPage = brandPageDetails => {
    let title = "";
    let { language } = this.props;
    if (brandPageDetails) {
      language = languageFromPathName(language);
      const { parentCategory, brandName } = brandPageDetails;
      if (language === "en") {
        if (brandName) {
          title = brandPageDetails[`${language}_brandName`];
        }
        if (parentCategory) {
          title =
            title +
            " " +
            brandPageDetails[`${language}_parentCategory`] +
            "'s Collection";
        }
      } else {
        if (brandName) {
          title = brandPageDetails[`${language}_brandName`];
        }
        if (parentCategory) {
          title = title + " " + brandPageDetails[`${language}_parentCategory`];
        }
      }
    }
    return title;
  };

  getDocTitleForBrandsPage = (brandPageDetails, productTranslation) => {
    let title = "";
    let { language } = this.props;
    if (brandPageDetails) {
      language = languageFromPathName(language);
      const { brandName } = brandPageDetails;
      if (language === "en") {
        if (brandName) {
          title = productTranslation.docTitleForBrandsPage.replace(
            /{BrandName}/,
            brandPageDetails[`${language}_brandName`]
          );
        }
      } else {
        if (brandName) {
          title = productTranslation.docTitleForBrandsPage.replace(
            /{BrandName}/,
            brandPageDetails[`${language}_brandName`]
          );
        }
      }
    }
    return title;
  };

  setBreadcrumbCategoryTitle = searchText => {
    const { title, productTranslation } = this.state;
    const { ar_title, en_title } = this.props;
    const _title = !!title && title.split("=");
    return _title && _title.length
      ? _title[0] === "q"
        ? !!searchText &&
          `${productTranslation.searchResultsFor} ${decodeURI(searchText)}`
        : ""
      : !!searchText
      ? !!searchText &&
        `${productTranslation.searchResultsFor} ${decodeURI(searchText)}`
      : {
          ar_productListName: !!ar_title && ar_title,
          en_productListName: !!en_title && en_title
        };
  };

  toggleQuickLookState = () => {
    this.setState({ toggleQuicklook: false });
  };

  switchWebAndMobileFilters = value => {
    this.handleSwitchFilter(value);
    this.handleMobileFilter(value);
  };

  handleSortChangeEvent = sort => {
    const sortOptions = [
      { key: " ", value: "relevance" },
      { key: "ASC", value: "lowToHigh" },
      { key: "DESC", value: "highToLow" }
    ];
    const { searchText } = this.props;
    const sortObj = sortOptions.find(each => each.key === sort);
    const sortText = productListing[sortObj.value];
    GAService.product.trackProductsSort({
      sort_by: sortText,
      sort_location: searchText ? "search results" : "product list"
    });
  };

  handleFilterItemChecked = (checkedfacet, isChecked) => {
    if (isChecked) {
      const { analyticsData } = this.getSelectedFilter();
      const { products, searchText } = this.props;
      GAService.product.trackListingFilterApplied({
        appliedFacet: checkedfacet,
        selectedFilters: analyticsData.selectedFilters,
        resultCount: get(products, "nbHits", 0),
        searchText,
        enTranslation: productListing
      });
    }
  };
  getSelectedSortOption = () => {
    const { products } = this.props;
    const { productTranslation } = this.state;
    return (
      (products.sort &&
        ((Array.isArray(products.sort) &&
          products.sort.length &&
          products.sort[0].toUpperCase()) ||
          (products.sort && products.sort.toUpperCase()))) ||
      productTranslation.sort
    );
  };

  getPLPSortOptions = () => {
    const { products } = this.props;
    const { productTranslation } = this.state;
    const options = get(products, "sortFilter.options", {});
    const { isAscEnabled, isRelEnabled, isDescEnabled } = options;
    const KEYS = {
      none: {
        key: " ",
        isEnabled: isRelEnabled,
        label: productTranslation.relevance
      },
      asc: {
        key: "ASC",
        isEnabled: isAscEnabled,
        label: productTranslation.lowToHigh
      },
      desc: {
        key: "DESC",
        isEnabled: isDescEnabled,
        label: productTranslation.highToLow
      }
    };

    const mappedSortOptions = [];
    Object.keys(KEYS).forEach(option => {
      const obj = KEYS[option];
      if (obj.isEnabled) {
        mappedSortOptions.push(obj);
      }
    });

    return mappedSortOptions;
  };

  handleProductListingImpressions = _arr => {
    const { searchText, products } = this.props;
    GAService.product.trackViewItemListImpressions({
      _arr,
      products,
      searchText,
      searchListingStaticHeading: productListing.searchResultsFor
    });
  };

  render() {
    const {
      productTranslation,
      selectedIndex,
      isCategoryTitle,
      isWeb,
      seoTranslated,
      hcKeys,
      isBrandPageFlow
    } = this.state;
    const {
      products,
      gridValue,
      language,
      showQuicklookModal,
      wishListGeneralProducts,
      cart,
      currencyCode,
      history,
      sizeSelection,
      handleSizeSelection,
      commonSettings,
      en_title,
      ar_title,
      searchText,
      clearSizeSelection,
      brandPageDetails,
      content,
      colors,
      redirectionData,
      wishListAndCartBaseDataLoaded,
      deleteWishListItemLoaded,
      appOnlyItemModalState,
      toggleAppOnlyItemModal,
      configCdnImagesSettings
    } = this.props;
    const { showAppOnlyItemModal } = appOnlyItemModalState;
    const { selectedFilters, categoriesSelected, subCategoriesSelected } =
      this.getSelectedFilter();
    const userCountrySelected = getUserCountrySelectionStorage();
    const breadcrumbCategoryTitle = this.setBreadcrumbCategoryTitle(searchText);

    const selectedSort = this.getSelectedSortOption();
    const sortOptions = this.getPLPSortOptions();

    let querystring =
      get(history, "location.search", "") &&
      decodeURIComponent(
        urlFilterMap(decodeURI(history.location.search))
          .split("?")[1]
          .split("&")
      );

    querystring = categoriesContainerHandler(querystring);
    const hcLvel =
      querystring &&
      querystring.filter(
        hcLvel =>
          hcLvel.includes("hierarchicalCategories.lvl") ||
          hcLvel.includes("categories")
      );

    const SearchTitle =
      hcLvel &&
      hcLvel[hcLvel.length - 1] &&
      hcLvel[hcLvel.length - 1].split("=");
    let productSearchTitle =
      SearchTitle &&
      (SearchTitle[0] === "hierarchicalCategories.lvl0" ||
        SearchTitle[0] === "categories-0")
        ? SearchTitle[1]
        : SearchTitle &&
          (SearchTitle[0] === "hierarchicalCategories.lvl1" ||
            SearchTitle[0] === "categories-1")
        ? SearchTitle[1].split(">")[0] + SearchTitle[1].split(">")[1]
        : SearchTitle &&
          (SearchTitle[0] === "hierarchicalCategories.lvl2" ||
            SearchTitle[0] === "categories-2")
        ? SearchTitle[1].split(">")[0] + SearchTitle[1].split(">")[2]
        : "";

    let currentSelectedCategory =
      SearchTitle &&
      (SearchTitle[0] === "hierarchicalCategories.lvl0" ||
        SearchTitle[0] === "categories-0")
        ? SearchTitle[1].split(">")[0]
        : SearchTitle &&
          (SearchTitle[0] === "hierarchicalCategories.lvl1" ||
            SearchTitle[0] === "categories-1")
        ? SearchTitle[1].split(">")[1]
        : SearchTitle &&
          (SearchTitle[0] === "hierarchicalCategories.lvl2" ||
            SearchTitle[0] === "categories-2")
        ? SearchTitle[1].split(">")[2]
        : "";
    const isQuickFilterData = checkUrlSearchMultiCategory(
      history.location.search
    );

    const productsTitle = language.includes("en")
      ? get(content, "en_title", en_title)
      : get(content, "ar_title", ar_title);
    const titleText = productsTitle || currentSelectedCategory || "";
    const Title = `${seoTranslated.buy} ${titleText} ${seoTranslated.onlineBestpriceIn} ${commonSettings.countryCode} | ${seoTranslated.bfl}`;
    const metaInfo = language
      ? {
          [language]: {
            metaDescription: `${seoTranslated.PlpMetaDesc1} ${
              currentSelectedCategory || productsTitle || ""
            } ${seoTranslated.PlpMetaDesc2}`
          }
        }
      : {};

    let pageTitle = this.getPageTitleForBrandsPage(brandPageDetails);
    if (pageTitle || isQuickFilterData) {
      currentSelectedCategory = "";
    }
    if (!brandPageDetails) {
      const { meta } = redirectionData;
      if (meta?.headingH2) {
        pageTitle = meta.headingH2;
      } else {
        pageTitle = language.includes("en") ? en_title : ar_title;
      }
    }
    const docTitle = this.getDocTitleForBrandsPage(
      brandPageDetails,
      productTranslation
    );

    const pageMetaInfo = getMetaTitleDescription(
      redirectionData,
      history,
      commonSettings,
      brandPageDetails
    );
    const seoPageTitle = get(pageMetaInfo, "title", "");
    const seoPageDescription = get(pageMetaInfo, "description", "");
    const seoPageDescriptionInfo = language
      ? {
          [language]: {
            metaDescription: seoPageDescription
          }
        }
      : {};
    const { cms } = redirectionData;

    return (
      <Page
        title={seoPageTitle || docTitle || Title}
        meta={seoPageDescriptionInfo || metaInfo}
        id="product-listing"
        language={language === "en" ? "english" : "arabic"}
        indexable={this.isPageIndexable(querystring, !!brandPageDetails)}
      >
        {!isBrandPageFlow && !isServer && (
          <>
            <Breadcrumb />
            <MobileFilterTypes
              products={products}
              translation={productTranslation}
              language={language}
              title={pageTitle}
              productSearchTitle={productSearchTitle}
              searchText={searchText && decodeURI(searchText)}
              selectedFilterCount={selectedFilters && selectedFilters.length}
              handleSwitchFilter={this.switchWebAndMobileFilters}
              selectedFilters={selectedFilters}
              resetIsResponsive={this.resetIsResponsive}
              currentSelectedCategory={currentSelectedCategory}
              handleFilterItemChecked={this.handleFilterItemChecked}
              handleSortChangeEvent={this.handleSortChangeEvent}
              sortOptions={sortOptions}
              selectedSort={selectedSort}
            />
            <div className="promotion_banner">
              {products && products.userData && products.userData.length ? (
                <div className="store_banner">
                  {products.userData.map((obj, index) => (
                    <HtmlParser key={index} html={obj.html} />
                  ))}
                </div>
              ) : null}
            </div>
            <div className="sticky_filters">
              <ContentHeader
                categoriesSelected={categoriesSelected}
                subCategoriesSelected={subCategoriesSelected}
                products={products}
                gridValue={gridValue}
                translation={productTranslation}
                onGridChange={this.onGridChange}
                language={language}
                isCategoryTitle={isCategoryTitle}
                title={pageTitle}
                productSearchTitle={productSearchTitle}
                searchText={searchText && decodeURI(searchText)}
                currentSelectedCategory={currentSelectedCategory}
              />
              {cms &&
                cms.map((section, index) => {
                  if (section.slug === PLP_CMS_DATA_SLUGS.TOP_SECTION) {
                    return (
                      <div className="cms_html_data" key={index}>
                        <DynamicBannersWrapper
                          section={section}
                          bannerPageTitle={section.sectionTitle}
                        />
                      </div>
                    );
                  }
                })}
              <div className="d-flex flex-column-reverse">
                <Headroom disableInlineStyles className="listing_filter_web">
                  <Filtertypes
                    products={products}
                    searchText={searchText && decodeURI(searchText)}
                    handleFilterItemChecked={this.handleFilterItemChecked}
                    handleSortChangeEvent={this.handleSortChangeEvent}
                    translation={productTranslation}
                    handleSwitchFilter={this.handleSwitchFilter}
                    isWeb={isWeb}
                    handleMobileFilter={this.handleMobileFilter}
                    categoryTitle={breadcrumbCategoryTitle}
                    isCountrySelected={!isServer && !userCountrySelected}
                    sortOptions={sortOptions}
                    selectedSort={selectedSort}
                  />
                </Headroom>
                {products &&
                  products.assistiveFilter &&
                  products.facets &&
                  !hcKeys && (
                    <Headroom
                      disableInlineStyles
                      className="listing_filter_responsive"
                    >
                      <AssistiveFilterTitle
                        products={products}
                        history={history}
                        language={language}
                      />
                    </Headroom>
                  )}
              </div>
            </div>
            <div>
              <SelectedFilter
                selectedFilters={selectedFilters}
                translation={productTranslation}
                language={language}
                handleSwitchFilter={this.switchWebAndMobileFilters}
                colors={colors}
              />
            </div>
            <ContentListing
              translation={productTranslation}
              title={Title}
              pageTitle={pageTitle}
              moveToProductDetail={this.moveToProductDetail}
              deleteFromWishList={this.delayedWishlistHandler(
                this.deleteFromWishList
              )}
              toggleQuicklook={this.toggleQuickLook}
              saveToWishList={this.delayedWishlistHandler(this.saveToWishList)}
              showLoading={this.showLoading}
              handleProductListingImpressions={
                this.handleProductListingImpressions
              }
            />
            <Pagination
              products={products}
              handlePageClick={this.handlePageClick}
              language={language}
              translation={productTranslation}
              history={history}
              clearAll={this.clearAll}
              countryShort={
                (commonSettings &&
                  commonSettings.countrySHORT &&
                  commonSettings.countrySHORT.toLowerCase()) ||
                "ae"
              }
            />
            {cms &&
              cms.map((section, index) => {
                if (section.slug === PLP_CMS_DATA_SLUGS.BOTTOM_SECTION) {
                  return (
                    <div className="cms_html_data" key={index}>
                      <DynamicBannersWrapper
                        section={section}
                        bannerPageTitle={section.sectionTitle}
                      />
                    </div>
                  );
                }
              })}
            {showQuicklookModal && (
              <QuicklookModal
                showQuicklookModal={showQuicklookModal}
                selectedIndex={selectedIndex}
                toggleQuicklook={this.toggleQuickLook}
                saveToWishList={this.delayedWishlistHandler(
                  this.saveToWishList
                )}
                deleteFromWishList={this.delayedWishlistHandler(
                  this.deleteFromWishList
                )}
                wishListBaseDataLoaded={wishListAndCartBaseDataLoaded}
                deleteWishListItemLoaded={deleteWishListItemLoaded}
                wishListBaseData={wishListGeneralProducts}
                cart={cart}
                addToCart={this.addToCart}
                product={products["hits"][selectedIndex]}
                products={products.hits.length}
                handelSwipperButtonClick={this.handelSwipperButtonClick}
                translation={productTranslation}
                language={language}
                moveToProductDetail={this.moveToProductDetail}
                currencyCode={currencyCode}
                sizeSelection={sizeSelection}
                handleSizeSelection={handleSizeSelection}
                clearSizeSelection={clearSizeSelection}
                commonSettings={commonSettings}
                updateProductListingToggleQuickLook={
                  this.updateProductListingToggleQuickLook
                }
              />
            )}
            {showAppOnlyItemModal && (
              <ModalBFL
                showModal={showAppOnlyItemModal}
                modalClass={cn(
                  { arabic: language.includes("ar") },
                  "generic_modal app_exclusive_modal wide_generic_modal"
                )}
              >
                <button
                  className="cross_btn"
                  onClick={e => toggleAppOnlyItemModal(false)}
                >
                  &times;
                </button>
                <div className="app_exclusive_modal_content">
                  <AppOnlyItemInfo
                    language={language}
                    configCdnImagesSettings={configCdnImagesSettings}
                  />
                </div>
              </ModalBFL>
            )}
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  currencyCode: state.common.settings && state.common.settings.currencyCode,
  products: state.productListingReducer.products,
  en_title: state.productListingReducer.en_title,
  ar_title: state.productListingReducer.ar_title,
  gridValue: state.productListingReducer.gridValue,
  cart: state.cartReducer.cart,
  searchText: state.search.searchText,
  showQuicklookModal: state.productListingReducer.showQuicklookModal,
  appOnlyItemModalState: selectShowAppOnlyItemModalState(state),
  handleSelectedSize: state.productListingReducer.handleSelectedSize,
  authReducer: state.authReducer,
  commonSettings: state.common.settings,
  sizeSelection: state.productDetailReducer.sizeSelection,
  sysGeneratedUrl: state.productListingReducer.sysGeneratedUrl,
  redirectionData: state.page.redirectionData,
  storeId: state.page.homepageState.storeId,
  settings: state.common.settings,
  countryData: state.common.countryData,
  ipCountryData: state.common.ipCountryData,
  loader: state.common.showLoader,
  routeLanguage:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  brandPageDetails: state.productListingReducer.brandPageDetails,
  colors: state.common.colors,
  wishListAndCartBaseDataLoaded: state.cartReducer.wishListAndCartGeneralLoaded,
  deleteWishListItemLoaded: state.cartReducer.deleteWishListItemLoaded,
  wishListGeneralProducts: selectWishListGeneralData(state),
  configCdnImagesSettings: selectCdnImageSettings(state)
});

const mapDispatchToProps = {
  getProductsData,
  handleLanguageChange,
  handleGridChange,
  handlePageChange,
  setPageRoute,
  toggleQuicklookView,
  toggleAppOnlyItemModal,
  saveToWishList,
  getWishListBaseData,
  deleteWishListData,
  saveToCart,
  fetchCartData,
  setProducts,
  showLoadingIndicator,
  hideLoadingIndicator,
  handleFacetChange,
  handleRangeChange,
  handleSizeSelection,
  clearSizeSelection,
  setInitialState,
  handleURLChange,
  setHomeBreadcrumb,
  fetchGeneratedUrls,
  fetchAndSetBrandPageUrlsFromSeoSafeUrls,
  getColors,
  getCartAndWishlistGeneralData,
  clearRedirectionData
};

export default compose(
  withDeliverySettingsFetching,
  withRouter,
  withLanguageFromUrl,
  withAnalyticPageBrowseTracking,
  connect(mapStateToProps, mapDispatchToProps),
  withPageTypeTracking
)(ProductListing);
