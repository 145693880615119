import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Scrollbars } from "react-custom-scrollbars";
import cn from "classnames";
import queryString from "query-string";
import moment from "moment";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { selectRouteSlug } from "../../util/selectors";
import { MEMBERSHIP_STYLE_CLASS } from "../../constants/loyalty-points";
import * as priceUtil from "../../util/priceUtil";
import { getCardList } from "../../util/my-account";

import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import OverViewCard from "../../components/my-acc/myAcc-overview-card";
import RecentOrder from "../../components/my-acc/myAcc-recent-order";
import RecentOrderReturnVersion from "../../components/my-acc/myAcc-recent-order-version-return";
import DetailOverview from "../../components/my-detail-overview";
import LoyaltyPointsOverview from "../../components/loyalty/loyalty-points-overview";
import LoyaltyGeneralInfo from "../../components/loyalty/loyalty-points-general-info";
import AddressBook from "../../components/address/address-book";
import AddressForm from "./address-form";
import SaveCard from "../../components/saved-cards";
import ModalBFL from "../../components/modal/modal";
import Loader from "../../components/loader";
import {
  loadCreditCards,
  setAddress,
  deleteCreditCard
} from "../../redux/actions/checkout.action";
import {
  deleteAddress,
  setDefaultAddress
} from "../../redux/actions/authConfig.action";
import {
  saveAddress,
  resetAPIError
} from "../../redux/actions/homepage.action";
import {
  registerLoyaltyProgram,
  fetchMyOrders,
  toggleAddNewCardTab,
  fetchEarnedLoyaltyPoints
} from "../../redux/actions/myAccount.action";
import { resetAddress } from "../../redux/actions/address.action";
import {
  getCountryList,
  setUserOriginCountry
} from "../../redux/actions/common.action";
import { ENV_CONFIG } from "../../config/env";
import { LOADER_TYPES, commonLoaderStyle } from "../../constants";
import AddAddressMobileNumber from "../../components/modal/modal-mobile-verification/addAddressMobileNumber";
import { GAService } from "../../services/GA-service";

const loaderStyle = {
  ...commonLoaderStyle,
  backgroundColor: "rgb(255 255 255 / 75%)"
};

class AccountOverview extends Component {
  state = {
    openAddressModal: false,
    userMobileCountryCode: "",
    userOriginCountry: null,
    showOTPModal: false
  };

  componentDidMount() {
    const {
      language,
      fetchMyOrders,
      loadCreditCards,
      location,
      countryData,
      currencyCode,
      userInfo,
      countryId,
      fetchEarnedLoyaltyPoints
    } = this.props;
    const { page } = queryString.parse(location.search, {
      parseNumbers: true
    });

    if (userInfo && !isEmpty(countryData)) {
      this.updateUserOriginCountry();
    }

    fetchMyOrders(language, page || 1);
    loadCreditCards();
    if (currencyCode) {
      fetchEarnedLoyaltyPoints(language, currencyCode, countryId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userInfo,
      countryData,
      currencyCode,
      language,
      countryId,
      fetchEarnedLoyaltyPoints
    } = this.props;
    if (
      !isEqual(prevProps.userInfo, userInfo) ||
      !isEqual(prevProps.countryData, countryData)
    ) {
      this.updateUserOriginCountry();
    }
    if (!isEqual(prevProps.currencyCode, currencyCode)) {
      fetchEarnedLoyaltyPoints(language, currencyCode, countryId);
    }
  }

  componentWillUnmount() {
    const { resetAPIError } = this.props;
    resetAPIError();
  }

  async updateUserOriginCountry() {
    const { userInfo, countryData, setUserOriginCountry, getCountryList } =
      this.props;
    const { userMobileCountryCode } = this.state;
    try {
      const updatedUserInfo = {
        ...userInfo,
        countryId: userInfo.countryId || ENV_CONFIG.countryId
      };
      if (updatedUserInfo.countryId && !userMobileCountryCode.length) {
        if (countryData.length === 1) {
          await getCountryList();
        }
        const result = countryData.find(
          item => item.countryId === updatedUserInfo.countryId
        );
        this.setState({
          userMobileCountryCode: result.mobileCountryCode,
          userOriginCountry: result
        });
        setUserOriginCountry(result);
      }
    } catch (error) {}
  }

  handleRemoveCard = id => this.props.deleteCreditCard(id);

  myDetailsHandleAction = (position, title, subTab = false) => {
    const { selectList } = this.props;
    //this.props.toggleAddNewCardTab(subTab)
    selectList(position, title, subTab);
  };
  handleEditAddress = address => {
    this.setState({ openAddressModal: true });
    this.props.setAddress(address);
  };
  toggleAddressModal = value => {
    const { resetAddress } = this.props;
    this.setState({ openAddressModal: value });
    if (value === false) {
      resetAddress && resetAddress();
    }
  };
  handleDefaultAddress = (e, index, address) => {
    const { setDefaultAddress, userInfo, saveAddress } = this.props;
    setDefaultAddress(
      e.target.name,
      e.target.type === "checkbox" ? e.target.checked : e.target.value,
      index
    );
    if (userInfo.addressBook[index]) {
      userInfo.addressBook[index].addressLine2 =
        userInfo.addressBook[index].addressLine2 || "";
      saveAddress({
        ...address,
        default: e.target.checked,
        lastname: address.lastname || ""
      });
    }
  };

  updateAddress = async data => {
    const {
      saveAddress,
      address: { addressId }
    } = this.props;
    const addressData = this.getTransformedAddressData(data, addressId);

    const response = await saveAddress(addressData);
    if (response.OTPNeeded) {
      this.setState({
        showOTPModal: true
      });
      return;
    }
    this.toggleAddressModal(false);
  };

  getTransformedAddressData = (
    {
      address,
      area,
      phoneArea = "",
      city,
      country,
      isDefaultAddress,
      firstName,
      lastName,
      phoneNumber,
      zipCode
    },
    addressId
  ) => {
    const areaCode = phoneArea || this.defaultCountryPhoneArea || "";
    const dialCode = this.props.mobileCountryCode;

    return {
      addressLine1: address.trim(),
      area: area.value,
      areaCode,
      areaId: area.key,
      cityId: city.key,
      city: city.value,
      country: country.value,
      countryId: country.key,
      default: isDefaultAddress,
      dialCode,
      firstname: firstName,
      lastname: lastName,
      phone: dialCode + areaCode.toString() + phoneNumber,
      zipcode: zipCode.toString(),
      addressId
    };
  };

  getTitleForLoyaltyCard = () => {
    const {
      LoyaltyStatics: { loyaltyDetails },
      myAccountTranslation: t
    } = this.props;

    switch (MEMBERSHIP_STYLE_CLASS[get(loyaltyDetails, "memeberLevel", "")]) {
      case "yellow":
        return (
          <>
            <>{t.loyaltyPoints}</>
            {" - "}
            <b className="card-tier-name">{t.yellowTier}</b>
          </>
        );
      case "black":
        return (
          <>
            <>{t.loyaltyPoints}</>
            {" - "}
            <b className="card-tier-name">
              {t.black} {t.tier}
            </b>
          </>
        );

      default:
        return t.loyaltyProgram;
    }
  };

  getLoyaltyCardContent = () => {
    const {
      LoyaltyStatics: { loyaltyDetails },
      myAccountTranslation,
      currencyCode,
      apiErrorDetail
    } = this.props;

    if (isUndefined(loyaltyDetails)) {
      return (
        <Loader
          type={LOADER_TYPES.RING}
          width={25}
          height={25}
          visible
          style={loaderStyle}
        />
      );
    } else if (isNull(loyaltyDetails)) {
      return (
        <LoyaltyGeneralInfo
          onJoinLoyaltyProgram={this.onJoinLoyaltyProgram}
          translation={myAccountTranslation}
          errorMessage={apiErrorDetail.message}
        />
      );
    }
    return (
      <LoyaltyPointsOverview
        myAccountTranslation={myAccountTranslation}
        loyaltyDetails={loyaltyDetails}
        currencyCode={currencyCode}
      />
    );
  };

  onJoinLoyaltyProgram = () => {
    const {
      customerId,
      countryId,
      registerLoyaltyProgram,
      language,
      currencyCode
    } = this.props;
    registerLoyaltyProgram({ customerId, countryId }, language, currencyCode);
  };

  onSubmitBtnClick = () => {
    const { checkoutTranslationEn } = this.props;
    GAService.common.trackCTAClick(checkoutTranslationEn.saveAddress, "button");
  };

  render() {
    const {
      myAccountTranslation,
      orderSuccessTranslation,
      userInfo,
      language,
      myOrders,
      LoyaltyStatics: { loyaltyDetails },
      deleteAddress,
      mobileCountryCode,
      deliveryOptions,
      history,
      routeSlug,
      countryId,
      savedCardDisplayType,
      checkoutCardsList,
      checkoutTranslation,
      enableReturnVersion
    } = this.props;
    const {
      openAddressModal,
      userMobileCountryCode,
      userOriginCountry,
      showOTPModal
    } = this.state;

    const cardList = getCardList(savedCardDisplayType, checkoutCardsList);

    return (
      <div className="overview-wrapper my-account-overview">
        <OverViewHeading
          headingName={myAccountTranslation.accountOverview}
          icon="my_account_icon"
          handleGoBack={() =>
            this.myDetailsHandleAction(
              "my-details",
              myAccountTranslation.myDetails
            )
          }
        />
        <div className="row">
          <div className="col col-12 col-xl-6">
            {userOriginCountry && Object.keys(userOriginCountry).length && (
              <OverViewCard
                isTitleStrip={true}
                isBorder={true}
                title={myAccountTranslation.myDetails}
                action={myAccountTranslation.edit}
                handleAction={() =>
                  this.myDetailsHandleAction(
                    "my-details",
                    myAccountTranslation.myDetails
                  )
                }
              >
                <DetailOverview
                  userInfo={userInfo}
                  myAccountTranslation={myAccountTranslation}
                  language={language}
                  orderSuccessTranslation={orderSuccessTranslation}
                  mobileCountryCode={userMobileCountryCode}
                  userOriginCountry={userOriginCountry}
                />
              </OverViewCard>
            )}
          </div>

          <div className="col col-12 col-xl-6">
            <OverViewCard
              action={
                isNull(loyaltyDetails) && myAccountTranslation.viewBenefits
              }
              handleAction={() =>
                history.replace(`/${routeSlug}/loyaltyprogram/`)
              }
              isTitleStrip={true}
              className={
                MEMBERSHIP_STYLE_CLASS[get(loyaltyDetails, "memeberLevel", "")]
              }
              title={this.getTitleForLoyaltyCard()}
            >
              {this.getLoyaltyCardContent()}
            </OverViewCard>
          </div>

          {myOrders.order && myOrders.order.length !== 0 && (
            <div className="col-md-12">
              <OverViewCard
                isTitleStrip={true}
                title={myAccountTranslation.myRecentOrder}
                action={myAccountTranslation.viewAll}
                handleAction={() =>
                  this.myDetailsHandleAction(
                    "my-orders",
                    myAccountTranslation.myRecentOrder
                  )
                }
              >
                {myOrders &&
                  myOrders.order &&
                  myOrders.order.map((order, index) => (
                    <div key={order.orderId}>
                      {index < 3 &&
                        (enableReturnVersion ? (
                          <RecentOrderReturnVersion
                            accordion={false}
                            orderId={order.orderId}
                            totalAmount={`${
                              order.CurrencyCode
                            } ${priceUtil.shapePriceWithComma(
                              order.transactionAmount
                            )} `}
                            orderPlaced={moment
                              .utc(order.createDate)
                              .format("LLL")}
                            estimatedDelivery={
                              order.deliveryDate
                                ? moment(order.deliveryDate).format("LLL")
                                : ""
                            }
                            myAccountTranslation={myAccountTranslation}
                            language={language}
                            order={order}
                            handleAction={() =>
                              this.myDetailsHandleAction(
                                "my-orders",
                                myAccountTranslation.myRecentOrder
                              )
                            }
                            deliveryOptions={deliveryOptions}
                          />
                        ) : (
                          <RecentOrder
                            accordion={false}
                            orderId={order.orderId}
                            totalAmount={`${
                              order.CurrencyCode
                            } ${priceUtil.shapePriceWithComma(
                              order.transactionAmount
                            )} `}
                            orderPlaced={moment
                              .utc(order.createDate)
                              .format("LLL")}
                            estimatedDelivery={
                              order.deliveryDate
                                ? moment(order.deliveryDate).format("LLL")
                                : ""
                            }
                            myAccountTranslation={myAccountTranslation}
                            language={language}
                            order={order}
                            handleAction={() =>
                              this.myDetailsHandleAction(
                                "my-orders",
                                myAccountTranslation.myRecentOrder
                              )
                            }
                            deliveryOptions={deliveryOptions}
                          />
                        ))}
                    </div>
                  ))}
              </OverViewCard>
            </div>
          )}

          <div className="col col-12 col-xl-6">
            <OverViewCard
              isTitleStrip={true}
              isBorder={true}
              title={myAccountTranslation.myAddressBook}
              action={myAccountTranslation.viewAll}
              handleAction={() =>
                this.myDetailsHandleAction(
                  "my-address-book",
                  myAccountTranslation.myAddressBook
                )
              }
            >
              <div className="address_book_overview">
                {userInfo.addressBook.length !== 0 &&
                userInfo.addressBook.find(
                  address => address.countryId === countryId
                ) ? (
                  <AddressBook
                    index={0}
                    address={userInfo.addressBook.find(
                      address => address.countryId === countryId
                    )}
                    handleEditAddress={this.handleEditAddress}
                    handleDefaultAddress={this.handleDefaultAddress}
                    key={userInfo.addressBook[0].addressId}
                    myAccountTranslation={myAccountTranslation}
                    orderSuccessTranslation={orderSuccessTranslation}
                    handleDeleteAddress={deleteAddress}
                    language={language}
                    mobileCountryCode={mobileCountryCode}
                  />
                ) : (
                  <input
                    type="button"
                    className="round_btn form_black_btn account_cta"
                    value={myAccountTranslation.addNewAddress}
                    onClick={() =>
                      this.myDetailsHandleAction(
                        "my-address-book",
                        myAccountTranslation.myAddressBook,
                        true
                      )
                    }
                  />
                )}
                <ModalBFL
                  showModal={openAddressModal}
                  modalClass={`login_modal change_address_modal ${
                    language.includes("ar-") || language.includes("ar")
                      ? "arabic"
                      : ""
                  }`}
                >
                  <button
                    className="cross_btn"
                    onClick={e => this.toggleAddressModal(false)}
                  >
                    &times;
                  </button>
                  <h2>{checkoutTranslation.editAddress}</h2>
                  <Scrollbars
                    autoHeight
                    autoHeightMin={200}
                    autoHeightMax={500}
                  >
                    <AddressForm
                      closeModal={() => this.toggleAddressModal(false)}
                      checkoutTranslation={checkoutTranslation}
                      language={language}
                      submitButtonText={checkoutTranslation.saveAddress}
                      onSubmit={this.updateAddress}
                      onSubmitBtnClick={this.onSubmitBtnClick}
                    />
                  </Scrollbars>
                </ModalBFL>
                <AddAddressMobileNumber
                  showModal={showOTPModal}
                  onSuccessOtp={() => this.toggleAddressModal(false)}
                  closeModal={() => this.setState({ showOTPModal: false })}
                />
              </div>
            </OverViewCard>
          </div>

          <div className="col col-12 col-xl-6">
            <OverViewCard
              isTitleStrip={true}
              isBorder={true}
              title={myAccountTranslation.paymentMethod}
              action={myAccountTranslation.viewAll}
              shadow={true}
              handleAction={() =>
                this.myDetailsHandleAction(
                  "saved-cards",
                  myAccountTranslation.paymentMethod
                )
              }
            >
              <div
                className={cn("account-card", {
                  add_new_card_btn: !cardList.length
                })}
              >
                {cardList.length ? (
                  <SaveCard
                    myAccountTranslation={myAccountTranslation}
                    card={cardList[0]}
                    handleRemoveCard={this.handleRemoveCard}
                  />
                ) : (
                  <p className="no_card_message">
                    {myAccountTranslation.noCardDesc}
                  </p>
                )}
              </div>
            </OverViewCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  address: get(state, "addressReducer"),
  userInfo: get(state, "authReducer"),
  myOrders: get(state, "myAccountReducer.myOrders"),
  LoyaltyStatics: get(state, "myAccountReducer.loyaltyPoints.LoyaltyStatics"),
  language: get(state, "common.language"),
  mobileCountryCode: get(state, "common.settings.mobileCountryCode"),
  addNewCardTab: get(state, "myAccountReducer.addNewCardTab"),
  customerId: get(state, "authReducer.customerId"),
  countryId: get(state, "common.settings.countryId"),
  countryData: get(state, "common.countryData"),
  currencyCode: get(state, "common.settings.currencyCode"),
  checkoutCardsList: get(state, "checkoutReducer.creditCardList"),
  savedCardDisplayType: get(state, "common.settings.savedCardDisplayType"),
  enableReturnVersion: get(state, "common.settings.enableReturnVersion"),
  routeSlug: selectRouteSlug(state),
  apiErrorDetail: state.homepage.APIErrorResponse
});

const mapStateToDispatch = {
  setAddress,
  setUserOriginCountry,
  setDefaultAddress,
  saveAddress,
  deleteAddress,
  toggleAddNewCardTab,
  resetAddress,
  fetchMyOrders,
  registerLoyaltyProgram,
  loadCreditCards,
  deleteCreditCard,
  resetAPIError,
  fetchEarnedLoyaltyPoints,
  getCountryList
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapStateToDispatch)
)(AccountOverview);
