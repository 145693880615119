import React, { useRef, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRedirectionUrl, isLinkStartsFromHTTPS } from "../../../util/common";
import { selectRouteSlug } from "../../../util/selectors";
import BannerLoaderImage from "../../banner/banner-loader-image";
import LoaderIcon from "../../../images/loading-icon.svg";
import { isMobile } from "../../../util";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";

const stylesForSingleRow = (
  { hover, ...restStyles },
  { hovered, imageWidth, marginForImage, hoverValues }
) => ({
  ...restStyles,
  ...(!isMobile.any() && hovered && hoverValues && hover),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: `1 0 calc(${imageWidth}% - ${marginForImage}px)`
});

const removeBackgroundColor = el => {
  if (el) {
    el.style.background = "none";
  }
};

const CarouselItem = ({
  imageContent,
  language,
  imageWidth,
  dataForAnalytic,
  itemIndex
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const routeSlug = useSelector(selectRouteSlug);
  const imageLinkWrapperRef = useRef(null);
  const styles = imageContent.styles;
  const content = imageContent[language];
  const redirectionLink = content?.redirectionLink;
  const hoverValues = styles.hoverValues?.transform;
  const marginForImage = styles.marginLeft + styles.marginRight || 0;
  const isExternalLink =
    redirectionLink && isLinkStartsFromHTTPS(redirectionLink);
  const redirectionPath = isExternalLink
    ? redirectionLink
    : `/${routeSlug}${redirectionLink || "/"}`;
  const linkTarget = isExternalLink ? "_blank" : "_self";

  return (
    <Link
      className={cn("carousel-link", {
        "disabled-link": !redirectionLink
      })}
      to={getRedirectionUrl(isExternalLink, redirectionPath)}
      target={linkTarget}
      style={stylesForSingleRow(styles, {
        imageWidth,
        marginForImage,
        hovered,
        hoverValues
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-banner-link={redirectionLink}
      data-banner-index={itemIndex}
    >
      <BannerIntersectionObserver data={dataForAnalytic}>
        <div
          className="banner-element"
          style={{ paddingBottom: `${content?.ratio}%` }}
          ref={imageLinkWrapperRef}
        >
          <div className="image-wrapper">
            {imageLoading && <BannerLoaderImage loaderIcon={LoaderIcon} />}
            <img
              src={imageContent[language]?.imageURL}
              alt="banner-image"
              className="banner-image"
              loading="lazy"
              onLoad={() => {
                setImageLoading(false);
                removeBackgroundColor(imageLinkWrapperRef.current);
              }}
            />
          </div>
        </div>
      </BannerIntersectionObserver>
    </Link>
  );
};

export default CarouselItem;
