export const SHOW_SIGNUP_MODAL = "SHOW_SIGNUP_MODAL";
export const SIGN_IN_MODAL = "SIGN_IN_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const HIDE_SIGNUP_MODAL = "HIDE_SIGNUP_MODAL";
export const CREATE_USER = "CREATE_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const HANDLE_TEXT_CHANGE = "HANDLE_TEXT_CHANGE";
export const HOME_PAGE_DATA = "HOME_PAGE_DATA";
export const SHOW_FORGOTPASSWORD_MODAL = "SHOW_FORGOTPASSWORD_MODAL";
export const HIDE_FORGOTPASSWORD_MODAL = "HIDE_FORGOTPASSWORD_MODAL";
export const RESET_PASSWORD_TOKEN_VALIDATION =
  "RESET_PASSWORD_TOKEN_VALIDATION";
export const API_ERROR = "API_ERROR";
export const RESET_API_ERROR = "RESET_API_ERROR";
export const API_NOTIFICATION_MSG = "API_NOTIFICATION_MSG";
export const RESET_NOTIFICATION_MSG = "RESET_NOTIFICATION_MSG";
export const RESET_PASSWORD_DATA = "RESET_PASSWORD_DATA";
export const IS_HOME_PAGE = "IS_HOME_PAGE";
export const HOME_PAGE_ERROR = "HOME_PAGE_ERROR";
export const HOME_PAGE_SECTION_DATA = "HOME_PAGE_SECTION_DATA";
