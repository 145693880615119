export const CLEAR_BANNERS_SECTIONS = "CLEAR_BANNERS_SECTIONS";
export const HOME_PAGE_DATA = "HOME_PAGE_DATA";
export const HOME_PAGE_SECTION_DATA = "HOME_PAGE_SECTION_DATA";
export const REDIRECTION_DATA = "REDIRECTION_DATA";
export const CLEAR_REDIRECTION_DATA = "CLEAR_REDIRECTION_DATA";
export const SET_STORE_LIST = "SET_STORE_LIST";
export const SET_META = "SET_META";
export const CLEAR_META = "CLEAR_META";
export const SET_ANALITIC_DATA = "SET_ANALITIC_DATA";
export const SET_PAGE_TYPE = "SET_PAGE_TYPE";
