import { get, lowerCase } from "lodash";
import { getCommerceDataForListingFormat } from "../utils";
import { myCart } from "../../../language/myCart/en/myCart";
import { checkoutTranslation } from "../../../language/checkoutFlow/en/checkoutFlow";
import GAExtensions from "../extensions";

class CheckoutService extends GAExtensions {
  getPaymentMethods = (shortCode = "") => {
    return checkoutTranslation.paymentMethods?.[shortCode]?.title || shortCode;
  };
  trackInitiateCheckout = data => {
    const { items, totalAmount } = data;
    const { currencyCode } = this.getConfigData();
    const { payload: products } = getCommerceDataForListingFormat(items, true);
    this.pushToDataLayer({
      event: "begin_checkout",
      ecommerce: {
        currency: currencyCode,
        value: totalAmount,
        items: products
      }
    });
  };

  trackAddShippingInfo = data => {
    const { items, totalAmount, deliveryType } = data;
    const { currencyCode } = this.getConfigData();
    const { payload: products } = getCommerceDataForListingFormat(items, true);
    this.pushToDataLayer({
      event: "add_shipping_info",
      ecommerce: {
        value: totalAmount,
        currency: currencyCode,
        coupon: undefined,
        shipping_tier: lowerCase(deliveryType),
        items: products
      }
    });
  };
  trackApplyVoucher = data => {
    const { code = "", voucherData = {} } = data;
    const message = voucherData.en_message || voucherData.message || "";
    this.pushToDataLayer({
      event: "apply_voucher",
      voucher_code: lowerCase(code),
      voucher_message: this.sanitizeString(message)
    });
  };

  trackProductPurchase = ({ orderData, coupon = "" }) => {
    const {
      transactionShippingCharge,
      transactionAmount,
      deliveryType,
      vatper = 0,
      paymentMode,
      currencyCode,
      items,
      discountType = "",
      orderId
    } = orderData;
    let appliedCoupon = coupon || discountType || "";
    let { payload: products } = getCommerceDataForListingFormat(items);
    const paymentModesFullNameArray = paymentMode.map(mode => {
      return this.getPaymentMethods(mode);
    });
    this.pushToDataLayer({
      event: "purchase",
      ecommerce: {
        affiliation: "bfl online store",
        currency: lowerCase(currencyCode),
        value: transactionAmount,
        coupon: this.sanitizeString(appliedCoupon),
        shipping: transactionShippingCharge,
        transaction_id: `${orderId}`.toLowerCase(),
        tax: 0,
        payment_type: paymentModesFullNameArray?.join("|")?.toLowerCase(),
        shipping_tier: myCart[deliveryType]?.toLowerCase(),
        items: products
      }
    });
  };
  trackContinueAsGuest = () => {
    this.pushToDataLayer({
      event: "continue_as_guest",
      login_status: "logged out"
    });
  };
  trackPaymentTypeSelected = paymentType => {
    const paymentModeFullName = this.getPaymentMethods(paymentType);
    this.pushToDataLayer({
      event: "payment_type_selected",
      payment_type: lowerCase(paymentModeFullName)
    });
  };
  trackTrackOrder = order_id => {
    this.pushToDataLayer({
      event: "track_order",
      order_id
    });
  };
  trackAddPaymentInfo = ({ orderData }) => {
    const { currencyCode } = this.getConfigData();
    const paymentType = get(orderData, "selectedPaymentOption.paymentType", "");
    const orderItems = get(orderData, "orderItems", []);
    const discount = get(orderData, "discount", 0);
    const coupon = get(orderData, "voucherCode", "");

    const { payload: products, totalPrice } = getCommerceDataForListingFormat(
      orderItems,
      true
    );
    const paymentTypeFullName = this.getPaymentMethods(paymentType);
    this.pushToDataLayer({
      event: "add_payment_info",
      ecommerce: {
        currency: currencyCode,
        value: totalPrice - +discount,
        coupon: this.sanitizeString(coupon),
        payment_type: paymentTypeFullName?.toLowerCase(),
        items: products
      }
    });
  };

  trackPaymentError = data => {
    const { payment_method, error_message } = data;
    const paymentMethodFullName = this.getPaymentMethods(payment_method);

    this.pushToDataLayer({
      event: "payment_error",
      payment_method: paymentMethodFullName?.toLowerCase(),
      error_message
    });
  };

  trackLocationPointOnCheckout = dataObj => {
    const { name = "", city = "" } = dataObj;
    this.pushToDataLayer({
      event: "pin_your_location",
      location: `${name}, ${city}`.trim().toLowerCase(),
      status: "success"
    });
  };
  trackPickUpStoreSelect = data => {
    const { name = "", city = "" } = data;
    this.trackLocationPointOnCheckout({
      name,
      city
    });
  };
  trackCollectionPtPickUp = data => {
    const stationName = data.en_station_name || data.station_name || "";
    this.trackLocationPointOnCheckout({
      name: this.sanitizeString(stationName),
      city: this.sanitizeString(data.city)
    });
  };
}

export default CheckoutService;
