import React, { Component } from "react";
import { Image as CustomImage } from "../image";
import { isServer } from "../../util";

class HoverImage extends Component {
  state = {
    imageSrc: "",
    zoomedImage: "",
    cx: 0,
    cy: 0,
    lensXOffset: 0,
    lensYOffset: 0,
    xPosition: 0,
    yPosition: 0
  };

  handleMouseMove = (e, imgUrl) => {
    const { handleImageMouseMove, forwardRef, zoomedBlockRef } = this.props;
    const {
      cx,
      cy,
      lensXOffset,
      lensYOffset,
      xPosition,
      yPosition
    } = this.state;

    const containerHeight =
      forwardRef &&
      forwardRef.firstElementChild &&
      forwardRef.firstElementChild.offsetHeight;
    const imgHeight = e && e.target && e.target.naturalHeight;

    if (imgHeight && containerHeight && imgHeight < containerHeight) {
      this.props.forwardRef.firstElementChild.classList.add("hideZoom");
      return null;
    } else {
      this.props.forwardRef.firstElementChild.classList.remove("hideZoom");
    }
    const { height, width } = e.target.getBoundingClientRect();

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const centeredX = x - lensXOffset;
    const centeredY = y - lensYOffset;

    if (zoomedBlockRef && zoomedBlockRef.current) {
      const zoomedRect = zoomedBlockRef.current.getBoundingClientRect();

      if (imgUrl !== this.state.imageSrc) {
        const zoomedImage = new Image();
        zoomedImage.src = imgUrl;
        zoomedImage.onload = () => {
          const newImageWidth = zoomedImage.width;
          const newImageHeight = zoomedImage.height;
          const cx = newImageWidth / width;
          const cy = newImageHeight / height;
          const lensYOffset = (0.5 * zoomedRect.height) / cy;
          const lensXOffset = (0.5 * zoomedRect.width) / cx;

          this.setState({
            imageSrc: imgUrl,
            zoomedImage,
            cx,
            cy,
            lensYOffset,
            lensXOffset
          });
        };
      }
    }
    let newXPosition = -centeredX * cx;
    let newYPosition = -centeredY * cy;

    if (centeredX < 0 || x > width - lensXOffset) {
      newXPosition = xPosition;
    }

    if (centeredY < 0 || y > height - lensYOffset) {
      newYPosition = yPosition;
    }

    if (handleImageMouseMove)
      handleImageMouseMove({
        backgroundImage: imgUrl,
        isHover: true,
        backgroundPosition: `${newXPosition}px ${newYPosition}px`
      });
    this.setState({ xPosition: newXPosition, yPosition: newYPosition });
  };

  replaceSpace = name => {
    try {
      return name.replace(/ /g, "%20");
    } catch (error) {
      return name;
    }
  };

  handleMoveOut = () =>
    this.props.handleImageMouseOut && this.props.handleImageMouseOut();

  render() {
    const { lazyLoad, url } = this.props;
    const hoverImg = this.replaceSpace(this.props.hoverImg);
    return (
      <div
        onMouseMove={e =>
          !isServer && this.handleMouseMove(e, `${url}${hoverImg}`)
        }
        onMouseOut={this.handleMoveOut}
      >
        <CustomImage src={`${url}${hoverImg}`} lazyLoad={lazyLoad} />
      </div>
    );
  }
}

export default HoverImage;
