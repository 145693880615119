import { createSelector } from "reselect";
import {
  selectCheckoutData,
  selectCommonSettings,
  selectCurrency,
  selectLanguageSlug,
  selectWalletData
} from "../../../util/selectors";

export const selectOrderPaymentOption = createSelector(
  selectCheckoutData,
  data => data?.selectedPaymentOption
);

export const selectPaymentOptionDetails = createSelector(
  selectOrderPaymentOption,
  payment => ({
    paymentCurrencyCode: payment?.currencyCode,
    paymentCurrency: payment?._paymentCurrency,
    paymentCurrencyExchangeRate: payment?._paymentCurrencyExchangeRate
  })
);

export const selectOrderDiscountDetails = createSelector(
  selectCheckoutData,
  ({ discount, discount_type }) => ({
    discount,
    discountType: discount_type
  })
);

export const selectOrderCodFee = createSelector(
  selectOrderPaymentOption,
  data => data?.cod_fee || 0
);

export const selectCommonSettingsDetails = createSelector(
  selectCommonSettings,
  selectLanguageSlug,
  (settings, language) => ({
    pricePreviewHTML: settings[`${language}_pricePreviewHTML`],
    pricePreviewBoxHTML: settings[`${language}_pricePreviewHTMLBox`],
    decimalPlaces: settings?.decimalPlaces
  })
);

export const selectWalletAmount = createSelector(
  selectWalletData,
  wallet => wallet?.walletAmount
);

export const selectCurrentWallet = createSelector(
  selectWalletAmount,
  selectCurrency,
  (amount, currency) =>
    amount?.find(walletAmount => (walletAmount.currencyCode = currency))
);

export const selectTransactionWalletAmount = createSelector(
  selectCurrentWallet,
  wallet => wallet?.transactionAmount
);
