import React, { PureComponent, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { getProductUrlName, itemsInCart } from "../../../util";
import * as priceUtil from "../../../util/priceUtil";

import Header from "./header";
import ContentImage from "../../content/content-image";
import ContentDescription from "../../content/content-description";
import CartSubTotal from "./subTotalOfCart";
import FloatingMessage from "../../floating-message";

class CartHoverDropdown extends PureComponent {
  static defaultProps = {
    cart: []
  };

  state = {
    showToaster: false
  };

  componentDidMount() {
    if (!this.isMyCartPage()) {
      document.addEventListener("scroll", this.handlePageScroll);
    }
  }

  componentWillUnmount() {
    if (!this.isMyCartPage()) {
      document.removeEventListener("scroll", this.handlePageScroll);
    }
  }

  handlePageScroll = () => {
    const { showToaster } = this.state;

    const scrollToHide = 200;

    if (window.pageYOffset >= scrollToHide) {
      if (showToaster) this.setState({ showToaster: false });
    }
  };

  isMyCartPage() {
    const { location } = this.props;

    return location.pathname.includes("/my-cart");
  }

  UNSAFE_componentWillReceiveProps(nextProp) {
    const { fromCheckout, setStatusOnAddingNewProduct } = this.props;

    const isNextLocationMyCart =
      nextProp.location.pathname.includes("/my-cart");

    if (!fromCheckout && !isNextLocationMyCart && nextProp.isAddedNewProducts) {
      setStatusOnAddingNewProduct(false);

      setTimeout(() => this.setState({ showToaster: true }), 1000);
      setTimeout(() => this.setState({ showToaster: false }), 4000);
    }
  }

  getTotal() {
    const { cart, commonSettings } = this.props;
    const total = cart
      .filter(item => item !== null)
      .map(item => {
        const quantity = Number(item.quantity) || 1;
        return Number(item.currentPrice) * quantity;
      })
      .reduce((total, num) => total + num, 0);

    return total;
  }

  getProductUrl({ en_title = "", title, productId = "" }) {
    const { language, commonSettings } = this.props;

    const baseUrl = `${language}-${get(
      commonSettings,
      "countrySHORT",
      "ae"
    ).toLowerCase()}`;

    const productUrlName = getProductUrlName(en_title || title || "") || "---";

    return `/${baseUrl}/${productUrlName}/${productId}/p/`;
  }

  renderTrackHorizontal = props => (
    <div {...props} className="track-horizontal" />
  );

  render() {
    const {
      language,
      translation,
      cart,
      deleteCartList,
      cartSubTotal,
      validateCheckout,
      currencyCode,
      moveToCart,
      commonSettings,
      logOutGuestUser,
      showCrossIcon,
      showDiscountSlab,
      configCdnImagesSettings
    } = this.props;
    const { showToaster } = this.state;

    const total = this.getTotal();
    const count = itemsInCart(cart);
    const isPriceAvailableForAllProducts = isEmpty(cart)
      ? true
      : cart.every(product =>
          priceUtil.isPriceAvailableByUserIpcountry(product)
        );

    return (
      <div
        className={cn(
          "cart_hover_dropdown_wrapper",
          { arabic: language.includes("ar") },
          { itemAdded: showToaster }
        )}
      >
        <Header
          translation={translation}
          count={count}
          total={total}
          currencyCode={currencyCode}
        />
        {Boolean(count) && (
          <div className="inner_wrapper">
            {showToaster && (
              <FloatingMessage translation={translation} count={1} />
            )}
            <div className="items_in_user_cart">
              <Scrollbars
                autoHeight
                autoHeightMin={42}
                autoHeightMax={250}
                renderTrackHorizontal={this.renderTrackHorizontal}
              >
                {cart
                  .sort(product =>
                    priceUtil.isPriceAvailableByUserIpcountry(product) ? 0 : -1
                  )
                  .map((item, index) => {
                    const isPriceAvailable =
                      priceUtil.isPriceAvailableByUserIpcountry(item);

                    return (
                      <div className="user_cart_item" key={index}>
                        <div onClick={logOutGuestUser}>
                          <Link to={this.getProductUrl(item)}>
                            <ContentImage
                              img={item && item.captionImageURL}
                              url={configCdnImagesSettings?.thumb}
                              showDefaultImage
                            />
                          </Link>
                        </div>
                        <div className="cart_item_content">
                          <ContentDescription
                            item={item}
                            translation={translation}
                            language={language}
                            currencyCode={currencyCode}
                            commonSettings={commonSettings}
                            getProductUrlName={getProductUrlName}
                            showDiscountSlab={showDiscountSlab}
                            Redirection
                            showEllipses
                            isProductAvailableInCountry={isPriceAvailable}
                            cartOnDiscount
                          />

                          <div className="product_size_info">
                            {isPriceAvailable && (
                              <p className="quantity_in_cart">
                                {translation && translation.quantity}:
                                <span>{get(item, "quantity", 1)}</span>
                              </p>
                            )}
                            {isPriceAvailable && Boolean(item && item.size) && (
                              <p className="size_in_cart">
                                {translation && translation.size}:
                                <span title={item.size}>{item.size}</span>
                              </p>
                            )}

                            {!isPriceAvailable && (
                              <div className="error">
                                {translation.notAvailableIn}
                              </div>
                            )}
                          </div>
                        </div>
                        {showCrossIcon && (
                          <div
                            className="cross-icon"
                            onClick={e => deleteCartList(e, item)}
                          >
                            {/* <p className="tooltip_alert">{translation && translation.quantityAlert}</p> */}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Scrollbars>
            </div>
            {!cartSubTotal && (
              <Fragment>
                <CartSubTotal
                  translation={translation}
                  total={total}
                  currencyCode={currencyCode}
                  commonSettings={commonSettings}
                />
                <div className="cart_dropdown_action">
                  <button className="small_grey_btn" onClick={moveToCart}>
                    {translation.viewBag}
                  </button>
                  <button
                    className={cn("small_yellow_btn", {
                      disabled: !isPriceAvailableForAllProducts
                    })}
                    onClick={validateCheckout}
                    disabled={!isPriceAvailableForAllProducts}
                  >
                    {translation && translation.checkout}
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CartHoverDropdown);
