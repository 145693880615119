import React from "react";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import MenuList from "./menuList";

const ListWrapper = ({ col, countryShort, language }) => (
  <div className="list-item">
    {map(col, (list, i) => {
      const isMarginClass =
        i + 1 < col.length && !isEmpty(get(col, `${[i + 1]}.children`));

      const listItem = {
        ...list,
        isMarginClass
      };

      return (
        <MenuList
          key={i}
          listItem={listItem}
          countryShort={countryShort}
          language={language}
        />
      );
    })}
  </div>
);

export default ListWrapper;
