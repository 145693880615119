import React, { useState, useEffect } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

/*
 * Two-pass rendering wrapper for preventing
 * SSR and CSR DOM differences in result.
 */
const withClientOnlyRender = Component => {
  const EnhancedComponent = props => {
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
      setIsMounted(true);
    }, []);

    if (!isMounted) {
      return null;
    }

    return <Component {...props} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withClientOnlyRender;
