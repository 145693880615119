import OverViewHeading from "../../components/my-acc/myAcc-overview-heading";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import get from "lodash/get";
import { getSocialAccountObject } from "../../util/my-account";
import {
  fetchSocialAccount,
  deleteSocialAccount
} from "../../redux/actions/myAccount.action";
import facebookIcon from "../../images/facebook_icon.png";
import googlePlus from "../../images/google_icon.png";
import EmptyCart from "../../components/cart/cart-empty";
import {
  authenticateUser,
  resetAPIError,
  resetPasswordData
} from "../../redux/actions/homepage.action";
import ModalBFL from "../../components/modal/modal";
import ResetPassword from "../../components/modal/reset-password";
import { resetPasswordTranslation } from "../../language/resetPassword/en/resetPassword";
import {
  fetchUserAndLoadData,
  logOut
} from "../../redux/actions/authConfig.action";
import {
  facebookAppId,
  facebookSDKVersion,
  googleAppId
} from "../../config/app";
import DeleteModal from "../../components/delete-modal";
import GoogleSignInBtn from "../../components/google/google-sign-in";

class SocialAccount extends Component {
  state = {
    accountDisconnectModal: false,
    resetPasswordTranslation: resetPasswordTranslation,
    googleLoggedIn: false,
    facebookLoggedIn: false,
    disconnectState: "",
    isConfirmModalActive: false
  };

  UNSAFE_componentWillMount() {
    this.props.resetAPIError();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { socialAccounts } = nextProps;
    const { googleLoggedIn, facebookLoggedIn } = this.state;
    let _googleLoggedIn = false;
    let _facebookLoggedIn = false;
    if (
      socialAccounts &&
      socialAccounts.socialAccountDetails &&
      socialAccounts.socialAccountDetails.length
    ) {
      socialAccounts.socialAccountDetails.forEach(item => {
        if (item.mode === "google") {
          _googleLoggedIn = true;
        }
        if (item.mode === "facebook") {
          _facebookLoggedIn = true;
        }
      });
    }
    if (
      googleLoggedIn !== _googleLoggedIn ||
      facebookLoggedIn !== _facebookLoggedIn
    ) {
      this.setState({
        googleLoggedIn: _googleLoggedIn,
        facebookLoggedIn: _facebookLoggedIn
      });
    }
  }

  componentDidMount() {
    const { fetchSocialAccount, language, socialAccounts } = this.props;
    let googleLoggedIn = false;
    let facebookLoggedIn = false;
    if (
      socialAccounts &&
      socialAccounts.socialAccountDetails &&
      socialAccounts.socialAccountDetails.length
    ) {
      socialAccounts.socialAccountDetails.forEach(item => {
        if (item.mode === "google") {
          googleLoggedIn = true;
        }
        if (item.mode === "facebook") {
          facebookLoggedIn = true;
        }
      });
    }
    this.setState({ googleLoggedIn, facebookLoggedIn });
    fetchSocialAccount(language);
  }

  socialCallback = (loginFrom, response) => {
    let { facebookLoggedIn, googleLoggedIn } = this.state;
    if (loginFrom === "facebook" && response && response.userID) {
      facebookLoggedIn = true;
    }
    if (loginFrom === "google" && response && response.sub) {
      googleLoggedIn = true;
    }
    this.setState({
      facebookLoggedIn,
      googleLoggedIn
    });

    const {
      history,
      language,
      loginUser,
      fetchUserAndLoadData,
      fetchSocialAccount,
      settings
    } = this.props;
    response = response.profileObj || response;
    if (response && response.email && !response.error) {
      loginUser(
        {
          email: response.email,
          mode: loginFrom.toLowerCase(),
          password: response.userID || response.googleId,
          firstname: response.name
        },
        history,
        language,
        false,
        (settings &&
          settings.countrySHORT &&
          settings.countrySHORT.toLowerCase()) ||
          "ae"
      ).then(resp => {
        if (resp && resp.data) {
          fetchUserAndLoadData();
          fetchSocialAccount(language);
        }
      });
    }
  };

  toggleAccountDisconnectModal = value => {
    const { logOut, authReducer } = this.props;
    if (value && !!authReducer.BFLUser) {
      logOut(true, true);
      this.setState({ accountDisconnectModal: false });
    } else if (!!authReducer.BFLUser === false) {
      this.setState({ accountDisconnectModal: value });
    }
    if (value === false) {
      this.props.resetPasswordData();
    }
  };

  handleResetPasswordConfirm = response => {
    const { socialAccounts, language } = this.props;
    const { disconnectState } = this.state;
    if (response && response.data && response.data.success) {
      this.toggleAccountDisconnectModal(true);
      const socialAccountObject = getSocialAccountObject(
        socialAccounts,
        disconnectState
      );
      this.props.deleteSocialAccount(
        socialAccountObject.socialLoginId,
        language
      );
      this.setState({ disconnectState: "" });
    }
  };

  disconnectState = type => {
    this.setState({ disconnectState: type }, () => {
      this.handleModalOpen();
    });
  };

  directDisconnect = type => {
    const { socialAccounts, language } = this.props;
    const socialAccountObject = getSocialAccountObject(socialAccounts, type);
    this.props.deleteSocialAccount(socialAccountObject.socialLoginId, language);
    this.setState({ disconnectState: "" });
  };

  handleCloseModal = value => {
    this.setState({ isConfirmModalActive: value });
  };

  handleModalOpen = e => {
    e && e.stopPropagation();
    e && e.preventDefault();
    this.handleCloseModal(true);
  };

  handleAccountDlink = () => {
    const { socialAccounts } = this.props;
    const { disconnectState } = this.state;
    const countOfAccountsLoggedIn = get(
      socialAccounts,
      "socialAccountDetails.length",
      0
    );
    this.toggleAccountDisconnectModal(countOfAccountsLoggedIn === 1);
    if (countOfAccountsLoggedIn > 1) {
      this.directDisconnect(disconnectState);
    }
  };

  render() {
    const {
      accountDisconnectModal,
      resetPasswordTranslation,
      googleLoggedIn,
      facebookLoggedIn,
      isConfirmModalActive
    } = this.state;
    const {
      myAccountTranslation,
      socialAccounts,
      userInfo,
      language,
      noRecordImgUrl,
      authReducer,
      handleGoBack
    } = this.props;

    return (
      <div className="overview-wrapper">
        <OverViewHeading
          headingName={myAccountTranslation.socialAccount}
          icon="my_social_account_icon"
          handleGoBack={handleGoBack}
        />

        <ModalBFL
          showModal={accountDisconnectModal}
          modalClass={`generic_modal social_account_disc ${
            language.includes("ar-") || language.includes("ar") ? "arabic" : ""
          }`}
        >
          <button
            className="cross_btn"
            onClick={e => this.toggleAccountDisconnectModal(false)}
          >
            &times;
          </button>
          <div className="generic_modal_content">
            <ResetPassword
              currentPassword={false}
              token={authReducer.accessToken}
              email={authReducer.email}
              language={language}
              resetPasswordTranslation={resetPasswordTranslation}
              resetPasswordConfirm={this.handleResetPasswordConfirm}
              toggleResetPasswordModal={() =>
                this.toggleAccountDisconnectModal(false)
              }
            />
          </div>
        </ModalBFL>

        <DeleteModal
          language={language}
          titleTxt={myAccountTranslation.delinkAccountTitle}
          bodyTxt={myAccountTranslation.delinkAccount}
          cancelBtnTxt={myAccountTranslation.no}
          deleteBtnTxt={myAccountTranslation.yes}
          isDeleteModalActive={isConfirmModalActive}
          handleCloseModal={this.handleCloseModal}
          handleDeleteItem={this.handleAccountDlink}
        />

        {socialAccounts.socialAccountDetails ? (
          <Fragment>
            <div className="social_account_login">
              <div className="account_head">
                <h6>
                  <p className="social-icon">
                    <img className="fb" src={googlePlus} alt="social icon" />
                  </p>
                  Google
                </h6>
                <div className="signin_account">
                  <p>
                    {googleLoggedIn && myAccountTranslation.youAreSignedInAs}
                  </p>
                  <p>
                    {" "}
                    {googleLoggedIn &&
                      userInfo.firstname &&
                      `${userInfo.firstname}`}{" "}
                    {googleLoggedIn &&
                      userInfo.lastname &&
                      `${userInfo.lastname}`}{" "}
                    {googleLoggedIn && userInfo.lastname && `-`}
                    <br /> {googleLoggedIn && userInfo.email}
                  </p>
                </div>
                <div className="link_btn_container">
                  <GoogleSignInBtn
                    socialCallback={
                      googleLoggedIn
                        ? () => this.disconnectState("google")
                        : this.socialCallback
                    }
                    buttonLabel={
                      googleLoggedIn
                        ? `${myAccountTranslation.disconnect}`
                        : `${myAccountTranslation.connect}`
                    }
                    className={"round_btn form_black_border_btn"}
                  />
                </div>
              </div>
            </div>
            <div className="social_account_login">
              <div className="account_head">
                <h6>
                  <p className="social-icon">
                    <img className="fb" src={facebookIcon} alt="social icon" />
                  </p>
                  Facebook
                </h6>
                <div className="signin_account">
                  <p>
                    {facebookLoggedIn && myAccountTranslation.youAreSignedInAs}
                  </p>
                  <p>
                    {" "}
                    {facebookLoggedIn &&
                      userInfo.firstname &&
                      `${userInfo.firstname}`}{" "}
                    {facebookLoggedIn &&
                      userInfo.lastname &&
                      `${userInfo.lastname}`}
                    {facebookLoggedIn && userInfo.lastname && `-`}
                    <br /> {facebookLoggedIn && userInfo.email}
                  </p>
                </div>
                <div className="link_btn_container">
                  <FacebookLogin
                    version={facebookSDKVersion}
                    appId={facebookAppId}
                    fields="name,email,picture"
                    callback={this.socialCallback.bind(this, "facebook")}
                    render={renderProps => (
                      <button
                        onClick={
                          facebookLoggedIn
                            ? () => {
                                this.disconnectState("facebook");
                              }
                            : renderProps.onClick
                        }
                        className="round_btn form_black_border_btn"
                      >
                        {facebookLoggedIn
                          ? `${myAccountTranslation.disconnect}`
                          : `${myAccountTranslation.connect}`}
                      </button>
                    )}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="no_record_found">
            <EmptyCart
              noRecordHeading={myAccountTranslation.noSocialHeading}
              noRecordDesc={myAccountTranslation.noSocialDesc}
              language={language}
              noRecordImgUrl={noRecordImgUrl}
              facebookBtnText={myAccountTranslation.connectOnFacebook}
              googleBtnText={myAccountTranslation.connectOnGoogle}
              socialCallback={this.socialCallback}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  settings: state.common.settings,
  socialAccounts: state.myAccountReducer.socialAccounts,
  userInfo: state.authReducer,
  authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => ({
  fetchSocialAccount: bindActionCreators(fetchSocialAccount, dispatch),
  deleteSocialAccount: bindActionCreators(deleteSocialAccount, dispatch),
  loginUser: bindActionCreators(authenticateUser, dispatch),
  fetchUserAndLoadData: bindActionCreators(fetchUserAndLoadData, dispatch),
  resetPasswordData: bindActionCreators(resetPasswordData, dispatch),
  resetAPIError() {
    dispatch(resetAPIError());
  },
  logOut: bindActionCreators(logOut, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccount);
