import {
  PAYMENT_CHANGE,
  FILL_SELECTED_SAVED_CARD,
  RESET_SAVED_CARD
} from "../constants";

export const handleTextChange = (name, value, status) => ({
  type: PAYMENT_CHANGE,
  name,
  value,
  status
});

export const fillSelectedSavedCard = data => ({
  type: FILL_SELECTED_SAVED_CARD,
  data
});

export const resetSavedCard = data => ({ type: RESET_SAVED_CARD });
