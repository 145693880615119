import { Fragment } from "react";

export const ProductInfoListItem = ({ text, icon, className }) => (
  <li className={className}>
    {icon && (
      <img className="product_info_item_icon" src={icon} alt="list element" />
    )}
    <span className="product_info_item_text">{text}</span>
  </li>
);

export const ProductDefaultAttributeList = ({
  attributes,
  listItemClassName
}) =>
  Object.entries(attributes).map(([key, value]) => (
    <Fragment key={key}>
      <h3 className="default_attribute_name">{value.name}</h3>
      <ul>
        {value.values.map(attribute => (
          <ProductInfoListItem
            key={`${key}_${attribute.text}`}
            className={listItemClassName}
            icon={attribute.icon}
            text={attribute.text}
          />
        ))}
      </ul>
    </Fragment>
  ));

export const ProductDefaultAttributeListNoName = ({
  attributes,
  listItemClassName
}) =>
  Object.entries(attributes).map(([key, value]) => (
    <ul key={key} className="py-2">
      {value.values.map(attribute => (
        <ProductInfoListItem
          key={`${key}_${attribute.text}`}
          className={listItemClassName}
          icon={attribute.icon}
          text={attribute.text}
        />
      ))}
    </ul>
  ));
