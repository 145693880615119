import React from "react";
import moment from "moment";

const OrderList = ({
  order,
  handleSelectOrder,
  selectedOrderId,
  toggleModal
}) => {
  return (
    !!order &&
    order.map(order => (
      <li
        className={
          !!selectedOrderId && selectedOrderId === order.orderId
            ? "checked right_check_label popup"
            : "right_check_label popup"
        }
        key={order.orderId}
      >
        <span
          className="check_circle_radio"
          onClick={() => {
            handleSelectOrder(order.orderId);
            toggleModal(false);
          }}
        >
          <input type="radio" checked />
        </span>
        <div className="order_item">
          <span>{order.orderId}</span>
          <span>{moment(order.createDate).format("DD MMM YYYY")}</span>
          <span>
            {order.CurrencyCode} {order.transactionAmount}
          </span>
        </div>
      </li>
    ))
  );
};

export default OrderList;
