import React, { Component, Fragment } from "react";
import cn from "classnames";
import Scrollbars from "react-custom-scrollbars";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { connect } from "react-redux";

import { getProductUrlName, validateProduct } from "../../util";
import { getInternationalSizes } from "../../util/cart";
import { isPriceAvailableByUserIpcountry } from "../../util/priceUtil";
import ModalBFL from "../modal/modal";

import SlideBottomToTop from "../../components/slide-bottom-to-top";
import ProductMultiSize from "../product/product-multi-size";

import ContentImage from "../content/content-image";
import ContentDescription from "../content/content-description";
import ProductSize from "../product/product-size";

import CustomDropdown from "../genericDropDown";
import { DiscountSection } from "../price/price-slab";
import {
  selectCartData,
  selectCheckoutData,
  selectCommonSettings,
  selectCurrency,
  selectLanguageSlug,
  selectWishList,
  selectCdnImageSettings
} from "../../util/selectors";
import { ButtonWithLoader } from "../generalElements/buttons";

class WishlistItem extends Component {
  state = {
    size: "",
    sizeType: "",
    showModal: false,
    openQuantity: false,
    selectedInternational: "",
    quantityOverflowMessage: false,
    oneSizeCartQty: null,
    multiSizeProductCartQty: null,
    showButtonLoader: false
  };

  setShowModal = showModal => this.setState({ showModal });

  setSize = (productId, size, sizeId, type, _id) => {
    this.setState({ size }, () => {
      this.saveItem(productId, size, sizeId, type, _id);
    });
  };

  setSizeType = sizeType => this.setState({ sizeType, size: "" });

  saveItem = async (productId, size, sizeId, sizeType, _id) => {
    const { saveItem } = this.props;

    const response = await saveItem(productId, size, sizeId, sizeType, _id);

    if (get(response, "data.success")) {
      this.setShowModal(false);
      this.setState({ quantityOverflowMessage: false });
    }
  };

  _getAvailableSizes = () => {
    const { item } = this.props;

    return get(item, "colors.colorSizeOptions", []).filter(
      item => item.sizeLabel && item.sizeStock
    );
  };

  handleQtyModal = value => this.setState({ openQuantity: value });

  handleSizeModalToggle = () =>
    this.setState({ openQuantity: !this.state.openQuantity });

  handleAddToCart = (selectedSize, oneSize, item, internationalSizes) => {
    const { cart } = this.props;
    if (
      selectedSize &&
      internationalSizes &&
      !Object.keys(internationalSizes).length
    ) {
      let _wishListedItem =
        cart &&
        cart.filter(
          product =>
            product.productId === (item && item.productId) &&
            product.size === (selectedSize && selectedSize.sizeLabel)
        );
      const totalQty =
        _wishListedItem &&
        _wishListedItem
          .map(item => item.quantity)
          .reduce((total, num) => total + num, 0);
      this.setState({
        quantityOverflowMessage:
          (selectedSize && selectedSize.sizeStock) <= +totalQty
      });
      return selectedSize && selectedSize.sizeStock <= totalQty;
    } else if (internationalSizes && Object.keys(internationalSizes).length) {
      const itemDetails =
        internationalSizes &&
        internationalSizes[
          (item && item.sizeType) || Object.keys(internationalSizes)[0]
        ].find(items => items.sizeLabel.trim() === item.size);
      const cartQty =
        cart &&
        cart.reduce((total, items) => {
          if (
            items.sizeId === (itemDetails && itemDetails.sizeId) &&
            items.productId === item.productId
          ) {
            return total + items.quantity;
          }
          return total;
        }, 0);
      this.setState({
        quantityOverflowMessage:
          cartQty >= (itemDetails && itemDetails.sizeStock),
        multiSizeProductCartQty: cartQty
      });
      return cartQty >= (itemDetails && itemDetails.sizeStock);
    } else {
      let wishListedItem =
        cart &&
        cart.filter(product => product.productId === (item && item.productId));
      this.setState({
        quantityOverflowMessage:
          item.pstock ===
          (wishListedItem && wishListedItem[0] && wishListedItem[0].quantity),
        oneSizeCartQty:
          wishListedItem && wishListedItem[0] && wishListedItem[0].quantity
      });
      return (
        item.pstock ===
        (wishListedItem && wishListedItem[0] && wishListedItem[0].quantity)
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cart, wishList } = this.props;
    if (nextProps.cart && nextProps.cart.length !== (cart && cart.length)) {
      this.setState({ quantityOverflowMessage: false, oneSizeCartQty: null });
    }
    if (nextProps.wishList && nextProps.wishList.length !== wishList.length) {
      this.setState({
        quantityOverflowMessage: false,
        oneSizeCartQty: null,
        multiSizeProductCartQty: null
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      quantityOverflowMessage: false,
      oneSizeCartQty: null,
      multiSizeProductCartQty: null
    });
  }

  render() {
    const {
      showModal,
      size,
      sizeType,
      oneSizeCartQty,
      multiSizeProductCartQty,
      showButtonLoader
    } = this.state;
    const {
      language,
      productTranslation,
      deleteWishListData,
      addToCart,
      moveToProductDetail,
      currencyCode,
      item,
      commonSettings,
      configCdnImagesSettings,
      checkoutData
    } = this.props;
    let internationalSizes = {};
    let availableSizes = this._getAvailableSizes();
    item &&
      item.IsMultisize &&
      getInternationalSizes(availableSizes, internationalSizes);

    if (
      Object.keys(internationalSizes) &&
      Object.keys(internationalSizes).length
    ) {
      availableSizes =
        internationalSizes[sizeType || item.sizeType] ||
        internationalSizes[Object.keys(internationalSizes)[0]];
    }
    const firstSizeType =
      Object.keys(internationalSizes).length &&
      Object.keys(internationalSizes)[0];

    const selectedSize =
      availableSizes &&
      availableSizes.length &&
      availableSizes.find(
        selectedItem => selectedItem.sizeLabel.trim() === item.size
      );
    const { _OutOfStock: isOutOfStock } = validateProduct(checkoutData, item);

    const selectedSizeOutofStock = selectedSize
      ? selectedSize.sizeStock
      : item.pstock > 0 && !isOutOfStock;
    const selectedSizeStock =
      availableSizes && availableSizes.length
        ? availableSizes[0].sizeStock
        : item.colors &&
          item.colors.colorSizeOptions &&
          item.colors.colorSizeOptions[0].sizeStock;

    const oneSize =
      item.colors &&
      item.colors.colorSizeOptions &&
      item.colors.colorSizeOptions.filter(data => data.sizeLabel);

    const onAddingItem = async itemId => {
      const validator = this.handleAddToCart(
        selectedSize,
        oneSize,
        item,
        internationalSizes
      );
      if (!validator) {
        this.setState(() => ({ showButtonLoader: true }));
        try {
          await addToCart(item, selectedSize);
        } catch (e) {
          this.setState(() => ({ showButtonLoader: false }));
          console.error(e);
        }
      }
    };

    const isPriceAvailableInCountry = isPriceAvailableByUserIpcountry(item);
    const isProductUnavailable =
      !(item.pstock && Boolean(selectedSizeStock)) || isOutOfStock;
    const showInPriceSlab = item?.overlayTagLabel;

    const addToCartDisabled =
      isPriceAvailableInCountry &&
      (!(
        item.pstock &&
        !!selectedSizeStock &&
        selectedSizeOutofStock > 0 &&
        (!!oneSize && !oneSize.length ? oneSize : item.size)
      ) ||
        showButtonLoader);

    const wishlistSelectedSize =
      (selectedSize?.sizeLabel && selectedSize.sizeLabel.trim()) || item.size;

    return (
      <Fragment>
        <ModalBFL
          showModal={showModal}
          modalClass={cn("generic_modal", "size_selector_modal", {
            arabic: language.includes("ar")
          })}
        >
          <button
            className="cross_btn"
            onClick={() => this.setShowModal(false)}
          >
            &times;
          </button>
          <h3>{productTranslation && productTranslation.selectSize}</h3>
          <div className="size_group">
            {!isEmpty(firstSizeType) && (
              <CustomDropdown
                data={Object.keys(internationalSizes)}
                selectedItem={sizeType || item.sizeType || firstSizeType}
                containerClass={"international_size_selector withBorder"}
                handleChange={this.setSizeType}
                scrollbarMaxHeight={150}
              />
            )}

            <ProductSize
              colorSizeOption={availableSizes}
              selectedSize={size || item.size}
              onSizeChange={size => {
                const { sizeId } = availableSizes.find(
                  availableSize =>
                    availableSize.sizeLabel.trim() === size.trim()
                );
                this.setSize(
                  item.id,
                  size,
                  sizeId,
                  sizeType || item.sizeType || firstSizeType,
                  item._id
                );
              }}
              translation={productTranslation}
            />
          </div>
        </ModalBFL>
        <div className="wishlist_item">
          <div
            className={cn("wishlist_content_container", {
              unavailable: isProductUnavailable
            })}
          >
            <div className="wishlistInfo">
              <div
                className={cn("wishList_img_wrap", item._id, {
                  outOfStock: isProductUnavailable
                })}
                onClick={e => moveToProductDetail(item, e)}
              >
                <ContentImage
                  img={item && item.captionImageURL}
                  url={configCdnImagesSettings?.thumb}
                  overlayLabel={item && item.overlayTagLabel}
                  overlayTagBgColor={item && item.overlayTagBgColor}
                  overlayTagLabelColor={item && item.overlayTagLabelColor}
                  showDefaultImage={true}
                />
                {isProductUnavailable && (
                  <div className="out_of_stock_block">
                    <p>{productTranslation && productTranslation.outOfStock}</p>
                  </div>
                )}
                {!showInPriceSlab && (
                  <DiscountSection
                    item={item}
                    countrySettings={commonSettings}
                    translation={productTranslation}
                    language={language}
                  />
                )}
              </div>

              <div className="content_description">
                <ContentDescription
                  item={item}
                  translation={productTranslation}
                  language={language}
                  currencyCode={currencyCode}
                  commonSettings={commonSettings}
                  showVat
                  Redirection={true}
                  getProductUrlName={getProductUrlName}
                  isProductAvailableInCountry={isPriceAvailableInCountry}
                  showDiscountSlab={showInPriceSlab}
                  showRetailPrice
                  cartOnDiscount
                />
                {this.state.quantityOverflowMessage && (
                  <span className="error qty_overflow">
                    {productTranslation.alreadyAdded}{" "}
                    {selectedSize
                      ? selectedSize.sizeStock
                      : oneSizeCartQty
                      ? oneSizeCartQty
                      : multiSizeProductCartQty}{" "}
                    {productTranslation.qtyItemInCart}.
                    {productTranslation.theAvailableStock}{" "}
                    {selectedSize
                      ? selectedSize.sizeStock
                      : oneSizeCartQty
                      ? oneSizeCartQty
                      : multiSizeProductCartQty}
                    .{" "}
                  </span>
                )}
                {item && item.pstock > 0 && (
                  <Fragment>
                    {!isEmpty(oneSize) && !isEmpty(availableSizes) && (
                      <div className="sizeSlide">
                        <span className="selectSize">
                          {productTranslation && productTranslation.size} :{" "}
                        </span>
                        <span onClick={() => this.handleQtyModal(true)}>
                          <span className="sizes">
                            {" "}
                            {item.size ||
                              `${
                                productTranslation &&
                                productTranslation.selectSize
                              }`}{" "}
                          </span>
                          <span className="angleDown" />
                        </span>
                      </div>
                    )}
                    <div
                      className={cn(
                        "slideTop",
                        "multipleSizeContainer",
                        "productSizes",
                        this.state.openQuantity ? "slideUp" : "slideBack"
                      )}
                    >
                      <div
                        className="backOverlay"
                        onClick={() => this.handleQtyModal(false)}
                      />
                      <>
                        <p className="sizeText">{productTranslation.size}</p>
                        <span
                          className="mCrossIcon"
                          onClick={() => this.handleQtyModal(false)}
                        />
                      </>
                      <Scrollbars
                        autoHeight
                        autoHeightMin={100}
                        autoHeightMax={285}
                      >
                        <SlideBottomToTop>
                          {item && item.IsMultisize ? (
                            <div className="multiSizeWrapper">
                              <ProductMultiSize
                                sizeTypes={Object.keys(internationalSizes)}
                                handleChange={this.setSizeType}
                                colorSizeOption={availableSizes || oneSize}
                                id={item.id}
                                onMultiSizeChange={(
                                  sizeLabel,
                                  sizeStock,
                                  defaultAddToCart,
                                  sizeId,
                                  sizeType
                                ) => {
                                  this.setSize(
                                    item.id,
                                    sizeLabel,
                                    sizeId,
                                    sizeType,
                                    item._id
                                  );
                                }}
                                selectedSize={wishlistSelectedSize}
                                internationalSizes={internationalSizes}
                                language={language}
                                showStockMobile={true}
                                showStock={false}
                                translation={productTranslation}
                                handleMultipleSizesModal={
                                  this.handleSizeModalToggle
                                }
                                firstSizeType={
                                  sizeType || item.sizeType || firstSizeType
                                }
                              />
                            </div>
                          ) : (
                            <Fragment>
                              <ProductSize
                                id={item.id}
                                colorSizeOption={availableSizes || oneSize}
                                onSizeChange={size => {
                                  const { sizeId } = (
                                    availableSizes || oneSize
                                  ).find(
                                    availableSize =>
                                      availableSize.sizeLabel.trim() ===
                                      size.trim()
                                  );
                                  this.setSize(
                                    item.id,
                                    size,
                                    sizeId,
                                    sizeType || item.sizeType || firstSizeType,
                                    item._id
                                  );
                                }}
                                selectedSize={wishlistSelectedSize}
                                language={language}
                                showStock={false}
                                showStockMobile={true}
                                translation={productTranslation}
                                handleMultipleSizesModal={
                                  this.handleSizeModalToggle
                                }
                              />
                            </Fragment>
                          )}
                        </SlideBottomToTop>
                      </Scrollbars>
                    </div>
                  </Fragment>
                )}

                {!isPriceAvailableInCountry && (
                  <span className="error">
                    {productTranslation.notAvailableIn}
                  </span>
                )}

                <div className="action_btn web">
                  {!item.size && !isEmpty(availableSizes) && (
                    <button
                      className="add_to_btn select_size white"
                      onClick={() => {
                        this.setShowModal(true);
                      }}
                    >
                      {productTranslation && productTranslation.selectSize}
                    </button>
                  )}
                  {item.size && !isEmpty(availableSizes) && (
                    <div
                      className="item_size web"
                      onClick={() => {
                        this.setShowModal(true);
                      }}
                    >
                      <span className="label">Size:</span>
                      <span className="size">{item.size}</span>
                    </div>
                  )}

                  {isPriceAvailableInCountry && (
                    <ButtonWithLoader
                      className={cn("add_to_btn", "white", {
                        buttonEnable:
                          item.pstock &&
                          (!isEmpty(availableSizes) ? item.size : true)
                      })}
                      disabled={addToCartDisabled}
                      onClick={() => {
                        onAddingItem(item._id);
                      }}
                      showLoader={showButtonLoader}
                      loaderProps={{
                        bounceLoaderColor: "#000"
                      }}
                    >
                      {productTranslation && productTranslation.addToCart}
                    </ButtonWithLoader>
                  )}
                </div>
              </div>
            </div>
            <div className="action_btn mobile">
              <div className="wishlist_mobile_action">
                {isPriceAvailableInCountry && (
                  <ButtonWithLoader
                    className={cn("mobile", "add_to_btn", "white", {
                      buttonEnable:
                        item.pstock &&
                        (availableSizes && availableSizes.length
                          ? item.size
                          : true)
                    })}
                    disabled={addToCartDisabled}
                    onClick={() => {
                      onAddingItem(item.productId);
                    }}
                    showLoader={showButtonLoader}
                    loaderProps={{
                      bounceLoaderColor: "#000"
                    }}
                  >
                    {productTranslation && productTranslation.addToBag}
                  </ButtonWithLoader>
                )}
                <span
                  className="remove_txt"
                  onClick={() => deleteWishListData(item)}
                >
                  {productTranslation && productTranslation.remove}
                </span>
              </div>
            </div>
            <div className="close-btn" onClick={() => deleteWishListData(item)}>
              <span className="close-icon" />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  configCdnImagesSettings: selectCdnImageSettings(state),
  checkoutData: selectCheckoutData(state),
  currencyCode: selectCurrency(state),
  commonSettings: selectCommonSettings(state),
  cart: selectCartData(state),
  language: selectLanguageSlug(state),
  wishList: selectWishList(state)
});

export default connect(mapStateToProps)(WishlistItem);
