import AuthGAService from "./modules/auth";
import BannerService from "./modules/banner";
import CartGAService from "./modules/cart";
import CheckoutService from "./modules/checkout";
import CommonGAService from "./modules/common";
import HeaderService from "./modules/header";
import FooterService from "./modules/footer";
import ProductGAService from "./modules/product";

class GAServices {
  header = new HeaderService();
  footer = new FooterService();
  cart = new CartGAService();
  auth = new AuthGAService();
  common = new CommonGAService();
  product = new ProductGAService();
  checkout = new CheckoutService();
  banner = new BannerService();
}

const instance = new GAServices();

export default instance;
