import React from "react";
import cn from "classnames";

export const ModalRoundedCard = React.memo(
  ({ selected, title, className, image }) => (
    <div className={cn("quick-filter-card-wrapper rounded", className)}>
      <div
        className="modal-rounded-card"
        style={{
          backgroundImage: `url(${image})`
        }}
      >
        <div className={cn("checkbox-icon", { selected })} />
      </div>
      <div className="rounded-card-description">
        <h3>{title}</h3>
      </div>
    </div>
  )
);
