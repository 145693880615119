import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import cn from "classnames";

import { AnalyticService, CheckoutComService } from "../../services";

import { selectRouteSlug } from "../../util/selectors";

import BrandsForLessLogo from "../../images/brandsForLessLogo.png";
import { Image } from "../../components/image";
import { changeLanguage } from "../../redux/actions/common.action";
import { getCartAndWishlistGeneralData } from "../../redux/actions/cart.action";
import ContactInfo from "../../components/contact-info";
import PaymentFailIcon from "../../images/payment_fail.svg";

import { paymentErrorTranslation } from "../../language/paymentError/en/paymentError";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";

class PaymentError extends Component {
  state = {
    translation: paymentErrorTranslation,
    message: "",
    checkoutId: "",
    sessionId: "",
    paymentDetails: null
  };

  async componentDidMount() {
    const {
      language: languageProp,
      handleLanguageChange,
      match,
      location
    } = this.props;

    const query = new URLSearchParams(location.search);

    const language = (match.params.language || languageProp).includes("ar")
      ? "ar"
      : "en";

    handleLanguageChange(language);
    this.loadLanguage(language);

    this.setState({
      message: query.get("message"),
      checkoutId: query.get("checkoutId")
    });

    const isCheckout = query.get("type") === "checkout-com";
    if (isCheckout) {
      const sessionId = query.get("cko-session-id");

      const paymentDetails = await CheckoutComService.getPaymentDetails(
        sessionId
      );

      if (paymentDetails) this.setState({ paymentDetails });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;

    if (nextProps.language !== this.props.language) {
      this.loadLanguage(nextProps.language);
    }

    if (this.props.email !== nextProps.email) {
      const query = new URLSearchParams(location.search);

      const email = nextProps.email;

      const checkoutAmount = query.get("checkoutAmount");
      if (checkoutAmount) {
        AnalyticService.common.trackLeadSubmitted({
          email,
          totalAmount: checkoutAmount
        });
      }
    }
  }

  async loadLanguage(language) {
    const translation = await import(
      `../../language/paymentError/${language}/paymentError`
    );

    this.setState({
      translation: translation.paymentErrorTranslation
    });
  }

  getErrorMessage() {
    const { message, paymentDetails, translation } = this.state;

    if (message) {
      const errorMap = {
        default: translation.defaultErrorMessage
      };

      return errorMap[message] || message;
    }

    if (paymentDetails) {
      return paymentDetails.errorMessage;
    }

    return null;
  }

  render() {
    const {
      language,
      staticContent,
      routeSlug,
      getCartAndWishlistGeneralData
    } = this.props;
    const { translation } = this.state;
    const errorMessage = this.getErrorMessage();

    return (
      <div
        className={cn("payment_error_wrapper", {
          arabic: language.includes("ar")
        })}
      >
        <div className="payment_header_bg">
          <div className="container payment_header">
            <div className="Payment_logo_wrap">
              <Link
                to={`/${routeSlug}/`}
                onClick={getCartAndWishlistGeneralData}
              >
                <span className="current_logo">
                  <Image src={BrandsForLessLogo} alt="Brands For Less" />
                </span>
              </Link>
            </div>
            <div className="contact_wrap">
              <a href={`tel:${staticContent && staticContent.csNumber}`}>
                <span className="phone">
                  {staticContent && staticContent.csNumber}
                </span>
              </a>
              <a
                href={`mailto:${staticContent && staticContent.csEmail}`}
                target="_top"
              >
                <span className="email">
                  {staticContent && staticContent.csEmail}
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className={cn("payment_error", {
              arabic: language === "ar"
            })}
          >
            <div className="icon_wrapper">
              <Image src={PaymentFailIcon} />
            </div>

            <h2 className="pageTitle">{translation.pageTitle}</h2>

            <p className="message">{translation.pageMessage}</p>

            {errorMessage && (
              <h4 className="red_alert">
                {translation.bankResponse}: {errorMessage}
              </h4>
            )}

            <p className="message">{translation.pageMessageForBank}</p>

            <ContactInfo
              orderSuccessTranslation={translation}
              customerCare={staticContent}
            />

            <div className="callToActions">
              <Link
                to={`/${routeSlug}/my-cart/`}
                className="round_btn goToHome"
                onClick={getCartAndWishlistGeneralData}
              >
                {translation.retryPayment}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.common.language,
  email: state.authReducer.email,
  staticContent: state.footerReducer.staticContent,
  routeSlug: selectRouteSlug(state)
});

const mapDispatchToProps = {
  handleLanguageChange: changeLanguage,
  getCartAndWishlistGeneralData
};

export default compose(
  withAnalyticPageBrowseTracking,
  withPageTypeTracking,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentError);
