import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  DEFAULT_PAGE_LOADER_CONFIG,
  LANGUAGE,
  LANGUAGE_ROUTE_KEY,
  REDIRECTION_PAGES,
  ROUTE_SLUG_REG_EXP,
  UNMINIMIZED_NAME
} from "../../constants";
import {
  decrementPageNoInQueryString,
  deleteServiceQueries,
  isServer
} from "../../util";
import {
  changeIsHomePageRedux,
  errorNotFound,
  hideLoadingIndicator,
  hidePageLoader
} from "../../redux/actions/common.action";
import {
  clearRedirectionData,
  getPageApi
} from "../../redux/actions/page.action";
import {
  getPrevModeStorage,
  getUserCountryStorage
} from "../../util/browserStorage";
import Catalog from "../../routes/catalog";
import ErrorPage from "../error-page";
import HomePage from "../../routes/homepage";
import ProductListing from "../../routes/product-listing";
import StaticHtmlPages from "../../routes/static-html-pages";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import {
  withAnalyticPageBrowseTracking,
  withPageTypeTracking
} from "../../hocs";
import withAuthenticationChecking from "../../hocs/withAuthenticationChecking";
import AnalyticService from "../../services/analytic-service";
import ssrConfig from "./ssrConfig";
class Redirection extends React.Component {
  static unminimizedName = UNMINIMIZED_NAME.REDIRECTION;

  state = {
    isHomePage: false
  };

  componentDidMount() {
    const {
      settings,
      history,
      countryData,
      ipCountryData,
      getPageApi,
      countryId,
      storeId,
      match,
      routeLanguage,
      hidePageLoader,
      hideLoadingIndicator,
      redirectionData
    } = this.props;
    const { pathname, search } = history.location;

    if (!isEmpty(settings)) {
      const lang = pathname.includes("/ar-") ? "ar" : "en";

      const isFound = countryData.some(country =>
        pathname.includes(`/${lang}-${country.countrySHORT.toLowerCase()}/`)
      );

      if (!isFound && !pathname.match(ROUTE_SLUG_REG_EXP)) {
        history.push(
          `/${lang}-${settings.countrySHORT.toLowerCase()}${pathname}`
        );
      }
    }

    const url = window.location.href.toLowerCase();

    const storeList = [];

    let country = null;
    let countryAE = {};
    let selectedCountry = null;
    let selectedIPCountry = null;
    let countryCookie = null;

    const userCountry = getUserCountryStorage();

    countryData.forEach(country => {
      const countrySlug = country.countrySHORT.toLowerCase();
      if (
        url.includes(`/en-${countrySlug}`) ||
        url.includes(`/ar-${countrySlug}`)
      ) {
        selectedCountry = country;
      }
      if (country.countrySHORT === userCountry) {
        countryCookie = country;
      }
      if (country.countrySHORT === "AE") {
        countryAE = country;
      }
      if (
        get(ipCountryData, "countrySHORT") &&
        ipCountryData.countrySHORT === country.countrySHORT
      ) {
        selectedIPCountry = country;
      }
    });

    if (selectedCountry) {
      country = selectedCountry;
    } else {
      if (countryCookie) {
        country = countryCookie;
      } else if (selectedIPCountry) {
        country = selectedIPCountry;
      } else {
        country = countryAE;
      }
    }

    let pageName = decrementPageNoInQueryString(
      pathname.toLowerCase() + search
    );

    let pageNameWithoutServiceQueries = deleteServiceQueries(pageName);

    pageNameWithoutServiceQueries = pageNameWithoutServiceQueries
      .replace(ROUTE_SLUG_REG_EXP, "")
      .replace(/^\//, "");

    const isLoginPage = pageNameWithoutServiceQueries.includes("login/");

    get(settings, "stores", []).forEach(store => {
      const storeURL = store.name.toLowerCase().replace(/ /g, "-");

      if (storeURL.includes("brands-for-less")) {
        storeList.push(``);
      } else {
        storeList.push(`${storeURL}`);
        storeList.push(`${storeURL}/`);
      }
    });

    const language = this.getLanguageFromMatch(match, routeLanguage);
    const isShopStore = storeList.includes(pageNameWithoutServiceQueries);
    const needToCallPageApi =
      (!isLoginPage && this.isEmptyRedirectionData()) || getPrevModeStorage();
    if (needToCallPageApi) {
      if (countryId) {
        getPageApi(
          pageNameWithoutServiceQueries,
          language,
          countryId,
          storeId || ""
        );
      } else if (settings) {
        this.setState({ isHomePage: false });
        if (settings.countryId) {
          getPageApi(
            pageNameWithoutServiceQueries,
            language,
            settings.countryId,
            storeId || ""
          );
        }
      }
    }

    if (!isEmpty(redirectionData.data)) {
      hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG);
      hideLoadingIndicator();
    }
  }

  componentWillUnmount() {
    const { clearApiData } = this.props;

    clearApiData();
  }

  isEmptyRedirectionData() {
    const { redirectionData } = this.props;

    if (isEmpty(redirectionData)) return true;

    const notFoundStatusCode = 404;
    if (redirectionData.code === notFoundStatusCode) return true;

    return false;
  }

  getLanguageFromMatch(match, routeLanguage) {
    const languageParam = match.params.language || routeLanguage;
    const languageKey = languageParam.includes("ar") ? "ar" : "en";
    const language =
      languageKey === LANGUAGE_ROUTE_KEY.arabic
        ? LANGUAGE.arabic
        : LANGUAGE.english;

    return language;
  }

  setCategoryMoEngage(response) {
    const { breadcrumbReducer } = this.props;
    const pageType = get(response, "data.type");
    const parentCategory = get(
      breadcrumbReducer,
      "productList.en_productListName",
      ""
    );
    const category = get(
      breadcrumbReducer,
      "productListLvl1.productListLvl1Name",
      ""
    );
    const subCategory = get(
      breadcrumbReducer,
      "productListLvl2.productListLvl2Name",
      ""
    );
    const catalogueCategory = get(
      breadcrumbReducer,
      "category.categoryName",
      ""
    );

    switch (pageType) {
      case "Product Listing":
        AnalyticService.common.trackViewSubCategory({
          parentCategory,
          category,
          subCategory
        });
        break;
      case "Catalog":
        AnalyticService.common.trackViewCategory(catalogueCategory);
        break;
      default:
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      getPageApi,
      countryId,
      settings,
      storeId,
      countryData,
      ipCountryData,
      location,
      routeLanguage,
      hidePageLoader,
      hideLoadingIndicator,
      redirectionData
    } = this.props;

    const needsToHideLoader =
      !isEmpty(nextProps.redirectionData) &&
      !isEqual(redirectionData, nextProps.redirectionData);

    if (needsToHideLoader) {
      hidePageLoader(DEFAULT_PAGE_LOADER_CONFIG);
      hideLoadingIndicator();
    }

    const {
      pathname: nextPathname,
      search: nextSearch,
      state
    } = nextProps.location;
    const { pathname, search } = location;

    let pageName = nextPathname.toLowerCase();
    const clearFilter = get(state, "clearFilter");
    const navigateToGeneralLocation =
      !nextSearch && Boolean(location.search) && !clearFilter;

    if (pageName !== pathname.toLowerCase() || navigateToGeneralLocation) {
      pageName += nextSearch;
    } else {
      pageName += search;
    }
    pageName = decrementPageNoInQueryString(pageName);

    let pageNameWithoutServiceQueries = deleteServiceQueries(pageName);

    let url = window.location.href.toLowerCase();
    let countryAE = {};
    let selectedCountry = null;
    let selectedIPCountry = null;
    let countryCookie = null;
    let _storeList = [];

    const userCountry = getUserCountryStorage();

    countryData.map(country => {
      const countrySlug = country.countrySHORT.toLowerCase();
      if (
        url.includes(`/en-${countrySlug}`) ||
        url.includes(`/ar-${countrySlug}`)
      ) {
        selectedCountry = country;
      }
      if (country.countrySHORT === userCountry) {
        countryCookie = country;
      }
      if (country.countrySHORT === "AE") {
        countryAE = country;
      }
      if (get(ipCountryData, "countrySHORT") === country.countrySHORT) {
        selectedIPCountry = country;
      }
      return null;
    });

    pageNameWithoutServiceQueries = pageNameWithoutServiceQueries
      .replace(ROUTE_SLUG_REG_EXP, "")
      .replace(/^\//, "");

    get(settings, "stores", []).forEach(store => {
      const storeURL = store.name.toLowerCase().replace(/ /g, "-");

      if (storeURL.includes("brands-for-less")) {
        _storeList.push(``);
      } else {
        _storeList.push(`${storeURL}`);
        _storeList.push(`${storeURL}/`);
      }
    });

    const nextMatchLanguage = this.getLanguageFromMatch(
      nextProps.match,
      routeLanguage
    );
    const currentMatchLanguage = this.getLanguageFromMatch(
      this.props.match,
      routeLanguage
    );
    const languageFromMatchSame = nextMatchLanguage === currentMatchLanguage;
    const languageChanged = this.props.language !== nextProps.language;
    const pathChanged = nextPathname.toLowerCase() !== pathname.toLowerCase();

    const needToCallPageApi =
      navigateToGeneralLocation ||
      (languageFromMatchSame && pathChanged) ||
      languageChanged ||
      (!countryId && nextProps.countryId !== countryId);
    if (needToCallPageApi) {
      this.setState({ isHomePage: false });
      if (nextProps.countryId) {
        getPageApi(
          pageNameWithoutServiceQueries,
          nextProps.language,
          nextProps.countryId,
          nextProps.storeId || ""
        )
          .then(response => {
            this.setCategoryMoEngage(response);
          })
          .catch(error => {
            return error.response;
          });
      }
    } else if (nextProps.settings) {
      this.setState({ isHomePage: false });

      if (
        !countryId &&
        get(nextProps, "settings.countryId") !== get(settings, "countryId") &&
        nextProps.storeId !== storeId
      ) {
        if (get(nextProps, "settings.countryId")) {
          getPageApi(
            pageNameWithoutServiceQueries,
            nextMatchLanguage,
            nextProps.settings.countryId,
            nextProps.storeId || ""
          );
        }
      }
    }
  }

  render() {
    const { redirectionData, loader, routeLanguage, match } = this.props;
    const language = this.getLanguageFromMatch(match, routeLanguage);
    const isHomePage = isServer
      ? this.props.isHomePageRedux
      : this.state.isHomePage;

    if (!isHomePage && isEmpty(redirectionData)) {
      return null;
    }

    let contentToDisplay = null;
    if (!isHomePage) {
      const pageName = match.params.redirected;

      switch (redirectionData.type) {
        case REDIRECTION_PAGES.catalog:
          contentToDisplay = (
            <Catalog
              CatalogContent={redirectionData.data}
              language={language}
              pageName={pageName}
            />
          );
          break;
        case REDIRECTION_PAGES.static:
        case REDIRECTION_PAGES.staticAccordion:
          contentToDisplay = (
            <StaticHtmlPages
              StaticContent={redirectionData.data}
              language={language}
              pageName={pageName}
              routeLanguage={routeLanguage}
            />
          );
          break;
        case REDIRECTION_PAGES.productCatalog:
        case REDIRECTION_PAGES.productListing:
          contentToDisplay = (
            <ProductListing
              content={redirectionData.data}
              language={language}
              pageName={pageName}
            />
          );
          break;
        default: {
          this.props.is404();
          if (!loader) {
            contentToDisplay = <ErrorPage language={language} />;
          }
        }
      }
    } else {
      contentToDisplay = <HomePage />;
    }

    return <div>{contentToDisplay}</div>;
  }
}

Redirection.need = ssrConfig;

const mapStateToProps = state => ({
  redirectionData: state.page.redirectionData,
  countryId: state.common.settings.countryId,
  storeId: state.page.homepageState.storeId,
  settings: state.common.settings,
  countryData: state.common.countryData,
  ipCountryData: state.common.ipCountryData,
  loader: state.common.showLoader,
  language:
    state.common.language === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english,
  routeLanguage: state.common.language,
  isHomePageRedux: state.common.isHomePageRedux,
  breadcrumbReducer: state.breadcrumbReducer
});

const mapDispatchToProps = {
  getPageApi,
  getMegaMenuData,
  changeIsHomePageRedux,
  is404: errorNotFound,
  clearApiData: clearRedirectionData,
  hidePageLoader,
  hideLoadingIndicator
};

export default compose(
  withRouter,
  withAnalyticPageBrowseTracking,
  connect(mapStateToProps, mapDispatchToProps),
  withAuthenticationChecking
)(Redirection);
