import ApiService from "./ApiService";
import { webapiPost } from "../webapis/core";
import { BEConfig } from "../config/env";

class AlgoliaInsightsService extends ApiService {
  async trackProductClick({ position, productId, queryID, algoliaIndex }) {
    const apiURL = `${this.getBaseUrl()}/product-click`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        position,
        productId: productId.toString(),
        queryID,
        algoliaIndex
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async trackProductDetailView({ productId }) {
    const apiURL = `${this.getBaseUrl()}/product-detail-view`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        productId: productId.toString()
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async trackProductPurchase({ productIdList }) {
    const apiURL = `${this.getBaseUrl()}/product-purchase`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        productIdList
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.productsApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}insights`;

    return url;
  }
}

const instance = new AlgoliaInsightsService();
export default instance;
