import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { selectRouteSlug } from "../../../util/selectors";

import getBrandUrlWithCategorySlug from "./getBrandUrlWithCategorySlug";

const Brands = ({ groupName, data, routeSlug, selectedCategory }) => (
  <div className="brands">
    <div className="brands-header" id={`section${groupName}`}>
      {!isEmpty(data) ? (
        <p>
          {groupName} <span>({data.length})</span>
        </p>
      ) : (
        <div>No Result found</div>
      )}
    </div>
    <ul className="brands-list-wrapper">
      {!isEmpty(data) &&
        data.map(brand => (
          <li key={brand.name + brand.category}>
            <Link
              to={`/${routeSlug}${getBrandUrlWithCategorySlug(
                brand,
                selectedCategory
              )}`}
              className="brands-list-text"
            >
              {brand.name}
            </Link>
          </li>
        ))}
    </ul>
  </div>
);

const mapStateToProps = state => ({
  routeSlug: selectRouteSlug(state)
});

export default connect(mapStateToProps)(Brands);
